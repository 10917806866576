import React, { useMemo } from 'react';
import { useProjectSalesInfoPaymentHistoryCreateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoPaymentHistoryCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/widgets/context';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { ProjectSalesInfoPaymentHistoryCreateModalTable } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/components/create-modal-table';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoPaymentHistoryCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    h: { onCreate },
    d: { isPaidOptionList, consortiumCompanyList },
  } = useProjectSalesInfoPaymentHistoryCreateModalLogic(menuId);
  const { Layout, Body } = collectionModalLayout;
  const { isCreateModalOpen, setIsCreateModalOpen } = useProjectSalesInfoPaymentHistoryState(
    (state) => ({
      isCreateModalOpen: state.isCreateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    })
  );
  const value = useMemo(
    () => ({
      d: {
        isPaidOptionList,
        consortiumCompanyList,
      },
      h: {
        onCreate,
      },
    }),
    [onCreate, isPaidOptionList, consortiumCompanyList]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isCreateModalOpen}
      onClose={() => setIsCreateModalOpen()}
      title="구조 검토비 지급 내역 추가"
    >
      <ProjectSalesInfoPaymentHistoryCreateModalContext.Provider value={value}>
        <Layout width="100%">
          <Body>
            <ProjectSalesInfoPaymentHistoryCreateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoPaymentHistoryCreateModalContext.Provider>
    </ModalLayout>
  );
};
