import React, { useCallback, useMemo } from 'react';
import type { ProceedingsCommentId } from '../../domain';
import CommentsListSection from '../../view/Form/CommentsListSection';
import { useDispatch, useSelector } from 'react-redux';
import type { ProceedingsCommentParameter } from '../../parameter';
import { proceedingsAction } from '../../action';
import { FormikProvider, useFormik } from 'formik';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { RootState } from '@front/services/reducer';
import { getAttendance, getReader, getWriter } from '@front/proceedings/util/getAuthority';

interface Props extends SectionComponentProps {}

export default function CommentsRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const dispatch = useDispatch();
  const addComment = useCallback(
    (params: ProceedingsCommentParameter) => dispatch(proceedingsAction.addComment(params)),
    [dispatch]
  );
  const updateComment = useCallback(
    (params: ProceedingsCommentParameter) => dispatch(proceedingsAction.updateComment(params)),
    [dispatch]
  );
  const deleteComment = useCallback(
    (id: ProceedingsCommentId) => dispatch(proceedingsAction.deleteComment(id)),
    [dispatch]
  );
  const formik = useFormik<ProceedingsCommentParameter>({
    initialValues: {} as ProceedingsCommentParameter,
    onSubmit: (values) => {
      addComment(values);
      formik.resetForm();
    },
  });
  const { detail, access } = useSelector((root: RootState) => root.proceedings);
  const isReader: boolean = useMemo(() => {
    if (access && detail) {
      return getReader(access, detail);
    }
    return false;
  }, [detail?.readerList, access]);
  const isWriter: boolean = useMemo(() => {
    if (access && detail) {
      return getWriter(access, detail);
    }
    return false;
  }, [detail?.writer, access]);
  const isAttendance: boolean = useMemo(() => {
    if (access && detail) {
      return getAttendance(access, detail);
    }
    return false;
  }, [detail?.attendanceList, access]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <FormikProvider value={formik}>
            <CommentsListSection
              list={detail.commentList}
              updateComment={updateComment}
              deleteComment={deleteComment}
              isReader={isReader}
              isWriter={isWriter}
              isAttendance={isAttendance}
              detail={detail}
            />
          </FormikProvider>
        )}
      </Body>
    </Layout>
  );
}
