import React from 'react';
import { AccountingSettingsTexAccountingAccountUploadModal } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/upload/components/modal';
import { AccountingSettingsTexAccountingAccountUploadButton } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/upload/components/upload-button';

export { Widget as AccountingSettingsTexAccountingAccountUploadWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <>
    <AccountingSettingsTexAccountingAccountUploadModal menuId={menuId} />
    <AccountingSettingsTexAccountingAccountUploadButton />
  </>
);
