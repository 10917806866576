import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as affiliatedCompanyPersonEducationApi };

const url = {
  create: (id) => `${personBaseUrl}/${id}/education`,
  getList: (id) => `${personBaseUrl}/${id}/education`,
  update: (id) => `${personBaseUrl}/education/${id}`,
  delete: () => `${personBaseUrl}/education`,
};

const api = {
  create: async (id, menuId) => {
    await axios.post(url.create(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getList: async (id, menuId) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params, menuId) => {
    await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  delete: async (params, menuId) => {
    await axios.delete(url.delete(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
};
