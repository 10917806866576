import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { AccountingSettingsBankAccountQuery } from '@front/src/features/accounting/features/settings/features/bank-account/query/query';
import { AccountingSettingsBankAccountMutation } from '@front/src/features/accounting/features/settings/features/bank-account/query/mutation';

export { Repository as AccountingSettingsBankAccountRepository };

const Repository = {
  useGetList: (menuId) => {
    const { data, isLoading } = AccountingSettingsBankAccountQuery.useGetList(menuId);
    return {
      data,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = AccountingSettingsBankAccountMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
