import { projectSalesInfoContractEarningsItemCreateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { useEffect } from 'react';

export { useLogic as useProjectSalesInfoContractEarningsItemCreateModalLogic };

const useLogic = (menuId) => {
  const itemId = useContractEarningsItemState((state) => state.itemId);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const resetCollectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.resetCollectionManagementList
  );
  const { run: onCreate } =
    projectSalesInfoContractEarningsItemCreateModalRepository.useContractEarningsItemCreate(
      itemId!,
      id!,
      menuId
    );
  const { data: consortiumCompanyList, isLoading: isConsortiumCompanyListLoading } =
    projectSalesInfoContractEarningsItemCreateModalRepository.useConsortiumCompanyGet(id!, menuId);
  const { data: confirmedContractDetail, isLoading: isConfirmedContractDetailLoading } =
    projectSalesInfoContractEarningsItemCreateModalRepository.useConfirmedContractGet(id!, menuId);
  useEffect(
    () => () => {
      resetCollectionManagementList();
    },
    [resetCollectionManagementList]
  );
  return {
    h: {
      onCreate,
    },
    d: {
      consortiumCompanyList,
      confirmedContractDetail,
    },
    isLoading: isConsortiumCompanyListLoading || isConfirmedContractDetailLoading,
  };
};
