import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import WorkOutputTableBody from '@front/src/features/work/features/work/features/output/components/TableBody';
import useWorkOutputGetIsLoading from '@front/src/features/work/features/work/features/output/hooks/useGetLoading';
import workOutputQuery from '@front/src/features/work/features/work/features/output/query/query';
import WorkOutputTableHead from '@front/src/features/work/features/work/features/output/components/TableHead';

interface Props extends SectionComponentProps {
  item: WorkOutputBundleView;
  index: number;
  onOpenCreateModal: () => void;
  onOpenUpdateModal: () => void;
}

export default function WorkOutputTable(props: Props) {
  const { item, sectionId, dataId, menuId, index, onOpenUpdateModal, onOpenCreateModal } = props;

  const bundleOptions = {
    index,
    item,
    onOpenCreateModal,
    onOpenUpdateModal,
  };

  const isLoading = useWorkOutputGetIsLoading(item.id);

  const { data: list } = workOutputQuery.useGetList({
    bundleId: bundleOptions?.item.id,
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkOutputTableHead}
      BodyComponent={WorkOutputTableBody}
      bundleOptions={bundleOptions}
      list={list}
    />
  );
}

export interface UIBuilderBundleOptions {
  index: number;
  item: any;
  onOpenCreateModal?: () => void;
  onOpenUpdateModal?: () => void;
}
