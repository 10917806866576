import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectAffiliatedCompanyPersonUpdateParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  delete: () => `${projectSalesInfoURL}/affiliated-company-person`,
  update: (id?) => `${projectSalesInfoURL}/affiliated-company-person/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (params: IdListDeleteParameter, menuId) => {
    await axios.delete(url.delete(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
  update: async (params: ProjectAffiliatedCompanyPersonUpdateParameter, menuId) => {
    await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
