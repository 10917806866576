// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yGJ39CFJgw93_lqSAc2u{display:grid;grid-template-columns:2fr 1fr;gap:40px;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/subject-review/features/modal/components/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".container {\r\n  display: grid;\r\n  grid-template-columns: 2fr 1fr;\r\n  gap: 40px;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `yGJ39CFJgw93_lqSAc2u`
};
export default ___CSS_LOADER_EXPORT___;
