import React from 'react';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomDialog } from '@front/src/features/dialog';
import workCarouselBundleRepository from '@front/src/features/work/features/work/features/carousel-bundle/repository';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkCarouselBundleView } from '@front/src/features/work/features/work/features/carousel-bundle/types/view';

interface Props extends SectionComponentProps {
  item: WorkCarouselBundleView;
  index: number;
}

export default function WorkCarouselNameUpdateButton(props: Props) {
  const { sectionId, dataId, menuId, item } = props;
  const { prompt } = useCustomDialog();

  const { run: onUpdate } = workCarouselBundleRepository.useUpdate({
    sectionId,
    dataId,
    menuId,
  });

  const handleClick = () => {
    prompt({
      lineBreakChildren: [],
      defaultValue: item.name,
      title: '제목 수정',
      promptText: '확인',
      closeText: '취소',
      multiline: false,
      maxLength: 500,
      rows: 10,
      afterPrompt: (value) => {
        onUpdate({
          id: item.id,
          name: value,
        });
      },
    });
  };
  return (
    <ButtonIconUI
      shape="tertiary"
      size="medium"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon="pen" />
    </ButtonIconUI>
  );
}
