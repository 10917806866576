import type { AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import { accountingManagementAccountingURL } from '@front/src/features/accounting/features/settings/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as accountingManagementAccountingAccountTableApi };

const url = {
  getCountMap: () => `${accountingManagementAccountingURL}`,
  getList: () => `${accountingManagementAccountingURL}/get-list`,
  create: () => `${accountingManagementAccountingURL}`,
  insert: () => `${accountingManagementAccountingURL}/insert`,
  update: (id?) => `${accountingManagementAccountingURL}/${id}`,
  delete: (id?) => `${accountingManagementAccountingURL}/${id}`,
};

const Api = {
  getCountMap: async (menuId) => {
    const { data } = await axios.get(url.getCountMap(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getList: async (
    params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter,
    menuId
  ) => {
    const { data } = await axios.post(url.getList(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  create: async (params, menuId) => {
    await axios.post(url.create(), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  insert: async (params, menuId) => {
    const { data } = await axios.post(url.insert(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params, menuId) => {
    if (!params.id) {
      return;
    }
    const { data } = await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (id?, menuId?) => {
    await axios.delete(url.delete(id), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
