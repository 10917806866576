import React, { useEffect } from 'react';
import { AffiliatedCompanyProjectListWidget } from '@front/src/features/affiliated-company/features/project/widgets/list/widget';
import { AffiliatedCompanyProjectListTable } from '@front/src/features/affiliated-company/features/project/components/table';
import { useShallow } from 'zustand/react/shallow';

import { useAffiliatedCompanyProjectState } from '@front/src/features/affiliated-company/features/project/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyProjectFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const { setReadOnly } = useAffiliatedCompanyProjectState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AffiliatedCompanyProjectListTable>
          <AffiliatedCompanyProjectListWidget menuId={menuId} />
        </AffiliatedCompanyProjectListTable>
      </Body>
    </Layout>
  );
};

export default AffiliatedCompanyProjectFeature;
