import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import { affiliatedPersonMemoApi } from '@front/src/features/affiliated-person/api/api';
import type { AffiliatedPersonMemoCreateParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';

export { mutation as affiliatedPersonMemoMutation };

const mutation = {
  useCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedPersonMemoCreateParameter) =>
        affiliatedPersonMemoApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'memo', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params) => affiliatedPersonMemoApi.update(params, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'memo', 'list'],
        });
        return data;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params) => affiliatedPersonMemoApi.delete(params, menuId),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'memo', 'list'],
        });
        return data;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
