import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ColorPalette } from '@front/assets/theme';
import UserSelectorDepartmentList from '@front/src/features/user-selector/features/form/components/DepartmentList';
import UserSelectorUserSelectForm from '@front/src/features/user-selector/features/form/components/UserSelectForm';
import UserSelectorSearchForm from '@front/src/features/user-selector/features/form/components/SearchForm';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';
import Box from '@mui/material/Box';
import type { UserVO } from '@front/user/domain';
import Button from '@front/layouts/Button';
import { Divider } from '@mui/material';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';

interface Props extends UserSelectorInputProps {
  onClose: () => void;
}

export default function UserSelectorFormFeature(props: Props) {
  const { onChange, onClose, value } = props;

  const { data: allUser } = userSelectorRepository.useGetAllUser();

  const methods = useForm<UserSelectorFormValues>({
    defaultValues,
  });
  const { reset, handleSubmit } = methods;

  const onSubmit = (data) => {
    onClose();
    onChange(data.selectedList);
  };

  const initialize = () => {
    reset({
      ...defaultValues,
      selectedList: getInputList(value, allUser),
    });
  };

  useEffect(() => {
    if (value && allUser) {
      initialize();
    }
  }, [value, allUser]);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <Button
            shape="basic2"
            onClick={onClose}
          >
            취소
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>저장</Button>
        </Box>
        <Divider />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <UserSelectorSearchForm initialize={initialize} />
          <Box
            sx={{
              display: 'flex',
              gap: '2px',
            }}
          >
            <Box
              sx={{
                flex: 2,
                border: `1px solid ${ColorPalette._e4e9f2}`,
                borderRadius: '5px',
                maxHeight: '40dvh',
                overflowY: 'auto',
                minWidth: '200px',
              }}
            >
              <UserSelectorDepartmentList />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowRightIcon />
            </Box>
            <UserSelectorUserSelectForm {...props} />
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

const defaultValues = {
  searchKeyword: '',
  keyword: '',
  departmentId: null,
  selectedList: [],
};

interface UserSelectorFormValues {
  keyword: string;
  searchKeyword: string;
  departmentId: number | null;
  selectedList: UserVO[] | undefined;
}

const getInputList = (value: number | number[] | undefined, allUser) => {
  if (value === undefined) return [];
  if (Array.isArray(value)) {
    return value.map((id) => allUser?.find((a) => a.id === id));
  }
  const find = allUser?.find((a) => a.id === value);
  if (find) {
    return [find];
  }
  return [];
};
