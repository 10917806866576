import React, { Suspense } from 'react';
import { AccountingUploadEcountDataListProvider } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/provider/provider';
import { AccountingUploadEcountDataList } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/components/list';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';

export { Widget as AccountingUploadEcountDataListWidget };
interface Props {
  menuId?: number;
}

const Widget = ({ menuId }: Props) => (
  <Suspense
    fallback={
      <TableLoadingSpinner
        colSpan={5}
        height="60px"
      />
    }
  >
    <AccountingUploadEcountDataListProvider menuId={menuId}>
      <AccountingUploadEcountDataList />
    </AccountingUploadEcountDataListProvider>
  </Suspense>
);
