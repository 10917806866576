import React from 'react';
import { Box, Typography } from '@mui/material';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { APPROVAL_DOCUMENT_STATUS, APPROVAL_TYPE } from '@front/type/domain';
import { approvalDocumentMutation } from '@front/src/features/drawer-approval-document/query/mutation';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props {
  item?: ApprovalDocumentDetailView;
}

export default function ApprovalDocumentForm({ item }: Props) {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { mutate: cancelApprovalDocument } = approvalDocumentMutation.useCancelApprovalDocument();
  const { confirm } = useCustomDialog();

  const onCancel = () => {
    confirm({
      title: '결재 상신 취소 확인',
      lineBreakChildren: [
        { value: '결재 상신이 취소되면 되돌릴 수 없습니다.' },
        { value: '결재 요청을 취소 하시겠습니까?' },
      ],
      afterConfirm: () => {
        cancelApprovalDocument(item?.id ?? 0);
      },
    });
  };

  const { documentId } = useIdGroup();
  const isSubmitter = item?.approvalSteps[0].manager.id === loginUser?.id;
  const isApprover = item?.approvalSteps.slice(1).find((step) => step.manager.id === loginUser?.id);
  const isOnGoing = isApprover?.stepName === '진행';
  const approvalId = isApprover?.approvalId;
  const { mutate: decideApprovalDocument } = approvalDocumentMutation.useDecideApprovalDocument(
    approvalId ?? 0
  );
  const setDocumentId = useApprovalDocumentStore((state) => state.setDocumentId);

  const onDecide = (isApprove: boolean) => {
    if (isApprove) {
      confirm({
        title: '결재 승인 확인',
        lineBreakChildren: [
          { value: '결재가 승인되면 되돌릴 수 없습니다.' },
          { value: '결재를 승인하시겠습니까?' },
        ],
        afterConfirm: () => {
          decideApprovalDocument({
            result: APPROVAL_TYPE.APPROVED,
          });
        },
      });
    } else {
      confirm({
        title: '결재 반려 확인',
        lineBreakChildren: [
          { value: '결재가 반려되면 되돌릴 수 없습니다.' },
          { value: '결재를 반려하시겠습니까?' },
        ],
        afterConfirm: () => {
          decideApprovalDocument({
            result: APPROVAL_TYPE.REJECTED,
          });
        },
      });
    }
  };
  return (
    <Box sx={{ ...SX_FORM_WRAP }}>
      <Typography
        sx={{
          borderRight: '1px solid rgba(0,0,0,0.1)',
          paddingRight: '10px',
        }}
      >
        총 <strong>{item?.approvalDocumentList.length}</strong>건
      </Typography>
      <Typography>결재 건 선택</Typography>
      <Box width="250px">
        <DropDownUI
          value={documentId}
          onChange={(e) => setDocumentId(+(e.target.value as string))}
          options={
            item?.approvalDocumentList.map((doc) => ({
              label: `${doc.title} (${doc.status})`,
              value: doc.id,
            })) ?? []
          }
        />
      </Box>
      {!isSubmitter && !isApprover && <Typography>{}</Typography>}
      {isSubmitter && item?.status === APPROVAL_DOCUMENT_STATUS.SUBMISSION && (
        <ButtonBasicUI
          onClick={onCancel}
          sx={{
            marginRight: '5px',
            background: 'var(--design-v-10-light-sub-2-sub-2-primary, #E43333)',
          }}
        >
          결재 상신 취소
        </ButtonBasicUI>
      )}
      {isApprover && isOnGoing && (
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <ButtonBasicUI
            onClick={() => onDecide(true)}
            sx={{
              background: 'var(--design-v-10-light-background-bg-05, #35455F)',
              width: '70px',
            }}
          >
            승인
          </ButtonBasicUI>
          <ButtonBasicUI
            onClick={() => onDecide(false)}
            sx={{
              background: 'var(--design-v-10-light-sub-2-sub-2-primary, #E43333)',
              width: '70px',
            }}
          >
            반려
          </ButtonBasicUI>
        </Box>
      )}
    </Box>
  );
}

const SX_FORM_WRAP = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '14px',
  alignItems: 'center',
  background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
