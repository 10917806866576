import type { ApprovalBoxSearchParams } from '@front/src/features/approval-box/type/parameter';
import { create } from 'zustand';
import { APPROVAL_BOX_TYPE } from '@front/type/domain';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import type { ApprovalBoxState, BoxDetailState } from '@front/src/features/approval-box/type/view';

interface State {
  boxSearchParams: ApprovalBoxSearchParams;
  isBoxList: boolean;
  documentId: number;
  params: ApprovalDocumentDetailQueryParams;

  boxState: ApprovalBoxState;
  boxDetailState: Record<string, BoxDetailState>;
}

interface Action {
  setBoxSearchParams: (params: Partial<ApprovalBoxSearchParams>) => void;
  setIsBoxList: (isBoxList: boolean) => void;
  setDocumentId: (documentId: number) => void;

  setBoxState: (boxState: Partial<ApprovalBoxState>) => void;
  resetBoxState: () => void;
  setBoxDetailState: (id: number, boxDetailState: BoxDetailState) => void;
  resetBoxDetailState: () => void;
}

const useApprovalBoxStore = create<State & Action>((set) => ({
  boxSearchParams: {
    type: APPROVAL_BOX_TYPE.APPROVAL_DOCUMENT.toLowerCase(),
    filter: 'all',
  },
  isBoxList: true,
  documentId: 0,
  params: {
    where: 'approval',
  },
  boxState: {
    keyword: '',
    startDate: '',
    endDate: '',
  },
  boxDetailState: {},

  setBoxSearchParams: (params) =>
    set((state) => ({ boxSearchParams: { ...state.boxSearchParams, ...params } })),
  setIsBoxList: (isBoxList) => set({ isBoxList }),
  setDocumentId: (documentId) => set({ documentId }),
  setBoxState: (boxState: Partial<ApprovalBoxState>) =>
    set((state) => ({ boxState: { ...state.boxState, ...boxState } })),
  resetBoxState: () => set({ boxState: { keyword: '', startDate: '', endDate: '' } }),
  setBoxDetailState: (id: number, boxDetailState: BoxDetailState) =>
    set((state) => ({
      boxDetailState: {
        ...state.boxDetailState,
        [id]: boxDetailState,
      },
    })),
  resetBoxDetailState: () => set({ boxDetailState: {} }),
}));

export default useApprovalBoxStore;
