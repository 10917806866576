export interface ProposalQuery {
  keyword: string;
  status: string;
}

export const initialProposalQuery: ProposalQuery = {
  keyword: '',
  status: '',
};

export interface ProposalMemoQuery {
  keyword: string;
}

export const initialProposalMemoQuery: ProposalMemoQuery = {
  keyword: '',
};
