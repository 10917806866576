import React, { Suspense } from 'react';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { EstimationCompetitorTable } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/components/table';
import { estimationCompetitorTableCells } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/components/table-cells';

export { Section as EstimationCompetitorSection };

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]견적 / [섹션]경쟁사 현황
 * @param props
 * @constructor
 */
function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(estimationCompetitorTableCells);
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={props.name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <EstimationCompetitorTable sectionProps={props} />
      </Suspense>
    </Layout>
  );
}
