import React, { useCallback, useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useAccountingUploadSalesInfoGoalModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/useState';
import { AccountingUploadSalesInfoGoalModalUpdateContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/provider/context';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useFormContext } from 'react-hook-form';
import { AccountingUploadSalesInfoGoalModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/provider/context';
import { useShallow } from 'zustand/react/shallow';
import { convertAccountingUploadSalesInfoGoalUpdateParameterFromGoalModalForm } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/utils';

export { ButtonGroup as AccountingUploadSalesInfoGoalModalButtonGroup };

const ButtonGroup = () => {
  const { list } = useContext(AccountingUploadSalesInfoGoalModalListContext);
  const { onUpdate } = useContext(AccountingUploadSalesInfoGoalModalUpdateContext);
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { setIsOpen } = useAccountingUploadSalesInfoGoalModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const { handleSubmit } = useFormContext();
  const onSave = handleSubmit((data) => {
    const dataConverted = convertAccountingUploadSalesInfoGoalUpdateParameterFromGoalModalForm(
      year,
      data,
      list
    );
    onUpdate({
      ...dataConverted,
      isConfirmed: false,
    });
  });
  const onConfirm = handleSubmit((data) => {
    const dataConverted = convertAccountingUploadSalesInfoGoalUpdateParameterFromGoalModalForm(
      year,
      data,
      list
    );
    onUpdate({
      ...dataConverted,
      isConfirmed: !isConfirmed,
    });
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const isConfirmed = useMemo(() => list?.[0]?.isConfirmed, [list]);
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
      }}
    >
      <Button onClick={handleClose}>취소</Button>
      <Button
        onClick={onSave}
        disabled={isConfirmed}
      >
        저장
      </Button>
      <Box
        sx={{
          width: '120px',
        }}
      >
        <Button onClick={onConfirm}>{isConfirmed ? '확정 취소 요청' : '확정'}</Button>
      </Box>
    </Box>
  );
};
