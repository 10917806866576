import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { shallow } from 'zustand/shallow';
import { useQueryClient } from 'react-query';
import { useProjectSalesInfoSubjectReviewModalComplexInformationState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/useState';
import { ProjectSalesInfoSubjectReviewModalBody } from '@front/src/features/project-sales-info/features/subject-review/features/modal/components/body';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { Index as ProjectSalesInfoSubjectReviewModalFeature };
interface Props {
  menuId?: number;
}
const Index = ({ menuId }: Props) => {
  const { isOpen, setIsOpen } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { setIdList } = useProjectSalesInfoSubjectReviewModalComplexInformationState(
    (state) => ({
      setIdList: state.setIdList,
    }),
    shallow
  );
  const queryClient = useQueryClient();
  const handleClose = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['project', 'sales-info', 'subject-review', 'experiment'],
    });
    await queryClient.invalidateQueries({
      queryKey: ['project', 'sales-info', 'subject-info', 'shape-ratio', 'list'],
    });
    setIdList([]);
    setIsOpen(false);
  }, [queryClient, setIsOpen, setIdList]);
  return (
    <ModalLayout
      title="실험대상 검토 상세"
      width="80dvw"
      height="80dvh"
      open={isOpen}
      onClose={handleClose}
    >
      <ProjectSalesInfoSubjectReviewModalBody menuId={menuId} />
    </ModalLayout>
  );
};
