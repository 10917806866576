import { useQuery } from 'react-query';
import { projectSalesInfoContractHistoryModalApi } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/query/api';
import type { ProjectContractHistoryView } from '@front/src/features/project-sales-info/features/contract/features/history/types/view';

export { query as projectSalesInfoContractHistoryModalQuery };

const query = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectContractHistoryView>({
      queryFn: () => projectSalesInfoContractHistoryModalApi.getDetail(id, menuId),
      queryKey: ['project', 'sales-info', 'contract', 'contract-history', id],
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
