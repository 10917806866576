import { FormControl, RadioGroup } from '@mui/material';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { OptionType } from '@front/src/components/Select';
import RadioButtonUI from '@front/src/components/components-with-design/component/RadioButtonUI';

interface Props<T = string> {
  name: string;
  optionList: OptionType<T>[];
  required?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export default function RadioWithHookForm({
  name,
  optionList,
  required,
  disabled,
  readOnly,
}: Readonly<Props>) {
  const { control, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control, rules: { required } });
  const getChecked = (value) => getValues(name) === value;
  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={onChange}
        style={{ flexDirection: 'row', gap: '20px' }}
      >
        {optionList.map(({ value, label, disabled: optionDisabled }) => (
          <RadioButtonUI
            key={value}
            value={value}
            label={label}
            checked={getChecked(value)}
            disabled={disabled || readOnly || optionDisabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
