import React, { memo, useContext } from 'react';
import { TableRow } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import { OldTd } from '@front/layouts/Table';

import { convertGiftTargetType } from '@front/src/features/affiliated-company/utils';
import { AffiliatedCompanyGiftHistoryContext } from '@front/src/features/affiliated-company/features/gift-history/widgets/context';

export { GiftHistoryTableBody as AffiliatedCompanyGiftHistoryTableBody };

const GiftHistoryTableBody = () => {
  const {
    d: { giftList },
    isLoading,
  } = useContext(AffiliatedCompanyGiftHistoryContext);
  if (typeof giftList === 'undefined' || isLoading) {
    return <Spinner />;
  }
  if (giftList.length === 0) {
    return <NoResult />;
  }
  return (
    <>
      {giftList.map((gift, index) => (
        <TableRow key={gift.id}>
          <OldTd>{index + 1}</OldTd>
          <OldTd>{gift.type ? convertGiftTargetType(gift.type) : '-'}</OldTd>
          <OldTd>{gift.name ?? '-'}</OldTd>
          <OldTd>{gift.date ?? '-'}</OldTd>
          <OldTd>{gift.gift ?? '-'}</OldTd>
          <OldTd>{gift.purpose ?? '-'}</OldTd>
          <OldTd>{gift.amount ? `${gift.amount.toLocaleString()}원` : '-'}</OldTd>
          <OldTd>{gift.deliveryMethod ?? '-'}</OldTd>
          <OldTd>{gift.sender?.name ?? '-'}</OldTd>
          <OldTd>{gift.note ?? '-'}</OldTd>
        </TableRow>
      ))}
    </>
  );
};

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={10}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));

Spinner.displayName = 'AffiliatedCompanyGiftHistoryTableBodySpinner';

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={10}>저장된 선물이력이 없습니다.</OldTd>
  </TableRow>
));

NoResult.displayName = 'AffiliatedCompanyGiftHistoryTableBodyNoResult';
