import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyOutlineBankInfoApi } from '@front/src/features/affiliated-company/features/outline/features/bank-info/query/api';
import type {
  AffiliatedCompanyOutlineBankBookFileUpdateParameter,
  AffiliatedCompanyOutlineBankInfoUpdateParameter,
} from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';

export const affiliatedCompanyOutlineBankInfoMutation = {
  useBankInfoUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineBankInfoUpdateParameter) =>
        affiliatedCompanyOutlineBankInfoApi.updateBankInfo(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'outline']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useBankBookFileUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineBankBookFileUpdateParameter) =>
        affiliatedCompanyOutlineBankInfoApi.updateBankBookFile(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'outline']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
