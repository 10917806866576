import React from 'react';
import UIBuilderTableHeader, { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';

type Props = Readonly<UIBuilderTableHeadProps>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UIBuilderTableHeader>;

const AccountStatusWidgetDetailTableHead: ElementType = (props) => {

  return (
    <UIBuilderTableHeader
      {...props}
    />
  );
};

export default AccountStatusWidgetDetailTableHead;
