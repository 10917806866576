import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

interface Props extends CellComponentProps {
  name: string;
  type?: 'default' | 'percent' | 'comma';
  displayValue?: string;
}

export default function UIBuilderTableCellInputNumber(props: Props) {
  const {
    isEditMode,
    isForm,
    onSubmit,
    readOnly,
    onClick,
    sx,
    name,
    type = 'default',
    displayValue,
  } = props;

  const { getValues } = useFormContext();
  const value = getValues(name);

  const renderContent = () => {
    if (readOnly || !isEditMode) {
      if (displayValue) return displayValue;
      if (type === 'default') return value || '-';
      if (type === 'percent') return value ? `${value}%` : '-';
      if (type === 'comma') return value?.toLocaleString() || '-';
    }
    return (
      <UncontrolledInput
        type="number"
        name={name}
        onBlur={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
      />
    );
  };

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
