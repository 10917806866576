interface Page<T> extends Pagination {
  content: T[];
}

export interface Pagination {
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  empty: boolean;
  first: boolean;
  last: boolean;
  numberOfElements: number;
}

export const pageSizeList = [10, 20, 50, 100];

export default Page;
