import { useRef } from 'react';

const useDebounce = (delay = 300) => {
  const debounceTimeout = useRef<number | undefined>();

  const handleDebounce = (callback: () => void) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = window.setTimeout(callback, delay);
  };

  return handleDebounce;
};

export default useDebounce;
