import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { approvalDocumentMutation } from '@front/src/features/drawer-approval-document/query/mutation';

const useComment = (type: string) => {
  const noteId = useGlobalNavBarState((state) => state.noteId);
  const commentQueryParams = {
    targetId: noteId ?? 0,
    targetType: type,
  };

  const { mutate: createComment } = approvalDocumentMutation.useCreateComment(commentQueryParams);
  const { mutate: updateComment } = approvalDocumentMutation.useUpdateComment(commentQueryParams);
  const { mutate: deleteComment } = approvalDocumentMutation.useDeleteComment(commentQueryParams);
  return { createComment, updateComment, deleteComment };
};

export default useComment;
