import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyOutlinePrimaryBusinessButtonGroup } from '@front/src/features/affiliated-company/features/outline/features/primary-business/components/button-group';
import { AffiliatedCompanyOutlinePrimaryBusinessTable } from '@front/src/features/affiliated-company/features/outline/features/primary-business/components/primary-business-table';
import { useAffiliatedCompanyOutlinePrimaryBusinessLogic } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/useLogic';
import { AffiliatedCompanyOutlinePrimaryBusinessContext } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlinePrimaryBusinessWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { primaryBusinessList },
    h: { onCreatePrimaryBusiness, setIdList, onDeletePrimaryBusiness, onUpdatePrimaryBusiness },
    idList,
    isLoading,
  } = useAffiliatedCompanyOutlinePrimaryBusinessLogic(menuId);
  const value = useMemo(
    () => ({
      d: { primaryBusinessList },
      h: { onCreatePrimaryBusiness, setIdList, onDeletePrimaryBusiness, onUpdatePrimaryBusiness },
      isLoading,
      idList,
    }),
    [
      primaryBusinessList,
      isLoading,
      onCreatePrimaryBusiness,
      setIdList,
      idList,
      onDeletePrimaryBusiness,
      onUpdatePrimaryBusiness,
    ]
  );
  return (
    <AffiliatedCompanyOutlinePrimaryBusinessContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyOutlinePrimaryBusinessButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyOutlinePrimaryBusinessTable />
        </Body>
      </Layout>
    </AffiliatedCompanyOutlinePrimaryBusinessContext.Provider>
  );
};

export default AffiliatedCompanyOutlinePrimaryBusinessWidget;
