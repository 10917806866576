import React, { memo } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@mui/material';

import Select from '@front/layouts/Select';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import type { OstStatus } from '@front/ost/domain';
import { ostStatusList, ostStatusName } from '@front/ost/domain';

interface Props {
  status?: OstStatus;
  onStatusChange: (e: SelectChangeEvent<unknown>) => void;
}

const OstUpdateFormStatus = ({ onStatusChange, status }: Props) => (
  <DataFieldWithLabel
    label="상태"
    labelSX={{ minWidth: '80px' }}
  >
    <Select
      displayEmpty
      variant="outlined"
      value={status || ''}
      onChange={onStatusChange}
    >
      <MenuItem value={''}>상태명</MenuItem>
      {ostStatusList.map((item) => (
        <MenuItem
          key={item}
          value={item}
        >
          {ostStatusName(item)}
        </MenuItem>
      ))}
    </Select>
  </DataFieldWithLabel>
);

const OstFormStatus = memo(OstUpdateFormStatus);

export default OstFormStatus;
