import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextBox from '@front/layouts/Text';
import type { SxProps } from '@mui/system';

export { InfoText as AccountingUploadSalesInfoInfoText };

interface Props {
  label: string;
  value: ReactNode;
  sx?: SxProps;
}

const InfoText = ({ value, label, sx }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    }}
  >
    <FontAwesomeIcon
      icon="circle"
      fontSize="6px"
    />
    <TextBox
      variant="body11"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Box>{label} :</Box>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          width: '160px',
          ...sx,
        }}
      >
        {value}
      </Box>
    </TextBox>
  </Box>
);
