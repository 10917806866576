import React, { useCallback, useContext, useMemo } from 'react';
import { AffiliatedPersonMemoListContext } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/context';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';
import { Box } from '@mui/material';
import DateFormat from '@front/layouts/DateFormat';
import Tooltip from '@front/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';

export { ItemHeader as AffiliatedPersonMemoItemHeader };

const ItemHeader = () => {
  const { deleteWidget } = useContext(AffiliatedPersonMemoListContext);
  const { getValues } = useFormContext();
  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );
  const { id: memoId, setId: setMemoId } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
    }))
  );
  const isWriter = useMemo(() => getValues('writer')?.id === (login?.id ?? 1), [getValues, login]);
  const sxIfWriter = useMemo(
    () => ({
      justifyContent: isWriter ? 'space-between' : 'flex-start',
    }),
    [isWriter]
  );
  const name = useMemo(() => {
    const departmentName = getValues('writer')?.department?.name;
    if (departmentName === '한양풍동실험연구소') {
      return '회사';
    }
    return departmentName;
  }, [getValues]);
  const handleUpdateIconClick = useCallback(() => {
    setMemoId(getValues('memoId'));
  }, [setMemoId, getValues]);
  const isTarget = useMemo(() => getValues('memoId') === memoId, [getValues, memoId]);
  const sxIfTarget = useMemo(
    () => ({
      visibility: isTarget ? 'hidden' : 'visible',
    }),
    [isTarget]
  );
  const modifiedAt = getValues('modifiedAt');
  const iconStyle = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      color: ColorPalette._9bb6ea,
      fontSize: '11px',
      cursor: 'pointer',
    }),
    []
  );
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          ...sxIfWriter,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            marginRight: '4px',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          <DateFormat
            date={getValues('createdAt')}
            format="YYYY-MM-DD HH:mm"
          />
          <Box>{name}</Box>
          <Box>{getValues('writer')?.name}</Box>
        </Box>
        {isWriter && (
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              ...sxIfTarget,
            }}
          >
            <Tooltip title="수정">
              <FontAwesomeIcon
                icon="pen"
                style={iconStyle}
                onClick={handleUpdateIconClick}
              />
            </Tooltip>
            {deleteWidget}
          </Box>
        )}
      </Box>
      {modifiedAt && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            marginRight: '4px',
            fontSize: '11px',
            fontWeight: 'bold',
          }}
        >
          (
          <DateFormat
            date={modifiedAt}
            format="YYYY-MM-DD HH:mm"
          />
          <Box>편집됨)</Box>
        </Box>
      )}
    </>
  );
};
