export const getIsChangedSubjectReviewModalDetailParameter = (before, now) => {
  if (typeof before !== 'boolean' && typeof now !== 'boolean') {
    return false;
  }
  if (typeof before !== 'boolean') {
    return true;
  }
  if (typeof now !== 'boolean') {
    return false;
  }
  return before !== now;
};
