import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const useWorkNameForm = ({ onUpdate, detail }) => {
  const methods = useForm({
    defaultValues: {
      name: '',
      status: '',
      completionRate: '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      name: data.name,
      status: data.status || null,
      completionRate: +data.completionRate,
    });
  });
  useEffect(() => {
    if (!detail) return;
    methods.reset({
      ...getFormValues(detail),
    });
  }, [detail]);
  return {
    methods,
    onSubmit,
  };
};

export default useWorkNameForm;

const getFormValues = (detail) => ({
  name: detail?.name ?? '',
  status: detail?.status ?? '',
  completionRate: detail?.completionRate ?? '',
});
