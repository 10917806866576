import React, { memo } from 'react';
import CircularProgress from '@front/components/CircularProgress';

export { LoadingSpinner };

interface Props {
  width?: string;
  height?: string;
  padding?: string;
  circleSize?: number
}

const LoadingSpinner = memo(({width, height, padding, circleSize = 30}: Props) => (
  <div
    style={{
      width,
      height,
      padding,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress size={circleSize} />
  </div>
));

LoadingSpinner.displayName = 'LoadingSpinner';
