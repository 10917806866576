import { create } from 'zustand';

export { useState as useAccountingSettingsDailyReportStatusState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpe: boolean) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) =>
    set({
      isOpen,
    }),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
