import React, { useContext, useMemo } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoSubjectReviewShapeRatioListContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/widgets/context';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoSubjectReviewShapeRatioListItemWidget } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/widgets/widget';

export { List as ProjectSalesInfoSubjectReviewShapeRatioList };
interface Props {
  menuId?: number;
}
const List = ({ menuId }: Props) => {
  const { list, isLoading } = useContext(ProjectSalesInfoSubjectReviewShapeRatioListContext);
  const existIsConfirmed = useMemo(() => !!list?.find((item) => item.isConfirmed), [list]);
  if (isLoading) {
    return (
      <TableLoadingSpinner
        colSpan={9}
        height="60px"
      />
    );
  }
  if (list?.length === 0) {
    return (
      <NoResult
        colSpan={9}
        height="60px"
      />
    );
  }
  return (
    <>
      {list?.map((item, index) => (
        <ProjectSalesInfoSubjectReviewShapeRatioListItemWidget
          key={item.id}
          item={item}
          index={index}
          existIsConfirmed={existIsConfirmed}
          menuId={menuId}
        />
      ))}
    </>
  );
};
