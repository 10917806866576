import type { FileItem } from 'file-item/index';

export type FileItemView = {
  id: number;
  filename: string;
  ext: string;
  size: number;
  readableSize: string;
  multipartFile?: File;
  requestDelete?: boolean;
  createdAt?: string;
  fileKey: string;
  description: string;
  hasAuth: boolean;
};

export const toReadableSize = (size: number, toBinary?: boolean): string => {
  const unit: string[] = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const divider: number = toBinary ? 1024 : 1000;
  const memory: number[] = [size];
  const whileBoolean = true;
  while (whileBoolean) {
    const temp: number = memory[memory.length - 1];
    if (temp < divider) {
      break;
    }
    memory.push(temp / divider);
  }
  return `${memory[memory.length - 1].toFixed(2)}${unit[memory.length - 1]}B`;
};

export const getExtension = (filename: string | undefined): string => {
  if (!filename || !filename.includes('.')) {
    return '';
  }
  const split: string[] = filename.split('.');
  if (split.length >= 2) {
    return split[split.length - 1].toLowerCase();
  }
  return '';
};

export function isFile(value: File | FileItemView | FileItem | undefined): value is File {
  return (
    !!value &&
    typeof (value as any).name !== 'undefined' &&
    typeof (value as any).ext === 'undefined'
  );
}

export function toView(value: File | FileItem | FileItemView): FileItemView {
  return {
    id: (value as any).id || undefined,
    filename: isFile(value) ? value.name : value.filename,
    ext: isFile(value) ? getExtension(value.name) : value.ext,
    size: value.size,
    readableSize: toReadableSize(value.size),
    multipartFile: isFile(value) ? value : undefined,
  };
}

export const fileToView = (file: File): FileItemView => ({
  filename: file.name,
  ext: getExtension(file.name),
  size: file.size,
  readableSize: toReadableSize(file.size),
  multipartFile: file,
});

export type DrawingView = {
  id?: number;
  stringifiedData: string;
  fileName: string;
  createdAt?: string;
  modifiedAt?: string;
};
