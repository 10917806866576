import React, { useCallback } from 'react';
import UserSelectorModal from '@front/components/UserSelector/Modal';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';
import type { UserId } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';

const ProposalAdvisorModalService = () => {
  const dispatch = useDispatch();

  const { isOpen, advisor, contributorViewList } = useSelector(
    (root: RootState) => ({
      isOpen: root.proposal.setAdvisorModalOpen,
      advisor: root.proposal.detail?.advisor,
      contributorViewList: root.proposal.detail?.contributorViewList,
    }),
    shallowEqual
  );

  const onClose = useCallback(() => {
    dispatch(proposalAction.setAdvisorModal(false));
  }, [dispatch]);

  const onChange = useCallback(
    (id) => {
      if (!id) {
        dispatch(
          snackbarAction.show({
            message: '한명의 협의자가 필요합니다.',
            severity: SnackbarSeverityType.warning,
          })
        );
        return;
      }
      dispatch(
        proposalAction.setAdvisor({
          advisorId: id,
        })
      );
    },
    [dispatch]
  );

  const getUserListExcluded = useCallback(() => {
    const list: UserId[] = [1 as UserId];
    if (contributorViewList && contributorViewList.length > 0) {
      list.push(...contributorViewList.map((contributorInfo) => contributorInfo.contributor.id));
    }
    return Array.from(new Set(list));
  }, [contributorViewList]);

  return (
    <UserSelectorModal
      open={isOpen}
      onClose={onClose}
      multi={false}
      value={advisor ? [advisor.id] : []}
      onChange={onChange}
      exclude={getUserListExcluded()}
      menuIdList={[33]}
      typeList={[AuthorizationType.W]}
    />
  );
};

export default ProposalAdvisorModalService;
