import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkCategoryTableBody from '@front/src/features/work/features/work/features/category/components/TableBody';
import { useIsMutating } from 'react-query';
import workCategoryQuery from '@front/src/features/work/features/work/features/category/query/query';
import WorkCategoryTableHead from '@front/src/features/work/features/work/features/category/components/TableHead';

export default function WorkWorkCategoryTable(props: Readonly<SectionComponentProps>) {
  const { menuId, sectionId, dataId } = props;

  const isLoading = !!useIsMutating({
    mutationKey: ['work', 'category', 'update'],
  });

  const { data: list } = workCategoryQuery.useGetOne({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkCategoryTableHead}
      BodyComponent={WorkCategoryTableBody}
      list={list}
    />
  );
}
