import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/context';
import { useCustomDialog } from '@front/src/features/dialog';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useState';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

export { ButtonGroup as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonButtonGroup };

const ButtonGroup = () => {
  const {
    h: { onDelete },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext);
  const { idList, setIdList } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      onDelete({ idList });
      setIdList([]);
    });
  }, [idList, confirm, setIdList, onDelete]);
  const handleAddClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택 삭제
      </Button>
      <Button
        onClick={handleAddClick}
        disabled={readOnly}
      >
        행 추가
      </Button>
    </>
  );
};
