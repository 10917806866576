import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyCharacterOpinionUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/opinion/types/parameter';

const url = {
  createCharacterOpinion: (id?) => `${baseUrl}/${id}/opinion`,
  getCharacterOpinion: (id?) => `${baseUrl}/${id}/opinion`,
  updateCharacterOpinion: (id?) => `${baseUrl}/opinion/${id}`,
  deleteCharacterOpinion: () => `${baseUrl}/opinion`,
};

export const affiliatedCompanyCharacterOpinionApi = {
  createCharacterOpinion: async (id?: number, menuId?) => {
    await axios.post(url.createCharacterOpinion(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getCharacterOpinion: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getCharacterOpinion(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCharacterOpinion: async (
    params: AffiliatedCompanyCharacterOpinionUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updateCharacterOpinion(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteCharacterOpinion: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteCharacterOpinion(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
