// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lgMmeBODbgMuafvgDAfQ{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2;font-size:13px}`, "",{"version":3,"sources":["webpack://./front/src/components/no-result-box/no-result-box.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA,CACA,cAAA","sourcesContent":["@import \"@front/assets/colors.scss\";\r\n\r\n.no__result {\r\n  display: flex;\r\n  justify-content: center;\r\n  padding: 20px;\r\n  border: 1px solid $_e4e9f2;\r\n  font-size: 13px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no__result": `lgMmeBODbgMuafvgDAfQ`
};
export default ___CSS_LOADER_EXPORT___;
