import dayjs from 'dayjs';
import {
  AccountStatusBankAccountStatusDTO,
  AccountStatusBankAccountStatusGroupDTO,
  AccountStatusDetailView,
  AccountStatusSummaryView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';


type DtoToDetailViewType = (dto: AccountStatusBankAccountStatusDTO) => AccountStatusDetailView;
type ApiResponseToDetailViewType = (apiResponse: AccountStatusBankAccountStatusGroupDTO | undefined) => AccountStatusDetailView[] | undefined;
type ApiResponseToSummaryViewType = (apiResponse: AccountStatusBankAccountStatusGroupDTO | undefined) => AccountStatusSummaryView[] | undefined;
type NumberFormattingType = (amount: number | undefined | null) => string;
type DateFormattingType = (date: Date | null | undefined | string) => string;
type DateTimeFormattingType = (date: Date | null | undefined | string) => string;
type FloatFormattingType = (float: number | undefined | null, fractionDigits?: number) => string;


export const accountStatusDtoToDetailView: DtoToDetailViewType = (dto) => {

  return {
    id: dto.id,
    alias: dto.alias ?? undefined,
    amount: dto.amount ?? 0,
    totalAmount: dto.totalAmount ?? 0,
    type: dto.type,
    code: dto.code,
    bankName: dto.bankName,
    openDate: dto.openDate,
    endDate: dto.endDate ?? undefined,
    interestRate: dto.interestRate ?? undefined,
    taxRate1: dto.taxRate1 ?? undefined,
    taxRate2: dto.taxRate2 ?? undefined,
    expectedInterest: dto.expectedInterest ?? undefined,
    expectedTax: dto.expectedTax ?? undefined,
    branchName: dto.branchName || undefined,
    productName: dto.productName || undefined,
    noteCount: dto.noteCount,
    textAttr1: dto.textAttr1,
    textAttr2: dto.textAttr2,
    textAttr3: dto.textAttr3,
    numberAttr1: dto.numberAttr1,
    numberAttr2: dto.numberAttr2,
    numberAttr3: dto.numberAttr3,
    dateAttr1: dto.dateAttr1,
    dateAttr2: dto.dateAttr2,
    dateAttr3: dto.dateAttr3,
  };
};

export const accountStatusApiResponseToDetailView: ApiResponseToDetailViewType = (apiResponse) => {
  if (typeof apiResponse === 'undefined') {
    return undefined;
  }
  return apiResponse.bankAccountStatusDtoList.map(accountStatusDtoToDetailView).sort((a, b) => {
    if (!b.alias) {
      return -1;
    }
    if (!a.alias) {
      return 1;
    }
    return a.alias.localeCompare(b.alias);
  });
};

export const accountStatusApiResponseToSummaryView: ApiResponseToSummaryViewType = (apiResponse) => {
  if (typeof apiResponse === 'undefined') {
    return undefined;
  }
  const result: AccountStatusSummaryView[] = [{
    id: '',
    aliasCount: 0,
    totalAmount: 0,
    expectedPrincipalInterest: 0,
    typeList: [],
  }];

  const typeSet = new Set<string>();
  const aliasSet = new Set<string>();
  for (let i = 0; i < apiResponse.bankAccountStatusDtoList.length; i++) {
    const dto = apiResponse.bankAccountStatusDtoList[i];
    typeSet.add(dto.type);
    if (dto.totalAmount) {
      result[0].totalAmount += dto.totalAmount;
      result[0].expectedPrincipalInterest += dto.totalAmount;
    }
    if (dto.expectedInterest) {
      result[0].expectedPrincipalInterest += dto.expectedInterest;
    }
    if (dto.alias) {
      aliasSet.add(dto.alias);
    }
  }
  result[0].aliasCount = aliasSet.size;

  typeSet.forEach(type => {
    result[0].id += `${type}-`;
    result[0].typeList.push({
      id: type,
      type,
      amount: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .map(dto => dto.amount ?? 0)
        .reduce((a, b) => a + b, 0),
      branchCount: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => dto.branchName !== null && dto.branchName.trim() !== '')
        .map(dto => dto.branchName as string)
        .map(branchName => {
          const set = new Set<string>();
          set.add(branchName);
          return set;
        })
        .reduce((a, b) => {
          if (a.size === 0) {
            return b;
          }
          if (b.size !== 0) {
            b.forEach(bItem => {
              a.add(bItem);
            });
          }
          return a;
        }, new Set<string>()).size,
      bankCount: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => dto.bankName !== null && dto.bankName.trim() !== '')
        .map(dto => dto.bankName as string)
        .map(bankName => {
          const set = new Set<string>();
          set.add(bankName);
          return set;
        })
        .reduce((a, b) => {
          if (a.size === 0) {
            return b;
          }
          if (b.size !== 0) {
            b.forEach(bItem => {
              a.add(bItem);
            });
          }
          return a;
        }, new Set<string>()).size,
      codeCount: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => dto.code !== null && dto.code.trim() !== '')
        .map(dto => dto.code as string)
        .map(code => {
          const set = new Set<string>();
          set.add(code);
          return set;
        })
        .reduce((a, b) => {
          if (a.size === 0) {
            return b;
          }
          if (b.size !== 0) {
            b.forEach(bItem => {
              a.add(bItem);
            });
          }
          return a;
        }, new Set<string>()).size,
      expectedProductInterest: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.expectedInterest)
        .map(dto => dto.expectedInterest as number)
        .reduce((a, b) => a + b, 0) + apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .map(dto => dto.amount ?? 0)
        .reduce((a, b) => a + b, 0),
      expectedInterest: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.expectedInterest)
        .map(dto => dto.expectedInterest as number)
        .reduce((a, b) => a + b, 0),
      expectedTax: apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.expectedTax)
        .map(dto => dto.expectedTax as number)
        .reduce((a, b) => a + b, 0),
      averageInterestRate: (apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.expectedInterest)
        .map(dto => dto.expectedInterest as number)
        .reduce((a, b) => a + b, 0) / apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .map(dto => dto.amount ?? 0)
        .reduce((a, b) => a + b, 0)) || 0,
      taxRate1: (apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.taxRate1)
        .map(dto => dto.taxRate1 as number)
        .reduce((a, b) => a + b, 0) / apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.taxRate1)
        .length) || 0,
      taxRate2: (apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.taxRate2)
        .map(dto => dto.taxRate2 as number)
        .reduce((a, b) => a + b, 0) / apiResponse.bankAccountStatusDtoList
        .filter(dto => dto.type === type)
        .filter(dto => !!dto.taxRate2)
        .length) || 0,
    });
  });
  return result;
};

export const numberFormatting: NumberFormattingType = (amount) => {
  if (amount === null || typeof amount === 'undefined') {
    return '';
  }
  return amount.toLocaleString();
};

export const dateFormatting: DateFormattingType = (date) => {
  if (!date) {
    return '';
  }
  const d = dayjs(date);
  if (!d.isValid()) {
    return '';
  }
  return d.format('YYYY-MM-DD');
};

export const dateTimeFormatting: DateTimeFormattingType = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  const dt = dayjs(dateTime);
  if (!dt.isValid()) {
    return '';
  }
  return dt.format('YYYY-MM-DD HH:mm:ss');
};

export const floatFormatting: FloatFormattingType = (float, fractionDigits = 2) => {
  if (typeof float === 'undefined' || float === null) {
    return '';
  }
  return float.toFixed(fractionDigits);
};

