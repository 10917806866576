import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import useContractPaymentTermsRowForm from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/hooks/useForm';
import { ContractPaymentTermsView } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/types/views';

export { TableBody as ContractPaymentTermsTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<ContractPaymentTermsView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<ContractPaymentTermsView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useContractPaymentTermsRowForm(item);
  const { handleSubmit } = methods;

  const contextMenuItems = [];

  const onSubmit = handleSubmit((data) => {
    console.log('onSubmit');
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        key={item.id}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={false}
      />
    </FormProvider>
  );
}
