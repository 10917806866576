import React from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import TextLink from '@front/layouts/TextLink';
import { useNavigate } from 'react-router-dom';
import type { VoteVO } from '@front/ost_vote/domain';
import dayjs from 'dayjs';
import { ProposalStatus } from '@front/ost_proposal/domain';
import ListInCell from '@front/components/ListInCell';

interface Props {
  item: VoteVO;
  index: number;
}

const VoteHistoryItem = ({ item, index }: Props) => {
  const navigate = useNavigate();
  return (
    <TableRow key={`${item.id}_${index}`}>
      <OldTd>{index + 1}</OldTd>
      <OldTd>
        <TextLink
          onClick={() => {
            navigate(`/ost/vote-list/${item.proposal.id}`);
          }}
        >
          {item.proposal.title}
        </TextLink>
      </OldTd>
      <OldTd>
        <ListInCell
          list={item.proposal.contributorViewList.map((item) => {
            if (item.contributor) {
              return item.contributor.name;
            }
            return '';
          })}
        />
      </OldTd>
      <OldTd>{item.proposal.category.name}</OldTd>
      <OldTd>
        <ListInCell
          list={item.proposal.estimatorList.map((estimatorInfo) => estimatorInfo.estimator.name)}
        />
      </OldTd>
      <OldTd>{item.proposal.grade}</OldTd>
      <OldTd>
        {item.proposal.status === ProposalStatus.VOTING
          ? '진행중'
          : item.proposal.status === ProposalStatus.END
          ? '완료'
          : '-'}
      </OldTd>
      <OldTd>{item.price ? item.price.toLocaleString() : 0}</OldTd>
      <OldTd>{dayjs(item.votedAt).format('YYYY-MM-DD')}</OldTd>
    </TableRow>
  );
};

export default VoteHistoryItem;
