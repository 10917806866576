import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { ColorPalette } from '@front/assets/theme';
import type { ProposalEstimatorVO } from '@front/ost_proposal/domain';
import { ProposalGrade, ProposalStatus } from '@front/ost_proposal/domain';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import type { ProposalEstimatorUpdateGradeParameter } from '@front/ost_proposal/parameter';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import useDialog from '@front/dialog/hook';

interface Props {
  clickedGrade: ProposalGrade;
  status: ProposalStatus;
  item: ProposalEstimatorVO;
  gridArea: string;
  updateGrade: (parameter: ProposalEstimatorUpdateGradeParameter) => void;
  name: string;
}

const GradeButton = ({ name, item, status, clickedGrade, gridArea, updateGrade }: Props) => {
  const { confirm } = useDialog();
  const loginUser = useSelector((state: RootState) => state.login.detail, shallowEqual);
  const estimationCompletionRate = useSelector(
    (root: RootState) => root.proposal.detail!.campaign.estimationCompletionRate,
    shallowEqual
  );
  const detail = useSelector((root: RootState) => root.proposal.detail!, shallowEqual);
  const [estimatorInfoList] = useSelector(
    (root: RootState) => [
      root.proposal.estimatorList.filter(
        (estimatorInfo) =>
          !detail.contributorViewList
            .filter((cv) => cv.contributor)
            .map((cv) => cv.contributor.id)
            .includes(estimatorInfo.estimator.id)
      ),
    ],
    shallowEqual
  );

  const totalSize = estimatorInfoList.length;
  const evaluatedProposalSize =
    estimatorInfoList.filter((estimatorInfo) => estimatorInfo.grade !== null).length + 1;
  const evaluationCompletedRate = (evaluatedProposalSize / totalSize) * 100;
  const result = useMemo(
    () => estimationCompletionRate <= evaluationCompletedRate,
    [estimatorInfoList, estimationCompletionRate, evaluatedProposalSize]
  );

  return (
    <Box
      sx={{
        gridArea: `${gridArea}`,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          padding: '5px 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid #b4bccb`,
          borderRadius: '5px',
          backgroundColor:
            clickedGrade == (item && item.grade) ? ColorPalette._d2e7fa : ColorPalette._ffffff,
          cursor: `${
            ProposalStatus.ESTIMATING === status && loginUser && loginUser.id === item.estimator.id
              ? 'pointer'
              : 'default'
          }`,
        }}
        onClick={() => {
          if (
            ProposalStatus.ESTIMATING === status &&
            loginUser &&
            loginUser.id === item.estimator.id
          ) {
            confirm({
              title: '선택한 심사 의견을 확인하십시오',
              children:
                !item.grade && result ? (
                  <>
                    <Box>{'마지막 심사자입니다.'}</Box>
                    <Box>{'심사 의견이 있으신 경우 심사 의견을 먼저 작성해 주시기 바랍니다.'}</Box>
                  </>
                ) : (
                  getGradeText(clickedGrade)
                ),
              afterConfirm: () => {
                updateGrade({
                  id: item.id,
                  grade: clickedGrade,
                });
              },
              confirmText: '확인',
            });
          }
        }}
      >
        <TextBox variant={'body9'}>{name}</TextBox>
      </Box>
    </Box>
  );
};

function getGradeText(grade: ProposalGrade): ReactNode {
  switch (grade) {
    case ProposalGrade.HOLD:
      return (
        <>
          <Box>{'"제안 검토 중이지만 심사는 아직 진행하기 전 상태입니다."'}</Box>
          <Box>{'선택한 등급으로 진행하시겠습니까?'}</Box>
        </>
      );
    case ProposalGrade.EDIT:
      return (
        <>
          <Box>{'"제안자에게 제안 수정 후 다시 제출하기를 요청합니다."'}</Box>
          <Box>{'선택한 등급으로 진행하시겠습니까?'}</Box>
        </>
      );
    case ProposalGrade.DENY:
      return (
        <>
          <Box>{'"본 제안에 대한 심사를 진행할 수 없음을 의미합니다."'}</Box>
          <Box>{'선택한 등급으로 진행하시겠습니까?'}</Box>
        </>
      );
    default:
      return '선택한 등급으로 진행하시겠습니까?';
  }
}

export default GradeButton;
