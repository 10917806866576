import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoEstimationComparedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useState';
import { ProjectSalesInfoEstimationComparedContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/context';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useProjectSalesInfoEstimationComparedCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { EstimationComparedButtonGroup as ProjectSalesInfoEstimationComparedButtonGroup };

const EstimationComparedButtonGroup = () => {
  const {
    h: { onDelete },
  } = useContext(ProjectSalesInfoEstimationComparedContext);
  const { idList, setIdList } = useProjectSalesInfoEstimationComparedState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen } = useProjectSalesInfoEstimationComparedCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      onDelete({ idList });
      setIdList([]);
    });
  }, [idList, confirm, setIdList, onDelete]);
  const handleAddClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const { readOnly } = useProjectSalesInfoEstimationComparedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={handleAddClick}
        disabled={readOnly}
      >
        대비견적 추가
      </Button>
    </>
  );
};
