import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import type { TableBodyProps } from '@mui/material/TableBody';
import TableBody from '@mui/material/TableBody';

export default function VirtualizedAutoSizerTableBodyUI({ ...rest }: Readonly<TableBodyProps>) {
  return (
    <TableBody
      {...rest}
      component="div"
      sx={{
        height: '100%',
        background: ColorPalette.background.bg,

        '& div:last-child': {
          textAlign: 'left',
        },
      }}
    />
  );
}
