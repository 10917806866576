// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o9WHpSXpjAwvXRnfxtr0{width:2%}.vu131IjToDoPk3GQAH7k{width:4%}.rGKaiQ4Mk3nXri1UXUWP{width:6%;white-space:nowrap}.StyQ9LRjXuTpIRKBX474{width:8%}.f0bX5AufrQT7rXCQpu7v{width:10%}.mJf9xU4ODJpdVPR26zaK{word-break:keep-all}.fdOCWeaVcEU1wOXL22Ks{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CACA,kBAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA,CAGF,sBACE,mBAAA,CAKF,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".width__2 {\r\n  width: 2%;\r\n}\r\n\r\n.width__4 {\r\n  width: 4%;\r\n}\r\n\r\n.width__6 {\r\n  width: 6%;\r\n  white-space: nowrap;\r\n}\r\n\r\n.width__8 {\r\n  width: 8%;\r\n}\r\n\r\n.width__10 {\r\n  width: 10%;\r\n}\r\n\r\n.bid__th {\r\n  word-break: keep-all;\r\n}\r\n\r\n@import \"@front/assets/colors.scss\";\r\n\r\n.no__result {\r\n  display: flex;\r\n  justify-content: center;\r\n  padding: 20px;\r\n  border: 1px solid $_e4e9f2;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `o9WHpSXpjAwvXRnfxtr0`,
	"width__4": `vu131IjToDoPk3GQAH7k`,
	"width__6": `rGKaiQ4Mk3nXri1UXUWP`,
	"width__8": `StyQ9LRjXuTpIRKBX474`,
	"width__10": `f0bX5AufrQT7rXCQpu7v`,
	"bid__th": `mJf9xU4ODJpdVPR26zaK`,
	"no__result": `fdOCWeaVcEU1wOXL22Ks`
};
export default ___CSS_LOADER_EXPORT___;
