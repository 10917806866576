import React from 'react';
import TableRowNoResultUI from '@front/src/components/components-with-design/compound/table/TableRowNoResult';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import { SortStatus } from '@front/src/types';
import headerMeta, { arraySupportKeyword } from '@front/src/components/ui-builder/headerMeta';
import useGetUiMetaHiddenList from '@front/src/features/ui-builder/features/ui-meta/query/useGetHiddenList';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';

export interface UIBuilderTableBodyProps<T = any> extends Omit<UiBuilderTableProps<T>, 'name'> {
  onCloseEditModal: (reset: () => void) => void;
  someOfRowIsEditMode: boolean,
  setSomeOfRowIsEditMode: (state: boolean) => void,
}

interface Props<T = any> extends UIBuilderTableBodyProps<T> {
  list?: T[];
  RowComponent: (props: UIBuilderTableRowProps) => JSX.Element;
}

export default function UIBuilderTableBody<T extends { id: number }>(props: Props<T>) {
  const { RowComponent, readOnly, sectionId, menuId, dataId } = props;
  const methods = useFormContext<UIBuilderTableFieldValues>();

  const { watch, getValues } = methods;

  const headerList = getValues('filterBundle');

  const sortPriorityList = watch('sortPriorityList');
  const showList = watch('showList');
  const showAllRow = watch('showAllRow');

  const getColSpan = () => {
    if (!headerList) return 0;
    if (readOnly) return headerList.length + 1;
    return headerList.length + 2;
  };

  // 숨기기
  const hiddenList = useGetUiMetaHiddenList({
    menuId,
    sectionId,
    dataId,
  });
  const hiddenMapped = showList?.map((s, index) => {
    const isNextHidden =
      index + 1 <= showList.length - 1 &&
      hiddenList?.find((t) => t.targetId === showList[index + 1].id)?.isBlind;
    return {
      ...s,
      hidden: hiddenList?.find((h) => h.targetId === s.id)?.isBlind,
      isNextHidden,
    };
  });

  const hiddenFiltered = hiddenMapped?.filter((h) => {
    if (!h.hidden) return true;

    return showAllRow;
  });

  // 소팅
  const sortedList = [...(hiddenFiltered ?? [])];
  for (let i = sortPriorityList.length - 1; i >= 0; i--) {
    const s = sortPriorityList[i];
    sortedList.sort((a, b) => {
      const { attributeName } = headerMeta[s.id];
      const order = s.sortStatus === SortStatus.DESC ? 1 : -1;
      const isArray = attributeName?.includes(arraySupportKeyword);

      const realAttributeName = attributeName?.map((item) =>
        item === arraySupportKeyword ? '0' : item
      );
      let value1 = realAttributeName?.reduce((acc, key) => acc?.[key], a) ?? '';
      let value2 = realAttributeName?.reduce((acc, key) => acc?.[key], b) ?? '';

      // inner cell을 통한 행 병합 효과 사용 시 number 타입에 대해서는 sort 합산 대응 로직
      if (isArray && typeof value1 === 'number') {
        const aa = a[realAttributeName!![0]] as [];
        aa.forEach((value, index) => {
          if (index > 0) {
            value1 += realAttributeName?.slice(1).reduce((acc, key) => acc?.[key], aa) ?? '';
          }
        });
        const bb = b[realAttributeName!![0]] as [];
        bb.forEach((value, index) => {
          if (index > 0) {
            value2 += realAttributeName?.slice(1).reduce((acc, key) => acc?.[key], bb) ?? '';
          }
        });
      }

      if (value1 > value2) return order;
      if (value1 < value2) return -order;
      return 0;
    });
  }

  if (sortedList?.length === 0) return <TableRowNoResultUI colSpan={getColSpan()} />;

  if (props.groupAttr) {
    const grouped = sortedList.reduce((acc, item) => {
      const groupValue = item[props.groupAttr!!];
      const prev = acc as { [key: string]: any };
      if (!prev[groupValue]) {
        prev[groupValue] = [];
      }
      prev[groupValue].push(item);
      return prev;
    }, {} as Record<string, any[]>);

    return (
      <>
        {Object.entries(grouped).map(([alias, group]) => {
          const castedGroup = group as any[];
          const rowspan = castedGroup.length;
          const groupSum = {};
          props.groupSumAttrs?.forEach((attr) => {
            groupSum[attr] = castedGroup.reduce((sum, item) => sum + item[attr], 0);
          });

          return castedGroup.map((item, index) => {
            return (
              <RowComponent
                {...props}
                rowSpan={rowspan}
                rowSpanIndex={index}
                rowSpanSum={groupSum}
                key={item.id}
                item={item}
                formContext={methods}
              />
            );
          });
        })}
      </>
    );
  }

  return (
    <>
      {sortedList.map((item) => (
        <RowComponent
          {...props}
          key={item.id}
          item={item}
          formContext={methods}
        />
      ))}
    </>
  );
}
