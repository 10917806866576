import { projectSalesActivityRepository } from '@front/src/features/project-sales-info/features/activity/repository/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useProjectSalesInfoActivityUserItemLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { run: onCreate } = projectSalesActivityRepository.useAffiliatesCreate(id!, menuId);
  const { run: onUpdate } = projectSalesActivityRepository.useUsersUpdate(id!, menuId);
  const { run: onDelete } = projectSalesActivityRepository.useUsersDelete(id!, menuId);
  return { onCreate, onUpdate, onDelete };
};
