import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

export default function WorkOutputCreateOrUpdateForm({ item, isCreate, onUpdate, onCreate }) {
  const methods = useForm({
    defaultValues: {
      name: isCreate ? '' : item.name ?? '',
    },
  });
  const { handleSubmit } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const onValid = (data) => {
    if (isCreate) {
      onCreate({
        name: data.name,
      });
    } else {
      onUpdate({
        id: item.id,
        name: data.name,
      });
    }
  };
  const onInvalid = (errors) => {
    if (errors.name) {
      enqueueSnackbar(errors.name.message, { variant: 'error' });
    }
  };
  const onSubmit = handleSubmit(onValid, onInvalid);
  return {
    methods,
    onSubmit,
  };
}
