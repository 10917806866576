import React from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import CircularProgress from '@front/components/CircularProgress';
import type {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
} from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import UserSelectorUserInput from '@front/src/features/user-selector/features/form/components/UserInput';
import UserSelectorUserIcon from '@front/src/features/user-selector/components/UserIcon';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';
import userSelectorQuery from '@front/src/features/user-selector/query/query';

interface Props extends UserSelectorInputProps {
  append: UseFieldArrayAppend<FieldValues, 'selectedList'>;
  remove: UseFieldArrayRemove;
  replace: UseFieldArrayReplace<FieldValues, 'selectedList'>;
}

export default function UserSelectorUserList(props: Props) {
  const {
    append,
    remove,
    menuIdList = [],
    typeList = [],
    replace,
    predefinedIdList,
    menuIdListType,
  } = props;

  const { watch } = useFormContext();

  const { data: userList } = userSelectorQuery.useGetUserList({
    keyword: watch('keyword'),
    departmentId: watch('departmentId'),
    menuIdList: menuIdList.join(','),
    typeList: typeList.join(','),
    menuIdListType,
  });

  const { data: allUser } = userSelectorRepository.useGetAllUser();

  const newUserList = (
    predefinedIdList ? allUser?.filter((user) => predefinedIdList.includes(user.id)) : userList
  )?.filter((user) => user.statusEn === 'EMPLOYED');
  if (!newUserList) {
    return (
      <TextBox
        variant="body2"
        sx={{ width: '100%', height: '100%' }}
      >
        <CircularProgress
          size={30}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </TextBox>
    );
  }
  if (newUserList.length === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextBox variant="body2">검색 결과가 없습니다.</TextBox>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: 'fit-content',
      }}
    >
      {newUserList.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}
        >
          <UserSelectorUserInput
            {...props}
            item={item}
            append={append}
            remove={remove}
            replace={replace}
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <UserSelectorUserIcon user={item} />
            <TextBox
              variant="body2"
              sx={{
                marginRight: '10px',
              }}
            >
              {item.name}
            </TextBox>
          </Box>
          <TextBox variant="body2">{item.department?.name}</TextBox>
        </Box>
      ))}
    </Box>
  );
}
