import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import type { SpareParameter } from '@front/src/types/parameter';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import type { FileItemView } from '@front/file-item';
import type { UserNameView } from '@front/src/types';

const useEstimationHistoryTableRowForm = (item: EstimationHistoryView) => {
  const methods = useForm<EstimationHistoryFormValues>({
    defaultValues,
  });

  useEffect(() => {
    const {
      formState: { isDirty },
      setValue,
    } = methods;
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof EstimationHistoryFormValues)[]).forEach(
        (field) => {
          setValue(field, getFormValues(item)[field]);
        }
      );
    }
  }, [item, methods]);

  return {
    methods,
  };
};
export default useEstimationHistoryTableRowForm;

const defaultValues = {
  id: null,
  seq: null,
  code: null,
  isConfirmed: '',
  estimationDate: null,
  type: '',
  status: '',
  possibilityOfOrder: '',
  projectDifficulty: '',
  modelDifficulty: '',
  estimationRequestCompany: null,
  estimationRequestCompanyPerson: null,
  nextContactDateWithRequestCompany: null,
  createdAt: null,
  numberAttr1: null,
  numberAttr2: null,
  numberAttr3: null,
  textAttr1: null,
  textAttr2: null,
  textAttr3: null,
  dateAttr1: null,
  dateAttr2: null,
  dateAttr3: null,

  estimationClient: [],
};

const getFormValues = (item: EstimationHistoryView) => ({
  id: item.id ?? null,
  seq: item.estimation.seq ?? null,
  code: item.estimation.code ?? null,

  isConfirmed: item.estimation.isConfirmed === null ? '' : item.estimation.isConfirmed ? 'Y' : 'N',
  estimationDate: item.estimation.estimationDate ?? null,
  type: item.estimation.type ?? '',
  status: item.estimation.status ?? '',
  possibilityOfOrder: item.estimation.possibilityOfOrder ?? '',
  projectDifficulty: item.estimation.projectDifficulty ?? '',
  modelDifficulty: item.estimation.modelDifficulty ?? '',
  estimationRequestCompany: item.estimation.estimationRequestCompany ?? null,
  estimationRequestCompanyPerson: item.estimation.estimationRequestCompanyPerson ?? null,
  nextContactDateWithRequestCompany: item.estimation.nextContactDateWithRequestCompany ?? null,
  createdAt: item.estimation.createdAt ?? null,

  numberAttr1: item.numberAttr1 ?? null,
  numberAttr2: item.numberAttr2 ?? null,
  numberAttr3: item.numberAttr3 ?? null,
  textAttr1: item.textAttr1 ?? null,
  textAttr2: item.textAttr2 ?? null,
  textAttr3: item.textAttr3 ?? null,
  dateAttr1: item.dateAttr1 ?? null,
  dateAttr2: item.dateAttr2 ?? null,
  dateAttr3: item.dateAttr3 ?? null,

  estimationClient: item.estimationClient.map((client) => ({
    id: client.id ?? null,
    seq: client.seq ?? null,
    clientCompany: client.clientCompany ?? null,
    clientCompanyPerson: client.clientCompanyPerson ?? null,
    orderType: client.orderType ?? '',
    nextContactDateClient: client.nextContactDateClient ?? null,
    blockName: client.blockName ?? null,
    siteModel: client.siteModel ?? null,
    testPlan: client.testPlan ?? null,
    requiredWeeksStart: client.requiredWeeksStart ?? null,
    requiredWeeksEnd: client.requiredWeeksEnd ?? null,
    estimatedStartDate: client.estimatedStartDate ?? null,
    totalEstimationPrice: client.totalEstimationPrice ?? null,
    estimatedWindTunnelPrice: client.estimatedWindTunnelPrice ?? null,
    actualWindTunnelPrice: client.actualWindTunnelPrice ?? null,
    structuralReviewPrice: client.structuralReviewPrice ?? null,
    structuralReviewRatio: client.structuralReviewRatio ?? null,
    estimationCm: client.estimationCm ?? null,
    estimationCmPostDeduction: client.estimationCmPostDeduction ?? null,
    cmDeductionRatio: client.cmDeductionRatio ?? null,
    cmRatio: client.cmRatio ?? null,
    actualUnitPrice: client.actualUnitPrice ?? null,
    targetUnitPrice: client.targetUnitPrice ?? null,
    minimumUnitPrice: client.minimumUnitPrice ?? null,
    actualCostPrice: client.actualCostPrice ?? null,
    excelFile: client.excelFile ?? null,
    pdfFile: client.pdfFile ?? null,
    createdBy: client.createdBy ?? null,
    createdAt: client.createdAt ?? null,
  })),
});

export interface EstimationHistoryFormValues extends SpareParameter {
  id: number | null;
  seq: number | null;
  code: string | null;

  isConfirmed: string;
  estimationDate: string | null;
  type: string;
  status: string;
  possibilityOfOrder: string;
  projectDifficulty: string;
  modelDifficulty: string;
  estimationRequestCompany: AffiliatedCompanyShortView | null;
  estimationRequestCompanyPerson: AffiliatedCompanyPersonShortView | null;
  nextContactDateWithRequestCompany: string | null;
  createdAt: string | null;

  estimationClient: EstimationHistoryClientFormValues[];
}

interface EstimationHistoryClientFormValues {
  id: number | null;
  seq: number | null;

  clientCompany: AffiliatedCompanyShortView | null;
  clientCompanyPerson: AffiliatedCompanyPersonShortView | null;
  orderType: string;
  nextContactDateClient: string | null;
  blockName: number | null;
  siteModel: number | null;
  testPlan: number | null;
  requiredWeeksStart: number | null;
  requiredWeeksEnd: number | null;
  estimatedStartDate: string | null;
  totalEstimationPrice: number | null;
  estimatedWindTunnelPrice: number | null;
  actualWindTunnelPrice: number | null;
  structuralReviewPrice: number | null;
  structuralReviewRatio: number | null;
  estimationCm: number | null;
  estimationCmPostDeduction: number | null;
  cmDeductionRatio: number | null;
  cmRatio: number | null;
  actualUnitPrice: number | null;
  targetUnitPrice: number | null;
  minimumUnitPrice: number | null;
  actualCostPrice: number | null;
  excelFile: FileItemView | null;
  pdfFile: FileItemView | null;
  createdBy: UserNameView | null;
  createdAt: string | null;
}
