import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import type { WorkCreatorParameter } from '@front/src/features/work/features/work/features/creator/types/parameter';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { userNotificationAction } from '@front/user_notification/action';

import type { UIBuilderParameter } from '@front/src/types/parameter';

interface Props {
  commonParams: UIBuilderParameter;
  onCloseEditModal: (reset: () => void) => void;
  reset: () => void;
}

export default function useWorkManagerMutation({
  commonParams,
  reset,
  onCloseEditModal,
}: Readonly<Props>) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (params: WorkCreatorParameter) =>
      workWorkApi.updateManager({ ...params, ...commonParams }, commonParams),
    mutationKey: ['work', 'manager', 'update'],
    onSuccess: () => {
      onCloseEditModal(reset);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['work', 'manager'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['work', 'drawer'],
      });
      await queryClient.invalidateQueries(['global-nav-bar']);
      await queryClient.invalidateQueries({
        queryKey: ['work', 'role'],
      });
      dispatch(userNotificationAction.requestCount());
    },
  });
  return mutate;
}
