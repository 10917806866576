import { useQuery } from 'react-query';
import { adminHeaderSettingsApi } from '@front/src/features/admin/features/header-settings/api/api';

export { query as personalSettingsModalUISettingsHeaderSettingsPositionQuery };

const query = {
  useGetHeaderUsageList: (id?: number, authMenuId?: number) => {
    const { data } = useQuery({
      queryKey: [
        'personal-settings-modal',
        'ui-settings',
        'header-settings',
        'header',
        id,
        'menu-tree',
      ],
      queryFn: () => adminHeaderSettingsApi.getHeaderUsageList(id, authMenuId),
      enabled: !!id,
    });
    return {
      data,
    };
  },
  useGetStatus: (menuId?: number, headerId?: number, authMenuId?: number) => {
    const { data } = useQuery({
      queryKey: [
        'personal-settings-modal',
        'ui-settings',
        'header-settings',
        'header',
        headerId,
        'menu',
        menuId,
      ],
      queryFn: () => adminHeaderSettingsApi.getStatusList(menuId, headerId, authMenuId),
      enabled: !!headerId && !!menuId,
    });
    return {
      data,
    };
  },
};
