// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DIveVoD_auckhNI721tD{display:none}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/components/extra-company-button.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA","sourcesContent":[".display__none {\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"display__none": `DIveVoD_auckhNI721tD`
};
export default ___CSS_LOADER_EXPORT___;
