import type { ReactNode } from 'react';
import React from 'react';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import type { UseFieldArrayReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type {
  UIBuilderTableFieldValues,
  UIBuilderTableFilterItem,
} from '@front/src/components/ui-builder/table/hooks/useForm';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortStatus } from '@front/src/types';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import useUIBuilderTableSort from '@front/src/components/ui-builder/table/hooks/useSort';
import UIBuilderFilterPopover from '@front/src/components/ui-builder/filter/Popover';
import { ColorPalette } from '@front/assets/theme';

interface Props extends UiBuilderTableProps {
  children: ReactNode;
  headerId: number;
  sortFieldArray: UseFieldArrayReturn<UIBuilderTableFieldValues, 'sortPriorityList', string>;
  bundleIndex: number;
  onFilterChange: (newFilter: UIBuilderTableFilterItem[], active: boolean) => void;
  active: boolean;
}

export default function UIBuilderTableHeaderCellItem(props: Readonly<Props>) {
  const { children, sortFieldArray, headerId } = props;
  const { watch } = useFormContext<UIBuilderTableFieldValues>();
  const { width, sortable, filterable } = headerMeta[headerId];

  const { findIndex, onSort, sortStatus } = useUIBuilderTableSort({
    id: headerId,
    fieldArray: sortFieldArray,
    sortable,
  });

  const renderSortPriorityNumber = () => {
    if (findIndex == -1) return <></>;
    return `(${findIndex + 1})`;
  };

  return (
    <TableCellUI
      isHead
      width={`${width ?? 100}px`}
      data-meta-id={headerId}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          userSelect: 'none',
        }}
      >
        <Box
          onClick={onSort}
          sx={{
            width: 'fit-content',
            textDecoration: sortable ? 'underline' : 'default',
            cursor: sortable ? 'pointer' : 'default',
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
          }}
        >
          {filterable && watch('useFilter') && <UIBuilderFilterPopover {...props} />}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            color: ColorPalette.main.main_primary,
          }}
        >
          {findIndex > -1 && (
            <>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  position: 'absolute',
                  top: 4,
                  right: 0,
                  transform: sortStatus === SortStatus.ASC ? 'rotate(0)' : 'rotate(180deg)',
                  transition: 'all 0.4s ease',
                }}
              />
              <div
                style={{
                  width: '2rem',
                  textAlign: 'right',
                  fontSize: '1rem',
                  position: 'absolute',
                  top: -12,
                  right: -2,
                  opacity: 0.5,
                }}
              >
                {renderSortPriorityNumber()}
              </div>
            </>
          )}
        </Box>
      </Box>
    </TableCellUI>
  );
}
