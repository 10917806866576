import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import { contractHistoryTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/history/components/table-cells';
import { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

interface Props extends CellComponentProps<ContractHistoryView> {}

export const contractFinalTableCells: CellComponent = {
  522: contractHistoryTableCells[482],
  523: contractHistoryTableCells[483],
  524: contractHistoryTableCells[484],
  525: contractHistoryTableCells[485],
  526: contractHistoryTableCells[486],
  527: contractHistoryTableCells[487],
  528: contractHistoryTableCells[488],
  529: contractHistoryTableCells[489],
  530: contractHistoryTableCells[490],
  531: contractHistoryTableCells[491],
  532: contractHistoryTableCells[492],
  533: contractHistoryTableCells[493],
  534: contractHistoryTableCells[494],
  535: contractHistoryTableCells[495],
  536: contractHistoryTableCells[496],
  537: contractHistoryTableCells[497],
  538: contractHistoryTableCells[498],
  539: contractHistoryTableCells[499],
  540: contractHistoryTableCells[500],
  541: contractHistoryTableCells[501],
  542: contractHistoryTableCells[502],
  543: contractHistoryTableCells[503],
  544: contractHistoryTableCells[504],
  545: contractHistoryTableCells[505],
  546: contractHistoryTableCells[506],
  547: contractHistoryTableCells[507],
  548: contractHistoryTableCells[508],
  549: contractHistoryTableCells[509],
  550: contractHistoryTableCells[510],
  551: contractHistoryTableCells[511],
  552: contractHistoryTableCells[512],
  553: contractHistoryTableCells[513],
  554: contractHistoryTableCells[514],
  555: contractHistoryTableCells[515],
  556: contractHistoryTableCells[516],
  557: contractHistoryTableCells[517],
  558: contractHistoryTableCells[518],
  559: contractHistoryTableCells[519],
  560: contractHistoryTableCells[520],
  561: contractHistoryTableCells[521],
};
