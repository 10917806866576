import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { ControlPanelItem } from '@front/src/types/domain';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ArithmeticOperator } from '@front/src/utils';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import { ColorPalette } from '@front/assets/theme';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

interface Props {
  item: any;
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
  commonParams: UIBuilderParameter;
  onAdd: (rowId: number, parentId?: number) => void;
  onUpdateSeq: (id: number, operator: ArithmeticOperator) => void;
  onDelete: (id: number) => void;
}
const useUIBuilderTableRowLogic = ({ item, formContext, onAdd, onUpdateSeq, onDelete }: Props) => {
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);
  const { watch, getValues } = formContext;
  const editOpenIdList = watch('editOpenIdList');
  const recentUpdatedId = getValues('recentUpdatedId');
  const isEditOpen = editOpenIdList.includes(item.id);

  const contextMenuItems: ControlPanelItem[] = [
    {
      text: '추가',
      icon: AddCircleOutlineIcon,
      action: () => onAdd(item.id),
      seq: 2,
    },
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => toggleEditOpenId(item.id),
      split: true,
      seq: 3,
    },
    {
      text: '이동(위)',
      icon: ArrowUpwardIcon,
      action: () => {
        onUpdateSeq(item.id, ArithmeticOperator.SUBTRACTION);
      },
      seq: 4,
    },
    {
      text: '이동(아래)',
      icon: ArrowDownwardIcon,
      action: () => {
        onUpdateSeq(item.id, ArithmeticOperator.ADDITION);
      },
      seq: 5,
    },
    // { text: '비고', icon: NoteIcon, action: actions.onNoteOpen, seq: 6 },
    {
      text: '삭제',
      icon: DeleteIcon,
      action: () => {
        onDelete(item.id);
      },
      iconColor: '#e43333',
      seq: 100,
    },
  ];

  const sx = {
    backgroundColor: recentUpdatedId === item.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };

  return {
    sx,
    contextMenuItems,
    isEditOpen,
  };
};

export default useUIBuilderTableRowLogic;
