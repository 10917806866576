import { create } from 'zustand';
import { noOp } from '@front/common/contants';

interface State {
  listPath: string;
  setListPath: (listPath: string) => void;
  drawerToggleHandler: (toggleFnc: boolean) => void;
  setDrawerToggleHandler: (drawerToggleHandler: (toggleFnc: boolean) => void) => void;
  memoToggleHandler: (toggleFnc: boolean) => void;
  setMemoToggleHandler: (memoToggleHandler: (toggleFnc: boolean) => void) => void;
  viewportMode?: 'DESKTOP' | 'MOBILE';
  setViewportMode: (viewportMode: 'DESKTOP' | 'MOBILE') => void;
}

export { useState as useBreadCrumbState };

const useState = create<State>((set) => ({
  listPath: localStorage.getItem('listPath') || '/',
  setListPath: (listPath: string) => set({ listPath }),
  drawerToggleHandler: noOp,
  setDrawerToggleHandler: (drawerToggleHandler: (toggleFnc: boolean) => void) =>
    set({ drawerToggleHandler }),
  viewportMode: (localStorage.getItem('viewportMode') as 'DESKTOP' | 'MOBILE') || undefined,
  setViewportMode: (viewportMode: 'DESKTOP' | 'MOBILE') => set({ viewportMode }),
  memoToggleHandler: noOp,
  setMemoToggleHandler: (memoToggleHandler: (toggleFnc: boolean) => void) =>
    set({ memoToggleHandler }),
}));
