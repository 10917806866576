import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoCollectionPaymentHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/context';
import { useProjectSalesInfoCollectionPaymentHistoryLogic } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useLogic';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoPaymentHistoryTable } from '@front/src/features/project-sales-info/features/collection/features/payment-history/components/payment-history-table';
import { ProjectSalesInfoCollectionPaymentHistoryBtn } from '@front/src/features/project-sales-info/features/collection/features/payment-history/components/payment-history-btn';
import { ProjectSalesInfoPaymentHistoryCreateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/components/modal-wrapper';
import { ProjectSalesInfoPaymentHistoryUpdateModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/components/modal-wrapper';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoCollectionPaymentHistoryWidget = ({
  readOnly,
  name,
  menuId,
}: Readonly<Props>) => {
  const { Body, Layout, Header } = AddDeleteTable;
  const {
    d: { paymentHistoryList },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoCollectionPaymentHistoryLogic(menuId);

  const value = useMemo(
    () => ({
      d: {
        paymentHistoryList,
      },
      h: {
        onDelete,
      },
      isLoading,
    }),
    [paymentHistoryList, onDelete, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoPaymentHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoCollectionPaymentHistoryContext.Provider value={value}>
      <ProjectSalesInfoPaymentHistoryCreateModalWrapper />
      <ProjectSalesInfoPaymentHistoryUpdateModalWrapper />
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoCollectionPaymentHistoryBtn />
        </Header>
        <Body>
          <ProjectSalesInfoPaymentHistoryTable />
        </Body>
      </Layout>
    </ProjectSalesInfoCollectionPaymentHistoryContext.Provider>
  );
};

export default ProjectSalesInfoCollectionPaymentHistoryWidget;
