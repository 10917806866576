import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getOutline: (id?) => `${baseUrl}/${id}`,
};

export const affiliatedCompanyOutlineApi = {
  getOutline: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getOutline(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
