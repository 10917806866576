import { useMutation, useQueryClient } from 'react-query';
import { mobileNotificationApi } from '@front/src/features/mobile-notification/query/api';
import { handleError } from '@front/src/utils';
import type {
  UserNotificationIsReadParameter,
  UserNotificationParameter,
} from '@front/src/features/mobile-notification/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export const mobileNotificationMutation = {
  useUserNotificationToggleAll: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => mobileNotificationApi.toggleAllUserNotification(),
      onMutate: async () => sessionStorage.getItem('menuId'),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['mobile-notification', 'list'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        await queryClient.invalidateQueries(['mobile-notification', 'count']);
        await queryClient.invalidateQueries(['project', 'sales-info', 'drawer', 'search']);
        await queryClient.invalidateQueries(['affiliated-company', 'drawer', 'search']);
        await queryClient.invalidateQueries(['work', 'drawer']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },

  useUserNotificationToggle: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: UserNotificationIsReadParameter) =>
        mobileNotificationApi.toggleUserNotification(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['mobile-notification', 'list'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        await queryClient.invalidateQueries(['mobile-notification', 'count']);
        await queryClient.invalidateQueries(['project', 'sales-info', 'drawer', 'search']);
        await queryClient.invalidateQueries(['affiliated-company', 'drawer', 'search']);
        await queryClient.invalidateQueries(['work', 'drawer']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },

  useUserNotificationDeleteAll: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => mobileNotificationApi.deleteAllUserNotification(),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['mobile-notification', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },

  useUserNotificationDelete: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => mobileNotificationApi.deleteUserNotification(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['mobile-notification', 'list'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        await queryClient.invalidateQueries(['mobile-notification', 'count']);
        await queryClient.invalidateQueries(['project', 'sales-info', 'drawer', 'search']);
        await queryClient.invalidateQueries(['affiliated-company', 'drawer', 'search']);
        await queryClient.invalidateQueries(['work', 'drawer']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },

  useUserNotificationPinUpdate: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: UserNotificationParameter) =>
        mobileNotificationApi.updateUserNotificationPin(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['mobile-notification', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
