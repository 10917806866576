import React from 'react';
import UiBuilderTableRow, { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import {
  AccountStatusSummaryTypeView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';

type Props = Readonly<UIBuilderTableRowProps<AccountStatusSummaryTypeView>>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UiBuilderTableRow>;

const AccountStatusWidgetSummaryTableRow: ElementType = (props) => {
  return (
    <UiBuilderTableRow
      {...props}
    />
  );
};

export default AccountStatusWidgetSummaryTableRow;
