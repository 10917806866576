import { useQuery } from 'react-query';
import fileItemApi from '@front/src/features/file-item/api/api';

const fileAuthSettingsQuery = {
  useGetList: (fileItemId) => {
    const { data } = useQuery({
      queryFn: () => fileItemApi.getList(fileItemId),
      queryKey: ['file-auth', fileItemId],
      suspense: true,
      enabled: !!fileItemId,
      staleTime: 0,
    });
    return data;
  },
};

export default fileAuthSettingsQuery;
