import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import { headerHistoryApi } from '@front/src/features/header-history/query/api';

export { query as headerHistoryQuery };
const query = {
  useGetHistoryList: (id, headerId, authMenuId) => {
    const params = headerId && { headerId };
    const { data } = useQuery({
      queryKey: ['header-history-modal', 'section', id, 'history', params],
      queryFn: () => headerHistoryApi.getHistoryList(id, params, authMenuId),
      enabled: !!id,
    });
    return {
      data,
    };
  },
  useGetHeaderList: (id?, authMenuId?) => {
    const { data } = useQuery({
      queryKey: ['header-history-modal', 'section', id, 'header'],
      queryFn: () =>
        commonApi.getHeaderList(
          id,
          {
            isIncludedBlindItem: true,
          },
          authMenuId
        ),
      enabled: !!id && !!authMenuId,
    });
    return {
      data,
    };
  },
};
