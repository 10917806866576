import {useDialogState} from '@front/src/features/dialog/widgets/useState';
import {useShallow} from 'zustand/react/shallow';

export {useLogic as useDialogPromptLogic};

const useLogic = () => {
  const {openPrompt, onClose, promptProps} = useDialogState(
    useShallow((state) => ({
      openPrompt: state.openPrompt,
      onClose: state.onClose,
      promptProps: state.promptProps,
    }))
  );

  const h = {
    openPrompt,
    onClose,
  };
  return {h, promptProps};
};
