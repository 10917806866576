import React, { useCallback, useEffect } from 'react';
import type { AppRoute } from '../../../services/routes';
import { Box } from '@mui/material';
import logo from '@front/assets/logo_rect_type.webp';
import { snackbarAction, SnackbarSeverityType } from '../../../components/Snackbar/action';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

function NotReadyPage() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    openSnackbar('준비중인 페이지 입니다', SnackbarSeverityType.info);
  }, [pathname]);

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={logo}
        alt="한양풍동실험연구소 로고"
        style={{
          opacity: 0.1,
          maxWidth: '25dvw',
          maxHeight: '25dvh',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Box>
  );
}

//// 개발중인 메뉴에 대한 작업중 표기
export const projectCollectionPageRoute: AppRoute = {
  path: '/sales/collections-management',
  element: <NotReadyPage />,
};

export const projectOrdersPageRoute: AppRoute = {
  path: '/sales/orders-management',
  element: <NotReadyPage />,
};

export const projectTasksPageRoute: AppRoute = {
  path: '/engineering/tasks-management',
  element: <NotReadyPage />,
};
