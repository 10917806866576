import { useIsMutating } from 'react-query';

const useWorkBasicInfoGetIsLoading = () => {
  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'basic', 'update'],
  });
  const isSearchWordCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'basic', 'search-work-create'],
  });
  const isSearchWordUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'basic', 'search-work-update'],
  });
  return isUpdateLoading || isSearchWordCreateLoading || isSearchWordUpdateLoading;
};

export default useWorkBasicInfoGetIsLoading;
