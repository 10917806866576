import React, { useEffect, useMemo } from 'react';
import { accountingUploadSalesInfoLayout } from '@front/src/features/accounting/features/upload/features/sales-info/components/layouts/index';
import { AccountingUploadSalesInfoDownloadExcelWidget } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/widget';
import { AccountingUploadSalesInfoGetYearWidget } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/widget';
import { AccountingUploadSalesInfoYearSelect } from '@front/src/features/accounting/features/upload/features/sales-info/components/year-select';
import { AccountingUploadSalesInfoProvider } from '@front/src/features/accounting/features/upload/features/sales-info/provider/provider';
import { AccountingUploadSalesInfoTargetButton } from '@front/src/features/accounting/features/upload/features/sales-info/components/target-button';
import { AccountingUploadSalesInfoGoalModalFeature } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal';
import { AccountingUploadSalesInfoExpectModalFeature } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal';
import { AccountingUploadSalesInfoExpectButton } from '@front/src/features/accounting/features/upload/features/sales-info/components/expect-button';
import dayjs from 'dayjs';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoPerformanceButton } from '@front/src/features/accounting/features/upload/features/sales-info/components/performance-button';
import { AccountingUploadSalesInfoPerformanceModalFeature } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getIsShowForAuthorizationFromMenu } from '@front/src/utils';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AccountingUploadSalesInfoFeature = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Header } = accountingUploadSalesInfoLayout;
  const { setYear } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      setYear: state.setYear,
    }))
  );
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromMenu(loginUser.menus);
  }, [loginUser]);
  useEffect(() => {
    setYear(dayjs(new Date()).year());
  }, [setYear]);
  return (
    <AccountingUploadSalesInfoProvider {...props}>
      <AccountingUploadSalesInfoGoalModalFeature {...props} />
      <AccountingUploadSalesInfoExpectModalFeature {...props} />
      <AccountingUploadSalesInfoPerformanceModalFeature {...props} />
      <Layout>
        <Header
          title={props.name}
          leftComponent={<AccountingUploadSalesInfoYearSelect />}
          rightComponent={
            isShow && (
              <>
                <AccountingUploadSalesInfoTargetButton />
                <AccountingUploadSalesInfoExpectButton />
                <AccountingUploadSalesInfoPerformanceButton />
                <AccountingUploadSalesInfoDownloadExcelWidget {...props} />
              </>
            )
          }
        />
        <AccountingUploadSalesInfoGetYearWidget {...props} />
      </Layout>
    </AccountingUploadSalesInfoProvider>
  );
};

export default AccountingUploadSalesInfoFeature;
