import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import classes from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/components/create-modal-table.module.scss';
import { convertToManageableOptionList } from '@front/src/features/project-sales-info/features/collection/utils/constant';
import { ProjectSalesInfoPaymentHistoryUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/widgets/context';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { ProjectSalesInfoPaymentHistoryUpdateModalBtn } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/components/update-modal-btn';
import { covertToConstructingCompanyOptionList } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { UpdateModalTable as ProjectSalesInfoPaymentHistoryUpdateModalTable };

const UpdateModalTable = () => {
  const itemId = useProjectSalesInfoPaymentHistoryState((state) => state.itemId);
  const {
    d: { isPaidOptionList, paymentHistoryDetail, consortiumCompanyList },
  } = useContext(ProjectSalesInfoPaymentHistoryUpdateModalContext);
  const methods = useForm<ProjectReviewAmountPaymentHistoryParams>({
    values: {
      id: itemId,
      structureCompanyId: paymentHistoryDetail?.structureCompany?.id ?? '',
      paymentRequestDate: paymentHistoryDetail?.paymentRequestDate ?? '',
      taxInvoiceIssuedDate: paymentHistoryDetail?.taxInvoiceIssuedDate ?? '',
      paymentAmount: paymentHistoryDetail?.paymentAmount?.toLocaleString() ?? '',
      paymentType: paymentHistoryDetail?.paymentType ?? '',
      paymentDate: paymentHistoryDetail?.paymentDate ?? '',
      note: paymentHistoryDetail?.note ?? '',
    },
  });
  const { readOnly } = useProjectSalesInfoPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <div className={classes.table__group}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th width="140px">계약 구조사</Th>
                <OldTd>
                  <HookFormSelect
                    name="structureCompanyId"
                    optionList={covertToConstructingCompanyOptionList(consortiumCompanyList)}
                    width="150px"
                    defaultLabel="선택"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow key={Math.random()}>
                <Th>지급 요청일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="paymentRequestDate"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow key={Math.random()}>
                <Th>세금계산서 발행일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="taxInvoiceIssuedDate"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>지급액</Th>
                <OldTd>
                  <HookFormInput
                    name="paymentAmount"
                    width="200px"
                    inputType={InputType.AMOUNT}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>지급 여부</Th>
                <OldTd>
                  <HookFormSelect
                    name="paymentType"
                    optionList={convertToManageableOptionList(isPaidOptionList)}
                    width="150px"
                    defaultLabel="선택"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow key={Math.random()}>
                <Th>지급일</Th>
                <OldTd sx={{textAlign:'left'}}>
                  <DatePickerWithHookForm
                    name="paymentDate"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>비고</Th>
                <OldTd>
                  <HookFormTextarea
                    name="note"
                    minRows={2}
                    resize="none"
                    disabled={readOnly}
                    variable={true}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ProjectSalesInfoPaymentHistoryUpdateModalBtn />
      </div>
    </FormProvider>
  );
};
