import type {
  ApprovalBoxCountView,
  ApprovalBoxLinkView,
  ApprovalBoxView,
} from '@front/src/features/approval-box/type/view';
import axios, { AxiosResponse } from 'axios';
import { approvalDocumentUrlPath } from '@front/src/features/drawer-approval/utils/constants';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import type { ApprovalBoxSearchParams } from '@front/src/features/approval-box/type/parameter';

const url = {
  getApprovalBoxCount: () => '/api/approval-document/count',
  getApprovalBoxDetail: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}`,
  getApprovalBoxSearchList: () => `${approvalDocumentUrlPath}`,

  cancelApprovalDocument: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}/cancel`,
  getApprovalDocumentLink: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}/link`,
};

export const approvalBoxApi = {
  getApprovalBoxCount: async <T = ApprovalBoxCountView>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalBoxCount());
    return data;
  },
  getApprovalBoxDetail: async <T = ApprovalDocumentDetailView>(
    documentId: number,
    params: ApprovalDocumentDetailQueryParams
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalBoxDetail(documentId), {
      params,
    });
    return data;
  },
  getApprovalBoxSearchList: async <T = ApprovalBoxView[]>(params: ApprovalBoxSearchParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalBoxSearchList(), {
      params,
    });
    return data;
  },

  cancelApprovalDocument: async <T = unknown>(documentId: number) =>
    await axios.patch<T, AxiosResponse<T>>(url.cancelApprovalDocument(documentId)),

  getApprovalDocumentLink: async <T = ApprovalBoxLinkView>(documentId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocumentLink(documentId));
    return data;
  },
};
