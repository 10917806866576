import React from 'react';
import { Box, Fade, TableBody, TableHead, TableRow } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { OldTd, OldTh, Table } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalGrade, ProposalStatus } from '@front/ost_proposal/domain';
import GradeButton from '@front/ost_proposal/components/GradeButton';
import type { ProposalEstimatorUpdateGradeParameter } from '@front/ost_proposal/parameter';
import { ColorPalette } from '@front/assets/theme';

import Input from '@front/layouts/Input';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  updateGrade: (parameter: ProposalEstimatorUpdateGradeParameter) => void;
}

const EvaluationInfo = ({ updateGrade }: Props) => {
  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.estimatorList],
    shallowEqual
  );
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="심사"
        children={
          <>
            <Table disableSticky>
              <TableHead>
                <TableRow>
                  <OldTh sx={{ width: '10px' }}>No</OldTh>
                  <OldTh sx={{ width: '100px' }}>부서</OldTh>
                  <OldTh sx={{ width: '100px' }}>이름</OldTh>
                  <OldTh sx={{ width: '150px' }}>심사의견</OldTh>
                </TableRow>
              </TableHead>
              <TableBody>
                {!estimatorList && (
                  <TableRow>
                    <OldTd
                      colSpan={4}
                      sx={{ minHeight: '38px' }}
                    >
                      <CircularProgress
                        size={4}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                      />
                    </OldTd>
                  </TableRow>
                )}
                {estimatorList?.length === 0 && (
                  <TableRow>
                    <OldTd colSpan={6}>
                      <Fade in={true}>
                        <Box>조회 결과가 없습니다</Box>
                      </Fade>
                    </OldTd>
                  </TableRow>
                )}
                {estimatorList?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    {(loginUser?.role?.id === 1 || item.estimator?.id === loginUser?.id) && (
                      <>
                        <TableRow>
                          <OldTd rowSpan={2}>{index + 1}</OldTd>
                          <OldTd>{item.estimator?.department.name}</OldTd>
                          <OldTd>{item.estimator?.name}</OldTd>
                          <OldTd>
                            <Box
                              sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                                gridTemplateAreas: `"tall grande venti supreme hold edit deny"`,
                                columnGap: '10px',
                                width: '100%',
                              }}
                            >
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.TALL}
                                status={status}
                                name={'Tall'}
                                gridArea={'tall'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.GRANDE}
                                status={status}
                                name={'Grande'}
                                gridArea={'grande'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.VENTI}
                                status={status}
                                name={'Venti'}
                                gridArea={'venti'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.SUPREME}
                                status={status}
                                name={'Supreme'}
                                gridArea={'supreme'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.HOLD}
                                status={status}
                                name={'심사보류'}
                                gridArea={'hold'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.EDIT}
                                status={status}
                                name={'수정요청'}
                                gridArea={'edit'}
                                updateGrade={updateGrade}
                              />
                              <GradeButton
                                item={item}
                                clickedGrade={ProposalGrade.DENY}
                                status={status}
                                name={'제안무효'}
                                gridArea={'deny'}
                                updateGrade={updateGrade}
                              />
                            </Box>
                          </OldTd>
                        </TableRow>
                        <TableRow>
                          <OldTd
                            colSpan={3}
                            sx={{
                              padding: '5px',
                            }}
                          >
                            <Input
                              multiline
                              readOnly={status !== ProposalStatus.ESTIMATING}
                              variant="outlined"
                              placeholder={
                                '모든 심사에 대한 의견을 작성합니다 ("수정요청" 및 "제안무효"는 반드시 의견을 작성해야 합니다.)'
                              }
                              key={item.opinion}
                              defaultValue={item.opinion}
                              sx={{
                                border: `1px solid ${ColorPalette._e4e9f2}`,
                              }}
                              onBlur={(e) => {
                                const value = e.target.value || undefined;
                                if (!value) {
                                  updateGrade({
                                    id: item.id,
                                    isOpinionDeleted: true,
                                  });
                                  return;
                                }
                                if (item.opinion !== value) {
                                  updateGrade({
                                    id: item.id,
                                    opinion: value,
                                  });
                                }
                              }}
                            />
                          </OldTd>
                        </TableRow>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            {status === ProposalStatus.APPROVING && (
              <Box
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: `${ColorPalette._d2e7fa}`,
                  borderRadius: '5px',
                  padding: '10px 5px',
                }}
              >
                <TextBox variant="body7">결재 중입니다.</TextBox>
              </Box>
            )}
          </>
        }
      />
    </Box>
  );
};

export default EvaluationInfo;
