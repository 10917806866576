import type { FocusEvent, KeyboardEvent } from 'react';
import React from 'react';

import { Box } from '@mui/material';

import type { DefaultFunction } from '@front/type/Function';

import ModalLayout from '@front/layouts/ModalLayout';
import FormAddProposalCategory from '@front/ost_campaign/view/modal/FormAddProposalCategory';
import Button from '@front/layouts/Button';
import type { FormikProps } from 'formik';
import type { ProposalCategoryAddParameter } from '@front/ost_campaign/parameter';

interface Props {
  isOpen: boolean;
  onClose: DefaultFunction;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  formik: FormikProps<ProposalCategoryAddParameter>;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const ModalAddProposalCategory = ({ formik, isOpen, onClose, onBlur, onKeyDown }: Props) => (
  <ModalLayout
    open={isOpen}
    title={'신규 제안 구분 추가'}
    width="20dvw"
    height="20dvh"
    onClose={onClose}
    footer={
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {' '}
        <Button
          onClick={() => formik.handleSubmit()}
          sx={{
            marginRight: '20px',
          }}
        >
          {' '}
          등록
        </Button>
        <Button
          shape={'basic2'}
          onClick={onClose}
        >
          {' '}
          취소
        </Button>
      </Box>
    }
  >
    {' '}
    <FormAddProposalCategory
      formik={formik}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />{' '}
  </ModalLayout>
);

export default ModalAddProposalCategory;
