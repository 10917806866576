import { useQuery } from 'react-query';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import { affiliatedCompanyOutlineBankInfoApi } from '@front/src/features/affiliated-company/features/outline/features/bank-info/query/api';

export const affiliatedCompanyOutlineBankInfoQuery = {
  useBankNameOptionListGet: (menuId) => {
    const { data } = useQuery<VariableInformationView[]>({
      queryKey: ['affiliated-company', 'outline', 'bank-info', 'option-list'],
      queryFn: () => affiliatedCompanyOutlineBankInfoApi.getBankNameOptionList(menuId),
    });
    return { data };
  },
};
