import React from 'react';
import type { TooltipProps } from '@mui/material';
import { Tooltip as MuiTooltip, Typography } from '@mui/material';

interface Props extends Omit<TooltipProps, 'title'> {
  title?: string;
}

const Tooltip = ({ title, placement = 'bottom', children, ...props }: Props) => {
  if (title) {
    return (
      <MuiTooltip
        title={
          <>
            {title.includes('\n')
              ? title.split('\n').map((line, index) => (
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                    key={`${line.slice(0, 2)}_${index}`}
                  >
                    {line}
                  </Typography>
                ))
              : title}
          </>
        }
        placement={placement}
        {...props}
      >
        <div>{children}</div>
      </MuiTooltip>
    );
  }
  return children;
};

export default Tooltip;
