import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';

export { CreateModal as AffiliatedPersonCreateModal };

interface Props {
  children: ReactNode;
}

const CreateModal = ({ children }: Props) => {
  const { isCreateModalOpen, setIsCreateModalOpen } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      isCreateModalOpen: state.isCreateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    }))
  );
  const handleClose = useCallback(() => {
    setIsCreateModalOpen(false);
  }, [setIsCreateModalOpen]);
  return (
    <ModalLayout
      title="신규 인물 등록"
      open={isCreateModalOpen}
      width="60dvw"
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          width: '100%',
          height: '200px',
        }}
      >
        {children}
      </Box>
    </ModalLayout>
  );
};
