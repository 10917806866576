import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useWorkOutputBundleMutation from '@front/src/features/work/features/work/features/output-bundle/hooks/useMutation';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkOutputCreateOrUpdateForm from '@front/src/features/work/features/work/features/output/hooks/useCreateOrUpdateForm';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

interface Props extends SectionComponentProps {
  item: WorkOutputBundleView;
  isCreate: boolean;
  onClose: () => void;
}

export default function WorkOutputBundleModalForm(props: Readonly<Props>) {
  const { item, isCreate, onClose, dataId, menuId, sectionId } = props;
  const { onUpdate, onCreate } = useWorkOutputBundleMutation({
    dataId,
    menuId,
    sectionId,
    onClose,
  });
  const { methods, onSubmit } = WorkOutputCreateOrUpdateForm({
    item,
    isCreate,
    onUpdate,
    onCreate,
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ModalBodyUI>
          <ModalHeaderUI>
            <ButtonBasicUI
              shape="tertiary"
              size="medium"
              onClick={onClose}
              sx={{
                width: '64px',
              }}
            >
              취소
            </ButtonBasicUI>
            <ButtonBasicUI
              type="submit"
              shape="primary2"
              size="medium"
              sx={{
                width: '64px',
              }}
            >
              확인
            </ButtonBasicUI>
          </ModalHeaderUI>
          <ModalContentUI>
            <UncontrolledInput
              name="name"
              required="폴더명을 입력하세요"
              placeholder="추가된 폴더"
            />
          </ModalContentUI>
        </ModalBodyUI>
      </form>
    </FormProvider>
  );
}
