import React, { useCallback, useMemo } from 'react';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  length: number;
  key: string;
  selected?: number;
}

export const useGetCellLogic = ({ length, key, selected }: Readonly<Props>) => {
  const emptyRows = useMemo(
    () =>
      Array(20 - length)
        .fill(null)
        .map((_, index) => (
          <TableRowBox key={`empty-${key}-${index}`}>
            <TbcBox />
          </TableRowBox>
        )),
    [length]
  );
  const getSx = useCallback(
    (id) => ({
      backgroundColor: selected === id ? ColorPalette._d7e2f7 : ColorPalette._ffffff,
      cursor: 'pointer',
    }),
    [selected]
  );
  const iconSx = {
    color: ColorPalette._ffb72b,
  };
  return {
    emptyRows,
    getSx,
    iconSx,
  };
};
