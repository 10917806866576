import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import type {
  AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter,
  AccountingSettingsManagementAccountingAccountTableCategoryParameter,
} from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import { accountingManagementAccountingAccountTableApi } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/query/api';

export { Mutation as accountingManagementAccountingAccountTableMutation };

const Mutation = {
  useCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (
        params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter
      ) => accountingManagementAccountingAccountTableApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'category-list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'table-header-count'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useInsert: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AccountingSettingsManagementAccountingAccountTableCategoryParameter) =>
        accountingManagementAccountingAccountTableApi.insert(params, menuId),
      onSuccess: async (id) => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'category-list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'table-header-count'],
        });
        return id;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AccountingSettingsManagementAccountingAccountTableCategoryParameter) =>
        accountingManagementAccountingAccountTableApi.update(params, menuId),
      onSuccess: async (item) => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'category-list'],
        });
        return item;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id?) => accountingManagementAccountingAccountTableApi.delete(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'category-list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'management-accounting-account', 'table-header-count'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
