// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ag1sqWQPxbWhGpQwDvEZ{display:flex;justify-content:flex-start;width:100%;margin-left:10px}.j0__l3WHiJka2sdPTnws{margin-left:4px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/outline/features/short/components/short-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,UAAA,CACA,gBAAA,CAGF,sBACE,eAAA","sourcesContent":[".td {\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  width: 100%;\r\n  margin-left: 10px;\r\n}\r\n\r\n.modified__at {\r\n  margin-left: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"td": `Ag1sqWQPxbWhGpQwDvEZ`,
	"modified__at": `j0__l3WHiJka2sdPTnws`
};
export default ___CSS_LOADER_EXPORT___;
