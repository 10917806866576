import React from 'react';
import { Box } from '@mui/material';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import useDialog from '@front/dialog/hook';
import { ColorPalette } from '@front/assets/theme';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  addComment: (comment: CommentParameter) => void;
}

function AddCommentRow({ inputRef, addComment }: Props) {
  const { error } = useDialog();
  const currentPage = useSelector(
    (state: RootState) => state.navigation.currentPage!.targetPage,
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'inherit',
        width: '100%',
        gridArea: 'contents',
      }}
    >
      <Input
        variant="outlined"
        endAdornment={
          <Button
            onClick={() => {
              if (inputRef.current?.value.trim() === '') {
                error('내용을 입력해주세요.');
                inputRef.current.value = '';
              } else {
                if (inputRef.current) {
                  addComment({
                    content: inputRef.current.value,
                    category: currentPage,
                  });
                  inputRef.current.value = '';
                }
              }
            }}
          >
            등록
          </Button>
        }
        sx={{
          border: `1px solid ${ColorPalette._e4e9f2}`,
        }}
        multiline
        placeholder="제안 피드백을 작성 후 등록 버튼을 클릭 하세요."
        inputRef={inputRef}
      />
    </Box>
  );
}

export default AddCommentRow;
