import React, { useMemo } from 'react';
import type { AuthorizationType } from '@front/user/domain';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import UserSelectorInputAndModal from '@front/src/features/user-selector/features/input/components/InputAndModal';
import useModalOpen from '@front/src/hooks/useModalOpen';

export interface UserSelectorInputProps {
  value: number | number[] | undefined;
  onChange: (value: number | number[] | undefined) => void;
  multi?: boolean;
  readOnly?: boolean;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
  onClickForRemove?: () => void;
  predefinedIdList?: number[];
}

export default function UserSelectorInputFeature({
  value,
  multi = false,
  readOnly = false,
  menuIdList = [],
  typeList = [],
  ...rest
}: Readonly<UserSelectorInputProps>) {
  const { onOpen, open, onClose } = useModalOpen();
  const isMulti = useMemo(
    () => multi || (Array.isArray(value) && value.length > 1),
    [multi, value]
  );
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  if (!allUser) {
    return <></>;
  }
  return (
    <UserSelectorInputAndModal
      value={value}
      readOnly={readOnly}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      multi={isMulti}
      menuIdList={menuIdList}
      typeList={typeList}
      {...rest}
    />
  );
}
