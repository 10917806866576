import React, { memo, Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalBoxContent from '@front/src/features/approval-box/components/ApprovalBoxContent';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

function ApprovalBoxFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);

  return (
    <Suspense fallback={<LoadingSpinner padding="20px" />}>
      <ResizableWrapper>
        <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval-box'}>
          <ApprovalBoxContent />
        </ResizableLeftDrawer>
      </ResizableWrapper>
    </Suspense>
  );
}

export default memo(ApprovalBoxFeature);

function ResizableWrapper({ children }: { children: React.ReactNode }) {
  const { documentId, params } = useApprovalDocumentStore(
    useShallow((state) => ({
      documentId: state.documentId,
      params: state.params,
    }))
  );
  const { data: boxDetail } = approvalBoxQuery.useGetApprovalBoxDetail(documentId, params);
  const methods = useForm({
    values: {
      comment: '',
      commentList: boxDetail?.commentList ?? [],
    },
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
}
