export const parentsTypeInKor = {
  PROJECT: '영업정보 관리',
  PROCEEDINGS: '회의록',
  PROPOSAL: '제안',
  OST: 'OST',
  CAMPAIGN: '캠페인 관리',
  EVALUATION: '심사 목록',
  REWARD: '포상 관리',
  VOTE: '투찰 목록',
  ARRANGEMENT: '협의 목록',
  APPROVAL: '결재함',
  AFFILIATED_COMPANY: '관계사',
  WORK: '업무',
};

export const notificationTypeInKor = {
  approval: '결재',
  assignment: '담당 알림',
  note: '비고',
  review: '회람',
};

export const notificationTypeInENG = {
  approval: 'approval',
  assignment: 'assignment',
  note: 'note',
  review: 'review',
};

export const notificationTypeOptionList = [
  {
    label: '전체 유형',
    value: ' ',
  },
  {
    label: '결재',
    value: notificationTypeInENG.approval,
  },
  {
    label: '비고',
    value: notificationTypeInENG.note,
  },
  {
    label: '회람',
    value: notificationTypeInENG.review,
  },
  {
    label: '담당 알림',
    value: notificationTypeInENG.assignment,
  },
];
