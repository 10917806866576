import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkWorkerParams } from '@front/src/features/work/features/work/features/worker/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import { useDispatch } from 'react-redux';
import { userNotificationAction } from '@front/user_notification/action';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workWorkerMutation = {
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.createWorker(id, commonParams),
      mutationKey: ['work', 'worker', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddTop: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addTopWorker(commonParams),
      mutationKey: ['work', 'worker', 'add-top'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddBottom: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addBottomWorker(commonParams),
      mutationKey: ['work', 'worker', 'add-bottom'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate } = useMutation({
      mutationFn: (params: WorkWorkerParams) =>
        workWorkApi.updateWorker({ ...params, ...commonParams }, commonParams),
      mutationKey: ['work', 'worker', 'update'],
      onMutate: async (data: WorkWorkerParams) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'worker']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return (
          newData.userId === (prevData?.user?.id ?? null) &&
          newData.roleDetail === prevData?.roleDetail &&
          newData.role === prevData?.role &&
          newData.participationRate === prevData?.participationRate
        );
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
    };
  },
  useSeqUp: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateWorkerSeqUp(id, commonParams),
      mutationKey: ['work', 'worker', 'seq-up'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqDown: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateWorkerSeqDown(id, commonParams),
      mutationKey: ['work', 'worker', 'seq-down'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteWorker(id, commonParams),
      mutationKey: ['work', 'worker', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
    };
  },
  useDeleteAll: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.deleteAllWorker(commonParams),
      mutationKey: ['work', 'worker', 'delete-all'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'worker'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
        await queryClient.invalidateQueries(['global-nav-bar']);
        dispatch(userNotificationAction.requestCount());
      },
    });
    return {
      mutate,
    };
  },
};

export default workWorkerMutation;
