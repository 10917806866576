import React, { useCallback, useContext, useEffect } from 'react';
import { TableRow } from '@mui/material';
import type { ProjectCMSaveHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/view';
import { OldTd } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import {
  convertEmptyToNull,
  strToYNConvertor,
  YNOptionList,
  YNToStrConvertor
} from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useProjectSalesInfoCMSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/useState';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoCMSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/context';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useShallow } from 'zustand/react/shallow';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { CMSaveHistoryTableRow as ProjectSalesInfoCMSaveHistoryTableRow };

interface Props {
  item: ProjectCMSaveHistoryView;
}

const CMSaveHistoryTableRow = ({ item }: Props) => {
  const { Text } = AddDeleteTable;
  const methods = useForm<ProjectCMSaveHistoryParameter>({
    values: {
      id: item.id,
      isSaved: YNToStrConvertor(item.isSaved) ?? '',
      note: item.note ?? '',
      saveDate: item.saveDate ?? null
    },
  });
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoCMSaveHistoryContext);
  const { id, setId } = useProjectSalesInfoCMSaveHistoryState(
    (state) => ({
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { getValues, handleSubmit } = methods;
  const isSelected = item.id === id;
  const { readOnly } = useProjectSalesInfoCMSaveHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(() => {
    if (readOnly) return;
    setId(item.id);
  }, [setId, item.id, readOnly]);
  const onSubmit = handleSubmit((data: ProjectCMSaveHistoryParameter) => {
    onUpdate(convertEmptyToNull({
      ...data,
      saveDate: formatDateOrNull(data.saveDate),
      isSaved: strToYNConvertor[data.isSaved as string]
    }));
  });
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  useEffect(() => {
    if (currentAuth === 'V') setId();
  }, [setId, currentAuth]);
  return (
    <FormProvider {...methods}>
      <TableRow
        hover={true}
        style={{ cursor: 'pointer', height: '51px' }}
        onClick={handleRowClick}
      >
        <OldTd>{item.collectionNameCompleted}</OldTd>
        <OldTd>{item.paymentRatio}</OldTd>
        <OldTd>{item.CMRatio}</OldTd>
        <OldTd>{item.CMAmount?.toLocaleString() ?? '-'}</OldTd>
        <OldTd>{item.convertedCMAmount}</OldTd>
        <OldTd>
          {isSelected ? (
            <HookFormSelect
              name="isSaved"
              optionList={YNOptionList}
              defaultLabel="선택"
              isDefaultPossible={true}
              onSubmit={onSubmit}
              height="0px"
              disabled={readOnly}
            />
          ) : (
            <Text>{getValues('isSaved') || '-'}</Text>
          )}
        </OldTd>
        <OldTd>
          {isSelected ? (
            <DatePickerWithHookForm
              name="saveDate"
              onSubmit={onSubmit}
              disabled={readOnly}
            />
          ) : (
            <Text>{getValues('saveDate') || '-'}</Text>
          )}
        </OldTd>
        <OldTd>
          {isSelected ? (
            <HookFormInput
              name="note"
              onSubmit={onSubmit}
              disabled={readOnly}
            />
          ) : (
            <Text>{getValues('note') || '-'}</Text>
          )}
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
