import React, { memo } from 'react';
import { TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';

export { TableHeader as AccountingSettingsDailyReportStatusListTableHeader };

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="5%"
      >
        계정명
      </Th>
      <Th
        center
        width="10%"
      >
        거래처명
      </Th>
      <Th
        center
        width="10%"
      >
        거래처코드
      </Th>
      <Th
        center
        width="10%"
      >
        이월잔액
      </Th>
      <Th
        center
        width="10%"
      >
        증가
      </Th>
      <Th
        center
        width="10%"
      >
        감소
      </Th>
      <Th
        center
        width="10%"
      >
        금일잔액
      </Th>
      <Th
        center
        width="10%"
      >
        실제통장잔액
      </Th>
      <Th
        center
        width="10%"
      >
        차이
      </Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'AccountingSettingsDailyReportStatusListTableHeader';
