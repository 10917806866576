import React from 'react';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import type { ApprovalBoxView } from '@front/src/features/approval-box/type/view';
import { Box, Typography } from '@mui/material';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { useShallow } from 'zustand/react/shallow';
import BadgeUI from '@front/src/components/components-with-design/component/BadgeUI';
import dayjs from 'dayjs';
import ChipUI from '@front/src/components/components-with-design/component/ChipUI';
import { ColorPalette } from '@front/assets/theme';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

interface Props {
  item: ApprovalBoxView;
}

export default function ApprovalBoxTableRow({ item }: Props) {
  const { setIsBoxList } = useApprovalBoxStore(
    useShallow((state) => ({
      setIsBoxList: state.setIsBoxList,
    }))
  );
  const { setDocumentId, setParams } = useApprovalDocumentStore(
    useShallow((state) => ({
      setParams: state.setParams,
      setDocumentId: state.setDocumentId,
    }))
  );
  const onClick = () => {
    setDocumentId(item.id);
    setIsBoxList(false);
    setParams({ where: 'approval' });
  };
  return (
    <TableRowUI
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <TableCellUI>
        <Typography>{item.title}</Typography>
        <Typography>{item.writerName}</Typography>
      </TableCellUI>
      <TableCellUI>
        <Typography>{dayjs(item.submissionDate).format('YY-MM-DD HH:mm')}</Typography>
      </TableCellUI>
      <TableCellUI>
        <Box
          display="flex"
          flexDirection="column"
          gap="3px"
          alignItems="center"
        >
          <Box
            display="flex"
            gap="15px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography>{item.approvalDocumentStatus}</Typography>
            <BadgeUI badgeContent={item.alarmCount}>
              <Box>{}</Box>
            </BadgeUI>
          </Box>
          {item.isMyApprovalStatusWaiting && (
            <ChipUI
              sx={{
                height: '22px',
                background: ColorPalette.sub.sub_primary,
                color: ColorPalette._ffffff,
              }}
              readOnly={true}
              label={<Typography>결재대기</Typography>}
            />
          )}
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
}
