import React from 'react';
import { controlPanelTableCellWidth } from '@front/src/utils';
import { commonRepository } from '@front/src/api/repository';
import UIBuilderTableCell from '@front/src/components/ui-builder/table/Cell';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import type { ControlPanelItem } from '@front/src/types/domain';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import { noteAutoScrollingAnchorClassName } from '@front/src/features/note/utils';
import { useNote } from '@front/src/features/note/hooks/useNote';
import useGetUiMetaHiddenList from '@front/src/features/ui-builder/features/ui-meta/query/useGetHiddenList';
import useUpdateUiMeta from '@front/src/features/ui-builder/features/ui-meta/query/useUpdate';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

export interface UIBuilderTableRowProps<T = any> extends UIBuilderTableBodyProps<T> {
  item: T;
  isEditMode?: boolean;
  onSubmit?: any;
  rowProps?: {
    onUpdateFile?: () => void;
  };
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
}

interface Props extends UIBuilderTableRowProps {
  itemList?: ControlPanelItem[];
  sx?: SxProps;
}

export default function UiBuilderTableRow(props: Props) {
  const { readOnly, item, sectionId, itemList, menuId, sx, dataId, formContext } = props;

  const commonParams = {
    menuId,
    sectionId,
    dataId,
  };

  const { watch } = formContext;
  const showAllRow = watch('showAllRow');

  const { data: headerList } = commonRepository.useGetHeaderList(sectionId, menuId);

  const hiddenList = useGetUiMetaHiddenList(commonParams);

  const onUpdateUIMeta = useUpdateUiMeta(commonParams);

  const find = hiddenList?.find((h) => h.targetId === item.id);

  const hidden = find?.isBlind;

  const onHiddenRow = () => {
    onUpdateUIMeta({
      id: find?.id,
      targetId: item.id,
      isBlind: !hidden,
      ...commonParams,
    });
  };

  const tableCellList = headerList?.map((h) => (
    <UIBuilderTableCell
      {...props}
      key={`${item.id} - ${h.id}`}
      header={h}
      sx={{
        borderBottomWidth: !showAllRow && item.isNextHidden ? '3px' : '1px',
      }}
    />
  ));

  const renderControlPanel = () => {
    if (readOnly) return <></>;
    return (
      <TableCellUI
        width={`${controlPanelTableCellWidth}px`}
        sx={{
          borderBottomWidth: !showAllRow && item.isNextHidden ? '3px' : '1px',
        }}
      >
        <ContextMenuUI
          itemList={[
            ...(itemList ?? []),
            {
              text: hidden ? '숨기기 취소' : '숨기기',
              icon: hidden ? VisibilityOffIcon : VisibilityIcon,
              action: onHiddenRow,
              seq: 5,
              split: true,
            },
          ]}
        />
      </TableCellUI>
    );
  };

  const { openNoteOverlay } = useNoteCountClick({
    rowId: item.id,
    sectionId,
    readOnly,
  });

  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );

  const renderNote = () => (
    <TableCellUI
      onClick={openNoteOverlay}
      sx={{
        maxWidth: `${controlPanelTableCellWidth}px`,
        backgroundColor:
          sectionId === noteSectionId && rowId === item.id ? ColorPalette._d2e7fa : 'inherit',
        textDecoration: readOnly ? 'none' : 'underline',
        cursor: 'pointer',
        borderBottomWidth: !showAllRow && item.isNextHidden ? '3px' : '1px',
      }}
      className={
        sectionId === noteSectionId && rowId === item.id
          ? `${noteAutoScrollingAnchorClassName}-${rowId}`
          : ''
      }
    >
      {item.noteCount}
    </TableCellUI>
  );

  return (
    <TableRowUI
      sx={{
        opacity: item.hidden ? '0.5' : 1,
        ...sx,
      }}
    >
      {renderControlPanel()}
      {renderNote()}
      {tableCellList}
      {/* 컬럼에 명시적으로 설정한 너비가 정상적으로 반영되기 위한 여백 컬럼(헤더/바디 각각 추가) */}
      <TableCellUI sx={{ width: 'auto' }} />
    </TableRowUI>
  );
}

interface NoteCountClickProps {
  rowId: number;
  sectionId: number | undefined;
  readOnly: boolean;
}

function useNoteCountClick({ rowId, sectionId, readOnly }: Readonly<NoteCountClickProps>) {
  const { alert } = useCustomDialog();
  const { open: openNoteQuickView } = useNote();

  const openNoteOverlay = () => {
    if (readOnly) return;
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    sectionId && openNoteQuickView({ sectionId, rowId });
  };
  return {
    openNoteOverlay,
  };
}
