import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoEstimationCategory } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils/constant';
import { projectSalesInfoEstimationEstimationCreateModalRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/repository';
import { useProjectSalesInfoEstimationEstimationCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/useState';
import { projectSalesInfoSubjectReviewExperimentRepository } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoEstimationEstimationCreateModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { isOpen, setIsOpen } = useProjectSalesInfoEstimationEstimationCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { run: onCreate, setCallback } =
    projectSalesInfoEstimationEstimationCreateModalRepository.useCreate(menuId);
  const { data: experiment, isLoading: isExperimentLoading } =
    projectSalesInfoSubjectReviewExperimentRepository.useDetailGet(id, menuId);
  const { data: categoryList, isLoading: isCategoryVariableLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoEstimationCategory, menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const d = { categoryList, experiment };
  const h = { onCreate, onClose: handleClose };
  return {
    d,
    h,
    isOpen,
    isLoading: isExperimentLoading || isCategoryVariableLoading,
  };
};
