import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoContractEarningsItemCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/context';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { useProjectSalesInfoContractEarningsItemCreateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/useLogic';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { ProjectSalesInfoContractEarningsItemDetailCreateModalTable } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/components/detail-create-modal-table';
import { ProjectSalesInfoCollectionManagementModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/components/modal-wrapper';
import { isMobileDevice } from '@front/util/PwaUtil';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as ProjectSalesInfoContractEarningsItemCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const { Body, Layout } = collectionModalLayout;
  const { isDetailCreateModalOpen, setIsDetailCreateModalOpen } = useContractEarningsItemState(
    (state) => ({
      isDetailCreateModalOpen: state.isDetailCreateModalOpen,
      setIsDetailCreateModalOpen: state.setIsDetailCreateModalOpen,
    })
  );
  const {
    h: { onCreate },
    d: { consortiumCompanyList, confirmedContractDetail },
    isLoading,
  } = useProjectSalesInfoContractEarningsItemCreateModalLogic(menuId);
  const value = useMemo(
    () => ({
      h: {
        onCreate,
      },
      d: {
        consortiumCompanyList,
        confirmedContractDetail,
      },
    }),
    [onCreate, consortiumCompanyList, confirmedContractDetail]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="기성 추가"
      open={isDetailCreateModalOpen}
      onClose={() => setIsDetailCreateModalOpen()}
    >
      <ProjectSalesInfoContractEarningsItemCreateModalContext.Provider value={value}>
        <ProjectSalesInfoCollectionManagementModalWrapper menuId={menuId} />
        <Layout width="100%">
          <Body>
            {isLoading && <LoadingSpinner />}
            {!isLoading && <ProjectSalesInfoContractEarningsItemDetailCreateModalTable />}
          </Body>
        </Layout>
      </ProjectSalesInfoContractEarningsItemCreateModalContext.Provider>
    </ModalLayout>
  );
};
