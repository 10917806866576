import React, { useContext, useEffect } from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import {
  calcUnitPrice,
  convertYNToBooleanForParameter,
  getValueIfExist,
  YNOptionList,
} from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { ProjectSalesInfoExperimentAmountHookForm } from '@front/src/features/project-sales-info/components/experiment-amount-hook-form';
import { ProjectSalesInfoReviewAmountHookForm } from '@front/src/features/project-sales-info/components/review-amount-hook-form';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { HookFormCompanySelector } from '@front/src/components/company-selector-with-hook-form';
import { ProjectSalesInfoContractContractCreateModalContext } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/context';
import { ProjectSalesInfoContractContractCreateModalButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/components/modal-button-group';
import { ProjectSalesInfoContractContractModalEstimationSelect } from '@front/src/features/project-sales-info/features/contract/features/contract/components/modal-estimation-select';
import RequiredMark from '@front/layouts/RequiredMark';
import { ProjectSalesInfoContractContractContext } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/context';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalTable as ProjectSalesInfoContractContractCreateModalTable };

const values = {
  isConfirmed: '',
  orderIntakeDate: null,
  contractDate: null,
  category: '',
  estimationId: '',
  complexName: '',
  experimentInformation: '',
  experimentAmount: '',
  reviewAmount: '',
  totalAmount: '',
  CMAmount: '',
  convertedCMAmount: '',
  unitPrice: '',
  schedule: '',
  isCm: '',
  CMMethod: '',
  orderCompany: '',
  hwp: null,
  pdf: null,
  note: '',
};

const ModalTable = () => {
  const {
    d: { categoryList, projectFullName, estimationList },
  } = useContext(ProjectSalesInfoContractContractCreateModalContext);
  const {
    d: { CMMethodOptionList },
  } = useContext(ProjectSalesInfoContractContractContext);
  const methods = useForm({
    values,
  });
  const { watch, setValue } = methods;
  const experimentAmount = watch('experimentAmount');
  const experimentInformation = watch('experimentInformation');
  const isCm = convertYNToBooleanForParameter(watch('isCm'));
  const { readOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    const newUnitPrice = calcUnitPrice(experimentInformation, +experimentAmount.replace(/,/g, ''));
    setValue('unitPrice', newUnitPrice);
  }, [experimentAmount, experimentInformation, setValue]);
  useEffect(() => {
    if (isCm) return;
    setValue('CMAmount', '');
    setValue('convertedCMAmount', '');
    setValue('CMMethod', '');
  }, [isCm, setValue]);
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th
                center={false}
                width="140px"
              >
                확정여부
              </Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isConfirmed"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>수주일</Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="orderIntakeDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>계약일자</Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="contractDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="계약분류"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="category"
                  optionList={getOptionListFromVariableList(categoryList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="견적번호"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <ProjectSalesInfoContractContractModalEstimationSelect
                  estimationList={estimationList}
                  readOnly={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="발주처명"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="orderCompany"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>계약명</Th>
              <TableCell>{getValueIfExist(projectFullName)}</TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>단지명</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="complexName"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>실험정보</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="experimentInformation"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>풍동금액</Th>
              <OldTd>
                <ProjectSalesInfoExperimentAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>구검비</Th>
              <OldTd>
                <ProjectSalesInfoReviewAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>총액</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.AMOUNT}
                  width="200px"
                  name="totalAmount"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM비</Th>
                <OldTd>
                  <HookFormInput
                    inputType={InputType.AMOUNT}
                    width="200px"
                    name="CMAmount"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            {isCm && (
              <TableRow>
                <Th center={false}>환산CM비</Th>
                <OldTd>
                  <HookFormInput
                    inputType={InputType.AMOUNT}
                    width="200px"
                    name="convertedCMAmount"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>순동단가</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.AMOUNT}
                  width="200px"
                  name="unitPrice"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>일정</Th>
              <OldTd>
                <HookFormInput
                  width="160px"
                  name="schedule"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>CM 여부</Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isCm"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM 방식</Th>
                <OldTd>
                  <HookFormSelect
                    width="100px"
                    defaultLabel="선택"
                    isDefaultPossible
                    name="CMMethod"
                    optionList={getOptionListFromVariableList(CMMethodOptionList)}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>한글파일 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="hwp"
                  ext="hwp"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>PDF 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="pdf"
                  ext="pdf"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>비고</Th>
              <OldTd sx={{ padding: '10px' }}>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoContractContractCreateModalButtonGroup />
    </FormProvider>
  );
};
