import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import { useFormContext } from 'react-hook-form';
import { changeViewToParams } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';

export { Index as ProjectSalesInfoProjectDivisionBlueprintTable };

const Index = () => {
  const {
    h: { onProjectDivisionUpdate },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) =>
    onProjectDivisionUpdate(changeViewToParams(data as ProjectDivisionView))
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow key={Math.random()}>
            <Th>설계도서 수급예정일</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <DatePickerWithHookForm
                name="expectedDesignDocumentSupplyDate"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow key={Math.random()}>
            <Th>설계도서 수급일</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <DatePickerWithHookForm
                name="designDocumentSupplyDate"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
