import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import DateTimePickerUI from '../components-with-design/component/DateTimePickerUI';
import { YY_MM_DD_HH_MM } from '@front/src/utils';

interface Props extends DatePickerProps<any, any> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  required?: boolean;
}

export default function DateTimePickerWithHookForm({
  name,
  onSubmit,
  required,
  readOnly,
  ...rest
}: Readonly<Props>) {
  const { control, getValues } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required } });

  const handleChange = (value: string | Dayjs) => {
    onHookFormChange(value ? dayjs(value) : null);
    onSubmit?.();
  };

  if (readOnly && getValues(name) === null) {
    return <>-</>;
  }

  if (readOnly && getValues(name) !== null) {
    return <>{dayjs(getValues(name)).format(YY_MM_DD_HH_MM)}</>;
  }

  return (
    <DateTimePickerUI
      value={value ? dayjs(value) : null}
      onChange={handleChange}
      {...rest}
    />
  );
}
