import { useQueryClient } from 'react-query';
import type { ProjectSalesInfoBidInfoView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/view';

export const YNOptionList = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const useBidInfoNumberArray = (id: number) => {
  const cache = useQueryClient();
  return (
    cache.getQueryData([
      'project-sales-info',
      'bid-info',
      'list',
      id,
    ]) as ProjectSalesInfoBidInfoView[]
  ).map((item) => ({ label: `${item.seq}`, value: `${item.id}` }));
};

export const YNToStrConvertor = (value: boolean | undefined) => {
  if (typeof value === 'undefined' || value === null) return '';
  return value ? 'Y' : 'N';
};
