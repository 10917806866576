import React from 'react';
import classes from '@front/src/features/project-sales-info/features/activity/components/layouts/layout.module.scss';

export { Layout as ActivityLayout };

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => <div className={classes.container}>{children}</div>;
