import React from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAbstractContractTable } from '@front/src/features/project-sales-info/features/abstract/features/contract/components/table';
import { ProjectSalesInfoAbstractContractDetailWidget } from '@front/src/features/project-sales-info/features/abstract/features/contract/widgets/detail/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

/**
 * @module 영업정보>요약탭>계약섹션
 */
const ProjectSalesInfoAbstractContractFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <ProjectSalesInfoAbstractContractTable>
          <ProjectSalesInfoAbstractContractDetailWidget menuId={menuId} />
        </ProjectSalesInfoAbstractContractTable>
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoAbstractContractFeature;
