import React from 'react';
import { type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import type { TaxAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';
import TaxAccountTableFullCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/FullCell';

interface Props {
  colIndex: number;
  rowIndex: number;
  showListFieldArray: UseFieldArrayReturn<TaxAccountTableFormValues, 'showList', 'id'>;
  activeRowListFieldArray: UseFieldArrayReturn<TaxAccountTableFormValues, 'activeRowList', 'id'>;
}

export default function TaxAccountTableCell(props: Props) {
  const { rowIndex, colIndex } = props;
  const { getValues } = useFormContext<TaxAccountTableFormValues>();
  const showList = getValues('showList');
  const cellData = showList[colIndex]?.[rowIndex];

  if (cellData) return <TaxAccountTableFullCell {...props} />;

  return <NDepthTableBoxCell>{''}</NDepthTableBoxCell>;
}
