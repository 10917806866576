import React, { useMemo } from 'react';
import InfomationService from '@front/ost_proposal/service/detail/advisorForm/infomation';
import PlanService from '@front/ost_proposal/service/detail/advisorForm/plan';
import AttachedFileService from '@front/ost_proposal/service/detail/advisorForm/attachedFile';
import { Box } from '@mui/material';
import { ArrangementCommentService } from '@front/ost_proposal/service/detail/advisorForm/arrangementComment';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  isContributor: boolean;
}

const AdvisorFormService = ({ isContributor }: Props) => {
  const advisor = useSelector((root: RootState) => root.proposal.detail!.advisor, shallowEqual);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isAdvisor = useMemo(() => loginUser?.id === advisor?.id, [advisor, loginUser]);
  return (
    <>
      {(isAdvisor || isContributor || (loginUser && loginUser.role.id === 1)) && (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <InfomationService isAdvisor={isAdvisor} />
          <PlanService isAdvisor={isAdvisor} />
          <ArrangementCommentService />
          <AttachedFileService isAdvisor={isAdvisor} />
        </Box>
      )}
    </>
  );
};
export default AdvisorFormService;
