import { createContext } from 'react';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';
import { noOp } from '@front/src/utils';
import type { AccountingSettingsBankAccountUpdateParameter } from '@front/src/features/accounting/features/settings/features/bank-account/types/parameter';

export { Context as AccountingSettingsBankAccountListContext };

interface State {
  list?: BankAccountView[];
  onUpdate: (params: AccountingSettingsBankAccountUpdateParameter) => void;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
