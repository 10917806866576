import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { AffiliatedCompanyMeetingHistoryView } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/view';
import type { AffiliatedCompanyMeetingHistoryModalFormState } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types';
import { AffiliatedCompanyMeetingHistoryModalForm } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form';
import { AffiliatedCompanyMeetingHistoryModalFormButton } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-button';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';

const getValues = (detail?: AffiliatedCompanyMeetingHistoryView) => ({
  date: detail?.date ?? '',
  requester: detail?.requester,
  businessMeetingAffiliatedCompany: detail?.businessMeetingAffiliatedCompany,
  location: detail?.location ?? '',
  purpose: detail?.purpose ?? '',
  purposeDetail: detail?.purposeDetail ?? '',
  companionList: detail?.companionList ?? [],
  participantList: detail?.participantList ?? [],
  projectList: detail?.projectList ?? [],
  projectContent: detail?.projectContent ?? '',
  personContent: detail?.personContent ?? '',
  summary: detail?.summary ?? '',
  type: detail?.transportation?.type ?? '',
  transportationNote: detail?.transportation?.note ?? '',
  km: detail?.distance?.km ? String(detail.distance.km) : '',
  distanceNote: detail?.distance?.note ?? '',
});

export { UpdateForm as AffiliatedCompanyMeetingHistoryModalUpdateForm };

const UpdateForm = () => {
  const {
    d: { detail },
    h: { onMeetingHistoryUpdate, onUpdateModalClose },
  } = useContext(AffiliatedCompanyMeetingHistoryModalContext);
  const methods = useForm<AffiliatedCompanyMeetingHistoryModalFormState>({
    values: getValues(detail),
  });
  const handleClick = methods.handleSubmit((data) => {
    onMeetingHistoryUpdate({
      ...data,
      requesterId: data.requester?.id,
      businessMeetingAffiliatedCompanyId: data.businessMeetingAffiliatedCompany?.id,
      companionIdList: data.companionList.map((item) => item.id),
      participantIdList: data.participantList.map((item) => item.id),
      projectIdList: data.projectList.map((item) => item.id),
    });
  });
  return (
    <>
      <FormProvider {...methods}>
        <AffiliatedCompanyMeetingHistoryModalForm />
      </FormProvider>
      <AffiliatedCompanyMeetingHistoryModalFormButton
        onSubmit={handleClick}
        onCancel={onUpdateModalClose}
      />
    </>
  );
};
