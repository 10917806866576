import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UseFormReturn } from 'react-hook-form';

const useGetUIBuilderTableEditState = (
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>
) => {
  const { getValues, setValue } = formContext;

  const editOpenIdList = getValues('editOpenIdList');
  const recentUpdatedId = getValues('recentUpdatedId');

  const toggleEditOpenId = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId = (id) => {
    setValue('recentUpdatedId', id);
  };

  const clearEditOpenIdList = () => {
    if (getValues('editOpenIdList').length === 0) return;
    setValue('editOpenIdList', []);
  };

  const addIfNotExist = (id) => {
    if (getValues('editOpenIdList').includes(id)) return;
    toggleEditOpenId(id);
  };

  return {
    toggleEditOpenId,
    setRecentUpdatedId,
    clearEditOpenIdList,
    recentUpdatedId,
    addIfNotExist,
  };
};

export default useGetUIBuilderTableEditState;

const getEditOpenIdList = (id, editOpenIdList) => {
  if (editOpenIdList.includes(id)) {
    return editOpenIdList.filter((originId) => originId !== id);
  }
  return [...editOpenIdList, id];
};
