import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyWebHardRepository } from '@front/src/features/affiliated-company/features/outline/features/web-hard/repository/repository';

export { useLogic as useAffiliatedCompanyOutlineWebHardLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState((state) => ({ id: state.id }), shallow);
  const { data: webHardList, isLoading } = affiliatedCompanyWebHardRepository.useListGet(
    id,
    menuId
  );
  const { run: onCreate } = affiliatedCompanyWebHardRepository.useCreate(menuId);
  const { run: onDelete } = affiliatedCompanyWebHardRepository.useDelete(menuId);
  const { run: onUpdate } = affiliatedCompanyWebHardRepository.useUpdate(menuId);
  const d = { webHardList };
  const h = {
    onCreate,
    onDelete,
    onUpdate,
  };
  return { d, h, isLoading };
};
