import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyOutlineShortMutation } from '@front/src/features/affiliated-company/features/outline/features/short/query/mutation';
import { affiliatedCompanyOutlineShortQuery } from '@front/src/features/affiliated-company/features/outline/features/short/query';

export const affiliatedCompanyOutlineShortRepository = {
  useOutlineShortUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineShortMutation.useOutlineShortUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useCategoryOptionListGet: (menuId) => {
    const { data } = affiliatedCompanyOutlineShortQuery.useCategoryOptionListGet(menuId);
    return { data };
  },
};
