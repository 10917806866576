import React from 'react';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';

export default function VerticalLine() {
  return (
    <Box
      sx={{
        width: '16px',
        border: `1px solid ${ColorPalette.line.line02}`,
        height: '0px',
        transform: 'rotate(-90deg)',
      }}
    />
  );
}
