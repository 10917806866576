import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkReferenceCreate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workReferenceMutation.useCreate(commonParams);
  return {
    run: (workId: number) => mutate(workId, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkReferenceCreate;
