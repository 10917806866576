import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import projectSalesMutation from '@front/src/features/project-sales/query/mutation';
import { ProjectSalesCreateParameters } from '@front/src/features/project-sales/features/aside/type/parameters';

const useProjectSalesCreate = (commonParams) => {
  const callback = getNoOpCallback();
  const { mutate } = projectSalesMutation.useCreate(commonParams);
  return {
    run: (params: ProjectSalesCreateParameters) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useProjectSalesCreate;
