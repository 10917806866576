import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import type { SxProps } from '@mui/system';
import TextFieldBasic from '@front/src/components/components-with-design/component/TextFieldBasic';

interface Props extends Omit<OutlinedInputProps, 'required' | 'onBlur'> {
  required?: string;
  name: string;
  onBlur?: () => void;
  type?: string;
  sx?: SxProps;
  maxLength?: number;
}

export default function UncontrolledInput(props: Readonly<Props>) {
  const { sx = {}, readOnly = false, name, onBlur } = props;
  const { required, ...rest } = props;
  const { getValues, register } = useFormContext();
  const value = getValues(name);
  if (readOnly) return value || '-';
  return (
    <TextFieldBasic
      {...rest}
      {...register(name, {
        required: required ?? '',
      })}
      defaultValue={value}
      onBlur={onBlur}
      sx={sx}
    />
  );
}
