import React from 'react';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import Box from '@mui/material/Box';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import type { UserSelectorUnchangedMultiFormTFieldValues } from '@front/src/features/user-selector/features/unchanged-multi-form';

export default function UserSelectorUnchangedMultiFormSearchSection() {
  const { setValue } = useFormContext<UserSelectorUnchangedMultiFormTFieldValues>();
  const methods = useForm({
    defaultValues: {
      keyword: '',
    },
  });
  const { handleSubmit, reset } = methods;
  const onSubmit = handleSubmit((data) => {
    setValue('keyword', data.keyword);
  });
  const handleKeyDown = async (e) => {
    const { isComposing, key, keyCode } = e;
    if (isComposing || keyCode === 229) return;
    if (key !== 'Enter') return;
    await onSubmit();
  };
  const handleReset = async () => {
    reset({
      keyword: '',
    });
    await onSubmit();
  };
  return (
    <Box
      sx={{
        width: '400px',
        height: '32px',
        minHeight: '32px',
        display: 'flex',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          width: '270px',
          height: '32px',
        }}
      >
        <FormProvider {...methods}>
          <InputWithHookForm
            name="keyword"
            placeholder="전체 부서 사용자 대상 검색"
            onKeyDown={handleKeyDown}
          />
        </FormProvider>
      </Box>
      <ButtonBasicUI
        shape="primary"
        size="medium"
        onClick={onSubmit}
      >
        검색
      </ButtonBasicUI>
      <ButtonBasicUI
        shape="secondary"
        size="medium"
        onClick={handleReset}
      >
        초기화
      </ButtonBasicUI>
    </Box>
  );
}
