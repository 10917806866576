import { useQuery } from 'react-query';
import { projectSalesInfoAbstractApi } from '@front/src/features/project-sales-info/features/abstract/query/api';
import type { SmProjectBriefProjectInfoView } from '@front/src/features/project-sales-info/features/abstract/features/project-info/types/view';

export { query as projectSalesInfoAbstractProjectInfoQuery };
const query = {
  useDetailGet: (id?, menuId?) => {
    const { data, remove } = useQuery<SmProjectBriefProjectInfoView>({
      queryKey: ['project', 'sales-info', 'abstract', 'project-info', id],
      queryFn: () => projectSalesInfoAbstractApi.getProjectInfo(id, menuId),
      suspense: true,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      remove,
    };
  },
};
