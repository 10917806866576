import { useCallback } from 'react';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import { affiliatedCompanyBusinessRegistrationRepository } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/repository/repository';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';

export { useLogic as useAffiliatedCompanyOutlineNumberBusinessRegistrationFileLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detailOutline, isLoading } = affiliatedCompanyOutlineRepository.useOutlineGet(
    id,
    menuId
  );
  const { run: onOutlineBusinessRegistrationFileUpdate } =
    affiliatedCompanyBusinessRegistrationRepository.useOutlineBusinessRegistrationFileUpdate(
      menuId
    );
  const handleOutlineNumberUpdate = useCallback(
    (params: AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter) => {
      onOutlineBusinessRegistrationFileUpdate({ ...params, id });
    },
    [onOutlineBusinessRegistrationFileUpdate, id]
  );
  const d = { businessRegistrationNumberFile: detailOutline?.businessRegistrationNumberFile };
  const h = { onOutlineBusinessRegistrationFileUpdate: handleOutlineNumberUpdate };
  return { d, h, isLoading };
};
