import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { EstimationCompetitorView } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/types/views';
import useEstimationCompetitorTableRowForm from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/hooks/useTableRowForm';
import { toFormData } from '@front/services/api';
import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import estimationCompetitorMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/query/mutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import type { UpdateByFormDataParameter } from '@front/src/types/parameter';

export { TableBody as EstimationCompetitorTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationCompetitorView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationCompetitorView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useEstimationCompetitorTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<UpdateByFormDataParameter>({
      onAdd: estimationCompetitorMutation.useAdd(commonParams),
      onUpdateSeq: estimationCompetitorMutation.useUpdateSeq(commonParams),
      onDelete: estimationCompetitorMutation.useDelete(commonParams),
      onUpdate: estimationCompetitorMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'competitor'],
    });

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    commonParams,
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete,
  });

  const onSubmit = handleSubmit((data) => {
    let formData = new FormData();

    if (data.estimationDocument) {
      formData = toFormData({
        pdf: { ...data.estimationDocument, ...commonParams },
      });
    }

    const request = {
      seq: data.seq ?? null,
      estimationDate: data.estimationDate ?? null,
      competitorCompanyId: data.competitorCompany?.id ?? null,
      testPlan: data.testPlan ?? null,
      totalPriceDiff: data.totalPriceDiff ?? null,
      competitorTotalPrice: data.competitorTotalPrice ?? null,
      windTunnelPrice: data.windTunnelPrice ?? null,
      structuralReviewPrice: data.structuralReviewPrice ?? null,
      pureUnitPrice: data.pureUnitPrice ?? null,
      requiredWeeksStart: data.requiredWeeksStart ?? null,
      requiredWeeksEnd: data.requiredWeeksEnd ?? null,
      estimationDocumentId: data.estimationDocument?.id ?? null,
      managerId: data.createdBy?.id ?? null,

      numberAttr1: data.numberAttr1 ?? null,
      numberAttr2: data.numberAttr2 ?? null,
      numberAttr3: data.numberAttr3 ?? null,
      textAttr1: data.textAttr1 ?? null,
      textAttr2: data.textAttr2 ?? null,
      textAttr3: data.textAttr3 ?? null,
      dateAttr1: data.dateAttr1 ?? null,
      dateAttr2: data.dateAttr2 ?? null,
      dateAttr3: data.dateAttr3 ?? null,
    };

    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    onUpdate({
      id: item.id,
      formData,
    });
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
      />
    </FormProvider>
  );
}
