import { useDialogState } from '@front/src/features/dialog/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useDialogConfirmLogic };

const useLogic = () => {
  const { openConfirm, onClose, confirmProps } = useDialogState(
    useShallow((state) => ({
      openConfirm: state.openConfirm,
      onClose: state.onClose,
      confirmProps: state.confirmProps,
    }))
  );

  const h = {
    openConfirm,
    onClose,
  };
  return { h, confirmProps };
};
