import React, { memo, Suspense, useEffect } from 'react';
import ApprovalContent from '@front/src/features/drawer-approval/components/ApprovalContent';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { approvalDocumentQuery } from '@front/src/features/drawer-approval-document/query/query';
import {
  APPROVAL_DOCUMENT_STATUS,
  BODY_TEMPLATE_TYPE,
  COMMON_TARGET_TYPE,
} from '@front/type/domain';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { useShallow } from 'zustand/react/shallow';
import { FormProvider, useForm } from 'react-hook-form';
import type { ApprovalDocumentCreateParameter } from '@front/src/features/drawer-approval/types/parameter';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import { approvalQuery } from '@front/src/features/drawer-approval/query/query';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

function ApprovalFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);
  const { documentId } = useIdGroup();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ResizeWrapper>
        <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval'}>
          <ApprovalContent key={documentId} />
        </ResizableLeftDrawer>
      </ResizeWrapper>
    </Suspense>
  );
}

export default memo(ApprovalFeature);

function ResizeWrapper({ children }: { children: React.ReactNode }) {
  const { menuId, dataId, noteId, documentId, sectionId } = useIdGroup();
  const docStatus = useApprovalDocumentStore((state) => state.docStatus);
  const { data: sourceTitle } = approvalQuery.useGetApprovalDocumentSourceTitle({ menuId, dataId });
  const { data: tempDocument } = approvalDocumentQuery.useGetApprovalDocument(
    documentId,
    {
      where: 'approval-document',
      noteId,
    },
    docStatus === APPROVAL_DOCUMENT_STATUS.TEMPORARY
  );
  const newTemp =
    tempDocument?.status === APPROVAL_DOCUMENT_STATUS.TEMPORARY ? tempDocument : undefined;
  const { formType, setFormType } = useApprovalStore(
    useShallow((state) => ({
      formType: state.formType,
      setFormType: state.setFormType,
    }))
  );
  const methods = useForm<ApprovalDocumentCreateParameter>({
    values:
      formType === BODY_TEMPLATE_TYPE.BASIC
        ? getBasicForm(documentId, sectionId, newTemp)
        : getCompareForm(documentId, sectionId, menuId, dataId, newTemp),
  });
  useEffect(() => {
    setFormType(newTemp?.contentsType ?? BODY_TEMPLATE_TYPE.BASIC);
  }, [newTemp]);

  return <FormProvider {...methods}>{children}</FormProvider>;
}

const getBasicForm = (
  documentId: number,
  sectionId: number,
  newTemp?: ApprovalDocumentDetailView
) => ({
  request: {
    id: documentId ?? 0,
    title: newTemp?.title ?? '',
    content: newTemp?.contents.body ?? '',
    approvalList:
      newTemp?.approvalSteps.map((item, index) => ({
        seq: index + 1,
        userId: item.manager.id,
      })) ?? [],
    referenceList:
      newTemp?.referenceList.map((item, index) => ({
        seq: index + 1,
        userId: item.id,
      })) ?? [],
    status: newTemp?.status ?? APPROVAL_DOCUMENT_STATUS.SUBMISSION,
    ...(newTemp?.status === APPROVAL_DOCUMENT_STATUS.TEMPORARY
      ? {
          deleteFileItemList: [],
          contentsId: newTemp?.contents.id ?? 0,
        }
      : {}),
    dataId: 0,
    menuId: 0,
    noteId: 0,
    contentsType: BODY_TEMPLATE_TYPE.BASIC,
    hasFileItemIdAddList: [],
    hasFileItemFileAddList: [],
  },
  fileItemList:
    newTemp?.fileItemList.map((item) => ({
      id: item.id,
      requestDelete: false,
      subPath: '',
      menuId: item.menuId,
      dataId: item.dataId,
      targetId: documentId,
      sectionId,
      targetType: COMMON_TARGET_TYPE.APPROVAL,
    })) ?? [],
});

const getCompareForm = (
  documentId: number,
  sectionId: number,
  menuId: number,
  dataId: number,
  newTemp?: ApprovalDocumentDetailView
) => ({
  request: {
    id: documentId ?? 0,
    title: newTemp?.title ?? '',
    beforeContent: newTemp?.contents.beforeBody ?? '',
    afterContent: newTemp?.contents.afterBody ?? '',
    beforeChangeDate: '',
    approvalList:
      newTemp?.approvalSteps.map((item, index) => ({
        seq: index + 1,
        userId: item.manager.id,
      })) ?? [],
    referenceList:
      newTemp?.referenceList.map((item, index) => ({
        seq: index + 1,
        userId: item.id,
      })) ?? [],
    status: newTemp?.status ?? APPROVAL_DOCUMENT_STATUS.SUBMISSION,
    ...(newTemp?.status === APPROVAL_DOCUMENT_STATUS.TEMPORARY
      ? {
          deleteFileItemList: [],
          contentsId: newTemp?.contents.id ?? 0,
        }
      : {}),
    dataId: 0,
    menuId: 0,
    noteId: 0,
    contentsType: BODY_TEMPLATE_TYPE.COMPARE,
    hasFileItemIdAddList: [],
    hasFileItemFileAddList: [],
  },
  fileItemList:
    newTemp?.fileItemList.map((item) => ({
      id: item.id,
      requestDelete: false,
      subPath: '',
      menuId: item.menuId,
      dataId: item.dataId,
      targetId: documentId,
      sectionId,
      targetType: COMMON_TARGET_TYPE.APPROVAL,
    })) ?? [],
  beforeFileItem: newTemp?.contents.beforeFileItem
    ? {
        requestDelete: false,
        multipartFile: newTemp?.contents.beforeFileItem,
        targetType: COMMON_TARGET_TYPE.APPROVAL,
        subPath: '',
        menuId,
        dataId,
        sectionId,
        targetId: documentId,
      }
    : undefined,
  afterFileItem: newTemp?.contents.afterFileItem
    ? {
        requestDelete: false,
        multipartFile: newTemp?.contents.afterFileItem,
        targetType: COMMON_TARGET_TYPE.APPROVAL,
        subPath: '',
        menuId,
        dataId,
        sectionId,
        targetId: documentId,
      }
    : undefined,
});
