import { projectSalesInfoCollectionSaveHistoryQuery } from '@front/src/features/project-sales-info/features/collection/features/save-history/query';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoCollectionSaveHistoryMutation } from '@front/src/features/project-sales-info/features/collection/features/save-history/query/mutation';
import type { ProjectReviewSavePaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/parameter';

export const projectSalesInfoCollectionSaveHistoryRepository = {
  useSaveHistoryGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoCollectionSaveHistoryQuery.useSaveHistoryGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useSaveHistoryUpdate: (id: number, menuId) => {
    const { mutate } = projectSalesInfoCollectionSaveHistoryMutation.useSaveHistoryUpdate(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectReviewSavePaymentHistoryParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
