import React from 'react';
import { Box, Divider } from '@mui/material';

import HeaderHistoryHeaderSelect from '@front/src/features/header-history/components/HeaderSelect';
import HeaderHistoryTable from '@front/src/features/header-history/components/HistoryTable';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props {
  onClose?: () => void;
}

export default function HeaderHistoryContent({ onClose }: Readonly<Props>) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '8dvw',
          }}
        >
          <HeaderHistoryHeaderSelect />
        </Box>
        {onClose && (
          <ButtonBasicUI
            shape="tertiary"
            size="medium"
            onClick={onClose}
            sx={{
              width: '64px',
            }}
          >
            닫기
          </ButtonBasicUI>
        )}
      </Box>
      <Divider />
      <HeaderHistoryTable />
    </Box>
  );
}
