import React from 'react';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import notificationMutation from '@front/src/features/notification/query/mutation';
import { useCustomDialog } from '@front/src/features/dialog';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

interface Props {
  isBookmarked?: boolean;
  isRead?: boolean;
  notificationId: number;
  handleLink: () => void;
}

export default function NotificationControlPanel({
  isBookmarked,
  isRead,
  notificationId,
  handleLink,
}: Props) {
  const { mutate: onDelete } = notificationMutation.useDelete(notificationId);
  const { mutate: onToggleRead } = notificationMutation.useToggleRead(notificationId, {
    isRead: !isRead,
  });
  const { mutate: onToggleBookMark } = notificationMutation.useToggleBookmark(notificationId, {
    isBookmarked: !isBookmarked,
  });

  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { confirm, alert } = useCustomDialog();

  const handleDelete = () => {
    if (!isRead) {
      alert({
        title: '알림',
        children: [{ value: '읽은 알림만 삭제 할 수 있습니다' }],
      });

      return;
    }
    if (isBookmarked) {
      alert({
        title: '알림',
        children: [{ value: '북마크되지 않은 알림만 삭제 할 수 있습니다' }],
      });

      return;
    }
    confirm({
      title: '삭제 확인',
      children: ['알림을 삭제하시겠습니까?'],
      afterConfirm: () => onDelete(),
    });
  };
  const toggleBookMark = () => {
    onToggleBookMark();
  };
  const toggleRead = () => {
    onToggleRead();
  };
  const moveToLink = () => {
    setIsRightDrawerOpen(false);
    handleLink();
  };

  const itemList = [
    {
      text: '삭제',
      icon: DeleteIcon,
      action: handleDelete,
    },
    {
      text: isBookmarked ? '북마크 해제' : '북마크',
      icon: isBookmarked ? BookmarkIcon : TurnedInNotIcon,
      action: toggleBookMark,
    },
    {
      text: isRead ? '읽지않음' : '읽음',
      icon: isRead ? VisibilityOffIcon : RemoveRedEyeIcon,
      action: toggleRead,
    },
    {
      text: '링크 이동',
      icon: ArrowForwardIcon,
      action: moveToLink,
    },
  ];
  return (
    <ControlPanel
      itemList={itemList}
      isPropagation={true}
    />
  );
}
