import { default as axios } from '@front/src/config/axios';
import type {
  VoteAddParameter,
  VoteMemoAddParameter,
  VoteMemoChangeParameter,
} from '@front/ost_vote/parameter';
import type { ProposalId } from '@front/ost_proposal/domain';
import type { VoteMemoQuery, VoteQuery } from '@front/ost_vote/query';
import type { VoteMemoId } from '@front/ost_vote/domain';

class VoteApi {
  /**
   * 제안 목록 조회 API
   * @param query
   */
  async getProposalList(query: VoteQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/proposal/vote/list?keyword=${query.keyword}&status=${query.status}&isVoted=${query.isVoted}`
    );
    return data;
  }

  async addVote(id: ProposalId, params: VoteAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/ost/proposal/${id}/vote`, { price: params.price });
    return data;
  }

  async getVote(id: ProposalId): Promise<void> {
    const { data } = await axios.get(`/api/ost/proposal/${id}/vote`);
    return data;
  }

  /**
   * 투찰 메모 관련 API
   * @param id
   * @param params
   */
  async addMemo(id: ProposalId, params: VoteMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/vote/${id}/memo`, params);
    return data;
  }

  async getMemoList(id: ProposalId, query: VoteMemoQuery): Promise<void> {
    const { data } = await axios.get(`/api/vote/${id}/memo?keyword=${query.keyword}`);
    return data;
  }

  async changeMemo(id: ProposalId, params: VoteMemoChangeParameter): Promise<void> {
    const { data } = await axios.put(`/api/vote/${id}/memo/${params.id}`, params);
    return data;
  }

  async deleteMemo(id: ProposalId, memoId: VoteMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/vote/${id}/memo/${memoId}`);
    return data;
  }
}

export const voteApi = new VoteApi();
