import React, { useCallback, useEffect } from 'react';
import { Box, Card, Typography } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import NotificationControlPanel from '@front/src/features/notification/components/ControlPanel';
import { ColorPalette } from '@front/assets/theme';
import type { NotificationView } from '@front/src/features/notification/types/view';
import { notificationTypeInKor } from '@front/src/features/notification/utils/constants';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';
import useNotificationStore from '@front/src/features/notification/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import notificationQuery from '@front/src/features/notification/query/query';
import { useNavigate } from 'react-router-dom';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';

interface Props {
  item: NotificationView;
}

export default function NotificationCard({ item }: Props) {
  const { setMenuId } = useGlobalNavBarState();
  const { currentNoteId, setCurrentNoteId } = useNotificationStore(
    useShallow((state) => ({
      currentNoteId: state.currentNoteId,
      setCurrentNoteId: state.setCurrentNoteId,
    }))
  );
  const { alert } = useCustomDialog();
  const { data: link, isLoading } = notificationQuery.useGetLink(
    item.id,
    currentNoteId === item.id
  );
  const navigate = useNavigate();
  const { setNoteId, setIsRightDrawerOpen, setLeftDrawerState, setIsThroughLink, isThroughLink } =
    useGlobalNavBarState(
      useShallow((state) => ({
        setNoteId: state.setNoteId,
        setIsRightDrawerOpen: state.setIsRightDrawerOpen,
        setLeftDrawerState: state.setLeftDrawerState,
        setIsThroughLink: state.setIsThroughLink,
        isThroughLink: state.isThroughLink,
      }))
    );
  const { setSectionId, setRowId, setSelectedNoteId, setSelectedCommentId } = useNoteStore(
    useShallow((state) => ({
      setSectionId: state.setSectionId,
      setRowId: state.setRowId,
      setSelectedNoteId: state.setSelectedNoteId,
      setSelectedCommentId: state.setSelectedCommentId,
    }))
  );
  const { setDocumentId, setParams, documentId } = useApprovalDocumentStore(
    useShallow((state) => ({
      setDocumentId: state.setDocumentId,
      setParams: state.setParams,
      documentId: state.documentId,
    }))
  );
  const { setIsBoxList, setBoxSearchParams } = useApprovalBoxStore(
    useShallow((state) => ({
      setIsBoxList: state.setIsBoxList,
      setBoxSearchParams: state.setBoxSearchParams,
    }))
  );
  const moveToLink = useCallback(() => {
    if (currentNoteId !== item.id || isLoading) return;
    if (item.isDeleted) {
      alert({
        title: '알림',
        children: [{ value: '해당 글은 삭제되었습니다.' }],
      });
      return;
    } else if (!link?.forwardUrl) {
      alert({
        title: '알림',
        children: [{ value: '열람 권한이 없습니다.' }],
      });
      return;
    }

    const isWork = !link?.noteId && !link?.approvalDocumentId;
    const isApproval = link?.type === 'APPROVAL';
    sessionStorage.setItem('menuId', `${link?.menuId ?? ''}`);
    sessionStorage.setItem('dataId', `${link?.dataId ?? ''}`);
    const noteAuth = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    sessionStorage.setItem('noteAuth', JSON.stringify({ ...noteAuth, noteId: link.noteMenuId }));
    setSectionId(link?.sectionId ?? 0);
    setRowId(link?.rowId ?? 0);
    navigate(link?.forwardUrl ?? '');

    if (isWork) return;
    setNoteId(link?.noteId ?? 0);
    if (isApproval) {
      setDocumentId(link?.approvalDocumentId ?? 0);
      setIsBoxList(false);
      setParams({
        where: 'approval-document',
        noteId: link?.noteId ?? 0,
      });
      setCurrentNoteId();
      setBoxSearchParams({ type: link.boxTypeRelatedToApproval });
      return;
    }
    setIsThroughLink(true);

    setSelectedNoteId(link?.noteId);
    setSelectedCommentId(link.defaultId);

    setTimeout(() => {
      setSelectedNoteId();
      setSelectedCommentId();
    }, 5000);
    setCurrentNoteId();
  }, [currentNoteId, isLoading, item, link]);

  useEffect(() => {
    if (currentNoteId === item.id && !isLoading) moveToLink();
  }, [isLoading, moveToLink, currentNoteId, item]);

  useEffect(() => {
    if (isThroughLink) setIsRightDrawerOpen(true);
  }, [isThroughLink]);

  useEffect(() => {
    if (documentId !== 0) setLeftDrawerState('approval-box');
  }, [documentId]);

  useEffect(() => () => setCurrentNoteId(), []);

  return (
    <Box
      sx={{
        height: '121px',
      }}
    >
      <Card
        sx={{
          padding: '5px',
          background: item.readAt ? ColorPalette._e4e9f2 : '',
          opacity: item.readAt ? 0.6 : 1,
          cursor: 'pointer',
          height: '100%',
        }}
        onClick={() => {
          setIsRightDrawerOpen(false);
          setCurrentNoteId(item.id);
          setMenuId(item.menuId);
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            gap="5px"
          >
            <NotificationControlPanel
              notificationId={item.id}
              isRead={!!item.readAt}
              isBookmarked={item.isBookmarked}
              handleLink={() => setCurrentNoteId(item.id)}
            />
            <Typography
              sx={{
                border: '1px solid',
                borderRadius: '20px',
                padding: '3px 15px',
                fontSize: '12px',
                color: item.readAt ? ColorPalette._4c576d : '',
              }}
            >
              {notificationTypeInKor[item.type]}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap="5px"
          >
            <Typography
              fontSize="12px"
              color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
            >
              {dayjs(item.createdAt).format(DATE_TIME_FORMAT)}
            </Typography>
            {item.isBookmarked && <BookmarkIcon />}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          padding="5px 0 0 5px"
        >
          <Typography
            fontSize="16px"
            fontWeight="bold"
            color={item.readAt ? ColorPalette._4c576d : ''}
            sx={{
              textDecoration: item.isDeleted ? 'line-through' : '',
            }}
          >
            {item.title}
          </Typography>
          <Typography
            fontSize="12px"
            color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
            sx={{
              textDecoration: item.isDeleted ? 'line-through' : '',
              whiteSpace: 'pre',
            }}
          >
            {item.contents}
          </Typography>
          <Typography
            fontSize="12px"
            color={item.readAt ? ColorPalette._4c576d : ColorPalette._9b9ea4}
          >
            {item.source}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
