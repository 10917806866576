import { useQuery } from 'react-query';
import { projectSalesInfoEstimationCompetitorUpdateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/query/api';
import type { ProjectEstimationCompetitorView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/view';

export { query as projectSalesInfoEstimationCompetitorUpdateModalQuery };

const query = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectEstimationCompetitorView>({
      queryFn: () => projectSalesInfoEstimationCompetitorUpdateModalApi.getDetail(id, menuId),
      queryKey: ['project', 'sales-info', 'estimation', 'estimation-competitor', id],
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
