import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/bid-info-table.module.scss';
import { bidInfoHeadList } from '@front/src/features/project-sales-info/features/bid/features/bid-information/utils/constants';
import { ProjectSalesInfoBidInfoTableRow } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/bid-info-table-row';
import { ProjectSalesInfoBidInfoContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/context';
import CircularProgress from '@front/components/CircularProgress';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { Index as ProjectSalesInfoBidInfoTable };

const Index = () => {
  const {
    d: { bidInfoList },
    isLoading,
  } = useContext(ProjectSalesInfoBidInfoContext);
  const isListLoading = typeof bidInfoList === 'undefined' || isLoading;
  const isListEmpty = bidInfoList?.length === 0;

  return (
    <TableContainer
      sx={{
        minWidth: '1000px',
        overflow: 'visible',
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {bidInfoHeadList.map((item, index) => (
              <Th
                key={item}
                center={true}
                noWrap={true}
                className={`${index <= 1 ? classes.width__2 : classes.width__6} ${classes.bid__th}`}
              >
                {item}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isListLoading && <Spinner />}
          {!isListLoading && isListEmpty && <NoResult />}
          {!isListLoading &&
            !isListEmpty &&
            bidInfoList?.map((item, index) => (
              <ProjectSalesInfoBidInfoTableRow
                key={item.id}
                item={item}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={10}>저장된 입찰정보가 없습니다.</OldTd>
  </TableRow>
));

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={8}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));
