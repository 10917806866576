import dayjs from 'dayjs';
import {
  ContractHistoryItemView,
  ContractHistoryView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import { ContractCmView } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views';
import { ContractPaymentTermsView } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/types/views';

export const contractHistoryApi = {
  getList: async <T = ContractHistoryView[]>(commonParams) => {
    return await generateDummyData(10);
  },
};

const generateDummyData = async (recordCounts: number) => {
  return new Promise<ContractHistoryView[]>((resolve) => {
    setTimeout(() => {
      const data: ContractHistoryView[] = [];
      for (let i = 1; i <= recordCounts; i++) {
        const contractCM: ContractCmView[] = [];

        const paymentTermsItem: ContractPaymentTermsView = {
          id: 1,
          paymentStage: '계약금',
          paymentSequence: 1,
          client: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          paymentTiming: '협의',
          expectedPaymentDate: '2024-12-25',
          clientRatio: 100,
          companyStageRatio: 33.3,
          totalContractPrice: Math.round(Math.random() * 100000000),
          windTunnelPrice: Math.round(Math.random() * 100000000),
          structuralReviewPrice: Math.round(Math.random() * 100000000),
          structuralReviewRatio: 15,
          structuralReviewPaymentTiming: '수금비율',
          structuralReviewPaymentTarget: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          structuralReviewPaymentDate: '2024-12-30',
          textAttr1: '',
          textAttr2: '',
          textAttr3: '',
          dateAttr1: '',
          dateAttr2: '',
          dateAttr3: '',
          numberAttr1: 0,
          numberAttr2: 0,
          numberAttr3: 0,
        };
        const paymentTermsItemList: ContractPaymentTermsView[] = [];
        if(i%2 === 0){
          paymentTermsItemList.push(paymentTermsItem);
          paymentTermsItemList.push(paymentTermsItem);
          paymentTermsItemList.push(paymentTermsItem);
        }

        const contractCMItem: ContractCmView = {
          id: 1,
          paymentStage: '계약금',
          contractCM: 240000000,
          cmRatioAgainstContractPrice: 22,
          cmPostDeduction: 15000000,
          cmDeductionRatio: 105,
          cmMethod: '상품권',
          cmRecipient: {
            id: 1,
            name: '홍길동',
            position: '과장',
            department: '구매팀',
            affiliatedCompany: {
              id: 1,
              name: '테스트회사',
              code: 'C11111111',
              status: '',
              alarmCount: 0,
            },
          },
          cmPaymentTiming: '잔금완료후',
          cmExpectedPaymentDate: '2024-12-25',
          textAttr1: '',
          textAttr2: '',
          textAttr3: '',
          dateAttr1: '',
          dateAttr2: '',
          dateAttr3: '',
          numberAttr1: 0,
          numberAttr2: 0,
          numberAttr3: 0,
        };
        if (i % 3 === 0) {
          contractCM.push(contractCMItem);
          contractCM.push(contractCMItem);
          contractCM.push(contractCMItem);
        }

        const detailItems: ContractHistoryItemView[] = [];
        const item: ContractHistoryItemView = {
          id: i,
          client: {
            id: 1482,
            name: '터구조㈜',
            status: '',
            alarmCount: 0,
            code: '',
          },
          clientRatio: i === 3 ? 33.3 : 100,
          projectName: `테스트 프로젝트 ${i}`,
          blockName: `${(i % 10) + 1}BL`,
          siteModel: i % 10,
          testPlan: `10F${(i % 10) + 1}P`,
          requiredWeeks: `8/10`,
          paymentTerms: paymentTermsItemList,
          totalEstimationPrice: Math.round(Math.random() * 100000000),
          totalContractPrice: Math.round(Math.random() * 100000000),
          contractedWindTunnelPrice: Math.round(Math.random() * 100000000),
          actualWindTunnelPrice: Math.round(Math.random() * 100000000),
          structuralReviewPrice: Math.round(Math.random() * 1000000),
          structuralReviewRatio: Math.round(Math.random() * 100),
          contractCM: contractCM,
          cmRatio: 10,
          actualUnitPrice: Math.round(Math.random() * 10000000),
          contractUnitPrice: Math.round(Math.random() * 10000000),
          targetUnitPrice: Math.round(Math.random() * 10000000),
          minimumUnitPrice: Math.round(Math.random() * 10000000),
          actualCost: Math.round(Math.random() * 10000000),
          hwpFile: {
            id: 1,
            filename: 'example_file.hwp',
            ext: 'docx',
            size: 1024,
            readableSize: '',
            fileKey: 'example-file-key',
            description: 'example',
            hasAuth: true,
          },
          pdfFile: {
            id: 1,
            filename: 'example_file.docx',
            ext: 'docx',
            size: 1024,
            readableSize: '',
            fileKey: 'example-file-key',
            description: 'example',
            hasAuth: true,
          },
          createdBy: {
            id: 1,
            name: '관리자',
          },
          createdAt: dayjs().add(i, 'day').format('YYYY-MM-DD HH:mm:ss'),
        };

        detailItems.push(item);
        if (i === 3) {
          detailItems.push({
            ...item,
            client: { ...item.client, id: 1251, name: '유노엔지니어링' },
          });
          detailItems.push({
            ...item,
            client: { ...item.client, id: 1251, name: '유노엔지니어링' },
          });
        }

        data.push({
          id: i,
          seq: i,
          confirmed: i === 3 ? 'Y' : 'N',
          estimationCode: `QE${dayjs().format('YYMMDD')}${i.toString().padStart(3, '0')}`,
          orderDate: dayjs().add(i, 'day').format('YYYY-MM-DD HH:mm:ss'),
          orderType: ['미수주', '수주1', '수주2', '수주3'][i % 4],
          contractType: ['본계약', '변경계약', '구두계약'][i % 3],
          contractDate: dayjs().add(i, 'day').format('YYYY-MM-DD HH:mm:ss'),
          detail: detailItems,
        });
      }
      resolve(data);
    }, Math.random() * 1000);
  });
};
