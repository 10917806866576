import { shallow } from 'zustand/shallow';
import { affiliatedCompanyCareerRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useAffiliatedCompanyPersonDetailModalOutlineCareerState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineCareerLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyPersonDetailModalOutlineCareerState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { run: onCareerCreate } =
    affiliatedCompanyCareerRepository.usePersonOutlineCareerCreate(menuId);
  const { isLoading, data: careerList } =
    affiliatedCompanyCareerRepository.usePersonOutlineCareerListGet(modalId, menuId);
  const { run: onCareerUpdate } =
    affiliatedCompanyCareerRepository.usePersonOutlineCareerUpdate(menuId);
  const { run: onCareerDelete } =
    affiliatedCompanyCareerRepository.usePersonOutlineCareerDelete(menuId);
  const h = {
    onCareerCreate: () => onCareerCreate(modalId),
    onCareerUpdate,
    onCareerDelete,
    setIdList,
  };
  const d = { careerList };
  return { d, h, isLoading, idList };
};
