import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { ProjectContractEarningsParameters } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { projectSalesInfoContractEarningsModalApi } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/query/api';

export const projectSalesInfoContractEarningsModalMutation = {
  useContractEarningsCreate: (id: number, menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectContractEarningsParameters) =>
        projectSalesInfoContractEarningsModalApi.createContractEarnings(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'contract-earnings',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
