import React, { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { CellDate as SalesInfoActivityTableCellDate };

interface Props {
  onSubmit: () => Promise<void>;
}

const CellDate = ({ onSubmit }: Props) => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (item.activity?.id !== id) {
    return <Td rowSpan={item.sameActivitySize}>{item.activity?.date}</Td>;
  }
  return (
    <Td rowSpan={item.sameActivitySize}>
      <DatePickerWithHookForm
        name="date"
        onSubmit={onSubmit}
        disabled={readOnly}
      />
    </Td>
  );
};
