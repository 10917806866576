import React from 'react';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';

interface Props extends TypographyProps {
  shape?:
    | 'h4'
    | 'h5'
    | 'h6'
    | 'text-l-semibold'
    | 'text-m-semibold'
    | 'text-l-regular'
    | 'text-m-regular'
    | 'text-s-semibold'
    | 'text-s-regular'
    | 'text-xs-semibold'
    | 'text-xs-regular';
}

const renderTypography = {
  h4: H4Typography,
  h5: H5Typography,
  h6: H6Typography,
  'text-l-semibold': SemiLgTypography,
  'text-l-regular': RegLgTypography,
  'text-m-semibold': SemiMdTypography,
  'text-m-regular': RegMdTypography,
  'text-s-semibold': SemiSmTypography,
  'text-s-regular': RegSmTypography,
  'text-xs-semibold': SemiXsTypography,
  'text-xs-regular': RegXsTypography,
};

export default function TypographyUI({
  children,
  shape = 'text-l-regular',
  ...rest
}: Readonly<Props>) {
  const NewTypography = renderTypography[shape];
  return <NewTypography {...rest}>{children}</NewTypography>;
}

function H4Typography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '3.2rem',
        fontWeight: '700',
        lineHeight: '4.2rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function H5Typography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '2.4rem',
        fontWeight: '700',
        lineHeight: '3.0rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function H6Typography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.8rem',
        fontWeight: '700',
        lineHeight: '2.4rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function SemiLgTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.6rem',
        fontWeight: '600',
        lineHeight: '2.2rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function RegLgTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.2rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function SemiMdTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.4rem',
        fontWeight: '600',
        lineHeight: '2.0rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function RegMdTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.4rem',
        fontWeight: '400',
        lineHeight: '2.0rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function SemiSmTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.2rem',
        fontWeight: '600',
        lineHeight: '1.6rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function RegSmTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.2rem',
        fontWeight: '400',
        lineHeight: '1.6rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function SemiXsTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.1rem',
        fontWeight: '600',
        lineHeight: '1.4rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

function RegXsTypography({ children, ...rest }: Readonly<Props>) {
  return (
    <Typography
      sx={{
        fontSize: '1.1rem',
        fontWeight: '400',
        lineHeight: '1.4rem',
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}
