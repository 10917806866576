import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonGiftHistoryView } from '@front/src/features/affiliated-company/features/gift-history/types/view';

export { Context as AffiliatedCompanyGiftHistoryContext };

interface State {
  d: {
    giftList?: AffiliatedCompanyPersonGiftHistoryView[];
  };
  h: {
    setParameter: (params) => void;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {
    giftList: [],
  },
  h: {
    setParameter: noOp,
  },
  isLoading: false,
});
