import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyOutlineView } from '@front/src/features/affiliated-company/features/outline/types/view';

export { Context as AffiliatedCompanyOutlineInfoContext };

interface State {
  d: {
    detailOutline?: AffiliatedCompanyOutlineView;
  };
  h: {
    onOutlineInfoUpdate: (params: {
      foundationAnniversary: string | null;
      salesAmount: number;
      numberOfEmployees: number
    }) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onOutlineInfoUpdate: noOp,
  },
});
