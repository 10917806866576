import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { modalLayout } from '@front/src/components/layout/modal';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { convertAffiliatedCompanyWebHardModalDataForParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/utils';

export { ButtonGroup as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalButtonGroup };
const ButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext);
  const { handleSubmit } = useFormContext();
  const { setIsOpen, id } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      id: state.id,
    }),
    shallow
  );
  const handleCancelClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onSubmit = handleSubmit((data) => {
    onUpdate({ id, ...convertAffiliatedCompanyWebHardModalDataForParameter(data) });
  });
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={handleCancelClick}
      >
        취소
      </Button>
      <Button onClick={onSubmit}>저장</Button>
    </Footer>
  );
};
