import type { CSSProperties } from 'react';
import React, { useEffect } from 'react';
import { Th } from '@front/src/components/layout/table/th';

export { WorkTh };

interface Props {
  children: React.ReactNode;
  width?: string;
  padding?: string;
  rowSpan?: number;
  colSpan?: number;
  height?: string;
  sx?: CSSProperties;
  noWrap?: boolean;
}

const WorkTh = ({
  children,
  width = '4dvw',
  padding = '0px',
  rowSpan = 1,
  colSpan = 1,
  height,
  sx,
  noWrap = true,
}: Props) => {
  const thref = React.useRef<HTMLTableCellElement>(null);
  useEffect(() => {
    if (thref.current && thref.current.closest('.MuiTableHead-root')) {
      thref.current.style.borderBottom = 'none';
    }
  }, []);
  return (
    <Th
      padding={padding}
      width={width}
      sx={{
        border: 'none',
        height,
        ...sx,
      }}
      thref={thref}
      rowSpan={rowSpan}
      colSpan={colSpan}
      noWrap={noWrap}
    >
      {children}
    </Th>
  );
};
