import React from 'react';
import { SalesInfoActivityTableRow } from '@front/src/features/project-sales-info/features/activity/widgets/row/components/row';
import type { SalesActivityAffiliatesConvertedView } from '@front/src/features/project-sales-info/features/activity/types/views';
import { SalesInfoActivityRowProvider } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/proivder';

export { Widget as SalesInfoActivityRowWidget };

interface Props {
  item: SalesActivityAffiliatesConvertedView;
  menuId?: number;
}

const Widget = ({ item, menuId }: Props) => (
  <SalesInfoActivityRowProvider
    menuId={menuId}
    item={item}
  >
    <SalesInfoActivityTableRow menuId={menuId} />
  </SalesInfoActivityRowProvider>
);
