import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useAccountStatusIsLoading
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/hook';
import AccountStatusRepository
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/repository';
import AccountStatusWidgetDetailTableHead
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/detail-table/head';
import AccountStatusWidgetDetailTableBody
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/detail-table/body';

type Props = Readonly<SectionComponentProps>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UiBuilderTable>;

const AccountStatusWidgetDetailTable: ElementType = (props) => {

  const { menuId, sectionId } = props;
  const isLoading = useAccountStatusIsLoading();
  if (!menuId) {
    return (<LoadingSpinnerUI />);
  }

  const { data } = AccountStatusRepository.useGetDetailList({ menuId, sectionId });

  if (!menuId || !data) {
    return (<LoadingSpinnerUI />);
  }
  return (
    <UiBuilderTable
      {...props}
      HeadComponent={AccountStatusWidgetDetailTableHead}
      BodyComponent={AccountStatusWidgetDetailTableBody}
      isLoading={isLoading}
      list={data}
      groupAttr="alias"
      groupSumAttrs={['totalAmount']}
    />
  );
};

export default AccountStatusWidgetDetailTable;
