import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyFamilyInformationApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/query/api';
import type { AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/types/parameter';

export const affiliatedCompanyFamilyInformationMutation = {
  usePersonCharacterFamilyInformationCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (id: number) =>
        affiliatedCompanyFamilyInformationApi.createPersonCharacterFamilyInformation(id, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'family-information',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterFamilyInformationDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyFamilyInformationApi.deletePersonCharacterFamilyInformation(
          params,
          menuId
        ),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'family-information',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterFamilyInformationUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter) =>
        affiliatedCompanyFamilyInformationApi.updatePersonCharacterFamilyInformation(
          params,
          menuId
        ),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'family-information',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
