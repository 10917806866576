import type { ReactNode } from 'react';
import React from 'react';
import { useAffiliatedCompanySalesActivityListLogic } from '@front/src/features/affiliated-company/features/sales-activity/widgets/list/provider/useLogic';

export { Provider as AffiliatedCompanySalesActivityListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  useAffiliatedCompanySalesActivityListLogic(menuId);
  return <>{children}</>;
};
