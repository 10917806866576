import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { AffiliatedCompanyPersonDetailModalCharacterCharacterContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/widgets/context';
import type { AffiliatedCompanyPersonCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/parameter';
import type { AffiliatedCompanyPersonCharacterCharacterView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/view';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import TableSideHeaderWithCheckbox from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox';

interface Props {
  item: AffiliatedCompanyPersonCharacterCharacterView;
  index: number;
}

export { CharacterTableRow as AffiliatedCompanyPersonDetailModalCharacterCharacterTableRow };
const CharacterTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList, onUpdateCharacter },
    idList,
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterCharacterContext);
  const methods = useForm<AffiliatedCompanyPersonCharacterCharacterUpdateParameter>({
    values: {
      id: item.id,
      content: item.content ?? '',
    },
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onUpdateCharacter(data);
  });

  return (
    <TableRow>
      <TableSideHeaderWithCheckbox
        title="특징"
        index={index}
        idList={idList}
        item={item}
        handleCheckboxChange={handleCheckboxChange}
        disabled={readOnly}
      />
      <OldTd>
        <FormProvider {...methods}>
          <HookFormInput
            name="content"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </FormProvider>
      </OldTd>
    </TableRow>
  );
};
