import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workOutputQuery = {
  useGetList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getOutputList(commonParams),
      queryKey: ['work', 'output-bundle', 'output', commonParams.bundleId],
      suspense: true,
      enabled: !!commonParams.bundleId && !!commonParams.menuId,
    });
    return { data };
  },
};

export default workOutputQuery;
