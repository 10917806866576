import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import type { MenuView } from '@front/src/types';
import {
  TabMain2ItemUI,
  TabMain2UI,
} from '@front/src/components/components-with-design/component/TabUI';

interface Props {
  id?: number;
  tabList?: MenuView[];
  readOnly?: boolean;
  beforeTabNavigate?: (tab: MenuView) => void; // 탭 클릭 후 navigate 수행 전 이벤트 핸들러
  afterTabNavigate?: (tab: MenuView) => void;  // 탭 클릭 후 navigate 수행 후 이벤트 핸들러
}

export default function TabsWithNavigate({ id, tabList, beforeTabNavigate, afterTabNavigate }: Readonly<Props>) {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/').at(-1);
  const index = tabList
    ?.map((t) => t.path?.split('/').at(-1))
    .findIndex((f) => f === splitPathname);
  const { value, onChange, onClick } = useTabsLogic({ id, index, beforeTabNavigate, afterTabNavigate });
  return (
    <TabMain2UI
      value={value}
      onChange={onChange}
    >
      {tabList?.map((t) => (
        <TabMain2ItemUI
          key={t.id}
          label={t.title}
          onClick={() => onClick(t)}
          {...(process.env.NODE_ENV === 'development' ? { 'data-id': t.id } : {})}
        />
      ))}
    </TabMain2UI>
  );
}

interface TabsLogicProps {
  id?: number;
  index?: number;
  beforeTabNavigate?: (tab: MenuView) => void; // 탭 클릭 후 navigate 수행 전 이벤트 핸들러
  afterTabNavigate?: (tab: MenuView) => void;  // 탭 클릭 후 navigate 수행 후 이벤트 핸들러
}

function useTabsLogic({ id, index, beforeTabNavigate, afterTabNavigate }: TabsLogicProps) {
  const [value, setValue] = React.useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const handleClick = (tab) => {
    beforeTabNavigate && beforeTabNavigate(tab);
    let path = tab.path;
    if (id) {
      path = tab.path?.replace('{:id}', `${id}`);
    }
    navigate(path);
    afterTabNavigate && afterTabNavigate(tab);
  };
  useEffect(() => {
    if (index === undefined) return;
    setValue(index);
  }, [index]);
  return {
    value,
    onChange: handleChange,
    onClick: handleClick,
  };
}
