import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { contractPaymentTermsApi } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/api/api';

const contractPaymentTermsQuery = {
  useList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryFn: () => contractPaymentTermsApi.getList(commonParams),
      queryKey: [
        'project',
        'sales',
        commonParams.dataId,
        'contract',
        'history',
        'payment-terms',
        'list',
      ],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return { data };
  },
};

export default contractPaymentTermsQuery;
