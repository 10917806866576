import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';
import {
  accountingBankAccountURL,
  accountingDailyReportURL,
  accountingTaxAccountingURL,
} from '@front/src/features/accounting/features/settings/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as AccountingUploadApi };

const url = {
  getList: () => `/api/accounting/upload-history`,
  createTaxAccounting: () => `${accountingTaxAccountingURL}`,
  createBankAccount: () => `${accountingBankAccountURL}`,
  createDailyReport: () => `${accountingDailyReportURL}`,
};

const Api = {
  getList: async (menuId) => {
    const { data } = await axios.get(url.getList(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createTaxAccounting: async (params: AccountingUploadEcountUploadParameter, menuId) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createTaxAccounting(), formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'Menu-Id': menuId },
    });
    return data;
  },
  createBankAccount: async (params: AccountingUploadEcountUploadParameter, menuId) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createBankAccount(), formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'Menu-Id': menuId },
    });
    return data;
  },
  createDailyReport: async (params: AccountingUploadEcountUploadParameter, menuId) => {
    const formData = new FormData();
    formData.append('file', params.file.multipartFile!);
    formData.append('category', params.category);
    const { data } = await axios.post(url.createDailyReport(), formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'Menu-Id': menuId },
    });
    return data;
  },
};
