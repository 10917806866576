import React, { useCallback } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import dayjs from 'dayjs';
import { DATE_FORMAT, hasImageExtension } from '@front/src/utils';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import Box from '@mui/material/Box';
import WorkOutputTableBodyRowFile from '@front/src/features/work/features/work/features/output/components/File';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import useWorkOutputUpdateFile from '@front/src/features/work/features/work/features/output/repository/useUpdateFile';
import { useQueryClient } from 'react-query';
import useImageDraw from '@front/src/features/image-draw/hooks/useImageDraw';

const workOutputCellComponent = {
  25: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowCreatedAt {...rest} />,
  26: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowUserName {...rest} />,
  27: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowFile {...rest} />,
  113: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowNumberAttr1 {...rest} />,
  114: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowTextAttr1 {...rest} />,
  115: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowDateAttr1 {...rest} />,
  116: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowNumberAttr2 {...rest} />,
  117: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowTextAttr2 {...rest} />,
  118: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowDateAttr2 {...rest} />,
  119: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowNumberAttr3 {...rest} />,
  120: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowTextAttr3 {...rest} />,
  121: ({ ...rest }: CellComponentProps) => <WorkOutputTableRowDateAttr3 {...rest} />,
};

export default workOutputCellComponent;

interface Props extends CellComponentProps<WorkOutputView> {}

const WorkOutputTableRowCreatedAt = ({ item, onClick, sx = {} }: Props) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.createdAt ? dayjs(item.createdAt).format(DATE_FORMAT) : '-'}
  </Box>
);

const WorkOutputTableRowUserName = ({ item, onClick, sx = {} }: Props) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.user?.name}
  </Box>
);

const WorkOutputTableRowFile = (props: Props) => {
  const {
    item,
    isEditMode,
    onClick,
    sx = {},
    readOnly,
    bundleOptions,
    menuId,
    sectionId,
    dataId,
    options,
    formContext,
  } = props;

  const bundleId = bundleOptions?.item.id;

  const { open: openImageDraw } = useImageDraw();
  const onOpenImageModal = useCallback(() => {
    openImageDraw(item.fileItem);
  }, [item]);

  const { onDownload } = useFileItemDownload({ fileKey: item.fileItem?.fileKey });
  const { run: onUpdateFile, setCallback } = useWorkOutputUpdateFile(
    {
      menuId,
      sectionId,
      dataId,
    },
    bundleId
  );

  const { setValue } = formContext;

  const queryClient = useQueryClient();

  setCallback({
    onSuccess: (data) => {
      setValue('recentUpdatedId', data.data);
      const prevData: WorkOutputView[] | undefined = queryClient.getQueryData([
        'work',
        'output-bundle',
        'output',
        bundleId,
      ]);
      const find = prevData?.find((p) => p.id === data.data);
      const findFileItemId = find ? find.fileItem?.id : undefined;
      options?.setFileItemId?.(findFileItemId);
      if (!findFileItemId) return;
      options?.onOpenFileAuthSettingsModal?.();
    },
  });

  const renderContent = () => {
    if (isEditMode && !readOnly) {
      return (
        <WorkOutputTableBodyRowFile
          {...props}
          item={item}
          onUpdateFile={onUpdateFile}
          targetId={bundleId}
        />
      );
    }

    if (!item.fileItem) return <>-</>;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <FileDisplayText
          fileName={item.fileItem?.filename ?? '-'}
          onClick={onDownload}
          align="left"
        />
        {hasImageExtension(item.fileItem?.ext) && (
          <ButtonIconUI
            shape="tertiary"
            size="medium"
            onClick={onOpenImageModal}
          >
            <FontAwesomeIcon icon="magnifying-glass" />
          </ButtonIconUI>
        )}
      </Box>
    );
  };

  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

const WorkOutputTableRowNumberAttr1 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkOutputTableRowTextAttr1 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkOutputTableRowDateAttr1 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkOutputTableRowNumberAttr2 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkOutputTableRowTextAttr2 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkOutputTableRowDateAttr2 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkOutputTableRowNumberAttr3 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkOutputTableRowTextAttr3 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkOutputTableRowDateAttr3 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
