import { useShallow } from 'zustand/react/shallow';
import { useSnackbarState } from '@front/src/features/snackbar/useState';

export const useSnackbar = () => {
  const { show } = useSnackbarState(
    useShallow((state) => ({
      show: state.show,
    }))
  );
  return {
    show,
  };
};
