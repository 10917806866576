import React from 'react';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import ProposalDetailService from '@front/ost_proposal/service/detail';
import ArrangementMemoService from '@front/ost_arrangement/service/memo';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useOstAuth } from '@front/src/hooks/useOstAuth';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const OstArrangementDetailRoute = ({ name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const isMemo = useMobileMenuState((state) => state.isMemo);
  useOstAuth('/ost/arrangement-list');
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <PageLayoutWithMemo>
          <ProposalDetailService />
          {isMemo && <ArrangementMemoService />}
        </PageLayoutWithMemo>
      </Body>
    </Layout>
  );
};

export default OstArrangementDetailRoute;
