import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyPersonDetailModalGiftHistoryLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalGiftHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/context';
import { AffiliatedCompanyPersonDetailModalGiftHistoryButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/components/gift-history-button';
import { AffiliatedCompanyPersonDetailModalGiftHistoryTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/components/gift-history-table';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalGiftHistoryState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/useState';

interface Props {
  readOnly?: boolean;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalGiftHistoryWidget = ({ readOnly, menuId }: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { giftHistoryList },
    h: { setIdList, onGiftHistoryDelete, onGiftHistoryCreate, onGiftHistoryUpdate },
    idList,
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalGiftHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { giftHistoryList },
      h: { setIdList, onGiftHistoryDelete, onGiftHistoryCreate, onGiftHistoryUpdate },
      isLoading,
      idList,
    }),
    [
      giftHistoryList,
      isLoading,
      setIdList,
      idList,
      onGiftHistoryDelete,
      onGiftHistoryCreate,
      onGiftHistoryUpdate,
    ]
  );
  const { setReadOnly } = useAffiliatedCompanyPersonDetailModalGiftHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AffiliatedCompanyPersonDetailModalGiftHistoryContext.Provider value={value}>
      <Layout>
        <Header>
          <AffiliatedCompanyPersonDetailModalGiftHistoryButton />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalGiftHistoryTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalGiftHistoryContext.Provider>
  );
};
export default AffiliatedCompanyPersonDetailModalGiftHistoryWidget;
