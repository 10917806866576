import React, { useCallback } from 'react';

import { Box, MenuItem, TextareaAutosize } from '@mui/material';
import TextBox from '../../../layouts/Text';
import { ColorPalette } from '../../../assets/theme';
import Input from '../../../layouts/Input';
import Select from '../../../layouts/Select';
import type { ProceedingsVO, ProceedingType } from '../../domain';
import { ProceedingsStatus, ProceedingTypeList, ProceedingTypeName } from '../../domain';
import type { ProceedingsUpdateParameter } from '../../parameter';
import { snackbarAction, SnackbarSeverityType } from '../../../components/Snackbar/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import EditorComponent from '../../../components/TextEditor/EditorComponent';
import MeetingInfoSection from '@front/proceedings/view/Form/MeetingInfoSection';
import type { RootState } from '@front/services/reducer';
import { FilePaths } from '@front/common/filePath';

interface Props {
  detail: ProceedingsVO;
  update: (params: ProceedingsUpdateParameter) => void;
  isReader: boolean;
  isWriter: boolean;
  isAttendance: boolean;
}

export default function ContentsSection({
  detail,
  update,
  isReader,
  isWriter,
  isAttendance,
}: Props) {
  const dispatch = useDispatch();
  const detailId = useSelector((root: RootState) => root.proceedings.detail?.id, shallowEqual);
  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        minHeight: '300px',
      }}
    >
      <MeetingInfoSection
        detail={detail}
        update={update}
        isReader={isReader}
        isWriter={isWriter}
        isAttendance={isAttendance}
      />
      <Box sx={{ marginTop: '10px' }}>
        <TextBox
          variant={'body7'}
          sx={{
            width: '13%',
            marginBottom: '10px',
          }}
        >
          안건명
        </TextBox>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              width: '80%',
              marginRight: '10px',
            }}
          >
            <Input
              readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
              variant="outlined"
              defaultValue={detail.agenda}
              placeholder="회의 주제를 간략히 작성 하세요."
              onBlur={(e) => {
                const value = e.target.value;
                if (value === '') {
                  openSnackbar('회의 주제를 입력해주세요.');
                  return;
                }
                if (value !== detail.agenda) {
                  update({
                    agenda: value,
                  });
                }
              }}
            />
          </Box>
          <Box sx={{ width: '20%' }}>
            <Select
              readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
              displayEmpty
              variant="outlined"
              value={detail.category ?? ''}
              onChange={(e) => {
                const value = e.target.value as ProceedingType;
                if (detail.category !== value) {
                  update({
                    category: value,
                  });
                }
              }}
            >
              <MenuItem
                disabled
                value=""
              >
                회의 분류
              </MenuItem>
              {ProceedingTypeList.map((item, index) => (
                <MenuItem
                  key={`${item}_${index}`}
                  value={item}
                >
                  {ProceedingTypeName(item)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <TextBox
          variant={'body7'}
          sx={{
            width: '13%',
            marginBottom: '10px',
          }}
        >
          내용
        </TextBox>
        <EditorComponent
          readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
          value={{
            id: detail.id,
            beforeContent: detail.content,
          }}
          update={update}
          dataId={detailId}
          filePath={FilePaths.PROCEEDINGS}
        />
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        <TextBox
          variant={'body7'}
          sx={{
            width: '13%',
            marginBottom: '10px',
          }}
        >
          의사결정 사항
        </TextBox>
        <TextareaAutosize
          readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
          style={{
            border: `1px solid ${ColorPalette._e4e9f2}`,
            marginTop: '10px',
            width: '100%',
            padding: '10px',
            fontFamily: 'Noto Sans KR',
            fontSize: '13px',
            fontWeight: 400,
          }}
          minRows={10}
          placeholder="의사 결정 사항을 입력 하세요."
          defaultValue={detail.decision}
          onBlur={(e) => {
            const value = e.target.value;

            if (value === '') {
              openSnackbar('의사 결정 사항을 입력 하세요.');
              return;
            }

            if (value !== detail.decision) {
              update({
                decision: value,
              });
            }
          }}
        />
      </Box>
    </Box>
  );
}
