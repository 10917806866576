import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';

export { Context as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext };

interface State {
  d: {
    list?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[];
  };
  h: {
    onDelete: (id) => void;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {
    list: [],
  },
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
