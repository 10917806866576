import { createAction } from 'typesafe-actions';
import type { ProposalId, ProposalShortVO } from '@front/ost_proposal/domain';
import type { ArrangementMemoQuery, ArrangementQuery } from '@front/ost_arrangement/query';
import type {
  ArrangementMemoAddParameter,
  ArrangementMemoChangeParameter,
} from '@front/ost_arrangement/parameter';
import type { ArrangementMemoId, ArrangementMemoVO } from '@front/ost_arrangement/domain';

export enum ArrangementAction {
  /**
   * 평가 목록 관련 액션
   */
  setId = 'arrangement/id/set',
  setList = 'arrangement/list/set',
  setFilter = 'arrangement/filter/set',
  toggleDrawer = 'arrangement/drawer/toggle',
  setLoading = 'arrangement/loading/set',
  searchFilterOpen = 'arrangement/search-filter-open/set',
  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen = 'arrangement/drawer/set',
  addMemo = 'arrangement/memo/add',
  setMemoFilter = 'arrangement/memo/filter',
  setMemoList = 'arrangement/memo/list/set',
  changeMemo = 'arrangement/memo/change',
  deleteMemo = 'arrangement/memo/delete',
  setMemoLoading = 'arrangement/memo/loading/set',
}

export const arrangementAction = {
  /**
   * 평가 목록 관련 액션
   */
  setId: createAction(ArrangementAction.setId)<ProposalId | undefined>(),
  setList: createAction(ArrangementAction.setList)<ProposalShortVO[]>(),
  setFilter: createAction(ArrangementAction.setFilter)<ArrangementQuery>(),
  toggleDrawer: createAction(ArrangementAction.toggleDrawer)<boolean>(),
  setLoading: createAction(ArrangementAction.setLoading)<boolean>(),
  searchFilterOpen: createAction(ArrangementAction.searchFilterOpen)<boolean>(),

  /**
   * 제안 메모 관련 액션
   */
  setMemoDrawerOpen: createAction(ArrangementAction.setMemoDrawerOpen)<boolean>(),
  addMemo: createAction(ArrangementAction.addMemo)<ArrangementMemoAddParameter>(),
  setMemoFilter: createAction(ArrangementAction.setMemoFilter)<ArrangementMemoQuery>(),
  setMemoList: createAction(ArrangementAction.setMemoList)<ArrangementMemoVO[]>(),
  changeMemo: createAction(ArrangementAction.changeMemo)<ArrangementMemoChangeParameter>(),
  deleteMemo: createAction(ArrangementAction.deleteMemo)<ArrangementMemoId>(),
  setMemoLoading: createAction(ArrangementAction.setMemoLoading)<boolean>(),
};
