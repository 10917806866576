import React from 'react';
import { ProjectSalesInfoActivityCreateButtonProvider } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/provider/proivder';
import { ProjectSalesInfoActivityCreateButton } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/components/create-button';

export { Widget as ProjectSalesInfoActivityCreateButtonWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoActivityCreateButtonProvider menuId={menuId}>
    <ProjectSalesInfoActivityCreateButton />
  </ProjectSalesInfoActivityCreateButtonProvider>
);
