import React from 'react';
import { Box } from '@mui/material';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useFormContext } from 'react-hook-form';
import { approvalMutation } from '@front/src/features/drawer-approval/query/mutation';
import { APPROVAL_DOCUMENT_STATUS, BODY_TEMPLATE_TYPE } from '@front/type/domain';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { useSnackbar } from 'notistack';
import { useCustomDialog } from '@front/src/features/dialog';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { approvalQuery } from '@front/src/features/drawer-approval/query/query';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

export default function ApprovalForm() {
  const dataId = sessionStorage.getItem('dataId');
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const { handleSubmit, getValues } = useFormContext();
  const { documentId } = useIdGroup();
  const { noteId, setLeftDrawerState } = useGlobalNavBarState(
    useShallow((state) => ({
      noteId: state.noteId,
      setLeftDrawerState: state.setLeftDrawerState,
    }))
  );
  const { mutate: createApprovalDocument } = approvalMutation.useCreateApprovalDocument(noteId);
  const { mutate: updateTemp } = approvalMutation.useUpdateTemp();
  const { mutate: deleteTemp } = approvalMutation.useDeleteTemp(documentId);

  const { setParams, setDocumentId } = useApprovalDocumentStore(
    useShallow((state) => ({
      setParams: state.setParams,
      setDocumentId: state.setDocumentId,
    }))
  );

  const { confirm, alert } = useCustomDialog();
  const { formType, setFormType } = useApprovalStore(
    useShallow((state) => ({
      formType: state.formType,
      setFormType: state.setFormType,
    }))
  );
  const { data: roles } = approvalQuery.useGetWorkRole(Number(dataId));
  const isIndicator = roles?.creators.find((el) => el.type === 'INDICATOR')?.user;
  const isManager = roles?.creators.find((el) => el.type === 'MANAGER')?.user;
  const isWorker = roles?.workers.some((el) => el.user);
  const isReviewer = roles?.reviewers.some((el) => el.user);

  const isTemp = getValues(`request.status`) === APPROVAL_DOCUMENT_STATUS.TEMPORARY;
  const { enqueueSnackbar } = useSnackbar();

  const validateEmpty = () => {
    if (formType === BODY_TEMPLATE_TYPE.BASIC)
      return getValues('request.title') && getValues('request.content');
    if (formType === BODY_TEMPLATE_TYPE.COMPARE)
      return (
        getValues('request.title') &&
        getValues('request.beforeContent') &&
        getValues('request.afterContent')
      );
  };

  const onSubmit = handleSubmit((data: any) => {
    if (!validateEmpty()) {
      enqueueSnackbar('값을 입력해주세요', { variant: 'warning' });
      return;
    }
    if (!isIndicator || !isManager || !isWorker || !isReviewer) {
      alert({
        title: '인물 미선택 알림',
        children: [
          { value: isIndicator ? '' : '지시자가 선택되지 않았습니다.' },
          { value: isManager ? '' : '담당자가 선택되지 않았습니다.' },
          { value: isWorker ? '' : '협업자가 선택되지 않았습니다.' },
          { value: isReviewer ? '' : '회람자가 선택되지 않았습니다.' },
        ],
      });
      return;
    }
    !isTemp
      ? createApprovalDocument({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.SUBMISSION,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          fileItemList: data.fileItemList.map((file) => {
            const { menuId, dataId, sectionId, ...rest } = file;
            return rest;
          }),
        })
      : updateTemp({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.SUBMISSION,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          addFileItemList: data.fileItemList
            .filter((item) => !item.id)
            .map((file) => {
              const { menuId, dataId, sectionId, ...rest } = file;
              return rest;
            }),
        });
    setLeftDrawerState('');
    setParams({ where: '' });
    setDocumentId(0);
  });

  const onSubmitClick = () => {
    confirm({
      title: '결재 상신 확인',
      lineBreakChildren: [
        { value: '결재가 요청되면 되돌릴 수 없습니다.' },
        { value: '결재 상신하시겠습니까?' },
      ],
      afterConfirm: () => {
        onSubmit();
      },
    });
  };

  const onTempClick = handleSubmit((data: any) => {
    if (!validateEmpty()) {
      enqueueSnackbar('값을 입력해주세요', { variant: 'warning' });
      return;
    }
    isTemp
      ? updateTemp({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.TEMPORARY,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          addFileItemList: data.fileItemList
            .filter((item) => !item.id)
            .map((file) => {
              const { menuId, dataId, sectionId, ...rest } = file;
              return rest;
            }),
        })
      : createApprovalDocument({
          ...data,
          request: {
            ...data.request,
            approvalList: data.request.approvalList.map((item, index) => ({
              ...item,
              seq: index + 1,
            })),
            dataId: Number(dataId) || 0,
            menuId: Number(menuId) || 0,
            noteId: noteId,
            status: APPROVAL_DOCUMENT_STATUS.TEMPORARY,
            hasFileItemIdAddList: data.request.hasFileItemFileAddList.map((item) => item.id),
          },
          fileItemList: data.fileItemList.map((file) => {
            const { menuId, dataId, sectionId, ...rest } = file;
            return rest;
          }),
        });
    setLeftDrawerState('');
    setParams({ where: '' });
    setDocumentId(0);
  });

  const onDelete = () => {
    confirm({
      title: '초기화 확인',
      lineBreakChildren: [{ value: '임시 저장된 데이터를 초기화 하시겠습니까?' }],
      confirmText: '예',
      closeText: '아니오(그냥 닫기)',
      afterConfirm: () => {
        deleteTemp();
        setLeftDrawerState('');
        setParams({ where: '' });
        setDocumentId(0);
      },
      afterClose: () => {
        setLeftDrawerState('');
        setParams({ where: '' });
        setDocumentId(0);
      },
    });
  };

  const onCancel = () => {
    confirm({
      title: '취소 확인',
      lineBreakChildren: [{ value: '저장하지 않고 닫으시겠습니까?' }],
      confirmText: '예',
      closeText: '아니오',
      afterConfirm: () => {
        setLeftDrawerState('');
        setParams({ where: '' });
        setDocumentId(0);
      },
    });
  };

  return (
    <Box sx={{ ...SX_FORM_WRAP }}>
      <Box width="200px">
        <DropDownUI
          value={formType}
          options={[
            { value: BODY_TEMPLATE_TYPE.BASIC, label: '기본 템플릿' },
            { value: BODY_TEMPLATE_TYPE.COMPARE, label: '개선 결과 전/후 템플릿' },
          ]}
          onChange={(e) => setFormType(e.target.value as BODY_TEMPLATE_TYPE)}
        />
      </Box>
      <Box
        display="flex"
        gap="10px"
      >
        <ButtonBasicUI onClick={isTemp ? onDelete : onCancel}>
          {isTemp ? '취소 or 초기화' : '취소'}
        </ButtonBasicUI>
        <ButtonBasicUI onClick={onTempClick}>임시 저장</ButtonBasicUI>
        <ButtonBasicUI onClick={onSubmitClick}>결재 상신</ButtonBasicUI>
      </Box>
    </Box>
  );
}

const SX_FORM_WRAP = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '14px',
  alignItems: 'flex-start',
  background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
