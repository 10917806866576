import { useLocation } from 'react-router-dom';
import { replaceNumbersWithId } from '@front/src/features/project-sales/util/util';
import { MenuView } from '@front/src/types';

const useActiveTab = (tabList: MenuView[]) => {
  const { pathname } = useLocation();
  const pathnameWithId = replaceNumbersWithId(pathname);
  return tabList.find((tabItem) => tabItem.path === pathnameWithId) ?? null;
};

export default useActiveTab;
