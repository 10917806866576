import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoPerformanceModalUpdateLogic } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/update/provider/useLogic';
import { AccountingUploadSalesInfoPerformanceModalUpdateContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/update/provider/context';

export { Provider as AccountingUploadSalesInfoPerformanceModalUpdateProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onUpdate } = useAccountingUploadSalesInfoPerformanceModalUpdateLogic(menuId);
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <AccountingUploadSalesInfoPerformanceModalUpdateContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoPerformanceModalUpdateContext.Provider>
  );
};
