import React from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import type { SmProjectBriefCollectionView } from '@front/src/features/project-sales-info/features/abstract/features/collection/types/view';
import { getLocaleStringIfExist, getValueIfExist } from '@front/src/utils';

export { Row as ProjectSalesInfoAbstractCollectionRow };

interface Props {
  item: SmProjectBriefCollectionView;
}

const Row = ({ item }: Props) => (
  <TableRow>
    <OldTd>{getValueIfExist(item.name)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.amount)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.netAmount)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.netCollectionAmount)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.netUnpaidAmount)}</OldTd>
    <OldTd>{getValueIfExist(item.depositDate)}</OldTd>
    <OldTd>{getValueIfExist(item.collectionForecastDate)}</OldTd>
    <OldTd>
      {item.collectionPossibilityRatio !== null ? `${item.collectionPossibilityRatio}%` : '-'}
    </OldTd>
    <OldTd>{getValueIfExist(item.contractEarningsCase?.[0])}</OldTd>
    <OldTd>{getValueIfExist(item.unpaidReason)}</OldTd>
  </TableRow>
);
