import React from 'react';
import Box from '@mui/material/Box';
import UserSelectorUnchangedMultiFormSearchSection from '@front/src/features/user-selector/features/unchanged-multi-form/components/SearchSection';
import UserSelectorUnchangedMultiFormSubmitButtonGroup from '@front/src/features/user-selector/features/unchanged-multi-form/components/SubmitButtonGroup';
import type { UserVO } from '@front/user/domain';

interface Props {
  onChange: (value: UserVO[]) => void;
  onClose?: () => void;
}

export default function UserSelectorUnchangedMultiFormHeader(props: Readonly<Props>) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '10px',
      }}
    >
      <UserSelectorUnchangedMultiFormSearchSection />
      <UserSelectorUnchangedMultiFormSubmitButtonGroup {...props} />
    </Box>
  );
}
