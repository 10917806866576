import { shallow } from 'zustand/shallow';
import { affiliatedCompanyWebHardRepository } from '@front/src/features/affiliated-company/features/outline/features/web-hard/repository/repository';
import { useCallback } from 'react';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/repository';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    setIsOpen,
    id: modalId,
    setId,
    setCompanyId,
    companyId,
  } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      id: state.id,
      setId: state.setId,
      setCompanyId: state.setCompanyId,
      companyId: state.companyId,
    }),
    shallow
  );
  const { data: companyList, isLoading: isCompanyListLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useListGet(id, menuId);
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoAffiliatedCompanyWebHardUpdateModalRepository.useDetailGet(modalId, menuId);
  const { run: onUpdate, setCallback } =
    projectSalesInfoAffiliatedCompanyWebHardUpdateModalRepository.useUpdate(menuId);
  const { data: webHardList, isLoading: isWebHardListLoading } =
    affiliatedCompanyWebHardRepository.useListGet(companyId, menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setCompanyId(undefined);
    setIsOpen(false);
  }, [setId, setCompanyId, setIsOpen]);
  const h = {
    onUpdate,
    onClose: handleClose,
  };
  const d = {
    companyList,
    webHardList,
    detail,
  };
  return {
    d,
    h,
    isLoading: isCompanyListLoading || isDetailLoading,
    isWebHardListLoading,
  };
};
