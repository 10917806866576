import { createContext } from 'react';
import type { AccountingSettingsBankAccountUpdateParameter } from '@front/src/features/accounting/features/settings/features/bank-account/types/parameter';
import { noOp } from '@front/src/utils';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';

export { Context as AccountingSettingsBankAccountUpdateBankNameContext };

interface State {
  onUpdate: (params: AccountingSettingsBankAccountUpdateParameter) => void;
  item?: BankAccountView;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
