import React from 'react';
import CommentList from '@front/ost_proposal/view/detail/comment/contents/CommentList';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import type { CommentId, ProposalCommentEditVO } from '@front/ost_proposal/domain';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  addComment: (comment: CommentParameter) => void;
  updateComment: (comment: CommentParameter) => void;
  deleteComment: (id: CommentId) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  setCommentId: (id: CommentId | undefined) => void;
  setCommentEditInput: (state: ProposalCommentEditVO) => void;
  setCommentParentId: (id: CommentId | undefined) => void;
  setReplyCommentParentId: (id: CommentId | undefined) => void;
}

function CommentContents({
  updateComment,
  deleteComment,
  setCommentId,
  setCommentEditInput,
  setCommentParentId,
  addComment,
  setReplyCommentParentId,
}: Props) {
  return (
    <Box
      sx={{
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}}`,
        padding: '10px',
      }}
    >
      <CommentList
        addComment={addComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        setCommentId={setCommentId}
        setCommentEditInput={setCommentEditInput}
        setCommentParentId={setCommentParentId}
        setReplyCommentParentId={setReplyCommentParentId}
      />
    </Box>
  );
}

export default CommentContents;
