import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoContractConfirmedWidget from '@front/src/features/project-sales-info/features/contract/features/confirmed/widgets/widget';
import ProjectSalesInfoContractContractWidget from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/widget';
import ProjectSalesInfoContractHistoryWidget from '@front/src/features/project-sales-info/features/contract/features/history/widgets/widget';
import ProjectSalesInfoContractReviewWidget from '@front/src/features/project-sales-info/features/contract/features/reivew/widgets/widget';
import ProjectSalesInfoContractReviewHistoryWidget from '@front/src/features/project-sales-info/features/contract/features/review-history/widgets/widget';

export { sectionComponent as projectSalesInfoContractSectionComponent };

const sectionComponent: SectionComponent = {
  29: ({ ...rest }) => <ProjectSalesInfoContractConfirmedWidget {...rest} />,
  30: ({ ...rest }) => <ProjectSalesInfoContractContractWidget {...rest} />,
  31: ({ ...rest }) => <ProjectSalesInfoContractHistoryWidget {...rest} />,
  32: ({ ...rest }) => <ProjectSalesInfoContractReviewWidget {...rest} />,
  33: ({ ...rest }) => <ProjectSalesInfoContractReviewHistoryWidget {...rest} />,
};
