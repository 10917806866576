import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export { Index as ApprovalLayout };
const Index = ({ children }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }}
  >
    {children}
  </Box>
);
