import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoContractContractQuery } from '@front/src/features/project-sales-info/features/contract/features/contract/query/query';
import { projectSalesInfoContractContractMutation } from '@front/src/features/project-sales-info/features/contract/features/contract/query/mutation';

export { repository as projectSalesInfoContractContractRepository };

const repository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoContractContractQuery.useListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const { mutate } = projectSalesInfoContractContractMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useEstimationListGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoContractContractQuery.useEstimationListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
