import type { AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import {
  accountingManagementAccountingURL,
  accountingTaxAccountingURL,
} from '@front/src/features/accounting/features/settings/utils/constant';
import type { AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as accountingManagementAccountingReadApi };

const url = {
  getList: () => `${accountingManagementAccountingURL}/get-list`,
  create: () => `${accountingManagementAccountingURL}`,
  insert: () => `${accountingManagementAccountingURL}/insert`,
  update: (id?) => `${accountingManagementAccountingURL}/${id}`,
  delete: (id?) => `${accountingManagementAccountingURL}/${id}`,
  getCategoryMicroList: () => `${accountingTaxAccountingURL}`,
};

const Api = {
  getList: async (
    params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter,
    menuId
  ) => {
    const { data } = await axios.post(url.getList(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  create: async (params, menuId) => {
    await axios.post(url.create(), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  insert: async (params, menuId) => {
    const { data } = await axios.post(url.insert(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params, menuId) => {
    if (!params.id) {
      return;
    }
    const { data } = await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (id?, menuId?) => {
    await axios.delete(url.delete(id), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getCategoryMicroList: async (
    params: AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter,
    menuId
  ) => {
    const { data } = await axios.get(url.getCategoryMicroList(), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
