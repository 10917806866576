import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';

interface Props {
  value: string;
}

export default function EditorCollapseText({ value }: Props) {
  const [displayedHtml, setDisplayedHtml] = useState<string>('');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

  const lines = value
    .split(/<\/p>/)
    .filter((line) => line.trim() !== '')
    .map((l) => `${l}</p>`);

  const handleClick = () => {
    setDisplayedHtml(isCollapsed ? value : lines.slice(0, 3).join(''));
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const shouldCollapse = lines.length > 3;
    setIsCollapsed(shouldCollapse);
    setIsButtonVisible(shouldCollapse);
    setDisplayedHtml(shouldCollapse ? lines.slice(0, 3).join('') : value);
  }, [value]);

  const fontAwesomeIconStyles = {
    width: '11px',
    height: '13px',
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '12px',
    textAlign: 'center' as any,
    transform: isCollapsed ? 'rotate(0)' : 'rotate(180deg)',
    transition: 'all 0.4s ease',
  };

  return (
    <div className="quill">
      <div
        className="ql-container ql-snow"
        style={{
          border: 'none',
        }}
      >
        <div
          className="ql-editor"
          style={{
            position: 'relative',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: displayedHtml,
            }}
          />
          {isButtonVisible && (
            <ButtonIconUI
              shape="ghost"
              size="small"
              onClick={handleClick}
              sx={buttonIconSx}
            >
              <FontAwesomeIcon
                icon="angle-down"
                style={fontAwesomeIconStyles}
              />
            </ButtonIconUI>
          )}
        </div>
      </div>
    </div>
  );
}

const buttonIconSx = {
  position: 'absolute',
  top: '5px',
  right: '10px',
  width: '26px',
  height: '26px',
};
