import React, { useContext } from 'react';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { getYesOrNo } from '@front/src/features/affiliated-company/utils';

import { AffiliatedCompanyPersonDetailModalCharacterBasicContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/widgets/context';
import { YNOptionList } from '@front/src/features/affiliated-company/utils/constant';
import { AffiliatedCompanyPersonDetailModalCharacterBasicSelector } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/components/selector';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';

export { Basic as AffiliatedCompanyPersonDetailModalCharacterBasic };

const Basic = () => {
  const {
    d: { personCharacterBasic },
    h: { onPersonCharacterBasicUpdate },
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterBasicContext);
  const methods = useForm({
    values: {
      familyRelationship: personCharacterBasic?.familyRelationship ?? '',
      isMarried: getYesOrNo(personCharacterBasic?.isMarried),
      carType1: personCharacterBasic?.carType1 ?? '',
      carType2: personCharacterBasic?.carType2 ?? '',
      isSmoking: getYesOrNo(personCharacterBasic?.isSmoking),
      drinkingCapacity: personCharacterBasic?.drinkingCapacity ?? '',
      favoriteDrink: personCharacterBasic?.favoriteDrink ?? '',
      favoriteAffiliatedCompany: personCharacterBasic?.favoriteAffiliatedCompany,
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onPersonCharacterBasicUpdate({
      ...data,
      favoriteAffiliatedCompanyId: data.favoriteAffiliatedCompany?.id,
    });
  });
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th>가족관계</Th>
              <OldTd>
                <HookFormInput
                  disabled={readOnly}
                  name="familyRelationship"
                  onSubmit={onSubmit}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>결혼유무</Th>
              <TableCell>
                <HookFormSelect
                  disabled={readOnly}
                  width="100px"
                  name="isMarried"
                  onSubmit={onSubmit}
                  optionList={YNOptionList}
                  defaultLabel="선택"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>차종1</Th>
              <OldTd>
                <HookFormInput
                  disabled={readOnly}
                  name="carType1"
                  onSubmit={onSubmit}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>차종2</Th>
              <OldTd>
                <HookFormInput
                  disabled={readOnly}
                  name="carType2"
                  onSubmit={onSubmit}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>흡연</Th>
              <TableCell>
                <HookFormSelect
                  disabled={readOnly}
                  width="100px"
                  name="isSmoking"
                  onSubmit={onSubmit}
                  optionList={YNOptionList}
                  defaultLabel="선택"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>주량</Th>
              <OldTd>
                <HookFormInput
                  disabled={readOnly}
                  name="drinkingCapacity"
                  onSubmit={onSubmit}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>선호주류</Th>
              <OldTd>
                <HookFormInput
                  disabled={readOnly}
                  name="favoriteDrink"
                  onSubmit={onSubmit}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>선호풍동</Th>
              <OldTd>
                <AffiliatedCompanyPersonDetailModalCharacterBasicSelector onSubmit={onSubmit} />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};
