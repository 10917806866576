// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kALZ3S4KPPKQqQEpu77N{cursor:pointer}.aiU8I7HgmbLlQwNCQNUc{padding:4px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-selector-modal/components/result-table-body.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAGF,sBACE,WAAA","sourcesContent":[".row {\r\n  cursor: pointer;\r\n}\r\n\r\n.cell {\r\n  padding: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `kALZ3S4KPPKQqQEpu77N`,
	"cell": `aiU8I7HgmbLlQwNCQNUc`
};
export default ___CSS_LOADER_EXPORT___;
