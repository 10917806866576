import type { ProjectSalesInfoBidInfoView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/view';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/types/view';

interface State {
  d: {
    bidInfoDetail?: ProjectSalesInfoBidInfoView;
    estimationIdList?: ProjectEstimationView[];
  };
  h: {
    onUpdate: (params: ProjectSalesInfoBidInfoParams) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoBidInfoUpdateModalContext };

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
  },
  isLoading: false,
});
