import { ContractPaymentTermsView } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/types/views';

export const contractPaymentTermsApi = {
  getList: async <T = ContractPaymentTermsView[]>(commonParams) => {
    return await generatePaymentTermsDummyData(3);
  },
};

const generatePaymentTermsDummyData = async (recordCounts: number) => {
  return new Promise<ContractPaymentTermsView[]>((resolve) => {
    setTimeout(() => {
      const contractPaymentTerms: ContractPaymentTermsView[] = [];
      for (let i = 1; i <= recordCounts; i++) {
        const contractPaymentTermsItem: ContractPaymentTermsView = {
          id: i,
          paymentStage: '계약금',
          paymentSequence: 1,
          client: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          paymentTiming: '협의',
          expectedPaymentDate: '2024-12-25',
          clientRatio: 100,
          companyStageRatio: 33.3,
          totalContractPrice: Math.round(Math.random() * 100000000),
          windTunnelPrice: Math.round(Math.random() * 100000000),
          structuralReviewPrice: Math.round(Math.random() * 100000000),
          structuralReviewRatio: 15,
          structuralReviewPaymentTiming: '수금비율',
          structuralReviewPaymentTarget: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          structuralReviewPaymentDate: '2024-12-30',
          textAttr1: '',
          textAttr2: '',
          textAttr3: '',
          dateAttr1: '',
          dateAttr2: '',
          dateAttr3: '',
          numberAttr1: 0,
          numberAttr2: 0,
          numberAttr3: 0,
        };

        contractPaymentTerms.push(contractPaymentTermsItem);
      }
      resolve(contractPaymentTerms);
    }, Math.random() * 1000);
  });
};
