import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/query/api';
import { affiliatedCompanyCharacterCharacterApi } from '@front/src/features/affiliated-company/features/character/features/character/query/api';
import type {
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalView,
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonView,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/types/view';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<
      ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonView[]
    >({
      queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company-person', id],
      queryFn: () => affiliatedCompanyCharacterCharacterApi.getCharacterCharacter(id, menuId),
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      useQuery<ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalView>({
        queryFn: () =>
          projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi.getDetail(
            id,
            menuId
          ),
        queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company'],
        cacheTime: 0,
        enabled: !!id && !!menuId,
      });
    return {
      data,
      isLoading,
    };
  },
};
