import React from 'react';
import useManagedVariable from '@front/src/components/ui-builder/managed-variable/hooks/useManageableVariable';
import type { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import { convertToOptionList } from '@front/src/utils';

interface Props {
  code: ManagedVariable;
  // menuId?: number | undefined;
  name: string;
  onSubmit?: any;
}

export default function ManagedDropDown({ code, name, onSubmit }: Props) {
  const list = useManagedVariable({ code });

  const options = convertToOptionList(list);

  return (
    <DropDownWithHookForm
      defaultLabel="선택"
      name={name}
      options={options}
      onSubmit={onSubmit}
    />
  );
}
