import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingUploadEcountDataContext } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/context';
import type { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { useAccountingUploadEcountDataLogic } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/useLogic';

export { Provider as AccountingUploadEcountDataProvider };

interface Props {
  children: ReactNode;
  type?: ECOUNT_FILE_TYPES;
  isModalMode?: boolean;
}

const Provider = ({ children, type, isModalMode }: Props) => {
  useAccountingUploadEcountDataLogic({ type, isModalMode });
  const value = useMemo(
    () => ({
      isModalMode,
    }),
    [isModalMode]
  );
  return (
    <AccountingUploadEcountDataContext.Provider value={value}>
      {children}
    </AccountingUploadEcountDataContext.Provider>
  );
};
