import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workDetailQuery = {
  useGetList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryKey: ['work', 'detail', commonParams.dataId],
      queryFn: () => workWorkApi.getDetailList(commonParams),
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return {
      data,
    };
  },
};

export default workDetailQuery;
