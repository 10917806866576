import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import type { WorkOutputContentUpdateParameter } from '@front/src/features/work/features/work/features/output/types/parameter';

const useWorkOutputUpdate = (bundleId: number, commonParams) => {
  const callback = getNoOpCallback();
  const { mutate } = workOutputMutation.useUpdate(bundleId, commonParams);
  return {
    run: (params: WorkOutputContentUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkOutputUpdate;
