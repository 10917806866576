import { useIsMutating } from 'react-query';

const useWorkScheduleGetIsLoading = () => {
  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'schedule', 'update'],
  });
  const isInterimReportDateCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'interim-report-date', 'create'],
  });
  const isInterimReportDateUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'interim-report-date', 'update'],
  });
  const isInterimReportDateDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'interim-report-date', 'delete'],
  });
  return (
    isUpdateLoading ||
    isInterimReportDateCreateLoading ||
    isInterimReportDateUpdateLoading ||
    isInterimReportDateDeleteLoading
  );
};

export default useWorkScheduleGetIsLoading;
