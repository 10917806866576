import noteQuery from '@front/src/features/note/query/query';
import { useEffect } from 'react';

const useNoteMenuId = (propMenuId?: number) => {
  const sessionStorageMenuId = sessionStorage.getItem('menuId');
  const { data: noteAuth } = noteQuery.useGetNoteMenuId(
    propMenuId ?? Number(sessionStorageMenuId ?? 0)
  );

  useEffect(() => {
    if (noteAuth) {
      sessionStorage.setItem('noteAuth', JSON.stringify(noteAuth ?? {}));
    }
  }, [noteAuth]);

  return noteAuth;
};

export default useNoteMenuId;
