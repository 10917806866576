import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, TextareaAutosize } from '@mui/material';

import { ColorPalette } from '@front/assets/theme';
import type { RootState } from '@front/services/reducer';
import TextBox from '@front/layouts/Text';

const Notice = () => {
  const { notice, isNoticeExposed } = useSelector((root: RootState) => ({
    notice: root.proposal.detail?.campaign.notice,
    isNoticeExposed: root.proposal.detail?.campaign.isNoticeExposed,
  }));

  if (!isNoticeExposed) {
    return <></>;
  }
  if (!notice) {
    return <></>;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <TextBox
        variant="heading3"
        sx={{
          marginBottom: '10px',
        }}
      >
        공지사항
      </TextBox>
      <TextareaAutosize
        readOnly={true}
        key={notice}
        style={{
          border: `1px solid ${ColorPalette._e4e9f2}`,
          marginTop: '10px',
          width: '100%',
          minHeight: '10px',
          padding: '10px',
          fontFamily: 'Noto Sans KR',
          resize: 'none',
        }}
        minRows={2}
        value={notice}
      />
    </Box>
  );
};

const NoticeService = memo(Notice);
export default NoticeService;
