import { useForm } from 'react-hook-form';
import type { HeaderSettingView, SortStatus } from '@front/src/types';
import { getDefaultFilterBundle } from '@front/src/components/ui-builder/table/utils';

interface Props {
  list: any[] | undefined;
  headerList: HeaderSettingView[] | undefined;
}

const useUIBuilderTableForm = ({ list, headerList }: Props) => {
  const methods = useForm<UIBuilderTableFieldValues>({
    defaultValues,
  });

  const { setValue, reset, getValues } = methods;

  const resetValues = () => {
    // const hasFolded = headerList?.some((h) => h.isFoldStartColumn);
    const initializeFilterBundle = () => getDefaultFilterBundle(headerList, list);
    const currentValues = getValues();

    reset({
      ...currentValues,
      filterBundle: initializeFilterBundle(),
      showList: list,
    });
  };

  const onCloseEditModal = (reset) => {
    reset();
    setValue('headerId', undefined);
    setValue('rowId', undefined);
  };

  return {
    methods,
    onCloseEditModal,
    resetValues,
  };
};

export default useUIBuilderTableForm;

const defaultValues = {
  fileItemId: undefined,
  openHeaderSettingsHistoryModal: false,
  headerId: undefined,
  rowId: undefined,
  sortPriorityList: [],
  useFilter: false,
  filterBundle: [],
  showList: undefined,
  showAllRow: false,
  recentUpdatedId: undefined,
  editOpenIdList: [],
  useHeadFold: true,
};

export interface UIBuilderTableFieldValues {
  fileItemId: number | undefined;
  openHeaderSettingsHistoryModal: boolean;
  headerId: number | undefined;
  rowId: number | undefined;
  sortPriorityList: {
    id: number;
    sortStatus: SortStatus;
  }[];
  useFilter: boolean;
  filterBundle: UIBuilderTableFilterBundle[];
  showList: any[] | undefined;
  showAllRow: boolean;
  recentUpdatedId: number | undefined;
  editOpenIdList: number[];
  useHeadFold: boolean;
}

export interface UIBuilderTableFilterBundle {
  header: HeaderSettingView;
  headerId: number;
  name: string;
  active: boolean;
  attributeName?: string[];
  filter: UIBuilderTableFilterItem[];
}

export interface UIBuilderTableFilterItem {
  value: string;
  checked: boolean;
  shown: boolean;
}
