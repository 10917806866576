import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

export { Layout as AddDeleteTableLayout };

interface Props {
  children: ReactNode;
  height?: string;
  width?: string;
  sx?: SxProps;
}

const Layout = ({ children, height, width, sx = {} }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width,
      height,
      ...sx,
    }}
  >
    {children}
  </Box>
);
