import React, { useCallback } from 'react';
import { MenuItem } from '@mui/material';
import type { OptionType } from '@front/src/components/Select';
import Select from '@front/layouts/Select';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  optionList: OptionType[];
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  disabled?: boolean;
}

export { GiftHistoryTableBodyTotalSelect as AffiliatedCompanyGiftHistoryTableBodyTotalSelect };

const GiftHistoryTableBodyTotalSelect = ({ optionList, onSubmit, disabled }: Props) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name: 'type', control });
  const handleChange = useCallback(
    async (e) => {
      onChange(e);
      await onSubmit();
    },
    [onChange, onSubmit]
  );
  return (
    <Select
      variant="outlined"
      displayEmpty
      value={value}
      onChange={handleChange}
      disabled={disabled}
    >
      <MenuItem value="">전체</MenuItem>
      {optionList.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};
