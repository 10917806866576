import React, { useMemo } from 'react';
import { ProjectSalesInfoActivityItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/context';
import { useProjectSalesInfoActivityItemLogic } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/useLogic';

export { Provider as ProjectSalesInfoActivityItemProvider };

interface Props {
  children: React.ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onUserCreate, onActivityUpdate } = useProjectSalesInfoActivityItemLogic(menuId);
  const value = useMemo(
    () => ({
      onUserCreate,
      onActivityUpdate,
    }),
    [onUserCreate, onActivityUpdate]
  );
  return (
    <ProjectSalesInfoActivityItemContext.Provider value={value}>
      {children}
    </ProjectSalesInfoActivityItemContext.Provider>
  );
};
