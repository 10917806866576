import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { ProjectSalesInfoSubjectReviewModalComplexInformationDeleteContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/delete/widgets/context';
import { useProjectSalesInfoSubjectReviewModalComplexInformationState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/useState';
import { shallow } from 'zustand/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';

export { DeleteButton as ProjectSalesInfoSubjectReviewModalComplexInformationDeleteButton };

const DeleteButton = () => {
  const { onDelete } = useContext(
    ProjectSalesInfoSubjectReviewModalComplexInformationDeleteContext
  );
  const { idList, setIdList } = useProjectSalesInfoSubjectReviewModalComplexInformationState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const { confirm } = useCustomDialog();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      onDelete({ idList });
      setIdList([]);
    });
  }, [idList, confirm, setIdList, onDelete]);
  return (
    <Button
      onClick={handleDeleteClick}
      disabled={readOnly}
    >
      선택동삭제
    </Button>
  );
};
