import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AffiliatedCompanyMeetingHistoryModalForm } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form';
import { AffiliatedCompanyMeetingHistoryModalFormButton } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-button';
import type { AffiliatedCompanyMeetingHistoryModalFormState } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';

const values = {
  date: '',
  location: '',
  purpose: '',
  purposeDetail: '',
  companionList: [],
  participantList: [],
  projectList: [],
  projectContent: '',
  personContent: '',
  summary: '',
  type: '',
  transportationNote: '',
  km: '',
  distanceNote: '',
};

export { CreateForm as AffiliatedCompanyMeetingHistoryModalCreateForm };
const CreateForm = () => {
  const {
    h: { onMeetingHistoryCreate, onCreateModalClose },
  } = useContext(AffiliatedCompanyMeetingHistoryModalContext);
  const methods = useForm<AffiliatedCompanyMeetingHistoryModalFormState>({
    values,
  });
  const handleClick = methods.handleSubmit((data) => {
    onMeetingHistoryCreate({
      ...data,
      requesterId: data.requester?.id,
      businessMeetingAffiliatedCompanyId: data.businessMeetingAffiliatedCompany?.id,
      companionIdList: data.companionList.map((item) => item.id),
      participantIdList: data.participantList.map((item) => item.id),
      projectIdList: data.projectList.map((item) => item.id),
      km: Number(data.km.split(',').join('')),
    });
  });
  return (
    <>
      <FormProvider {...methods}>
        <AffiliatedCompanyMeetingHistoryModalForm />
      </FormProvider>
      <AffiliatedCompanyMeetingHistoryModalFormButton
        onSubmit={handleClick}
        onCancel={onCreateModalClose}
      />
    </>
  );
};
