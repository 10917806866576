import {useCallback, useRef} from 'react';
import * as fabric from "fabric";
import useCanvasUtils, {
  CanvasRuntimeProps
} from "@front/src/features/image-draw/hooks/useCanvasUtil";
import useStateManager, {
  EXT_OBJECT_ATTR_DRAW_ID,
  EXT_OBJECT_ATTR_FILE_ID
} from "@front/src/features/image-draw/hooks/useStateManager";

export interface OverlayItem {
  contents: string,
  imageDrawId: number,
  fileId: number,
  comment: string,
}

interface OverlayStore {
  [key: number]: OverlayItem
}

export default function useOverlayManager() {
  const overlayContents = useRef<OverlayStore>({});

  const {
    lockObject,
    removeObjectsByImageDrawId,
    setObjectOpacityByEditableState
  } = useCanvasUtils();

  const {
    load: loadCanvasData
  } = useStateManager();

  const contains = useCallback((imageDrawId: number) => {
    return !!overlayContents[imageDrawId];
  }, [overlayContents]);

  const unload = useCallback((canvas: fabric.Canvas, imageDrawId: number) => {
      removeObjectsByImageDrawId(canvas, imageDrawId);
      canvas.renderAll();
      delete overlayContents[imageDrawId];
  }, [overlayContents]);

  const load = useCallback((canvas: fabric.Canvas, item: OverlayItem, runtimeProps: CanvasRuntimeProps) => {
    const {contents, imageDrawId, fileId, comment} = item;
    loadCanvasData(canvas, contents, ()=>{ /* post loading callback */
        overlayContents[imageDrawId] = {
          contents,
          imageDrawId,
          fileId,
          comment
        };
      }, (object) => { /* object mapper */
        object.set(EXT_OBJECT_ATTR_FILE_ID, fileId);
        object.set(EXT_OBJECT_ATTR_DRAW_ID, imageDrawId);

        // 과거 첨삭 상태에서 오버레이 데이터 로딩 시 흐리게 처리해줘야 함
        if(runtimeProps.actingDrawingId) {
          setObjectOpacityByEditableState(object, runtimeProps.actingDrawingId === imageDrawId);
        }
        lockObject(object);
      }
    );
  }, [overlayContents]);

  /**
   * 첨삭 데이터 원래 상태로 돌리기
   * @param canvas
   * @param imageDrawId
   */
  const revert = useCallback((canvas: fabric.Canvas, imageDrawId: number, runtimeProps: CanvasRuntimeProps) => {
    removeObjectsByImageDrawId(canvas, imageDrawId);
    load(canvas, overlayContents[imageDrawId], runtimeProps)
  }, [overlayContents]);

  /**
   * 첨삭 데이터 치환
   * @param canvas
   * @param item
   */
  const replace = ((canvas: fabric.Canvas, item: OverlayItem) => {
    overlayContents[item.imageDrawId].contents = item.contents;
    overlayContents[item.imageDrawId].comment = item.comment;
  });

  /**
   * 첨삭 데이터 리턴
   * @param imageDrawId
   */
  const get = useCallback((imageDrawId: number) => {
    return overlayContents[imageDrawId];
  }, [overlayContents]);


  return {load, unload, revert, replace, contains, get}
}