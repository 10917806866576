import { useFormContext } from 'react-hook-form';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { useSnackbar } from 'notistack';
import managementAccountMutation from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/query/mutation';

interface Props {
  commonParams: UIBuilderParameter;
  onTableBodyFocus: () => void;
}

const useManagementAccountInputMutation = ({ commonParams, onTableBodyFocus }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setValue } = useFormContext<ManagementAccountTableFormValues>();

  const onCreate = managementAccountMutation.useCreate(commonParams, () => {
    enqueueSnackbar('생성 성공했습니다.', { variant: 'success' });
    onTableBodyFocus();
  });

  const onChangeName = managementAccountMutation.useUpdate(commonParams, () => {
    enqueueSnackbar('수정 성공했습니다.', { variant: 'success' });
    setValue('updateId', undefined);
    onTableBodyFocus();
  });

  return {
    onCreate,
    onChangeName,
  };
};

export default useManagementAccountInputMutation;
