import React from 'react';
import { AffiliatedCompanySalesActivityRowProvider } from '@front/src/features/affiliated-company/features/sales-activity/widgets/row/provider/proivder';
import { AffiliatedCompanySalesActivityRow } from '@front/src/features/affiliated-company/features/sales-activity/widgets/row/components/row';
import type { AffiliatedCompanySalesActivityConvertedView } from '@front/src/features/affiliated-company/features/sales-activity/types/view';

export { Widget as AffiliatedCompanySalesActivityRowWidget };

interface Props {
  item: AffiliatedCompanySalesActivityConvertedView;
}

const Widget = ({ item }: Props) => (
  <AffiliatedCompanySalesActivityRowProvider item={item}>
    <AffiliatedCompanySalesActivityRow />
  </AffiliatedCompanySalesActivityRowProvider>
);
