import React from 'react';
import { ProjectSalesInfoSubjectReviewModalExperimentTarget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/components/experiment-target-table';

export { Index as ProjectSalesInfoSubjectReviewModalExperimentTargetFeature };
interface Props {
  menuId?: number;
}
const Index = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalExperimentTarget menuId={menuId} />
);
