// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UfVGmXaYp4nbgqvUkLVx{padding:10px;overflow:auto}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/activity/components/layouts/body.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,aAAA","sourcesContent":[".container {\r\n  padding: 10px;\r\n  overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UfVGmXaYp4nbgqvUkLVx`
};
export default ___CSS_LOADER_EXPORT___;
