import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkWorkerSeqDown = (params: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workWorkerMutation.useSeqDown(params);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkWorkerSeqDown;
