import React from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import type { UseFormHandleSubmit } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import type { ConfirmView } from '@front/src/features/dialog/types';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props {
  onClose: () => void;
  onChange: (value: number | number[] | undefined) => void;
  multi?: boolean;
  handleSubmit: UseFormHandleSubmit<{ selectedIdList: (UserVO | undefined)[] }>;
}

export default function UserSelectorButtonSection({
  onClose,
  onChange,
  multi = false,
  handleSubmit,
}: Readonly<Props>) {
  const onSubmit = (data) => {
    onClose();
    if (multi) {
      onChange(data.selectedIdList);
      return;
    }
    onChange(data.selectedIdList?.[0]);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
      }}
    >
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button onClick={handleSubmit(onSubmit)}>저장</Button>
    </Box>
  );
}
