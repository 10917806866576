import { useQuery } from 'react-query';
import accountingApi from '@front/src/features/accounting/api/api';

const managementAccountQuery = {
  useGetList: (commonParams) => {
    const { data } = useQuery({
      queryFn: () => accountingApi.getManagementAccount(commonParams),
      queryKey: ['accounting', 'account-settings', 'management-account'],
      suspense: true,
    });

    return data;
  },

  useGetManual: (params, commonParams) => {
    const { data } = useQuery({
      queryFn: () => accountingApi.getManual(params, commonParams),
      queryKey: ['accounting', 'account-settings', 'management-account', 'manual', params],
      enabled: !!params.category,
      suspense: false,
    });

    return data;
  },
};

export default managementAccountQuery;
