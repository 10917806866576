import { Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { ListDepartment } from '@front/department_tree/entity';
import type { AuthorizationType, UserId, UserVO } from '@front/user/domain';
import type { DefaultFunction } from '@front/type/Function';
import { userApi } from '@front/user/api';
import departmentTreeApi from '@front/department_tree/api';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import SelectedUserList from '@front/components/UserSelector/SelectedUserList';
import UserList from '@front/components/UserSelector/UserList';
import DepartmentTree from '@front/components/UserSelector/DepartmentTree';
import Progress from '@front/components/Progress';
import ModalLayout, { type ModalPositionType } from '@front/layouts/ModalLayout';
import { isMobileDevice } from '@front/util/PwaUtil';

interface Props {
  open: boolean;
  multi: boolean | undefined;
  title?: string;
  value?: UserId | UserId[];
  departmentId?: number;
  onClose: DefaultFunction;
  onChange: (value: UserId | UserId[] | undefined, name?: string) => void;
  addOnly?: boolean;
  include?: UserId[];
  exclude?: UserId[];
  menuIdList?: number[];
  typeList?: AuthorizationType[];
  position?: ModalPositionType;
}

export default function UserSelectorModal({
  menuIdList = [],
  typeList = [],
  position,
  ...props
}: Props) {
  const [userList, setUserList] = useState<UserVO[] | undefined>(undefined);
  const [selectedUserList, setSelectedUserList] = useState<UserVO[] | undefined>(undefined);
  const [departmentList, setDepartmentList] = useState<ListDepartment[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const search = useCallback(
    (values) => {
      setLoading(true);
      userApi
        .getListByDepartmentId({
          keyword: values.keyword,
          departmentId: values.departmentId,
          menuIdList: menuIdList.join(','),
          typeList: typeList.join(','),
        })
        .then((values) => {
          if (props.exclude && props.exclude.length > 0) {
            const result = values.filter((user) => !props.exclude?.includes(user.id));
            setUserList(result);
          } else if (props.include && props.include.length > 0) {
            const result = values.filter((user) => props.include?.includes(user.id));
            setUserList(result);
          } else {
            setUserList(values);
          }
          setTimeout(() => {
            setLoading(false);
          }, 200);
        });
    },
    [props.exclude, props.include]
  );

  const getSelectedUserList = useCallback(
    (query: UserId[], callback: (selectedUsers: UserVO[]) => void) => {
      userApi
        .getListByDepartmentId({
          keyword: '',
          menuIdList: '',
          typeList: '',
        })
        .then(callback);
    },
    []
  );

  const excludeSelectedUserList = useCallback(
    (user: UserVO) => {
      if (selectedUserList) {
        setSelectedUserList((prev) => {
          if (typeof prev === 'undefined') {
            return;
          }
          return [...prev.filter((value) => value.id !== user.id)];
        });
      }
    },
    [selectedUserList]
  );

  const formik = useFormik({
    initialValues: {
      keyword: '',
      departmentId: props?.departmentId,
    },
    onSubmit: (values) => {
      search(values);
    },
  });

  const handleKeywordChange = useCallback(
    (e) => {
      const value = e.target.value || undefined;
      if (formik.values.keyword !== value) {
        formik.setFieldValue('departmentId', undefined);
        formik.setFieldValue('keyword', value);
      }
    },
    [formik]
  );

  useEffect(() => {
    if (props.open) {
      departmentTreeApi.getAll().then(setDepartmentList);
      getSelectedUserList([], (selectedUsers) => {
        if (Array.isArray(props?.value)) {
          const compArr: UserId[] = props.value;
          setSelectedUserList([...selectedUsers.filter((user) => compArr.includes(user.id))]);
        } else {
          setSelectedUserList([...selectedUsers.filter((value1) => value1.id === props?.value)]);
        }
      });
      search({
        keyword: '',
        departmentId: props.departmentId,
        menuIdList: '',
        typeList: '',
      });
    } else {
      formik.resetForm();
      setSelectedUserList(undefined);
      setDepartmentList(undefined);
      setUserList(undefined);
    }
  }, [props.open, props.departmentId]);

  useEffect(() => {
    formik.setFieldValue('departmentId', props.departmentId);
  }, [props.departmentId]);

  return (
    <>
      {props.open && (
        <ModalLayout
          width={isMobileDevice() ? '100dvw' : '50dvw'}
          height={isMobileDevice() ? '100dvh' : '50dvh'}
          open={props.open}
          title={props.title ?? '유저 선택'}
          onClose={props.onClose}
          position={position}
          children={
            <FormikProvider value={formik}>
              <Progress
                loading={loading}
                sx={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  minWidth: '900px',
                  flexWrap: 'wrap',
                  height: isMobileDevice() ? '100dvh' : 'calc(50dvh - 120px)',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: isMobileDevice() ? '40%' : '100%',
                    flexWrap: 'nowrap',
                    padding: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      width: isMobileDevice() ? '100dvw' : `${props.addOnly ? '83%' : '70%'}`,
                      marginRight: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <DataFieldWithLabel label="검색어">
                      <Input
                        key={formik.values.keyword}
                        variant="standard"
                        defaultValue={formik.values.keyword ?? ''}
                        placeholder="전체 부서 사용자 대상 검색"
                        onBlur={handleKeywordChange}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === 'enter') {
                            const value = (e.target as HTMLInputElement).value ?? undefined;
                            formik.setFieldValue('departmentId', undefined);
                            formik.setFieldValue('keyword', value);
                            formik.handleSubmit();
                          }
                        }}
                      />
                    </DataFieldWithLabel>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      width: 'calc(30% - 10px)',
                    }}
                  >
                    <Button
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      검색
                    </Button>
                    <Button
                      disabled={!formik.values.keyword && !formik.values.departmentId}
                      shape="basic2"
                      onClick={() => {
                        formik.setFieldValue('keyword', undefined);
                        formik.setFieldValue('departmentId', undefined);
                        formik.handleSubmit();
                      }}
                    >
                      초기화
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    // height: 'calc(100% - 40px)',
                    flexWrap: isMobileDevice() ? 'noWrap' : 'wrap',
                    flexDirection: isMobileDevice() ? 'column' : 'row',
                    alignItems: isMobileDevice() ? 'center' : '',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '40%',
                      height: isMobileDevice() ? 'auto' : '100%',
                      margin: '0 0.5%',
                      flexWrap: 'wrap',
                      border: '1px solid #e4e9f2',
                      borderRadius: '5px',
                    }}
                  >
                    <DepartmentTree departmentList={departmentList} />
                  </Box>
                  <Box
                    className="scroll-bar-holder"
                    sx={{
                      display: 'flex',
                      width: `${props.addOnly ? '53%' : '40%'}`,
                      height: '100%',
                      margin: '0 0.5%',
                      overflowY: 'auto',
                      flexWrap: 'wrap',
                      border: '1px solid #e4e9f2',
                      borderRadius: '5px',
                      alignItems: 'baseline',
                      alignContent: 'start',
                    }}
                  >
                    <UserList
                      multi={props.multi}
                      userList={userList}
                      selectedUserList={selectedUserList}
                      onChange={(user, checked) => {
                        if (props.multi) {
                          setSelectedUserList((prev) => {
                            if (typeof prev === 'undefined') {
                              return;
                            }
                            if (checked) {
                              return [...prev.filter((value) => value.id !== user.id)];
                            } else {
                              return [...prev, user];
                            }
                          });
                        } else {
                          setSelectedUserList([user]);
                        }
                      }}
                    />
                  </Box>
                  {!props.addOnly && (
                    <Box
                      className="scroll-bar-holder"
                      sx={{
                        display: 'flex',
                        width: isMobileDevice() ? '40%' : '13%',
                        height: '100%',
                        margin: '0 0.5%',
                        overflowY: 'auto',
                        flexWrap: 'wrap',
                        border: '1px solid #e4e9f2',
                        borderRadius: '5px',
                        alignItems: 'baseline',
                        alignContent: 'start',
                      }}
                    >
                      <SelectedUserList
                        userList={selectedUserList}
                        onUserClick={excludeSelectedUserList}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormikProvider>
          }
          footer={
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                shape="basic2"
                onClick={props.onClose}
                sx={{
                  marginRight: '10px',
                }}
              >
                취소
              </Button>
              <Button
                onClick={() => {
                  props.onClose();
                  if (props.multi) {
                    const result: UserId[] = [];
                    selectedUserList?.forEach((user) => {
                      result.push(user.id);
                    });
                    props.onChange(result);
                  } else {
                    props.onChange(
                      selectedUserList && selectedUserList.length > 0
                        ? selectedUserList[0].id
                        : undefined,
                      selectedUserList && selectedUserList[0]?.name
                    );
                  }
                }}
              >
                저장
              </Button>
            </Box>
          }
        />
      )}
    </>
  );
}
