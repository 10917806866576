import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useProjectSalesInfoContractHistoryLogic } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/useLogic';
import { ProjectSalesInfoContractHistoryModal } from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-modal';
import { ProjectSalesInfoContractHistoryContext } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/context';
import { ProjectSalesInfoContractHistoryButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-button-group';
import { ProjectSalesInfoContractHistoryTable } from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-table';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoContractHistoryState } from '@front/src/features/project-sales-info/features/contract/features/history/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoContractHistoryWidget = ({ readOnly, name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoContractHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
    }),
    [list, onDelete]
  );
  const { setReadOnly } = useProjectSalesInfoContractHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return <LoadingSpinner height="116px" />;
  }
  return (
    <Layout>
      <ProjectSalesInfoContractHistoryModal />
      <ProjectSalesInfoContractHistoryContext.Provider value={value}>
        <Header title={name}>
          <ProjectSalesInfoContractHistoryButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoContractHistoryTable />
        </Body>
      </ProjectSalesInfoContractHistoryContext.Provider>
    </Layout>
  );
};

export default ProjectSalesInfoContractHistoryWidget;
