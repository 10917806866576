import type { ReactNode } from 'react';
import React from 'react';

import classes from '@front/src/components/layout/drawer-search-form/layout.module.scss';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => <div className={classes.container}>{children}</div>;

export default Layout;
