import React, { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { PersonalSettingsModalUISettingsHeaderSettingsSubmitSection } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/SubmitSection';
import { PersonalSettingsModalUISettingsHeaderSettingsTable } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/UISettingsTable';
import { FormProvider, useForm } from 'react-hook-form';
import { personalSettingsModalUISettingsHeaderSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/repository/repository';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { PersonalSettingsModalUISettingsHeaderSettingsListDrawer } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/ListDrawer';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { useShallow } from 'zustand/react/shallow';

export { Form as PersonalSettingsModalUISettingsHeaderSettingsForm };
interface Props {
  authMenuId: number | undefined;
  onClose: () => void;
}
const Form = ({ authMenuId, onClose }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useGetHeaderList(authMenuId);
  const { setIsDirty } = useCancelBlockModalState(
    useShallow((state) => ({
      setIsDirty: state.setIsDirty,
    }))
  );
  const methods = useForm({
    values: {
      list: list?.map((item) => ({
        id: item.id,
        categoryId: item.categoryId,
        note: item.note ?? '',
        isFixed: item.isFixed,
        isBlind: item.isBlind,
        headerId: item.headerId,
        currentName: item.name ?? '',
        version: item.version,
        headerVersion: item.headerVersion,
        use: item.use,
        newName: '',
        isPassed: true,
        isDuplicate: false,
      })),
    },
  });
  const {
    formState: { isDirty },
  } = methods;
  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);
  if (list === undefined) {
    return <LoadingSpinner height="40dvh" />;
  }
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <PersonalSettingsModalUISettingsHeaderSettingsSubmitSection
          authMenuId={authMenuId}
          onClose={onClose}
        />
        <Divider />
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
          }}
        >
          <PersonalSettingsModalUISettingsHeaderSettingsTable />
          <PersonalSettingsModalUISettingsHeaderSettingsListDrawer authMenuId={authMenuId} />
        </Box>
      </Box>
    </FormProvider>
  );
};
