import React from 'react';
import { Box } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import type {
  ProposalContentAddParameter,
  ProposalContentChangeParameter,
} from '@front/ost_proposal/parameter';
import type { RootState } from '@front/services/reducer';
import type { ProposalContentId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import ContentList from '@front/ost_proposal/view/detail/content/ContentList';
import { ContentButtonGroup } from '@front/ost_proposal/view/detail/content/ContentButtonGroup';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  addContent: (params: ProposalContentAddParameter) => () => void;
  deleteContent: (id: ProposalContentId) => void;
  updateContent: (params: ProposalContentChangeParameter) => void;
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  isContributor: boolean;
}

function ContentLayout({
  openSnackbar,
  deleteContent,
  updateContent,
  addContent,
  isContributor,
}: Props) {
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        padding: '10px',
      }}
    >
      <ArticleItem
        title="내용"
        children={
          <ContentList
            deleteContent={deleteContent}
            updateContent={updateContent}
            openSnackbar={openSnackbar}
            isContributor={isContributor}
          />
        }
        buttonsNode={
          <>
            {status === ProposalStatus.WRITING && isContributor && (
              <ContentButtonGroup addContent={addContent} />
            )}
          </>
        }
      />
    </Box>
  );
}

export default ContentLayout;
