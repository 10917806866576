import React, { useCallback } from 'react';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';
import ModalLayout from '@front/layouts/ModalLayout';
import { Box } from '@mui/material';
import { AffiliatedPersonAppBarCompanySelectModalForm } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/components/form';
import { AffiliatedPersonAppBarCompanySelectModalTable } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/components/modal-table';
import { AffiliatedPersonAppBarCompanySelectModalProvider } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/provider/provider';
import { AffiliatedPersonCompanySelectModalListWidget } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/widget';

export { Index as AffiliatedPersonAppBarCompanySelectModalFeature };

interface Props {
  onCompanySelect: (company: AffiliatedPersonCompanyView) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  menuId?: number;
}

const Index = ({ onCompanySelect, isOpen, setIsOpen, menuId }: Props) => {
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      title="소속회사 검색"
      open={isOpen}
      width="32dvw"
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
          minHeight: '40vh',
        }}
      >
        <AffiliatedPersonAppBarCompanySelectModalForm />
        <AffiliatedPersonAppBarCompanySelectModalProvider
          onCompanySelect={onCompanySelect}
          setIsOpen={setIsOpen}
        >
          <AffiliatedPersonAppBarCompanySelectModalTable
            widget={<AffiliatedPersonCompanySelectModalListWidget menuId={menuId} />}
          />
        </AffiliatedPersonAppBarCompanySelectModalProvider>
      </Box>
    </ModalLayout>
  );
};
