import React from 'react';
import { Box, Typography } from '@mui/material';
import { Select } from '@front/src/components/Select';
import { hasVatOptionList } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/utils/constants';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContractEarningsSurtax as ProjectSalesInfoCollectionContractEarningsSurtax };

const ContractEarningsSurtax = () => {
  const { hasVat, setHasVat } = useContractEarningsItemState(
    useShallow((state) => ({
      hasVat: state.hasVat,
      setHasVat: state.setHasVat,
    }))
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Typography
        fontSize="14px"
        fontWeight="bold"
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        부가세
      </Typography>
      <Select
        value={hasVat}
        optionList={hasVatOptionList}
        width="100px"
        onChange={(e) => setHasVat(e.target.value)}
      />
    </Box>
  );
};
