import { ArticleItemLayout } from '@front/src/components/layout/article-item-view/article-item-layout';
import { ArticleItemTitle } from '@front/src/components/layout/article-item-view/article-item-title';
import { ArticleItemBody } from '@front/src/components/layout/article-item-view/article-item-body';
import { ArticleItemButton } from '@front/src/components/layout/article-item-view/article-item-button';
import { ArticleItemHeader } from '@front/src/components/layout/article-item-view/article-item-header';

export const ArticleItemView = {
  Layout: ArticleItemLayout,
  Title: ArticleItemTitle,
  Body: ArticleItemBody,
  Button: ArticleItemButton,
  Header: ArticleItemHeader,
};
