import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import { Th } from '@front/src/components/layout/table/th';
import { AffiliatedCompanyOutlineWebHardTableRow } from '@front/src/features/affiliated-company/features/outline/features/web-hard/components/web-hard-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { AffiliatedCompanyOutlineWebHardContext } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/context';

export { WebHardTable as AffiliatedCompanyOutlineWebHardTable };
const WebHardTable = () => {
  const {
    d: { webHardList },
  } = useContext(AffiliatedCompanyOutlineWebHardContext);
  if (webHardList?.length === 0) {
    return <NoResultBox message="저장된 웹하드가 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          {webHardList?.map((item) => (
            <AffiliatedCompanyOutlineWebHardTableRow
              key={item.id}
              item={item}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="20%">웹하드 이름(용도)</Th>
      <Th width="60%">접속주소</Th>
      <Th width="20%">비고</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'AffiliatedCompanyOutlineWebHardTableHeader';
