import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkWorkerAddTop = (params: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workWorkerMutation.useAddTop(params);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkWorkerAddTop;
