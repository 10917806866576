// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ko8IVzc1h4GxGobVwVi4{display:flex;width:100%;flex-direction:column;border:1px solid #e4e9f2;border-radius:5px}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/article-item-view/article-item-layout.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,UAAA,CACA,qBAAA,CACA,wBAAA,CACA,iBAAA","sourcesContent":["@import '@front/assets/colors.scss';\r\n\r\n.container {\r\n  display: flex;\r\n  width: 100%;\r\n  flex-direction: column;\r\n  border: 1px solid $_e4e9f2;\r\n  border-radius: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ko8IVzc1h4GxGobVwVi4`
};
export default ___CSS_LOADER_EXPORT___;
