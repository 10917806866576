import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectContractReviewView } from '@front/src/features/project-sales-info/features/contract/features/reivew/types/view';
import type { ProjectSalesInfoContractReviewParameter } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/types/parameter';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';

export { context as ProjectSalesInfoContractReviewModalContext };

interface State {
  d: {
    detail?: ProjectContractReviewView;
    categoryList?: VariableInformationView[];
    companyList?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoContractReviewParameter) => void;
    onUpdate: (params: ProjectSalesInfoContractReviewParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
    onUpdate: noOp,
    onClose: noOp,
  },
});
