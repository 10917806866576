import axios from 'axios';
import type { ProjectExperimentInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/parameter';
import { projectSalesInfoAspectRatioExaminationUrl } from '@front/src/features/project-sales-info/features/subject-review/features/modal/utils/constant';
import { toFormData } from '@front/services/api';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  update: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/experiment-information/${id}`,
  getDetail: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/${id}/experiment-information`,
  delete: (id?) => `${projectSalesInfoAspectRatioExaminationUrl}/experiment-information/${id}`,
};

export const projectSalesInfoSubjectReviewModalApi = {
  getDetail: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params: ProjectExperimentInformationParameter, menuId) => {
    await axios.put(
      url.update(params.id),
      toFormData({
        ...params,
        file: params.file,
      }),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
  delete: async (id: number, menuId) => {
    await axios.delete(url.delete(id), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
