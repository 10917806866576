import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoEstimationConfirmedWidget from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/widget';
import ProjectSalesInfoEstimationEstimationWidget from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/widget';
import ProjectSalesInfoEstimationCompetitorWidget from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/widget';
import ProjectSalesInfoEstimationComparedWidget from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/widget';

export { sectionComponent as projectSalesInfoEstimationSectionComponent };

const sectionComponent: SectionComponent = {
  25: ({ ...rest }) => <ProjectSalesInfoEstimationConfirmedWidget {...rest} />,
  26: ({ ...rest }) => <ProjectSalesInfoEstimationEstimationWidget {...rest} />,
  27: ({ ...rest }) => <ProjectSalesInfoEstimationCompetitorWidget {...rest} />,
  28: ({ ...rest }) => <ProjectSalesInfoEstimationComparedWidget {...rest} />,
};
