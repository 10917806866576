import { useQuery } from 'react-query';
import { projectSelectorModalApi } from '@front/src/features/project-selector-modal/query/api';
import type { ProjectListGetParameter } from '@front/src/features/project-selector-modal/types/parameter';
import type { ProjectShortView } from '@front/src/features/project-selector-modal/types/view';

export const projectSelectorModalQuery = {
  useProjectListGet: (params: ProjectListGetParameter) => {
    const { data, isLoading } = useQuery<ProjectShortView[]>({
      queryFn: () => projectSelectorModalApi.getProjectList(params),
      queryKey: ['project', 'list', params],
      onSuccess: () => {},
      onError: () => {},
    });
    return {
      data,
      isLoading,
    };
  },
};
