import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AddressModal } from '@front/components/AddressModal/AddressModal';
import { AffiliatedCompanyOutlineAddressButtonGroup } from '@front/src/features/affiliated-company/features/outline/features/address/components/button-group';
import { AffiliatedCompanyOutlineAddressTable } from '@front/src/features/affiliated-company/features/outline/features/address/components/address-table';
import { useAffiliatedCompanyOutlineAddressLogic } from '@front/src/features/affiliated-company/features/outline/features/address/widgets/useLogic';
import { AffiliatedCompanyOutlineAddressContext } from '@front/src/features/affiliated-company/features/outline/features/address/widgets/context';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedCompanyOutlineAddressWidget = ({
  name,
  menuId,
}: Readonly<SectionComponentProps>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { addressList, addressId },
    h: {
      onCreateAddress,
      setIdList,
      onDeleteAddress,
      onUpdateAddress,
      openAddressModal,
      setAddressId,
    },
    idList,
    isLoading,
  } = useAffiliatedCompanyOutlineAddressLogic(menuId);
  const value = useMemo(
    () => ({
      d: { addressList },
      h: { onCreateAddress, setIdList, onDeleteAddress, openAddressModal, setAddressId },
      isLoading,
      idList,
    }),
    [
      addressList,
      isLoading,
      onCreateAddress,
      setIdList,
      idList,
      onDeleteAddress,
      openAddressModal,
      setAddressId,
    ]
  );
  return (
    <AffiliatedCompanyOutlineAddressContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyOutlineAddressButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyOutlineAddressTable />
        </Body>
        <AddressModal updateByDispatch={{ onUpdate: onUpdateAddress, id: addressId }} />
      </Layout>
    </AffiliatedCompanyOutlineAddressContext.Provider>
  );
};

export default AffiliatedCompanyOutlineAddressWidget;
