import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoAbstractProjectInfoDetailContext } from '@front/src/features/project-sales-info/features/abstract/features/project-info/widgets/detail/provider/context';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { getValueIfExist, getValueToYNIfExist } from '@front/src/utils';

export { Detail as ProjectSalesInfoAbstractProjectInfoDetail };

const Detail = () => {
  const { detail } = useContext(ProjectSalesInfoAbstractProjectInfoDetailContext);
  if (!detail) return <NoResult colSpan={7} />;
  return (
    <TableRow>
      <OldTd>{getValueIfExist(detail.experimentInfo)}</OldTd>
      <OldTd>{getValueIfExist(detail.businessType)}</OldTd>
      <OldTd>{getValueIfExist(detail.buildPurpose)}</OldTd>
      <OldTd>{getValueToYNIfExist(detail.isTargetOfSafetyEvaluation)}</OldTd>
      <OldTd>{getValueIfExist(detail.bidType)}</OldTd>
      <OldTd>{getValueIfExist(detail.orderIntakeDate)}</OldTd>
      <OldTd>{getValueIfExist(detail.workStartDate)}</OldTd>
    </TableRow>
  );
};
