import React, { useCallback } from 'react';
import type { ProjectReviewAmountPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/view';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { useShallow } from 'zustand/react/shallow';

export { PaymentHistoryTableRow as ProjectSalesInfoCollectionPaymentHistoryTableRow };

interface Props {
  item: ProjectReviewAmountPaymentHistoryView;
  index: number;
}

const PaymentHistoryTableRow = ({ item, index }: Props) => {
  const { setItemId, setIsUpdateModalOpen, idList, setIdList } =
    useProjectSalesInfoPaymentHistoryState((state) => ({
      setItemId: state.setItemId,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      idList: state.idList,
      setIdList: state.setIdList,
    }));
  const { readOnly } = useProjectSalesInfoPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const onClick = () => {
    setItemId(item.id);
    setIsUpdateModalOpen();
  };
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  return (
    <TableRow
      onClick={onClick}
      hover={true}
      sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(item.id)}
          onChange={handleCheckboxChange(item.id)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>{item?.structureCompany?.name ?? '-'}</OldTd>
      <OldTd>{item?.paymentRequestDate ?? '-'}</OldTd>
      <OldTd>{item?.taxInvoiceIssuedDate ?? '-'}</OldTd>
      <OldTd>{item?.paymentAmount?.toLocaleString() ?? '-'}</OldTd>
      <OldTd>{item?.paymentType ?? '-'}</OldTd>
      <OldTd>{item?.paymentDate ?? '-'}</OldTd>
      <OldTd>
        <div
          style={{
            maxWidth: '300px',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {item.note ?? '-'}
        </div>
      </OldTd>
    </TableRow>
  );
};
