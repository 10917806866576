import { useCallback, useEffect } from 'react';
import type { DefaultFunction } from '@front/src/types';
import type { ProjectShortView } from '@front/src/features/project-selector-modal/types/view';
import {
  defaultParameter,
  useProjectSelectorModalFormState,
} from '@front/src/features/project-selector-modal/widgets/body/useState';
import { shallow } from 'zustand/shallow';

export { useLogic as useProjectSelectorModalLogic };

interface Props {
  onSelect: (project: ProjectShortView) => void;
  onClose: DefaultFunction;
  isOpen: boolean;
}

const useLogic = ({ onSelect, onClose, isOpen }: Props) => {
  const { setParameter } = useProjectSelectorModalFormState((state) => state, shallow);
  const handleSelect = useCallback(
    (project) => {
      onSelect(project);
      onClose();
    },
    [onSelect, onClose]
  );
  const h = {
    onProjectSelect: handleSelect,
  };
  useEffect(
    () => () => {
      setParameter(defaultParameter);
    },
    [isOpen]
  );
  return {
    h,
  };
};
