import React from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/components/layout/layout.module.scss';

interface Props {
  children: React.ReactNode;
  width?: string;
  padding?: string;
}

export const Layout = ({ children, width, padding }: Props) => (
  <div
    className={classes.layout}
    style={{ width: width, padding: padding }}
  >
    {children}
  </div>
);
