import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkDetailTable from '@front/src/features/work/features/work/features/detail/components/Table';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

import WorkDetailTitleRight from '@front/src/features/work/features/work/features/detail/components/TitleRight';

export default function WorkWorkDetailFeature(props: Readonly<SectionComponentProps>) {
  const { name } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <Suspense fallback={<LoadingSpinnerUI />}>
        <TitleWrap
          left={name}
          right={<WorkDetailTitleRight {...props} />}
        />
        <WorkDetailTable {...props} />
      </Suspense>
    </Layout>
  );
}
