import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { projectSalesInfoPaymentHistoryUpdateModalApi } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/query/api';

export const projectSalesInfoPaymentHistoryUpdateModalMutation = {
  usePaymentHistoryDetailUpdate: (id: number, menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectReviewAmountPaymentHistoryParams) =>
        projectSalesInfoPaymentHistoryUpdateModalApi.updatePaymentHistoryDetail(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'project-sales-info',
          'collection',
          'payment-history',
          id,
        ]);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
