import React from 'react';

import AffiliatedCompanyPersonDetailModalGiftHistoryWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/widgets/widget';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedPersonGiftHistoryFeature = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={props.name} />
      <Body>
        <AffiliatedCompanyPersonDetailModalGiftHistoryWidget {...props} />
      </Body>
    </Layout>
  );
};

export default AffiliatedPersonGiftHistoryFeature;
