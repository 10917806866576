import { useForm } from 'react-hook-form';

import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import { useSnackbar } from 'notistack';

const useManagementAccountInputForm = ({ colIndex, rowIndex }) => {
  const methods = useForm<ManagementAccountCellInputValues>({
    defaultValues: {
      id: undefined,
      name: '',
      sequence: rowIndex,
      parentId: null,
      depth: colIndex,
    },
  });

  const { reset, getValues } = methods;

  const { enqueueSnackbar } = useSnackbar();
  const onInvalid = (errors) => {
    if (errors.name) {
      enqueueSnackbar(errors.name.message, { variant: 'error' });
    }
  };

  const initialize = (mode: 'create' | 'update', cellData?: ManagementAccountingTreeView) => {
    if (mode === 'update' && cellData) {
      const currentValues = getValues();

      reset({
        ...currentValues,
        id: cellData.id,
        name: cellData.name,
      });
    }
  };

  return {
    methods,
    initialize,
    onInvalid,
  };
};

export default useManagementAccountInputForm;

export interface ManagementAccountCellInputValues {
  id?: number | undefined;
  name: string;
  sequence: number;
  parentId: number | null;
  depth: number;
}
