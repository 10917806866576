import { affiliatedCompanyPersonEducationRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { useAffiliatedCompanyPersonDetailModalOutlineEducationState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineEducationLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
    }))
  );
  const { setIdList, setId } = useAffiliatedCompanyPersonDetailModalOutlineEducationState(
    useShallow((state) => ({
      setIdList: state.setIdList,
      setId: state.setId,
    }))
  );
  const { run: onCreate } = affiliatedCompanyPersonEducationRepository.useCreate(menuId);
  const { data: list, remove } = affiliatedCompanyPersonEducationRepository.useListGet(
    modalId,
    menuId
  );
  const { run: onUpdate } = affiliatedCompanyPersonEducationRepository.useUpdate(menuId);
  const { run: onDelete, setCallback: setDeleteCallback } =
    affiliatedCompanyPersonEducationRepository.useDelete(menuId);
  setDeleteCallback({
    onSuccess: () => {
      setIdList([]);
    },
  });
  useEffect(
    () => () => {
      remove();
      setId(undefined);
    },
    [remove, setId]
  );
  return { onCreate, list, onUpdate, onDelete };
};
