import React from 'react';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import WorkOutputTableBodyRow from '@front/src/features/work/features/work/features/output/components/TableRow';
import workOutputQuery from '@front/src/features/work/features/work/features/output/query/query';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';

export default function WorkOutputTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { outputOptions, dataId, menuId, sectionId } = props;
  const { data: list } = workOutputQuery.useGetList({
    bundleId: outputOptions?.bundleId,
    dataId,
    menuId,
    sectionId,
  });
  return (
    <UIBuilderTableBody<WorkOutputView>
      {...props}
      list={list}
      RowComponent={WorkOutputTableBodyRow}
    />
  );
}
