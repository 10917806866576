import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { ListDrawer as ListDrawer };

interface Props {
  openedWidth?: number;
  closedWidth?: number;
  open: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  closedChildren?: ReactNode;
  isVertical?: boolean;
  openedHeight?: string;
  closedHeight?: string;
  overlay?: boolean;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
}

const ListDrawer = ({
  openedWidth,
  closedWidth,
  open,
  children,
  sx,
  closedChildren,
  isVertical,
  openedHeight,
  closedHeight,
  overlay,
  anchor,
}: Props) => {
  const AppDrawer = useMemo(
    () =>
      styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        '& .MuiDrawer-paper': {
          zIndex: theme.zIndex.drawer - 1,
          padding: 0,
          position: overlay ? 'absolute' : 'relative',
          whiteSpace: 'nowrap',
          border: 'none',
          width: `${openedWidth ?? 330}px`,
          height: `${openedHeight ?? '100dvh'}`,
          backgroundColor: ColorPalette._f1f5fc,
          overflowY: 'hidden',
          transition: theme.transitions.create(isVertical ? 'height' : 'width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: 'border-box',
          ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create(isVertical ? 'height' : 'width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: `${closedWidth ?? 0}px`,
            height: `${closedHeight ?? '100dvh'}`,
          }),
          ...(sx ?? {}),
        },
      })),
    [openedWidth, closedWidth, openedHeight, closedHeight, isVertical, overlay, sx]
  );
  return (
    <AppDrawer
      variant="permanent"
      open={open}
      sx={sx}
      anchor={anchor}
    >
      {open ? <>{children}</> : <>{closedChildren}</>}
    </AppDrawer>
  );
};
