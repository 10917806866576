import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import useEstimationHistoryTableRowForm from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';
import estimationHistoryMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/mutation';
import type { EstimationHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/parameter';

export { TableBody as EstimationHistoryTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationHistoryView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useEstimationHistoryTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<EstimationHistoryUpdateParameter>({
      onAdd: estimationHistoryMutation.useAdd(commonParams),
      onUpdateSeq: estimationHistoryMutation.useUpdateSeq(commonParams),
      onDelete: estimationHistoryMutation.useDelete(commonParams),
      onUpdate: estimationHistoryMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', dataId, 'estimation', 'history'],
    });

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    commonParams,
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete,
  });

  const onSubmit = handleSubmit((data) => {
    if (!data.id) return;

    const params = {
      id: data.id,
      seq: data.seq ?? null,
      isConfirmed: data.isConfirmed === '' ? null : data.isConfirmed === 'Y',
      estimationDate: data.estimationDate ?? null,
      type: data.type || null,
      status: data.status || null,
      possibilityOfOrder: data.possibilityOfOrder || null,
      projectDifficulty: data.projectDifficulty || null,
      modelDifficulty: data.modelDifficulty || null,
      estimationRequestCompanyId: data.estimationRequestCompany?.id ?? null,
      estimationRequestCompanyPersonId: data.estimationRequestCompanyPerson?.id ?? null,
      nextContactDateWithRequestCompany: data.nextContactDateWithRequestCompany ?? null,

      numberAttr1: data.numberAttr1 ?? null,
      numberAttr2: data.numberAttr2 ?? null,
      numberAttr3: data.numberAttr3 ?? null,
      textAttr1: data.textAttr1 ?? null,
      textAttr2: data.textAttr2 ?? null,
      textAttr3: data.textAttr3 ?? null,
      dateAttr1: data.dateAttr1 ?? null,
      dateAttr2: data.dateAttr2 ?? null,
      dateAttr3: data.dateAttr3 ?? null,
    };

    onUpdate(params);
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
      />
    </FormProvider>
  );
}
