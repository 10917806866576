import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Accordion, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

export { SettingsAccordion as PersonalSettingsModalAccordion };

const SettingsAccordion = () => (
  <Accordion
    sx={{
      backgroundColor: ColorPalette._57EF04FF,
      color: ColorPalette._ffffff,
    }}
  >
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{
            color: ColorPalette._ffffff,
          }}
        />
      }
      aria-controls="panel1-content"
      id="panel1-header"
    >
      ※ 부서 권한 안내 - V : 접근 가능 권한 / R : 접근 + 열람 권한 / W : 접근 + 열람 + 쓰기 권한
      (등록/수정/삭제) / X : 접근 불가 (Forbidden){' '}
    </AccordionSummary>
    <AccordionDetails>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
        >
          <Typography>※ 부서 권한은 탭 기준으로 접근권한을 설정합니다.</Typography>
          <Typography>
            ※ 부서는 개별로 권한이 설정 됩니다. (상위 부서 권한을 따라가지 않음)
          </Typography>
        </Box>
        <Box>
          <Typography>※ 권한 처리 우선 순위 - 개인 권한 {'>'} 부서 권한</Typography>
          <Typography sx={{ marginTop: '15px' }}>
            ex) 부서 권한은 W이고 해당 부서 소속 인물의 개인 권한이 R인 경우,
          </Typography>
          <Typography>해당 인물의 최종 권한은 R이 됩니다.</Typography>
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
);
