import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { ColorPalette } from '@front/assets/theme';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open: boolean;
}

export default function ModalLoadingSpinnerUI({ open }: Props) {
  return (
    <Backdrop
      open={open}
      style={{
        zIndex: '3',
        position: 'absolute',
        background: ColorPalette.etc.loading_spinner,
      }}
    >
      <CircularProgress
        style={{
          width: '40px',
          height: '40px',
          strokeLinecap: 'round',
          strokeWidth: 4,
          color: ColorPalette.greyscale.white,
        }}
      />
    </Backdrop>
  );
}
