import React, { useCallback, useContext } from 'react';
import { TableCell, TableRow } from '@mui/material';

import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyPersonOutlineExtraCompanyView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/view';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/context';
import TableSideHeaderWithCheckbox from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

interface Props {
  item: AffiliatedCompanyPersonOutlineExtraCompanyView;
  index: number;
}

export { AddressTableRow as AffiliatedCompanyPersonDetailModalOutlineExtraCompanyTableRow };
const AddressTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext);
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <TableSideHeaderWithCheckbox
        title="소속회사"
        index={index}
        idList={idList}
        item={item}
        handleCheckboxChange={handleCheckboxChange}
        disabled={readOnly}
      />
      <TableCell>{item.name}</TableCell>
    </TableRow>
  );
};
