import React, { useContext } from 'react';
import { MuiTableVirtualizer } from '@front/src/components/virtualizer-with-mui';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListItemWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/widgets/widget';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/widgets/context';
import { useProjectSalesInfoSubjectReviewModalComplexInformationState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/useState';
import { columns } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/constants';

export { ComplexInformationTable as ProjectSalesInfoSubjectReviewModalComplexInformationTable };
interface Props {
  menuId?: number;
}
const ComplexInformationTable = ({ menuId }: Props) => {
  const { list, isLoading } = useContext(
    ProjectSalesInfoSubjectReviewModalComplexInformationListContext
  );
  const setId = useProjectSalesInfoSubjectReviewModalComplexInformationState(
    (state) => state.setId
  );
  const isListLoading = typeof list === 'undefined' || isLoading;
  const isListEmpty = !isListLoading && list?.length === 0;
  const height = 62 + (list?.length ?? 1) * 50;
  return (
    <div style={{ height: isListLoading || isListEmpty ? '130px' : height, maxHeight: '400px' }}>
      <MuiTableVirtualizer
        columns={columns}
        RowComponent={ProjectSalesInfoSubjectReviewModalComplexInformationListItemWidget}
        colSpan={9}
        setId={setId}
        list={list}
        isTableFit={true}
        isListEmpty={isListEmpty}
        isListLoading={isListLoading}
        menuId={menuId}
      />
    </div>
  );
};
