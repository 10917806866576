import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkReviewerParams } from '@front/src/features/work/features/work/features/reviewer/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workReviewerMutation = {
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.createReviewer(id, commonParams),
      mutationKey: ['work', 'reviewer', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddTop: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addTopReviewer(commonParams),
      mutationKey: ['work', 'reviewer', 'add-top'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddBottom: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addBottomReviewer(commonParams),
      mutationKey: ['work', 'reviewer', 'add-bottom'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkReviewerParams) =>
        workWorkApi.updateReviewer({ ...params, ...commonParams }, commonParams),
      mutationKey: ['work', 'reviewer', 'update'],
      onMutate: async (data: WorkReviewerParams) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'reviewer']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.userId === (prevData?.user?.id ?? null) && newData.note === prevData?.note;
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqUp: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReviewerSeqUp(id, commonParams),
      mutationKey: ['work', 'reviewer', 'seq-up'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqDown: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateReviewerSeqDown(id, commonParams),
      mutationKey: ['work', 'reviewer', 'seq-down'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteReviewer(id, commonParams),
      mutationKey: ['work', 'reviewer', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDeleteAll: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.deleteAllReviewer(commonParams),
      mutationKey: ['work', 'reviewer', 'delete-all'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reviewer'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'role'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workReviewerMutation;
