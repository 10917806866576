import React from 'react';
import useWorkScheduleGetIsLoading from '@front/src/features/work/features/work/features/schedule/hooks/useGetIsLoading';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';

export default function WorkScheduleLoadingSpinner() {
  const isLoading = useWorkScheduleGetIsLoading();
  return (
    <BackdropLoadingSpinnerUI
      sx={{
        position: 'absolute',
      }}
      open={isLoading}
    />
  );
}
