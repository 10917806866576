import { personalSettingsModalUISettingsQuery } from '@front/src/features/personal-settings-modal/features/ui-settings/query/query';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { repository as personalSettingsModalUISettingsRepository };

const repository = {
  useGetSectionList: (authMenuId) => {
    const menuId = useGlobalNavBarState((state) => state.menuId);
    const { data } = personalSettingsModalUISettingsQuery.useGetSectionList(
      menuId !== null ? Number(menuId) : undefined,
      authMenuId
    );
    return {
      data,
    };
  },
};
