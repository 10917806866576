import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

interface Props {
  dataId: number | undefined;
  menuId: number | undefined;
}

const useProjectSalesAuthCheck = ({ dataId, menuId }: Props) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loginUser || !menuId) return;
    const auth = loginUser.menus?.find((menu) => menu.id === +menuId);
    const hasAccess = auth && (auth.authorization.type === 'R' || auth.authorization.type === 'W');

    if (!hasAccess) {
      navigate('/project/sales');
      enqueueSnackbar('조회 권한이 없습니다', { variant: 'error' });
    }
  }, [loginUser, menuId, dataId]);
};

export default useProjectSalesAuthCheck;
