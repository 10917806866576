// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rczx1fxUYYGDfmpC1cXA{display:flex;flex-direction:column;gap:10px;max-height:80dvh;scrollbar-gutter:auto}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/selected-part.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,gBAAA,CACA,qBAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  max-height: 80dvh;\r\n  scrollbar-gutter: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Rczx1fxUYYGDfmpC1cXA`
};
export default ___CSS_LOADER_EXPORT___;
