import workCarouselUpdateSqeMutation from '@front/src/features/work/features/work/features/carousel/features/move-seq/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

const useWorkCarouselUpdateSeq = (commonParams) => {
  const callback = getNoOpCallback();
  const { mutate } = workCarouselUpdateSqeMutation.useUpdateSeq(commonParams);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkCarouselUpdateSeq;
