import { TableRow } from '@mui/material';
import React, { useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { Th } from '@front/src/components/layout/table/th';
import classes from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/components/file.module.scss';
import { AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/widgets/context';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { downloadFile } from '@front/src/utils';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';

export { File as AffiliatedCompanyOutlineNumberTableRowBusinessRegistrationFile };

const File = () => {
  const {
    d: { businessRegistrationNumberFile },
    h: { onOutlineBusinessRegistrationFileUpdate },
  } = useContext(AffiliatedCompanyOutlineNumberBusinessRegistrationFileContext);
  const methods = useForm<AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter>({
    values: {
      businessRegistrationNumberFile,
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onOutlineBusinessRegistrationFileUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <Th>사업자등록증 파일</Th>
        <OldTd>
          {readOnly ? (
            <FileDisplayText
              fileName={businessRegistrationNumberFile?.filename ?? ''}
              onClick={downloadFile(businessRegistrationNumberFile?.id)}
              align="left"
            />
          ) : (
            <div className={classes.container}>
              <HookFormFileUploader
                name="businessRegistrationNumberFile"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </div>
          )}
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
