import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { WorkReviewerParams } from '@front/src/features/work/features/work/features/reviewer/types/parameter';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkReviewerUpdate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workReviewerMutation.useUpdate(commonParams);
  return {
    run: (params: WorkReviewerParams) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkReviewerUpdate;
