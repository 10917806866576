import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutLayout } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/layout';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPartSection } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/selected-part-section';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPartHeader } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/selected-part-header';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPartSection } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/search-part-section';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPartHeader } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/search-part-header';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectButtonPart } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/select-button-part';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPart } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/selected-part';
import { ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPart } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/search-part';

export const projectSalesInfoAffiliatedCompanyPersonCreateModalLayout = {
  Layout: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutLayout,
  SelectedPartSection: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPartSection,
  SelectedPartHeader: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPartHeader,
  SearchPartSection: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPartSection,
  SearchPartHeader: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPartHeader,
  SelectButtonPart: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectButtonPart,
  SelectedPart: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSelectedPart,
  SearchPart: ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPart,
};
