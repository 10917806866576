import React, { Suspense, useEffect } from 'react';

import { useAccountingSettingsBankAccountState } from '@front/src/features/accounting/features/settings/features/bank-account/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingSettingsBankAccountListProvider } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/provider';
import { AccountingSettingsBankAccountListHeader } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/header';
import { AccountingSettingsBankAccountListTable } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/table';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const AccountingSettingsBankAccountFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  const { setReadOnly } = useAccountingSettingsBankAccountState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Suspense fallback={<LoadingSpinner height="600px" />}>
      <AccountingSettingsBankAccountListProvider menuId={menuId}>
        <Layout>
          <Header
            title={name}
            rightComponent={<AccountingSettingsBankAccountListHeader menuId={menuId} />}
          />
          <Body>
            <AccountingSettingsBankAccountListTable menuId={menuId} />
          </Body>
        </Layout>
      </AccountingSettingsBankAccountListProvider>
    </Suspense>
  );
};

export default AccountingSettingsBankAccountFeature;
