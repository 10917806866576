import { handleError } from '@front/src/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { projectSalesInfoProjectDivisionApi } from '@front/src/features/project-sales-info/features/project-division/query/form/api';
import type { ProjectDivisionParams } from '@front/src/features/project-sales-info/features/project-division/types/parameter';

export const projectSalesInfoProjectDivisionMutation = {
  useProjectDivisionUpdate: (id: number, menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectDivisionParams) =>
        projectSalesInfoProjectDivisionApi.updateProjectDivision(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'project-division', id],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'drawer', 'search'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useCodeCreate: (id: number, menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => projectSalesInfoProjectDivisionApi.createCode(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'project-division', id],
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'drawer', 'search'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
