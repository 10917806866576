import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { AffiliatedCompanyAffiliatesSection } from '@front/src/features/project-sales/features/tabs/affiliated-company/sections/affiliates';
import { AffiliatedCompanyPersonsSection } from '@front/src/features/project-sales/features/tabs/affiliated-company/sections/persons';
import { AffiliatedCompanyWebhardSection } from '@front/src/features/project-sales/features/tabs/affiliated-company/sections/webhard';
import { AffiliatedCompanyAccountsSection } from '@front/src/features/project-sales/features/tabs/affiliated-company/sections/accounts';
import { useSectionComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useSectionComponentLifeCycleManager';
import useActiveTab from '@front/src/features/project-sales/hooks/useActiveTab';

export { Tab as AffiliatedCompanyTab };

// 객체 key는 mn_section 엔티티의 id 값
const sectionComponents = {
  106: AffiliatedCompanyAffiliatesSection,
  107: AffiliatedCompanyPersonsSection,
  108: AffiliatedCompanyWebhardSection,
  109: AffiliatedCompanyAccountsSection,
};

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]관계사
 * @constructor
 */
function Tab() {
  useSectionComponentLifeCycleManager(sectionComponents);
  const { id } = useParams<{ id: string }>();
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);

  return (
    <div>
      {activeTab && id && (
        <CommonSectionList
          menuId={activeTab.id}
          dataId={+id}
        />
      )}
    </div>
  );
}
