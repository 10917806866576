const FILE_COLUMN_WIDTH = 400;
const DATE_COLUMN_WIDTH = 110;
const NAME_COLUMN_WIDTH = 100;

export const arraySupportKeyword = '{:index}';

/**
 * attributeName은 string[] 타입으로, 객체에서 접근할 키들을 순차적으로 배열에 담습니다.
 * attributeName이 배열에 있는 속성을 대응해야 할 경우 ['arrayAttrName', '{:index}', 'innerAttrName'] 와 같이 index를 넣어 처리할 수 있습니다.
 * headerMeta는 키가 숫자 타입이고, 값이 { width: number; sortable: boolean; attributeName?: string[] } 형태인 객체를 정의합니다.
 * 각 항목은 열의 메타데이터를 표현하며, 열 너비, 정렬 가능 여부, 및 접근할 속성 경로를 지정할 수 있습니다.
 */
const headerMeta: Record<
  number,
  {
    width: number;
    sortable: boolean;
    filterable: boolean;
    attributeName?: string[];
  }
> = {
  1: {
    // 업무-기본정보T-협업자S-부서
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'department'],
  },
  2: {
    // 업무-기본정보T-협업자S-직책
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'jobDuty'],
  },
  3: {
    // 업무-기본정보T-협업자S-성명
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  4: {
    // 업무-기본정보T-협업자S-역할
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['workerRole'],
  },
  5: {
    // 업무-기본정보T-협업자S-참여율(%)
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['participationRate'],
  },
  6: {
    // 업무-기본정보T-협업자S-상세역할
    width: 550,
    filterable: true,
    sortable: true,
    attributeName: ['roleDetail'],
  },
  7: {
    width: 80,
    filterable: false,
    sortable: false,
  },
  8: {
    width: 140,
    filterable: false,
    sortable: false,
  },
  9: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  10: {
    width: 120,
    filterable: false,
    sortable: false,
  },
  11: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  12: {
    width: 140,
    filterable: false,
    sortable: false,
  },
  13: {
    width: 400,
    filterable: false,
    sortable: false,
  },
  14: {
    // 업무-기본정보T-회람자S-부서
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'department'],
  },
  15: {
    // 업무-기본정보T-회람자S-직책
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'jobDuty'],
  },
  16: {
    // 업무-기본정보T-회람자S-성명
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  17: {
    width: 600,
    filterable: false,
    sortable: false,
  },
  18: {
    width: DATE_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['date'],
  },
  19: {
    width: NAME_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  20: {
    width: 980,
    filterable: false,
    sortable: false,
    attributeName: ['content'],
  },
  21: {
    width: DATE_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['createdAt'],
  },
  22: {
    width: NAME_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  23: {
    width: FILE_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['fileItem', 'filename'],
  },
  24: {
    width: 400,
    filterable: false,
    sortable: false,
  },
  25: {
    width: DATE_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['createdAt'],
  },
  26: {
    width: NAME_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  27: {
    width: FILE_COLUMN_WIDTH,
    filterable: true,
    sortable: true,
    attributeName: ['fileItem', 'filename'],
  },
  28: {
    width: 400,
    filterable: false,
    sortable: false,
  },
  29: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  30: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  31: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  32: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  33: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  34: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  35: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  36: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  37: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  38: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  39: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  40: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  41: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  42: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  43: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  44: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  45: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  46: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  47: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  48: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  49: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  50: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  51: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  52: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  53: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  54: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  55: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  56: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  57: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  58: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  59: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  60: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  61: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  62: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  63: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  64: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  65: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  66: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  67: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  68: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  69: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  70: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  71: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  72: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  73: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  74: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  75: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  76: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  77: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  78: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  79: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  80: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  81: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  82: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  83: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  84: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  85: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  86: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  87: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  88: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  89: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  90: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  91: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  92: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  93: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  94: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  95: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  96: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  97: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  98: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  99: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  100: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  101: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  102: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  103: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  104: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  105: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  106: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  107: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  108: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  109: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  110: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  111: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  112: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  113: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  114: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  115: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  116: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  117: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  118: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  119: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  120: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  121: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  122: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  123: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  124: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  125: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  126: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  127: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  128: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  129: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  130: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  131: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  132: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  133: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  134: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  135: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  136: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  137: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  138: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  139: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  140: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  141: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  142: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  143: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  144: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  145: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  146: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  147: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  148: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  149: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  150: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  151: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  152: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  153: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  154: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  155: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  156: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  157: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  158: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  159: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  160: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  161: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  162: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  163: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  164: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  165: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  166: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  167: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  168: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  169: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  170: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  171: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  172: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  173: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  174: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  175: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  176: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  177: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  178: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  179: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  180: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  181: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  182: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  183: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  184: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  185: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  186: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  187: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  188: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  189: {
    width: 100,
    filterable: false,
    sortable: false,
  },
  190: {
    // 업무-기본정보T-업무구분S-영역
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['area'],
  },
  191: {
    // 업무-기본정보T-업무구분S-부서
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['department'],
  },
  192: {
    // 업무-기본정보T-업무구분S-협업
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['collaboration'],
  },
  193: {
    // 업무-기본정보T-업무구분S-영향
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['impact'],
  },
  194: {
    // 업무-기본정보T-업무구분S-보안
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['security'],
  },
  195: {
    width: 500,
    filterable: false,
    sortable: false,
  },
  196: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  197: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  198: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  199: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  200: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  201: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  202: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  203: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  204: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  205: {
    // 업무-기본정보T-지시자S-부서
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'department'],
  },
  206: {
    // 업무-기본정보T-지시자S-직책
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'jobDuty'],
  },
  207: {
    // 업무-기본정보T-지시자S-성명
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  208: {
    // 업무-기본정보T-지시자S-지시 세부사항/주안점
    width: 800,
    filterable: true,
    sortable: true,
    attributeName: ['description'],
  },
  209: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  210: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  211: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  212: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  213: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  214: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  215: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  216: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  217: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  218: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  219: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  220: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  221: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  222: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  223: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  224: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  225: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  226: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  227: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  228: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  229: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  230: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  231: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  232: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  233: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  234: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  235: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  236: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  237: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  238: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  239: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  240: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  241: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  242: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  243: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  244: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  245: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  246: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  247: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  248: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  249: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  250: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  251: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  252: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  253: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  254: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  255: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  256: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  257: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  258: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  259: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  260: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  261: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  262: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  263: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  264: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  265: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  266: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  267: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  268: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  269: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  270: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  271: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  272: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  273: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  274: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  275: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  276: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  277: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  278: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  279: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  280: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  281: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  282: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  283: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  284: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  285: {
    width: 260,
    filterable: false,
    sortable: false,
  },
  286: {
    // 업무-기본정보T-담당자S-부서
    width: 150,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'department'],
  },
  287: {
    // 업무-기본정보T-담당자S-직책
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'jobDuty'],
  },
  288: {
    // 업무-기본정보T-담당자S-성명
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['user', 'name'],
  },
  289: {
    // 업무-기본정보T-담당자S-지시 세부사항/주안점
    width: 800,
    filterable: true,
    sortable: true,
    attributeName: ['description'],
  },
  290: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  291: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  292: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  293: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  294: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  295: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  296: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  297: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  298: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  299: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['approvalDocument', 'submissionDateTime'],
  },
  300: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['approvalDocument', 'title'],
  },
  301: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['approvalDocument', 'user', 'name'],
  },
  302: {
    width: 260,
    filterable: false,
    sortable: false,
    attributeName: ['approvalDocument', 'status'],
  },
  303: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  304: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  305: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  306: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  307: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  308: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  309: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  310: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  311: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  // 업무상세-구분
  312: {
    width: 100,
    filterable: true,
    sortable: true,
    attributeName: ['category'],
  },
  // 업무상세-완료율
  313: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['completionRate'],
  },
  // 회계-계좌 집계현황-용도
  314: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['aliasCount'],
  },
  // 회계-계좌 집계현황-용도별 잔액계
  315: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['totalAmount'],
  },
  // 회계-계좌 집계현황-계좌별 잔액
  316: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['amount'],
  },
  // 회계-계좌 집계현황-구분
  317: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['type'],
  },
  // 회계-계좌 집계현황-관리지점
  318: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['branchCount'],
  },
  // 회계-계좌 집계현황-통장
  319: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['codeCount'],
  },
  // 회계-계좌 집계현황-연말예상 원금, 이자액
  320: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedPrincipalInterest'],
  },
  // 회계-계좌 집계현황-계좌별 연말예상 원금, 이자액
  321: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedProductInterest'],
  },
  // 회계-계좌 집계현황-연간예상 이자수익
  322: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedInterest'],
  },
  // 회계-계좌 집계현황-연간예상 이자비용
  323: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedTax'],
  },
  // 회계-계좌 집계현황-평균 금리 적용율
  324: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['averageInterestRate'],
  },
  // 회계-계좌 집계현황-세율1
  325: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['taxRate1'],
  },
  // 회계-계좌 집계현황-세율2
  326: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['taxRate2'],
  },
  // 회계-계좌 세부현황-용도
  327: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['alias'],
  },
  // 회계-계좌 세부현황-용도별 잔액
  328: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['totalAmount'],
  },
  // 회계-계좌 세부현황-계좌별 잔액
  329: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['amount'],
  },
  // 회계-계좌 세부현황-구분
  330: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['type'],
  },
  // 회계-계좌 세부현황-계좌번호
  331: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['code'],
  },
  // 회계-계좌 세부현황-은행명
  332: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['bankName'],
  },
  // 회계-계좌 세부현황-개설일
  333: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['openDate'],
  },
  // 회계-계좌 세부현황-만기일
  334: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['endDate'],
  },
  // 회계-계좌 세부현황-금리
  335: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['interestRate'],
  },
  // 회계-계좌 세부현황-세율1
  336: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['taxRate1'],
  },
  // 회계-계좌 세부현황-세율2
  337: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['taxRate2'],
  },
  // 회계-계좌 세부현황-연간예상 이자수익
  338: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedInterest'],
  },
  // 회계-계좌 세부현황-연간예상 이자세금
  339: {
    width: 115,
    filterable: false,
    sortable: false,
    attributeName: ['expectedTax'],
  },
  // 회계-계좌 세부현황-관리지점
  340: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['branchName'],
  },
  // 회계-계좌 세부현황-상품명
  341: {
    width: 125,
    filterable: false,
    sortable: false,
    attributeName: ['productName'],
  },
  // 영업정보 - 견적 - 대비견적 섹션
  342: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['seq'],
  },
  343: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationDate'],
  },
  344: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['affiliatedCompany', 'name'],
  },
  345: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['affiliatedCompanyPerson', 'name'],
  },
  346: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationRequestCompany', 'name'],
  },
  347: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['testPlan'],
  },
  348: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['totalPriceDiff'],
  },
  349: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['comparisonTotalPrice'],
  },
  350: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['windTunnelPrice'],
  },
  351: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['structuralReviewPrice'],
  },
  352: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['pureUnitPrice'],
  },
  353: {
    width: 112,
    filterable: false,
    sortable: false,
    attributeName: ['requiredWeeksStart'],
  },
  354: {
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['estimationDocument', 'filename'],
  },
  355: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['createdBy', 'name'],
  },
  356: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['createdAt'],
  },
  357: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  358: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  359: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  360: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  361: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  362: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  363: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  364: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  365: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  // 경쟁사 현황
  366: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['seq'],
  },
  367: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationDate'],
  },
  368: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['competitorCompany', 'name'],
  },
  369: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['testPlan'],
  },
  370: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['totalPriceDiff'],
  },
  371: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['competitorTotalPrice'],
  },
  372: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['windTunnelPrice'],
  },
  373: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['structuralReviewPrice'],
  },
  374: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['pureUnitPrice'],
  },
  375: {
    width: 112,
    filterable: false,
    sortable: false,
    attributeName: ['requiredWeeksStart'],
  },
  376: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationDocument', 'filename'],
  },
  377: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['createdBy', 'name'],
  },
  378: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['createdAt'],
  },
  379: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  380: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  381: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  382: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  383: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  384: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  385: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  386: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  387: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
  // 견적 이력
  388: {
    width: 112,
    filterable: false,
    sortable: true,
    attributeName: ['estimation', 'isConfirmed'], // 확정여부
  },
  389: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'seq'], // 견적 No.
  },
  390: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'code'], // 견적서번호
  },
  391: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'estimationDate'], // 견적일자
  },
  392: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'type'], // 견적구분
  },
  393: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'status'], // 견적상태
  },
  394: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'possibilityOfOrder'], // 수주가능성
  },
  395: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'projectDifficulty'], // 프로젝트 난이도
  },
  396: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'modelDifficulty'], // 모형 난이도
  },
  477: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'estimationRequestCompany', 'name'], // 견적요청사
  },
  397: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'estimationRequestCompanyPerson', 'name'], // 견적요청사인명
  },
  398: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimation', 'nextContactDateWithRequestCompany'], // 요청사 차기연락일
  },
  399: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'clientCompany', 'name'], // 발주처
  },
  400: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'clientCompanyPerson', 'name'], // 발주처인명
  },
  401: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'orderType'], // 발주형태
  },
  402: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'nextContactDateClient'], // 발주처 차기연락일
  },
  403: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'blockName'], // 단지명(블럭명)
  },
  404: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'siteModel'], // 대지모형
  },
  405: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'testPlan'], // 실험대상
  },
  406: {
    width: 112,
    filterable: false,
    sortable: false,
    attributeName: ['estimationClient', arraySupportKeyword, 'requiredWeeksStart'], // 소요기간(주)
  },
  407: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'estimatedStartDate'], // 예상착수시기
  },
  408: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'totalEstimationPrice'], // 견적총액
  },
  409: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'estimatedWindTunnelPrice'], // 견적순풍동금액
  },
  478: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'actualWindTunnelPrice'], // 견적실풍동금액
  },
  410: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'structuralReviewPrice'], // 견적구검
  },
  411: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'structuralReviewRatio'], // 견적구검비율
  },
  412: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'estimationCm'], // 견적CM
  },
  413: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'estimationCmPostDeduction'], // 공제 후 견적CM
  },
  414: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'cmDeductionRatio'], // 견적CM공제비율
  },
  415: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'cmRatio'], // 견적CM비율
  },
  416: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'actualUnitPrice'], // 견적 실동단가
  },
  417: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'targetUnitPrice'], // 목표 견적실동단가
  },
  418: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'minimumUnitPrice'], // 최저 견적실동단가
  },
  419: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'actualCostPrice'], // 견적실원가
  },
  420: {
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'excelFile', 'filename'], // 견적서파일(엑셀)
  },
  421: {
    width: 200,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'pdfFile', 'filename'], // 견적서파일(PDF)
  },
  422: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'createdBy', 'name'], // 담당자
  },
  423: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimationClient', arraySupportKeyword, 'createdAt'], // 등록일
  },
  424: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  425: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  426: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  427: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  428: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  429: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  430: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  431: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  432: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },

  // 최종계약 테이블
  522: { width: 112, filterable: true, sortable: true, attributeName: ['seq'] },
  523: { width: 112, filterable: true, sortable: true, attributeName: ['estimationCode'] },
  524: { width: 112, filterable: true, sortable: true, attributeName: ['orderType'] },
  525: { width: 112, filterable: true, sortable: true, attributeName: ['orderDate'] },
  526: { width: 112, filterable: true, sortable: true, attributeName: ['contractType'] },
  527: { width: 112, filterable: true, sortable: true, attributeName: ['contractDate'] },
  528: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'client', 'name'],
  },
  529: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'clientRatio'],
  },
  530: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'projectName'],
  },
  531: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'blockName'],
  },
  532: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'siteModel'],
  },
  533: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'testPlan'],
  },
  534: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'requiredWeeks'],
  },
  535: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'paymentTerms'],
  },
  536: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'totalEstimationPrice'],
  },
  537: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'totalContractPrice'],
  },
  538: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractedWindTunnelPrice'],
  },
  539: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualWindTunnelPrice'],
  },
  540: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'structuralReviewPrice'],
  },
  541: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'structuralReviewRatio'],
  },
  542: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractCM'],
  },
  543: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'cmRatio'],
  },
  544: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualUnitPrice'],
  },
  545: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractUnitPrice'],
  },
  546: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'targetUnitPrice'],
  },
  547: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'minimumUnitPrice'],
  },
  548: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualCost'],
  },
  549: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'hwpFile', 'filename'],
  },
  550: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'pdfFile', 'filename'],
  },
  551: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'createdBy', 'name'],
  },
  552: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'createdAt'],
  },
  553: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  554: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  555: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  556: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  557: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  558: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  559: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  560: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  561: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },

  // 계약이력 테이블
  481: { width: 112, filterable: true, sortable: true, attributeName: ['confirmed'] },
  482: { width: 112, filterable: true, sortable: true, attributeName: ['seq'] },
  483: { width: 112, filterable: true, sortable: true, attributeName: ['estimationCode'] },
  484: { width: 112, filterable: true, sortable: true, attributeName: ['orderType'] },
  485: { width: 112, filterable: true, sortable: true, attributeName: ['orderDate'] },
  486: { width: 112, filterable: true, sortable: true, attributeName: ['contractType'] },
  487: { width: 112, filterable: true, sortable: true, attributeName: ['contractDate'] },
  488: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'client', 'name'],
  },
  489: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'clientRatio'],
  },
  490: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'projectName'],
  },
  491: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'blockName'],
  },
  492: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'siteModel'],
  },
  493: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'testPlan'],
  },
  494: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'requiredWeeks'],
  },
  495: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'paymentTerms'],
  },
  496: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'totalEstimationPrice'],
  },
  497: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'totalContractPrice'],
  },
  498: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractedWindTunnelPrice'],
  },
  499: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualWindTunnelPrice'],
  },
  500: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'structuralReviewPrice'],
  },
  501: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'structuralReviewRatio'],
  },
  502: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractCM', arraySupportKeyword, 'contractCM'],
  },
  503: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'cmRatio'],
  },
  504: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualUnitPrice'],
  },
  505: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'contractUnitPrice'],
  },
  506: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'targetUnitPrice'],
  },
  507: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'minimumUnitPrice'],
  },
  508: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'actualCost'],
  },
  509: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'hwpFile', 'filename'],
  },
  510: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'pdfFile', 'filename'],
  },
  511: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'createdBy', 'name'],
  },
  512: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['detail', arraySupportKeyword, 'createdAt'],
  },
  513: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  514: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  515: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  516: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  517: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  518: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  519: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  520: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  521: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },

  // 지불조건(모달) 테이블
  562: { width: 112, filterable: true, sortable: true, attributeName: ['paymentStage'] },
  563: { width: 112, filterable: true, sortable: true, attributeName: ['paymentSequence'] },
  564: { width: 112, filterable: true, sortable: true, attributeName: ['client'] },
  565: { width: 112, filterable: true, sortable: true, attributeName: ['paymentTiming'] },
  566: { width: 112, filterable: true, sortable: true, attributeName: ['expectedPaymentDate'] },
  567: { width: 112, filterable: true, sortable: true, attributeName: ['clientRatio'] },
  568: { width: 112, filterable: true, sortable: true, attributeName: ['companyStageRatio'] },
  569: { width: 112, filterable: true, sortable: true, attributeName: ['totalContractPrice'] },
  570: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['estimatedWindTunnelPrice'],
  },
  571: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewPrice'] },
  572: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewRatio'] },
  573: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['structuralReviewPaymentTiming'],
  },
  574: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['structuralReviewPaymentTarget'],
  },
  575: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['structuralReviewPaymentDate'],
  },
  585: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  586: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  587: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  588: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  589: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  590: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  591: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  592: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  593: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },

  // 계약CM(모달) 테이블
  576: { width: 112, filterable: true, sortable: true, attributeName: ['paymentStage'] },
  577: { width: 112, filterable: true, sortable: true, attributeName: ['contractCM'] },
  578: {
    width: 112,
    filterable: true,
    sortable: true,
    attributeName: ['cmRatioAgainstContractPrice'],
  },
  579: { width: 112, filterable: true, sortable: true, attributeName: ['cmPostDeduction'] },
  580: { width: 112, filterable: true, sortable: true, attributeName: ['cmDeductionRatio'] },
  581: { width: 112, filterable: true, sortable: true, attributeName: ['cmMethod'] },
  582: { width: 112, filterable: true, sortable: true, attributeName: ['cmRecipient'] },
  583: { width: 112, filterable: true, sortable: true, attributeName: ['cmPaymentTiming'] },
  584: { width: 112, filterable: true, sortable: true, attributeName: ['cmExpectedPaymentDate'] },
  594: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  595: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  596: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  597: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  598: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  599: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  600: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  601: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  602: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },

  // 수금 - 계약 최종수금 테이블
  603: { width: 112, filterable: true, sortable: true, attributeName: ['paymentStage'] },
  604: { width: 112, filterable: true, sortable: true, attributeName: ['paymentSequence'] },
  605: { width: 112, filterable: true, sortable: true, attributeName: ['client'] },
  606: { width: 112, filterable: true, sortable: true, attributeName: ['clientPaymentManager'] },
  607: { width: 112, filterable: true, sortable: true, attributeName: ['paymentTiming'] },
  608: { width: 112, filterable: true, sortable: true, attributeName: ['expectedPaymentDate'] },
  609: { width: 112, filterable: true, sortable: true, attributeName: ['clientRatio'] },
  610: { width: 112, filterable: true, sortable: true, attributeName: ['companyStageRatio'] },
  611: { width: 112, filterable: true, sortable: true, attributeName: ['totalContractPrice'] },
  612: { width: 112, filterable: true, sortable: true, attributeName: ['windTunnelPrice'] },
  613: { width: 112, filterable: true, sortable: true, attributeName: ['actualWindTunnelPrice'] },
  614: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewPrice'] },
  615: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewRatio'] },
  616: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewPaymentTarget'] },
  617: { width: 112, filterable: true, sortable: true, attributeName: ['structuralReviewPaymentDate'] },
  618: { width: 112, filterable: true, sortable: true, attributeName: ['contractCM'] },
  619: { width: 112, filterable: true, sortable: true, attributeName: ['cmRatioAgainstContractPrice'] },

  // 수금 - 수금이력 테이블
  620: { width: 112, filterable: true, sortable: true, attributeName: ['paymentStage'] },
  621: { width: 112, filterable: true, sortable: true, attributeName: ['paymentSequence'] },
  622: { width: 112, filterable: true, sortable: true, attributeName: ['client'] },
  623: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'bondStatus'] },
  624: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionSeq'] },
  625: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'relatedCollectionSeq'] },
  626: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'clientPaymentManager'] },
  627: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionTiming'] },
  628: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionDueDate'] },
  629: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionDueDateChangeDate'] },
  630: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionDelayDays'] },
  631: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionPossibility'] },
  632: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'expectedCollectionAmount'] },
  633: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectedAmount'] },
  634: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'unpaidAmount'] },
  635: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionCompletionDate'] },
  636: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'collectionManager'] },
  637: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'claimDocument'] },
  638: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'taxInvoice'] },
  639: { width: 112, filterable: true, sortable: true, attributeName: ['detail', arraySupportKeyword, 'depositDestination'] },
  640: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr1'],
  },
  641: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr1'],
  },
  642: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr1'],
  },
  643: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr2'],
  },
  644: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr2'],
  },
  645: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr2'],
  },
  646: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['numberAttr3'],
  },
  647: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['textAttr3'],
  },
  648: {
    width: 260,
    filterable: true,
    sortable: true,
    attributeName: ['dateAttr3'],
  },
};

export default headerMeta;
