import React, { useMemo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { AccountingUploadSalesInfoGoalModalListTableBody } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/components/table-body';
import { useShallow } from 'zustand/react/shallow';

export { Table as AccountingUploadSalesInfoGoalModalListTable };

const Table = () => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const yearTwoDigits = useMemo(() => {
    if (!year) {
      return '-';
    }
    return (year - 2000).toString();
  }, [year]);
  return (
    <TableContainer
      sx={{
        maxWidth: '88dvw',
        overflowX: 'auto',
      }}
    >
      <MuiTable>
        <TableHead>
          <TableRow>
            <Th width="120px">-</Th>
            <Th width="80px">내용</Th>
            <Th width="160px">년 합계</Th>
            <Th width="160px">년 평균</Th>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <Th
                width="160px"
                key={`modal-th-${month}`}
              >
                {yearTwoDigits}
                {month.toString().padStart(2, '0')}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <AccountingUploadSalesInfoGoalModalListTableBody />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
