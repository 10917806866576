import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoContractContractCreateModalLogic } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoContractContractCreateModalContext } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoContractContractCreateModalTable } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/components/modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoContractContractCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    d: { categoryList, projectFullName, contractConfirmed, estimationList },
    h: { onClose, onCreate },
    isLoading,
    isOpen,
  } = useProjectSalesInfoContractContractCreateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { categoryList, projectFullName, contractConfirmed, estimationList },
      h: { onClose, onCreate },
    }),
    [categoryList, onClose, onCreate, projectFullName, contractConfirmed, estimationList]
  );
  return (
    <ProjectSalesInfoContractContractCreateModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="계약 추가"
        open={isOpen}
        onClose={onClose}
      >
        {isLoading && <LoadingSpinner height="1000px" />}
        {!isLoading && <ProjectSalesInfoContractContractCreateModalTable />}
      </ModalLayout>
    </ProjectSalesInfoContractContractCreateModalContext.Provider>
  );
};
