import type { AuthUpdateParams } from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { AuthorizationPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';

interface State {
  clientDeptList: AuthorizationPersonalSettingsDeptView[];
  deptList?: AuthorizationPersonalSettingsDeptView[];
  onAuthUpdate: (params: AuthUpdateParams) => void;
}

export { context as PersonalSettingsModalDeptSettingsContext };

const context = createContext<State>({
  clientDeptList: [],
  onAuthUpdate: noOp,
});
