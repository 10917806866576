import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { ProjectEstimationComparedView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/view';
import type { ProjectEstimationComparedParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/parameter';

export { context as ProjectSalesInfoEstimationComparedUpdateModalContext };

interface State {
  d: {
    detail?: ProjectEstimationComparedView;
  };
  h: {
    onUpdate: (params: ProjectEstimationComparedParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
    onClose: noOp,
  },
});
