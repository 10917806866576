import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import { dialogAction } from '@front/dialog/action';
import type { RootState } from '@front/services/reducer';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { OstRewardManagementMemoId } from '@front/ost_reward/domian';
import { ostRewardManagementApi } from '@front/ost_reward/api';
import type {
  OstRewardManagementMemoCreateParameter,
  OstRewardManagementMemoUpdateParameter,
} from '@front/ost_reward/parameter';
import type { CampaignId } from '@front/ost_campaign/domain';

import { createDataWithMap, excelDownload } from '@front/util/ExcelDownload';

import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

export { saga as ostRewardManagementSaga };

function* setList() {
  yield put(ostRewardManagementAction.setIsLoading(true));
  try {
    const list = yield call(ostRewardManagementApi.getList);
    yield put(ostRewardManagementAction.setList(list));
    yield put(ostRewardManagementAction.setIsLoading(false));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setList, e);
    const code = getErrorCode(ostRewardManagementAction.setList, e);
    if (code === CustomHttpStatus.NO_AUTHORIZATION) {
      yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
      return;
    }
    yield put(dialogAction.openError(message));
  }
}

function* setDetail() {
  yield put(ostRewardManagementAction.setIsLoading(true));
  const { id } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
  }));
  try {
    const detailStatusList = yield call(ostRewardManagementApi.getDetailStatusList, id);
    yield put(ostRewardManagementAction.setDetail(detailStatusList));
    yield put(ostRewardManagementAction.setIsLoading(false));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setDetail, e);
    yield put(dialogAction.openError(message));
  }
}

function* setId() {
  try {
    yield fork(setDetail);
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setId, e);
    yield put(dialogAction.openError(message));
  }
}

function* setTotalStatusList() {
  try {
    const totalStatusList = yield call(ostRewardManagementApi.getTotalStatusList);
    yield put(ostRewardManagementAction.setTotalStatusList(totalStatusList));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setTotalStatusList, e);
    yield put(dialogAction.openError(message));
  }
}

function* setMemoFilter() {
  yield put(ostRewardManagementAction.setIsMemoLoading(true));
  const { id, memoFilter } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
    memoFilter: root.ostRewardMangement.memoFilter,
  }));
  try {
    const memoList = yield call(ostRewardManagementApi.getMemoList, id, memoFilter);
    yield put(ostRewardManagementAction.setMemoList(memoList));
    yield put(ostRewardManagementAction.setIsMemoLoading(false));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setMemoFilter, e);
    yield put(dialogAction.openError(message));
  }
}

function* createMemo({ payload: params }: { payload: OstRewardManagementMemoCreateParameter }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
  }));
  try {
    yield call(ostRewardManagementApi.createMemo, id, params);
    yield fork(setMemoFilter);
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.createMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* updateMemo({ payload: params }: { payload: OstRewardManagementMemoUpdateParameter }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
  }));
  try {
    yield call(ostRewardManagementApi.updateMemo, id, params);
    yield fork(setMemoFilter);
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.updateMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* deleteMemo({
  payload: ostRewardManagementMemoId,
}: {
  payload: OstRewardManagementMemoId;
}) {
  const { id } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
  }));
  try {
    yield call(ostRewardManagementApi.deleteMemo, id, ostRewardManagementMemoId);
    yield fork(setMemoFilter);
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.deleteMemo, e);
    yield put(dialogAction.openError(message));
  }
}

function* setMonthDetail() {
  const { id, month } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
    month: root.ostRewardMangement.month,
  }));
  try {
    yield delay(1000);
    const monthDetail = yield call(ostRewardManagementApi.getMonthDetail, id, month);
    yield put(ostRewardManagementAction.setMonthDetail(monthDetail));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setMonthDetail, e);
    yield put(dialogAction.openError(message));
  }
}

function* setCampaignRewards({ payload: campaignId }: { payload: CampaignId }) {
  const { id } = yield select((root: RootState) => ({
    id: root.ost.id,
  }));
  try {
    yield delay(1000);
    const campaignRewards = yield call(ostRewardManagementApi.getCampaignRewards, id, campaignId);
    yield put(ostRewardManagementAction.setCampaignRewards(campaignRewards));
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setCampaignRewards, e);
    yield put(dialogAction.openError(message));
  }
}

function* fixResult() {
  const { id, month, detail, monthDetail } = yield select((root: RootState) => ({
    id: root.ostRewardMangement.id,
    month: root.ostRewardMangement.month,
    monthDetail: root.ostRewardMangement.monthDetail,
    detail: root.ostRewardMangement.detail,
  }));
  try {
    yield call(ostRewardManagementApi.fixResult, id, month);
    const getData = () => {
      if (typeof monthDetail === 'undefined') {
        return [];
      }
      if (typeof detail === 'undefined') {
        return [];
      }
      const { individualList } = monthDetail;
      const map = new Map();
      individualList.forEach((user) => {
        const employeeId = user.employeeId;
        const reward = user.reward;
        if (map.has(employeeId)) {
          map.set(employeeId, map.get(employeeId) + reward);
        } else {
          map.set(employeeId, reward);
        }
      });
      return createDataWithMap(map);
    };
    excelDownload(
      getData(),
      [
        '사원',
        '기본급',
        '식대',
        '차량유지비',
        '연장근로수당',
        '심야근로수당',
        '연차수당',
        '실험수당',
        '특근수당',
        '추가연장수당',
        '추가야간수당',
        '직책수당',
        '가족수당',
        '개선포상',
        '직무수당',
        '성과급',
        '자기개발비',
        '기타수당',
        '연차수당',
        '파견수당',
        '연구수당',
        '상여',
        '휴가비',
        '기타상여',
        '소급급여',
        '기본급 (메모)',
        '식대 (메모)',
        '차량유지비 (메모)',
        '연장근로수당 (메모)',
        '심야근로수당 (메모)',
        '연차수당 (메모)',
        '실험수당 (메모)',
        '특근수당 (메모)',
        '추가연장수당 (메모)',
        '추가야간수당 (메모)',
        '직책수당 (메모)',
        '가족수당 (메모)',
        '개선포상 (메모)',
        '직무수당 (메모)',
        '성과급 (메모)',
        '자기개발비 (메모)',
        '기타수당 (메모)',
        '연차수당 (메모)',
        '파견수당 (메모)',
        '연구수당 (메모)',
        '상여 (메모)',
        '휴가비 (메모)',
        '기타상여 (메모)',
        '소급급여 (메모)',
      ],
      `${monthDetail.paymentStatus.month}월_OST_상금_지급_현황`
    );
  } catch (e) {
    const message = getErrorMessage(ostRewardManagementAction.setCampaignRewards, e);
    yield put(dialogAction.openError(message));
  }
}

function* watchSetList() {
  yield takeLatest(ostRewardManagementAction.getList, setList);
}

function* watchSetId() {
  yield takeLatest(ostRewardManagementAction.setId, setId);
}

function* watchGetTotalStatusList() {
  yield takeLatest(ostRewardManagementAction.getTotalStatusList, setTotalStatusList);
}

function* watchSetMemoFilter() {
  yield takeLatest(ostRewardManagementAction.setMemoFilter, setMemoFilter);
}

function* watchCreateMemo() {
  yield takeLatest(ostRewardManagementAction.createMemo, createMemo);
}

function* watchUpdateMemo() {
  yield takeLatest(ostRewardManagementAction.updateMemo, updateMemo);
}

function* watchDeleteMemo() {
  yield takeLatest(ostRewardManagementAction.deleteMemo, deleteMemo);
}

function* watchGetMonthDetail() {
  yield takeLatest(ostRewardManagementAction.getMonthDetail, setMonthDetail);
}

function* watchCampaignRewards() {
  yield takeLatest(ostRewardManagementAction.getCampaignRewards, setCampaignRewards);
}

function* watchFixResult() {
  yield takeLatest(ostRewardManagementAction.fixResult, fixResult);
}

function* saga() {
  yield fork(watchSetList);
  yield fork(watchSetId);
  yield fork(watchGetTotalStatusList);

  yield fork(watchSetMemoFilter);
  yield fork(watchCreateMemo);
  yield fork(watchUpdateMemo);
  yield fork(watchDeleteMemo);

  yield fork(watchGetMonthDetail);
  yield fork(watchCampaignRewards);

  yield fork(watchFixResult);
}
