import {useLocation, useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import type {RootState} from 'services/reducer';
import {loginAction} from 'login/action';
import ReactRouter from 'services/routes';
import {Box} from '@mui/material';
import {closeStatus} from 'components/DataFieldProps';
import {useInterval} from 'hook/useInterval';
import {registerFirebaseServiceWorker} from '@front/common/notification';
import {userNotificationAction} from '@front/user_notification/action';
import GlobalNavBarFeature from '@front/src/features/global-nav-bar';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import AlertRoute from 'dialog/route/alert';
import ConfirmRoute from 'dialog/route/confirm';
import BlockService from '@front/dialog/route/block';
import DialogFeature from '@front/src/features/dialog/widgets/widget';
import SnackbarFeature from '@front/src/features/snackbar/snackbar';
import NavigateWrapperService from '@front/navigate/service/navigateWrapperService';
import BlockModalFeature from '@front/src/features/modal/features/block-modal';
import AlertModalFeature from '@front/src/features/modal/features/alert-modal';
import CancelBlockModalFeature from '@front/src/features/modal/features/cancel-block-modal';

import Progress from '@front/components/Progress';
import Snackbar from '@front/components/Snackbar';
import ModalController from '@front/controller/ModalController';
import ResizableDrawerWrapper from '@front/src/app/component/ResizableDrawerWrapper';
import FileDownLoadRequestHandler from '@front/common/fileDownLoadRequestHandler';
import {useGlobalClickInterceptor} from "@front/src/hooks/useGlobalClickInterceptor";
import {imageDrawInitiatingCallbackForClickEvent, ImageDrawProvider} from "@front/src/features/image-draw/provider/ImageDrawProvider";

export const App = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const requestLogin = useSelector((root: RootState) => root.login.requestLogin, shallowEqual);
  const openLoginModal = useCallback(() => dispatch(loginAction.setLoginModal(true)), [dispatch]);
  const channel = new BroadcastChannel('push-alerts');
  const navigateChannel = new BroadcastChannel('navigate');
  const navigate = useNavigate();
  useEffect(() => {
    channel.onmessage = (event) => {
      dispatch(userNotificationAction.setCount(event.data));
    };
    return () => {
      channel.close();
    };
  }, [channel]);
  useEffect(() => {
    navigateChannel.onmessage = (event) => {
      navigate(event.data);
    };
    return () => {
      navigateChannel.close();
    };
  }, [navigateChannel]);
  useEffect(() => {
    if (!loginUser) {
      if (!pathname.startsWith('/login') && !pathname.startsWith('/user/password-reset')) {
        dispatch(loginAction.requestDetail());
      }
    }
  }, []);
  useEffect(() => {
    if (loginUser) {
      window.scrollTo(0, 0);
      handlePermission();
    }
  }, [loginUser]);
  useEffect(() => {
    closeStatus(
      requestLogin,
      undefined,
      () => {
        dispatch(loginAction.requestLogin('idle'));
      },
      () => {
        if (!pathname.startsWith('/login')) {
          openLoginModal();
        }
      }
    );
  }, [requestLogin]);
  useEffect(() => {
    const interval = useInterval(() => {
      if (loginUser) {
        dispatch(loginAction.setDetail(undefined));
        openLoginModal();
      }
    }, 5400000);
    interval.start();
    document.addEventListener('click', () => {
      interval.stop();
      interval.restart();
    });
    document.addEventListener(
      'scroll',
      () => {
        interval.stop();
        interval.restart();
      },
      { passive: true }
    );
    document.addEventListener('keydown', () => {
      interval.stop();
      interval.restart();
    });
    document.addEventListener('mousemove', () => {
      interval.stop();
      interval.restart();
    });
  }, [loginUser]);
  useGlobalClickInterceptor(imageDrawInitiatingCallbackForClickEvent);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100dvw',
        height: '100dvh',
        fontFamily: 'Pretendard Variable, Pretendard, Noto Sans KR, sans-serif',
      }}
    >
      <CssBaseline />
      <AlertRoute />
      <ConfirmRoute />
      <BlockService />
      <DialogFeature />
      <Progress />
      <Snackbar />
      <SnackbarFeature />
      <NavigateWrapperService />
      <BlockModalFeature />
      <CancelBlockModalFeature />
      <AlertModalFeature />
      <ModalController />
      <GlobalNavBarFeature />
      <ReactRouter />
      <ResizableDrawerWrapper />
      <FileDownLoadRequestHandler />
      <ImageDrawProvider/>
    </Box>
  );
};

function handlePermission() {
  if (process.env.REACT_APP_FCM_ACTIVE === 'false') {
    return;
  }

  // 사용자의 응답에 관계 없이 크롬이 정보를 저장할 수 있도록 함
  Notification.requestPermission().then((permission) => {
    if (
      (permission === 'default' &&
        (Notification.permission === 'default' || Notification.permission === 'denied')) ||
      (permission === 'denied' &&
        (Notification.permission === 'default' || Notification.permission === 'denied'))
    ) {
      return;
    }
    registerFirebaseServiceWorker();
  });
}
