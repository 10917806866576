import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workNameQuery = {
  useGetOne: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getName(commonParams),
      queryKey: ['work', 'name', commonParams.dataId],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return { data };
  },
};

export default workNameQuery;
