import { shallow } from 'zustand/shallow';
import { affiliatedCompanyExtraCompanyRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/repository/repository';
import { useAffiliatedCompanyPersonDetailModalOutlineExtraCompanyState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineExtraCompanyLogic };

const useLogic = (menuId) => {
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyPersonDetailModalOutlineExtraCompanyState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { run: onExtraCompanyCreate } =
    affiliatedCompanyExtraCompanyRepository.usePersonOutlineExtraCompanyCreate(menuId);
  const { isLoading, data: extraCompanyList } =
    affiliatedCompanyExtraCompanyRepository.usePersonOutlineExtraCompanyGet(modalId, menuId);
  const { run: onExtraCompanyDelete } =
    affiliatedCompanyExtraCompanyRepository.usePersonOutlineExtraCompanyDelete(menuId);
  const h = {
    onExtraCompanyCreate: (params) => onExtraCompanyCreate({ ...params, id: modalId }),
    onExtraCompanyDelete,
    setIdList,
  };
  const d = { extraCompanyList };
  return { d, h, isLoading, idList };
};
