import React from 'react';

export { CenterWrapper as Center };

interface Props {
  children: React.ReactNode;
}

const CenterWrapper = ({ children }: Props) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>{children}</div>
);
