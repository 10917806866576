import React from 'react';
import Drawer from 'layouts/Drawer';
import { ColorPalette } from 'assets/theme';
import { Box, Typography } from '@mui/material';
import Fade from 'components/Fade';
import IconButton from 'layouts/IconButton';
import { ArrowLeft as LeftIcon, ArrowRight as RightIcon } from '@mui/icons-material';
import { isMobileDevice } from '@front/util/PwaUtil';

interface Props {
  isOpen: boolean;
  setDrawer: (open: boolean) => void;
  children: React.ReactNode;
}

const DRAWER_OPEN_WIDTH = 310;
const DRAWER_CLOSE_WIDTH = 42;

export default function MemoDrawer(props: Props) {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: `${DRAWER_CLOSE_WIDTH}px`,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          padding: '15px 0',
          alignItems: 'center',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Fade
          in={!props.isOpen}
          title="제안 메모 열기"
          timeout={{
            enter: 0,
          }}
          children={
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                padding: '0 10px',
              }}
            >
              <IconButton
                children={<LeftIcon />}
                onClick={() => {
                  props.setDrawer(true);
                }}
              />
            </Box>
          }
        />
      </Box>
      <Drawer
        open={props.isOpen}
        openedWidth={isMobileDevice() ? window.screen.width : DRAWER_OPEN_WIDTH}
        closedWidth={DRAWER_CLOSE_WIDTH}
        sx={{
          backgroundColor: ColorPalette._f1f5fc,
          paddingTop: '0px',
          ...(isMobileDevice() && {
            position: 'absolute',
            left: 0,
            height: '100%',
          }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            backgroundColor: props.isOpen ? 'inherit' : 'transparent',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'nowrap',
              padding: '15px 10px',
            }}
          >
            <IconButton
              children={<RightIcon />}
              onClick={() => {
                props.setDrawer(false);
              }}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: ColorPalette._252627,
                marginLeft: '10px',
              }}
            >
              메모
            </Typography>
          </Box>
          {props.children}
        </Box>
      </Drawer>
    </>
  );
}
