import {
  apiAminSectionRequestUrl,
  getMenuIdHeaders,
  menuRequestUrl,
  userListUrl,
} from '@front/src/utils';
import type { HeaderSettingView, SectionView } from '@front/src/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  HeaderGetParams,
  SectionGetParams,
  UserListGetParams,
} from '@front/src/types/parameter';
import type { UserShortView } from '@front/src/features/mobile-notification/types/view';

const url = {
  getSectionList: (id?: number) => `${menuRequestUrl}/${id}/section`,
  getHeaderList: (id?: number) => `${apiAminSectionRequestUrl}/${id}/header-setting`,
  getUserList: () => `${userListUrl}`,
  createImage: () => `/api/file-item/image`,
};

export const commonApi = {
  getSectionList: async <T = SectionView[]>(authMenuId?: number, params?: SectionGetParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getSectionList(authMenuId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getHeaderList: async <T = HeaderSettingView[]>(
    id?: number,
    params?: HeaderGetParams,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderList(id), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getUserList: async <T = UserShortView[]>(params: UserListGetParams, authMenuId) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getUserList(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  createImage: async <T = string>(formData, authMenuId) =>
    await axios.post<T, AxiosResponse<T>>(url.createImage(), formData, {
      headers: getMenuIdHeaders(authMenuId),
    }),
};
