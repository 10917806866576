import React, { useContext, useMemo } from 'react';
import { MyPageAuthorizationMenuContext } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/context';
import { MyPageAuthorizationMenuCommonList } from '@front/src/features/my-page/features/authorization/widgets/menu/components/common-list';

export { Depth1List as MyPageAuthorizationMenuDepth1List };
const Depth1List = () => {
  const { list } = useContext(MyPageAuthorizationMenuContext);
  const depth1List = useMemo(() => {
    if (!list) return [];
    return list?.filter((l) => l.depth === 1);
  }, [list]);
  return (
    <MyPageAuthorizationMenuCommonList
      depth={1}
      list={depth1List}
    />
  );
};
