import React from 'react';
import { AffiliatedPersonMemoDeleteProvider } from '@front/src/features/affiliated-person/features/memo/widgets/delete/provider/provider';
import { AffiliatedPersonMemoDeleteButton } from '@front/src/features/affiliated-person/features/memo/widgets/delete/components/delete-button';

export { Widget as AffiliatedPersonMemoDeleteWidget };

const Widget = () => (
  <AffiliatedPersonMemoDeleteProvider>
    <AffiliatedPersonMemoDeleteButton />
  </AffiliatedPersonMemoDeleteProvider>
);
