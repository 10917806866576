import type { AuthorizationDepartmentView } from '@front/src/features/admin/features/access-authorization-settings/features/department-authorization/type/view';
import { AuthorizationType } from '@front/user/domain';
import { ColorPalette } from '@front/assets/theme';
import type { AuthorizationPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';

export const sortDepartmentList = (
  isSearched: boolean,
  list?: AuthorizationPersonalSettingsDeptView[] | AuthorizationDepartmentView[]
) => {
  if (!list) return;
  list.sort((a, b) => a.id - b.id);

  function sortData(
    list: AuthorizationPersonalSettingsDeptView[] | AuthorizationDepartmentView[],
    parentId: number | null = null
  ) {
    let sorted: AuthorizationPersonalSettingsDeptView[] | AuthorizationDepartmentView[] = [];

    for (const item of list) {
      if (item.parentId === parentId) {
        sorted.push(item);
        // 현재 항목의 자식들을 찾아서 바로 뒤에 추가
        const children = sortData(list, item.id);
        sorted = sorted.concat(children);
      } else if (isSearched) sorted.push(item);
    }

    return sorted;
  }

  return Array.from(new Set(sortData(list)));
};

export const getAuthColor = (type) => {
  if (type === AuthorizationType.V) return ColorPalette._9bb6ea;
  if (type === AuthorizationType.R) return ColorPalette._ffb72b;
  if (type === AuthorizationType.W) return ColorPalette._DAE8FC;
  return 'none';
};
