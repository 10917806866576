import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import type { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import ManagedDropDown from '@front/src/components/ui-builder/managed-variable/components/DropDown';
import { useFormContext } from 'react-hook-form';

interface Props extends CellComponentProps {
  code: ManagedVariable;
  displayValue?: string;
  name: string;
}

export default function UIBuilderTableCellManagedDropDown(props: Props) {
  const { onClick, sx, name, readOnly, isEditMode, onSubmit, isForm, code, displayValue } = props;

  const { getValues } = useFormContext();

  const renderContent = () => {
    if (readOnly || !isEditMode) return displayValue ?? (getValues(name) || '-');

    return (
      <ManagedDropDown
        name={name}
        code={code}
        // menuId={menuId}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
}
