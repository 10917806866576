import React, { Suspense } from 'react';
import UserSelectorUnchangedMultiFormFeature from '@front/src/features/user-selector/features/unchanged-multi-form';
import fileAuthSettingsQuery from '@front/src/features/file-item/features/auth-settings/query/query';
import type { UserVO } from '@front/user/domain';
import useFileAuthSettingsUpdate from '@front/src/features/file-item/features/auth-settings/repository/useUpdate';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

interface Props {
  unchangeableList?: number[];
  changeableList?: number[];
  onClose?: () => void;
  fileItemId: number | undefined;
  menuId: number | undefined;
  dataId: number | undefined;
}
export default function FileAuthSettingsFeature(props: Readonly<Props>) {
  return (
    <Suspense
      fallback={
        <LoadingSpinnerUI
          width="800px"
          height="406px"
        />
      }
    >
      <Index {...props} />
    </Suspense>
  );
}

function Index(props: Readonly<Props>) {
  const { onClose, fileItemId, unchangeableList = [], changeableList = [], menuId, dataId } = props;
  const fileAuthList = fileAuthSettingsQuery.useGetList(fileItemId);
  const { run: onUpdate, setCallback } = useFileAuthSettingsUpdate();
  const handleChange = (value: UserVO[]) => {
    const valueIdList = value.map((v) => v.id) as number[];
    const prevIdList = fileAuthList?.map((f) => f.userId);
    const deleteUserIdList = prevIdList?.filter((p) => !valueIdList.includes(p)) ?? [];
    const addUserIdList = valueIdList.filter((v) => !prevIdList?.includes(v));
    onUpdate({
      menuId,
      dataId,
      fileItemId,
      deleteUserIdList,
      addUserIdList,
    });
  };
  setCallback({
    onSuccess: () => {
      onClose?.();
    },
  });
  const filteredUnchangeableList = fileAuthList
    ?.filter((f) => f.isFixed)
    .map((f) => f.userId)
    .concat(unchangeableList);
  const filteredChangeableList = fileAuthList
    ?.filter((f) => !f.isFixed)
    .map((f) => f.userId)
    .concat(changeableList);
  return (
    <UserSelectorUnchangedMultiFormFeature
      onClose={onClose}
      onChange={handleChange}
      unchangeableList={filteredUnchangeableList}
      changeableList={filteredChangeableList}
    />
  );
}
