import React, { memo, useContext } from 'react';
import { TableRow } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import { OldTd } from '@front/layouts/Table';

import { convertPersonStatus, getIsKeyPerson } from '@front/src/features/affiliated-company/utils';
import { isEmpty } from '@front/components/DataFieldProps';
import { AffiliatedCompanyPersonContext } from '@front/src/features/affiliated-company/features/person/widgets/context';

export { PersonTableBody as AffiliatedCompanyPersonTableBody };

const PersonTableBody = () => {
  const {
    d: { personList },
    h: { onDetailModalOpen },
    isLoading,
  } = useContext(AffiliatedCompanyPersonContext);
  if (typeof personList === 'undefined' || isLoading) {
    return <Spinner />;
  }
  if (personList.length === 0) {
    return <NoResult />;
  }
  return (
    <>
      {personList.map((person) => (
        <TableRow
          key={person.id}
          onClick={() => onDetailModalOpen(person.id)}
          hover={true}
          sx={{ cursor: 'pointer' }}
        >
          <OldTd>{person.name}</OldTd>
          <OldTd>{person.position}</OldTd>
          <OldTd>{person.status ? convertPersonStatus(person.status) : '-'}</OldTd>
          <OldTd>{person.isKeyPerson ? getIsKeyPerson(person.isKeyPerson) : '-'}</OldTd>
          <OldTd>{person.department}</OldTd>
          <OldTd>{person.birthYear}</OldTd>
          <OldTd>{person.schoolName}</OldTd>
          <OldTd>{person.studentID}</OldTd>
          <OldTd>
            {person.personCharacterList.length === 0 && '-'}
            {person.personCharacterList.length > 0 &&
              convertStringWithComma(person.personCharacterList)}
          </OldTd>
          <OldTd>{person.date}</OldTd>
          <OldTd>{person.favoriteAffiliatedCompany?.name}</OldTd>
        </TableRow>
      ))}
    </>
  );
};

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={11}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));

Spinner.displayName = 'AffiliatedCompanyPersonTableBodySpinner';

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={11}>저장된 인물이 없습니다.</OldTd>
  </TableRow>
));

NoResult.displayName = 'AffiliatedCompanyPersonTableBodyNoResult';

const convertStringWithComma = (
  list: {
    id: string;
    content: string;
  }[]
) =>
  list
    .map((item) => item.content)
    .filter((item) => !isEmpty(item))
    .join(', ');
