import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import Box from '@mui/material/Box';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import type { ConfirmView } from '@front/src/features/dialog/types';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

const workManagerCellComponent = {
  286: ({ ...rest }: CellComponentProps) => <WorkManagerDepartment {...rest} />,
  287: ({ ...rest }: CellComponentProps) => <WorkManagerJobDuty {...rest} />,
  288: ({ ...rest }: CellComponentProps) => <WorkManagerUser {...rest} />,
  289: ({ ...rest }: CellComponentProps) => <WorkManagerDescription {...rest} />,
  290: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr1 {...rest} />,
  291: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr1 {...rest} />,
  292: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr1 {...rest} />,
  293: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr2 {...rest} />,
  294: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr2 {...rest} />,
  295: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr2 {...rest} />,
  296: ({ ...rest }: CellComponentProps) => <WorkManagerNumberAttr3 {...rest} />,
  297: ({ ...rest }: CellComponentProps) => <WorkManagerTextAttr3 {...rest} />,
  298: ({ ...rest }: CellComponentProps) => <WorkManagerDateAttr3 {...rest} />,
};

export default workManagerCellComponent;

interface Props extends CellComponentProps<WorkCreatorView> {}

const WorkManagerDepartment = ({ sx = {}, onClick, item }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.department?.name ?? '-'}
  </Box>
);
const WorkManagerJobDuty = ({ sx = {}, onClick, item }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.jobDuty ?? '-'}
  </Box>
);

const WorkManagerUser = (props: Props) => {
  const confirmProps: ConfirmView = {
    title: '담당자 삭제 확인 모달',
    lineBreakChildren: [
      { value: '역할명에서 제외되면 해당 업무를 열람할 수 없습니다.' },
      { value: '제외 하시겠습니까?' },
    ],
  };

  return (
    <UIBuilderTableCellUserSelector
      {...props}
      name="user"
      confirmProps={confirmProps}
    />
  );
};

function WorkManagerDescription({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
  isForm,
}: Props) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      <UncontrolledInput
        name="description"
        placeholder="직접 입력"
        onBlur={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

const WorkManagerNumberAttr1 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkManagerTextAttr1 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkManagerDateAttr1 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkManagerNumberAttr2 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkManagerTextAttr2 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkManagerDateAttr2 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkManagerNumberAttr3 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkManagerTextAttr3 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkManagerDateAttr3 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
