import { useIsMutating } from 'react-query';

const useWorkNameGetIsLoading = () => {
  const isLoading = !!useIsMutating({
    mutationKey: ['work', 'name', 'update'],
  });
  return isLoading;
};

export default useWorkNameGetIsLoading;
