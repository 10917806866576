import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workCarouselBundleMutation from '@front/src/features/work/features/work/features/carousel-bundle/query/mutation';

const workCarouselBundleRepository = {
  useCreate: (commonParams) => {
    const callback = getNoOpCallback();
    const mutate = workCarouselBundleMutation.useCreate(commonParams);
    return {
      run: (params) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useUpdate: (commonParams) => {
    const callback = getNoOpCallback();
    const mutate = workCarouselBundleMutation.useUpdate(commonParams);
    return {
      run: (params) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useDelete: (commonParams) => {
    const callback = getNoOpCallback();
    const mutate = workCarouselBundleMutation.useDelete(commonParams);
    return {
      run: (id) => mutate(id, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};

export default workCarouselBundleRepository;
