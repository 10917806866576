import React from 'react';
import type { TableProps } from '@mui/material/Table';
import Table from '@mui/material/Table';

export default function TableTableUI({ sx = {}, ...rest }: Readonly<TableProps>) {
  return (
    <Table
      stickyHeader
      aria-label="sticky table"
      sx={{
        tableLayout: 'fixed',
        width: 'max-content',
        minWidth: '100%',
        height: 'auto',
        maxHeight: '100%',
        ...sx,
      }}
      {...rest}
    />
  );
}
