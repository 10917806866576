import type { OptionType } from '@front/src/components/Select';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

export const getAffiliatedCompanyPersonOptionList = (list?: ProjectAffiliatedCompanyPersonView[]) =>
  removeDuplicateInObjectArray(
    list?.map((item) => ({
      label: item.affiliatedCompany?.name ?? '-',
      value:
        item.affiliatedCompany?.id?.toString() ??
        `${item.person?.id}${item.person?.affiliatedCompanyId}`,
    }))
  )?.filter((item) => item.label !== '-');

export const removeDuplicateInObjectArray = (list?: OptionType[]) => {
  const uniqueVal = new Set();
  return list?.filter((item) => {
    if (uniqueVal.has(item.value)) return false;
    uniqueVal.add(item.value);
    return true;
  });
};
