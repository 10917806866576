import useManagementAccountContextMenuMutation from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useContextMenuMutation';
import useManagementAccountContextMenuActions from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useContextMenuActions';
import type { UseFormReturn } from 'react-hook-form';
import { type UseFieldArrayReturn } from 'react-hook-form';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {
  formContext: UseFormReturn<ManagementAccountTableFormValues, any, undefined>;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
}

const useManagementAccountTableActions = (props: Props) => {
  const { dataId, sectionId, menuId, formContext, selectedRowIndexListFieldArray } = props;

  const { getValues } = formContext;
  const active = getValues('active');

  const mutations = useManagementAccountContextMenuMutation({
    commonParams: { dataId, sectionId, menuId },
    cellData: active,
    selectedRowIndexListFieldArray,
    formContext,
  });

  const { handleKeyDown } = useManagementAccountContextMenuActions({
    ...mutations,
    cellData: active,
    formContext,
  });

  return {
    onKeyDown: handleKeyDown,
  };
};

export default useManagementAccountTableActions;
