import type { FocusEvent, KeyboardEvent } from 'react';
import React from 'react';
import { Box } from '@mui/material';

import Input from '@front/layouts/Input';
import type { FormikProps } from 'formik';
import type { ProposalCategoryAddParameter } from '@front/ost_campaign/parameter';

interface Props {
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  formik: FormikProps<ProposalCategoryAddParameter>;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const FormAddProposalCategory = ({ formik, onBlur, onKeyDown }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
      width: '100%',
      margin: '10px 0px',
      padding: '10px',
    }}
  >
    {' '}
    <Box
      sx={{
        marginBottom: '10px',
      }}
    >
      {' '}
      <Input
        key={formik.values.name}
        sx={{
          marginTop: '10px',
        }}
        variant="outlined"
        placeholder="신규 제안 구분명을 입력해주세요."
        defaultValue={formik.values.name}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />{' '}
    </Box>{' '}
  </Box>
);

export default FormAddProposalCategory;
