import { useQuery } from 'react-query';
import { affiliatedCompanyPersonCharacterCharacterApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/query/api';
import type { AffiliatedCompanyPersonCharacterCharacterView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/view';

export const affiliatedCompanyPersonCharacterCharacterQuery = {
  usePersonCharacterCharacterListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonCharacterCharacterView[]>({
      queryKey: ['affiliated-company', 'person', 'character', 'character', id],
      queryFn: () =>
        affiliatedCompanyPersonCharacterCharacterApi.getPersonCharacterCharacterList(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
