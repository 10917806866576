import React, { ReactNode, useCallback } from 'react';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { useQueryErrorResetBoundary } from 'react-query';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useDispatch, useSelector } from 'react-redux';

import { dialogAction } from '@front/dialog/action';
import type { RootState } from '@front/services/reducer';

interface Props {
  children: ReactNode;
  to?: string;
}

const RootErrorBoundary = ({ children }: Props) => {
  const { reset } = useQueryErrorResetBoundary();
  const dispatch = useDispatch();
  const { isSessionExpired } = useSelector((root: RootState) => root.login);
  const closeDialogModal = useCallback(() => {
    dispatch(dialogAction.close());
  }, [dispatch]);
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {isSessionExpired && <SessionExpired resetErrorBoundary={resetErrorBoundary} />}
          {!isSessionExpired && (
            <ErrorMessage
              closeDialogModal={closeDialogModal}
              resetErrorBoundary={resetErrorBoundary}
            />
          )}
        </Box>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

function SessionExpired(props: { resetErrorBoundary }) {
  return (
    <>
      <TypographyUI shape={'h5'}>로그인 만료</TypographyUI>
      <TypographyUI shape={'text-l-semibold'}>
        로그인 이후 장시간 사용이 없어 로그아웃되었습니다.
      </TypographyUI>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <ButtonBasicUI
          shape="primary"
          size="medium"
          onClick={() => {
            document.location = '/login';
          }}
        >
          로그인 페이지로 이동
        </ButtonBasicUI>
      </Box>
    </>
  );
}

function ErrorMessage(props: { closeDialogModal: () => void; resetErrorBoundary }) {
  return (
    <>
      <TypographyUI shape={'h5'}>죄송합니다.</TypographyUI>
      <TypographyUI shape={'text-l-semibold'}>
        일시적인 서버 문제가 발생했거나 시스템 점검 중입니다.
      </TypographyUI>
      <TypographyUI shape={'text-l-semibold'}>잠시 후 다시 시도해 주세요.</TypographyUI>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
        }}
      >
        <ButtonBasicUI
          shape="primary"
          size="medium"
          onClick={() => {
            props.closeDialogModal();
            props.resetErrorBoundary();
          }}
        >
          이전 페이지로 돌아가기
        </ButtonBasicUI>
      </Box>
    </>
  );
}

export default RootErrorBoundary;
