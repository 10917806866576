import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsManagementAccountingReadCountLogic } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/provider/useLogic';
import { AccountingSettingsManagementAccountingReadCountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/provider/context';

export { Provider as AccountingSettingsManagementAccountingReadCountProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { detail } = useAccountingSettingsManagementAccountingReadCountLogic(menuId);
  const value = useMemo(
    () => ({
      detail,
    }),
    [detail]
  );
  return (
    <AccountingSettingsManagementAccountingReadCountContext.Provider value={value}>
      {children}
    </AccountingSettingsManagementAccountingReadCountContext.Provider>
  );
};
