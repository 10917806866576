import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import type { AffiliatedCompanyPersonCharacterHobbyUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/types/parameter';

const url = {
  getPersonCharacterHobby: (id?) => `${personBaseUrl}/${id}/hobby`,
  createPersonCharacterHobby: (id?) => `${personBaseUrl}/${id}/hobby`,
  deletePersonCharacterHobby: () => `${personBaseUrl}/hobby`,
  updatePersonCharacterHobby: (id?) => `${personBaseUrl}/hobby/${id}`,
};

export const affiliatedCompanyPersonHobbyApi = {
  getPersonCharacterHobby: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonCharacterHobby(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPersonCharacterHobby: async (id?: number, menuId?) => {
    await axios.post(url.createPersonCharacterHobby(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonCharacterHobby: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deletePersonCharacterHobby(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updatePersonCharacterHobby: async (
    params: AffiliatedCompanyPersonCharacterHobbyUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonCharacterHobby(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
