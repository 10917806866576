import { create } from 'zustand';
import type { AffiliatedCompanyGiftHistoryGetParameter } from '@front/src/features/affiliated-company/features/gift-history/types/parameter';

interface State {
  parameter: AffiliatedCompanyGiftHistoryGetParameter;
  readOnly?: boolean;
}

interface Actions {
  setParameter: (parameter) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useAffiliatedCompanyGiftHistoryState };

const useState = create<State & Actions>((set) => ({
  parameter: {
    type: '',
    name: '',
  },
  setParameter: (parameter) => set(() => ({ parameter })),
  readOnly: false,
  setReadOnly: (readOnly) => set(() => ({ readOnly })),
}));
