import React, { useCallback, useContext, useMemo } from 'react';
import { AffiliatedCompanyProjectListContext } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/context';
import { Box } from '@mui/material';
import { AffiliatedCompanyProjectListItem } from '@front/src/features/affiliated-company/features/project/widgets/list/components/list-item';

export { List as AffiliatedCompanyProjectList };

const List = () => {
  const { list } = useContext(AffiliatedCompanyProjectListContext);
  const projectIdList = useMemo(() => {
    if (!list) return [];
    return Array.from(new Set(list.map((l) => l.projectId)));
  }, [list]);
  const countList = useMemo(() => {
    if (!list) return [];
    return [0].concat(projectIdList.map((id) => list.filter((l) => l.projectId === id).length));
  }, [projectIdList, list]);
  const getPrefixSumList = useCallback(() => {
    if (countList.length === 0) return [];
    const result: number[] = [];
    countList.reduce((a = 0, b = 0) => {
      const temp = a + b;
      result.push(temp);
      return temp;
    }, 0);
    return result;
  }, [countList]);
  const prefixSumList = getPrefixSumList();
  if (list?.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '2544px',
          height: '48px',
          fontSize: '13px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          데이터가 없습니다.
        </Box>
      </Box>
    );
  }
  return (
    <>
      {projectIdList?.map((id, index) => (
        <AffiliatedCompanyProjectListItem
          key={id}
          countList={countList}
          prefixSumList={prefixSumList}
          index={index}
        />
      ))}
    </>
  );
};
