import React, { useCallback, useContext } from 'react';
import { ProjectSalesInfoEstimationEstimationContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/context';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoEstimationEstimationCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useSnackbar } from '@front/src/features/snackbar';
import { useShallow } from 'zustand/react/shallow';

export { EstimationButtonGroup as ProjectSalesInfoEstimationEstimationButtonGroup };

const EstimationButtonGroup = () => {
  const {
    d: { list },
    h: { onDelete },
  } = useContext(ProjectSalesInfoEstimationEstimationContext);
  const { idList, setIdList } = useProjectSalesInfoEstimationEstimationState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen } = useProjectSalesInfoEstimationEstimationCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const { show } = useSnackbar();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      if (typeof list === 'undefined') {
        return;
      }
      for (const id of idList) {
        const target = list.find((item) => item.id === id);
        if (target?.isConfirmed) {
          show({ message: '확정된 견적은 삭제할 수 없습니다.' });
          return;
        }
      }
      onDelete({ idList });
      setIdList([]);
    });
  }, [idList, confirm, setIdList, onDelete, show, list]);
  const handleAddClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const { readOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={handleAddClick}
        disabled={readOnly}
      >
        견적추가
      </Button>
    </>
  );
};
