import React from 'react';
import NotificationFeature from '@front/src/features/notification';
import NoteFeature from '@front/src/features/note';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import ApprovalFeature from 'src/features/drawer-approval';
import ApprovalDocumentFeature from '@front/src/features/drawer-approval-document';
import ApprovalBoxFeature from '@front/src/features/approval-box';

export default function ResizableDrawerWrapper() {
  const { leftDrawerState, isRightDrawerOpen } = useGlobalNavBarState(
    useShallow((state) => ({
      leftDrawerState: state.leftDrawerState,
      isRightDrawerOpen: state.isRightDrawerOpen,
    }))
  );

  return (
    <>
      {leftDrawerState === 'notification' && <NotificationFeature />}
      {leftDrawerState === 'approval' && <ApprovalFeature />}
      {leftDrawerState === 'approval-document' && <ApprovalDocumentFeature />}
      {leftDrawerState === 'approval-box' && <ApprovalBoxFeature />}
      {isRightDrawerOpen && <NoteFeature />}
    </>
  );
}
