import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-table.module.scss';
import { ProjectSalesInfoBidInfoCreateModalBtnGroup } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-btn-group';
import { ProjectSalesInfoBidInfoCreateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/widgets/context';
import {
  convertToEstimationOptionList,
  YNOptionList,
} from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/utils/constants';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import DateTimePickerWithHookForm from "@front/src/components/hook-form/DateTimePicker";

export { ModalTable as ProjectSalesInfoBidInfoCreateModalTable };

const ModalTable = () => {
  const {
    d: { estimationIdList },
  } = useContext(ProjectSalesInfoBidInfoCreateModalContext);

  const methods = useForm<ProjectSalesInfoBidInfoParams>({
    defaultValues: {
      startDate: null,
      endDate: null,
      estimationId: '',
      isBid: '',
      isConfirmed: '',
      note: '',
      bidNoticeFile: '',
      bidGuideLineFile: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <div className={classes.wrapper}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th
                  center={true}
                  width="140px"
                >
                  공고일
                </Th>
                <OldTd>
                  <DatePickerWithHookForm
                    name="startDate"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>마감일</Th>
                <OldTd>
                  <DateTimePickerWithHookForm
                    name="endDate"
                    openTo="day"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>견적 선택</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="estimationId"
                    optionList={convertToEstimationOptionList(estimationIdList)}
                    width="200px"
                    defaultLabel="선택"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>한풍투찰 여부</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="isBid"
                    optionList={YNOptionList}
                    width="150px"
                    defaultLabel="선택"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>최종 여부</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="isConfirmed"
                    optionList={YNOptionList}
                    defaultLabel="선택"
                    width="150px"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>입찰공고문 첨부</Th>
                <OldTd>
                  <div className={classes.container}>
                    <HookFormFileUploader name="bidNoticeFile" />
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>입찰지침서 첨부</Th>
                <OldTd>
                  <div className={classes.container}>
                    <HookFormFileUploader name="bidGuideLineFile" />
                  </div>
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>비고</Th>
                <OldTd>
                  <HookFormTextarea
                    name="note"
                    minRows={2}
                    resize="none"
                    variable={true}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ProjectSalesInfoBidInfoCreateModalBtnGroup />
      </div>
    </FormProvider>
  );
};
