import React, { useCallback } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import dayjs from 'dayjs';
import { DATE_FORMAT, hasImageExtension } from '@front/src/utils';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import Box from '@mui/material/Box';
import WorkReferenceTableBodyRowFile from '@front/src/features/work/features/work/features/reference/components/File';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import useWorkReferenceUpdateFile from '@front/src/features/work/features/work/features/reference/repository/useUpdateFile';
import { useQueryClient } from 'react-query';
import useImageDraw from '@front/src/features/image-draw/hooks/useImageDraw';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import useGetUIBuilderTableFileAuthState from '@front/src/components/ui-builder/table/hooks/useGetFileAuthState';

const workReferenceCellComponent = {
  21: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowCreatedAt {...rest} />,
  22: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowUserName {...rest} />,
  23: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowFile {...rest} />,
  104: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr1 {...rest} />,
  105: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr1 {...rest} />,
  106: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr1 {...rest} />,
  107: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr2 {...rest} />,
  108: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr2 {...rest} />,
  109: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr2 {...rest} />,
  110: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowNumberAttr3 {...rest} />,
  111: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowTextAttr3 {...rest} />,
  112: ({ ...rest }: CellComponentProps) => <WorkReferenceTableRowDateAttr3 {...rest} />,
};

export default workReferenceCellComponent;

interface Props extends CellComponentProps<WorkReferenceView> {}

const WorkReferenceTableRowCreatedAt = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={()=>{onClick && onClick()}}
    sx={sx}
  >
    {item.createdAt ? dayjs(item.createdAt).format(DATE_FORMAT) : '-'}
  </Box>
);

const WorkReferenceTableRowUserName = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={()=>{onClick && onClick()}}
    sx={sx}
  >
    {item.user.name}
  </Box>
);

const WorkReferenceTableRowFile = (props: Readonly<Props>) => {
  const {
    item,
    isEditMode,
    onClick,
    sx = {},
    readOnly,
    menuId,
    sectionId,
    dataId,
    formContext,
  } = props;

  const { open: openImageDraw } = useImageDraw();
  const onOpenImageModal = useCallback(() => {
    openImageDraw(item.fileItem);
  }, [item]);

  const { onDownload } = useFileItemDownload({ fileKey: item.fileItem?.fileKey });
  const { run: onUpdateFile, setCallback } = useWorkReferenceUpdateFile({
    menuId,
    sectionId,
    dataId,
  });

  const { setRecentUpdatedId } = useGetUIBuilderTableEditState(formContext);
  const { setAndOpen } = useGetUIBuilderTableFileAuthState({
    formContext,
  });

  const queryClient = useQueryClient();

  setCallback({
    onSuccess: (data) => {
      setRecentUpdatedId(data.data);
      const prevData: WorkReferenceView[] | undefined = queryClient.getQueryData([
        'work',
        'reference',
        dataId,
      ]);
      const find = prevData?.find((p) => p.id === data.data);
      const findFileItemId = find ? find.fileItem?.id : undefined;
      if (!findFileItemId) return;
      setAndOpen(findFileItemId);
    },
  });

  const renderContent = () => {
    if (isEditMode && !readOnly && onUpdateFile) {
      return (
        <WorkReferenceTableBodyRowFile
          {...props}
          item={item}
          onUpdateFile={onUpdateFile}
        />
      );
    }
    if (!item.fileItem) return <>-</>;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <FileDisplayText
          fileName={item.fileItem?.filename}
          onClick={onDownload}
          align="left"
        />

        {hasImageExtension(item.fileItem?.ext) && (
          <ButtonIconUI
            shape="tertiary"
            size="medium"
            onClick={onOpenImageModal}
          >
            <FontAwesomeIcon icon="magnifying-glass" />
          </ButtonIconUI>
        )}
      </Box>
    );
  };
  return (
    <Box
      onClick={()=>{onClick && onClick()}}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

const WorkReferenceTableRowNumberAttr1 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr1 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr1 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkReferenceTableRowNumberAttr2 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr2 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr2 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkReferenceTableRowNumberAttr3 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkReferenceTableRowTextAttr3 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkReferenceTableRowDateAttr3 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
