import { projectSalesInfoSectionComponent } from '@front/src/features/project-sales-info/components/section-component';
import type React from 'react';
import { affiliatedCompanySectionComponent } from '@front/src/features/affiliated-company/components/section-component';
import { affiliatedCompanyPersonSectionComponent } from '@front/src/features/affiliated-person/components/section-component';
import { ostSectionComponent } from '@front/src/features/ost/components/section-component';
import { projectSalesManagementSectionComponent } from '@front/src/features/project-sales-management/components/section-component';
import { proceedingsSectionComponent } from '@front/src/features/proceedings/components/section-component';
import { accountingSectionComponent } from '@front/src/features/accounting/components/section-component';
import { myPageSectionComponent } from '@front/src/features/my-page/components/section-component';
import workWorkSectionComponent from '@front/src/features/work/features/work/components/sectionComponent';
import hRCardSectionComponent from '@front/src/features/human-resources/hr-card/components/sectionComponent';
import departmentManagementSectionComponent from '@front/src/features/human-resources/department-management/components/sectionComponent';

export interface SectionComponentProps {
  name?: string;
  sectionId?: number;
  readOnly: boolean;
  dataId?: number;
  menuId?: number;
}

/**
 * 섹션 공통 테이블 셀 랜더링 컴포넌트 묶음
 * @remarks 객체의 key 는 mn_section 메타의 id 속성 값을 의미한다.
 */
export interface SectionComponent {
  [key: number]: React.FunctionComponent<SectionComponentProps>;
}

export const sectionComponent: SectionComponent = {
  ...workWorkSectionComponent,
  ...projectSalesInfoSectionComponent,
  ...affiliatedCompanySectionComponent,
  ...affiliatedCompanyPersonSectionComponent,
  ...ostSectionComponent,
  ...projectSalesManagementSectionComponent,
  ...proceedingsSectionComponent,
  ...hRCardSectionComponent,
  ...departmentManagementSectionComponent,
  ...accountingSectionComponent,
  // ...approvalSectionComponent,
  ...myPageSectionComponent,
};

/**
 * 섹션 컴포넌트 등록
 * @param sectionId
 * @param component
 */
export const register = (
  sectionId: number,
  component: React.FunctionComponent<SectionComponentProps>
) => {
  if (!sectionComponent[sectionId]) {
    sectionComponent[sectionId] = component;
  }
};

/**
 * 섹션 컴포넌트 삭제
 * @param seciontId
 */
export const unregister = (seciontId: number) => {
  delete sectionComponent[seciontId];
};
