import React from 'react';
import { ProjectSalesInfoSubjectReviewModalComplexInformationCreateProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/widgets/provider';
import { ProjectSalesInfoSubjectReviewModalComplexInformationCreateButton } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/create/components/create-button';

export { Widget as ProjectSalesInfoSubjectReviewModalComplexInformationCreateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalComplexInformationCreateProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewModalComplexInformationCreateButton />
  </ProjectSalesInfoSubjectReviewModalComplexInformationCreateProvider>
);
