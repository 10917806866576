// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kyw6R7yvmY7o6clKk110{cursor:pointer}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/add-delete-table/item-table-row.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".cursor__pointer {\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor__pointer": `kyw6R7yvmY7o6clKk110`
};
export default ___CSS_LOADER_EXPORT___;
