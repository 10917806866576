import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import type { SalesAccountingAmountInformationGetParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import { AccountingUploadSalesInfoExpectModalQuery } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/query/query';
import { AccountingUploadSalesInfoExpectModalMutation } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/query/mutation';

export { Repository as AccountingUploadSalesInfoExpectModalRepository };

const Repository = {
  useListGet: (params: SalesAccountingAmountInformationGetParameter, menuId) => {
    const { data, remove } = AccountingUploadSalesInfoExpectModalQuery.useGetList(params, menuId);
    return {
      data,
      remove,
    };
  },
  useExpectOrPerformanceListGet: (params: SalesAccountingAmountInformationGetParameter, menuId) => {
    const { data, remove } =
      AccountingUploadSalesInfoExpectModalQuery.useExpectOrPerformanceListGet(params, menuId);
    return {
      data,
      remove,
    };
  },
  useUpdate: (menuId) => {
    const { mutate, isLoading } = AccountingUploadSalesInfoExpectModalMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
};
