import type { RefObject } from 'react';
import React, { useCallback } from 'react';
import Input from '@front/layouts/Input';
import { Box, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import type { AffiliatedCompanySelectWidgetValueProp } from '@front/src/features/affiliated-company-selector/types/affiliated-company-selector-types';
import { useCustomDialog } from '@front/src/features/dialog';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

export type AffiliatedCompanySelectorProp = {
  value: AffiliatedCompanySelectWidgetValueProp;
  onCompanySelect: (company: AffiliatedCompanyShortView | undefined) => void;
  onPersonSelect: (person: AffiliatedCompanyPersonShortView | undefined) => void;
  onButtonClick: () => void;
  disabled?: boolean;
  defaultValue?: string;
  placeHolder?: string;
  children: React.ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
  usePersonSelector: boolean;
  from?: string;
};

export default function AffiliatedCompanySelector(props: AffiliatedCompanySelectorProp) {
  const {
    value,
    onCompanySelect,
    onPersonSelect,
    onButtonClick,
    children,
    disabled,
    inputRef,
    usePersonSelector,
    from,
  } = props;
  const { confirm } = useCustomDialog();
  const defaultValue = props.defaultValue ? props.defaultValue : '';
  const handleInputClick = useCallback(() => {
    if (disabled) return;
    if (!usePersonSelector && value.companyId) {
      confirm({
        confirmText: '확인',
        children: '삭제하시겠습니까?',
        afterConfirm: () => {
          onCompanySelect(undefined);
        },
      });
      return;
    }
    if (usePersonSelector && value.companyId && !value.personId) {
      onButtonClick();
      return;
    }
    if (
      (from !== 'company' && (value.companyId || value.personId)) ||
      (from === 'company' && value.companyId && value.personId)
    ) {
      confirm({
        confirmText: '확인',
        children: '삭제하시겠습니까?',
        afterConfirm: () => {
          onPersonSelect(undefined);
        },
      });
      return;
    }
    onButtonClick();
  }, [onButtonClick, value, confirm, onCompanySelect, onPersonSelect, usePersonSelector, disabled]);
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {!disabled && (
        <InputAdornment
          position="end"
          sx={{
            marginRight: '10px',
            position: 'absolute',
            top: '50%',
            right: '10px',
            zIndex: '1',
          }}
          onClick={onButtonClick}
        >
          <FontAwesomeIcon
            icon="building"
            style={{
              fontSize: '16px',
              color: ColorPalette._386dd6,
              cursor: 'pointer',
            }}
          />
        </InputAdornment>
      )}
      <Input
        inputRef={inputRef}
        value={value.textValue ? value.textValue : defaultValue}
        placeholder={props.placeHolder}
        readOnly={true}
        disabled={disabled!}
        onClick={handleInputClick}
        inputProps={{
          sx: {
            cursor: 'pointer !important',
            textOverflow: 'ellipsis',
            width: '88%',
          },
        }}
        sx={{
          cursor: 'pointer',
        }}
      />
      {children}
    </Box>
  );
}
