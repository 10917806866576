import { create } from 'zustand';

interface State {
  id?: number;
  setId: (id?: number) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export { useState as useProjectSalesInfoSubjectReviewModalState };

const useState = create<State>((set) => ({
  setId: (id) => set(() => ({ id })),
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}));
