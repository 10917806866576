import { TableRow } from '@mui/material';
import React, { useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { Th } from '@front/src/components/layout/table/th';
import classes from '@front/src/features/affiliated-company/features/outline/features/bank-info/components/file.module.scss';

import { AffiliatedCompanyOutlineBankInfoContext } from '@front/src/features/affiliated-company/features/outline/features/bank-info/widgets/context';
import type { AffiliatedCompanyOutlineBankBookFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';

import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { downloadFile } from '@front/src/utils';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';

export { File as AffiliatedCompanyOutlineBankBookFile };

const File = () => {
  const {
    d: { bankBookFile },
    h: { onBankBookFileUpdate },
  } = useContext(AffiliatedCompanyOutlineBankInfoContext);
  const methods = useForm<AffiliatedCompanyOutlineBankBookFileUpdateParameter>({
    values: {
      bankBookFile,
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onBankBookFileUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <Th>통장사본</Th>
        <OldTd>
          {readOnly ? (
            <FileDisplayText
              fileName={bankBookFile?.filename ?? ''}
              onClick={downloadFile(bankBookFile?.id)}
              align="left"
            />
          ) : (
            <div className={classes.container}>
              <HookFormFileUploader
                name="bankBookFile"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </div>
          )}
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
