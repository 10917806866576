import { createAction } from 'typesafe-actions';

import type { OstId, OstShortVO } from '@front/ost/domain';
import type {
  CampaignRewardsView,
  OstRewardManagementDetailMonthDetailView,
  OstRewardManagementDetailView,
  OstRewardManagementMemoId,
  OstRewardManagementMemoView,
  OstRewardManagementTotalStatusView,
} from '@front/ost_reward/domian';
import type {
  OstRewardManagementMemoCreateParameter,
  OstRewardManagementMemoUpdateParameter,
} from '@front/ost_reward/parameter';
import type { OstRewardManagementSearchQuery } from '@front/ost_reward/query';
import type { CampaignId } from '@front/ost_campaign/domain';

export enum OstRewardManagementAction {
  setDrawerIsOpen = 'ost-reward-management/drawer-is-open/set',
  setIsLoading = 'ost-reward-management/is-loading/set',
  setId = 'ost-reward-management/id/set',
  setDetail = 'ost-reward-management/detail/set',
  getList = 'ost-reward-management/list/get',
  setList = 'ost-reward-management/list/set',
  getTotalStatusList = 'ost-reward-management/total-status/get',
  setTotalStatusList = 'ost-reward-management/total-status/set',
  createMemo = 'ost-reward-management/memo/create',
  setMemoList = 'ost-reward-management/memo-list/set',
  updateMemo = 'ost-reward-management/memo/update',
  deleteMemo = 'ost-reward-management/memo/delete',
  setMemoFilter = `ost-reward-management/memo-filter/set`,
  setIsMemoLoading = `ost-reward-management/is-memo-loading/set`,
  getMonthDetail = `ost-reward-management/month-detail/get`,
  setMonthDetail = `ost-reward-management/month-detail/set`,
  setMonth = `ost-reward-management/month/set`,

  getCampaignRewards = `ost-reward-management/campaign-rewards/get`,
  setCampaignRewards = `ost-reward-management/campaign-rewards/set`,

  fixResult = `ost-reward-management/result/fix`,
}

export const ostRewardManagementAction = {
  setDrawerIsOpen: createAction(OstRewardManagementAction.setDrawerIsOpen)<boolean>(),
  setIsLoading: createAction(OstRewardManagementAction.setIsLoading)<boolean>(),
  setId: createAction(OstRewardManagementAction.setId)<OstId>(),
  setDetail: createAction(OstRewardManagementAction.setDetail)<OstRewardManagementDetailView>(),
  getList: createAction(OstRewardManagementAction.getList)(),
  setList: createAction(OstRewardManagementAction.setList)<OstShortVO[]>(),
  getTotalStatusList: createAction(OstRewardManagementAction.getTotalStatusList)(),
  setTotalStatusList: createAction(OstRewardManagementAction.setTotalStatusList)<
    OstRewardManagementTotalStatusView[]
  >(),
  createMemo: createAction(
    OstRewardManagementAction.createMemo
  )<OstRewardManagementMemoCreateParameter>(),
  setMemoList: createAction(OstRewardManagementAction.setMemoList)<OstRewardManagementMemoView[]>(),
  updateMemo: createAction(
    OstRewardManagementAction.updateMemo
  )<OstRewardManagementMemoUpdateParameter>(),
  deleteMemo: createAction(OstRewardManagementAction.deleteMemo)<OstRewardManagementMemoId>(),
  setMemoFilter: createAction(
    OstRewardManagementAction.setMemoFilter
  )<OstRewardManagementSearchQuery>(),
  setIsMemoLoading: createAction(OstRewardManagementAction.setIsMemoLoading)<boolean>(),

  getMonthDetail: createAction(OstRewardManagementAction.getMonthDetail)(),
  setMonthDetail: createAction(
    OstRewardManagementAction.setMonthDetail
  )<OstRewardManagementDetailMonthDetailView>(),
  setMonth: createAction(OstRewardManagementAction.setMonth)<number>(),

  getCampaignRewards: createAction(OstRewardManagementAction.getCampaignRewards)<CampaignId>(),
  setCampaignRewards: createAction(
    OstRewardManagementAction.setCampaignRewards
  )<CampaignRewardsView>(),

  fixResult: createAction(OstRewardManagementAction.fixResult)(),
};
