import { createContext } from 'react';
import type { ProjectSalesInfoAffiliatedCompanyWebHardView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';
import { noOp } from '@front/src/utils';

export { context as ProjectSalesInfoAffiliatedCompanyWebHardContext };

interface State {
  d: {
    list?: ProjectSalesInfoAffiliatedCompanyWebHardView[];
  };
  h: {
    onDelete: (idList) => void;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
});
