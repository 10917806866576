import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectComplexInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/parameter';

export { context as ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext };

interface State {
  onUpdate: (params: ProjectComplexInformationParameter) => void;
}

const context = createContext<State>({
  onUpdate: noOp,
});
