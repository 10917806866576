import React, { memo, useCallback } from 'react';
import type { FormikProps } from 'formik';
import { Box } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { CampaignMemoId, CampaignMemoVO } from '@front/ost_campaign/domain';
import type { CampaignMemoUpdateParameter } from '@front/ost_campaign/parameter';
import FormUpdateMemoService from '@front/ost_campaign/service/memo/formUpdate';
import TitleItemMemo from '@front/ost_campaign/view/memo/title';
import ContentItemMemo from '@front/ost_campaign/view/memo/content';
import { ColorPalette } from '@front/assets/theme';
import type { RootState } from '@front/services/reducer';
import { campaignAction } from '@front/ost_campaign/action';
import useDialog from '@front/dialog/hook';

interface Props {
  formik: FormikProps<CampaignMemoUpdateParameter>;
  memo: CampaignMemoVO;
}

const MemoItemService = ({ formik, memo }: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const login = useSelector((root: RootState) => root.login.detail, shallowEqual);

  const clickModifyButtonMemoHandler = useCallback(
    (memo: CampaignMemoUpdateParameter) => () => {
      formik.setValues({
        id: memo.id,
        description: memo.description,
      });
    },
    [formik]
  );

  const deleteMemoHandler = useCallback(
    (campaignMemoId: CampaignMemoId) => () => {
      confirm({
        children: '해당 메모를 삭제하시겠습니까?',
        afterConfirm: () => {
          dispatch(campaignAction.deleteMemo(campaignMemoId));
        },
        confirmText: '확인',
      });
    },
    [confirm, dispatch]
  );

  const saveMemoHandler = useCallback(
    (campaignMemoId: CampaignMemoId) => () => {
      if (formik.values.id === campaignMemoId) {
        formik.handleSubmit();
      }
    },
    [formik]
  );

  const cancelMemoHandler = useCallback(
    (campaignMemoId: CampaignMemoId) => () => {
      if (formik.values.id === campaignMemoId) {
        formik.resetForm();
      }
    },
    [formik]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: ColorPalette._ffffff,
        padding: '15px',
      }}
    >
      <TitleItemMemo
        formik={formik}
        login={login}
        memo={memo}
        onModify={clickModifyButtonMemoHandler}
        onDelete={deleteMemoHandler}
      />
      {formik.values.id !== memo.id && <ContentItemMemo memo={memo} />}
      {formik.values.id === memo.id && (
        <FormUpdateMemoService
          formik={formik}
          memo={memo}
          onSave={saveMemoHandler}
          onCancel={cancelMemoHandler}
        />
      )}
    </Box>
  );
};

const ItemMemoService = memo(MemoItemService);

export default ItemMemoService;
