import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyOutlineInfoUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/info/types/parameter';
import { affiliatedCompanyOutLineInfoApi } from '@front/src/features/affiliated-company/features/outline/features/info/query/api';

export const affiliatedCompanyOutlineInfoMutation = {
  useOutlineInfoUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineInfoUpdateParameter) =>
        affiliatedCompanyOutLineInfoApi.updateOutlineInfo(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'outline']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
