import type { FocusEvent, KeyboardEvent } from 'react';
import React, { memo, useContext } from 'react';
import { Box } from '@mui/material';

import Button from '@front/layouts/Button';
import ModalLayout from '@front/layouts/ModalLayout';
import FormAddCampaign from '@front/ost_campaign/view/modal/FormAddCampaign';
import { FormikContext } from 'formik';
import type { DefaultFunction } from '@front/type/Function';

interface Props {
  open: boolean;
  onClose: DefaultFunction;
  onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const CampaignAddModalView = ({ open, onClose, onBlur, onKeyUp }: Props) => {
  const formik = useContext(FormikContext);

  return (
    <ModalLayout
      title="신규 캠페인 등록"
      width="20dvw"
      height="20dvh"
      open={open}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => formik.handleSubmit()}
            sx={{
              marginRight: '20px',
            }}
          >
            등록
          </Button>
          <Button
            shape={'basic2'}
            onClick={onClose}
          >
            취소
          </Button>
        </Box>
      }
    >
      <FormAddCampaign
        onBlur={onBlur}
        onKeyUp={onKeyUp}
      />
    </ModalLayout>
  );
};

const CampaignAddModal = memo(CampaignAddModalView);

export default CampaignAddModal;
