import React from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import VoteHistoryItem from '@front/personnel/view/Page/Log/Vote/Item';

const VoteHistoryList = () => {
  const [voteLogList] = useSelector(
    (root: RootState) => [root.personnel.voteLogList],
    shallowEqual
  );
  return (
    <>
      {voteLogList.length === 0 ? (
        <TableRow>
          <OldTd colSpan={10}>{'투찰 이력이 없습니다.'}</OldTd>
        </TableRow>
      ) : (
        <>
          {voteLogList.map((item, index) => (
            <VoteHistoryItem
              key={item.id}
              item={item}
              index={index}
            />
          ))}
        </>
      )}
    </>
  );
};

export default VoteHistoryList;
