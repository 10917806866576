import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalDocumentForm from '@front/src/features/drawer-approval-document/components/ApprovalDocumentForm';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useShallow } from 'zustand/react/shallow';
import ApprovalDocumentDetailWidget from '@front/src/components/Drawer/ApprovalDocumentDetailWidget';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { useDocumentMutationLoading } from '@front/src/features/drawer-approval-document/hooks/useDocumentMutationLoading';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import { useQueryClient } from 'react-query';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';

export default function ApprovalDocumentContent() {
  const { noteId } = useIdGroup();
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const { params, documentId, setParams } = useApprovalDocumentStore(
    useShallow((state) => ({
      params: state.params,
      documentId: state.documentId,
      setParams: state.setParams,
    }))
  );
  const queryClient = useQueryClient();
  const documentDetail: any = queryClient.getQueryData([
    'approval-document',
    documentId,
    params,
    undefined,
  ]);
  const resetBoxDetailState = useApprovalBoxStore((state) => state.resetBoxDetailState);
  const isLoading = useDocumentMutationLoading();

  const onClose = () => {
    setLeftDrawerState('');
    resetBoxDetailState();
  };

  useEffect(() => {
    setParams({
      where: 'approval-document',
      noteId: noteId,
    });
  }, [noteId]);

  return (
    <Box sx={{ ...SX_MODAL }}>
      <BackdropLoadingSpinnerUI
        sx={{
          position: 'absolute',
        }}
        open={isLoading}
      />
      <Box sx={{ ...SX_HEADER }}>
        <Typography sx={{ ...SX_HEADER_TITLE }}>기안서 열람</Typography>
        <CloseIcon
          onClick={onClose}
          sx={{ ...SX_CLOSE }}
        />
      </Box>
      <ApprovalDocumentForm item={documentDetail} />
      <Box sx={{ ...SX_BODY }}>
        <ApprovalDocumentDetailWidget documentDetail={documentDetail} />
      </Box>
    </Box>
  );
}

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  boxShadow: '20px 0px 10px rgba(0,0,0,0.15)',
  overflow: 'hidden',
  position: 'relative',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_BODY = {
  height: 'calc(100dvh - 102px)',
  overflow: 'auto',
  padding: '14px',
};

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};

const SX_CLOSE = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  width: '18px',
  height: '18px',
};
