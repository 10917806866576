import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useSalesInfoActivityListLogic } from '@front/src/features/project-sales-info/features/activity/widgets/list/provider/useLogic';
import { SalesInfoActivityListContext } from '@front/src/features/project-sales-info/features/activity/widgets/list/provider/context';

export { Provider as SalesInfoActivityListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { categoryList } = useSalesInfoActivityListLogic(menuId);
  const value = useMemo(() => ({ categoryList }), [categoryList]);
  return (
    <SalesInfoActivityListContext.Provider value={value}>
      {children}
    </SalesInfoActivityListContext.Provider>
  );
};
