import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';

export { Body as AccountingUploadEcountDataBody };

interface Props {
  children: ReactNode;
}

const Body = ({ children }: Props) => <Box sx={{}}>{children}</Box>;
