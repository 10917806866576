import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

import WorkInterimReportDateRow from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/components/Row';
import workInterimReportDateQuery from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/query/query';

export default function WorkInterimReportDateFeature(props: Readonly<SectionComponentProps>) {
  const { menuId, dataId, sectionId } = props;
  const { data: list } = workInterimReportDateQuery.useGetList({
    menuId,
    dataId,
    sectionId,
  });
  return (
    <>
      {list?.map((item, index) => (
        <WorkInterimReportDateRow
          {...props}
          item={item}
          index={index}
          key={item.id}
          size={list?.length}
        />
      ))}
    </>
  );
}
