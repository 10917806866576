import workCarouselCreateMutation from '@front/src/features/work/features/work/features/carousel/features/create/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

const useWorkCarouselCreate = (commonParams) => {
  const callback = getNoOpCallback();
  const mutate = workCarouselCreateMutation.useCreate(commonParams);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkCarouselCreate;
