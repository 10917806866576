import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyOutlineInfoContext } from '@front/src/features/affiliated-company/features/outline/features/info/widgets/contex';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { Table as AffiliatedCompanyOutlineInfoTable };

const Table = () => {
  const {
    d: { detailOutline },
    h: { onOutlineInfoUpdate },
  } = useContext(AffiliatedCompanyOutlineInfoContext);
  const methods = useForm<{
    foundationAnniversary: string | null;
    numberOfEmployees: string;
    salesAmount: string;
  }>({
    values: {
      foundationAnniversary: detailOutline?.foundationAnniversary ?? '',
      numberOfEmployees: detailOutline?.numberOfEmployees
        ? String(detailOutline?.numberOfEmployees)
        : '',
      salesAmount: detailOutline?.salesAmount ? detailOutline.salesAmount.toLocaleString() : '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onOutlineInfoUpdate({
      ...data,
      foundationAnniversary: formatDateOrNull(data.foundationAnniversary),
      numberOfEmployees: Number(data.numberOfEmployees),
      salesAmount: Number(data.salesAmount.split(',').join('')),
    });
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow key={detailOutline?.foundationAnniversary ?? Math.random()}>
        <Th>창립기념일</Th>
        <OldTd>
          <DatePickerWithHookForm
            name="foundationAnniversary"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
      <TableRow key={detailOutline?.numberOfEmployees ?? Math.random()}>
        <Th>사원수</Th>
        <OldTd>
          <HookFormInput
            width="10%"
            type="number"
            name="numberOfEmployees"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
      <TableRow key={detailOutline?.salesAmount ?? Math.random()}>
        <Th>매출규모</Th>
        <OldTd>
          <HookFormInput
            width="30%"
            inputType={InputType.AMOUNT}
            name="salesAmount"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
