import React from 'react';
import { ProjectSalesInfoCMPaymentHistoryCreateModalProvider } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/widgets/provider';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { ProjectSalesInfoCMPaymentHistoryCreateModalTable } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/components/CM-payment-history-create-modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Widget as ProjectSalesInfoCMPaymentHistoryCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const { Layout } = collectionModalLayout;
  const { isCreateModalOpen, setIsCreateModalOpen } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      isCreateModalOpen: state.isCreateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    }))
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isCreateModalOpen}
      onClose={() => setIsCreateModalOpen()}
      title="CM 지급 내역 추가"
    >
      <ProjectSalesInfoCMPaymentHistoryCreateModalProvider menuId={menuId}>
        <Layout width="80%">
          <ProjectSalesInfoCMPaymentHistoryCreateModalTable />
        </Layout>
      </ProjectSalesInfoCMPaymentHistoryCreateModalProvider>
    </ModalLayout>
  );
};
