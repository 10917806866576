import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@front/layouts/Button';
import { PaymentStatusModal } from '@front/ost/view/modal/PaymentStatusModal';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { CampaignId } from '@front/ost_campaign/domain';

interface Props {
  name: string;
  campaignId: CampaignId;
}

export const PaymentStatusModalService = ({ name, campaignId }: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const onClick = useCallback(() => {
    dispatch(ostRewardManagementAction.getCampaignRewards(campaignId));
    setOpen(true);
  }, [dispatch, setOpen, campaignId]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      {open && (
        <PaymentStatusModal
          name={name}
          open={open}
          onClose={onClose}
        />
      )}
      <Button
        shape={'small'}
        onClick={onClick}
      >
        지급현황
      </Button>
    </>
  );
};
