import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import type { WorkReviewerView } from '@front/src/features/work/features/work/features/reviewer/types/view';
import { ColorPalette } from '@front/assets/theme';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import useWorkReviewerState from '@front/src/features/work/features/work/features/reviewer/useState';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import { useCustomDialog } from '@front/src/features/dialog';
import useWorkReviewerTableRowMutation from '@front/src/features/work/features/work/features/reviewer/hooks/useTableRowMutation';
import useWorkReviewerForm from '@front/src/features/work/features/work/features/reviewer/hooks/useForm';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NoteIcon from '@mui/icons-material/Note';
import DeleteIcon from '@mui/icons-material/Delete';

export default function WorkReviewerTableRow(props: UIBuilderTableRowProps<WorkReviewerView>) {
  const { item, sectionId, dataId, menuId, onCloseEditModal } = props;
  const { recentUpdatedId, editOpenList, addEditOpenId } = useWorkReviewerState(
    useShallow((state) => ({
      recentUpdatedId: state.recentUpdatedId,
      editOpenList: state.editOpenList,
      addEditOpenId: state.addEditOpenId,
    }))
  );
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { alert } = useCustomDialog();

  const { methods } = useWorkReviewerForm({
    item,
  });

  const { handleSubmit, reset } = methods;

  const { onUpdate, onCreate, onSeqUp, onSeqDown, onRemove } = useWorkReviewerTableRowMutation({
    dataId,
    menuId,
    sectionId,
    reset,
    onCloseEditModal,
  });

  const onSubmit = handleSubmit((data) => {
    onUpdate({
      id: item.id,
      ...data,
      dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
      dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
      dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
    });
  });

  const actions = {
    onAdd: () => onCreate(item.id),
    onRemove: () => onRemove(item.id),
    onMoveUp: () => onSeqDown(item.id),
    onMoveDown: () => onSeqUp(item.id),
    onEdit: () => addEditOpenId(item.id),
    onNoteOpen: () => {
      const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
      if (!noteAuth.noteId || noteAuth.type === 'X') {
        alert({
          title: '권한 오류',
          children: [{ value: '접근 권한이 없습니다.' }],
        });
        return;
      }
      setIsRightDrawerOpen(true);
      setRowId(item.id);
      setSectionId(sectionId);
    },
  };

  const isEditOpen = editOpenList.includes(item.id);

  const itemList = [
    { text: '추가', icon: AddCircleOutlineIcon, action: actions.onAdd, seq: 1 },
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: actions.onEdit,
      split: true,
      seq: 2,
    },
    { text: '이동(위)', icon: ArrowUpwardIcon, action: actions.onMoveUp, seq: 3 },
    { text: '이동(아래)', icon: ArrowDownwardIcon, action: actions.onMoveDown, seq: 4 },
    { text: '비고', icon: NoteIcon, action: actions.onNoteOpen, seq: 6 },
    { text: '삭제', icon: DeleteIcon, action: actions.onRemove, iconColor: '#e43333', seq: 7 },
  ];

  const sx = {
    backgroundColor: recentUpdatedId === item.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        sx={sx}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
}
