import React from 'react';

import { DialogAlert } from '@front/src/features/dialog/components/alert';
import { useDialogAlertLogic } from '@front/src/features/dialog/widgets/alert/useLogic';

export { Widget as DialogAlertWidget };
const Widget = () => {
  const {
    h: { onClose },
    alertProps,
  } = useDialogAlertLogic();

  const isOpen = typeof alertProps !== 'undefined';

  if (!alertProps) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <DialogAlert
          {...alertProps}
          open={typeof alertProps !== 'undefined'}
          onClose={() => {
            onClose();
            if (alertProps?.afterClose) {
              alertProps.afterClose();
            }
          }}
        />
      )}
    </>
  );
};
