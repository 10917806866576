import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyMeetingHistoryUpdateParameter } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/parameter';

const url = {
  getMeetingHistory: (id?) => `${baseUrl}/meeting-history/${id}`,
  createMeetingHistory: (id?) => `${baseUrl}/${id}/meeting-history`,
  updateMeetingHistory: (id?) => `${baseUrl}/meeting-history/${id}`,
  getUserList: () => `/api/admin/user-list`,
};

export const affiliatedCompanyMeetingHistoryModalApi = {
  getMeetingHistory: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getMeetingHistory(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createMeetingHistory: async (params: AffiliatedCompanyMeetingHistoryUpdateParameter, menuId) => {
    await axios.post(url.createMeetingHistory(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  updateMeetingHistory: async (params: AffiliatedCompanyMeetingHistoryUpdateParameter, menuId) => {
    await axios.put(url.updateMeetingHistory(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getUserList: async (idList: number[], menuId) => {
    const { data } = await axios.post(
      url.getUserList(),
      {
        idList,
      },
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
    return data;
  },
};
