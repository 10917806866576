import { useMutation, useQueryClient } from 'react-query';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';
import { projectSalesInfoAffiliatedCompanyWebHardCreateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/query/api';

export const projectSalesInfoAffiliatedCompanyWebHardCreateModalMutation = {
  useCreate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) =>
        projectSalesInfoAffiliatedCompanyWebHardCreateModalApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard', 'list'],
        });
      },
    });
    return {
      mutate,
    };
  },
};
