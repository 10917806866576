import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import type { DefaultFunction } from '@front/type/Function';
import { PaymentStatusTable } from '@front/ost/view/modal/PaymentStatusTable';

interface Props {
  name: string;
  open: boolean;
  onClose: DefaultFunction;
}

export const PaymentStatusModal = ({ name, open, onClose }: Props) => (
  <ModalLayout
    title={`${name} 지급 현황`}
    width="80dvw"
    height="80dvh"
    open={open}
    onClose={onClose}
  >
    <PaymentStatusTable />
  </ModalLayout>
);
