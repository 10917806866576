import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoEstimationCompetitorModal } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-modal';
import { ProjectSalesInfoEstimationCompetitorContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/context';
import { ProjectSalesInfoEstimationCompetitorButtonGroup } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-button-group';
import { ProjectSalesInfoEstimationCompetitorTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-table';
import { useProjectSalesInfoEstimationCompetitorLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/useLogic';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoEstimationCompetitorState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { Box } from '@mui/material';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoEstimationCompetitorWidget = ({
  readOnly,
  name,
  menuId,
}: Readonly<Props>) => {
  const { Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoEstimationCompetitorLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
      isLoading,
    }),
    [list, onDelete, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoEstimationCompetitorState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <ProjectSalesInfoEstimationCompetitorModal menuId={menuId} />
      <ProjectSalesInfoEstimationCompetitorContext.Provider value={value}>
        <Header title={name}>
          <ProjectSalesInfoEstimationCompetitorButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoEstimationCompetitorTable />
        </Body>
      </ProjectSalesInfoEstimationCompetitorContext.Provider>
    </Box>
  );
};

export default ProjectSalesInfoEstimationCompetitorWidget;
