import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import { affiliatedCompanyOutlineSectionComponent } from '@front/src/features/affiliated-company/features/outline/components/section-component';
import { affiliatedCompanyPersonSectionComponent } from '@front/src/features/affiliated-company/features/person/components/section-component';
import { affiliatedCompanyCharacterSectionComponent } from '@front/src/features/affiliated-company/features/character/components/section-component';
import { affiliatedCompanyProjectSectionComponent } from '@front/src/features/affiliated-company/features/project/components/section-component';
import { affiliatedCompanyGiftHistorySectionComponent } from '@front/src/features/affiliated-company/features/gift-history/components/section-component';
import { affiliatedCompanyMeetingHistorySectionComponent } from '@front/src/features/affiliated-company/features/meeting-history/components/section-component';
import { affiliatedCompanySalesActivitySectionComponent } from '@front/src/features/affiliated-company/features/sales-activity/components/section-component';

export { sectionComponent as affiliatedCompanySectionComponent };

const sectionComponent: SectionComponent = {
  ...affiliatedCompanyOutlineSectionComponent,
  ...affiliatedCompanyPersonSectionComponent,
  ...affiliatedCompanyCharacterSectionComponent,
  ...affiliatedCompanyProjectSectionComponent,
  ...affiliatedCompanyGiftHistorySectionComponent,
  ...affiliatedCompanyMeetingHistorySectionComponent,
  ...affiliatedCompanySalesActivitySectionComponent,
};
