import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyFamilyInformationQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/query/query';
import { affiliatedCompanyFamilyInformationMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/query/mutation';

export const affiliatedCompanyFamilyInformationRepository = {
  usePersonCharacterFamilyInformationListGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyFamilyInformationQuery.usePersonCharacterFamilyInformationListGet(
        id,
        menuId
      );
    return {
      data,
      isLoading,
    };
  },
  usePersonCharacterFamilyInformationCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyFamilyInformationMutation.usePersonCharacterFamilyInformationCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterFamilyInformationDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyFamilyInformationMutation.usePersonCharacterFamilyInformationDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterFamilyInformationUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyFamilyInformationMutation.usePersonCharacterFamilyInformationUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
