import React, { Suspense, useCallback, useState } from 'react';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import { contractPaymentTermsTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/components/table-cells';
import { ContractPaymentTermsTable } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/components/table';
import usePaymentTermsModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import ModalWithClientTabs from '@front/src/features/project-sales/features/tabs/contract/components/modal-with-client-tabs';
import { ClientShortView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export { Section as ContractPaymentTermsSection };

function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(contractPaymentTermsTableCells);
  const { isOpen, onClose, readOnly } = usePaymentTermsModalStore();

  //TODO: 발주처 목록 FETCH (By props.dataId) 후 clientsData map
  isOpen && console.debug(`발주처 목록 fetch 구현 예정`);
  const clientsData: ClientShortView[] = [
    {
      id: 1,
      name: '테스트1',
    },
    {
      id: 2,
      name: '테스트2',
    },
    {
      id: 3,
      name: '테스트3',
    },
  ];

  const [clientId, setClientId] = useState<number>(clientsData.length > 0 ? clientsData[0].id : 0);
  const onClientSelected = useCallback((selectedClientId: number) => {
    setClientId(selectedClientId);
  }, []);

  if (!isOpen) return <></>;

  return (
    <ModalWithClientTabs
      name="지불조건"
      isOpen={isOpen}
      onClose={onClose}
      onTabClick={onClientSelected}
      clients={clientsData}
      defaultClientId={clientId}
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <ContractPaymentTermsTable
          sectionProps={{ ...props, readOnly }}
          clientId={clientId}
        />
      </Suspense>
    </ModalWithClientTabs>
  );
}
