import { createContext } from 'react';
import { noOp } from '@front/src/utils';

import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyCharacterOpinionView } from '@front/src/features/affiliated-company/features/character/features/opinion/types/view';
import type { AffiliatedCompanyCharacterOpinionUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/opinion/types/parameter';

export { Context as AffiliatedCompanyCharacterOpinionContext };

interface State {
  d: {
    characterList?: AffiliatedCompanyCharacterOpinionView[];
  };
  h: {
    onCharacterCreate: DefaultFunction;
    onCharacterUpdate: (params: AffiliatedCompanyCharacterOpinionUpdateParameter) => void;
    onCharacterDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {
    characterList: [],
  },
  h: {
    onCharacterCreate: noOp,
    onCharacterUpdate: noOp,
    onCharacterDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
