import { accountingManagementAccountingAccountTableRepository } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/repository/repository';

export { useLogic as useAccountingSettingsManagementAccountingAccountTableHeaderLogic };

const useLogic = (menuId) => {
  const { data: detail, isLoading } =
    accountingManagementAccountingAccountTableRepository.useCountGet(menuId);
  return {
    detail,
    isLoading,
  };
};
