import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';
import type { AuthTabView } from '@front/src/features/admin/features/access-authorization-settings/type/view';
import { noOp } from '@front/common/contants';
import type { AuthPersonalSettingsModalCUDParams } from '@front/src/features/personal-settings-modal/features/auth-settings/types/parameter';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';

interface State {
  clientUserList?: AuthorizationPersonalSettingsUserView[];
  tabList?: AuthTabView[];
  onAuthCud: (params: AuthPersonalSettingsModalCUDParams) => void;
  setClientUserList: Dispatch<SetStateAction<AuthorizationPersonalSettingsUserView[]>>;
}

export { context as PersonalSettingsModalAuthSettingsContext };

const context = createContext<State>({
  onAuthCud: noOp,
  setClientUserList: noOp,
});
