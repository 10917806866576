import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import { create } from 'zustand';
import type { APPROVAL_DOCUMENT_STATUS } from '@front/type/domain';

interface State {
  params: ApprovalDocumentDetailQueryParams;
  documentId: number;
  docStatus?: APPROVAL_DOCUMENT_STATUS;

  imgModalId: number;
}

interface Action {
  setParams: (params: ApprovalDocumentDetailQueryParams) => void;
  setDocumentId: (documentId: number) => void;
  setDocStatus: (docStatus: APPROVAL_DOCUMENT_STATUS) => void;

  setImgModalId: (imgModalId: number) => void;
}

const useApprovalDocumentStore = create<State & Action>((set) => ({
  params: {
    where: 'approval',
  },
  documentId: 0,
  imgModalId: 0,

  setParams: (params: ApprovalDocumentDetailQueryParams) => set({ params }),
  setDocumentId: (documentId: number) => set({ documentId }),
  setImgModalId: (imgModalId: number) => set({ imgModalId }),
  setDocStatus: (docStatus: APPROVAL_DOCUMENT_STATUS) => set({ docStatus }),
}));

export default useApprovalDocumentStore;
