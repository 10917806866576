import { AccountingUploadSalesInfoExpectModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/repository/repository';
import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useEffect } from 'react';

export { useLogic as useAccountingUploadSalesInfoExpectModalListLogic };

const useLogic = (menuId) => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { month, setProjectList } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      month: state.month,
      setProjectList: state.setProjectList,
    }))
  );
  const { data: list, remove } = AccountingUploadSalesInfoExpectModalRepository.useListGet(
    {
      year,
      subType: SalesAccountingAmountSubType.EXPECTED,
    },
    menuId
  );
  const { data: expectList, remove: removeExpectList } =
    AccountingUploadSalesInfoExpectModalRepository.useExpectOrPerformanceListGet(
      {
        year,
        month,
        subType: SalesAccountingAmountSubType.EXPECTED,
      },
      menuId
    );
  useEffect(
    () => () => {
      remove();
      removeExpectList();
      setProjectList(undefined);
    },
    [remove, removeExpectList, setProjectList]
  );
  return {
    list: expectList,
    isConfirmed: list?.find((l) => l.month === month)?.isConfirmed,
  };
};
