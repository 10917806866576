import React, { memo, useContext } from 'react';
import type {
  ProjectAffiliatedCompanyPersonView,
  ProjectSalesInfoAffiliatedCompanyPersonModalShortView,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRow } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/affiliated-company-person-table-row';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useState';
import { MuiTableVirtualizer } from '@front/src/components/virtualizer-with-mui';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/context';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';
import { Box } from '@mui/material';

export { AffiliatedCompanyPersonTable as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTable };

interface ColumnData {
  dataKey:
    | keyof ProjectAffiliatedCompanyPersonView
    | keyof ProjectSalesInfoAffiliatedCompanyPersonModalShortView
    | 'checkbox';
  label: string;
  width: number | string;
}

const columns: ColumnData[] = [
  {
    width: '2%',
    label: '',
    dataKey: 'checkbox',
  },
  {
    width: '4%',
    label: '업체구분',
    dataKey: 'affiliatedCompanyCategory',
  },
  {
    width: '10%',
    label: '업체명',
    dataKey: 'affiliatedCompanyName',
  },
  {
    width: '4%',
    label: '이름',
    dataKey: 'name',
  },
  {
    width: '4%',
    label: '직위',
    dataKey: 'position',
  },
  {
    width: '4%',
    label: '부서명',
    dataKey: 'department',
  },
  {
    width: '4%',
    label: '주요인물',
    dataKey: 'isKeyPerson',
  },
  {
    width: '6%',
    label: '기여',
    dataKey: 'contribution',
  },
  {
    width: '6%',
    label: '회사(직통전화)',
    dataKey: 'directPhoneNumber',
  },
  {
    width: '6%',
    label: '핸드폰번호',
    dataKey: 'phoneNumber',
  },
  {
    width: '10%',
    label: '회사\u00A0이메일',
    dataKey: 'email',
  },
  {
    width: '10%',
    label: '비고',
    dataKey: 'note',
  },
];

const AffiliatedCompanyPersonTable = memo(() => {
  const {
    d: { list },
    isLoading,
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext);
  const setId = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    (state) => state.setId
  );
  const isListLoading = typeof list === 'undefined' || isLoading;
  const isListEmpty = !isListLoading && list?.length === 0;
  return (
    <Box
      sx={{
        height: isListLoading || isListEmpty ? '150px' : '400px',
        width: '100%',
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <MuiTableVirtualizer
        columns={columns}
        list={list}
        RowComponent={ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRow}
        setId={setId}
        colSpan={12}
        isListEmpty={isListEmpty}
        isListLoading={isListLoading}
      />
    </Box>
  );
});
