import React, { useCallback } from 'react';
import { drawerAppBarLayout } from '@front/src/components/layout/drawer-app-bar';
import IconButton from '@front/layouts/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShallow } from 'zustand/react/shallow';
import Button from '@front/layouts/Button';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

export { AppBar as AffiliatedPersonAppBar };
interface Props {
  menuId?: number;
}
const AppBar = ({ menuId }: Props) => {
  const { IconWrapper, ButtonWrapper, Layout } = drawerAppBarLayout;
  const { setIsCreateModalOpen } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    }))
  );
  const { toggle } = useListDrawerState(
    useShallow((state) => ({
      toggle: state.toggle,
    }))
  );
  const handleButtonClick = useCallback(() => setIsCreateModalOpen(true), [setIsCreateModalOpen]);
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  return (
    <Layout>
      <ButtonWrapper>
        {!readOnly && (
          <Button
            shape="small"
            onClick={handleButtonClick}
          >
            신규 인물 등록
          </Button>
        )}
      </ButtonWrapper>
      <IconWrapper>
        <IconButton
          tooltip="목록 접기"
          onClick={toggle}
        >
          <FontAwesomeIcon icon="angle-left" />
        </IconButton>
      </IconWrapper>
    </Layout>
  );
};
