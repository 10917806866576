import React from 'react';
import Box from '@mui/material/Box';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { FormProvider } from 'react-hook-form';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  conventWorkStatusLabelToVariable,
  conventWorkStatusVariableToLabel,
} from '@front/src/features/work/features/work/features/name/utils';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import workNameQuery from '@front/src/features/work/features/work/features/name/query/query';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import { ColorPalette } from '@front/assets/theme';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import useWorkNameForm from '@front/src/features/work/features/work/features/name/hooks/useForm';
import WorkNameTableMutationLoadingSpinner from '@front/src/features/work/features/work/features/name/components/MutationLoadingSpinner';
import workNameMutation from '@front/src/features/work/features/work/features/name/query/mutation';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

export default function WorkNameTable(props: Readonly<SectionComponentProps>) {
  const { readOnly, dataId, menuId, sectionId } = props;
  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };
  const { data: detail } = workNameQuery.useGetOne(commonParams);
  const { data: statusList } = adminManageableVariableRepository.useListGet('일정-상태', menuId);
  const { mutate: onUpdate } = workNameMutation.useUpdate({ dataId, menuId, sectionId });
  const { onSubmit, methods } = useWorkNameForm({
    onUpdate,
    detail,
  });
  const { getValues } = methods;
  const renderStatus = () => {
    if (readOnly) return conventWorkStatusVariableToLabel[getValues('status')];
    return (
      <DropDownWithHookForm
        name="status"
        defaultLabel="선택"
        onSubmit={onSubmit}
        options={getOptions(statusList)}
      />
    );
  };
  return (
    <TableContainerUI
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <WorkNameTableMutationLoadingSpinner />
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <FormProvider {...methods}>
              <TableCellUI
                isHead
                sx={{
                  width: '10%',
                }}
              >
                업무명
                <RedStar />
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '40%',
                }}
              >
                <UncontrolledInput
                  name="name"
                  onBlur={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{
                  width: '12.5%',
                }}
              >
                상태
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '12.5%',
                }}
              >
                {renderStatus()}
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{
                  width: '12.5%',
                }}
              >
                완료율
              </TableCellUI>
              <TableCellUI
                sx={{
                  width: '12.5%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  <UncontrolledInput
                    type="number"
                    name="completionRate"
                    placeholder="숫자만 입력"
                    onBlur={onSubmit}
                    readOnly={readOnly}
                  />
                  %
                </Box>
              </TableCellUI>
            </FormProvider>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}

const RedStar = () => (
  <TypographyUI
    variant="caption"
    sx={{
      color: ColorPalette.sub2.sub2_primary,
    }}
  >
    *
  </TypographyUI>
);

const getOptions = (view?: VariableInformationView[]) => {
  if (!view) return [];
  return view?.map((item) => ({
    label: item.label,
    value: conventWorkStatusLabelToVariable[item.value],
  }));
};
