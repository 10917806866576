import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { personalSettingsModalUISettingsHeaderSettingsHistoryRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/repository/repository';
import { TableRow } from '@mui/material';
import { Tbc } from '@front/src/components/ui-builder/table';
import { NoResult } from '@front/src/components/layout/table/no-result';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';

export { TableContent as PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTableContent };

interface Props {
  authMenuId: number | undefined;
}

const TableContent = ({ authMenuId }: Props) => {
  const { header } = usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
    useShallow((state) => ({
      header: state.header,
    }))
  );
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsHistoryRepository.useGetHeaderHistoryList(
      header?.id,
      authMenuId
    );
  if (list === undefined) return null;
  if (list.length === 0) return <NoResult colSpan={5} />;
  return (
    <>
      {list.map((item) => (
        <TableRow key={item.id}>
          <Tbc align="center">{dayjs(item.createdAt).format(DATE_TIME_FORMAT)}</Tbc>
          <Tbc>{item.beforeName}</Tbc>
          <Tbc>{item.currentName}</Tbc>
          <Tbc>{item.note}</Tbc>
          <Tbc>{item.writerName}</Tbc>
        </TableRow>
      ))}
    </>
  );
};
