import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import Select from '@front/layouts/Select';
import { ColorPalette } from '@front/assets/theme';
import useGetNotificationFilterList from '@front/src/features/notification/repository/useGetNotificationFilterList';
import useNotificationStore from '@front/src/features/notification/useState';
import { useShallow } from 'zustand/react/shallow';
import { notificationTypeOptionList } from '@front/src/features/notification/utils/constants';
import useGetNotificationList from '@front/src/features/notification/repository/useGetNotificationList';

export default function NotificationFilter() {
  const filterList = useGetNotificationFilterList();
  const { searchFilter, setSearchFilter } = useNotificationStore(
    useShallow((state) => ({
      searchFilter: state.searchFilter,
      setSearchFilter: state.setSearchFilter,
    }))
  );
  const { unreadCount, bookmarkCount } = useGetNotificationList();

  const onSourceTypeChange = (e) => {
    setSearchFilter({ ...searchFilter, sourceType: e.target.value });
  };

  const onTypeChange = (e) => {
    setSearchFilter({ ...searchFilter, type: e.target.value });
  };

  const onUnreadClick = () => {
    setSearchFilter({ ...searchFilter, isUnread: !searchFilter.isUnread });
  };

  const onBookmarkClick = () => {
    setSearchFilter({ ...searchFilter, isBookmarked: !searchFilter.isBookmarked });
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ ...SX_WRAP }}>
        <Box
          onClick={onUnreadClick}
          sx={{ ...SX_CELL, cursor: 'pointer' }}
        >
          <Typography>읽지 않음</Typography>
          <Typography>
            <span
              style={{
                color: ColorPalette._FFB611,
                textDecoration: searchFilter.isUnread ? 'underline' : 'none',
              }}
            >
              {unreadCount}
            </span>
            건
          </Typography>
        </Box>
        <Box sx={{ ...SX_SPLIT }}></Box>
        <Box
          onClick={onBookmarkClick}
          sx={{ ...SX_CELL, cursor: 'pointer' }}
        >
          <Typography>북마크</Typography>
          <Typography>
            <span
              style={{
                color: ColorPalette._FFB611,
                textDecoration: searchFilter.isBookmarked ? 'underline' : 'none',
              }}
            >
              {bookmarkCount}
            </span>
            건
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...SX_FORM_WRAP }}>
        <Select
          value={searchFilter.sourceType}
          onChange={onSourceTypeChange}
          inputProps={{
            sx: {
              padding: '10px',
            },
          }}
        >
          {filterList?.map((item) => (
            <MenuItem
              key={item.id}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={searchFilter.type}
          onChange={onTypeChange}
          inputProps={{
            sx: {
              padding: '10px',
            },
          }}
        >
          {notificationTypeOptionList?.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}

const SX_SPLIT = {
  width: '1px',
  height: '16px',
  background: '#B0B5BE',
};

const SX_WRAP = {
  display: 'flex',
  padding: '7px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  alignSelf: 'stretch',
  background: 'var(--design-v-10-light-background-bg-06, #49576F)',
};

const SX_CELL = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0 0',
  color: 'var(--design-v-10-light-greyscale-white, #FFF)',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
  '& > *': {
    color: 'white',
  },
  '& > * > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    paddingLeft: '20px',
    paddingRight: '5px',
  },
};

const SX_FORM_WRAP = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '14px',
  alignItems: 'flex-start',
  background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
