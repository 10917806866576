import { useForm, UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import {
  AccountStatusDetailView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';

type UseDetailTableRowFormValuesType = (props: Pick<AccountStatusDetailView, 'alias'>) => Pick<AccountStatusDetailView, 'alias'>;
type UseDetailTableRowFormHookType = (props: Pick<AccountStatusDetailView, 'alias'>) => {
  methods: UseFormReturn<Pick<AccountStatusDetailView, 'alias'>>
};

const useAccountStatusDetailTableRowFormHook: UseDetailTableRowFormHookType = (props) => {

  const getFormValues: UseDetailTableRowFormValuesType = (item) => ({
    alias: item.alias ?? '',
  });

  const methods = useForm({
    defaultValues: getFormValues(props),
  });

  useEffect(() => {
    methods.reset({
      ...getFormValues(props),
    });
  }, [props]);

  return {
    methods,
  };
};

export default useAccountStatusDetailTableRowFormHook;