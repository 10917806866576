import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonPreferenceQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/query/query';
import { affiliatedCompanyPersonPreferenceMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/query/mutation';

export const affiliatedCompanyPersonPreferenceRepository = {
  usePersonCharacterPreferenceGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyPersonPreferenceQuery.usePersonCharacterPreferenceGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  usePersonCharacterPreferenceCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonPreferenceMutation.usePersonCharacterPreferenceCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterPreferenceDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonPreferenceMutation.usePersonCharacterPreferenceDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonCharacterPreferenceUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonPreferenceMutation.usePersonCharacterPreferenceUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
