import workIndicatorCellComponent from '@front/src/features/work/features/work/features/creator/features/indicator/components/cellComponent';
import workManagerCellComponent from '@front/src/features/work/features/work/features/creator/features/manager/components/cellComponent';
import workWorkerCellComponent from '@front/src/features/work/features/work/features/worker/components/cellComponent';
import workReviewerCellComponent from '@front/src/features/work/features/work/features/reviewer/components/cellComponent';
import workCategoryCellComponent from '@front/src/features/work/features/work/features/category/components/cellComponent';
import workDetailCellComponent from '@front/src/features/work/features/work/features/detail/components/cellComponent';
import workReferenceCellComponent from '@front/src/features/work/features/work/features/reference/components/cellComponent';
import workOutputCellComponent from '@front/src/features/work/features/work/features/output/components/cellComponent';
import workApprovalCellComponent from '@front/src/features/work/features/work/features/approval/components/cellComponent';

const workCellComponent = {
  ...workCategoryCellComponent,
  ...workIndicatorCellComponent,
  ...workWorkerCellComponent,
  ...workReviewerCellComponent,
  ...workDetailCellComponent,
  ...workReferenceCellComponent,
  ...workOutputCellComponent,
  ...workManagerCellComponent,
  ...workApprovalCellComponent,
};

export default workCellComponent;
