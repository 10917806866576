import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { ProjectSalesInfoBidInfoCreateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/widgets/context';
import { useFormContext } from 'react-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-table.module.scss';
import { strToYNConvertor } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/utils/constants';
import dayjs from 'dayjs';
import {DATE_FORMAT, DATE_TIME_FORMAT} from '@front/src/utils';

export { ModalBtnGroup as ProjectSalesInfoBidInfoCreateModalBtnGroup };

const ModalBtnGroup = () => {
  const setIsCreateModalOpen = useProjectSalesInfoBidInfoState(
    (state) => state.setIsCreateModalOpen
  );
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoBidInfoCreateModalContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate({
      id: data.id,
      startDate: data.startDate? dayjs(data.startDate).format(DATE_FORMAT) : null,
      endDate: data.endDate ? dayjs(data.endDate).format(DATE_TIME_FORMAT) : null,
      estimationId: data.estimationId || null,
      isBid: data.isBid === '' ? null : strToYNConvertor[data.isBid],
      isConfirmed: data.isConfirmed === '' ? null : strToYNConvertor[data.isConfirmed],
      note: data.note || null,
      bidNoticeFile: data.bidNoticeFile || null,
      bidGuideLineFile: data.bidGuideLineFile || null,
    });
    setIsCreateModalOpen();
  });
  return (
    <div className={classes.btn__container}>
      <Button
        onClick={() => setIsCreateModalOpen()}
        fullWidth={true}
        shape="basic2"
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onSubmit}
      >
        등록
      </Button>
    </div>
  );
};
