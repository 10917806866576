import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/parameter';

const url = {
  getOutlinePrimaryBusiness: (id?) => `${baseUrl}/${id}/primary-business`,
  createOutlinePrimaryBusiness: (id?) => `${baseUrl}/${id}/primary-business`,
  deleteOutlinePrimaryBusiness: () => `${baseUrl}/primary-business`,
  updateOutlinePrimaryBusiness: (id?) => `${baseUrl}/primary-business/${id}`,
};

export const affiliatedCompanyPrimaryBusinessApi = {
  getOutlinePrimaryBusiness: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getOutlinePrimaryBusiness(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createOutlinePrimaryBusiness: async (id?: number, menuId?) => {
    await axios.post(url.createOutlinePrimaryBusiness(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteOutlinePrimaryBusiness: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteOutlinePrimaryBusiness(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
  updateOutlinePrimaryBusiness: async (
    params: AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updateOutlinePrimaryBusiness(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
