import React from 'react';
import { Typography } from '@mui/material';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { ProjectSalesInfoVatRatioKey } from '@front/src/features/project-sales-info/utils/constant';

export { VatRatioHeader as VatRatioHeader };

const VatRatioHeader = () => {
  const { data: vatRatio } = adminManageableVariableRepository.useListGet(
    ProjectSalesInfoVatRatioKey
  );
  return <Typography fontSize="14px">부가세 요율: {vatRatio?.[0]?.value ?? 0}%</Typography>;
};
