import React from 'react';
import ModalLayout from '../../layouts/ModalLayout';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  imageSrc: string;
}

function ImageModal({ isOpen, setIsOpen, imageSrc }: Props) {
  return (
    <ModalLayout
      width="100%"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      title={'이미지 상세'}
    >
      <img src={imageSrc ?? ''} />
    </ModalLayout>
  );
}

export default ImageModal;
