import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from '@front/src/features/snackbar';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { AffiliatedPersonMemoUpdateContext } from '@front/src/features/affiliated-person/features/memo/widgets/update/provider/context';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';

export { UpdateButton as AffiliatedPersonMemoUpdateButton };

const UpdateButton = () => {
  const { onUpdate } = useContext(AffiliatedPersonMemoUpdateContext);
  const { id: personId } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { setId: setMemoId } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      setId: state.setId,
    }))
  );
  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      personId,
      memoId: getValues('memoId'),
      description: data.description || null,
    });
    setMemoId(undefined);
  });
  const { show } = useSnackbar();
  const handleButtonClick = useCallback(async () => {
    if (!getValues('description')) {
      show({ message: '내용을 입력해주세요.' });
      return;
    }
    await onSubmit();
  }, [onSubmit, show, getValues]);
  return (
    <Button
      fullWidth
      disabled={isSubmitting}
      onClick={handleButtonClick}
    >
      작성 완료
    </Button>
  );
};
