import React from 'react';
import type { Control } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import UserSelectorDefaultInput from '@front/src/features/user-selector/features/input/components/DefaultInput';
import UserSelectorReadOnlyInput from '@front/src/features/user-selector/features/input/components/ReadOnlyInput';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  onOpen: () => void;
  control: Control<{ inputList: UserVO[] }, any>;
}

export default function UserSelectorSingleInput({ readOnly, ...rest }: Readonly<Props>) {
  if (readOnly) {
    return <UserSelectorReadOnlyInput {...rest} />;
  }
  return <UserSelectorDefaultInput {...rest} />;
}
