import { affiliatedPersonAppBarRepository } from '@front/src/features/affiliated-person/features/app-bar/repository/repository';
import { useNavigate } from 'react-router-dom';
import { affiliatedPersonPath } from '@front/src/features/affiliated-company/utils/constant';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCallback } from 'react';

export { useLogic as useAffiliatedPersonAppBarPersonCreateLogic };

const useLogic = (menuId) => {
  const { setIsCreateModalOpen, setIsConfirmModalOpen } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      setIsCreateModalOpen: state.setIsCreateModalOpen,
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
    }))
  );
  const handleModalClose = useCallback(() => {
    setIsCreateModalOpen(false);
    setIsConfirmModalOpen(false);
  }, [setIsCreateModalOpen, setIsConfirmModalOpen]);
  const navigate = useNavigate();
  const { run: onCreate, setCallback } = affiliatedPersonAppBarRepository.useCreate(menuId);
  setCallback({
    onSuccess: (data) => {
      handleModalClose();
      navigate(`${affiliatedPersonPath}/${data}/outline`);
    },
  });
  return {
    onCreate,
  };
};
