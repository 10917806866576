import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import WorkNameTable from '@front/src/features/work/features/work/features/name/components/Table';

export default function WorkNameFeature(props: Readonly<SectionComponentProps>) {
  const { name } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;

  return (
    <Layout>
      <TitleWrap left={name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkNameTable {...props} />
      </Suspense>
    </Layout>
  );
}
