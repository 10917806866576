import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { personnelAction } from 'personnel/action';
import type Page from 'type/Page';
import type { PersonnelShortVO, PersonnelVO } from 'personnel/domain';
import { personnelApi } from 'personnel/api';
import { dialogAction } from 'dialog/action';
import { getErrorMessage } from 'type/Error';
import type { RootState } from '@front/services/reducer';
import type { VoteVO } from '@front/ost_vote/domain';
import type { ProposalVO } from '@front/ost_proposal/domain';

function* watchId() {
  while (true) {
    const { payload: idParams } = yield take(personnelAction.setId);
    if (idParams.id) {
      const detail: PersonnelVO = yield call(personnelApi.getOne, idParams);
      yield put(personnelAction.setOne(detail));
    } else {
      yield put(personnelAction.setOne(undefined));
    }
  }
}

function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(personnelAction.setFilter);
    try {
      const page: Page<PersonnelShortVO> = yield call(personnelApi.getPage, query);
      yield put(personnelAction.setPage(page));
    } catch (e) {
      yield put(personnelAction.setPage(undefined));
    }
  }
}

function* watchUpdate() {
  while (true) {
    const { payload: params } = yield take(personnelAction.update);
    try {
      yield put(personnelAction.requestUpdate('request'));
      yield call(personnelApi.update, params);
      yield put(personnelAction.requestUpdate('done'));
      yield put(dialogAction.openAlert('변경하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(personnelAction.update, e);
      yield put(dialogAction.openError(message));
      yield put(personnelAction.requestUpdate(message));
    }
  }
}

function* watchVoteList() {
  yield takeLatest(personnelAction.getVoteLog, handleVoteList);
}

function* watchProposalList() {
  yield takeLatest(personnelAction.getProposalLog, handleProposalList);
}

function* handleVoteList() {
  try {
    const idParams = yield select((root: RootState) => root.personnel.idParams);
    const voteList: VoteVO[] = yield call(personnelApi.getVoteLog, idParams);
    yield put(personnelAction.setVoteLog(voteList));
  } catch (e) {
    const message = getErrorMessage(personnelAction.getVoteLog, e);
    yield put(dialogAction.openError(message));
  }
}

function* handleProposalList() {
  try {
    const idParams = yield select((root: RootState) => root.personnel.idParams);
    const proposalList: ProposalVO[] = yield call(personnelApi.getProposalLog, idParams);
    yield put(personnelAction.setProposalLog(proposalList));
  } catch (e) {
    const message = getErrorMessage(personnelAction.getProposalLog, e);
    yield put(dialogAction.openError(message));
  }
}

export default function* personnelSaga() {
  yield fork(watchId);
  yield fork(watchFilter);
  yield fork(watchUpdate);
  yield fork(watchVoteList);
  yield fork(watchProposalList);
}
