import { useMutation, useQueryClient } from 'react-query';
import { approvalApi } from '@front/src/features/drawer-approval/api/api';
import type {
  ApprovalDocumentCreateParameter,
  ApprovalDocumentUpdateParameter,
} from '@front/src/features/drawer-approval/types/parameter';

export const approvalMutation = {
  useCreateApprovalDocument: (noteId?: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['approval-document', 'create', noteId],
      mutationFn: (params: ApprovalDocumentCreateParameter) =>
        approvalApi.createApprovalDocument(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['note', 'list']);
        await queryClient.invalidateQueries(['note', 'detail']);
        await queryClient.invalidateQueries(['note', 'subData']);
        await queryClient.invalidateQueries(['note', 'filter']);
        await queryClient.invalidateQueries({
          queryKey: ['work', 'approval'],
        });
      },
    });
    return { mutate };
  },

  useDeleteTemp: (documentId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => approvalApi.deleteTemp(documentId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['note', 'list']);
        await queryClient.invalidateQueries({
          queryKey: ['work', 'approval-list'],
        });
      },
    });
    return { mutate };
  },

  useUpdateTemp: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ApprovalDocumentUpdateParameter) => approvalApi.updateTemp(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['note', 'list']);
        await queryClient.invalidateQueries({
          queryKey: ['work', 'approval-list'],
        });
      },
    });
    return { mutate };
  },
};
