import UserPage from 'user/view/Page';
import React, { Suspense, useCallback, useEffect } from 'react';
import type { AppRoute } from 'services/routes';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'user/action';
import type { UserQuery } from 'user/query';
import { initialUserQuery } from 'user/query';
import type { RootState } from 'services/reducer';
import { useFormik } from 'formik';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

function Element() {
  const dispatch = useDispatch();

  const { page } = useSelector((root: RootState) => root.user);
  const setFilter = useCallback(
    (formikProps: UserQuery) => dispatch(userAction.setFilter(formikProps)),
    [dispatch]
  );
  const setRoleList = useCallback(() => dispatch(userAction.setRoleList()), [dispatch]);

  const formik = useFormik<UserQuery>({
    initialValues: initialUserQuery,
    onSubmit: (values) => {
      setFilter(values);
    },
  });

  useEffect(() => {
    setFilter(initialUserQuery);
    setRoleList();
  }, []);

  useEffect(() => {
    formik.setSubmitting(false);
  }, [page]);

  return (
    <UserPage
      page={page}
      formik={formik}
    />
  );
}

const Wrapper = () => {
  const { menuId } = useSetMenuAndTab();
  if (!menuId) return <></>;
  return <Element />;
};

const userPageRoute: AppRoute = {
  path: '/admin/user-management',
  element: (
    <Suspense fallback={<LoadingSpinner />}>
      <Wrapper />
    </Suspense>
  ),
};

export default userPageRoute;
