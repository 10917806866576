import { projectSalesInfoCMPaymentHistoryCreateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';

export const projectSalesInfoCMPaymentHistoryCreateModalRepository = {
  useCMPaymentHistoryCreate: (id: number, menuId) => {
    const { mutate } =
      projectSalesInfoCMPaymentHistoryCreateModalMutation.useCMPaymentHistoryCreate(id, menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectCMPaymentHistoryParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
