import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/parameter';
import axios from 'axios';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/consortium-company`,
  update: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
  getDetail: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi = {
  create: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter,
    menuId
  ) => {
    await axios.post(url.create(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  update: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter,
    menuId
  ) => {
    await axios.put(url.update(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getDetail: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
