import React, { memo, useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyMeetingHistoryModalCreateForm } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/create-form';
import { AffiliatedCompanyMeetingHistoryModalUpdateForm } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/update-form';
import { useAffiliatedCompanyMeetingHistoryModalLogic } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/useLogic';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';

export { Widget as AffiliatedCompanyMeetingHistoryModalWidget };
interface Props {
  menuId?: number | undefined;
}
const Widget = ({ menuId }: Props) => {
  const {
    h: {
      onUpdateModalClose,
      onMeetingHistoryUpdate,
      onMeetingHistoryCreate,
      onCreateModalClose,
      setUserIdList,
    },
    d: { detail, userList, status },
    isUpdateModalOpen,
    isCreateModalOpen,
    isLoading,
    id,
    userIdList,
  } = useAffiliatedCompanyMeetingHistoryModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detail, userList, status },
      h: {
        onCreateModalClose,
        onUpdateModalClose,
        onMeetingHistoryUpdate,
        onMeetingHistoryCreate,
        setUserIdList,
      },
      isUpdateModalOpen,
      id,
      userIdList,
    }),
    [
      detail,
      onUpdateModalClose,
      onMeetingHistoryUpdate,
      onMeetingHistoryCreate,
      onCreateModalClose,
      isUpdateModalOpen,
      id,
      setUserIdList,
      userList,
      userIdList,
      status,
    ]
  );
  return (
    <>
      {isCreateModalOpen && (
        <ModalLayout
          title="출장보고서 등록/수정"
          width="80dvw"
          height="80dvh"
          open={isCreateModalOpen}
          onClose={onCreateModalClose}
        >
          <AffiliatedCompanyMeetingHistoryModalContext.Provider value={value}>
            <AffiliatedCompanyMeetingHistoryModalCreateForm />
          </AffiliatedCompanyMeetingHistoryModalContext.Provider>
        </ModalLayout>
      )}
      {isUpdateModalOpen && (
        <ModalLayout
          title="출장보고서 등록/수정"
          width="80dvw"
          height="80dvh"
          open={isUpdateModalOpen}
          onClose={onUpdateModalClose}
        >
          {(typeof detail === 'undefined' || isLoading) && <Spinner />}
          {typeof detail !== 'undefined' && !isLoading && (
            <AffiliatedCompanyMeetingHistoryModalContext.Provider value={value}>
              <AffiliatedCompanyMeetingHistoryModalUpdateForm />
            </AffiliatedCompanyMeetingHistoryModalContext.Provider>
          )}
        </ModalLayout>
      )}
    </>
  );
};

const Spinner = memo(() => {
  const height = useMemo(
    () => ({
      height: '80dvh',
    }),
    []
  );
  return (
    <div style={height}>
      <CircularProgress size={30} />
    </div>
  );
});

Spinner.displayName = 'AffiliatedCompanyMeetingHistoryModalWidgetSpinner';
