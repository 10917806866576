import { default as axios } from '@front/src/config/axios';
import type { ProposalId } from '@front/ost_proposal/domain';
import type {
  EvaluationMemoAddParameter,
  EvaluationMemoChangeParameter,
} from '@front/ost_evaluation/parameter';
import type { EvaluationMemoQuery, EvaluationQuery } from '@front/ost_evaluation/query';
import type { EvaluationMemoId } from '@front/ost_evaluation/domain';

class EvaluationApi {
  /**
   * 제안 목록 조회 API
   * @param query
   */
  async getProposalList(query: EvaluationQuery): Promise<void> {
    const { data } = await axios.get(
      `/api/ost/proposal/evaluation/list?keyword=${query.keyword}&status=${query.status}`
    );
    return data;
  }

  /**
   * 제안 메모 관련 API
   * @param id
   * @param params
   */
  async addMemo(id: ProposalId, params: EvaluationMemoAddParameter): Promise<void> {
    const { data } = await axios.post(`/api/evaluation/${id}/memo`, params);
    return data;
  }

  async getMemoList(id: ProposalId, query: EvaluationMemoQuery): Promise<void> {
    const { data } = await axios.get(`/api/evaluation/${id}/memo?keyword=${query.keyword}`);
    return data;
  }

  async changeMemo(id: ProposalId, params: EvaluationMemoChangeParameter): Promise<void> {
    const { data } = await axios.put(`/api/evaluation/${id}/memo/${params.id}`, params);
    return data;
  }

  async deleteMemo(id: ProposalId, memoId: EvaluationMemoId): Promise<void> {
    const { data } = await axios.delete(`/api/evaluation/${id}/memo/${memoId}`);
    return data;
  }
}

export const evaluationApi = new EvaluationApi();
