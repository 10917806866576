import { useCustomDialog } from '@front/src/features/dialog';
import useWorkOutputCreate from '@front/src/features/work/features/work/features/output/repository/useCreate';
import useWorkOutputSeqUp from '@front/src/features/work/features/work/features/output/repository/useSeqUp';
import useWorkOutputSeqDown from '@front/src/features/work/features/work/features/output/repository/useSeqDown';
import useWorkOutputUpdate from '@front/src/features/work/features/work/features/output/repository/useUpdate';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import { getEditOpenIdList } from '@front/src/components/ui-builder/table/utils';

const useWorkOutputTableRowMutation = ({ bundleId, menuId, sectionId, dataId, formContext }) => {
  const params = { menuId, sectionId, dataId };

  const { getValues, setValue } = formContext;

  const editOpenIdList = getValues('editOpenIdList');

  const toggleEditOpenId = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId = (id) => {
    setValue('recentUpdatedId', id);
  };

  const { confirm } = useCustomDialog();
  const { run: onCreate, setCallback: setCreateCallback } = useWorkOutputCreate(bundleId, params);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onSeqUp, setCallback: setSeqUpCallback } = useWorkOutputSeqUp(bundleId, params);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onSeqDown, setCallback: setSeqDownCallback } = useWorkOutputSeqDown(
    bundleId,
    params
  );
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkOutputUpdate(bundleId, params);
  setUpdateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
    },
  });

  const { mutate: onDelete } = workOutputMutation.useDelete(bundleId, params);
  const onRemove = (id: number) => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };

  return {
    onCreate,
    onUpdate,
    onSeqUp,
    onSeqDown,
    onRemove,
  };
};

export default useWorkOutputTableRowMutation;
