import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AffiliatedCompanyOutlineNumberTableRowContactNumber } from '@front/src/features/affiliated-company/features/outline/features/number/components/contact-number';
import { AffiliatedCompanyOutlineNumberTableRowFaxNumber } from '@front/src/features/affiliated-company/features/outline/features/number/components/fax-number';
import { AffiliatedCompanyOutlineNumberTableRowEmail } from '@front/src/features/affiliated-company/features/outline/features/number/components/email';
import { AffiliatedCompanyOutlineNumberTableRowBusinessRegistrationNumber } from '@front/src/features/affiliated-company/features/outline/features/number/components/business-registration-number';
import type { AffiliatedCompanyOutlineNumberUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/types/parameter';
import { AffiliatedCompanyOutlineNumberContext } from '@front/src/features/affiliated-company/features/outline/features/number/widgets/context';

export { NameTable as AffiliatedCompanyOutlineNumberTable };
const NameTable = () => {
  const {
    d: { detailOutline },
  } = useContext(AffiliatedCompanyOutlineNumberContext);
  const methods = useForm<AffiliatedCompanyOutlineNumberUpdateParameter>({
    values: {
      contactNumber: detailOutline?.contactNumber ?? '',
      faxNumber: detailOutline?.faxNumber ?? '',
      email: detailOutline?.email ?? '',
      businessRegistrationNumber: detailOutline?.businessRegistrationNumber ?? '',
    },
  });

  return (
    <FormProvider {...methods}>
      <AffiliatedCompanyOutlineNumberTableRowContactNumber />
      <AffiliatedCompanyOutlineNumberTableRowFaxNumber />
      <AffiliatedCompanyOutlineNumberTableRowEmail />
      <AffiliatedCompanyOutlineNumberTableRowBusinessRegistrationNumber />
    </FormProvider>
  );
};
