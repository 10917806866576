import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyWebHardView } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/view';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';
import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';

export { Context as ProjectSalesInfoAffiliatedCompanyWebHardCreateModalContext };

interface State {
  d: {
    companyList?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[];
    webHardList?: AffiliatedCompanyWebHardView[];
    consortiumCompanyList?: ProjectContractEarningsConsortiumCompanyView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) => void;
  };
  isWebHardListLoading: boolean;
}

const Context = createContext<State>({
  d: {
    companyList: [],
    webHardList: [],
  },
  h: {
    onCreate: noOp,
  },
  isWebHardListLoading: false,
});
