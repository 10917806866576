import type { AccountingUploadEcountUploadParameter } from '@front/src/features/accounting/features/upload/features/ecount-data/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/src/utils';

export { Context as AccountingSettingsDailyReportStatusUploadContext };

interface State {
  onCreate: (params: AccountingUploadEcountUploadParameter) => void;
}

const Context = createContext<State>({
  onCreate: noOp,
});
