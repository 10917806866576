import React from 'react';
import FileAuthSettingsModal from '@front/src/features/file-item/features/auth-settings/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { WorkCarouselFieldValues } from '@front/src/features/work/features/work/features/carousel/components/Form';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

export default function WorkCarouselFileAuthModal(props: WorkCarouselSectionProps) {
  const { menuId, dataId } = props;
  const { watch, setValue } = useFormContext<WorkCarouselFieldValues>();

  const fileItemId = watch('fileItemId');

  const onClose = () => {
    setValue('fileItemId', undefined);
  };

  if (!fileItemId) return <></>;

  return (
    <FileAuthSettingsModal
      open={!!fileItemId}
      onClose={onClose}
      fileItemId={fileItemId}
      menuId={menuId}
      dataId={dataId}
    />
  );
}
