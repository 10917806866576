import { create } from 'zustand';
import type { ProjectListGetParameter } from '@front/src/features/project-selector-modal/types/parameter';
import { ProjectKeywordType } from '@front/src/features/project-selector-modal/types/domain';

export { useModalFormState as useProjectSelectorModalFormState };

interface ModalFormState {
  parameter: ProjectListGetParameter;
  setParameter: (parameter: ProjectListGetParameter) => void;
}

export const defaultParameter = {
  keywordType: ProjectKeywordType.NICK_NAME,
  keyword: '',
};

const useModalFormState = create<ModalFormState>((set) => ({
  parameter: defaultParameter,
  setParameter: (parameter) => set(() => ({ parameter })),
}));
