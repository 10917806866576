import { projectSalesInfoEstimationConfirmedRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  ProjectSalesInfoEstimationConfirmedStatusKey,
  ProjectSalesInfoEstimationConfirmedWinningPossibilityKey,
} from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/utils/constant';

export { useLogic as useProjectSalesInfoEstimationConfirmedLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState((state) => ({ id: state.id }), shallow);
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoEstimationConfirmedRepository.useDetailGet(id, menuId);
  const { run: onUpdate } = projectSalesInfoEstimationConfirmedRepository.useUpdate(menuId);
  const { data: statusVariableList, isLoading: isStatusVariableLoading } =
    adminManageableVariableRepository.useListGet(
      ProjectSalesInfoEstimationConfirmedStatusKey,
      menuId
    );
  const { data: winningPossibilityVariableList, isLoading: isWinningPossibilityVariableLoading } =
    adminManageableVariableRepository.useListGet(
      ProjectSalesInfoEstimationConfirmedWinningPossibilityKey,
      menuId
    );
  const d = { detail, statusVariableList, winningPossibilityVariableList };
  const h = { onUpdate };
  return {
    d,
    h,
    isLoading: isDetailLoading || isStatusVariableLoading || isWinningPossibilityVariableLoading,
  };
};
