import React, { useCallback } from 'react';
import { Badge, Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import type { UseFieldArrayRemove } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';
import UserSelectorUserIcon from '@front/src/features/user-selector/components/UserIcon';
import type { UserVO } from '@front/user/domain';

interface Props {
  fields: Record<'id', string>[];
  remove: UseFieldArrayRemove;
  onChange: (value: UserVO | UserVO[] | undefined) => void;
  multi: boolean;
}

export default function UserSelectorSelectedList({
  fields,
  remove,
  onChange,
  multi,
}: Readonly<Props>) {
  const { getValues, control } = useFormContext();
  const {
    field: { value: selectedList },
  } = useController({
    control,
    name: 'selectedList',
  });
  const handleClick = useCallback(
    (index) => {
      remove(index);
      if (multi) {
        const copy = [...selectedList];
        copy.splice(index, 1);
        onChange(copy.map((c) => c.id));
      } else {
        onChange(undefined);
      }
    },
    [remove, onChange, multi, selectedList]
  );
  if (fields.length === 0) {
    return <TextBox variant="body2">선택된 유저 없음</TextBox>;
  }
  return (
    <>
      {fields.map((f, i) => {
        const item = getValues(`selectedList.${i}`);
        return (
          <Box
            key={f.id}
            onClick={() => handleClick(i)}
            sx={{
              display: 'flex',
              gap: '10px',
              width: '100%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent="X"
              color="error"
              overlap="rectangular"
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '0.6rem',
                  height: '0.7rem',
                  minWidth: '0.7rem',
                  padding: '0.3rem',
                },
              }}
            >
              <UserSelectorUserIcon user={item} />
            </Badge>
            <TextBox
              variant="body2"
              sx={{
                marginRight: '10px',
              }}
            >
              {item?.name}
            </TextBox>
          </Box>
        );
      })}
    </>
  );
}
