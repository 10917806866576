import React from 'react';
import type { InputProps as MuiInputProps } from '@mui/material';
import { Input as MuiInput, InputAdornment } from '@mui/material';
import { ColorPalette } from 'assets/theme';
import TextBox from 'layouts/Text';

export interface InputProps extends Omit<MuiInputProps, 'fullWidth' | 'inputProps'> {
  variant?: 'outlined' | 'standard';
  inputProps?: MuiInputProps['inputProps'];
  isAmount?: boolean;
  fullHeight?: boolean;
  alignCenter?: boolean;
  isPercent?: boolean;
  min?: number;
  max?: number;
  openModal?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  isPointer?: boolean;
  allowMultiline?: boolean;
}

export default function Input({ variant, ...props }: InputProps) {
  if (variant === 'outlined') {
    return <OutlinedInput {...props} />;
  }
  return <StandardInput {...props} />;
}

export function OutlinedInput({
  isAmount,
  isPercent,
  fullHeight,
  alignCenter,
  openModal,
  allowMultiline,
  ...props
}: Omit<InputProps, 'variant'>) {
  return (
    <MuiInput
      {...props}
      multiline={allowMultiline}
      fullWidth
      endAdornment={
        isPercent ? (
          <InputAdornment position="start">
            <TextBox variant="body11">%</TextBox>
          </InputAdornment>
        ) : (
          props.endAdornment
        )
      }
      startAdornment={
        isAmount ? (
          <InputAdornment position="start">
            <TextBox variant="body11">￦</TextBox>
          </InputAdornment>
        ) : (
          props.startAdornment
        )
      }
      sx={
        props.multiline
          ? {
              height: fullHeight ? '100%' : 'auto',
              padding: '10px',
              borderRadius: '5px',
              backgroundColor:
                props.readOnly || props.disabled ? ColorPalette._f4f4f4 : ColorPalette._ffffff,
              '& > .MuiInputBase-multiline': {
                padding: 0,
              },
              '&::before': {
                borderBottom: 'none !important',
              },
              '&::after': {
                borderBottom: 'none !important',
              },
              ...props.sx,
            }
          : {
              padding: '0 10px',
              height: fullHeight ? '100%' : 'auto',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              backgroundColor:
                props.readOnly || props.disabled ? ColorPalette._f4f4f4 : ColorPalette._ffffff,
              borderRadius: '5px',
              '&::before': {
                borderBottom: 'none !important',
              },
              '&::after': {
                borderBottom: 'none !important',
              },
              '&.Mui-focused': {
                border: `1px solid ${ColorPalette._0047d3}`,
              },
              '&::--webkit-inner-spin-button': {
                appearance: props.type === 'number' ? 'none' : 'unset',
              },
              '&::--webkit-outer-spin-button': {
                appearance: props.type === 'number' ? 'none' : 'unset',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              ...props.sx,
            }
      }
      inputProps={{
        ...props.inputProps,
        style: {
          fontFamily: 'Noto Sans KR',
          height: fullHeight
            ? '100%'
            : props.multiline
            ? '80px'
            : props.size === 'small'
            ? '24px'
            : '32px',
          fontSize: props.size === 'small' ? '11px' : '13px',
          // color: ColorPalette._252627,
          border: 'none',
          backgroundColor: 'transparent',
          boxSizing: 'border-box',
          cursor:
            props.readOnly && openModal
              ? 'pointer'
              : props.readOnly || props.disabled
              ? 'default'
              : openModal
              ? 'pointer'
              : 'text',
          textAlign: alignCenter
            ? 'center'
            : isAmount || props.type === 'number'
            ? 'right'
            : 'left',
        },
      }}
    />
  );
}

export function StandardInput({
  isAmount,
  fullHeight,
  openModal,
  ...props
}: Omit<InputProps, 'variant'>) {
  return (
    <MuiInput
      {...props}
      startAdornment={
        isAmount ? (
          <InputAdornment position="start">
            <TextBox variant="body11">￦</TextBox>
          </InputAdornment>
        ) : (
          props.startAdornment
        )
      }
      fullWidth
      sx={
        props.multiline
          ? {
              ...props.sx,
              padding: '10px',
              height: fullHeight ? '100%' : 'auto',
              backgroundColor:
                props.readOnly || props.disabled ? ColorPalette._f4f4f4 : ColorPalette._ffffff,
              '& > .MuiInputBase-multiline': {
                padding: 0,
              },
            }
          : {
              ...props.sx,
              padding: '0 10px',
              height: fullHeight ? '100%' : 'auto',
              backgroundColor:
                props.readOnly || props.disabled ? ColorPalette._f4f4f4 : ColorPalette._ffffff,
            }
      }
      inputProps={{
        ...props.inputProps,
        min: props.type === 'number' ? 0 : undefined,
        style: {
          fontFamily: 'Noto Sans KR',
          height: fullHeight
            ? '100%'
            : props.multiline
            ? '80px'
            : props.size === 'small'
            ? '32px'
            : '40px',
          fontSize: props.size === 'small' ? '11px' : '13px',
          color: ColorPalette._252627,
          border: 'none',
          borderBottom: `1px solid ${ColorPalette._e4e9f2}`,
          backgroundColor: 'transparent',
          borderRadius: 0,
          boxSizing: 'border-box',
          cursor:
            props.readOnly && openModal
              ? 'pointer'
              : props.readOnly || props.disabled
              ? 'default'
              : openModal
              ? 'pointer'
              : 'text',
          textAlign: isAmount || props.type === 'number' ? 'right' : 'left',
        },
      }}
    />
  );
}
