import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget as AffiliatedCompanySelectorFeature } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext, useWatch } from 'react-hook-form';
import type { ModalPositionType } from '@front/layouts/ModalLayout';

export { Index as HookFormPersonSelector };

interface Props {
  companyName: string;
  managerName: string;
  position?: ModalPositionType;
  disabled?: boolean;
}

const Index = ({ companyName, managerName, position, disabled }: Props) => {
  const { control, setValue } = useFormContext();
  const person = useWatch({ control, name: managerName });
  const company = useWatch({ control, name: companyName });

  const handlePersonSelect = useCallback(
    (company, person) => {
      setValue(managerName, person);
      setValue(companyName, company);
    },
    [companyName, setValue, managerName]
  );
  return (
    <AffiliatedCompanySelectorFeature
      position={position}
      value={{
        companyId: company?.id,
        textValue: `${company?.name ?? ''} ${company?.name ? '-' : ''} ${person?.name ?? ''}`,
        personId: person?.id,
      }}
      onPersonSelect={handlePersonSelect}
      usePersonSelector={true}
      disabled={disabled}
    />
  );
};
