import { useDialogState } from '@front/src/features/dialog/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useDialogAlertLogic };

const useLogic = () => {
  const { openAlert, onClose, alertProps } = useDialogState(
    useShallow((state) => ({
      openAlert: state.openAlert,
      onClose: state.onClose,
      alertProps: state.alertProps,
    }))
  );
  const h = {
    openAlert,
    onClose,
  };
  return { h, alertProps };
};
