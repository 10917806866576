import React from 'react';
import { FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkBasicSearchWordParams } from '@front/src/features/work/features/work/features/basic-info/types/parameter';
import workBasicInfoQuery from '@front/src/features/work/features/work/features/basic-info/query/query';
import { useCustomDialog } from '@front/src/features/dialog';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

import ChipUI from '@front/src/components/components-with-design/component/ChipUI';
import useWorkSearchWordForm from '@front/src/features/work/features/work/features/basic-info/hooks/useSearchWordForm';
import useWorkSearchWordMutation from '@front/src/features/work/features/work/features/basic-info/hooks/useSearchWordMutation';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

interface Props
  extends Pick<SectionComponentProps, 'readOnly' | 'dataId' | 'menuId' | 'sectionId'> {}

export default function WorkBasicInfoTableSearchWordRow(props: Readonly<Props>) {
  const { readOnly, dataId, sectionId, menuId } = props;
  const { onDelete, onCreate, setCreateCallback } = useWorkSearchWordMutation({
    dataId,
    sectionId,
    menuId,
  });
  const { data: detail } = workBasicInfoQuery.useGetOne({ dataId, menuId, sectionId });
  const { methods, onSubmit } = useWorkSearchWordForm({
    onCreate,
  });
  const { resetField } = methods;
  const { confirm } = useCustomDialog();
  const onRemove = (params: WorkBasicSearchWordParams) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(params);
      },
    });
  };
  const onKeyDown = async (e) => {
    const { isComposing, key, keyCode } = e;
    if (isComposing || keyCode === 229) return;
    if (key !== 'Enter') return;
    await onSubmit();
  };
  setCreateCallback({
    onSuccess: () => {
      resetField('searchWord');
    },
  });
  const renderInput = () => {
    if (readOnly) return <></>;
    return (
      <FormProvider {...methods}>
        <UncontrolledInput
          name="searchWord"
          placeholder="검색어 입력 후 Enter"
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          sx={{
            width: '200px',
          }}
        />
      </FormProvider>
    );
  };
  const handleDelete = (item) => {
    onRemove({
      searchWord: item,
    });
  };
  const renderSearchWords = () =>
    detail?.searchWord?.split(',').map((item) => (
      <ChipUI
        key={item}
        label={item}
        readOnly={readOnly}
        onDelete={() => handleDelete(item)}
      />
    ));
  return (
    <TableRowUI>
      <TableCellUI isHead>검색어</TableCellUI>
      <TableCellUI>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {renderInput()}
          {renderSearchWords()}
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
}
