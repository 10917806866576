import { Box } from '@mui/material';
import type { ComponentType } from 'react';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { OstVO } from '@front/ost/domain';
import type { RootState } from '@front/services/reducer';
import type { DefaultFunction } from '@front/type/Function';
import Button from '@front/layouts/Button';
import LoadingBar from '@front/layouts/LoadingBar';
import DetailLayout from '@front/ost_proposal/view/detail';
import ErrorBoundary from '@front/components/ErrorBoundary';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import OstUpdateFormService from '@front/ost/service/detail/updateForm';
import OstBudgetStatusService from '@front/ost/service/detail/budgetStatus';
import useId from '@front/services/useId';
import { ostAction } from '@front/ost/action';
import useDialog from '@front/dialog/hook';
import type { CampaignVO } from '@front/ost_campaign/domain';
import { useLocation } from 'react-router-dom';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface ConditionalProps extends SectionComponentProps {
  campaignList?: CampaignVO[];
  detail?: OstVO;
  onDelete: DefaultFunction;
}

const withConditionalRender =
  (Component: ComponentType<ConditionalProps>) => (props: ConditionalProps) => {
    if (!props.detail || !props.campaignList) {
      return <LoadingBar />;
    }
    return <Component {...props} />;
  };

const Service = ({ campaignList, detail, onDelete, menuId }: ConditionalProps) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  if (!detail || !campaignList) {
    return (
      <PageLayoutWithMemo>
        <ErrorBoundary>요청한 데이터를 가져오는데 실패했습니다.</ErrorBoundary>
      </PageLayoutWithMemo>
    );
  }
  return (
    <DetailLayout
      titleRightComponent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
          }}
        >
          {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
          <Button
            shape={'basic3'}
            onClick={onDelete}
          >
            삭제
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '100px',
        }}
      >
        <OstUpdateFormService />
        <OstBudgetStatusService />
      </Box>
    </DetailLayout>
  );
};

const ConditionedService = withConditionalRender(Service);

const OstDetailService = (props: SectionComponentProps) => {
  const urlId = useId();
  const location = useLocation();
  const dispatch = useDispatch();
  const { confirm } = useDialog();

  const { detail } = useSelector(
    (root: RootState) => ({
      detail: root.ost.detail,
    }),
    shallowEqual
  );

  const [campaignList] = useSelector((root: RootState) => [root.ost.campaignList], shallowEqual);

  const onDelete = useCallback(() => {
    confirm({
      children: '해당 OST를 삭제하시겠습니까?' + '\n하위 모든 캠페인들이 삭제됩니다.',
      afterConfirm: () => {
        dispatch(ostAction.deleteDetail());
      },
      confirmText: '확인',
    });
  }, [confirm, dispatch]);

  useEffect(() => {
    dispatch(ostAction.setId((location.state && location.state.id) ?? urlId));
  }, [dispatch, location.state, urlId]);

  return (
    <ConditionedService
      detail={detail}
      campaignList={campaignList}
      onDelete={onDelete}
      {...props}
    />
  );
};

export default OstDetailService;
