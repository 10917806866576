import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';

export { ProjectTable as AccountingUploadSalesInfoExpectModalProjectTable };

interface Props {
  children: ReactNode;
}

const ProjectTable = ({ children }: Props) => (
  <TableContainer
    sx={{
      maxHeight: '464px',
      overflowY: 'auto',
    }}
  >
    <MuiTable>
      <TableHead
        sx={{
          position: 'sticky',
          top: 0,
        }}
      >
        <TableRow>
          <Th width="100px">관련프로젝트 번호</Th>
        </TableRow>
        <TableRow>
          <Th width="100px">수주</Th>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </MuiTable>
  </TableContainer>
);
