import React, { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { useAccountingSettingsManagementAccountingAccountFocus } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/hooks/useFocus';
import { ColorPalette } from '@front/assets/theme';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { AccountingSettingsManagementAccountingAccountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/context';
import { accountingSettingsManagementAccountingAccountTableLayout } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table';
import { useShallow } from 'zustand/react/shallow';

export { LargeItem as AccountingSettingsManagementAccountingAccountTableBodyCellLargeItem };

interface Props {
  item: AccountingSettingsManagementAccountingAccountTableCategoryView;
  handleCategoryClick: (item) => void;
}

const LargeItem = ({ item, handleCategoryClick }: Props) => {
  const { readOnly } = useContext(AccountingSettingsManagementAccountingAccountContext);
  const { TableBodyCell } = accountingSettingsManagementAccountingAccountTableLayout;
  const { handleKeyDown } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const { parentIdList, setLargeAnchorEl, setTargetItem, setUpdateId, targetItem } =
    useAccountingSettingsManagementAccountingAccountTableState(
      useShallow((state) => ({
        parentIdList: state.parentIdList,
        setLargeAnchorEl: state.setLargeAnchorEl,
        setTargetItem: state.setTargetItem,
        setUpdateId: state.setUpdateId,
        targetItem: state.targetItem,
      }))
    );
  const { ref, isFocused, setIsFocused } = useAccountingSettingsManagementAccountingAccountFocus();
  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      if (readOnly) return;
      setIsFocused(true);
      setTargetItem(item);
      setLargeAnchorEl(e.currentTarget);
      setUpdateId(undefined);
    },
    [setLargeAnchorEl, setTargetItem, setUpdateId, readOnly, item, setIsFocused]
  );
  const styleIsSelected = useMemo(() => {
    const selected = parentIdList?.includes(item.id);
    return {
      backgroundColor: selected ? ColorPalette._cddaf5 : ColorPalette._ffffff,
      fontWeight: selected ? 'bold' : 'normal',
      cursor: selected ? 'default' : 'pointer',
    };
  }, [parentIdList, item.id]);
  const styleIsFocused = useMemo(
    () => ({
      border:
        !readOnly && isFocused
          ? `4px solid ${ColorPalette._9bb6ea}`
          : `1px solid ${ColorPalette._e4e9f2}`,
    }),
    [isFocused, readOnly]
  );
  const handleRowClick = useCallback(() => {
    handleCategoryClick(item);
  }, [handleCategoryClick, item]);
  const sx = useMemo(
    () => ({
      ...styleIsSelected,
      ...styleIsFocused,
      '&:hover': {
        backgroundColor: ColorPalette._9bb6ea,
      },
    }),
    [styleIsSelected, styleIsFocused]
  );
  const handleCellKeyDown = useCallback(
    (e) => {
      if (!isFocused) {
        return;
      }
      handleKeyDown(e);
    },
    [isFocused, handleKeyDown]
  );
  useLayoutEffect(() => {
    if (!targetItem) {
      setIsFocused(false);
      return;
    }
    if (targetItem.id === item.id) {
      setIsFocused(true);
    }
  }, [targetItem, setIsFocused, item.id]);
  return (
    <div ref={ref}>
      <TableBodyCell
        sx={sx}
        onClick={handleRowClick}
        onContextMenu={handleContextMenu}
        onKeyDown={handleCellKeyDown}
      >
        {item.name}
      </TableBodyCell>
    </div>
  );
};
