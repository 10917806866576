import React, { useMemo } from 'react';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/widgets/context';
import { useCMPaymentHistoryUpdateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/widgets/useLogic';

export { Provider as ProjectSalesInfoCMPaymentHistoryUpdateModalProvider };

interface Props {
  children: React.ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const {
    d: { CMPaymentHistoryDetail, CMPaymentMethodOptionList, CMPaymentStatusOptionList },
    h: { onUpdate },
    isLoading,
  } = useCMPaymentHistoryUpdateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: {
        CMPaymentHistoryDetail,
        CMPaymentStatusOptionList,
        CMPaymentMethodOptionList,
      },
      h: {
        onUpdate,
      },
      isLoading,
    }),
    [
      CMPaymentHistoryDetail,
      onUpdate,
      isLoading,
      CMPaymentMethodOptionList,
      CMPaymentStatusOptionList,
    ]
  );
  return (
    <ProjectSalesInfoCMPaymentHistoryUpdateModalContext.Provider value={value}>
      {children}
    </ProjectSalesInfoCMPaymentHistoryUpdateModalContext.Provider>
  );
};
