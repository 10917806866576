import React from 'react';
import { Box } from '@mui/material';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import {
  AccountStatusDetailView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';
import {
  dateFormatting,
  floatFormatting,
  numberFormatting,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/util/convert';

type CellProps = Readonly<CellComponentProps<AccountStatusDetailView>>;
type ElementType = (props: CellProps) => React.ReactElement;
type CellComponentType = { [key: number]: ElementType };

const AccountStatusDetailTableCellComponent: CellComponentType = {
  327: (props) => (
    <UIBuilderTableCellInputText
      {...props}
      name="alias"
      data-cell-component-id={327}
    />
  ),
  328: (props) => (
    <Box
      data-cell-component-id={328}
      key={328}
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick(328);
        }
      }}
    >
      {numberFormatting(props.groupedSum)}
    </Box>
  ),
  329: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={329}
      key={329}
      onClick={() => {
        if (props.onClick) {
          props.onClick(329);
        }
      }}
    >
      {numberFormatting(props.item.amount)}
    </Box>
  ),
  330: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={330}
      key={330}
      onClick={() => {
        if (props.onClick) {
          props.onClick(330);
        }
      }}
    >
      {props.item.type}
    </Box>
  ),
  331: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={331}
      key={331}
      onClick={() => {
        if (props.onClick) {
          props.onClick(331);
        }
      }}
    >
      {props.item.code}
    </Box>
  ),
  332: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={332}
      key={332}
      onClick={() => {
        if (props.onClick) {
          props.onClick(332);
        }
      }}
    >
      {props.item.bankName}
    </Box>
  ),
  333: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={333}
      key={333}
      onClick={() => {
        if (props.onClick) {
          props.onClick(333);
        }
      }}
    >
      {dateFormatting(props.item.openDate)}
    </Box>
  ),
  334: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={334}
      key={334}
      onClick={() => {
        if (props.onClick) {
          props.onClick(334);
        }
      }}
    >
      {dateFormatting(props.item.endDate)}
    </Box>
  ),
  335: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={335}
      key={335}
      onClick={() => {
        if (props.onClick) {
          props.onClick(335);
        }
      }}
    >
      {floatFormatting(props.item.interestRate)}
    </Box>
  ),
  336: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={336}
      key={336}
      onClick={() => {
        if (props.onClick) {
          props.onClick(336);
        }
      }}
    >
      {`${floatFormatting(props.item.taxRate1)}%`}
    </Box>
  ),
  337: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={337}
      key={337}
      onClick={() => {
        if (props.onClick) {
          props.onClick(337);
        }
      }}
    >
      {`${floatFormatting(props.item.taxRate2)}%`}
    </Box>
  ),
  338: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={338}
      key={338}
      onClick={() => {
        if (props.onClick) {
          props.onClick(338);
        }
      }}
    >
      {numberFormatting(props.item.expectedInterest)}
    </Box>
  ),
  339: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={339}
      key={339}
      onClick={() => {
        if (props.onClick) {
          props.onClick(339);
        }
      }}
    >
      {numberFormatting(props.item.expectedTax)}
    </Box>
  ),
  340: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={340}
      key={340}
      onClick={() => {
        if (props.onClick) {
          props.onClick(340);
        }
      }}
    >
      {props.item.branchName}
    </Box>
  ),
  341: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={341}
      key={341}
      onClick={() => {
        if (props.onClick) {
          props.onClick(341);
        }
      }}
    >
      {props.item.productName}
    </Box>
  ),
};

export default AccountStatusDetailTableCellComponent;
