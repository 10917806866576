// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OZgNJjbBxbK4TMf4RMDe{width:100%;display:flex;gap:8px;align-items:center}`, "",{"version":3,"sources":["webpack://./front/src/features/project-selector-modal/components/form.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".container {\r\n  width: 100%;\r\n  display: flex;\r\n  gap: 8px;\r\n  align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OZgNJjbBxbK4TMf4RMDe`
};
export default ___CSS_LOADER_EXPORT___;
