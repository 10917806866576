import React, { useCallback, useEffect, useMemo } from 'react';
import BasicInfoSection from '../../view/Form/BasicInfoSection';
import type { ProceedingsUpdateParameter } from '../../parameter';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proceedingsAction } from '@front/proceedings/action';
import { getAttendance, getReader, getWriter } from '@front/proceedings/util/getAuthority';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import {
  type ProceedingsAccessVO,
  ProceedingsAuthority,
  ProceedingsId,
} from '@front/proceedings/domain';
import { loginAction } from '@front/login/action';
import { closeStatus } from '@front/components/DataFieldProps';
import { initialProceedingsQuery } from '@front/proceedings/query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useCustomDialog } from '@front/src/features/dialog';
import {
  getIsShowForAuthorizationFromMenu,
  ReadAuthErrorMsgArr,
  WriteAuthErrorMsgArr,
} from '@front/src/utils';

interface Props extends SectionComponentProps {}

export default function BasicInfoRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const { alert } = useCustomDialog();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: proceedingsId } = useParams();
  const { id, detail, requestDelete, access, list, requestAccess, filter } = useSelector(
    (root: RootState) => root.proceedings
  );
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const update = useCallback(
    (params: ProceedingsUpdateParameter) => dispatch(proceedingsAction.update(params)),
    [dispatch]
  );
  const isReader: boolean = useMemo(() => {
    if (access && detail) {
      return getReader(access, detail);
    }
    return false;
  }, [detail?.readerList, access]);
  const isWriter: boolean = useMemo(() => {
    if (access && detail) {
      return getWriter(access, detail);
    }
    return false;
  }, [detail?.writer, access]);
  const isAttendance: boolean = useMemo(() => {
    if (access && detail) {
      return getAttendance(access, detail);
    }
    return false;
  }, [detail?.attendanceList, access]);
  useEffect(() => {
    if (!loginUser || !access?.authority || !list) return;
    const auth = getIsShowForAuthorizationFromMenu(loginUser?.menus ?? []);
    const authority = access.authority;
    const status = list.find(
      (item) => (item.id as number) === (proceedingsId ? +proceedingsId : 0)
    )?.status;
    if (auth || !authority || !status) return;
    if (status === 'WRITING' && authority !== ProceedingsAuthority.WRITER) return;
    if (status === 'READING' && authority === ProceedingsAuthority.READER) return;
    if (status === 'COMPLETE' && authority !== ProceedingsAuthority.WRITER) return;
    alert({
      title: '권한 오류',
      closeText: '확인',
      afterClose: () => navigate('/proceedings'),
      children: WriteAuthErrorMsgArr,
    });
    return () => {
      dispatch(proceedingsAction.setAccess({} as ProceedingsAccessVO));
    };
  }, [alert, loginUser, navigate, access, list, proceedingsId, dispatch]);
  useEffect(() => {
    if (!loginUser) return;
    const menuId = sessionStorage.getItem('menuId');
    const auth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0));
    const isReaderV = auth?.authorization.type === 'V';
    if (!isReaderV) return;
    alert({
      title: '권한 오류',
      closeText: '확인',
      afterClose: () => navigate('/proceedings'),
      children: ReadAuthErrorMsgArr,
    });
  }, [alert, loginUser, navigate, access, list, proceedingsId]);
  useEffect(() => {
    const proceedingId = location.state
      ? location.state.id
      : location.pathname.split('/').reverse()[0];
    dispatch(proceedingsAction.setId(ProceedingsId(proceedingId)));
    return () => {
      dispatch(proceedingsAction.setId(undefined));
      dispatch(proceedingsAction.setDetail(undefined));
      dispatch(proceedingsAction.setMemoList([]));
    };
  }, [dispatch, location.state, location.pathname]);
  useEffect(() => {
    if (id) {
      dispatch(proceedingsAction.setId(ProceedingsId(id)));
      dispatch(loginAction.requestDetail());
      dispatch(proceedingsAction.getAccess());
    }
  }, [id]);
  useEffect(() => {
    closeStatus(
      requestDelete,
      () => {
        navigate('/proceedings');
        dispatch(proceedingsAction.setId(undefined));
        dispatch(proceedingsAction.setFilter(initialProceedingsQuery));
      },
      () => {
        dispatch(proceedingsAction.requestDelete('idle'));
      }
    );
  }, [requestDelete]);
  useEffect(() => {
    closeStatus(
      requestAccess,
      () => {},
      () => {
        dispatch(proceedingsAction.requestAccess('idle'));
      },
      () => {
        alert({
          children: [
            {
              value: requestAccess,
            },
          ],
          afterClose: () => {
            navigate('/proceedings');
            dispatch(proceedingsAction.setFilter(filter));
          },
        });
      }
    );
  }, [requestAccess]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <BasicInfoSection
            detail={detail}
            update={update}
            isReader={isReader}
            isWriter={isWriter}
            isAttendance={isAttendance}
          />
        )}
      </Body>
    </Layout>
  );
}
