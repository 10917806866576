import { affiliatedCompanyOutlineQuery } from '@front/src/features/affiliated-company/features/outline/query/query';

export const affiliatedCompanyOutlineRepository = {
  useOutlineGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyOutlineQuery.useOutlineGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
};
