import { useIsMutating } from 'react-query';

export const useNoteMutationLoading = () => {
  const isNoteCreateLoading = useIsMutating({ mutationKey: ['note', 'create'] });
  const isNoteUpdateLoading = useIsMutating({ mutationKey: ['note', 'update'] });
  const isNoteDeleteLoading = useIsMutating({ mutationKey: ['note', 'delete'] });
  const isCommentCreateLoading = useIsMutating({ mutationKey: ['note', 'comment', 'create'] });
  const isCommentUpdateLoading = useIsMutating({ mutationKey: ['note', 'comment', 'update'] });
  const isCommentDeleteLoading = useIsMutating({ mutationKey: ['note', 'comment', 'delete'] });
  const isNoteReviewCreateLoading = useIsMutating({ mutationKey: ['note', 'review', 'create'] });
  const isNoteReviewUpdateLoading = useIsMutating({ mutationKey: ['note', 'review', 'update'] });

  return !!(
    isNoteCreateLoading ||
    isNoteUpdateLoading ||
    isNoteDeleteLoading ||
    isCommentCreateLoading ||
    isCommentUpdateLoading ||
    isCommentDeleteLoading ||
    isNoteReviewCreateLoading ||
    isNoteReviewUpdateLoading
  );
};
