import React from 'react';
import ProjectSalesFeature from '@front/src/features/project-sales/index';
import { Route, Routes } from 'react-router-dom';
import ProjectSalesPageFeature from '@front/src/features/project-sales/features/page';
import { ActivityTab } from '@front/src/features/project-sales/features/tabs/activity';
import { DrawingManagementTab } from '@front/src/features/project-sales/features/tabs/drawing-management';
import { StatusTab } from '@front/src/features/project-sales/features/tabs/status';
import { DesignOutlineTab } from '@front/src/features/project-sales/features/tabs/design-outline';
import { TestObjectTab } from '@front/src/features/project-sales/features/tabs/test-object';
import { BiddingTab } from '@front/src/features/project-sales/features/tabs/bidding';
import { AffiliatedCompanyTab } from '@front/src/features/project-sales/features/tabs/affiliated-company';
import { EstimationTab } from '@front/src/features/project-sales/features/tabs/estimation';
import { ContractTab } from '@front/src/features/project-sales/features/tabs/contract';
import { CollectionTab } from '@front/src/features/project-sales/features/tabs/collection';
import { CmTab } from '@front/src/features/project-sales/features/tabs/cm';
import { AbstractTab } from '@front/src/features/project-sales/features/tabs/abstract';
import { StructuralAnalysisTab } from '@front/src/features/project-sales/features/tabs/structural-analysis';
import {Navigate} from "react-router";

export const projectSalesPath = `/project/sales`;
export const projectSalesDefaultTabPath = 'abstract';

const ProjectSalesRoutes = () => (
  <Routes>
    <Route
      path=""
      element={<ProjectSalesFeature />}
    >
      <Route
        path=":id"
        element={<ProjectSalesPageFeature />}
      >
        <Route index element={<Navigate to={projectSalesDefaultTabPath} replace />} />
        <Route
          path="abstract"
          element={<AbstractTab />}
        />
        <Route
          path="activity"
          element={<ActivityTab />}
        />
        <Route
          path="status"
          element={<StatusTab />}
        />
        <Route
          path="drawing-management"
          element={<DrawingManagementTab />}
        />
        <Route
          path="design-outline"
          element={<DesignOutlineTab />}
        />
        <Route
          path="test-object"
          element={<TestObjectTab />}
        />
        <Route
          path="bidding"
          element={<BiddingTab />}
        />
        <Route
          path="affiliated-company"
          element={<AffiliatedCompanyTab />}
        />
        <Route
          path="estimation"
          element={<EstimationTab />}
        />
        <Route
          path="contract"
          element={<ContractTab />}
        />
        <Route
          path="collection"
          element={<CollectionTab />}
        />
        <Route
          path="structural-ananlysis"
          element={<StructuralAnalysisTab />}
        />
        <Route
          path="cm"
          element={<CmTab />}
        />
        <Route
          path="*"
          element={<div style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>존재하지 않는 탭입니다. 주소를 다시한번 확인하세요.</div>}
        />
      </Route>
    </Route>
  </Routes>
);
export default ProjectSalesRoutes;
