import { useForm } from 'react-hook-form';
import { WorkCarouselPosition } from '@front/src/features/work/features/work/features/carousel/utils/constant';
import { useSnackbar } from 'notistack';

const useWorkCarouselMoveSLideForm = () => {
  const methods = useForm<WorkCarouselMoveSlideFormValues>({
    defaultValues,
  });

  const { reset } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const onInvalid = (errors) => {
    if (errors.position) {
      enqueueSnackbar(errors.position.message, { variant: 'error' });
    }
    if (errors.workCarouselBundleId) {
      enqueueSnackbar(errors.workCarouselBundleId.message, { variant: 'error' });
    }
  };

  const resetValues = () => {
    reset({
      ...defaultValues,
    });
  };

  return {
    methods,
    onInvalid,
    resetValues,
  };
};

export default useWorkCarouselMoveSLideForm;

const defaultValues = {
  position: WorkCarouselPosition.FRONT,
  workCarouselBundleId: undefined,
};

interface WorkCarouselMoveSlideFormValues {
  position: WorkCarouselPosition;
  workCarouselBundleId: number | undefined;
}
