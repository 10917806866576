import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { contractHistoryApi } from '@front/src/features/project-sales/features/tabs/contract/sections/history/api/api';

const contractHistoryQuery = {
  useList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryFn: () => contractHistoryApi.getList(commonParams),
      queryKey: ['project', 'sales', commonParams.dataId, 'contract', 'history', 'list'],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return { data };
  },
};

export default contractHistoryQuery;
