import { useQuery } from 'react-query';
import AccountStatusConstant
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/constant';
import AccountStatusApi
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/api';
import {
  AccountStatusBankAccountStatusGroupDTO,
  AccountStatusGetParameter,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';

type QueryType = {
  useGetList: (params: AccountStatusGetParameter) => {
    data: AccountStatusBankAccountStatusGroupDTO | undefined,
    isLoading: boolean,
  }
}
const AccountStatusQuery: QueryType = {
  useGetList: (params) => {
    const { data, isLoading } = useQuery<AccountStatusBankAccountStatusGroupDTO>({
      queryFn: () => AccountStatusApi.getList(params),
      queryKey: AccountStatusConstant.queryKey.list,
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};

export default AccountStatusQuery;
