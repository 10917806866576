import React, { memo, ReactNode, Suspense } from 'react';
import ResizableRightDrawer from '@front/src/components/list-drawer/components/resizable-right-drawer';
import NoteContent from '@front/src/features/note/components/Content';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import useNoteMenuId from '@front/src/hooks/useNoteMenuId';
import { FormProvider, useForm } from 'react-hook-form';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import noteQuery from '@front/src/features/note/query/query';
import NoteUserSelectorModalWrapperContainer from '@front/src/features/note/components/NoteUserSelectorModalWrapper';

function NoteFeature() {
  return (
    <Suspense
      fallback={
        <ResizableRightDrawer>
          <LoadingSpinner
            width="100%"
            height="100%"
          />
        </ResizableRightDrawer>
      }
    >
      <ResizeWrapper>
        <NoteFeatureWrapper />
      </ResizeWrapper>
    </Suspense>
  );
}

export default memo(NoteFeature);

const NoteFeatureWrapper = () => {
  useNoteMenuId();

  return (
    <ResizableRightDrawer>
      <NoteContent />
    </ResizableRightDrawer>
  );
};

const ResizeWrapper = ({ children }: { children: ReactNode }) => {
  const { menuId, dataId, rowId, sectionId, noteId } = useIdGroup();
  const params = { menuId, dataId, rowId, sectionId };
  const userParams = { menuId, dataId };
  const { data: subData } = noteQuery.useGetNoteSubData(params);
  const { data: userList } = noteQuery.useGetAuthUserList(userParams);

  const methods = useForm({
    values: {
      note: '',
      search: '',
      sectionName: subData?.sectionName ?? '',
      waitApprovalCount: subData?.waitApprovalCount ?? 0,
      unreadReviewCount: subData?.unreadReviewCount ?? 0,
    },
  });

  return (
    <FormProvider {...methods}>
      <NoteUserSelectorModalWrapperContainer
        noteId={noteId ?? 0}
        reviewerList={userList ?? []}
      />
      {children}
    </FormProvider>
  );
};
