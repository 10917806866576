import { projectSalesInfoSubjectReviewModalComplexInformationRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { useLogic as useProjectSalesInfoSubjectReviewModalComplexInformationListLogic };
const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: list, isLoading } =
    projectSalesInfoSubjectReviewModalComplexInformationRepository.useListGet(id, menuId);
  return { list, isLoading };
};
