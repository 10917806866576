import { strToYNConvertor } from '@front/src/utils';

export const bidInfoHeadList = [
  '',
  '회차',
  '공고일',
  '마감일시',
  '견적번호',
  '한풍투찰여부',
  '최종여부',
  '입찰공고문\n업로드/다운',
  '입찰지침서\n업로드/다운',
  '비고',
];

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const convertBidResultForParameter = (data) => ({
  id: data.id,
  isDoneBid: data.isDoneBid === '' ? null : strToYNConvertor[data.isDoneBid],
  amount: data.amount ? +data.amount.replace(/,/g, '') : null,
  projectBidInformationId: data.projectBidInformationId || null,
  note: data.note || null,
  winnerId: data.winnerId || null,
  winnerName: data.winnerName || null,
});
