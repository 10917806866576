import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyCharacterOpinionApi } from '@front/src/features/affiliated-company/features/character/features/opinion/query/api';
import type { AffiliatedCompanyCharacterOpinionUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/opinion/types/parameter';

export const affiliatedCompanyCharacterOpinionMutation = {
  useCharacterOpinionCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        affiliatedCompanyCharacterOpinionApi.createCharacterOpinion(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'character', 'opinion'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useCharacterOpinionUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyCharacterOpinionUpdateParameter) =>
        affiliatedCompanyCharacterOpinionApi.updateCharacterOpinion(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'character', 'opinion'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useCharacterOpinionDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyCharacterOpinionApi.deleteCharacterOpinion(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'character', 'opinion'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
