import React from 'react';
import cellComponent, {
  type CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { HeaderSettingView } from '@front/src/types';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';

interface Props extends Omit<CellComponentProps, 'isEditMode'> {
  header: HeaderSettingView;
  innerCellIndex?: number | undefined;   // 행 병합 효과 제어용 속성
}

export default function UIBuilderEditModalTableBody(props: Readonly<Props>) {
  const { header, readOnly, sx, innerCellIndex, ...rest } = props;
  return (
    <>
      {header.modalHeaderList.map((c) => {
        const Component = cellComponent[c.headerSettingId];
        return (
          <TableRowUI key={c.headerSettingId}>
            <TableCellUI
              isHead
              width="120px"
            >
              {c.name}
            </TableCellUI>
            <TableCellUI>
              <Component
                {...rest}
                isForm
                readOnly={readOnly}
                isEditMode={!readOnly}
                innerCellIndex={innerCellIndex}
                sx={{ width: '100%', '& .MuiFormControl-root': { width: '100%' } }}
              />
            </TableCellUI>
          </TableRowUI>
        );
      })}
    </>
  );
}
