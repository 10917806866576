import React from 'react';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import { ProjectSalesInfoCollectionManagementModalUpdateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoCollectionManagementModalUpdateModalWrapper };
interface Props {
  menuId?: number;
}
const ModalWrapper = ({ menuId }: Props) => {
  const isUpdateModalOpen = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.isUpdateModalOpen
  );
  return (
    <>
      {isUpdateModalOpen && (
        <ProjectSalesInfoCollectionManagementModalUpdateModalWidget menuId={menuId} />
      )}
    </>
  );
};
