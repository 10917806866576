import React, { useState } from 'react';

import type { MenuView } from '@front/src/types';
import {
  TabMainItemUI,
  TabMainUI,
} from '@front/src/components/components-with-design/component/TabUI';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { ModalContentUI } from '@front/src/components/components-with-design/layout/ModalUI';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export default function WorkApprovalReadingContent() {
  const { watch } = useFormContext();
  const [index, setIndex] = useState<number>(0);
  const handleChange = (event, value) => {
    event.preventDefault();
    setIndex(value);
  };
  const menuId = tabList[index].id;
  return (
    <ModalContentUI>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxHeight: '72dvh',
        }}
      >
        <TabMainUI
          value={index}
          onChange={handleChange}
        >
          {tabList.map((t) => (
            <TabMainItemUI
              key={t.id}
              label={t.title}
            />
          ))}
        </TabMainUI>
        <Box
          sx={{
            width: '100%',
            padding: '14px',
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
            background: ColorPalette.background.bg,
          }}
        >
          <CommonSectionList
            readOnly
            menuId={menuId}
            dataId={+watch('workId')}
          />
        </Box>
      </Box>
    </ModalContentUI>
  );
}

const tabList: MenuView[] = [
  {
    id: 88,
    title: '업무',
    path: '/work/{:id}/work',
    seq: 1,
    depth: null,
    icon: null,
    isTab: true,
    alarmType: null,
    hasDrawer: null,
    count: null,
    children: null,
  },
  {
    id: 121,
    title: '기본정보',
    path: '/work/{:id}/basic-info',
    seq: 2,
    depth: null,
    icon: null,
    isTab: true,
    alarmType: null,
    hasDrawer: null,
    count: null,
    children: null,
  },
];
