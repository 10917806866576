import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoEstimationConfirmedTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/components/estimation-confirmed-table';
import { ProjectSalesInfoEstimationConfirmedContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/context';
import { useProjectSalesInfoEstimationConfirmedLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/useLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { VatRatioHeader } from '@front/src/components/vat-ratio-header/vat-ratio-header';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoEstimationConfirmedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { Box } from '@mui/material';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoEstimationConfirmedWidget = ({ readOnly, name, menuId }: Readonly<Props>) => {
  const { Body, Header } = AddDeleteTable;
  const {
    d: { detail, statusVariableList, winningPossibilityVariableList },
    h: { onUpdate },
    isLoading,
  } = useProjectSalesInfoEstimationConfirmedLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detail, statusVariableList, winningPossibilityVariableList },
      h: { onUpdate },
      isLoading,
    }),
    [isLoading, detail, onUpdate, statusVariableList, winningPossibilityVariableList]
  );
  const { setReadOnly } = useProjectSalesInfoEstimationConfirmedState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (isLoading) {
    return <LoadingSpinner height="200px" />;
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Header title={name}>
        <VatRatioHeader />
      </Header>
      <Body>
        <ProjectSalesInfoEstimationConfirmedContext.Provider value={value}>
          <ProjectSalesInfoEstimationConfirmedTable />
        </ProjectSalesInfoEstimationConfirmedContext.Provider>
      </Body>
    </Box>
  );
};

export default ProjectSalesInfoEstimationConfirmedWidget;
