import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/mutation';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonQuery.useListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const { mutate } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: (menuId) => {
    const { mutate } =
      projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
