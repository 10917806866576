import React, { memo, useContext } from 'react';
import { TableRow } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';
import { OldTd } from '@front/layouts/Table';

import { AffiliatedCompanyPersonDetailModalMeetingHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/widgets/context';
import { purposeCategoryKOR } from '@front/src/features/affiliated-company/types/domain';
import { getCodeIfExist } from '@front/src/features/project-selector-modal/utils';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { MeetingHistoryTableBody as AffiliatedCompanyPersonDetailModalMeetingHistoryTableBody };

const MeetingHistoryTableBody = () => {
  const {
    d: { meetingList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalMeetingHistoryContext);
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  if (typeof meetingList === 'undefined' || isLoading) {
    return <Spinner />;
  }
  if (meetingList.length === 0) {
    return <NoResult />;
  }

  return (
    <>
      {meetingList.map((meeting, index) => (
        <TableRow key={meeting.id}>
          <OldTd>{index + 1}</OldTd>
          <OldTd>{meeting.date ?? '-'}</OldTd>
          <OldTd>
            {meeting.participantList.length === 0 && '-'}
            {meeting.participantList.length > 0 &&
              meeting.participantList.map((item) => <div key={item.id}>{item.name}</div>)}
          </OldTd>
          <OldTd>
            {meeting.companionList.length === 0 && '-'}
            {meeting.companionList.length > 0 &&
              meeting.companionList.map((item) => <div key={item.id}>{item.name}</div>)}
          </OldTd>
          <OldTd>{purposeCategoryKOR[meeting.purpose] ?? '-'}</OldTd>
          <OldTd>
            {(meeting.projectList.length === 0 || currentAuth === 'V') && '-'}
            {meeting.projectList.length > 0 &&
              currentAuth !== 'V' &&
              meeting.projectList.map((item) => (
                <div key={item.id}>
                  {getCodeIfExist(item.code)} {item.nickName}
                </div>
              ))}
          </OldTd>
          <OldTd>{meeting.summary ?? '-'}</OldTd>
        </TableRow>
      ))}
    </>
  );
};

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={8}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));

Spinner.displayName = 'AffiliatedCompanyPersonDetailModalMeetingHistoryTableBodySpinner';

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={8}>저장된 미팅이 없습니다.</OldTd>
  </TableRow>
));

NoResult.displayName = 'AffiliatedCompanyPersonDetailModalMeetingHistoryTableBodyNoResult';
