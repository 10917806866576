import { ProjectSalesView } from '@front/src/features/project-sales/features/aside/type/views';
import { ProjectSalesCreateParameters } from '@front/src/features/project-sales/features/aside/type/parameters';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import axios, { type AxiosResponse } from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  create: () => '/api/project/sales/sales-information',
  getOne: (id: number) => `/api/project/sales/sales-information/${id}`,
  getList: () => '/api/project/sales/sales-information',
};

export const projectSalesApi = {
  create: async (params: ProjectSalesCreateParameters, commonParams: UIBuilderParameter) =>
    await axios.post(url.create(), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getOne: async <T = ProjectSalesView>(id: number, commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getOne(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  getList: async <T = ProjectSalesView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getList(), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
};
