import React, { useContext } from 'react';
import { HookFormTimePicker } from '@front/src/components/time-picker-with-hook-form/time-picker-new';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import dayjs from 'dayjs';
import { HH_MM } from '@front/src/utils';
import { Td } from '@front/src/components/layout/table/td';

export { CellTime as SalesInfoActivityTableCellTime };

interface Props {
  onSubmit: () => Promise<void>;
}

const CellTime = ({ onSubmit }: Props) => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (item.activity?.id !== id) {
    return (
      <Td rowSpan={item.sameActivitySize}>
        {item.activity?.time && dayjs(item.activity.time, HH_MM).format(HH_MM)}
      </Td>
    );
  }
  return (
    <Td rowSpan={item.sameActivitySize}>
      <HookFormTimePicker
        name="time"
        onChange={onSubmit}
        disabled={readOnly}
      />
    </Td>
  );
};
