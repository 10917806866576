import { HeaderSettingView } from '@front/src/types';
import React, { ReactNode, useEffect, useRef } from 'react';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import { controlPanelTableCellWidth } from '@front/src/utils';

interface FixedColumnSupportProp {
  headerList: undefined | HeaderSettingView[];
  readOnly?: boolean;
  children: ReactNode;
}

export default function FixedColumnSupport({
  headerList,
  readOnly = false,
  children,
}: FixedColumnSupportProp) {
  const ref = useRef<HTMLTableSectionElement | null>(null);
  useEffect(() => {
    if (!ref || !ref.current) return;
    handleFixedColumnsOfTable(ref, headerList, readOnly);
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          handleFixedColumnsOfTable(ref, headerList, readOnly);
        }
      }
    });
    if (ref.current?.getElementsByTagName('tbody')) {
      const tbody = ref.current?.getElementsByTagName('tbody')[0];
      observer.observe(tbody, { childList: true });
    }

    const debouncedResize = debounce(
      () => updateScrollClass(ref.current?.firstChild as HTMLElement),
      200
    );
    debouncedResize();
    window.addEventListener('resize', debouncedResize);

    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (debouncedResize) {
        window.removeEventListener('resize', debouncedResize);
      }
    };
  }, [headerList, readOnly, ref?.current, ref?.current?.getElementsByTagName('tbody')]);

  return <div ref={ref}>{children}</div>;
}

function handleFixedColumnsOfTable(
  ref: React.MutableRefObject<HTMLTableSectionElement | null>,
  headerList: undefined | HeaderSettingView[],
  readOnly: boolean
) {
  const tableElement = ref.current?.getElementsByTagName('table')[0];
  const rowElements = tableElement?.getElementsByTagName('tr');
  if (!rowElements) return;
  for (let i = 0; i < rowElements.length; i++) {
    handleFixedColumnsOfRows(rowElements[i], headerList, readOnly);
  }
}

function handleFixedColumnsOfRows(
  rowElement: HTMLTableRowElement,
  headerList: undefined | HeaderSettingView[],
  readOnly: boolean
) {
  const ths = rowElement.getElementsByTagName('th');
  const tds = rowElement.getElementsByTagName('td');
  if (!ths || !tds || !headerList || !headerMeta) return;

  // clean-up previous sticky styles
  rowElement
    .querySelectorAll('td.fixed, th.fixed')
    .forEach((value) => releaseColumn(value as HTMLElement));

  const fixedColumnIndex = headerList.findIndex((header) => header.isFixed);
  if (fixedColumnIndex < 0) return;

  const systemColumnCounts = readOnly ? 1 : 2;
  const fixedColumnCounts = fixedColumnIndex + systemColumnCounts;
  let leftOffsetSum = 0;
  for (let i = 0; i <= fixedColumnCounts; i++) {
    i < ths.length && fixColumn(ths[i], leftOffsetSum);
    i < tds.length && fixColumn(tds[i], leftOffsetSum);

    if (i === fixedColumnCounts) {
      i < ths.length && ths[i]?.classList.add('last-fixed');
      i < tds.length && tds[i]?.classList.add('last-fixed');
    }
    const width =
      i < systemColumnCounts
        ? controlPanelTableCellWidth
        : headerMeta[headerList[i - systemColumnCounts].id].width;
    leftOffsetSum += width;
  }
}

function fixColumn(element: HTMLElement, columnLeftOffset) {
  if (!element) return;
  element.classList.add('fixed');
  element.classList.remove('last-fixed');
  element.style.left = `${columnLeftOffset}px`;
}

function releaseColumn(element: HTMLElement) {
  if (!element) return;
  element.classList.remove('fixed');
  element.classList.remove('last-fixed');
  element.style.removeProperty('left');
}

function updateScrollClass(parentElement: HTMLElement) {
  if (!parentElement) return;
  if (parentElement.scrollWidth > parentElement.clientWidth) {
    parentElement.classList.add('has-scrollbar');
  } else {
    parentElement.classList.remove('has-scrollbar');
  }
}

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  } as T;
}
