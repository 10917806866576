import React, { memo } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@mui/material';

import Select from '@front/layouts/Select';
import type { CampaignStatus } from '@front/ost_campaign/domain';
import { campaignStatusList, campaignStatusName } from '@front/ost_campaign/domain';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';

interface Props {
  status?: CampaignStatus;
  onChangeStatus: (e: SelectChangeEvent<unknown>) => void;
}

const Status = ({ status, onChangeStatus }: Props) => (
  <DataFieldWithLabel
    label="상태"
    labelSX={{ minWidth: '80px' }}
  >
    <Select
      displayEmpty
      variant="outlined"
      value={status || ''}
      onChange={onChangeStatus}
    >
      <MenuItem value="">상태명</MenuItem>
      {campaignStatusList.map((item) => (
        <MenuItem
          key={item}
          value={item}
        >
          {campaignStatusName(item)}
        </MenuItem>
      ))}
    </Select>
  </DataFieldWithLabel>
);

const StatusForm = memo(Status);

export default StatusForm;
