import React, { useCallback, useContext } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingSettingsManagementAccountingAccountAttributeModalBody } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/attribute-modal-body';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';

export { AttributeModal as AccountingSettingsManagementAccountingAccountAttributeModal };

const AttributeModal = () => {
  const { handleClose: onMenuClose } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const { isOpen, setIsOpen } = useAccountingSettingsManagementAccountingAccountTableState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }))
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
    onMenuClose();
  }, [setIsOpen, onMenuClose]);
  return (
    <ModalLayout
      open={isOpen}
      title="계정 속성 설정"
      width="28vw"
      height="200px"
      onClose={handleClose}
    >
      <AccountingSettingsManagementAccountingAccountAttributeModalBody />
    </ModalLayout>
  );
};
