import React from 'react';
import { AffiliatedPersonSalesActivityRowProvider } from '@front/src/features/affiliated-person/features/sales-activity/widgets/row/provider/proivder';
import type { AffiliatedPersonSalesActivityConvertedView } from '@front/src/features/affiliated-person/features/sales-activity/types/view';
import { AffiliatedPersonSalesActivityRow } from '@front/src/features/affiliated-person/features/sales-activity/widgets/row/components/row';

export { Widget as AffiliatedPersonSalesActivityRowWidget };

interface Props {
  item: AffiliatedPersonSalesActivityConvertedView;
}

const Widget = ({ item }: Props) => (
  <AffiliatedPersonSalesActivityRowProvider item={item}>
    <AffiliatedPersonSalesActivityRow />
  </AffiliatedPersonSalesActivityRowProvider>
);
