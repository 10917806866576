import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewShapeRatioCreateButtonLogic } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewShapeRatioCreateButtonContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewShapeRatioCreateButtonProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onCreate } = useProjectSalesInfoSubjectReviewShapeRatioCreateButtonLogic(menuId);
  const value = useMemo(() => ({ onCreate }), [onCreate]);
  return (
    <ProjectSalesInfoSubjectReviewShapeRatioCreateButtonContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewShapeRatioCreateButtonContext.Provider>
  );
};
