import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UIBuilderTableHeader, { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';

type Props = Readonly<UIBuilderTableHeadProps>;
export type ElementType = (props: Props) => React.ReactElement<Props, typeof UIBuilderTableHeader>;

const AccountStatusWidgetSummaryTableHead: ElementType = (props) => {

  const controlPanelHeaderItemList = [
    {
      text: '최상단에 추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        console.log('action fired');
      },
      seq: 1,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
    />
  );
};

export default AccountStatusWidgetSummaryTableHead;
