import { projectUrl } from '@front/src/features/accounting/utils/constant';
import type { OptionType } from '@front/src/components/Select';
import {
  AccountingUploadCategory,
  ECOUNT_FILE_TYPES,
} from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';

export const accountingProjectSalesInfoUrl = `${projectUrl}/sales-information`;
export const accountingProjectSalesInfoAmountUrl = `${projectUrl}/amount-information`;

export const accountingUploadCategoryList: OptionType[] = [
  {
    label: AccountingUploadCategory[ECOUNT_FILE_TYPES.BANK_ACCOUNT],
    value: ECOUNT_FILE_TYPES.BANK_ACCOUNT,
  },
  {
    label: AccountingUploadCategory[ECOUNT_FILE_TYPES.DAILY_FUNDS],
    value: ECOUNT_FILE_TYPES.DAILY_FUNDS,
  },
  {
    label: AccountingUploadCategory[ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT],
    value: ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT,
  },
];
