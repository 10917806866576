import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React from 'react';

export { TableHead as CollectionFinalTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  return (
    <UIBuilderTableHeader
      {...props}
      isSingleRow
      controlPanelHeaderItemList={[]}
    />
  );
}
