import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import type { WorkOutputFileUpdateParameter } from '@front/src/features/work/features/work/features/output/types/parameter';

const useWorkOutputUpdateFile = (commonParams, bundleId?: number) => {
  const callback = getNoOpCallback();
  const { mutate } = workOutputMutation.useUpdateFile(commonParams, bundleId);
  return {
    run: (params: WorkOutputFileUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkOutputUpdateFile;
