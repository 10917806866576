// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ihe0DvuMQbCXzACMTrGd{display:flex;gap:10px;justify-content:space-between}.AtK2IDgHs9QHAqgGCztB{display:flex;align-items:center;justify-content:center;font-size:16px;font-weight:bold}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-header.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 10px;\r\n  justify-content: space-between;\r\n}\r\n\r\n.title {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ihe0DvuMQbCXzACMTrGd`,
	"title": `AtK2IDgHs9QHAqgGCztB`
};
export default ___CSS_LOADER_EXPORT___;
