import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export { context as ProjectSalesInfoActivityDeleteButtonContext };

interface State {
  onDelete: (params: IdListDeleteParameter) => void;
}

const context = createContext<State>({
  onDelete: noOp,
});
