import React from 'react';
import { AffiliatedCompanySalesActivityTable } from '@front/src/features/affiliated-company/features/sales-activity/components/sales-activity-table';
import { AffiliatedCompanySalesActivityListWidget } from '@front/src/features/affiliated-company/features/sales-activity/widgets/list/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';

interface Props extends SectionComponentProps {}

const AffiliatedCompanySalesActivityFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AffiliatedCompanySalesActivityTable
          widget={<AffiliatedCompanySalesActivityListWidget menuId={menuId} />}
        />
      </Body>
    </Layout>
  );
};

export default AffiliatedCompanySalesActivityFeature;
