import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoPaymentHistoryCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/widgets/context';
import classes from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/components/create-modal-table.module.scss';
import { convertProjectReviewAmountPaymentHistoryForParameter } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { useShallow } from 'zustand/react/shallow';

export { CreateModalBtn as ProjectSalesInfoPaymentHistoryCreateModalBtn };

const CreateModalBtn = () => {
  const setIsCreateModalOpen = useProjectSalesInfoPaymentHistoryState(
    (state) => state.setIsCreateModalOpen
  );
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoPaymentHistoryCreateModalContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate(convertProjectReviewAmountPaymentHistoryForParameter(data));
    setIsCreateModalOpen();
  });
  const { readOnly } = useProjectSalesInfoPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.btn__group}>
      <Button
        shape="basic2"
        onClick={() => setIsCreateModalOpen()}
        fullWidth={true}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        fullWidth={true}
        disabled={readOnly}
      >
        저장
      </Button>
    </div>
  );
};
