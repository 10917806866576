import React, { useContext, useEffect, useMemo } from 'react';
import { OldTh } from '@front/layouts/Table';
import { MenuItem, TableRow } from '@mui/material';
import type { ProposalApprovalVO, ProposalGrade } from '@front/ost_proposal/domain';
import {
  ApprovalResult,
  ApprovalResultList,
  ApprovalResultName,
  proposalGradeList,
  ProposalStatus,
} from '@front/ost_proposal/domain';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Input from '@front/layouts/Input';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import Select from '@front/layouts/Select';
import type { RootState } from '@front/services/reducer';
import { FormikContext } from 'formik';
import type { ProposalApprovalId } from '@front/ost_campaign/domain';

interface Props {
  id: ProposalApprovalId;
  index: number;
  item: ProposalApprovalVO;
  approvalList: ProposalApprovalVO[];
}

export { Item as ApprovalItem };

const Item = ({ id, item, index, approvalList }: Props) => {
  const dispatch = useDispatch();

  const { status, loginUser } = useSelector(
    (root: RootState) => ({
      status: root.proposal.detail!.status,
      loginUser: root.login.detail,
    }),
    shallowEqual
  );

  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.estimatorList],
    shallowEqual
  );

  const isApprovalManager = useMemo(
    () => item.manager.id === loginUser?.id,
    [item.manager, loginUser]
  );
  const formik = useContext(FormikContext);

  const allSameGrade = useMemo(() => {
    if (typeof estimatorList === 'undefined') {
      return '';
    }
    for (let i = 0; i < estimatorList.length - 1; i++) {
      if (estimatorList[i].grade !== estimatorList[i + 1].grade) {
        return '';
      }
    }
    return estimatorList[0].grade;
  }, [estimatorList]);

  useEffect(() => {
    if (allSameGrade === '') {
      return;
    }
    formik.setFieldValue('grade', allSameGrade);
    formik.setFieldValue('id', item.id);
  }, [allSameGrade, item]);

  return (
    <TableRow>
      <OldTh>{index + 1 === approvalList.length ? '최종 결재' : `${index + 1}차 결재`}</OldTh>
      <OldTh>{item.position}</OldTh>
      <OldTh>{item.manager.name}</OldTh>
      <OldTh>
        <Select
          displayEmpty
          readOnly={
            !isApprovalManager ||
            !(index > 0 ? approvalList[index - 1].result : true) ||
            (approvalList.length === index + 1
              ? !!approvalList[index].result
              : !!approvalList[index + 1].result) ||
            status === ProposalStatus.END ||
            status === ProposalStatus.DENY
          }
          variant="outlined"
          value={
            (id === formik.values.id && formik.values.result) ||
            item.result ||
            ApprovalResult.APPROVED
          }
          onChange={(e) => {
            const value = e.target.value as ApprovalResult;
            if (formik.values.result !== value) {
              formik.setFieldValue('id', item.id);
              formik.setFieldValue(`result`, value);
            }
          }}
        >
          <MenuItem
            disabled
            value=""
          >
            선택
          </MenuItem>
          {index === 0 &&
            ApprovalResultList.filter((item) => item !== ApprovalResult.BEFORE_REJECTED).map(
              (item) => (
                <MenuItem
                  key={`${item}`}
                  value={item}
                  selected={item === ApprovalResult.APPROVED}
                >
                  {ApprovalResultName(item)}
                </MenuItem>
              )
            )}
          {index > 0 &&
            (approvalList[index - 1].result !== ApprovalResult.BEFORE_REJECTED
              ? ApprovalResultList.map((item) => (
                  <MenuItem
                    key={`${item}`}
                    value={item}
                    selected={item === ApprovalResult.APPROVED}
                  >
                    {ApprovalResultName(item)}
                  </MenuItem>
                ))
              : ApprovalResultList.filter((item) => item !== ApprovalResult.BEFORE_REJECTED).map(
                  (item) => (
                    <MenuItem
                      key={`${item}`}
                      value={item}
                      selected={item === ApprovalResult.APPROVED}
                    >
                      {ApprovalResultName(item)}
                    </MenuItem>
                  )
                ))}
        </Select>
      </OldTh>
      <OldTh>
        <Input
          variant="outlined"
          disabled={
            !isApprovalManager ||
            !(index > 0 ? approvalList[index - 1].result : true) ||
            (approvalList.length === index + 1
              ? !!approvalList[index].result
              : !!approvalList[index + 1].result) ||
            status === ProposalStatus.END ||
            status === ProposalStatus.DENY
          }
          readOnly={
            !isApprovalManager ||
            !(index > 0 ? approvalList[index - 1].result : true) ||
            status === ProposalStatus.END ||
            status === ProposalStatus.DENY
          }
          defaultValue={(id === formik.values.id && formik.values.comment) || item.comment || ''}
          onBlur={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (!value.trim()) {
              dispatch(
                snackbarAction.show({
                  message: '심사의견을 입력 하세요.',
                  severity: SnackbarSeverityType.warning,
                })
              );
            }
            if (formik.values.comment !== value) {
              formik.setFieldValue('id', item.id);
              formik.setFieldValue(`comment`, value);
            }
          }}
        />
      </OldTh>
      <OldTh>
        <Select
          displayEmpty
          disabled={!item.result && formik.values.result !== ApprovalResult.APPROVED}
          readOnly={
            !isApprovalManager ||
            !(index > 0 ? approvalList[index - 1].result : true) ||
            (approvalList.length === index + 1
              ? !!approvalList[index].result
              : !!approvalList[index + 1].result) ||
            status === ProposalStatus.END ||
            status === ProposalStatus.DENY
          }
          variant="outlined"
          value={(id === formik.values.id && formik.values.grade) || item.grade || allSameGrade}
          onChange={(e) => {
            const value = e.target.value as ProposalGrade;
            if (formik.values.grade !== value) {
              formik.setFieldValue('id', item.id);
              formik.setFieldValue(`grade`, value);
            }
          }}
        >
          <MenuItem
            disabled
            value=""
          >
            선택
          </MenuItem>
          {proposalGradeList.map((item) => (
            <MenuItem
              key={`${item}`}
              value={item}
              selected={item === allSameGrade}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </OldTh>
    </TableRow>
  );
};
