import React, { Suspense } from 'react';
import {
  absenteeismPath,
  accountingBudgetPath,
  adminPath,
  calendarPath,
  dbAnalysisPath,
  defaultApprovalListPath,
  departmentDetailPath,
  departmentManagementPath,
  departmentTreePath,
  design1Path,
  design2Path,
  editPath,
  executionPath,
  experimentPath,
  generalizationPath,
  headDepartmentPath,
  hRCardPath,
  manufacturePath,
  myPagePath,
  ostPath,
  personnelDesignPath,
  personnelGantPath,
  personnelOstLogPath,
  pmPath,
  proceedingsPath,
  reportPath,
  rewardPath,
  statisticsPath,
  technologyPath,
  tubingPath,
  wbsPath,
} from '@front/src/utils';
import {
  affiliatedPersonPath,
  defaultAffiliatedCompanyPath,
  workPath,
} from '@front/src/features/affiliated-company/utils/constant';
import { projectSalesInfoPath } from '@front/src/features/project-sales-info/utils/constant';
import { accountingPath } from '@front/src/features/accounting/utils/constant';
import { projectSalesManagementPath } from '@front/src/features/project-sales-management/utils/constant';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { PersonnelOstLog } from '@front/personnel/route/ostLog';
import { DepartmentDetail } from '@front/department/route/detail';
import { DepartmentTreeWrapper } from '@front/department_tree/route/page';
import TempWidget from '@front/src/temp/temp-widget';
import AffiliatedPersonRoutes from '@front/src/features/affiliated-person/routes';
import ProjectSalesRoutes, { projectSalesPath } from '@front/src/features/project-sales/routes';

const Approval = React.lazy(() => import('@front/src/features/approval/routes'));
const AffiliatedCompany = React.lazy(() => import('@front/src/features/affiliated-company/routes'));
const ProjectSalesInfo = React.lazy(() => import('@front/src/features/project-sales-info/routes'));
const Accounting = React.lazy(() => import('@front/src/features/accounting/routes'));
const ProjectSalesManagement = React.lazy(
  () => import('@front/src/features/project-sales-management/routes')
);
const Work = React.lazy(() => import('@front/src/features/work/routes'));
const Admin = React.lazy(() => import('@front/src/features/admin/routes'));
const Proceedings = React.lazy(() => import('@front/src/features/proceedings/routes'));
const Ost = React.lazy(() => import('@front/src/features/ost/routes'));
const HRCard = React.lazy(() => import('@front/src/features/human-resources/hr-card/routes'));
const DepartmentManagement = React.lazy(
  () => import('@front/src/features/human-resources/department-management/routes')
);
const MyPage = React.lazy(() => import('@front/src/features/my-page/routes/routes'));

const FallbackLoadingSpinner = () => (
  <div style={{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}><LoadingSpinner/></div>
);

const newRoutes = [
  {
    path: `${defaultApprovalListPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Approval />
      </Suspense>
    ),
  },
  {
    path: `${defaultAffiliatedCompanyPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <AffiliatedCompany />
      </Suspense>
    ),
  },
  {
    path: `${projectSalesInfoPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <ProjectSalesInfo />
      </Suspense>
    ),
  },
  {
    path: `${projectSalesPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <ProjectSalesRoutes />
      </Suspense>
    ),
  },
  {
    path: `${accountingPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Accounting />
      </Suspense>
    ),
  },
  {
    path: `${projectSalesManagementPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <ProjectSalesManagement />
      </Suspense>
    ),
  },
  {
    path: `${affiliatedPersonPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <AffiliatedPersonRoutes />
      </Suspense>
    ),
  },
  {
    path: `${workPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Work />
      </Suspense>
    ),
  },
  {
    path: `${adminPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Admin />
      </Suspense>
    ),
  },
  {
    path: `${proceedingsPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Proceedings />
      </Suspense>
    ),
  },
  {
    path: `${ostPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <Ost />
      </Suspense>
    ),
  },
  {
    path: `${hRCardPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <HRCard />
      </Suspense>
    ),
  },
  {
    path: `${departmentManagementPath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <DepartmentManagement />
      </Suspense>
    ),
  },
  {
    path: `${myPagePath}/*`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <MyPage />
      </Suspense>
    ),
  },
  {
    path: `${personnelOstLogPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <PersonnelOstLog />
      </Suspense>
    ),
  },
  {
    path: `${departmentDetailPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <DepartmentDetail menuId={42} />
      </Suspense>
    ),
  },
  {
    path: `/personnel/department-management/add`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <DepartmentDetail menuId={42} />
      </Suspense>
    ),
  },
  {
    path: `${departmentTreePath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <DepartmentTreeWrapper />
      </Suspense>
    ),
  },

  {
    path: `${technologyPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${experimentPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${design1Path}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${design2Path}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${manufacturePath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${tubingPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${editPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${generalizationPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${pmPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${headDepartmentPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${personnelGantPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${calendarPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${wbsPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${rewardPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${absenteeismPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${personnelDesignPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${personnelDesignPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${accountingBudgetPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${executionPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${reportPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${dbAnalysisPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
  {
    path: `${statisticsPath}`,
    element: (
      <Suspense fallback={<FallbackLoadingSpinner />}>
        <TempWidget />
      </Suspense>
    ),
  },
];

export default newRoutes;