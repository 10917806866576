import type { UserId, UserVO } from '@front/user/domain';
import type { ProposalStatus, ProposalVO } from '@front/ost_proposal/domain';

export type CampaignId = number & { readonly _brand: symbol };

export interface CampaignShortVO {
  id: CampaignId;
  name: string;
  status: CampaignStatus;
  isFavorite: boolean;
  proposalCategoryList: ProposalCategoryVO[];
  alarmCount: number;
}

export type StatusMap = {
  [key in ProposalStatus]: number;
};

export interface CampaignVO extends CampaignShortVO {
  startDate: string;
  endDate: string;
  estimationStartDate: string;
  estimationEndDate: string;
  voteStartDate: string;
  voteEndDate: string;
  totalReward: number;
  manager: UserVO;
  proposalList: ProposalVO[];
  estimateList: UserVO[];
  statusMap: StatusMap;
  fixedResultSum: number;
  ostName: string;
  notice: string;
  isNoticeExposed: boolean;
  approvalList: CampaignApprovalVO[];
  estimationCompletionRate: number;
  campaignBanner: CampaignBannerVO;
}

export enum CampaignStatus {
  /**
   * 준비
   */
  READY = 'READY',
  /**
   * 진행
   */
  ON_GOING = 'ON_GOING',
  /**
   * 종료
   */
  DONE = 'DONE',
  /**
   * 투찰중지
   */
  STOP_VOTING = 'STOP_VOTING',
}

export const campaignStatusList: CampaignStatus[] = [
  CampaignStatus.READY,
  CampaignStatus.ON_GOING,
  CampaignStatus.DONE,
  CampaignStatus.STOP_VOTING,
];

export function campaignStatusName(status: CampaignStatus | '') {
  switch (status) {
    case CampaignStatus.READY:
      return '준비';
    case CampaignStatus.ON_GOING:
      return '진행';
    case CampaignStatus.DONE:
      return '종료';
    case CampaignStatus.STOP_VOTING:
      return '투찰중지';
    default:
      return '-';
  }
}

export type ProposalCategoryId = number & { readonly _brand: symbol };

export interface ProposalCategoryVO {
  id: ProposalCategoryId;
  name: string;
  isUsed: boolean;
}

export type CampaignEstimatorId = number & { readonly _brand: symbol };

export interface CampaignEstimatorVO {
  id: CampaignEstimatorId;
  estimator: UserVO;
}

export type CampaignMemoId = number & { readonly _brand: symbol };

export interface CampaignMemoVO {
  id: CampaignMemoId;
  description: string;
  writer: UserVO;
  createdAt: Date;
  modifiedAt?: Date;
  attendanceList: UserId[];
  isOpenAttendanceList: boolean;
}

//TODO: ProposalApprovalID 관련 로직 이동 필요.
export type ProposalApprovalId = number & { readonly _brand: symbol };

export interface CampaignApprovalVO {
  id: ProposalApprovalId;
  manager: UserVO;
  note: string;
}

export type CampaignBannerId = number & { readonly _brand: symbol };

export function CampaignBannerId(id: number) {
  return id as CampaignBannerId;
}

export interface CampaignBannerVO {
  id: CampaignBannerId;
  content: string;
  isExposed: boolean;
}
