import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkReviewerTableBody from '@front/src/features/work/features/work/features/reviewer/components/TableBody';
import useWorkWorkerGetIsLoading from '@front/src/features/work/features/work/features/reviewer/hooks/useGetIsLoading';
import workReviewerQuery from '@front/src/features/work/features/work/features/reviewer/query/query';
import WorkReviewerTableHead from '@front/src/features/work/features/work/features/reviewer/components/TableHead';

export default function WorkReviewerTable(props: Readonly<SectionComponentProps>) {
  const { sectionId, dataId, menuId } = props;

  const isLoading = useWorkWorkerGetIsLoading();

  const { data: list } = workReviewerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkReviewerTableHead}
      BodyComponent={WorkReviewerTableBody}
      list={list}
    />
  );
}
