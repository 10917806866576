import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { FileAuthView } from '@front/src/features/file-item/features/auth-settings/types/view';
import type { FileAuthUpdateParameter } from '@front/src/features/file-item/features/auth-settings/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getList: () => `/api/file-auth`,
  update: () => `/api/file-auth`,
  getLink: () => `/api/file-item/link`,
  updateDescription: (id) => `/api/file-item/${id}`,
};

const fileItemApi = {
  getList: async <T = FileAuthView[]>(id) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getList(), {
      params: {
        fileItemId: id,
      },
    });
    return data;
  },
  update: async <D = FileAuthUpdateParameter>(params: D) =>
    await axios.patch<unknown, unknown, D>(url.update(), params),
  getLink: async <T = string>(id) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getLink(), {
      params: {
        fileItemId: id,
      },
    });
    return data;
  },
  updateDescription: async (params, commonParams) =>
    await axios.patch(url.updateDescription(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
};

export default fileItemApi;
