import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { ProjectEstimationCompetitorView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/view';
import type { ProjectEstimationCompetitorParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/parameter';

export { context as ProjectSalesInfoEstimationCompetitorUpdateModalContext };

interface State {
  d: {
    detail?: ProjectEstimationCompetitorView;
  };
  h: {
    onUpdate: (params: ProjectEstimationCompetitorParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
    onClose: noOp,
  },
});
