import dayjs from 'dayjs';
import { DATE_FORMAT as DATE_FORMAT, TIME_FORMAT as TIME } from '@front/src/utils';
import type { RefObject } from 'react';

export const formatDateKor = (date: string) => {
  const now = dayjs(date);
  return `${now.format(DATE_FORMAT)} ${now.hour() >= 12 ? '오후' : '오전'} ${now.format(TIME)}`;
};

export const getIsOverflowed = (ref: RefObject<any>, minRows?: number, isHorizontal?: boolean) => {
  if (!ref.current) {
    return false;
  }
  if (isHorizontal) {
    return ref.current.scrollWidth > ref.current.clientWidth;
  }
  return ref.current.scrollHeight - (minRows ?? 0) > ref.current.clientHeight;
};
