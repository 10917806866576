import { GiftTargetType, PersonStatus } from '@front/src/features/affiliated-company/types/domain';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import type { AffiliatedCompanyCharacterPartnerCompanyUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/parameter';

export const getIsKeyPerson = (isKeyPerson: boolean | undefined | null) => {
  let result;
  if (isKeyPerson) {
    result = 'O';
  } else if (typeof isKeyPerson === 'boolean' && !isKeyPerson) {
    result = 'X';
  } else {
    result = '';
  }
  return result;
};

export const convertGiftTargetType = (type: GiftTargetType) => {
  switch (type) {
    case GiftTargetType.PERSON:
      return '개인';
    case GiftTargetType.COMPANY:
      return '회사';
  }
};

export const getYesOrNo = (boolean: boolean | null | undefined) => {
  let result;
  if (boolean) {
    result = '예';
  } else if (boolean === null || boolean === undefined) {
    result = '';
  } else {
    result = '아니오';
  }
  return result;
};

export const spliceAndPushParticipantListIfExist = (
  list: AffiliatedCompanyPersonShortView[],
  target: AffiliatedCompanyPersonShortView | undefined,
  before?: AffiliatedCompanyPersonShortView
) => {
  const updatedList = [...list];
  if (before) {
    const beforeIndex = updatedList.findIndex((item) => item.id === before.id);
    if (beforeIndex !== -1) {
      updatedList.splice(beforeIndex, 1);
    }
  }
  if (typeof target !== 'undefined') {
    const targetIndex = updatedList.findIndex((item) => item.id === target.id);
    if (targetIndex !== -1) {
      updatedList.splice(targetIndex, 1);
    }
    updatedList.push(target);
  }
  return updatedList;
};

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const convertPersonStatus = (status: PersonStatus) => {
  switch (status) {
    case PersonStatus.EMPLOYED:
      return '재직';
    case PersonStatus.RESIGNATION:
      return '퇴사';
  }
  return '-';
};

export const convertIsYesOrNOToBoolean = (
  params: AffiliatedCompanyCharacterPartnerCompanyUpdateParameter
) => {
  let isKeyPartner;
  if (params.isKeyPartner === '예') {
    isKeyPartner = true;
  } else if (params.isKeyPartner === '') {
    isKeyPartner = null;
  } else {
    isKeyPartner = false;
  }
  return {
    ...params,
    isKeyPartner,
  };
};
