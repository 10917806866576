import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsDailyReportStatusUploadLogic } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/provider/useLogic';
import { AccountingSettingsDailyReportStatusUploadContext } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/provider/context';

export { Provider as AccountingSettingsDailyReportStatusUploadProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onCreate } = useAccountingSettingsDailyReportStatusUploadLogic(menuId);
  const value = useMemo(() => ({ onCreate }), [onCreate]);
  return (
    <AccountingSettingsDailyReportStatusUploadContext.Provider value={value}>
      {children}
    </AccountingSettingsDailyReportStatusUploadContext.Provider>
  );
};
