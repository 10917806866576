import React, { useContext } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyPersonDetailModalOutlineCareerTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/components/career-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { AffiliatedCompanyPersonDetailModalOutlineCareerContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/context';

export { CareerTable as AffiliatedCompanyPersonDetailModalOutlineCareerTable };
const CareerTable = () => {
  const {
    d: { careerList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineCareerContext);
  if (typeof careerList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (careerList.length === 0) {
    return <NoResultBox message="저장된 경력이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {careerList.map((item, i) => (
            <AffiliatedCompanyPersonDetailModalOutlineCareerTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
