import { create } from 'zustand';
import type { UserId } from '@front/user/domain';
import type { CardState, NoteItemView, NoteState } from '@front/src/features/note/types/view';

interface State {
  rowId: number;
  sectionId: number;
  sectionName: string;
  noteFilter: string;
  isUnreadReview?: boolean;
  isWaitApproval?: boolean;
  keyword: string;

  isUserSelectorOpen: boolean;
  selectedUserList: UserId[];

  cardState: Record<string, CardState>;
  noteState: NoteState;
  selectedNoteId?: number;
  tempList: NoteItemView[];
  selectedCommentId?: number;

  clickedNoteId?: number;
}

interface Actions {
  setRowId: (rowId: number) => void;
  setSectionId: (sectionId: number) => void;
  setSectionName: (sectionName: string) => void;
  setNoteFilter: (noteFilter: string) => void;
  setIsUnreadReview: (isUnreadReview?: boolean) => void;
  setIsWaitApproval: (isWaitApproval?: boolean) => void;
  setKeyword: (keyword: string) => void;

  setIsUserSelectorOpen: (isUserSelectorOpen: boolean) => void;
  setSelectedUserList: (selectedUserList: UserId[]) => void;

  mentionList: number[];
  setMentionList: (mentionList: number[]) => void;

  clickedReviewer: string;
  setClickedReviewer: (clickedReviewer: string) => void;

  setCardState: (cardId: number, cardState: CardState) => void;
  resetCardState: () => void;
  setNoteState: (noteState: Partial<NoteState>) => void;
  resetNoteState: () => void;

  setSelectedNoteId: (selectedNoteId?: number) => void;
  setTempList: (tempList: NoteItemView[]) => void;
  setSelectedCommentId: (selectedCommentId?: number) => void;

  setClickedNoteId: (clickedNoteId?: number) => void;
}

const useNoteStore = create<State & Actions>((set) => ({
  rowId: 0,
  setRowId: (rowId: number) => set(() => ({ rowId })),
  sectionId: 0,
  setSectionId: (sectionId: number) => set(() => ({ sectionId })),
  sectionName: '',
  setSectionName: (sectionName: string) => set(() => ({ sectionName })),
  noteFilter: 'all',
  setNoteFilter: (noteFilter: string) => set(() => ({ noteFilter })),
  setIsUnreadReview: (isUnreadReview?: boolean) => set(() => ({ isUnreadReview })),
  setIsWaitApproval: (isWaitApproval?: boolean) => set(() => ({ isWaitApproval })),
  keyword: '',
  setKeyword: (keyword: string) => set(() => ({ keyword })),

  isUserSelectorOpen: false,
  setIsUserSelectorOpen: (isUserSelectorOpen: boolean) => set(() => ({ isUserSelectorOpen })),

  selectedUserList: [],
  setSelectedUserList: (selectedUserList: UserId[]) => set(() => ({ selectedUserList })),
  mentionList: [],
  setMentionList: (mentionList: number[]) => set(() => ({ mentionList })),

  clickedReviewer: '',
  setClickedReviewer: (clickedReviewer: string) => set(() => ({ clickedReviewer })),

  cardState: {},
  setCardState: (cardId: number, cardState: CardState) =>
    set((state) => ({
      cardState: {
        ...state.cardState,
        [cardId]: {
          ...state.cardState[cardId],
          ...cardState,
        },
      },
    })),
  resetCardState: () => set(() => ({ cardState: {} })),
  noteState: {
    note: '',
    noteUpdate: '',
    search: '',
    comment: '',
  },
  setNoteState: (noteState: Partial<NoteState>) =>
    set((state) => ({ noteState: { ...state.noteState, ...noteState } })),
  resetNoteState: () =>
    set(() => ({ noteState: { note: '', search: '', noteUpdate: '', comment: '' } })),

  setSelectedNoteId: (selectedNoteId?: number) => set(() => ({ selectedNoteId })),

  tempList: [],
  setTempList: (tempList: NoteItemView[]) => set(() => ({ tempList })),
  setSelectedCommentId: (selectedCommentId?: number) => set(() => ({ selectedCommentId })),

  setClickedNoteId: (clickedNoteId?: number) => set(() => ({ clickedNoteId })),
}));

export default useNoteStore;
