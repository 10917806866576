import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Button from '@front/layouts/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedPersonAppBarCompanySelectModalState } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/useState';
import {
  affiliatedCompanySearchOptionList,
  defaultAffiliatedPersonCompanySearchForm,
} from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/utils';

export { Form as AffiliatedPersonAppBarCompanySelectModalForm };

const Form = () => {
  const methods = useForm({
    defaultValues: defaultAffiliatedPersonCompanySearchForm,
  });
  const { setSearchParameter } = useAffiliatedPersonAppBarCompanySelectModalState(
    useShallow((state) => ({
      setSearchParameter: state.setSearchParameter,
    }))
  );
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    setSearchParameter(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: '100%',
        }}
      >
        <TextBox variant="body1">회사명 검색</TextBox>
        <HookFormSelect
          width="100px"
          isDefaultPossible
          defaultLabel="선택"
          name="keywordType"
          optionList={affiliatedCompanySearchOptionList}
        />
        <HookFormInput
          width="300px"
          name="keyword"
          placeholder="검색어 입력"
          onKeyDown={handleKeyDown}
        />
        <Button onClick={onSubmit}>검색</Button>
      </Box>
    </FormProvider>
  );
};
