import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useProjectSalesCreateForm = ({ onCreate }) => {
  const methods = useForm({
    defaultValues: {
      name: '',
    },
  });
  const { handleSubmit } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const onValid = (data) => {
    onCreate({
      ...data,
    });
  };
  const onInvalid = (errors) => {
    if (errors.name) {
      enqueueSnackbar(errors.name.message, { variant: 'error' });
    }
  };
  const onSubmit = handleSubmit(onValid, onInvalid);
  return {
    methods,
    onSubmit,
  };
};

export default useProjectSalesCreateForm;
