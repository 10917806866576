import React from 'react';
import { useIsMutating } from 'react-query';
import ModalLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/ModalLoadingSpinner';

export default function FileAuthSettingsLoadingSpinner() {
  const isLoading = !!useIsMutating({
    mutationKey: ['file-auth', 'update'],
  });
  return <ModalLoadingSpinnerUI open={isLoading} />;
}
