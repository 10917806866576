import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import WorkWorkBasicInfoFeature from '@front/src/features/work/features/work/features/basic-info';
import WorkWorkerFeature from '@front/src/features/work/features/work/features/worker';
import WorkWorkReviewerFeature from '@front/src/features/work/features/work/features/reviewer';
import WorkIndicatorFeature from '@front/src/features/work/features/work/features/creator/features/indicator';
import WorkWorkCategoryFeature from '@front/src/features/work/features/work/features/category';
import WorkManagerFeature from '@front/src/features/work/features/work/features/creator/features/manager';
import WorkWorkScheduleFeature from '@front/src/features/work/features/work/features/schedule';
import WorkNameFeature from '@front/src/features/work/features/work/features/name';
import WorkWorkDetailFeature from '@front/src/features/work/features/work/features/detail';
import WorkReferenceFeature from '@front/src/features/work/features/work/features/reference';
import WorkOutputBundleFeature from '@front/src/features/work/features/work/features/output-bundle';
import WorkApprovalFeature from '@front/src/features/work/features/work/features/approval';
import WorkCarouselBundleFeature from '@front/src/features/work/features/work/features/carousel-bundle';

const workWorkSectionComponent: SectionComponent = {
  1: ({ ...rest }) => <WorkWorkBasicInfoFeature {...rest} />,
  2: ({ ...rest }) => <WorkWorkCategoryFeature {...rest} />,
  3: ({ ...rest }) => <WorkWorkScheduleFeature {...rest} />,
  4: ({ ...rest }) => <WorkIndicatorFeature {...rest} />,
  5: ({ ...rest }) => <WorkWorkerFeature {...rest} />,
  7: ({ ...rest }) => <WorkWorkReviewerFeature {...rest} />,
  8: ({ ...rest }) => <WorkWorkDetailFeature {...rest} />,
  9: ({ ...rest }) => <WorkReferenceFeature {...rest} />,
  10: ({ ...rest }) => <WorkOutputBundleFeature {...rest} />,
  92: ({ ...rest }) => <WorkCarouselBundleFeature {...rest} />,
  93: ({ ...rest }) => <WorkNameFeature {...rest} />,
  94: ({ ...rest }) => <WorkManagerFeature {...rest} />,
  95: ({ ...rest }) => <WorkApprovalFeature {...rest} />,
};

export default workWorkSectionComponent;
