import { createReducer } from 'typesafe-actions';

import { OstRewardManagementAction } from '@front/ost_reward/action';
import type { OstId } from '@front/ost/domain';
import type {
  CampaignRewardsView,
  OstRewardManagementDetailMonthDetailView,
  OstRewardManagementDetailView,
  OstRewardManagementMemoView,
  OstRewardManagementTotalStatusView,
  OstShortView,
} from '@front/ost_reward/domian';
import type { OstRewardManagementSearchQuery } from '@front/ost_reward/query';

export interface OstRewardMangementState {
  isDrawerOpen: boolean;
  isLoading: boolean;
  list: OstShortView[];
  id: OstId;
  totalStatusList?: OstRewardManagementTotalStatusView[];
  detail?: OstRewardManagementDetailView;
  memoList: OstRewardManagementMemoView[];
  memoFilter: OstRewardManagementSearchQuery;
  isMemoLoading: boolean;
  monthDetail?: OstRewardManagementDetailMonthDetailView;
  month?: number;
  campaignRewards?: CampaignRewardsView[];
  formHeight?: number;
}

const initialState: OstRewardMangementState = {
  isDrawerOpen: true,
  isLoading: false,
  list: [],
  id: 0 as OstId,
  memoList: [],
  memoFilter: {
    keyword: '',
  },
  isMemoLoading: false,
};

const initialHandlers = {
  [OstRewardManagementAction.setDrawerIsOpen]: (state, action) => ({
    ...state,
    isDrawerOpen: action.payload,
  }),
  [OstRewardManagementAction.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [OstRewardManagementAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [OstRewardManagementAction.setList]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [OstRewardManagementAction.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  [OstRewardManagementAction.setTotalStatusList]: (state, action) => ({
    ...state,
    totalStatusList: action.payload,
  }),
  [OstRewardManagementAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [OstRewardManagementAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
  [OstRewardManagementAction.setIsMemoLoading]: (state, action) => ({
    ...state,
    isMemoLoading: action.payload,
  }),
  [OstRewardManagementAction.setMonth]: (state, action) => ({
    ...state,
    month: action.payload,
  }),
  [OstRewardManagementAction.setMonthDetail]: (state, action) => ({
    ...state,
    monthDetail: action.payload,
  }),
  [OstRewardManagementAction.setCampaignRewards]: (state, action) => ({
    ...state,
    campaignRewards: action.payload,
  }),
};

export const ostRewardManagementReducer = createReducer(initialState, initialHandlers);
