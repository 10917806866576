import { useCustomDialog } from '@front/src/features/dialog';
import useWorkWorkerAddTop from '@front/src/features/work/features/work/features/worker/repository/useAddTop';
import useWorkWorkerAddBottom from '@front/src/features/work/features/work/features/worker/repository/useAddBottom';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkWorkerTableMutation = ({ sectionId, dataId, menuId }) => {
  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const formContext = useFormContext<UIBuilderTableFieldValues>();

  const { toggleEditOpenId, setRecentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkWorkerAddTop(commonParams);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onAddBottom, setCallback: setAddBottomCallback } =
    useWorkWorkerAddBottom(commonParams);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDeleteAll } = workWorkerMutation.useDeleteAll(commonParams);
  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  return {
    onAddTop,
    onAddBottom,
    onRemoveAll,
  };
};

export default useWorkWorkerTableMutation;
