import { useCustomDialog } from '@front/src/features/dialog';
import { useQuery } from 'react-query';
import { drawingApi } from '@front/src/features/image-draw/api/api';
import { useState } from 'react';

interface Props {
  fileKey: string;
}

const useFileItemDownload = (props: Props) => {
  const { fileKey } = props;
  const [enabled, setEnabled] = useState<boolean>(false);
  const { alert } = useCustomDialog();

  const handleDownload = () => {
    setEnabled(true);
  };

  useQuery({
    queryFn: () => drawingApi.getFileItemDataByFileKey(fileKey),
    queryKey: ['file-item', fileKey],
    useErrorBoundary: false,
    enabled: !!fileKey && enabled,
    onSuccess: ({ id }) => {
      window.location.assign(`/api/file-item/${id}`);
    },
    onError: (error: any) => {
      const { message } = error.response.data;
      alert({
        children: message.map((m) => ({
          value: m,
        })),
      });
    },
    onSettled: () => setEnabled(false),
  });
  return {
    onDownload: handleDownload,
  };
};

export default useFileItemDownload;
