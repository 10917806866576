import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  getDetail: (id?) => `${projectSalesInfoURL}/affiliated-company-person/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getDetail: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
