import { useQuery } from 'react-query';
import { AccountingUploadSalesInfoApi } from '@front/src/features/accounting/features/upload/features/sales-info/query/api';
import type { SalesAccountingAmountInformationGetParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import type { SalesAccountingAmountInformationView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';

export { Query as AccountingUploadSalesInfoQuery };

const Query = {
  useGetList: (params: SalesAccountingAmountInformationGetParameter, menuId) => {
    const { data, remove } = useQuery<SalesAccountingAmountInformationView[]>({
      queryFn: () => AccountingUploadSalesInfoApi.getList(params, menuId),
      queryKey: ['accounting', 'upload', 'sales-info', 'list', params],
      suspense: true,
      enabled: !!params.year,
    });
    return {
      data,
      remove,
    };
  },
};
