import { imageDrawAction } from '@front/src/features/image-draw/reducer/reducer';
import { useDispatch } from 'react-redux';
import { FileItemView } from '@front/file-item';

const useImageDraw = () => {
  const dispatcher = useDispatch();
  const open = (fileItem: FileItemView) => {
    dispatcher(
      imageDrawAction.setImageFileItem({
        fileItem,
        isLoading: false,
      })
    );
  };
  const close = () => {
    dispatcher(imageDrawAction.setImageFileItem(undefined));
  };
  return { open, close };
};

export default useImageDraw;