import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedPersonMemoDeleteParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';

export { Context as AffiliatedPersonMemoDeleteContext };

interface State {
  onDelete: (params: AffiliatedPersonMemoDeleteParameter) => void;
}

const Context = createContext<State>({
  onDelete: noOp,
});
