import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ArrangementAttachedFileParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import type { ArrangementAttachedFileId } from '@front/ost_proposal/domain';
import { ArrangementAttachedFile } from '@front/ost_proposal/view/detail/advisorForm/attached_file/Layout';

interface Props {
  isAdvisor: boolean;
}

const ArrangementAttachedFileService = ({ isAdvisor }: Props) => {
  const dispatch = useDispatch();
  const addArrangementAttachedFile = useCallback(
    (params: ArrangementAttachedFileParameter) =>
      dispatch(proposalAction.addArrangementAttachedFile(params)),
    [dispatch]
  );
  const deleteArrangementAttachedFile = useCallback(
    (id: ArrangementAttachedFileId) => dispatch(proposalAction.deleteArrangementAttachedFile(id)),
    [dispatch]
  );
  return (
    <ArrangementAttachedFile
      addArrangementAttachedFile={addArrangementAttachedFile}
      deleteArrangementAttachedFile={deleteArrangementAttachedFile}
      isAdvisor={isAdvisor}
    />
  );
};

export default ArrangementAttachedFileService;
