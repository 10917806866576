export interface EvaluationQuery {
  keyword: string;
  status: string;
}

export const initialEvaluationQuery: EvaluationQuery = {
  keyword: '',
  status: '',
};

export interface EvaluationMemoQuery {
  keyword: string;
}

export const initialEvaluationMemoQuery: EvaluationMemoQuery = {
  keyword: '',
};
