import React from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import { ColorPalette } from '@front/assets/theme';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import InputWithHookForm from '@front/src/components/hook-form/Input';

export default function ProjectSalesAsideCreateTable() {
  return (
    <TableContainerUI>
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <TableCellUI isHead>
              프로젝트명
              <RedStar />
            </TableCellUI>
            <TableCellUI>
              <InputWithHookForm
                name="name"
                required="프로젝트명을 입력해주세요"
                autoFocus={true}
              />
            </TableCellUI>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}

function RedStar() {
  return (
    <TypographyUI
      variant="caption"
      sx={{
        color: ColorPalette.sub2.sub2_primary,
      }}
    >
      *
    </TypographyUI>
  );
}
