import React from 'react';
import FileAuthSettingsModal from '@front/src/features/file-item/features/auth-settings/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { WorkCarouselFieldValues } from '@front/src/features/work/features/work/features/carousel/components/Form';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

export default function WorkCarouselFileAuthModal(props: WorkCarouselSectionProps) {
  const { menuId, dataId } = props;
  const { watch, setValue, getValues } = useFormContext<WorkCarouselFieldValues>();

  const fileItemId = getValues('fileItemId');
  const open = watch('openFileAuthSettingsModal');

  const onClose = () => {
    setValue('openFileAuthSettingsModal', false);
    setValue('fileItemId', undefined);
  };

  if (!fileItemId || !open) return <></>;

  return (
    <FileAuthSettingsModal
      open={open}
      onClose={onClose}
      fileItemId={fileItemId}
      menuId={menuId}
      dataId={dataId}
    />
  );
}
