import { Box } from '@mui/material';
import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MenuView } from '@front/src/types';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';
import { GlobalNavBarClosedChildrenDepth2Menu } from '@front/src/features/global-nav-bar/components/depth2-menu';

interface Props extends MenuView {}

export { Depth1Menu as GlobalNavBarClosedChildrenDepth1Menu };

const Depth1Menu = ({ path, hasDrawer, children, id, icon, gnbClosed }: Props) => {
  const { hasChild, onClick, clickable, active } = useGnbLogic({
    path,
    hasDrawer,
    children,
    id,
  });
  return (
    <>
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '40px',
          backgroundColor: active ? ColorPalette._4c9eeb : 'transparent',
          cursor: clickable ? 'pointer' : 'default',
          borderTop: '2px solid #4c9eeb'
        }}
      >
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: active ? ColorPalette._0047d3 : ColorPalette._4c9eeb,
              fontSize: '18px',
            }}
          />
        )}
      </Box>
      {hasChild &&
        children?.map((child) => {
          if (child.depth === null) return;
          return (
            <GlobalNavBarClosedChildrenDepth2Menu
              key={child.id}
              gnbClosed={gnbClosed}
              {...child}
            />
          );
        })}
    </>
  );
};
