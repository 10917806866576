import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';
import type { UiMetaView } from '@front/src/features/ui-builder/features/ui-meta/types/view';

const url = {
  getUIMetaList: '/api/ui-meta',
  updateUIMeta: '/api/ui-meta',
};

const uIBuilderApi = {
  getUIMetaList: async <T = UiMetaView[]>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getUIMetaList, {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: {
        sectionId: commonParams.sectionId,
        dataId: commonParams.dataId,
      },
    });
    return data;
  },
  updateUIMeta: async (params, commonParams) =>
    await axios.patch(
      url.updateUIMeta,
      {
        ...params,
        ...commonParams,
      },
      {
        headers: getMenuIdHeaders(commonParams.menuId),
      }
    ),
};

export default uIBuilderApi;
