import React from 'react';
import FileAuthSettingsModal from '@front/src/features/file-item/features/auth-settings/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

interface Props {
  menuId: number | undefined;
  dataId: number | undefined;
}

export default function UIBuilderFileAuthSettingsModal({ dataId, menuId }: Props) {
  const { watch, setValue } = useFormContext<UIBuilderTableFieldValues>();
  const fileItemId = watch('fileItemId');

  const onClose = () => {
    setValue('fileItemId', undefined);
  };

  if (!fileItemId) return <></>;

  return (
    <FileAuthSettingsModal
      fileItemId={fileItemId}
      open={!!fileItemId}
      onClose={onClose}
      menuId={menuId}
      dataId={dataId}
    />
  );
}
