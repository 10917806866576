import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-sales-info/features/subject-review/features/modal/components/layout/section.module.scss';

export { Section as ProjectSalesInfoSubjectReviewModalLayoutSection };

interface Props {
  children: ReactNode;
}

const Section = ({ children }: Props) => <div className={classes.container}>{children}</div>;
