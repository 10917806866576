import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type {
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter,
  ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-person`,
  getPersonList: () => `${projectSalesInfoURL}/affiliated-company-person/search`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi = {
  create: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateParameter,
    menuId
  ) => {
    await axios.post(url.create(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getPersonList: async (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter,
    menuId
  ) => {
    const { data } = await axios.get(url.getPersonList(), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
