import React, { memo, useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoEstimationConfirmedTableRow } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/components/estimation-confirmed-table-row';
import { ProjectSalesInfoEstimationConfirmedContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/widgets/context';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { EstimationConfirmedTable as ProjectSalesInfoEstimationConfirmedTable };

const EstimationConfirmedTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <TableRow>
          <ProjectSalesInfoEstimationConfirmedTableRow />
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoEstimationConfirmedContext);
  return (
    <TableHead>
      <TableRow>
        <Th width="200px">견적상태</Th>
        <Th width="200px">수주가능성</Th>
        <Th width="200px">실험정보</Th>
        <Th width="200px">풍동금액</Th>
        <Th width="200px">구검비</Th>
        {detail?.isCm && <Th width="200px">CM비</Th>}
        {detail?.isCm && <Th width="200px">환산CM비</Th>}
        <Th width="200px">총액</Th>
        <Th width="200px">순동단가</Th>
        <Th width="200px">일정</Th>
      </TableRow>
    </TableHead>
  );
});

TableHeader.displayName = 'ProjectSalesInfoEstimationConfirmedTableHeader';
