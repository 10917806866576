import React from 'react';
import {useOutletContext} from "react-router";
import {ProjectSalesCommonFeatureProps} from "@front/src/features/project-sales";
import useActiveTab from "@front/src/features/project-sales/hooks/useActiveTab";

export {Tab as ActivityTab};

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]영업활동
 * @constructor
 */
function Tab() {
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);
  return (
    <div>
      {`TAB #${activeTab?.id}`}: {activeTab?.title}
    </div>
  );
}