import React from 'react';
import { useDrawerListStyles } from '@front/components/CommonDrawer/style';
import { TableCell, TableRow } from '@mui/material';

export { DrawerNoResult };

interface Props {
  colSpan?: number;
}

const DrawerNoResult = ({ colSpan = 3 }: Props) => {
  const classes = useDrawerListStyles();
  return (
    <TableRow
      component="div"
      className={classes.no_result}
    >
      <TableCell
        component="div"
        colSpan={colSpan}
      >
        결과가 없습니다.
      </TableCell>
    </TableRow>
  );
};
