import React from 'react';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';

interface Props extends BoxProps {
  shape?: 'default' | 'aside';
}

const componentObj = {
  default: ContentsDefaultWrapUI,
  aside: ContentsAsideWrapUI,
};

export default function ContentsWrapUI({ shape = 'default', ...rest }: Readonly<Props>) {
  const Component = componentObj[shape];
  return <Component {...rest} />;
}

function ContentsDefaultWrapUI({ children, sx = {}, ...rest }: Readonly<Props>) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: ColorPalette.background.bg,
        padding: '14px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

function ContentsAsideWrapUI({ children }: Readonly<Props>) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        background: ColorPalette.background.bg03,
        padding: '14px',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
}
