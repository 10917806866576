import React, { Fragment, useContext } from 'react';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { TableRow, Typography } from '@mui/material';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';
import { getAuthColor } from '@front/src/features/admin/features/access-authorization-settings/features/department-authorization/utils';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { authOptionList } from '@front/src/features/admin/features/access-authorization-settings/utils/constants';
import { useFormContext } from 'react-hook-form';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import type { AuthUserMenusView } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/types/view';
import { ColorPalette } from '@front/assets/theme';
import { PersonalSettingsModalAuthSettingsContext } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/provider/context';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { SettingsModalTableRow as PersonalSettingsModalTableRow };

interface Props {
  item: AuthorizationPersonalSettingsUserView;
  rowIdx: number;
}

const SettingsModalTableRow = ({ item, rowIdx }: Props) => {
  const { editOpenList, setColoredId, setUpdateList, updateCreateList, updateList } =
    usePersonalSettingsModalState(
      useShallow((state) => ({
        editOpenList: state.editOpenList,
        setColoredId: state.setColoredId,
        updateCreateList: state.updateCreateList,
        setUpdateList: state.setUpdateList,
        updateList: state.updateList,
        createList: state.createList,
      }))
    );
  const { getValues, watch } = useFormContext();
  const isEditOpen = editOpenList.includes(item.originalId!);
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const onSelectChange = (el: AuthUserMenusView, index: number) => {
    setColoredId(item.originalId!);
    if (item.isNew)
      updateCreateList({
        menuId: menuId ? +menuId : 0,
        userId: item.userId!,
        type: getValues(`users.${rowIdx}.menus.${index}.authorizationUser.type`),
      });
    else
      setUpdateList([
        ...updateList,
        {
          authorizationByUserAndMenuId: el.authorizationUser.id,
          userId: item.originalId!,
          type: getValues(`users.${rowIdx}.menus.${index}.authorizationUser.type`),
        },
      ]);
  };
  const { clientUserList } = useContext(PersonalSettingsModalAuthSettingsContext);
  return (
    <TableRow>
      {item.menus.map((el, index) => (
        <Fragment key={el.id}>
          <WorkTd
            height="53px"
            sx={{
              backgroundColor: getAuthColor(
                getValues(`users.${rowIdx}.menus.${index}.authorizationDepartment.type`)
              ),
            }}
          >
            {item.isNew && !getValues(`users.${rowIdx}.userId`) && <></>}
            {(!item.isNew || (item.isNew && getValues(`users.${rowIdx}.userId`))) && (
              <Typography id="access-auth-dep-text">
                {getValues(`users.${rowIdx}.menus.${index}.authorizationDepartment.type`)}
              </Typography>
            )}
          </WorkTd>
          <WorkTd
            height="53px"
            sx={{
              backgroundColor:
                updateList
                  .map((u) => u.authorizationByUserAndMenuId)
                  .includes(watch(`users.${rowIdx}.menus.${index}.authorizationUser.id`)) ||
                (!clientUserList?.map((user) => user.id).includes(watch(`users.${rowIdx}.id`)) &&
                  isEditOpen)
                  ? ColorPalette._eb4c4c
                  : getAuthColor(watch(`users.${rowIdx}.menus.${index}.authorizationUser.type`)),
            }}
          >
            {item.isNew && !getValues(`users.${rowIdx}.userId`) && <></>}
            {(!item.isNew || (item.isNew && getValues(`users.${rowIdx}.userId`))) &&
              (!isEditOpen ? (
                <Typography id="access-auth-dep-text">
                  {getValues(`users.${rowIdx}.menus.${index}.authorizationUser.type`)}
                </Typography>
              ) : (
                <HookFormSelect
                  name={`users.${rowIdx}.menus.${index}.authorizationUser.type`}
                  optionList={authOptionList}
                  onChange={() => {
                    onSelectChange(el, index);
                  }}
                />
              ))}
          </WorkTd>
        </Fragment>
      ))}
    </TableRow>
  );
};
