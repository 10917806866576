// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ozIrsZK36B6_vsyeLLC3{height:100%;overflow:auto}.ozIrsZK36B6_vsyeLLC3::-webkit-scrollbar{display:none}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/components/layouts/body.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,aAAA,CAEA,yCACE,YAAA","sourcesContent":[".container {\r\n  height: 100%;\r\n  overflow: auto;\r\n\r\n  &::-webkit-scrollbar {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ozIrsZK36B6_vsyeLLC3`
};
export default ___CSS_LOADER_EXPORT___;
