import React, { useCallback, useMemo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { getChecked, getLocaleStringIfExist, getValueIfExist } from '@front/src/utils';
import classes from '@front/src/features/project-sales-info/features/contract/features/reivew/components/review-table-row.module.scss';
import { shallow } from 'zustand/shallow';
import type { ProjectContractReviewView } from '@front/src/features/project-sales-info/features/contract/features/reivew/types/view';
import { useProjectSalesInfoContractReviewState } from '@front/src/features/project-sales-info/features/contract/features/reivew/widgets/useState';
import { useProjectSalesInfoContractReviewModalState } from '@front/src/features/project-sales-info/features/contract/features/reivew/features/modal/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import { useShallow } from 'zustand/react/shallow';

export { ReviewTableRow as ProjectSalesInfoContractReviewTableRow };

interface Props {
  item: ProjectContractReviewView;
  index: number;
}

const ReviewTableRow = ({ item, index }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoContractReviewState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoContractReviewModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const checked = useMemo(() => getChecked(item.id, idList), [item.id, idList]);
  const handleCheckboxChange = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, item.id));
  }, [setIdList, idList, item.id]);
  const { readOnly } = useProjectSalesInfoContractReviewState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleIndexClick = useCallback(() => {
    setId(item.id);
    setIsOpen(true);
  }, [setIsOpen, setId, item.id]);
  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>
        <div
          className={classes.index}
          onClick={handleIndexClick}
        >
          {index + 1}
        </div>
      </OldTd>
      <OldTd>{getValueIfExist(item.category)}</OldTd>
      <OldTd>{getValueIfExist(item.structureCompany?.name)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.reviewAmount)}</OldTd>
      <OldTd>{getValueIfExist(item.reviewContractDate)}</OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
