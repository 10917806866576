import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonAppBarPersonListContext } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/provider/context';
import { useAffiliatedPersonAppBarPersonListLogic } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/provider/useLogic';

export { Provider as AffiliatedPersonAppBarPersonListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list } = useAffiliatedPersonAppBarPersonListLogic(menuId);
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AffiliatedPersonAppBarPersonListContext.Provider value={value}>
      {children}
    </AffiliatedPersonAppBarPersonListContext.Provider>
  );
};
