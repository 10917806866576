import { AccountingSettingsDailyReportStatusRepository } from '@front/src/features/accounting/features/settings/features/daily-report-status/repository/repository';
import { useMemo } from 'react';

export { useLogic as useAccountingSettingDailyReportStatusListLogic };

const useLogic = (menuId) => {
  const { data: status } = AccountingSettingsDailyReportStatusRepository.useGetList(menuId);
  const sumDailyReportStatus = (statusList) =>
    statusList.reduce(
      (sum, v) => {
        sum.balanceCarriedForward += v.balanceCarriedForward;
        sum.balanceIncrement += v.balanceIncrement;
        sum.balanceDecrement += v.balanceDecrement;
        sum.balance += v.balance;
        sum.balanceAtAccount += v.balanceAtAccount;
        sum.difference += v.difference;
        return sum;
      },
      {
        id: 0,
        startDate: '',
        endDate: '',
        category: '',
        name: '',
        accountCode: '',
        balanceCarriedForward: 0,
        balanceIncrement: 0,
        balanceDecrement: 0,
        balance: 0,
        balanceAtAccount: 0,
        difference: 0,
        createdAt: '',
      }
    );

  const list = useMemo(() => {
    if (!status) return [];
    return Object.values(status).flat();
  }, [status]);

  const sumList = useMemo(() => {
    if (!status) return [];
    const tempList = Object.entries(status).map(([category, statusList]) => ({
      ...sumDailyReportStatus(statusList),
      category,
    }));

    const totalSum = sumDailyReportStatus(tempList);
    tempList.push({
      ...totalSum,
      category: '합계',
    });

    return tempList;
  }, [status]);

  return {
    list,
    sumList,
  };
};
