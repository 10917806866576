import React, { useContext } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { AccountingSettingsDailyReportStatusUploadWidget } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/upload/widget';

import { AccountingSettingsDailyReportStatusListContext } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/context';
import { convertDateToStringFormat, DATE_TIME_FORMAT } from '@front/src/utils';

export { Header as AccountingSettingsDailyReportStatusListHeader };

const Header = () => {
  const { list } = useContext(AccountingSettingsDailyReportStatusListContext);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
      }}
    >
      <TextBox variant="body1">E계좌 데이터 업데이트 일시:</TextBox>
      <TextBox variant="body1">
        {convertDateToStringFormat(list?.[0]?.createdAt, DATE_TIME_FORMAT)}
      </TextBox>
      <AccountingSettingsDailyReportStatusUploadWidget />
    </Box>
  );
};
