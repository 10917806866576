import { createContext } from 'react';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';
import { noOp } from '@front/src/utils';

export { Context as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext };

interface State {
  d: {
    list?: PersonModalShortView[];
  };
  h: { onCreate: (params) => void };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {
    list: [],
  },
  h: { onCreate: noOp },
  isLoading: false,
});
