import React from 'react';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useFormContext } from 'react-hook-form';
import InputWithHookForm from '@front/src/components/hook-form/Input';

interface Props {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export default function ProjectSalesAsideSearchForm({ onSubmit }: Readonly<Props>) {
  const { reset } = useFormContext();
  const handleKeyDown = async (e) => {
    if (e.isComposing || e.keyCode === 229) return;
    if (e.key !== 'Enter') return;
    await onSubmit();
  };
  const handleRest = () => {
    reset();
    onSubmit();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <InputWithHookForm
        name="codeOrName"
        placeholder="코드번호 또는 프로젝트명 검색"
        onKeyDown={handleKeyDown}
      />
      <InputWithHookForm
        name="searchWordOrPurpose"
        placeholder="프로젝트 주요 속성 검색"
        onKeyDown={handleKeyDown}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '10px',
        }}
      >
        <ButtonBasicUI
          shape="primary"
          size="medium"
          onClick={onSubmit}
        >
          검색
        </ButtonBasicUI>
        <ButtonBasicUI
          shape="secondary"
          size="medium"
          onClick={handleRest}
        >
          초기화
        </ButtonBasicUI>
      </Box>
    </Box>
  );
}
