import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';
import { ContractHistoryTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/history/components/table-body';
import { ContractHistoryTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/history/components/table-head';

export { Table as ContractHistoryTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const { data: list } = contractHistoryQuery.useList({
    dataId: sectionProps.sectionId,
    menuId: sectionProps.sectionId,
    sectionId: sectionProps.sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={ContractHistoryTableHead}
      BodyComponent={ContractHistoryTableBody}
      readOnly={false}
      list={list}
    />
  );
}
