import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/query/api';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyQuery = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[]>({
      queryFn: () => projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi.getList(id, menuId),
      queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company', 'list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
