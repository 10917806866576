import { create } from 'zustand';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';
import { defaultSearchParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils/constant';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  parameter: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter;
  setParameter: (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter
  ) => void;
  addList: PersonModalShortView[];
  setAddList: (addList: PersonModalShortView[]) => void;
  selectedList: PersonModalShortView[];
  setSelectedList: (addList: PersonModalShortView[]) => void;
  deleteIdList: string[];
  setDeleteIdList: (id: string[]) => void;
}

export { useState as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState };

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
  parameter: defaultSearchParameter,
  setParameter: (parameter) => set(() => ({ parameter })),
  addList: [],
  setAddList: (addList) => set(() => ({ addList })),
  selectedList: [],
  setSelectedList: (selectedList) => set(() => ({ selectedList })),
  deleteIdList: [],
  setDeleteIdList: (deleteIdList) => set(() => ({ deleteIdList })),
}));
