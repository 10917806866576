import type { PersonnelQuery } from 'personnel/query';
import type Page from 'type/Page';
import type { PersonnelId, PersonnelShortVO, PersonnelVO } from 'personnel/domain';
import { createReducer } from 'typesafe-actions';
import { PersonnelActionType } from 'personnel/action';
import type { ApiStatus } from 'components/DataFieldProps';
import type { ProposalVO } from '@front/ost_proposal/domain';
import type { VoteVO } from '@front/ost_vote/domain';

export interface PersonnelState {
  idParams?: {
    id: PersonnelId;
    menuId: number;
  };
  detail?: PersonnelVO;
  filter?: PersonnelQuery;
  page?: Page<PersonnelShortVO>;
  requestUpdate: ApiStatus;
  proposalLogList: ProposalVO[];
  voteLogList: VoteVO[];
}

const initialState: PersonnelState = {
  requestUpdate: 'idle',
  proposalLogList: [],
  voteLogList: [],
};

export const personnelReducer = createReducer(initialState, {
  [PersonnelActionType.setId]: (state, action) => ({
    ...state,
    idParams: action.payload,
  }),
  [PersonnelActionType.setOne]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  [PersonnelActionType.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload.values,
  }),
  [PersonnelActionType.setPage]: (state, action) => ({
    ...state,
    page: action.payload,
  }),
  [PersonnelActionType.requestUpdate]: (state, action) => ({
    ...state,
    requestUpdate: action.payload,
  }),
  [PersonnelActionType.setProposalLog]: (state, action) => ({
    ...state,
    proposalLogList: action.payload,
  }),
  [PersonnelActionType.setVoteLog]: (state, action) => ({
    ...state,
    voteLogList: action.payload,
  }),
});
