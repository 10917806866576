import React, { useMemo } from 'react';
import type { AuthorizationType, UserVO } from '@front/user/domain';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import UserSelectorInputAndModal from '@front/src/features/user-selector/features/input/components/InputAndModal';
import useModalOpen from '@front/src/hooks/useModalOpen';
import { LogicalOperator } from '@front/user/query';

export interface UserSelectorInputProps {
  value: number | number[] | undefined;
  onChange: (value: UserVO[] | undefined) => void;
  multi?: boolean;
  readOnly?: boolean;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
  onClickForRemove?: () => void;
  predefinedIdList?: number[];
  menuIdListType?: LogicalOperator;
}

export default function UserSelectorInputFeature(props: Readonly<UserSelectorInputProps>) {
  const {
    value,
    multi = false,
    readOnly = false,
    menuIdList = [],
    typeList = [],
    menuIdListType = LogicalOperator.OR,
  } = props;

  const { onOpen, open, onClose } = useModalOpen();
  const isMulti = useMemo(
    () => multi || (Array.isArray(value) && value.length > 1),
    [multi, value]
  );
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  if (!allUser) {
    return <></>;
  }
  return (
    <UserSelectorInputAndModal
      {...props}
      readOnly={readOnly}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      multi={isMulti}
      menuIdList={menuIdList}
      typeList={typeList}
      menuIdListType={menuIdListType}
    />
  );
}
