import React from 'react';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export default function PageLayoutWithMemo({ children }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100dvh',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
}
