import { createContext } from 'react';

import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonCharacterBasicView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/types/view';
import type { AffiliatedCompanyPersonCharacterBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalCharacterBasicContext };

interface State {
  d: { personCharacterBasic?: AffiliatedCompanyPersonCharacterBasicView };
  h: {
    onPersonCharacterBasicUpdate: (
      params: AffiliatedCompanyPersonCharacterBasicUpdateParameter
    ) => void;
  };
  isLoading: boolean;
  readOnly?: boolean;
}

const Context = createContext<State>({
  d: {},
  h: {
    onPersonCharacterBasicUpdate: noOp,
  },
  isLoading: false,
  readOnly: false,
});
