import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getReadOnlyFromChildren, getReadonlyFromMenu } from '@front/src/utils';

const useGetReadOnlyFromMenuId = (menuId) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const readOnly =
    getReadonlyFromMenu(loginUser?.menus, menuId) &&
    getReadOnlyFromChildren(loginUser?.menus, menuId);
  return { readOnly };
};

export default useGetReadOnlyFromMenuId;
