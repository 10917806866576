import React, { Suspense } from 'react';
import { AccountingSettingsTexAccountingAccountGetCountProvider } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/provider/provider';
import { AccountingSettingsTexAccountingAccountTableHeader } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/components/table-header';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingSettingsTexAccountingAccountGetCountWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<LoadingSpinner height="20px" />}>
    <AccountingSettingsTexAccountingAccountGetCountProvider menuId={menuId}>
      <AccountingSettingsTexAccountingAccountTableHeader />
    </AccountingSettingsTexAccountingAccountGetCountProvider>
  </Suspense>
);
