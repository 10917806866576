import React, { type ReactNode } from 'react';

import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';

import workCarouselQuery from '@front/src/features/work/features/work/features/carousel/query/query';
import WorkCarouselNameUpdateButton from '@front/src/features/work/features/work/features/carousel/components/NameUpdateButton';
import WorkCarouselContextMenu from '@front/src/features/work/features/work/features/carousel/components/ContextMenu';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';
import '@front/src/components/components-with-design/common-styles.css';

export default function WorkCarouselHeader(props: Readonly<WorkCarouselSectionProps>) {
  const { readOnly, dataId, menuId, sectionId, item } = props;
  const { data: list } = workCarouselQuery.useListGet(item.id, {
    dataId,
    menuId,
    sectionId,
  });

  const icon = readOnly ? <></> : <WorkCarouselNameUpdateButton {...props} />;

  const title = (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div
        className="cs-action-button"
        style={{ marginRight: '1rem' }}
      >
        <WorkCarouselContextMenu {...props} />
      </div>
      {item.name ?? '이미지 슬라이드'}
    </div>
  );

  return (
    <Title
      title={title}
      length={list?.length ?? 0}
    >
      {icon}
    </Title>
  );
}

interface TitleProps {
  title: ReactNode;
  length: number;
  children: ReactNode;
  right?: ReactNode;
}

function Title({ children, right, length, title }: TitleProps) {
  const { TitleWithSubTitleWrap } = SectionWrapperUI;
  const subTitle = `총 ${length}개`;
  return (
    <TitleWithSubTitleWrap
      title={title}
      subTitle={subTitle}
      right={right}
    >
      {children}
    </TitleWithSubTitleWrap>
  );
}
