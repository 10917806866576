import React from 'react';
import type { Control } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import UserSelectorCommonInput from '@front/src/features/user-selector/features/input/components/CommonInput';

interface Props {
  control: Control<{ inputList: (UserVO | undefined)[] }, any>;
}

export default function UserSelectorReadOnlyInput({ control }: Readonly<Props>) {
  const inputList = useWatch({ control, name: 'inputList' });
  return (
    <UserSelectorCommonInput
      value={inputList?.[0]?.id}
      sx={{
        cursor: 'default',
      }}
      inputProps={{
        sx: {
          cursor: 'default',
        },
      }}
    />
  );
}
