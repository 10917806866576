import { useMutation, useQueryClient } from 'react-query';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyMeetingHistoryApi } from '@front/src/features/affiliated-company/features/meeting-history/query/api';

export const affiliatedCompanyMeetingHistoryMutation = {
  useMeetingHistoryDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyMeetingHistoryApi.deleteMeetingHistory(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'meeting-history', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
