import React from 'react';
import ModalUI, { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';
import WorkCarouselMoveSlideFeature from '@front/src/features/work/features/work/features/carousel/features/move-slide';
import { useFormContext } from 'react-hook-form';
import type { WorkCarouselFieldValues } from '@front/src/features/work/features/work/features/carousel/components/Form';
import type { UIBuilderParameter } from '@front/src/types/parameter';

export default function WorkCarouselMoveSlideModal(props: UIBuilderParameter) {
  const { setValue, watch } = useFormContext<WorkCarouselFieldValues>();
  const moveSlideId = watch('moveSlideId');

  const onClose = () => setValue('moveSlideId', undefined);
  if (!moveSlideId) return <></>;

  return (
    <ModalUI
      title="이미지 슬라이드 이동"
      open={!!moveSlideId}
      onClose={onClose}
    >
      <ModalBodyUI>
        <WorkCarouselMoveSlideFeature
          {...props}
          id={moveSlideId}
          onClose={onClose}
        />
      </ModalBodyUI>
    </ModalUI>
  );
}
