import React, { useCallback, useContext, useMemo } from 'react';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import Button from '@front/layouts/Button';
import { AffiliatedPersonDetailDeleteContext } from '@front/src/features/affiliated-person/features/detail/widgets/delete/provider/context';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getIsShowForAuthorizationFromTab } from '@front/src/utils';

export { DeleteButton as AffiliatedPersonDetailDeleteButton };

const DeleteButton = () => {
  const { onDelete } = useContext(AffiliatedPersonDetailDeleteContext);
  const { id: personId } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { confirm } = useCustomDialog();
  const onButtonClick = useCallback(() => {
    if (!personId) return;
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        if (!personId) return;
        onDelete(personId);
      },
    });
  }, [onDelete, confirm, personId]);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromTab(loginUser.menus);
  }, [loginUser]);
  if (!isShow) {
    return <></>;
  }
  return (
    <Button
      shape="basic3"
      onClick={onButtonClick}
    >
      삭제
    </Button>
  );
};
