import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workApprovalQuery = {
  useGetList: (params: UIBuilderParameter) => {
    const { data } = useQuery({
      queryKey: ['work', 'approval', params.dataId],
      queryFn: () => workWorkApi.getWorkApproval(params),
      suspense: true,
      enabled: !!params.dataId && !!params.menuId,
    });
    return {
      data,
    };
  },
};
export default workApprovalQuery;
