import type { DefaultFunction } from 'type/Function';
import type { ReactNode } from 'react';
import type { SxProps } from '@mui/system';

export enum DialogStatus {
  OK = 'OK',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

interface CommonView {
  title?: string;
  status?: DialogStatus;
  closeText?: string;
  afterClose?: DefaultFunction;
}

export interface IAlertChildren {
  value: string;
  sx?: SxProps;
}

export interface AlertView extends CommonView {
  children?: IAlertChildren[];
}

export interface ConfirmView extends CommonView {
  afterConfirm?: DefaultFunction;
  confirmText?: string;
  children?: ReactNode;
  lineBreakChildren?: IAlertChildren[];
}

export interface PromptView extends CommonView {
  afterPrompt?: DefaultFunction<string>;
  promptText?: string;
  defaultValue?: string;
  children?: ReactNode;
  lineBreakChildren?: IAlertChildren[];
  multiline?: boolean;
  maxLength?: number;
  rows?: number;
}
