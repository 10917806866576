import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useMobileNotificationListLogic } from '@front/src/features/mobile-notification/widgets/list/provider/useLogic';
import { MobileNotificationListContext } from '@front/src/features/mobile-notification/widgets/list/provider/context';

export { Provider as MobileNotificationListProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { mobileNotificationList, onToggle, onDelete, onPinUpdate } =
    useMobileNotificationListLogic();
  const value = useMemo(
    () => ({
      mobileNotificationList,
      onToggle,
      onDelete,
      onPinUpdate,
    }),
    [mobileNotificationList, onToggle, onDelete, onPinUpdate]
  );
  return (
    <MobileNotificationListContext.Provider value={value}>
      {children}
    </MobileNotificationListContext.Provider>
  );
};
