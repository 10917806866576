import React from 'react';

import { FormProvider } from 'react-hook-form';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import { WorkCarouselPosition } from '@front/src/features/work/features/work/features/carousel/utils/constant';
import WorkCarouselCreateLoadingSpinner from '@front/src/features/work/features/work/features/carousel/features/create/components/LoadingSpinner';
import useWorkCarouselCreateForm from '@front/src/features/work/features/work/features/carousel/features/create/hooks/useForm';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import RadioWithHookForm from '@front/src/components/hook-form/Radio';
import useWorkCarouselCreate from '@front/src/features/work/features/work/features/carousel/features/create/repository/useCreate';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

interface Props extends WorkCarouselSectionProps {
  onClose: () => void;
}

export default function WorkCarouselCreateFeature(props: Readonly<Props>) {
  const { sectionId, dataId, menuId, onClose, item } = props;
  const commonParams = { menuId, dataId, sectionId };
  const { run: onCreate, setCallback } = useWorkCarouselCreate(commonParams);
  setCallback({
    onSuccess: () => {
      onClose();
    },
  });
  const { methods, onSubmit } = useWorkCarouselCreateForm({
    bundleId: item.id,
    onCreate,
    ...commonParams,
  });
  const { register } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <WorkCarouselCreateLoadingSpinner />
        <ModalBodyUI>
          <ModalHeaderUI>
            <ButtonBasicUI
              shape="tertiary"
              size="medium"
              onClick={onClose}
              sx={{
                width: '64px',
              }}
            >
              취소
            </ButtonBasicUI>
            <ButtonBasicUI
              type="submit"
              shape="primary2"
              size="medium"
              sx={{
                width: '64px',
              }}
            >
              등록
            </ButtonBasicUI>
          </ModalHeaderUI>
          <ModalContentUI>
            <TableContainerUI>
              <TableTableUI>
                <TableBodyUI>
                  <TableRowUI>
                    <TableCellUI
                      isHead
                      width="120px"
                    >
                      이름
                    </TableCellUI>
                    <TableCellUI align="left">
                      <UncontrolledInput
                        required="이름을 입력해주세요."
                        name="description"
                      />
                    </TableCellUI>
                  </TableRowUI>
                  <TableRowUI>
                    <TableCellUI isHead>추가 위치</TableCellUI>
                    <TableCellUI align="left">
                      <RadioWithHookForm
                        required="추가 위치를 선택해주세요."
                        name="position"
                        optionList={optionList}
                      />
                    </TableCellUI>
                  </TableRowUI>
                  <TableRowUI>
                    <TableCellUI isHead>이미지</TableCellUI>
                    <TableCellUI>
                      <input
                        {...register('images', {
                          required: '이미지를 추가해주세요',
                        })}
                        type="file"
                        multiple
                      />
                    </TableCellUI>
                  </TableRowUI>
                </TableBodyUI>
              </TableTableUI>
            </TableContainerUI>
          </ModalContentUI>
        </ModalBodyUI>
      </form>
    </FormProvider>
  );
}

const optionList = [
  {
    label: '맨 앞',
    value: WorkCarouselPosition.FRONT,
  },
  {
    label: '맨 뒤',
    value: WorkCarouselPosition.BACK,
  },
];
