import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import DatePickerUI from '@front/src/components/components-with-design/component/DatePickerUI';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';

interface Props extends DatePickerProps<any, any> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  required?: string;
}

export default function DatePickerWithHookForm(props: Readonly<Props>) {
  const { name, onSubmit, readOnly, required } = props;
  const { control, getValues } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required } });

  const handleChange = (value: string | Dayjs) => {
    onHookFormChange(value ? dayjs(value) : null);
    onSubmit?.();
  };

  if (readOnly && (getValues(name) === '' || getValues(name) === null)) {
    return <>-</>;
  }

  if (readOnly && getValues(name) !== null) {
    return <>{dayjs(getValues(name)).format(YY_MM_DD)}</>;
  }

  return (
    <DatePickerUI
      {...props}
      value={value ? dayjs(value) : null}
      onChange={handleChange}
    />
  );
}
