import { useState } from 'react';
import { affiliatedCompanySelectorRepository } from '@front/src/features/affiliated-company-selector/repository/repository';
import type { AffiliatedCompanySelectWidgetValueProp } from '@front/src/features/affiliated-company-selector/types/affiliated-company-selector-types';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import { AffiliatedCompanyCategory } from '@front/src/features/affiliated-company/types/domain';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';

export { useLogic as useAffiliatedCompanySelectorLogic };

type PersonSearchResult = { isLoading: boolean; data: AffiliatedCompanyPersonShortView[] };

export type CompanySearchHelper = {
  category: AffiliatedCompanyCategory;
  setCategory: (category: AffiliatedCompanyCategory) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  result: { isLoading: boolean; data: AffiliatedCompanyShortView[] };
  targetCompany: AffiliatedCompanyShortView;
  setTargetCompany: (company: AffiliatedCompanyShortView | undefined) => void;
};

export type PersonSearchHelper = {
  category: string;
  setCategory: (category: string) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  result: PersonSearchResult;
  targetPersonId: number | undefined;
  setTargetPersonId: (personId: number | undefined) => void;
};

interface Props {
  defaultCompanyCategory?: AffiliatedCompanyCategory;
  isCompany: boolean;
  usePersonSelector: boolean;
}

const useLogic = ({
  defaultCompanyCategory = AffiliatedCompanyCategory.DEFAULT,
  isCompany,
  usePersonSelector,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<AffiliatedCompanySelectWidgetValueProp>({
    textValue: '',
    companyId: undefined,
    personId: undefined,
  });

  const [targetCompany, setTargetCompany] = useState<AffiliatedCompanyShortView | undefined>();
  const [companySearchKeyword, setCompanySearchKeyword] = useState<string>('');
  const [companySearchCategory, setCompanySearchCategory] =
    useState<AffiliatedCompanyCategory>(defaultCompanyCategory);

  const companySearchResult = affiliatedCompanySelectorRepository.useListGet(
    {
      keyword: companySearchKeyword,
    },
    modalOpen,
    isCompany
  );

  const [targetPersonId, setTargetPersonId] = useState<number | undefined>();
  const [personSearchKeyword, setPersonSearchKeyword] = useState<string>('');
  const [personSearchCategory, setPersonSearchCategory] = useState<string>('');
  const personSearchResult = affiliatedCompanySelectorRepository.usePersonGet(
    targetCompany?.id,
    usePersonSelector
  );

  return {
    modalOpen,
    setModalOpen,
    inputValue,
    setInputValue,
    company: {
      setCategory: setCompanySearchCategory,
      setKeyword: setCompanySearchKeyword,
      setTargetCompany: setTargetCompany,
      targetCompany: targetCompany,
      category: companySearchCategory,
      keyword: companySearchKeyword,
      result: companySearchResult,
    } as CompanySearchHelper,
    person: {
      setCategory: setPersonSearchCategory,
      setKeyword: setPersonSearchKeyword,
      setTargetPersonId: setTargetPersonId,
      targetPersonId: targetPersonId,
      category: personSearchCategory,
      keyword: personSearchKeyword,
      result: personSearchResult,
    } as PersonSearchHelper,
  };
};
