import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ColorPalette } from '@front/assets/theme';
import Button from '@front/layouts/Button';
import type { UseFieldArraySwap } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import type { ButtonProps } from '@mui/material';
import { Box } from '@mui/material';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { ChildrenTableRow as PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow };

interface Props {
  index: number;
  swap: UseFieldArraySwap;
  length: number;
  authMenuId: number | undefined;
}

const ChildrenTableRow = ({ index, swap, length, authMenuId }: Readonly<Props>) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { control } = useFormContext();
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );

  const child = useWatch({ control, name: `childList.${index}` });

  const { name, headerSettingId } = child ?? {};

  const handleUp = () => {
    swap(index, index - 1);
  };

  const handleDown = () => {
    swap(index, index + 1);
  };

  const original = list
    ?.find((l) => l.id === parentId)
    ?.modalHeaderList.find((m) => m.headerSettingId === headerSettingId);

  const isChanged = original === undefined;
  const sx = {
    backgroundColor: isChanged ? ColorPalette._fff2cc : ColorPalette._d7e2f7,
  };

  const upButtonDisabled = index - 1 < 0;

  const downButtonDisabled = index >= length - 1;

  return (
    <TableRowBox>
      <TbcBox
        sx={{
          flex: 2,
          ...sx,
        }}
      >
        {name}
      </TbcBox>
      <TbcBox
        sx={{
          flex: 1,
          justifyContent: 'center',
          gap: '4px',
        }}
      >
        <Box>
          <SeqButton
            disabled={upButtonDisabled}
            onClick={handleUp}
          >
            <ArrowDropUpIcon />
          </SeqButton>
        </Box>
        <Box>
          <SeqButton
            disabled={downButtonDisabled}
            onClick={handleDown}
          >
            <ArrowDropDownIcon />
          </SeqButton>
        </Box>
      </TbcBox>
    </TableRowBox>
  );
};

const SeqButton = ({ children, disabled, onClick }: ButtonProps) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    sx={{ height: '25px', minWidth: '25px', padding: 0 }}
  >
    {children}
  </Button>
);
