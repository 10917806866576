import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import fileAuthSettingsMutation from '@front/src/features/file-item/features/auth-settings/query/mutation';

const useFileAuthSettingsUpdate = () => {
  const callback = getNoOpCallback();
  const mutate = fileAuthSettingsMutation.useUpdate();
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useFileAuthSettingsUpdate;
