import { makeStyles } from '@mui/styles';
import { ColorPalette } from '../../assets/theme';

export const useDrawerListStyles = makeStyles(() => ({
  root: {
    display: 'flex !important',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: '0 10px',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
      backgroundColor: ColorPalette._e4e9f2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: ColorPalette._697183,
    },
  },
  thead: {
    display: 'flex !important',
    flexDirection: 'row',
    width: '100%',
    '& div': {
      justifyContent: 'center',
      cursor: 'auto',
      '&:nth-child(2)': {
        width: '120px',
      },
    },
  },
  tbody: {
    flex: 1,
    display: 'flex !important',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    '& > div > div > div > div > div': {
      '&:nth-child(1)': {
        flexGrow: 0,
        minWidth: '62px',
        color: ColorPalette._1c6ebb,
        letterSpacing: '-1px',
        fontWeight: '500',
      },
      '&:nth-child(2)': {
        flexGrow: 1,
        width: '120px',
        justifyContent: 'left',
      },
    },
  },
  row: {
    display: 'flex !important',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  cell: {
    display: 'flex !important',
    flexShrink: 0,
    padding: '5px !important',
    justifyContent: 'start',
    '& > span': {
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&:nth-child(1)': {
      flexGrow: 0,
      minWidth: '62px',
    },
    '&:nth-child(2)': {
      flexGrow: 1,
      width: '120px',
    },
    '&:nth-child(3)': {
      flexGrow: 0,
      minWidth: '62px',
    },
    '&:nth-child(4)': {
      flexGrow: 0,
      minWidth: '62px',
    },
    '&:nth-child(5)': {
      flexGrow: 0,
      minWidth: '62px',
    },
  },
  no_result: {
    display: 'flex !important',
    width: '100%',
    justifyContent: 'center',
  },
}));
