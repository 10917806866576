import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyCharacterPartnerCompanyView } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/view';
import type { AffiliatedCompanyCharacterPartnerCompanyUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/parameter';

export { Context as AffiliatedCompanyCharacterPartnerCompanyContext };

interface State {
  d: {
    partnerCompanyList?: AffiliatedCompanyCharacterPartnerCompanyView[];
  };
  h: {
    onPartnerCompanyCreate: DefaultFunction;
    onPartnerCompanyUpdate: (
      params: AffiliatedCompanyCharacterPartnerCompanyUpdateParameter
    ) => void;
    onPartnerCompanyDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
  id?: number;
}

const Context = createContext<State>({
  d: {
    partnerCompanyList: [],
  },
  h: {
    onPartnerCompanyCreate: noOp,
    onPartnerCompanyUpdate: noOp,
    onPartnerCompanyDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
