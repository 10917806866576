import { useListDrawerState } from '@front/src/components/list-drawer/useState';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useMemoDrawerState } from '@front/src/components/memo-drawer/useState';
import { useMemo } from 'react';

const widthOpenGlobalNavBar = 230;
const widthCloseGlobalNavBar = 52;

const widthOpenListDrawer = 330;
const widthCloseListDrawer = 40;
const widthOpenMemo = 300;
const widthCloseMemo = 40;

interface Props {
  value?: number;
  hasMemo?: boolean;
}

export const useGetMaxWidthSx = ({ value = 100, hasMemo = false }: Props) => {
  const { open: openGlobalNavBar } = useGlobalNavBarState(
    useShallow((state) => ({
      open: state.open,
    }))
  );
  const { open: openListDrawer } = useListDrawerState(
    useShallow((state) => ({
      open: state.open,
    }))
  );
  const { open: openMemo } = useMemoDrawerState(
    useShallow((state) => ({
      open: state.open,
      setOpen: state.setOpen,
    }))
  );
  const width = useMemo(() => {
    if (!hasMemo) {
      if (openGlobalNavBar && openListDrawer) return widthOpenGlobalNavBar + widthOpenListDrawer;
      else if (!openGlobalNavBar && openListDrawer)
        return widthCloseGlobalNavBar + widthOpenListDrawer;
      else if (openGlobalNavBar && !openListDrawer)
        return widthOpenGlobalNavBar + widthCloseListDrawer;
      return widthCloseGlobalNavBar + widthCloseListDrawer;
    }
    if (openGlobalNavBar && !openListDrawer && openMemo)
      return widthOpenGlobalNavBar + widthCloseListDrawer + widthOpenMemo;
    else if (openGlobalNavBar && openListDrawer && !openMemo)
      return widthOpenGlobalNavBar + widthOpenListDrawer + widthCloseMemo;
    else if (openGlobalNavBar && !openListDrawer && !openMemo)
      return widthOpenGlobalNavBar + widthCloseListDrawer + widthCloseMemo;
    else if (!openGlobalNavBar && openListDrawer && openMemo)
      return widthCloseGlobalNavBar + widthOpenListDrawer + widthOpenMemo;
    else if (!openGlobalNavBar && !openListDrawer && openMemo)
      return widthCloseGlobalNavBar + widthCloseListDrawer + widthOpenMemo;
    else if (!openGlobalNavBar && openListDrawer && !openMemo)
      return widthCloseGlobalNavBar + widthOpenListDrawer + widthCloseMemo;
    else if (!openGlobalNavBar && !openListDrawer && !openMemo)
      return widthCloseGlobalNavBar + widthCloseListDrawer + widthCloseMemo;
    return widthOpenGlobalNavBar + widthOpenListDrawer + widthOpenMemo;
  }, [openGlobalNavBar, openListDrawer, openMemo, hasMemo]);
  return {
    maxWidth: `calc(100dvw - ${width + value}px)`,
    overflowX: 'auto',
  };
};
