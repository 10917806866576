import { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';

export const collectionFinalApi = {
  getList: async <T = CollectionFinalView[]>(commonParams) => {
    return await generateCollectionFinalDummyData(5);
  },
};

const generateCollectionFinalDummyData = async (recordCounts: number) => {
  return new Promise<CollectionFinalView[]>((resolve) => {
    setTimeout(() => {
      const collectionFinals: CollectionFinalView[] = [];

      const generatePaymentStage = (index: number, length: number) => {
        if (index === 1) {
          return '계약금';
        } else if (index === length) {
          return '잔금';
        } else {
          return `중도금${index - 1}`;
        }
      };

      for (let i = 1; i <= recordCounts; i++) {
        const totalContractPrice = Math.round(Math.random() * 100000000);
        const structuralReviewRatio = Math.round(Math.random() * 20);
        const structuralReviewPrice =  Math.round(totalContractPrice * (structuralReviewRatio / 100));
        const cmRatioAgainstContractPrice = Math.round(Math.random() * 20);
        const contractCM = Math.round(totalContractPrice * (cmRatioAgainstContractPrice / 100));

        const collectionItem: CollectionFinalView = {
          id: i,
          paymentStage: generatePaymentStage(i, recordCounts),
          paymentSequence: 1,
          client: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          clientPaymentManager: {
            id: 1,
            name: '홍길동',
            position: '과장',
            department: '구매팀',
            affiliatedCompany: {
              id: 1,
              name: '테스트회사',
              code: 'C11111111',
              status: '',
              alarmCount: 0,
            },
          },
          paymentTiming: '협의',
          expectedPaymentDate: '2024-12-25',
          clientRatio: 100,
          companyStageRatio: 100/recordCounts,
          totalContractPrice: totalContractPrice,
          windTunnelPrice: totalContractPrice - structuralReviewPrice,
          actualWindTunnelPrice: totalContractPrice - structuralReviewPrice - contractCM,
          structuralReviewPrice: structuralReviewPrice,
          structuralReviewRatio: structuralReviewRatio,
          structuralReviewPaymentTarget: {
            id: 1,
            name: '테스트회사',
            alarmCount: 0,
            status: '',
            code: 'C11111111',
          },
          structuralReviewPaymentDate: '2024-12-30',
          contractCM: contractCM,
          cmRatioAgainstContractPrice: cmRatioAgainstContractPrice,
        };

        collectionFinals.push(collectionItem);
      }
      resolve(collectionFinals);
    }, Math.random() * 1000);
  });
};
