import React, { type CSSProperties, memo } from 'react';
import VirtualizedAutoSizerTableTableUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableTableUI';
import VirtualizedAutoSizerTableHeadUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableHeadUI';
import VirtualizedAutoSizerTableRowUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableRowUI';
import VirtualizedAutoSizerTableCellUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableCellUI';
import VirtualizedAutoSizerTableBodyUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableBodyUI';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList } from 'react-window';
import VirtualizedAutoSizerTableContainerUI from '@front/src/components/components-with-design/compound/virtualize-auto-sizer-table/TableContainerUI';
import projectSalesListQuery from '@front/src/features/project-sales/query/query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import module from '@front/src/features/project-sales-info/components/drawer/body/list/index.module.scss';
import { ColorPalette } from '@front/assets/theme';
import { NotificationBadge } from '@front/components/badge/badge';
import { ProjectSalesView } from '@front/src/features/project-sales/features/aside/type/views';
import { ProjectSalesSearchParameter } from '@front/src/features/project-sales/features/aside/type/parameters';

const colWidthCode = '27%';
const colWidthName = '73%';

interface Props {
  menuId: number;
  parameter: ProjectSalesSearchParameter;
}

export default function List(props: Props) {
  const { menuId } = props;
  const { data: list } = projectSalesListQuery.useList({ menuId });

  return (
    <VirtualizedAutoSizerTableContainerUI>
      <VirtualizedAutoSizerTableTableUI>
        <VirtualizedAutoSizerTableHeadUI>
          <VirtualizedAutoSizerTableRowUI>
            <VirtualizedAutoSizerTableCellUI
              isHead={true}
              sx={{
                width: colWidthCode,
              }}
            >
              코드
            </VirtualizedAutoSizerTableCellUI>
            <VirtualizedAutoSizerTableCellUI
              isHead={true}
              sx={{
                width: colWidthName,
              }}
            >
              이름
            </VirtualizedAutoSizerTableCellUI>
          </VirtualizedAutoSizerTableRowUI>
        </VirtualizedAutoSizerTableHeadUI>
        <VirtualizedAutoSizerTableBodyUI>
          {(!list || list.length === 0) && <NoResult />}
          {list && list.length > 0 && (
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  overscanCount={20}
                  itemCount={list?.length}
                  itemSize={32}
                  width={width}
                  height={height - 40}
                  itemData={list}
                  className="scroll-bar-holder"
                  style={{
                    overflowX: 'hidden',
                  }}
                >
                  {TableRowWrap as any}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
        </VirtualizedAutoSizerTableBodyUI>
      </VirtualizedAutoSizerTableTableUI>
    </VirtualizedAutoSizerTableContainerUI>
  );
}

const TableRowWrap = memo((props: any) => {
  const { id } = useParams<{ id: string }>();
  const index: number = props.index;
  const item: ProjectSalesView = props.data[index];
  const count = 0;
  const style: CSSProperties = { ...props.style };

  return (
    <Link
      to={`/project/sales/${item.id}/abstract`}
      state={item.id}
      className={count > 9 ? module.link_large : module.link_small}
      style={{
        cursor: item.id === null ? 'default' : 'pointer',
      }}
    >
      <VirtualizedAutoSizerTableRowUI
        style={style}
        sx={{
          position: 'relative',
          backgroundColor: id && item.id === +id ? ColorPalette.main.main_tertiary : '',
          color:
            item.id === null
              ? ColorPalette.greyscale.disabled
              : ColorPalette.greyscale.text_primary,
        }}
      >
        <VirtualizedAutoSizerTableCellUI
          sx={{
            width: colWidthCode,
            fontWeight: 500,
            letterSpacing: '-0.1rem',
          }}
        >
          {item.code ?? '가등록'}
        </VirtualizedAutoSizerTableCellUI>
        <VirtualizedAutoSizerTableCellUI
          sx={{
            width: colWidthName,
            textAlign: 'start',
          }}
        >
          {item.name}
        </VirtualizedAutoSizerTableCellUI>
        <div
          className={module.badge_container}
          style={{
            justifyContent: 'left',
            top: '5px',
          }}
        >
          <NotificationBadge count={count}>
            <span />
          </NotificationBadge>
        </div>
      </VirtualizedAutoSizerTableRowUI>
    </Link>
  );
}, areEqual);
TableRowWrap.displayName = 'ProjectSalesInfoDrawerListItem';

function NoResult() {
  return (
    <VirtualizedAutoSizerTableRowUI>
      <VirtualizedAutoSizerTableCellUI sx={{}}>결과가 없습니다.</VirtualizedAutoSizerTableCellUI>
    </VirtualizedAutoSizerTableRowUI>
  );
}
