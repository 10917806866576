import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AccountingUploadSalesInfoContext } from '@front/src/features/accounting/features/upload/features/sales-info/provider/context';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useAccountingUploadSalesInfoGoalModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/useState';
import { useShallow } from 'zustand/react/shallow';

export { TargetButton as AccountingUploadSalesInfoTargetButton };

const TargetButton = () => {
  const { readOnly } = useContext(AccountingUploadSalesInfoContext);
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { setIsOpen: setIsGoalUpdateModalOpen } = useAccountingUploadSalesInfoGoalModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const handleButtonClick = useCallback(() => {
    if (!year) return;
    setIsGoalUpdateModalOpen(true);
  }, [setIsGoalUpdateModalOpen, year]);
  return (
    <Button
      onClick={handleButtonClick}
      disabled={!year || readOnly}
    >
      목표 등록 / 수정
    </Button>
  );
};
