import { mobileNotificationQuery } from '@front/src/features/mobile-notification/query';
import { mobileNotificationMutation } from '@front/src/features/mobile-notification/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type {
  UserNotificationIsReadParameter,
  UserNotificationParameter,
  UserNotificationSearchParams,
} from '@front/src/features/mobile-notification/types/parameter';

export const mobileNotificationRepository = {
  useUserNotificationToggleAll: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationToggleAll();
    const callback = getNoOpCallback();
    return {
      run: () => {
        mutate(undefined, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useUserNotificationToggle: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationToggle();
    const callback = getNoOpCallback();
    return {
      run: (params: UserNotificationIsReadParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUserNotificationNavigateToggle: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationToggle();
    const callback = getNoOpCallback();
    return {
      run: (params: UserNotificationIsReadParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUserNotificationDeleteAll: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationDeleteAll();
    const callback = getNoOpCallback();
    return {
      run: () => {
        mutate();
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useUserNotificationDelete: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationDelete();
    const callback = getNoOpCallback();
    return {
      run: (id: number) => {
        mutate(id, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUserNotificationCountGet: () => {
    const { data, isLoading } = mobileNotificationQuery.useUserNotificationCountGet();
    return {
      data,
      isLoading,
    };
  },

  useUserNotificationPinUpdate: () => {
    const { mutate } = mobileNotificationMutation.useUserNotificationPinUpdate();
    const callback = getNoOpCallback();
    return {
      run: (params: UserNotificationParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useUserNotificationSearchListGet: (params: UserNotificationSearchParams) => {
    const { data, isLoading } = mobileNotificationQuery.useUserNotificationSearchListGet(params);
    return {
      data,
      isLoading,
    };
  },
};
