import { create } from 'zustand';

interface State {
  regCode: string;
  readOnly?: boolean;
}

interface Actions {
  setRegCode: (regCode: string) => void;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoDesignOutlineCityState };

const useState = create<State & Actions>((set) => ({
  regCode: '',
  setRegCode: (regCode: string) => set(() => ({ regCode })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
