import React from 'react';
import { accountingSettingsManagementAccountingAccountTableLayout } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table';
import { AccountingSettingsManagementAccountingAccountTableHeaderWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/widget';
import { AccountingSettingsManagementAccountingAccountTableCategoryListWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/widget';

export { Table as AccountingSettingsManagementAccountingAccountTableFeature };
interface Props {
  menuId?: number;
}

const Table = ({ menuId }: Props) => {
  const { Table } = accountingSettingsManagementAccountingAccountTableLayout;
  return (
    <Table>
      <AccountingSettingsManagementAccountingAccountTableHeaderWidget menuId={menuId} />
      <AccountingSettingsManagementAccountingAccountTableCategoryListWidget menuId={menuId} />
    </Table>
  );
};
