import React, { useCallback, useContext } from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { AccountingUploadSalesInfoExpectModalUpdateContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/update/provider/context';
import { useFormContext } from 'react-hook-form';
import { AccountingUploadSalesInfoExpectModalListContext } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/list/provider/context';
import { convertDataFromProjectSalesManagementModal } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';

export { ButtonGroup as AccountingUploadSalesInfoExpectModalButtonGroup };

const ButtonGroup = () => {
  const { isConfirmed } = useContext(AccountingUploadSalesInfoExpectModalListContext);
  const { onUpdate } = useContext(AccountingUploadSalesInfoExpectModalUpdateContext);
  const { handleSubmit } = useFormContext();
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { setIsOpen, month } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      month: state.month,
    }))
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onSave = handleSubmit((data) => {
    const converted = convertDataFromProjectSalesManagementModal(
      data,
      year,
      month,
      SalesAccountingAmountSubType.EXPECTED
    );
    onUpdate({
      list: converted,
      isConfirmed: false,
    });
  });
  const onConfirm = handleSubmit((data) => {
    const converted = convertDataFromProjectSalesManagementModal(
      data,
      year,
      month,
      SalesAccountingAmountSubType.EXPECTED
    );
    onUpdate({
      list: converted,
      isConfirmed: !isConfirmed,
    });
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '12px',
      }}
    >
      <Button onClick={handleClose}>취소</Button>
      <Button
        onClick={onSave}
        disabled={isConfirmed}
      >
        저장
      </Button>
      <Box
        sx={{
          width: '120px',
        }}
      >
        <Button onClick={onConfirm}>{isConfirmed ? '확정 취소 요청' : '확정'}</Button>
      </Box>
    </Box>
  );
};
