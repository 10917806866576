import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';
import { ProjectSalesInfoContractEarningsModal } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/components/contract-earnings-modal';
import { useProjectSalesInfoContractEarningsModalLogic } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/widgets/useLogic';
import { ProjectSalesInfoContractEarningsModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/widgets/context';

export { Widgets as ProjectSalesInfoContractEarningsModalWidgets };
interface Props {
  menuId?: number;
}
const Widgets = ({ menuId }: Props) => {
  const { isModalOpen, setIsModalOpen } = useProjectSalesInfoContractEarningsState((state) => ({
    isModalOpen: state.isModalOpen,
    setIsModalOpen: state.setIsModalOpen,
  }));
  const {
    h: { onCreate },
  } = useProjectSalesInfoContractEarningsModalLogic(menuId);
  const value = useMemo(() => ({ h: { onCreate } }), [onCreate]);
  return (
    <ModalLayout
      open={isModalOpen}
      onClose={() => setIsModalOpen()}
      width="30dvw"
      title="기성단계 추가"
    >
      <ProjectSalesInfoContractEarningsModalContext.Provider value={value}>
        <ProjectSalesInfoContractEarningsModal />
      </ProjectSalesInfoContractEarningsModalContext.Provider>
    </ModalLayout>
  );
};
