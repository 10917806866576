import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import AccountStatusApi
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/api';
import AccountStatusConstant
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/constant';
import {
  AccountStatusCommonParameter,
  AccountStatusUpdateParameter,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';

type MutationType = {
  useUpdate: (params: AccountStatusCommonParameter) => {
    mutate: UseMutateFunction<unknown, unknown, AccountStatusUpdateParameter>,
    isLoading: boolean,
  }
}

const AccountStatusMutation: MutationType = {
  useUpdate: (commonParams) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AccountStatusUpdateParameter) => AccountStatusApi.update({ ...params, ...commonParams }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: AccountStatusConstant.queryKey.list });
      },
      onError: async (error) => handleError(error, alert),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: AccountStatusConstant.queryKey.list,
        });
      },
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default AccountStatusMutation;
