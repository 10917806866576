import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyOutlineNumberMutation } from '@front/src/features/affiliated-company/features/outline/features/number/query/mutation';

export const affiliatedCompanyOutlineNumberRepository = {
  useOutlineNumberUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineNumberMutation.useOutlineNumberUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
