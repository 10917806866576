import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalForm } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/form';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTable } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/result-table';
import { projectSalesInfoAffiliatedCompanyPersonCreateModalLayout } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSection } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-section';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartHeader } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-header';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalAddOrDeleteButton } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/add-or-delete-button';

export { Widget as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    SearchPartHeader,
    SearchPartSection,
    SearchPart,
    Layout,
    SelectButtonPart,
    SelectedPart,
    SelectedPartHeader,
    SelectedPartSection,
  } = projectSalesInfoAffiliatedCompanyPersonCreateModalLayout;
  const {
    d: { list },
    h: { onClose, onCreate },
    isOpen,
    isLoading,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list },
      h: { onCreate },
      isLoading,
    }),
    [list, onCreate, isLoading]
  );
  return (
    <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext.Provider
      value={value}
    >
      <ModalLayout
        position="top"
        width="88dvw"
        title="인물 추가"
        open={isOpen}
        onClose={onClose}
      >
        <Layout>
          <SearchPart>
            <SearchPartHeader>
              <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalForm />
            </SearchPartHeader>
            <SearchPartSection>
              <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTable />
            </SearchPartSection>
          </SearchPart>
          <SelectButtonPart>
            <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalAddOrDeleteButton />
          </SelectButtonPart>
          <SelectedPart>
            <SelectedPartHeader>
              <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartHeader />
            </SelectedPartHeader>
            <SelectedPartSection>
              <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSection />
            </SelectedPartSection>
          </SelectedPart>
        </Layout>
      </ModalLayout>
    </ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext.Provider>
  );
};
