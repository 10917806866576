import React, { useCallback, useContext, useMemo } from 'react';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import Button from '@front/layouts/Button';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormText } from '@front/src/components/text-with-hook-form/text-with-hook-form';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';
import { changeViewToParams } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { Index as ProjectSalesInfoProjectDivisionNameTable };

const Index = () => {
  const {
    h: { onCodeUpdate, onProjectDivisionUpdate },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const { confirm } = useCustomDialog();
  const { control, handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) =>
    onProjectDivisionUpdate(changeViewToParams(data as ProjectDivisionView))
  );
  const alertMSG = useMemo(
    () => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>번호 부여는 한 번만 가능합니다.</span>
        <span>하시겠습니까?</span>
      </div>
    ),
    []
  );
  const onCodeBtnClick = useCallback(() => {
    confirm({
      confirmText: '확인',
      children: alertMSG,
      afterConfirm: () => {
        onCodeUpdate();
      },
    });
  }, [confirm, onCodeUpdate, alertMSG]);
  const code = useWatch({ control, name: 'code' });
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th>프로젝트번호</Th>
            <OldTd
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '58px',
              }}
            >
              <HookFormText name="code" />
              <Button
                onClick={onCodeBtnClick}
                disabled={(code[0] !== 'T' && code !== '가등록') || readOnly}
              >
                번호 부여
              </Button>
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>프로젝트 풀네임</Th>
            <OldTd>
              <HookFormInput
                name="fullName"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>프로젝트 닉네임</Th>
            <OldTd>
              <HookFormInput
                name="nickName"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
