import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useProjectSalesInfoEstimationComparedLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useLogic';
import { ProjectSalesInfoEstimationComparedModal } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/components/estimation-competitor-modal';
import { ProjectSalesInfoEstimationComparedContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/context';
import { ProjectSalesInfoEstimationComparedButtonGroup } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/components/estimation-competitor-button-group';
import { ProjectSalesInfoEstimationComparedTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/components/estimation-competitor-table';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoEstimationComparedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { Box } from '@mui/material';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoEstimationComparedWidget = ({ readOnly, name, menuId }: Readonly<Props>) => {
  const { Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoEstimationComparedLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
      isLoading,
    }),
    [list, onDelete, isLoading]
  );
  const { setReadOnly } = useProjectSalesInfoEstimationComparedState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <ProjectSalesInfoEstimationComparedModal menuId={menuId} />
      <ProjectSalesInfoEstimationComparedContext.Provider value={value}>
        <Header title={name}>
          <ProjectSalesInfoEstimationComparedButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoEstimationComparedTable />
        </Body>
      </ProjectSalesInfoEstimationComparedContext.Provider>
    </Box>
  );
};

export default ProjectSalesInfoEstimationComparedWidget;
