import React, { Suspense } from 'react';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import AccountStatusWidgetDetailTable
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/detail-table';

type ElementType = (props: Readonly<SectionComponentProps>) => React.ReactElement;

const AccountStatusDetailTableFeature: ElementType = (props) => {
  const { name } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <AccountStatusWidgetDetailTable {...props} />
      </Suspense>
    </Layout>
  );
};

export default AccountStatusDetailTableFeature;
