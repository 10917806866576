import React, { useContext } from 'react';
import type { UserRecordView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { FormProvider, useForm } from 'react-hook-form';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import CircularProgress from '@front/components/CircularProgress';
import { ProjectSalesInfoProjectDivisionNameTable } from '@front/src/features/project-sales-info/features/project-division/components/name-table';
import { ProjectInfoSalesInfoProjectDivisionSortTable } from '@front/src/features/project-sales-info/features/project-division/components/sort-table';
import { ProjectSalesInfoProjectDivisionManagerTable } from '@front/src/features/project-sales-info/features/project-division/components/manager-table';
import { ProjectSalesInfoProjectDivisionEstimateDateTable } from '@front/src/features/project-sales-info/features/project-division/components/estimate-time-table';
import { ProjectSalesInfoProjectDivisionIntakeDateTable } from '@front/src/features/project-sales-info/features/project-division/components/intake-date-table';
import { ProjectSalesInfoProjectDivisionNoteTable } from '@front/src/features/project-sales-info/features/project-division/components/note-table';
import { addOldProjectNumber } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import { ProjectSalesInfoProjectDivisionContactDateTable } from '@front/src/features/project-sales-info/features/project-division/components/contact-date-table';
import { ProjectSalesInfoProjectDivisionBlueprintTable } from '@front/src/features/project-sales-info/features/project-division/components/blueprint-table';

export { Form as ProjectSalesInfoProjectDivisionForm };
interface Props {
  menuId?: number;
}
const Form = ({ menuId }: Props) => {
  const {
    isLoading,
    id,
    d: { projectDivisionData },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const methods = useForm({
    values: {
      id,
      code: addOldProjectNumber(projectDivisionData) ?? '가등록',
      nickName: projectDivisionData?.nickName ?? '',
      fullName: projectDivisionData?.fullName ?? '',
      businessType: projectDivisionData?.businessType ?? '',
      bidType: projectDivisionData?.bidType ?? '',
      receptionManager: (projectDivisionData?.receptionManager as UserRecordView)?.id ?? '',
      salesManager: (projectDivisionData?.salesManager as UserRecordView)?.id ?? '',
      collectionManager: (projectDivisionData?.collectionManager as UserRecordView)?.id ?? '',
      projectManager: (projectDivisionData?.projectManager as UserRecordView)?.id ?? '',
      estimationReceptionDate: projectDivisionData?.estimationReceptionDate ?? '',
      expectedWorkStartDate: projectDivisionData?.expectedWorkStartDate ?? '-',
      latestContactDate: projectDivisionData?.latestContactDate ?? '',
      expectedCallDate: projectDivisionData?.expectedCallDate ?? '',
      orderIntakeDate: projectDivisionData?.orderIntakeDate ?? '',
      workStartDate: projectDivisionData?.workStartDate ?? '',
      expectedDesignDocumentSupplyDate: projectDivisionData?.expectedDesignDocumentSupplyDate ?? '',
      designDocumentSupplyDate: projectDivisionData?.designDocumentSupplyDate ?? '',
      note: projectDivisionData?.note ?? '',
      contractDate: projectDivisionData?.contractDate ?? '',
    },
  });
  if (typeof projectDivisionData === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  return (
    <FormProvider {...methods}>
      <ProjectSalesInfoProjectDivisionNameTable />
      <ProjectInfoSalesInfoProjectDivisionSortTable />
      <ProjectSalesInfoProjectDivisionManagerTable />
      <ProjectSalesInfoProjectDivisionEstimateDateTable menuId={menuId} />
      <ProjectSalesInfoProjectDivisionContactDateTable />
      <ProjectSalesInfoProjectDivisionIntakeDateTable />
      <ProjectSalesInfoProjectDivisionBlueprintTable />
      <ProjectSalesInfoProjectDivisionNoteTable />
    </FormProvider>
  );
};
