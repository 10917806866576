import { ApprovalDocumentStatus } from '@front/src/types';

export const getApprovalDocumentStatusLabel = {
  [ApprovalDocumentStatus.TEMPORARY]: '임시 저장',
  [ApprovalDocumentStatus.SUBMISSION]: '제출',
  [ApprovalDocumentStatus.ON_GOING]: '진행',
  [ApprovalDocumentStatus.COMPLETED]: '완료',
  [ApprovalDocumentStatus.REJECTED]: '반려',
  [ApprovalDocumentStatus.CANCELED]: '상신 취소',
};
