import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyPersonOutlineCareerView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/view';
import type { AffiliatedCompanyPersonOutlineCareerUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/parameter';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyPersonDetailModalOutlineCareerContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/widgets/context';
import TableSideHeaderWithCheckbox from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

interface Props {
  item: AffiliatedCompanyPersonOutlineCareerView;
  index: number;
}

export { AddressTableRow as AffiliatedCompanyPersonDetailModalOutlineCareerTableRow };
const AddressTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList, onCareerUpdate },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineCareerContext);
  const methods = useForm<AffiliatedCompanyPersonOutlineCareerUpdateParameter>({
    values: {
      id: item.id,
      content: item.content ?? '',
    },
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onCareerUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <TableSideHeaderWithCheckbox
        title="경력"
        index={index}
        idList={idList}
        item={item}
        handleCheckboxChange={handleCheckboxChange}
        disabled={readOnly}
      />
      <OldTd>
        <FormProvider {...methods}>
          <HookFormInput
            name="content"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </FormProvider>
      </OldTd>
    </TableRow>
  );
};
