import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyPersonRelationshipApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/query/api';
import type { AffiliatedCompanyPersonRelationshipUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/parameter';

export const affiliatedCompanyPersonRelationshipMutation = {
  usePersonRelationshipCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        affiliatedCompanyPersonRelationshipApi.createPersonRelationship(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'relationship'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonRelationshipUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonRelationshipUpdateParameter) =>
        affiliatedCompanyPersonRelationshipApi.updatePersonRelationship(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'relationship'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonRelationshipDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPersonRelationshipApi.deletePersonRelationship(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'relationship'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
