import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyPersonDetailModalApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/query/api';

export const affiliatedCompanyPersonDetailModalMutation = {
  usePersonDelete: () => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => affiliatedCompanyPersonDetailModalApi.deletePerson(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'person', 'list']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
