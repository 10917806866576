import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { AffiliatedCompanyGiftHistoryTableBodyTotal } from '@front/src/features/affiliated-company/features/gift-history/components/gift-history-table-body-total';
import { AffiliatedCompanyGiftHistoryTableBody } from '@front/src/features/affiliated-company/features/gift-history/components/gift-history-table-body';

export { GiftHistoryTable as AffiliatedCompanyGiftHistoryTable };

const GiftHistoryTable = () => (
  <TableContainer>
    <Table>
      <TableHeadRow />
      <TableBody>
        <AffiliatedCompanyGiftHistoryTableBodyTotal />
        <AffiliatedCompanyGiftHistoryTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeadRow = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        No.
      </Th>
      <Th
        center
        width="100px"
      >
        대상구분
      </Th>
      <Th
        center
        width="100px"
      >
        대상
      </Th>
      <Th
        center
        width="120px"
      >
        선물일자
      </Th>
      <Th
        center
        width="200px"
      >
        선물내용
      </Th>
      <Th
        center
        width="200px"
      >
        선물목적
      </Th>
      <Th
        center
        width="200px"
      >
        금액
      </Th>
      <Th
        center
        width="100px"
      >
        전달방식
      </Th>
      <Th
        center
        width="100px"
      >
        전달자
      </Th>
      <Th
        center
        width="200px"
      >
        비고
      </Th>
    </TableRow>
  </TableHead>
);
