import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { AxiosError } from 'axios';
import type { EstimationCompetitorView } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/types/views';
import { commonApi } from '@front/src/api/api';

const estimationCompetitorQuery = {
  useList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery<unknown, AxiosError, EstimationCompetitorView[]>({
      queryFn: () =>
        commonApi.getList(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation-competitor`,
          commonParams
        ),
      queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'competitor'],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return data;
  },
};

export default estimationCompetitorQuery;
