import { affiliatedCompanyGiftHistoryQuery } from '@front/src/features/affiliated-company/features/gift-history/query/query';
import type { AffiliatedCompanyGiftHistoryGetParameter } from '@front/src/features/affiliated-company/features/gift-history/types/parameter';

export const affiliatedCompanyGiftHistoryRepository = {
  useGiftHistoryListGet: (params: AffiliatedCompanyGiftHistoryGetParameter, menuId) => {
    const { data, isLoading } = affiliatedCompanyGiftHistoryQuery.useGiftHistoryListGet(
      params,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
