import React from 'react';
import { Box, Divider } from '@mui/material';
import Button from '@front/layouts/Button';
import HeaderHistoryHeaderSelect from '@front/src/features/header-history/components/HeaderSelect';
import HeaderHistoryTable from '@front/src/features/header-history/components/HistoryTable';

interface Props {
  onClose?: () => void;
}

export default function HeaderHistoryContent({ onClose }: Readonly<Props>) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '8dvw',
          }}
        >
          <HeaderHistoryHeaderSelect />
        </Box>
        {onClose && <Button onClick={onClose}>닫기</Button>}
      </Box>
      <Divider />
      <HeaderHistoryTable />
    </Box>
  );
}
