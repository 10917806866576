import React from 'react';
import { type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import { ColorPalette } from '@front/assets/theme';
import type { TaxAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';
import { getRemoveIndex } from '@front/src/components/n-depth-table-box/utils';

interface Props {
  colIndex: number;
  rowIndex: number;
  showListFieldArray: UseFieldArrayReturn<TaxAccountTableFormValues, 'showList', 'id'>;
  activeRowListFieldArray: UseFieldArrayReturn<TaxAccountTableFormValues, 'activeRowList', 'id'>;
}

export default function TaxAccountTableFullCell(props: Props) {
  const { rowIndex, colIndex, activeRowListFieldArray, showListFieldArray } = props;
  const { getValues, setValue } = useFormContext<TaxAccountTableFormValues>();

  const showList = getValues('showList');
  const cellData = showList[colIndex]?.[rowIndex];

  const { append, remove } = showListFieldArray;
  const { append: appendActive, remove: removeActive } = activeRowListFieldArray;

  const activeRowList = getValues('activeRowList');
  const lastCol = getValues('col') - 1;
  const isActive = activeRowList[colIndex]?.value === rowIndex;

  const isLastDepth = cellData.children.length === 0;

  const handleClick = () => {
    if (!cellData || isLastDepth) return;

    if (activeRowList[colIndex]) {
      remove(getRemoveIndex(colIndex + 1, lastCol));
      removeActive(getRemoveIndex(colIndex, lastCol));
    }

    if (colIndex !== lastCol) {
      append([cellData.children]);
    }

    appendActive({ value: rowIndex });

    const filtered = cellData.children.filter((c) => c.children.length === 0);
    setValue('lastAccount', filtered);
  };

  return (
    <NDepthTableBoxCell
      onClick={handleClick}
      sx={{
        backgroundColor: isActive ? ColorPalette.main.main_secondary : 'transparent',
        cursor: isLastDepth ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: isActive
            ? ColorPalette.main.main_secondary
            : isLastDepth
            ? 'transparent'
            : ColorPalette.main.main_tertiary,
        },
      }}
    >
      {cellData?.name ?? ''}
    </NDepthTableBoxCell>
  );
}
