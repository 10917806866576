import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { affiliatedCompanyBusinessRegistrationApi } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/query/api';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';

export const affiliatedCompanyBusinessRegistrationMutation = {
  useOutlineBusinessRegistrationFileUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter) =>
        affiliatedCompanyBusinessRegistrationApi.updateOutlineBusinessRegistrationFile(
          params,
          menuId
        ),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'outline']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
