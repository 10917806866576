import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import {
  convertBooleanToYNIfExistForForm,
  convertEmptyToNullForParameter,
  convertNullToEmptyForForm,
  convertYNToBooleanForParameter,
  downloadFile,
  getValueIfExist,
  YNOptionList,
} from '@front/src/utils';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectExperimentInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/view';
import { getIsChangedSubjectReviewModalDetailParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/utils';
import { ProjectSalesInfoSubjectReviewModalSpecialWindContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/widgets/context';
import { shallow } from 'zustand/shallow';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';

export { SpecialWindTableRow as ProjectSalesInfoSubjectReviewModalSpecialWindTableRow };

const SpecialWindTableRow = () => {
  const { detail, onUpdate } = useContext(ProjectSalesInfoSubjectReviewModalSpecialWindContext);
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const methods = useForm({
    values: getValues(detail),
  });
  const onSubmit = methods.handleSubmit((data) => {
    const hasConditionOfSpecialWindExperiment = convertYNToBooleanForParameter(
      data.hasConditionOfSpecialWindExperiment
    );
    onUpdate({
      id,
      gettingDesignDate: convertEmptyToNullForParameter(detail?.gettingDesignDate),
      experimentStandardCode: convertEmptyToNullForParameter(detail?.experimentStandardCode),
      countOfSiteModel: convertEmptyToNullForParameter(detail?.countOfSiteModel),

      hasConditionOfSpecialWindExperiment,
      isNearCoastBy3Km: convertYNToBooleanForParameter(data.isNearCoastBy3Km),
      basicWindSpeed: convertEmptyToNullForParameter(data.basicWindSpeed),
      file: data.windSpeedMapPdf,

      hasWindEnvironment: convertYNToBooleanForParameter(detail?.hasWindEnvironment),
      hasBuildingWind: convertYNToBooleanForParameter(detail?.hasBuildingWind),

      isChanged: getIsChangedSubjectReviewModalDetailParameter(
        detail?.hasConditionOfSpecialWindExperiment,
        hasConditionOfSpecialWindExperiment
      ),
    });
  });
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );

  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>
          <HookFormSelect
            height="60px"
            isDefaultPossible
            defaultLabel="선택"
            name="hasConditionOfSpecialWindExperiment"
            optionList={YNOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormSelect
            isDefaultPossible
            defaultLabel="선택"
            name="isNearCoastBy3Km"
            optionList={YNOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            type="number"
            name="basicWindSpeed"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>{getValueIfExist(detail?.address)}</OldTd>
        <OldTd>
          {!readOnly ? (
            <HookFormFileUploader
              name="windSpeedMapPdf"
              onSubmit={onSubmit}
              ext="pdf"
              message="기본풍속지도 파일은 pdf 파일 형식입니다."
            />
          ) : (
            <FileDisplayText
              fileName={detail?.windSpeedMapPdf.filename ?? ''}
              onClick={downloadFile(detail?.windSpeedMapPdf.id)}
            />
          )}
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};

const getValues = (detail?: ProjectExperimentInformationView) => ({
  hasConditionOfSpecialWindExperiment: convertBooleanToYNIfExistForForm(
    detail?.hasConditionOfSpecialWindExperiment
  ),
  isNearCoastBy3Km: convertBooleanToYNIfExistForForm(detail?.isNearCoastBy3Km),
  basicWindSpeed: convertNullToEmptyForForm(detail?.basicWindSpeed),
  windSpeedMapPdf: detail?.windSpeedMapPdf,
});
