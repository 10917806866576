import { ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/domain';
import type { OptionType } from '@front/src/components/Select';
import { convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils/index';

export const defaultSearchParameter = {
  keywordType: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME,
  keyword: '',
};
export const projectSalesInfoAffiliatedCompanyPersonSearchKeywordTypeOptionList: OptionType[] = [
  {
    value: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME,
    label: convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean(
      ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME
    ),
  },
  {
    value: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.NAME,
    label: convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean(
      ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.NAME
    ),
  },
  {
    value: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.PHONE_NUMBER,
    label: convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean(
      ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.PHONE_NUMBER
    ),
  },
  {
    value: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.EMAIL,
    label: convertAffiliatedCompanyPersonSearchKeywordTypeEnglishToKorean(
      ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.EMAIL
    ),
  },
];
