import headerMeta from '@front/src/components/ui-builder/headerMeta';

export const getDefaultFilterBundle = (headerList, list) => {
  const getConverted = () =>
    // if (!hasFolded) return headerList;
    //
    // const findIndex = headerList?.findIndex((d) => d.isFoldStartColumn);
    //
    // return headerList?.slice(0, findIndex);
    headerList;
  return getConverted()?.map((h) => {
    const { attributeName, filterable } = headerMeta[h.id];

    if (filterable) {
      return {
        header: h,
        headerId: h.id,
        filterable,
        name: h.name,
        active: false,
        attributeName,
        filter: Array.from(
          new Set(list?.map((l) => attributeName?.reduce((acc, key) => acc?.[key], l) ?? ''))
        )
          .sort()
          .map((m) => ({
            value: m,
            checked: true,
            shown: true,
          })),
      };
    }
    return {
      header: h,
      headerId: h.id,
      filterable,
      name: h.name,
      active: false,
      attributeName,
      filter: [],
    };
  });
};
