import React from 'react';
import { AccountingUploadEcountDataFeature } from '@front/src/features/accounting/features/upload/features/ecount-data';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const AccountingUploadHistoryManagementUploadFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AccountingUploadEcountDataFeature
          readOnly={readOnly}
          menuId={menuId}
        />
      </Body>
    </Layout>
  );
};

export default AccountingUploadHistoryManagementUploadFeature;
