import { Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { DefaultFunction } from 'type/Function';
import type { AlertVO } from 'dialog/domain';
import { DialogStatus } from 'dialog/domain';
import Button from 'layouts/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextBox from 'layouts/Text';
import { ColorPalette } from 'assets/theme';
import type { ChildrenProps } from '@front/dialog/reducer';
import type { ModalPositionType } from '@front/layouts/ModalLayout';
import Select from '@front/layouts/Select';

interface Props extends AlertVO {
  open: boolean;
  onClose: DefaultFunction;
  position?: ModalPositionType;
}

export default function Alert(props: Props) {
  const [positionType, setPositionType] = useState(props.position ?? 'center');
  const sxPosition = useMemo(() => {
    switch (positionType) {
      case 'center': {
        return {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
        };
      }
      case 'top': {
        return {
          transform: 'translate(-50%, 0%)',
          top: '0',
          left: '50%',
        };
      }
      case 'bottom': {
        return {
          transform: 'translate(-50%, 0%)',
          bottom: '0',
          left: '50%',
        };
      }
      case 'left': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          left: '0',
        };
      }
      case 'right': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          right: '0',
        };
      }
    }
  }, [positionType]);
  const handleSelectChange = useCallback(
    (e) => {
      setPositionType(e.target.value);
    },
    [setPositionType]
  );
  useEffect(() => {
    return () => setPositionType('center');
  }, []);
  return (
    <>
      {props.open && (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          fullWidth={true}
          maxWidth="sm"
          PaperProps={{
            sx: {
              position: 'fixed',
              margin: '0px',
              ...sxPosition,
            },
          }}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: 0,
            },
            width: '100%',
            height: '100%',
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: ColorPalette._2d3a54,
              color: ColorPalette._ffffff,
              height: '50px',
              padding: '0 20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextBox variant="heading2">
                {props.title === 'ERROR' ? props.title : 'ALARM'}
              </TextBox>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
              >
                <Select
                  variant="outlined"
                  value={positionType}
                  onChange={handleSelectChange}
                >
                  {optionList.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FontAwesomeIcon
                  icon="xmark"
                  onClick={props.onClose}
                  style={{
                    cursor: 'pointer',
                    fontSize: '18px',
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                flexWrap: 'wrap',
                display: 'flex',
                width: '100%',
                paddingTop: '20px',
              }}
            >
              {typeof props.children === 'string' && props.children}
              {Array.isArray(props.children) &&
                props.children.map((data: ChildrenProps) =>
                  typeof data === 'string' ? (
                    <Box key={data}>{data}</Box>
                  ) : (
                    <Box
                      key={data.value}
                      sx={data.sx}
                    >
                      {data.value}
                    </Box>
                  )
                )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
          >
            <Button
              onClick={props.onClose}
              color={
                props.status === DialogStatus.ERROR
                  ? 'error'
                  : props.status === DialogStatus.WARN
                  ? 'warning'
                  : undefined
              }
            >
              {props.closeText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const optionList = [
  {
    value: 'center',
    label: '중앙',
  },
  {
    value: 'top',
    label: '상단',
  },
  {
    value: 'bottom',
    label: '하단',
  },
  {
    value: 'left',
    label: '왼쪽',
  },
  {
    value: 'right',
    label: '오른쪽',
  },
];
