import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { createProjectSalesInfoAffiliatedCompanyWebHardCompanyList } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/utils';

export { CompanySelect as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalCompanySelect };

const CompanySelect = () => {
  const {
    d: { companyList, detail },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext);
  const { setCompanyId } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    (state) => ({
      setCompanyId: state.setCompanyId,
    }),
    shallow
  );
  const { setValue } = useFormContext();
  const handleCompanySelectChange = useCallback(
    (e) => {
      setCompanyId(+e.target.value);
      setValue('webHardId', '');
    },
    [setCompanyId, setValue]
  );
  const disabled = useMemo(() => companyList?.length === 0, [companyList]);
  useEffect(() => {
    setCompanyId(detail?.affiliatedCompanyId);
    setValue('companyId', detail?.affiliatedCompanyId?.toString());
  }, [detail, setCompanyId, setValue]);
  return (
    <HookFormSelect
      defaultLabel={disabled ? '관계사를 먼저 추가하세요' : '선택'}
      disabled={disabled}
      name="companyId"
      optionList={createProjectSalesInfoAffiliatedCompanyWebHardCompanyList(companyList)}
      onChange={handleCompanySelectChange}
    />
  );
};
