import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Table, OldTd } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { getValueIfExist } from '@front/src/utils';

export { DetailTable as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyDetailModalDetailTable };

const DetailTable = () => {
  const {
    d: { detail },
    isLoading,
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext);
  if (typeof detail === 'undefined' || isLoading) {
    return <LoadingSpinner height="232px" />;
  }
  if (detail === null) {
    return <NoResultBox />;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th width="12%">회사명</Th>
            <OldTd width="24%">{getValueIfExist(detail?.company?.name)}</OldTd>
            <Th width="12%">이름(직급)</Th>
            <OldTd width="24%">{`${getValueIfExist(detail?.person?.name)}(${getValueIfExist(
              detail?.person?.position
            )})`}</OldTd>
          </TableRow>
          <TableRow>
            <Th>회사 대표 번호</Th>
            <OldTd>{getValueIfExist(detail?.company?.contactNumber)}</OldTd>
            <Th>핸드폰 번호</Th>
            <OldTd>{getValueIfExist(detail?.person?.phoneNumber)}</OldTd>
          </TableRow>
          <TableRow>
            <Th>회사 직통 번호</Th>
            <OldTd>{getValueIfExist(detail?.person?.directPhoneNumber)}</OldTd>
            <Th>회사 이메일</Th>
            <OldTd>{getValueIfExist(detail?.person?.companyEmail)}</OldTd>
          </TableRow>
          <TableRow>
            <Th>회사 팩스 번호</Th>
            <OldTd>{getValueIfExist(detail?.person?.companyFaxNumber)}</OldTd>
            <Th>개인 이메일</Th>
            <OldTd>{getValueIfExist(detail?.person?.email)}</OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
