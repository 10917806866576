import React, { useCallback } from 'react';
import { useBlocker } from 'react-router';
import ModalLayout from '@front/layouts/ModalLayout';
import { Box, Typography } from '@mui/material';
import { useBlockModalState } from '@front/src/features/modal/features/block-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import Button from '@front/layouts/Button';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

const BlockModalFeature = () => {
  const { isDirty, callback, setIsDirty } = useBlockModalState(
    useShallow((state) => ({
      isDirty: state.isDirty,
      callback: state.callback,
      setIsDirty: state.setIsDirty,
    }))
  );
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );
  const onClose = useCallback(() => {
    setIsDirty(false);
  }, [setIsDirty]);
  const handleConfirmClick = useCallback(() => {
    callback?.();
    blocker.proceed?.();
    onClose();
  }, [callback, blocker, onClose]);
  const isBlocked = blocker.state === 'blocked';
  return (
    <>
      {isBlocked && (
        <ModalLayout
          open={blocker.state === 'blocked'}
          title="페이지 이동 확인"
          width="30dvw"
          onClose={() => {
            blocker.reset?.();
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography>저장되지 않은 변경사항은 반영되지 않습니다.</Typography>
            <Typography>페이지를 벗어나시겠습니까?</Typography>
            <Box
              display="flex"
              gap="10px"
              padding="10px"
            >
              <Button
                shape="basic2"
                onClick={() => {
                  blocker.reset?.();
                }}
              >
                취소
              </Button>
              <Button onClick={handleConfirmClick}>확인</Button>
            </Box>
          </Box>
        </ModalLayout>
      )}
    </>
  );
};

export default BlockModalFeature;
