import { AccountingSettingsDailyReportStatusQuery } from '@front/src/features/accounting/features/settings/features/daily-report-status/query/query';

export { Repository as AccountingSettingsDailyReportStatusRepository };

const Repository = {
  useGetList: (menuId) => {
    const { data, isLoading } = AccountingSettingsDailyReportStatusQuery.useGetList(menuId);
    return {
      data,
      isLoading,
    };
  },
};
