import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyPersonRelationshipView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/view';
import { AffiliatedCompanyPersonDetailModalRelationshipContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/context';
import type { AffiliatedCompanyPersonRelationshipUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/parameter';
import { AffiliatedCompanyCharacterRelationshipTableRowSelector } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/components/relationship-table-row-selector';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalRelationshipState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useState';

interface Props {
  item: AffiliatedCompanyPersonRelationshipView;
  index: number;
}

const getValues = (item: AffiliatedCompanyPersonRelationshipView) => ({
  id: item.id,
  affiliatedCompanyId: item.affiliatedCompanyId ?? undefined,
  personId: item.personId ?? '',
  reason: item.reason ?? '',
  relationshipLevel: item.relationshipLevel ?? '',
});

export { AddressTableRow as AffiliatedCompanyPersonDetailModalRelationshipTableRow };
const AddressTableRow = ({ item }: Props) => {
  const {
    h: { setIdList, onRelationshipUpdate },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalRelationshipContext);
  const methods = useForm<AffiliatedCompanyPersonRelationshipUpdateParameter>({
    values: getValues(item),
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onRelationshipUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyPersonDetailModalRelationshipState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>
          <Checkbox
            checked={idList.includes(item.id)}
            onChange={handleCheckboxChange(item.id)}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd sx={{ padding: '8px' }}>
          <AffiliatedCompanyCharacterRelationshipTableRowSelector
            item={item}
            onSubmit={onSubmit}
          />
        </OldTd>
        <OldTd>{item.position ?? '-'}</OldTd>
        <OldTd>{item.department ?? '-'}</OldTd>
        <OldTd>{item.affiliatedCompanyName ?? '-'}</OldTd>
        <OldTd>
          <HookFormInput
            name="reason"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="relationshipLevel"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
