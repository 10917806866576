import XLSX from 'xlsx-js-style';
import type { ProjectComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/view';

export const projectSalesInfoSubjectReviewShapeRatioExcelDownload = (
  data: ProjectComplexInformationView[],
  excelFileName
) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    [
      '',
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
            left: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '0000d0' } },
            right: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
    ],
    [
      '',
      {
        v: '',
        s: {
          border: {
            left: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '[별첨] 풍동실험 대상 검토',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
            left: { style: 'medium', color: { rgb: '000000' } },
          },
          font: { sz: 16 },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            top: { style: 'medium', color: { rgb: '000000' } },
            right: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            right: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
    ],
    [
      '',
      {
        v: '',
        s: {
          border: {
            left: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            left: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '',
        s: {
          border: {
            right: { style: 'medium', color: { rgb: '000000' } },
          },
        },
      },
      {
        v: '',
        s: {
          border: {
            right: { style: 'medium', color: { rgb: '0000d0' } },
          },
        },
      },
    ],
    [],
    [],
    [
      '',
      '',
      {
        v: 'KDS 41 12 00 : 2022 건축물 설계하중 (5.1.4 특별풍하중)',
        s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
      },
    ],
    ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(1) 풍진동의 영향을 고려해야 할 건축구조물',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(2) 특수한 지붕골조',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(3) 골바람효과가 발생하는 건설지점',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(4) 인접효과가 우려되는 신축건축구조물',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(5) 외장재의 파손에 주의해야할 건축구조물',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      {
        v: '(6) 특수한 형상의 건축구조물',
        s: { alignment: { vertical: 'center' }, font: { sz: 8 } },
      },
    ],
    [],
    [
      '',
      '',
      {
        v: '건물(동)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '층수',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '높이\n(H, m)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '면적\n(A, m2)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '형상비\n(H/A)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '특별풍하중 조건',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      '',
      '',
      '',
      {
        v: '최소 실험대상',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      '',
      {
        v: '비고',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
    ],
    [
      '',
      '',
      {
        v: '',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          border: {
            top: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
          },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      '',
      '',
      '',
      '',
      {
        v: '(1)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '(4)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '(5)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '(2), (3), (6)',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '주골조설계용',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
      {
        v: '외장재설계용',
        s: {
          alignment: { vertical: 'center', horizontal: 'center' },
          fill: {
            fgColor: { rgb: '80a0ff' },
          },
          font: { sz: 8 },
        },
      },
    ],
    [],
  ]);
  ws['!merges'] = [
    // 별첨
    { s: { r: 2, c: 2 }, e: { r: 5, c: 13 } },
    // KDS
    { s: { r: 6, c: 2 }, e: { r: 6, c: 13 } },
    // 공백
    { s: { r: 7, c: 2 }, e: { r: 7, c: 13 } },
    // (1)
    { s: { r: 8, c: 6 }, e: { r: 8, c: 9 } },
    // (2)
    { s: { r: 9, c: 6 }, e: { r: 9, c: 7 } },
    // (3)
    { s: { r: 10, c: 6 }, e: { r: 10, c: 9 } },
    // (4)
    { s: { r: 11, c: 6 }, e: { r: 11, c: 9 } },
    // (5)
    { s: { r: 12, c: 6 }, e: { r: 12, c: 9 } },
    // (6)
    { s: { r: 13, c: 6 }, e: { r: 13, c: 8 } },
    // 공백
    { s: { r: 14, c: 2 }, e: { r: 14, c: 13 } },
    // 건물(동)
    { s: { r: 15, c: 2 }, e: { r: 16, c: 2 } },
    // 층수
    { s: { r: 15, c: 3 }, e: { r: 16, c: 3 } },
    // 높이
    { s: { r: 15, c: 4 }, e: { r: 16, c: 4 } },
    // 면적
    { s: { r: 15, c: 5 }, e: { r: 16, c: 5 } },
    // 형상비
    { s: { r: 15, c: 6 }, e: { r: 16, c: 6 } },
    // 특별풍하중 조건
    { s: { r: 15, c: 7 }, e: { r: 15, c: 10 } },
    // 최소 실험대상
    { s: { r: 15, c: 11 }, e: { r: 15, c: 12 } },
    // 비고
    { s: { r: 15, c: 13 }, e: { r: 16, c: 13 } },
  ];
  data.forEach((item) => {
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          '',
          '',
          {
            v: item.name,
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.floor,
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.height,
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.floorArea,
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.aspectRatio,
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.hasAspectRatioOverThree ? 'check' : '',
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.hasAspectRatioOverThree ? 'check' : '',
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.hasConditionOfSpecialWindExperiment ? 'Y' : 'N',
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          '',
          {
            v: item.experimentType ?? '',
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
          {
            v: item.experimentType ?? '',
            s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { sz: 8 } },
          },
        ],
      ],
      { origin: -1 }
    );
    ws['!cols'] = [
      { wpx: 12 },
      { wpx: 12 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 48 },
      { wpx: 12 },
    ];
  });

  XLSX.utils.book_append_sheet(wb, ws, '별첨-풍동실험 대상 검토');
  XLSX.writeFile(wb, excelFileName + '.xlsx');
};

export const getShapeRatioFileName = (code, gettingDesignDate) => {
  if (!code && !gettingDesignDate) {
    return '형상비검토';
  }
  if (!code) {
    return '형상비검토_' + gettingDesignDate;
  }
  if (!gettingDesignDate) {
    return code + '_형상비검토';
  }
  return code + '_형상비검토_' + gettingDesignDate;
};
