import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/types/view';

interface State {
  d: {
    estimationIdList?: ProjectEstimationView[];
  };
  h: {
    onCreate: (params: ProjectSalesInfoBidInfoParams) => void;
  };
}

export { Context as ProjectSalesInfoBidInfoCreateModalContext };

const Context = createContext<State>({
  h: {
    onCreate: noOp,
  },
  d: {},
});
