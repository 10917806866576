import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import Box from '@mui/material/Box';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import type { ConfirmView } from '@front/src/features/dialog/types';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

const workIndicatorCellComponent = {
  205: ({ ...rest }: CellComponentProps) => <WorkIndicatorDepartment {...rest} />,
  206: ({ ...rest }: CellComponentProps) => <WorkIndicatorJobDuty {...rest} />,
  207: ({ ...rest }: CellComponentProps) => <WorkIndicatorUser {...rest} />,
  208: ({ ...rest }: CellComponentProps) => <WorkIndicatorDescription {...rest} />,
  209: ({ ...rest }: CellComponentProps) => <WorkIndicatorNumberAttr1 {...rest} />,
  210: ({ ...rest }: CellComponentProps) => <WorkIndicatorTextAttr1 {...rest} />,
  211: ({ ...rest }: CellComponentProps) => <WorkIndicatorDateAttr1 {...rest} />,
  212: ({ ...rest }: CellComponentProps) => <WorkIndicatorNumberAttr2 {...rest} />,
  213: ({ ...rest }: CellComponentProps) => <WorkIndicatorTextAttr2 {...rest} />,
  214: ({ ...rest }: CellComponentProps) => <WorkIndicatorDateAttr2 {...rest} />,
  215: ({ ...rest }: CellComponentProps) => <WorkIndicatorNumberAttr3 {...rest} />,
  216: ({ ...rest }: CellComponentProps) => <WorkIndicatorTextAttr3 {...rest} />,
  217: ({ ...rest }: CellComponentProps) => <WorkIndicatorDateAttr3 {...rest} />,
};

export default workIndicatorCellComponent;

interface Props extends CellComponentProps<WorkCreatorView> {}

function WorkIndicatorDepartment({ sx = {}, onClick, item }: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {item.user?.department?.name ?? '-'}
    </Box>
  );
}

function WorkIndicatorJobDuty({ sx = {}, onClick, item }: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      {item.user?.jobDuty ?? '-'}
    </Box>
  );
}

function WorkIndicatorUser(props: Readonly<Props>) {
  const confirmProps: ConfirmView = {
    title: '지시자 삭제 확인 모달',
    lineBreakChildren: [
      { value: '역할명에서 제외되면 해당 업무를 열람할 수 없습니다.' },
      { value: '제외 하시겠습니까?' },
    ],
  };

  return (
    <UIBuilderTableCellUserSelector
      {...props}
      name="user"
      confirmProps={confirmProps}
    />
  );
}

function WorkIndicatorDescription({
  onClick,
  sx = {},
  onSubmit,
  isEditMode,
  readOnly,
  isForm,
}: Readonly<Props>) {
  return (
    <Box
      sx={sx}
      onClick={onClick}
    >
      <UncontrolledInput
        name="description"
        onBlur={isForm ? undefined : onSubmit}
        placeholder="직접 입력"
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

function WorkIndicatorNumberAttr1({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr1"
      {...rest}
    />
  );
}

function WorkIndicatorTextAttr1({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr1"
      {...rest}
    />
  );
}

function WorkIndicatorDateAttr1({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr1"
      {...rest}
    />
  );
}

function WorkIndicatorNumberAttr2({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr2"
      {...rest}
    />
  );
}

function WorkIndicatorTextAttr2({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr2"
      {...rest}
    />
  );
}

function WorkIndicatorDateAttr2({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr2"
      {...rest}
    />
  );
}

function WorkIndicatorNumberAttr3({ ...rest }: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr3"
      {...rest}
    />
  );
}

function WorkIndicatorTextAttr3({ ...rest }: Readonly<Props>) {
  return (
    <TextAttr
      name="textAttr3"
      {...rest}
    />
  );
}

function WorkIndicatorDateAttr3({ ...rest }: Readonly<Props>) {
  return (
    <DateAttr
      name="dateAttr3"
      {...rest}
    />
  );
}
