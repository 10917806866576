// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SDR74bphz1_d_wGLG4Qz{display:flex;gap:10px;height:52dvh;overflow-y:hidden;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 10px;\r\n  height: 52dvh;\r\n  overflow-y: hidden;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SDR74bphz1_d_wGLG4Qz`
};
export default ___CSS_LOADER_EXPORT___;
