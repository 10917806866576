// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._YBTeegWatS7O9832U9t{width:20%;max-width:200px;background-color:#f1f5fc !important;color:#252627;font-size:13px;font-weight:bold}.ZaJ46vAxxNi0gU2xjhNC{display:flex;justify-content:space-between}.yv5SmufGAm9XHVW9J577{display:flex;justify-content:center}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/table/th.module.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,eAAA,CACA,mCAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,sBAAA","sourcesContent":["@import \"@front/assets/colors.scss\";\r\n\r\n.th {\r\n  width: 20%;\r\n  max-width: 200px;\r\n  background-color: $_f1f5fc !important;\r\n  color: $_252627;\r\n  font-size: 13px;\r\n  font-weight: bold;\r\n}\r\n\r\n.children {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.center {\r\n  display: flex;\r\n  justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": `_YBTeegWatS7O9832U9t`,
	"children": `ZaJ46vAxxNi0gU2xjhNC`,
	"center": `yv5SmufGAm9XHVW9J577`
};
export default ___CSS_LOADER_EXPORT___;
