import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { ProjectSalesInfoAbstractEstimationRepository } from '@front/src/features/project-sales-info/features/abstract/features/estimation/repository/repository';

export { useLogic as useProjectSalesInfoAbstractEstimationDetailLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: detail } = ProjectSalesInfoAbstractEstimationRepository.useDetailGet(id!, menuId);
  return {
    detail,
  };
};
