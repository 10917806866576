import { styled } from '@mui/material/styles';
import type { DrawerProps } from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import IconButton from '@front/layouts/IconButton';
import { ArrowRight as RightIcon } from '@mui/icons-material';
import { useMemoDrawerState } from '@front/src/components/memo-drawer/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props extends DrawerProps {
  openedWidth?: number;
  closedWidth?: number;
  open: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  closedChildren?: ReactNode;
}

export const MemoDrawer = ({
  openedWidth,
  closedWidth,
  open,
  children,
  sx,
  closedChildren,
}: Props) => {
  const AppDrawer = useMemo(
    () =>
      styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
        '& .MuiDrawer-paper': {
          zIndex: theme.zIndex.drawer + 1,
          padding: 0,
          position: 'relative',
          whiteSpace: 'nowrap',
          border: 'none',
          width: `${openedWidth ?? 300}px`,
          height: '100dvh',
          backgroundColor: ColorPalette._f1f5fc,
          overflowY: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: 'border-box',
          ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            width: `${closedWidth ?? 0}px`,
          }),
          ...(sx ?? {}),
        },
      })),
    [openedWidth, closedWidth, sx]
  );
  const { toggle } = useMemoDrawerState(
    useShallow((state) => ({
      toggle: state.toggle,
    }))
  );
  return (
    <AppDrawer
      open={open}
      variant="permanent"
      sx={sx}
      anchor="right"
    >
      <Box>
        {open ? (
          <>
            <Box
              sx={{
                display: 'flex',
                padding: '15px 10px',
              }}
            >
              <IconButton onClick={toggle}>
                <RightIcon />
              </IconButton>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: ColorPalette._252627,
                  marginLeft: '10px',
                }}
              >
                메모
              </Typography>
            </Box>
            {children}
          </>
        ) : (
          <>{closedChildren}</>
        )}
      </Box>
    </AppDrawer>
  );
};
