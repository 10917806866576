import { default as axios } from '@front/src/config/axios';
import { baseUrl } from '@front/src/features/project-selector-modal/utils/constant';

const url = {
  getProjectList: () => `${baseUrl}/short`,
};

export const projectSelectorModalApi = {
  getProjectList: async (params) => {
    const { data } = await axios.get(url.getProjectList(), { params });
    return data;
  },
};
