import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

interface Props extends CellComponentProps {
  name: string;
}

export default function UIBuilderTableCellInputText(props: Props) {
  const { isEditMode, isForm, onSubmit, readOnly, onClick, sx, name } = props;

  const { getValues } = useFormContext();
  const value = getValues(name);

  const renderContent = () => {
    if (readOnly || !isEditMode) return value || '-';

    return (
      <UncontrolledInput
        name={name}
        onBlur={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
      />
    );
  };

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
