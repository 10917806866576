import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type {
  IdDeleteParameter,
  ProjectStepInformationParameter,
} from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/parameter';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

interface State {
  h: {
    onCreate: (params?: any) => void;
    onDelete: (params: IdDeleteParameter) => void;
    onUpdate: (params: ProjectStepInformationParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  d: {
    projectDivisionModalData?: ProjectStepInformationView[];
    businessProgressStepOptionList?: VariableInformationView[];
  };
  isLoading: boolean;
  idList: number[];
}

export { Context as ProjectSalesInfoProjectDivisionModalContext };

const Context = createContext<State>({
  h: {
    onCreate: noOp,
    onDelete: noOp,
    onUpdate: noOp,
    setIdList: noOp,
  },
  d: {
    projectDivisionModalData: [],
  },
  isLoading: false,
  idList: [],
});
