import { useMutation } from 'react-query';
import type { FileAuthUpdateParameter } from '@front/src/features/file-item/features/auth-settings/types/parameter';
import fileItemApi from '@front/src/features/file-item/api/api';
import { useSnackbar } from 'notistack';

const fileAuthSettingsMutation = {
  useUpdate: () => {
    const { enqueueSnackbar } = useSnackbar();
    const { mutate } = useMutation<unknown, unknown, FileAuthUpdateParameter>({
      mutationFn: (params) => fileItemApi.update(params),
      mutationKey: ['file-auth', 'update'],
      onSuccess: async () => {
        enqueueSnackbar('권한 설정 성공했습니다.', { variant: 'success' });
      },
    });
    return mutate;
  },
};

export default fileAuthSettingsMutation;
