import { useQuery } from 'react-query';
import { projectSalesInfoSubjectReviewModalComplexInformationApi } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/query/api';
import type { ProjectComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/view';

export { query as projectSalesInfoSubjectReviewModalComplexInformationQuery };
const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectComplexInformationView[]>({
      queryFn: () => projectSalesInfoSubjectReviewModalComplexInformationApi.getList(id, menuId),
      queryKey: [
        'project',
        'sales-info',
        'subject-review',
        'shape-ratio',
        'complex-information',
        'list',
        id,
      ],
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
