import React from 'react';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import WorkCarouselMoveSeqTableRow from '@front/src/features/work/features/work/features/carousel/features/move-seq/components/TableRow';
import WorkCarouselUpdateSeqLoadingSpinner from '@front/src/features/work/features/work/features/carousel/features/move-seq/components/LoadingSpinner';
import workCarouselQuery from '@front/src/features/work/features/work/features/carousel/query/query';
import useWorkCarouselMoveSeqForm from '@front/src/features/work/features/work/features/carousel/features/move-seq/hooks/useForm';
import useWorkCarouselUpdateSeqMutation from '@front/src/features/work/features/work/features/carousel/features/move-seq/hooks/useMutation';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { FormProvider } from 'react-hook-form';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

interface Props extends WorkCarouselSectionProps {
  onClose: () => void;
}

export default function WorkCarouselMoveSeqFeature(props: Readonly<Props>) {
  const { menuId, dataId, sectionId, onClose, item } = props;
  const commonParams = {
    menuId,
    dataId,
    sectionId,
  };
  const { data: list } = workCarouselQuery.useListGet(item.id, commonParams);
  const { onUpdateSeq } = useWorkCarouselUpdateSeqMutation({
    menuId,
    dataId,
    sectionId,
    onClose,
  });
  const { fields, swap, onSubmit, methods } = useWorkCarouselMoveSeqForm({
    list,
    onUpdateSeq,
  });
  return (
    <>
      <WorkCarouselUpdateSeqLoadingSpinner />
      <form onSubmit={onSubmit}>
        <ModalBodyUI sx={{ maxHeight: '80dvh', minHeight: '60dvh', height: '100%' }}>
          <ModalHeaderUI>
            <ButtonBasicUI
              shape="tertiary"
              size="medium"
              onClick={onClose}
              sx={{
                width: '64px',
              }}
            >
              취소
            </ButtonBasicUI>
            <ButtonBasicUI
              type="submit"
              shape="primary2"
              size="medium"
              sx={{
                width: '64px',
              }}
            >
              저장
            </ButtonBasicUI>
          </ModalHeaderUI>
          <ModalContentUI sx={{ height: '100%', overflowY: 'hidden', alignItems: 'flex-start' }}>
            <AutoSizer disableWidth={true}>
              {({ height }) => (
                <TableContainerUI
                  sx={{
                    width: '100%',
                    height: `${height}px`,
                    overflowY: 'auto',
                  }}
                >
                  <TableTableUI>
                    <TableHeadUI>
                      <TableRowUI>
                        <TableCellUI
                          isHead
                          sx={{
                            width: '60px',
                          }}
                        >
                          순서
                        </TableCellUI>
                        <TableCellUI
                          isHead
                          sx={{
                            width: '568px',
                          }}
                        >
                          이미지
                        </TableCellUI>
                        <TableCellUI
                          isHead
                          sx={{
                            width: '100px',
                          }}
                        >
                          이동
                        </TableCellUI>
                      </TableRowUI>
                    </TableHeadUI>
                    <FormProvider {...methods}>
                      <TableBodyUI>
                        {fields.map((f, index) => (
                          <WorkCarouselMoveSeqTableRow
                            key={f.id}
                            index={index}
                            swap={swap}
                          />
                        ))}
                      </TableBodyUI>
                    </FormProvider>
                  </TableTableUI>
                </TableContainerUI>
              )}
            </AutoSizer>
          </ModalContentUI>
        </ModalBodyUI>
      </form>
    </>
  );
}
