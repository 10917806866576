import React, { useContext } from 'react';
import { ProjectSalesInfoDesignOutlineContext } from '@front/src/features/project-sales-info/features/design-outline/widgets/context';
import CircularProgress from '@front/components/CircularProgress';

export { FormWrapper as ProjectSalesInfoDesignOutlineFormWrapper };

interface Props {
  children: React.ReactNode;
}

const FormWrapper = ({ children }: Props) => {
  const {
    isLoading,
    d: { projectDesignOutlineData },
  } = useContext(ProjectSalesInfoDesignOutlineContext);
  if (isLoading || typeof projectDesignOutlineData === 'undefined') {
    return <CircularProgress size={30} />;
  }
  return <div>{children}</div>;
};
