import * as XLSX from 'xlsx';
import type { OstRewardManagementExcelData } from '@front/ost_reward/domian';

const excelFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const excelDownload = (
  excelData: OstRewardManagementExcelData[],
  columns: string[],
  excelFileName = '급여_내보내기'
) => {
  const ws = XLSX.utils.aoa_to_sheet([columns]);
  excelData.forEach((data) => {
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          data.employeeId,
          data.tmp1,
          data.tmp2,
          data.tmp3,
          data.tmp4,
          data.tmp5,
          data.tmp6,
          data.tmp7,
          data.tmp8,
          data.tmp9,
          data.tmp10,
          data.tmp11,
          data.tmp12,
          data.amount,
        ],
      ],
      { origin: -1 }
    );
    ws['!cols'] = new Array(49).fill(0).map(() => ({ wpx: 100 }));
  });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelFile = new Blob([excelButter], { type: excelFileType });
  const url = window.URL.createObjectURL(excelFile);
  const a = document.createElement('a');
  a.href = url;
  a.download = excelFileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 60000);
  a.remove();
};

export const createDataWithMap = (map: Map<string, number>) => {
  const list: OstRewardManagementExcelData[] = [];
  map.forEach((value, key) => {
    const tmp = {} as OstRewardManagementExcelData;
    tmp.employeeId = key;
    tmp.amount = value;
    list.push(tmp);
  });
  return list;
};
