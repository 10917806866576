import { createAction } from 'typesafe-actions';
import type {
  ProceedingsAccessVO,
  ProceedingsId,
  ProceedingsMemoVO,
  ProceedingsShortVO,
  ProceedingsVO,
} from './domain';
import type { ProceedingsMemoQuery, ProceedingsQuery } from './query';
import type {
  ProceedingsAddParameter,
  ProceedingsAttachedFileParameter,
  ProceedingsCommentParameter,
  ProceedingsMemoAddParameter,
  ProceedingsMemoChangeParameter,
  ProceedingsUpdateParameter,
} from './parameter';

import type { DepartmentVO } from '@front/department/domain';
import type { ApiStatus } from '@front/components/DataFieldProps';

export enum ProceedingsAction {
  setId = 'proceedings/id/set',
  setList = 'proceedings/list/set',
  setFilter = 'proceedings/filter/set',
  setMemoDrawerOpen = 'proceedings/drawer/set',
  toggleDrawer = 'proceedings/drawer/toggle',
  setListLoading = 'proceedings/list/loading/set',
  add = 'proceedings/add',
  setAddModal = 'proceedings/addModal/set',
  searchFilterOpen = 'proceedings/searchFilterOpen/set',
  requestDepartmentList = 'proceedings/departmentList/request',
  setDepartmentList = 'proceedings/departmentList/set',
  setDetail = 'proceedings/detail/set',
  addAttendanceModal = 'proceedings/attendance/add',
  addReaderModal = 'proceedings/reader/add',
  update = 'proceedings/update',
  delete = 'proceedings/delete',
  requestDelete = 'proceedings/request/delete',
  addMemo = 'proceedings/memo/add',
  setMemoFilter = 'proceedings/memo/filter/set',
  setMemoList = 'proceedings/memo/list/set',
  changeMemo = 'proceedings/memo/change',
  deleteMemo = 'proceedings/memo/delete',
  setMemoLoading = 'proceedings/memo/loading/set',
  addAttachedFile = 'proceedings/attached-file/add',
  deleteAttachedFile = 'proceedings/attached-file/delete',
  addComment = 'proceedings/comment/add',
  deleteComment = 'proceedings/comment/delete',
  updateComment = 'proceedings/comment/update',
  getAccess = 'proceedings/access/get',
  setAccess = 'proceedings/access/set',
  requestAccess = 'proceedings/access/request',
  requestAddMemo = 'proceedings/memo/request/add',
}

export const proceedingsAction = {
  setId: createAction(ProceedingsAction.setId)<ProceedingsId | undefined>(),
  toggleDrawer: createAction(ProceedingsAction.toggleDrawer)<boolean>(),
  setFilter: createAction(ProceedingsAction.setFilter)<ProceedingsQuery>(),
  setListLoading: createAction(ProceedingsAction.setListLoading)<boolean>(),
  setAddModal: createAction(ProceedingsAction.setAddModal)<boolean>(),
  add: createAction(ProceedingsAction.add)<ProceedingsAddParameter | undefined>(),
  setList: createAction(ProceedingsAction.setList)<ProceedingsShortVO[]>(),
  searchFilterOpen: createAction(ProceedingsAction.searchFilterOpen)<boolean>(),
  requestDepartmentList: createAction(ProceedingsAction.requestDepartmentList)(),
  setDepartmentList: createAction(ProceedingsAction.setDepartmentList)<DepartmentVO[]>(),
  setDetail: createAction(ProceedingsAction.setDetail)<ProceedingsVO | undefined>(),
  addAttendanceModal: createAction(ProceedingsAction.addAttendanceModal)<boolean>(),
  addReaderModal: createAction(ProceedingsAction.addReaderModal)<boolean>(),
  update: createAction(ProceedingsAction.update)<ProceedingsUpdateParameter | undefined>(),
  delete: createAction(ProceedingsAction.delete)<ProceedingsId | undefined>(),
  requestDelete: createAction(ProceedingsAction.requestDelete)<ApiStatus>(),
  setMemoFilter: createAction(ProceedingsAction.setMemoFilter)<ProceedingsMemoQuery>(),
  setMemoList: createAction(ProceedingsAction.setMemoList)<ProceedingsMemoVO[]>(),
  addMemo: createAction(ProceedingsAction.addMemo)<ProceedingsMemoAddParameter>(),
  changeMemo: createAction(ProceedingsAction.changeMemo)<ProceedingsMemoChangeParameter>(),
  deleteMemo: createAction(ProceedingsAction.deleteMemo)<ProceedingsId>(),
  setMemoLoading: createAction(ProceedingsAction.setMemoLoading)<boolean>(),
  setMemoDrawerOpen: createAction(ProceedingsAction.setMemoDrawerOpen)<boolean>(),
  addAttachedFile: createAction(
    ProceedingsAction.addAttachedFile
  )<ProceedingsAttachedFileParameter>(),
  deleteAttachedFile: createAction(ProceedingsAction.deleteAttachedFile)<ProceedingsId>(),
  addComment: createAction(ProceedingsAction.addComment)<ProceedingsCommentParameter | undefined>(),
  deleteComment: createAction(ProceedingsAction.deleteComment)<ProceedingsId | undefined>(),
  updateComment: createAction(ProceedingsAction.updateComment)<
    ProceedingsCommentParameter | undefined
  >(),
  getAccess: createAction(ProceedingsAction.getAccess)(),
  setAccess: createAction(ProceedingsAction.setAccess)<ProceedingsAccessVO>(),
  requestAccess: createAction(ProceedingsAction.requestAccess)<ApiStatus>(),
  requestAddMemo: createAction(ProceedingsAction.requestAddMemo)<ApiStatus>(),
};
