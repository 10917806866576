import useWorkOutputBundleCreate from '@front/src/features/work/features/work/features/output-bundle/repository/useCreate';
import useWorkOutputBundleUpdate from '@front/src/features/work/features/work/features/output-bundle/repository/useUpdate';

const useWorkOutputBundleMutation = ({ onClose, dataId, menuId, sectionId }) => {
  const params = {
    dataId,
    menuId,
    sectionId,
  };
  const { run: onCreate, setCallback: setCreateCallback } = useWorkOutputBundleCreate(params);
  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkOutputBundleUpdate(params);
  setCreateCallback({
    onSuccess: () => {
      onClose();
    },
  });
  setUpdateCallback({
    onSuccess: () => {
      onClose();
    },
  });
  return {
    onCreate,
    onUpdate,
  };
};

export default useWorkOutputBundleMutation;
