import { shallow } from 'zustand/shallow';
import { useEffect, useMemo } from 'react';
import { getTableRowNum } from '@front/src/features/accounting/utils';
import { useAccountingSettingsManagementAccountingReadState } from '@front/src/features/accounting/features/settings/features/management-accounting-read/useState';
import { accountingManagementAccountingReadRepository } from '@front/src/features/accounting/features/settings/features/management-accounting-read/repository/repository';

export { useLogic as useAccountingSettingsManagementAccountingReadListLogic };

const useLogic = (menuId) => {
  const { parentIdList, categoryMircoName } = useAccountingSettingsManagementAccountingReadState(
    (state) => ({
      parentIdList: state.parentIdList,
      categoryMircoName: state.categoryMircoName,
    }),
    shallow
  );
  const { data: list, remove: removeList } =
    accountingManagementAccountingReadRepository.useListGet(
      {
        parentIdList,
      },
      menuId
    );
  const { data: eAccountAndManualList, remove: removeCategoryMicroList } =
    accountingManagementAccountingReadRepository.useCategoryMicroListGet(
      {
        category: categoryMircoName,
      },
      menuId
    );
  const tableRowNum = useMemo(() => getTableRowNum(list), [list]);
  useEffect(() => {
    removeList();
    removeCategoryMicroList();
  }, [removeList, removeCategoryMicroList]);
  return {
    list,
    eAccountAndManualList,
    tableRowNum,
  };
};
