import { createContext } from 'react';
import type { SalesAccountingAmountInformationParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import { noOp } from '@front/src/utils';

export { Context as AccountingUploadSalesInfoGoalModalUpdateContext };

interface State {
  onUpdate: (params: SalesAccountingAmountInformationParameter) => void;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
