import React from 'react';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import { Box } from '@mui/material';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { ColorPalette } from '@front/assets/theme';
import useDialog from '@front/dialog/hook';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalStatus } from '@front/ost_proposal/domain';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
}

const AddArrangementCommentRow = ({ inputRef, addArrangementComment }: Props) => {
  const { error } = useDialog();
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);
  return (
    <Box
      sx={{
        display: 'inherit',
        width: '100%',
        gridArea: 'contents',
      }}
    >
      <Input
        variant="outlined"
        disabled={!(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION)}
        endAdornment={
          <Button
            disabled={!(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION)}
            onClick={() => {
              if (inputRef.current?.value.trim() === '') {
                error('내용을 입력해주세요.');
                inputRef.current.value = '';
              } else {
                if (inputRef.current) {
                  addArrangementComment({
                    content: inputRef.current.value,
                  });
                  inputRef.current.value = '';
                }
              }
            }}
          >
            등록
          </Button>
        }
        sx={{
          border: `1px solid ${ColorPalette._e4e9f2}`,
        }}
        multiline
        placeholder="제안 피드백을 작성 후 등록 버튼을 클릭 하세요."
        inputRef={inputRef}
      />
    </Box>
  );
};

export default AddArrangementCommentRow;
