import { createReducer } from 'typesafe-actions';
import { UserNotificationActionType } from 'user_notification/action';

export interface UserNotificationState {
  count: number;
}

const initial: UserNotificationState = {
  count: 0,
};

export const userNotificationReducer = createReducer(initial, {
  [UserNotificationActionType.setCount]: (state, action) => ({
    ...state,
    count: action.payload,
  }),
});
