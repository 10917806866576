import type { MenuView } from '@front/src/types';
import { create } from 'zustand';

interface State {
  tabList?: MenuView[];
  menuProps?: MenuView;
  menuData: MenuView[];
  open: boolean;
  noteId?: number;
  leftDrawerState: string;
  isRightDrawerOpen: boolean;
  menuId?: number;
  isThroughLink: boolean;
}

interface Actions {
  setTabList: (tabList: MenuView[]) => void;
  setMenuProps: (menuProps: MenuView) => void;
  setMenuData: (menuData: MenuView[]) => void;
  toggle: () => void;
  setNoteId: (noteId: number) => void;
  setLeftDrawerState: (leftDrawerState: string) => void;
  setIsRightDrawerOpen: (isRightDrawerOpen: boolean) => void;
  setMenuId: (noteId?: number) => void;
  setIsThroughLink: (isThroughLink: boolean) => void;
}

export { useState as useGlobalNavBarState };

const useState = create<State & Actions>((set) => ({
  setTabList: (tabList: MenuView[]) => set(() => ({ tabList })),
  setMenuProps: (menuProps: MenuView) =>
    set(() => ({
      menuProps,
    })),
  menuData: [],
  setMenuData: (menuData: MenuView[]) =>
    set(() => ({
      menuData,
    })),
  open: true,
  toggle: () => set(({ open }) => ({ open: !open })),
  leftDrawerState: '',
  isRightDrawerOpen: false,
  setLeftDrawerState: (leftDrawerState: string) =>
    set(() => ({
      leftDrawerState,
    })),
  setIsRightDrawerOpen: (isRightDrawerOpen: boolean) =>
    set(() => ({
      isRightDrawerOpen,
    })),
  noteId: 0,
  setNoteId: (noteId: number) => set(() => ({ noteId })),
  setMenuId: (menuId) => set(() => ({ menuId })),
  isThroughLink: false,
  setIsThroughLink: (isThroughLink: boolean) => set(() => ({ isThroughLink })),
}));
