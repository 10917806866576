import React from 'react';
import type { CheckboxProps } from '@mui/material/Checkbox';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';

interface Props extends CheckboxProps {
  label?: string;
  labelSx?: SxProps;
  sx?: SxProps;
}

export default function CheckboxBasicUI({
  disabled,
  checked,
  label,
  labelSx,
  sx = {},
  ...rest
}: Readonly<Props>) {
  return (
    <FormControlLabel
      control={
        <CheckboxStyled
          disabled={disabled}
          checked={checked}
          {...rest}
        />
      }
      label={label}
      sx={{
        margin: label ? 'inherit' : '0px',
        '& .MuiTypography-root': {
          fontSize: '1.4rem',
          color: disabled ? ColorPalette.greyscale.disabled : ColorPalette.greyscale.text_primary,
        },
        ...(labelSx ?? {}),
      }}
    />
  );
}

const CheckboxStyled = ({ sx = {}, ...rest }: Readonly<Props>) => (
  <Checkbox
    sx={{
      padding: '6px',

      '& .MuiSvgIcon-root': {
        fontSize: '20px',
        color: 'transparent',
        outline: `2px solid ${ColorPalette.line.line02}`,
        outlineOffset: '-3px',
        borderRadius: '4px',
      },

      '&.Mui-checked .MuiSvgIcon-root': {
        fontSize: '20px',
        color: ColorPalette.main.main_primary,
        outline: `2px solid ${ColorPalette.main.main_primary}`,
        outlineOffset: '-3px',
        borderRadius: '4px',
      },

      '&:hover': {
        '& .MuiSvgIcon-root': {
          outline: `2px solid ${ColorPalette.main.main_hover}`,
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          color: ColorPalette.main.main_hover,
        },
      },

      '&:active': {
        '& .MuiSvgIcon-root': {
          outline: `2px solid ${ColorPalette.main.main_hover}`,
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          color: ColorPalette.main.main_hover,
        },
      },

      '&.Mui-focusVisible .MuiSvgIcon-root': {
        outline: `2px solid ${ColorPalette.sub.sub_primary}`,
      },

      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          outline: `2px solid ${ColorPalette.greyscale.disabled}`,
          background: ColorPalette.greyscale.disabled,
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          color: ColorPalette.greyscale.disabled,
          background: 'transparent',
        },
      },
      ...sx,
    }}
    {...rest}
  />
);
