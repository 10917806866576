import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoEstimationEstimationTableBody } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { EstimationEstimationTable as ProjectSalesInfoEstimationEstimationTable };

const EstimationEstimationTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoEstimationEstimationTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px"> </Th>
      <Th width="80px">순번</Th>
      <Th width="88px">확정여부</Th>
      <Th width="120px">견적일자</Th>
      <Th width="100px">견적번호</Th>
      <Th width="200px">요청사</Th>
      <Th width="200px">의뢰처</Th>
      <Th width="100px">실험정보</Th>
      <Th width="160px">총액</Th>
      <Th width="120px">일정</Th>
      <Th width="200px">PDF파일</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoEstimationEstimationTableHeader';
