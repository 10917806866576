import {
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
} from '@front/src/utils';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const convertContractReviewDataForParameter = (data) => ({
  category: convertEmptyToNullForParameter(data.category),
  structureCompanyId: convertEmptyToNullForParameter(data.structureCompanyId),
  reviewAmount: convertEmptyToNullForAmountParameter(data.reviewAmount),
  reviewContractDate: formatDateOrNull(data.reviewContractDate),
  note: convertEmptyToNullForParameter(data.note),
});
