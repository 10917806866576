import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React from 'react';
import estimationComparisonMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/query/mutation';
import useUIBuilderTableHeadMutation from '@front/src/hooks/ui-builder-table/useTableHeadMutation';
import useUIBuilderTableHeadLogic from '@front/src/hooks/ui-builder-table/useTableHeadLogic';

export { TableHead as EstimationComparisonTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;
  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const { onAdd, onDelete } = useUIBuilderTableHeadMutation({
    onAdd: estimationComparisonMutation.useAdd(commonParams),
    onDelete: estimationComparisonMutation.useDelete(commonParams),
    commonParams,
    queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'comparison'],
  });

  const { itemList } = useUIBuilderTableHeadLogic({
    onAdd,
    onDelete,
  });

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
