import type { SxProps } from '@mui/system';
import type React from 'react';
import type { FormikContextType } from 'formik';
import type { DefaultFunction } from 'type/Function';

export type ApiStatus = 'idle' | 'request' | 'done' | string;

export function closeStatus(
  status: ApiStatus,
  done: DefaultFunction | undefined,
  close: DefaultFunction | undefined,
  fail?: DefaultFunction
) {
  if (status !== 'idle' && status !== 'request') {
    if (status === 'done') {
      if (done) {
        done();
      }
    } else if (fail) {
      fail();
    }
    if (close) {
      close();
    }
  }
}

export type Values = {
  [key: string]: any;
};

export enum FieldStatus {
  /** 정상 상태, 드래그 가능, 편집 가능, 필드 제공 */
  Idle,
  /** 잠금 모드, 드래그 불가, 편집 불가, 필드 제공 */
  Disabled,
  /** 읽기 모드, 드래그 가능, 편집 불가, 필드 제공 */
  ReadOnly,
}

export type DataFieldValue = string | number;

type SX = SxProps | undefined;

export interface LabelProps {
  label: React.ReactNode;
  disableLabel?: boolean;
  labelWidth?: number;
  labelPosition?: 'top' | 'left';
  labelSX?: SX;
}

export interface FieldProps extends LabelProps {
  name: string;
  status?: FieldStatus;
  helperText?: React.ReactNode;
  required?: boolean;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  formik?: FormikContextType<any>;
  autoSubmit?: boolean;
  backgroundColor?: string;
  border?: string;
}

export interface Option {
  key: DataFieldValue;
  text: DataFieldValue;
  disabled?: boolean;
  invisible?: boolean;
}

export function isDataFieldValue(value: any): value is DataFieldValue {
  return value === null || typeof value === 'string' || typeof value === 'number';
}

export function isOption(value: any): value is Option {
  return !isDataFieldValue(value) && isDataFieldValue((value as Option).key);
}

export function getValue<T = unknown>(values: Values, name: string): T | undefined {
  if (!values) {
    return undefined;
  }
  const key = name.includes('.') ? name.split('.')[0] : name;
  const value = values[key];
  if (!name.includes('.')) {
    return value;
  }
  return getValue(value, name.substring(key.length + 1));
}

export function isEmpty(value: unknown): boolean {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  if (typeof value === 'string') {
    return value === '' || value.trim() === '';
  }

  if (typeof value === 'object') {
    const keys = Object.keys(value);
    if (keys.length === 0) {
      return true;
    }
    return (
      keys
        // eslint-disable-next-line no-prototype-builtins
        .filter((key) => value.hasOwnProperty(key))
        .map((key) => value[key])
        .map((item) => isEmpty(item))
        .filter((flag) => !flag).length === 0
    );
  }
  // NaN
  return typeof value === 'number' && !value && value !== 0;
}
