import { SalesAccountingAmountType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import type { IAlertChildren } from '@front/src/features/dialog/types';

export const descriptionObjectFromProjectSalesManagementExpectModalForAlert: {
  [s in SalesAccountingAmountType]: IAlertChildren[];
} = {
  [SalesAccountingAmountType.ORDER]: [
    { value: '수주 예상' },
    { value: '금액 : 영업정보-견적탭-확정견적-총액(수주가능성=상만)' },
    { value: '시기 (월) : 영업정보-프로젝트구분-예상착수시기' },
  ],
  [SalesAccountingAmountType.REVIEW]: [
    { value: '구검 예상' },
    { value: '금액 : 영업정보-견적탭-확정견적-구검비(수주가능성 = 상만)' },
    { value: '시기 (월) : 영업정보-프로젝트구분-예상착수시기' },
  ],
  [SalesAccountingAmountType.NET_ORDER]: [
    { value: '순수주 예상' },
    { value: '금액 : 영업정보-견적탭-확정견적-풍동금액(수주가능성 = 상만)' },
    { value: ' 시기 (월): 영업정보-프로젝트구분-예상착수시기' },
  ],
  [SalesAccountingAmountType.COLLECTION]: [
    { value: '수금 예상' },
    {
      value:
        '금액 : 영업정보-수금탭-각기성단계-입금일이 없는 기성구분의 금액의 합(단, 수금가능성80%만)',
    },
    { value: '시기 (월) : 영업정보-수금탭-각기성단계-한풍예측일' },
  ],
  [SalesAccountingAmountType.SAVED_REVIEW]: [
    { value: '적립구검 예상' },
    {
      value:
        '금액 : 영업정보-수금탭-각기성단계-입금일이 없는 기성구분의 구검비의 합(단,수금가능성80%만)',
    },
    { value: '시기 (월) : 영업정보-수금탭-각기성단계-한풍예측일' },
  ],
  [SalesAccountingAmountType.NET_COLLECTION]: [
    { value: '순수금 예상' },
    {
      value:
        '금액 : 영업정보-수금탭-각기성단계-입금일이 없는 기성구분의 순수금액의 합(단,수금가능성80%만)',
    },
    { value: '시기 (월) : 영업정보-수금탭-각기성단계-한풍예정일' },
  ],
  [SalesAccountingAmountType.NET_ORDER_BALANCE]: [
    { value: '순수주잔고 예상' },
    { value: '금액 : 미수금 예상(8번) +응당예정금 예상(9번)+ 미착수금 예상(10번)' },
  ],
  [SalesAccountingAmountType.UNPAID_AMOUNT]: [
    { value: '미수금 예상' },
    {
      value: '금액 : 현재의 미수금 실적 - 조건1의 금액 + 조건2의 금액 + 조건3의 금액',
      sx: {},
    },
    {
      value:
        '조건 : 1. 영업정보-수금탭- 각기성단계에서 미수금사유1~5이고 한풍예측일이 예상 할려고하는 해당 월인 경우에 해당하는  수금가능성이 80%의 각 기성단계의 입금일이 없는 기성구분의 순금액의 합 (미수금 중에 수금될 것으로 예상하는 조건)',
      sx: {
        fontSize: '14px',
      },
    },
    {
      value:
        '2.영업정보-수금탭- 각기성단계에서 기성CASE가 ㄱ~ㅂ이면서 미수금 사유가 공백인 경우에 해당하고  한풍예측일이 예상 할려고하는 해당월인 경우이고 각 기성단계의 수금가능성이 50% 인 각 기성단계의입금일이 없는 기성구분의 순금액의 합 (응당예정금 중에 미수금으로 될 것으로 예상하는 조건)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value:
        '3. 영업정보-수금탭 -첫번째 기성단계에 (등록된 모든 행 중) 기성CASE가 N1에 해당하고 조건4의 날짜가 예상 할려고하는 해당월인 경우에 첫번째 기성단계의  수금가능성이 50%인  해당 기성단계의 입금일이 없는 기성구분의 순금액의 합 ( 미착수금중에 미수금으로 될 것으로 예상하는 조건)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value: '4. 영업정보-프로젝트 구분 탭 -설계도서 수급예정일',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
  ],
  [SalesAccountingAmountType.EXPECTED_AMOUNT]: [
    { value: '응당예정금 예상' },
    {
      value:
        '금액 : 현재의 응당예정금 실적 - 조건1의 금액 - 조건2의 금액 + 조건3의 금액 + 조건4의 금액',
    },
    {
      value:
        '조건 : 1. 영업정보-수금탭- 각 기성단계에서 기성CASE가 ㄱ~ㅂ이면서 미수금 사유가 공백인 경우에 해당하고  한풍예측일이 예상 할려고하는 해당월인 경우이고 각 기성단계의 수금가능성이 50% 인 각 기성단계의입금일이 없는 기성구분의 순금액의 합 (= 위 미수금 예상에서 조건2와 동일) (응당예정금 중에 미수금 될 것으로 예상하는 조건)',
      sx: {
        fontSize: '14px',
      },
    },
    {
      value:
        '2. 영업정보-수금탭- 각 기성단계에서 기성CASE가 ㄱ~ㅂ이면서 미수금 사유가 공백인 경우에 해당하고  한풍예측일이 예상 할려고하는 해당월인 경우이고 각 기성단계의 수금가능성이 80% 인 각 기성단계의 입금일이 없는 기성구분의 순금액의 합(붉은동그라미 6번) (응당예정금 중에 수금 될 것으로 예상하는 조건)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value:
        '3. 영업정보-수금탭 - 각 기성단계에서 기성CASE가 N1~N4에 해당하고 조건5의 날짜가 예상 할려고하는 해당월인 경우인 각 기성단계의입금일이 없는 기성구분의 순금액의 합 ( 미착수금중에 응당예정금으로 될 것으로 예상하는 조건)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value:
        '4. 예상할려고하는 해당월의 붉은동그라미3번 순수주 금액에 해당하는 프로젝트중에  조건5의 날짜가 예상할려고하는 해당월인 경우의 순수주 예상 금액의 30% (신규수주에 의한 응당예정금(계약금만) 추가 예상)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value: '5. 영업정보-프로젝트 구분 탭 -설계도서 수급 예정일',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
  ],
  [SalesAccountingAmountType.UNCLAIMED_AMOUNT]: [
    { value: '미착수금 예상' },
    {
      value:
        '금액 : 현재의 미착수금 실적 - 조건1의 금액 - 조건2의 금액 + 조건3의 금액 + 조건4의 금액',
    },
    {
      value:
        '조건 : 1. 영업정보-수금탭 - 첫번째 기성단계에서 기성CASE가 N1에 해당하고 조건4의 날짜가 예상 할려고하는 해당월인 경우에 첫번째 기성단계의  수금가능성이 50%인  해당 기성단계의입금일이 없는 기성구분의 순금액의 합 (= 미수금 예상의 조건3과 동일)( 미착수금중에 미수금으로 될 것으로 예상하는 조건)',
      sx: {
        fontSize: '14px',
      },
    },
    {
      value:
        '2. 영업정보-수금탭 - 각 기성단계에서 기성CASE가 N1~N4에 해당하고 조건4의 날짜가 예상 할려고하는 해당월인 경우인 각 기성단계의 입금일이 없는 기성구분의 순금액의 합 (= 응당예정금 예상의 조건3과 동일)( 미착수금중에 응당예정금으로 될 것으로 예상하는 조건)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value:
        '3. 예상할려고하는 해당월의 붉은동그라미3번 순수주 금액의 30% (신규수주에 의한 미착수금(계약금정도만) 추가 예상)',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
    {
      value: '4. 영업정보-프로젝트 구분 탭 -설계도서 수급예정일',
      sx: {
        margin: '0 0 0 36px',
        fontSize: '14px',
      },
    },
  ],
};
