import React, { useCallback, useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { ProjectSalesInfoAbstractCollectionListContext } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/provider/context';
import { filterUndefinedAndNull, getLocaleStringIfExist } from '@front/src/utils';

export { FirstRow as ProjectSalesInfoAbstractCollectionFirstRow };

const FirstRow = () => {
  const { list } = useContext(ProjectSalesInfoAbstractCollectionListContext);
  const getSumValues = useCallback(() => {
    let amountSum = 0;
    let netAmountSum = 0;
    let netCollectionAmountSum = 0;
    let netUnpaidAmountSum = 0;
    const depositDateList: string[] = [];
    const collectionForecastDateList: string[] = [];
    const collectionPossibilityRatioList: string[] = [];
    const contractEarningsCaseList: string[] = [];
    const unpaidReasonList: string[] = [];
    list?.forEach((l) => {
      amountSum += l.amount;
      netAmountSum += l.netAmount;
      netCollectionAmountSum += l.netCollectionAmount;
      netUnpaidAmountSum += l.netUnpaidAmount;
      depositDateList.push(l.depositDate);
      collectionForecastDateList.push(l.collectionForecastDate);
      collectionPossibilityRatioList.push(l.collectionPossibilityRatio?.toString());
      contractEarningsCaseList.push(l.contractEarningsCase?.[0]);
      unpaidReasonList.push(l.unpaidReason);
    });
    return {
      amountSum,
      netAmountSum,
      netCollectionAmountSum,
      netUnpaidAmountSum,
      depositDateList: Array.from(new Set(depositDateList)),
      collectionForecastDateList: Array.from(new Set(collectionForecastDateList)),
      collectionPossibilityRatioList: Array.from(new Set(collectionPossibilityRatioList)),
      contractEarningsCaseList: Array.from(new Set(contractEarningsCaseList)),
      unpaidReasonList: Array.from(new Set(unpaidReasonList)),
    };
  }, [list]);
  const getConvertedValues = useCallback(() => {
    const {
      amountSum,
      netCollectionAmountSum,
      netUnpaidAmountSum,
      netAmountSum,
      depositDateList,
      collectionForecastDateList,
      collectionPossibilityRatioList,
      contractEarningsCaseList,
      unpaidReasonList,
    } = getSumValues();
    return {
      amount: getLocaleStringIfExist(amountSum),
      netAmount: getLocaleStringIfExist(netAmountSum),
      netCollectionAmount: getLocaleStringIfExist(netCollectionAmountSum),
      netUnpaidAmount: getLocaleStringIfExist(netUnpaidAmountSum),
      depositDate: filterUndefinedAndNull(depositDateList).join(', ') || '-',
      collectionForecastDate: filterUndefinedAndNull(collectionForecastDateList).join(', ') || '-',
      collectionPossibilityRatio:
        filterUndefinedAndNull(collectionPossibilityRatioList).join(', ') || '-',
      contractEarningsCase: filterUndefinedAndNull(contractEarningsCaseList).join(', ') || '-',
      unpaidReason: filterUndefinedAndNull(unpaidReasonList).join(', ') || '-',
    };
  }, [getSumValues]);
  const {
    amount,
    netUnpaidAmount,
    netCollectionAmount,
    netAmount,
    collectionForecastDate,
    depositDate,
    contractEarningsCase,
    collectionPossibilityRatio,
    unpaidReason,
  } = getConvertedValues();
  return (
    <TableRow>
      <OldTd>합계</OldTd>
      <OldTd>{amount}</OldTd>
      <OldTd>{netAmount}</OldTd>
      <OldTd>{netCollectionAmount}</OldTd>
      <OldTd>{netUnpaidAmount}</OldTd>
      <OldTd>{depositDate}</OldTd>
      <OldTd>{collectionForecastDate}</OldTd>
      <OldTd>{collectionPossibilityRatio}</OldTd>
      <OldTd>{contractEarningsCase}</OldTd>
      <OldTd>{unpaidReason}</OldTd>
    </TableRow>
  );
};
