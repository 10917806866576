import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoContractReviewHistoryQuery } from '@front/src/features/project-sales-info/features/contract/features/review-history/query/query';
import { projectSalesInfoContractReviewHistoryMutation } from '@front/src/features/project-sales-info/features/contract/features/review-history/query/mutation';

export { repository as projectSalesInfoContractReviewHistoryRepository };

const repository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoContractReviewHistoryQuery.useListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const { mutate } = projectSalesInfoContractReviewHistoryMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
