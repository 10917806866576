import React, { useContext, useMemo } from 'react';
import { MyPageAuthorizationMenuContext } from '@front/src/features/my-page/features/authorization/widgets/menu/provider/context';
import { MyPageAuthorizationMenuCommonList } from '@front/src/features/my-page/features/authorization/widgets/menu/components/common-list';
import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { useShallow } from 'zustand/react/shallow';

export { TabList as MyPageAuthorizationMenuTabList };

const depth = 3;
const TabList = () => {
  const { list } = useContext(MyPageAuthorizationMenuContext);
  const { clicked, setTabId } = useMyPageAuthorizationState(
    useShallow((state) => ({
      clicked: state.clicked,
      setTabId: state.setTabId,
    }))
  );
  const tabList = useMemo(() => {
    if (!list) return [];
    if (clicked.length <= depth - 2) return [];
    const find = list.find((l) => l.id === clicked[0]);
    if (!find) return [];
    if (find.children.some((item) => item.isTab)) {
      return find.children.filter((c) => c.isTab);
    }
    const child = find.children.find((c) => c.id === clicked[1]);
    if (!child) return [];
    if (child.children.some((item) => item.isTab)) {
      return child.children.filter((c) => c.isTab);
    }
    const tab = child.children.find((c) => c.id === clicked[2]);
    if (!tab) return [];

    return tab.children.filter((c) => c.isTab);
  }, [list, clicked, setTabId]);
  return (
    <MyPageAuthorizationMenuCommonList
      list={tabList}
      depth={depth}
    />
  );
};
