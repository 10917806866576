import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoAbstractAffiliatedCompanyRow } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/widgets/list/components/row';
import { ProjectSalesInfoAbstractAffiliatedCompanyListContext } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/widgets/list/provider/context';

export { List as ProjectSalesInfoAbstractAffiliatedCompanyList };

const List = () => {
  const { list } = useContext(ProjectSalesInfoAbstractAffiliatedCompanyListContext);
  if (list?.length === 0) {
    return <NoResult colSpan={7} />;
  }
  return (
    <>
      {list?.map((item, index) => (
        <ProjectSalesInfoAbstractAffiliatedCompanyRow
          key={`${item.id}_${index}`}
          item={item}
        />
      ))}
    </>
  );
};
