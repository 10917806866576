import type { CheckboxFieldProps } from 'components/CheckboxField';
import CheckboxField from 'components/CheckboxField';
import type { Option } from 'components/DataFieldProps';
import React, { useEffect, useState } from 'react';
import { departmentApi } from 'department/api';

interface Props extends Omit<CheckboxFieldProps, 'options'> {
  menuId?: number | undefined;
}

export default function DepartmentCheckboxField(props: Props) {
  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    departmentApi
      .getList(props.menuId)
      .then((list) =>
        list.map(
          (item) =>
            ({
              key: item.id,
              text: item.name,
            } as Option)
        )
      )
      .then(setOptions);
  }, []);

  return (
    <CheckboxField
      {...props}
      options={options}
    />
  );
}
