import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectEstimationParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/parameter';
import { toFormData } from '@front/services/api';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as projectSalesInfoEstimationEstimationCreateModalApi };
const url = {
  create: (id?: number) => `${projectSalesInfoURL}/${id}/estimation`,
};

const api = {
  create: async (params: ProjectEstimationParameter, menuId) => {
    await axios.post(url.create(params.id), toFormData(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
