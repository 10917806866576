import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkDetailUpdate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workDetailMutation.useUpdate(commonParams);
  return {
    run: (params: WorkDetailUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkDetailUpdate;
