// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TyIpr5C1Dgyd8csfPeea{display:flex;gap:20px;margin-top:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/components/button-group.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 20px;\r\n  margin-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TyIpr5C1Dgyd8csfPeea`
};
export default ___CSS_LOADER_EXPORT___;
