import React, { useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table.module.scss';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/context';
import { familyTableHeadList } from '@front/src/features/affiliated-company/utils/constant';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';

export { FamilyInformationTable as AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTable };
const FamilyInformationTable = () => {
  const {
    d: { familyInformationList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext);
  const isFamilyUndefinedOrLoading = typeof familyInformationList === 'undefined' || isLoading;
  const isFamily = familyInformationList?.length !== 0;
  const loadingSpinner = (
    <div className={classes.no__result}>
      <CircularProgress size={30} />
    </div>
  );
  const noFamilyLetter = <NoResultBox message="저장된 가족 정보가 없습니다." />;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <Th className={classes.width__4}>{}</Th>
            {familyTableHeadList.map((head) => (
              <Th
                key={head}
                center
                className={classes.width__6}
              >
                {head}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFamilyUndefinedOrLoading &&
            isFamily &&
            familyInformationList?.map((item) => (
              <AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTableRow
                key={item.id}
                item={item}
              />
            ))}
        </TableBody>
      </Table>
      {isFamilyUndefinedOrLoading && loadingSpinner}
      {!isFamilyUndefinedOrLoading && !isFamily && noFamilyLetter}
    </TableContainer>
  );
};
