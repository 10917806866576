import type { ReactNode } from 'react';
import React, { useState } from 'react';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { ModalProps } from '@mui/material/Modal';
import Modal from '@mui/material/Modal';
import ModalUIPositionDropDown, {
  getSxFromModalPosition,
} from '@front/src/components/components-with-design/layout/modal/PositionDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';

export type ModalPositionType =
  | 'left'
  | 'top'
  | 'right'
  | 'bottom'
  | 'center'
  | 'fullscreen'
  | 'none';

interface Props extends Omit<ModalProps, 'onClose' | 'children'> {
  shape?: 'modal-default' | 'modal-full-screen';
  title: string;
  subTitle?: string;
  position?: ModalPositionType;
  onClose: () => void;
  children: ReactNode;
}

const componentObj = {
  'modal-default': ModalDefault,
  'modal-full-screen': ModalFullScreen,
};

export default function ModalUI({
  shape = 'modal-default',
  position = 'center',
  children,
  ...rest
}: Readonly<Props>) {
  const Component = componentObj[shape];
  return (
    <Component
      {...rest}
      position={position}
    >
      {children}
    </Component>
  );
}

interface ModalDefaultProps extends Omit<Props, 'shape' | 'position'> {
  position: ModalPositionType;
}

function ModalDefault({
  children,
  title,
  subTitle,
  position,
  onClose,
  sx = {},
  ...rest
}: Readonly<ModalDefaultProps>) {
  const [positionType, setPositionType] = useState<ModalPositionType>(position);
  // useEffect(() => {
  //   setPositionType(position);
  // }, []);
  return (
    <Modal
      className="modal-header"
      sx={{
        '& .MuiModal-backdrop': {
          background: ColorPalette.etc.modal_scrim,
        },
        ...sx,
      }}
      {...rest}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          minWidth: '400px',
          minHeight: '200px',
          maxHeight: '90%',
          borderRadius: '5px',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.40)',
          overflow: 'hidden',
          outline: 'none',
          ...getSxFromModalPosition(positionType),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '42px',
            background: ColorPalette.background.bg04,
            padding: '0px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.6rem',
              fontWeight: '600',
              lineHeight: '2.2rem',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                color: ColorPalette.greyscale.white,
              }}
            >
              {title}
            </Box>
            {subTitle && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '100',
                    color: ColorPalette.line.line02,
                  }}
                >
                  |
                </Box>
                <Box
                  sx={{
                    color: ColorPalette.sub.sub_primary,
                  }}
                >
                  {subTitle}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
          >
            <Box>
              <ModalUIPositionDropDown
                position={positionType}
                setPositionType={setPositionType}
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& span': {
                    color: '#a0a0a0 !important',
                  },
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            </Box>
            <Box
              onClick={onClose}
              sx={{
                fontSize: '2.0rem',
                color: ColorPalette.greyscale.white,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon="xmark" />
            </Box>
          </Box>
        </Box>
        {children}
      </Box>
    </Modal>
  );
}

interface ModalFullScreenProps extends Omit<Props, 'shape' | 'position'> {}

function ModalFullScreen({
  children,
  title,
  subTitle,
  onClose,
  ...rest
}: Readonly<ModalFullScreenProps>) {
  return (
    <Modal {...rest}>
      <Box
        sx={{
          position: 'fixed',
          width: '100dvw',
          height: '100dvh',
          outline: 'none',
        }}
      >
        <Box
          className="modal-header"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '50px',
            background: ColorPalette.background.bg,
            padding: '0px 15px 0px 10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.8rem',
              fontWeight: '600',
              lineHeight: '2rem',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                color: ColorPalette.greyscale.text_primary,
              }}
            >
              {title}
            </Box>
            {subTitle && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '100',
                    color: ColorPalette.line.line02,
                  }}
                >
                  |
                </Box>
                <Box
                  sx={{
                    color: ColorPalette.sub.sub_primary,
                  }}
                >
                  {subTitle}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              fontSize: '2.0rem',
              color: ColorPalette.greyscale.text_secondary,
              cursor: 'pointer',
            }}
          >
            <Box onClick={onClose}>
              <FontAwesomeIcon icon="xmark" />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: 'calc(100% - 50px)' }}>{children}</Box>
      </Box>
    </Modal>
  );
}

export function ModalBodyUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        background: ColorPalette.background.bg,
        padding: '20px 0px',
        gap: '14px',
        overflow: 'hidden',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function ModalContentUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: '1',
        padding: '0px 20px',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function ModalHeaderUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px 20px',
        gap: '10px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
