import React from 'react';

import classes from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form.module.scss';
import { AffiliatedCompanyMeetingHistoryModalFormInfo } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-info';
import { AffiliatedCompanyMeetingHistoryModalFormCompanion } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-companion';
import { AffiliatedCompanyMeetingHistoryModalFormParticipant } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-participant';
import { AffiliatedCompanyMeetingHistoryModalFormProject } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-project';
import { AffiliatedCompanyMeetingHistoryModalFormContent } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-content';
import { AffiliatedCompanyMeetingHistoryModalFormSummary } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-summary';
import { AffiliatedCompanyMeetingHistoryModalFormBusinessTrip } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-business-trip';

export { Form as AffiliatedCompanyMeetingHistoryModalForm };
const Form = () => (
  <div className={classes.section}>
    <AffiliatedCompanyMeetingHistoryModalFormInfo />
    <AffiliatedCompanyMeetingHistoryModalFormCompanion />
    <AffiliatedCompanyMeetingHistoryModalFormParticipant />
    <AffiliatedCompanyMeetingHistoryModalFormProject />
    <AffiliatedCompanyMeetingHistoryModalFormContent />
    <AffiliatedCompanyMeetingHistoryModalFormSummary />
    <AffiliatedCompanyMeetingHistoryModalFormBusinessTrip />
  </div>
);
