import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import React from 'react';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import Box from '@mui/material/Box';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import { useFormContext } from 'react-hook-form';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';

interface Props extends CellComponentProps<EstimationComparisonView> {}

/**
 * No.
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="seq"
  />
);

/**
 * 견적일자
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="estimationDate"
  />
);

/**
 * 협력사
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="affiliatedCompany"
  />
);

/**
 * 협력사인명
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanyPersonSelector
    {...props}
    companyName="affiliatedCompany"
    personName="affiliatedCompanyPerson"
  />
);

/**
 * 견적요청사
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="estimationRequestCompany"
  />
);

/**
 * 실험대상
 * @param rest
 * @constructor
 */
const CellRenderer6 = ({ ...rest }: Props) => (
  <UIBuilderTableCellInputText
    {...rest}
    name="testPlan"
  />
);

/**
 * 한풍기준총액차이
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="totalPriceDiff"
  />
);

/**
 * 대비견적총액
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="comparisonTotalPrice"
  />
);

/**
 * 풍동금액
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="windTunnelPrice"
  />
);

/**
 * 구검비
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewPrice"
  />
);

/**
 * 순동단가
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="pureUnitPrice"
  />
);

/**
 * 소요기간(주)
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => {
  const { onClick, sx, isEditMode, onSubmit, isForm, readOnly } = props;

  const { getValues } = useFormContext();
  const requiredWeeksStart = getValues('requiredWeeksStart');
  const requiredWeeksEnd = getValues('requiredWeeksEnd');

  const renderContent = () => {
    if (readOnly || !isEditMode) return `${requiredWeeksStart ?? '-'}/${requiredWeeksEnd ?? '-'}`;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UncontrolledInput
          type="number"
          name="requiredWeeksStart"
          onBlur={isForm ? undefined : onSubmit}
          readOnly={readOnly || !isEditMode}
        />
        /
        <UncontrolledInput
          type="number"
          name="requiredWeeksEnd"
          onBlur={isForm ? undefined : onSubmit}
          readOnly={readOnly || !isEditMode}
        />
      </Box>
    );
  };

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

/**
 * 견적서파일(PDF)
 * @param rest
 * @constructor
 */
const CellRenderer13 = (props: Props) => (
  <UIBuilderTableCellFileAttachment
    {...props}
    name="estimationDocument"
  />
);

/**
 * 담당자
 * @param rest
 * @constructor
 */
const CellRenderer14 = (props: Props) => (
  <UIBuilderTableCellUserSelector
    {...props}
    name="createdBy"
  />
);

/**
 * 등록일
 * @param rest
 * @constructor
 */
const CellRenderer15 = ({ ...rest }: Props) => (
  <UIBuilderTableCellInputDate
    {...rest}
    readOnly={true}
    name="createdAt"
  />
);

export const estimationComparisonTableCells: CellComponent = {
  342: CellRenderer1, // No.
  343: CellRenderer2, // 견적일자
  344: CellRenderer3, // 협력사
  345: CellRenderer4, // 협력사인명
  346: CellRenderer5, // 견적요청사
  347: CellRenderer6, // 실험대상
  348: CellRenderer7, // 한풍기준총액차이
  349: CellRenderer8, // 대비견적총액
  350: CellRenderer9, // 풍동금액
  351: CellRenderer10, // 구검비
  352: CellRenderer11, // 순동단가
  353: CellRenderer12, // 소요기간(주)
  354: CellRenderer13, // 견적서파일(PDF)
  355: CellRenderer14, // 담당자
  356: CellRenderer15, // 등록일
  ...generateSpareAttrCellRenders(424),
};
