import React from 'react';
import type { TableHeadProps } from '@mui/material/TableHead';
import TableHead from '@mui/material/TableHead';
import { ColorPalette } from '@front/assets/theme';

export default function TableHeadUI({ sx = {}, ...rest }: Readonly<TableHeadProps>) {
  return (
    <TableHead
      sx={{
        background: ColorPalette.background.bg03,
        '& th': {
          fontWeight: '500',
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
