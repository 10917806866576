import React from 'react';
import type { BadgeProps } from '@mui/material/Badge';
import Badge from '@mui/material/Badge';
import { ColorPalette } from '@front/assets/theme';

interface Props extends BadgeProps {}

export default function BadgeUI({ children, ...rest }: Readonly<Props>) {
  return (
    <Badge
      {...rest}
      sx={{
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
          fontSize: '2.4rem',
          color: ColorPalette.sub.sub_primary,
        },

        '& .MuiBadge-badge': {
          fontSize: '1.2rem',
          fontWeight: '700',
          lineHeight: 'normal',
          padding: '0.1rem 0.2rem',
          color: ColorPalette.greyscale.white,
          background: ColorPalette.sub2.sub2_primary,
        },
      }}
    >
      {children}
    </Badge>
  );
}
