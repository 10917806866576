import React, { Suspense, useEffect } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import NoteForm from '@front/src/features/note/components/Form';
import NoteCard from '@front/src/features/note/components/Card';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import NoteTitle from '@front/src/features/note/components/Title';
import NoteCardSkeleton from '@front/src/features/note/components/CardSkeleton';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import { useNoteMutationLoading } from '@front/src/features/note/hooks/useNoteMutationLoading';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import useGetNoteList from '@front/src/features/note/repository/useGetNoteList';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';

export default function NoteContent() {
  const onClose = () => {
    setIsRightDrawerOpen(false);
    resetCardState();
    setRowId(0);
    setSectionId(0);
    resetNoteState();
    setIsThroughLink(false);
  };
  const { setRowId, setSectionId, resetCardState, resetNoteState } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
      resetCardState: state.resetCardState,
      resetNoteState: state.resetNoteState,
    }))
  );
  const { setIsRightDrawerOpen, setIsThroughLink } = useGlobalNavBarState(
    useShallow((state) => ({
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
      noteId: state.noteId,
      setIsThroughLink: state.setIsThroughLink,
    }))
  );
  const isLoading = useNoteMutationLoading();

  return (
    <Box sx={{ ...SX_MODAL }}>
      <BackdropLoadingSpinnerUI
        open={isLoading}
        sx={{
          position: 'absolute',
        }}
      />
      <Box sx={{ ...SX_HEADER }}>
        <Suspense fallback={<LoadingSpinnerUI />}>
          <NoteTitle />
        </Suspense>
        <CloseIcon
          onClick={onClose}
          sx={{ ...SX_CLOSE }}
        />
      </Box>
      <Box>
        <Suspense fallback={<LoadingSpinnerUI height="306px" />}>
          <NoteForm />
        </Suspense>
      </Box>
      <Box sx={{ ...SX_NOTECARD_WRAP }}>
        <Suspense fallback={<NoteCardSkeleton />}>
          <NoteCardList />
        </Suspense>
      </Box>
    </Box>
  );
}

const NoteCardList = () => {
  const { searchList } = useGetNoteList();
  const { tempList, setTempList } = useNoteStore(
    useShallow((state) => ({
      tempList: state.tempList,
      setTempList: state.setTempList,
    }))
  );

  const { currentWidth, isResizeActive } = useListDrawerState(
    useShallow((state) => ({
      currentWidth: state.currentWidth,
      isResizeActive: state.isResizeActive,
    }))
  );

  const methods = useForm({
    values: { noteList: searchList },
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'noteList',
  });

  const newList = methods.watch('noteList');

  useEffect(() => {
    if (!isResizeActive) setTempList(newList ?? []);
  }, [newList, isResizeActive]);

  useEffect(() => {
    if (isResizeActive) methods.reset({ noteList: tempList });
  }, [tempList, isResizeActive]);

  if (fields.length === 0) return null;

  return (
    <FormProvider {...methods}>
      {fields?.map((item, index) => (
        <NoteCard
          key={item.id}
          index={index}
        />
      ))}
    </FormProvider>
  );
};

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: 'var(--design-v-10-light-background-bg-02, #EBEEF2)',
  boxShadow: '-20px 0px 10px rgba(0,0,0,0.15)',
  position: 'relative',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_CLOSE = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  width: '18px',
  height: '18px',
};

const SX_NOTECARD_WRAP = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: 'calc(100dvh - 348px)',
  overflow: 'auto',
  padding: '0 14px 14px 14px',
  backgroundColor: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
