import React from 'react';
import NotificationContent from '@front/src/features/notification/components/Content';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

function NotificationFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);
  return (
    <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'notification'}>
      <NotificationContent />
    </ResizableLeftDrawer>
  );
}

export default NotificationFeature;
