export interface CurrentPage {
  path: string;
  targetPage: TargetPage;
}

export enum TargetPage {
  VOTE = 'VOTE',
  PROPOSAL = 'PROPOSAL',
  EVALUATION = 'EVALUATION',
  ARRANGEMENT = 'ARRANGEMENT',
}
