import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import type { UserState } from 'user/reducer';
import { userReducer } from 'user/reducer';
import type { DepartmentState } from 'department/reducer';
import { departmentReducer } from 'department/reducer';
import type { PersonnelState } from 'personnel/reducer';
import { personnelReducer } from 'personnel/reducer';
import type { UserNotificationState } from 'user_notification/reducer';
import { userNotificationReducer } from 'user_notification/reducer';
import type { LoginState } from 'login/reducer';
import { loginReducer } from 'login/reducer';
import type { MenuState } from 'menu/reducer';
import { menuReducer } from 'menu/reducer';
import type { DialogState } from 'dialog/reducer';
import { dialogReducer } from 'dialog/reducer';
import type { ProgressState } from 'components/Progress/reducer';
import { progressReducer } from 'components/Progress/reducer';
import type { AddressState } from 'components/AddressModal/reducer';
import { addressReducer } from 'components/AddressModal/reducer';
import type { SnackbarState } from 'components/Snackbar/reducer';
import { snackbarReducer } from 'components/Snackbar/reducer';
import type { DepartmentTreeState } from 'department_tree/reducer';
import departmentTreeReducer from 'department_tree/reducer';
import type { ProposalState } from '@front/ost_proposal/reducer';
import { proposalReducer } from '@front/ost_proposal/reducer';
import type { ProceedingsState } from 'proceedings/reducer';
import { proceedingsReducer } from 'proceedings/reducer';
import type { CampaignState } from '@front/ost_campaign/reducer';
import { campaignReducer } from '@front/ost_campaign/reducer';
import type { EvaluationState } from '@front/ost_evaluation/reducer';
import { evaluationReducer } from '@front/ost_evaluation/reducer';
import type { VoteState } from '@front/ost_vote/reducer';
import { voteReducer } from '@front/ost_vote/reducer';
import type { NavigationState } from '@front/navigate/reducer';
import { navigationReducer } from '@front/navigate/reducer';
import type { OstState } from '@front/ost/reducer';
import { ostReducer } from '@front/ost/reducer';
import type { ArrangementState } from '@front/ost_arrangement/reducer';
import { arrangementReducer } from '@front/ost_arrangement/reducer';
import type { OstRewardMangementState } from '@front/ost_reward/reducer';
import { ostRewardManagementReducer } from '@front/ost_reward/reducer';
import {imageDrawReducer, ImageDrawState} from "@front/src/features/image-draw/reducer/reducer";

export interface RootState {
  department: DepartmentState;
  departmentTree: DepartmentTreeState;
  dialog: DialogState;
  login: LoginState;
  menu: MenuState;
  personnel: PersonnelState;
  user: UserState;
  userNotification: UserNotificationState;
  progress: ProgressState;
  snackbar: SnackbarState;
  address: AddressState;
  proposal: ProposalState;
  proceedings: ProceedingsState;
  campaign: CampaignState;
  evaluation: EvaluationState;
  vote: VoteState;
  navigation: NavigationState;
  ost: OstState;
  arrangement: ArrangementState;
  ostRewardMangement: OstRewardMangementState;
  imageDraw: ImageDrawState;
}

const reducer = combineReducers<RootState>({
  ost: ostReducer,
  department: departmentReducer,
  departmentTree: departmentTreeReducer,
  dialog: dialogReducer,
  login: loginReducer,
  menu: menuReducer,
  personnel: personnelReducer,
  user: userReducer,
  userNotification: userNotificationReducer,
  progress: progressReducer,
  snackbar: snackbarReducer,
  address: addressReducer,
  proposal: proposalReducer,
  proceedings: proceedingsReducer,
  campaign: campaignReducer,
  evaluation: evaluationReducer,
  vote: voteReducer,
  navigation: navigationReducer,
  arrangement: arrangementReducer,
  ostRewardMangement: ostRewardManagementReducer,
  imageDraw: imageDrawReducer
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === 'user/logout') {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

export default rootReducer;
