import React from 'react';
import type { ChipProps } from '@mui/material/Chip';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ColorPalette } from '@front/assets/theme';

interface Props extends ChipProps {
  readOnly?: boolean;
}

export default function ChipUI({ disabled, onDelete, readOnly = false, ...rest }: Readonly<Props>) {
  return (
    <Chip
      deleteIcon={
        <FontAwesomeIcon
          icon={faXmark}
          style={{
            fontSize: '1.4rem',
            color: ColorPalette.background.bg07,
            margin: '0',
          }}
        />
      }
      onDelete={readOnly ? undefined : onDelete}
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        padding: '6px 12px',
        fontSize: '1.4rem',
        fontWeight: '400',
        lineHeight: 'normal',
        color: ColorPalette.greyscale.text_primary,
        background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
        border: `1px solid ${ColorPalette.line.line02}`,
        gap: '4px',

        '& .MuiChip-label': {
          padding: '0px',
        },

        '&:hover': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '&:active': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '&:focus-visible': {
          background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
          outline: `2px solid ${ColorPalette.sub.sub_primary}`,
          outlineOffset: '-2px',
        },
      }}
      {...rest}
    />
  );
}
