import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import type { AffiliatedCompanyCharacterOpinionView } from '@front/src/features/affiliated-company/features/character/features/opinion/types/view';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { OpinionTableRowSelector as AffiliatedCompanyCharacterOpinionTableRowSelector };

interface Props {
  item: AffiliatedCompanyCharacterOpinionView;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

const OpinionTableRowSelector = ({ item, onSubmit }: Props) => {
  const { setValue } = useFormContext();
  const handleSelector = useCallback(
    async (company) => {
      setValue('affiliatedCompanyId', company?.id);
      await onSubmit();
    },
    [onSubmit, setValue]
  );
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      value={{
        companyId: item.competitor?.id ?? undefined,
        textValue: `${item.competitor?.name ?? ''}`,
      }}
      onCompanySelect={handleSelector}
      usePersonSelector={false}
      disabled={readOnly}
    />
  );
};
