import { create } from 'zustand';

export { useState as useAccountingUploadSalesInfoState };

interface State {
  year?: number;
  setYear: (year?: number) => void;
}

const useState = create<State>((set) => ({
  setYear: (year) =>
    set(() => ({
      year,
    })),
}));
