import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';

import { campaignAction } from '@front/ost_campaign/action';
import type { CampaignAddParameter } from '@front/ost_campaign/parameter';

import useDialog from '@front/dialog/hook';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import CampaignAddModal from '@front/ost_campaign/view/modal/AddCampaign';
import type { OstId } from '@front/ost/domain';
import { OstStatus } from '@front/ost/domain';
import type { RootState } from '@front/services/reducer';

interface Props {
  status?: OstStatus;
}

const ModalAddCampaignService = ({ status }: Props) => {
  const dispatch = useDispatch();
  const { error } = useDialog();
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useSelector((root: RootState) => ({ id: root.ost.id }), shallowEqual);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onSubmit = useCallback(
    (params: CampaignAddParameter) => {
      if (!params.name || !params.name.trim()) {
        error('캠페인명은 필수 항목입니다.');
        formik.resetForm();
        return;
      }
      params.ostId = id as OstId;
      onClose();
      dispatch(campaignAction.add(params));
      formik.resetForm();
    },
    [error, dispatch, onClose, id]
  );

  const formik = useFormik<CampaignAddParameter>({
    initialValues: {
      name: '',
    } as CampaignAddParameter,
    onSubmit: onSubmit,
  });

  const onKeyUp = useCallback(
    (e) => {
      const value = (e.target as HTMLInputElement).value || undefined;
      if (e.key.toLowerCase() === 'enter' && value) {
        formik.setFieldValue('name', value);
        onClose();
        formik.handleSubmit();
      }
    },
    [formik, onClose]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value || undefined;
      if (formik.values.name !== value) {
        formik.setFieldValue('name', value);
      }
    },
    [formik]
  );

  return (
    <>
      {isOpen && (
        <FormikProvider value={formik}>
          <CampaignAddModal
            open={isOpen}
            onClose={onClose}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
          />
        </FormikProvider>
      )}
      <Box sx={{ marginLeft: 'auto' }}>
        <Button
          onClick={onOpen}
          disabled={status !== OstStatus.ON_GOING}
        >
          신규 캠페인 등록
        </Button>
      </Box>
    </>
  );
};

export default ModalAddCampaignService;
