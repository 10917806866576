import { create } from 'zustand';

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
  id?: number;
  setId: (id?: number) => void;
}

export { useState as useProjectSalesInfoProjectDivisionModalState };
const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList: number[]) => set(() => ({ idList })),
  setId: (id?: number) => set(() => ({ id })),
}));
