import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/query/api';
import { useCustomDialog } from '@front/src/features/dialog';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyMutation = {
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi.delete(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
