import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import type { ProposalShortVO } from '@front/ost_proposal/domain';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import { dialogAction } from '@front/dialog/action';
import type { RootState } from '@front/services/reducer';
import { userNotificationAction } from '@front/user_notification/action';
import { evaluationAction } from '@front/ost_evaluation/action';
import { evaluationApi } from '@front/ost_evaluation/api';

import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

/**
 * 제안 목록 및 검색 관련 saga
 */
function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(evaluationAction.setFilter);
    yield put(evaluationAction.setLoading(true));
    try {
      const list: ProposalShortVO[] = yield call(evaluationApi.getProposalList, query);
      yield put(evaluationAction.setList(list));
    } catch (e) {
      const message = getErrorMessage(evaluationAction.setFilter, e);
      yield put(dialogAction.openError(message));
    } finally {
      yield delay(500);
      yield put(evaluationAction.setLoading(false));
    }
  }
}

function* watchAddMemo() {
  while (true) {
    const { payload: params } = yield take(evaluationAction.addMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.evaluation.memoFilter);
    try {
      yield call(evaluationApi.addMemo, id, params);
      // yield put(evaluationAction.setId(id));
      yield put(evaluationAction.setMemoFilter(memoFilter));
      if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
        yield put(userNotificationAction.requestCount());
      }
    } catch (e) {
      const message = getErrorMessage(evaluationAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchFilterMemo() {
  while (true) {
    const { payload: params } = yield take(evaluationAction.setMemoFilter);
    yield put(evaluationAction.setMemoLoading(true));
    const id = yield select((root: RootState) => root.proposal.id);
    try {
      const list = yield call(evaluationApi.getMemoList, id, params);
      yield put(evaluationAction.setMemoList(list));
    } catch (e) {
      const message = getErrorMessage(evaluationAction.setMemoFilter, e);
      const code = getErrorCode(evaluationAction.setMemoFilter, e);
      if (code === CustomHttpStatus.NO_AUTHORIZATION) {
        yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
        return;
      }
      yield put(dialogAction.openError(message));
    } finally {
      yield put(evaluationAction.setMemoLoading(false));
    }
  }
}

function* watchChangeMemo() {
  while (true) {
    const { payload: params } = yield take(evaluationAction.changeMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.evaluation.memoFilter);
    try {
      yield call(evaluationApi.changeMemo, id, params);
      yield put(evaluationAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(evaluationAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoDelete() {
  while (true) {
    const { payload: memoId } = yield take(evaluationAction.deleteMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.evaluation.memoFilter);
    try {
      yield call(evaluationApi.deleteMemo, id, memoId);
      yield put(evaluationAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(evaluationAction.deleteMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

export default function* evaluationSaga() {
  yield fork(watchChangeMemo);
  yield fork(watchAddMemo);
  yield fork(watchFilterMemo);
  yield fork(watchMemoDelete);
  yield fork(watchFilter);
}
