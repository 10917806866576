import { useQuery } from 'react-query';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';
import { useAffiliatedPersonDrawerState } from '@front/src/features/affiliated-person/features/drawer/useState';
import { useShallow } from 'zustand/react/shallow';

export { query as affiliatedPersonDrawerQuery };
const query = {
  useListGet: (menuId) => {
    const { keyword } = useAffiliatedPersonDrawerState(
      useShallow((state) => ({
        keyword: state.keyword,
      }))
    );
    const { data, remove } = useQuery({
      queryFn: () => affiliatedPersonApi.getList({ keyword: keyword || null }, menuId),
      queryKey: ['affiliated-person', 'drawer', 'list', keyword],
      select: (res) => res.data,
      suspense: true,
    });
    return { data, remove };
  },
};
