import { AddDeleteTableHeader } from '@front/src/components/layout/add-delete-table/header';
import { AddDeleteTableBody } from '@front/src/components/layout/add-delete-table/body';
import { AddDeleteTableLayout } from '@front/src/components/layout/add-delete-table/layout';
import { AddDeleteTableItemTableRow } from '@front/src/components/layout/add-delete-table/item-table-row';
import { AddDeleteTableItemTableCell } from '@front/src/components/layout/add-delete-table/item-table-cell';
import { AddDeleteTableText } from '@front/src/components/layout/add-delete-table/text';
import { AddDeleteTableHelp } from '@front/src/components/layout/add-delete-table/help';

export { AddDeleteTable };
const AddDeleteTable = {
  Layout: AddDeleteTableLayout,
  Header: AddDeleteTableHeader,
  Body: AddDeleteTableBody,
  ItemTableRow: AddDeleteTableItemTableRow,
  ItemTableCell: AddDeleteTableItemTableCell,
  Text: AddDeleteTableText,
  Help: AddDeleteTableHelp,
};
