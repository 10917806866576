import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { personalSettingsModalUISettingsHeaderSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/repository/repository';
import type { HeaderSettingUpdateType } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/types/parameter';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { useAlertModalState } from '@front/src/features/modal/features/alert-modal/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';

import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { SubmitSection as PersonalSettingsModalUISettingsHeaderSettingsSubmitSection };
interface Props {
  authMenuId: number | undefined;
  onClose: () => void;
}
const SubmitSection = ({ authMenuId, onClose }: Props) => {
  const { data: originalList } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useGetHeaderList(authMenuId);
  const { run: onUpdate, setCallback } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useUpdateHeader(authMenuId);
  const { setOpen, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const {
    setOpen: setAlertOpen,
    setTitle: setAlertTitle,
    setDescriptionList: setAlertDescriptionList,
  } = useAlertModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      setTitle: state.setTitle,
      setDescriptionList: state.setDescriptionList,
    }))
  );
  const { setHeader: setHistoryHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
      useShallow((state) => ({
        setHeader: state.setHeader,
      }))
    );
  const { setHeader: setPositionHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
      useShallow((state) => ({
        setHeader: state.setHeader,
      }))
    );
  const { clearEditOpenIdList } = usePersonalSettingsModalUISettingsHeaderSettingsState(
    useShallow((state) => ({
      clearEditOpenIdList: state.clearEditOpenIdList,
    }))
  );
  const {
    reset,
    formState: { isDirty },
    handleSubmit,
    getValues,
  } = useFormContext();
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const onSubmit = useCallback(
    (data) => {
      const list: HeaderSettingUpdateType[] = [];
      const seqList = data.list.map(({ id, version, headerVersion }, i) => ({
        id,
        version,
        headerVersion,
        seq: i + 1,
      }));
      for (const item of data.list) {
        const converted = convertItem(item, originalList);
        if (!converted) continue;
        list.push(converted);
      }
      onUpdate({
        list,
        seqList,
        sectionId,
      });
    },
    [onUpdate, originalList, setAlertOpen, sectionId]
  );
  const handleCancel = () => {
    setOpen(true, () => {
      reset();
      setHistoryHeader(undefined);
      setPositionHeader(undefined);
      clearEditOpenIdList();
      onClose();
    });
    setTitle('취소 확인');
    setDescriptionList(['저장되지 않은 변경사항은 반영되지 않습니다.', '모달을 닫으시겠습니까?']);
  };
  const handleSaveClick = () => {
    if (!getValues('list').every((l) => l.isPassed)) {
      setAlertOpen(true);
      setAlertTitle('중복 미확인 알림');
      setAlertDescriptionList(['변경될 헤더명의 중복 확인을 해주세요.']);
      return;
    }
    setTitle('저장 확인');
    setDescriptionList(['저장하면 되돌릴 수 없습니다.', '저장하시겠습니까?']);
    setOpen(true, handleSubmit(onSubmit));
  };
  setCallback({
    onSuccess: () => {
      reset();
      setHistoryHeader(undefined);
      setPositionHeader(undefined);
      clearEditOpenIdList();
    },
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <Box
        sx={{
          fontSize: '13px',
        }}
      >
        저장하기 않은 내용은 반영되지 않습니다.
      </Box>
      <Button
        shape="basic2"
        disabled={!isDirty}
        onClick={handleCancel}
      >
        취소
      </Button>
      <Button
        disabled={!isDirty}
        onClick={handleSaveClick}
      >
        저장
      </Button>
    </Box>
  );
};

const convertItem = (item, originalList) => {
  const copy = { ...item };
  const original = originalList?.find((o) => o.id === copy.id);
  if (!original) return undefined;
  const { note, newName, isBlind, isFixed, currentName, isFoldStartColumn } = copy;
  if (newName && newName !== currentName) {
    copy.name = newName;
  }
  if (note === (original.note ?? '')) delete copy.note;
  if (isBlind === original.isBlind) delete copy.isBlind;
  if (isFoldStartColumn === original.isFoldStartColumn) delete copy.isFoldStartColumn;
  if (isFixed === original.isFixed) delete copy.isFixed;
  if (
    ['name', 'note', 'isBlind', 'isFixed', 'isFoldStartColumn'].every(
      (key) => copy[key] === undefined
    )
  )
    return undefined;
  ['currentName', 'newName', 'use', 'seq', 'isPassed', 'isDuplicate'].forEach(
    (key) => delete copy[key]
  );
  return copy;
};
