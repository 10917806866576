import { changeViewport, isMobileDevice, ViewportMode } from '@front/util/PwaUtil';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useBreadCrumbState } from '@front/state/useState';
import { useCallback, useMemo } from 'react';
import { menuAction } from '@front/menu/action';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { MenuView } from '@front/src/types';
import { useShallow } from 'zustand/react/shallow';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';

interface Props {
  hasDrawer: boolean | null;
  path: string | null;
  children: MenuView[] | null;
  id: number;
}

export const useGnbLogic = ({ hasDrawer, path, children, id }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleMenu = useCallback(() => dispatch(menuAction.toggleMenu()), [dispatch]);
  const { setIsList, setIsDetails, setIsMemo, setHasDrawer } = useMobileMenuState(
    useShallow((state) => ({
      setIsList: state.setIsList,
      setIsDetails: state.setIsDetails,
      setIsMemo: state.setIsMemo,
      setHasDrawer: state.setHasDrawer,
    }))
  );
  const { setListPath, setViewportMode } = useBreadCrumbState(
    useShallow((state) => ({
      setListPath: state.setListPath,
      setViewportMode: state.setViewportMode,
    }))
  );
  const { setRowId, setSectionId, resetCardState } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
      resetCardState: state.resetCardState,
    }))
  );
  const { setIsRightDrawerOpen, setIsThroughLink } = useGlobalNavBarState(
    useShallow((state) => ({
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
      setIsThroughLink: state.setIsThroughLink,
    }))
  );
  const { pathname } = useLocation();
  const active = useMemo(() => path && pathname.startsWith(path), [path, pathname]);
  const hasChild = useMemo(
    () => children && children?.every((item) => item.depth !== null),
    [children]
  );
  const clickable = useMemo(
    () => typeof path === 'string' && (!children || children?.some((item) => item.depth === null)),
    [path, children]
  );
  const saveMenuId = () => {
    const find =
      Array.isArray(children) &&
      children.find((c) => c.seq === Math.min(...children.map((c) => c.seq)));
    if (!find) {
      sessionStorage.setItem('menuId', id.toString());
      return;
    }
    sessionStorage.setItem('menuId', find.id.toString());
  };
  const navigateByHasDrawerAndChildren = useCallback(() => {
    if (hasDrawer || !children) {
      navigate(path!);
      return;
    }
    const find = children.find((c) => c.seq === Math.min(...children.map((c) => c.seq)));
    if (!find?.path) return;
    navigate(find.path);
  }, [hasDrawer, children, path, navigate]);
  const doIfHasDrawer = useCallback(() => {
    if (hasDrawer) {
      setIsList(true);
      setListPath(path ?? '');
      setHasDrawer(true);
      return;
    }
    setViewportMode(changeViewport(ViewportMode.DESKTOP));
    setIsDetails(true);
    setIsMemo(false);
    setHasDrawer(false);
  }, [
    hasDrawer,
    setIsList,
    setIsDetails,
    setIsMemo,
    setHasDrawer,
    setListPath,
    setViewportMode,
    path,
  ]);
  const onClick = useCallback(() => {
    if (!clickable) return;
    // PWA NAVIGATION UX IMPROVEMENT
    isMobileDevice() && toggleMenu();
    navigateByHasDrawerAndChildren();
    doIfHasDrawer();
    saveMenuId();
    setIsRightDrawerOpen(false);
    setIsThroughLink(false);
    resetCardState();
    setRowId(0);
    setSectionId(0);
  }, [clickable, toggleMenu, doIfHasDrawer, navigateByHasDrawerAndChildren]);
  return {
    onClick,
    clickable,
    active,
    hasChild,
  };
};
