import React, { useCallback, useContext } from 'react';
import { Box } from '@mui/material';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Button from '@front/layouts/Button';
import { ColorPalette } from '@front/assets/theme';
import { AffiliatedPersonMemoListContext } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/context';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';
import { useShallow } from 'zustand/react/shallow';

export { UpdateForm as AffiliatedPersonMemoListUpdateForm };

const UpdateForm = () => {
  const { updateWidget } = useContext(AffiliatedPersonMemoListContext);
  const { setId: setMemoId } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      setId: state.setId,
    }))
  );
  const handleCancelClick = useCallback(() => {
    setMemoId(undefined);
  }, [setMemoId]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '12px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          marginBottom: '5px',
          border: `1px solid ${ColorPalette._e4e9f2}`,
        }}
      >
        <HookFormInput
          multiline
          name="description"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          gap: '8px',
        }}
      >
        {updateWidget}
        <Button
          shape="basic2"
          onClick={handleCancelClick}
        >
          취소
        </Button>
      </Box>
    </Box>
  );
};
