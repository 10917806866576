export const enum ProjectContribution {
  RECOMMENDATION = 'RECOMMENDATION',
  INTRODUCTION = 'INTRODUCTION',
  INQUIRY = 'INQUIRY',
  AMOUNT_NEGOTIATION = 'AMOUNT_NEGOTIATION',
  ESTIMATE_COMPARED = 'ESTIMATE_COMPARED',
  AMOUNT_OPEN = 'AMOUNT_OPEN',
}

export const enum AffiliatedCompanyStatus {
  NORMAL = 'NORMAL',
}
