import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import { ManageableVariableIsUsed } from '@front/src/features/manageable-variable/types/domain';

export const getOptionListFromVariableList = (list?: VariableInformationView[]) => {
  if (typeof list === 'undefined') {
    return [];
  }
  return list.map((item) => ({
    label: item.label,
    value: item.value,
  }));
};

export const convertManageableVariableToBooleanForParameter = (value) => {
  if (typeof value !== 'boolean' && value === '') {
    return value;
  }
  return value === ManageableVariableIsUsed.USED;
};
