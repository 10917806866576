import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { useProjectSalesInfoEstimationCompetitorCreateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/widgets/useState';
import { projectSalesInfoEstimationCompetitorCreateModalRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/create-modal/repository';

export { useLogic as useProjectSalesInfoEstimationCompetitorCreateModalLogic };

const useLogic = (menuId) => {
  const { isOpen, setIsOpen } = useProjectSalesInfoEstimationCompetitorCreateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { run: onCreate, setCallback } =
    projectSalesInfoEstimationCompetitorCreateModalRepository.useCreate(menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const h = { onCreate, onClose: handleClose };
  return {
    h,
    isOpen,
  };
};
