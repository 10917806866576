import { useState } from 'react';

const useModalOpen = () => {
  const [open, setOpen] = useState<boolean>(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const toggle = () => setOpen(!open);
  return {
    open,
    onOpen,
    onClose,
    toggle,
  };
};

export default useModalOpen;
