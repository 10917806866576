import workInterimReportDateMutation from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/query/mutation';

const useWorkScheduleInterimReportDateMutation = ({ menuId, sectionId, dataId }) => {
  const params = {
    menuId,
    sectionId,
    dataId,
  };
  const { mutate: onCreate } = workInterimReportDateMutation.useCreate(params);
  const { mutate: onUpdate } = workInterimReportDateMutation.useUpdate(params);
  const { mutate: onDelete } = workInterimReportDateMutation.useDelete(params);

  return {
    onCreate,
    onUpdate,
    onDelete,
  };
};

export default useWorkScheduleInterimReportDateMutation;
