import React from 'react';
import { Badge, Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import type { UseFieldArrayRemove } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import UserSelectorUserIcon from '@front/src/features/user-selector/components/UserIcon';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  fields: Record<'id', string>[];
  remove: UseFieldArrayRemove;
}

export default function UserSelectorSelectedList({ fields, remove }: Readonly<Props>) {
  const { getValues } = useFormContext();

  const handleClick = (index) => {
    remove(index);
  };

  if (fields.length === 0) {
    return <TextBox variant="body2">선택된 유저 없음</TextBox>;
  }

  return (
    <>
      {fields.map((f, i) => {
        const item = getValues(`selectedList.${i}`);
        return (
          <Box
            key={f.id}
            onClick={() => handleClick(i)}
            sx={{
              display: 'flex',
              gap: '10px',
              width: '100%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent="X"
              color="error"
              overlap="rectangular"
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: '0.6rem',
                  height: '0.7rem',
                  minWidth: '0.7rem',
                  padding: '0.3rem',
                },
              }}
            >
              <UserSelectorUserIcon user={item} />
            </Badge>
            <TextBox
              variant="body2"
              sx={{
                marginRight: '10px',
              }}
            >
              {item?.name}
            </TextBox>
          </Box>
        );
      })}
    </>
  );
}
