import { ColorPalette } from '@front/assets/theme';
import Box from '@mui/material/Box';
import React from 'react';

export default function WorkCarouselNoImageCard({ children }: { children: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        backgroundColor: ColorPalette.background.bg02,
      }}
    >
      {children}
    </Box>
  );
}
