// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AikCoUoP3Y08jsWxbBti{display:flex;gap:5px;width:200px;min-width:100px;justify-content:right;align-items:center}`, "",{"version":3,"sources":["webpack://./front/src/features/mobile-notification/widgets/header/components/mobile-notification-btn-group.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,WAAA,CACA,eAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 5px;\r\n  width: 200px;\r\n  min-width: 100px;\r\n  justify-content: right;\r\n  align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AikCoUoP3Y08jsWxbBti`
};
export default ___CSS_LOADER_EXPORT___;
