import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@mui/material/styles';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

type TextTipProps = {
  children: ReactNode;
  sx?: SxProps;
};

const Element = styled(Box)({
  borderRadius: '5px',
  backgroundColor: `${ColorPalette._e4e9f2}`,
  fontSize: '13px',
  marginBottom: 'auto',
  padding: '5px 10px',
  width: 'max-content',
});

export default function TextTip(props: TextTipProps) {
  const { children, sx } = props;
  return (
    <>
      <Element sx={sx}>{children}</Element>
    </>
  );
}
