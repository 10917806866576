import React, { Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { GlobalNavBarList } from '@front/src/features/global-nav-bar/widgets/list/components/global-nav-bar-list';

export { Widget as GlobalNavBarListWidget };

const Widget = () => (
  <Suspense
    fallback={
      <LoadingSpinner
        width="100%"
        height="100%"
      />
    }
  >
    <GlobalNavBarList />
  </Suspense>
);
