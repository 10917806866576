import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { AxiosError } from 'axios';
import type { WorkCarouselSeqUpdateParameter } from '@front/src/features/work/features/work/features/carousel/features/move-seq/types/parameter';

const workCarouselUpdateSqeMutation = {
  useUpdateSeq: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, AxiosError, WorkCarouselSeqUpdateParameter>({
      mutationFn: (params) => workWorkApi.updateWorkCarouselSeq(params, commonParams),
      mutationKey: ['work', 'carousel', 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workCarouselUpdateSqeMutation;
