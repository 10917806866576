import { useForm } from 'react-hook-form';
import { YYYY_MM_DD } from '@front/src/utils';
import dayjs from 'dayjs';
import { useEffect } from 'react';

const useWorkScheduleForm = ({ detail, onUpdate }) => {
  const methods = useForm({
    defaultValues: {
      instructionDate: '',
      instructionDateNote: '',
      startDate: '',
      startDateNote: '',
      finalReportDate: '',
      finalReportDateNote: '',
      dueDate: '',
      dueDateNote: '',
      completionDate: '',
      completionDateNote: '',
      nextExecutionDate: '',
      nextExecutionDateNote: '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      instructionDate: data.instructionDate && dayjs(data.instructionDate).format(YYYY_MM_DD),
      instructionDateNote: data.instructionDateNote || null,
      startDate: data.startDate && dayjs(data.startDate).format(YYYY_MM_DD),
      startDateNote: data.startDateNote || null,
      finalReportDate: data.finalReportDate && dayjs(data.finalReportDate).format(YYYY_MM_DD),
      finalReportDateNote: data.finalReportDateNote || null,
      dueDate: data.dueDate && dayjs(data.dueDate).format(YYYY_MM_DD),
      dueDateNote: data.dueDateNote || null,
      completionDate: data.completionDate && dayjs(data.completionDate).format(YYYY_MM_DD),
      completionDateNote: data.completionDateNote || null,
      nextExecutionDate: data.nextExecutionDate && dayjs(data.nextExecutionDate).format(YYYY_MM_DD),
      nextExecutionDateNote: data.nextExecutionDateNote || null,
    });
  });
  useEffect(() => {
    if (detail) {
      methods.reset({
        ...getFormValues(detail),
      });
    }
  }, [detail]);
  return {
    methods,
    onSubmit,
  };
};

export default useWorkScheduleForm;

const getFormValues = (detail) => ({
  instructionDate: detail?.instructionDate ?? '',
  instructionDateNote: detail?.instructionDateNote ?? '',
  startDate: detail?.startDate ?? '',
  startDateNote: detail?.startDateNote ?? '',
  finalReportDate: detail?.finalReportDate ?? '',
  finalReportDateNote: detail?.finalReportDateNote ?? '',
  dueDate: detail?.dueDate ?? '',
  dueDateNote: detail?.dueDateNote ?? '',
  completionDate: detail?.completionDate ?? '',
  completionDateNote: detail?.completionDateNote ?? '',
  nextExecutionDate: detail?.nextExecutionDate ?? '',
  nextExecutionDateNote: detail?.nextExecutionDateNote ?? '',
});
