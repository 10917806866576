import { useQuery } from 'react-query';
import type { SalesAccountingAmountInformationGetParameter } from '@front/src/features/accounting/features/upload/features/sales-info/types/parameter';
import type {
  SalesAccountingAmountInformationExpectPerformanceView,
  SalesAccountingAmountInformationView,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/view';
import { AccountingUploadSalesInfoApi } from '@front/src/features/accounting/features/upload/features/sales-info/query/api';

export { Query as AccountingUploadSalesInfoPerformanceModalQuery };

const Query = {
  useGetList: (params: SalesAccountingAmountInformationGetParameter, menuId) => {
    const { data, remove } = useQuery<SalesAccountingAmountInformationView[]>({
      queryFn: () => AccountingUploadSalesInfoApi.getList(params, menuId),
      queryKey: [
        'project',
        'sales-management',
        'sales-target-expect',
        'performance-modal',
        'list',
        params,
      ],
      suspense: true,
      enabled: !!params.year && !!params.subType,
    });
    return {
      data,
      remove,
    };
  },
  useExpectOrPerformanceListGet: (params: SalesAccountingAmountInformationGetParameter, menuId) => {
    const { data, remove } = useQuery<SalesAccountingAmountInformationExpectPerformanceView[]>({
      queryFn: () => AccountingUploadSalesInfoApi.getExpectOrPerformanceList(params, menuId),
      queryKey: [
        'project',
        'sales-management',
        'sales-target-expect',
        'performance-modal',
        'expect-list',
        params,
      ],
      suspense: true,
      enabled: !!params.year && !!params.month && !!params.subType,
    });
    return {
      data,
      remove,
    };
  },
};
