import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';

import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createCMPaymentHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-payment-history`,
};

export const projectSalesInfoCMPaymentHistoryCreateModalApi = {
  createCMPaymentHistory: async (id: number, params: ProjectCMPaymentHistoryParams, menuId) => {
    const { data } = await axios.post(
      url.createCMPaymentHistory(id),
      {
        ...params,
        companyId: params.companyId,
      },
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
    return data;
  },
};
