import type { AffiliatedCompanySearchParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanySelectorQuery } from '@front/src/features/affiliated-company-selector/query/query';

export const affiliatedCompanySelectorRepository = {
  useListGet: (params: AffiliatedCompanySearchParameter, enabled: boolean, isCompany: boolean) => {
    const { data, isLoading } = isCompany
      ? affiliatedCompanySelectorQuery.useCompanyListGet(params, enabled)
      : affiliatedCompanySelectorQuery.useListGet(params, enabled);
    return {
      data,
      isLoading,
    };
  },
  usePersonGet: (id?: number, usePersonSelector?: boolean) => {
    const { data, isLoading } = affiliatedCompanySelectorQuery.usePersonGet(id, usePersonSelector);
    return {
      data,
      isLoading,
    };
  },
};
