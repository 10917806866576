import { createContext } from 'react';
import type { ProjectExperimentTargetByComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/types/view';

interface State {
  d: {
    detail?: ProjectExperimentTargetByComplexInformationView;
  };
}

export { context as ProjectSalesInfoSubjectReviewExperimentContext };

const context = createContext<State>({
  d: {},
});
