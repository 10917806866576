import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import { YNOptionList } from '@front/src/features/affiliated-company/utils/constant';
import { getYesOrNo, spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import type { AffiliatedCompanyCharacterPartnerCompanyView } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/view';
import { AffiliatedCompanyCharacterPartnerCompanyContext } from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/context';
import type { AffiliatedCompanyCharacterPartnerCompanyUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/parameter';
import { AffiliatedCompanyCharacterPartnerCompanyTableRowSelector } from '@front/src/features/affiliated-company/features/character/features/partner-company/components/partner-company-table-row-selector';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { PartnerCompanyTableRow as AffiliatedCompanyCharacterPartnerCompanyTableRow };

interface Props {
  item: AffiliatedCompanyCharacterPartnerCompanyView;
  index: number;
}

const getValues = (item) => ({
  id: item.id ?? undefined,
  affiliatedCompanyId: item.partnerCompanyId ?? undefined,
  affiliatedCompanyPersonId: item.partnerCompanyPerson?.id ?? undefined,
  relationLevel: item.relationLevel ?? '',
  isKeyPartner: getYesOrNo(item.isKeyPartner),
  note: item.note ?? '',
});
const PartnerCompanyTableRow = ({ item }: Props) => {
  const {
    h: { setIdList, onPartnerCompanyUpdate },
    idList,
  } = useContext(AffiliatedCompanyCharacterPartnerCompanyContext);
  const methods = useForm<AffiliatedCompanyCharacterPartnerCompanyUpdateParameter>({
    values: getValues(item),
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onPartnerCompanyUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>
          <Checkbox
            checked={idList.includes(item.id)}
            onChange={handleCheckboxChange(item.id)}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <AffiliatedCompanyCharacterPartnerCompanyTableRowSelector
            item={item}
            onSubmit={onSubmit}
          />
        </OldTd>
        <OldTd>{item.partnerCompanyPerson?.name ?? '-'}</OldTd>
        <OldTd>
          <HookFormInput
            name="relationLevel"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <TableCell>
          <HookFormSelect
            defaultLabel="선택"
            name="isKeyPartner"
            optionList={YNOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </TableCell>
        <OldTd>
          <HookFormInput
            name="note"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
