import { useQuery } from 'react-query';
import { projectSalesInfoBidResultApi } from '@front/src/features/project-sales-info/features/bid/features/bid-result/query/api';

export const projectSalesInfoBidResultQuery = {
  useProjectSalesInfoBidResultListGet: (id: number, menuId) => {
    const { data, isLoading } = useQuery({
      queryKey: ['project-sales-info', 'bid-result', 'list', id],
      queryFn: () => projectSalesInfoBidResultApi.getBidResultList(id, menuId),
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
