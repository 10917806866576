import { useCustomDialog } from '@front/src/features/dialog';
import { useQuery } from 'react-query';
import { drawingApi } from '@front/src/features/image-draw/api/api';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

interface Props {
  fileKey?: string | undefined;
}

const useFileItemDownload = (props: Props) => {
  const { fileKey } = props;

  const [enabled, setEnabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { alert } = useCustomDialog();

  const handleDownload = () => {
    if (!fileKey) {
      enqueueSnackbar('다운로드할 파일이 없습니다.', { variant: 'warning' });
      setEnabled(false);
      return;
    }
    setEnabled(true);
  };

  useQuery({
    queryFn: () => drawingApi.getFileItemDataByFileKey(fileKey),
    queryKey: ['file-item', fileKey],
    useErrorBoundary: false,
    enabled: !!fileKey && enabled,

    onSuccess: ({ id }) => {
      window.location.assign(`/api/file-item/${id}`);
    },

    onError: (error: any) => {
      const { message } = error.response.data;
      alert({
        children: message.map((m) => ({
          value: m,
        })),
      });
    },
    onSettled: () => {
      setEnabled(false);
    },
  });

  return {
    onDownload: handleDownload,
  };
};

export default useFileItemDownload;
