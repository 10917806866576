import React, { useCallback, useContext, useEffect } from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import {
  calcUnitPrice,
  convertAmountToStringForForm,
  convertBooleanToYNIfExistForForm,
  convertDateToStringFormat,
  convertNullToEmptyForForm,
  convertYNToBooleanForParameter,
  DATE_TIME_FORMAT,
  getValueIfExist,
  YNOptionList,
} from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { ProjectSalesInfoContractContractUpdateModalContext } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/widgets/context';
import { ProjectSalesInfoContractContractUpdateModalButtonGroup } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/components/modal-button-group';
import type { ProjectContractContractView } from '@front/src/features/project-sales-info/features/contract/features/contract/types/view';
import { ProjectSalesInfoExperimentAmountHookForm } from '@front/src/features/project-sales-info/components/experiment-amount-hook-form';
import { ProjectSalesInfoReviewAmountHookForm } from '@front/src/features/project-sales-info/components/review-amount-hook-form';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { HookFormCompanySelector } from '@front/src/components/company-selector-with-hook-form';
import { ProjectSalesInfoContractContractModalEstimationSelect } from '@front/src/features/project-sales-info/features/contract/features/contract/components/modal-estimation-select';
import RequiredMark from '@front/layouts/RequiredMark';
import { ProjectSalesInfoContractContractContext } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/context';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalTable as ProjectSalesInfoContractContractUpdateModalTable };

const getValuesForForm = (detail?: ProjectContractContractView) => ({
  isConfirmed: convertBooleanToYNIfExistForForm(detail?.isConfirmed),
  orderIntakeDate: convertNullToEmptyForForm(detail?.orderIntakeDate),
  contractDate: convertNullToEmptyForForm(detail?.contractDate),
  category: convertNullToEmptyForForm(detail?.category),
  estimationId: convertNullToEmptyForForm(detail?.estimationId),
  complexName: convertNullToEmptyForForm(detail?.complexName),
  experimentInformation: convertNullToEmptyForForm(detail?.experimentInformation),
  experimentAmount: convertAmountToStringForForm(detail?.experimentAmount),
  reviewAmount: convertAmountToStringForForm(detail?.reviewAmount),
  totalAmount: convertAmountToStringForForm(detail?.totalAmount),
  CMAmount: convertAmountToStringForForm(detail?.CMAmount),
  convertedCMAmount: convertAmountToStringForForm(detail?.convertedCMAmount),
  unitPrice: convertAmountToStringForForm(detail?.unitPrice),
  schedule: convertNullToEmptyForForm(detail?.schedule),
  isCm: convertBooleanToYNIfExistForForm(detail?.isCm),
  CMMethod: convertNullToEmptyForForm(detail?.CMMethod),
  orderCompany: detail?.orderCompany,
  hwp: detail?.hwp,
  pdf: detail?.pdf,
  note: convertNullToEmptyForForm(detail?.note),
});
const ModalTable = () => {
  const {
    d: { detail, categoryList, estimationList },
  } = useContext(ProjectSalesInfoContractContractUpdateModalContext);
  const {
    d: { CMMethodOptionList },
  } = useContext(ProjectSalesInfoContractContractContext);
  const methods = useForm({
    values: getValuesForForm(detail),
  });
  const { watch, setValue, getValues } = methods;
  const onExpChange = useCallback(() => {
    const experimentAmount = getValues('experimentAmount');
    const experimentInformation = getValues('experimentInformation');
    const newUnitPrice = calcUnitPrice(experimentInformation, +experimentAmount.replace(/,/g, ''));
    setValue('unitPrice', newUnitPrice);
  }, [setValue, getValues]);
  const isCm = convertYNToBooleanForParameter(watch('isCm'));
  const { readOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    if (isCm) return;
    setValue('CMAmount', '');
    setValue('convertedCMAmount', '');
    setValue('CMMethod', '');
  }, [isCm, setValue]);
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th
                center={false}
                width="140px"
              >
                순번
              </Th>
              <TableCell>{getValueIfExist(detail?.seq)}</TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>확정여부</Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isConfirmed"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow key={Math.random()}>
              <Th center={false}>수주일</Th>
              <OldTd>
                <DatePickerWithHookForm
                  name="orderIntakeDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow key={Math.random()}>
              <Th center={false}>계약일자</Th>
              <OldTd>
                <DatePickerWithHookForm
                  name="contractDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="계약분류"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  required={true}
                  name="category"
                  optionList={getOptionListFromVariableList(categoryList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="견적번호"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <ProjectSalesInfoContractContractModalEstimationSelect
                  estimationList={estimationList}
                  readOnly={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="발주처명"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="orderCompany"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>계약명</Th>
              <TableCell>{getValueIfExist(detail?.projectFullName)}</TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>단지명</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="complexName"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>실험정보</Th>
              <OldTd>
                <HookFormInput
                  width="200px"
                  name="experimentInformation"
                  onPressChange={onExpChange}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>풍동금액</Th>
              <OldTd>
                <ProjectSalesInfoExperimentAmountHookForm
                  onExpChange={onExpChange}
                  readOnly={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>구검비</Th>
              <OldTd>
                <ProjectSalesInfoReviewAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>총액</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.AMOUNT}
                  width="200px"
                  name="totalAmount"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM비</Th>
                <OldTd>
                  <HookFormInput
                    inputType={InputType.AMOUNT}
                    width="200px"
                    name="CMAmount"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            {isCm && (
              <TableRow>
                <Th center={false}>환산CM비</Th>
                <OldTd>
                  <HookFormInput
                    inputType={InputType.AMOUNT}
                    width="200px"
                    name="convertedCMAmount"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>순동단가</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.AMOUNT}
                  width="200px"
                  name="unitPrice"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>일정</Th>
              <OldTd>
                <HookFormInput
                  width="160px"
                  name="schedule"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>CM 여부</Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isCm"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM 방식</Th>
                <OldTd>
                  <HookFormSelect
                    width="100px"
                    defaultLabel="선택"
                    isDefaultPossible
                    name="CMMethod"
                    optionList={getOptionListFromVariableList(CMMethodOptionList)}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>한글파일 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="hwp"
                  ext="hwp"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>PDF 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="pdf"
                  ext="pdf"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>등록일시</Th>
              <TableCell>
                {convertDateToStringFormat(detail?.createdAt, DATE_TIME_FORMAT)}
              </TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>등록자</Th>
              <TableCell>{getValueIfExist(detail?.writer?.name)}</TableCell>
            </TableRow>
            <TableRow>
              <Th center={false}>비고</Th>
              <OldTd sx={{ padding: '10px' }}>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoContractContractUpdateModalButtonGroup />
    </FormProvider>
  );
};
