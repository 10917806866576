import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import FileItem from '@front/ost_proposal/view/detail/attached_file/FileItem';
import type { AttachedFileId } from '@front/ost_proposal/domain';
import { TargetPage } from '@front/navigate/domain';

interface Props {
  deleteAttachedFile: (id: AttachedFileId) => void;
  isContributor: boolean;
}

function FileList({ deleteAttachedFile, isContributor }: Props) {
  const [fileList] = useSelector((root: RootState) => [root.proposal.fileList], shallowEqual);
  const currentPage = useSelector(
    (root: RootState) => root.navigation.currentPage!.targetPage,
    shallowEqual
  );
  return (
    <>
      {fileList.length === 0 && (
        <TableRow>
          <OldTd colSpan={currentPage === TargetPage.VOTE ? 4 : 5}>{'첨부 파일이 없습니다.'}</OldTd>
        </TableRow>
      )}
      {fileList.map((item, index) => (
        <FileItem
          key={item.id}
          item={item}
          index={index}
          deleteAttachedFile={deleteAttachedFile}
          isContributor={isContributor}
        />
      ))}
    </>
  );
}

export default FileList;
