import React, { useCallback } from 'react';
import Button from '@front/layouts/Button';
import { handleDeleteIdListConfirmAfterClick } from '@front/src/utils';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { shallow } from 'zustand/shallow';

export { ManageCollectionBtn as ProjectSalesInfoCollectionManagementCreateModalBtn };

const ManageCollectionBtn = () => {
  const { idList, setIdList, setIsCreateModalOpen, deleteCollectionManagementList } =
    useProjectSalesInfoCollectionManagementModalState(
      (state) => ({
        idList: state.idList,
        setIdList: state.setIdList,
        setIsCreateModalOpen: state.setIsCreateModalOpen,
        deleteCollectionManagementList: state.deleteCollectionManagementList,
      }),
      shallow
    );
  const { confirm } = useCustomDialog();
  const onDeleteClick = useCallback(() => {
    handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
      deleteCollectionManagementList(idList);
      setIdList([]);
    });
  }, [deleteCollectionManagementList, idList, setIdList]);
  return (
    <>
      <Button onClick={onDeleteClick}>선택삭제</Button>
      <Button onClick={() => setIsCreateModalOpen()}>추가</Button>
    </>
  );
};
