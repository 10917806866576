import React from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/layouts/Table';
import { ProjectSalesInfoCMSaveHistoryTableList } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/components/CM-save-history-table-list';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';
import { useProjectSalesInfoCMSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/useState';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { CMSaveHistoryTable as ProjectSalesInfoCMSaveHistoryTable };

const CMSaveHistoryTable = () => {
  const setId = useProjectSalesInfoCMSaveHistoryState((state) => state.setId);
  return (
    <RowClickTableContainer
      setId={setId}
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <Th
              noWrap={true}
              width="8vw"
            >
              수금완료 기성구분
            </Th>
            <Th
              noWrap={true}
              width="4vw"
            >
              기성비율
            </Th>
            <Th
              noWrap={true}
              width="4vw"
            >
              CM비율
            </Th>
            <Th
              noWrap={true}
              width="8vw"
            >
              필요 적립 CM
            </Th>
            <Th
              noWrap={true}
              width="8vw"
            >
              환산 CM
            </Th>
            <Th
              noWrap={true}
              width="4vw"
            >
              적립 여부
            </Th>
            <Th
              noWrap={true}
              width="8vw"
            >
              적립일
            </Th>
            <Th
              noWrap={true}
              width="14vw"
            >
              비고
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          <ProjectSalesInfoCMSaveHistoryTableList />
        </TableBody>
      </Table>
    </RowClickTableContainer>
  );
};
