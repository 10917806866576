import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { personalSettingsModalUISettingsHeaderSettingsQuery } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/query/query';
import { personalSettingsModalUISettingsHeaderSettingsMutation } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/query/mutation';
import type { HeaderSettingUpdateParameter } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/types/parameter';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { repository as personalSettingsModalUISettingsHeaderSettingsRepository };
const repository = {
  useGetHeaderList: (authMenuId) => {
    const { sectionId } = usePersonalSettingsModalUISettingsState(
      useShallow((state) => ({
        sectionId: state.sectionId,
      }))
    );
    const { data } = personalSettingsModalUISettingsHeaderSettingsQuery.useGetHeaderList(
      sectionId,
      authMenuId
    );
    return {
      data,
    };
  },
  useGetLink: (authMenuId) => {
    const { sectionId } = usePersonalSettingsModalUISettingsState(
      useShallow((state) => ({
        sectionId: state.sectionId,
      }))
    );
    const { data } = personalSettingsModalUISettingsHeaderSettingsQuery.useGetLink(
      {
        targetId: sectionId,
        isSection: true,
      },
      authMenuId
    );
    return {
      data,
    };
  },
  useUpdateHeader: (authMenuId) => {
    const callback = getNoOpCallback();
    const { mutate } =
      personalSettingsModalUISettingsHeaderSettingsMutation.useUpdateHeader(authMenuId);
    return {
      run: (params: HeaderSettingUpdateParameter) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useGetHeaderBySectionId: (authMenuId) => {
    const { sectionId } = usePersonalSettingsModalUISettingsState(
      useShallow((state) => ({
        sectionId: state.sectionId,
      }))
    );
    const { data } = personalSettingsModalUISettingsHeaderSettingsQuery.useGetHeaderBySectionId(
      sectionId ?? 0,
      authMenuId
    );
    return { data };
  },
};
