import React, { useCallback, useEffect, useMemo } from 'react';
import { PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ChildrenTableRow';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { ChildrenTableData as PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableData };
interface Props {
  authMenuId: number | undefined;
}
const ChildrenTableData = ({ authMenuId }: Props) => {
  const { control } = useFormContext();
  const { setAppend, setRemove } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      setAppend: state.setAppend,
      setRemove: state.setRemove,
    }))
  );
  const { fields, swap, append, remove } = useFieldArray({
    name: `childList`,
    control,
  });

  //TODO: 로직 확인 필요 (기존 20개 이상 필드 대응 못하는 부분 퀵픽스 했지만 원래 의도와 맞는지 확인 필요)
  const emptyRows = useMemo(() => {
    const rowsCount = fields ? (fields.length > 20 ? 0 : 20 - fields.length) : 0;
    return Array(rowsCount).fill(null);
  }, [fields]);
  const renderEmpty = useCallback(
    (rows) =>
      rows.map((_, index) => (
        <TableRowBox key={`children-empty-${index}`}>
          <TbcBox
            sx={{
              flex: 2,
            }}
          />
          <TbcBox
            sx={{
              flex: 1,
            }}
          />
        </TableRowBox>
      )),
    []
  );
  useEffect(() => {
    setAppend(append);
    setRemove(remove);
  }, [setAppend, setRemove, append, remove]);
  return (
    <>
      {fields.map((f, i) => (
        <PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableRow
          key={f.id}
          index={i}
          swap={swap}
          length={fields.length}
          authMenuId={authMenuId}
        />
      ))}
      {renderEmpty(emptyRows)}
    </>
  );
};
