import React from 'react';
import { ActivityLayouts } from '@front/src/features/project-sales-info/features/activity/components/layouts';
import { AffiliatedPersonSalesActivityTable } from '@front/src/features/affiliated-person/features/sales-activity/components/sales-activity-table';
import { AffiliatedPersonSalesActivityListWidget } from '@front/src/features/affiliated-person/features/sales-activity/widgets/list/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedPersonSalesActivityFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = ActivityLayouts;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AffiliatedPersonSalesActivityTable
          widget={<AffiliatedPersonSalesActivityListWidget menuId={menuId} />}
        />
      </Body>
    </Layout>
  );
};

export default AffiliatedPersonSalesActivityFeature;
