import React from 'react';
import type Page from 'type/Page';
import type { PersonnelShortVO } from 'personnel/domain';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { OldTh, Table, OldTd } from 'layouts/Table';
import TextLink from 'layouts/TextLink';
import TableLayout from 'layouts/TableLayout';
import DateFormat from 'layouts/DateFormat';

export interface ListProps {
  page: Page<PersonnelShortVO> | undefined;
}

export default function List(props: ListProps) {
  const { page } = props;
  return (
    <TableLayout
      pagination={page ? { ...page } : undefined}
      sizeFieldName="size"
      pageFieldName="page"
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>No</OldTh>
              <OldTh>이름</OldTh>
              <OldTh>이메일</OldTh>
              <OldTh>성별</OldTh>
              <OldTh>생년월일</OldTh>
              <OldTh>소속 부서</OldTh>
              <OldTh>입사 구분</OldTh>
              <OldTh>입사일</OldTh>
              <OldTh>상태</OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!page || page.content.length === 0) && (
              <TableRow>
                <OldTd
                  colSpan={9}
                  children="결과가 없습니다."
                />
              </TableRow>
            )}
            {page &&
              page.content.map((item, i) => {
                const no = i + 1 + page.size * page.number;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={item.id}
                  >
                    <OldTd>{no}</OldTd>
                    <OldTd>
                      <TextLink onClick={`/hr-card-management/${item.id}/detail`}>
                        {item.name}
                      </TextLink>
                    </OldTd>
                    <OldTd>
                      <TextLink onClick={`/hr-card-management/${item.id}/detail`}>
                        {item.email}
                      </TextLink>
                    </OldTd>
                    <OldTd>{item.basic?.sex}</OldTd>
                    <OldTd>
                      <DateFormat date={item.basic?.birthDate} />
                    </OldTd>
                    <OldTd>{item.department?.name}</OldTd>
                    <OldTd>{item.company?.hiredType}</OldTd>
                    <OldTd>
                      <DateFormat date={item.company?.hiredDate} />
                    </OldTd>
                    <OldTd>{item.userStatus}</OldTd>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableLayout>
  );
}
