import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';
import { initializeObject } from '@front/src/utils';

const useCollectionFinalForm = (item: CollectionFinalView) => {
  const methods = useForm<CollectionFinalView>({
    defaultValues: getFormValues(item),
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

const getFormValues = (item: CollectionFinalView) => ({
  ...(initializeObject(item) as CollectionFinalView),
});

export default useCollectionFinalForm;
