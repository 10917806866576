import React, { useCallback } from 'react';
import { TableRow as MuiTableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { SalesAccountingAmountType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import {
  SalesAccountingAmountSubType,
  SalesAccountingAmountSubTypeObjectForLabel,
  SalesAccountingAmountTypeObjectForLabel,
} from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import type { SalesAccountingAmountInformationView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';
import { projectSalesManagementSubTypeList } from '@front/src/features/accounting/features/upload/features/sales-info/utils';

export { TableRow as AccountingUploadSalesInfoGetYearTableRow };

interface Props {
  type: SalesAccountingAmountType;
  listByType?: SalesAccountingAmountInformationView[];
}

const TableRow = ({ listByType, type }: Props) => {
  const getListBySubType = useCallback(
    (subType) => listByType?.filter((l) => l.subType === subType),
    [listByType]
  );
  const getAmount = useCallback(
    (month, list) => list?.find((l) => l.month === month)?.amount?.toLocaleString() ?? '-',
    []
  );
  const getTotalAndAverage = useCallback(
    (subType) => {
      const listBySubType = getListBySubType(subType);
      let sumValue = 0;
      let count = 0;
      if (listBySubType?.length === 0) {
        return {
          sumResult: '-',
          averageResult: '-',
        };
      }
      for (let month = 1; month < 13; month += 1) {
        const find = listBySubType?.find((g) => g.month === month);
        if (!find) continue;
        count += 1;
        sumValue += find.amount;
      }
      return {
        sumResult: (sumValue / count).toLocaleString(),
        averageResult: sumValue.toLocaleString(),
      };
    },
    [getListBySubType]
  );
  const renderTdList = useCallback(
    (subType) => {
      const { averageResult, sumResult } = getTotalAndAverage(subType);
      const subTypeList = getListBySubType(subType);
      return (
        <>
          <OldTd>{SalesAccountingAmountSubTypeObjectForLabel[subType]}</OldTd>
          <OldTd>{sumResult}</OldTd>
          <OldTd>{sumResult}</OldTd>
          <OldTd>{averageResult}</OldTd>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
            const isConfirmed = subTypeList?.find((s) => s.month === month)?.isConfirmed;
            const sx = {
              fontWeight: isConfirmed ? 'bold' : 'normal',
            };
            return (
              <OldTd
                sx={sx}
                key={`${type}-${subType}-${month}`}
              >
                {getAmount(month, subTypeList)}
              </OldTd>
            );
          })}
        </>
      );
    },
    [getTotalAndAverage, getListBySubType, getAmount, type]
  );
  return (
    <>
      {projectSalesManagementSubTypeList.map((p) => {
        if (p === SalesAccountingAmountSubType.TARGET) {
          return (
            <MuiTableRow key={`row-${p}`}>
              <OldTd rowSpan={3}>{SalesAccountingAmountTypeObjectForLabel[type]}</OldTd>
              {renderTdList(p)}
            </MuiTableRow>
          );
        }
        return <MuiTableRow key={`row-${p}`}>{renderTdList(p)}</MuiTableRow>;
      })}
    </>
  );
};
