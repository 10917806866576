import React, { useEffect, useRef } from 'react';
import type { DefaultFunction } from 'type/Function';
import type { PromptView } from '@front/src/features/dialog/types';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import { FormProvider, useForm } from 'react-hook-form';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import Box from '@mui/material/Box';

interface Props extends PromptView {
  open: boolean;
  onClose: DefaultFunction;
  onConfirm: DefaultFunction<string>;
  defaultValue?: string;
  multiline?: boolean;
  maxLength?: number;
  rows?: number;
}

export function DialogPrompt(props: Props) {
  const {
    title,
    open,
    onClose,
    onConfirm,
    rows,
    defaultValue,
    maxLength,
    multiline,
    promptText,
    closeText,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const methods = useForm({
    defaultValues: {
      message: '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    onConfirm(data.message);
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    methods.reset({
      message: defaultValue,
    });
  }, [defaultValue]);

  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title={title ?? ''}
    >
      <ModalBodyUI>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <ModalHeaderUI>
              <ButtonBasicUI
                onClick={onClose}
                shape="tertiary"
                sx={{
                  width: '64px',
                }}
              >
                {closeText}
              </ButtonBasicUI>
              <ButtonBasicUI
                type="submit"
                shape="primary2"
                sx={{
                  width: '64px',
                }}
              >
                {promptText}
              </ButtonBasicUI>
            </ModalHeaderUI>
            <ModalContentUI>
              <Box
                sx={{
                  width: '100%',
                  padding: '1rem 0 0 0',
                }}
              >
                <UncontrolledInput
                  name="message"
                  multiline={multiline}
                  maxLength={maxLength}
                  inputRef={inputRef}
                  defaultValue={defaultValue ?? ''}
                  rows={rows}
                />
              </Box>
            </ModalContentUI>
          </form>
        </FormProvider>
      </ModalBodyUI>
    </ModalUI>
  );
}
