import React from 'react';
import { Th } from '@front/src/components/layout/table/th';
import Checkbox from '@front/layouts/Checkbox';
import classes from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox.module.scss';

interface Props<T extends { id: number }> {
  title: string;
  item: T;
  index: number;
  idList: number[];
  handleCheckboxChange: (id: number) => () => void;
  disabled?: boolean;
}

const TableSideHeaderWithCheckbox = <T extends { id: number }>({
  title,
  index,
  idList,
  item,
  handleCheckboxChange,
  disabled = false,
}: Props<T>) => (
  <Th>
    <div className={classes.th}>
      {`${title}${index + 1}`}
      <Checkbox
        checked={idList.includes(item.id)}
        onChange={handleCheckboxChange(item.id)}
        disabled={disabled}
      />
    </div>
  </Th>
);

export default TableSideHeaderWithCheckbox;
