import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoSubjectReviewShapeRatioCreateButtonContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/widgets/context';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';

export { CreateButton as ProjectSalesInfoSubjectReviewShapeRatioCreateButton };

const CreateButton = () => {
  const { onCreate } = useContext(ProjectSalesInfoSubjectReviewShapeRatioCreateButtonContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const handleAddClick = useCallback(() => {
    onCreate(id);
  }, [onCreate, id]);
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={handleAddClick}
      disabled={readOnly}
    >
      검토추가
    </Button>
  );
};
