import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { ostRewardManagementAction } from '@front/ost_reward/action';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { OstRewardManagementMemoCreateParameter } from '@front/ost_reward/parameter';
import { OstRewardManagementMemoCreateForm } from '@front/ost_reward/view/memo/create-form';

export { Create as OstRewardManagementMemoCreateService };

const Create = () => {
  const dispatch = useDispatch();

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (params: OstRewardManagementMemoCreateParameter) => {
      if (!params.description.trim()) {
        openSnackbar('내용을 입력해주세요.');
        formik.resetForm();
        return;
      }
      dispatch(ostRewardManagementAction.createMemo(params));
      formik.resetForm();
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      description: '',
      attendanceList: [],
    } as OstRewardManagementMemoCreateParameter,
    onSubmit: onSubmit,
  });

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value ?? '';
      if (value !== formik.values.description) {
        formik.setFieldValue('description', value);
      }
    },
    [formik]
  );

  const onChange = useCallback(
    (list) => {
      formik.setFieldValue('attendanceList', list);
    },
    [formik]
  );

  const onClick = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <OstRewardManagementMemoCreateForm
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
      />
    </FormikProvider>
  );
};
