import { createContext } from 'react';
import type { DefaultFunction } from '@front/src/types';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyPersonCharacterPreferenceView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/types/view';
import type { AffiliatedCompanyPersonCharacterPreferenceUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalCharacterPreferenceContext };

interface State {
  d: {
    preferenceList?: AffiliatedCompanyPersonCharacterPreferenceView[];
  };
  h: {
    onCreatePreference: DefaultFunction;
    setIdList: (idList: number[]) => void;
    onDeletePreference: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    onUpdatePreference: (params: AffiliatedCompanyPersonCharacterPreferenceUpdateParameter) => void;
  };
  isLoading: boolean;
  idList: number[];
  readOnly?: boolean;
}

const Context = createContext<State>({
  d: {
    preferenceList: [],
  },
  h: {
    onCreatePreference: noOp,
    setIdList: noOp,
    onDeletePreference: noOp,
    onUpdatePreference: noOp,
  },
  isLoading: false,
  idList: [],
});
