import { MenuActionType } from 'menu/action';
import { createReducer } from 'typesafe-actions';
import { isMobileDevice } from '@front/util/PwaUtil';

export interface MenuState {
  open: boolean;
}

const initial: MenuState = {
  open: !isMobileDevice(),
};

export const menuReducer = createReducer(initial, {
  [MenuActionType.toggleMenu]: (state) => ({
    ...state,
    open: !state.open,
  }),
});
