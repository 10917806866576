import React, { useCallback, useEffect } from 'react';
import DepartmentDetail from 'department/view/Detail';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import useId from 'services/useId';
import { FormikProvider, useFormik } from 'formik';
import type { DepartmentParameter } from 'department/parameter';
import { initialDepartmentParameter } from 'department/parameter';
import { departmentAction } from 'department/action';
import { DepartmentId } from 'department/domain';
import useDialog from 'dialog/hook';
import { useNavigate } from 'react-router-dom';
import { DialogStatus } from 'dialog/domain';
import { closeStatus } from 'components/DataFieldProps';

interface Props {
  menuId?: number | undefined;
}

function Element({ menuId }: Props) {
  const id = useId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirm, rollback } = useDialog();
  const { detail, requestUpsert, requestDelete } = useSelector(
    (root: RootState) => root.department
  );
  const upsert = useCallback(
    (formikProps: DepartmentParameter) =>
      dispatch(departmentAction.upsert({ ...formikProps, menuId })),
    [dispatch]
  );
  const deleteOne = useCallback(
    (id: DepartmentId) =>
      dispatch(
        departmentAction.deleteOne({
          id,
          menuId,
        })
      ),
    [dispatch]
  );
  const formik = useFormik<DepartmentParameter>({
    initialValues: initialDepartmentParameter,
    onSubmit: (values) => {
      upsert(values);
    },
  });

  useEffect(() => {
    dispatch(
      departmentAction.setId({
        id: id ? DepartmentId(id) : undefined,
        menuId,
      })
    );
  }, [id]);

  useEffect(() => {
    if (detail) {
      formik.setValues({
        id: detail.id,
        name: detail.name,
        category: detail.category,
        parent: detail.parent,
        parentId: detail.parentId ?? detail.parent?.id,
        note: detail.note,
        code: detail.code,
        edit: false,
      } as unknown as DepartmentParameter);
    } else {
      formik.setValues({ ...initialDepartmentParameter });
    }
  }, [detail]);

  useEffect(() => {
    closeStatus(
      requestUpsert,
      () => {
        if (id) {
          dispatch(
            departmentAction.setId({
              id: DepartmentId(id),
              menuId,
            })
          );
        } else {
          navigate('/personnel/department-management');
        }
      },
      () => {
        formik.setSubmitting(false);
        dispatch(departmentAction.requestUpsert('idle'));
      }
    );
  }, [requestUpsert]);

  useEffect(() => {
    closeStatus(
      requestDelete,
      () => {
        dispatch(
          departmentAction.setId({
            id: undefined,
            menuId,
          })
        );
        navigate('/personnel/department-management');
      },
      () => {
        dispatch(departmentAction.requestDelete('idle'));
      }
    );
  }, [requestDelete]);

  return (
    <FormikProvider value={formik}>
      <DepartmentDetail
        menuId={menuId}
        onCancel={() => {
          rollback(() => {
            if (detail) {
              formik.setValues({
                id: detail.id,
                name: detail.name,
                parent: detail.parent,
                parentId: detail.parentId ?? detail.parent?.id,
                note: detail.note,
                edit: false,
              } as unknown as DepartmentParameter);
            } else {
              navigate('/personnel/department-management');
            }
          });
        }}
        onDelete={() => {
          if (id) {
            confirm({
              status: DialogStatus.WARN,
              children: '해당 조직 정보를 삭제하시겠습니까?',
              confirmText: '삭제',
              afterConfirm: () => {
                deleteOne(DepartmentId(id));
              },
            });
          }
        }}
      />
    </FormikProvider>
  );
}

export { Element as DepartmentDetail };
