import { adminHeaderApi } from '@front/src/features/admin/features/header-settings/utils/constants';
import type {
  AdminHeaderSettingsSearchParams,
  AdminHeaderSettingsUpdateParams,
} from '@front/src/features/admin/features/header-settings/features/list/types/parameter';
import type { HistoryPageParams } from '@front/src/features/admin/features/header-settings/features/history/types/parameter';
import type {
  AdminHeaderSettingsHeaderSearchParams,
  AdminHeaderSettingsHierarchyLinkParams,
} from '@front/src/features/admin/features/header-settings/features/hierarchy/types/parameter';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { AdminHeaderSettingsUsageView } from '@front/src/features/admin/features/header-settings/features/list/types/view';
import type { SectionWithStatusView } from '@front/src/features/admin/features/header-settings/features/hierarchy/types/view';
import type { PersonalSettingsModalUISettingsHeaderView } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/types/view';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getHeaderList: () => `${adminHeaderApi}/header`,
  updateHeaderList: () => `${adminHeaderApi}/header`,
  getHeaderHistoryList: (headerId: number) =>
    `${adminHeaderApi}/section/header/${headerId}/history`,
  getHeaderUsageList: (headerId?: number) => `${adminHeaderApi}/header/${headerId}`,
  getSectionHeaderList: (sectionId: number) => `${adminHeaderApi}/section/${sectionId}/header`,
  getNonSectionHeaderList: (menuId: number) => `${adminHeaderApi}/menu/${menuId}/header`,
  getHeaderHistory: () => `${adminHeaderApi}/section/header-history`,
  getLink: () => `${adminHeaderApi}/menu/link`,
  getStatusList: (menuId?: number, headerId?: number) =>
    `${adminHeaderApi}/menu/${menuId}/header/${headerId}/type`,
  getHeaderListBySectionId: (sectionId: number) => `${adminHeaderApi}/section/${sectionId}/all`,
};

export const adminHeaderSettingsApi = {
  getHeaderList: async (params: AdminHeaderSettingsSearchParams, authMenuId) =>
    await axios.get(url.getHeaderList(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateHeaderList: async (params: AdminHeaderSettingsUpdateParams, authMenuId) =>
    await axios.patch(url.updateHeaderList(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHeaderHistoryList: async (headerId: number, authMenuId) =>
    await axios.get(url.getHeaderHistoryList(headerId), {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHeaderUsageList: async <T = AdminHeaderSettingsUsageView[]>(
    headerId?: number,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderUsageList(headerId), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getSectionHeaderList: async (
    sectionId: number,
    params: AdminHeaderSettingsHeaderSearchParams,
    authMenuId
  ) =>
    await axios.get(url.getSectionHeaderList(sectionId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    }),
  getNonSectionHeaderList: async (
    menuId: number,
    params: AdminHeaderSettingsHeaderSearchParams,
    authMenuId
  ) =>
    await axios.get(url.getNonSectionHeaderList(menuId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHeaderHistory: async (params: HistoryPageParams, authMenuId) =>
    await axios.get(url.getHeaderHistory(), {
      params: {
        page: params.page,
        size: params.size,
      },
      headers: getMenuIdHeaders(authMenuId),
    }),
  getLink: async <T = string>(params: AdminHeaderSettingsHierarchyLinkParams, authMenuId) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getLink(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getStatusList: async <T = SectionWithStatusView[]>(
    menuId?: number,
    headerId?: number,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getStatusList(menuId, headerId), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getHeaderListBySectionId: async <T = PersonalSettingsModalUISettingsHeaderView[]>(
    sectionId: number,
    authMenuId
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderListBySectionId(sectionId), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
};
