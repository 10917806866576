import React, { useContext } from 'react';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { useFormContext } from 'react-hook-form';
import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { UpdateModalBtn as ProjectSalesInfoCMPaymentHistoryUpdateModalBtn };

const UpdateModalBtn = () => {
  const setIsUpdateModalOpen = useProjectSalesInfoCMPaymentHistoryState(
    (state) => state.setIsUpdateModalOpen
  );
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoCMPaymentHistoryUpdateModalContext);
  const { handleSubmit } = useFormContext<ProjectCMPaymentHistoryParams>();
  const handleSaveClick = handleSubmit((data) => {
    onUpdate(
      convertEmptyToNull({
        ...data,
        paymentAmount: +(data.paymentAmount as string).replace(/,/g, ''),
        companyId: data.company?.id as number,
        paymentRequestDate: formatDateOrNull(data.paymentRequestDate as string),
        paymentDate: formatDateOrNull(data.paymentDate as string),
      }) as ProjectCMPaymentHistoryParams
    );
    setIsUpdateModalOpen();
  });
  const { readOnly } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '50px',
        width: '50%',
        paddingTop: '10px',
      }}
    >
      <Button
        shape="basic2"
        onClick={() => setIsUpdateModalOpen()}
        fullWidth={true}
      >
        취소
      </Button>
      <Button
        onClick={handleSaveClick}
        fullWidth={true}
        disabled={readOnly}
      >
        수정
      </Button>
    </Box>
  );
};
