import React from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import type { CommentParameter } from '@front/ost_proposal/parameter';
import type { CommentId, ProposalCommentEditVO } from '@front/ost_proposal/domain';
import CommentItem from '@front/ost_proposal/view/detail/comment/contents/CommentItem';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import UserIcon from '@front/layouts/UserIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';
import useDialog from '@front/dialog/hook';
import ReplyAddRowLayout from '@front/ost_proposal/view/detail/comment/contents/ReplyAddRowLayout';
import { TargetPage } from '@front/navigate/domain';

interface Props {
  updateComment: (comment: CommentParameter) => void;
  deleteComment: (id: CommentId) => void;
  setCommentId: (id: CommentId | undefined) => void;
  setCommentEditInput: (state: ProposalCommentEditVO) => void;
  setCommentParentId: (id: CommentId | undefined) => void;
  addComment: (comment: CommentParameter) => void;
  setReplyCommentParentId: (id: CommentId | undefined) => void;
}

function CommentList({
  updateComment,
  deleteComment,
  setCommentId,
  setCommentEditInput,
  setCommentParentId,
  addComment,
  setReplyCommentParentId,
}: Props) {
  const [commentList] = useSelector((root: RootState) => [root.proposal.commentList], shallowEqual);
  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );
  const loginUser = useSelector((root: RootState) => root.login.detail!, shallowEqual);
  const commentId = useSelector((root: RootState) => root.proposal.commentId, shallowEqual);
  const commentParentId = useSelector(
    (root: RootState) => root.proposal.commentParentId,
    shallowEqual
  );
  const replyCommentParentId = useSelector(
    (root: RootState) => root.proposal.replyCommentParentId,
    shallowEqual
  );
  const currentPage = useSelector(
    (root: RootState) => root.navigation.currentPage!.targetPage,
    shallowEqual
  );

  const { confirm } = useDialog();
  return (
    <>
      {commentList.length > 0 &&
        commentList.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: '30px auto 30px',
              gridTemplateAreas: `"avatar contents setting"
              "reply reply reply"`,
              padding: '5px 10px',
              minHeight: '100px',
            }}
          >
            {!item.parentId && item.isBlind && (
              <Box
                sx={{
                  gridColumn: '2',
                  alignSelf: 'self-end',
                  color: ColorPalette._b2b4b7,
                  fontSize: '12px',
                }}
              >
                삭제된 피드백 입니다.
              </Box>
            )}
            {!item.parentId && !item.isBlind && (
              <>
                <Box
                  sx={{
                    gridArea: 'avatar',
                    width: '100%',
                    paddingTop: '5px',
                  }}
                >
                  <UserIcon
                    user={item.writer.id}
                    defaultImage={
                      currentPage === TargetPage.VOTE &&
                      contributorList.some(
                        (contributorInfo) => contributorInfo.contributor.id === item.writer.id
                      )
                    }
                  />
                </Box>
                <CommentItem
                  key={item.id}
                  item={item}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  setCommentEditInput={setCommentEditInput}
                  setCommentParentId={setCommentParentId}
                />
                <Box
                  sx={{
                    gridArea: 'setting',
                    width: '100%',
                  }}
                >
                  {loginUser?.id === item.writer.id && (
                    <>
                      <IconButton
                        children={
                          <MoreVertIcon
                            sx={{
                              color: `${ColorPalette._b2b4b7}`,
                              cursor: 'pointer',
                            }}
                          />
                        }
                        onClick={() => {
                          if (commentId) {
                            setCommentId(undefined);
                          } else {
                            setCommentId(item.id);
                          }
                        }}
                        onBlur={() => {
                          setCommentId(undefined);
                        }}
                      />
                      {commentId === item.id && (
                        <Collapse
                          in={!!commentId}
                          sx={{
                            width: '60px',
                            zIndex: 1,
                            backgroundColor: `${ColorPalette._ffffff}`,
                            border: `1px solid ${ColorPalette._b2b4b7}`,
                            position: 'relative',
                            left: '-30px',
                            borderRadius: '5px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '20px',
                              borderTopLeftRadius: '5px',
                              borderTopRightRadius: '5px',
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: `${ColorPalette._e4e9f2}`,
                              },
                            }}
                            onMouseDown={() => {
                              setCommentEditInput({
                                id: item.id,
                                show: true,
                              });
                            }}
                          >
                            <TextBox variant="body9">수정</TextBox>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '20px',
                              cursor: 'pointer',
                              borderBottomLeftRadius: '5px',
                              borderBottomRightRadius: '5px',
                              ':hover': {
                                backgroundColor: `${ColorPalette._e4e9f2}`,
                              },
                            }}
                            onMouseDown={() => {
                              confirm({
                                children: '해당 피드백을 삭제하시겠습니까?',
                                confirmText: '확인',
                                afterConfirm: () => {
                                  deleteComment(item.id);
                                },
                              });
                            }}
                          >
                            <TextBox variant="body9">삭제</TextBox>
                          </Box>
                        </Collapse>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}

            {commentParentId === item.id && (
              <ReplyAddRowLayout
                setCommentParentId={setCommentParentId}
                addComment={addComment}
                commentParentId={commentParentId}
              />
            )}
            {item.step > 0 && (
              <>
                <Box
                  sx={{
                    gridArea: 'reply',
                    display: 'grid',
                    width: '100%',
                    paddingLeft: '20px',
                    gridTemplateColumns: '30px auto 30px',
                    gridTemplateAreas: `"avatar contents setting"
              "reply reply reply"`,
                    minHeight: '100px',
                    marginTop: '5px',
                  }}
                >
                  <Box
                    sx={{
                      gridArea: 'avatar',
                      width: '100%',
                      paddingTop: '5px',
                    }}
                  >
                    <UserIcon
                      user={item.writer.id}
                      defaultImage={
                        currentPage === TargetPage.VOTE &&
                        contributorList.some(
                          (contributorInfo) => contributorInfo.contributor.id === item.writer.id
                        )
                      }
                    />
                  </Box>
                  <CommentItem
                    key={`${item.id}_${item.step}`}
                    item={item}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                    setCommentEditInput={setCommentEditInput}
                    setCommentParentId={setReplyCommentParentId}
                  />
                  <Box
                    sx={{
                      gridArea: 'setting',
                      width: '100%',
                    }}
                  >
                    {loginUser?.id === item.writer.id && (
                      <>
                        <IconButton
                          children={
                            <MoreVertIcon
                              sx={{
                                color: `${ColorPalette._b2b4b7}`,
                                cursor: 'pointer',
                              }}
                            />
                          }
                          onClick={() => {
                            if (commentId) {
                              setCommentId(undefined);
                            } else {
                              setCommentId(item.id);
                            }
                          }}
                          onBlur={() => {
                            setCommentId(undefined);
                          }}
                        />
                        {commentId === item.id && (
                          <Collapse
                            in={!!commentId}
                            sx={{
                              width: '60px',
                              zIndex: 1,
                              backgroundColor: `${ColorPalette._ffffff}`,
                              border: `1px solid ${ColorPalette._b2b4b7}`,
                              position: 'relative',
                              left: '-30px',
                              borderRadius: '5px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20px',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                                cursor: 'pointer',
                                ':hover': {
                                  backgroundColor: `${ColorPalette._e4e9f2}`,
                                },
                              }}
                              onMouseDown={() => {
                                setCommentEditInput({
                                  id: item.id,
                                  show: true,
                                });
                              }}
                            >
                              <TextBox variant="body9">수정</TextBox>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20px',
                                cursor: 'pointer',
                                borderBottomLeftRadius: '5px',
                                borderBottomRightRadius: '5px',
                                ':hover': {
                                  backgroundColor: `${ColorPalette._e4e9f2}`,
                                },
                              }}
                              onMouseDown={() => {
                                confirm({
                                  children: '해당 피드백을 삭제하시겠습니까?',
                                  confirmText: '확인',
                                  afterConfirm: () => {
                                    deleteComment(item.id);
                                  },
                                });
                              }}
                            >
                              <TextBox variant="body9">삭제</TextBox>
                            </Box>
                          </Collapse>
                        )}
                      </>
                    )}
                  </Box>
                  {replyCommentParentId === item.id && (
                    <ReplyAddRowLayout
                      setCommentParentId={setReplyCommentParentId}
                      addComment={addComment}
                      commentParentId={replyCommentParentId}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        ))}
    </>
  );
}

export default CommentList;
