// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K9VHJgEgvL0dQX5tlcdt{width:35%}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/drawer-search-form/select.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA","sourcesContent":[".container {\r\n  width: 35%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `K9VHJgEgvL0dQX5tlcdt`
};
export default ___CSS_LOADER_EXPORT___;
