import { createContext } from 'react';
import type { DefaultFunction } from '@front/src/types';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyPersonCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/parameter';
import type { AffiliatedCompanyPersonCharacterCharacterView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/character/types/view';

export { Context as AffiliatedCompanyPersonDetailModalCharacterCharacterContext };

interface State {
  d: {
    characterList?: AffiliatedCompanyPersonCharacterCharacterView[];
  };
  h: {
    onCreateCharacter: DefaultFunction;
    setIdList: (idList: number[]) => void;
    onDeleteCharacter: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    onUpdateCharacter: (params: AffiliatedCompanyPersonCharacterCharacterUpdateParameter) => void;
  };
  isLoading: boolean;
  idList: number[];
  readOnly?: boolean;
}

const Context = createContext<State>({
  d: {
    characterList: [],
  },
  h: {
    onCreateCharacter: noOp,
    setIdList: noOp,
    onDeleteCharacter: noOp,
    onUpdateCharacter: noOp,
  },
  isLoading: false,
  idList: [],
});
