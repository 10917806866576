import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { projectSalesInfoContractReviewHistoryRepository } from '@front/src/features/project-sales-info/features/contract/features/review-history/repository';

export { useLogic as useProjectSalesInfoContractReviewHistoryLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: list, isLoading } = projectSalesInfoContractReviewHistoryRepository.useListGet(
    id,
    menuId
  );
  const { run: onDelete } = projectSalesInfoContractReviewHistoryRepository.useDelete(menuId);
  const d = { list };
  const h = { onDelete };
  return {
    d,
    h,
    isLoading,
  };
};
