import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyPersonRelationshipView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/view';
import type { AffiliatedCompanyPersonRelationshipUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/parameter';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalRelationshipContext };

interface State {
  d: { relationshipList?: AffiliatedCompanyPersonRelationshipView[] };
  h: {
    onRelationshipCreate: DefaultFunction;
    onRelationshipUpdate: (params: AffiliatedCompanyPersonRelationshipUpdateParameter) => void;
    onRelationshipDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {},
  h: {
    onRelationshipCreate: noOp,
    onRelationshipUpdate: noOp,
    onRelationshipDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
