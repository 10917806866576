import React, { useMemo } from 'react';
import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ProposalId } from '@front/ost_proposal/domain';
import { getIsShowForAuthorizationFromMenu } from '@front/src/utils';

interface Props {
  deleteDetail: (id: ProposalId) => void;
}

const DeleteButton = ({ deleteDetail }: Props) => {
  const { confirm } = useDialog();
  const id = useSelector((root: RootState) => root.proposal.id!, shallowEqual);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isShow = useMemo(() => {
    if (!loginUser?.menus) return false;
    return getIsShowForAuthorizationFromMenu(loginUser.menus);
  }, [loginUser]);
  if (!isShow) {
    return <></>;
  }
  return (
    <Button
      shape="basic3"
      onClick={() => {
        confirm({
          children: '해당 제안을 삭제하시겠습니까?',
          afterConfirm: () => {
            deleteDetail(id);
          },
          confirmText: '확인',
        });
      }}
    >
      삭제
    </Button>
  );
};

export default DeleteButton;
