import { APPROVAL_BOX_STATUS } from '@front/src/features/approval-box/type/domain';

export const approvalBoxFilterOptionList = [
  {
    label: '전체',
    value: APPROVAL_BOX_STATUS.ALL.toLowerCase(),
  },
  {
    label: '진행',
    value: APPROVAL_BOX_STATUS.ON_GOING.toLowerCase(),
  },
  {
    label: '완료',
    value: APPROVAL_BOX_STATUS.COMPLETED.toLowerCase(),
  },
  {
    label: '반려',
    value: APPROVAL_BOX_STATUS.REJECTED.toLowerCase(),
  },
  {
    label: '상신 취소',
    value: APPROVAL_BOX_STATUS.CANCELED.toLowerCase(),
  },
];

export const approvalBoxApprovalFilterOptionList = [
  {
    label: '전체',
    value: APPROVAL_BOX_STATUS.ALL.toLowerCase(),
  },
  {
    label: '결재대기',
    value: APPROVAL_BOX_STATUS.WAITING.toLowerCase(),
  },
  {
    label: '진행',
    value: APPROVAL_BOX_STATUS.ON_GOING.toLowerCase(),
  },
  {
    label: '완료',
    value: APPROVAL_BOX_STATUS.COMPLETED.toLowerCase(),
  },
  {
    label: '반려',
    value: APPROVAL_BOX_STATUS.REJECTED.toLowerCase(),
  },
  {
    label: '상신 취소',
    value: APPROVAL_BOX_STATUS.CANCELED.toLowerCase(),
  },
];
