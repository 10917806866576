import { useCallback } from 'react';
import { useDialogState } from '@front/src/features/dialog/widgets/useState';
import type { AlertView, ConfirmView, PromptView } from '@front/src/features/dialog/types';
import { useShallow } from 'zustand/react/shallow';

export const useCustomDialog = () => {
  const { openPrompt, openConfirm, openAlert } = useDialogState(
    useShallow((state) => ({
      openPrompt: state.openPrompt,
      openConfirm: state.openConfirm,
      openAlert: state.openAlert,
    }))
  );

  const alert = useCallback((props: AlertView) => openAlert(props), [openAlert]);

  const error = useCallback((props: AlertView) => openAlert(props), [openAlert]);

  const confirm = useCallback((props: ConfirmView) => openConfirm(props), [openConfirm]);

  const prompt = useCallback((props: PromptView) => openPrompt(props), [openPrompt]);

  return {
    alert,
    error,
    confirm,
    prompt,
  };
};
