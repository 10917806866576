import React, { useContext, useEffect } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { calcUnitPrice, convertYNToBooleanForParameter, YNOptionList } from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { getOptionListFromVariableList } from '@front/src/features/manageable-variable/utils';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { ProjectSalesInfoEstimationEstimationCreateModalButtonGroup } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/components/modal-button-group';
import { ProjectSalesInfoEstimationEstimationCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/context';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import { ProjectSalesInfoEstimationExperimentAmountHookForm } from '@front/src/features/project-sales-info/features/estimation/components/experiment-amount-hook-form';
import { ProjectSalesInfoEstimationReviewAmountHookForm } from '@front/src/features/project-sales-info/features/estimation/components/review-amount-hook-form';
import { HookFormCompanySelector } from '@front/src/components/company-selector-with-hook-form';
import { ProjectSalesInfoEstimationEstimationCreateModalTableExperimentInformation } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/experiment-information';
import { HookFormPersonSelector } from '@front/src/components/person-selector-with-hook-form';
import RequiredMark from '@front/layouts/RequiredMark';
import { ProjectSalesInfoEstimationEstimationContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/context';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { ModalTable as ProjectSalesInfoEstimationEstimationCreateModalTable };

const ModalTable = () => {
  const {
    d: { categoryList, experiment },
  } = useContext(ProjectSalesInfoEstimationEstimationCreateModalContext);
  const {
    d: { CMMethodOptionList },
  } = useContext(ProjectSalesInfoEstimationEstimationContext);
  const methods = useForm({
    values,
  });
  const { watch, setValue } = methods;
  const experimentAmount = watch('experimentAmount');
  const experimentInformation = watch('experimentInformation');
  useEffect(() => {
    const newUnitPrice = calcUnitPrice(experimentInformation, +experimentAmount.replace(/,/g, ''));
    setValue('unitPrice', newUnitPrice);
  }, [experimentAmount, experimentInformation, setValue]);
  const isCm = convertYNToBooleanForParameter(watch('isCm'));
  const { readOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  useEffect(() => {
    if (isCm) return;
    setValue('CMAmount', '');
    setValue('convertedCMAmount', '');
    setValue('CMMethod', '');
  }, [isCm, setValue]);
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th
                center={false}
                width="120px"
              >
                확정여부
              </Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isConfirmed"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="견적일자"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd sx={{textAlign: 'left'}}>
                <DatePickerWithHookForm
                  name="estimationDate"
                  required={true}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>요청사</Th>
              <OldTd>
                <HookFormPersonSelector
                  position="left"
                  companyName="requestCompany"
                  managerName="requestCompanyManager"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>의뢰처</Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="clientCompany"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>
                <div>
                  <RequiredMark
                    required={true}
                    text="실험정보"
                    isRed={true}
                    size="16px"
                  />
                </div>
              </Th>
              <OldTd>
                <ProjectSalesInfoEstimationEstimationCreateModalTableExperimentInformation
                  experiment={experiment}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>풍동금액</Th>
              <OldTd>
                <ProjectSalesInfoEstimationExperimentAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>구검비</Th>
              <OldTd>
                <ProjectSalesInfoEstimationReviewAmountHookForm readOnly={readOnly} />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM비</Th>
                <OldTd>
                  <HookFormInput
                    name="CMAmount"
                    width="200px"
                    inputType={InputType.EST_AMOUNT}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            {isCm && (
              <TableRow>
                <Th center={false}>환산CM비</Th>
                <OldTd>
                  <HookFormInput
                    name="convertedCMAmount"
                    width="200px"
                    inputType={InputType.EST_AMOUNT}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>총액</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.EST_AMOUNT}
                  width="200px"
                  name="totalAmount"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>순동단가</Th>
              <OldTd>
                <HookFormInput
                  inputType={InputType.AMOUNT}
                  width="200px"
                  name="unitPrice"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>일정</Th>
              <OldTd>
                <HookFormInput
                  width="160px"
                  name="schedule"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>견적서 구분</Th>
              <OldTd>
                <HookFormSelect
                  width="120px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="estimationCategory"
                  optionList={getOptionListFromVariableList(categoryList)}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>CM 여부</Th>
              <OldTd>
                <HookFormSelect
                  width="100px"
                  defaultLabel="선택"
                  isDefaultPossible
                  name="isCm"
                  optionList={YNOptionList}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            {isCm && (
              <TableRow>
                <Th center={false}>CM방식</Th>
                <OldTd>
                  <HookFormSelect
                    width="100px"
                    defaultLabel="선택"
                    isDefaultPossible
                    name="CMMethod"
                    optionList={getOptionListFromVariableList(CMMethodOptionList)}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
            )}
            <TableRow>
              <Th center={false}>엑셀 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="excel"
                  ext="xlsx"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>PDF 업로드</Th>
              <OldTd>
                <HookFormFileUploader
                  name="pdf"
                  ext="pdf"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th center={false}>비고</Th>
              <OldTd sx={{ padding: '10px' }}>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoEstimationEstimationCreateModalButtonGroup />
    </FormProvider>
  );
};

const values = {
  isConfirmed: '',
  estimationDate: undefined,
  requestCompany: undefined,
  requestCompanyManager: undefined,
  clientCompany: undefined,
  experimentInformation: '',
  experimentAmount: '',
  reviewAmount: '',
  totalAmount: '',
  CMAmount: '',
  convertedCMAmount: '',
  unitPrice: '',
  schedule: '',
  estimationCategory: '',
  isCm: '',
  CMMethod: '',
  excel: '',
  pdf: '',
  note: '',
};
