import React, { useCallback, useMemo } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import {
  convertDateToStringFormat,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  downloadFile,
  getChecked,
  getLocaleStringIfExist,
  getValueIfExist,
} from '@front/src/utils';
import classes from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-table-row.module.scss';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoEstimationComparedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useState';
import type { ProjectEstimationComparedView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/types/view';
import { useProjectSalesInfoEstimationComparedUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/update-modal/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import { useShallow } from 'zustand/react/shallow';

export { EstimationComparedTableRow as ProjectSalesInfoEstimationComparedTableRow };

interface Props {
  item: ProjectEstimationComparedView;
  index: number;
}

const EstimationComparedTableRow = ({ item, index }: Props) => {
  const { idList, setIdList } = useProjectSalesInfoEstimationComparedState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { setIsOpen, setId } = useProjectSalesInfoEstimationComparedUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const checked = useMemo(() => getChecked(item.id, idList), [idList, item.id]);
  const handleCheckboxChange = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, item.id));
  }, [setIdList, idList, item.id]);
  const handleCodeClick = useCallback(() => {
    setId(item.id);
    setIsOpen(true);
  }, [item.id, setIsOpen, setId]);
  const { readOnly } = useProjectSalesInfoEstimationComparedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <OldTd>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>
        <div
          className={classes.name}
          onClick={handleCodeClick}
          aria-hidden
        >
          {convertDateToStringFormat(item.estimationDate, DATE_FORMAT)}
        </div>
      </OldTd>
      <OldTd>{getValueIfExist(item.competitorCompany?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.clientCompany?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.experimentInformation)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.experimentAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.reviewAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.totalAmount)}</OldTd>
      <OldTd>{getLocaleStringIfExist(item.unitPrice)}</OldTd>
      <OldTd>{getValueIfExist(item.date)}</OldTd>
      <OldTd>
        {item.pdf?.id ? (
          <div
            className={classes.file}
            onClick={() => {
              downloadFile(item.pdf?.id);
            }}
            aria-hidden
          >
            {item.pdf?.filename}
          </div>
        ) : (
          '-'
        )}
      </OldTd>
      <OldTd>{convertDateToStringFormat(item.createdAt, DATE_TIME_FORMAT)}</OldTd>
      <OldTd>{getValueIfExist(item.writer?.name)}</OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
