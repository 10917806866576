import { projectSalesInfoProjectDivisionRepository } from '@front/src/features/project-sales-info/features/project-division/repository/form/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoContractEarningsCollectionManagementLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: divisionDetail } = projectSalesInfoProjectDivisionRepository.useProjectDivisionGet(
    id!,
    menuId
  );
  return {
    d: {
      divisionDetail,
    },
  };
};
