import React, { useCallback, useContext, useMemo } from 'react';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import Button from '@front/layouts/Button';
import type { AccountingSettingsManagementAccountingAccountTableCategoryParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';

export { AttributeModalBody as AccountingSettingsManagementAccountingAccountAttributeModalBody };
const AttributeModalBody = () => {
  const {
    onUpdate,
    list,
    handleClose: onMenuClose,
  } = useContext(AccountingSettingsManagementAccountingAccountTableCategoryListContext);
  const { targetItem, setIsOpen } = useAccountingSettingsManagementAccountingAccountTableState(
    useShallow((state) => ({
      targetItem: state.targetItem,
      setIsOpen: state.setIsOpen,
    }))
  );
  const item = useMemo(() => list?.find((l) => l.id === targetItem?.id), [list, targetItem]);
  const methods = useForm({
    values: {
      isBudgetManagementCategory: item?.isBudgetManagementCategory ?? false,
      isIncomeExpenseReconciliation: item?.isIncomeExpenseReconciliation ?? false,
    },
  });
  const { handleSubmit, control } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      ...data,
      id: targetItem?.id,
      sequence: targetItem?.sequence,
      name: targetItem?.name,
      parentId: targetItem?.parentId,
      depth: targetItem?.depth,
      isBudgetManagementCategory: data.isBudgetManagementCategory,
      isIncomeExpenseReconciliation: data.isIncomeExpenseReconciliation,
    } as AccountingSettingsManagementAccountingAccountTableCategoryParameter);
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
    onMenuClose();
  }, [setIsOpen, onMenuClose]);
  const {
    field: { value: isBudgetManagementCategory, onChange: onIsBudgetManagementCategoryFormChange },
  } = useController({ name: 'isBudgetManagementCategory', control });
  const {
    field: {
      value: isIncomeExpenseReconciliation,
      onChange: onIsIncomeExpenseReconciliationFormChange,
    },
  } = useController({ name: 'isIncomeExpenseReconciliation', control });
  const handleIsBudgetManagementCategoryChange = useCallback(
    (e) => {
      onIsBudgetManagementCategoryFormChange(e.target.checked);
    },
    [onIsBudgetManagementCategoryFormChange]
  );
  const handleIsIncomeExpenseReconciliationChange = useCallback(
    (e) => {
      onIsIncomeExpenseReconciliationFormChange(e.target.checked);
    },
    [onIsIncomeExpenseReconciliationFormChange]
  );
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '20px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <Checkbox
            checked={isBudgetManagementCategory}
            onChange={handleIsBudgetManagementCategoryChange}
          />
          <Box>예산 관리 항목</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
          }}
        >
          <Checkbox
            checked={isIncomeExpenseReconciliation}
            onChange={handleIsIncomeExpenseReconciliationChange}
          />
          <Box>수입 지출 결산</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '12px',
            width: '100%',
          }}
        >
          <Button onClick={onSubmit}>저장</Button>
          <Button onClick={handleClose}>취소</Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
