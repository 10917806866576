import dayjs from 'dayjs';
import type { TextFieldProps } from '@mui/material';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import React, { memo, useCallback } from 'react';

import { RenderDateInput } from '@front/components/RenderDateInput';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import type {
  CampaignUpdateEstimationEndDateParameter,
  CampaignUpdateEstimationStartDateParameter,
} from '@front/ost_campaign/parameter';
import { isValidDate } from '@front/util/DateUtil';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  estimationStartDate?: string;
  estimationEndDate?: string;
  updateEstimationStartDate: (params: CampaignUpdateEstimationStartDateParameter) => void;
  updateEstimationEndDate: (params: CampaignUpdateEstimationEndDateParameter) => void;
}

const EstimationPeriod = ({
  estimationStartDate,
  estimationEndDate,
  updateEstimationStartDate,
  updateEstimationEndDate,
}: Props) => {
  const dispatch = useDispatch();

  const openSnackbar = useCallback(
    (message: string, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onEstimationStartDateChange = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateEstimationStartDate({
          estimationStartDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateEstimationStartDate({
        estimationStartDate: value,
      });
    },
    [updateEstimationStartDate, openSnackbar]
  );

  const onEstimationEndDateChange = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateEstimationEndDate({
          estimationEndDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateEstimationEndDate({
        estimationEndDate: value,
      });
    },
    [updateEstimationEndDate, openSnackbar]
  );

  return (
    <DataFieldWithLabel
      label="심사 기간"
      labelSX={{ minWidth: '80px' }}
    >
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={estimationStartDate && dayjs(estimationStartDate).format('YYYY-MM-DD')}
        onChange={onEstimationStartDateChange}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginInline: '10px',
        }}
      >
        ~
      </Box>
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={estimationEndDate && dayjs(estimationEndDate).format('YYYY-MM-DD')}
        onChange={onEstimationEndDateChange}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
    </DataFieldWithLabel>
  );
};

const EstimationPeriodForm = memo(EstimationPeriod);
export default EstimationPeriodForm;
