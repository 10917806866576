import type { ReactNode } from 'react';
import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';

export { Td as AffiliatedCompanyProjectTd };

interface Props {
  children?: ReactNode;
  width?: string;
  onClick?: (e) => void;
  sx?: SxProps;
}

const Td = ({ width, children, sx, ...rest }: Props) => (
  <Box
    {...rest}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: `1px solid ${ColorPalette._e4e9f2}`,
      ...sx,
    }}
  >
    <Box
      sx={{
        width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        fontSize: '13px',
      }}
    >
      {children}
    </Box>
  </Box>
);
