import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { projectSalesInfoAbstractProjectInfoRepository } from '@front/src/features/project-sales-info/features/abstract/features/project-info/repository/repository';

export { useLogic as useProjectSalesInfoAbstractProjectInfoDetailLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { data: detail } = projectSalesInfoAbstractProjectInfoRepository.useDetailGet(id, menuId);
  return {
    detail,
  };
};
