// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdCYTWG8oiK10a123gbt{display:flex;gap:10px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table-header.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA","sourcesContent":[".btn__group {\r\n  display: flex;\r\n  gap: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__group": `mdCYTWG8oiK10a123gbt`
};
export default ___CSS_LOADER_EXPORT___;
