import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyWebHardView } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/view';
import type { AffiliatedCompanyOutlineWebHardUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/parameter';

export { Context as AffiliatedCompanyOutlineWebHardContext };

interface State {
  d: {
    webHardList?: AffiliatedCompanyWebHardView[];
  };
  h: {
    onCreate: (params) => void;
    onDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    onUpdate: (params: AffiliatedCompanyOutlineWebHardUpdateParameter) => void;
  };
}

const Context = createContext<State>({
  d: {
    webHardList: [],
  },
  h: {
    onCreate: noOp,
    onDelete: noOp,
    onUpdate: noOp,
  },
});
