import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

interface State {
  h: {
    onCreate: (params: ProjectSalesInfoBidResultParams) => void;
  };
}

export { Context as ProjectSalesInfoBidResultCreateModalContext };

const Context = createContext<State>({
  h: {
    onCreate: noOp,
  },
});
