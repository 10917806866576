import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoAbstractCollectionListContext } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/provider/context';
import { ProjectSalesInfoAbstractCollectionRow } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/components/row';

export { List as ProjectSalesInfoAbstractCollectionList };

const List = () => {
  const { list } = useContext(ProjectSalesInfoAbstractCollectionListContext);
  if (list?.length === 0) {
    return <NoResult colSpan={10} />;
  }
  return (
    <>
      {list?.map((item) => (
        <ProjectSalesInfoAbstractCollectionRow
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
