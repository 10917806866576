import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import UserSelectorInput from '@front/src/features/user-selector/features/input/components/Input';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';
import UserSelectorModal from '@front/src/features/user-selector/components/Modal';
import type { UserVO } from '@front/user/domain';

interface Props extends UserSelectorInputProps {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
}

export default function UserSelectorInputAndModal(props: Readonly<Props>) {
  const { value, readOnly } = props;
  const { data: allUser } = userSelectorRepository.useGetAllUser();

  const { control, reset } = useForm<{
    inputList: UserVO[];
  }>({
    defaultValues: {
      inputList: [],
    },
  });

  useEffect(() => {
    if (allUser) {
      reset({
        inputList: getInputList(value, allUser),
      });
    }
  }, [value, allUser]);

  return (
    <>
      {!readOnly && <UserSelectorModal {...props} />}
      <UserSelectorInput
        {...props}
        control={control}
      />
    </>
  );
}

const getInputList = (value: number | number[] | undefined, allUser) => {
  if (value === undefined) return [];
  if (Array.isArray(value)) {
    return value.map((id) => allUser?.find((a) => a.id === id));
  }
  const find = allUser?.find((a) => a.id === value);
  if (find) {
    return [find];
  }
  return [];
};
