import { projectSalesInfoSubjectReviewExperimentQuery } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/query/query';

export const projectSalesInfoSubjectReviewExperimentRepository = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoSubjectReviewExperimentQuery.useDetailGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
