import type { ChangeEvent } from 'react';
import React from 'react';
import classes from '@front/src/components/file-uploader/file-uploader.module.scss';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label?: string;
  id?: string;
}

export { FileUploader };
const FileUploader = ({ onChange, id, name, label = '파일 선택' }: Props) => (
  <>
    <input
      hidden
      type="file"
      name={`${name}_${id}`}
      id={`${name}_${id}`}
      onChange={onChange}
      className={classes.input}
    />
    <label
      htmlFor={`${name}_${id}`}
      className={classes.label}
    >
      {label}
    </label>
  </>
);
