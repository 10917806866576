import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';
import { PersonalSettingsModalTab } from '@front/src/features/personal-settings-modal/components/modal-tab';
import { PersonalSettingsModalSection } from '@front/src/features/personal-settings-modal/components/section';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';

interface Props {
  authMenuId: number | undefined;
  open: boolean;
  onClose: () => void;
}

const PersonalSettingsModal = ({ authMenuId, open, onClose }: Props) => {
  const { setOpen, isDirty, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      isDirty: state.isDirty,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const handleClose = () => {
    if (isDirty) {
      setTitle('닫기 확인');
      setDescriptionList(['저장되지 않은 변경사항은 반영되지 않습니다.', '모달을 닫으시겠습니까?']);
      setOpen(true, () => {});
      return;
    }
    onClose();
  };
  return (
    <ModalLayout
      open={open}
      onClose={handleClose}
      title="설정"
      width="1600px"
      height="84dvh"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        width="100%"
        height="100%"
      >
        <PersonalSettingsModalTab />
        <PersonalSettingsModalSection
          authMenuId={authMenuId}
          onClose={onClose}
        />
      </Box>
    </ModalLayout>
  );
};

export default PersonalSettingsModal;
