import React from 'react';
import ModalUI, { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';
import FileAuthSettingsFeature from '@front/src/features/file-item/features/auth-settings';
import FileAuthSettingsLoadingSpinner from '@front/src/features/file-item/features/auth-settings/components/LoadingSpinner';

interface Props {
  open: boolean;
  onClose: () => void;
  fileItemId: number | undefined;
  menuId: number | undefined;
  dataId: number | undefined;
}

export default function FileAuthSettingsModal(props: Readonly<Props>) {
  const { open, onClose, fileItemId, menuId, dataId } = props;

  return (
    <ModalUI
      title="권한 설정"
      open={open}
      onClose={onClose}
    >
      <>
        <FileAuthSettingsLoadingSpinner />
        <ModalBodyUI>
          <FileAuthSettingsFeature
            onClose={onClose}
            fileItemId={fileItemId}
            menuId={menuId}
            dataId={dataId}
          />
        </ModalBodyUI>
      </>
    </ModalUI>
  );
}
