import React, { memo, useCallback, useContext } from 'react';
import type { OstMemoId, OstMemoVO } from '@front/ost/domain';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import { FormikContext } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import OstMemoItemTitle from '@front/ost/view/memo/title';
import OstMemoItemUpdateForm from '@front/ost/view/memo/updateForm';
import OstMemoItemContent from '@front/ost/view/memo/content';
import useDialog from '@front/dialog/hook';
import type { OstMemoUpdateParameter } from '@front/ost/parameter';
import { ostAction } from '@front/ost/action';

interface Props {
  memo: OstMemoVO;
}

const MemoItemService = ({ memo }: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const formik = useContext(FormikContext);

  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );

  const onModify = useCallback(
    (params: OstMemoUpdateParameter) => () => {
      formik.setValues(params);
    },
    [formik]
  );
  const onDelete = useCallback(
    (id: OstMemoId) => () => {
      confirm({
        children: '해당 메모를 삭제하시겠습니까?',
        afterConfirm: () => {
          dispatch(ostAction.deleteMemo(id));
        },
        confirmText: '확인',
      });
    },
    [dispatch]
  );
  const onSave = useCallback(
    (id: OstMemoId) => () => {
      if (formik.values.id === id) {
        formik.handleSubmit();
      }
    },
    [dispatch, formik]
  );

  const onCancel = useCallback(
    (id: OstMemoId) => () => {
      if (formik.values.id === id) {
        formik.resetForm();
      }
    },
    [dispatch, formik]
  );

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value ?? '';
      if (value !== formik.values.description) {
        formik.setFieldValue('description', value);
      }
    },
    [dispatch, formik]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: ColorPalette._ffffff,
        padding: '15px',
      }}
    >
      <OstMemoItemTitle
        login={login}
        memo={memo}
        onModify={onModify}
        onDelete={onDelete}
      />
      {formik.values.id !== memo.id && <OstMemoItemContent memo={memo} />}
      {formik.values.id === memo.id && (
        <OstMemoItemUpdateForm
          memo={memo}
          onSave={onSave}
          onCancel={onCancel}
          onBlur={onBlur}
        />
      )}
    </Box>
  );
};

const OstMemoItemService = memo(MemoItemService);
export default OstMemoItemService;
