import type { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import { TableRow } from '@mui/material';
import classes from '@front/src/components/layout/add-delete-table/item-table-row.module.scss';

export { ItemTableRow as AddDeleteTableItemTableRow };

interface Props {
  children: ReactNode;
  hover?: boolean;
  pointer?: boolean;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  className?: string;
  sx?: CSSProperties;
}

const ItemTableRow = ({
  children,
  className,
  sx,
  hover = true,
  pointer = true,
  ...rest
}: Props) => (
  <TableRow
    hover={hover}
    className={`${pointer && classes.cursor__pointer} ${className}`}
    sx={sx}
    {...rest}
  >
    {children}
  </TableRow>
);
