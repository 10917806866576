import dayjs from "dayjs";
import {YYYY_MM_DD} from "@front/src/utils";

export const getProjectSalesInfoDrawerCreateModalEstimationStatusOptionList = (list) =>
  list?.map((item) => ({
    label: item.label,
    value: item.value,
  }));

export const getSortedExperimentString = (value: string[]) => {
  const customSort = (a, b) => {
    const order = { F: 1, P: 2, A: 3 };
    return order[a] - order[b];
  };
  return [...value].sort(customSort).join(', ');
};

export const formatDateOrNull = (data: string | null) =>
  data? dayjs(data).format(YYYY_MM_DD) : null;