import { AccountingSettingsBankAccountRepository } from '@front/src/features/accounting/features/settings/features/bank-account/repository/repository';

export { useLogic as useAccountingSettingsBankAccountListLogic };

const useLogic = (menuId) => {
  const { data: list } = AccountingSettingsBankAccountRepository.useGetList(menuId);
  const { run: onUpdate } = AccountingSettingsBankAccountRepository.useUpdate(menuId);
  return {
    list,
    onUpdate,
  };
};
