import React, { useCallback, useMemo } from 'react';
import Button from '@front/layouts/Button';
import { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { useAccountingUploadEcountDataState } from '@front/src/features/accounting/features/upload/features/ecount-data/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { useShallow } from 'zustand/react/shallow';

export { UploadButton as AccountingUploadEcountDataDownloadTemplateButton };

const UploadButton = () => {
  const { ecountFileType } = useAccountingUploadEcountDataState(
    useShallow((state) => ({
      ecountFileType: state.ecountFileType,
    }))
  );
  const fileName = useMemo(() => {
    if (ecountFileType === ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT) {
      return 'ecount_data_template.xlsx';
    } else if (ecountFileType === ECOUNT_FILE_TYPES.BANK_ACCOUNT) {
      return 'ecount_bank_account_template.xlsx';
    } else if (ecountFileType === ECOUNT_FILE_TYPES.DAILY_FUNDS) {
      return 'daily_fund_template.xlsx';
    }
  }, [ecountFileType]);
  const { alert } = useCustomDialog();
  const handleTemplateDownload = useCallback(() => {
    if (!fileName || !ecountFileType) {
      alert({
        children: [
          {
            value: '처리 대상을 선택해주세요.',
          },
        ],
      });
      return;
    }
    window.location.assign(`/api/file-item/template?fileName=${fileName}`);
  }, [fileName, alert, ecountFileType]);
  return <Button onClick={handleTemplateDownload}>표준 파일 템플릿 다운로드</Button>;
};
