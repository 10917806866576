import { default as axios } from '@front/src/config/axios';

import type { OstId } from '@front/ost/domain';
import type { OstRewardManagementMemoId } from '@front/ost_reward/domian';
import type {
  OstRewardManagementMemoCreateParameter,
  OstRewardManagementMemoUpdateParameter,
} from '@front/ost_reward/parameter';
import type { OstRewardManagementSearchQuery } from '@front/ost_reward/query';
import type { CampaignId } from '@front/ost_campaign/domain';

export const ostRewardManagementApi = {
  // 드로워 리스트
  getList: async () => {
    const { data } = await axios.get(`/api/ost/rewards`);
    return data;
  },

  // 예산 집행 현황 보기
  getTotalStatusList: async () => {
    const { data } = await axios.get(`/api/ost/yearly-rewards`);
    return data;
  },

  // 상세
  getDetailStatusList: async (id: OstId) => {
    const { data } = await axios.get(`/api/ost/${id}/monthly-rewards`);
    return data;
  },

  getMemoList: async (id: OstId, query: OstRewardManagementSearchQuery) => {
    const { data } = await axios.get(`/api/reward/${id}/memo?keyword=${query.keyword}`);
    return data;
  },

  createMemo: async (id: OstId, params: OstRewardManagementMemoCreateParameter) => {
    const { data } = await axios.post(`/api/reward/${id}/memo`, params);
    return data;
  },

  updateMemo: async (id: OstId, params: OstRewardManagementMemoUpdateParameter) => {
    const { data } = await axios.put(`/api/reward/${id}/memo/${params.id}`, params);
    return data;
  },

  deleteMemo: async (id: OstId, ostRewardManagementMemoId: OstRewardManagementMemoId) => {
    const { data } = await axios.delete(`/api/reward/${id}/memo/${ostRewardManagementMemoId}`);
    return data;
  },

  getMonthDetail: async (id: OstId, month: number) => {
    const { data } = await axios.get(`/api/ost/${id}/monthly-rewards/${month}`);
    return data;
  },

  getCampaignRewards: async (ostId: OstId, campaignId: CampaignId) => {
    const { data } = await axios.get(`/api/ost/${ostId}/campaign/${campaignId}/campaign-rewards`);
    return data;
  },

  fixResult: async (id: OstId, month: number) => {
    const { data } = await axios.post(`/api/ost/${id}/monthly-rewards/${month}/fix`);
    return data;
  },
};
