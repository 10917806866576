import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  projectSalesInfoContractCategoryKey,
  projectSalesInfoContractTypeKey,
} from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { projectSalesInfoContractReviewHistoryModalRepository } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/repository';
import { useProjectSalesInfoContractReviewHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/useState';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoContractReviewHistoryModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    isOpen,
    setIsOpen,
    id: modalId,
    setId,
  } = useProjectSalesInfoContractReviewHistoryModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { data: detail } = projectSalesInfoContractReviewHistoryModalRepository.useDetailGet(
    modalId,
    menuId
  );
  const { run: onCreate, setCallback: setCreateCallback } =
    projectSalesInfoContractReviewHistoryModalRepository.useCreate(menuId);
  const { run: onUpdate, setCallback: setUpdateCallback } =
    projectSalesInfoContractReviewHistoryModalRepository.useUpdate(menuId);
  const { data: categoryList } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractCategoryKey,
    menuId
  );
  const { data: typeList } = adminManageableVariableRepository.useListGet(
    projectSalesInfoContractTypeKey,
    menuId
  );
  const { data: companyList } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useListGet(id, menuId);
  setCreateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  setUpdateCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setIsOpen(false);
  }, [setIsOpen, setId]);
  const d = { detail, categoryList, typeList, companyList };
  const h = { onCreate, onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isOpen,
  };
};
