import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkCarouselBundleCreateParameter,
  WorkCarouselBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/carousel-bundle/types/parameter';
import type { BundleUpdateSeqParameter } from '@front/src/features/work/features/work/types/parameter';

const workCarouselBundleMutation = {
  useCreate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkCarouselBundleCreateParameter>({
      mutationFn: (params) => workWorkApi.createCarouselBundle(params, commonParams),
      mutationKey: ['work', 'carousel-bundle', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries(['work', 'carousel-bundle']);
      },
    });
    return mutate;
  },

  useUpdate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkCarouselBundleUpdateParameter>({
      mutationFn: (params) => workWorkApi.updateCarouselBundle(params, commonParams),
      mutationKey: ['work', 'carousel-bundle', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries(['work', 'carousel-bundle']);
      },
    });
    return mutate;
  },

  useDelete: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, number>({
      mutationFn: (id) => workWorkApi.deleteCarouselBundle(id, commonParams),
      mutationKey: ['work', 'carousel-bundle', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries(['work', 'carousel-bundle']);
      },
    });
    return mutate;
  },

  useMove: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, BundleUpdateSeqParameter>({
      mutationFn: (params) => workWorkApi.moveCarouselBundle(params, commonParams),
      mutationKey: ['work', 'carousel-bundle', 'move'],
      onSettled: async () => {
        await queryClient.invalidateQueries(['work', 'carousel-bundle']);
      },
    });
    return mutate;
  },
};

export default workCarouselBundleMutation;
