import { AccountingSettingsTaxAccountingAccountQuery } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/query/query';

export { Repository as AccountingSettingsTaxAccountingAccountRepository };

const Repository = {
  useAllGet: (menuId) => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useListGet(menuId);
    return {
      data,
      isLoading,
    };
  },
  useCountGet: (menuId) => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useCountGet(menuId);
    return {
      data,
      isLoading,
    };
  },
  useCreatedAt: (menuId) => {
    const { data, isLoading } = AccountingSettingsTaxAccountingAccountQuery.useCreatedAtGet(menuId);
    return {
      data,
      isLoading,
    };
  },
};
