import { projectSalesInfoCMPaymentHistoryMutation } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { projectSalesInfoCMPaymentHistoryQuery } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/query';

export const projectSalesInfoCMPaymentHistoryRepository = {
  useCMPaymentHistoryGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoCMPaymentHistoryQuery.useCMPaymentHistoryGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useCMPaymentHistoryDelete: (id: number, menuId) => {
    const { mutate } = projectSalesInfoCMPaymentHistoryMutation.useCMPaymentHistoryDelete(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: IdListDeleteParameter) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
