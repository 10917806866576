import React, { Suspense } from 'react';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import noteQuery from '@front/src/features/note/query/query';
import noteMutation from '@front/src/features/note/query/mutation';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { AuthorizationType, UserId } from '@front/user/domain';
import NoteUserSelectorModal from '@front/src/features/note/components/NoteUserSelectorModal';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import type { AuthUserView } from '@front/src/features/note/types/view';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  noteId: number;
  reviewerList: AuthUserView[];
}

export default function NoteUserSelectorModalWrapperContainer({ noteId, reviewerList }: Props) {
  const isUserSelectorOpen = useNoteStore((state) => state.isUserSelectorOpen);
  return (
    <>
      {isUserSelectorOpen && (
        <Suspense fallback={<LoadingSpinnerUI />}>
          <NoteUserSelectorModalWrapper
            reviewerList={reviewerList}
            noteId={noteId}
          />
        </Suspense>
      )}
    </>
  );
}

function NoteUserSelectorModalWrapper({ noteId, reviewerList }: Props) {
  const { setSelectedUserList } = useNoteStore(
    useShallow((state) => ({
      setSelectedUserList: state.setSelectedUserList,
    }))
  );

  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );

  const { menuId, dataId } = useIdGroup();

  const { data: reviewerDetail } = noteQuery.useGetReviewerDetail(noteId);
  const { mutate: updateReview } = noteMutation.useUpdateReview(reviewerDetail?.id ?? 0);
  const { mutate: createReview } = noteMutation.useCreateReview(noteId);

  const isUpdate = reviewerDetail?.reviewDate;

  return (
    <>
      {reviewerList && (
        <NoteUserSelectorModal
          key={Math.random()}
          multi={true}
          onChange={(value, date, addList, deleteList) => {
            setSelectedUserList(value as UserId[]);
            isUpdate
              ? updateReview({
                  reviewDate: date,
                  addReviewerInfoIdList: addList ?? [],
                  deleteReviewerInfoIdList: deleteList ?? [],
                })
              : createReview({
                  reviewDate: date,
                  reviewerInfoIdList: value as number[],
                  menuId,
                  dataId,
                  targetId: noteId,
                });
          }}
          fixedList={
            reviewerList?.filter((item) => item.id !== login?.id).map((item) => item.id) ?? []
          }
          value={reviewerDetail?.reviewerList?.map((item) => item.reviewerId) as UserId[]}
          reviewerDetail={reviewerDetail}
          typeList={[AuthorizationType.R, AuthorizationType.W]}
          menuIdList={[menuId, JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}').noteId]}
        />
      )}
    </>
  );
}
