import React from 'react';
import CheckboxBasicUI from '@front/src/components/components-with-design/component/CheckboxBasicUI';
import { useController, useFormContext } from 'react-hook-form';
import type { FilterFormValues } from '@front/src/components/ui-builder/filter/hooks/useForm';

interface Props {
  index: number;
}

export default function UIBuilderFilterItem({ index }: Readonly<Props>) {
  const { control } = useFormContext<FilterFormValues>();

  const {
    field: { value: item, onChange },
  } = useController({
    control,
    name: `filterBundle.filter.${index}`,
  });

  const handleChange = (e) => {
    onChange({
      ...item,
      checked: e.target.checked,
    });
  };

  const { checked, shown, value } = item;

  return (
    <CheckboxBasicUI
      checked={checked}
      label={value}
      onChange={handleChange}
      disabled={!shown}
    />
  );
}
