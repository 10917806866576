import React, { memo } from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewShapeRatioListWidget } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/widgets/widget';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ShapeRatioTable as ProjectSalesInfoSubjectReviewShapeRatioTable };

const ShapeRatioTable = () => {
  const setId = useProjectSalesInfoSubjectReviewModalState((state) => state.setId);
  return (
    <RowClickTableContainer
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
        maxHeight: '400px',
        overflow: 'auto',
      }}
      setId={setId}
    >
      <Table>
        <TableHeader />
        <TableBody>
          <ProjectSalesInfoSubjectReviewShapeRatioListWidget />
        </TableBody>
      </Table>
    </RowClickTableContainer>
  );
};

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="60px">순번</Th>
      <Th width="120px">도면수급일</Th>
      <Th width="100px">확정여부</Th>
      <Th width="100px">적용기준</Th>
      <Th width="88px">실험대상</Th>
      <Th width="220px">형상비검토 엑셀파일</Th>
      <Th width="80px">검토자</Th>
      <Th width="140px">등록일시</Th>
      <Th width="200px">비고</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoSubjectReviewShapeRatioTable';
