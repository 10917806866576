import { useCustomDialog } from '@front/src/features/dialog';
import useWorkWorkerUpdate from '@front/src/features/work/features/work/features/worker/repository/useUpdate';
import useWorkWorkerCreate from '@front/src/features/work/features/work/features/worker/repository/useCreate';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import useWorkWorkerSeqUp from '@front/src/features/work/features/work/features/worker/repository/useSeqUp';
import useWorkWorkerSeqDown from '@front/src/features/work/features/work/features/worker/repository/useSeqDown';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkWorkerTableRowMutation = ({
  sectionId,
  dataId,
  menuId,
  onCloseEditModal,
  reset,
  formContext,
}) => {
  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const { toggleEditOpenId, setRecentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();
  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkWorkerUpdate(commonParams);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
      onCloseEditModal(reset);
    },
  });

  const { run: onCreate, setCallback: setCreateCallback } = useWorkWorkerCreate(commonParams);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDelete } = workWorkerMutation.useDelete(commonParams);
  const { run: onSeqUp, setCallback: setSeqUpCallback } = useWorkWorkerSeqUp(commonParams);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onSeqDown, setCallback: setSeqDownCallback } = useWorkWorkerSeqDown(commonParams);
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });
  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };

  return {
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
  };
};

export default useWorkWorkerTableRowMutation;
