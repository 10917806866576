import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from 'services/store';
import 'dayjs/locale/ko';
import { ThemeProvider } from '@mui/material/styles';
import mainTheme from 'assets/theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import UserAuthenticationForm from '@front/user/view/Page/UserAuthenticationForm';
import CustomSnackbarProvider from '@front/src/config/SnackbarProvider';

import { App } from '@front/src/app/app';
import CustomQueryClientProvider from '@front/src/config/QueryClientProvider';
import RootErrorBoundary from '@front/src/components/error/RootErrorBoundary';

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Provider store={store}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="ko"
        >
          <ThemeProvider theme={mainTheme}>
            <CustomQueryClientProvider>
              <CustomSnackbarProvider>
                <RootErrorBoundary>
                  <App />
                </RootErrorBoundary>
              </CustomSnackbarProvider>
            </CustomQueryClientProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Provider>
    ),
  },
  {
    path: '/user/authenticate',
    element: <UserAuthenticationForm />,
  },
]);

const render = () => {
  const root = createRoot(document.getElementById('root')!);
  root.render(<RouterProvider router={router} />);
};

render();

if (module.hot) {
  module.hot.accept('/', render);
}
