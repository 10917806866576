import { useIsMutating } from 'react-query';

const useWorkReferenceGetIsLoading = () => {
  const isAddTopLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'add-top'],
  });
  const isAddBottomLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'add-bottom'],
  });
  const isCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'create'],
  });
  const isUpdateFileLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'update-file'],
  });
  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'update'],
  });
  const isSeqUpLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'seq-up'],
  });
  const isSeqDownLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'seq-down'],
  });
  const isDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'delete'],
  });
  const isDeleteAllLoading = !!useIsMutating({
    mutationKey: ['work', 'reference', 'delete-all'],
  });
  return (
    isDeleteAllLoading ||
    isAddTopLoading ||
    isAddBottomLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isSeqUpLoading ||
    isSeqDownLoading ||
    isUpdateFileLoading
  );
};

export default useWorkReferenceGetIsLoading;
