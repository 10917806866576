import { create } from 'zustand';
import type { WidthInfoType } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { useState as useAccountingSettingsTaxAccountingAccountState };

interface State {
  parentIdList: number[];
  setParentIdList: (parentIdList: number[]) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  widthInfo?: WidthInfoType;
  setWidthInfo: (widthInfo: WidthInfoType) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

const useState = create<State>((set) => ({
  parentIdList: [],
  setParentIdList: (parentIdList) =>
    set({
      parentIdList,
    }),
  isOpen: false,
  setIsOpen: (isOpen) =>
    set({
      isOpen,
    }),
  setWidthInfo: (widthInfo) =>
    set({
      widthInfo,
    }),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
