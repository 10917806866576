import React from 'react';
import cellComponent from '@front/src/components/ui-builder/cellComponent';
import type { HeaderSettingView } from '@front/src/types';
import UIBuilderEditModal from '@front/src/components/ui-builder/table/EditModal';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import type { SxProps } from '@mui/system';

interface Props extends UIBuilderTableRowProps {
  header: HeaderSettingView;
  sx?: SxProps;
}

export default function UIBuilderTableCell(props: Readonly<Props>) {
  const { header, isEditMode, item, sx, formContext } = props;
  const { reset } = useFormContext();
  const { setValue, watch } = formContext;
  const hasModal = header.modalHeaderList.length > 0;

  const handleClick = () => {
    if (!hasModal || isEditMode) return;
    setValue('headerId', header.id);
    setValue('rowId', item.id);
  };

  const headerId = watch('headerId');
  const rowId = watch('rowId');

  const open = headerId === header.id && rowId === item.id;
  const onClose = () => {
    reset();
    setValue('headerId', undefined);
    setValue('rowId', undefined);
  };

  const CellComponent = cellComponent[header.id];
  const cellSx =
    !hasModal || isEditMode
      ? {}
      : {
          textDecoration: 'underline',
          cursor: 'pointer',
        };

  return (
    <TableCellUI sx={sx}>
      {hasModal && (
        <UIBuilderEditModal
          {...props}
          open={open}
          onClose={onClose}
          header={header}
        />
      )}
      <CellComponent
        {...props}
        onClick={handleClick}
        sx={cellSx}
      />
    </TableCellUI>
  );
}
