import { HeaderSettingView } from '@front/src/types';
import React, { useEffect, useRef } from 'react';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import { controlPanelTableCellWidth } from '@front/src/utils';

interface FixedColumnSupportProps {
  isDataReady: boolean;
  headerList: HeaderSettingView[] | undefined;
  readOnly: boolean;
  useNote: boolean;
  children: React.ReactNode;
}

export default function FixedColumnSupport(props: FixedColumnSupportProps): React.ReactElement {
  const { isDataReady, headerList, readOnly, useNote, children } = props;
  const fixedColumnSupportRef = useRef<HTMLTableSectionElement | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (isDataReady) {
        const thElement = fixedColumnSupportRef.current?.querySelector('th');
        if (thElement) {
          handleFixedColumnsOfTable(fixedColumnSupportRef, headerList, readOnly, useNote);
        }
      }
    });
    if (fixedColumnSupportRef.current) {
      observer.observe(fixedColumnSupportRef.current, { childList: true, subtree: true });
    }
    return () => observer.disconnect();
  }, [isDataReady, fixedColumnSupportRef]);

  return (
    <div
      ref={fixedColumnSupportRef}
      className={`fixed-column-support-wrapper ${isDataReady ? 'ready' : 'loading'}`}
    >
      {children}
    </div>
  );
}

function handleFixedColumnsOfTable(
  ref: React.MutableRefObject<HTMLTableSectionElement | null>,
  headerList: undefined | HeaderSettingView[],
  readOnly: boolean,
  useNote: boolean
) {
  if (!headerList) return;
  const fixedColumnIndex = headerList.findIndex((header) => header.isFixed);
  if (fixedColumnIndex == null || fixedColumnIndex < 0) return;

  const tableElement = ref.current?.querySelector('table');
  if (!tableElement) return;
  const tableBodyElement = tableElement.querySelector('tbody');
  if (!tableBodyElement) return;
  const tableBodyFirstTrElement = tableBodyElement.querySelector('tr');
  if (!tableBodyFirstTrElement) return;

  const systemColumnCounts = (readOnly ? 0 : 1) + (useNote ? 1 : 0);
  const cells = tableBodyFirstTrElement.getElementsByTagName('td');
  const mergedCells = Array.from(cells).map((cell) => cell.rowSpan > 1);

  const rowElements = tableElement.getElementsByTagName('tr');
  Array.from(rowElements).forEach((rowElement, index) => {
    handleFixedColumnsOfRows(
      index,
      rowElement,
      headerList,
      fixedColumnIndex,
      systemColumnCounts,
      mergedCells
    );
  });
}

function handleFixedColumnsOfRows(
  rowIndex: number,
  rowElement: HTMLTableRowElement,
  headerList: HeaderSettingView[],
  fixedColumnIndex: number,
  systemColumnCounts: number,
  mergedCells: boolean[]
) {
  const ths = rowElement.getElementsByTagName('th');
  const tds = rowElement.getElementsByTagName('td');
  const cells = ths.length > 0 ? ths : tds;
  if (!cells) return;
  rowElement
    .querySelectorAll('td.fixed, th.fixed')
    .forEach((value) => releaseColumn(value as HTMLElement));

  const realFixedColumnIndex = fixedColumnIndex + systemColumnCounts;
  let leftOffsetSum = 0;
  let skippedCellCount = 0;
  for (let i = 0; i <= realFixedColumnIndex; i++) {
    const width =
      i < systemColumnCounts
        ? controlPanelTableCellWidth
        : headerMeta[headerList[i - systemColumnCounts].id].width;
    if (rowIndex < 2) {
      fixColumn(cells[i], leftOffsetSum);
    } else {
      if (mergedCells[i]) {
        skippedCellCount++;
        leftOffsetSum += width;
        continue;
      }
      fixColumn(cells[i - skippedCellCount], leftOffsetSum);
    }
    if (i === realFixedColumnIndex) {
      cells[i - skippedCellCount]?.classList.add('last-fixed');
    }
    leftOffsetSum += width;
  }
}

function fixColumn(element: HTMLElement, columnLeftOffset) {
  if (!element) return;
  element.classList.add('fixed');
  element.classList.remove('last-fixed');
  element.style.left = `${columnLeftOffset}px`;
}

function releaseColumn(element: HTMLElement) {
  if (!element) return;
  element.classList.remove('fixed');
  element.classList.remove('last-fixed');
  element.style.removeProperty('left');
}
