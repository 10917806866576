import React, { useContext } from 'react';
import { AccountingSettingsBankAccountListContext } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/provider/context';
import { Box } from '@mui/material';
import { convertDateToStringFormat, DATE_TIME_FORMAT } from '@front/src/utils';
import { AccountingSettingsBankAccountUploadWidget } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/widget';
import TextBox from '@front/layouts/Text';

export { Header as AccountingSettingsBankAccountListHeader };
interface Props {
  menuId?: number;
}
const Header = ({ menuId }: Props) => {
  const { list } = useContext(AccountingSettingsBankAccountListContext);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
      }}
    >
      <TextBox variant="body1">E계좌 데이터 업데이트 일시:</TextBox>
      <TextBox variant="body1">
        {convertDateToStringFormat(list?.[0]?.createdAt, DATE_TIME_FORMAT)}
      </TextBox>
      <AccountingSettingsBankAccountUploadWidget menuId={menuId} />
    </Box>
  );
};
