import React from 'react';
import type { AppRoute } from 'services/routes';
import FormToFindPassword from 'login/view/FormToFindPassword';

function Element() {
  return <FormToFindPassword />;
}

const loginForgotRoute: AppRoute = {
  path: '/login/forgot',
  element: <Element />,
};

export default loginForgotRoute;
