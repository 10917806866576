import { accountingDailyReportURL } from '@front/src/features/accounting/features/settings/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as AccountingSettingsDailyReportStatusApi };

const url = {
  getList: () => `${accountingDailyReportURL}`,
};

const Api = {
  getList: async (menuId) => {
    const { data } = await axios.get(url.getList(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
