import { notificationApi } from '@front/src/features/notification/api/api';
import { useQuery, useQueryClient } from 'react-query';
import type { UserNotificationSearchParameter } from '@front/src/features/notification/types/parameter';

const notificationQuery = {
  useGetList: (params: UserNotificationSearchParameter) => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'list', params],
      queryFn: () => notificationApi.getList(params),
      suspense: true,
    });

    return {
      data,
    };
  },

  useGetLink: (notificationId: number, isEnabled: boolean) => {
    const queryClient = useQueryClient();
    const { data, isLoading } = useQuery({
      queryKey: ['user-notification', 'link', notificationId],
      queryFn: () => notificationApi.getLink(notificationId),
      enabled: isEnabled,
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'count'] });
      },
    });

    return {
      data,
      isLoading,
    };
  },

  useGetCount: () => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'count'],
      queryFn: () => notificationApi.getCount(),
      suspense: true,
    });

    return {
      data,
    };
  },

  useGetFilterList: () => {
    const { data } = useQuery({
      queryKey: ['user-notification', 'filter'],
      queryFn: () => notificationApi.getFilterList(),
      suspense: true,
    });

    return {
      data,
    };
  },
};

export default notificationQuery;
