import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { FormProvider } from 'react-hook-form';
import UiBuilderTableRow, { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/Note';
import { ColorPalette } from '@front/assets/theme';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useAccountStatusDetailTableRowFormHook
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/hook/detailRowForm';
import useAccountStatusState
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/state';
import AccountStatusRepository
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/repository';
import { NoteMenuIdView } from '@front/src/features/note/types/view';
import type { ControlPanelItem } from '@front/src/types/domain';
import type {
  AccountStatusDetailView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';
import useNoteStore from '@front/src/features/note/useState';


type Props = Readonly<UIBuilderTableRowProps<AccountStatusDetailView>>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UiBuilderTableRow>;


const AccountStatusWidgetDetailTableRow: ElementType = (props) => {

  const { item, menuId, sectionId, formContext, onCloseEditModal } = props;

  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );

  const { watch } = formContext;
  const editOpenIdList = watch('editOpenIdList');
  const isEditOpen = editOpenIdList.includes(item.id);
  const {
    setRecentUpdatedId,
    toggleEditOpenId,
    recentUpdatedId,
  } = useAccountStatusState(formContext);

  const { methods } = useAccountStatusDetailTableRowFormHook(item);

  const { handleSubmit, reset } = methods;
  const { run: onUpdate, setCallback: setUpdateCallback } = AccountStatusRepository.useUpdate({ menuId, sectionId });

  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
      onCloseEditModal(reset);
      toggleEditOpenId(item.id);
    },
  });

  const onSubmit = handleSubmit((data) => {
    onUpdate({
      id: item.id,
      alias: data.alias || undefined,
    });
  });

  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);

  const itemList: ControlPanelItem[] = [{
    text: isEditOpen ? '수정 닫기' : '수정',
    icon: EditIcon,
    action: () => {
      toggleEditOpenId(item.id);
    },
    split: true,
    seq: 2,
  }, {
    text: '비고',
    icon: NoteIcon,
    action: () => {
      const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
      if (!noteAuth.noteId || noteAuth.type === 'X') {
        alert({
          title: '권한 오류',
          children: [{ value: '접근 권한이 없습니다.' }],
        });
        return;
      }
      setIsRightDrawerOpen(true);
      setRowId(item.id);
      setSectionId(sectionId!);
    },
    seq: 3,
  }];

  const sx = {
    backgroundColor: recentUpdatedId === item.id ? ColorPalette._d2e7fa : 'inherit',
  };

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        sx={sx}
        itemList={itemList}
        onSubmit={onSubmit}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
};

export default AccountStatusWidgetDetailTableRow;
