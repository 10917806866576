import React from 'react';
import { ProjectSalesInfoContractEarningsItemManageCollectionModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/widget';
import { useProjectSalesInfoContractEarningsItemUpdateModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/useState';

export { ModalWrapper as ProjectSalesInfoCollectionManagementModalWrapper };

const ModalWrapper = () => {
  const isManagementModalOpen = useProjectSalesInfoContractEarningsItemUpdateModalState(
    (state) => state.isManagementModalOpen
  );
  return (
    <>
      {isManagementModalOpen && <ProjectSalesInfoContractEarningsItemManageCollectionModalWidget />}
    </>
  );
};
