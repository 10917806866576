// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pkilBDf2Scy6S_wQTSNJ{display:flex;justify-content:center;flex-direction:column;align-items:center;padding:10px;gap:10px;margin:5px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/components/layout/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".layout {\r\n  display: flex;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding: 10px;\r\n  gap: 10px;\r\n  margin: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `pkilBDf2Scy6S_wQTSNJ`
};
export default ___CSS_LOADER_EXPORT___;
