import { useMutation, useQueryClient } from 'react-query';
import type { AxiosError } from 'axios';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { projectSalesApi } from '@front/src/features/project-sales/api/api';
import { ProjectSalesCreateParameters } from '@front/src/features/project-sales/features/aside/type/parameters';

const projectSalesMutation = {
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<any, AxiosError, ProjectSalesCreateParameters>({
      mutationFn: (params) => projectSalesApi.create(params, commonParams),
      mutationKey: ['project', 'sales', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default projectSalesMutation;
