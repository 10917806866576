import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

const useContractHistoryTableRowForm = (item: ContractHistoryView) => {
  const methods = useForm<ContractHistoryView>({
    defaultValues: getFormValues(item),
  });

  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

const getFormValues = (item: ContractHistoryView) => ({
  id: item.id ?? null,
  confirmed: item.confirmed ? 'Y' : 'N',
  seq: item.seq ?? '',
  estimationCode: item.estimationCode ?? '',
  orderType: item.orderType ?? '',
  orderDate: item.orderDate ?? '',
  contractType: item.contractType ?? '',
  contractDate: item.contractDate ?? null,
  detail: item.detail ?? null,
});

export default useContractHistoryTableRowForm;
