import React from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ApprovalStepControlPanel from '@front/src/features/drawer-approval/components/ApprovalStepControlPanel';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ApprovalStepTableRow from '@front/src/features/drawer-approval/components/ApprovalStepTableRow';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { Box, Typography } from '@mui/material';
import { SX_APPROVAL_SECTION } from '@front/src/features/drawer-approval/components/ApprovalContent';

export default function ApprovalStepTable() {
  const { control } = useFormContext();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'request.approvalList',
  });
  return (
    <Box>
      <Typography sx={{ ...SX_APPROVAL_SECTION }}>결재 단계</Typography>
      <TableContainerUI>
        <TableTableUI>
          <TableHeadUI>
            <TableRowUI>
              <TableCellUI
                isHead={true}
                sx={{ width: '7%' }}
              >
                <ApprovalStepControlPanel
                  isHeader={true}
                  append={append}
                  remove={remove}
                />
              </TableCellUI>
              <TableCellUI
                isHead={true}
                sx={{ width: '20%' }}
              >
                순서
              </TableCellUI>
              <TableCellUI
                isHead={true}
                sx={{ width: '23%' }}
              >
                부서
              </TableCellUI>
              <TableCellUI
                isHead={true}
                sx={{ width: '15%' }}
              >
                직책
              </TableCellUI>
              <TableCellUI
                isHead={true}
                sx={{ width: '35%' }}
              >
                성명
              </TableCellUI>
            </TableRowUI>
          </TableHeadUI>
          <TableBodyUI>
            {fields.length === 0 && <NoResult colSpan={5} />}
            {fields.map((field, index) => (
              <ApprovalStepTableRow
                key={field.id}
                index={index}
                append={append}
                remove={remove}
                move={move}
                id={field.id}
              />
            ))}
          </TableBodyUI>
        </TableTableUI>
      </TableContainerUI>
    </Box>
  );
}
