import React from 'react';

import AffiliatedCompanyPersonDetailModalCharacterWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/character/widget';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedPersonCharacterFeature = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={props.name} />
      <Body>
        <AffiliatedCompanyPersonDetailModalCharacterWidget {...props} />
      </Body>
    </Layout>
  );
};

export default AffiliatedPersonCharacterFeature;
