import React, {useCallback} from 'react';
import IconButton from '@front/layouts/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/material';
import {useShallow} from 'zustand/react/shallow';
import {useGlobalNavBarState} from '@front/src/features/global-nav-bar/useState';

export { Hamburger as GlobalNavBarHamburger };
const Hamburger = () => {
  const { open, toggle } = useGlobalNavBarState(
    useShallow((state) => ({
      open: state.open,
      toggle: state.toggle,
    }))
  );
  const handleButtonClick = useCallback(() => {
    toggle();
  }, [toggle]);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        tooltip={'메인 메뉴 ' + (open ? '접기' : '펴기')}
        onClick={handleButtonClick}
        sx={{
          backgroundColor: '#3b4f7d',
          borderRadius: '3px',
          width: '20px',
          height: '32px',
          '&:hover': {
            backgroundColor: '#3b4f7d',
          },
        }}
      >
        <FontAwesomeIcon
          icon="angle-left"
          style={{
            fontSize: '1.4rem',
            color: '#ffffff',
            transition: 'transform .2s',
            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      </IconButton>
    </Box>
  );
};
