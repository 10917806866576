import React, {useEffect, useRef, useState} from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import Box from '@mui/material/Box';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import WorkCarouselCard from '@front/src/features/work/features/work/features/carousel/components/Card';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSizer from 'react-virtualized-auto-sizer';
import WorkCarouselNoImageCard from '@front/src/features/work/features/work/features/carousel/components/NoImageCard';
import WorkCarouselMutationLoadingSpinner from '@front/src/features/work/features/work/features/carousel/components/MutationLoadingSpinner';
import workCarouselQuery from '@front/src/features/work/features/work/features/carousel/query/query';
import TooltipUI from '@front/src/components/components-with-design/component/TooltipUI';
import useWorkCarouselSwiper from '@front/src/features/work/features/work/features/carousel/hooks/useSwiper';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';
import {ColorPalette} from "@front/assets/theme";
import useNoteStore from "@front/src/features/note/useState";
import {useShallow} from "zustand/react/shallow";
import {noteAutoScrollingAnchorClassName} from "@front/src/features/note/utils";
import {sectionWrapperClassName} from "@front/src/components/ui-builder/CommonSectionList";
import {scrollToElement} from "@front/src/utils";
import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";

export default function WorkCarousel(props: Readonly<WorkCarouselSectionProps>) {
  const { readOnly, menuId, dataId, sectionId, item: bundleItem } = props;
  const { data: list } = workCarouselQuery.useListGet(bundleItem.id, {
    dataId,
    menuId,
    sectionId,
  });

  const swiperKey = `${dataId}_${bundleItem.id}`;
  const { params, onSlidePrev, onSlideNext } = useWorkCarouselSwiper({ dataId: swiperKey });
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

  const renderNoImageCards = () => {
    if (!list) return <></>;
    if (list.length > 4) return <></>;
    return new Array(6 - list.length).fill(0).map((_, index) => (
      <SwiperSlide key={`no-image-${index}`}>
        <WorkCarouselNoImageCard>이미지 없음</WorkCarouselNoImageCard>
      </SwiperSlide>
    ));
  };

  const renderCards = () => (
    <>
      {list?.map((item) => (
        <SwiperSlide
          data-hash={`slide-${item.seq}`}
          key={item.id}
        >
          <WorkCarouselCard
            menuId={menuId}
            dataId={dataId}
            sectionId={sectionId}
            item={item}
            readOnly={readOnly}
          />
        </SwiperSlide>
      ))}
      {renderNoImageCards()}
    </>
  );

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { leftDrawerState } = useGlobalNavBarState();
  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );

  useEffect(() => {
    if (swiperInstance && noteSectionId && rowId && leftDrawerState === 'notification') {
      const activeSeq = list?.find((item) => item.id === rowId)?.seq ?? 0;
      if (activeSeq > 0) {
        const elements = document.getElementsByClassName(
          `${noteAutoScrollingAnchorClassName}-${rowId}`
        );
        if (elements[0]) {
          const sectionWrapper = elements[0].closest(`.${sectionWrapperClassName}`);
          if (sectionWrapper) {
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              scrollToElement(
                sectionWrapper as HTMLElement,
                () => {
                  swiperInstance.slideToLoop(activeSeq - 1, 1000);
                },
                0.5
              );
              timeoutRef.current = null;
            }, 600);
          }
        }
      }
    }
  }, [timeoutRef.current, swiperInstance, noteSectionId, rowId, leftDrawerState]);

  if (list?.length === 0) return (
    <Box sx={{
      display: 'flex',
      height: '400px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundColor: ColorPalette.background.bg02
    }}>등록된 이미지가 없습니다</Box>
  );


  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '400px',
      }}
    >
      <WorkCarouselMutationLoadingSpinner />
      <AutoSizer>
        {({ width, height }) => (
          <div
            style={{
              width,
              height,
            }}
          >
            <Swiper
              {...params}
              onSwiper={setSwiperInstance}
            >
              {renderCards()}
            </Swiper>
          </div>
        )}
      </AutoSizer>
      <TooltipUI
        title="이전 이미지"
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          left: 0,
        }}
      >
        <ButtonIconUI
          shape="ghost"
          size="medium"
          onClick={onSlidePrev}
          onMouseEnter={(e) => e.currentTarget.focus()}
          onMouseLeave={(e) => e.currentTarget.blur()}
        >
          <FontAwesomeIcon icon="angle-left" />
        </ButtonIconUI>
      </TooltipUI>
      <TooltipUI
        title="다음 이미지"
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '50%',
          right: 0,
        }}
      >
        <ButtonIconUI
          shape="ghost"
          size="medium"
          onClick={onSlideNext}
          onMouseEnter={(e) => e.currentTarget.focus()}
          onMouseLeave={(e) => e.currentTarget.blur()}
        >
          <FontAwesomeIcon icon="angle-right" />
        </ButtonIconUI>
      </TooltipUI>
    </Box>
  );
}