import React from 'react';
import { TableRow as MuiTableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';
import {
  convertForeignCurrencyAccountInUseBoolean,
  convertInUseBoolean,
} from '@front/src/features/accounting/features/settings/features/bank-account/utils';
import { getValueIfExist } from '@front/src/utils';
import { AccountingSettingsBankAccountUpdateBankNameWidget } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/widget';

export { TableRow as AccountingSettingsBankAccountListTableRow };

interface Props {
  item: BankAccountView;
  menuId?: number;
}

const TableRow = ({ item, menuId }: Props) => (
  <MuiTableRow>
    <AccountingSettingsBankAccountUpdateBankNameWidget
      item={item}
      key={item.alias}
      menuId={menuId}
    />
    <OldTd>{getValueIfExist(item.name)}</OldTd>
    <OldTd>{getValueIfExist(item.code)}</OldTd>
    <OldTd>{getValueIfExist(item.nameWithCode)}</OldTd>
    <OldTd>{getValueIfExist(item.searchTitle)}</OldTd>
    <OldTd>{getValueIfExist(item.memo)}</OldTd>
    <OldTd>{convertForeignCurrencyAccountInUseBoolean(item.foreignCurrencyAccountInUse)}</OldTd>
    <OldTd>{convertInUseBoolean(item.inUse)}</OldTd>
  </MuiTableRow>
);
