import type { ReactNode } from 'react';
import React from 'react';
import { Table as CustomTable } from '@front/layouts/Table';
import classes from '@front/src/components/layout/table/table.module.scss';

export { Table };

const Table = ({ children }: { children: ReactNode }) => (
  <CustomTable className={classes.table}>{children}</CustomTable>
);
