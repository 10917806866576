import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectEstimationCompetitorParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/parameter';
import type { DefaultFunction } from '@front/src/types';

export { context as ProjectSalesInfoEstimationCompetitorCreateModalContext };

interface State {
  h: {
    onCreate: (params: ProjectEstimationCompetitorParameter) => void;
    onClose: DefaultFunction;
  };
}

const context = createContext<State>({
  h: {
    onCreate: noOp,
    onClose: noOp,
  },
});
