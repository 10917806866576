import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Fade, TableBody, TableHead, TableRow } from '@mui/material';
import Button from '@front/layouts/Button';
import { OldTd, OldTh, Table } from '@front/layouts/Table';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import UserSelector from '@front/components/UserSelector';
import type { CampaignUpdateApprovalParameter } from '@front/ost_campaign/parameter';
import type { UserId } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';
import type { CampaignApprovalVO, ProposalApprovalId } from '@front/ost_campaign/domain';
import { CampaignStatus } from '@front/ost_campaign/domain';
import Input from '@front/layouts/Input';
import { ArticleItem } from '@front/src/components/article-item';

interface Props {
  onAdd: () => void;
  onUpdate: (params: CampaignUpdateApprovalParameter) => void;
  onDelete: (id: ProposalApprovalId) => void;
  getExclude: (approvalList: CampaignApprovalVO[], item: CampaignApprovalVO) => UserId[];
}

const ApprovalLineSettingForm = ({ onAdd, onUpdate, onDelete, getExclude }: Props) => {
  const [approvalList] = useSelector(
    (root: RootState) => [root.campaign.approvalList],
    shallowEqual
  );
  const status = useSelector((root: RootState) => root.campaign.detail!.status, shallowEqual);
  return (
    <Layout>
      <ArticleItem
        title="결재 라인 설정"
        buttonsNode={status === CampaignStatus.READY && <Button onClick={onAdd}>추가</Button>}
        children={
          <Table disableSticky>
            <TableHead>
              <TableRow>
                <OldTh sx={{ width: '150px' }}>구분</OldTh>
                <OldTh sx={{ width: '180px' }}>부서</OldTh>
                <OldTh sx={{ width: '180px' }}>이름</OldTh>
                <OldTh
                  colSpan={2}
                  sx={{ width: '200px' }}
                >
                  비고
                </OldTh>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalList.length > 0 ? (
                <>
                  {approvalList.map((item, index) => (
                    <TableRow key={item.id}>
                      <OldTd>{`${
                        index + 1 === approvalList.length ? '최종 결재자' : `${index + 1}차 결재자`
                      }`}</OldTd>
                      <OldTd>{item.manager && item.manager.department.name}</OldTd>
                      <OldTd>
                        <UserSelector
                          readOnly={status !== CampaignStatus.READY}
                          variant="outlined"
                          value={item.manager?.id}
                          onChange={(value) => {
                            if (item.manager?.id !== value) {
                              onUpdate({ id: item.id, manageId: value as UserId });
                            }
                          }}
                          addOnly
                          exclude={getExclude(approvalList, item)}
                          menuIdList={[47, 29]}
                          typeList={[AuthorizationType.W]}
                        />
                      </OldTd>
                      <OldTd>
                        <Input
                          readOnly={status !== CampaignStatus.READY}
                          variant="outlined"
                          key={item.note}
                          defaultValue={item.note ?? ''}
                          onBlur={(e) => {
                            const value = e.target.value || undefined;
                            if (!value) {
                              onUpdate({ id: item.id, note: undefined });
                              return;
                            }
                            if (item.note !== value) {
                              onUpdate({ id: item.id, note: value });
                            }
                          }}
                        />
                      </OldTd>
                      {status === CampaignStatus.READY && (
                        <OldTd>
                          <Button
                            shape="basic3"
                            onClick={() => {
                              onDelete(item.id);
                            }}
                          >
                            삭제
                          </Button>
                        </OldTd>
                      )}
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <OldTd colSpan={5}>
                    <Fade in={true}>
                      <Box>결재 라인을 설정하시기 바랍니다.</Box>
                    </Fade>
                  </OldTd>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
      />
    </Layout>
  );
};

const Layout = styled(Box)({
  margin: '20px 0px',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  width: '100%',
  minHeight: '60px',
});

export default ApprovalLineSettingForm;
