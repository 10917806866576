import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalDesignDateDetailLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalDesignDateDetailContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/detail/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalDesignDateDetailProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { detail, isLoading, onUpdate, codeList } =
    useProjectSalesInfoSubjectReviewModalDesignDateDetailLogic(menuId);
  const value = useMemo(
    () => ({
      detail,
      isLoading,
      onUpdate,
      codeList,
    }),
    [detail, isLoading, onUpdate, codeList]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalDesignDateDetailContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalDesignDateDetailContext.Provider>
  );
};
