import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { AffiliatedPersonSalesActivityListProvider } from '@front/src/features/affiliated-person/features/sales-activity/widgets/list/provider/proivder';
import { AffiliatedPersonSalesActivityTableBody } from '@front/src/features/affiliated-person/features/sales-activity/widgets/list/components/table-body';

export { Widget as AffiliatedPersonSalesActivityListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<TableLoadingSpinner />}>
    <AffiliatedPersonSalesActivityListProvider menuId={menuId}>
      <AffiliatedPersonSalesActivityTableBody />
    </AffiliatedPersonSalesActivityListProvider>
  </Suspense>
);
