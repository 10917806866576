import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/parameter';
import { affiliatedCompanyExtraCompanyApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/query/api';

export const affiliatedCompanyExtraCompanyMutation = {
  usePersonOutlineExtraCompanyCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter) =>
        affiliatedCompanyExtraCompanyApi.createPersonOutlineExtraCompany(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'extra-company'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonOutlineExtraCompanyDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyExtraCompanyApi.deletePersonOutlineExtraCompany(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'extra-company'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
