import React, { memo, Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ResizableLeftDrawer from '@front/src/components/list-drawer/components/resizable-left-drawer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalDocumentContent from '@front/src/features/drawer-approval-document/components/ApprovalDocumentContent';
import { FormProvider, useForm } from 'react-hook-form';
import { approvalDocumentQuery } from '@front/src/features/drawer-approval-document/query/query';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useShallow } from 'zustand/react/shallow';

function ApprovalDocumentFeature() {
  const leftDrawerState = useGlobalNavBarState((state) => state.leftDrawerState);

  return (
    <Suspense
      fallback={
        <LoadingSpinner
          height="100%"
          width="100%"
        />
      }
    >
      <ResizeWrapper>
        <ResizableLeftDrawer leftDrawerState={leftDrawerState === 'approval-document'}>
          <ApprovalDocumentContent />
        </ResizableLeftDrawer>
      </ResizeWrapper>
    </Suspense>
  );
}

export default memo(ApprovalDocumentFeature);

function ResizeWrapper({ children }: { children: React.ReactNode }) {
  const { params, documentId } = useApprovalDocumentStore(
    useShallow((state) => ({
      params: state.params,
      documentId: state.documentId,
    }))
  );

  const { data: documentDetail } = approvalDocumentQuery.useGetApprovalDocument(documentId, params);

  const methods = useForm({
    values: {
      comment: '',
      commentList: documentDetail?.commentList ?? [],
    },
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
}
