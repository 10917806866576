import React, { useCallback } from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import Button from '@front/layouts/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { projectSalesInfoAffiliatedCompanyPersonSearchKeywordTypeOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils/constant';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/form.module.scss';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';
import { ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/domain';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { shallow } from 'zustand/shallow';

export { Form as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalForm };

const Form = () => {
  const { setParameter } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
      (state) => ({
        setParameter: state.setParameter,
      }),
      shallow
    );
  const methods = useForm<ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter>({
    values: {
      keywordType: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME,
      keyword: '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  return (
    <div className={classes.container}>
      <div className={classes.title}>인물 검색</div>
      <FormProvider {...methods}>
        <HookFormSelect
          width="112px"
          name="keywordType"
          optionList={projectSalesInfoAffiliatedCompanyPersonSearchKeywordTypeOptionList}
        />
        <HookFormInput
          name="keyword"
          onKeyDown={handleKeyDown}
        />
      </FormProvider>
      <Button onClick={onSubmit}>검색</Button>
    </div>
  );
};
