import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoProjectDivisionModalLogic } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/useLogic';
import { ProjectSalesInfoProjectDivisionModalContext } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/context';
import { ProjectSalesInfoProjectDivisionModalTable } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/modal-table';
import { ProjectSalesInfoProjectDivisionModalButtonGroup } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/Button';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoProjectDivisionHelp } from '@front/src/features/project-sales-info/features/project-division/components/help';

export { Widget as ProjectSalesInfoProjectDivisionModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const { Header, Layout, Body } = AddDeleteTable;
  const {
    d: { projectDivisionModalData, businessProgressStepOptionList },
    h: { onCreate, onDelete, onUpdate, setIdList, onModalClose },
    isLoading,
    idList,
    isModalOpen,
  } = useProjectSalesInfoProjectDivisionModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: {
        projectDivisionModalData,
        businessProgressStepOptionList,
      },
      h: {
        onCreate,
        onDelete,
        onUpdate,
        setIdList,
      },
      isLoading,
      idList,
    }),
    [
      projectDivisionModalData,
      onCreate,
      onDelete,
      onUpdate,
      isLoading,
      idList,
      setIdList,
      businessProgressStepOptionList,
    ]
  );
  return (
    <ModalLayout
      position="top"
      width="80dvw"
      open={isModalOpen}
      onClose={onModalClose}
      title="예상착수시기 및 사업진행단계 수정"
    >
      <ProjectSalesInfoProjectDivisionModalContext.Provider value={value}>
        <Layout
          width="100%"
          height="400px"
        >
          <Header>
            <ProjectSalesInfoProjectDivisionHelp />
            <ProjectSalesInfoProjectDivisionModalButtonGroup />
          </Header>
          <Body
            sx={{
              paddingBottom: '20px',
            }}
          >
            <ProjectSalesInfoProjectDivisionModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoProjectDivisionModalContext.Provider>
    </ModalLayout>
  );
};
