import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedPersonAppBarPersonCreateContext } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/provider/context';
import { convertEmptyToNull } from '@front/src/utils';
import { useFormContext } from 'react-hook-form';

export { CreateButton as AffiliatedPersonCreateButton };

const CreateButton = () => {
  const { onCreate } = useContext(AffiliatedPersonAppBarPersonCreateContext);
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate({
      personName: data.personName,
      ...convertEmptyToNull({
        affiliatedCompanyId: data.affiliatedCompany?.id,
      }),
    });
  });
  return <Button onClick={onSubmit}>저장</Button>;
};
