import type { AffiliatedCompanyProjectListGetParameter } from '@front/src/features/affiliated-company/features/project/types/parameter';

export const defaultParameterFromAffiliatedCompanyProject: AffiliatedCompanyProjectListGetParameter =
  {
    estimationStatus: '',
    contractStatus: '',
    category: '',
    contribution: '',
    contractStep: '',
    contractEarningsStep: '',
    orderCompanyName: '',
  };
