import React, { Suspense } from 'react';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export { Section as AffiliatedCompanyAccountsSection };

function Section(props: SectionComponentProps) {
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={props.name} />
      <Suspense fallback={<LoadingSpinnerUI />}>TBD</Suspense>
    </Layout>
  );
}
