import axios from 'axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createBidResult: (id: number) => `${bidURLAPI}/${id}/bid-result`,
};

export const projectSalesInfoBidResultCreateModalApi = {
  createBidResult: async (
    data: { params: ProjectSalesInfoBidResultParams; id: number },
    menuId
  ) => {
    await axios.post(url.createBidResult(data.id!), data.params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
