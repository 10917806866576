import type { DepartmentVO } from '../department/domain';
import type { UserId, UserVO } from '../user/domain';
import type { FileItemView } from '../file-item';

export type ProceedingsId = number & { readonly _brand: symbol };

export function ProceedingsId(id: number) {
  return id as ProceedingsId;
}

export type ProceedingsCommentId = number & { readonly _brand: symbol };

export interface ProceedingsShortVO {
  id: ProceedingsId;
  agenda: string;
  code: string;
  status: string;
  department: DepartmentVO;
  startTime: Date;
  isFavorite: boolean;
}

export interface ProceedingsVO {
  id: ProceedingsId;
  code: string;
  agenda: string;
  status: string;
  category: ProceedingType;
  writer: UserVO;
  startTime: Date;
  endTime: Date;
  attendanceList: UserVO[];
  readerList: UserVO[];
  createdAt: Date;
  modifiedAt: Date;
  place: string;
  content: string;
  decision: string;
  commentList: ProceedingsCommentVO[];
  fileList: ProceedingsAttachedFileVO[];
  isFavorite: boolean;
}

export enum ProceedingsStatus {
  /**
   * 작성중
   */
  WRITING = 'WRITING',
  /**
   * 회람중
   */
  READING = 'READING',
  /**
   * 완료
   */
  COMPLETE = 'COMPLETE',
}

export const proceedingStatusList: ProceedingsStatus[] = [
  ProceedingsStatus.WRITING,
  ProceedingsStatus.READING,
  ProceedingsStatus.COMPLETE,
];

export function proceedingStatusName(status: ProceedingsStatus | '') {
  switch (status) {
    case ProceedingsStatus.WRITING:
      return '작성중';
    case ProceedingsStatus.READING:
      return '회람중';
    case ProceedingsStatus.COMPLETE:
      return '완료';
    default:
      return '-';
  }
}

//TODO: 회의 분류 결정시 변경 사항.
export enum ProceedingType {
  /**
   * 정기
   */
  REGULAR = 'REGULAR',
  /**
   * 긴급
   */
  EMERGENCY = 'EMERGENCY',
}

export const ProceedingTypeList: ProceedingType[] = [
  ProceedingType.REGULAR,
  ProceedingType.EMERGENCY,
];

export function ProceedingTypeName(type: ProceedingType | '') {
  switch (type) {
    case ProceedingType.REGULAR:
      return '정기';
    case ProceedingType.EMERGENCY:
      return '긴급';
    default:
      return '-';
  }
}

export function ProceedingsCommentId(id: number) {
  return id as ProceedingsCommentId;
}

export interface ProceedingsCommentVO {
  id: ProceedingsCommentId;
  writer: UserVO;
  comment: string;
  createdAt: Date;
}

export type ProceedingsAttachedFileId = number & { readonly _brand: symbol };

export function ProceedingsAttachedFileId(id: number) {
  return id as ProceedingsAttachedFileId;
}

export interface ProceedingsAttachedFileVO {
  id: ProceedingsAttachedFileId;
  writer: UserVO;
  file: FileItemView;
}

export type ProceedingsMemoId = number & { readonly _brand: symbol };

export function ProceedingsMemoId(id: number) {
  return id as ProceedingsMemoId;
}

export interface ProceedingsMemoVO {
  id: ProceedingsMemoId;
  description: string;
  writer: UserVO;
  createdAt: Date;
  modifiedAt?: Date;
  attendanceList: UserId[];
}

export enum memoMessageLabel {
  'agenda' = 'agenda',
  'place' = 'place',
  'startTime' = 'startTime',
  'endTime' = 'endTime',
  'writer' = 'writer',
  'content' = 'content',
}

export const messageLabelList = ['agenda', 'place', 'startTime', 'endTime', 'writer', 'content'];

export function messageConvertor(label: memoMessageLabel | '') {
  switch (label) {
    case memoMessageLabel.agenda:
      return '안건명';
    case memoMessageLabel.startTime:
      return '시작 일시';
    case memoMessageLabel.endTime:
      return '종료 일시';
    case memoMessageLabel.place:
      return '장소';
    case memoMessageLabel.writer:
      return '작성자';
    case memoMessageLabel.content:
      return '내용';
    default:
      return '-';
  }
}

export enum ProceedingsAuthority {
  WRITER = 'WRITER',
  ATTENDANCE = 'ATTENDANCE',
  READER = 'READER',
}

export interface ProceedingsAccessVO {
  authority: ProceedingsAuthority;
  user: UserVO;
}
