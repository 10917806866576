import { createContext } from 'react';
import type { DailyReportStatusView } from '@front/src/features/accounting/features/settings/features/daily-report-status/types/view';

export { Context as AccountingSettingsDailyReportStatusListContext };

interface State {
  list: DailyReportStatusView[];
  sumList: DailyReportStatusView[];
}

const Context = createContext<State>({
  list: [],
  sumList: [],
});
