import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsManagementAccountingReadListLogic } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/provider/useLogic';
import { AccountingSettingsManagementAccountingReadListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/provider/context';

export { Provider as AccountingSettingsManagementAccountingReadListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list, eAccountAndManualList, tableRowNum } =
    useAccountingSettingsManagementAccountingReadListLogic(menuId);
  const value = useMemo(
    () => ({
      list,
      eAccountAndManualList,
      tableRowNum,
    }),
    [list, eAccountAndManualList, tableRowNum]
  );
  return (
    <AccountingSettingsManagementAccountingReadListContext.Provider value={value}>
      {children}
    </AccountingSettingsManagementAccountingReadListContext.Provider>
  );
};
