// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IZw3Jes4tKKkybIxx1JR{display:flex;width:300px;gap:20px;margin-top:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/components/detail-create-modal-btn.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".btn__group {\r\n  display: flex;\r\n  width: 300px;\r\n  gap: 20px;\r\n  margin-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__group": `IZw3Jes4tKKkybIxx1JR`
};
export default ___CSS_LOADER_EXPORT___;
