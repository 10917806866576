import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    if (isPublicApi(config)) {
      return config;
    }
    const menuId = sessionStorage.getItem('menuId');
    if (config.headers) config.headers['Menu-Id'] = menuId ? +menuId : null;
    return config;
  },
  (error) => Promise.reject(error)
);

function isPublicApi(config: AxiosRequestConfig<any>) {
  return config?.url?.startsWith('https://business.juso.go.kr');
}

export default axiosInstance;
