import { projectSalesInfoContractEarningsItemQuery } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/query';
import { projectSalesInfoContractEarningsItemMutation } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { CollectionIdDeleteParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';

export const projectSalesInfoContractEarningsItemRepository = {
  useContractEarningsItemGet: (itemId: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoContractEarningsItemQuery.useContractEarningsItemGet(itemId, menuId);
    return {
      data,
      isLoading,
    };
  },
  useContractEarningsDelete: (itemId: number, id: number, menuId) => {
    const { mutate } = projectSalesInfoContractEarningsItemMutation.useContractEarningsDelete(
      itemId,
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: () => mutate(),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useContractEarningsItemDetailsDelete: (itemId: number, id: number, menuId) => {
    const { mutate } =
      projectSalesInfoContractEarningsItemMutation.useContractEarningsItemDetailsDelete(
        itemId,
        id,
        menuId
      );
    const callback = getNoOpCallback();
    return {
      run: (params: CollectionIdDeleteParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useContractEarningsItemDetailsUpdate: (id: number, menuId) => {
    const { mutate } =
      projectSalesInfoContractEarningsItemMutation.useContractEarningsItemNameUpdate(id, menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectContractEarningsNameParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
