import {useMutation, useQueryClient} from 'react-query';
import {drawingApi} from "@front/src/features/image-draw/api/api";
import {
  ImageDrawCommentParameter,
  ImageDrawHistoryParameter
} from "@front/src/features/image-draw/types/view";

const imageDrawMutation = {
  useCreate: (menuId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ImageDrawHistoryParameter) => drawingApi.createDrawing(params, menuId),
      mutationKey: ['image', 'draw', 'create'],
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['image', 'draw'],
        });
        return data;
      },
    });
    return {
      mutate
    };
  },
  useUpdate: (menuId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ImageDrawHistoryParameter) => drawingApi.updateDrawing(params, menuId),
      mutationKey: ['image', 'draw', 'update'],
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['image', 'draw'],
        });
        return data;
      },
    });
    const {mutate: mutateComment} = useMutation({
      mutationFn: (params: ImageDrawCommentParameter) => drawingApi.updateDrawingComment(params, menuId),
      mutationKey: ['image', 'draw', 'update'],
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['image', 'draw'],
        });
        return data;
      },
    });
    return {
      mutate,
      mutateComment
    };
  },

  useDelete: (menuId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (drawingId: number) => drawingApi.deleteDrawing(drawingId, menuId),
      mutationKey: ['image', 'draw', 'delete'],
      onSettled: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: ['image', 'draw'],
        });
        return data;
      },
    });
    return {
      mutate,
    };
  },
};

export default imageDrawMutation;
