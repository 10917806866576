import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoBidResultUpdateModalQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/query';
import { projectSalesInfoBidResultUpdateModalMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/query/mutation';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

export const projectSalesInfoBidResultUpdateModalRepository = {
  useProjectSalesInfoBidResultDetailGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoBidResultUpdateModalQuery.useProjectSalesInfoBidResultDetailGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useProjectSalesInfoBidResultDetailUpdate: (id: number, menuId) => {
    const { mutate } =
      projectSalesInfoBidResultUpdateModalMutation.useProjectSalesInfoBidResultUpdate(id, menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectSalesInfoBidResultParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
