import { CompanyKeywordType } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/types';

export const defaultAffiliatedPersonCompanySearchForm = {
  keywordType: '',
  keyword: '',
};

export const affiliatedPersonCompanyLabelObject = {
  [CompanyKeywordType.ALL]: '선택',
  [CompanyKeywordType.CATEGORY]: '업체구분',
  [CompanyKeywordType.CODE]: '업체 코드',
  [CompanyKeywordType.NAME]: '업체명',
};

export const affiliatedCompanySearchOptionList = [
  {
    label: affiliatedPersonCompanyLabelObject[CompanyKeywordType.CATEGORY],
    value: CompanyKeywordType.CATEGORY,
  },
  {
    label: affiliatedPersonCompanyLabelObject[CompanyKeywordType.CODE],
    value: CompanyKeywordType.CODE,
  },
  {
    label: affiliatedPersonCompanyLabelObject[CompanyKeywordType.NAME],
    value: CompanyKeywordType.NAME,
  },
];

export const affiliatedPersonSearchModalTableHeaderNameList = [
  {
    width: '100px',
    name: affiliatedPersonCompanyLabelObject[CompanyKeywordType.CATEGORY],
  },
  {
    width: '120px',
    name: affiliatedPersonCompanyLabelObject[CompanyKeywordType.CODE],
  },
  {
    width: '220px',
    name: affiliatedPersonCompanyLabelObject[CompanyKeywordType.NAME],
  },
];
