import React, { useRef } from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import EditorUI from '@front/src/components/components-with-design/compound/EditorUI';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Link, Typography } from '@mui/material';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APPROVAL_DOCUMENT_STATUS, COMMON_TARGET_TYPE } from '@front/type/domain';
import ApprovalImgModal from '@front/src/features/drawer-approval/components/ApprovalImgModal';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { isImg } from '@front/src/utils';
import type { FileItemDto } from '@front/src/features/drawer-approval-document/types/view';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';
import ApprovalHasFileModalWrapper from '@front/src/features/drawer-approval/components/ApprovalHasFileModal';
import type { HasFileItemView } from '@front/src/features/drawer-approval/types/view';
import { fileToView } from '@front/file-item';
import AutoSizer from 'react-virtualized-auto-sizer';

interface Props {
  fileItemList?: FileItemDto[];
}

export default function ApprovalDescriptionTable({ fileItemList }: Props) {
  const { watch, getValues, setValue, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fileItemList',
  });
  const { menuId, dataId, sectionId, documentId } = useIdGroup();

  const {
    fields: hasFields,
    append: hasAppend,
    remove: hasRemove,
  } = useFieldArray({
    control,
    name: 'request.hasFileItemFileAddList',
  });

  const {
    field: { value, onChange: onQuillChange },
  } = useController({
    control,
    name: 'request.content',
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const update = (value) => {
    onQuillChange(value);
  };

  const onChange = (e) => {
    const newFileList = Array.from(e.target.files).map((item, index) => ({
      requestDelete: false,
      multipartFile: item,
      targetType: COMMON_TARGET_TYPE.APPROVAL,
      subPath: '',
      menuId,
      dataId,
      sectionId,
      targetId: documentId,
    }));
    newFileList.forEach((item) => append(item));
  };

  const onDropChange = (e) => {
    const newFileList = Array.from(e.dataTransfer.files).map((item, index) => ({
      requestDelete: false,
      multipartFile: item,
      targetType: COMMON_TARGET_TYPE.APPROVAL,
      subPath: '',
      menuId,
      dataId,
      sectionId,
      targetId: documentId,
    }));
    newFileList.forEach((item) => append(item));
  };

  const { setImgModalId, setIsHasModalOpen, setHasQueryParams } = useApprovalStore(
    useShallow((state) => ({
      setImgModalId: state.setImgModalId,
      setIsHasModalOpen: state.setIsHasModalOpen,
      setHasQueryParams: state.setHasQueryParams,
    }))
  );

  return (
    <TableContainerUI>
      <ApprovalHasFileModalWrapper append={(fileItem: HasFileItemView[]) => hasAppend(fileItem)} />
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <TableCellUI isHead={true}>내용</TableCellUI>
            <TableCellUI>
              <AutoSizer disableHeight={true}>
                {({ width }) => (
                  <Box
                    sx={{
                      width: width,
                    }}
                  >
                    <EditorUI
                      value={value}
                      onChange={update}
                      targetId={documentId}
                      targetType={'APPROVAL'}
                    />
                  </Box>
                )}
              </AutoSizer>
            </TableCellUI>
          </TableRowUI>
          <TableRowUI>
            <TableCellUI isHead={true}>
              첨부
              <br />
              파일
            </TableCellUI>
            <TableCellUI>
              <Box
                display="flex"
                flexDirection="column"
                // gap="10px"
              >
                <Box
                  sx={{ border: '1px dashed', padding: '10px 50px' }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    onDropChange(e);
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>첨부파일을 추가하거나 드래그해주세요.</Typography>
                    <Typography>※ 파일 크기는 각 10MB를 초과할 수 없습니다.</Typography>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                      <label htmlFor="approval-file">
                        <div
                          style={{
                            height: '32px',
                            display: 'flex',
                            alignItems: 'center',
                            background: ColorPalette.main.main_primary,
                            color: ColorPalette._ffffff,
                            fontWeight: 600,
                            fontSize: '14px',
                            borderRadius: '5px',
                            padding: '6px 12px',
                            cursor: 'pointer',
                          }}
                        >
                          내 PC 추가
                        </div>
                      </label>
                      <input
                        id="approval-file"
                        type="file"
                        multiple={true}
                        onChange={onChange}
                        style={{ display: 'none' }}
                        ref={inputRef}
                      />
                      <ButtonBasicUI
                        onClick={() => {
                          setIsHasModalOpen(true);
                          setHasQueryParams({ menuId, dataId });
                        }}
                      >
                        HAS 추가
                      </ButtonBasicUI>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {fields.map((item, index) => {
                    const file = getValues(`fileItemList.${index}.multipartFile`);
                    const fileDto = fileItemList?.[index] ?? getValues(`fileItemList.${index}`);
                    const convertedFile =
                      getValues('request.status') === APPROVAL_DOCUMENT_STATUS.TEMPORARY
                        ? fileDto
                        : fileToView(file);
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <ApprovalImgModal
                          item={getValues(`fileItemList.${index}`)}
                          fileDto={fileDto}
                          fieldId={item.id}
                        />
                        <Box
                          display="flex"
                          gap="5px"
                          alignItems="center"
                        >
                          <FontAwesomeIcon icon="file" />
                          <Typography>{file ? file.name : fileDto?.filename}</Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap="10px"
                          alignItems="center"
                        >
                          {isImg(file ? convertedFile?.ext ?? '' : fileDto?.ext ?? '') && (
                            <FontAwesomeIcon
                              icon="magnifying-glass"
                              cursor="pointer"
                              onClick={() => setImgModalId(item.id)}
                            />
                          )}
                          <Link
                            href={
                              file ? URL.createObjectURL(file) : `/api/file-item/${fileDto?.id}`
                            }
                            download={file ? file.name : fileDto?.filename}
                          >
                            <FontAwesomeIcon icon="download" />
                          </Link>
                          <FontAwesomeIcon
                            icon="square-xmark"
                            cursor="pointer"
                            onClick={() => {
                              const fileId = getValues(`fileItemList.${index}.id`);
                              remove(index);
                              if (inputRef.current) {
                                inputRef.current.value = '';
                              }
                              if (!fileId) return;
                              setValue(`request.deleteFileItemList`, [
                                ...getValues(`request.deleteFileItemList`),
                                fileId,
                              ]);
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                  {hasFields.map((item, index) => {
                    const file = getValues(`request.hasFileItemFileAddList.${index}`);
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <ApprovalImgModal
                          item={file}
                          fileDto={file}
                          fieldId={item.id}
                        />
                        <Box
                          display="flex"
                          gap="5px"
                          alignItems="center"
                        >
                          <FontAwesomeIcon icon="file" />
                          <Typography>{file.filename}</Typography>
                        </Box>
                        <Box
                          display="flex"
                          gap="10px"
                          alignItems="center"
                        >
                          {isImg(file.ext) && (
                            <FontAwesomeIcon
                              icon="magnifying-glass"
                              cursor="pointer"
                              onClick={() => setImgModalId(item.id)}
                            />
                          )}
                          <Link
                            href={`/api/file-item/${file.id}`}
                            download={file.filename}
                          >
                            <FontAwesomeIcon icon="download" />
                          </Link>
                          <FontAwesomeIcon
                            icon="square-xmark"
                            cursor="pointer"
                            onClick={() => {
                              hasRemove(index);
                            }}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </TableCellUI>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
