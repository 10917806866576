import { useQuery } from 'react-query';
import type { AffiliatedCompanyGiftHistoryGetParameter } from '@front/src/features/affiliated-company/features/gift-history/types/parameter';
import { affiliatedCompanyGiftHistoryApi } from '@front/src/features/affiliated-company/features/gift-history/query/api';
import type { AffiliatedCompanyPersonGiftHistoryView } from '@front/src/features/affiliated-company/features/gift-history/types/view';

export const affiliatedCompanyGiftHistoryQuery = {
  useGiftHistoryListGet: (params: AffiliatedCompanyGiftHistoryGetParameter, menuId) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonGiftHistoryView[]>({
      queryFn: () => affiliatedCompanyGiftHistoryApi.getGiftHistoryList(params, menuId),
      queryKey: ['affiliated-company', 'gift-history', 'list', params],
      enabled: !!params.id && !!menuId,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
