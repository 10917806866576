import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkOutputFeature from '@front/src/features/work/features/work/features/output';
import useWorkOutputBundleQuery from '@front/src/features/work/features/work/features/output-bundle/query/query';

export default function WorkOutputBundleList(props: Readonly<SectionComponentProps>) {
  const { dataId, menuId, sectionId } = props;
  const { data: bundleList } = useWorkOutputBundleQuery.useListGet({
    dataId,
    menuId,
    sectionId,
  });
  return (
    <>
      {bundleList?.map((item, index) => (
        <WorkOutputFeature
          {...props}
          key={item.id}
          item={item}
          index={index}
        />
      ))}
    </>
  );
}
