import { createContext } from 'react';
import { noOp } from '@front/src/utils';

export { context as ProjectSalesInfoActivityCreateButtonContext };

interface State {
  onCreate: (params) => void;
}

const context = createContext<State>({
  onCreate: noOp,
});
