import { noteApi } from '@front/src/features/note/api/api';
import type {
  AuthUserListQueryParams,
  CommentFilterParams,
  NoteFilterParams,
  NoteParams,
} from '@front/src/features/note/types/parameter';
import { useQuery } from 'react-query';

const noteQuery = {
  useGetList: (params: NoteParams, isResizing: boolean) => {
    const { data } = useQuery({
      queryKey: ['note', 'list', params],
      queryFn: () => noteApi.getList(params),
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: !isResizing,
    });

    return { data };
  },

  useGetCommentList: (params: CommentFilterParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'comment', params],
      queryFn: () => noteApi.getCommentList(params),
      suspense: true,
    });

    return { data };
  },

  useGetDetail: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'detail', noteId],
      queryFn: () => noteApi.getDetail(noteId),
      suspense: true,
    });

    return { data };
  },

  useGetReviewHistoryList: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'review-history', noteId],
      queryFn: () => noteApi.getReviewHistoryList(noteId),
      suspense: true,
    });

    return { data };
  },

  useGetFilter: (params: NoteFilterParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'filter', params],
      queryFn: () => noteApi.getFilter(params),
      suspense: true,
    });

    return { data };
  },

  useGetReviewerDetail: (noteId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'reviewer', noteId],
      queryFn: () => noteApi.getReviewerDetail(noteId),
      suspense: true,
      refetchOnWindowFocus: false,
    });

    return { data };
  },

  useGetNoteMenuId: (menuId: number) => {
    const { data } = useQuery({
      queryKey: ['note', 'menuId', menuId],
      queryFn: () => noteApi.getNoteMenuId(menuId),
      suspense: true,
      enabled: menuId !== 0,
    });

    return { data };
  },

  useGetAuthUserList: (params: AuthUserListQueryParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'authUserList', params],
      queryFn: () => noteApi.getAuthUserList(params),
      refetchOnWindowFocus: false,
      suspense: true,
    });

    return { data };
  },

  useGetNoteSubData: (params: NoteFilterParams) => {
    const { data } = useQuery({
      queryKey: ['note', 'subData', params],
      queryFn: () => noteApi.getNoteSubData(params),
      suspense: true,
      enabled: params.rowId !== 0 && params.sectionId !== 0,
      refetchOnWindowFocus: false,
    });

    return { data };
  },
};

export default noteQuery;
