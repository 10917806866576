import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineCareerUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/parameter';

const url = {
  createPersonOutlineCareer: (id?) => `${personBaseUrl}/${id}/career`,
  getPersonOutlineCareerList: (id?) => `${personBaseUrl}/${id}/career`,
  updatePersonOutlineCareer: (id?) => `${personBaseUrl}/career/${id}`,
  deletePersonOutlineCareer: () => `${personBaseUrl}/career`,
};

export const affiliatedCompanyCareerApi = {
  createPersonOutlineCareer: async (id?: number, menuId?) => {
    await axios.post(url.createPersonOutlineCareer(id), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getPersonOutlineCareerList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonOutlineCareerList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePersonOutlineCareer: async (
    params: AffiliatedCompanyPersonOutlineCareerUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonOutlineCareer(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonOutlineCareer: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deletePersonOutlineCareer(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
