import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPersonMeetingHistoryList: (id?) => `${personBaseUrl}/${id}/meeting-history`,
};

export const affiliatedCompanyPersonMeetingHistoryApi = {
  getPersonMeetingHistoryList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonMeetingHistoryList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
