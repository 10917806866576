import React, { useEffect } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoSubjectReviewShapeRatioTable } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/components/shape-ratio-table';
import { ProjectSalesInfoSubjectReviewShapeRatioCreateButtonWidget } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/widgets/widget';
import { ProjectSalesInfoSubjectReviewShapeRatioHelp } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/components/help';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';

import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { ProjectSalesInfoSubjectReviewModalWrapper } from '@front/src/features/project-sales-info/features/subject-review/components/modal-wrapper';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoSubjectReviewShapeRatioFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Body, Header, Layout } = AddDeleteTable;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  const { setReadOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Layout>
      <ProjectSalesInfoSubjectReviewModalWrapper menuId={menuId} />
      <Header title={name}>
        <ProjectSalesInfoSubjectReviewShapeRatioHelp />
        <ProjectSalesInfoSubjectReviewShapeRatioCreateButtonWidget menuId={menuId} />
      </Header>
      <Body>
        <ProjectSalesInfoSubjectReviewShapeRatioTable />
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoSubjectReviewShapeRatioFeature;
