import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { projectSalesInfoContractEarningsItemUpdateModalQuery } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/query';
import { projectSalesInfoContractEarningsItemUpdateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/query/mutation';

export const projectSalesInfoContractEarningsItemUpdateModalRepository = {
  useContractEarningsItemDetailsUpdate: (itemId: number, id: number, menuId) => {
    const { mutate } =
      projectSalesInfoContractEarningsItemUpdateModalMutation.useContractEarningsItemDetailsUpdate(
        itemId,
        id,
        menuId
      );
    const callback = getNoOpCallback();
    return {
      run: (params: SmProjectCollectionParameter) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useContractEarningsItemDetailsGet: (detailId: number, menuId) => {
    const { data, isLoading, remove } =
      projectSalesInfoContractEarningsItemUpdateModalQuery.useContractEarningsItemDetailsGet(
        detailId,
        menuId
      );
    return {
      data,
      isLoading,
      remove,
    };
  },
  useConsortiumCompanyGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoContractEarningsItemUpdateModalQuery.useConsortiumCompanyGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
};
