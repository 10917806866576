import React from 'react';

interface ProjectStatusProps {
  className?: string;
}
interface TableMeta {
  name: string;
  value: string | number;
}
export default function ProjectStatusTable(props: ProjectStatusProps) {
  const tableMeta: TableMeta[] = [
    { name: '견적상태', value: 0 },
    { name: '수주가능성', value: 0 },
    { name: '실험종류', value: 0 },
    { name: '총금액', value: 0 },
    { name: '순금액', value: 0 },
    { name: '계약상태', value: 0 },
    { name: '업무진행도', value: 0 },
    { name: '수금현황', value: 0 },
  ];

  return (
    <table>
      <thead>
        <tr>
          {tableMeta.map((column: any, index: number) => (
            <th key={index}>{column.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {tableMeta.map((column: any, index: number) => (
            <td key={index}>{column.value}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
