import type { ProjectCMSaveHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/view';
import { noOp } from '@front/common/contants';
import { createContext } from 'react';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';

interface State {
  d: {
    CMSaveHistoryList?: ProjectCMSaveHistoryView[];
  };
  h: {
    onUpdate: (params: ProjectCMSaveHistoryParameter) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoCMSaveHistoryContext };

const Context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
  },
  isLoading: false,
});
