import React from 'react';
import { useLocation } from 'react-router-dom';
import { ColorPalette } from '@front/assets/theme';
import TabsWithNavigate from '@front/src/components/tabs/TabsWithNavigate';
import { Box } from '@mui/material';
import { useOutletContext } from 'react-router';
import { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import { extractFirstNumber } from '@front/src/features/project-sales/util/util';

interface TabProps {
  children?: React.ReactNode;
}

export { HeaderTabs as ProjectSalesPageHeaderTabs };
const HeaderTabs = ({ children }: TabProps) => {
  const { pathname } = useLocation();
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const dataId = extractFirstNumber(pathname);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: ColorPalette.background.bg03,
      }}
    >
      <TabsWithNavigate
        id={dataId!!}
        tabList={tabList}
      />
      {children}
    </Box>
  );
};
