import { useCustomDialog } from '@front/src/features/dialog';
import useWorkDetailAddTop from '@front/src/features/work/features/work/features/detail/repository/useAddTop';
import useWorkDetailAddBottom from '@front/src/features/work/features/work/features/detail/repository/useAddBottom';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';
import { getEditOpenIdList } from '@front/src/components/ui-builder/table/utils';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

const useWorkDetailTableHeadMutation = ({ dataId, menuId, sectionId }) => {
  const params = { dataId, menuId, sectionId };

  const { getValues, setValue } = useFormContext<UIBuilderTableFieldValues>();

  const editOpenIdList = getValues('editOpenIdList');

  const toggleEditOpenId = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId = (id) => {
    setValue('recentUpdatedId', id);
  };

  const { confirm } = useCustomDialog();
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkDetailAddTop(params);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onAddBottom, setCallback: setAddBottomCallback } = useWorkDetailAddBottom(params);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDeleteAll } = workDetailMutation.useDeleteAll(params);
  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '작성자의 데이터가 전체 삭제됩니다.' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  return {
    onAddTop,
    onAddBottom,
    onRemoveAll,
  };
};

export default useWorkDetailTableHeadMutation;
