import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/layouts/Table';
import { ProjectSelectorModalBodyResultTableBody } from '@front/src/features/project-selector-modal/components/result-table-body';

export { ResultTable as ProjectSelectorModalBodyResultTable };

const ResultTable = () => (
  <TableContainer>
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSelectorModalBodyResultTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="4%">No.</Th>
      <Th width="16%">프로젝트명</Th>
      <Th width="4%">번호</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSelectorModalBodyListTableHeader';
