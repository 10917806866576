import React from 'react';
import Box from '@mui/material/Box';
import UserSelectorDepartmentList from '@front/src/features/user-selector/features/form/components/DepartmentList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserSelectorUnchangedMultiFormUserList from '@front/src/features/user-selector/features/unchanged-multi-form/components/UserList';
import UserSelectorUnchangedMultiFormSelectedList from '@front/src/features/user-selector/features/unchanged-multi-form/components/SelectedList';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { UserSelectorUnchangedMultiFormTFieldValues } from '@front/src/features/user-selector/features/unchanged-multi-form';

export default function UserSelectorUnchangedMultiFormBody() {
  const { control } = useFormContext<UserSelectorUnchangedMultiFormTFieldValues>();
  const { remove, fields, append } = useFieldArray<
    UserSelectorUnchangedMultiFormTFieldValues,
    never
  >({
    control,
    name: 'changeableUserList' as never,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '14px',
      }}
    >
      <Box
        sx={{
          width: '270px',
          height: '360px',
          border: '1px',
          borderRadius: '5px',
          overflowY: 'auto',
        }}
      >
        <UserSelectorDepartmentList />
      </Box>
      <CaretRight />
      <UserSelectorUnchangedMultiFormUserList
        remove={remove}
        append={append}
      />
      <CaretRight />
      <UserSelectorUnchangedMultiFormSelectedList
        fields={fields}
        remove={remove}
      />
    </Box>
  );
}

const CaretRight = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <FontAwesomeIcon icon="caret-right" />
  </Box>
);
