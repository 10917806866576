import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import UserSelectorUnchangedMultiFormHeader from '@front/src/features/user-selector/features/unchanged-multi-form/components/Header';
import UserSelectorUnchangedMultiFormBody from '@front/src/features/user-selector/features/unchanged-multi-form/components/Body';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import Box from '@mui/material/Box';

interface Props {
  unchangeableList: number[] | undefined;
  changeableList: number[] | undefined;
  onChange: (value: UserVO[]) => void;
  onClose?: () => void;
}
export default function UserSelectorUnchangedMultiFormFeature(props: Readonly<Props>) {
  const { unchangeableList = [], changeableList = [] } = props;
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  const methods = useForm<UserSelectorUnchangedMultiFormTFieldValues>({
    defaultValues: {
      keyword: '',
      departmentId: undefined,
      unchangeableUserList: [],
      changeableUserList: [],
    },
  });
  const unchangeableUserList = getUserVOListByUserIdList(unchangeableList, allUser);
  const changeableUserList = getUserVOListByUserIdList(changeableList, allUser);
  useEffect(() => {
    if (allUser) {
      methods.reset({
        keyword: '',
        departmentId: undefined,
        unchangeableUserList,
        changeableUserList,
      });
    }
  }, [allUser, unchangeableList, changeableList]);
  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '800px',
          height: '406px',
          padding: '0 20px',
          gap: '14px',
        }}
      >
        <UserSelectorUnchangedMultiFormHeader {...props} />
        <UserSelectorUnchangedMultiFormBody />
      </Box>
    </FormProvider>
  );
}

const getUserVOListByUserIdList = (userIdList: number[], userList: UserVO[] = []) =>
  userList.filter((user) => userIdList.includes(user.id));

export interface UserSelectorUnchangedMultiFormTFieldValues {
  keyword: string;
  departmentId: number | undefined;
  unchangeableUserList: UserVO[];
  changeableUserList: UserVO[];
}
