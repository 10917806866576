import React from 'react';
import { AffiliatedPersonMemoCreateForm } from '@front/src/features/affiliated-person/features/memo/components/create-form';
import { AffiliatedPersonMemoCreateWidget } from '@front/src/features/affiliated-person/features/memo/widgets/create/widget';
import { AffiliatedPersonMemoSearchForm } from '@front/src/features/affiliated-person/features/memo/components/search-form';
import { AffiliatedPersonMemoListWidget } from '@front/src/features/affiliated-person/features/memo/widgets/list/widget';
import { AffiliatedPersonMemoUpdateWidget } from '@front/src/features/affiliated-person/features/memo/widgets/update/widget';
import { AffiliatedPersonMemoDeleteWidget } from '@front/src/features/affiliated-person/features/memo/widgets/delete/widget';
import { MemoDrawerFeature } from '@front/src/components/memo-drawer';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';

const AffiliatedPersonMemoFeature = () => {
  const { menuId } = useSetMenuAndTab();
  return (
    <MemoDrawerFeature>
      <AffiliatedPersonMemoCreateForm
        widget={<AffiliatedPersonMemoCreateWidget menuId={menuId} />}
      />
      <AffiliatedPersonMemoSearchForm />
      <AffiliatedPersonMemoListWidget
        updateWidget={<AffiliatedPersonMemoUpdateWidget />}
        deleteWidget={<AffiliatedPersonMemoDeleteWidget />}
      />
    </MemoDrawerFeature>
  );
};

export default AffiliatedPersonMemoFeature;
