import React from 'react';
import useModalOpen from '@front/src/hooks/useModalOpen';
import PersonalSettingsModal from '@front/src/features/personal-settings-modal/components/Modal';
import { PersonalSettingsModalBtn } from '@front/src/features/personal-settings-modal/components/Button';

interface Props {
  authMenuId: number | undefined;
}

const PersonalSettingsFeature = ({ ...rest }: Props) => {
  const { onOpen, open, onClose } = useModalOpen();
  return (
    <>
      <PersonalSettingsModal
        open={open}
        onClose={onClose}
        {...rest}
      />
      <PersonalSettingsModalBtn onOpen={onOpen} />
    </>
  );
};

export default PersonalSettingsFeature;
