import React, { useCallback, useContext, useMemo } from 'react';
import MuiButton from '@front/layouts/Button';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { shallow } from 'zustand/shallow';
import { downloadExcelAccountingUploadSalesInfo } from '@front/src/features/accounting/features/upload/features/sales-info/utils/excel';
import { AccountingUploadSalesInfoDownloadExcelContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/provider/context';
import { AccountingUploadSalesInfoContext } from '@front/src/features/accounting/features/upload/features/sales-info/provider/context';

export { Button as AccountingUploadSalesInfoDownloadExcelButton };

const Button = () => {
  const { readOnly } = useContext(AccountingUploadSalesInfoContext);
  const { list } = useContext(AccountingUploadSalesInfoDownloadExcelContext);
  const { year } = useAccountingUploadSalesInfoState(
    (state) => ({
      year: state.year,
    }),
    shallow
  );
  const yearTwoDigits = useMemo(() => {
    if (!year) {
      return '-';
    }
    return (year - 2000).toString();
  }, [year]);
  const handleClick = useCallback(() => {
    downloadExcelAccountingUploadSalesInfo(
      yearTwoDigits,
      `20${yearTwoDigits}년 영업정보 업로드`,
      list
    );
  }, [list, yearTwoDigits]);
  return (
    <MuiButton
      onClick={handleClick}
      disabled={!year || readOnly}
    >
      엑셀 내보내기
    </MuiButton>
  );
};
