import React, { useContext } from 'react';
import { ProjectSalesInfoContractEarningsItemWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/widget';
import { ProjectSalesInfoContractEarningsContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/context';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContractEarningsItem as ProjectSalesInfoContractEarningsItem };
interface Props {
  menuId?: number;
}
const ContractEarningsItem = ({ menuId }: Props) => {
  const {
    d: { contractEarnings },
    isLoading,
  } = useContext(ProjectSalesInfoContractEarningsContext);
  const { readOnly } = useProjectSalesInfoContractEarningsState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (typeof contractEarnings === 'undefined' || isLoading || !contractEarnings) return null;
  return (
    <>
      {contractEarnings.map((item) => (
        <ProjectSalesInfoContractEarningsItemWidget
          key={item.id}
          name={item.name}
          itemId={item.id}
          readOnly={readOnly}
          menuId={menuId}
        />
      ))}
    </>
  );
};
