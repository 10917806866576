import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import DrawIoLayOut from '@front/ost_proposal/view/detail/draw_io/DrawIoLayOut';
import type { DrawingParameter } from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import type { DrawingId } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import type { DrawIoData } from '@front/ost_proposal/view/detail/draw_io/integration/useDrawIo';
import { useDrawIo } from '@front/ost_proposal/view/detail/draw_io/integration/useDrawIo';
import type { RootState } from '@front/services/reducer';

interface Props {
  isContributor: boolean;
}

const DrawIoService = ({ isContributor }: Props) => {
  const dispatch = useDispatch();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const onSave = (stringifiedData: string, fileName: string, id?: number) => {
    if (!(status === ProposalStatus.WRITING)) {
      alert('작성중인 제안서가 아닙니다.)');
      return;
    }
    if (id) {
      updateDrawing({
        id: id as DrawingId,
        stringifiedData,
        fileName,
      });
    } else {
      addDrawing({
        stringifiedData,
        fileName,
      });
    }
  };
  const onClose = () => {
    console.debug('useDrawIo::onClose callback executed');
  };
  const drawIo = useDrawIo(onSave, onClose, {
    hideSaveButton: !(status === ProposalStatus.WRITING),
  });
  const onClickText = useCallback(
    (originalDrawData: DrawIoData | undefined) => () => {
      drawIo.open(originalDrawData);
    },
    [drawIo]
  );

  const onDownloadFile = useCallback(
    (drawIoData: DrawIoData) => {
      drawIo.download(drawIoData);
    },
    [drawIo]
  );

  const addDrawing = useCallback(
    (params: DrawingParameter) => dispatch(proposalAction.addDrawing(params)),
    [dispatch]
  );
  const deleteDrawing = useCallback(
    (id: DrawingId) => dispatch(proposalAction.deleteDrawing(id)),
    [dispatch]
  );
  const updateDrawing = useCallback(
    (params: DrawingParameter) => dispatch(proposalAction.updateDrawing(params)),
    [dispatch]
  );
  return (
    <DrawIoLayOut
      addDrawing={addDrawing}
      deleteDrawing={deleteDrawing}
      updateDrawing={updateDrawing}
      onClickText={onClickText}
      onDownloadFile={onDownloadFile}
      drawIo={drawIo}
      isContributor={isContributor}
    />
  );
};

export default DrawIoService;
