import React, { useContext, useEffect } from 'react';
import { AffiliatedPersonDrawerListContext } from '@front/src/features/affiliated-person/features/drawer/widgets/list/provider/context';
import { DrawerNoResult } from '@front/src/components/Drawer/drawer-no-result';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { AffiliatedPersonDrawerRow } from '@front/src/features/affiliated-person/features/drawer/widgets/list/components/row';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useAffiliatedPersonDrawerState } from '@front/src/features/affiliated-person/features/drawer/useState';

export { List as AffiliatedPersonDrawerList };

const List = () => {
  const { list } = useContext(AffiliatedPersonDrawerListContext);
  const setIsOpen = useAffiliatedPersonDrawerState((state) => state.setIsOpen);
  useEffect(() => {
    if (isMobileDevice()) {
      setIsOpen(true);
    }
  }, []);
  if (list?.length === 0) {
    return <DrawerNoResult colSpan={3} />;
  }
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          overscanCount={20}
          itemCount={list?.length ?? 0}
          itemSize={32}
          itemData={list}
          className="scroll-bar-holder"
          width={width}
          height={height - 4}
          style={{
            overflowX: 'hidden',
          }}
        >
          {AffiliatedPersonDrawerRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
