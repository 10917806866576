import React, { Suspense } from 'react';
import { AffiliatedCompanyPersonDetailModalOutlineEducationProvider } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/provider/provider';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AffiliatedCompanyPersonDetailModalOutlineEducation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/components/education';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalOutlineEducationWidget = ({ menuId }: Props) => (
  <Suspense fallback={<LoadingSpinner height="200px" />}>
    <AffiliatedCompanyPersonDetailModalOutlineEducationProvider menuId={menuId}>
      <AffiliatedCompanyPersonDetailModalOutlineEducation />
    </AffiliatedCompanyPersonDetailModalOutlineEducationProvider>
  </Suspense>
);

export default AffiliatedCompanyPersonDetailModalOutlineEducationWidget;
