import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workCarouselBundleQuery = {
  useGetList: (commonParams) => {
    const { data } = useQuery({
      queryKey: ['work', 'carousel-bundle', commonParams.dataId],
      queryFn: () => workWorkApi.getCarouselBundleList(commonParams),
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });

    return data;
  },
};

export default workCarouselBundleQuery;
