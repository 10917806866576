import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/components/layout/header.module.scss';

interface Props {
  title?: string;
  children?: ReactNode;
  margin?: string;
}

export { Header };
const Header = ({ children, title, margin }: Props) => (
  <div
    className={`${classes.container} ${classes.between}`}
    style={{ margin: margin }}
  >
    {title && <div className={classes.title}>{title}</div>}
    {children && <div className={classes.children}>{children}</div>}
  </div>
);
