import React, { Suspense, useEffect } from 'react';

import { useShallow } from 'zustand/react/shallow';
import { useAccountingSettingsDailyReportStatusState } from '@front/src/features/accounting/features/settings/features/daily-report-status/useState';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingSettingsDailyReportStatusListProvider } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/provider';
import { AccountingSettingsDailyReportStatusListHeader } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/header';
import { AccountingSettingsDailyReportStatusListTable } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/table';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const AccountingSettingsDailyReportStatusFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  const { setReadOnly } = useAccountingSettingsDailyReportStatusState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <Suspense fallback={<LoadingSpinner height="600px" />}>
      <AccountingSettingsDailyReportStatusListProvider menuId={menuId}>
        <Layout>
          <Header
            title={name}
            rightComponent={<AccountingSettingsDailyReportStatusListHeader />}
          />
        </Layout>
        <Body>
          <AccountingSettingsDailyReportStatusListTable />
        </Body>
      </AccountingSettingsDailyReportStatusListProvider>
    </Suspense>
  );
};

export default AccountingSettingsDailyReportStatusFeature;
