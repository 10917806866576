import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { AccountingUploadEcountDataFeature } from '@front/src/features/accounting/features/upload/features/ecount-data';
import { Box } from '@mui/material';
import { shallow } from 'zustand/shallow';
import { useAccountingSettingsBankAccountState } from '@front/src/features/accounting/features/settings/features/bank-account/useState';
import { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { useShallow } from 'zustand/react/shallow';

export { Modal as AccountingSettingsManagementAccountingAccountHeaderUploadModal };
interface Props {
  menuId?: number;
}
const Modal = ({ menuId }: Props) => {
  const { isOpen, setIsOpen } = useAccountingSettingsBankAccountState(
    (state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
    }),
    shallow
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const { readOnly } = useAccountingSettingsBankAccountState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <ModalLayout
      open={isOpen}
      title="E계정 업로드"
      onClose={handleClose}
      width="60dvw"
      height="40dvw"
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <AccountingUploadEcountDataFeature
          isModalMode
          type={ECOUNT_FILE_TYPES.BANK_ACCOUNT}
          readOnly={readOnly}
          menuId={menuId}
        />
      </Box>
    </ModalLayout>
  );
};
