import { useQuery } from 'react-query';
import uIBuilderApi from '@front/src/features/ui-builder/api/api';

const useGetUiMetaHiddenList = (commonParams) => {
  const { data } = useQuery({
    queryKey: ['ui-builder', 'ui-meta', 'hidden-list', commonParams.sectionId],
    queryFn: () => uIBuilderApi.getUIMetaList(commonParams),
    enabled: !!commonParams.sectionId,
    suspense: true,
    select: (data) =>
      data.map((d) => ({
        id: d.id,
        targetId: d.targetId,
        isBlind: d.isBlind,
      })),
  });

  return data;
};

export default useGetUiMetaHiddenList;
