import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { AccountingSettingsManagementAccountingReadCountContext } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/provider/context';

export { TableHeader as AccountingSettingsManagementAccountingReadTableHeader2 };

const TableHeader = () => {
  const { detail } = useContext(AccountingSettingsManagementAccountingReadCountContext);
  return (
    <TableRow>
      <Th width="8%">통장명 ({detail?.depth0Count})</Th>
      <Th width="8%">대분류 ({detail?.depth0Count})</Th>
      <Th width="8%">중분류 ({detail?.depth1Count})</Th>
      <Th width="8%">소분류 ({detail?.depth2Count})</Th>
      <Th width="8%">미분류 ({detail?.depth3Count})</Th>
      <Th width="8%">E계정 ({detail?.depth3Count})</Th>
      <Th width="20%">한풍메뉴얼 ({detail?.depth3Count})</Th>
    </TableRow>
  );
};
