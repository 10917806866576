import React, { type MouseEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@front/components/Tooltip';
import type { ControlPanelItem } from '@front/src/types/domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import Box from '@mui/material/Box';

interface Props {
  itemList?: ControlPanelItem[];
  isPropagation?: boolean;
  isHeader?: boolean;
  disabled?: boolean;
}

export default function ContextMenuUI({ itemList, isHeader, isPropagation, disabled }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    isPropagation && event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, onAction?: () => void) => {
    isPropagation && e.stopPropagation();
    onAction?.();
    setAnchorEl(null);
  };

  itemList?.sort((a, b) => {
    if (!a?.seq || !b.seq) return 0;
    return a.seq > b.seq ? 1 : -1;
  });

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleClick}
        variant="text"
        sx={{
          minWidth: '18px',
          height: '18px',
          fontSize: '1.6rem',
          lineHeight: 'normal',
          color: ColorPalette.main.main_primary,
          borderRadius: '5px',
          padding: '0px',
        }}
      >
        <FontAwesomeIcon
          icon="ellipsis-vertical"
          color={
            disabled
              ? ColorPalette.greyscale.disabled
              : isHeader
              ? ColorPalette.main.main_primary
              : ColorPalette.greyscale.text_secondary
          }
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        sx={{
          '& .MuiPaper-root': {
            display: 'inline-flex',
            flexDirection: 'column',
            background: ColorPalette.background.bg,
            borderRadius: '5px',
            boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
            marginTop: '-20px',
            marginLeft: '20px',
          },

          '& .MuiMenu-list': {
            padding: '6px',
          },

          '& .MuiMenuItem-root': {
            display: 'flex',
            height: '32px',
            minHeight: '32px',
            fontSize: '1.4rem',
            fontWeight: '400',
            lineHeight: 'normal',
            borderRadius: '5px',
            padding: '6px 10px',
            gap: '10px',

            '&:hover': {
              background: ColorPalette.main.main_tertiary,
            },
            '&:active': {
              background: ColorPalette.main.main_tertiary,
            },
            '&:focus-visible': {
              background: 'transparent',
              outline: `2px solid ${ColorPalette.sub.sub_primary}`,
              outlineOffset: '-2px',
            },
            '&:disabled': {
              color: ColorPalette.greyscale.disabled,
            },
          },

          '& svg': {
            width: '14px',
            height: '14px',
            fontSize: '1.4rem',
            lineHeight: 'normal',
            color: ColorPalette.main.main_primary,

            '&:disabled': {
              color: ColorPalette.greyscale.disabled,
            },
          },
        }}
      >
        {itemList?.map((item) => {
          if (item.text === '') return;
          return (
            <Tooltip
              title={item.disabled ? item.tooltip : ''}
              key={item.text}
            >
              <MenuItem
                onClick={(e) => handleClose(e, item.action)}
                disabled={item.disabled}
                sx={{
                  borderBottom: item.split ? '1px solid rgba(0,0,0,0.1)' : '',
                  borderRadius: item.split ? '0 !important' : '',
                }}
              >
                <>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '14px',
                      height: '14px',
                      '& svg': { color: item.iconColor ?? '' },
                    }}
                  >
                    <item.icon />
                  </Box>
                  {item.text}
                </>
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
}
