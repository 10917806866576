import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import type { AffiliatedCompanyCharacterCharacterView } from '@front/src/features/affiliated-company/features/character/features/character/types/view';
import { AffiliatedCompanyCharacterCharacterContext } from '@front/src/features/affiliated-company/features/character/features/character/widgets/context';
import type { AffiliatedCompanyCharacterCharacterUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/character/types/parameter';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  item: AffiliatedCompanyCharacterCharacterView;
  index: number;
}

export { CharacterTableRow as AffiliatedCompanyCharacterCharacterTableRow };
const CharacterTableRow = ({ item }: Props) => {
  const {
    h: { setIdList, onCharacterUpdate },
    idList,
  } = useContext(AffiliatedCompanyCharacterCharacterContext);
  const methods = useForm<AffiliatedCompanyCharacterCharacterUpdateParameter>({
    values: {
      id: item.id,
      category: item.category ?? '',
      content: item.content ?? '',
      source: item.source ?? '',
    },
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onCharacterUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>
          <Checkbox
            checked={idList.includes(item.id)}
            onChange={handleCheckboxChange(item.id)}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="category"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="content"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="source"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
