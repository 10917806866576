import { type UseFieldArrayReturn, type UseFormReturn } from 'react-hook-form';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import { useSnackbar } from 'notistack';
import type { ManagementAccountingParameter } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/parameter';
import managementAccountMutation from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/query/mutation';

interface Props {
  commonParams: UIBuilderParameter;
  cellData?: ManagementAccountingTreeView;
  onClose?: () => void;
  selectedRowIndexListFieldArray?: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
  formContext: UseFormReturn<ManagementAccountTableFormValues, any, undefined>;
}

const useManagementAccountContextMenuMutation = ({
  commonParams,
  cellData,
  onClose,
  selectedRowIndexListFieldArray,
  formContext,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, setValue } = formContext;
  const selectedRowIndexList = getValues('selectedRowIndexList');
  const active = getValues('active');

  const createMoveSuccessHandler =
    (isUp: boolean) =>
    (data: ManagementAccountingTreeView, variables: ManagementAccountingParameter) => {
      const { depth, sequence } = variables;
      const prevSequence = isUp ? sequence + 1 : sequence - 1;
      const currentSequence = sequence;

      enqueueSnackbar('이동 성공했습니다.', { variant: 'success' });
      onClose?.();

      if (active && data.id === active.id) {
        setValue('active', data);
      }

      const selectedSequence = selectedRowIndexList[depth]?.value;

      if (selectedSequence === prevSequence) {
        selectedRowIndexListFieldArray?.update(depth, {
          value: currentSequence,
        });
      }
    };

  const onInsert = managementAccountMutation.useInsert(
    commonParams,
    (data: number, variables: ManagementAccountingParameter) => {
      enqueueSnackbar('추가 성공했습니다.', { variant: 'success' });
      onClose?.();

      setValue('updateId', data);

      const { depth, sequence } = variables;

      if (active && sequence <= active.sequence) {
        setValue('active', { ...active, sequence: active.sequence + 1 });
      }

      const selectedSequence = selectedRowIndexList[depth]?.value;

      if (selectedSequence >= sequence) {
        selectedRowIndexListFieldArray?.update(depth, {
          value: selectedSequence + 1,
        });
      }
    }
  );

  const onMoveUp = managementAccountMutation.useUpdate(
    commonParams,
    createMoveSuccessHandler(true)
  );

  const onMoveDown = managementAccountMutation.useUpdate(
    commonParams,
    createMoveSuccessHandler(false)
  );

  const onDelete = managementAccountMutation.useDelete(commonParams, () => {
    enqueueSnackbar('삭제 성공했습니다.', { variant: 'success' });
    onClose?.();

    if (cellData) {
      const { depth, sequence } = cellData;

      const selectedSequence = selectedRowIndexList[depth]?.value;

      if (selectedSequence > sequence) {
        selectedRowIndexListFieldArray?.update(depth, {
          value: selectedSequence - 1,
        });
      } else if (selectedSequence === sequence) {
        selectedRowIndexListFieldArray?.remove(depth);
      }
    }
  });

  return {
    onInsert,
    onMoveUp,
    onMoveDown,
    onDelete,
  };
};

export default useManagementAccountContextMenuMutation;
