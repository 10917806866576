import React from 'react';
import { ProjectSalesInfoSubjectReviewShapeRatioCreateButtonProvider } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/widgets/provider';
import { ProjectSalesInfoSubjectReviewShapeRatioCreateButton } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/create-button/components/create-button';

export { Widget as ProjectSalesInfoSubjectReviewShapeRatioCreateButtonWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewShapeRatioCreateButtonProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewShapeRatioCreateButton />
  </ProjectSalesInfoSubjectReviewShapeRatioCreateButtonProvider>
);
