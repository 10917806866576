import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingUploadSalesInfoDownloadExcelContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/provider/context';
import { useAccountingUploadSalesInfoDownloadExcelLogic } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/download-excel/provider/useLogic';

export { Provider as AccountingUploadSalesInfoDownloadExcelProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list } = useAccountingUploadSalesInfoDownloadExcelLogic(menuId);
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AccountingUploadSalesInfoDownloadExcelContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoDownloadExcelContext.Provider>
  );
};
