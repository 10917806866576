import { projectSalesInfoAbstractAffiliatedCompanyQuery } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/query/query';

export { repository as projectSalesInfoAbstractAffiliatedCompanyRepository };
const repository = {
  useListGet: (id?, menuId?) => {
    const { data, remove } = projectSalesInfoAbstractAffiliatedCompanyQuery.useListGet(id, menuId);
    return {
      data,
      remove,
    };
  },
};
