import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type {
  AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateCompanyParameter,
  AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter,
} from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/types/parameter';
import { affiliatedCompanyRepresentativeContractInformationApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/query/api';
import { affiliatedPersonApi } from '@front/src/features/affiliated-person/api/api';

export const affiliatedCompanyRepresentativeContractInformationMutation = {
  usePersonOutlineRepresentativeContactInformationUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (
        params: AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateParameter
      ) =>
        affiliatedCompanyRepresentativeContractInformationApi.updatePersonOutlineRepresentativeContactInformation(
          params,
          menuId
        ),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [
            'affiliated-company',
            'person',
            'outline',
            'representative-contact-information',
          ],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useCompanyUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (
        params: AffiliatedCompanyPersonOutlineRepresentativeContactInformationUpdateCompanyParameter
      ) => affiliatedPersonApi.updateCompany(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [
            'affiliated-company',
            'person',
            'outline',
            'representative-contact-information',
          ],
        });
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'drawer', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
