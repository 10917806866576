import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { ColorPalette } from '@front/assets/theme';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';
import useDialog from '@front/dialog/hook';
import type { ProposalEstimatorVO } from '@front/ost_proposal/domain';

interface Props {
  onAgree: (isAgreed: boolean) => void;
  estimatorInfo?: ProposalEstimatorVO;
}

export { RequestProposalSubmissionCancel as RequestProposalSubmissionCancelForm };
const RequestProposalSubmissionCancel = ({ onAgree, estimatorInfo }: Props) => {
  const { confirm } = useDialog();
  const proposalCancelRequest = useSelector(
    (root: RootState) => root.proposal.proposalCancelRequest,
    shallowEqual
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '20px',
          borderRadius: '5px',
          backgroundColor: `${ColorPalette._DAE8FC}`,
          columnGap: '10px',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <DataFieldWithLabel
            label="제안 수정 요청 알림"
            labelSX={{
              color: `${ColorPalette._252627}`,
              fontWeight: 'bold',
            }}
          >
            <Input
              readOnly
              variant="outlined"
              value={proposalCancelRequest?.reason}
            />
          </DataFieldWithLabel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            columnGap: '10px',
          }}
        >
          <Button
            disabled={estimatorInfo?.isAgreed || estimatorInfo?.isAgreed === false}
            shape="basic1"
            onClick={() => {
              confirm({
                children: '제안 수정 요청을 허용하시겠습니까?',
                confirmText: '확인',
                afterConfirm: () => {
                  onAgree(true);
                },
              });
            }}
          >
            허용
          </Button>
          <Button
            disabled={estimatorInfo?.isAgreed || estimatorInfo?.isAgreed === false}
            shape="basic3"
            onClick={() => {
              confirm({
                children: '제안 수정 요청을 거절하시겠습니까?',
                confirmText: '확인',
                afterConfirm: () => {
                  onAgree(false);
                },
              });
            }}
          >
            거절
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
