import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { BundleUpdateSeqParameter } from '@front/src/features/work/features/work/types/parameter';

const workOutputBundleMutation = {
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkOutputBundleCreateParameter) =>
        workWorkApi.createOutputBundle(params, commonParams),
      mutationKey: ['work', 'output-bundle', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkOutputBundleUpdateParameter) =>
        workWorkApi.updateOutputBundle(params, commonParams),
      mutationKey: ['work', 'output-bundle', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<any, any, number, any>({
      mutationFn: (id) => workWorkApi.deleteOutputBundle(id, commonParams),
      mutationKey: ['work', 'output-bundle', 'delete'],
      onMutate: (id) => {
        const prevData: WorkOutputBundleView[] | undefined = queryClient.getQueryData([
          'work',
          'output-bundle',
          commonParams.dataId,
        ]);
        const data = prevData?.filter((p) => p.id !== id);
        queryClient.setQueryData(['work', 'output-bundle', commonParams.dataId], data);
        return {
          prevData,
        };
      },
      onError: (err, id, context) => {
        queryClient.setQueryData(['work', 'output-bundle'], context?.prevData);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useMove: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, BundleUpdateSeqParameter>({
      mutationFn: (params) => workWorkApi.moveOutputBundle(params, commonParams),
      mutationKey: ['work', 'output-bundle', 'move'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle'],
        });
      },
    });
    return mutate;
  },
};

export default workOutputBundleMutation;
