import useWorkCarouselUpdateSeq from '@front/src/features/work/features/work/features/carousel/features/move-seq/repository/useUpdateSeq';

const useWorkCarouselUpdateSeqMutation = ({ menuId, dataId, sectionId, onClose }) => {
  const { run: onUpdateSeq, setCallback } = useWorkCarouselUpdateSeq({
    menuId,
    dataId,
    sectionId,
  });
  setCallback({
    onSuccess: () => {
      onClose();
    },
  });
  return {
    onUpdateSeq,
  };
};

export default useWorkCarouselUpdateSeqMutation;
