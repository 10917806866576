import React from 'react';
import { Box, Typography } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  text?: React.ReactNode;
  required?: boolean;
  isRed?: boolean;
  size?: string;
  marginLeft?: string;
}

const RequiredMark = ({ text, required, isRed, size, marginLeft }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    {text}
    <Box
      sx={{
        width: '8px',
        height: '24px',
      }}
    >
      {required && (
        <Typography
          variant="caption"
          sx={{
            marginLeft: marginLeft ?? '4px',
            fontSize: size ?? '0.7rem',
            color: isRed ? ColorPalette._eb4c4c : 'inherit',
          }}
        >
          *
        </Typography>
      )}
    </Box>
  </Box>
);
export default RequiredMark;
