import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import { getMenuIdHeaders } from '@front/src/utils';

const estimationHistoryQuery = {
  useList: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery<EstimationHistoryView[], AxiosError, EstimationHistoryView[]>({
      queryFn: () =>
        estimationHistoryGetApi(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation`,
          commonParams
        ),
      queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'history'],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return data;
  },
};

export default estimationHistoryQuery;

const estimationHistoryGetApi = async (url: string, commonParams: UIBuilderParameter) => {
  const { data } = await axios.get(url, {
    params: {
      menuId: commonParams.menuId,
      sectionId: commonParams.sectionId,
    },
    headers: getMenuIdHeaders(commonParams.menuId),
  });

  const mapped = [...data].map((d) => ({
    ...d,
    id: d.estimation.id,
  }));

  return mapped.sort((a, b) => a.estimation.seq - b.estimation.seq);
};
