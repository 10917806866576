import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingUploadSalesInfoGetYearLogic } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/useLogic';
import { AccountingUploadSalesInfoGetYearContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/context';

export { Provider as AccountingUploadSalesInfoGetYearProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list } = useAccountingUploadSalesInfoGetYearLogic(menuId);
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AccountingUploadSalesInfoGetYearContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoGetYearContext.Provider>
  );
};
