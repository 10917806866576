import { projectSalesInfoSubjectReviewModalRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { ProjectSalesInfoSubjectReviewModalExperimentStandardCodeKey } from '@front/src/features/project-sales-info/features/subject-review/features/modal/utils/constant';

export { useLogic as useProjectSalesInfoSubjectReviewModalDesignDateDetailLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoSubjectReviewModalRepository.useDetailGet(id, menuId);
  const { data: codeList, isLoading: isCodeListLoading } =
    adminManageableVariableRepository.useListGet(
      ProjectSalesInfoSubjectReviewModalExperimentStandardCodeKey,
      menuId
    );
  const { run: onUpdate } = projectSalesInfoSubjectReviewModalRepository.useUpdate(menuId);
  return {
    detail,
    isLoading: isDetailLoading || isCodeListLoading,
    onUpdate,
    codeList,
  };
};
