import React from 'react';
import { CustomTable, Thc } from '@front/src/components/ui-builder/table';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/components/TableContent';

export { HistoryTable as PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTable };
interface Props {
  authMenuId: number | undefined;
}
const HistoryTable = ({ authMenuId }: Props) => (
  <TableContainer>
    <CustomTable>
      <TableHead>
        <TableRow>
          <Thc width="100px">변경일시</Thc>
          <Thc width="160px">기존</Thc>
          <Thc width="160px">변경</Thc>
          <Thc width="200px">비고</Thc>
          <Thc width="60px">처리자</Thc>
        </TableRow>
      </TableHead>
      <TableBody>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentHistoryTableContent
          authMenuId={authMenuId}
        />
      </TableBody>
    </CustomTable>
  </TableContainer>
);
