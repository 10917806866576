import { useMutation, useQueryClient } from 'react-query';
import fileItemApi from '@front/src/features/file-item/api/api';
import type { FileItemUpdateDescriptionParameter } from '@front/src/features/file-item/types/parameter';

const useFileItemDescriptionUpdate = (commonParams) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<unknown, unknown, FileItemUpdateDescriptionParameter>({
    mutationFn: (params) => fileItemApi.updateDescription(params, commonParams),
    mutationKey: [],
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['work', 'carousel'],
      });
    },
  });

  return mutate;
};

export default useFileItemDescriptionUpdate;
