import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyCharacterPartnerCompanyLogic } from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/useLogic';
import { AffiliatedCompanyCharacterPartnerCompanyContext } from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/context';
import { AffiliatedCompanyCharacterPartnerCompanyButton } from '@front/src/features/affiliated-company/features/character/features/partner-company/components/partner-company-button';
import { AffiliatedCompanyCharacterPartnerCompanyTable } from '@front/src/features/affiliated-company/features/character/features/partner-company/components/partner-company-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyCharacterPartnerCompanyWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { partnerCompanyList },
    h: { onPartnerCompanyUpdate, onPartnerCompanyDelete, onPartnerCompanyCreate, setIdList },
    isLoading,
    idList,
    id,
  } = useAffiliatedCompanyCharacterPartnerCompanyLogic(menuId);
  const value = useMemo(
    () => ({
      d: { partnerCompanyList },
      h: { onPartnerCompanyUpdate, onPartnerCompanyDelete, onPartnerCompanyCreate, setIdList },
      isLoading,
      idList,
      id,
    }),
    [
      partnerCompanyList,
      isLoading,
      onPartnerCompanyUpdate,
      onPartnerCompanyDelete,
      onPartnerCompanyCreate,
      setIdList,
      idList,
      id,
    ]
  );
  return (
    <AffiliatedCompanyCharacterPartnerCompanyContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyCharacterPartnerCompanyButton />
        </Header>
        <Body>
          <AffiliatedCompanyCharacterPartnerCompanyTable />
        </Body>
      </Layout>
    </AffiliatedCompanyCharacterPartnerCompanyContext.Provider>
  );
};

export default AffiliatedCompanyCharacterPartnerCompanyWidget;
