import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";
import useNoteStore from "@front/src/features/note/useState";
import {useShallow} from "zustand/react/shallow";

const useNote = () => {
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const {setRowId, setSectionId} = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );

  const open = (props: { sectionId: number, rowId: number }) => {
    const {rowId, sectionId} = props;
    setIsRightDrawerOpen(true);
    setRowId(rowId);
    sectionId && setSectionId(sectionId);
  };
  return {
    open,
  };
};

export {useNote};