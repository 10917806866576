import { useQuery } from 'react-query';
import { projectSalesInfoSubjectReviewShapeRatioApi } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/query/api';
import type { ProjectAspectRatioExaminationView } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/view';

export const projectSalesInfoSubjectReviewShapeRatioQuery = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectAspectRatioExaminationView[]>({
      queryFn: () => projectSalesInfoSubjectReviewShapeRatioApi.getList(id, menuId),
      queryKey: ['project', 'sales-info', 'subject-info', 'shape-ratio', 'list'],
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
