import React, { memo, useCallback } from 'react';
import type {
  CampaignUpdateVoteEndDateParameter,
  CampaignUpdateVoteStartDateParameter,
} from '@front/ost_campaign/parameter';
import { useDispatch } from 'react-redux';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import dayjs from 'dayjs';
import { isValidDate } from '@front/util/DateUtil';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { DatePicker } from '@mui/x-date-pickers';
import type { TextFieldProps } from '@mui/material';
import { Box } from '@mui/material';
import { RenderDateInput } from '@front/components/RenderDateInput';

interface Props {
  voteStartDate?: string;
  voteEndDate?: string;
  updateVoteStartDate: (params: CampaignUpdateVoteStartDateParameter) => void;
  updateVoteEndDate: (params: CampaignUpdateVoteEndDateParameter) => void;
}

const VotePeriod = ({
  voteStartDate,
  voteEndDate,
  updateVoteStartDate,
  updateVoteEndDate,
}: Props) => {
  const dispatch = useDispatch();
  const openSnackbar = useCallback(
    (message: string, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onEstimationStartDateChange = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateVoteStartDate({
          voteStartDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateVoteStartDate({
        voteStartDate: value,
      });
    },
    [updateVoteStartDate, openSnackbar]
  );

  const onEstimationEndDateChange = useCallback(
    (e) => {
      const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
      const error = !value || !isValidDate(value);
      if (!value) {
        updateVoteEndDate({
          voteEndDate: undefined,
        });
        return;
      }
      if (error) {
        openSnackbar('올바르지 않은 날짜 형식입니다.');
        return;
      }
      updateVoteEndDate({
        voteEndDate: value,
      });
    },
    [updateVoteEndDate, openSnackbar]
  );

  return (
    <DataFieldWithLabel
      label="투찰 기간"
      labelSX={{ minWidth: '80px' }}
    >
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={voteStartDate && dayjs(voteStartDate).format('YYYY-MM-DD')}
        onChange={onEstimationStartDateChange}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginInline: '10px',
        }}
      >
        ~
      </Box>
      <DatePicker
        inputFormat="YYYY-MM-DD"
        mask="____-__-__"
        value={voteEndDate && dayjs(voteEndDate).format('YYYY-MM-DD')}
        onChange={onEstimationEndDateChange}
        renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
      />
    </DataFieldWithLabel>
  );
};

const VotePeriodForm = memo(VotePeriod);

export default VotePeriodForm;
