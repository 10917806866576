import { Box } from '@mui/material';
import type { ComponentType } from 'react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import useId from '@front/services/useId';
import LoadingBar from '@front/layouts/LoadingBar';
import type { RootState } from '@front/services/reducer';
import ErrorBoundary from '@front/components/ErrorBoundary';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import { DetailStatus } from '@front/ost_reward/view/detail/detail-status';
import type { OstRewardManagementDetailView } from '@front/ost_reward/domian';
import { isMobileDevice } from '@front/util/PwaUtil';

const withConditionalRender =
  (Component: ComponentType<ConditionalProps>) => (props: ConditionalProps) => {
    if (!props.detail) {
      return <LoadingBar />;
    }
    return <Component {...props} />;
  };

interface ConditionalProps {
  detail?: OstRewardManagementDetailView;
}

const Service = ({ detail }: ConditionalProps) => {
  if (typeof detail === 'undefined') {
    return (
      <PageLayoutWithMemo>
        <ErrorBoundary>요청한 데이터를 가져오는데 실패했습니다.</ErrorBoundary>
      </PageLayoutWithMemo>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px',
        paddingTop: isMobileDevice() ? '100px' : '20px',
      }}
    >
      <DetailStatus />
    </Box>
  );
};

const ConditionedService = withConditionalRender(Service);

export { Index as OstRewardManagementDetailService };

const Index = () => {
  const urlId = useId();
  const location = useLocation();
  const dispatch = useDispatch();

  const { detail } = useSelector(
    (root: RootState) => ({
      detail: root.ostRewardMangement.detail,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(ostRewardManagementAction.setId((location.state && location.state.id) ?? urlId));
  }, [dispatch, location.state, urlId]);

  return <ConditionedService detail={detail} />;
};
