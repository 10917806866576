import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoActivityDeleteButtonContext } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';

export { DeleteButton as ProjectSalesInfoActivityDeleteButton };

const DeleteButton = () => {
  const { onDelete } = useContext(ProjectSalesInfoActivityDeleteButtonContext);
  const { idList } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      idList: state.idList,
    }))
  );
  const { confirm } = useCustomDialog();
  const handleButtonClick = useCallback(() => {
    if (idList.length === 0) return;
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onDelete({ idList });
      },
    });
  }, [confirm, onDelete, idList]);
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={handleButtonClick}
      disabled={readOnly}
    >
      선택 삭제
    </Button>
  );
};
