import React from 'react';
import classes from './no-result-box.module.scss';

interface Props {
  message?: string;
}

export { NoResultBox };

const NoResultBox = ({ message = '데이터가 없습니다.' }: Props) => (
  <div className={classes.no__result}>{message}</div>
);
