import React from 'react';
import { ProjectSalesInfoActivityItemProvider } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/provider/provider';
import { ProjectSalesInfoActivityItemRow } from '@front/src/features/project-sales-info/features/activity/widgets/activity-item/components/activity-item-row';

export { Widget as ProjectSalesInfoActivityItemWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoActivityItemProvider menuId={menuId}>
    <ProjectSalesInfoActivityItemRow />
  </ProjectSalesInfoActivityItemProvider>
);
