import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedCompanyProjectListContext } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/context';
import { useAffiliatedCompanyProjectListLogic } from '@front/src/features/affiliated-company/features/project/widgets/list/provider/useLogic';

export { Provider as AffiliatedCompanyProjectListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const {
    list,
    contractStepVariableList,
    contractStatusVariableList,
    estimationStatusVariableList,
  } = useAffiliatedCompanyProjectListLogic(menuId);
  const value = useMemo(
    () => ({
      list,
      contractStepVariableList,
      contractStatusVariableList,
      estimationStatusVariableList,
    }),
    [list, contractStepVariableList, contractStatusVariableList, estimationStatusVariableList]
  );
  return (
    <AffiliatedCompanyProjectListContext.Provider value={value}>
      {children}
    </AffiliatedCompanyProjectListContext.Provider>
  );
};
