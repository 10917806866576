import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/mutation';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/query';

export const projectSalesInfoAffiliatedCompanyWebHardUpdateModalRepository = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      projectSalesInfoAffiliatedCompanyWebHardUpdateModalQuery.useDetailGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useUpdate: (menuId) => {
    const { mutate } =
      projectSalesInfoAffiliatedCompanyWebHardUpdateModalMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
