import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { OstRewardManagementMemoSearchForm } from '@front/ost_reward/view/memo/search-form';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { OstRewardManagementSearchQuery } from '@front/ost_reward/query';

export { Search as OstRewardManagementMemoSearchService };
const Search = () => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (query: OstRewardManagementSearchQuery) => {
      dispatch(ostRewardManagementAction.setMemoFilter(query));
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      keyword: '',
    } as OstRewardManagementSearchQuery,
    onSubmit: onSubmit,
  });

  const onKeyDown = useCallback(
    (e) => {
      if (e.key.toLowerCase() === 'enter') {
        const value = (e.target as HTMLInputElement).value ?? '';
        formik.setFieldValue('keyword', value);
        formik.handleSubmit();
      }
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <OstRewardManagementMemoSearchForm onKeyDown={onKeyDown} />
    </FormikProvider>
  );
};
