import { useQuery } from 'react-query';
import { projectSalesInfoContractContractApi } from '@front/src/features/project-sales-info/features/contract/features/contract/query/api';
import type { ProjectContractContractView } from '@front/src/features/project-sales-info/features/contract/features/contract/types/view';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';

export { query as projectSalesInfoContractContractQuery };

const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectContractContractView[]>({
      queryFn: () => projectSalesInfoContractContractApi.getList(id),
      queryKey: ['project', 'sales-info', 'contract', 'contract', 'list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
  useEstimationListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectEstimationView[]>({
      queryFn: () => projectSalesInfoContractContractApi.getEstimationList(id, menuId),
      queryKey: ['project', 'sales-info', 'contract', 'contract', 'estimation-list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
