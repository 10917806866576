import { HAS_VAT_OPTION } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/domain';
import type { ProjectContractEarningsCollectionStatusView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { vatRatioKeyword } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';

export const useRefineStatus = (
  hasVat: HAS_VAT_OPTION,
  status?: ProjectContractEarningsCollectionStatusView
) => {
  const { data: vatRatioList } = adminManageableVariableRepository.useListGet(vatRatioKeyword);
  if (!status || hasVat === HAS_VAT_OPTION.EXCLUDE || typeof vatRatioList === 'undefined')
    return status;
  const vatRatio = 1 + +(vatRatioList[0]?.value ?? 0) / 100;
  return {
    ...status,
    contractAmount: status.contractAmount * vatRatio || '',
    collectionAmount: status.collectionAmount * vatRatio || '',
    netCollectionAmount: status.netCollectionAmount * vatRatio || '',
    unpaidAmount: status.unpaidAmount * vatRatio || '',
    netUnpaidAmount: status.netUnpaidAmount * vatRatio || '',
    dueAmount: status.dueAmount * vatRatio || '',
    netDueAmount: status.netDueAmount * vatRatio || '',
    unclaimedAmount: status.unclaimedAmount * vatRatio || '',
    netUnclaimedAmount: status.netUnclaimedAmount * vatRatio || '',
  };
};
