import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { projectSalesInfoSubjectReviewModalComplexInformationApi } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/query/api';
import type { ProjectComplexInformationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoSubjectReviewModalComplexInformationMutation };
const mutation = {
  useCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        projectSalesInfoSubjectReviewModalComplexInformationApi.create(id, menuId),
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales-info',
            'subject-review',
            'shape-ratio',
            'complex-information',
            'list',
          ],
        });
        return res;
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectComplexInformationParameter) =>
        projectSalesInfoSubjectReviewModalComplexInformationApi.update(params, menuId),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales-info',
            'subject-review',
            'shape-ratio',
            'complex-information',
            'list',
          ],
        });
        if (variables.isChanged) {
          await queryClient.invalidateQueries({
            queryKey: [
              'project',
              'sales-info',
              'subject-review',
              'shape-ratio',
              'experiment-information',
            ],
          });
        }
      },
      onError: async (error) => {
        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales-info',
            'subject-review',
            'shape-ratio',
            'complex-information',
            'list',
          ],
        });
        handleError(error, alert);
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdListDeleteParameter) =>
        projectSalesInfoSubjectReviewModalComplexInformationApi.delete(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales-info',
            'subject-review',
            'shape-ratio',
            'complex-information',
            'list',
          ],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
