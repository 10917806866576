import { projectSalesInfoCollectionPaymentHistoryRepository } from '@front/src/features/project-sales-info/features/collection/features/payment-history/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoCollectionPaymentHistoryLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: paymentHistoryList, isLoading } =
    projectSalesInfoCollectionPaymentHistoryRepository.usePaymentHistoryListGet(id!, menuId);
  const { run: onDelete } =
    projectSalesInfoCollectionPaymentHistoryRepository.usePaymentHistoryDelete(id!, menuId);
  return {
    d: {
      paymentHistoryList,
    },
    h: {
      onDelete,
    },
    isLoading,
  };
};
