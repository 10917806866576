import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoAbstractEstimationDetailProvider } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/provider/provider';
import { ProjectSalesInfoAbstractEstimationDetail } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/components/detail';

export { Widget as ProjectSalesInfoAbstractEstimationDetailWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<TableLoadingSpinner colSpan={8} />}>
    <ProjectSalesInfoAbstractEstimationDetailProvider menuId={menuId}>
      <ProjectSalesInfoAbstractEstimationDetail />
    </ProjectSalesInfoAbstractEstimationDetailProvider>
  </Suspense>
);
