import { useMutation, useQueryClient } from 'react-query';
import { approvalBoxApi } from '@front/src/features/approval-box/api/api';

export const approvalBoxMutation = {
  useCancelApprovalDocument: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['approval-document', 'cancel'],
      mutationFn: (documentId: number) => approvalBoxApi.cancelApprovalDocument(documentId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['approval-box']);
      },
    });
    return { mutate };
  },
};
