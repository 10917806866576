import React from 'react';
import ApprovalDocumentDetailWidget from '@front/src/components/Drawer/ApprovalDocumentDetailWidget';
import { useQueryClient } from 'react-query';
import { useShallow } from 'zustand/react/shallow';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

export default function ApprovalBoxDetailWrapper() {
  const queryClient = useQueryClient();
  const { documentId, params } = useApprovalDocumentStore(
    useShallow((state) => ({
      documentId: state.documentId,
      params: state.params,
    }))
  );
  const boxDetail: any = queryClient.getQueryData(['approval-box', 'detail', documentId, params]);

  return <ApprovalDocumentDetailWidget documentDetail={boxDetail} />;
}
