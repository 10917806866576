import React from 'react';
import type { PopoverProps } from '@mui/material/Popover';
import Popover from '@mui/material/Popover';

interface Props extends Omit<PopoverProps, 'open'> {
  onClose: () => void;
  anchorEl: any | null;
}

export default function PopoverUI({ children, anchorEl, onClose, anchorOrigin }: Props) {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
}
