import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';

import { OldTd } from '@front/layouts/Table';
import { ColorPalette } from '@front/assets/theme';
import type { DefaultFunction } from '@front/type/Function';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { OstRewardManagementDetailStatusView } from '@front/ost_reward/domian';
import { convertMonthNumber } from '@front/ost_reward/view/detail/detail-status-table';

interface Props {
  item: OstRewardManagementDetailStatusView;
  onOpen: DefaultFunction;
}

export const DetailStatusTableRow = ({ item, onOpen }: Props) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(ostRewardManagementAction.setMonth(convertMonthNumber(item.month)));
    onOpen();
  }, [dispatch, item, onOpen]);

  return (
    <TableRow
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: ColorPalette._f4f4f4,
        },
      }}
    >
      <OldTd>{convertMonthNumber(item.month)}</OldTd>
      <OldTd>{item.totalPaymentAmount.toLocaleString()} 원</OldTd>
      <OldTd>{item.numberOfPeople}</OldTd>
      <OldTd>{item.highestAmount.toLocaleString()} 원</OldTd>
      <OldTd>{item.meanAmount.toLocaleString()} 원</OldTd>
      <OldTd>{item.lowestAmount.toLocaleString()} 원</OldTd>
    </TableRow>
  );
};
