import type { ReactNode } from 'react';
import React, { useState } from 'react';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkOutputTable from '@front/src/features/work/features/work/features/output/components/Table';
import WorkOutputModal from '@front/src/features/work/features/work/features/output/components/Modal';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';

import WorkOutputUpdateButtonIcon from '@front/src/features/work/features/work/features/output/components/UpdateButtonIcon';
import useModalOpen from '@front/src/hooks/useModalOpen';
import { Box } from '@mui/material';

/**
 * @module 업무-업무탭-폴더섹션
 */
interface Props extends SectionComponentProps {
  item: WorkOutputBundleView;
  index: number;
}

export default function WorkOutputFeature(props: Readonly<Props>) {
  const { item, index, readOnly, name } = props;
  const { onOpen, open, onClose } = useModalOpen();
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const { Layout } = SectionWrapperUI;
  const onOpenCreateModal = () => {
    setIsCreate(true);
    onOpen();
  };
  const onOpenUpdateModal = () => {
    setIsCreate(false);
    onOpen();
  };
  const icon = readOnly ? (
    <></>
  ) : (
    <WorkOutputUpdateButtonIcon
      onOpen={onOpenUpdateModal}
      setIsCreate={setIsCreate}
    />
  );
  return (
    <Layout>
      <Box sx={{ marginBottom: '14px' }}>
        <Title
          index={index}
          name={item.name}
          sectionName={name ?? ''}
        >
          {icon}
        </Title>
        <WorkOutputModal
          {...props}
          item={item}
          open={open}
          onClose={onClose}
          isCreate={isCreate}
        />
        <WorkOutputTable
          {...props}
          item={item}
          index={index}
          onOpenCreateModal={onOpenCreateModal}
          onOpenUpdateModal={onOpenUpdateModal}
        />
      </Box>
    </Layout>
  );
}

interface TitleProps {
  index: number;
  name: string;
  children: ReactNode;
  sectionName: string;
}

function Title({ index, name, children, sectionName }: TitleProps) {
  const { TitleWithSubTitleWrap } = SectionWrapperUI;
  const title = index === 0 ? '기본 폴더' : `${sectionName} 폴더 (종류별)`;
  const subTitle = name;
  return (
    <TitleWithSubTitleWrap
      title={title}
      subTitle={subTitle}
    >
      {children}
    </TitleWithSubTitleWrap>
  );
}
