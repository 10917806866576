import { useMutation, useQueryClient } from 'react-query';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';
import { projectSalesInfoAffiliatedCompanyWebHardUpdateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/query/api';

export const projectSalesInfoAffiliatedCompanyWebHardUpdateModalMutation = {
  useUpdate: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter) =>
        projectSalesInfoAffiliatedCompanyWebHardUpdateModalApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard', 'list'],
        });
      },
    });
    return {
      mutate,
    };
  },
};
