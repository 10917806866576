import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalWidget } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/widget';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Modal as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModal };
interface Props {
  menuId?: number;
}
const Modal = ({ menuId }: Props) => {
  const { isOpen, setIsOpen } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalState(
    useShallow((state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
    }))
  );
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  if (!isOpen) {
    return <></>;
  }
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="관계사 설정"
      open={isOpen}
      onClose={handleClose}
    >
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalWidget menuId={menuId} />
    </ModalLayout>
  );
};
