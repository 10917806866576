import axios, { type AxiosResponse } from 'axios';
import type {
  ImageDrawCommentParameter,
  ImageDrawHistoryParameter,
  ImageDrawHistoryView,
} from '@front/src/features/image-draw/types/view';
import type { FileItemView } from '@front/file-item';

const baseUrl = '/api/image-drawing';
const url = {
  getDrawingList: () => `${baseUrl}`,
  count: () => `${baseUrl}/count`,
  createDrawing: () => `${baseUrl}`,
  updateDrawing: (drawingId: number) => `${baseUrl}/${drawingId}`,
  deleteDrawing: (drawingId: number) => `${baseUrl}/${drawingId}`,
  getDrawing: (drawingId: number) => `${baseUrl}/${drawingId}`,
  getFileItemDataByFileKey: (fileKey?: string) => `/api/file-item/detail?fileKey=${fileKey}`,
};

export const drawingApi = {
  getDrawingList: async <T = ImageDrawHistoryView>(fileId: number, menuId?: number | undefined) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      `${url.getDrawingList()}?fileId=${fileId}`,
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
    return data;
  },
  count: async <T = number>(fileId: number, menuId?: number | undefined) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(`${url.count()}?fileId=${fileId}`, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getDrawing: async <T = ImageDrawHistoryView>(drawingId: number, menuId?: number | undefined) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getDrawing(drawingId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createDrawing: async <T = ImageDrawHistoryView>(
    params: ImageDrawHistoryParameter,
    menuId?: number
  ) => {
    const { data } = await axios.post<T, AxiosResponse<T>>(url.createDrawing(), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateDrawing: async (params: ImageDrawHistoryParameter, menuId?: number | undefined) =>
    await axios.put(url.updateDrawing(params.drawingId!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateDrawingComment: async (params: ImageDrawCommentParameter, menuId?: number | undefined) =>
    await axios.patch(url.updateDrawing(params.drawingId!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteDrawing: async (drawingId: number, menuId?: number) =>
    await axios.delete(url.deleteDrawing(drawingId), {
      headers: getMenuIdHeaders(menuId),
    }),
  getFileItemDataByFileKey: async <T = FileItemView>(
    fileKey?: string,
    menuId?: number | undefined
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      `${url.getFileItemDataByFileKey(fileKey)}`,
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
    return data;
  },
};

const getMenuIdHeaders = (menuId) => ({
  'Menu-Id': menuId,
});
