import { accountingBankAccountURL } from '@front/src/features/accounting/features/settings/utils/constant';
import type { AccountingSettingsBankAccountUpdateParameter } from '@front/src/features/accounting/features/settings/features/bank-account/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as AccountingSettingsBankAccountApi };

const url = {
  getList: () => `${accountingBankAccountURL}`,
  update: (id?) => `${accountingBankAccountURL}/${id}`,
};

const Api = {
  getList: async (menuId) => {
    const { data } = await axios.get(url.getList(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params: AccountingSettingsBankAccountUpdateParameter, menuId) => {
    const { data } = await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
