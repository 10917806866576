import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import type { TableHeadProps } from '@mui/material/TableHead';
import TableHead from '@mui/material/TableHead';

export default function VirtualizedAutoSizerTableHeadUI({ ...rest }: Readonly<TableHeadProps>) {
  return (
    <TableHead
      {...rest}
      component="div"
      sx={{
        background: ColorPalette.background.bg02,
        paddingRight: '10px',
      }}
    />
  );
}
