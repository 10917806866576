import type { DepartmentQuery } from 'department/query';
import type { DepartmentShortVO, DepartmentVO } from 'department/domain';
import type Page from 'type/Page';
import type { DepartmentParameter } from 'department/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

class DepartmentApi {
  async getList(menuId): Promise<DepartmentShortVO[]> {
    const { data } = await axios.get('/api/department/all', {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  }

  async getPage(query: DepartmentQuery): Promise<Page<DepartmentShortVO>> {
    const { data } = await axios.get('/api/department', {
      params: {
        ...query,
        category: query.category?.join(',') as unknown as string,
        parentId: query.parentId?.join(',') as unknown as string,
      },
      headers: getMenuIdHeaders(query.menuId),
    });
    return data;
  }

  async getOne(params): Promise<DepartmentVO> {
    const { data } = await axios.get(`/api/department/${params.id}`, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }

  async upsert(parameter: DepartmentParameter): Promise<void> {
    const { data } = await axios.put(
      `/api/department${parameter.id ? `/${parameter.id}` : ''}`,
      parameter,
      {
        headers: getMenuIdHeaders(parameter.menuId),
      }
    );
    return data;
  }

  async deleteOne(params): Promise<void> {
    const { data } = await axios.delete(`/api/department/${params.id}`, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  }
}

export const departmentApi = new DepartmentApi();
