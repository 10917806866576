import type { AddParameter } from '@front/src/types/parameter';
import { type IdsDataParameter, type SeqUpdateParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ArithmeticOperator } from '@front/src/utils';
import { AddPosition } from '@front/src/utils';
import type { MutateOptions } from 'react-query';
import { useQueryClient } from 'react-query';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

interface Props<T = any> {
  onAdd: (
    params: AddParameter,
    options?: MutateOptions<'', Error, AddParameter, unknown> | undefined
  ) => void;

  onUpdateSeq: (
    params: SeqUpdateParameter,
    options?: MutateOptions<'', Error, SeqUpdateParameter, unknown> | undefined
  ) => void;
  onDelete: (
    params: IdsDataParameter,
    options?: MutateOptions<'', Error, IdsDataParameter, unknown> | undefined
  ) => void;

  onUpdate: (params: T, options?: MutateOptions<'', Error, T, unknown> | undefined) => void;
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
  queryKey: (string | number | undefined)[];
}

const useUIBuilderTableRowMutation = <
  T extends {
    id: number;
  }
>({
  onAdd,
  onUpdateSeq,
  onDelete,
  formContext,
  onUpdate,
  queryKey,
}: Props<T>) => {
  const { confirm } = useCustomDialog();
  const queryClient = useQueryClient();
  const { setValue } = formContext;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);

  const handleAdd = (rowId: number) => {
    onAdd(
      {
        rowId,
        position: AddPosition.DEFAULT,
      },
      {
        onSuccess: (data, variables) => {
          const prevData: any[] | undefined = queryClient.getQueryData(queryKey);

          const findIndex = prevData?.findIndex((f) => f.id === variables.rowId);
          if (findIndex !== undefined && findIndex > -1 && prevData?.[findIndex + 1]) {
            const id = prevData[findIndex + 1].id;
            setValue('recentUpdatedId', id);
            toggleEditOpenId(id);
          }
        },
      }
    );
  };

  const handleUpdateSeq = (id: number, operator: ArithmeticOperator) => {
    onUpdateSeq(
      {
        id,
        operator,
      },
      {
        onSuccess: async (data, variables) => {
          setValue('recentUpdatedId', variables.id);
        },
      }
    );
  };

  const handleUpdate = (params: T) => {
    onUpdate(params, {
      onSuccess: async (data, variables) => {
        setValue('recentUpdatedId', variables.id);
      },
    });
  };

  const handleDelete = (id) => {
    onDelete(
      { id },
      {
        onSuccess: async () => {
          setValue('recentUpdatedId', undefined);
        },
      }
    );
  };

  const handleDeleteAfterConfirm = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        handleDelete(id);
      },
    });
  };

  return {
    onAdd: handleAdd,
    onUpdateSeq: handleUpdateSeq,
    onDelete: handleDeleteAfterConfirm,
    onUpdate: handleUpdate,
  };
};

export default useUIBuilderTableRowMutation;
