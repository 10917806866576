import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onDelete } = useProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonLogic(menuId);
  const value = useMemo(
    () => ({
      onDelete,
    }),
    [onDelete]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonContext.Provider>
  );
};
