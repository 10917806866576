import type { CSSProperties } from 'react';
import React from 'react';
import { Td } from '@front/src/components/layout/table/td';

export { WorkTd };

interface Props {
  children?: React.ReactNode;
  width?: string;
  colSpan?: number;
  align?: 'left' | 'center' | 'right';
  sx?: CSSProperties;
  height?: string;
}

const WorkTd = ({ children, sx, height = '40px', align = 'center', ...rest }: Props) => (
  <Td
    sx={{
      border: 'none',
      ...sx,
    }}
    align={align}
    height={height}
    {...rest}
  >
    {children}
  </Td>
);
