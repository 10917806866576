import React from 'react';
import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteSweep } from '@mui/icons-material';
import useWorkDetailTableHeadMutation from '@front/src/features/work/features/work/features/detail/hooks/useTableHeadMutation';

export default function WorkDetailTableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;

  const { onAddTop, onAddBottom, onRemoveAll } = useWorkDetailTableHeadMutation({
    dataId,
    menuId,
    sectionId,
  });

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
  };

  const controlPanelHeaderItemList = [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop },
    { text: '최하단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddBottom, split: true },
    { text: '전체 삭제', icon: DeleteSweep, action: actions.onRemoveAll, split: true },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
    />
  );
}
