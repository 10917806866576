export enum ECOUNT_FILE_TYPES {
  /**
   * 기초계좌
   */
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  /**
   * 자금일보
   */
  DAILY_FUNDS = 'DAILY_FUNDS',
  /**
   * 세무회계
   */
  FINANCIAL_ACCOUNT = 'FINANCIAL_ACCOUNT',
}

export const AccountingUploadCategory = {
  [ECOUNT_FILE_TYPES.BANK_ACCOUNT]: '기초 계좌 등록 파일',
  [ECOUNT_FILE_TYPES.DAILY_FUNDS]: '자금 일보 등록 파일',
  [ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT]: '회계 계정 등록 파일',
};

export const AccountingUploadCategoryName = {
  [ECOUNT_FILE_TYPES.BANK_ACCOUNT]: '기초 계좌 등록',
  [ECOUNT_FILE_TYPES.DAILY_FUNDS]: '자금 일보 등록',
  [ECOUNT_FILE_TYPES.FINANCIAL_ACCOUNT]: '회계 계정 등록',
};
