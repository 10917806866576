import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
  NDepthTableBox,
  NDepthTableBoxBody,
  NDepthTableBoxHead,
  NDepthTableBoxRow,
  NDepthTableContainerBox,
} from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import NDepthTableBoxHeadCell from '@front/src/components/n-depth-table-box/components/TableBoxHeadCell';
import TaxAccountTableLastHeadCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/LastHeadCell';
import TaxAccountTableVerticalRow from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/VerticalRow';
import TaxAccountTableLastRow from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/LastRow';
import type { TaxAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/types/view';

interface Props {
  header: number[];
  body: TaxAccountingTreeView[];
}

export default function TaxAccountTable(props: Props) {
  const { header, body } = props;
  const methods = useForm<TaxAccountTableFormValues>({
    defaultValues,
  });

  const { reset, getValues, control } = methods;

  const showListFieldArray = useFieldArray<TaxAccountTableFormValues, 'showList'>({
    control,
    name: 'showList',
  });

  const activeRowListFieldArray = useFieldArray<TaxAccountTableFormValues, 'activeRowList'>({
    control,
    name: 'activeRowList',
  });

  const emptyColList = [...Array(getValues('col'))];

  useEffect(() => {
    if (!header || !body) return;
    reset({
      ...defaultValues,
      col: header.length,
      header,
      showList: [body],
    });
  }, [header, body]);

  return (
    <FormProvider {...methods}>
      <NDepthTableContainerBox
        sx={{ overflowY: 'visible !important', overflowX: 'visible !important' }}
      >
        <NDepthTableBox>
          <NDepthTableBoxHead>
            <NDepthTableBoxRow>
              {emptyColList.map((h, index) => {
                if (emptyColList.length - 1 === index)
                  return (
                    <TaxAccountTableLastHeadCell
                      key="head-last"
                      count={header[index]}
                    />
                  );

                return (
                  <NDepthTableBoxHeadCell
                    key={`head-${index}`}
                    index={index}
                  />
                );
              })}
            </NDepthTableBoxRow>
          </NDepthTableBoxHead>
          <NDepthTableBoxBody>
            {emptyColList.map((_, index) => {
              if (emptyColList.length - 1 === index)
                return <TaxAccountTableLastRow key="row-last" />;

              return (
                <TaxAccountTableVerticalRow
                  key={`row-col-${index}`}
                  index={index}
                  showListFieldArray={showListFieldArray}
                  activeRowListFieldArray={activeRowListFieldArray}
                />
              );
            })}
          </NDepthTableBoxBody>
        </NDepthTableBox>
      </NDepthTableContainerBox>
    </FormProvider>
  );
}

export interface TaxAccountTableFormValues {
  row: number;
  col: number;
  header: number[];
  activeRowList: { value: number }[];
  showList: TaxAccountingTreeView[][];
  lastAccount: TaxAccountingTreeView[];
}

const defaultValues = {
  row: 20,
  col: 8,
  header: [],
  activeRowList: [],
  showList: [],
};
