import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { RootState } from '@front/services/reducer';
import { campaignAction } from '@front/ost_campaign/action';
import UserSelectorModal from '@front/components/UserSelector/Modal';
import type { EstimatorListUpsertParameter } from '@front/ost_campaign/parameter';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { AuthorizationType } from '@front/user/domain';

const ModalAddCampaignEstimatorService = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (root: RootState) => root.campaign.addCampaignEstimatorModalOpen,
    shallowEqual
  );
  const [estimatorList] = useSelector(
    (root: RootState) => [root.campaign.estimatorList],
    shallowEqual
  );
  const onClose = useCallback(() => {
    dispatch(campaignAction.setAddCampaignEstimatorModalOpen(false));
  }, [dispatch]);
  const onChange = useCallback(
    (list) => {
      if (list.length <= 0) {
        dispatch(
          snackbarAction.show({
            message: '최소 한 명의 심사 담당자가 필요합니다.',
            severity: SnackbarSeverityType.warning,
          })
        );
        return;
      }
      dispatch(
        campaignAction.upsertCampaignEstimatorList({
          estimatorList: list,
        } as EstimatorListUpsertParameter)
      );
    },
    [dispatch]
  );
  return (
    <UserSelectorModal
      open={isOpen}
      onClose={onClose}
      multi={true}
      value={estimatorList.map((item) => item.estimator.id)}
      onChange={onChange}
      menuIdList={[29]}
      typeList={[AuthorizationType.W]}
    />
  );
};

const ModalAddEstimatorService = memo(ModalAddCampaignEstimatorService);

export default ModalAddEstimatorService;
