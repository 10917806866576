import React, { useContext } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/context';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceTableRow } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/components/preference-table-row';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';

export { PreferenceTable as AffiliatedCompanyPersonDetailModalCharacterPreferenceTable };
const PreferenceTable = () => {
  const {
    d: { preferenceList },
    isLoading,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterPreferenceContext);
  if (typeof preferenceList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (preferenceList.length === 0) {
    return <NoResultBox message="저장된 취향이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {preferenceList.map((item, i) => (
            <AffiliatedCompanyPersonDetailModalCharacterPreferenceTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
