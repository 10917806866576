import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { getCommonControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import useWorkReviewerState from '@front/src/features/work/features/work/features/reviewer/useState';
import WorkReviewerTableBody from '@front/src/features/work/features/work/features/reviewer/components/TableBody';
import useWorkReviewerTableMutation from '@front/src/features/work/features/work/features/reviewer/hooks/useTableMutation';
import useWorkWorkerGetIsLoading from '@front/src/features/work/features/work/features/reviewer/hooks/useGetIsLoading';
import workReviewerQuery from '@front/src/features/work/features/work/features/reviewer/query/query';

export default function WorkReviewerTable(props: Readonly<SectionComponentProps>) {
  const { sectionId, dataId, menuId } = props;
  const { clearEditOpenList } = useWorkReviewerState(
    useShallow((state) => ({
      clearEditOpenList: state.clearEditOpenList,
    }))
  );

  const { onAddTop, onAddBottom, onRemoveAll } = useWorkReviewerTableMutation({
    sectionId,
    dataId,
    menuId,
  });

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
  };

  const controlPanelHeaderItemList = getCommonControlPanelHeaderItemList({
    actions,
  });

  const isLoading = useWorkWorkerGetIsLoading();

  const { data: list } = workReviewerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      BodyComponent={WorkReviewerTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      list={list}
    />
  );
}
