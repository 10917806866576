import React from 'react';

import { AccountingUploadDataUploadProvider } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/provider/provider';
import { AccountingUploadEcountDataForm } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/components/form';
import { accountingUploadEcountDataFormLayout } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/components/layouts';

export { Widget as AccountingUploadEcountDataUploadWidget };

interface Props {
  menuId?: number;
}

const Widget = ({ menuId }: Props) => {
  const { Layout } = accountingUploadEcountDataFormLayout;
  return (
    <Layout>
      <AccountingUploadDataUploadProvider menuId={menuId}>
        <AccountingUploadEcountDataForm />
      </AccountingUploadDataUploadProvider>
    </Layout>
  );
};
