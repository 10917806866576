// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wa133Ni5c8Gv_Dx9U20K{display:flex;gap:10px;align-items:center}.uCjEb3u3hn7je8cwzxNX{font-size:14px;font-weight:bold;cursor:pointer}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/outline/features/number/features/business-registration/components/file.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 10px;\r\n  align-items: center;\r\n}\r\n\r\n.result {\r\n  font-size: 14px;\r\n  font-weight: bold;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `wa133Ni5c8Gv_Dx9U20K`,
	"result": `uCjEb3u3hn7je8cwzxNX`
};
export default ___CSS_LOADER_EXPORT___;
