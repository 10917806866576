import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import UserSelectorSelectedList from '@front/src/features/user-selector/features/form/components/SelectedList';
import { ColorPalette } from '@front/assets/theme';
import UserSelectorUserList from '@front/src/features/user-selector/features/form/components/UserList';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

export default function UserSelectorUserSelectForm(props: Readonly<UserSelectorInputProps>) {
  const { control } = useFormContext();
  const { append, remove, fields, replace } = useFieldArray({
    name: 'selectedList',
    control,
  });
  return (
    <>
      <Box
        sx={{
          flex: 2.5,
          flexDirection: 'column',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          minHeight: '40dvh',
          maxHeight: '40dvh',
          overflowY: 'auto',
          scrollbarGutter: 'stable',
          minWidth: '300px',
        }}
      >
        <UserSelectorUserList
          {...props}
          append={append}
          remove={remove}
          replace={replace}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ArrowRightIcon />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          p: '10px',
          maxHeight: '40dvh',
          overflowY: 'auto',
          minWidth: '200px',
        }}
      >
        <UserSelectorSelectedList
          {...props}
          fields={fields}
          remove={remove}
        />
      </Box>
    </>
  );
}
