import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type {
  ProjectContractEarningsConsortiumCompanyView,
  SmProjectCollectionView,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';

interface State {
  h: {
    onUpdate: (params: SmProjectCollectionParameter) => void;
  };
  d: {
    consortiumCompanyList?: ProjectContractEarningsConsortiumCompanyView[];
    contractEarningsItemDetails?: SmProjectCollectionView;
    confirmedContractDetail?: ProjectContractConfirmedView;
  };
  isLoading?: boolean;
  remove: () => void;
}

export { Context as ProjectSalesInfoContractEarningsItemUpdateModalContext };

const Context = createContext<State>({
  h: {
    onUpdate: noOp,
  },
  d: {},
  remove: noOp,
});
