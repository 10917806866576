import { useFieldArray, useForm } from 'react-hook-form';
import type {
  ManagementAccountingTreeView,
  ManagementAccountingTreeWithCountView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';

const useManagementAccountTableForm = () => {
  const methods = useForm<ManagementAccountTableFormValues>({
    defaultValues,
  });

  const { getValues, control, reset } = methods;

  const showListFieldArray = useFieldArray<ManagementAccountTableFormValues, 'showList'>({
    control,
    name: 'showList',
  });

  const selectedRowIndexListFieldArray = useFieldArray<
    ManagementAccountTableFormValues,
    'selectedRowIndexList'
  >({
    control,
    name: 'selectedRowIndexList',
  });

  const initialize = (data: ManagementAccountingTreeWithCountView) => {
    const currentValues = getValues();

    const showList = getShowListFromSelectedRowIndexList(
      data.tree,
      currentValues.selectedRowIndexList,
      data.countList.length - 1
    );

    const maxRow = Math.max(...showList.map((s) => s.length));

    const active = showList.flat().find((s) => s.id === currentValues.active?.id);

    reset({
      ...currentValues,
      header: data.countList.map((c) => c.depthCount),
      showList,
      row: Math.max(maxRow + 1, 20),
      active,
    });
  };

  return {
    methods,
    showListFieldArray,
    selectedRowIndexListFieldArray,
    initialize,
  };
};

const getShowListFromSelectedRowIndexList = (
  tree: ManagementAccountingTreeView[],
  selectedRowIndexList: { value: number }[],
  lastColIndex
) => {
  const showList = [tree];

  selectedRowIndexList.forEach((rowIndex, colIndex) => {
    if (lastColIndex !== colIndex && showList[colIndex][rowIndex.value]) {
      showList.push(showList[colIndex][rowIndex.value].children);
    }
  });

  return showList;
};

export interface ManagementAccountTableFormValues {
  row: number;
  col: number;
  header: number[];
  selectedRowIndexList: { value: number }[];
  showList: ManagementAccountingTreeView[][];
  updateId: number | undefined;
  active: ManagementAccountingTreeView | undefined;
  showManual: boolean;
  manual: any[];
}

const defaultValues = {
  row: 20,
  col: 6,
  header: [],
  selectedRowIndexList: [],
  showList: [],
  updateId: undefined,
  active: undefined,
  showManual: false,
  manual: [],
};

export default useManagementAccountTableForm;
