import { CallbackOption, getNoOpCallback, getSetCallbackFunc } from '@front/src/utils/constant';
import {
  AccountStatusCommonParameter,
  AccountStatusDetailView,
  AccountStatusSummaryView,
  AccountStatusGetParameter,
  AccountStatusUpdateParameter,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';
import AccountStatusQuery
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/query';
import AccountStatusMutation
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/mutation';
import {
  accountStatusApiResponseToDetailView,
  accountStatusApiResponseToSummaryView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/util/convert';

type RepositoryType = {
  useGetDate: (params: AccountStatusGetParameter) => {
    data: string | undefined,
    isLoading: boolean,
  },
  useGetSummaryList: (params: AccountStatusGetParameter) => {
    data: AccountStatusSummaryView[] | undefined,
    isLoading: boolean,
  },
  useGetDetailList: (params: AccountStatusGetParameter) => {
    data: AccountStatusDetailView[] | undefined,
    isLoading: boolean
  },
  useUpdate: (params: AccountStatusCommonParameter) => {
    run: (params: AccountStatusUpdateParameter) => void,
    setCallback: (to: Partial<CallbackOption>) => void,
  }
}

const AccountStatusRepository: RepositoryType = {

  useGetDate: (params) => {
    const { data, isLoading } = AccountStatusQuery.useGetList(params);
    return {
      data: data?.standardDate,
      isLoading,
    };
  },

  useGetSummaryList: (params) => {
    const { data, isLoading } = AccountStatusQuery.useGetList(params);
    return {
      data: accountStatusApiResponseToSummaryView(data),
      isLoading,
    };
  },

  useGetDetailList: (params) => {
    const { data, isLoading } = AccountStatusQuery.useGetList(params);
    return {
      data: accountStatusApiResponseToDetailView(data),
      isLoading,
    };
  },

  useUpdate: (commonParams) => {
    const { mutate } = AccountStatusMutation.useUpdate(commonParams);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};

export default AccountStatusRepository;
