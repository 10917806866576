import React from 'react';
import { Box } from '@mui/material';

import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { EvaluationMethodBottomRateInput } from '@front/ost/view/detail/TrimmedMeanBottomRateInput';
import { TrimmedMeanTopRateInput } from '@front/ost/view/detail/TrimmedMeanTopRateInput';

export const TrimmedMeanForm = () => (
  <DataFieldWithLabel
    label="상금 계산 방식"
    labelSX={{ minWidth: '80px' }}
  >
    <DataFieldWithLabel label="하위">
      <EvaluationMethodBottomRateInput />
    </DataFieldWithLabel>
    <Box sx={{ width: '40px' }} />
    <DataFieldWithLabel label="상위">
      <TrimmedMeanTopRateInput />
    </DataFieldWithLabel>
  </DataFieldWithLabel>
);
