import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/query/api';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/parameter';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalMutation = {
  useCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalParameter) =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyModalApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
