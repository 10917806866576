import React, { useContext } from 'react';
import { Table } from '@front/src/components/layout/table/table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import UserSelector from '@front/components/UserSelector';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoProjectDivisionContext } from '@front/src/features/project-sales-info/features/project-division/widgets/context';
import { changeViewToParams } from '@front/src/features/project-sales-info/features/project-division/utils/constants';
import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";

export { Index as ProjectSalesInfoProjectDivisionManagerTable };

const Index = () => {
  const { setValue, watch, handleSubmit } = useFormContext();
  const {
    h: { onProjectDivisionUpdate },
  } = useContext(ProjectSalesInfoProjectDivisionContext);
  const onSubmit = handleSubmit((data) =>
    onProjectDivisionUpdate(changeViewToParams(data as ProjectDivisionView))
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th>접수자</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <UserSelector
                position="top"
                name="receptionManager"
                onChange={(value) => {
                  setValue('receptionManager', value);
                  onSubmit();
                }}
                value={currentAuth === 'V' ? 0 : watch('receptionManager')}
                style={{ width: '20%' }}
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>영업 담당자</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <UserSelector
                position="top"
                onChange={(value) => {
                  setValue('salesManager', value);
                  onSubmit();
                }}
                value={currentAuth === 'V' ? 0 : watch('salesManager')}
                style={{ width: '20%' }}
                readOnly={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>수금 담당자</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <UserSelector
                position="top"
                onChange={(value) => {
                  setValue('collectionManager', value);
                  onSubmit();
                }}
                value={currentAuth === 'V' ? 0 : watch('collectionManager')}
                style={{ width: '20%' }}
                readOnly={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th>PM</Th>
            <OldTd sx={{ textAlign: 'left' }}>
              <UserSelector
                position="top"
                onChange={(value) => {
                  setValue('projectManager', value);
                  onSubmit();
                }}
                value={currentAuth === 'V' ? 0 : watch('projectManager')}
                style={{ width: '20%' }}
                readOnly={readOnly}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
