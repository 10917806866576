import { createContext } from 'react';
import type { AccountingSettingsManagementAccountingAccountCountView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';

export { Context as AccountingSettingsManagementAccountingReadCountContext };

interface State {
  detail?: AccountingSettingsManagementAccountingAccountCountView;
}

const Context = createContext<State>({});
