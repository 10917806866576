import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/types/view';
import type { OptionType } from '@front/src/types';

export const getStructuralDesignCompanyOptionList = (
  companyList?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyView[]
) => {
  const result: OptionType[] = [];
  if (typeof companyList === 'undefined') {
    return result;
  }
  const companyIdSet = new Set<number>();
  companyList
    .filter((item) => item.structuralDesignCompany?.id)
    .forEach((item) => {
      if (!companyIdSet.has(item.structuralDesignCompany.id!)) {
        result.push({
          value: item.structuralDesignCompany.id!.toString(),
          label: item.structuralDesignCompany.name,
        });
      }
      companyIdSet.add(item.structuralDesignCompany.id!);
    });
  return result;
};
