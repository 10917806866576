// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xy6zXO4IGsl8AiwoxZp8{display:flex;gap:10px;align-items:center}.YGDjkMycnvsi1q3j9qXW{font-size:14px;font-weight:bold;display:flex;align-items:center;gap:5px;white-space:nowrap}.Oq5I_2efMluIj5wuw3Wn{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:20px}._veLthrgjkuEIoyE3PhQ{display:flex;justify-content:center;gap:50px;width:300px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/components/modal-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,WAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 10px;\r\n  align-items: center;\r\n}\r\n\r\n.result {\r\n  font-size: 14px;\r\n  font-weight: bold;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n  white-space: nowrap;\r\n}\r\n\r\n.wrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 20px;\r\n}\r\n\r\n.btn__container {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 50px;\r\n  width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Xy6zXO4IGsl8AiwoxZp8`,
	"result": `YGDjkMycnvsi1q3j9qXW`,
	"wrapper": `Oq5I_2efMluIj5wuw3Wn`,
	"btn__container": `_veLthrgjkuEIoyE3PhQ`
};
export default ___CSS_LOADER_EXPORT___;
