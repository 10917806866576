import { create } from 'zustand';

interface State {
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: () => void;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: () => void;
  itemId?: number;
  setItemId: (itemId: number) => void;
  idList: number[];
  setIdList: (idList: number[]) => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoPaymentHistoryState };

const useState = create<State>((set) => ({
  isCreateModalOpen: false,
  setIsCreateModalOpen: () =>
    set(({ isCreateModalOpen }) => ({ isCreateModalOpen: !isCreateModalOpen })),
  isUpdateModalOpen: false,
  setIsUpdateModalOpen: () =>
    set(({ isUpdateModalOpen }) => ({ isUpdateModalOpen: !isUpdateModalOpen })),
  setItemId: (itemId) => set(() => ({ itemId })),
  idList: [],
  setIdList: (idList: number[]) => set(() => ({ idList })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
