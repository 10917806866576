import React, { useMemo } from 'react';
import { TableRow } from '@mui/material';
import { Tbc } from '@front/src/components/ui-builder/table';
import { useFormContext, useWatch } from 'react-hook-form';
import { ColorPalette } from '@front/assets/theme';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableCheckbox } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableCheckbox';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableNote } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableNote';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { SelectableHeaderTableRow as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableRow };

interface Props {
  index: number;
  authMenuId: number | undefined;
}

const SelectableHeaderTableRow = ({ index, authMenuId }: Readonly<Props>) => {
  const { control } = useFormContext();
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { data: selectableHeaderList } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  const item = useWatch({
    name: `selectableHeaderList.${index}`,
    control,
  });
  const { isUsed, note, parentName, headerSettingId } = item;
  const originalChildList = list?.find((l) => l.id === parentId)?.modalHeaderList ?? [];
  const findFromChildList = originalChildList.find((o) => o.headerSettingId === headerSettingId);
  const findFromSelectableHeaderList = selectableHeaderList?.find(
    (s) => s.headerSettingId === headerSettingId
  );
  const isNoteChanged = useMemo(
    () => (findFromSelectableHeaderList?.note ?? '') !== note,
    [findFromSelectableHeaderList, note]
  );
  const checkboxSx = useMemo(() => {
    const getColor = () => {
      if (isUsed) {
        return findFromChildList !== undefined ? ColorPalette._d7e2f7 : ColorPalette._fff2cc;
      }
      return 'inherit';
    };
    return { backgroundColor: getColor() };
  }, [isUsed, findFromChildList, parentId]);
  return (
    <TableRow>
      <Tbc
        align="center"
        sx={checkboxSx}
      >
        <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableCheckbox
          index={index}
          authMenuId={authMenuId}
        />
      </Tbc>
      <Tbc>{item.name}</Tbc>
      <Tbc sx={checkboxSx}>{parentName}</Tbc>
      <Tbc
        align="center"
        sx={getIsNewSx(isNoteChanged)}
      >
        <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableNote index={index} />
      </Tbc>
    </TableRow>
  );
};

const getIsNewSx = (isChanged) => ({
  backgroundColor: isChanged ? ColorPalette._fff2cc : 'inherit',
});
