import React, { useCallback, useMemo } from 'react';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';
import CheckIcon from '@mui/icons-material/Check';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import type { HeaderSettingView } from '@front/src/types';
import { useFormContext } from 'react-hook-form';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';

export { ParentTableRow as PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableRow };

interface Props {
  item: HeaderSettingView;
}

const ParentTableRow = ({ item }: Props) => {
  const { setParentId, parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      setParentId: state.setParentId,
      parentId: state.parentId,
    }))
  );
  const { setOpen, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const active = useMemo(() => parentId === item.id, [parentId, item]);
  const {
    formState: { isDirty },
    reset,
  } = useFormContext();
  const onCancel = useCallback(() => {
    reset();
    setParentId(item.id);
  }, [reset, setParentId, item]);
  const handleClick = useCallback(() => {
    if (active) return;
    if (isDirty) {
      setTitle('헤더(모) 이동 확인');
      setDescriptionList([
        '저장되지 않은 변경사항은 반영되지 않습니다.',
        '헤더(모)를 이동하시겠습니까?',
      ]);
      setOpen(true, () => {
        onCancel();
      });
      return;
    }
    onCancel();
  }, [active, isDirty, setOpen, onCancel, setDescriptionList, setTitle]);
  const sx = useMemo(
    () => ({
      backgroundColor: active ? ColorPalette._9bb6ea : ColorPalette._ffffff,
    }),
    [active]
  );
  const rowSx = useMemo(
    () => ({
      cursor: active ? 'default' : 'pointer',
    }),
    [active]
  );
  return (
    <TableRowBox
      sx={rowSx}
      onClick={handleClick}
    >
      <TbcBox
        sx={{
          flex: 2,
          ...sx,
        }}
      >
        <Box>{item.name}</Box>
      </TbcBox>
      <TbcBox
        sx={{
          flex: 1,
          justifyContent: 'center',
          ...sx,
        }}
      >
        {item.modalHeaderList?.length > 0 && <CheckIcon sx={iconSx} />}
      </TbcBox>
    </TableRowBox>
  );
};

const iconSx = {
  color: ColorPalette._ffb72b,
};
