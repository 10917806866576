import React from 'react';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import HeaderHistoryFeature from '@front/src/features/header-history';

interface Props {
  sectionId?: number;
  open: boolean;
  onClose: () => void;
}

export default function HeaderSettingsHistoryModal({ sectionId, open, onClose }: Readonly<Props>) {
  return (
    <ModalUI
      open={open}
      title="헤더명 변경 이력"
      onClose={onClose}
    >
      <HeaderHistoryFeature
        sectionId={sectionId}
        onClose={onClose}
      />
    </ModalUI>
  );
}
