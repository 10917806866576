import React from 'react';
import { ProjectSalesInfoActivityDeleteButton } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/components/delete-button';
import { ProjectSalesInfoActivityDeleteButtonProvider } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/provider/proivder';

export { Widget as ProjectSalesInfoActivityDeleteButtonWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoActivityDeleteButtonProvider menuId={menuId}>
    <ProjectSalesInfoActivityDeleteButton />
  </ProjectSalesInfoActivityDeleteButtonProvider>
);
