import { create } from 'zustand';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';

export { useState as useAccountingSettingsManagementAccountingAccountTableState };

interface State {
  parentIdList: number[];
  setParentIdList: (parentIdList: number[]) => void;
  targetItem?: AccountingSettingsManagementAccountingAccountTableCategoryView;
  setTargetItem: (targetItem) => void;
  updateId?: number;
  setUpdateId: (updateId) => void;
  anchorEl: HTMLElement | null;
  largeAnchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl) => void;
  setLargeAnchorEl: (anchorEl) => void;
  categoryMircoName?: string;
  setCategoryMircoName: (categoryMircoName) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const useState = create<State>((set) => ({
  parentIdList: [],
  setParentIdList: (parentIdList) =>
    set({
      parentIdList,
    }),
  setUpdateId: (updateId) =>
    set({
      updateId,
    }),
  setTargetItem: (targetItem) =>
    set({
      targetItem,
    }),
  anchorEl: null,
  largeAnchorEl: null,
  setAnchorEl: (anchorEl) =>
    set({
      anchorEl,
    }),
  setLargeAnchorEl: (largeAnchorEl) =>
    set({
      largeAnchorEl,
    }),
  setCategoryMircoName: (categoryMircoName) =>
    set({
      categoryMircoName,
    }),
  isOpen: false,
  setIsOpen: (isOpen) =>
    set({
      isOpen,
    }),
}));
