import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';
import type { WorkApprovalDocumentView } from '@front/src/features/work/features/work/features/approval/types/view';
import { getApprovalDocumentStatusLabel } from '@front/src/features/drawer-approval-document/utils';
import { useFormContext } from 'react-hook-form';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface Props extends Pick<CellComponentProps, 'dataId' | 'menuId' | 'sectionId'> {
  onClose: () => void;
}

export default function WorkApprovalReadingHeader(props: Readonly<Props>) {
  const { sectionId, onClose, dataId, menuId } = props;
  const { setValue } = useFormContext();
  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  const handleChange = (event, child) => {
    event.preventDefault();
    setValue('workId', child.props.value);
  };
  return (
    <Box
      sx={{
        height: '46px',
        p: '0 20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '14px',
          borderBottom: `1px solid ${ColorPalette.line.line}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TypographyUI shape="text-m-regular">
            {`총 ${data?.approvalDocumentList?.length}`}
          </TypographyUI>
          <Box
            sx={{
              width: '14px',
              height: '0px',
              border: `1px solid ${ColorPalette.line.line03}`,
              transform: 'rotate(90deg)',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <TypographyUI shape="text-m-regular">결재 건 선택</TypographyUI>
            <Box
              sx={{
                width: '800px',
              }}
            >
              <DropDownWithHookForm
                name="workId"
                options={getOptions(data?.approvalDocumentList)}
                onChange={handleChange}
              />
            </Box>
            <TypographyUI
              shape="text-s-regular"
              sx={{
                color: ColorPalette.greyscale.text_secondary,
              }}
            >
              결재 요청 시점의 업무 내용이 보여집니다.
            </TypographyUI>
          </Box>
        </Box>
        <Box
          sx={{
            width: '64px',
          }}
        >
          <ButtonBasicUI onClick={onClose}>닫기</ButtonBasicUI>
        </Box>
      </Box>
    </Box>
  );
}

function getOptions(list?: WorkApprovalDocumentView[]) {
  if (!list) return [];
  return list.map((l) => ({
    label: `${l.approvalDocument.title} (${
      getApprovalDocumentStatusLabel[l.approvalDocument.status]
    })`,
    value: l.workId.toString(),
  }));
}
