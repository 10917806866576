import React, { memo } from 'react';
import { Box } from '@mui/material';

import Button from '@front/layouts/Button';
import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import { ProposalStatus } from '@front/ost_proposal/domain';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  proposal: ProposalVO;
  onOpen: (id: ProposalId) => () => void;
}

const ProposalStateEstimator = ({ proposal, onOpen }: Props) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      placeItems: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {proposal.estimatorList.length === 0 && <Box>-</Box>}
      {proposal.estimatorList.length > 0 &&
        proposal.estimatorList.map((estimatorInfo) => (
          <Box
            key={estimatorInfo.id}
            sx={{
              backgroundColor:
                estimatorInfo.grade !== null
                  ? ColorPalette._fff2cc
                  : proposal.contributorViewList
                      .filter((cv) => cv.contributor)
                      .map((cv) => cv.contributor.id)
                      .includes(estimatorInfo.estimator.id)
                  ? ColorPalette._F8CECC
                  : '',
            }}
          >
            {estimatorInfo.estimator.name}
          </Box>
        ))}
    </Box>

    <Button
      onClick={onOpen(proposal.id)}
      disabled={
        !(
          proposal.status === ProposalStatus.WRITING ||
          proposal.status === ProposalStatus.SUBMISSION
        )
      }
    >
      변경
    </Button>
  </Box>
);

const ProposalEstimator = memo(ProposalStateEstimator);

export default ProposalEstimator;
