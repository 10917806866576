import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyPersonDetailModalRelationshipLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalRelationshipContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/context';
import { AffiliatedCompanyPersonDetailModalRelationshipButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/components/relationship-button';
import { AffiliatedCompanyPersonDetailModalRelationshipTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/components/relationship-table';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalRelationshipState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useState';

interface Props {
  readOnly?: boolean;
  menuId?: number;
}

const AffiliatedCompanyPersonDetailModalRelationshipWidget = ({ readOnly, menuId }: Props) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { relationshipList },
    h: { setIdList, onRelationshipDelete, onRelationshipCreate, onRelationshipUpdate },
    idList,
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalRelationshipLogic(menuId);
  const value = useMemo(
    () => ({
      d: { relationshipList },
      h: { setIdList, onRelationshipDelete, onRelationshipCreate, onRelationshipUpdate },
      isLoading,
      idList,
    }),
    [
      relationshipList,
      isLoading,
      setIdList,
      idList,
      onRelationshipDelete,
      onRelationshipCreate,
      onRelationshipUpdate,
    ]
  );
  const { setReadOnly } = useAffiliatedCompanyPersonDetailModalRelationshipState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AffiliatedCompanyPersonDetailModalRelationshipContext.Provider value={value}>
      <Layout>
        <Header>
          <AffiliatedCompanyPersonDetailModalRelationshipButton />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalRelationshipTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalRelationshipContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalRelationshipWidget;
