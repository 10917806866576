import React, { memo } from 'react';
import { Box } from '@mui/material';

import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';

import type { DefaultFunction } from '@front/type/Function';
import type { ProposalCategoryId, ProposalCategoryVO } from '@front/ost_campaign/domain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import type { SectionActionProp } from '@front/components/Section';
import Section from '@front/components/Section';
import { styled } from '@mui/material/styles';

import Checkbox from '@front/layouts/Checkbox';

interface Props {
  proposalCategoryList: ProposalCategoryVO[];
  onAdd: DefaultFunction;
  onDelete: (id: ProposalCategoryId) => () => void;
  onChange: (id: ProposalCategoryId) => () => void;
}

const ProposalCategoryForm = ({
  proposalCategoryList,
  onAdd,
  onDelete,

  onChange,
}: Props) => {
  const actions: SectionActionProp[] = [
    {
      name: '추가',
      onClick: onAdd,
    },
  ];

  return (
    <Section
      title="제안 구분"
      actions={actions}
      minHeight={70}
    >
      {proposalCategoryList.length === 0 && <NoRegisteredItems />}
      {proposalCategoryList.length > 0 && (
        <CategoryItemContainer>
          {proposalCategoryList.map((proposalCategory) => (
            <CategoryItem key={proposalCategory.id}>
              <Box
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <Checkbox
                  sx={{
                    marginRight: '10px',
                  }}
                  defaultValue={proposalCategory.id}
                  checked={proposalCategory.isUsed}
                  onChange={onChange(proposalCategory.id)}
                />
                <TextBox variant="body2">{proposalCategory.name}</TextBox>
              </Box>
              <Box
                onClick={onDelete(proposalCategory.id)}
                sx={{
                  display: 'grid',
                  placeItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faDeleteLeft}
                  style={{ color: ColorPalette._eb4c4c }}
                />
              </Box>
            </CategoryItem>
          ))}
        </CategoryItemContainer>
      )}
    </Section>
  );
};

const CategoryItemContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  columnGap: '30px',
  rowGap: '20px',
  alignItems: 'start',
});

const CategoryItem = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '4fr 1fr',
  alignItems: 'center',
  backgroundColor: ColorPalette._f4f4f4,
  padding: '5px',
  borderRadius: '5px',
});

const NoRegisteredItems = () => (
  <Box
    sx={{
      display: 'grid',
      placeItems: 'center',
    }}
  >
    <TextBox variant="body9">현재 등록된 제안 구분이 없습니다</TextBox>
  </Box>
);

const CategoryForm = memo(ProposalCategoryForm);
export default CategoryForm;
