import { create } from 'zustand';

export { useState as useProjectSalesInfoContractHistoryModalState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  id?: number;
  setId: (id?: number) => void;
}

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
  setId: (id) => set(() => ({ id })),
}));
