import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';
import { AffiliatedPersonAppBarCompanySelectModalContext } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/provider/context';

export { Row as AffiliatedPersonCompanySelectModalListRow };

interface Props {
  item: AffiliatedPersonCompanyView;
}

const Row = ({ item }: Props) => {
  const { onCompanySelect, setIsOpen } = useContext(
    AffiliatedPersonAppBarCompanySelectModalContext
  );
  const handleRowClick = useCallback(
    (item) => {
      setIsOpen(false);
      onCompanySelect(item);
    },
    [onCompanySelect]
  );
  return (
    <TableRow
      hover
      key={item.id}
      onClick={() => handleRowClick(item)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <OldTd>{item.category}</OldTd>
      <OldTd>{item.code}</OldTd>
      <OldTd>{item.name}</OldTd>
    </TableRow>
  );
};
