import React from 'react';
import { OldTd, Table } from '@front/layouts/Table';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { transportationTypeList } from '@front/src/features/affiliated-company/utils/constant';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormBusinessTrip as AffiliatedCompanyMeetingHistoryModalFormBusinessTrip };
const FormBusinessTrip = () => {
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableContainer>
      <Table>
        <TableHeader />
        <TableBody>
          <TableRow>
            <Th width="86px">출장방법</Th>
            <TableCell>
              <HookFormSelect
                name="type"
                defaultLabel="선택"
                optionList={transportationTypeList}
                disabled={readOnly}
              />
            </TableCell>
            <OldTd>
              <HookFormInput
                name="transportationNote"
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="86px">출장거리(km)</Th>
            <OldTd>
              <HookFormInput
                name="km"
                type="number"
                disabled={readOnly}
              />
            </OldTd>
            <OldTd>
              <HookFormInput
                name="distanceNote"
                disabled={readOnly}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="200px"
      >
        세부내용
      </Th>
      <Th
        center
        width="600px"
      >
        비고
      </Th>
    </TableRow>
  </TableHead>
);
