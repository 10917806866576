import React, { useCallback, useContext, useMemo } from 'react';
import { TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { FormProvider, useForm } from 'react-hook-form';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/components/button-group';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/widgets/context';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/features/modal/types/view';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { convertNullToEmptyForForm, convertNumberToStringForForm } from '@front/src/utils';
import { AffiliatedCompanyCategory } from '@front/src/features/affiliated-company/types/domain';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';

export { Form as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalForm };

const getValues = (detail?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalView) => ({
  buildingCompanyId: convertNumberToStringForForm(detail?.buildingCompanyId),
  structuralDesignCompanyId: convertNumberToStringForForm(detail?.structuralDesignCompanyId),
  implementingCompanyId: convertNumberToStringForForm(detail?.implementingCompanyId),
  constructingCompanyId: convertNumberToStringForForm(detail?.constructingCompanyId),
  recommenderId: convertNumberToStringForForm(detail?.recommenderId),
  clientInContractId: convertNumberToStringForForm(detail?.clientInContractId),
  clientId: convertNumberToStringForForm(detail?.clientId),
  etcId: convertNumberToStringForForm(detail?.etcId),
  note: convertNullToEmptyForForm(detail?.note),
});
const Form = () => {
  const {
    d: { detail, list, affiliatedCompanyOptionList },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalContext);
  const methods = useForm({
    values: getValues(detail),
  });
  const companyList = useMemo(
    () => list?.filter((l) => l.affiliatedCompany).map((l) => l.affiliatedCompany),
    [list]
  );
  const filterCompanyList = useCallback(
    (category: AffiliatedCompanyCategory) => {
      const filteredList = companyList?.filter((l) => l.category === category);
      const idListAfterSet = Array.from(new Set(filteredList?.map((c) => c.id)));
      const result: any[] = [];
      if (filteredList?.length === 0) return result;
      idListAfterSet.forEach((id) => {
        const index = filteredList?.findIndex((f) => f.id === id);
        if (typeof index !== 'undefined' && filteredList?.[index]) {
          result.push(filteredList[index]);
        }
      });
      return result;
    },
    [companyList]
  );
  const convertOptionList = useCallback(
    (list) =>
      list.map((l) => ({
        value: l.id.toString(),
        label: l.name,
      })),
    []
  );
  const getRecommenderOptionList = useCallback(
    () =>
      Array.from(
        new Set(
          list
            ?.filter((l) => l.person)
            .map((l) => ({
              label: l.person.name,
              value: l.person.id.toString(),
            }))
        )
      ),
    [list]
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th width="140px">건축</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="buildingCompanyId"
                  optionList={convertOptionList(
                    filterCompanyList(AffiliatedCompanyCategory.BUILDING)
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>구조</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="structuralDesignCompanyId"
                  optionList={convertOptionList(
                    filterCompanyList(AffiliatedCompanyCategory.STRUCTURAL_DESIGN)
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>시행사</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="implementingCompanyId"
                  optionList={convertOptionList(
                    filterCompanyList(AffiliatedCompanyCategory.IMPLEMENTING)
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>시공사</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="constructingCompanyId"
                  optionList={convertOptionList(
                    filterCompanyList(AffiliatedCompanyCategory.CONSTRUCTING)
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>소개자</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="recommenderId"
                  optionList={getRecommenderOptionList()}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>발주처(계약서)</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="clientInContractId"
                  optionList={affiliatedCompanyOptionList ?? []}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>실발주처</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="clientId"
                  optionList={affiliatedCompanyOptionList ?? []}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>기타</Th>
              <TableCell>
                <HookFormSelect
                  defaultLabel="선택"
                  name="etcId"
                  optionList={affiliatedCompanyOptionList ?? []}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <Th>비고</Th>
              <TableCell>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  variable={true}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModalButtonGroup />
    </FormProvider>
  );
};
