import React, { memo } from 'react';
import { Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/widget';

export { ExperimentTargetTable as ProjectSalesInfoSubjectReviewModalExperimentTarget };
interface Props {
  menuId?: number;
}
const ExperimentTargetTable = ({ menuId }: Props) => (
  <TableContainer>
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoSubjectReviewModalExperimentTargetWidget menuId={menuId} />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="100px">실험대상 미 종류</Th>
      <Th width="100px">풍력</Th>
      <Th width="100px">풍압</Th>
      <Th width="100px">풍환경 여부</Th>
      <Th width="100px">공기력</Th>
      <Th width="100px">빌딩풍</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoSubjectReviewModalExperimentTargetTable';
