import React from 'react';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { TableRow } from '@mui/material';
import { Tbc } from '@front/src/components/ui-builder/table';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableData } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableData';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { SelectableHeaderTableContent as PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableContent };
interface Props {
  authMenuId: number | undefined;
}
const SelectableHeaderTableContent = ({ authMenuId }: Props) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  if (list === undefined) {
    return (
      <TableRow>
        <Tbc
          align="center"
          colSpan={4}
        >
          모달화 설정할 헤더를 선택해주세요.
        </Tbc>
      </TableRow>
    );
  }
  if (list.length === 0) {
    return <NoResult colSpan={4} />;
  }
  return (
    <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableData
      authMenuId={authMenuId}
    />
  );
};
