import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { projectSalesInfoEstimationComparedApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/query/api';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoEstimationComparedMutation };

const mutation = {
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdListDeleteParameter) =>
        projectSalesInfoEstimationComparedApi.delete(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'estimation', 'estimation-compared', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
