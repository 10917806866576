import workCarouselMutation from '@front/src/features/work/features/work/features/carousel/query/mutation';
import { useCustomDialog } from '@front/src/features/dialog';

const useWorkCarouselMutation = ({ dataId, menuId, sectionId }) => {
  const { mutate: onDelete } = workCarouselMutation.useDelete({
    dataId,
    menuId,
    sectionId,
  });
  const { confirm } = useCustomDialog();
  const onRemove = (id) => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '이미지를 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  return {
    onRemove,
  };
};

export default useWorkCarouselMutation;
