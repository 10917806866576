import type { ProjectListGetParameter } from '@front/src/features/project-selector-modal/types/parameter';
import { projectSelectorModalQuery } from '@front/src/features/project-selector-modal/query/query';

export const projectSelectorModalRepository = {
  useProjectListGet: (params: ProjectListGetParameter) => {
    const { data, isLoading } = projectSelectorModalQuery.useProjectListGet(params);
    return {
      data,
      isLoading,
    };
  },
};
