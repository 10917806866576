import { useQuery } from 'react-query';
import { projectSalesApi } from '@front/src/features/project-sales/api/api';

const projectSalesListQuery = {
  useList: (commonParams) => {
    const { data } = useQuery({
      queryKey: ['project', 'sales', 'list'],
      queryFn: () => projectSalesApi.getList(commonParams),
      enabled: !!commonParams.menuId,
      suspense: true,
    });
    return {
      data,
    };
  },
  useOne: (id: number, commonParams) => {
    const { data, isFetching } = useQuery({
      queryKey: ['project', 'sales', id],
      queryFn: () => projectSalesApi.getOne(id, commonParams),
      enabled: !!commonParams.menuId && !!id,
      suspense: false,
      keepPreviousData: true,
    });
    return {
      data,
      isFetching,
    };
  },
};

export default projectSalesListQuery;
