import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UseFormReturn } from 'react-hook-form';
import { useSnackbar } from 'notistack';

interface Props {
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
}

const useGetUIBuilderTableFileAuthState = (props: Props) => {
  const { formContext } = props;
  const { setValue } = formContext;
  const { enqueueSnackbar } = useSnackbar();

  const handleSetAndOpenFileAuthSettingsModal = (id: number) => {
    if (!id) {
      enqueueSnackbar('권한 설정할 파일이 없습니다.', { variant: 'warning' });
      return;
    }
    setValue('fileItemId', id);
  };

  return {
    setAndOpen: handleSetAndOpenFileAuthSettingsModal,
  };
};

export default useGetUIBuilderTableFileAuthState;
