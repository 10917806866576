import React from 'react';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/widgets/provider';
import { ProjectSalesInfoSubjectReviewModalComplexInformationTable } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/components/complex-information-table';

export { Widget as ProjectSalesInfoSubjectReviewModalComplexInformationListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalComplexInformationListProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewModalComplexInformationTable menuId={menuId} />
  </ProjectSalesInfoSubjectReviewModalComplexInformationListProvider>
);
