import React from 'react';
import { Box } from '@mui/material';

import Button from '@front/layouts/Button';
import { ProposalContentType } from '@front/ost_proposal/domain';
import type { ProposalContentAddParameter } from '@front/ost_proposal/parameter';

interface Props {
  addContent: (params: ProposalContentAddParameter) => () => void;
}

export const ContentButtonGroup = ({ addContent }: Props) => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <Box
      sx={{
        marginLeft: '10px',
      }}
    >
      <Button onClick={addContent({ type: ProposalContentType.BEFORE_COMPARISON_FORM })}>
        전/후 비교 폼 추가
      </Button>
    </Box>
    <Box
      sx={{
        marginLeft: '10px',
      }}
    >
      <Button onClick={addContent({ type: ProposalContentType.IMAGE_TEXT_FORM })}>
        이미지-텍스트 폼
      </Button>
    </Box>
    <Box
      sx={{
        marginLeft: '10px',
      }}
    >
      <Button onClick={addContent({ type: ProposalContentType.TEXT_FORM })}>텍스트 폼 추가</Button>
    </Box>
  </Box>
);
