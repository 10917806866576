import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';
import type { ApprovalDocumentParameter } from '@front/ost_proposal/parameter';
import { FormikProvider, useFormik } from 'formik';
import ApprovalDocumentModal from 'ost_proposal/view/modal/approval_document';
import { ParentsType } from '@front/user_notification/domain';

const ApprovalDocumentModalService = () => {
  const dispatch = useDispatch();
  const approvalDocumentModalOpen = useSelector(
    (root: RootState) => root.proposal.approvalDocumentModalOpen,
    shallowEqual
  );
  const closeApprovalDocumentModal = useCallback(
    () => dispatch(proposalAction.setApprovalDocumentModal(false)),
    [dispatch]
  );
  const onSubmit = useCallback(
    (params: ApprovalDocumentParameter) => dispatch(proposalAction.addApprovalDocument(params)),
    [dispatch]
  );
  const title = useSelector((root: RootState) => root.proposal.detail!.title, shallowEqual);
  const id = useSelector((root: RootState) => root.proposal.detail!.id, shallowEqual);

  const formik = useFormik<ApprovalDocumentParameter>({
    initialValues: {} as ApprovalDocumentParameter,
    onSubmit: (values) => {
      onSubmit({
        ...values,
        parentsId: id,
        type: ParentsType.OST,
        title: `${title} OST 제안`,
      });
      formik.resetForm();
    },
  });

  return (
    <FormikProvider value={formik}>
      <ApprovalDocumentModal
        open={approvalDocumentModalOpen}
        onClose={closeApprovalDocumentModal}
      />
    </FormikProvider>
  );
};

export default ApprovalDocumentModalService;
