import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workWorkQuery = {
  useGetRole: (commonParams) => {
    const { data } = useQuery({
      queryKey: ['work', 'role', commonParams.dataId, commonParams.menuId],
      queryFn: () => workWorkApi.getRole(commonParams),
      enabled: !!commonParams.menuId,
    });
    return {
      data,
    };
  },
};

export default workWorkQuery;
