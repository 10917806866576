import { projectSalesInfoCollectionPaymentHistoryQuery } from '@front/src/features/project-sales-info/features/collection/features/payment-history/query';
import { projectSalesInfoCollectionPaymentHistoryMutation } from '@front/src/features/project-sales-info/features/collection/features/payment-history/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { IdDeleteCollectionParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';

export const projectSalesInfoCollectionPaymentHistoryRepository = {
  usePaymentHistoryListGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoCollectionPaymentHistoryQuery.usePaymentHistoryListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  usePaymentHistoryDelete: (id: number, menuId) => {
    const { mutate } = projectSalesInfoCollectionPaymentHistoryMutation.usePaymentHistoryDelete(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: IdDeleteCollectionParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
