import axios from 'axios';
import { contractEarningsApiUrl } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getContractEarningsStatus: (id?: number) =>
    `${contractEarningsApiUrl}/${id}/contract-earnings/status`,
  getContractEarnings: (id: number) => `${contractEarningsApiUrl}/${id}/contract-earnings`,
};

export const projectSalesInfoContractEarningsApi = {
  getContractEarningsStatus: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getContractEarningsStatus(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },

  getContractEarnings: async (id: number, menuId) => {
    const { data } = await axios.get(url.getContractEarnings(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
