import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { Table, OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import { FormProvider, useForm } from 'react-hook-form';
import { convertToManageableOptionList } from '@front/src/features/project-sales-info/features/collection/utils/constant';
import { ProjectSalesInfoCollectionManagementModalCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/create-modal/widgets/context';
import type { UserRecordView } from '@front/src/types';
import { convertToAffiliatedPersonOptionList } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/utils/constant';
import { ProjectSalesInfoCollectionManagementModalCreateModalBtn } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/create-modal/components/create-modal-btn';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/create-modal/components/create-modal.module.scss';
import { ProjectSalesInfoContractEarningsCollectionManagementContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/context';
import {
  depositCompleteKeyword,
  unpaidReasonOptionList,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/utils/constants';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { CreateModalTable as ProjectSalesInfoCollectionManagementModalCreateModalTable };

const CreateModalTable = () => {
  const {
    d: { divisionDetail },
  } = useContext(ProjectSalesInfoContractEarningsCollectionManagementContext);
  const {
    d: { affiliatedPersonList },
  } = useContext(ProjectSalesInfoCollectionManagementModalCreateModalContext);
  const {
    d: { collectionPossibilityOptionList },
  } = useContext(ProjectSalesInfoContractEarningsItemContext);

  const methods = useForm<ProjectCollectionManagementParams>({
    values: {
      unpaidReason: '',
      collectionDueDate: null,
      billingDate: null,
      collectionForecastDate: null,
      affiliatedPersonId: '',
      affiliatedPersonName: '',
      collectionPossibilityRatio: '',
      comment: '',
      createdAt: '',
    },
  });
  return (
    <FormProvider {...methods}>
      <div className={classes.modal__group}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th width="140px">미수금사유</Th>
                <OldTd>
                  <HookFormSelect
                    name="unpaidReason"
                    optionList={unpaidReasonOptionList}
                    width="150px"
                    defaultLabel="선택"
                    disableValue={depositCompleteKeyword}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>고객예정일</Th>
                <OldTd sx={{textAlign: 'left'}}>
                  <DatePickerWithHookForm
                    name="collectionDueDate"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>청구일</Th>
                <OldTd sx={{textAlign: 'left'}}>
                  <DatePickerWithHookForm
                    name="billingDate"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>한풍예측일</Th>
                <OldTd sx={{textAlign: 'left'}}>
                  <DatePickerWithHookForm
                    name="collectionForecastDate"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>수금가능성</Th>
                <OldTd>
                  <HookFormSelect
                    name="collectionPossibilityRatio"
                    optionList={convertToManageableOptionList(
                      collectionPossibilityOptionList,
                      true
                    )}
                    width="150px"
                    defaultLabel="선택"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>영업담당자</Th>
                <OldTd align="left">{(divisionDetail?.salesManager as UserRecordView)?.name}</OldTd>
              </TableRow>
              <TableRow>
                <Th>관계사담당자</Th>
                <OldTd>
                  <HookFormSelect
                    name="affiliatedPersonId"
                    optionList={convertToAffiliatedPersonOptionList(affiliatedPersonList) ?? []}
                    width="150px"
                    defaultLabel="선택"
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th>코멘트</Th>
                <OldTd>
                  <HookFormInput name="comment" />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ProjectSalesInfoCollectionManagementModalCreateModalBtn />
      </div>
    </FormProvider>
  );
};
