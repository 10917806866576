import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoContractEarningsModalRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/repository';

export { useLogic as useProjectSalesInfoContractEarningsModalLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onCreate } =
    projectSalesInfoContractEarningsModalRepository.useContractEarningsCreate(id!, menuId);
  return {
    h: { onCreate },
  };
};
