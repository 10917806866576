// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YZx6AHFwAHkpNX6EHDsR{display:flex;gap:0;flex-direction:column}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/activity/components/layouts/layout.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,KAAA,CACA,qBAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  gap: 0;\r\n  flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YZx6AHFwAHkpNX6EHDsR`
};
export default ___CSS_LOADER_EXPORT___;
