import React from 'react';
import { Box } from '@mui/material';
import { PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ParentTableContent';
import { PersonalSettingsModalUISettingsModalSettingsModalStatusChildrenTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ChildrenTableContent';
import {
  TableBodyBox,
  TableBox,
  TableHeadBox,
  TableRowBox,
  ThcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';

export { ModalStatusTable as PersonalSettingsModalUISettingsModalSettingsModalStatusTable };
interface Props {
  authMenuId: number | undefined;
}
const ModalStatusTable = ({ authMenuId }: Props) => (
  <TableBox>
    <TableHeadBox>
      <TableRowBox>
        <ThcBox sx={thcBoxFlex(1)}>헤더(모)</ThcBox>
        <ThcBox sx={thcBoxFlex(1)}>헤더(자)</ThcBox>
      </TableRowBox>
      <TableRowBox>
        <ThcBox sx={thcBoxFlex(2)}>헤더명</ThcBox>
        <ThcBox sx={thcBoxFlex(1)}>헤더(자) 유/무</ThcBox>
        <ThcBox sx={thcBoxFlex(2)}>헤더명</ThcBox>
        <ThcBox sx={thcBoxFlex(1)}>순서</ThcBox>
      </TableRowBox>
    </TableHeadBox>
    <TableBodyBox>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableContent
          authMenuId={authMenuId}
        />
      </Box>
      <Box sx={sx}>
        <PersonalSettingsModalUISettingsModalSettingsModalStatusChildrenTableContent
          authMenuId={authMenuId}
        />
      </Box>
    </TableBodyBox>
  </TableBox>
);

const thcBoxFlex = (flex) => ({
  flex,
});

const sx = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',
};
