import { CommonImageView } from '@front/src/features/work/features/work/components/ZoomImageButton';
import * as fabric from 'fabric';
import { EXT_OBJECT_ATTR_DRAW_ID, HistoryStack } from './useStateManager';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { GifReader } from 'omggif';

/**
 * 캔버스 런타임 속성 관리용 타입
 */
export type CanvasRuntimeProps = {
  zoom: {
    level: number;
    min: number;
    max: number;
  };
  touch: {
    lastDistance: number;
  };
  selectable: boolean;
  drag: {
    enabled: boolean;
    dragging: boolean;
    lastPosX: number | undefined;
    lastPosY: number | undefined;
  };
  brush: {
    enabled: boolean;
    size: number;
    color: string;
  };
  history: HistoryStack;
  historyAside: boolean;
  isModified: boolean;
  actingDrawingId: number | null;
  resizeDebounceHandlerId?: number;
  usePinchToZoom: boolean;
};

interface FrameItem {
  canvas: HTMLCanvasElement;
  delay: number;
  width: number;
  height: number;
}

export default function useCanvasUtil() {
  const dispatch = useDispatch();

  /**
   * 캔버스 컨테이너에 맞춰 캔버스 사이즈 조정
   * @param canvas - Fabric.js 캔버스 인스턴스
   * @param canvasElement
   */
  const fitCanvasToContainer = (canvas: fabric.Canvas, canvasElement: HTMLCanvasElement) => {
    const container = canvasElement.parentElement!.parentElement!.parentElement!;
    const toolBar = container.getElementsByClassName('image-canvas-toolbar')[0];
    const canvasWidth = container.clientWidth;
    const canvasHeight = container.clientHeight - toolBar.clientHeight;

    canvas.setDimensions({ width: canvasWidth, height: canvasHeight });
    canvas.renderAll();
  };

  /**
   * 캔버스 배경 이미지 설정
   * @param canvas - Fabric.js 캔버스 인스턴스
   * @param item
   * @param callback - 배경 랜더링 콜백(gif 로딩 시 애니메이션 실행을 위해 사용한 setInterval의 handler id 제공)
   */
  const setBackgroundImage = (
    canvas: fabric.Canvas,
    item: CommonImageView,
    callback?: (intervalHandle?: NodeJS.Timeout) => void
  ) => {
    if (!item) return;
    const filePath =
      item.fileItem.filename.indexOf('http') > -1
        ? item.fileItem.filename
        : `/api/file-item/${item.fileItem?.id}`;

    if (item.fileItem.ext === 'gif') {
      _loadGifFrameList(filePath)
        .then((frames: FrameItem[]) => {
          if (!frames || !frames.length) return;

          let currentFrame = 0;
          const intervalHandle = setInterval(() => {
            currentFrame = (currentFrame + 1) % frames.length; // 프레임 인덱스 순환
            const img = new fabric.Image(frames[currentFrame].canvas);
            canvas.set({
              backgroundImage: img,
              backgroundVpt: true,
            });
            img.setPositionByOrigin(new fabric.Point(0, 0), 'center', 'center');
            canvas.renderAll();
          }, frames[0].delay);
          callback && callback(intervalHandle);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      fabric.Image.fromURL(filePath).then((img) => {
        canvas.set({
          backgroundImage: img,
          backgroundVpt: true,
        });

        img.setPositionByOrigin(new fabric.Point(0, 0), 'center', 'center');
        canvas.renderAll();
        callback && callback();
      });
    }
  };

  /**
   * 선택 객체 삭제
   * @param canvas - Fabric.js 캔버스 인스턴스
   */
  const removeSelectedObjects = (canvas: fabric.Canvas) => {
    const activeObjects = canvas.getActiveObjects(); // 현재 선택된 객체 가져오기

    if (activeObjects.length > 0) {
      if (activeObjects.length === 1 && activeObjects[0].type === 'textbox') {
        const focusedElement = document.activeElement;
        if (focusedElement && focusedElement.tagName === 'TEXTAREA') {
          return false; // Textbox에서 포커스가 있을 경우 함수 종료
        }
      }

      // 선택된 객체를 캔버스에서 제거
      activeObjects.forEach((obj: fabric.Object) => {
        canvas.remove(obj);
      });

      canvas.discardActiveObject(); // 활성 객체 선택 해제
      canvas.renderAll(); // 변경 사항 반영을 위해 캔버스 다시 렌더링
      return true;
    }
    return false;
  };

  /**
   * 두 손가락 사이의 거리 계산 함수
   * @param touch1
   * @param touch2
   */
  const getTouchDistance = (touch1: Touch, touch2: Touch) => {
    return Math.sqrt(
      Math.pow(touch2.clientX - touch1.clientX, 2) + Math.pow(touch2.clientY - touch1.clientY, 2)
    );
  };

  /**
   * 탄력적 줌 효과를 위한 이징 함수 (예: ease-out)
   * @param t
   */
  const easeOut = (t: number) => {
    return t * (2 - t);
  };

  /**
   * zoom과 paning을 고려한 클릭 좌표 보정
   * @param pointer
   * @param canvas - Fabric.js 캔버스 인스턴스
   */
  const getTransformedPointer = (pointer: fabric.Point, canvas: fabric.Canvas): fabric.Point => {
    const zoom = canvas.getZoom();
    const vpt = canvas.viewportTransform; // 현재 뷰포트 변환

    return new fabric.Point(
      (pointer.x - vpt[4]) / zoom, // x 좌표 변환
      (pointer.y - vpt[5]) / zoom // y 좌표 변환
    );
  };

  /**
   * 모든 객체의 selectable 속성 설정
   * @param {fabric.Canvas} canvas - Fabric.js 캔버스 인스턴스
   * @param {boolean} selectable - 선택 가능 여부
   * @param actingDrawingId (optional) 첨삭 히스토리도 수정 상태에서는 선택 가능 여부 설정하기 위함
   */
  const setSelectableForAllObjects = (
    canvas: fabric.Canvas,
    selectable: boolean,
    actingDrawingId?: number | null
  ): void => {
    canvas.forEachObject((obj: fabric.Object) => {
      const drawId = obj.get(EXT_OBJECT_ATTR_DRAW_ID);
      if (drawId) {
        if (drawId === actingDrawingId) {
          obj.selectable = selectable; // selectable 속성 설정
          obj.evented = selectable; // 이벤트 가능 여부 설정
        } else {
          // 첨삭 히스토리 데이터는 손대지 않는다
        }
      } else {
        obj.selectable = selectable; // selectable 속성 설정
        obj.evented = selectable; // 이벤트 가능 여부 설정
      }
    });
    canvas.renderAll(); // 변경 사항 반영을 위해 캔버스 다시 렌더링
  };

  /**
   * 캔버스 모든 객체 삭제
   * @param canvas - Fabric.js 캔버스 인스턴스
   * @param actingDrawingId (optional) 첨삭 히스토리도 수정 상태에서는 삭제 허용하기 위함
   */
  const removeAllObjects = (canvas: fabric.Canvas, actingDrawingId?: number | null) => {
    const objects = canvas.getObjects(); // 모든 객체를 가져옵니다.
    objects.forEach((obj) => {
      const drawId = obj.get(EXT_OBJECT_ATTR_DRAW_ID);
      if (drawId) {
        if (drawId === actingDrawingId) {
          canvas.remove(obj);
        } else {
          // 첨삭 히스토리 데이터는 손대지 않는다
        }
      } else {
        canvas.remove(obj);
      }
    });
  };

  /**
   * 캔버스 특정 첨삭 객체 삭제
   * @param canvas - Fabric.js 캔버스 인스턴스
   * @param imageDrawId
   */
  const removeObjectsByImageDrawId = (canvas: fabric.Canvas, imageDrawId: number) => {
    const objects = canvas.getObjects(); // 모든 객체를 가져옵니다.
    objects.forEach((obj) => {
      if (obj[EXT_OBJECT_ATTR_DRAW_ID] === imageDrawId) {
        canvas.remove(obj); // 각 객체를 캔버스에서 제거합니다.
      }
    });
  };

  /**
   * 객체 고정
   * @param object
   */
  const lockObject = (object: fabric.Object) => {
    object.set({
      selectable: false, // 선택 불가
      evented: false, // 마우스 이벤트를 받지 않음
      hasControls: false, // 컨트롤러 숨김
      lockMovementX: true, // X축 이동 잠금
      lockMovementY: true, // Y축 이동 잠금
      lockRotation: true, // 회전 잠금
      lockScalingX: true, // X축 스케일링 잠금
      lockScalingY: true, // Y축 스케일링 잠금
    });
  };

  /**
   * 객체 고정 해제
   * @param object
   */
  const unlockObject = (object: fabric.Object) => {
    object.set({
      selectable: true, // 선택 불가
      evented: true, // 마우스 이벤트를 받지 않음
      hasControls: true, // 컨트롤러 숨김
      lockMovementX: false, // X축 이동 잠금
      lockMovementY: false, // Y축 이동 잠금
      lockRotation: false, // 회전 잠금
      lockScalingX: false, // X축 스케일링 잠금
      lockScalingY: false, // Y축 스케일링 잠금
    });
  };

  /**
   * 표준 비활성 객체 투명도 처리 함수
   * @param object
   * @param editable
   */
  const setObjectOpacityByEditableState = (object: fabric.Object, editable: boolean) => {
    object.set('opacity', editable ? 1 : 0.2);
  };

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  /**
   * 줌 설정
   * @param factor 양수 값: 줌인, 음수 값: 줌 아웃
   * @param canvas
   * @param runtimeProps
   */
  const zoom = (factor: number, canvas: fabric.Canvas, runtimeProps: CanvasRuntimeProps) => {
    runtimeProps.zoom.level += factor;
    runtimeProps.zoom.level = Math.max(
      runtimeProps.zoom.min,
      Math.min(runtimeProps.zoom.max, runtimeProps.zoom.level)
    );

    const viewportTransform = canvas.viewportTransform;
    const zoomPoint = new fabric.Point({ x: viewportTransform[4], y: viewportTransform[5] });
    canvas.zoomToPoint(zoomPoint, runtimeProps.zoom.level);
  };

  const _loadGifFrameList = async (gifUrl: string): Promise<FrameItem[]> => {
    const response = await fetch(gifUrl);
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    const intArray = new Uint8Array(arrayBuffer);

    const reader = new GifReader(intArray as Buffer);
    const info = reader.frameInfo(0);

    return new Array(reader.numFrames()).fill(0).map((_, k) => {
      const image = new ImageData(info.width, info.height);
      reader.decodeAndBlitFrameRGBA(k, image.data as any);

      let canvas = document.createElement('canvas');
      canvas.width = info.width;
      canvas.height = info.height;
      canvas.getContext('2d')!.putImageData(image, 0, 0);

      return {
        canvas,
        width: info.width,
        height: info.height,
        delay: info.delay * 10,
      };
    });
  };

  return {
    lockObject,
    unlockObject,
    fitCanvasToContainer,
    setBackgroundImage,
    removeAllObjects,
    removeSelectedObjects,
    removeObjectsByImageDrawId,
    setSelectableForAllObjects,
    getTransformedPointer,
    getTouchDistance,
    easeOut,
    setObjectOpacityByEditableState,
    openSnackbar,
    zoom
  };
}
