import { createContext } from 'react';
import type { DefaultFunction } from '@front/src/types';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyAddressView } from '@front/src/features/affiliated-company/features/outline/features/address/types/view';

export { Context as AffiliatedCompanyOutlineAddressContext };

interface State {
  d: {
    addressList?: AffiliatedCompanyAddressView[];
  };
  h: {
    onCreateAddress: DefaultFunction;
    setIdList: (idList: number[]) => void;
    onDeleteAddress: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    openAddressModal: () => void;
    setAddressId: (addressId: number) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {
    addressList: [],
  },
  h: {
    onCreateAddress: noOp,
    setIdList: noOp,
    onDeleteAddress: noOp,
    openAddressModal: noOp,
    setAddressId: noOp,
  },
  isLoading: false,
  idList: [],
});
