import React, { useEffect } from 'react';
import { ProjectSalesInfoCMSaveHistoryProvider } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/provider';
import { ProjectSalesInfoCMSaveHistoryTable } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/components/CM-save-history-table';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoCollectionHelp } from '@front/src/features/project-sales-info/features/collection/components/help';
import { useProjectSalesInfoCMSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoCMSaveHistoryWidget = ({ readOnly, name }: Readonly<Props>) => {
  const { Body, Layout, Header } = AddDeleteTable;
  const { setReadOnly } = useProjectSalesInfoCMSaveHistoryState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoCMSaveHistoryProvider>
      <Layout>
        <Header title={name}>
          <ProjectSalesInfoCollectionHelp />
        </Header>
        <Body>
          <ProjectSalesInfoCMSaveHistoryTable />
        </Body>
      </Layout>
    </ProjectSalesInfoCMSaveHistoryProvider>
  );
};

export default ProjectSalesInfoCMSaveHistoryWidget;
