import React from 'react';
import { TableBody, TableContainer, TableFooter } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { AccountingSettingsDailyReportStatusListTableHeader } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/table-header';
import { AccountingSettingsDailyReportStatusListTableBody } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/table-body';
import { AccountingSettingsBankAccountListTableFooter } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/components/table-footer';

export { Table as AccountingSettingsDailyReportStatusListTable };

const Table = () => (
  <TableContainer
    sx={{
      maxHeight: '84dvh',
      overflowY: 'auto',
    }}
  >
    <MuiTable>
      <AccountingSettingsDailyReportStatusListTableHeader />
      <TableBody>
        <AccountingSettingsDailyReportStatusListTableBody />
      </TableBody>
      <TableFooter>
        <AccountingSettingsBankAccountListTableFooter />
      </TableFooter>
    </MuiTable>
  </TableContainer>
);
