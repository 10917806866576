import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { getCommonControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import useWorkWorkerState from '@front/src/features/work/features/work/features/worker/useState';
import WorkWorkerTableBody from '@front/src/features/work/features/work/features/worker/components/TableBody';
import useWorkWorkerGetIsLoading from '@front/src/features/work/features/work/features/worker/hooks/useGetIsLoading';
import useWorkWorkerTableMutation from '@front/src/features/work/features/work/features/worker/hooks/useTableMutation';
import workWorkerQuery from '@front/src/features/work/features/work/features/worker/query/query';

export default function WorkWorkerTable(props: Readonly<SectionComponentProps>) {
  const { sectionId, dataId, menuId } = props;
  const { clearEditOpenList } = useWorkWorkerState(
    useShallow((state) => ({
      clearEditOpenList: state.clearEditOpenList,
    }))
  );

  const { onAddTop, onAddBottom, onRemoveAll } = useWorkWorkerTableMutation({
    sectionId,
    dataId,
    menuId,
  });

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
  };

  const controlPanelHeaderItemList = getCommonControlPanelHeaderItemList({
    actions,
  });

  const isLoading = useWorkWorkerGetIsLoading();

  const { data: list } = workWorkerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      BodyComponent={WorkWorkerTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      list={list}
    />
  );
}
