import React, { useContext } from 'react';

import { AccountingSettingsDailyReportStatusListContext } from '@front/src/features/accounting/features/settings/features/daily-report-status/widgets/list/provider/context';

import { TableRow as MuiTableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { getLocaleStringIfExist } from '@front/src/utils';

export { Footer as AccountingSettingsBankAccountListTableFooter };

const Footer = () => {
  const { sumList } = useContext(AccountingSettingsDailyReportStatusListContext);
  return (
    <>
      {sumList.map((item, index) => (
        <MuiTableRow key={item.category}>
          <OldTd colSpan={3}>{`${item.category} ${
            sumList.length - 1 === index ? '' : '합계'
          }`}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.balanceCarriedForward)}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.balanceIncrement)}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.balanceDecrement)}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.balance)}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.balanceAtAccount)}</OldTd>
          <OldTd>{getLocaleStringIfExist(item.difference)}</OldTd>
        </MuiTableRow>
      ))}
    </>
  );
};
