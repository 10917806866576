import { create } from 'zustand';
import type { SalesAccountingAmountProjectView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';

export { useState as useAccountingUploadSalesInfoExpectModalState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  month?: number;
  setMonth: (month?: number) => void;
  projectList?: SalesAccountingAmountProjectView[];
  setProjectList: (projectList?: SalesAccountingAmountProjectView[]) => void;
}

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) =>
    set(() => ({
      isOpen,
    })),
  setMonth: (month) =>
    set(() => ({
      month,
    })),
  setProjectList: (projectList) =>
    set(() => ({
      projectList,
    })),
}));
