import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import React from 'react';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { createDropdownOptions } from '@front/src/utils';

interface Props extends CellComponentProps<EstimationComparisonView> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="paymentStage"
    options={createDropdownOptions(['계약금', '중도금', '잔금'])}
  />
);

/**
 * 계약지불순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputText
    {...props}
    name="paymentSequence"
  />
);

/**
 * 계약발주처
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="client"
  />
);

/**
 * 계약수금시기
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="paymentTiming"
    options={createDropdownOptions([
      '계약전',
      '계약시',
      '업무개시전',
      '업무개시후',
      '설풍납품전',
      '설풍납품시',
      '최종보고서 납품전',
      '최종보고서 납품시',
      '구조심의전',
      '구조심의 완료시',
      'PF완료시',
      '기타',
    ])}
  />
);

/**
 * 계약응당일
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="expectedPaymentDate"
  />
);

/**
 * 발주처별 비율
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="clientRatio"
  />
);

/**
 * 각사 지불단계비율
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="companyStageRatio"
  />
);

/**
 * 계약총액
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="totalContractPrice"
  />
);

/**
 * 계약순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="windTunnelPrice"
  />
);

/**
 * 계약구검
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewPrice"
  />
);

/**
 * 계약구검비율
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewRatio"
  />
);

/**
 * 계약구검 지급시기
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="structuralReviewPaymentTiming"
    options={createDropdownOptions(['잔금완료후', '수금비율', '협의'])}
  />
);

/**
 * 계약구검 지급대상
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="structuralReviewPaymentTarget"
  />
);

/**
 * 계약구검 지급일
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="structuralReviewPaymentDate"
  />
);

export const contractPaymentTermsTableCells: CellComponent = {
  562: CellRenderer1, // 계약지불단계
  563: CellRenderer2, // 계약지불순번
  564: CellRenderer3, // 계약발주처
  565: CellRenderer4, // 계약수금시기
  566: CellRenderer5, // 계약응당일
  567: CellRenderer6, // 발주처별 비율
  568: CellRenderer7, // 각사 지불단계비율
  569: CellRenderer8, // 계약총액
  570: CellRenderer9, // 계약순풍동금액
  571: CellRenderer10, // 계약구검
  572: CellRenderer11, // 계약구검비율
  573: CellRenderer12, // 계약구검 지급시기
  574: CellRenderer13, // 계약구검 지급대상
  575: CellRenderer14, // 계약구검 지급일
  ...generateSpareAttrCellRenders(585),
};
