import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ProjectSalesAsideCreateModal from '@front/src/features/project-sales/features/aside/features/create-button/components/Modal';
import useModalOpen from '@front/src/hooks/useModalOpen';

interface Props {
  menuId: number | undefined;
}

export default function ProjectSalesCreateButtonFeature({ menuId }: Props) {
  const { onClose, open, onOpen } = useModalOpen();

  return (
    <>
      <ProjectSalesAsideCreateModal
        open={open}
        onClose={onClose}
        menuId={menuId}
      />
      <ButtonBasicUI
        shape="primary"
        size="medium"
        onClick={onOpen}
      >
        신규 프로젝트 등록
      </ButtonBasicUI>
    </>
  );
}
