import React, { useCallback, useEffect, useRef } from 'react';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';
import type { ProposalId } from '@front/ost_proposal/domain';
import { gradeRangeConverter, ProposalGrade, ProposalStatus } from '@front/ost_proposal/domain';
import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import type { VoteAddParameter } from '@front/ost_vote/parameter';
import type { SnackbarSeverityType } from '@front/components/Snackbar/action';
import { Box } from '@mui/material';

interface Props {
  openSnackbar: (message: string, severity?: SnackbarSeverityType) => void;
  vote: (params: VoteAddParameter) => void;
  grade?: ProposalGrade;
  id?: ProposalId;
  price?: number;
  totalReward?: number;
  status?: ProposalStatus;
}

const VoteInput = ({ vote, totalReward, openSnackbar, grade, id, price, status }: Props) => {
  const { confirm } = useDialog();

  const inputRef = useRef<HTMLInputElement>(null);

  const onBlur = useCallback(
    (e) => {
      const value = +e.target.value.replace(/,/g, '');
      if (value === 0) {
        return;
      }
      if (!value) {
        openSnackbar('숫자만 입력 가능합니다.');
        if (inputRef.current) {
          if (price) {
            inputRef.current.value = price.toLocaleString();
          } else {
            inputRef.current.value = '';
          }
        }
      } else {
        if (inputRef.current) {
          if (Number.isInteger(value)) {
            inputRef.current.value = value.toLocaleString();
          }
        }
      }
    },
    [totalReward, openSnackbar, vote]
  );

  useEffect(() => {
    if (inputRef.current && (price === 0 || price)) {
      inputRef.current.value = price.toLocaleString();
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };
  }, [price, inputRef.current && inputRef.current.value]);

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '10px',
      }}
    >
      <DataFieldWithLabel label="투찰 금액">
        <Input
          key={inputRef.current?.value ?? price ?? id}
          isAmount
          inputRef={inputRef}
          readOnly={price === 0 || !!price || status !== ProposalStatus.VOTING}
          variant="outlined"
          defaultValue={
            inputRef.current &&
            (inputRef.current.value === '0' ? 0 : inputRef.current.value.toLocaleString())
          }
          placeholder={grade && gradeRangeConverter(grade)}
          onBlur={onBlur}
        />
      </DataFieldWithLabel>
      <Button
        disabled={price === 0 || !!price || status !== ProposalStatus.VOTING}
        onClick={() => {
          confirm({
            children: '해당 제안에 대한 투찰을 하시겠습니까? 투찰 후 수정이 불가능합니다.',
            afterConfirm: () => {
              if (inputRef.current) {
                const value = inputRef.current.value;
                if (value === '') {
                  openSnackbar('투찰 금액을 입력해 주시기 바랍니다.');
                  return;
                }
                const price = +value.replace(/,/g, '');
                const validation = validatePriceWithGrade(price, grade);
                if (validation) {
                  openSnackbar(validation);
                  return;
                }
                vote({
                  price: price,
                });
              }
            },
            confirmText: '확인',
          });
        }}
      >
        {status === ProposalStatus.STOP_VOTING ? '투찰 중지' : '투찰'}
      </Button>
    </Box>
  );
};

function validatePriceWithGrade(price: number, grade?: ProposalGrade): string | undefined {
  if (!grade) {
    return '투찰 금액 오류 - 아이디어 등급이 먼저 선정되어야 합니다.';
  }
  if (price === 0) {
    return;
  }

  if (grade === ProposalGrade.TALL) {
    if (0 > price || price >= 100000) {
      return `투찰 금액 오류 - ${gradeRangeConverter(grade)}으로 입력해 주시기 바랍니다.`;
    }
    return;
  } else if (grade === ProposalGrade.GRANDE) {
    if (100000 > price || price >= 1000000) {
      return `투찰 금액 오류 - ${gradeRangeConverter(grade)}으로 입력해 주시기 바랍니다.`;
    }
    return;
  } else if (grade === ProposalGrade.VENTI) {
    if (1000000 > price || price >= 5000000) {
      return `투찰 금액 오류 - ${gradeRangeConverter(grade)}으로 입력해주시기 바랍니다.`;
    }
    return;
  } else if (grade === ProposalGrade.SUPREME) {
    if (5000000 > price || price > 10000000) {
      return `투찰 금액 오류 - ${gradeRangeConverter(grade)}으로 입력해주시기 바랍니다.`;
    }
    return;
  }
  return '등급에 해당하는 금액이 아닙니다.';
}

export default VoteInput;
