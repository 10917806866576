import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, MenuItem } from '@mui/material';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import Button from '@front/layouts/Button';
import Select from '@front/layouts/Select';
import NoteFilter from '@front/src/features/note/components/Filter';
import useGetNoteFilterList from '@front/src/features/note/repository/useGetNoteFilter';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import useCreateNote from '@front/src/features/note/repository/useCreateNote';
import { useSnackbar } from 'notistack';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';

export default function NoteForm() {
  const {
    noteFilter,
    setNoteFilter,
    setKeyword,
    keyword,
    noteState,
    setNoteState,
    resetNoteState,
    rowId,
    sectionId,
    setIsUnreadReview,
    setIsWaitApproval,
  } = useNoteStore(
    useShallow((state) => ({
      noteFilter: state.noteFilter,
      setNoteFilter: state.setNoteFilter,
      setKeyword: state.setKeyword,
      keyword: state.keyword,
      noteState: state.noteState,
      setNoteState: state.setNoteState,
      resetNoteState: state.resetNoteState,
      rowId: state.rowId,
      sectionId: state.sectionId,
      setIsUnreadReview: state.setIsUnreadReview,
      setIsWaitApproval: state.setIsWaitApproval,
    }))
  );

  const methods = useFormContext();
  const { handleSubmit, reset } = methods;
  const isResizeActive = useListDrawerState((state) => state.isResizeActive);
  const noteFilterList = useGetNoteFilterList(isResizeActive);

  const onNoteFilterChange = (e) => {
    setNoteFilter(e.target.value);
    setIsWaitApproval();
    setIsUnreadReview();
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      e.preventDefault(); // 엔터 키가 기본적으로 폼 제출을 트리거하는 것을 막음
    }
  };
  const { enqueueSnackbar } = useSnackbar();

  const { createNote, params, isLoading } = useCreateNote();
  const onSubmit = handleSubmit((data) => {
    if (!methods.getValues('note')) {
      enqueueSnackbar('값을 입력해주세요', { variant: 'warning' });
      return;
    }

    createNote({ ...params, contents: data.note });
    setNoteState({ note: '' });
    reset();
  });

  const textAreaSx = useMemo(
    () => ({
      minHeight: '120px',
    }),
    []
  );
  return (
    <>
      <NoteFilter />
      <Box>
        <Box sx={{ ...SX_FORM_WRAP }}>
          <HookFormTextarea
            name="note"
            variable={true}
            sx={textAreaSx}
            placeholder="비고를 입력해주세요"
          />
          <Button
            onClick={onSubmit}
            sx={{ ...SX_DONE_BUTTON }}
          >
            작성 완료
          </Button>
        </Box>
        <Box sx={{ ...SX_SEARCH_WRAP }}>
          <Select
            value={noteFilter}
            onChange={onNoteFilterChange}
            inputProps={{
              sx: {
                padding: '6px 10px',
              },
            }}
            style={{ width: '40%' }}
          >
            {noteFilterList?.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
              >
                {item.label} ({item.count}건)
              </MenuItem>
            ))}
          </Select>
          {/*<Input*/}
          {/*  value={inputVal}*/}
          {/*  placeholder="검색어 입력 후 엔터"*/}
          {/*  onChange={onInputChange}*/}
          {/*  onKeyDown={onKeyDown}*/}
          {/*/>*/}
          <HookFormInput
            width="100%"
            sx={{
              height: '40px',
            }}
            name="search"
            placeholder="검색어 입력 후 엔터"
            onKeyDown={onKeyDown}
          />
        </Box>
      </Box>
    </>
  );
}

const SX_FORM_WRAP = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '14px',
  alignItems: 'flex-start',
};

const SX_DONE_BUTTON = {
  display: 'flex',
  minHeight: '32px',
  padding: '6px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderRadius: '5px',
  background: 'var(--design-v-10-light-main-main-primary, #2F6ACA)',
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};

const SX_SEARCH_WRAP = {
  display: 'flex',
  padding: '14px',
  alignItems: 'flex-start',
  gap: '14px',
  background: 'var(--design-v-10-light-background-bg-03, #D8DCE2)',
};
