import { createContext } from 'react';
import type { DefaultFunction } from '@front/src/types';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyPersonCharacterHobbyView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/types/view';
import type { AffiliatedCompanyPersonCharacterHobbyUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalCharacterHobbyContext };

interface State {
  d: {
    hobbyList?: AffiliatedCompanyPersonCharacterHobbyView[];
  };
  h: {
    onCreateHobby: DefaultFunction;
    setIdList: (idList: number[]) => void;
    onDeleteHobby: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    onUpdateHobby: (params: AffiliatedCompanyPersonCharacterHobbyUpdateParameter) => void;
  };
  isLoading: boolean;
  readOnly?: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {
    hobbyList: [],
  },
  h: {
    onCreateHobby: noOp,
    setIdList: noOp,
    onDeleteHobby: noOp,
    onUpdateHobby: noOp,
  },
  isLoading: false,
  idList: [],
});
