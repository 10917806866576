import React, { useCallback, useContext } from 'react';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/components/contract-earnings-table-header.module.scss';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import {
  handleDeleteIdConfirmAfterClick,
  handleDeleteIdListConfirmAfterClick,
} from '@front/src/utils';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ContractEarningsTableHeader as ProjectSalesInfoContractEarningsHeader };

const ContractEarningsTableHeader = () => {
  const setIsDetailCreateModalOpen = useContractEarningsItemState(
    (state) => state.setIsDetailCreateModalOpen
  );
  const {
    itemId,
    name,
    d: { contractEarningsItemList },
  } = useContext(ProjectSalesInfoContractEarningsItemContext);
  const methods = useForm<ProjectContractEarningsNameParams>({
    values: {
      id: itemId,
      name: name ?? '',
    },
  });
  const {
    h: { onDeleteDetails, onDelete, onUpdate },
    idList,
    setIdList,
  } = useContext(ProjectSalesInfoContractEarningsItemContext);
  const onUpdateInput = methods.handleSubmit((data) => {
    onUpdate(data);
  });
  const { setItemId, setName } = useContractEarningsItemState((state) => ({
    idList: state.idList,
    setIdList: state.setIdList,
    setItemId: state.setItemId,
    setName: state.setName,
  }));
  const resetCollectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.resetCollectionManagementList
  );
  const { confirm } = useCustomDialog();
  const onDeleteDetailsClick = useCallback(
    () =>
      handleDeleteIdListConfirmAfterClick(idList, confirm, () => {
        onDeleteDetails({ idList });
        setIdList([]);
      }),
    [onDeleteDetails, idList, setIdList, confirm]
  );
  const onDeleteClick = useCallback(
    () =>
      handleDeleteIdConfirmAfterClick(itemId, confirm, '', () => {
        onDelete(itemId!);
      }),
    [onDelete, confirm, itemId]
  );
  const { readOnly } = useContractEarningsItemState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <HookFormInput
        name="name"
        width="120px"
        onSubmit={onUpdateInput}
        disabled={readOnly}
      />
      <div className={classes.btn__group}>
        <Button
          disabled={contractEarningsItemList?.length !== 0 || readOnly}
          onClick={onDeleteClick}
        >
          기성 삭제
        </Button>
        <Button
          onClick={onDeleteDetailsClick}
          disabled={readOnly}
        >
          수금 선택삭제
        </Button>
        <Button
          onClick={() => {
            setIsDetailCreateModalOpen();
            setItemId(itemId!);
            setName(name!);
            resetCollectionManagementList();
          }}
          disabled={readOnly}
        >
          추가
        </Button>
      </div>
    </FormProvider>
  );
};
