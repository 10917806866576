import type { CSSProperties } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  fileName: string;
  onClick?: () => void;
  sx?: CSSProperties;
  align?: 'left' | 'center' | 'right';
}

export const FileDisplayText = ({ fileName, onClick, sx, align = 'center' }: Props) => (
  <Box
    onClick={onClick}
    aria-hidden
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: align,
      color: ColorPalette._0047d3,
      fontWeight: 'bold',
      cursor: 'pointer',
      whiteSpace: 'normal',
      textAlign: align,

      '&:hover': {
        textDecoration: 'underline',
      },
      ...sx,
    }}
  >
    {fileName}
  </Box>
);
