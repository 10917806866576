/**
 * URI에서 숫자 부분을 {:id}로 치환하는 함수
 * @param uri - 입력 URI 문자열
 * @returns 숫자가 {:id}로 치환된 URI
 */

export function replaceNumbersWithId(uri: string): string {
  return uri.replace(/\/\d+(?=\/|$)/g, '/{:id}');
}

/**
 * URI에서 첫 번째 숫자를 추출하는 함수
 * @param uri - 입력 URI 문자열
 * @returns 추출된 숫자 또는 null
 */
export function extractFirstNumber(uri: string): number | null {
  const match = uri.match(/\d+/); // 숫자 부분을 찾는 정규식
  return match ? Number(match[0]) : null; // 매칭된 값이 있으면 반환, 없으면 null 반환
}