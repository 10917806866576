import React, { useMemo } from 'react';
import { TableRow } from '@mui/material';
import { Tbc } from '@front/src/components/ui-builder/table';
import { PersonalSettingsModalUISettingsHeaderSettingsRowControlPanel } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/RowControlPanel';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentCurrentName } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/CurrentName';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentNewName } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/NewName';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentIsBlind } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/IsBlind';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContentNote } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/Note';
import { useFormContext, useWatch } from 'react-hook-form';
import { ColorPalette } from '@front/assets/theme';

export { Row as PersonalSettingsModalUISettingsHeaderSettingsTableRow };

interface Props {
  index: number;
}

const Row = ({ index }: Readonly<Props>) => {
  const { getValues, control } = useFormContext();
  const use = getValues(`list.${index}.use`) ?? '-';
  const isFixed = useWatch({ name: `list.${index}.isFixed`, control });
  const sx = useMemo(
    () => ({
      '& > td': {
        borderBottom: isFixed ? `2px solid ${ColorPalette._252627}` : 'default',
      },
    }),
    [isFixed]
  );
  return (
    <TableRow sx={sx}>
      <Tbc
        align="center"
        sx={{
          p: '0',
        }}
      >
        <PersonalSettingsModalUISettingsHeaderSettingsRowControlPanel index={index} />
      </Tbc>
      <Tbc>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentCurrentName index={index} />
      </Tbc>
      <Tbc>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentNewName
          index={index}
          key={index + getValues(`list.${index}.id`) + 'newName'}
        />
      </Tbc>
      <Tbc align="center">
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentIsBlind
          index={index}
          key={index + getValues(`list.${index}.id`) + 'isBlind'}
        />
      </Tbc>
      <Tbc align="center">{use}</Tbc>
      <Tbc>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContentNote
          key={index + getValues(`list.${index}.id`) + 'note'}
          index={index}
        />
      </Tbc>
    </TableRow>
  );
};
