import React from 'react';
import type { AuthorizationType, UserVO } from '@front/user/domain';
import UserSelectorForm from '@front/src/features/user-selector/features/form/components/Form';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';

interface Props {
  value: number | number[] | undefined;
  onChange: (value: UserVO | UserVO[] | undefined) => void;
  multi?: boolean;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
  predefinedIdList?: number[];
}

export default function UserSelectorFormFeature({ ...rest }: Readonly<Props>) {
  const { data: allUser } = userSelectorRepository.useGetAllUser();
  if (!allUser) return <></>;
  return <UserSelectorForm {...rest} />;
}
