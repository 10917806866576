import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';

const useWorkOutputAddTop = (bundleId: number, commonParams) => {
  const callback = getNoOpCallback();
  const { mutate } = workOutputMutation.useAddTop(bundleId, commonParams);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkOutputAddTop;
