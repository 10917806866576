import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

interface Props extends Pick<CellComponentProps, 'sx' | 'onClick'> {
  name: string;
  displayValue?: string | number;
}

export default function UIBuilderTableCellText({ displayValue, sx, onClick, name }: Props) {
  const { getValues } = useFormContext();
  const value = getValues(name);
  const children = value ?? (displayValue != null ? String(displayValue) : '-');

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </Box>
  );
}
