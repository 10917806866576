import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

const useGetUIBuilderTableHeaderActions = ({ resetValues }) => {
  const { setValue, getValues } = useFormContext<UIBuilderTableFieldValues>();

  const onOpenHeaderSettingsHistoryModal = () => setValue('openHeaderSettingsHistoryModal', true);
  const toggleUseFilter = () => {
    if (getValues('useFilter')) {
      setValue('useFilter', false);
      resetValues();
      return;
    }
    setValue('useFilter', true);
  };
  const resetSortPriorityList = () => {
    if (getValues('sortPriorityList').length === 0) return;
    setValue('sortPriorityList', []);
  };

  const toggleHiddenRow = () => {
    setValue('showAllRow', !getValues('showAllRow'));
  };

  return {
    onOpenHeaderSettingsHistoryModal,
    toggleUseFilter,
    resetSortPriorityList,
    toggleHiddenRow,
  };
};

export default useGetUIBuilderTableHeaderActions;
