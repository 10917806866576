import { useFormContext } from 'react-hook-form';
import type { EstimationHistoryFormValues } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';
import { toFormData } from '@front/services/api';
import estimationHistoryClientMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/clientMutation';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';

interface Props
  extends Pick<CellComponentProps<EstimationHistoryView>, 'dataId' | 'sectionId' | 'menuId'> {}

const useEstimationHistoryClientUpdate = ({ dataId, sectionId, menuId }: Props) => {
  const commonParams = { dataId, sectionId, menuId };
  const { getValues } = useFormContext<EstimationHistoryFormValues>();

  const onUpdate = estimationHistoryClientMutation.useUpdateByFormData(commonParams);

  const onSubmit = (index) => {
    const item = getValues(`estimationClient.${index}`);

    if (!item.id) return;

    const formData =
      item.excelFile || item.pdfFile
        ? toFormData({
            ...(item.excelFile && { excel: { ...item.excelFile, ...commonParams } }),
            ...(item.pdfFile && { pdf: { ...item.pdfFile, ...commonParams } }),
          })
        : new FormData();

    const request = {
      seq: item.seq,
      clientCompanyId: item.clientCompany?.id ?? null,
      clientCompanyPersonId: item.clientCompanyPerson?.id ?? null,
      orderType: item.orderType || null,
      nextContactDateClient: item.nextContactDateClient ?? null,
      blockName: item.blockName ?? null,
      siteModel: item.siteModel ?? null,
      testPlan: item.testPlan ?? null,
      requiredWeeksStart: item.requiredWeeksStart ?? null,
      requiredWeeksEnd: item.requiredWeeksEnd ?? null,
      estimatedStartDate: item.estimatedStartDate ?? null,
      totalEstimationPrice: item.totalEstimationPrice ?? null,
      estimatedWindTunnelPrice: item.estimatedWindTunnelPrice ?? null,
      actualWindTunnelPrice: item.actualWindTunnelPrice ?? null,
      structuralReviewPrice: item.structuralReviewPrice ?? null,
      structuralReviewRatio: item.structuralReviewRatio ?? null,
      estimationCm: item.estimationCm ?? null,
      estimationCmPostDeduction: item.estimationCmPostDeduction ?? null,
      cmDeductionRatio: item.cmDeductionRatio ?? null,
      cmRatio: item.cmRatio ?? null,
      actualUnitPrice: item.actualUnitPrice ?? null,
      targetUnitPrice: item.targetUnitPrice ?? null,
      minimumUnitPrice: item.minimumUnitPrice ?? null,
      actualCostPrice: item.actualCostPrice ?? null,
      excelFileId: item.excelFile?.id ?? null,
      pdfFileId: item.pdfFile?.id ?? null,
      managerId: item.createdBy?.id ?? null,
    };

    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    onUpdate({
      id: item.id,
      formData,
    });
  };

  return {
    onSubmit,
  };
};

export default useEstimationHistoryClientUpdate;
