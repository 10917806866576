import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import axios from 'axios';
import type { CollectionIdDeleteParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  deleteContractEarnings: (itemId: number) =>
    `${contractEarningsApiUrl}/contract-earnings/${itemId}`,
  getContractEarningsItem: (itemId: number) => `${smProjectCollectionApiUrl}/${itemId}/collection`,
  deleteContractEarningsItem: () => `${smProjectCollectionApiUrl}/collection`,
  updateContractEarningsName: (itemId: number) =>
    `${contractEarningsApiUrl}/contract-earnings/${itemId}`,
};

export const projectSalesInfoContractEarningsItemApi = {
  deleteContractEarnings: async (itemId: number, menuId) =>
    await axios.delete(url.deleteContractEarnings(itemId), {
      headers: getMenuIdHeaders(menuId),
    }),
  getContractEarningsItem: async (itemId: number, menuId) => {
    const { data } = await axios.get(url.getContractEarningsItem(itemId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteContractEarningsItemDetails: async (params: CollectionIdDeleteParams, menuId) =>
    await axios.delete(url.deleteContractEarningsItem(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    }),
  updateContractEarningsName: async (params: ProjectContractEarningsNameParams, menuId) =>
    await axios.put(url.updateContractEarningsName(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
};
