import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as projectSalesInfoEstimationEstimationApi };
const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/estimation`,
  delete: () => `${projectSalesInfoURL}/estimation`,
};

const api = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (params: IdListDeleteParameter, menuId) => {
    await axios.delete(url.delete(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
};
