import { createAction } from 'typesafe-actions';

import type { CampaignMemoQuery, CampaignQuery } from '@front/ost_campaign/query';
import type { UserId } from '@front/user/domain';
import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import type {
  CampaignAddParameter,
  CampaignBannerUpdateContentParameter,
  CampaignBannerUpdateIsExposedParameter,
  CampaignMemoAddParameter,
  CampaignMemoUpdateParameter,
  CampaignUpdateApprovalParameter,
  CampaignUpdateEndDateParameter,
  CampaignUpdateEstimationCompletionRateParameter,
  CampaignUpdateEstimationEndDateParameter,
  CampaignUpdateEstimationStartDateParameter,
  CampaignUpdateIsNoticeExposedParameter,
  CampaignUpdateManagerParameter,
  CampaignUpdateNameParameter,
  CampaignUpdateNoticeParameter,
  CampaignUpdateParameter,
  CampaignUpdateProposalCategoryParameter,
  CampaignUpdateStartDateParameter,
  CampaignUpdateStatusParameter,
  CampaignUpdateTotalRewardParameter,
  CampaignUpdateVoteEndDateParameter,
  CampaignUpdateVoteStartDateParameter,
  EstimatorListUpsertParameter,
  ProposalCategoryAddParameter,
  ProposalPaymentDateParameter,
} from '@front/ost_campaign/parameter';
import type {
  CampaignApprovalVO,
  CampaignEstimatorVO,
  CampaignId,
  CampaignMemoId,
  CampaignMemoVO,
  CampaignShortVO,
  CampaignVO,
  ProposalApprovalId,
  ProposalCategoryId,
  ProposalCategoryVO,
} from '@front/ost_campaign/domain';

export enum CampaignAction {
  /**
   * 캠페인 목록 관련 액션
   */
  setId = 'campaign/id/set',
  setList = 'campaign/list/set',
  setFilter = 'campaign/filter/set',
  toggleDrawer = 'campaign/drawer/toggle',
  setLoading = 'campaign/loading/set',

  /**
   * 캠페인 등록 관련 액션
   */
  add = 'campaign/campaign/add',

  /**
   * 캠페인 상세 관련 액션
   */
  setDetail = 'campaign/detail/set',
  deleteDetail = 'campaign/detail/delete',

  /**
   * 캠페인 수정 관련 액션
   */

  updateName = 'campaign/name/update',
  updateStartDate = 'campaign/start-date/update',
  updateEndDate = 'campaign/end-date/update',
  updateTotalReward = 'campaign/total-reward/update',
  updateManager = 'campaign/manager/update',
  updateStatus = 'campaign/status/update',

  /**
   * 제안 구분 목록 관련 액션
   */
  setProposalCategoryList = 'campaign/proposal-category-list/set',

  /**
   * 제안 구분 추가 관련 액션
   */
  addProposalCategory = 'campaign/proposal-category/add',
  setAddProposalCategoryModalOpen = 'campaign/add-proposal-category-modal-open/set',
  updateProposalCategoryList = 'campaign/proposal-category/update',

  /**
   * 제안 구분 추가 관련 액션
   */
  deleteProposalCategory = 'campaign/proposal-category/delete',

  /**
   * 평가 담당자 목록 관련 액션
   */
  setEstimatorList = 'campaign/estimator-list/set',

  /**
   * 평가 담당자 추가 관련 액션
   */
  upsertCampaignEstimatorList = 'campaign/campaign-estimator-list/upsert',
  setAddCampaignEstimatorModalOpen = 'campaign/add-campaign-estimator-modal-open/set',

  /**
   * 평가 담당자 삭제 관련 액션
   */

  deleteCampaignEstimator = 'campaign/campaign-estimator/delete',

  /**
   * 제안 현황 관련 액션
   */
  setAddProposalEstimatorModalOpen = 'campaign/add-proposal-estimator-modal-open/set',
  setProposalList = 'campaign/proposal-list/set',
  setProposalEstimatorModalId = 'campaign/proposal-estimator-modal-id/set',
  upsertProposalEstimatorList = 'campaign/proposal-estimator-list/upsert',
  upsertProposalPaymentDate = 'campaign/proposal-payment-date/upsert',

  /**
   * 메모 관련 액션
   */
  setIsOpenMemo = 'campaign/is-open-memo/set',
  addMemo = 'campaign/memo/add',
  setFormHeight = 'campaign/form-height/set',
  setFilterMemo = 'campaign/filter-memo/set',
  setMemoList = 'campaign/memo-list/set',
  setIsLoadingMemo = 'campaign/is-loading-memo/set',

  updateMemo = 'campaign/memo/update',
  deleteMemo = 'campaign/memo/delete',

  /**
   * 캠페인 진행 목록
   */
  setCampaignListUnderOnGoing = 'campaign/campaign-on-going-list/set',
  getCampaignListUnderOnGoing = 'campaign/campaign-on-going-list/get',

  /**
   * 캠페인 평가 기간
   */
  updateEstimationStartDate = 'campaign/estimation-start-date/update',
  updateEstimationEndDate = 'campaign/estimation-end-date/update',
  updateNotice = 'campaign/notice/update',
  updateIsNoticeExposed = 'campaign/is-notice-exposed/update',

  /**
   * 캠페인 투찰 기간
   */
  updateVoteStartDate = 'campaign/vote-start-date/update',
  updateVoteEndDate = 'campaign/vote-end-date/update',

  /**
   * 결재선 설정
   */
  setApprovalList = 'campaign/approval-list/set',
  addApproval = 'campaign/approval/add',

  /**
   * 결재 업데이트
   */
  updateApproval = 'campaign/approval/update',

  /**
   * 결재 삭제
   */
  deleteApproval = 'campaign/approval/delete',
  /**
   * 평가 완료 비율
   */
  updateEstimationCompletionRate = 'campaign/estimation-completion-rate/set',

  /**
   * 캠페인 안내 컨텐츠 업데이트
   */
  updateCampaignBannerContent = 'campaign/banner-content/update',

  /**
   * 캠페인 안내 노출 업데이트
   */
  updateCampaignBannerExposed = 'campaign/banner-exposed/update',
}

export const campaignAction = {
  /**
   * 캠페인 목록 관련 액션
   */
  setId: createAction(CampaignAction.setId)<CampaignId | undefined>(),
  setList: createAction(CampaignAction.setList)<CampaignShortVO[]>(),
  setFilter: createAction(CampaignAction.setFilter)<CampaignQuery>(),
  toggleDrawer: createAction(CampaignAction.toggleDrawer)<boolean>(),
  setLoading: createAction(CampaignAction.setLoading)<boolean>(),

  /**
   * 캠페인 등록 관련 액션
   */
  add: createAction(CampaignAction.add)<CampaignAddParameter>(),

  /**
   * 캠페인 상세 관련 액션
   */
  setDetail: createAction(CampaignAction.setDetail)<CampaignVO | undefined>(),
  deleteDetail: createAction(CampaignAction.deleteDetail)(),

  /**
   * 캠페인 수정 관련 액션
   */
  updateDetail: createAction(CampaignAction.updateName)<CampaignUpdateParameter>(),

  updateName: createAction(CampaignAction.updateName)<CampaignUpdateNameParameter>(),
  updateStartDate: createAction(CampaignAction.updateStartDate)<CampaignUpdateStartDateParameter>(),
  updateEndDate: createAction(CampaignAction.updateEndDate)<CampaignUpdateEndDateParameter>(),
  updateTotalReward: createAction(
    CampaignAction.updateTotalReward
  )<CampaignUpdateTotalRewardParameter>(),
  updateManager: createAction(CampaignAction.updateManager)<CampaignUpdateManagerParameter>(),
  updateStatus: createAction(CampaignAction.updateStatus)<CampaignUpdateStatusParameter>(),

  /**
   * 제안 구분 목록 관련 액션
   */
  setProposalCategoryList: createAction(CampaignAction.setProposalCategoryList)<
    ProposalCategoryVO[]
  >(),

  /**
   * 제안 구분 추가 관련 액션
   */
  addProposalCategory: createAction(
    CampaignAction.addProposalCategory
  )<ProposalCategoryAddParameter>(),
  setAddProposalCategoryModalOpen: createAction(
    CampaignAction.setAddProposalCategoryModalOpen
  )<boolean>(),

  updateProposalCategoryList: createAction(
    CampaignAction.updateProposalCategoryList
  )<CampaignUpdateProposalCategoryParameter>(),

  /**
   * 제안 구분 삭제 관련 액션
   */
  deleteProposalCategory: createAction(CampaignAction.deleteProposalCategory)<ProposalCategoryId>(),

  /**
   * 평가 담당자 목록 관련 액션
   */
  setEstimatorList: createAction(CampaignAction.setEstimatorList)<CampaignEstimatorVO[]>(),

  /**
   * 평가 담당자 추가 관련 액션
   */
  upsertCampaignEstimatorList: createAction(
    CampaignAction.upsertCampaignEstimatorList
  )<EstimatorListUpsertParameter>(),
  setAddCampaignEstimatorModalOpen: createAction(
    CampaignAction.setAddCampaignEstimatorModalOpen
  )<boolean>(),

  deleteCampaignEstimator: createAction(CampaignAction.deleteCampaignEstimator)<UserId>(),

  /**
   * 제안 현황 관련 액션
   */
  setAddProposalEstimatorModalOpen: createAction(
    CampaignAction.setAddProposalEstimatorModalOpen
  )<boolean>(),
  setProposalList: createAction(CampaignAction.setProposalList)<ProposalVO[]>(),

  setProposalEstimatorModalId: createAction(
    CampaignAction.setProposalEstimatorModalId
  )<ProposalId | null>(),

  upsertProposalEstimatorList: createAction(
    CampaignAction.upsertProposalEstimatorList
  )<EstimatorListUpsertParameter>(),

  upsertProposalPaymentDate: createAction(
    CampaignAction.upsertProposalPaymentDate
  )<ProposalPaymentDateParameter>(),

  setIsOpenMemo: createAction(CampaignAction.setIsOpenMemo)<boolean>(),
  addMemo: createAction(CampaignAction.addMemo)<CampaignMemoAddParameter>(),
  setFormHeight: createAction(CampaignAction.setFormHeight)<number>(),

  setFilterMemo: createAction(CampaignAction.setFilterMemo)<CampaignMemoQuery>(),
  setMemoList: createAction(CampaignAction.setMemoList)<CampaignMemoVO[]>(),
  setIsLoadingMemo: createAction(CampaignAction.setIsLoadingMemo)<boolean>(),

  updateMemo: createAction(CampaignAction.updateMemo)<CampaignMemoUpdateParameter>(),
  deleteMemo: createAction(CampaignAction.deleteMemo)<CampaignMemoId>(),
  /**
   * 캠페인 진행 목록
   */
  setCampaignListUnderOnGoing: createAction(CampaignAction.setCampaignListUnderOnGoing)<
    CampaignShortVO[]
  >(),
  getCampaignListUnderOnGoing: createAction(CampaignAction.getCampaignListUnderOnGoing)(),

  /**
   * 캠페인 평가기간
   */
  updateEstimationStartDate: createAction(
    CampaignAction.updateEstimationStartDate
  )<CampaignUpdateEstimationStartDateParameter>(),
  updateEstimationEndDate: createAction(
    CampaignAction.updateEstimationEndDate
  )<CampaignUpdateEstimationEndDateParameter>(),

  updateNotice: createAction(CampaignAction.updateNotice)<CampaignUpdateNoticeParameter>(),
  updateIsNoticeExposed: createAction(
    CampaignAction.updateIsNoticeExposed
  )<CampaignUpdateIsNoticeExposedParameter>(),

  /**
   * 캠페인 투찰기간
   */
  updateVoteStartDate: createAction(
    CampaignAction.updateVoteStartDate
  )<CampaignUpdateVoteStartDateParameter>(),
  updateVoteEndDate: createAction(
    CampaignAction.updateVoteEndDate
  )<CampaignUpdateVoteEndDateParameter>(),

  /**
   * 결재선 설정
   */
  setApprovalList: createAction(CampaignAction.setApprovalList)<CampaignApprovalVO[]>(),
  addApproval: createAction(CampaignAction.addApproval)(),
  updateApproval: createAction(CampaignAction.updateApproval)<CampaignUpdateApprovalParameter>(),
  deleteApproval: createAction(CampaignAction.deleteApproval)<ProposalApprovalId>(),
  /**
   * 평가 완료 비율
   */
  updateEstimationCompletionRate: createAction(
    CampaignAction.updateEstimationCompletionRate
  )<CampaignUpdateEstimationCompletionRateParameter>(),

  /**
   * 캠페인 안내 컨텐츠 업데이트
   */
  updateCampaignBannerContent: createAction(
    CampaignAction.updateCampaignBannerContent
  )<CampaignBannerUpdateContentParameter>(),

  /**
   * 캠페인 안내 노출 업데이트
   */
  updateCampaignBannerExposed: createAction(
    CampaignAction.updateCampaignBannerExposed
  )<CampaignBannerUpdateIsExposedParameter>(),
};
