import { create } from 'zustand';
import type { DefaultFunction } from '@front/type/Function';

interface State {
  colored: number | undefined;
  editOpenList: number[];
}

interface Actions {
  setColored: (colored?: number) => void;
  addEditOpenId: (id: number) => void;
  clearEditOpenList: DefaultFunction;
  reset: () => void;
}

const useWorkWorkerState = create<State & Actions>((set) => ({
  colored: undefined,
  setColored: (colored) => set({ colored }),
  editOpenList: [],
  clearEditOpenList: () => set({ editOpenList: [] }),
  addEditOpenId: (id) =>
    set((state) => {
      if (state.editOpenList.includes(id)) {
        return { editOpenList: state.editOpenList.filter((originId) => originId !== id) };
      }
      return { editOpenList: [...state.editOpenList, id] };
    }),
  reset: () =>
    set({
      colored: undefined,
      editOpenList: [],
    }),
}));

export default useWorkWorkerState;
