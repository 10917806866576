import React from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApprovalDocumentImgModal from '@front/src/features/drawer-approval-document/components/ApprovalDocumentImgModal';
import { isImg } from '@front/src/utils';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  item?: ApprovalDocumentDetailView;
}

export default function ApprovalDocumentContentTable({ item }: Props) {
  const setImgModalId = useApprovalDocumentStore((state) => state.setImgModalId);
  const onClick = () => {
    window.location.assign(
      `/api/file-item/all?fileItemIdList=${item?.fileItemList.map((file) => file.id)}`
    );
  };

  return (
    <TableContainerUI>
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <TableCellUI
              isHead={true}
              sx={{ width: '10%' }}
            >
              내용
            </TableCellUI>
            <TableCellUI sx={{ width: '90%' }}>
              <Box
                sx={{
                  textAlign: 'left',
                }}
                dangerouslySetInnerHTML={{
                  __html: item?.contents.body || '-',
                }}
              />
            </TableCellUI>
          </TableRowUI>
          <TableRowUI>
            <TableCellUI
              isHead={true}
              sx={{ width: '10%' }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="5px"
                alignItems="center"
              >
                <Typography>
                  첨부
                  <br />
                  파일
                </Typography>
                {item?.fileItemList.length !== 0 && (
                  <ButtonBasicUI
                    onClick={onClick}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                      whiteSpace: 'nowrap',
                      background: 'transparent',
                      border: `1px solid ${ColorPalette.main.main_primary}`,
                      color: ColorPalette.main.main_primary,
                      fontSize: '14px',
                      '&:hover': {
                        background: ColorPalette.main.main_secondary,
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>전체</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}> 다운로드</Typography>
                  </ButtonBasicUI>
                )}
              </Box>
            </TableCellUI>
            <TableCellUI sx={{ width: '90%' }}>
              {item?.fileItemList.length === 0 && <Typography>파일이 없습니다.</Typography>}
              {item?.fileItemList.map((file) => (
                <Box
                  key={file.id}
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <ApprovalDocumentImgModal item={file} />
                  <Box
                    display="flex"
                    gap="5px"
                    alignItems="center"
                  >
                    <FontAwesomeIcon icon="file" />
                    <Typography>{file.filename}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap="10px"
                    alignItems="center"
                  >
                    {isImg(file.ext) && (
                      <FontAwesomeIcon
                        icon="magnifying-glass"
                        cursor="pointer"
                        onClick={() => setImgModalId(file.id)}
                      />
                    )}
                    <FontAwesomeIcon
                      icon="download"
                      cursor="pointer"
                      onClick={() => window.location.assign(`/api/file-item/${file.id}`)}
                    />
                  </Box>
                </Box>
              ))}
            </TableCellUI>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
