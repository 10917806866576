import useWorkWorkerState from '@front/src/features/work/features/work/features/worker/useState';
import { useShallow } from 'zustand/react/shallow';
import { useCustomDialog } from '@front/src/features/dialog';
import useWorkWorkerAddTop from '@front/src/features/work/features/work/features/worker/repository/useAddTop';
import useWorkWorkerAddBottom from '@front/src/features/work/features/work/features/worker/repository/useAddBottom';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import { useEffect } from 'react';

const useWorkWorkerTableMutation = ({ sectionId, dataId, menuId }) => {
  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };
  const { addEditOpenId, setColored, reset } = useWorkWorkerState(
    useShallow((state) => ({
      setColored: state.setColored,
      addEditOpenId: state.addEditOpenId,
      reset: state.reset,
    }))
  );
  const { confirm } = useCustomDialog();
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkWorkerAddTop(commonParams);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      addEditOpenId(data);
    },
  });
  const { run: onAddBottom, setCallback: setAddBottomCallback } =
    useWorkWorkerAddBottom(commonParams);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setColored(data);
      addEditOpenId(data);
    },
  });
  const { mutate: onDeleteAll } = workWorkerMutation.useDeleteAll(commonParams);
  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };
  useEffect(
    () => () => {
      reset();
    },
    []
  );
  return {
    onAddTop,
    onAddBottom,
    onRemoveAll,
  };
};

export default useWorkWorkerTableMutation;
