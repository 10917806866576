import React, { useContext } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import CircularProgress from '@front/components/CircularProgress';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import { AffiliatedCompanyCharacterOpinionTableRow } from '@front/src/features/affiliated-company/features/character/features/opinion/components/opinion-table-row';
import { AffiliatedCompanyCharacterOpinionContext } from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/context';

export { OpinionTable as AffiliatedCompanyCharacterOpinionTable };

const OpinionTable = () => {
  const {
    d: { characterList },
    isLoading,
  } = useContext(AffiliatedCompanyCharacterOpinionContext);
  if (typeof characterList === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  if (characterList.length === 0) {
    return <NoResultBox message="저장된 풍동 업체에 대한 의견이 없습니다." />;
  }
  return (
    <TableContainer>
      <Table>
        <TableHeadRow />
        <TableBody>
          {characterList?.map((item, i) => (
            <AffiliatedCompanyCharacterOpinionTableRow
              key={item.id}
              item={item}
              index={i}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableHeadRow = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="40px"
      >
        {' '}
      </Th>
      <Th
        center
        width="140px"
      >
        날짜
      </Th>
      <Th
        center
        width="200px"
      >
        풍동업체
      </Th>
      <Th
        center
        width="300px"
      >
        사유
      </Th>
      <Th
        center
        width="100px"
      >
        선호정도
      </Th>
      <Th
        center
        width="100px"
      >
        출처
      </Th>
    </TableRow>
  </TableHead>
);
