import { useCallback } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { useShallow } from 'zustand/react/shallow';

export { useContextMenu as useAccountingSettingsManagementAccountingAccountTableContextMenu };

interface Props {
  onUpdate: (params) => void;
  list?: AccountingSettingsManagementAccountingAccountTableCategoryView[];
  onDelete: (id) => void;
  onInsert: (params) => void;
  readOnly?: boolean;
}

const useContextMenu = ({ onUpdate, list, onDelete, onInsert, readOnly }: Props) => {
  const { setUpdateId, setTargetItem, targetItem, setAnchorEl, setIsOpen, setLargeAnchorEl } =
    useAccountingSettingsManagementAccountingAccountTableState(
      useShallow((state) => ({
        anchorEl: state.anchorEl,
        setUpdateId: state.setUpdateId,
        setTargetItem: state.setTargetItem,
        targetItem: state.targetItem,
        setAnchorEl: state.setAnchorEl,
        setLargeAnchorEl: state.setLargeAnchorEl,
        setIsOpen: state.setIsOpen,
      }))
    );
  const handleClose = useCallback(() => {
    setTargetItem(undefined);
    setAnchorEl(null);
    setLargeAnchorEl(null);
  }, [setTargetItem, setAnchorEl, setLargeAnchorEl]);
  const handleChangeName = useCallback(() => {
    setUpdateId(targetItem?.id);
    handleClose();
  }, [handleClose, setUpdateId, targetItem]);
  const handleMoveUp = useCallback(() => {
    handleClose();
    if (typeof targetItem === 'undefined') {
      return;
    }
    const sequence = targetItem?.sequence;
    if (sequence === 0) {
      return;
    }
    onUpdate({
      id: targetItem.id,
      name: targetItem.name,
      alias: targetItem.alias,
      sequence: sequence - 1,
      depth: targetItem.depth,
      parentId: targetItem.parentId,
    });
  }, [onUpdate, targetItem, handleClose]);
  const handleMoveDown = useCallback(() => {
    handleClose();
    if (typeof targetItem === 'undefined' || typeof list === 'undefined') {
      return;
    }
    const sequence = targetItem?.sequence;
    if (targetItem?.sequence >= list.filter((item) => item.depth === targetItem.depth).length - 1) {
      return;
    }
    onUpdate({
      id: targetItem.id,
      name: targetItem.name,
      alias: targetItem.alias,
      sequence: sequence + 1,
      depth: targetItem.depth,
      parentId: targetItem.parentId,
    });
  }, [onUpdate, targetItem, list, handleClose]);
  const handleInsert = useCallback(() => {
    handleClose();
    onInsert({
      name: '',
      alias: '',
      parentId: targetItem?.parentId,
      depth: targetItem?.depth,
      sequence: targetItem?.sequence,
    });
  }, [onInsert, targetItem, handleClose]);
  const { confirm } = useCustomDialog();
  const handleDelete = useCallback(() => {
    handleClose();
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onDelete(targetItem?.id);
      },
    });
  }, [onDelete, targetItem, confirm, handleClose]);
  const handleKeyDown = useCallback(
    (e) => {
      if (readOnly) {
        return;
      }
      if (typeof targetItem === 'undefined') {
        return;
      }
      const { key, ctrlKey, altKey } = e;
      if (key === 'F2') {
        handleChangeName();
        return;
      }
      if (ctrlKey && altKey && key === 'ArrowUp') {
        handleMoveUp();
        return;
      }
      if (ctrlKey && altKey && key === 'ArrowDown') {
        handleMoveDown();
        return;
      }
      if (ctrlKey && key === 'Help') {
        handleInsert();
        return;
      }
      if (ctrlKey && key === 'Delete') {
        handleDelete();
      }
    },
    [
      handleChangeName,
      handleMoveUp,
      handleMoveDown,
      handleDelete,
      handleInsert,
      targetItem,
      readOnly,
    ]
  );
  const handleAttributeClick = useCallback(() => {
    handleClose();
    setIsOpen(true);
  }, [handleClose, setIsOpen]);
  return {
    handleClose,
    handleChangeName,
    handleMoveUp,
    handleMoveDown,
    handleInsert,
    handleDelete,
    handleKeyDown,
    handleAttributeClick,
  };
};
