import React from 'react';
import FileAuthSettingsModal from '@front/src/features/file-item/features/auth-settings/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

interface Props {
  menuId: number | undefined;
  dataId: number | undefined;
}

export default function UIBuilderFileAuthSettingsModal({ dataId, menuId }: Props) {
  const { watch, setValue, getValues } = useFormContext<UIBuilderTableFieldValues>();
  const fileItemId = getValues('fileItemId');
  const open = watch('openFileAuthSettingsModal');

  const onCloseFileAuthSettingsModal = () => {
    setValue('openFileAuthSettingsModal', false);
    setValue('fileItemId', undefined);
  };

  if (!fileItemId || !open) return <></>;

  return (
    <FileAuthSettingsModal
      fileItemId={fileItemId}
      open={open}
      onClose={onCloseFileAuthSettingsModal}
      menuId={menuId}
      dataId={dataId}
    />
  );
}
