import React, { useCallback, useContext, useMemo } from 'react';
import { Box, TableRow } from '@mui/material';
import type { AffiliatedCompanyPersonEducationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/types/view';
import { OldTd } from '@front/layouts/Table';
import { useAffiliatedCompanyPersonDetailModalOutlineEducationState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/useState';
import Checkbox from '@front/layouts/Checkbox';
import { useShallow } from 'zustand/react/shallow';
import {
  educationLevelObjectFormLabel,
  educationLevelOptionList,
} from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/utils';
import { AffiliatedCompanyPersonDetailModalOutlineEducationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/widgets/provider/context';
import { FormProvider, useForm } from 'react-hook-form';
import { convertEmptyToNull, convertValueToEmptyFromObjectForFormIfIsNull } from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/utils/constants';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';

export { Row as AffiliatedCompanyPersonDetailModalOutlineEducationTableRow };

interface Props {
  item: AffiliatedCompanyPersonEducationView;
}

const Row = ({ item }: Props) => {
  const { onUpdate } = useContext(AffiliatedCompanyPersonDetailModalOutlineEducationContext);
  const { id, idList, setIdList, setId } =
    useAffiliatedCompanyPersonDetailModalOutlineEducationState(
      useShallow((state) => ({
        id: state.id,
        idList: state.idList,
        setIdList: state.setIdList,
        setId: state.setId,
      }))
    );
  const checked = useMemo(() => idList.includes(item.id), [idList, item.id]);
  const handleCheckboxChange = useCallback(
    (e) => {
      e.stopPropagation();
      setIdList(spliceAndPushIfExist(idList, item.id));
    },
    [idList, setIdList, item.id]
  );
  const isSelected = item.id === id;
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(() => {
    if (readOnly) return;
    if (isSelected) return;
    setId(item.id);
  }, [readOnly, setId, isSelected, item.id]);
  const methods = useForm({
    values: { ...convertValueToEmptyFromObjectForFormIfIsNull(item) },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({ ...convertEmptyToNull(data) });
  });
  const renderCellInput = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <OldTd>
            <HookFormInput
              name={name}
              onSubmit={onSubmit}
              disabled={readOnly}
            />
          </OldTd>
        );
      }
      return <OldTd>{item[name] ?? '-'}</OldTd>;
    },
    [isSelected, onSubmit, item, readOnly]
  );
  const rowSx = useMemo(
    () => ({
      cursor: readOnly ? 'default' : 'pointer',
    }),
    [readOnly]
  );
  return (
    <FormProvider {...methods}>
      <TableRow
        hover={!readOnly}
        onClick={handleRowClick}
        sx={rowSx}
      >
        <OldTd>
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          {isSelected ? (
            <HookFormSelect
              height="0px"
              isDefaultPossible
              defaultLabel="선택"
              name="level"
              optionList={educationLevelOptionList}
              onSubmit={onSubmit}
              disabled={readOnly}
            />
          ) : (
            <Box>{educationLevelObjectFormLabel[item.level] ?? '-'}</Box>
          )}
        </OldTd>
        {renderCellInput('name')}
        {renderCellInput('major')}
        {renderCellInput('studentID')}
        {renderCellInput('professor')}
        {renderCellInput('note')}
      </TableRow>
    </FormProvider>
  );
};
