import { useState } from 'react';
import type { Swiper as SwiperClass } from 'swiper/types';
import type { SwiperProps } from 'swiper/swiper-react';
import { EffectCoverflow } from 'swiper/modules';

const useWorkCarouselSwiper = ({ dataId }) => {
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const params: SwiperProps = {
    ref: setSwiper,
    onSwiper: setSwiper,
    coverflowEffect: {
      rotate: 0,
      stretch: 100,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    modules: [EffectCoverflow],
    effect: 'coverflow',
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    initialSlide: 0,
    centeredSlides: true,
    loop: true,
    key: `work-carosel-${dataId}`,
    // grabCursor: true,
    lazyPreloadPrevNext: 3,
  };

  const handlePrev = () => {
    swiper?.slidePrev();
  };
  const handleNext = () => {
    swiper?.slideNext();
  };

  return {
    onSlidePrev: handlePrev,
    onSlideNext: handleNext,
    params,
  };
};

export default useWorkCarouselSwiper;
