import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import React, { Suspense, useRef } from 'react';
import { commonRepository } from '@front/src/api/repository';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderBundleOptions } from '@front/src/features/work/features/work/features/output/components/Table';

import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableRowLoadingSpinnerUI';
import UIBuilderHeaderSettingsHistoryModal from '@front/src/components/ui-builder/table/HeaderSettingsHistoryModal';
import TableBodyLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableBodyLoadingSpinnerUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import FixedColumnSupport from '@front/src/components/ui-builder/table/FixedColumnSupport';
import { FormProvider } from 'react-hook-form';
import useUIBuilderTableForm from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import './ui-builder-table.css';
import UIBuilderFileAuthSettingsModal from '@front/src/components/ui-builder/table/FileAuthSettingsModal';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { noteAutoScrollingAnchorClassName } from '@front/src/features/note/utils';
import { scrollToElement } from '@front/src/utils';
import {useGlobalNavBarState} from "@front/src/features/global-nav-bar/useState";

export interface UiBuilderTableProps<T = any> extends SectionComponentProps {
  options?: UIBuilderTableOptions;
  list?: T[];
  bundleOptions?: UIBuilderBundleOptions;
}

interface Props extends UiBuilderTableProps {
  HeadComponent?: (props: UIBuilderTableHeadProps) => JSX.Element;
  BodyComponent: (props: UIBuilderTableBodyProps) => JSX.Element;
  isLoading?: boolean;
}

export default function UiBuilderTable(props: Props) {
  const {
    readOnly,
    sectionId,
    BodyComponent,
    HeadComponent,
    isLoading = false,
    menuId,
    options,
    dataId,
    list,
  } = props;
  const ref = useRef<HTMLTableSectionElement | null>(null);
  const { data: headerList } = commonRepository.useGetHeaderList(sectionId, menuId);

  const { methods, onOpenFileAuthSettingsModal, setFileItemId, onCloseEditModal, resetValues } =
    useUIBuilderTableForm({ headerList, list });

  const colSpan = (headerList?.length ?? 0) + (readOnly ? 0 : 1) + 1;

  const newOptions = {
    ...options,
    onOpenFileAuthSettingsModal,
    setFileItemId,
  };

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { leftDrawerState } = useGlobalNavBarState();
  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );

  useEffect(() => {
    if (ref.current && noteSectionId && rowId && leftDrawerState === 'notification') {
      const elements = document.getElementsByClassName(
        `${noteAutoScrollingAnchorClassName}-${rowId}`
      );
      if (elements[0]) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          scrollToElement(elements[0] as HTMLElement, () => {}, 0.9);
          timeoutRef.current = null;
        }, 300);
      }
    }
  }, [ref.current, timeoutRef.current, noteSectionId, rowId, leftDrawerState]);

  return (
    <FormProvider {...methods}>
      <FixedColumnSupport
        headerList={headerList}
        readOnly={readOnly}
      >
        <TableContainerUI
          sx={{
            position: 'relative',
          }}
        >
          <TableBodyLoadingSpinnerUI
            sx={{
              height: ref.current ? ref.current.clientHeight : 0,
              top: 36,
              zIndex: 11,
            }}
            open={isLoading}
          />
          <UIBuilderHeaderSettingsHistoryModal sectionId={sectionId} />
          <UIBuilderFileAuthSettingsModal
            menuId={menuId}
            dataId={dataId}
          />
          <TableTableUI
            sx={{
              '& .MuiTableRow-root > .MuiTableCell-root:nth-last-of-type(2)': {
                borderRight: 'none',
              },
              '& thead .MuiTableRow-root > .MuiTableCell-root:last-child': {
                padding: 0,
              },
            }}
          >
            {HeadComponent ? (
              <HeadComponent
                resetValues={resetValues}
                {...props}
              />
            ) : (
              <UIBuilderTableHeader
                resetValues={resetValues}
                {...props}
              />
            )}
            <TableBodyUI ref={ref}>
              <Suspense fallback={<TableRowLoadingSpinnerUI colSpan={colSpan} />}>
                <BodyComponent
                  onCloseEditModal={onCloseEditModal}
                  options={newOptions}
                  {...props}
                />
              </Suspense>
            </TableBodyUI>
          </TableTableUI>
        </TableContainerUI>
      </FixedColumnSupport>
    </FormProvider>
  );
}

export interface UIBuilderTableOptions {
  setWorkId?: Dispatch<SetStateAction<number | undefined>>;
  onOpenFileAuthSettingsModal?: () => void;
  setFileItemId?: (id) => void;
}
