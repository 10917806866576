import { ProjectSalesInfoEstimationHasVatOption } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/domain';
import {
  convertEmptyToNullForAmountParameter,
  convertEmptyToNullForParameter,
  convertYNToBooleanForParameter,
} from '@front/src/utils';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const convertHasVatForParameter = (value) => {
  if (value === ProjectSalesInfoEstimationHasVatOption.INCLUDE) {
    return true;
  }
  if (value === ProjectSalesInfoEstimationHasVatOption.SEPARATE) {
    return false;
  }
  return null;
};
export const convertIsConfirmed = (value) => {
  if (typeof value !== 'boolean') {
    return '-';
  }
  if (value) {
    return 'Y';
  }
  return 'N';
};

export const convertEstimationDataForParameter = (data) => {
  const isConfirmed = convertYNToBooleanForParameter(data.isConfirmed);
  return {
    isConfirmed,
    estimationDate: formatDateOrNull(data.estimationDate),
    requestCompanyId: convertEmptyToNullForParameter(data.requestCompany?.id),
    requestCompanyManagerId: convertEmptyToNullForParameter(data.requestCompanyManager?.id),
    clientCompanyId: convertEmptyToNullForParameter(data.clientCompany?.id),
    experimentInformation: convertEmptyToNullForParameter(data.experimentInformation),
    experimentAmount: convertEmptyToNullForAmountParameter(data.experimentAmount),
    reviewAmount: convertEmptyToNullForAmountParameter(data.reviewAmount),
    totalAmount: convertEmptyToNullForAmountParameter(data.totalAmount),
    CMAmount: convertEmptyToNullForAmountParameter(data.CMAmount),
    convertedCMAmount: convertEmptyToNullForAmountParameter(data.convertedCMAmount),
    unitPrice: convertEmptyToNullForAmountParameter(data.unitPrice),
    schedule: convertEmptyToNullForParameter(data.schedule),
    estimationCategory: convertEmptyToNullForParameter(data.estimationCategory),
    isCm: convertYNToBooleanForParameter(data.isCm),
    CMMethod: convertEmptyToNullForParameter(data.CMMethod),
    excel: data.excel,
    pdf: data.pdf,
    note: convertEmptyToNullForParameter(data.note),
  };
};
