import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ContractPaymentTermsView } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/types/views';
import { initializeObject } from '@front/src/utils';

const useContractPaymentTermsForm = (item: ContractPaymentTermsView) => {
  const methods = useForm<ContractPaymentTermsView>({
    defaultValues: getFormValues(item),
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

const getFormValues = (item: ContractPaymentTermsView) => {
  return initializeObject(item) as ContractPaymentTermsView;
};

export default useContractPaymentTermsForm;
