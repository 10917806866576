import { useMutation, useQueryClient } from 'react-query';
import type {
  CommentFilterParams,
  CommentParams,
  CommentUpdateParams,
  NoteCreateParams,
  NoteUpdateParams,
  ReviewCreateParams,
  ReviewerUpdateParams,
  ReviewUpdateRequest,
} from '@front/src/features/note/types/parameter';
import { noteApi } from '@front/src/features/note/api/api';

const noteMutation = {
  useCreate: () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'create'],
      mutationFn: (params: NoteCreateParams) => noteApi.createNote(params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: [] });
        // await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        // await queryClient.invalidateQueries({ queryKey: ['note', 'filter'] });
        // await queryClient.invalidateQueries({ queryKey: ['work'] });
        // await queryClient.invalidateQueries({ queryKey: ['project', 'sales'] });
      },
    });
    return { mutate, isLoading };
  },

  useUpdateReview: (reviewId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['note', 'review', 'update'],
      mutationFn: (params: ReviewUpdateRequest) => noteApi.updateReview(reviewId, params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'reviewer'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'review-history'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'detail'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'subData'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'filter'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'count'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['global-nav-bar'] });
        await queryClient.invalidateQueries({ queryKey: ['work', 'aside'] });
      },
    });
    return { mutate };
  },

  useToggleRead: (reviewerId: number, params: ReviewerUpdateParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'create'],
      mutationFn: () => noteApi.toggleRead(reviewerId, params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'detail'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'review-history'] });
      },
    });
    return { mutate, isLoading };
  },

  useDeleteNote: (noteId: number) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'delete'],
      mutationFn: () => noteApi.deleteNote(noteId),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'filter'] });
        await queryClient.invalidateQueries({ queryKey: ['work'] });
      },
    });
    return { mutate, isLoading };
  },

  useUpdateNote: (noteId: number) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'update'],
      mutationFn: (params: NoteUpdateParams) => noteApi.updateNote(noteId, params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
      },
    });
    return { mutate, isLoading };
  },

  useCreateComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'comment', 'create'],
      mutationFn: (params: CommentParams) => noteApi.createComment(params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'comment', commentQueryParams] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'count'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['global-nav-bar'] });
        await queryClient.invalidateQueries({ queryKey: ['work', 'aside'] });
      },
    });
    return { mutate, isLoading };
  },

  useUpdateComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'comment', 'update'],
      mutationFn: (params: CommentUpdateParams) => noteApi.updateComment(params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'comment', commentQueryParams] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'count'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['global-nav-bar'] });
        await queryClient.invalidateQueries({ queryKey: ['work', 'aside'] });
      },
    });
    return { mutate, isLoading };
  },

  useDeleteComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['note', 'comment', 'delete'],
      mutationFn: (commentId: number) => noteApi.deleteComment(commentId),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'comment', commentQueryParams] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
      },
    });
    return { mutate, isLoading };
  },

  useCreateReview: (noteId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['note', 'review', 'create'],
      mutationFn: (params: ReviewCreateParams) => noteApi.createReview(noteId, params),
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: ['note', 'reviewer', noteId] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'review-history'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'detail'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'subData'] });
        await queryClient.invalidateQueries({ queryKey: ['note', 'filter'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'count'] });
        await queryClient.invalidateQueries({ queryKey: ['user-notification', 'list'] });
        await queryClient.invalidateQueries({ queryKey: ['global-nav-bar'] });
        await queryClient.invalidateQueries({ queryKey: ['work', 'aside'] });
      },
    });
    return { mutate };
  },
};

export default noteMutation;
