import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { modalLayout } from '@front/src/components/layout/modal';
import { convertEstimationComparedDataForParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/utils';
import { ProjectSalesInfoEstimationComparedCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/features/create-modal/widgets/context';
import { useProjectSalesInfoEstimationComparedState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-compared/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ModalButtonGroup as ProjectSalesInfoEstimationComparedCreateModalButtonGroup };

const ModalButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    h: { onClose, onCreate },
  } = useContext(ProjectSalesInfoEstimationComparedCreateModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onCreate({
      id,
      ...convertEstimationComparedDataForParameter(data),
    });
  });
  const { readOnly } = useProjectSalesInfoEstimationComparedState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </Footer>
  );
};
