import { useIsMutating } from 'react-query';

const useWorkCarouselGetIsLoading = () => {
  const isLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel', 'delete'],
  });
  return isLoading;
};

export default useWorkCarouselGetIsLoading;
