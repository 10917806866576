import dayjs from 'dayjs';
import React, { memo, useCallback } from 'react';
import { Box, InputAdornment, TableBody, TableHead, TableRow } from '@mui/material';

import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import {
  ProposalArrangementInformationStatus,
  ProposalStatus,
  proposalStatusName,
} from '@front/ost_proposal/domain';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import { OldTh, Table, OldTd } from '@front/layouts/Table';
import ProposalEstimator from '@front/ost_campaign/view/detail/state/estimator';
import { ProposalRowTitle } from '@front/ost_campaign/view/detail/state/rowTitle';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { campaignAction } from '@front/ost_campaign/action';
import { FixResultInput } from '@front/ost_campaign/view/detail/state/fix-result-input';
import LoadingBar from '@front/layouts/LoadingBar';

const getContributorList = (proposal: ProposalVO) => {
  let totalList = proposal.contributorViewList.map(
    (contributorView) => contributorView.contributor
  );
  let writerIndex = 0;
  for (let i = 0; i < totalList.length; i++) {
    if (totalList[i].id === proposal.writer.id) {
      writerIndex = i;
      break;
    }
  }
  const writer = totalList.splice(writerIndex, 1);
  totalList.unshift(writer[0]);
  totalList = totalList.filter((item) => item !== null);
  return totalList;
};

const dateFormat = 'YYYY/MM/DD';

const ProposalStateTable = () => {
  const dispatch = useDispatch();
  const manager = useSelector((root: RootState) => root.campaign.detail!.manager, shallowEqual);
  const [proposalList] = useSelector(
    (root: RootState) => [root.campaign.proposalList],
    shallowEqual
  );

  const onOpen = useCallback(
    (proposalId: ProposalId) => () => {
      dispatch(campaignAction.setProposalEstimatorModalId(proposalId));
      dispatch(campaignAction.setAddProposalEstimatorModalOpen(true));
    },
    [dispatch]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <OldTh>관리번호</OldTh>
          <OldTh>주제</OldTh>
          <OldTh>제안자</OldTh>
          <OldTh>구분</OldTh>
          <OldTh>상태</OldTh>
          <OldTh>심사자</OldTh>
          <OldTh>협의자</OldTh>
          <OldTh>제안등급</OldTh>
          <OldTh>확정등급</OldTh>
          <OldTh>투찰현황</OldTh>
          <OldTh>심사금액</OldTh>
          <OldTh>확정금액</OldTh>
          <OldTh>확정일(정산기준월)</OldTh>
          <OldTh>지급예정일</OldTh>
        </TableRow>
      </TableHead>
      <TableBody>
        {proposalList ? (
          <>
            {proposalList.length <= 0 && (
              <TableRow>
                <OldTd colSpan={13}>{'제안이 없습니다.'}</OldTd>
              </TableRow>
            )}
            {proposalList.length > 0 &&
              proposalList
                .sort((a, b) =>
                  a.isRequestedAdvisor === b.isRequestedAdvisor ? 0 : a.isRequestedAdvisor ? -1 : 1
                )
                .map((proposal) => (
                  <TableRow
                    key={proposal.id}
                    sx={{
                      backgroundColor:
                        proposal.arrangementStatus === ProposalArrangementInformationStatus.SKIP ||
                        proposal.status === ProposalStatus.ESTIMATING ||
                        !!proposal.advisor
                          ? `${ColorPalette._ffffff}`
                          : proposal.isRequestedAdvisor
                          ? `${ColorPalette._F8CECC}`
                          : proposal.status === ProposalStatus.SUBMISSION &&
                            proposal.arrangementStatus === ProposalArrangementInformationStatus.WAIT
                          ? `${ColorPalette._F8CECC}`
                          : `${ColorPalette._ffffff}`,
                    }}
                  >
                    <OldTd>{proposal.code}</OldTd>

                    <OldTd>
                      <ProposalRowTitle
                        id={proposal.id}
                        title={proposal.title}
                        status={proposal.status}
                        managerId={manager?.id}
                      />
                    </OldTd>

                    <OldTd>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {getContributorList(proposal)?.map((user) => (
                          <Box key={user.id}>{user.name}</Box>
                        ))}
                      </Box>
                    </OldTd>

                    <OldTd>{proposal.category.name ? proposal.category.name : '-'}</OldTd>

                    <OldTd>{proposalStatusName(proposal.status)}</OldTd>

                    <OldTd>
                      <ProposalEstimator
                        proposal={proposal}
                        onOpen={onOpen}
                      />
                    </OldTd>

                    <OldTd>
                      {proposal.advisor ? (
                        <TextBox variant="body9">{proposal.advisor.name}</TextBox>
                      ) : (
                        <>
                          {proposal.arrangementStatus ===
                            ProposalArrangementInformationStatus.SKIP ||
                          proposal.status === ProposalStatus.ESTIMATING ? (
                            <TextBox variant="body9">{'-'}</TextBox>
                          ) : proposal.isRequestedAdvisor ? (
                            <TextBox
                              sx={{
                                color: ColorPalette._eb4c4c,
                              }}
                              variant="body8"
                            >
                              {'협의자 요청'}
                            </TextBox>
                          ) : proposal.status === ProposalStatus.SUBMISSION ? (
                            <TextBox
                              sx={{
                                color: ColorPalette._eb4c4c,
                              }}
                              variant="body8"
                            >
                              {'협의수요판단'}
                            </TextBox>
                          ) : (
                            <TextBox variant="body8">{'-'}</TextBox>
                          )}
                        </>
                      )}
                    </OldTd>

                    <OldTd>{proposal.gradeByContributor ? proposal.gradeByContributor : '-'}</OldTd>
                    <OldTd>{proposal.grade ? proposal.grade : '-'}</OldTd>

                    <OldTd>
                      {[
                        ProposalStatus.STOP_VOTING,
                        ProposalStatus.VOTING,
                        ProposalStatus.END,
                      ].includes(proposal.status) && proposal.ratioVoted
                        ? proposal.ratioVoted
                        : '-'}
                    </OldTd>

                    <OldTd>
                      {proposal.result ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <InputAdornment position="start">
                            <TextBox variant="body11">￦</TextBox>
                          </InputAdornment>
                          {proposal.result.toLocaleString()}
                        </Box>
                      ) : (
                        '-'
                      )}
                    </OldTd>
                    <OldTd>
                      {[ProposalStatus.END].includes(proposal.status) ? (
                        <FixResultInput
                          readOnly={proposal.fixedAt !== null}
                          proposalId={proposal.id}
                          fixedResult={proposal.fixedResult}
                        />
                      ) : (
                        '-'
                      )}
                    </OldTd>
                    <OldTd>
                      {proposal.fixedAt === null && '-'}
                      {proposal.fixedAt !== null && (
                        <>
                          {dayjs(proposal.fixedAt).format(dateFormat)}(
                          {`${dayjs(proposal.voteEndAt).format('YYYY')}년 ${dayjs(
                            proposal.voteEndAt
                          ).format('MM')}월`}
                          )
                        </>
                      )}
                    </OldTd>
                    <OldTd>
                      {proposal.paymentDate ? dayjs(proposal.paymentDate).format(dateFormat) : '-'}
                    </OldTd>
                  </TableRow>
                ))}
          </>
        ) : (
          <TableRow>
            <OldTd colSpan={13}>
              <LoadingBar />
            </OldTd>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const StateTable = memo(ProposalStateTable);

export default StateTable;
