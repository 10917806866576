import { useMutation, useQueryClient } from 'react-query';
import { notificationApi } from '@front/src/features/notification/api/api';
import type {
  UserNotificationIsImportantParameter,
  UserNotificationIsReadParameter,
} from '@front/src/features/notification/types/parameter';

const notificationMutation = {
  useToggleRead: (notificationId: number, params: UserNotificationIsReadParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['notification', 'read'],
      mutationFn: () => notificationApi.toggleRead(notificationId, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['user-notification', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['user-notification', 'count'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['approval-box'],
        });
      },
    });

    return {
      mutate,
    };
  },

  useDelete: (notificationId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['notification', 'delete'],
      mutationFn: () => notificationApi.delete(notificationId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['user-notification', 'list'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['user-notification', 'count'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['approval-box'],
        });
      },
    });

    return {
      mutate,
    };
  },

  useToggleBookmark: (notificationId: number, params: UserNotificationIsImportantParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['notification', 'bookmark'],
      mutationFn: () => notificationApi.toggleBookMark(notificationId, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['user-notification', 'list'],
        });
      },
    });

    return {
      mutate,
    };
  },
};

export default notificationMutation;
