import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddPosition } from '@front/src/utils';
import { DeleteSweep } from '@mui/icons-material';

interface Props {
  onAdd: (position: AddPosition) => void;
  onDelete: () => void;
}

const useUIBuilderTableHeadLogic = ({ onAdd, onDelete }: Props) => {
  const itemList = [
    {
      text: '최상단에 추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        onAdd(AddPosition.TOP);
      },
      seq: 4,
    },
    {
      text: '최하단에 추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        onAdd(AddPosition.BOTTOM);
      },
      seq: 5,
      split: true,
    },
    {
      text: '전체 삭제',
      icon: DeleteSweep,
      action: () => {
        onDelete();
      },
      seq: 100,
      iconColor: '#e43333',
    },
  ];

  return {
    itemList,
  };
};

export default useUIBuilderTableHeadLogic;
