import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import axios from 'axios';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createPaymentHistoryDetail: (id: number) =>
    `${paymentHistoryApiUrl}/${id}/review-amount-payment-history`,
  getConsortiumCompany: (id: number) => `${paymentHistoryApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoPaymentHistoryCreateModalApi = {
  createPaymentHistoryDetail: async (params: ProjectReviewAmountPaymentHistoryParams, menuId) =>
    await axios.post(url.createPaymentHistoryDetail(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getConsortiumCompany: async (id: number, menuId) => {
    const { data } = await axios.get(url.getConsortiumCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
