import { affiliatedPersonDrawerRepository } from '@front/src/features/affiliated-person/features/drawer/repository/repository';
import { useEffect } from 'react';
import { useAffiliatedPersonDrawerState } from '@front/src/features/affiliated-person/features/drawer/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAffiliatedPersonDrawerListLogic };

const useLogic = (menuId) => {
  const { setKeyword } = useAffiliatedPersonDrawerState(
    useShallow((state) => ({
      setKeyword: state.setKeyword,
    }))
  );
  const { data: list, remove } = affiliatedPersonDrawerRepository.useListGet(menuId);
  useEffect(
    () => () => {
      remove();
      setKeyword('');
    },
    [remove, setKeyword]
  );
  return {
    list,
  };
};
