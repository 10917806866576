import React, { useEffect } from 'react';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import { FormProvider, type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import TextFieldBasic from '@front/src/components/components-with-design/component/TextFieldBasic';
import type { ManagementAccountCellInputValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useInputForm';
import useManagementAccountInputForm from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useInputForm';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import useManagementAccountInputMutation from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useInputMutation';
import { getManagementAccountParentId } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/utils';

interface Props extends SectionComponentProps {
  colIndex: number;
  rowIndex: number;
  showListFieldArray: UseFieldArrayReturn<ManagementAccountTableFormValues, 'showList', 'id'>;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
  mode: 'create' | 'update';
  cellData: ManagementAccountingTreeView;
  onTableBodyFocus: () => void;
}

export default function ManagementAccountTableCellInput(props: Props) {
  const {
    dataId,
    sectionId,
    menuId,
    colIndex,
    rowIndex,
    mode = 'create',
    cellData,
    onTableBodyFocus,
  } = props;

  const { getValues } = useFormContext<ManagementAccountTableFormValues>();
  const commonParams = {
    dataId,
    sectionId,
    menuId,
  };

  const { initialize, methods, onInvalid } = useManagementAccountInputForm({
    colIndex,
    rowIndex,
  });

  const { handleSubmit, register, setValue } = methods;

  const { onCreate, onChangeName } = useManagementAccountInputMutation({
    commonParams,
    onTableBodyFocus,
  });

  const onValid = (data: ManagementAccountCellInputValues) => {
    const params = {
      id: cellData?.id,
      name: data.name,
      sequence: data.sequence,
      parentId: data.parentId,
      depth: data.depth,
    };
    if (mode === 'create') {
      onCreate(params);
      return;
    }
    onChangeName(params);
  };

  const onSubmit = handleSubmit(onValid, onInvalid);

  const handleKeyDown = async (e) => {
    if (e.isComposing || e.keyCode === 229 || e.key !== 'Enter') return;

    const parentId = getManagementAccountParentId(
      getValues('showList'),
      getValues('selectedRowIndexList'),
      colIndex
    );

    setValue('name', e.target.value);
    setValue('parentId', parentId);

    await onSubmit();
  };

  useEffect(() => {
    initialize(mode, cellData);
  }, [mode, cellData]);

  return (
    <FormProvider {...methods}>
      <NDepthTableBoxCell>
        <TextFieldBasic
          {...register('name', {
            required: '값을 입력해주세요',
          })}
          // inputRef={(ref) => ref?.focus()}
          onKeyDown={handleKeyDown}
        />
      </NDepthTableBoxCell>
    </FormProvider>
  );
}
