import { create } from 'zustand';

interface State {
  idList: number[];
  setIdList: (idList: number[]) => void;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: () => void;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: () => void;
  detailId: number;
  setDetailId: (id: number) => void;
  isHover: boolean;
  setIsHover: () => void;
  readOnly?: boolean;
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useProjectSalesInfoBidInfoState };

const useState = create<State>((set) => ({
  idList: [],
  setIdList: (idList: number[]) => set(() => ({ idList })),
  isUpdateModalOpen: false,
  setIsUpdateModalOpen: () => set((state) => ({ isUpdateModalOpen: !state.isUpdateModalOpen })),
  detailId: 0,
  setDetailId: (detailId: number) => set(() => ({ detailId })),
  isCreateModalOpen: false,
  setIsCreateModalOpen: () => set((state) => ({ isCreateModalOpen: !state.isCreateModalOpen })),
  isHover: true,
  setIsHover: () => set((state) => ({ isHover: !state.isHover })),
  readOnly: false,
  setReadOnly: (readOnly) => set({ readOnly }),
}));
