import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import axios from 'axios';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPaymentHistoryDetail: (itemId: number) =>
    `${paymentHistoryApiUrl}/review-amount-payment-history/${itemId}`,
  updatePaymentHistoryDetail: (id: number) =>
    `${paymentHistoryApiUrl}/review-amount-payment-history/${id}`,
  getConsortiumCompany: (id: number) => `${paymentHistoryApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoPaymentHistoryUpdateModalApi = {
  getPaymentHistoryDetail: async (itemId: number, menuId) => {
    const { data } = await axios.get(url.getPaymentHistoryDetail(itemId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePaymentHistoryDetail: async (params: ProjectReviewAmountPaymentHistoryParams, menuId) =>
    await axios.put(url.updatePaymentHistoryDetail(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getConsortiumCompany: async (id: number, menuId) => {
    const { data } = await axios.get(url.getConsortiumCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
