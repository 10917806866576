import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';
import type { BoxProps } from '@mui/material';

export function NDepthTableContainerBox(props: BoxProps) {
  const { children, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        width: 'fit-content',
        borderRadius: '5px',
        border: `1px solid ${ColorPalette.line.line}`,
        overflowX: 'auto',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function NDepthTableBox(props: BoxProps) {
  const { children, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function NDepthTableBoxHead(props: BoxProps) {
  const { children, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        background: ColorPalette.background.bg03,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export const NDepthTableBoxBody = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { children, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        display: 'flex',
        background: ColorPalette.background.bg,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});

export function NDepthTableBoxRow(props: BoxProps) {
  const { children, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        '&:last-child': {
          borderBottom: `0px`,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

interface CellProps extends BoxProps {
  isHead?: boolean;
}

export function NDepthTableBoxCell(props: CellProps) {
  const { children, isHead = false, sx, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: isHead ? 'center' : 'flex-start',
        alignItems: 'center',
        width: '8dvw',
        minWidth: '180px',
        height: '3dvh',
        minHeight: '36px',
        fontSize: '1.3rem',
        lineHeight: '1.5rem',
        fontWeight: isHead ? '600' : '400',
        verticalAlign: 'middle',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        background: isHead ? ColorPalette.background.bg02 : 'transparent',
        borderRight: `1px solid ${ColorPalette.line.line}`,
        borderBottom: `1px solid ${ColorPalette.line.line}`,
        padding: '0 4px',
        '&:last-child': {
          borderBottom: `0px`,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
