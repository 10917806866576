import { useMutation, useQueryClient } from 'react-query';
import type {
  WorkReferenceFileUpdateParameter,
  WorkReferenceUpdateParameter,
} from '@front/src/features/work/features/work/features/reference/types/parameter';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { convertEmptyToNull } from '@front/src/utils';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workReferenceMutation = {
  useAddTop: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'add-top'],
      mutationFn: () => workWorkApi.createApprovalReferenceTop(commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddBottom: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'add-bottom'],
      mutationFn: () => workWorkApi.createApprovalReferenceBottom(commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'create'],
      mutationFn: (id: number) => workWorkApi.createReference(id, commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdateFile: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkReferenceFileUpdateParameter>({
      mutationKey: ['work', 'reference', 'update-file'],
      mutationFn: (params) => workWorkApi.updateReferenceFile(params, commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkReferenceUpdateParameter>({
      mutationKey: ['work', 'reference', 'update'],
      mutationFn: (params) => workWorkApi.updateReference(params, commonParams),
      onMutate: async (data) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'reference']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.content === prevData?.content;
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqUp: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'seq-up'],
      mutationFn: (id: number) => workWorkApi.updateReferenceSeqUp(id, commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqDown: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'seq-down'],
      mutationFn: (id: number) => workWorkApi.updateReferenceSeqDown(id, commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'delete'],
      mutationFn: (id: number) => workWorkApi.deleteReference(id, commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDeleteAll: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationKey: ['work', 'reference', 'delete-all'],
      mutationFn: () => workWorkApi.deleteAllReference(commonParams),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'reference'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workReferenceMutation;
