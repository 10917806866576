import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoEstimationEstimationCreateModalMutation } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/query/mutation';

export { repository as projectSalesInfoEstimationEstimationCreateModalRepository };

const repository = {
  useCreate: (menuId) => {
    const { mutate } = projectSalesInfoEstimationEstimationCreateModalMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
