import React, { Suspense } from 'react';
import { AccountingUploadSalesInfoGetYearProvider } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/provider';
import { AccountingUploadSalesInfoGetYear } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/components';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingUploadSalesInfoGetYearWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense
    fallback={
      <LoadingSpinner
        width="100%"
        height="60dvh"
      />
    }
  >
    <AccountingUploadSalesInfoGetYearProvider menuId={menuId}>
      <AccountingUploadSalesInfoGetYear />
    </AccountingUploadSalesInfoGetYearProvider>
  </Suspense>
);
