import React, { useCallback, useContext } from 'react';
import { Th } from '@front/src/components/layout/table/th';
import { Table } from '@front/src/components/layout/table/table';
import { Box, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AffiliatedCompanyPersonDetailModalOutlineBasicContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/widgets/context';
import { getIsKeyPerson } from '@front/src/features/affiliated-company/utils';
import { InputType, PersonStatus } from '@front/src/features/affiliated-company/types/domain';
import {
  affiliatedCompanyPersonHomeTownOptionList,
  booleanOptionList,
  personStatusRadioList,
  sexOptionList,
} from '@front/src/features/affiliated-company/utils/constant';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormRadio } from '@front/src/components/radio-with-hook-form/radio-with-hook-form';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { convertEmptyToNull } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/parameter';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalOutlineState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/section/outline/useState';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import dayjs from "dayjs";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { Basic as AffiliatedCompanyPersonDetailModalOutlineBasic };

const Basic = () => {
  const {
    d: { personOutlineBasic },
    h: { onPersonOutlineBasicUpdate },
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineBasicContext);
  const methods = useForm({
    values: {
      name: personOutlineBasic?.name ?? '',
      position: personOutlineBasic?.position ?? '',
      department: personOutlineBasic?.department ?? '',
      birthYear: personOutlineBasic?.birthYear ? String(personOutlineBasic?.birthYear) : '',
      birthDate: personOutlineBasic?.birthDate ?? '',
      age: personOutlineBasic?.age ?? '',
      sex: personOutlineBasic?.sex ?? '',
      isKeyPerson: getIsKeyPerson(personOutlineBasic?.isKeyPerson),
      phoneNumber: personOutlineBasic?.phoneNumber ?? '',
      email: personOutlineBasic?.email ?? '',
      certificate1: personOutlineBasic?.certificate1 ?? '',
      certificate2: personOutlineBasic?.certificate2 ?? '',
      status: personOutlineBasic?.status ?? PersonStatus.EMPLOYED,
      homeTown: personOutlineBasic?.homeTown ?? '',
      homeTownDetail: personOutlineBasic?.homeTownDetail ?? '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onPersonOutlineBasicUpdate({
      ...convertEmptyToNull({
        ...data,
      }),
      birthYear: data.birthYear === '' ? null : dayjs(data.birthYear).year(),
      birthDate: formatDateOrNull(data.birthDate),
      isKeyPerson: data.isKeyPerson === '' ? null : data.isKeyPerson === 'O',
    } as AffiliatedCompanyPersonOutlineBasicUpdateParameter);
  });
  const renderRow = useCallback(
    (label, component) => (
      <TableRow>
        <Th>{label}</Th>
        <TableCell>{component}</TableCell>
      </TableRow>
    ),
    []
  );
  const { readOnly } = useAffiliatedCompanyPersonDetailModalOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table key={Math.random()}>
          <TableBody>
            {renderRow(
              '이름',
              <HookFormInput
                name="name"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '직급',
              <HookFormInput
                name="position"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '소속',
              <HookFormInput
                name="department"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '상태',
              <HookFormRadio
                name="status"
                onSubmit={onSubmit}
                direction="row"
                radioList={personStatusRadioList}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '출생연도',
              <DatePickerWithHookForm
                openTo="year"
                format="YYYY"
                // inputFormat="YYYY"
                views={['year']}
                // width="88px"
                name="birthYear"
                // regexp={YEAR_REGEX}
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '생일',
              <DatePickerWithHookForm
                openTo="day"
                name="birthDate"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '나이',
              <HookFormInput
                width="48px"
                name="age"
                onSubmit={onSubmit}
                type="number"
                disabled={readOnly}
              />
            )}
            {renderRow(
              '성별',
              <HookFormSelect
                width="100px"
                name="sex"
                optionList={sexOptionList}
                defaultLabel="선택"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '연고지',
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                }}
              >
                <HookFormSelect
                  isDefaultPossible
                  width="100px"
                  name="homeTown"
                  optionList={affiliatedCompanyPersonHomeTownOptionList}
                  defaultLabel="선택"
                  onSubmit={onSubmit}
                  disabled={readOnly}
                />
                <HookFormInput
                  placeholder="상세"
                  name="homeTownDetail"
                  onSubmit={onSubmit}
                  disabled={readOnly}
                />
              </Box>
            )}
            {renderRow(
              '주요인물',
              <HookFormSelect
                isDefaultPossible
                width="100px"
                name="isKeyPerson"
                optionList={booleanOptionList}
                defaultLabel="선택"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '핸드폰번호',
              <HookFormInput
                name="phoneNumber"
                onSubmit={onSubmit}
                inputType={InputType.MOBILE}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '개인이메일',
              <HookFormInput
                name="email"
                onSubmit={onSubmit}
                inputType={InputType.EMAIL}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '자격증1',
              <HookFormInput
                name="certificate1"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
            {renderRow(
              '자격증2',
              <HookFormInput
                name="certificate2"
                onSubmit={onSubmit}
                disabled={readOnly}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};
