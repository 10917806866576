import React, { useContext } from 'react';
import { ProjectSalesInfoCMPaymentHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/context';
import { ProjectSalesInfoCmPaymentHistoryTableRow } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/components/CM-payment-history-table-row';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { CMPaymentHistoryTableList as ProjectSalesInfoCMPaymentHistoryTableList };

const CMPaymentHistoryTableList = () => {
  const {
    d: { CMPaymentHistoryList },
    isLoading,
  } = useContext(ProjectSalesInfoCMPaymentHistoryContext);
  if (typeof CMPaymentHistoryList === 'undefined' || isLoading)
    return <TableLoadingSpinner colSpan={9} />;
  if (CMPaymentHistoryList?.length === 0) return <NoResult colSpan={9} />;
  return (
    <>
      {CMPaymentHistoryList.map((item, index) => (
        <ProjectSalesInfoCmPaymentHistoryTableRow
          item={item}
          index={index}
          key={item.id}
        />
      ))}
    </>
  );
};
