import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import type {
  UIBuilderTableFieldValues,
  UIBuilderTableFilterBundle,
} from '@front/src/components/ui-builder/table/hooks/useForm';

const useUIBuilderFilterForm = ({ bundleIndex, onFilterChange }) => {
  const { getValues: getUIBuilderTableFieldValues } = useFormContext<UIBuilderTableFieldValues>();
  const methods = useForm<FilterFormValues>({
    defaultValues: {
      keyword: '',
      filterBundle: undefined,
    },
  });

  const { getValues, handleSubmit, control } = methods;

  const { fields, replace } = useFieldArray({
    control,
    name: `filterBundle.filter`,
  });

  const handleKeyDown = (e) => {
    if (e.isComposing || e.keyCode === 229 || e.key !== 'Enter') return;
    const keyword = getValues('keyword');

    const newFields = fields.map((f) => ({
      ...f,
      shown: keyword ? f.value.toString().includes(keyword) : true,
    }));
    replace(newFields);
  };

  const onSubmit = handleSubmit((data) => {
    if (!data.filterBundle) return;

    const list = data.filterBundle.filter;
    const active = list.filter((n) => n.checked).length !== fields.length;
    onFilterChange(list, active);
  });

  useEffect(() => {
    replace(getUIBuilderTableFieldValues(`filterBundle.${bundleIndex}.filter`));
  }, [bundleIndex]);

  return {
    methods,
    onSubmit,
    onKeyDown: handleKeyDown,
    fields,
  };
};

export default useUIBuilderFilterForm;

export interface FilterFormValues {
  keyword: string;
  filterBundle: UIBuilderTableFilterBundle | undefined;
}
