import { projectSalesInfoSubjectReviewModalRepository } from '@front/src/features/project-sales-info/features/subject-review/features/modal/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { useLogic as useProjectSalesInfoSubjectReviewModalSpecialWindLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detail, isLoading } = projectSalesInfoSubjectReviewModalRepository.useDetailGet(
    id,
    menuId
  );
  const { run: onUpdate } = projectSalesInfoSubjectReviewModalRepository.useUpdate(menuId);
  return {
    detail,
    onUpdate,
    isLoading,
  };
};
