import { useQuery } from 'react-query';
import { approvalDocumentApi } from '@front/src/features/drawer-approval-document/api/api';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import type { CommentFilterParams } from '@front/src/features/note/types/parameter';

export const approvalDocumentQuery = {
  useGetApprovalDocument: (
    documentId: number,
    params: ApprovalDocumentDetailQueryParams,
    isTemp?: boolean
  ) => {
    const { data } = useQuery({
      queryKey: ['approval-document', documentId, params, isTemp],
      queryFn: () => approvalDocumentApi.getApprovalDocument(documentId, params),
      suspense: true,
      enabled: !!documentId && isTemp,
    });
    return { data };
  },

  useGetAllFiles: (params: { fileItemIdList: number[] }) => {
    const { data } = useQuery({
      queryKey: ['approval-document', 'all-files'],
      queryFn: () => approvalDocumentApi.getAllFiles(params),
      suspense: true,
    });
    return { data };
  },

  useGetCommentList: (params: CommentFilterParams) => {
    const { data } = useQuery({
      queryKey: ['approval-document', 'comment', params],
      queryFn: () => approvalDocumentApi.getCommentList(params),
      suspense: true,
    });

    return { data };
  },
};
