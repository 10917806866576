import type { ReactNode } from 'react';
import React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

export { TableHeader as AccountingSettingsManagementAccountingTableLayoutTableHeader };

interface Props {
  sx?: SxProps<Theme>;
  children: ReactNode;
}

const TableHeader = ({ sx, children }: Props) => (
  <Box
    sx={{
      display: 'flex',
      position: 'sticky',
      top: 0,
      ...sx,
    }}
  >
    {children}
  </Box>
);
