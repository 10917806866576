import { affiliatedCompanyRepresentativeContractInformationRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/repository/repository';

export { useLogic as useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateLogic };

const useLogic = (menuId) => {
  const { run: onUpdate } =
    affiliatedCompanyRepresentativeContractInformationRepository.useCompanyUpdate(menuId);
  return {
    onUpdate,
  };
};
