import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';
import { createContext } from 'react';

interface State {
  d: {
    affiliatedPersonList?: ProjectAffiliatedCompanyPersonView[];
  };
}

export { Context as ProjectSalesInfoCollectionManagementModalCreateModalContext };

const Context = createContext<State>({
  d: {},
});
