import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyPersonHobbyRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/repository/repository';
import { useAffiliatedCompanyDetailModalCharacterHobbyState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyDetailModalCharacterHobbyLogic };

interface Props {
  id?: number;
  menuId?: number;
}

const useLogic = ({ id, menuId }: Props) => {
  const { idList, setIdList } = useAffiliatedCompanyDetailModalCharacterHobbyState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: hobbyList, isLoading } =
    affiliatedCompanyPersonHobbyRepository.usePersonCharacterHobbyGet(id ?? modalId, menuId);
  const { run: onCreateHobby } =
    affiliatedCompanyPersonHobbyRepository.usePersonCharacterHobbyCreate(menuId);
  const { run: onDeleteHobby } =
    affiliatedCompanyPersonHobbyRepository.usePersonCharacterHobbyDelete(menuId);
  const { run: onUpdateHobby } =
    affiliatedCompanyPersonHobbyRepository.usePersonCharacterHobbyUpdate(menuId);
  const handleHobbyCreate = useCallback(() => {
    onCreateHobby(modalId);
  }, [onCreateHobby, modalId]);
  const d = { hobbyList };
  const h = {
    onCreateHobby: handleHobbyCreate,
    setIdList,
    onDeleteHobby,
    onUpdateHobby,
  };
  return { d, h, isLoading, idList };
};
