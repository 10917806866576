import React, { Suspense } from 'react';
import { AccountingSettingsManagementAccountingAccountTableCategoryListProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/provider';
import { AccountingSettingsManagementAccountingAccountTableCategoryList } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/category-list';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingSettingsManagementAccountingAccountContextMenu } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/context-menu';
import { AccountingSettingsManagementAccountingAccountAttributeModal } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/attribute-modal';
import { AccountingSettingsManagementAccountingAccountContextMenuWithAttribute } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/context-menu-with-attribute';

export { Widget as AccountingSettingsManagementAccountingAccountTableCategoryListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense
    fallback={
      <LoadingSpinner
        width="1000px"
        height="800px"
      />
    }
  >
    <AccountingSettingsManagementAccountingAccountTableCategoryListProvider menuId={menuId}>
      <AccountingSettingsManagementAccountingAccountAttributeModal />
      <AccountingSettingsManagementAccountingAccountContextMenu />
      <AccountingSettingsManagementAccountingAccountContextMenuWithAttribute />
      <AccountingSettingsManagementAccountingAccountTableCategoryList />
    </AccountingSettingsManagementAccountingAccountTableCategoryListProvider>
  </Suspense>
);
