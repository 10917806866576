import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker } from '@mui/x-date-pickers';
import FormHelperText from '@mui/material/FormHelperText';
import { ColorPalette } from '@front/assets/theme';
import { YY_MM_DD } from '@front/src/utils';

interface Props extends DatePickerProps<any, any> {
  error?: boolean;
}

export default function DatePickerUI({ disabled, error, ...rest }: Readonly<Props>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format={YY_MM_DD}
        showDaysOutsideCurrentMonth={true}
        slotProps={{
          textField: {
            sx: {
              '& .MuiOutlinedInput-root': {
                height: '32px',
                background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
                borderRadius: '5px',
                padding: '6px 10px',
              },

              '& .MuiOutlinedInput-input': {
                fontSize: '1.4rem',
                fontWeight: '400',
                lineHeight: 'normal',
                color: ColorPalette.greyscale.text_primary,
                padding: '0px',
              },

              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${ColorPalette.line.line02}`,
              },

              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: disabled
                  ? `1px solid ${ColorPalette.line.line02}`
                  : `1px solid ${ColorPalette.main.main_hover}`,
              },

              '&:active .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: disabled
                  ? `1px solid ${ColorPalette.line.line02}`
                  : `1px solid ${ColorPalette.main.main_hover}`,
              },

              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: disabled
                  ? `1px solid ${ColorPalette.line.line02}`
                  : `1px solid ${ColorPalette.main.main_hover}`,
                '&:focus-visible': {
                  border: `none`,
                  outline: `2px solid ${ColorPalette.sub.sub_primary}`,
                  outlineOffset: '-2px',
                },
              },

              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${ColorPalette.line.line02}`,
              },

              '&::placeholder': {
                color: ColorPalette.greyscale.text_quaternary,
              },

              '& .MuiInputAdornment-root': {
                width: '18px',
                height: '18px',
                marginLeft: '0',
              },

              '& .MuiIconButton-root': {
                padding: '0px',
              },

              '& .MuiSvgIcon-root': {
                fontSize: '1.8rem',
                color: ColorPalette.main.main_primary,
                padding: '0',
              },

              '& .MuiFormHelperText-root': {
                margin: '0',
              },
            },
            helperText: error ? (
              <FormHelperText
                sx={{
                  fontSize: '1.2rem',
                  color: ColorPalette.sub2.sub2_primary,
                  margin: '2px 0 0 0',
                }}
                id="component-error-text"
              >
                Error
              </FormHelperText>
            ) : null,
            error,
          },

          popper: {
            sx: {
              '& .MuiPaper-root': {
                borderRadius: '5px',
                boxShadow: `0px 2px 6px 0px rgba(0, 0, 0, 0.25)`,
              },

              '& .MuiDateCalendar-root': {
                width: '300px',
                height: '290px',
              },

              //Calendar Header
              '& .MuiPickersCalendarHeader-label': {
                fontSize: '1.6rem',
                fontWeight: '600',
                color: ColorPalette.greyscale.text_primary,
              },

              '& .MuiButtonBase-root': {
                padding: '0px',

                '& .MuiSvgIcon-root': {
                  width: '1.8em',
                  height: '1.8em',
                  color: ColorPalette.background.bg07,
                },
              },

              //Calendar Day
              '& .MuiDayCalendar-weekDayLabel': {
                width: '34px',
                height: '34px',
                fontSize: '1.4rem',
                fontWeight: '600',
                color: ColorPalette.greyscale.text_secondary,

                '&:first-of-type': {
                  color: ColorPalette.sub2.sub2_primary,
                },

                '&:last-child': {
                  color: ColorPalette.main.main_primary,
                },
              },

              '& .MuiPickersSlideTransition-root': {
                minHeight: '182px',
              },

              '& .MuiPickersDay-root': {
                width: '34px',
                height: '34px',
                fontSize: '1.4rem',
                lineHeight: '30px',
                color: ColorPalette.greyscale.text_primary,

                '&:first-of-type': {
                  color: ColorPalette.sub2.sub2_primary,
                },

                '&:last-child': {
                  color: ColorPalette.main.main_primary,
                },

                '&.Mui-selected:not': {
                  border: `1px solid ${ColorPalette.main.main_primary}`,
                },

                '&.Mui-selected': {
                  fontWeight: '600',
                  color: ColorPalette.greyscale.white,
                  background: ColorPalette.main.main_primary,

                  '&:hover': {
                    background: ColorPalette.main.main_hover,
                  },
                  '&:active': {
                    background: ColorPalette.main.main_hover,
                  },
                  '&:focus-visible': {
                    background: ColorPalette.main.main_primary,
                    outline: `2px solid ${ColorPalette.sub.sub_primary}`,
                    outlineOffset: '-2px',
                  },
                  '&:disabled': {
                    background: ColorPalette.greyscale.disabled,
                  },
                },
              },

              '& .css-ahqmp2-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                border: `1px solid ${ColorPalette.main.main_primary}`,

                '&:focus-visible': {
                  background: 'transparent',
                  border: 'none',
                  outline: `2px solid ${ColorPalette.sub.sub_primary}`,
                  outlineOffset: '-2px',
                },
              },

              //Calendar Year
              '& .MuiYearCalendar-root': {
                width: '300px',
              },

              '& .MuiPickersYear-yearButton': {
                width: '64px',
                height: '34px',
                fontSize: '1.4rem',
                lineHeight: '30px',
                color: ColorPalette.greyscale.text_primary,

                '&.Mui-selected': {
                  fontWeight: '600',
                  color: ColorPalette.greyscale.white,
                  background: ColorPalette.main.main_primary,

                  '&:focus-visible': {
                    background: ColorPalette.main.main_primary,
                    outline: `2px solid ${ColorPalette.sub.sub_primary}`,
                    outlineOffset: '-2px',
                  },
                },
              },

              //Calendar Month
              '& .MuiMonthCalendar-root': {
                width: '300px',
              },

              '& .MuiPickersMonth-monthButton': {
                width: '64px',
                height: '34px',
                fontSize: '1.4rem',
                lineHeight: '30px',
                color: ColorPalette.greyscale.text_primary,
                margin: '10px 0',

                '&.Mui-selected': {
                  fontWeight: '600',
                  color: ColorPalette.greyscale.white,
                  background: ColorPalette.main.main_primary,

                  '&:focus-visible': {
                    background: ColorPalette.main.main_primary,
                    outline: `2px solid ${ColorPalette.sub.sub_primary}`,
                    outlineOffset: '-2px',
                  },
                },
              },

              '& .MuiPickersDay-dayOutsideMonth': {
                color: `${ColorPalette.greyscale.text_quaternary} !important`,
              },
            },
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
