import React, { useMemo } from 'react';
import { MobileNotificationHeaderContext } from '@front/src/features/mobile-notification/widgets/header/provider/context';
import { useMobileNotificationHeaderLogic } from '@front/src/features/mobile-notification/widgets/header/provider/useLogic';

export { Provider as MobileNotificationHeaderProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onDeleteAll, onToggleAll } = useMobileNotificationHeaderLogic();
  const value = useMemo(
    () => ({
      onToggleAll,
      onDeleteAll,
    }),
    [onDeleteAll, onToggleAll]
  );
  return (
    <MobileNotificationHeaderContext.Provider value={value}>
      {children}
    </MobileNotificationHeaderContext.Provider>
  );
};
