import React from 'react';
import { AffiliatedPersonAppBar } from '@front/src/features/affiliated-person/features/app-bar/components/app-bar';
import { AffiliatedPersonCreateModal } from '@front/src/features/affiliated-person/features/app-bar/components/create-modal';
import { AffiliatedPersonCreateWidget } from '@front/src/features/affiliated-person/features/app-bar/widgets/create/widget';
import { AffiliatedPersonConfirmModal } from '@front/src/features/affiliated-person/features/app-bar/components/confirm-modal';
import { AffiliatedPersonCreateModalTable } from '@front/src/features/affiliated-person/features/app-bar/components/create-modal-table';
import { AffiliatedPersonAppBarPersonListWidget } from '@front/src/features/affiliated-person/features/app-bar/widgets/person-list/widget';
import { AffiliatedPersonCreateModalForm } from '@front/src/features/affiliated-person/features/app-bar/components/create-modal-form';

export { Index as AffiliatedPersonAppBarFeature };
interface Props {
  menuId?: number;
}
const Index = ({ menuId }: Props) => (
  <>
    <AffiliatedPersonCreateModal>
      <AffiliatedPersonCreateModalForm menuId={menuId} />
      <AffiliatedPersonCreateModalTable
        widget={<AffiliatedPersonAppBarPersonListWidget menuId={menuId} />}
      />
    </AffiliatedPersonCreateModal>
    <AffiliatedPersonConfirmModal widget={<AffiliatedPersonCreateWidget menuId={menuId} />} />
    <AffiliatedPersonAppBar menuId={menuId} />
  </>
);
