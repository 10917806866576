import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/types/view';

export const estimationURLAPI = '/api/project';
export const convertToEstimationOptionList = (data?: ProjectEstimationView[]) => {
  if (!data) return [];
  const newData = data.filter((item) => item.isConfirmed);
  return newData.map((item) => ({
    label: item.estimationCode,
    value: String(item.id),
  }));
};

export const YNOptionList = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];
