import React, { useCallback, useEffect, useMemo } from 'react';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/components/tab.module.scss';
import { personalSettingsModalTab } from '@front/src/features/personal-settings-modal/features/auth-settings/utils/contstants';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { getRefinedTab } from '@front/src/features/personal-settings-modal/features/auth-settings/utils';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';

export { Tab as PersonalSettingsModalTab };

const Tab = () => {
  const { tab, setTab } = usePersonalSettingsModalState(
    useShallow((state) => ({
      tab: state.tab,
      setTab: state.setTab,
    }))
  );
  const { isDirty, setOpen, reset, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      isDirty: state.isDirty,
      setOpen: state.setOpen,
      reset: state.reset,
      setTitle: state.setTitle,
    }))
  );
  const handleClick = useCallback(
    (target) => {
      if (isDirty) {
        setOpen(true, () => {
          setTab(target);
          reset();
        });
        setTitle('탭 이동 확인');
        return;
      }
      setTab(target);
    },
    [isDirty, setOpen, reset]
  );
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const tabList = useMemo(() => {
    if (!loginUser) return personalSettingsModalTab;
    return getRefinedTab(personalSettingsModalTab, loginUser.role);
  }, [getRefinedTab, personalSettingsModalTab, loginUser]);
  useEffect(() => {
    if (tabList.length !== 1) return;
    setTab(tabList[0]);
  }, [setTab, tabList]);
  if (!loginUser) return null;
  if (!loginUser.role) return null;
  return (
    <div className={classes.tab__list}>
      {getRefinedTab(personalSettingsModalTab, loginUser.role).map((item) => (
        <div
          key={item}
          onClick={() => handleClick(item)}
          className={`${classes.tab} ${tab === item && classes.selected}`}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
