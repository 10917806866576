import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import estimationComparisonMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/query/mutation';
import { AddPosition } from '@front/src/utils';

export { TableHead as ContractFinalTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const onAdd = estimationComparisonMutation.useAdd({
    dataId: props.dataId,
    menuId: props.menuId,
    sectionId: props.sectionId,
  });

  const itemList = [
    {
      text: '최상단에 추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        onAdd({ position: AddPosition.TOP });
      },
      seq: 4,
    },
    {
      text: '최하단에 추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        onAdd({ position: AddPosition.BOTTOM });
      },
      seq: 5,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      isSingleRow
      controlPanelHeaderItemList={itemList}
    />
  );
}
