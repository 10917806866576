import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { AffiliatedCompanySalesActivityTableBody } from '@front/src/features/affiliated-company/features/sales-activity/widgets/list/components/table-body';
import { AffiliatedCompanySalesActivityListProvider } from '@front/src/features/affiliated-company/features/sales-activity/widgets/list/provider/proivder';

export { Widget as AffiliatedCompanySalesActivityListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<TableLoadingSpinner />}>
    <AffiliatedCompanySalesActivityListProvider menuId={menuId}>
      <AffiliatedCompanySalesActivityTableBody />
    </AffiliatedCompanySalesActivityListProvider>
  </Suspense>
);
