import React, { useCallback, useContext } from 'react';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { Box } from '@mui/material';
import { useAccountingSettingsManagementAccountingAccountCategoryClick } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/hooks/useCategoryClick';
import { AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/category-list-create-input';
import { AccountingSettingsManagementAccountingAccountTableBodyCellItem } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/item';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { shallow } from 'zustand/shallow';
import { AccountingSettingsManagementAccountingAccountTableCategoryListUpdateInput } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/category-list-update-input';
import { accountingSettingsManagementAccountingAccountTableLayout } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table';

export { CategoryMiddleAndSmallList as AccountingSettingsManagementAccountingAccountTableCategoryMiddleAndSmallList };

const CategoryMiddleAndSmallList = () => {
  const { TableBodyCell } = accountingSettingsManagementAccountingAccountTableLayout;
  const { list, tableRowNum } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const { parentIdList, updateId } = useAccountingSettingsManagementAccountingAccountTableState(
    (state) => ({
      parentIdList: state.parentIdList,
      updateId: state.updateId,
    }),
    shallow
  );
  const { handleCategoryClick } = useAccountingSettingsManagementAccountingAccountCategoryClick();
  const renderList = useCallback(
    (depth) => {
      const targetList = list?.filter((t) => t.depth === depth);
      const lastItem = targetList?.at(-1);
      return new Array(tableRowNum).fill(0).map((_, i) => {
        const item = targetList?.[i];
        if (targetList?.length === 0 && i === 0 && parentIdList?.[depth - 1]) {
          return (
            <TableBodyCell
              key={`category-${depth}-${i}`}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput
                depth={depth}
                parentId={parentIdList[depth - 1]}
              />
            </TableBodyCell>
          );
        }
        if (lastItem && lastItem.sequence + 1 === i) {
          return (
            <TableBodyCell
              key={`category-${depth}-${i}`}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput
                depth={depth}
                parentId={parentIdList[depth - 1]}
              />
            </TableBodyCell>
          );
        }
        if (!item) {
          return <TableBodyCell key={`category-${depth}-${i}`} />;
        }
        if (updateId === item.id) {
          return (
            <TableBodyCell
              key={item.id}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableCategoryListUpdateInput
                item={item}
              />
            </TableBodyCell>
          );
        }
        return (
          <AccountingSettingsManagementAccountingAccountTableBodyCellItem
            key={`category-${depth}-${i}`}
            item={item}
            handleCategoryClick={handleCategoryClick}
          />
        );
      });
    },
    [handleCategoryClick, updateId, parentIdList, tableRowNum, list, TableBodyCell]
  );
  return (
    <>
      <Box>{renderList(1)}</Box>
      <Box>{renderList(2)}</Box>
      <Box>{renderList(3)}</Box>
    </>
  );
};
