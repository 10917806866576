import type { ReactNode } from 'react';
import React, { Suspense } from 'react';
import { AffiliatedPersonMemoListProvider } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/provider';
import { AffiliatedPersonMemoList } from '@front/src/features/affiliated-person/features/memo/widgets/list/components/list';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AffiliatedPersonMemoListWidget };

interface Props {
  updateWidget: ReactNode;
  deleteWidget: ReactNode;
}

const Widget = ({ ...rest }: Props) => (
  <Suspense
    fallback={
      <LoadingSpinner
        width="100%"
        height="100px"
      />
    }
  >
    <AffiliatedPersonMemoListProvider {...rest}>
      <AffiliatedPersonMemoList />
    </AffiliatedPersonMemoListProvider>
  </Suspense>
);
