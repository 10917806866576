import React, { memo, useCallback } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { getValueIfExist } from '@front/src/utils';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  idList: number[];
  setIdList: (list: number[]) => void;
}

export { FormProjectBody as AffiliatedCompanyMeetingHistoryModalFormProjectBody };

const FormProjectBody = ({ idList, setIdList }: Props) => {
  const { control } = useFormContext();
  const projectList = useWatch({ name: 'projectList', control });
  const handleCheckboxChange = useCallback(
    (id) => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  if (projectList.length === 0) {
    return <NoResult />;
  }
  return (
    <>
      {projectList.map((item, index) => (
        <TableRow key={item.id}>
          <OldTd>
            <Checkbox
              checked={idList.includes(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
              disabled={readOnly}
            />
          </OldTd>
          <OldTd>{index + 1}</OldTd>
          <OldTd>{getValueIfExist(item.code)}</OldTd>
          <OldTd>{getValueIfExist(item.nickName)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={4}>프로젝트가 없습니다.</OldTd>
  </TableRow>
));

NoResult.displayName = 'AffiliatedCompanyMeetingHistoryModalFormProjectBodyNoResult';
