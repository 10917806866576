import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import type { RootState } from '@front/services/reducer';
import CircularProgress from '@front/components/CircularProgress';
import { fixedMemoFormAndFilterHeight } from '@front/util/ConstantCommonVariable';
import { OstRewardManagementMemoListItemService } from '@front/ost_reward/service/memo/list-item';

export { List as OstRewardManagementMemoList };
const List = () => {
  const [memoList] = useSelector(
    (root: RootState) => [root.ostRewardMangement.memoList],
    shallowEqual
  );
  const { formHeight, isMemoLoading } = useSelector(
    (root: RootState) => ({
      formHeight: root.ostRewardMangement.formHeight,
      isMemoLoading: root.ostRewardMangement.isMemoLoading,
    }),
    shallowEqual
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: `calc(100dvh - ${fixedMemoFormAndFilterHeight + (formHeight ? formHeight : 0)}px)`,
        flexWrap: 'nowrap',
        flexDirection: 'column',
        overflowY: 'hidden',
        alignItems: 'flex-start',
        padding: '0 0 15px 0',
      }}
    >
      <Box
        className="scroll-bar-holder"
        sx={{
          width: '100%',
          height: `100%`,
          overflowY: 'auto',
          padding: '0 10px 0px 10px',
        }}
      >
        {memoList.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              borderRadius: '5px',
              marginBottom: '10px',
              backgroundColor: ColorPalette._ffffff,
              padding: '15px',
              justifyContent: 'center',
            }}
          >
            <TextBox
              variant="body2"
              sx={{ minHeight: '30px', lineHeight: '30px' }}
            >
              {isMemoLoading && <CircularProgress size={30} />}
              {!isMemoLoading && <>해당하는 메모가 없습니다.</>}
            </TextBox>
          </Box>
        )}

        {memoList.length > 0 &&
          memoList.map((memo) => (
            <OstRewardManagementMemoListItemService
              key={memo.id}
              memo={memo}
            />
          ))}
      </Box>
    </Box>
  );
};
