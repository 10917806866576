import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingUploadSalesInfoContext } from '@front/src/features/accounting/features/upload/features/sales-info/provider/context';

export { Provider as AccountingUploadSalesInfoProvider };

interface Props {
  children: ReactNode;
  readOnly: boolean;
}

const Provider = ({ children, readOnly }: Props) => {
  const value = useMemo(
    () => ({
      readOnly,
    }),
    [readOnly]
  );
  return (
    <AccountingUploadSalesInfoContext.Provider value={value}>
      {children}
    </AccountingUploadSalesInfoContext.Provider>
  );
};
