import { create } from 'zustand';
import type { UserNotificationSearchParams } from '@front/src/features/mobile-notification/types/parameter';

interface State {
  isAllRead: boolean;
  setIsAllRead: (isAllRead: boolean) => void;
  openedIdList: number[];
  addOpenedIdList: (newId: number) => void;
  deleteOpenedIdList: (id: number) => void;
  parameter: UserNotificationSearchParams;
  setParameter: (parameter: UserNotificationSearchParams) => void;
  prevMenuId: string;
  setPrevMenuId: (menuId: string) => void;
}

export { useState as useMobileNotificationState };

const useState = create<State>((set) => ({
  isAllRead: false,
  setIsAllRead: (isAllRead) => set(() => ({ isAllRead })),
  openedIdList: [],
  addOpenedIdList: (newId) =>
    set(({ openedIdList }) => ({ openedIdList: [...openedIdList, newId] })),
  deleteOpenedIdList: (id) =>
    set(({ openedIdList }) => ({ openedIdList: openedIdList.filter((i) => i !== id) })),
  parameter: {
    type: '',
    status: '',
  },
  setParameter: (parameter) => set(() => ({ parameter })),
  prevMenuId: sessionStorage.getItem('menuId') ?? '',
  setPrevMenuId: (prevMenuId: string) =>
    set(() => ({
      prevMenuId,
    })),
}));
