import React from 'react';
import WorkApprovalReadingFeature from '@front/src/features/work/features/work/features/approval/features/reading';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface Props extends Pick<CellComponentProps, 'dataId' | 'menuId' | 'sectionId'> {
  workId: number;
  onClose: () => void;
}

export default function WorkApprovalModal(props: Readonly<Props>) {
  const { workId, onClose } = props;
  return (
    <ModalUI
      open={!!workId}
      onClose={onClose}
      title="상신 시점 업무 열람"
    >
      <WorkApprovalReadingFeature
        {...props}
        onClose={onClose}
      />
    </ModalUI>
  );
}
