import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import classes from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/components/modal-button-group.module.scss';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { ProjectSalesInfoContractReviewHistoryModalContext } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/context';
import { useProjectSalesInfoContractReviewHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/widgets/useState';
import { convertContractReviewHistoryDataForParameter } from '@front/src/features/project-sales-info/features/contract/features/review-history/features/modal/utils';
import { useProjectSalesInfoContractReviewHistoryState } from '@front/src/features/project-sales-info/features/contract/features/review-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ModalButtonGroup as ProjectSalesInfoContractReviewHistoryModalButtonGroup };

const ModalButtonGroup = () => {
  const {
    h: { onClose, onUpdate, onCreate },
  } = useContext(ProjectSalesInfoContractReviewHistoryModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { id: modalId } = useProjectSalesInfoContractReviewHistoryModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    if (typeof modalId === 'undefined') {
      onCreate({
        id,
        ...convertContractReviewHistoryDataForParameter(data),
      });
      return;
    }
    onUpdate({
      id: modalId,
      ...convertContractReviewHistoryDataForParameter(data),
    });
  });
  const { readOnly } = useProjectSalesInfoContractReviewHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div className={classes.container}>
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </div>
  );
};
