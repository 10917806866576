import React, { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ModalLayout from '@front/layouts/ModalLayout';
import { useShallow } from 'zustand/react/shallow';
import { useAlertModalState } from '@front/src/features/modal/features/alert-modal/useState';
import Button from '@front/layouts/Button';

const AlertModalFeature = () => {
  const { setOpen, open, descriptionList, reset, title } = useAlertModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      open: state.open,
      descriptionList: state.descriptionList,
      reset: state.reset,
      title: state.title,
    }))
  );
  const onClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [setOpen, reset]);
  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );
  // 에러 1 - 모달 바깥 클릭시 고장
  // 에러 2 - 부서권한 상태 초기
  return (
    <>
      {open && (
        <ModalLayout
          open={open}
          onClose={onClose}
          title={title}
          width="30dvw"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {descriptionList.map((item) => (
              <Typography key={item}>{item}</Typography>
            ))}
            <Box
              display="flex"
              gap="10px"
              padding="10px"
            >
              <Button onClick={onClose}>닫기</Button>
            </Box>
          </Box>
        </ModalLayout>
      )}
    </>
  );
};

export default AlertModalFeature;
