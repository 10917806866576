import type { AffiliatedCompanySearchParameter } from '@front/src/features/affiliated-company/types/parameter';
import { useQuery } from 'react-query';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import { affiliatedCompanySelectorApi } from '@front/src/features/affiliated-company-selector/query/api';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import type { CompanyModalShortView } from '@front/src/features/affiliated-company-selector/types/view';

export const affiliatedCompanySelectorQuery = {
  useListGet: (params: AffiliatedCompanySearchParameter, enabled: boolean) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyShortView[]>(
      ['affiliated-company', 'search', params],
      () => affiliatedCompanySelectorApi.getList(params),
      {
        enabled: enabled,
      }
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonGet: (id?: number, usePersonSelector?: boolean) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonShortView[]>({
      queryKey: ['affiliated-company', 'person', 'list', id],
      queryFn: () => affiliatedCompanySelectorApi.getPersonList(id),
      enabled: !!id && usePersonSelector,
    });
    return {
      data,
      isLoading,
    };
  },
  useCompanyListGet: (params: AffiliatedCompanySearchParameter, enabled: boolean) => {
    const { data, isLoading } = useQuery<CompanyModalShortView[]>(
      ['project-sales-info', 'company', 'search', params],
      () => affiliatedCompanySelectorApi.getCompanyList(params),
      {
        enabled: enabled,
      }
    );
    return {
      data,
      isLoading,
    };
  },
};
