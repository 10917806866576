import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAffiliatedPersonDetailDeleteLogic } from '@front/src/features/affiliated-person/features/detail/widgets/delete/provider/useLogic';
import { AffiliatedPersonDetailDeleteContext } from '@front/src/features/affiliated-person/features/detail/widgets/delete/provider/context';

export { Provider as AffiliatedPersonDetailDeleteProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onDelete } = useAffiliatedPersonDetailDeleteLogic(menuId);
  const value = useMemo(
    () => ({
      onDelete,
    }),
    [onDelete]
  );
  return (
    <AffiliatedPersonDetailDeleteContext.Provider value={value}>
      {children}
    </AffiliatedPersonDetailDeleteContext.Provider>
  );
};
