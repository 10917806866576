import { useQuery } from 'react-query';
import { projectSalesInfoActivityApi } from '@front/src/features/project-sales-info/features/activity/api/api';
import { adminManageableVariableApi } from '@front/src/features/manageable-variable/query/api';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export const projectSalesInfoActivityQuery = {
  useActivityListGet: (id, menuId) => {
    const { data, remove } = useQuery({
      queryFn: () => projectSalesInfoActivityApi.getActivityList(id, menuId),
      queryKey: ['project-sales-info', 'activity', 'list', id],
      select: (res) => res.data,
      suspense: true,
      enabled: !!menuId,
    });
    return { data, remove };
  },
  useCategoryOptionListGet: (key: string, menuId) => {
    const { data } = useQuery<VariableInformationView[]>({
      queryFn: () => adminManageableVariableApi.getList(key, menuId),
      queryKey: ['project-sales-info', 'activity', 'manageable-variable', key],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
    };
  },
};
