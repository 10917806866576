import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import { controlPanelTableCellWidth } from '@front/src/utils';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import useGetUIBuilderTableHeaderActions from '@front/src/components/ui-builder/table/hooks/useGetHeaderActions';
import UIBuilderTableHeaderCellList from '@front/src/components/ui-builder/table/HeaderCellList';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import type { ControlPanelItem } from '@front/src/types/domain';

export interface UIBuilderTableHeadProps<T = any> extends Omit<UiBuilderTableProps<T>, 'name'> {
  resetValues: () => void;
  controlPanelHeaderItemList?: ControlPanelItem[];
}

interface Props<T = any> extends UIBuilderTableHeadProps<T> {
  HeadComponent?: (props: UIBuilderTableHeadProps) => JSX.Element;
}

export default function UIBuilderTableHeader(props: Props) {
  const { controlPanelHeaderItemList = [], readOnly, resetValues, HeadComponent } = props;
  const { watch, getValues, setValue } = useFormContext<UIBuilderTableFieldValues>();

  const showAllRow = getValues('showAllRow');
  const useFilter = watch('useFilter');

  const clearEditOpenIdList = () => {
    if (getValues('editOpenIdList').length === 0) return;
    setValue('editOpenIdList', []);
  };

  const {
    onOpenHeaderSettingsHistoryModal,
    toggleUseFilter,
    resetSortPriorityList,
    toggleHiddenRow,
  } = useGetUIBuilderTableHeaderActions({ resetValues });

  const itemList = [
    {
      text: useFilter ? '필터 해제' : '필터',
      icon: FilterAltIcon,
      action: toggleUseFilter,
    },
    {
      text: '정렬 해제',
      icon: SortIcon,
      action: resetSortPriorityList,
    },
    {
      text: showAllRow ? '숨긴건 안보기' : '모두 보기',
      icon: showAllRow ? VisibilityOffIcon : VisibilityIcon,
      action: toggleHiddenRow,
      split: true,
    },
    ...controlPanelHeaderItemList,
    ...(HeadComponent
      ? [{ text: '전체 수정 닫기', icon: HighlightOffIcon, action: clearEditOpenIdList }]
      : []),
    {
      text: '헤더명 변경이력',
      icon: HistoryIcon,
      action: onOpenHeaderSettingsHistoryModal,
    },
  ];

  const renderControlPanel = () => {
    if (readOnly) return <></>;
    return (
      <TableCellUI
        isHead
        width={`${controlPanelTableCellWidth}px`}
      >
        <ContextMenuUI
          isHeader
          itemList={itemList}
        />
      </TableCellUI>
    );
  };

  const renderNote = () => (
    <TableCellUI
      isHead
      width={`${controlPanelTableCellWidth}px`}
    >
      비고
    </TableCellUI>
  );

  return (
    <TableHeadUI>
      <TableRowUI key="skjdksdjks">
        {renderControlPanel()}
        {renderNote()}
        <UIBuilderTableHeaderCellList {...props} />
        {/* 컬럼에 명시적으로 설정한 너비가 정상적으로 반영되기 위한 여백 컬럼(헤더/바디 각각 추가) */}
        <TableCellUI
          isHead
          sx={{ width: 'auto' }}
        />
      </TableRowUI>
    </TableHeadUI>
  );
}
