import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';

const workBasicInfoQuery = {
  useGetOne: (commonParams) => {
    const { data } = useQuery({
      queryKey: ['work', 'basic', commonParams.dataId],
      queryFn: () => workWorkApi.getBasicInfo(commonParams),
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return {
      data,
    };
  },
};

export default workBasicInfoQuery;
