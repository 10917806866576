import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { ControlPanelItem } from '@front/src/types/domain';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import useContractHistoryForm from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

export { TableBody as ContractHistoryTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<ContractHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<ContractHistoryView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);
  const { watch } = formContext;
  const editOpenIdList = watch('editOpenIdList');
  const isEditOpen = editOpenIdList.includes(item.id);

  const { methods } = useContractHistoryForm(props.item);
  const { handleSubmit } = methods;

  const commonParams = {
    dataId,
    menuId,
    sectionId,
  };

  const contextMenuItems: ControlPanelItem[] = [
    {
      text: '추가',
      icon: AddCircleOutlineIcon,
      action: () => {
        console.debug('TBD');
      },
      seq: 2,
    },
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => toggleEditOpenId(item.id),
      split: true,
      seq: 3,
    },
    {
      text: '이동(위)',
      icon: ArrowUpwardIcon,
      action: () => {
        console.debug('TBD');
      },
      seq: 4,
    },
    {
      text: '이동(아래)',
      icon: ArrowDownwardIcon,
      action: () => {
        console.debug('TBD');
      },
      seq: 5,
    },
    {
      text: '삭제',
      icon: DeleteIcon,
      action: () => {
        console.debug('TBD');
      },
      iconColor: '#e43333',
      seq: 100,
    },
  ];

  const onSubmit = handleSubmit((data) => {
    //TODO: TBD
    console.debug(data);
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
}
