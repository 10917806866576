import React, { useMemo } from 'react';
import { useProjectSalesInfoEstimationEstimationUpdateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoEstimationEstimationUpdateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoEstimationEstimationUpdateModalTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/components/modal-table';

export { Widget as ProjectSalesInfoEstimationEstimationUpdateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    d: { detail, categoryList, experiment },
    h: { onClose, onUpdate },
    isLoading,
  } = useProjectSalesInfoEstimationEstimationUpdateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detail, categoryList, experiment },
      h: { onClose, onUpdate },
    }),
    [detail, categoryList, onClose, onUpdate, experiment]
  );
  if (isLoading) {
    return <LoadingSpinner height="1000px" />;
  }
  return (
    <ProjectSalesInfoEstimationEstimationUpdateModalContext.Provider value={value}>
      <ProjectSalesInfoEstimationEstimationUpdateModalTable />
    </ProjectSalesInfoEstimationEstimationUpdateModalContext.Provider>
  );
};
