import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkBasicInfoTable from '@front/src/features/work/features/work/features/basic-info/components/Table';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export default function WorkWorkBasicInfoFeature(props: Readonly<SectionComponentProps>) {
  const { name } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkBasicInfoTable {...props} />
      </Suspense>
    </Layout>
  );
}
