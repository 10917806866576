import { createContext } from 'react';
import type { ProjectComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/view';

export { context as ProjectSalesInfoSubjectReviewModalComplexInformationListContext };

interface State {
  list?: ProjectComplexInformationView[];
  isLoading: boolean;
}

const context = createContext<State>({
  isLoading: false,
});
