import React, { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableData } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ChildrenTableData';

export { ChildrenTableContent as PersonalSettingsModalUISettingsModalSettingsModalStatusChildrenTableContent };
interface Props {
  authMenuId: number | undefined;
}
const ChildrenTableContent = ({ authMenuId }: Props) => {
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  const emptyRows = useMemo(
    () =>
      Array(20)
        .fill(null)
        .map((_, index) => (
          <TableRowBox key={`children-empty-${index}`}>
            <TbcBox
              sx={{
                flex: 2,
              }}
            />
            <TbcBox
              sx={{
                flex: 1,
              }}
            />
          </TableRowBox>
        )),
    []
  );
  if (parentId === undefined) {
    return <>{emptyRows}</>;
  }
  return (
    <PersonalSettingsModalUISettingsModalSettingsCurrentSettingsChildrenTableData
      key={parentId}
      authMenuId={authMenuId}
    />
  );
};
