import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyCharacterOpinionRepository } from '@front/src/features/affiliated-company/features/character/features/opinion/repository/repository';
import { useAffiliatedCompanyCharacterOpinionState } from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/useState';

export { useLogic as useAffiliatedCompanyCharacterOpinionLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyCharacterOpinionState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { data: characterList, isLoading } =
    affiliatedCompanyCharacterOpinionRepository.useCharacterOpinionGet(id, menuId);
  const { run: onCharacterCreate } =
    affiliatedCompanyCharacterOpinionRepository.useCharacterOpinionCreate(menuId);
  const { run: onCharacterUpdate } =
    affiliatedCompanyCharacterOpinionRepository.useCharacterOpinionUpdate(menuId);
  const { run: onCharacterDelete } =
    affiliatedCompanyCharacterOpinionRepository.useCharacterOpinionDelete(menuId);
  const h = {
    onCharacterCreate: () => onCharacterCreate(id),
    onCharacterUpdate,
    onCharacterDelete,
    setIdList,
  };
  const d = { characterList };
  return {
    d,
    h,
    isLoading,
    idList,
  };
};
