import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import { initializeObject } from '@front/src/utils';

const useCollectionHistoryForm = (item: CollectionHistoryView) => {
  const methods = useForm<CollectionHistoryView>({
    defaultValues: getFormValues(item),
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

const getFormValues = (item: CollectionHistoryView) => ({
  ...(initializeObject(item) as CollectionHistoryView),
});

export default useCollectionHistoryForm;
