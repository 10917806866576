import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoBidResultQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-result/query';
import { projectSalesInfoBidResultMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-result/query/mutation';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

export const projectSalesInfoBidResultRepository = {
  useProjectSalesInfoBidResultListGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoBidResultQuery.useProjectSalesInfoBidResultListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },

  useProjectSalesInfoBidResultDelete: (menuId) => {
    const { mutate } = projectSalesInfoBidResultMutation.useProjectSalesInfoDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: IdDeleteParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
