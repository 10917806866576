import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/consortium-company`,
  delete: (id?) => `${projectSalesInfoURL}/consortium-company/${id}`,
};

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyApi = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (id: number, menuId) => {
    await axios.delete(url.delete(id), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
