import React from 'react';
import { AffiliatedPersonMemoCreateButton } from '@front/src/features/affiliated-person/features/memo/widgets/create/components/create-button';
import { AffiliatedPersonMemoCreateProvider } from '@front/src/features/affiliated-person/features/memo/widgets/create/provider/provider';

export { Widget as AffiliatedPersonMemoCreateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <AffiliatedPersonMemoCreateProvider menuId={menuId}>
    <AffiliatedPersonMemoCreateButton />
  </AffiliatedPersonMemoCreateProvider>
);
