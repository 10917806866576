import type { ReactNode } from 'react';
import React, { Suspense } from 'react';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

interface Props {
  children: ReactNode;
}

export default function SuspenseWrapper({ children }: Readonly<Props>) {
  return <Suspense fallback={<LoadingSpinnerUI />}>{children}</Suspense>;
}
