import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkBasicInfoParams,
  WorkBasicSearchWordParams,
} from '@front/src/features/work/features/work/features/basic-info/types/parameter';

const workBasicInfoMutation = {
  useUpdate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkBasicInfoParams) =>
        workWorkApi.updateBasicInfo(params, commonParams),
      mutationKey: ['work', 'basic', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'drawer'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSearchWordCreate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkBasicSearchWordParams) =>
        workWorkApi.createSearchWord(params, commonParams),
      mutationKey: ['work', 'basic', 'search-work-create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSearchWordDelete: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkBasicSearchWordParams) =>
        workWorkApi.deleteSearchWord(params, commonParams),
      mutationKey: ['work', 'basic', 'search-work-update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'basic'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workBasicInfoMutation;
