import React, { useContext, useRef } from 'react';
import type { ProceedingsCommentId, ProceedingsCommentVO, ProceedingsVO } from '../../domain';
import { ProceedingsStatus } from '../../domain';
import { Box, TableBody, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import TextBox from '../../../layouts/Text';
import { OldTd, OldTh, Table } from '../../../layouts/Table';
import dayjs from 'dayjs';
import Input from '../../../layouts/Input';
import Button from '../../../layouts/Button';
import useDialog from '../../../dialog/hook';
import { FormikContext } from 'formik';
import type { ProceedingsCommentParameter } from '../../parameter';

interface Props {
  detail: ProceedingsVO;
  list: ProceedingsCommentVO[];
  updateComment: (comment: ProceedingsCommentParameter) => void;
  deleteComment: (id: ProceedingsCommentId) => void;
  isReader: boolean;
  isWriter: boolean;
  isAttendance: boolean;
}

export default function CommentsListSection({
  list,
  updateComment,
  deleteComment,
  isReader,
  isWriter,
  isAttendance,
  detail,
}: Props) {
  const { error, confirm } = useDialog();
  const formik = useContext(FormikContext);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        margin: '10px 0px',
        padding: '10px',
      }}
    >
      <TextBox variant={'body7'}>코멘트</TextBox>
      <TableContainer
        sx={{
          maxHeight: '250px',
          marginTop: '10px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>날짜</OldTh>
              <OldTh>소속</OldTh>
              <OldTh>기록자</OldTh>
              <OldTh colSpan={2}>내용</OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.length === 0 && (
              <TableRow>
                <OldTd colSpan={5}>{'코멘트 이력이 없습니다.'}</OldTd>
              </TableRow>
            )}
            {list.map((item, index) => (
              <TableRow key={`${item.createdAt}_${index}`}>
                <OldTd>{dayjs(item.createdAt).format('YYYY-MM-DD')}</OldTd>
                <OldTd>{item.writer.department.name}</OldTd>
                <OldTd>{item.writer.name}</OldTd>
                <OldTd>
                  <Input
                    variant="outlined"
                    readOnly={
                      (isReader || isAttendance) && detail.status !== ProceedingsStatus.READING
                    }
                    defaultValue={item.comment || ''}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== item.comment) {
                        updateComment({
                          id: item.id,
                          comment: value,
                        });
                      }
                    }}
                  />
                </OldTd>
                {(isWriter || (isAttendance && detail.status === ProceedingsStatus.READING)) && (
                  <OldTd>
                    <Button
                      onClick={() =>
                        confirm({
                          children: '해당 코멘트을 삭제하시겠습니까?',
                          confirmText: '확인',
                          afterConfirm: () => {
                            deleteComment(item.id);
                          },
                        })
                      }
                    >
                      삭제
                    </Button>
                  </OldTd>
                )}
              </TableRow>
            ))}
          </TableBody>
          {(((isReader || isAttendance) && detail.status === ProceedingsStatus.READING) ||
            isWriter) && (
            <TableFooter>
              <TableRow>
                <OldTd colSpan={4}>
                  <Input
                    variant="outlined"
                    placeholder="코멘트을 작성 후 등록 버튼을 클릭 하세요."
                    inputRef={inputRef}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (formik.values.comment !== value) {
                        formik.setFieldValue('comment', value);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key.toLowerCase() === 'enter') {
                        const value = (e.target as HTMLInputElement).value || undefined;
                        formik.setFieldValue(`comment`, value);
                        formik.handleSubmit();
                        if (inputRef.current) {
                          inputRef.current.value = '';
                        }
                      }
                    }}
                  />
                </OldTd>
                <OldTd>
                  <Button
                    onClick={() => {
                      if (!formik.values.comment) {
                        error('내용을 입력해주세요.');
                      } else {
                        formik.handleSubmit();
                        if (inputRef.current) {
                          inputRef.current.value = '';
                        }
                      }
                    }}
                  >
                    등록
                  </Button>
                </OldTd>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
