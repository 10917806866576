import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/parameter';
import { affiliatedCompanyPersonOutlineBasicApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/query/api';

export const affiliatedCompanyPersonOutlineBasicMutation = {
  usePersonOutlineBasicUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonOutlineBasicUpdateParameter) =>
        affiliatedCompanyPersonOutlineBasicApi.updatePersonOutlineBasic(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'basic'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-person', 'drawer', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
