import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import UserSelectorFormFeature from '@front/src/features/user-selector/features/form';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  open: boolean;
  onClose: () => void;
}

export default function UserSelectorModal(props: Readonly<Props>) {
  const { open, onClose } = props;

  return (
    <ModalLayout
      open={open}
      title="유저 선택"
      onClose={onClose}
      width="44dvw"
      height="44dvh"
    >
      <UserSelectorFormFeature {...props} />
    </ModalLayout>
  );
}
