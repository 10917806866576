import { createTheme } from '@mui/material/styles';
import OTFBold from '@front/assets/font/noto_sans_kr/NotoSansKR-Bold.otf';
import OTFBlack from '@front/assets/font/noto_sans_kr/NotoSansKR-Black.otf';
import OTFLight from '@front/assets/font/noto_sans_kr/NotoSansKR-Light.otf';
import OTFMedium from '@front/assets/font/noto_sans_kr/NotoSansKR-Medium.otf';
import OTFThin from '@front/assets/font/noto_sans_kr/NotoSansKR-Thin.otf';
import OTFRegular from '@front/assets/font/noto_sans_kr/NotoSansKR-Regular.otf';

import WOFFBold from '@front/assets/font/noto_sans_kr/NotoSansKR-Bold.woff';
import WOFFBlack from '@front/assets/font/noto_sans_kr/NotoSansKR-Black.woff';
import WOFFLight from '@front/assets/font/noto_sans_kr/NotoSansKR-Light.woff';
import WOFFMedium from '@front/assets/font/noto_sans_kr/NotoSansKR-Medium.woff';
import WOFFThin from '@front/assets/font/noto_sans_kr/NotoSansKR-Thin.woff';
import WOFFRegular from '@front/assets/font/noto_sans_kr/NotoSansKR-Regular.woff';

import WOFF2Bold from '@front/assets/font/noto_sans_kr/NotoSansKR-Bold.woff2';
import WOFF2Black from '@front/assets/font/noto_sans_kr/NotoSansKR-Black.woff2';
import WOFF2Light from '@front/assets/font/noto_sans_kr/NotoSansKR-Light.woff2';
import WOFF2Medium from '@front/assets/font/noto_sans_kr/NotoSansKR-Medium.woff2';
import WOFF2Thin from '@front/assets/font/noto_sans_kr/NotoSansKR-Thin.woff2';
import WOFF2Regular from '@front/assets/font/noto_sans_kr/NotoSansKR-Regular.woff2';

import PretendBlackWOFF from '@front/assets/font/pretendard/Pretendard-Black.woff';
import PretendBoldWOFF from '@front/assets/font/pretendard/Pretendard-Bold.woff';
import PretendExtraBoldWOFF from '@front/assets/font/pretendard/Pretendard-ExtraBold.woff';
import PretendExtraLightWOFF from '@front/assets/font/pretendard/Pretendard-ExtraLight.woff';
import PretendLightWOFF from '@front/assets/font/pretendard/Pretendard-Light.woff';
import PretendMediumWOFF from '@front/assets/font/pretendard/Pretendard-Medium.woff';
import PretendRegularWOFF from '@front/assets/font/pretendard/Pretendard-Regular.woff';
import PretendSemiBoldWOFF from '@front/assets/font/pretendard/Pretendard-SemiBold.woff';
import PretendThinWOFF from '@front/assets/font/pretendard/Pretendard-Thin.woff';
import { configFontAwesome } from '@front/common/fontawesome/fontawesome-config';

configFontAwesome();

export const ColorPalette = {
  _ffffff: '#ffffff',
  _9b9ea4: '#9b9ea4',
  _b2b4b7: '#b2b4b7',
  _94a6ca: '#94a6ca',
  _ffb72b: '#ffb72b',
  _fff2cc: '#fff2cc',
  _252627: '#252627',
  _386dd6: '#386dd6',
  _1c6ebb: '#1c6ebb',
  _4c9eeb: '#4c9eeb',
  _9bb6ea: '#9bb6ea',
  _d7e2f7: '#d7e2f7',
  _d2e7fa: '#d2e7fa',
  _cddaf5: '#cddaf5',
  _e4e9f2: '#e4e9f2',
  _f1f5fc: '#f1f5fc',
  _2d3a54: '#2d3a54',
  _242e43: '#242e43',
  _414d65: '#414d65',
  _4c576d: '#4c576d',
  _697183: '#697183',
  _0047d3: '#0047d3',
  _eb4c4c: '#eb4c4c',
  _f4f4f4: '#f4f4f4',
  _a7abb2: '#a7abb2',
  _F8CECC: '#F8CECC',
  _DAE8FC: '#57ef04',
  _57EF04FF: '#4f4d4d',
  _D5D4D4FF: '#d5d4d4',
  _E6E6E6FF: '#E6E6E6FF',
  _1F314E: '#1F314E',
  _FFB611: '#FFB611',
  _B0B5BE: '#B0B5BE',
  transparent: 'transparent',
  greyscale: {
    white: '#FFFFFF',
    black: '#000000',
    disabled: '#C0C2C3',
    text_primary: '#282828',
    text_secondary: '#7E7E7E',
    text_tertiary: '#989898',
    text_quaternary: '#BABABA',
  },
  main: {
    main_primary: '#2F6ACA',
    main_secondary: '#77A0E5',
    main_tertiary: '#E8EFFC',
    main_hover: '#26529A',
  },
  sub: {
    sub_primary: '#FFB611',
    sub_secondary: '#FFD77B',
    sub_tertiary: '#FFE9B8',
    sub_quaternary: '#FFF8E8',
  },
  sub2: {
    sub2_primary: '#E43333',
    sub2_secondary: '#E57777',
    sub2_tertiary: '#FCE8E8',
  },
  background: {
    bg: '#FFFFFF',
    bg02: '#EBEEF2',
    bg03: '#D8DCE2',
    bg04: '#1F314E',
    bg05: '#35455F',
    bg06: '#49576F',
    bg07: '#90969F',
  },
  line: {
    line: '#DCE0E7',
    line02: '#B0B5BE',
    line03: '#AEAEAE',
  },
  etc: {
    modal_scrim: 'rgba(0, 0, 0, 0.4)',
    modal_scrim_light: 'rgba(0, 0, 0, 0.2)',
    modal_scrim_lightest: 'rgba(0, 0, 0, 0.1)',
    loading_spinner: 'rgba(31, 49, 78, 0.30)',
  },
};
const mainTheme = createTheme({
  typography: {
    fontFamily: ['Pretendard'].join(','),
    htmlFontSize: 10,
    allVariants: {
      fontSize: '1.4rem',
    },
  },
  palette: {
    primary: {
      main: '#2d3a54',
    },
    secondary: {
      main: '#386dd6',
    },
    error: {
      main: ColorPalette._eb4c4c,
    },
    warning: {
      main: '#ffb72b',
    },
    text: {
      primary: '#282828', // dark grey
    },
    action: {
      active: '#5470ff',
      hover: '#5470FF38',
      selected: '#5470ff',
      disabled: '#494949',
      disabledBackground: '#f1eaff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 100;
        src: url(${WOFF2Thin}) format('woff2'),
             url(${WOFFThin}) format('woff'),
             url(${OTFThin}) format('opentype');
      }
      @font-face {
        font-family: 'Noto Sans KR';
        font-style: normal;
        font-weight: 300;
        src: url(${WOFF2Light}) format('woff2'),
             url(${WOFFLight}) format('woff'),
             url(${OTFLight}) format('opentype');
      }
      @font-face {
         font-family: 'Noto Sans KR';
         font-style: normal;
         font-weight: 400;
         src: url(${WOFF2Regular}) format('woff2'),
              url(${WOFFRegular}) format('woff'),
              url(${OTFRegular}) format('opentype');
       }
      @font-face {
         font-family: 'Noto Sans KR';
         font-style: normal;
         font-weight: 500;
         src: url(${WOFF2Medium}) format('woff2'),
              url(${WOFFMedium}) format('woff'),
              url(${OTFMedium}) format('opentype');
       }
      @font-face {
         font-family: 'Noto Sans KR';
         font-style: normal;
         font-weight: 700;
         src: url(${WOFF2Bold}) format('woff2'),
              url(${WOFFBold}) format('woff'),
              url(${OTFBold}) format('opentype');
       }
      @font-face {
         font-family: 'Noto Sans KR';
         font-style: normal;
         font-weight: 900;
         src: url(${WOFF2Black}) format('woff2'),
              url(${WOFFBlack}) format('woff'),
              url(${OTFBlack}) format('opentype');
       }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 900;
        font-display: swap;
        src: url(${PretendBlackWOFF}) format('woff');
       }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 800;
        font-display: swap;
        src: url(${PretendExtraBoldWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 700;
        font-display: swap;
        src: url(${PretendBoldWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 600;
        font-display: swap;
        src: url(${PretendSemiBoldWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        font-display: swap;
        src: url(${PretendMediumWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        font-display: swap;
        src: url(${PretendRegularWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 300;
        font-display: swap;
        src: url(${PretendLightWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 200;
        font-display: swap;
        src: url(${PretendExtraLightWOFF}) format('woff');
      }
      @font-face {
        font-family: 'Pretendard';
        font-weight: 100;
        font-display: swap;
        src: url(${PretendThinWOFF}) format('woff');
      }
      
      ::-webkit-scrollbar {
        width: 10px; /* width of the scrollbar */
        height: 10px; /* height of the scrollbar */
      }
      ::-webkit-scrollbar-track {
        background: 'transparent'; /* color of the tracking area */
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${ColorPalette.greyscale.disabled}; /* color of the scrollbar thumb */
        border-radius: 0px; /* roundness of the scrollbar thumb */
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: ${ColorPalette.greyscale.text_quaternary}; /* color of the scrollbar thumb on hover */
      }
      `,
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Pretendard',
      },
      styleOverrides: {
        root: {
          fontFamily: 'Pretendard',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default mainTheme;
