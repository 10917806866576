import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import type { WorkReviewerView } from '@front/src/features/work/features/work/features/reviewer/types/view';
import Box from '@mui/material/Box';

import type { ConfirmView } from '@front/src/features/dialog/types';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

const workReviewerCellComponent = {
  14: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowDepartment {...rest} />,
  15: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowJobDuty {...rest} />,
  16: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowUser {...rest} />,
  86: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowNumberAttr1 {...rest} />,
  87: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowTextAttr1 {...rest} />,
  88: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowDateAttr1 {...rest} />,
  89: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowNumberAttr2 {...rest} />,
  90: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowTextAttr2 {...rest} />,
  91: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowDateAttr2 {...rest} />,
  92: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowNumberAttr3 {...rest} />,
  93: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowTextAttr3 {...rest} />,
  94: ({ ...rest }: CellComponentProps) => <WorkReviewerTableRowDateAttr3 {...rest} />,
};

export default workReviewerCellComponent;

interface Props extends CellComponentProps<WorkReviewerView> {}

const WorkReviewerTableRowDepartment = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.user?.department?.name ?? '-'}
  </Box>
);

const WorkReviewerTableRowJobDuty = ({ item, sx = {}, onClick }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item?.user?.jobDuty ?? '-'}
  </Box>
);

function WorkReviewerTableRowUser(props: Readonly<Props>) {
  const confirmProps: ConfirmView = {
    title: '회람자 삭제 확인 모달',
    lineBreakChildren: [
      { value: '역할명에서 제외되면 해당 업무를 열람할 수 없습니다.' },
      { value: '제외 하시겠습니까?' },
    ],
  };

  return (
    <UIBuilderTableCellUserSelector
      {...props}
      name="user"
      confirmProps={confirmProps}
    />
  );
}

const WorkReviewerTableRowNumberAttr1 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkReviewerTableRowTextAttr1 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkReviewerTableRowDateAttr1 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkReviewerTableRowNumberAttr2 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkReviewerTableRowTextAttr2 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkReviewerTableRowDateAttr2 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkReviewerTableRowNumberAttr3 = ({ ...rest }: Readonly<Props>) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkReviewerTableRowTextAttr3 = ({ ...rest }: Readonly<Props>) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkReviewerTableRowDateAttr3 = ({ ...rest }: Readonly<Props>) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
