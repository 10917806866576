import { useQuery } from 'react-query';
import { AccountingSettingsDailyReportStatusApi } from '@front/src/features/accounting/features/settings/features/daily-report-status/query/api';
import type { DailyReportStatusViewByCategory } from '@front/src/features/accounting/features/settings/features/daily-report-status/types/view';

export { Query as AccountingSettingsDailyReportStatusQuery };

const Query = {
  useGetList: (menuId) => {
    const { data, isLoading } = useQuery<DailyReportStatusViewByCategory>({
      queryFn: () => AccountingSettingsDailyReportStatusApi.getList(menuId),
      queryKey: ['accounting', 'settings', 'daily-report-status', 'list'],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
