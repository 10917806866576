import { useMutation, useQueryClient } from 'react-query';
import type {
  AddParameter,
  IdsDataParameter,
  SeqUpdateParameter,
  UIBuilderParameter,
} from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import type { EstimationHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/parameter';

const estimationHistoryMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, AddParameter>({
      mutationFn: (params) =>
        commonApi.add(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, EstimationHistoryUpdateParameter>({
      mutationFn: (params) =>
        commonApi.update(
          `/api/project/sales/sales-information/estimation/${params.id}`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdateSeq: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, SeqUpdateParameter>({
      mutationFn: (params) =>
        commonApi.updateSeq(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation/seq`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, IdsDataParameter>({
      mutationFn: (params) =>
        commonApi.delete(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation`,
          { ...params, ...commonParams },
          commonParams
        ),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },
};

export default estimationHistoryMutation;

const getQueryKey = (dataId) => ['project', 'sales', dataId, 'estimation', 'history'];
