import { AccountingUploadSalesInfoExpectModalRepository } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/repository/repository';
import { useAccountingUploadSalesInfoExpectModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAccountingUploadSalesInfoExpectModalUpdateLogic };

const useLogic = (menuId) => {
  const { setIsOpen } = useAccountingUploadSalesInfoExpectModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
    }))
  );
  const { run: onUpdate, setCallback } =
    AccountingUploadSalesInfoExpectModalRepository.useUpdate(menuId);
  setCallback({
    onSuccess: () => {
      setIsOpen(false);
    },
  });
  return {
    onUpdate,
  };
};
