import React, { useMemo } from 'react';
import type { UserId, UserVO } from '../../../user/domain';
import { AuthorizationType } from '../../../user/domain';
import type { DefaultFunction } from '../../../type/Function';
import { Box } from '@mui/material';
import TextBox from '../../../layouts/Text';
import Button from '../../../layouts/Button';
import { ColorPalette } from '../../../assets/theme';
import UserSelectorModal from '../../../components/UserSelector/Modal';
import type { ProceedingsMemoAddParameter, ProceedingsUpdateParameter } from '../../parameter';
import UserIcon from '../../../layouts/UserIcon';
import type { ProceedingsVO } from '../../domain';
import { messageConvertor, messageLabelList, ProceedingsStatus } from '../../domain';
import { makeMessage, MemoMessage } from '../../../util/MessageUtil';
import useDialog from '../../../dialog/hook';
import type { ApiStatus } from '../../../components/DataFieldProps';

interface Props {
  list: UserVO[];
  open: boolean;
  onClose: DefaultFunction;
  onOpen: DefaultFunction;
  update: (params: ProceedingsUpdateParameter) => void;
  addMemo: DefaultFunction<ProceedingsMemoAddParameter>;
  detail: ProceedingsVO;
  isWriter: boolean;
  isAttendance: boolean;
  requestAddMemo: ApiStatus;
}

export default function ReaderListSection({
  list,
  open,
  onClose,
  onOpen,
  update,
  addMemo,
  detail,
  isWriter,
  isAttendance,
  requestAddMemo,
}: Props) {
  const { error } = useDialog();
  const handleRequestButtonClick = () => {
    if (detail.readerList.length === 0) {
      error('열람 가능 사용자가 없습니다.');
      return;
    }

    addMemo({
      description: makeMessage(
        new MemoMessage('열람 가능 사용자 알림 메모', messageLabelList, messageConvertor, detail)
      ),
      attendanceList: detail.readerList.map((item) => item.id as UserId),
    });
  };
  const menuIdList = useMemo(() => {
    const menuId = sessionStorage.getItem('menuId');
    if (!menuId) return [];
    return [+menuId];
  }, []);
  const typeList = [AuthorizationType.W, AuthorizationType.R, AuthorizationType.V];
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        minHeight: '60px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <Box>
          <TextBox
            variant={'body7'}
            sx={{
              width: '13%',
              marginBottom: '10px',
            }}
          >
            열람 가능 사용자
          </TextBox>
        </Box>
        {(isWriter || (isAttendance && detail.status === ProceedingsStatus.READING)) && (
          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <Button
              sx={{
                marginRight: '10px',
              }}
              onClick={handleRequestButtonClick}
              disabled={requestAddMemo === 'request'}
            >
              열람 요청 메시지 발송
            </Button>
            <Button onClick={onOpen}>수정</Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '10px',
          borderRadius: '5px',
          columnGap: '5px',
          rowGap: '5px',
        }}
      >
        {list.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              width: 'inherit',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextBox variant={'body9'}>열람 가능 사용자가 없습니다.</TextBox>
          </Box>
        ) : (
          list.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <UserIcon
                  user={item}
                  sx={{
                    marginRight: '10px',
                  }}
                />
                <TextBox
                  variant="body2"
                  sx={{
                    marginRight: '10px',
                  }}
                >
                  {item.name}
                </TextBox>
              </Box>
              <TextBox variant="body2">{item.department.name}</TextBox>
            </Box>
          ))
        )}
      </Box>
      <UserSelectorModal
        open={open}
        onClose={onClose}
        multi={true}
        value={list.map((user) => user.id)}
        menuIdList={menuIdList}
        typeList={typeList}
        onChange={(list) => {
          update({
            readerList: list as UserId[],
          });
        }}
      />
    </Box>
  );
}
