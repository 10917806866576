import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { useQuery } from 'react-query';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';

const workCarouselQuery = {
  useListGet: (bundleId, commonParams) => {
    const { data } = useQuery<WorkCarouselView[]>({
      queryKey: ['work', 'carousel', bundleId],
      queryFn: () => workWorkApi.getCarousel(bundleId, commonParams),
      suspense: true,
      enabled: !!bundleId && !!commonParams.menuId,
      select: (data) => [...data].sort((a, b) => (a.seq > b.seq ? 1 : -1)),
    });
    return {
      data,
    };
  },
};

export default workCarouselQuery;
