import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/query/api';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ProjectAffiliatedCompanyPersonUpdateParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/parameter';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonMutation = {
  useDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdListDeleteParameter) =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi.delete(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company-person'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectAffiliatedCompanyPersonUpdateParameter) =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'affiliated-company', 'affiliated-company-person'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
