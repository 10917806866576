import create from 'zustand';

interface ModalState {
  isOpen: boolean;
  dataId: number | undefined;
  clientId: number | undefined;
  readOnly: boolean;
  onOpen: (dataId: number, clientId: number, readOnly: boolean) => void;
  onClose: () => void;
}

const useCmModalStore = create<ModalState>((set) => ({
  isOpen: false,
  dataId: undefined,
  clientId: undefined,
  readOnly: false,
  onOpen: (dataId: number, clientId: number, readOnly: boolean) =>
    set({ isOpen: true, dataId, clientId, readOnly }),
  onClose: () => set({ isOpen: false }),
}));

export default useCmModalStore;
