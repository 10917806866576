import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import type { ArrangementMemoAddParameter } from '@front/ost_arrangement/parameter';
import ArrangementMemoForm from '@front/ost_arrangement/view/memo/Form';
import { arrangementAction } from '@front/ost_arrangement/action';

interface Props {
  setFormHeight: (height: number) => void;
}

const ArrangementFormService = ({ setFormHeight }: Props) => {
  const dispatch = useDispatch();

  const addMemo = useCallback(
    (params: ArrangementMemoAddParameter) => dispatch(arrangementAction.addMemo(params)),
    [dispatch]
  );
  const formik = useFormik<ArrangementMemoAddParameter>({
    initialValues: {} as ArrangementMemoAddParameter,
    onSubmit: (values) => {
      addMemo(values);
      formik.resetForm();
    },
  });

  return (
    <FormikProvider value={formik}>
      <ArrangementMemoForm setFormHeight={setFormHeight} />
    </FormikProvider>
  );
};

export default ArrangementFormService;
