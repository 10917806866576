import { createContext } from 'react';
import type { AffiliatedCompanySalesActivityConvertedView } from '@front/src/features/affiliated-company/features/sales-activity/types/view';

export { context as AffiliatedCompanySalesActivityRowContext };

interface State {
  item: AffiliatedCompanySalesActivityConvertedView;
}

const context = createContext<State>({
  item: {
    id: 1,
    category: '',
    date: '',
    person: {
      id: 1,
      position: '',
      department: '',
      name: '',
    },
    affiliatedCompany: {
      id: 1,
      name: '',
    },
    activityUser: {
      id: 1,
      user: { id: 1, name: '' },
    },
    index: 1,
    sameActivityUserSize: 1,
    activity: {
      id: 1,
      category: '',
      date: '',
      note: '',
      time: '',
    },
    isFirstActivity: false,
    isFirstActivityUser: false,
    isLastActivity: false,
    sameActivitySize: 1,
    project: {
      code: '',
      nickName: '',
    },
  },
});
