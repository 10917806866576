import React, { memo, useContext } from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';

import { saveHistoryHeadList } from '@front/src/features/project-sales-info/features/collection/features/save-history/utils/constant';
import { ProjectSalesInfoCollectionSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/context';
import { ProjectSalesInfoCollectionSaveHistoryTableRow } from '@front/src/features/project-sales-info/features/collection/features/save-history/components/save-history-table-row';
import CircularProgress from '@front/components/CircularProgress';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';
import { useProjectSalesInfoCollectionSaveHistoryState } from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/useState';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { SaveHistoryTable as ProjectSalesInfoCollectionSaveHistoryTable };

const SaveHistoryTable = () => {
  const {
    d: { saveHistoryList },
    isLoading,
  } = useContext(ProjectSalesInfoCollectionSaveHistoryContext);
  const isListLoading = typeof saveHistoryList === 'undefined' || isLoading;
  const isListEmpty = saveHistoryList?.length === 0;
  const setId = useProjectSalesInfoCollectionSaveHistoryState((state) => state.setId);
  return (
    <RowClickTableContainer
      setId={setId}
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {saveHistoryHeadList.map(({ value, width }) => (
              <Th
                key={value}
                width={width}
              >
                {value}
              </Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isListLoading && <Spinner />}
          {!isListLoading && isListEmpty && <NoResult />}
          {!isListLoading &&
            !isListEmpty &&
            saveHistoryList?.map((item) => (
              <ProjectSalesInfoCollectionSaveHistoryTableRow
                key={item.id}
                item={item}
              />
            ))}
        </TableBody>
      </Table>
    </RowClickTableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={7}>저장된 입찰정보가 없습니다.</OldTd>
  </TableRow>
));

const Spinner = memo(() => (
  <TableRow
    sx={{
      height: '54px',
    }}
  >
    <OldTd colSpan={7}>
      <CircularProgress size={30} />
    </OldTd>
  </TableRow>
));
