import React, { useMemo } from 'react';
import CircularProgress from '@front/components/CircularProgress';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/widgets/useLogic';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/widgets/context';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/components/representative-contact-information';
import { AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateWidget } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/representative-contact-information/features/update/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationWidget = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    h: { onPersonOutlineRepresentativeContactInformationUpdate },
    d: { personOutlineRepresentativeContactInformation },
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationLogic();
  const value = useMemo(
    () => ({
      d: { personOutlineRepresentativeContactInformation },
      h: { onPersonOutlineRepresentativeContactInformationUpdate },
      isLoading,
    }),
    [
      onPersonOutlineRepresentativeContactInformationUpdate,
      personOutlineRepresentativeContactInformation,
      isLoading,
    ]
  );
  if (typeof personOutlineRepresentativeContactInformation === 'undefined' || isLoading) {
    return <CircularProgress size={30} />;
  }
  return (
    <Layout>
      <Header title={name}>
        <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationUpdateWidget
          menuId={menuId}
        />
      </Header>
      <Body>
        <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext.Provider
          value={value}
        >
          <AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformation />
        </AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationContext.Provider>
      </Body>
    </Layout>
  );
};

export default AffiliatedCompanyPersonDetailModalOutlineRepresentativeContactInformationWidget;
