import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { AxiosError } from 'axios';
import type { WorkCarouselCreateParameter } from '@front/src/features/work/features/work/features/carousel/features/create/types/parameter';

const workCarouselCreateMutation = {
  useCreate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, AxiosError, WorkCarouselCreateParameter>({
      mutationFn: (params) => workWorkApi.createCarousel(params, commonParams),
      mutationKey: ['work', 'carousel', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return mutate;
  },
};

export default workCarouselCreateMutation;
