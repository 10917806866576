import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget as AffiliatedCompanySelectorFeature } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext, useWatch } from 'react-hook-form';
import type { ModalPositionType } from '@front/layouts/ModalLayout';

export { Index as HookFormCompanySelector };

interface Props {
  name: string;
  position?: ModalPositionType;
  disabled?: boolean;
}

const Index = ({ name, position, disabled }: Props) => {
  const { control, setValue } = useFormContext();
  const company = useWatch({ control, name });
  const handleCompanySelect = useCallback(
    (company) => {
      setValue(name, company);
    },
    [name, setValue]
  );
  return (
    <AffiliatedCompanySelectorFeature
      position={position}
      value={{
        companyId: company?.id,
        textValue: company?.name,
      }}
      onCompanySelect={handleCompanySelect}
      disabled={disabled}
    />
  );
};
