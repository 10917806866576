import { projectSalesInfoPaymentHistoryCreateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { projectSalesInfoPaymentHistoryCreateModalQuery } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/query';

export const projectSalesInfoPaymentHistoryCreateModalRepository = {
  usePaymentHistoryCreate: (id: number, menuId) => {
    const { mutate } = projectSalesInfoPaymentHistoryCreateModalMutation.usePaymentHistoryCreate(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectReviewAmountPaymentHistoryParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useConsortiumCompanyGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoPaymentHistoryCreateModalQuery.useConsortiumCompanyGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
};
