import { create } from 'zustand';

interface State {
  readOnly?: boolean;
}

interface Actions {
  setReadOnly: (readOnly?: boolean) => void;
}

export { useState as useAffiliatedCompanyPersonDetailModalOutlineState };

const useState = create<State & Actions>((set) => ({
  readOnly: false,
  setReadOnly: (readOnly) => set(() => ({ readOnly })),
}));
