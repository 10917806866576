import { createReducer } from 'typesafe-actions';

import type { CampaignMemoQuery, CampaignQuery } from '@front/ost_campaign/query';
import { initialCampaignMemoQuery, initialCampaignQuery } from '@front/ost_campaign/query';
import { CampaignAction } from '@front/ost_campaign/action';
import type { ProposalId, ProposalVO } from '@front/ost_proposal/domain';
import type {
  CampaignApprovalVO,
  CampaignEstimatorVO,
  CampaignId,
  CampaignMemoVO,
  CampaignShortVO,
  CampaignVO,
  ProposalCategoryVO,
} from '@front/ost_campaign/domain';

export interface CampaignState {
  /**
   * 캠페인 목록 상세에 필요한 상태
   */
  id?: CampaignId;
  listDrawerOpen: boolean;
  filter: CampaignQuery;
  campaignList: CampaignShortVO[];
  loading: boolean;

  /**
   * 캠페인 등록 관련 상태
   */
  detail?: CampaignVO;

  /**
   * 제안 구분 등록 관련 상태
   */
  addProposalCategoryModalOpen: boolean;
  proposalCategoryList: ProposalCategoryVO[];
  addCampaignEstimatorModalOpen: boolean;

  /**
   * 평가 담당자 목록 관련 상태
   */
  estimatorList: CampaignEstimatorVO[];

  /**
   * 캠페인 제안 현황 관련 상태
   */
  addProposalEstimatorModalOpen: boolean;
  proposalEstimatorModalId?: ProposalId;
  proposalList: ProposalVO[] | null;

  /**
   * 메모 관련 상태
   */
  isOpenMemo: boolean;
  formHeight: number;
  isLoadingMemo: boolean;
  memoList: CampaignMemoVO[];
  filterMemo: CampaignMemoQuery;

  /**
   * 캠페인 진행 목록
   */
  campaignListUnderOnGoing: CampaignShortVO[];

  /**
   * 결재 라인
   */
  approvalList: CampaignApprovalVO[];
}

const initial: CampaignState = {
  listDrawerOpen: true,
  filter: initialCampaignQuery,
  campaignList: [],
  loading: false,
  addProposalCategoryModalOpen: false,
  proposalCategoryList: [],
  addCampaignEstimatorModalOpen: false,
  estimatorList: [],
  addProposalEstimatorModalOpen: false,
  proposalList: null,
  isOpenMemo: true,
  formHeight: 0,
  isLoadingMemo: false,
  memoList: [],
  filterMemo: initialCampaignMemoQuery,
  campaignListUnderOnGoing: [],
  approvalList: [],
};

export const campaignReducer = createReducer(initial, {
  /**
   * 캠페인 목록 및 상세에 필요한 핸들러
   */
  [CampaignAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [CampaignAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [CampaignAction.setList]: (state, action) => ({
    ...state,
    campaignList: action.payload,
  }),
  [CampaignAction.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [CampaignAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [CampaignAction.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),

  /**
   * 제안 구분 등록 관련 핸들러
   */
  [CampaignAction.setAddProposalCategoryModalOpen]: (state, action) => ({
    ...state,
    addProposalCategoryModalOpen: action.payload,
  }),
  [CampaignAction.setProposalCategoryList]: (state, action) => ({
    ...state,
    proposalCategoryList: action.payload,
  }),

  [CampaignAction.setAddCampaignEstimatorModalOpen]: (state, action) => ({
    ...state,
    addCampaignEstimatorModalOpen: action.payload,
  }),

  /**
   * 평가 담당자 관련 핸들러
   */
  [CampaignAction.setEstimatorList]: (state, action) => ({
    ...state,
    estimatorList: action.payload,
  }),
  [CampaignAction.setAddProposalEstimatorModalOpen]: (state, action) => ({
    ...state,
    addProposalEstimatorModalOpen: action.payload,
  }),

  [CampaignAction.setProposalEstimatorModalId]: (state, action) => ({
    ...state,
    proposalEstimatorModalId: action.payload,
  }),
  [CampaignAction.setProposalList]: (state, action) => ({
    ...state,
    proposalList: action.payload,
  }),

  /**
   * 메모 관련 핸들러
   */
  [CampaignAction.setIsOpenMemo]: (state, action) => ({
    ...state,
    isOpenMemo: action.payload,
  }),
  [CampaignAction.setFormHeight]: (state, action) => ({
    ...state,
    formHeight: action.payload,
  }),

  [CampaignAction.setIsLoadingMemo]: (state, action) => ({
    ...state,
    isLoadingMemo: action.payload,
  }),
  [CampaignAction.setFilterMemo]: (state, action) => ({
    ...state,
    filterMemo: action.payload,
  }),
  [CampaignAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  /**
   * 캠페인 진행 목록
   */
  [CampaignAction.setCampaignListUnderOnGoing]: (state, action) => ({
    ...state,
    campaignListUnderOnGoing: action.payload,
  }),
  /**
   * 결재선 설정
   */
  [CampaignAction.setApprovalList]: (state, action) => ({
    ...state,
    approvalList: action.payload,
  }),
});
