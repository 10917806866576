import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { ProjectSalesInfoAbstractCollectionListProvider } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/provider/provider';
import { ProjectSalesInfoAbstractCollectionList } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/components/list';
import { ProjectSalesInfoAbstractCollectionFirstRow } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/components/first-row';

export { Widget as ProjectSalesInfoAbstractCollectionListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<TableLoadingSpinner colSpan={10} />}>
    <ProjectSalesInfoAbstractCollectionListProvider menuId={menuId}>
      <ProjectSalesInfoAbstractCollectionFirstRow />
      <ProjectSalesInfoAbstractCollectionList />
    </ProjectSalesInfoAbstractCollectionListProvider>
  </Suspense>
);
