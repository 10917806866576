import React, { useContext } from 'react';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/components/tab.module.scss';
import { affiliatedPersonTabList } from '@front/src/features/affiliated-company/utils/constant';
import { AffiliatedCompanyPersonDetailModalContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/context';

export { Tab as AffiliatedCompanyPersonDetailModalTab };
const Tab = () => {
  const {
    tab,
    h: { setTab },
  } = useContext(AffiliatedCompanyPersonDetailModalContext);
  const handleClick = (target) => () => {
    setTab(target);
  };
  return (
    <div className={classes.tab__list}>
      {affiliatedPersonTabList.map(({ value, label }) => (
        <div
          key={value}
          onClick={handleClick(value)}
          className={`${classes.tab} ${tab === value && classes.selected}`}
        >
          {label}
        </div>
      ))}
    </div>
  );
};
