import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonMemoListContext } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/context';
import { useAffiliatedPersonMemoListLogic } from '@front/src/features/affiliated-person/features/memo/widgets/list/provider/useLogic';

export { Provider as AffiliatedPersonMemoListProvider };

interface Props {
  children: ReactNode;
  updateWidget: ReactNode;
  deleteWidget: ReactNode;
}

const Provider = ({ children, updateWidget, deleteWidget }: Props) => {
  const { list } = useAffiliatedPersonMemoListLogic();
  const value = useMemo(
    () => ({
      list,
      updateWidget,
      deleteWidget,
    }),
    [list, updateWidget, deleteWidget]
  );
  return (
    <AffiliatedPersonMemoListContext.Provider value={value}>
      {children}
    </AffiliatedPersonMemoListContext.Provider>
  );
};
