import { AccountingSettingsTaxAccountingAccountRepository } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/repository/repository';

export { useLogic as useAccountingSettingsTexAccountingAccountGetCountLogic };

const useLogic = (menuId) => {
  const { data: detail } = AccountingSettingsTaxAccountingAccountRepository.useCountGet(menuId);
  return {
    detail,
  };
};
