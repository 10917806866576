import React, { memo } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';

import type { CampaignStatus } from '@front/ost_campaign/domain';
import type {
  CampaignUpdateEndDateParameter,
  CampaignUpdateEstimationCompletionRateParameter,
  CampaignUpdateEstimationEndDateParameter,
  CampaignUpdateEstimationStartDateParameter,
  CampaignUpdateManagerParameter,
  CampaignUpdateNameParameter,
  CampaignUpdateStartDateParameter,
  CampaignUpdateTotalRewardParameter,
  CampaignUpdateVoteEndDateParameter,
  CampaignUpdateVoteStartDateParameter,
} from '@front/ost_campaign/parameter';
import type { UserVO } from '@front/user/domain';
import NameForm from '@front/ost_campaign/service/detail/form/name';
import ReceptionPeriodForm from '@front/ost_campaign/service/detail/form/receptionPeriod';
import TotalRewardForm from '@front/ost_campaign/service/detail/form/totalReward';
import RestRewardForm from '@front/ost_campaign/service/detail/form/restReward';
import ManagerForm from '@front/ost_campaign/service/detail/form/manager';
import StatusForm from '@front/ost_campaign/service/detail/form/status';
import TextTip from '@front/components/TextTip';
import Section from '@front/components/Section';
import CampaignFormOst from '@front/ost_campaign/service/detail/form/ost';
import EstimationPeriod from '@front/ost_campaign/service/detail/form/estimationPeriod';
import NoticeForm from '@front/ost_campaign/service/detail/form/notice';
import VotePeriod from '@front/ost_campaign/service/detail/form/votePeriod';
import EstimationCompletionRateForm from '@front/ost_campaign/service/detail/form/estimationCompletionRate';

interface Props {
  name?: string;
  totalReward?: number;
  status?: CampaignStatus;
  manager?: UserVO;
  startDate?: string;
  endDate?: string;
  estimationStartDate?: string;
  estimationEndDate?: string;
  voteStartDate?: string;
  voteEndDate?: string;
  fixedResultSum?: number;
  estimationCompletionRate?: number;
  updateName: (params: CampaignUpdateNameParameter) => void;
  updateStartDate: (params: CampaignUpdateStartDateParameter) => void;
  updateEndDate: (params: CampaignUpdateEndDateParameter) => void;
  updateEstimationStartDate: (params: CampaignUpdateEstimationStartDateParameter) => void;
  updateEstimationEndDate: (params: CampaignUpdateEstimationEndDateParameter) => void;
  updateTotalReward: (params: CampaignUpdateTotalRewardParameter) => void;
  updateManager: (params: CampaignUpdateManagerParameter) => void;
  onChangeStatus: (e: SelectChangeEvent<unknown>) => void;
  ostName?: string;
  notice?: string;
  isNoticeExposed?: boolean;
  updateVoteStartDate: (params: CampaignUpdateVoteStartDateParameter) => void;
  updateVoteEndDate: (params: CampaignUpdateVoteEndDateParameter) => void;
  updateEstimationCompletionRate: (params: CampaignUpdateEstimationCompletionRateParameter) => void;
}

const CampaignUpdateForm = ({
  name,
  totalReward,
  status,
  manager,
  startDate,
  endDate,
  voteStartDate,
  voteEndDate,
  fixedResultSum,
  updateName,
  updateStartDate,
  updateEndDate,
  updateManager,
  onChangeStatus,
  ostName,
  estimationStartDate,
  estimationEndDate,
  updateEstimationStartDate,
  updateEstimationEndDate,
  notice,
  isNoticeExposed,
  updateVoteStartDate,
  updateVoteEndDate,
  estimationCompletionRate,

  updateEstimationCompletionRate,
}: Props) => (
  <Section title="캠페인 일반 정보">
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        columnGap: '10%',
      }}
    >
      <Box>
        <Box
          sx={{
            margin: '10px 0',
          }}
        >
          <NameForm
            name={name}
            updateName={updateName}
          />
        </Box>

        <Box
          sx={{
            margin: '10px 0',
          }}
        >
          <CampaignFormOst ostName={ostName} />
        </Box>

        <Box
          sx={{
            width: '70%',
            margin: '10px 0',
          }}
        >
          <ReceptionPeriodForm
            startDate={startDate}
            endDate={endDate}
            updateStartDate={updateStartDate}
            updateEndDate={updateEndDate}
          />
        </Box>

        <Box
          sx={{
            width: '70%',
            margin: '10px 0',
          }}
        >
          <EstimationPeriod
            estimationStartDate={estimationStartDate}
            estimationEndDate={estimationEndDate}
            updateEstimationStartDate={updateEstimationStartDate}
            updateEstimationEndDate={updateEstimationEndDate}
          />
        </Box>
        <Box
          sx={{
            width: '70%',
            margin: '10px 0',
          }}
        >
          <VotePeriod
            voteStartDate={voteStartDate}
            voteEndDate={voteEndDate}
            updateVoteStartDate={updateVoteStartDate}
            updateVoteEndDate={updateVoteEndDate}
          />
        </Box>

        <Box
          sx={{
            width: '35%',
            margin: '10px 0',
          }}
        >
          <EstimationCompletionRateForm
            estimationCompletionRate={estimationCompletionRate}
            updateEstimationCompletionRate={updateEstimationCompletionRate}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            margin: '10px 0',
          }}
        >
          <Box
            sx={{
              width: '70%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '10%',
            }}
          >
            <TotalRewardForm totalReward={totalReward} />

            <RestRewardForm
              totalReward={totalReward}
              fixedResultSum={fixedResultSum}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: '35%',
            margin: '10px 0',
          }}
        >
          <ManagerForm
            manager={manager}
            updateManager={updateManager}
          />
        </Box>
      </Box>

      <Box
        sx={{
          margin: '10px 0',
        }}
      >
        <StatusForm
          status={status}
          onChangeStatus={onChangeStatus}
        />
      </Box>
    </Box>

    <Box
      sx={{
        margin: '10px 0',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        columnGap: '10%',
        alignItems: 'center',
      }}
    >
      <NoticeForm
        notice={notice}
        isNoticeExposed={isNoticeExposed}
      />
    </Box>

    <Box>
      <TextTip>
        (?) 심사 담당자는 제안 제출 시 자동으로 선택된 심사 담당자로 지정됩니다 (추후 제안건 별
        심사담당자 변경 가능)
      </TextTip>
    </Box>
  </Section>
);

const CampaignForm = memo(CampaignUpdateForm);

export default CampaignForm;
