import { affiliatedCompanySalesActivityQuery } from '@front/src/features/affiliated-company/features/sales-activity/query/query';

export { repository as affiliatedCompanySalesActivityRepository };
const repository = {
  useListGet: (id: number, menuId) => {
    const { data } = affiliatedCompanySalesActivityQuery.useListGet(id, menuId);
    return {
      data,
    };
  },
};
