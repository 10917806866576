import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { AxiosError } from 'axios';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workCarouselMutation = {
  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, AxiosError, number>({
      mutationFn: (id) => workWorkApi.deleteCarousel(id, commonParams),
      mutationKey: ['work', 'carousel', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useDeleteAll: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, AxiosError, number>({
      mutationFn: (id) => workWorkApi.deleteAllCarousel(id, commonParams),
      mutationKey: ['work', 'carousel', 'delete-all'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workCarouselMutation;
