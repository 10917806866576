import { useForm } from 'react-hook-form';
import { getSpareParameter } from '@front/src/utils';

import { useEffect } from 'react';

const useWorkOutputTableRowForm = ({ onUpdate, item }) => {
  const methods = useForm<TFieldValues>({
    defaultValues: {
      ...getFormValues(item),
      openImageModal: false,
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      id: item.id,
      ...getSpareParameter(data),
    });
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
      openImageModal: false,
    });
  }, [item]);
  return {
    methods,
    onSubmit,
  };
};

export default useWorkOutputTableRowForm;

interface TFieldValues {
  id: number;
  textAttr1: string;
  textAttr2: string;
  textAttr3: string;
  numberAttr1: number;
  numberAttr2: number;
  numberAttr3: number;
  dateAttr1: string;
  dateAttr2: string;
  dateAttr3: string;
  openImageModal: boolean;
}

function getFormValues(item) {
  return {
    id: item.id,
    textAttr1: item.textAttr1 ?? '',
    textAttr2: item.textAttr2 ?? '',
    textAttr3: item.textAttr3 ?? '',
    numberAttr1: item.numberAttr1,
    numberAttr2: item.numberAttr2,
    numberAttr3: item.numberAttr3,
    dateAttr1: item.dateAttr1 ?? '',
    dateAttr2: item.dateAttr2 ?? '',
    dateAttr3: item.dateAttr3 ?? '',
  };
}
