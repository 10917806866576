import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonRelationshipMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/query/mutation';
import { affiliatedCompanyPersonRelationshipQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/query/query';

export const affiliatedCompanyPersonRelationshipRepository = {
  usePersonRelationshipGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPersonRelationshipQuery.usePersonRelationshipGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonRelationshipCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonRelationshipMutation.usePersonRelationshipCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonRelationshipUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonRelationshipMutation.usePersonRelationshipUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonRelationshipDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonRelationshipMutation.usePersonRelationshipDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
