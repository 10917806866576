import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { projectSalesInfoBidResultUpdateModalRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/repository';

export { useLogic as useProjectSalesInfoBidResultUpdateModalLogic };
const useLogic = (menuId) => {
  const detailId = useProjectSalesInfoBidResultState((state) => state.detailId);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: bidResultDetail, isLoading } =
    projectSalesInfoBidResultUpdateModalRepository.useProjectSalesInfoBidResultDetailGet(
      detailId!,
      menuId
    );
  const { run: onUpdate } =
    projectSalesInfoBidResultUpdateModalRepository.useProjectSalesInfoBidResultDetailUpdate(
      id!,
      menuId
    );
  return {
    h: {
      onUpdate,
    },
    d: {
      bidResultDetail,
    },
    isLoading,
  };
};
