import React, { Fragment, MouseEvent, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Menu, MenuItem } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import type { ControlPanelItem } from '@front/src/types/domain';
import Tooltip from '@front/components/Tooltip';

interface Props {
  itemList?: ControlPanelItem[];
  isEditOpen?: boolean;
  isHeader?: boolean;
  isPropagation?: boolean;
  onClick?: () => void;
}

export const ControlPanel = ({ itemList, isEditOpen, isHeader, isPropagation, onClick }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuRef = useRef<HTMLDivElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    isPropagation && event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onClick?.();
  };
  const handleClose = (e, onAction?: () => void) => {
    isPropagation && e.stopPropagation();
    onAction?.();
    setAnchorEl(null);
    // MUI 버그 임시 해결
    if (menuRef.current && menuRef.current.style) {
      menuRef.current.style.visibility = 'hidden';
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="text"
        sx={{
          minWidth: '20px',
          padding: 0,
        }}
      >
        {isHeader ? <AddBoxIcon /> : <MoreVertIcon sx={{ ...SX_MORE_VERTICAL_ICON }} />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        ref={menuRef}
      >
        {itemList?.map((item) => {
          if (item.text === '') return;
          return (
            <Tooltip
              title={item.disabled ? item.tooltip : ''}
              key={item.text}
            >
              <MenuItem
                onClick={(e) => handleClose(e, item.action)}
                disabled={(isEditOpen && item.text === '상세') || item.disabled}
              >
                <>
                  <item.icon
                    sx={{
                      paddingRight: '5px',
                      color: 'var(--design-v-10-light-main-main-primary, #2F6ACA)',
                    }}
                  />
                  {item.text}
                </>
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

const SX_MORE_VERTICAL_ICON = {
  color: 'var(--design-v-10-light-main-main-primary, #2F6ACA)',
};
