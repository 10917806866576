import { createContext } from 'react';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/types/view';

export { Context as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalContext };

interface State {
  d: {
    detail?: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalView;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {},
  isLoading: false,
});
