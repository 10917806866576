import React, { useContext, useEffect } from 'react';
import Button from '@front/layouts/Button';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { modalLayout } from '@front/src/components/layout/modal';
import {
  convertContractContractDataForParameter,
  isPossibleSave,
} from '@front/src/features/project-sales-info/features/contract/features/contract/utils';
import { useSnackbar } from '@front/src/features/snackbar';
import { convertYNToBooleanForParameter } from '@front/src/utils';
import { convertHasVatForParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils';
import { ProjectSalesInfoContractContractCreateModalContext } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/context';
import { useProjectSalesInfoContractContractState } from '@front/src/features/project-sales-info/features/contract/features/contract/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { UpdateModalButtonGroup as ProjectSalesInfoContractContractCreateModalButtonGroup };

const UpdateModalButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    d: { contractConfirmed },
    h: { onClose, onCreate },
  } = useContext(ProjectSalesInfoContractContractCreateModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext();
  const { show } = useSnackbar();
  const hasError = Object.keys(errors).length > 0;
  useEffect(() => {
    if (hasError) {
      show({
        message: '필수값 입력 요망',
      });
    }
  }, [hasError, show]);
  const onSubmit = () => {
    if (!watch('orderCompany')) {
      show({
        message: '필수값 입력 요망',
      });
    } else {
      handleSubmit((data) => {
        const isConfirmed = convertYNToBooleanForParameter(data.isConfirmed);
        const hasVat = convertHasVatForParameter(data.hasVat);
        if (isConfirmed && !isPossibleSave(hasVat, contractConfirmed?.hasVat)) {
          show({
            message: '부가세 선택 값이 기존 확정된 계약서와 다를 경우 저장할 수 없습니다.',
          });
          return;
        }
        onCreate({
          id,
          ...convertContractContractDataForParameter(data),
        });
      })();
    }
  };
  const { readOnly } = useProjectSalesInfoContractContractState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </Footer>
  );
};
