import React from 'react';
import { MobileNotificationListItemProvider } from '@front/src/features/mobile-notification/widgets/list/widgets/item/provider/provider';
import { MobileNotificationListItem } from '@front/src/features/mobile-notification/widgets/list/widgets/item/components/mobile-notification-list-item';
import type { UserNotificationView } from '@front/src/features/mobile-notification/types/view';

export { Widget as MobileNotificationListItemWidget };

interface Props {
  item: UserNotificationView;
  refs: {
    leadingRef: React.RefObject<HTMLDivElement[] | null[]>;
    trailingRef: React.RefObject<HTMLDivElement[] | null[]>;
  };
  idx: number;
}

const Widget = ({ item, refs, idx }: Props) => (
  <MobileNotificationListItemProvider>
    <MobileNotificationListItem
      item={item}
      refs={refs}
      idx={idx}
    />
  </MobileNotificationListItemProvider>
);
