import React, { memo, useCallback } from 'react';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import type {
  ProposalArrangementInformationStatusParameter,
  ProposalStatusUpdateParameter,
} from '@front/ost_proposal/parameter';
import useDialog from '@front/dialog/hook';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';

interface Props {
  updateStatus: (status: ProposalStatusUpdateParameter) => void;
}

const ProposalController = ({ updateStatus: updateProposalStatus }: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (state: RootState) => state.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  const onUpdateProposalArrangementInformationStatus = useCallback(
    (params: ProposalArrangementInformationStatusParameter) =>
      dispatch(proposalAction.updateProposalArrangementInformationStatus(params)),
    [dispatch]
  );

  const openAdvisorModal = useCallback(() => {
    dispatch(proposalAction.setAdvisorModal(true));
  }, [dispatch]);
  return (
    <>
      {(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            columnGap: '10px',
          }}
        >
          <Button
            shape="basic1"
            onClick={openAdvisorModal}
          >
            협의자 지정
          </Button>
          {!(
            arrangementStatus === ProposalArrangementInformationStatus.SKIP ||
            arrangementStatus === ProposalArrangementInformationStatus.COMPLETE
          ) && (
            <Button
              shape="basic1"
              onClick={() => {
                if (
                  status === ProposalStatus.WRITING &&
                  arrangementStatus === ProposalArrangementInformationStatus.WAIT
                ) {
                  confirm({
                    children: '협의자 지정을 안 하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      onUpdateProposalArrangementInformationStatus({
                        status: ProposalArrangementInformationStatus.SKIP,
                      });
                    },
                  });
                  return;
                }
                if (
                  status === ProposalStatus.SUBMISSION &&
                  arrangementStatus === ProposalArrangementInformationStatus.WAIT
                ) {
                  confirm({
                    children: '협의 생략시 심사로 이동합니다. 협의를 생략하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      updateProposalStatus({
                        status: ProposalStatus.ESTIMATING,
                      });
                    },
                  });
                  return;
                }

                if (
                  status === ProposalStatus.SUBMISSION &&
                  arrangementStatus === ProposalArrangementInformationStatus.ON_GOING
                ) {
                  confirm({
                    children: '진행중인 협의가 있습니다. 협의를 생략하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      updateProposalStatus({
                        status: ProposalStatus.ESTIMATING,
                      });
                    },
                  });
                  return;
                }

                if (
                  status === ProposalStatus.WRITING &&
                  arrangementStatus === ProposalArrangementInformationStatus.ON_GOING
                ) {
                  confirm({
                    children: '진행중인 협의가 있습니다. 협의를 생략하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      onUpdateProposalArrangementInformationStatus({
                        status: ProposalArrangementInformationStatus.SKIP,
                      });
                    },
                  });
                  return;
                }
              }}
            >
              협의 생략
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
const memoized = memo(ProposalController);

export { memoized as OstProposalController };
