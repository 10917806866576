import { useMutation, useQueryClient } from 'react-query';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';

export { mutation as personalSettingsModalUISettingsModalSettingsMutation };
const mutation = {
  useUpdate: (authMenuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params) => personalSettingsModalApi.updateForModalSettings(params, authMenuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries([]);
      },
    });
    return {
      mutate,
    };
  },
};
