import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyCharacterCharacterRepository } from '@front/src/features/affiliated-company/features/character/features/character/repository/repository';
import { useAffiliatedCompanyCharacterCharacterState } from '@front/src/features/affiliated-company/features/character/features/character/widgets/useState';

export { useLogic as useAffiliatedCompanyCharacterCharacterLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyCharacterCharacterState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { data: characterList, isLoading } =
    affiliatedCompanyCharacterCharacterRepository.useCharacterCharacterGet(id, menuId);
  const { run: onCharacterCreate } =
    affiliatedCompanyCharacterCharacterRepository.useCharacterCharacterCreate(menuId);
  const { run: onCharacterUpdate } =
    affiliatedCompanyCharacterCharacterRepository.useCharacterCharacterUpdate(menuId);
  const { run: onCharacterDelete } =
    affiliatedCompanyCharacterCharacterRepository.useCharacterCharacterDelete(menuId);
  const h = {
    onCharacterCreate: () => onCharacterCreate(id),
    onCharacterUpdate,
    onCharacterDelete,
    setIdList,
  };
  const d = { characterList };
  return {
    d,
    h,
    isLoading,
    idList,
  };
};
