import React from 'react';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { ProjectSalesInfoBidResultCreateModalWidget } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoBidResultCreateModalWrapper };
interface Props {
  menuId?: number;
}
const ModalWrapper = ({ menuId }: Props) => {
  const isCreateModalOpen = useProjectSalesInfoBidResultState((state) => state.isCreateModalOpen);
  return <>{isCreateModalOpen && <ProjectSalesInfoBidResultCreateModalWidget menuId={menuId} />}</>;
};
