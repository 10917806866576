import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import ContextMenuNewIconUI from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  index: number;
}

export default function AccountingAccountManagementAccountSettingsManagementAccountTableHeadMenu({
  index,
}: Props) {
  const { watch, setValue } = useFormContext<ManagementAccountTableFormValues>();

  const showManual = watch('showManual');

  const onToggle = () => {
    if (showManual) {
      setValue('showManual', false);
    } else {
      setValue('showManual', true);
    }
  };

  const itemList = [
    {
      text: showManual ? '한풍메뉴얼 끄기' : '한풍메뉴얼 켜기',
      IconComponent: showManual ? (
        <FontAwesomeIcon icon="eye-slash" />
      ) : (
        <FontAwesomeIcon icon="eye" />
      ),
      action: onToggle,
      seq: 1,
    },
  ];

  if (index === 0) {
    return <ContextMenuNewIconUI itemList={itemList} />;
  }
  return <></>;
}
