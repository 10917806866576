import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import AccountStatusRepository
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/repository';
import AccountStatusWidgetSummaryTable
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/summary-table';

type ElementType = (props: Readonly<SectionComponentProps>) => React.ReactElement;

const AccountStatusSummaryTableFeature: ElementType = (props) => {

  const { name, menuId, sectionId } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  if (!menuId) {
    return (<LoadingSpinnerUI />);
  }
  const { data } = AccountStatusRepository.useGetDate({ menuId, sectionId });

  const TitleRight = data ? (
    <Box>조회일: {dayjs(data).format('YYYY년 MM월 DD일')}</Box>
  ) : undefined;

  return (
    <Layout>
      <TitleWrap left={name} right={TitleRight} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <AccountStatusWidgetSummaryTable {...props} />
      </Suspense>
    </Layout>
  );
};

export default AccountStatusSummaryTableFeature;