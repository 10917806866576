import { default as axios } from '@front/src/config/axios';

class UserNotificationApi {
  async count(): Promise<number> {
    const { data } = await axios.get('/api/user-notification/count');
    return data;
  }
}

export const userNotificationApi = new UserNotificationApi();
