import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputBundleMutation from '@front/src/features/work/features/work/features/output-bundle/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkOutputBundleUpdate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workOutputBundleMutation.useUpdate(commonParams);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkOutputBundleUpdate;
