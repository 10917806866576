import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoBidInfoCreateModalRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/repository';

export { useLogic as useProjectSalesInfoBidInfoCreateModalLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);

  const { data: estimationIdList } =
    projectSalesInfoBidInfoCreateModalRepository.useEstimateIdListGet(id!, menuId);
  const { run: onCreate } =
    projectSalesInfoBidInfoCreateModalRepository.useProjectSalesInfoBidInfoDetailCreate(
      id!,
      menuId
    );
  return {
    h: {
      onCreate,
    },
    d: {
      estimationIdList,
    },
  };
};
