import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system';

interface Props {
  children: ReactNode;
}

export default function CustomSnackbarProvider({ children }: Readonly<Props>) {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      dense={true}
      iconVariant={{
        error: (
          <IconBox
            sx={{
              backgroundColor: ColorPalette.sub2.sub2_tertiary,
            }}
          >
            <FontAwesomeIcon
              icon="exclamation"
              fontSize="12px"
              color={ColorPalette.sub2.sub2_primary}
            />
          </IconBox>
        ),
        warning: (
          <IconBox
            sx={{
              backgroundColor: ColorPalette.sub.sub_quaternary,
            }}
          >
            <FontAwesomeIcon
              icon="exclamation"
              fontSize="12px"
              color={ColorPalette.sub.sub_primary}
            />
          </IconBox>
        ),
        info: (
          <IconBox
            sx={{
              backgroundColor: ColorPalette.main.main_tertiary,
            }}
          >
            <FontAwesomeIcon
              icon="info"
              color={ColorPalette.main.main_primary}
              fontSize="12px"
            />
          </IconBox>
        ),
        success: (
          <IconBox
            sx={{
              backgroundColor: ColorPalette.main.main_tertiary,
            }}
          >
            <FontAwesomeIcon
              icon="check"
              color={ColorPalette.main.main_primary}
              fontSize="12px"
            />
          </IconBox>
        ),
      }}
      Components={{
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

interface Props {
  sx?: SxProps;
  children: ReactNode;
}

const IconBox = ({ children, sx = {} }: Props) => (
  <Box
    sx={{
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100px',
      ...sx,
    }}
  >
    {children}
  </Box>
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    width: '300px',
    height: '40px',
    padding: '10px 14px',

    borderRadius: '5px',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: ColorPalette.sub2.sub2_primary,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: ColorPalette.sub.sub_primary,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: ColorPalette.main.main_primary,
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: ColorPalette.main.main_primary,
  },
  '& #notistack-snackbar': {
    padding: '0',
    gap: '10px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    textAlign: 'left',
  },
}));
