import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { MultipleSelect } from '@front/src/components/Select/multiple-select';

export { HookFormMultipleSelect };

interface Props {
  name: string;
  optionList: string[];
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  width?: string;
  height?: string;
  onChange?: (e) => void;
  disabled?: boolean;
  readOnly?: boolean;
  sortFn?: (a, b) => void;
}

const HookFormMultipleSelect = ({ name, onSubmit, onChange, sortFn, ...rest }: Props) => {
  const { control, getValues } = useFormContext();
  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control });
  const handleChange = useCallback(
    async (e) => {
      const newE = sortFn
        ? {
            ...e,
            target: { ...e.target, value: e.target.value.sort(customSort) },
          }
        : e;
      onHookFormChange(newE);
      onChange?.(newE);
      onSubmit?.();
    },
    [onChange, onHookFormChange, onSubmit, sortFn]
  );
  return (
    <MultipleSelect
      checkedList={getValues(name)}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};

const customSort = (a, b) => {
  const order = { F: 1, P: 2, A: 3 };
  return order[a] - order[b];
};
