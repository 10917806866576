import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkCarouselForm from '@front/src/features/work/features/work/features/carousel/components/Form';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import type { WorkCarouselBundleView } from '@front/src/features/work/features/work/features/carousel-bundle/types/view';

export interface WorkCarouselSectionProps extends SectionComponentProps {
  item: WorkCarouselBundleView;
  index: number;
}

export default function WorkCarouselFeature(props: Readonly<WorkCarouselSectionProps>) {
  const { Layout } = SectionWrapperUI;
  return (
    <Layout
      sx={{
        minHeight: '450px',
      }}
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkCarouselForm {...props} />
      </Suspense>
    </Layout>
  );
}
