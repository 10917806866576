import axios from 'axios';
import type { ProjectReviewSavePaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/parameter';
import { saveHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/save-history/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getSaveHistory: (id: number) => `${saveHistoryApiUrl}/${id}/review-amount-save-history`,
  updateSaveHistory: (id: number) => `${saveHistoryApiUrl}/review-amount-save-history/${id}`,
};

export const projectSalesInfoCollectionSaveHistoryApi = {
  getSaveHistory: async (id: number, menuId) => {
    const { data } = await axios.get(url.getSaveHistory(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateSaveHistory: async (params: ProjectReviewSavePaymentHistoryParams, menuId) =>
    await axios.put(url.updateSaveHistory(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
};
