import {createAction, createReducer} from 'typesafe-actions';
import {FileItemView} from "@front/file-item";

enum ImageDrawAction {
  setImageDrawFileKey = 'image-draw/setImageDrawFileKey',
  setImageDrawIsLoading = 'image-draw/setImageDrawIsLoading',
}

export const imageDrawAction = {
  setImageFileItem: createAction(ImageDrawAction.setImageDrawFileKey)<ImageDrawState | undefined>(),
  setImageDrawIsLoading: createAction(ImageDrawAction.setImageDrawIsLoading)<boolean>()
}

export interface ImageDrawState {
  fileKey?: string | undefined;
  fileItem?: FileItemView | undefined;
  isLoading?: boolean;
}

const initialState: ImageDrawState = {
  fileKey: undefined,
  fileItem: undefined,
  isLoading: false
};

const initialHandlers = {
  [ImageDrawAction.setImageDrawFileKey]: (state: ImageDrawState, action) => ({
    ...state,
    fileKey: action?.payload?.fileKey,
    fileItem: action?.payload?.fileItem,
    isLoading: action?.payload,
  }),
  [ImageDrawAction.setImageDrawIsLoading]: (state: ImageDrawState, action) => ({
    ...state,
    isLoading: action?.payload,
  })
};

export const imageDrawReducer = createReducer(initialState, initialHandlers);