import type { FocusEvent } from 'react';
import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { FormikContext } from 'formik';

import { ColorPalette } from '@front/assets/theme';
import type {
  OstRewardManagementMemoId,
  OstRewardManagementMemoView,
} from '@front/ost_reward/domian';
import type { OstRewardManagementMemoUpdateParameter } from '@front/ost_reward/parameter';
import { OstRewardManagementMemoListItemTitle } from '@front/ost_reward/view/memo/list-item-title';
import { OstRewardManagementMemoListItemContent } from '@front/ost_reward/view/memo/list-item-content';
import { OstRewardManagementMemoListItemUpdateForm } from '@front/ost_reward/view/memo/list-item-update-form';

export { ListItem as OstRewardManagementMemoListItem };

interface Props {
  onModify: (params: OstRewardManagementMemoUpdateParameter) => () => void;
  onDelete: (id: OstRewardManagementMemoId) => () => void;
  onSave: (id: OstRewardManagementMemoId) => () => void;
  onCancel: (id: OstRewardManagementMemoId) => () => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  memo: OstRewardManagementMemoView;
}

const ListItem = ({ onModify, onDelete, onSave, onCancel, onBlur, memo }: Props) => {
  const formik = useContext(FormikContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        border: `1px solid ${ColorPalette._e4e9f2}`,
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: ColorPalette._ffffff,
        padding: '15px',
      }}
    >
      <OstRewardManagementMemoListItemTitle
        memo={memo}
        onModify={onModify}
        onDelete={onDelete}
      />
      {formik.values.id !== memo.id && <OstRewardManagementMemoListItemContent memo={memo} />}
      {formik.values.id === memo.id && (
        <OstRewardManagementMemoListItemUpdateForm
          memo={memo}
          onSave={onSave}
          onCancel={onCancel}
          onBlur={onBlur}
        />
      )}
    </Box>
  );
};
