import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React from 'react';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import type { EstimationCompetitorView } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/types/views';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';

interface Props extends CellComponentProps<EstimationCompetitorView> {}

/**
 * No.
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="seq"
  />
);

/**
 * 견적일자
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="estimationDate"
  />
);

/**
 * 협력사
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="competitorCompany"
  />
);

/**
 * 실험대상
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellInputText
    {...props}
    name="testPlan"
  />
);

/**
 * 한풍기준총액차이
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="totalPriceDiff"
  />
);

/**
 * 경쟁사총액
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="competitorTotalPrice"
  />
);

/**
 * 풍동금액
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="windTunnelPrice"
  />
);

/**
 * 구검비
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewPrice"
  />
);

/**
 * 순동단가
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="pureUnitPrice"
  />
);

/**
 * 소요기간(주)
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => {
  const { onClick, sx, isEditMode, onSubmit, isForm, readOnly } = props;

  const { getValues } = useFormContext();
  const requiredWeeksStart = getValues('requiredWeeksStart');
  const requiredWeeksEnd = getValues('requiredWeeksEnd');

  const renderContent = () => {
    if (readOnly || !isEditMode) return `${requiredWeeksStart ?? '-'}/${requiredWeeksEnd ?? '-'}`;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UncontrolledInput
          type="number"
          name="requiredWeeksStart"
          onBlur={isForm ? undefined : onSubmit}
          readOnly={readOnly || !isEditMode}
        />
        /
        <UncontrolledInput
          type="number"
          name="requiredWeeksEnd"
          onBlur={isForm ? undefined : onSubmit}
          readOnly={readOnly || !isEditMode}
        />
      </Box>
    );
  };

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

/**
 * 견적서파일(PDF)
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <UIBuilderTableCellFileAttachment
    {...props}
    name="estimationDocument"
  />
);

/**
 * 담당자
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => (
  <UIBuilderTableCellUserSelector
    {...props}
    name="createdBy"
  />
);

/**
 * 등록일
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    readOnly={true}
    name="createdAt"
  />
);

export const estimationCompetitorTableCells: CellComponent = {
  366: CellRenderer1, // No.
  367: CellRenderer2, // 견적일자
  368: CellRenderer3, // 경쟁업체명
  369: CellRenderer4, // 실험대상
  370: CellRenderer5, // 한풍기준총액차이
  371: CellRenderer6, // 경쟁사총액
  372: CellRenderer7, // 풍동금액
  373: CellRenderer8, // 구검비
  374: CellRenderer9, // 순동단가
  375: CellRenderer10, // 소요기간(주)
  376: CellRenderer11, // 견적서파일(PDF)
  377: CellRenderer12, // 담당자
  378: CellRenderer13, // 등록일
  ...generateSpareAttrCellRenders(424),
};
