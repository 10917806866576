import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  width?: string;
  height?: string;
}

export default function LoadingSpinnerUI({ width = '100%', height = '100%' }: Props) {
  return (
    <Box
      width={width}
      height={height}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        background: ColorPalette.etc.loading_spinner,
      }}
    >
      <CircularProgress
        style={{
          width: '40px',
          height: '40px',
          strokeLinecap: 'round',
          strokeWidth: 4,
          color: ColorPalette.greyscale.white,
        }}
      />
      {/*sx={{
          position: 'absolute',
          zIndex: 1,
          strokeLinecap: 'round',
          strokeWidth: 4,
          width: '40px',
          height: '40px',
          color: ColorPalette.greyscale.white,
        }}
        {...rest}
      />
      <Box
        sx={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: `3.5px solid ${ColorPalette.background.bg02}`,
        }}
      />*/}
    </Box>
  );
}
