import React, { useContext } from 'react';
import { AccountingSettingsManagementAccountingAccountTableHeaderContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/table-header/provider/context';
import { accountingSettingsManagementAccountingAccountTableLayout } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table';

export { TableHeader as AccountingSettingsManagementAccountingAccountTableHeader };

const TableHeader = () => {
  const { TableHeader, TableHeaderCell } = accountingSettingsManagementAccountingAccountTableLayout;
  const { detail } = useContext(AccountingSettingsManagementAccountingAccountTableHeaderContext);
  return (
    <TableHeader>
      <TableHeaderCell>통장명 ({detail?.depth0Count})</TableHeaderCell>
      <TableHeaderCell>대분류 ({detail?.depth0Count})</TableHeaderCell>
      <TableHeaderCell>중분류 ({detail?.depth1Count})</TableHeaderCell>
      <TableHeaderCell>소분류 ({detail?.depth2Count})</TableHeaderCell>
      <TableHeaderCell>미분류 ({detail?.depth3Count})</TableHeaderCell>
    </TableHeader>
  );
};
