import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { personalSettingsModalUISettingsModalSettingsQuery } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/query/query';
import { personalSettingsModalUISettingsModalSettingsMutation } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/query/mutation';

export { repository as personalSettingsModalUISettingsModalSettingsRepository };
const repository = {
  useGetHeaderList: (sectionId, authMenuId) => {
    const { data } = personalSettingsModalUISettingsModalSettingsQuery.useGetHeaderList(
      sectionId,
      authMenuId
    );
    return {
      data,
    };
  },
  useGetModalHeaderList: (sectionId, authMenuId) => {
    const { data } = personalSettingsModalUISettingsModalSettingsQuery.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
    return {
      // 컬럼 seq를 따르도록 sort 하지 않는다(사용자 혼란 방지)
      data//: data?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
    };
  },
  useUpdate: (authMenuId) => {
    const callback = getNoOpCallback();
    const { mutate } = personalSettingsModalUISettingsModalSettingsMutation.useUpdate(authMenuId);
    return {
      run: (params) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
