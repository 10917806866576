import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

interface Props extends BoxProps {
  isHead?: boolean;
}

export default function VirtualizedAutoSizerTableCellUI({
  isHead = false,
  sx = {},
  ...rest
}: Readonly<Props>) {
  return (
    <Box
      {...rest}
      sx={{
        width: '100%',
        height: '32px',
        lineHeight: '2.6rem',
        fontSize: '1.3rem',
        fontWeight: isHead ? 600 : 400,
        textAlign: 'center',
        background: isHead ? ColorPalette.background.bg02 : 'transparent',
        borderRight: `1px solid ${ColorPalette.line.line}`,
        borderBottom: `1px solid ${ColorPalette.line.line}`,
        padding: '0.4rem 0.4rem',

        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:last-child': {
          borderRight: `0px`,
        },
        ...sx,
      }}
    />
  );
}
