import React, { useContext, useMemo } from 'react';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { Box } from '@mui/material';
import { Td } from '@front/src/components/layout/table/td';

export { Note as SalesInfoActivityTableRowNote };

const Note = () => {
  const { item, onUpdate } = useContext(SalesInfoActivityRowContext);
  const { id, setNote } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
      setNote: state.setNote,
    }))
  );
  const methods = useForm({
    values: {
      note: item.activity?.note ?? '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    setNote(data.note);
    onUpdate({
      id: item.activity?.id,
      date: item.activity?.date,
      time: item.activity?.time,
      category: item.activity?.category,
      note: data.note,
    });
  });
  const isSelected = useMemo(() => item.activity?.id === id, [item, id]);
  return (
    <FormProvider {...methods}>
      <Td colSpan={12} sx={{padding:'10px'}}>
        {isSelected ? (
          <HookFormTextarea
            name="note"
            onBlur={onSubmit}
            placeholder="비고"
            minRows={4}
            resize="none"
            variable={true}
            sx={{margin:'-5px', padding:'5px'}}
          />
        ) : (
          <Box
            sx={{
              maxWidth: '1600px',
              height: '100%',
              textAlign: 'start',
              overflowWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {item.activity?.note}
          </Box>
        )}
      </Td>
    </FormProvider>
  );
};
