import { useMyPageAuthorizationState } from '@front/src/features/my-page/features/authorization/useState';
import { useShallow } from 'zustand/react/shallow';
import myPageAuthorizationQuery from '@front/src/features/my-page/features/authorization/query/query';

export { useLogic as useMyPageAuthorizationTabLogic };
const useLogic = (menuId) => {
  const { tabId } = useMyPageAuthorizationState(
    useShallow((state) => ({
      tabId: state.tabId,
    }))
  );
  const { data: list } = myPageAuthorizationQuery.useGetTabList(tabId, menuId);
  const { data: adminMenuList } = myPageAuthorizationQuery.useGetAdminMenuList(menuId);
  const { data: notDevelopmentMenuList } = myPageAuthorizationQuery.useGetNotDevelopmentMenuList(
    tabId,
    menuId
  );
  return { list, adminMenuList, notDevelopmentMenuList };
};
