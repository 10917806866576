import React, { useContext, useMemo } from 'react';
import { AccountingUploadSalesInfoInfoText } from '@front/src/features/accounting/features/upload/features/sales-info/components/info-text';
import { Box } from '@mui/material';
import { convertDateToStringFormat, DATE_TIME_FORMAT, getValueIfExist } from '@front/src/utils';
import { AccountingUploadSalesInfoGetYearContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/context';
import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';

export { TargetModifiedAt as AccountingUploadSalesInfoGetYearTargetModifiedAt };

const TargetModifiedAt = () => {
  const { list } = useContext(AccountingUploadSalesInfoGetYearContext);
  const targetList = useMemo(
    () => list?.filter((l) => l.subType === SalesAccountingAmountSubType.TARGET),
    [list]
  );
  const value = useMemo(() => {
    if (!targetList || targetList?.length === 0) {
      return <Box>등록된 목표 데이터 없음</Box>;
    }
    const modifiedAt = targetList?.[0]?.modifiedAt ?? targetList?.[0]?.createdAt;
    const writerName = targetList?.[0]?.writer?.name;
    return (
      <>
        <Box>{convertDateToStringFormat(modifiedAt, DATE_TIME_FORMAT)}</Box>
        <Box>{getValueIfExist(writerName)}</Box>
      </>
    );
  }, [targetList]);
  return (
    <AccountingUploadSalesInfoInfoText
      label="목표 업데이트 일시"
      value={value}
    />
  );
};
