import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';

export const getManagementAccountParentId = (
  showList: ManagementAccountingTreeView[][],
  selectedRowIndexList: { value: number }[],
  colIndex?: number
) => {
  if (!colIndex) return null;

  if (showList.length === 1) return null;

  return showList[colIndex - 1]?.[selectedRowIndexList[colIndex - 1]?.value]?.id ?? null;
};
