import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { projectSalesInfoContractEarningsItemCreateModalMutation } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/query/mutation';
import { projectSalesInfoContractEarningsItemCreateModalQuery } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/query';

export const projectSalesInfoContractEarningsItemCreateModalRepository = {
  useContractEarningsItemCreate: (itemId: number, id: number, menuId) => {
    const { mutate } =
      projectSalesInfoContractEarningsItemCreateModalMutation.useContractEarningsItemCreate(
        itemId,
        id,
        menuId
      );
    const callback = getNoOpCallback();
    return {
      run: (params: SmProjectCollectionParameter) => mutate({ params, itemId }, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useConsortiumCompanyGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoContractEarningsItemCreateModalQuery.useConsortiumCompanyGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useConfirmedContractGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoContractEarningsItemCreateModalQuery.useConfirmedContractGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
};
