import { Box } from '@mui/material';
import Button from 'layouts/Button';
import React, { useContext } from 'react';
import type { DefaultFunction } from 'type/Function';
import { FormikContext } from 'formik';

interface Props {
  onClose: DefaultFunction;
  onResetPassword: DefaultFunction;
  onLogout: DefaultFunction;
}

export default function (props: Props) {
  const formik = useContext(FormikContext);
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          gap: '1rem'
        }}
      >
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          계정정보 변경
        </Button>
        <Button
          onClick={props.onClose}
        >
          취소
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'nowrap',
          gap: '1rem'
        }}
      >
        <Button
          shape="basic5"
          onClick={props.onLogout}
        >
          로그아웃
        </Button>
        <Button
          shape="basic3"
          onClick={props.onResetPassword}
        >
          비밀번호 변경
        </Button>
      </Box>
    </Box>
  );
}
