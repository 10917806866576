import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyOutlineBankInfoMutation } from '@front/src/features/affiliated-company/features/outline/features/bank-info/query/mutation';
import { affiliatedCompanyOutlineBankInfoQuery } from '@front/src/features/affiliated-company/features/outline/features/bank-info/query';

export const affiliatedCompanyOutlineBankInfoRepository = {
  useBankInfoUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineBankInfoMutation.useBankInfoUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useBankBookFileUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineBankInfoMutation.useBankBookFileUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useBankNameOptionListGet: (menuId) => {
    const { data } = affiliatedCompanyOutlineBankInfoQuery.useBankNameOptionListGet(menuId);
    return { data };
  },
};
