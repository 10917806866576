import { createContext } from 'react';
import type { SalesAccountingAmountInformationExpectPerformanceView } from '@front/src/features/accounting/features/upload/features/sales-info/types/view';

export { Context as AccountingUploadSalesInfoPerformanceModalListContext };

interface State {
  list?: SalesAccountingAmountInformationExpectPerformanceView[];
  isConfirmed?: boolean;
}

const Context = createContext<State>({});
