import { createContext } from 'react';
import type { TaxAccountingCountView } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { Context as AccountingSettingsTexAccountingAccountGetCountContext };

interface State {
  detail?: TaxAccountingCountView;
}

const Context = createContext<State>({});
