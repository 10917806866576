import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import { projectSalesInfoBidResultCreateModalMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/query/mutation';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';

export const projectSalesInfoBidResultCreateModalRepository = {
  useProjectSalesInfoBidResultDetailCreate: (id: number, menuId) => {
    const { mutate } =
      projectSalesInfoBidResultCreateModalMutation.useProjectSalesInfoBidResultCreate(id, menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectSalesInfoBidResultParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
