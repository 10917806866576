import React, { Suspense } from 'react';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { PersonalSettingsModalAccordion } from '@front/src/features/personal-settings-modal/components/settings-accordion';
import { PersonalSettingsModalAuthSettingsProvider } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/provider/provider';
import { PersonalSettingsModalAuthSettings } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/components/user-settings';

export { Widget as PersonalSettingsModalAuthSettingsWidget };
interface Props {
  authMenuId: number | undefined;
}
const Widget = ({ authMenuId }: Props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <PersonalSettingsModalAuthSettingsProvider authMenuId={authMenuId}>
      <PersonalSettingsModalAccordion />
      <PersonalSettingsModalAuthSettings authMenuId={authMenuId} />
    </PersonalSettingsModalAuthSettingsProvider>
  </Suspense>
);
