import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import type { BoxProps } from '@mui/material';
import { Box, Tooltip } from '@mui/material';
import { ColorPalette } from 'assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { UserId, UserVO } from 'user/domain';
import { userApi } from 'user/api';

interface Props {
  user: UserId | UserVO | string;
  onClick?: (userId?: UserId) => void;
  sx?: BoxProps['sx'];
  defaultImage?: boolean;
  imageSx?: CSSProperties;
  userOne?: UserVO;
}

export default function UserIcon({ user, onClick, sx, defaultImage, imageSx, userOne }: Props) {
  const isPlus = user === 'plus';

  const [value, setValue] = useState<UserVO>();

  useEffect(() => {
    if (typeof user === 'string') {
      return;
    }
    if (userOne) {
      setValue(userOne);
      return;
    }
    if (typeof user === 'number') {
      userApi.getOne(user).then(setValue);
    } else {
      setValue(user);
    }
  }, []);

  return (
    <Box
      onClick={() => {
        if (onClick) {
          onClick(value?.id);
        }
      }}
      sx={{
        display: 'flex',
        width: '25px',
        height: '25px',
        justifyContent: 'center',
        alignItems: isPlus ? 'center' : 'flex-end',
        fontSize: '18px',
        borderRadius: '25px',
        backgroundColor: ColorPalette._e4e9f2,
        color: isPlus ? ColorPalette._386dd6 : ColorPalette._ffffff,
        border: `1px solid ${ColorPalette._e4e9f2}`,
        overflow: 'hidden',
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      }}
    >
      {typeof user === 'string' && !isPlus && <span>{user}</span>}
      {isPlus && <FontAwesomeIcon icon="plus" />}
      {value && (
        <>
          {defaultImage ? (
            <FontAwesomeIcon icon="user" />
          ) : (
            <Tooltip
              title={`${value.department.name} ${value.name}`}
              placement="top"
            >
              {value.profile?.id ? (
                <img
                  alt="프로필 사진"
                  src={`/api/file-item/${value.profile.id}`}
                  style={{
                    objectFit: 'cover',
                    width: '25px',
                    height: '25px',
                    ...imageSx,
                  }}
                />
              ) : (
                <FontAwesomeIcon icon="user" />
              )}
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );
}
