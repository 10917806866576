import { useEffect } from 'react';
import {
  CellComponent,
  register as registerCell,
  unregister as unregisterCell,
} from '@front/src/components/ui-builder/cellComponent';

export const useCellComponentLifeCycleManager = (cellComponents: CellComponent) => {
  Object.keys(cellComponents).forEach((cellId) => {
    registerCell(+cellId, cellComponents[cellId]);
  });

  useEffect(() => {
    return () => {
      Object.keys(cellComponents).forEach((cellId) => {
        unregisterCell(+cellId);
      });
    };
  }, []);
};
