import type { ControlPanelTextListProps } from '@front/src/types/domain';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { DeleteSweep } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoteIcon from '@mui/icons-material/Note';
import { ApprovalDocumentStatus } from '@front/src/types';

export const getCommonControlPanelHeaderItemList = ({ actions }: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop },
    { text: '최하단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddBottom, split: true },
    { text: '전체 삭제', icon: DeleteSweep, action: actions.onRemoveAll, split: true },
    { text: '전체 수정 닫기', icon: HighlightOffIcon, action: actions.onCloseAll },
  ];
};
export const getCommonControlPanelItemList = ({
  actions,
  isEditOpen,
}: ControlPanelTextListProps) => {
  if (!actions) return [];
  return [
    { text: '추가', icon: AddCircleOutlineIcon, action: actions.onAdd },
    { text: isEditOpen ? '수정 닫기' : '수정', icon: EditIcon, action: actions.onEdit, split: true },
    { text: '이동(위)', icon: ArrowUpwardIcon, action: actions.onMoveUp },
    { text: '이동(아래)', icon: ArrowDownwardIcon, action: actions.onMoveDown , split: true},
    { text: '비고', icon: NoteIcon, action: actions.onNoteOpen },
    { text: '삭제', icon: DeleteIcon, action: actions.onRemove, iconColor: '#e43333'},
  ];
};

export const getOutputControlPanelHeaderItemList = ({
  actions,
  isBasic,
}: ControlPanelTextListProps & {
  isBasic?: boolean;
}) => {
  if (!actions) return [];
  return [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop },
    { text: '최하단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddBottom, split: true },
    { text: '폴더 추가', icon: AddCircleOutlineIcon, action: actions.onAddBundle },
    {
      text: isBasic ? '' : '폴더 삭제',
      icon: DeleteIcon,
      action: actions.onRemoveBundle,
    },
    { text: '폴더명 수정', icon: EditIcon, action: actions.onEditBundleName },
    { text: '전체 삭제', icon: DeleteSweep, action: actions.onRemoveAll, split: true },
    { text: '전체 수정 닫기', icon: HighlightOffIcon, action: actions.onCloseAll},
  ];
};
export const getWorkStatusName = (status: ApprovalDocumentStatus | '') => {
  switch (status) {
    case ApprovalDocumentStatus.TEMPORARY:
      return '작성중';
    case ApprovalDocumentStatus.ON_GOING:
      return '결재 진행중';
    case ApprovalDocumentStatus.COMPLETED:
      return '결재 완료';
    case ApprovalDocumentStatus.REJECTED:
      return '결재 반려';
    case ApprovalDocumentStatus.CANCELED:
      return '결재 요청 취소';
    default:
      return '-';
  }
};

export enum WorkStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  BEFORE_REPORT = 'BEFORE_REPORT',
  REPORT_COMPLETED = 'REPORT_COMPLETED',
  WORK_COMPLETED = 'WORK_COMPLETED',
  ON_HOLD = 'ON_HOLD',
  ETC = 'ETC',
}