import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { ProjectContractConfirmedUpdateParameter } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/parameter';
import { projectSalesInfoContractConfirmedApi } from '@front/src/features/project-sales-info/features/contract/features/confirmed/query/api';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoContractConfirmedMutation };

const mutation = {
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectContractConfirmedUpdateParameter) =>
        projectSalesInfoContractConfirmedApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'contract', 'confirmed'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
