import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { AccountingUploadEcountDataListContext } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/provider/context';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { OldTd } from '@front/layouts/Table';
import {
  convertDateToStringFormat,
  DATE_TIME_FORMAT,
  downloadFile,
  getValueIfExist,
} from '@front/src/utils';
import { AccountingUploadCategoryName } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { AccountingUploadEcountDataContext } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/context';
import TextLink from '@front/layouts/TextLink';

export { List as AccountingUploadEcountDataList };

const List = () => {
  const { isModalMode } = useContext(AccountingUploadEcountDataContext);
  const { list } = useContext(AccountingUploadEcountDataListContext);
  if (list?.length === 0) {
    return (
      <NoResult
        colSpan={5}
        height="60px"
      />
    );
  }
  return (
    <>
      {list?.map((item) => (
        <TableRow key={item.id}>
          <OldTd>{convertDateToStringFormat(item.createdAt, DATE_TIME_FORMAT)}</OldTd>
          {!isModalMode && <OldTd>{AccountingUploadCategoryName[item.category]}</OldTd>}
          {!isModalMode && (
            <OldTd onClick={downloadFile(item.file.id)}>
              <TextLink
                sx={{
                  cursor: 'pointer',
                }}
              >
                {getValueIfExist(item.file?.filename)}
              </TextLink>
            </OldTd>
          )}
          <OldTd>{getValueIfExist(item.writer.name)}</OldTd>
          <OldTd>{getValueIfExist(item.memo)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
