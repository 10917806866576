import React from 'react';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import type { NDepthTableBoxFormValues } from '@front/src/components/n-depth-table-box';
import { useFormContext } from 'react-hook-form';

interface Props {
  index: number;
}

export default function NDepthTableBoxHeadCell({ index }: Props) {
  const { getValues } = useFormContext<NDepthTableBoxFormValues>();

  const showList = getValues('showList');

  const cellData = `${index + 1}레벨(${showList[index]?.length ?? 0}/${
    getValues('header')[index]
  })`;

  return (
    <NDepthTableBoxCell
      isHead
      key={`head-${index}`}
    >
      {cellData}
    </NDepthTableBoxCell>
  );
}
