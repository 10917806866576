import React, { useContext, useEffect } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoEstimationEstimationCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/context';
import { useFormContext } from 'react-hook-form';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { convertEstimationDataForParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils';
import { modalLayout } from '@front/src/components/layout/modal';
import { useSnackbar } from '@front/src/features/snackbar';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ModalButtonGroup as ProjectSalesInfoEstimationEstimationCreateModalButtonGroup };

const ModalButtonGroup = () => {
  const { Footer } = modalLayout;
  const {
    h: { onClose, onCreate },
  } = useContext(ProjectSalesInfoEstimationEstimationCreateModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { show } = useSnackbar();
  const {
    handleSubmit,

    formState: { errors },
  } = useFormContext();
  const hasError = Object.keys(errors).length > 0;
  useEffect(() => {
    if (hasError) {
      show({
        message: '필수값 입력 요망',
      });
    }
  }, [hasError, show]);
  const onSubmit = () => {
    handleSubmit((data) => {
      onCreate({
        id,
        ...convertEstimationDataForParameter(data),
      });
    })();
  };
  const { readOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Footer>
      <Button
        shape="basic2"
        onClick={onClose}
      >
        취소
      </Button>
      <Button
        onClick={onSubmit}
        disabled={readOnly}
      >
        저장
      </Button>
    </Footer>
  );
};
