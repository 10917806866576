import React, { Suspense } from 'react';
import WorkScheduleTable from '@front/src/features/work/features/work/features/schedule/components/Table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import WorkWorkScheduleButtonGroup from '@front/src/features/work/features/work/features/schedule/components/ButtonGroup';

export default function WorkWorkScheduleFeature(props: Readonly<SectionComponentProps>) {
  const { name, sectionId, dataId, readOnly } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  const renderTitleRight = () => {
    if (readOnly) return <></>;
    return (
      <WorkWorkScheduleButtonGroup
        dataId={dataId}
        sectionId={sectionId}
      />
    );
  };
  return (
    <Layout>
      <TitleWrap
        left={name}
        right={renderTitleRight()}
      />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkScheduleTable {...props} />
      </Suspense>
    </Layout>
  );
}
