import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { PersonalSettingsModalUISettingsModalSettingsForm } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/Form';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { Index as PersonalSettingsModalUISettingsModalSettingsFeature };
interface Props {
  authMenuId: number | undefined;
}
const Index = ({ authMenuId }: Props) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { data: selectableHeaderList } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  const { setParentId, parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      setParentId: state.setParentId,
      parentId: state.parentId,
    }))
  );
  useEffect(
    () => () => {
      setParentId(undefined);
    },
    [setParentId]
  );
  if (list === undefined || selectableHeaderList === undefined) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <PersonalSettingsModalUISettingsModalSettingsForm
        key={parentId}
        authMenuId={authMenuId}
      />
    </Box>
  );
};
