import React from 'react';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import { useFormContext } from 'react-hook-form';
import type { TaxAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';

interface Props {
  count: number;
}

export default function TaxAccountTableLastHeadCell({ count }: Props) {
  const { getValues } = useFormContext<TaxAccountTableFormValues>();

  const content = `말단계정(${getValues('lastAccount')?.length ?? 0}/${count})`;

  return <NDepthTableBoxCell isHead>{content}</NDepthTableBoxCell>;
}
