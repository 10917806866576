import { projectSalesInfoAbstractSectionComponent } from '@front/src/features/project-sales-info/features/abstract/components/section-component';
import { projectSalesInfoProjectDivisionSectionComponent } from '@front/src/features/project-sales-info/features/project-division/components/section-component';
import { projectSalesInfoDesignOutlineSectionComponent } from '@front/src/features/project-sales-info/features/design-outline/components/section-component';
import { projectSalesInfoSubjectReviewSectionComponent } from '@front/src/features/project-sales-info/features/subject-review/components/section-component';
import { projectSalesInfoBidSectionComponent } from '@front/src/features/project-sales-info/features/bid/components/section-component';
import { projectSalesInfoAffiliatedCompanySectionComponent } from '@front/src/features/project-sales-info/features/affiliated-company/components/section-component';
import { projectSalesInfoEstimationSectionComponent } from '@front/src/features/project-sales-info/features/estimation/components/section-component';
import { projectSalesInfoContractSectionComponent } from '@front/src/features/project-sales-info/features/contract/components/section-component';
import { projectSalesInfoCollectionSectionComponent } from '@front/src/features/project-sales-info/features/collection/components/section-component';
import { projectSalesInfoActivitySectionComponent } from '@front/src/features/project-sales-info/features/activity/components/section-component';

export const projectSalesInfoSectionComponent = {
  ...projectSalesInfoAbstractSectionComponent,
  ...projectSalesInfoProjectDivisionSectionComponent,
  ...projectSalesInfoDesignOutlineSectionComponent,
  ...projectSalesInfoSubjectReviewSectionComponent,
  ...projectSalesInfoBidSectionComponent,
  ...projectSalesInfoAffiliatedCompanySectionComponent,
  ...projectSalesInfoEstimationSectionComponent,
  ...projectSalesInfoContractSectionComponent,
  ...projectSalesInfoCollectionSectionComponent,
  ...projectSalesInfoActivitySectionComponent,
};
