import React, { memo } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import Section from '@front/components/Section';
import OstFormName from '@front/ost/service/detail/form/name';
import type { RootState } from '@front/services/reducer';
import OstFormPeriod from '@front/ost/service/detail/form/period';
import OstFormTotalBudget from '@front/ost/service/detail/form/totalBudget';
import OstFormUnspentBudget from '@front/ost/service/detail/form/unspentBudget';
import OstFormStatus from '@front/ost/service/detail/form/status';
import { TrimmedMeanForm } from '@front/ost/view/detail/TrimmedMeanForm';

interface Props {
  onStatusChange: (e: SelectChangeEvent<unknown>) => void;
}

const OstUpdateFormView = ({ onStatusChange }: Props) => {
  const { name, startDate, endDate, totalBudget, unspentBudget, status } = useSelector(
    (root: RootState) => ({
      name: root.ost.detail?.name,
      startDate: root.ost.detail?.startDate,
      endDate: root.ost.detail?.endDate,
      totalBudget: root.ost.detail?.totalBudget,
      unspentBudget: root.ost.detail?.unspentBudget,
      status: root.ost.detail?.status,
    }),
    shallowEqual
  );

  return (
    <Section>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          columnGap: '10%',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'repeat(3, 1fr)',
          }}
        >
          <Box
            sx={{
              margin: '10px 0',
            }}
          >
            <OstFormName name={name} />
          </Box>
          <Box
            sx={{
              margin: '10px 0',
            }}
          >
            <OstFormPeriod
              status={status}
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '10%',
            }}
          >
            <Box
              sx={{
                margin: '10px 0',
              }}
            >
              <OstFormTotalBudget
                status={status}
                totalBudget={totalBudget}
              />
            </Box>
            <Box
              sx={{
                margin: '10px 0',
              }}
            >
              <OstFormUnspentBudget
                totalBudget={totalBudget}
                unspentBudget={unspentBudget}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '50%',
              margin: '10px 0',
            }}
          >
            <TrimmedMeanForm />
          </Box>
        </Box>
        <Box
          sx={{
            margin: '10px 0',
          }}
        >
          <OstFormStatus
            onStatusChange={onStatusChange}
            status={status}
          />
        </Box>
      </Box>
    </Section>
  );
};

const OstUpdateForm = memo(OstUpdateFormView);

export default OstUpdateForm;
