import React from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/components/layout/body.module.scss';

interface Props {
  children: React.ReactNode;
  padding?: string;
}

export const Body = ({ children, padding }: Props) => (
  <div
    className={classes.body}
    style={{ padding: padding }}
  >
    {children}
  </div>
);
