import React, { useContext } from 'react';
import Input from '@front/layouts/Input';
import { ColorPalette } from '@front/assets/theme';
import { FormikContext } from 'formik';

const OpinionTextArea = () => {
  const formik = useContext(FormikContext);

  return (
    <Input
      multiline
      variant="outlined"
      placeholder={'본 제안 건에 대한 캠페인 담당자 직권 상신 의견을 입력하시길 바랍니다.'}
      key={formik.values.content}
      defaultValue={formik.values.content}
      sx={{
        border: `1px solid ${ColorPalette._e4e9f2}`,
      }}
      onBlur={(e) => {
        const value = e.target.value || undefined;
        if (formik.values.content !== value) {
          formik.setFieldValue('content', value);
        }
      }}
    />
  );
};

export default OpinionTextArea;
