import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import type { UIBuilderTableFilterItem } from '@front/src/components/ui-builder/table/hooks/useForm';
import UIBuilderFilter from '@front/src/components/ui-builder/filter/Filter';
import useUIBuilderPopover from '@front/src/components/ui-builder/filter/hooks/usePopover';
import { ColorPalette } from '@front/assets/theme';
import Menu from '@mui/material/Menu';

interface Props extends UiBuilderTableProps {
  headerId: number;
  bundleIndex: number;
  onFilterChange: (newFilter: UIBuilderTableFilterItem[], active: boolean) => void;
  active: boolean;
}

export default function UIBuilderFilterPopover(props: Props) {
  const { active } = props;
  const { anchorEl, onOpen, onClose } = useUIBuilderPopover();
  const open = Boolean(anchorEl);

  return (
    <>
      <FontAwesomeIcon
        icon="square-caret-down"
        onClick={onOpen}
        style={{
          cursor: 'pointer',
          color: active ? ColorPalette.main.main_primary : 'default',
        }}
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <UIBuilderFilter
          onClose={onClose}
          {...props}
        />
      </Menu>
    </>
  );
}
