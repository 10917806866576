import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyMeetingHistoryModalQuery } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/query/query';
import { affiliatedCompanyMeetingHistoryModalMutation } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/query/mutation';

export const affiliatedCompanyMeetingHistoryModalRepository = {
  useMeetingHistoryGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyMeetingHistoryModalQuery.useMeetingHistoryGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useMeetingHistoryCreate: (menuId) => {
    const { mutate } = affiliatedCompanyMeetingHistoryModalMutation.useMeetingHistoryCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useMeetingHistoryUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyMeetingHistoryModalMutation.useMeetingHistoryUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUserListGet: (idList: number[], menuId?) => {
    const { data, isLoading } = affiliatedCompanyMeetingHistoryModalQuery.useUserListGet(
      idList,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
