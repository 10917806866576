import React, { memo, useMemo } from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table } from '@front/layouts/Table';
import CircularProgress from '@front/components/CircularProgress';
import { AffiliatedCompanyOutlineInfoTable } from '@front/src/features/affiliated-company/features/outline/features/info/components/table';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyOutlineInfoLogic } from '@front/src/features/affiliated-company/features/outline/features/info/widgets/useLogic';
import { AffiliatedCompanyOutlineInfoContext } from '@front/src/features/affiliated-company/features/outline/features/info/widgets/contex';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlineInfoWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { detailOutline },
    h: { onOutlineInfoUpdate },
    isLoading,
  } = useAffiliatedCompanyOutlineInfoLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detailOutline },
      h: { onOutlineInfoUpdate },
    }),
    [onOutlineInfoUpdate, detailOutline]
  );
  if (typeof detailOutline === 'undefined' || isLoading) {
    return <Spinner />;
  }
  return (
    <Layout>
      <Body>
        <Header title={name} />
        <TableContainer>
          <Table>
            <TableBody>
              <AffiliatedCompanyOutlineInfoContext.Provider value={value}>
                <AffiliatedCompanyOutlineInfoTable
                  key={`${detailOutline?.foundationAnniversary} ${detailOutline.numberOfEmployees} ${detailOutline.salesAmount}`}
                />
              </AffiliatedCompanyOutlineInfoContext.Provider>
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Layout>
  );
};

const Spinner = memo(() => {
  const { Layout } = AddDeleteTable;
  return (
    <Layout height="174px">
      <CircularProgress size={30} />
    </Layout>
  );
});

Spinner.displayName = 'AffiliatedCompanyOutlineInfoWidgetSpinner';

export default AffiliatedCompanyOutlineInfoWidget;
