import { TableRow } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyPrimaryBusinessView } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/view';
import type { AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/parameter';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyOutlinePrimaryBusinessContext } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/context';
import TableSideHeaderWithCheckbox from '@front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  item: AffiliatedCompanyPrimaryBusinessView;
  index: number;
}

const getValues = (item: AffiliatedCompanyPrimaryBusinessView) => ({
  id: item.id,
  business: item.business ?? '',
});

export { PrimaryBusinessTableRow as AffiliatedCompanyOutlinePrimaryBusinessTableRow };
const PrimaryBusinessTableRow = ({ item, index }: Props) => {
  const {
    h: { setIdList, onUpdatePrimaryBusiness },
    idList,
  } = useContext(AffiliatedCompanyOutlinePrimaryBusinessContext);
  const methods = useForm<AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter>({
    values: getValues(item),
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onUpdatePrimaryBusiness(data);
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <TableSideHeaderWithCheckbox
        title="주력사업"
        index={index}
        idList={idList}
        item={item}
        handleCheckboxChange={handleCheckboxChange}
        disabled={readOnly}
      />
      <OldTd>
        <FormProvider {...methods}>
          <HookFormInput
            name="business"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </FormProvider>
      </OldTd>
    </TableRow>
  );
};
