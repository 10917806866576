import { create } from 'zustand';
import { convertEmptyToNull } from '@front/src/utils';
import { defaultAffiliatedPersonCompanySearchForm } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/utils';
import type { AffiliatedPersonCompanyListGetParameter } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/types/parameter';
import { CompanyKeywordType } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/types';

export { useState as useAffiliatedPersonAppBarCompanySelectModalState };

interface State {
  searchParameter: AffiliatedPersonCompanyListGetParameter;
  setSearchParameter: (searchFormValues: AffiliatedPersonCompanyListGetParameter) => void;
}

const useState = create<State>((set) => ({
  searchParameter: {
    ...convertEmptyToNull(defaultAffiliatedPersonCompanySearchForm),
    keywordType: defaultAffiliatedPersonCompanySearchForm.keywordType || CompanyKeywordType.ALL,
  },
  setSearchParameter: (searchParameter) =>
    set(() => ({
      searchParameter: {
        ...convertEmptyToNull(searchParameter),
        keywordType: searchParameter.keywordType || CompanyKeywordType.ALL,
      },
    })),
}));
