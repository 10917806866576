import type { AccountingTableView } from '@front/src/features/accounting/features/settings/types/view';

export const getTableRowNum = <T extends AccountingTableView>(list?: T[]) => {
  let maxRowNum = 20;
  const depth0Count = list?.filter((t) => t.depth === 0).length ?? 0;
  const depth1Count = list?.filter((t) => t.depth === 1).length ?? 0;
  const depth2Count = list?.filter((t) => t.depth === 2).length ?? 0;
  const depth3Count = list?.filter((t) => t.depth === 3).length ?? 0;
  const depth4Count = list?.filter((t) => t.depth === 4).length ?? 0;
  maxRowNum = Math.max(
    maxRowNum,
    depth0Count + 1,
    depth1Count + 1,
    depth2Count + 1,
    depth3Count + 1,
    depth4Count + 1
  );
  return maxRowNum;
};
