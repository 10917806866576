import React from 'react';
import { AccountingUploadSalesInfoGoalModalUpdateProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/provider/provider';
import { AccountingUploadSalesInfoGoalModalButtonGroup } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/update/components/button-group';

export { Widget as AccountingUploadSalesInfoGoalModalUpdateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <AccountingUploadSalesInfoGoalModalUpdateProvider menuId={menuId}>
    <AccountingUploadSalesInfoGoalModalButtonGroup />
  </AccountingUploadSalesInfoGoalModalUpdateProvider>
);
