import React from 'react';
import UIBuilderTableHeader, {
  type UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';

export default function WorkCategoryTableHead(props: UIBuilderTableHeadProps) {
  return (
    <UIBuilderTableHeader
      {...props}
      isSingleRow
    />
  );
}
