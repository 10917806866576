import * as fabric from "fabric";
import useCanvasUtil, {
  CanvasRuntimeProps
} from "./useCanvasUtil";

export interface HistoryStack {
  undo: string[],
  redo: string[],
}

const EXT_OBJECT_ATTR_PREFIX = 'custom_';
export const EXT_OBJECT_ATTR_FILE_ID = `${EXT_OBJECT_ATTR_PREFIX}fileId`
export const EXT_OBJECT_ATTR_DRAW_ID = `${EXT_OBJECT_ATTR_PREFIX}drawId`

export default function useStateManager() {

  const {
    setSelectableForAllObjects,
    removeAllObjects
  } = useCanvasUtil();

  /**
   * 드로잉 데이터 저장
   * @param canvas
   * @param actingDrawingId 본 값이 설정되면 해당 오버레이만 저장함
   * @param callback
   * @remarks 오버레이 드로잉은 포함하지 않음
   */
  const save = (canvas: fabric.Canvas,
                actingDrawingId: number | null,
                callback: (serializedObjects: string) => void) => {

    const plainObjects : fabric.Object[] = [];
    const objects = canvas.toObject([
      EXT_OBJECT_ATTR_FILE_ID,
      EXT_OBJECT_ATTR_DRAW_ID]
    ).objects;

    objects.forEach((object) => {
      if (actingDrawingId) {
        // acting drawing 만 저장한다
        if (actingDrawingId === object[EXT_OBJECT_ATTR_DRAW_ID]) {
          plainObjects.push(object);
        }
      } else {
        // 일반 상태는 오버레이 객체 제외하고 저장한다.
        if (!object[EXT_OBJECT_ATTR_DRAW_ID]) {
          plainObjects.push(object);
        }
      }
    });
    const result = {
      objects: plainObjects,
      canvas: {
        devicePixelRatio: window.devicePixelRatio,
        width: canvas.width,
        height: canvas.height,
      }
    };
    callback(JSON.stringify(result));
  };

  /**
   * 드로잉 데이터 로드
   * @param canvas
   * @param data
   * @param callback
   * @param objectMapper (optional) 개별 객체에 대한 처리
   */
  const load = (canvas: fabric.Canvas, data: string,
                callback: () => void,
                objectMapper?: (object: fabric.Object) => void) => {

    const canvasData = JSON.parse(data);
    // 구버전 호환성(베타버전에는 canvas w/h meta가 없었다)
    const objects = canvasData.objects? canvasData.objects: canvasData;
    fabric.util.enlivenObjects(objects).then((value) => {
      value.forEach((object) => {
        const _object = object as fabric.Object
        objectMapper && objectMapper(_object);
        canvas.add(_object);
      });
      callback();
    });

  };

  /**
   * undo/redo state 초기화
   * @param history
   */
  const resetState = (history: HistoryStack) => {
    history.redo.length = 0;
    history.undo.length = 0;
  };

  /**
   * undo/redo state 업데이트
   * @param canvas
   * @param runtimeProps
   * @param callback 상태 저장 후 실행
   * @remark 오버레이된 첨삭 데이터는 제외됨
   */
  const saveState = (canvas: fabric.Canvas, runtimeProps: CanvasRuntimeProps, callback: () => void) => {

    const {history, actingDrawingId} = runtimeProps;

    const redoStack = history.redo;
    const undoStack = history.undo;
    save(canvas, actingDrawingId, (serializedObjects) => {
      undoStack.push(serializedObjects);
      redoStack.length = 0;
      callback();
    })
  };

  /**
   * undo
   * @param canvas
   * @param runtimeProps
   * @return 후속 undo 가능 여부
   */
  const undo = (canvas: fabric.Canvas,
                runtimeProps: CanvasRuntimeProps ) => {

    const {history} = runtimeProps;
    const redoStack = history.redo;
    const undoStack = history.undo;

    if (undoStack.length > 0) {
      const lastState = undoStack.pop();

      if(lastState) {
        redoStack.push(lastState);

        if (undoStack.length > 0) {
          removeAllObjects(canvas, runtimeProps.actingDrawingId);
          const currentState = undoStack[undoStack.length - 1];
          const parsedData = JSON.parse(currentState);
          // 구버전 호환성(베타버전에는 canvas w/h meta가 없었다)
          const objects = parsedData.objects? parsedData.objects : parsedData;

          fabric.util.enlivenObjects(objects).then((value)=> {
            value.forEach((object) => {
              const _object = object as fabric.Object;
              canvas.add(_object);
            });
            setSelectableForAllObjects(canvas, runtimeProps.selectable, runtimeProps.actingDrawingId);
            canvas.renderAll();
          });

        } else {
          removeAllObjects(canvas, runtimeProps.actingDrawingId);
          canvas.renderAll();
        }
      }
      return true;
    }
    return false;
  };

  /**
   * redo
   * @param canvas
   * @param runtimeProps
   * @return 후속 redo 가능 여부
   */
  const redo = (canvas: fabric.Canvas,
                runtimeProps: CanvasRuntimeProps ) => {

    const {history} = runtimeProps;
    const redoStack = history.redo;
    const undoStack = history.undo;

    if (redoStack.length > 0) {
      const lastRedoState = redoStack.pop();

      if(lastRedoState){
        undoStack.push(lastRedoState);
        const parsedData = JSON.parse(lastRedoState);
        // 구버전 호환성(베타버전에는 canvas w/h meta가 없었다)
        const objects = parsedData.objects? parsedData.objects : parsedData;

        fabric.util.enlivenObjects(objects).then((value)=> {
          removeAllObjects(canvas, runtimeProps.actingDrawingId);
          value.forEach((object) => {
            const _object = object as fabric.Object;
            canvas.add(_object);
          });
          setSelectableForAllObjects(canvas, runtimeProps.selectable, runtimeProps.actingDrawingId);
          canvas.renderAll();
        });

      }
      return true;
    }
    return false;
  };

  return {
    save,
    load,
    undo,
    redo,
    saveState,
    resetState
  }
}