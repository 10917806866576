import { shallow } from 'zustand/shallow';
import { affiliatedCompanyWebHardRepository } from '@front/src/features/affiliated-company/features/outline/features/web-hard/repository/repository';
import { useCallback } from 'react';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useState';
import { projectSalesInfoAffiliatedCompanyWebHardCreateModalRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/repository';
import { projectSalesInfoContractEarningsItemCreateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/repository';

export { useLogic as useProjectSalesInfoAffiliatedCompanyWebHardCreateModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { isOpen, setIsOpen, setCompanyId, companyId } =
    useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState(
      (state) => ({
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
        setCompanyId: state.setCompanyId,
        companyId: state.companyId,
      }),
      shallow
    );
  const { data: companyList, isLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository.useListGet(id, menuId);
  const { data: consortiumCompanyList } =
    projectSalesInfoContractEarningsItemCreateModalRepository.useConsortiumCompanyGet(id!, menuId);
  const { run: onCreate, setCallback } =
    projectSalesInfoAffiliatedCompanyWebHardCreateModalRepository.useCreate(menuId);
  const { data: webHardList, isLoading: isWebHardListLoading } =
    affiliatedCompanyWebHardRepository.useListGet(companyId, menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setCompanyId(undefined);
    setIsOpen(false);
  }, [setCompanyId, setIsOpen]);
  const h = {
    onCreate,
  };
  const d = {
    companyList,
    webHardList,
    consortiumCompanyList,
  };
  return {
    d,
    h,
    isOpen,
    isLoading,
    isWebHardListLoading,
  };
};
