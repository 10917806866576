import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CampaignInfoModal from '@front/ost_proposal/view/modal/campaignInfo';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';

const CampaignInfoModalService = () => {
  const dispatch = useDispatch();
  const campaignInfoModalOpen = useSelector(
    (root: RootState) => root.proposal.campaignInfoModalOpen,
    shallowEqual
  );
  const closeCampaignInfoModal = useCallback(
    () => dispatch(proposalAction.setCampaignInfoModal(false)),
    [dispatch]
  );
  return (
    <>
      {campaignInfoModalOpen && (
        <CampaignInfoModal
          campaignInfoModalOpen={campaignInfoModalOpen}
          closeCampaignInfoModal={closeCampaignInfoModal}
        />
      )}
    </>
  );
};

export default CampaignInfoModalService;
