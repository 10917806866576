import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React, { useCallback } from 'react';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import InnerCellSupport from '@front/src/components/ui-builder/table/InnerCellSupport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import usePaymentTermsModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useCmModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/useCmModalStore';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { createDropdownOptions } from '@front/src/utils';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';

interface Props extends CellComponentProps<ContractHistoryView> {}

/**
 * 확정여부
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="confirmed"
    options={createDropdownOptions(['Y', 'N'])}
  />
);

/**
 * 계약순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => <UIBuilderTableCellText value={props.item.seq} />;

/**
 * 견적서번호
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellInputText
    {...props}
    name="estimationCode"
  />
);

/**
 * 수주구분
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="orderType"
    code={ManagedVariable.ProjectSalesContractOrderType}
  />
);

/**
 * 수주일
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="orderDate"
  />
);

/**
 * 계약구분
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => {
  const menuItems = [
    {
      text: 'item',
      icon: VisibilityIcon,
      action: () => {
        alert('test');
      },
      seq: 1,
      split: true,
    },
  ];

  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
      contextMenuItems={menuItems}
      onSubmit={() => {}}
    >
      <UIBuilderTableCellManagedDropDown
        {...props}
        name="contractType"
        code={ManagedVariable.ProjectSalesContractType}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약일자
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="contractDate"
  />
);

/**
 * 발주처
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellAffiliatedCompanySelector
      {...props}
      name={`client`}
    />
  </InnerCellSupport>
);

/**
 * 계약발주비율
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="clientRatio"
    />
  </InnerCellSupport>
);

/**
 * 계약서 프로젝트명
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputText
      {...props}
      name="projectName"
    />
  </InnerCellSupport>
);
/**
 * 단지명(블럭명)
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputText
      {...props}
      name="blockName"
    />
  </InnerCellSupport>
);
/**
 * 대지모형
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="siteModel"
    />
  </InnerCellSupport>
);

/**
 * 실험대상
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputText
      {...props}
      name="testPlan"
    />
  </InnerCellSupport>
);

/**
 * 소요기간(주)
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="requiredWeeks"
    />
  </InnerCellSupport>
);
/**
 * 지불조건
 * @param props
 * @constructor
 */
const CellRenderer15 = (props: Props) => {
  const { onOpen: onPaymentTermsModalOpen } = usePaymentTermsModalStore();

  const onCreateButtonClick = useCallback(
    (dataId: number, clientId: number) => {
      //TODO: 지불조건 1행 추가 후 모달 구동 로직 구현
      console.debug('TBD');
      alert('모달 구동전 행추가 로직 구현해야 함');
      onPaymentTermsModalOpen(dataId, clientId, props.readOnly);
    },
    [onPaymentTermsModalOpen]
  );

  return (
    <>
      {props.item.detail.map((detail, index) => {
        const paymentTerms = detail.paymentTerms;
        return (
          <div
            key={index}
            className="inner-cell"
          >
            <ButtonBasicUI
              shape="ghost"
              size="small"
              key={index}
              sx={{ minWidth: '5rem' }}
              onClick={() => {
                if (paymentTerms && paymentTerms.length > 0) {
                  onPaymentTermsModalOpen(props.dataId!, detail.id, props.readOnly);
                } else {
                  onCreateButtonClick(props.dataId!, detail.id);
                }
              }}
            >
              {paymentTerms.length > 0 ? paymentTerms.length : props.readOnly ? '-' : '추가'}
            </ButtonBasicUI>
          </div>
        );
      })}
    </>
  );
};
/**
 * 견적총액
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="totalEstimationPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약총액
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="totalContractPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="contractedWindTunnelPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약실풍동금액
 * @param props
 * @constructor
 */
const CellRenderer19 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="actualWindTunnelPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약구검
 * @param props
 * @constructor
 */
const CellRenderer20 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="structuralReviewPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약구검비율
 * @param props
 * @constructor
 */
const CellRenderer21 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="structuralReviewRatio"
    />
  </InnerCellSupport>
);
/**
 * 계약CM
 * @param props
 * @constructor
 */
const CellRenderer22 = (props: Props) => {
  const { onOpen: onCmModalOpen } = useCmModalStore();

  const onCreateButtonClick = useCallback(
    (dataId: number, clientId: number) => {
      //TODO: 계약CM 1행 추가 후 모달 구동 로직 구현
      console.debug('TBD');
      alert('모달 구동전 행추가 로직 구현해야 함');
      onCmModalOpen(dataId, clientId, props.readOnly);
    },
    [onCmModalOpen]
  );

  return (
    <>
      {props.item.detail.map((detail, index) => {
        const contractCM = detail.contractCM;
        const contractCMSum = contractCM.reduce(
          (acc, currentValue) => acc + currentValue.contractCM,
          0
        );

        return (
          <div
            key={index}
            className="inner-cell"
          >
            <ButtonBasicUI
              shape="ghost"
              size="small"
              key={index}
              onClick={() => {
                if (contractCM && contractCM.length > 0) {
                  onCmModalOpen(props.dataId!, detail.id, props.readOnly);
                } else {
                  onCreateButtonClick(props.dataId!, detail.id);
                }
              }}
            >
              {contractCM.length > 0 ? contractCMSum : props.readOnly ? '-' : '추가'}
            </ButtonBasicUI>
          </div>
        );
      })}
    </>
  );
};
/**
 * 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer23 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="cmRatio"
    />
  </InnerCellSupport>
);
/**
 * 견적 실동단가
 * @param props
 * @constructor
 */
const CellRenderer24 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="actualUnitPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약 실동단가
 * @param props
 * @constructor
 */
const CellRenderer25 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="contractUnitPrice"
    />
  </InnerCellSupport>
);
/**
 * 목표계약실동단가
 * @param props
 * @constructor
 */
const CellRenderer26 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="targetUnitPrice"
    />
  </InnerCellSupport>
);
/**
 * 최저계약실동단가
 * @param props
 * @constructor
 */
const CellRenderer27 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="minimumUnitPrice"
    />
  </InnerCellSupport>
);
/**
 * 계약실동원가
 * @param props
 * @constructor
 */
const CellRenderer28 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      name="actualCost"
    />
  </InnerCellSupport>
);
/**
 * 계약서파일(한글)
 * @param props
 * @constructor
 */
const CellRenderer29 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
    fileItemAttrName="hwpFile"
  >
    <UIBuilderTableCellFileAttachment
      {...props}
      name="hwpFile"
    />
  </InnerCellSupport>
);

/**
 * 계약서파일(PDF)
 * @param props
 * @constructor
 */
const CellRenderer30 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
    fileItemAttrName="pdfFile"
  >
    <UIBuilderTableCellFileAttachment
      {...props}
      name="pdfFile"
    />
  </InnerCellSupport>
);
/**
 * 등록자
 * @param props
 * @constructor
 */
const CellRenderer31 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
    displayValueAttrName="createdBy.name"
  >
    <UIBuilderTableCellUserSelector
      {...props}
      name="createdBy"
    />
  </InnerCellSupport>
);

/**
 * 등록일시
 * @param props
 * @constructor
 */
const CellRenderer32 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellInputDate
      {...props}
      readOnly={true}
      name="createdAt"
    />
  </InnerCellSupport>
);
export const contractHistoryTableCells: CellComponent = {
  481: CellRenderer1, // 확정여부
  482: CellRenderer2, // 계약순번
  483: CellRenderer3, // 견적번호
  484: CellRenderer4, // 수주구분
  485: CellRenderer5, // 수주일
  486: CellRenderer6, // 계약구분
  487: CellRenderer7, // 계약일자
  488: CellRenderer8, // 계약발주처
  489: CellRenderer9, // 계약발주비율
  490: CellRenderer10, // 계약서 프로젝트명
  491: CellRenderer11, // 단지명(블럭명)
  492: CellRenderer12, // 대지모형
  493: CellRenderer13, // 실험대상
  494: CellRenderer14, // 소요기간(주)
  495: CellRenderer15, // 지불조건
  496: CellRenderer16, // 견적총액
  497: CellRenderer17, //계약총액
  498: CellRenderer18, //계약순풍동금액
  499: CellRenderer19, //계약실풍동금액
  500: CellRenderer20, //계약구검
  501: CellRenderer21, //계약구검비율
  502: CellRenderer22, //계약CM
  503: CellRenderer23, //계약CM비율
  504: CellRenderer24, //견적실동단가
  505: CellRenderer25, //계약실동단가
  506: CellRenderer26, //목표계약실동단가
  507: CellRenderer27, //최저계약실동단가
  508: CellRenderer28, //계약실동원가
  509: CellRenderer29, //계약서파일(한글)
  510: CellRenderer30, //계약서파일(PDF)
  511: CellRenderer31, //등록자
  512: CellRenderer32, //등록일시
  ...generateSpareAttrCellRenders(513),
};
