import React from 'react';
import PlusOne from '@mui/icons-material/PlusOne';
import UIBuilderTableHeader, {
  type UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export default function WorkApprovalTableHead(props: UIBuilderTableHeadProps) {
  const { dataId } = props;

  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);

  const action = () => {
    sessionStorage.setItem('dataId', dataId ? dataId.toString() : '');
    setNoteId(0);
    setLeftDrawerState('approval');
  };

  const itemList = [
    {
      text: '결재 상신',
      icon: PlusOne,
      action,
      seq: 4,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      isSingleRow
      controlPanelHeaderItemList={itemList}
    />
  );
}
