import type { ReactNode, RefObject } from 'react';
import React from 'react';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';

export { HeaderCell as AccountingSettingsManagementAccountingTableLayoutTableHeaderCell };

interface Props {
  sx?: SxProps<Theme>;
  children: ReactNode;
  cellRef?: RefObject<any>;
}

const HeaderCell = ({ sx, children, cellRef }: Props) => (
  <TextBox
    variant="body2"
    cellRef={cellRef}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '200px',
      height: '60px',
      backgroundColor: ColorPalette._f1f5fc,
      fontWeight: 'bold',
      ...sx,
    }}
  >
    {children}
  </TextBox>
);
