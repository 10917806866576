import { createContext } from 'react';
import { noOp } from '@front/src/utils';

import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyPersonOutlineCareerView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/view';
import type { AffiliatedCompanyPersonOutlineCareerUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/parameter';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineCareerContext };

interface State {
  d: { careerList?: AffiliatedCompanyPersonOutlineCareerView[] };
  h: {
    onCareerCreate: DefaultFunction;
    onCareerUpdate: (params: AffiliatedCompanyPersonOutlineCareerUpdateParameter) => void;
    onCareerDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {},
  h: {
    onCareerCreate: noOp,
    onCareerUpdate: noOp,
    onCareerDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
