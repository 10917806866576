import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import PasswordChangeModalRoute from '@front/login/route/passwordChangeModal';
import LoginModalRoute from '@front/login/route/loginModalRoute';

//TODO: controller 형식으로 만들다 useSelector는 컴포넌트 하위에서 불려져야하는 문제로 아래와 같은 형식임. 좋은 방법이 있다면 수정 필요.
const ModalController = () => {
  const passwordChangeModal = useSelector(
    (root: RootState) => root.login.passwordChangeModal,
    shallowEqual
  );
  const loginModal = useSelector((root: RootState) => root.login.isOpenLoginModal, shallowEqual);

  return (
    <>
      {passwordChangeModal && <PasswordChangeModalRoute />}
      {loginModal && <LoginModalRoute />}
    </>
  );
};

export default ModalController;
