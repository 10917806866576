import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import React from 'react';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { createDropdownOptions } from '@front/src/utils';

interface Props extends CellComponentProps<EstimationComparisonView> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="paymentStage"
    options={createDropdownOptions(['계약금', '중도금', '잔금'])}
  />
);

/**
 * 계약CM
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="contractCM"
  />
);

/**
 * 계약액 대비 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="cmRatioAgainstContractPrice"
  />
);

/**
 * 공제후계약CM
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="cmPostDeduction"
  />
);

/**
 * 계약CM공제비율
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="cmDeductionRatio"
  />
);

/**
 * 계약CM방식
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="cmMethod"
    options={createDropdownOptions(['현금', '상품권', '구검', '프리렌서', '기타'])}
  />
);

/**
 * 계약CM지급대상
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  //TODO: CcompanyName attr. 없는 경우는 어떻게?
  <UIBuilderTableCellAffiliatedCompanyPersonSelector
    {...props}
    companyName="cmRecipient"
    personName="cmRecipient"
  />
);

/**
 * 계약CM지급시기
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="cmPaymentTiming"
  />
);

/**
 * 계약CM지급예정일
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="cmExpectedPaymentDate"
  />
);
export const contractCmTableCells: CellComponent = {
  576: CellRenderer1, // 계약지불단계
  577: CellRenderer2, // 계약CM
  578: CellRenderer3, // 계약액 대비 계약CM비율
  579: CellRenderer4, // 공제후계약CM
  580: CellRenderer5, // 계약CM공제비율
  581: CellRenderer6, // 계약CM방식
  582: CellRenderer7, // 계약CM지급대상
  583: CellRenderer8, // 계약CM지급시기
  584: CellRenderer9, // 계약CM지급예정일
  ...generateSpareAttrCellRenders(594),
};
