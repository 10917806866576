import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React from 'react';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import InnerCellSupport from '@front/src/components/ui-builder/table/InnerCellSupport';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import { createDropdownOptions } from '@front/src/utils';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';

interface Props extends CellComponentProps<CollectionHistoryView> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => <>{props.item.paymentStage}</>;
/**
 * 계약지불순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => <>{props.item.paymentSequence}</>;
/**
 * 계약발주처
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => <>{props.item.client.name}</>;
/**
 * 채권상태
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellDropDown
      {...props}
      name="bondStatus"
      options={createDropdownOptions(['정상', '지연', '부실', '악성', '망실', '비활성화'])}
    />
  </InnerCellSupport>
);
/**
 * 수금순번
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <>
    {props.item.detail.map((detail, index) => {
      return (
        <div
          key={index}
          className="inner-cell"
        >
          {detail.collectionSeq}
        </div>
      );
    })}
  </>
);
/**
 * 관계수금순번
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <>
    {props.item.detail.map((detail, index) => {
      return (
        <div
          key={index}
          className="inner-cell"
        >
          {detail.relatedCollectionSeq}
        </div>
      );
    })}
  </>
);
/**
 * 발주처 지불담당자
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellAffiliatedCompanyPersonSelector
      {...props}
      companyName="clientPaymentManager.affiliatedCompany"
      personName="clientPaymentManager"
    />
  </InnerCellSupport>
);
/**
 * 수금시기
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="detail"
  >
    <UIBuilderTableCellDropDown
      {...props}
      name="collectionTiming"
      options={createDropdownOptions([
        '계약전',
        '계약시',
        '업무개시전',
        '업무개시후',
        '설풍납품전',
        '설풍납품시',
        '최종보고서 납품전',
        '최종보고서 납품시',
        '구조심의전',
        '구조심의 완료시',
        'PF완료시',
        '기타',
      ])}
    />
  </InnerCellSupport>
);
/**
 * 수금응당일
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="collectionDueDate"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금응당일 변경일
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="collectionDueDateChangeDate"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금지연일
 * @param props
 * @constructor
 * @remarks 수금응당일이 (수금완료일 또는 오늘날짜) 보다 이전일 경우 수금응당일과의 차이일수
 */
const CellRenderer11 = (props: Props) => (
  <>
    {props.item.detail.map((detail, index) => {
      return (
        <div
          key={index}
          className="inner-cell"
        >
          {detail.collectionDelayDays}
        </div>
      );
    })}
  </>
);
/**
 * 수금가능성
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="collectionPossibility"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금예정액
 * @param props
 * @constructor
 * @remarks [수금예정액] 중 [수금순번]과 연관된 [관계수금순번]에 해당하는 [수금예정액]을 합계에서 제외하고 [영업집계-자금관리]에서도 해당 [수금예정액]은 제외하여 관리 [수금예정액]의 합계는 반드시 [계약총액]의 합계와 동일해야함. [수금예정액] 합계를 증감하기 위해서는 계약탭에서 새로운 계약을 형성하거나 기존계약을 편집하여 [계약총액]과 일치해야함
 */
const CellRenderer13 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="expectedCollectionAmount"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금액
 * @param props
 * @constructor
 * @remarks [수금액] 합계와 [미수금] 합계의 합은 [계약총액]과 동일 하여야 하며 오류시 오류메세지와 함께 다음단계 진행불가
 */
const CellRenderer14 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="collectedAmount"
      />
    </InnerCellSupport>
  );
};
/**
 * 미수금
 * @param props
 * @constructor
 * @remarks [수금예정액] 보다 적은 [수금액]의 경우 해당 [미수금] 차액은새롭계 수금순번을 부여받아 수금예정액을 생성해야 하며 이떼 수금예정액과 수금액에 차액이 발생한 수금순번의 수금예정액은 수금예정액의 합산과 [영업집계-수금관리]에서 해당금액을 제외해야함. 합계에 포함되지 않는 수금예정액은 미색으로 표현하여 구별하되 표현적으로 잔존.
 */
const CellRenderer15 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="unpaidAmount"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금완료일
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="collectionCompletionDate"
      />
    </InnerCellSupport>
  );
};
/**
 * 수금담당자
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
      displayValueAttrName="collectionManager.name"
    >
      <UIBuilderTableCellUserSelector
        {...props}
        name="collectionManager"
      />
    </InnerCellSupport>
  );
};
/**
 * 청구공문
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
      fileItemAttrName="claimDocument"
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="claimDocument"
      />
    </InnerCellSupport>
  );
};
/**
 * 세금계산서
 * @param props
 * @constructor
 */
const CellRenderer19 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
      fileItemAttrName="taxInvoice"
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="taxInvoice"
      />
    </InnerCellSupport>
  );
};
/**
 * 입금처
 * @param props
 * @constructor
 */
const CellRenderer20 = (props: Props) => {
  return (
    <InnerCellSupport
      {...props}
      listAttrName="detail"
    >
      <UIBuilderTableCellAffiliatedCompanySelector
        {...props}
        name="depositDestination"
      />
    </InnerCellSupport>
  );
};
export const collectionHistoryTableCells: CellComponent = {
  620: CellRenderer1, // 계약지불단계
  621: CellRenderer2, // 계약지불순번
  622: CellRenderer3, // 계약발주처
  623: CellRenderer4, // 채권상태
  624: CellRenderer5, // 수금순번
  625: CellRenderer6, // 관계수금순번
  626: CellRenderer7, // 발주처 지불담당자
  627: CellRenderer8, // 수금시기
  628: CellRenderer9, // 수금응당일
  629: CellRenderer10, // 수금응당일 변경일
  630: CellRenderer11, // 수금지연일
  631: CellRenderer12, // 수금가능성
  632: CellRenderer13, // 수금예정액
  633: CellRenderer14, // 수금액
  634: CellRenderer15, // 미수금
  635: CellRenderer16, // 수금완료일
  636: CellRenderer17, // 수금담당자
  637: CellRenderer18, // 청구공문
  638: CellRenderer19, // 세금계산서
  639: CellRenderer20, // 입금처
  ...generateSpareAttrCellRenders(640),
};
