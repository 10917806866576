import { affiliatedPersonMemoRepository } from '@front/src/features/affiliated-person/features/memo/repository/repository';

export { useLogic as useAffiliatedPersonMemoCreateLogic };

const useLogic = (menuId) => {
  const { run: onCreate } = affiliatedPersonMemoRepository.useCreate(menuId);
  return {
    onCreate,
  };
};
