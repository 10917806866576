import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkCarouselBundleToChangeUpdateParameter } from '@front/src/features/work/features/work/features/carousel/features/move-slide/types/parameter';

const workCarouselMoveSlideMutation = {
  useMoveSlide: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkCarouselBundleToChangeUpdateParameter>({
      mutationFn: (params) => workWorkApi.moveSlideCarousel(params, commonParams),
      mutationKey: ['work', 'carousel', 'move-slide'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });

    return mutate;
  },
};

export default workCarouselMoveSlideMutation;
