import React from 'react';
import type { Control } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import UserSelectorMultiInput from '@front/src/features/user-selector/features/input/components/MultiInput';
import UserSelectorSingleInput from '@front/src/features/user-selector/features/input/components/SingleInput';

interface Props {
  multi: boolean;
  readOnly: boolean;
  onOpen: () => void;
  control: Control<{ inputList: (UserVO | undefined)[] }, any>;
  onChange: (value: number | number[] | undefined) => void;
}

export default function UserSelectorInput({ multi, ...rest }: Readonly<Props>) {
  if (multi) {
    return <UserSelectorMultiInput {...rest} />;
  }
  return <UserSelectorSingleInput {...rest} />;
}
