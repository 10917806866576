import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';

import type { IdListDeleteParameter } from '@front/src/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getCMPaymentHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-payment-history`,
  deleteCMPaymentHistory: () => `${projectSalesInfoURL}/cm-amount-payment-history`,
};

export const projectSalesInfoCMPaymentHistoryApi = {
  getCMPaymentHistory: async (id: number, menuId) => {
    const { data } = await axios.get(url.getCMPaymentHistory(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteCMPaymentHistory: async (params: IdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteCMPaymentHistory(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
