import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workIndicatorQuery = {
  useGetOne: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryKey: ['work', 'indicator', commonParams.dataId],
      queryFn: () => workWorkApi.getIndicator(commonParams),
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
      select: (data) => [data],
    });
    return data;
  },
};

export default workIndicatorQuery;
