import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { FormProvider, useForm } from 'react-hook-form';
import { ProjectSalesInfoActivityAffiliatesItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-affiliates-item/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { convertEmptyToNull } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { SalesInfoActivityListContext } from '@front/src/features/project-sales-info/features/activity/widgets/list/provider/context';
import { Td } from '@front/src/components/layout/table/td';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box } from '@mui/material';
import { useCustomDialog } from '@front/src/features/dialog';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { AffiliatesCellGroup as SalesInfoActivityTableAffiliatesCellGroup };

const AffiliatesCellGroup = () => {
  const { categoryList } = useContext(SalesInfoActivityListContext);
  const { onUpdate, onDelete } = useContext(ProjectSalesInfoActivityAffiliatesItemContext);
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id, setId } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
    }))
  );
  const methods = useForm({
    values: {
      company: item.affiliatedCompany,
      person: item.person,
      date: item.date ?? '',
      category: item.category ?? '',
    },
  });
  const { handleSubmit, setValue, getValues } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate(
      convertEmptyToNull({
        salesActivityAffiliatesId: item.id,
        affiliatedCompanyId: data.company?.id,
        personId: data.person?.id,
        date: formatDateOrNull(data.date),
        category: data.category,
      })
    );
  });
  const handleSelectorChange = useCallback(
    (company, person) => {
      if (typeof company === 'undefined' || typeof person === 'undefined') {
        onDelete(item.id);
        return;
      }
      if (company?.name) {
        setValue('company', company);
      }
      setValue('person', person);
      onSubmit();
    },
    [onDelete, item, setValue, onSubmit]
  );
  const isSelected = useMemo(() => item.activity?.id === id, [item, id]);
  const { confirm } = useCustomDialog();
  const handleIconClick = useCallback(() => {
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onDelete(item.id);
      },
    });
  }, [onDelete, item.id, confirm]);
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  useEffect(() => {
    if (currentAuth === 'V') setId();
  }, [setId, currentAuth]);
  return (
    <FormProvider {...methods}>
      <Td>
        {isSelected ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <AffiliatedCompanySelectorWidget
                value={{
                  companyId: getValues('company')?.id,
                  personId: getValues('person')?.id,
                  textValue: getValues('company')?.name ?? '',
                }}
                usePersonSelector
                onPersonSelect={handleSelectorChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DeleteForeverIcon onClick={handleIconClick} />
            </Box>
          </Box>
        ) : (
          item.affiliatedCompany?.name
        )}
      </Td>
      <Td>{item.person?.name}</Td>
      <Td>{item.person?.department}</Td>
      <Td>{item.person?.position}</Td>
      <Td
        sx={{
          width: '60%',
        }}
      >
        {isSelected ? (
          <DatePickerWithHookForm
            name="date"
            onSubmit={onSubmit}
          />
        ) : (
          item.date
        )}
      </Td>
      <Td>
        {isSelected ? (
          <HookFormSelect
            isDefaultPossible
            defaultLabel="선택"
            name="category"
            optionList={categoryList ?? []}
            onSubmit={onSubmit}
          />
        ) : (
          item.category
        )}
      </Td>
    </FormProvider>
  );
};
