import React, { useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { OstRewardManagementMemoUpdateParameter } from '@front/ost_reward/parameter';
import { OstRewardManagementMemoList } from '@front/ost_reward/view/memo/list';

export { List as OstRewardManagementMemoListService };
const List = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (params: OstRewardManagementMemoUpdateParameter) => {
      dispatch(ostRewardManagementAction.updateMemo(params));
      formik.resetForm();
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      description: '',
    } as OstRewardManagementMemoUpdateParameter,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <OstRewardManagementMemoList />
    </FormikProvider>
  );
};
