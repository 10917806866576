import React from 'react';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { shallow } from 'zustand/shallow';
import { FormProvider, useForm } from 'react-hook-form';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import dayjs from "dayjs";

export { YearSelect as AccountingUploadSalesInfoYearSelect };

const YearSelect = () => {
  const { year, setYear } = useAccountingUploadSalesInfoState(
    (state) => ({
      year: state.year,
      setYear: state.setYear,
    }),
    shallow
  );
  const methods = useForm({
    values: {
      year: year ? year.toString() : '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const year = data.year ? dayjs(data.year).year() : undefined;
    setYear(year);
  });
  return (
    <FormProvider {...methods}>
      <DatePickerWithHookForm
        openTo="year"
        format="YYYY"
        views={['year']}
        name="year"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
