import React from 'react';
import { GlobalNavBarClosedChildrenDepth1Menu } from '@front/src/features/global-nav-bar/components/depth1-menu';

import globalNavBarRepository from '@front/src/features/global-nav-bar/repository/repository';

export { MenuList as GlobalNavBarMenuList };

const MenuList = () => {
  const { data: menuList } = globalNavBarRepository.useListGet();
  return (
    <>
      {menuList?.map((item) => {
        if (item.depth === null) return;
        return (
          <GlobalNavBarClosedChildrenDepth1Menu
            key={item.id}
            gnbClosed={true}
            {...item}
          />
        );
      })}
    </>
  );
};
