import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workInterimReportDateQuery = {
  useGetList: (params: UIBuilderParameter) => {
    const { data } = useQuery({
      queryKey: ['work', 'interim-report-date', params.dataId],
      queryFn: () => workWorkApi.getScheduleInterimReportDate(params),
      suspense: true,
    });
    return {
      data,
    };
  },
};

export default workInterimReportDateQuery;
