import { useCustomDialog } from '@front/src/features/dialog';
import useWorkReviewerAddTop from '@front/src/features/work/features/work/features/reviewer/repository/useAddTop';
import useWorkReviewerAddBottom from '@front/src/features/work/features/work/features/reviewer/repository/useAddBottom';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';

import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkReviewerTableMutation = ({ sectionId, dataId, menuId }) => {
  const commonParams = { sectionId, dataId, menuId };

  const formContext = useFormContext<UIBuilderTableFieldValues>();

  const { toggleEditOpenId, setRecentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkReviewerAddTop(commonParams);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onAddBottom, setCallback: setAddBottomCallback } =
    useWorkReviewerAddBottom(commonParams);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDeleteAll } = workReviewerMutation.useDeleteAll(commonParams);
  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  return {
    onAddTop,
    onAddBottom,
    onRemoveAll,
  };
};

export default useWorkReviewerTableMutation;
