import React from 'react';
import { useDrawerListStyles } from '@front/components/CommonDrawer/style';
import { TableCell, TableRow } from '@mui/material';
import CircularProgress from '@front/components/CircularProgress';

export { DrawerLoadingSpinner };

interface Props {
  colSpan?: number;
}

const DrawerLoadingSpinner = ({ colSpan = 3 }: Props) => {
  const classes = useDrawerListStyles();
  return (
    <TableRow
      component="div"
      className={classes.no_result}
    >
      <TableCell
        component="div"
        colSpan={colSpan}
      >
        <CircularProgress size={30} />
      </TableCell>
    </TableRow>
  );
};
