import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workCarouselMoveSlideMutation from '@front/src/features/work/features/work/features/carousel/features/move-slide/query/mutation';

const workCarouselMoveSlideRepository = {
  useMoveSlide: (commonParams) => {
    const callback = getNoOpCallback();
    const mutate = workCarouselMoveSlideMutation.useMoveSlide(commonParams);
    return {
      run: (params) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};

export default workCarouselMoveSlideRepository;
