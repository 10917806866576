import React from 'react';
import { Checkbox, Radio } from '@mui/material';
import {
  type FieldValues,
  type UseFieldArrayAppend,
  type UseFieldArrayRemove,
  type UseFieldArrayReplace,
  useFormContext,
} from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  item: UserVO;
  append: UseFieldArrayAppend<FieldValues, 'selectedList'>;
  remove: UseFieldArrayRemove;
  replace: UseFieldArrayReplace<FieldValues, 'selectedList'>;
}

export default function UserSelectorUserInput({
  item,
  multi,
  append,
  remove,
  replace,
}: Readonly<Props>) {
  const { getValues } = useFormContext();

  const selectedList = getValues('selectedList');

  const getChecked = (id) => selectedList.map((s) => s.id).includes(id);

  const handleCheckboxChange = () => {
    const index = selectedList.findIndex((s) => s.id === item.id);
    const copy = [...selectedList];
    if (index > -1) {
      remove(index);
      copy.splice(index, 1);
    } else {
      append(item);
      copy.push(item);
    }
  };

  const handleRadioChange = () => {
    replace([item]);
  };

  if (multi) {
    return (
      <Checkbox
        checked={getChecked(item.id)}
        onChange={handleCheckboxChange}
      />
    );
  }

  return (
    <Radio
      checked={getChecked(item.id)}
      onChange={handleRadioChange}
    />
  );
}
