import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, TableBody, TableHead, TableRow } from '@mui/material';

import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import { OldTh, Table, OldTd } from '@front/layouts/Table';
import type { RootState } from '@front/services/reducer';
import { ArticleItem } from '@front/src/components/article-item';
import { ostRewardManagementAction } from '@front/ost_reward/action';
import type { OstRewardManagementDetailMonthDetailIndividualPaymentStatusView } from '@front/ost_reward/domian';

interface Props {
  individualList: OstRewardManagementDetailMonthDetailIndividualPaymentStatusView[];
  ostName: string;
}

export const DetailStatusTableRowDetailIndividualPaymentStatusTable = ({
  individualList,
}: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();

  const { month } = useSelector(
    (root: RootState) => ({
      month: root.ostRewardMangement.month,
      detail: root.ostRewardMangement.detail,
    }),
    shallowEqual
  );

  const onClick = useCallback(() => {
    confirm({
      children: '지급 금액과 지급예정일을 확정합니다. 정말로 엑셀 파일로 내보내시겠습니까?',
      afterConfirm: () => {
        dispatch(ostRewardManagementAction.fixResult());
      },
      confirmText: '확인',
    });
  }, [confirm, dispatch]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          marginBlock: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={onClick}>급여 내보내기</Button>
      </Box>
      <ArticleItem title={<>{month}월 OST 상금 지급 현황</>}>
        <Table>
          <TableHead>
            <TableRow>
              <OldTh>번호</OldTh>
              <OldTh>캠페인명</OldTh>
              <OldTh>제안명</OldTh>
              <OldTh>소속</OldTh>
              <OldTh>성명</OldTh>
              <OldTh>직급</OldTh>
              <OldTh>상금</OldTh>
              <OldTh>연간 개인 누적상금 총액</OldTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {individualList.map((item, i) => (
              <TableRow key={i + item.name + item.reward}>
                <OldTd>{i + 1}</OldTd>
                <OldTd>{item.campaignName}</OldTd>
                <OldTd>{item.proposalName}</OldTd>
                <OldTd>{item.department}</OldTd>
                <OldTd>{item.name}</OldTd>
                <OldTd>{item.role ? item.role : '-'}</OldTd>
                <OldTd>{item.reward.toLocaleString()} 원</OldTd>
                <OldTd>{item.annualAccumulation.toLocaleString()} 원</OldTd>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ArticleItem>
    </>
  );
};
