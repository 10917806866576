import React from 'react';
import { OldTd, Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormSummary as AffiliatedCompanyMeetingHistoryModalFormSummary };

const FormSummary = () => {
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <Th width="140px">미팅내용 요약</Th>
            <OldTd>
              <HookFormTextarea
                name="summary"
                minRows={2}
                disabled={readOnly}
                variable={true}
              />
            </OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
