export const saveHistoryApiUrl = '/api/project';
export const saveHistoryHeadList = [
  {
    value: '수금완료 기성구분',
    width: '120px',
  },
  {
    value: '지급비율',
    width: '80px',
  },
  {
    value: '필요 적립구검비',
    width: '120px',
  },
  {
    value: '적립여부',
    width: '100px',
  },
  {
    value: '적립일',
    width: '120px',
  },
  {
    value: '비고',
    width: '200px',
  },
];

export const YNOptionListCollection = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const strToYNConvertorCollection = {
  Y: true,
  N: false,
};

export const YNToStrConvertorCollection = (value: boolean | undefined) => {
  if (typeof value === 'undefined' || value === null) return '';
  return value ? 'Y' : 'N';
};
