import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonGiftHistoryMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/query/mutation';
import { affiliatedCompanyPersonGiftHistoryQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/query/query';

export const affiliatedCompanyPersonGiftHistoryRepository = {
  usePersonGiftHistoryCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonGiftHistoryMutation.usePersonGiftHistoryCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonGiftHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPersonGiftHistoryQuery.usePersonGiftHistoryListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  usePersonGiftHistoryUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonGiftHistoryMutation.usePersonGiftHistoryUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  usePersonGiftHistoryDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonGiftHistoryMutation.usePersonGiftHistoryDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
