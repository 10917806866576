import React, { useCallback, useMemo } from 'react';
import type { ProceedingsUpdateParameter } from '../../parameter';
import ContentsSection from '../../view/Form/ContentsSection';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proceedingsAction } from '@front/proceedings/action';
import { getAttendance, getReader, getWriter } from '@front/proceedings/util/getAuthority';

interface Props extends SectionComponentProps {}

export default function ContentsRoute({ name }: Readonly<Props>) {
  const { Layout, Header, Body } = AddDeleteTable;
  const dispatch = useDispatch();
  const { detail, access } = useSelector((root: RootState) => root.proceedings);
  const update = useCallback(
    (params: ProceedingsUpdateParameter) => dispatch(proceedingsAction.update(params)),
    [dispatch]
  );
  const isReader: boolean = useMemo(() => {
    if (access && detail) {
      return getReader(access, detail);
    }
    return false;
  }, [detail?.readerList, access]);
  const isWriter: boolean = useMemo(() => {
    if (access && detail) {
      return getWriter(access, detail);
    }
    return false;
  }, [detail?.writer, access]);
  const isAttendance: boolean = useMemo(() => {
    if (access && detail) {
      return getAttendance(access, detail);
    }
    return false;
  }, [detail?.attendanceList, access]);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        {detail && (
          <ContentsSection
            detail={detail}
            update={update}
            isReader={isReader}
            isWriter={isWriter}
            isAttendance={isAttendance}
          />
        )}
      </Body>
    </Layout>
  );
}
