import React, { useContext } from 'react';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { ProjectSalesInfoContractEarningsItemUpdateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/widget';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import { shallow } from 'zustand/shallow';

export { DetailUpdateModalWrapper as ProjectSalesInfoContractEarningsItemDetailUpdateModalWrapper };
interface Props {
  menuId?: number;
}
const DetailUpdateModalWrapper = ({ menuId }: Props) => {
  const { isDetailUpdateModalOpen, itemId } = useContractEarningsItemState(
    (state) => ({
      isDetailUpdateModalOpen: state.isDetailUpdateModalOpen,
      itemId: state.itemId,
    }),
    shallow
  );
  const { itemId: contextItemId } = useContext(ProjectSalesInfoContractEarningsItemContext);
  return (
    <>
      {itemId === contextItemId && isDetailUpdateModalOpen && (
        <ProjectSalesInfoContractEarningsItemUpdateModalWidget menuId={menuId} />
      )}
    </>
  );
};
