// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZwtykPABtt5O0S8FvIP4{overflow:hidden}`, "",{"version":3,"sources":["webpack://./front/src/components/layout/table/table.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".table {\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ZwtykPABtt5O0S8FvIP4`
};
export default ___CSS_LOADER_EXPORT___;
