import { useQuery } from 'react-query';
import { affiliatedCompanyPersonPreferenceApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/query/api';
import type { AffiliatedCompanyPersonCharacterPreferenceView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/types/view';

export const affiliatedCompanyPersonPreferenceQuery = {
  usePersonCharacterPreferenceGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonCharacterPreferenceView[]>({
      queryKey: ['affiliated-company', 'person', 'character', 'preference', id],
      queryFn: () => affiliatedCompanyPersonPreferenceApi.getPersonCharacterPreference(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
