import React, { Suspense } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { HeaderSettingView } from '@front/src/types';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import UIBuilderEditModalTableBody from '@front/src/components/ui-builder/table/EditModalTableBody';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

interface Props extends Omit<CellComponentProps, 'isEditMode'> {
  open: boolean;
  onClose: () => void;
  header: HeaderSettingView;
}

export default function UIBuilderEditModal(props: Readonly<Props>) {
  const { open, onClose, header, onSubmit } = props;

  return (
    <ModalUI
      title={`${header.name} 상세`}
      open={open}
      onClose={onClose}
    >
      <ModalBodyUI sx={{ backfaceVisibility: 'hidden' }}>
        <Suspense
          fallback={
            <LoadingSpinnerUI
              width="100%"
              height="100%"
            />
          }
        >
          <ModalHeaderUI>
            <ButtonBasicUI
              shape="tertiary"
              size="medium"
              onClick={onClose}
              sx={{
                width: '64px',
              }}
            >
              취소
            </ButtonBasicUI>
            <ButtonBasicUI
              onClick={onSubmit}
              shape="primary2"
              size="medium"
              sx={{
                width: '64px',
              }}
            >
              저장
            </ButtonBasicUI>
          </ModalHeaderUI>
          <ModalContentUI>
            <TableContainerUI sx={{ overflowY: 'auto' }}>
              <TableTableUI>
                <TableBodyUI>
                  <UIBuilderEditModalTableBody {...props} />
                </TableBodyUI>
              </TableTableUI>
            </TableContainerUI>
          </ModalContentUI>
        </Suspense>
      </ModalBodyUI>
    </ModalUI>
  );
}
