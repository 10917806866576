import LoginForm from 'login/view/LoginForm';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { AppRoute } from 'services/routes';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from 'services/reducer';
import { menuAction } from '@front/menu/action';
import { isMobileDevice } from '@front/util/PwaUtil';

function Element() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = useSelector((root: RootState) => root.menu.open, shallowEqual);
  const toggleMenu = useCallback(() => dispatch(menuAction.toggleMenu()), [dispatch]);
  const { detail: loginUser } = useSelector((root: RootState) => root.login);

  useEffect(() => {
    if (isMobileDevice()) open && toggleMenu();
    else !open && toggleMenu();
  }, [open, toggleMenu]);

  useEffect(() => {
    if (loginUser) {
      navigate('/');
    }
  }, [loginUser]);

  return <LoginForm />;
}

const loginRoute: AppRoute = {
  path: '/login',
  element: <Element />,
};

export default loginRoute;
