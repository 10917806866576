import type { OptionType } from '@front/src/components/Select';

import type { ProjectDivisionView } from '@front/src/features/project-sales-info/features/project-division/types/view';
import type { AddressView } from '@front/src/types';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export const YNOptionList: OptionType[] = [
  {
    label: '예',
    value: '예',
  },
  {
    label: '아니오',
    value: '아니오',
  },
];

export const changeViewToParams = (data: ProjectDivisionView) => ({
  id: data.id,
  fullName: data.fullName || null,
  nickName: data.nickName || null,
  businessType: data.businessType || null,
  bidType: data.bidType || null,
  estimationReceptionDate: formatDateOrNull(data.estimationReceptionDate),
  expectedCallDate: formatDateOrNull(data.expectedCallDate),
  orderIntakeDate: formatDateOrNull(data.orderIntakeDate),
  workStartDate: formatDateOrNull(data.workStartDate),
  expectedDesignDocumentSupplyDate: formatDateOrNull(data.expectedDesignDocumentSupplyDate),
  designDocumentSupplyDate: formatDateOrNull(data.designDocumentSupplyDate),
  note: data.note || null,
  receptionManagerId: convertManagerIdToNull(data.receptionManager),
  salesManagerId: convertManagerIdToNull(data.salesManager),
  collectionManagerId: convertManagerIdToNull(data.collectionManager),
  projectManagerId: convertManagerIdToNull(data.projectManager),
});

export const convertManagerIdToNull = (data: any) =>
  !data || data.length === 0 ? null : Number(data);

export const periodValidator = (value: string | null | undefined) => {
  const newValue = value ?? '';
  return newValue !== '' ? newValue + ' 일' : '-';
};

export const changeCityDataToOptionData = (data?: AddressView[]) => {
  if (!data || data.length === 0) return [];
  return data?.map((item) => ({
    label: item.name,
    value: item.code,
  }));
};

export const addOldProjectNumber = (data?: ProjectDivisionView) => {
  if (!data) return null;
  if (data.oldCode) {
    return `${data.code}(${data.oldCode})`;
  }
  return data.code;
};
