import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import { useEffect } from 'react';

const useWorkCategoryForm = ({ item, onUpdate }) => {
  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      ...data,
      dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
      dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
      dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
    });
  });
  useEffect(() => {
    if (item) {
      methods.reset({
        ...getFormValues(item),
      });
    }
  }, [item]);
  return {
    methods,
    onSubmit,
  };
};

export default useWorkCategoryForm;

const defaultValues = {
  area: '',
  department: '',
  collaboration: '',
  impact: '',
  security: '',
  textAttr1: '',
  textAttr2: '',
  textAttr3: '',
  numberAttr1: null,
  numberAttr2: null,
  numberAttr3: null,
  dateAttr1: '',
  dateAttr2: '',
  dateAttr3: '',
};

const getFormValues = (item) => ({
  area: item.area ?? '',
  department: item.department ?? '',
  collaboration: item.collaboration ?? '',
  impact: item.impact ?? '',
  security: item.security ?? '',
  textAttr1: item.textAttr1 ?? '',
  textAttr2: item.textAttr2 ?? '',
  textAttr3: item.textAttr3 ?? '',
  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  dateAttr1: item.dateAttr1 ?? '',
  dateAttr2: item.dateAttr2 ?? '',
  dateAttr3: item.dateAttr3 ?? '',
});
