import { useMutation, useQueryClient } from 'react-query';
import { projectSalesInfoActivityApi } from '@front/src/features/project-sales-info/features/activity/api/api';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type {
  SalesInfoActivityAffiliatesUpdateParams,
  SalesInfoActivityUpdateParams,
  SalesInfoActivityUserUpdateParams,
} from '@front/src/features/project-sales-info/features/activity/types/parameters';

export const projectSalesInfoActivityMutation = {
  useActivityUpdate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: SalesInfoActivityUpdateParams) =>
        projectSalesInfoActivityApi.updateActivity(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUsersUpdate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: SalesInfoActivityUserUpdateParams) =>
        projectSalesInfoActivityApi.updateUsers(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAffiliatesUpdate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: SalesInfoActivityAffiliatesUpdateParams) =>
        projectSalesInfoActivityApi.updateAffiliates(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useActivityCreate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => projectSalesInfoActivityApi.createActivity(id, menuId),
      onSuccess: async (res) => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
        return res;
      },
    });
    return {
      mutate,
    };
  },
  useUsersCreate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (activityId: number) =>
        projectSalesInfoActivityApi.createUsers(activityId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAffiliatesCreate: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (userId: number) => projectSalesInfoActivityApi.createAffiliates(userId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useActivityDelete: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdListDeleteParameter) =>
        projectSalesInfoActivityApi.deleteActivity(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUsersDelete: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (userId: number) => projectSalesInfoActivityApi.deleteUsers(userId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAffiliatesDelete: (id: number, menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (affiliatesId: number) =>
        projectSalesInfoActivityApi.deleteAffiliates(affiliatesId, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project-sales-info', 'activity', 'list', id],
        });
      },
    });
    return {
      mutate,
    };
  },
};
