import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPrimaryBusinessQuery } from '@front/src/features/affiliated-company/features/outline/features/primary-business/query/query';
import { affiliatedCompanyPrimaryBusinessMutation } from '@front/src/features/affiliated-company/features/outline/features/primary-business/query/mutation';

export const affiliatedCompanyPrimaryBusinessRepository = {
  useOutlinePrimaryBusinessGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyPrimaryBusinessQuery.useOutlinePrimaryBusinessGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useOutlinePrimaryBusinessCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPrimaryBusinessMutation.useOutlinePrimaryBusinessCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useOutlinePrimaryBusinessDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyPrimaryBusinessMutation.useOutlinePrimaryBusinessDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useOutlinePrimaryBusinessUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPrimaryBusinessMutation.useOutlinePrimaryBusinessUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
