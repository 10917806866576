import { create } from 'zustand';

export { useState as useProjectSalesInfoEstimationCompetitorCreateModalState };

interface State {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const useState = create<State>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}));
