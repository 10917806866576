import type { ReactNode } from 'react';
import React from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { affiliatedCompanyPersonEducationTableHeaderNameList } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/utils';
import { Th } from '@front/src/components/layout/table/th';

export { Table as AffiliatedCompanyPersonDetailModalOutlineEducationTable };

interface Props {
  children: ReactNode;
}

const Table = ({ children }: Props) => (
  <TableContainer
    sx={{
      maxHeight: '200px',
    }}
  >
    <MuiTable>
      <TableHead>
        <TableRow>
          {affiliatedCompanyPersonEducationTableHeaderNameList.map(({ width, value }) => (
            <Th
              key={value}
              width={width}
            >
              {value}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </MuiTable>
  </TableContainer>
);
