import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAffiliatedPersonMemoUpdateLogic } from '@front/src/features/affiliated-person/features/memo/widgets/update/provider/useLogic';
import { AffiliatedPersonMemoUpdateContext } from '@front/src/features/affiliated-person/features/memo/widgets/update/provider/context';

export { Provider as AffiliatedPersonMemoUpdateProvider };

interface Props {
  children: ReactNode;
}

const Provider = ({ children }: Props) => {
  const { onUpdate } = useAffiliatedPersonMemoUpdateLogic();
  const value = useMemo(
    () => ({
      onUpdate,
    }),
    [onUpdate]
  );
  return (
    <AffiliatedPersonMemoUpdateContext.Provider value={value}>
      {children}
    </AffiliatedPersonMemoUpdateContext.Provider>
  );
};
