import { useQuery } from 'react-query';
import { affiliatedCompanyWebHardApi } from '@front/src/features/affiliated-company/features/outline/features/web-hard/query/api';
import type { AffiliatedCompanyWebHardView } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/view';

export const affiliatedCompanyWebHardQuery = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyWebHardView[]>({
      queryKey: ['affiliated-company', 'web-hard', id],
      queryFn: () => affiliatedCompanyWebHardApi.getList(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
