import { create } from 'zustand';
import type { ProjectCollectionManagementView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/view';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import produce from 'immer';

interface State {
  collectionManagementList: ProjectCollectionManagementView[];
  setCollectionManagementList: (
    collectionManagementList: ProjectCollectionManagementView[]
  ) => void;
  addCollectionManagementList: (item: ProjectCollectionManagementParams) => void;
  deleteCollectionManagementList: (idList: number[]) => void;
  updateCollectionManagementList: (index: number, item: ProjectCollectionManagementParams) => void;
  resetCollectionManagementList: () => void;
  idList: number[];
  setIdList: (idList: number[]) => void;
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: () => void;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: () => void;
  managementDetailId?: number;
  setManagementDetailId: (managementDetailId: number) => void;
  idx?: number;
  setIdx: (idx: number) => void;
}

export { useState as useProjectSalesInfoCollectionManagementModalState };

const useState = create<State>((set) => ({
  collectionManagementList: [],
  setCollectionManagementList: (collectionManagementList: ProjectCollectionManagementView[]) =>
    set({ collectionManagementList }),
  addCollectionManagementList: (item: ProjectCollectionManagementParams) =>
    set(
      produce(({ collectionManagementList }) => {
        collectionManagementList.push(item);
      })
    ),
  deleteCollectionManagementList: (idList: number[]) => {
    set(({ collectionManagementList }) => ({
      collectionManagementList: collectionManagementList.filter(
        (_, index) => !idList.includes(index)
      ),
    }));
  },
  updateCollectionManagementList: (index: number, item: ProjectCollectionManagementParams) =>
    set(
      produce(({ collectionManagementList }) => {
        collectionManagementList[index] = item;
      })
    ),
  resetCollectionManagementList: () =>
    set(() => ({
      collectionManagementList: [],
    })),
  idList: [],
  setIdList: (idList: number[]) => {
    set({ idList });
  },
  isCreateModalOpen: false,
  setIsCreateModalOpen: () =>
    set(({ isCreateModalOpen }) => ({ isCreateModalOpen: !isCreateModalOpen })),
  isUpdateModalOpen: false,
  setIsUpdateModalOpen: () =>
    set(({ isUpdateModalOpen }) => ({ isUpdateModalOpen: !isUpdateModalOpen })),
  setManagementDetailId: (managementDetailId: number) => {
    set({ managementDetailId });
  },
  setIdx: (idx: number) => set({ idx }),
}));
