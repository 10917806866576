import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import useContractHistoryForm from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

export { TableBody as ContractFinalTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationComparisonView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<ContractHistoryView>) {
  const { methods } = useContractHistoryForm(props.item);

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow {...props} />
    </FormProvider>
  );
}
