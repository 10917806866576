import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import BasicInfoRoute from '@front/proceedings/route/detail/basicInfo';
import WriterInfoRoute from '@front/proceedings/route/detail/writerInfo';
import AttendanceInfoRoute from '@front/proceedings/route/detail/attendanceInfo';
import ReaderInfoRoute from '@front/proceedings/route/detail/readerInfo';
import ContentsRoute from '@front/proceedings/route/detail/contents';
import AttachedFileListRoute from '@front/proceedings/route/detail/attachedFileList';
import CommentsRoute from '@front/proceedings/route/detail/comments';

export { sectionComponent as proceedingsSectionComponent };

const sectionComponent: SectionComponent = {
  73: ({ ...rest }) => <BasicInfoRoute {...rest} />,
  74: ({ ...rest }) => <WriterInfoRoute {...rest} />,
  75: ({ ...rest }) => <AttendanceInfoRoute {...rest} />,
  76: ({ ...rest }) => <ReaderInfoRoute {...rest} />,
  77: ({ ...rest }) => <ContentsRoute {...rest} />,
  78: ({ ...rest }) => <AttachedFileListRoute {...rest} />,
  79: ({ ...rest }) => <CommentsRoute {...rest} />,
};
