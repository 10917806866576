import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AffiliatedPersonFeature from '@front/src/features/affiliated-person/index';
import AffiliatedPersonDetailFeature from '@front/src/features/affiliated-person/features/detail';
import AffiliatedPersonMemoFeature from '@front/src/features/affiliated-person/features/memo';
import UIBuilderRouteElement from '@front/src/components/ui-builder/RouteElement';

const AffiliatedPersonRoutes = () => (
  <Routes>
    <Route
      path=""
      element={<AffiliatedPersonFeature />}
    >
      <Route
        path=":id"
        element={
          <AffiliatedPersonDetailFeature>
            <AffiliatedPersonMemoFeature />
          </AffiliatedPersonDetailFeature>
        }
      >
        <Route
          path="outline"
          element={<UIBuilderRouteElement />}
        />
        <Route
          path="character"
          element={<UIBuilderRouteElement />}
        />
        <Route
          path="relation"
          element={<UIBuilderRouteElement />}
        />
        <Route
          path="gift-history"
          element={<UIBuilderRouteElement />}
        />
        <Route
          path="meeting-history"
          element={<UIBuilderRouteElement />}
        />
        <Route
          path="sales-activity"
          element={<UIBuilderRouteElement />}
        />
      </Route>
    </Route>
  </Routes>
);

export default AffiliatedPersonRoutes;
