import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import Checkbox from '@front/layouts/Checkbox';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import type { UserRecordView } from '@front/src/types';
import { ProjectSalesInfoContractEarningsCollectionManagementContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/context';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import type { ProjectCollectionManagementView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/view';
import dayjs from 'dayjs';
import { shallow } from 'zustand/shallow';

export { ManageCollectionTableRow as ProjectSalesInfoCollectionManagementTableRow };

interface Props {
  item: ProjectCollectionManagementParams;
  index: number;
}

const ManageCollectionTableRow = ({ item, index }: Props) => {
  const { idList, setIdList, setIsUpdateModalOpen, setIdx } =
    useProjectSalesInfoCollectionManagementModalState(
      (state) => ({
        idList: state.idList,
        setIdList: state.setIdList,
        setIsUpdateModalOpen: state.setIsUpdateModalOpen,
        setIdx: state.setIdx,
      }),
      shallow
    );
  const {
    d: { divisionDetail },
  } = useContext(ProjectSalesInfoContractEarningsCollectionManagementContext);
  const handleCheckboxChange = useCallback(
    (index) => () => {
      setIdList(spliceAndPushIfExist(idList, index));
    },
    [setIdList, idList]
  );
  const onClick = () => {
    setIsUpdateModalOpen();
    setIdx(index);
  };
  return (
    <TableRow
      hover={true}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(index)}
          onChange={handleCheckboxChange(index)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        />
      </OldTd>
      <OldTd>{index + 1}</OldTd>
      <OldTd>{item?.unpaidReason}</OldTd>
      <OldTd>{item?.collectionDueDate}</OldTd>
      <OldTd>{item?.billingDate}</OldTd>
      <OldTd>{item?.collectionForecastDate}</OldTd>
      <OldTd>{item?.collectionPossibilityRatio ? item.collectionPossibilityRatio + '%' : ''}</OldTd>
      <OldTd>{(divisionDetail?.salesManager as UserRecordView)?.name}</OldTd>
      <OldTd>
        {item?.affiliatedPersonName ??
          (item as unknown as ProjectCollectionManagementView)?.affiliatedPerson?.name}
      </OldTd>
      <OldTd>{item?.comment}</OldTd>
      <OldTd>{dayjs(item?.createdAt).format('YYYY-MM-DD')}</OldTd>
    </TableRow>
  );
};
