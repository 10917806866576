import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewShapeRatioListLogic } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewShapeRatioListContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewShapeRatioListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list, isLoading } = useProjectSalesInfoSubjectReviewShapeRatioListLogic(menuId);
  const value = useMemo(() => ({ list, isLoading }), [list, isLoading]);
  return (
    <ProjectSalesInfoSubjectReviewShapeRatioListContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewShapeRatioListContext.Provider>
  );
};
