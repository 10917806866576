import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoActivityUserItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';

export { CellAddAffiliates as SalesInfoActivityTableCellAddAffiliates };

const CellAddAffiliates = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const salesActivityUserId = item.activityUser?.id;
  const { onCreate } = useContext(ProjectSalesInfoActivityUserItemContext);
  const handleButtonClick = useCallback(() => {
    onCreate(salesActivityUserId);
  }, [onCreate, salesActivityUserId]);
  if (item.activity?.id !== id) {
    return (
      <Td
        sx={{
          width: '15%',
        }}
        rowSpan={item.sameActivityUserSize}
      />
    );
  }
  return (
    <Td
      rowSpan={item.sameActivityUserSize}
      sx={{
        width: '15%',
      }}
    >
      <Button
        onClick={handleButtonClick}
        sx={{
          minWidth: '40px',
        }}
      >
        +
      </Button>
    </Td>
  );
};
