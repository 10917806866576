import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectReviewAmountPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';

interface State {
  d: {
    isPaidOptionList?: VariableInformationView[];
    consortiumCompanyList?: ProjectContractEarningsConsortiumCompanyView[];
  };
  h: { onCreate: (params: ProjectReviewAmountPaymentHistoryParams) => void };
}

export { Context as ProjectSalesInfoPaymentHistoryCreateModalContext };

const Context = createContext<State>({
  d: {},
  h: {
    onCreate: noOp,
  },
});
