import { projectSalesInfoSubjectReviewShapeRatioRepository } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoSubjectReviewShapeRatioListLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: list, isLoading } = projectSalesInfoSubjectReviewShapeRatioRepository.useListGet(
    id,
    menuId
  );
  return { list, isLoading };
};
