import { Box } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { memo, useCallback, useEffect } from 'react';

import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import { proposalAction } from '@front/ost_proposal/action';
import { ProposalStatus } from '@front/ost_proposal/domain';
import type { RootState } from '@front/services/reducer';

const VoteController = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirm } = useDialog();
  const status = useSelector((state: RootState) => state.proposal.detail!.status, shallowEqual);
  const onEnd = useCallback(() => {
    confirm({
      children:
        '투찰을 조기 종료하고 현재까지 취합된 투찰 데이터 기준으로 최종 낙찰가를 산정합니다. 실행하시겠습니까?',
      confirmText: '확인',
      afterConfirm: () => {
        dispatch(proposalAction.endVote());
      },
    });
  }, [confirm, dispatch]);
  const onStop = useCallback(() => {
    confirm({
      children: '투찰을 중지하시겠습니까?',
      confirmText: '확인',
      afterConfirm: () => {
        dispatch(proposalAction.updateProposalStatus({ status: ProposalStatus.STOP_VOTING }));
      },
    });
  }, [confirm, dispatch]);
  const onRestart = useCallback(() => {
    confirm({
      children: '투찰을 다시 시작하시겠습니까?',
      confirmText: '확인',
      afterConfirm: () => {
        dispatch(proposalAction.updateProposalStatus({ status: ProposalStatus.VOTING }));
      },
    });
  }, [confirm, dispatch]);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  if (!location?.state?.isManager) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '10px',
        }}
      >
        <Button
          disabled={!(status === ProposalStatus.VOTING || status === ProposalStatus.STOP_VOTING)}
          onClick={onEnd}
        >
          투찰종료처리
        </Button>
        {status === ProposalStatus.VOTING && (
          <Button
            shape={'basic1'}
            onClick={onStop}
          >
            투찰가능상태
          </Button>
        )}
        {status === ProposalStatus.STOP_VOTING && (
          <Button
            shape={'basic2'}
            onClick={onRestart}
          >
            투찰중지중
          </Button>
        )}
      </Box>
    </Box>
  );
};

const memoized = memo(VoteController);

export { memoized as OstVoteController };
