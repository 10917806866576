import React, { Suspense } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { SalesInfoActivityListProvider } from '@front/src/features/project-sales-info/features/activity/widgets/list/provider/proivder';
import { SalesInfoActivityTableBody } from '@front/src/features/project-sales-info/features/activity/widgets/list/components/table-body';

export { Widget as SalesInfoActivityListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<TableLoadingSpinner />}>
    <SalesInfoActivityListProvider menuId={menuId}>
      <SalesInfoActivityTableBody />
    </SalesInfoActivityListProvider>
  </Suspense>
);
