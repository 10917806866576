import { useQuery } from 'react-query';
import globalNavBarApi from '@front/src/features/global-nav-bar/api/api';

const globalNavBarQuery = {
  useMenuListGet: () => {
    const { data } = useQuery({
      queryFn: globalNavBarApi.getMenuList,
      queryKey: ['global-nav-bar'],
      suspense: true,
    });
    return {
      data,
    };
  },
};

export default globalNavBarQuery;
