import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyFamilyInformationRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/repository/repository';
import { useAffiliatedCompanyDetailModalCharacterFamilyInformationState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyDetailModalCharacterFamilyInformationLogic };

interface Props {
  id?: number;
  menuId?: number;
}

const useLogic = ({ id, menuId }: Props) => {
  const { idList, setIdList } = useAffiliatedCompanyDetailModalCharacterFamilyInformationState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { modalId } = useAffiliatedCompanyPersonState(
    (state) => ({
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: familyInformationList, isLoading } =
    affiliatedCompanyFamilyInformationRepository.usePersonCharacterFamilyInformationListGet(
      id ?? modalId,
      menuId
    );
  const { run: onCreateFamilyInformation } =
    affiliatedCompanyFamilyInformationRepository.usePersonCharacterFamilyInformationCreate(menuId);
  const { run: onDeleteFamilyInformation } =
    affiliatedCompanyFamilyInformationRepository.usePersonCharacterFamilyInformationDelete(menuId);
  const { run: onUpdateFamilyInformation } =
    affiliatedCompanyFamilyInformationRepository.usePersonCharacterFamilyInformationUpdate(menuId);
  const handleFamilyInformationCreate = useCallback(() => {
    onCreateFamilyInformation(modalId);
  }, [onCreateFamilyInformation, modalId]);
  const d = { familyInformationList };
  const h = {
    onCreateFamilyInformation: handleFamilyInformationCreate,
    setIdList,
    onDeleteFamilyInformation,
    onUpdateFamilyInformation,
  };
  return { d, h, isLoading, idList };
};
