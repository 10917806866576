import React, { memo, useEffect, useState } from 'react';

import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';

interface Props {
  totalReward?: number;
  fixedResultSum?: number;
}

const RestReward = ({ totalReward, fixedResultSum }: Props) => {
  const [rest, setRest] = useState<string>('');

  useEffect(() => {
    if (typeof totalReward === 'undefined') {
      return;
    }
    if (typeof fixedResultSum === 'undefined') {
      return;
    }
    setRest((totalReward - fixedResultSum).toLocaleString());
  }, [totalReward, fixedResultSum]);

  return (
    <DataFieldWithLabel
      label="잔여 상금"
      labelSX={{ minWidth: '80px' }}
    >
      <Input
        isAmount
        key={rest}
        readOnly
        variant="outlined"
        defaultValue={rest}
      />
    </DataFieldWithLabel>
  );
};

const RestRewardForm = memo(RestReward);

export default RestRewardForm;
