import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PersonalSettingsModalUISettingsHeaderSettingsTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/Row';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { usePersonalSettingsModalUISettingsHeaderSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/useState';

export { TableContent as PersonalSettingsModalUISettingsHeaderSettingsTableContent };

const TableContent = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    name: 'list',
    control,
  });
  const clearEditOpenIdList = usePersonalSettingsModalUISettingsHeaderSettingsState(
    (state) => state.clearEditOpenIdList
  );
  useEffect(() => () => clearEditOpenIdList(), [clearEditOpenIdList]);
  if (fields.length === 0) {
    return <NoResult colSpan={6} />;
  }
  return (
    <>
      {fields.map((field, index) => (
        <PersonalSettingsModalUISettingsHeaderSettingsTableRow
          key={field.id}
          index={index}
        />
      ))}
    </>
  );
};
