import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AccountingSettingsTexAccountingAccountGetCountContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/provider/context';
import { useAccountingSettingsTexAccountingAccountGetCountLogic } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-count/provider/useLogic';

export { Provider as AccountingSettingsTexAccountingAccountGetCountProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { detail } = useAccountingSettingsTexAccountingAccountGetCountLogic(menuId);
  const value = useMemo(() => ({ detail }), [detail]);
  return (
    <AccountingSettingsTexAccountingAccountGetCountContext.Provider value={value}>
      {children}
    </AccountingSettingsTexAccountingAccountGetCountContext.Provider>
  );
};
