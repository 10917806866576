import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { default as MuiSelect } from '@front/layouts/Select';
import Checkbox from '@front/layouts/Checkbox';

interface Props {
  optionList: string[];
  checkedList: string[];
  className?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  variant?: 'standard' | 'outlined' | 'filled' | 'transparent';
  value: string[];
  onChange: (e) => void;
}

export const MultipleSelect = ({
  checkedList,
  optionList,
  width,
  height,
  variant = 'outlined',
  ...rest
}: Props) => (
  <MuiSelect
    multiple
    displayEmpty
    variant={variant}
    MenuProps={{
      width,
      height,
    }}
    renderValue={(selected) => {
      if ((selected as string[]).length === 0) {
        return '선택';
      }
      return (selected as string[]).join(', ');
    }}
    {...rest}
  >
    <MenuItem
      disabled
      value=""
    >
      선택
    </MenuItem>
    {optionList.map((item) => (
      <MenuItem
        key={item}
        value={item}
      >
        <Checkbox checked={checkedList.indexOf(item) > -1} /> {item}
      </MenuItem>
    ))}
  </MuiSelect>
);
