import { ProjectContribution } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/domain';

export const affiliatedCompanyPersonContributionOptionForLabel = {
  [ProjectContribution.RECOMMENDATION]: '추천',
  [ProjectContribution.INTRODUCTION]: '소개',
  [ProjectContribution.INQUIRY]: '하-문의',
  [ProjectContribution.AMOUNT_NEGOTIATION]: '중-금액합의',
  [ProjectContribution.ESTIMATE_COMPARED]: '상-대비견적',
  [ProjectContribution.AMOUNT_OPEN]: '최상-금액오픈',
};

export const affiliatedCompanyPersonContributionOptionList = [
  {
    label: affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.RECOMMENDATION],
    value: ProjectContribution.RECOMMENDATION,
  },
  {
    label: affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.INTRODUCTION],
    value: ProjectContribution.INTRODUCTION,
  },
  {
    label: affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.INQUIRY],
    value: ProjectContribution.INQUIRY,
  },
  {
    label:
      affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.AMOUNT_NEGOTIATION],
    value: ProjectContribution.AMOUNT_NEGOTIATION,
  },
  {
    label: affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.ESTIMATE_COMPARED],
    value: ProjectContribution.ESTIMATE_COMPARED,
  },
  {
    label: affiliatedCompanyPersonContributionOptionForLabel[ProjectContribution.AMOUNT_OPEN],
    value: ProjectContribution.AMOUNT_OPEN,
  },
];
