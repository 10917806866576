import { affiliatedPersonDrawerQuery } from '@front/src/features/affiliated-person/features/drawer/query/query';

export { repository as affiliatedPersonDrawerRepository };

const repository = {
  useListGet: (menuId) => {
    const { data, remove } = affiliatedPersonDrawerQuery.useListGet(menuId);
    return {
      data,
      remove,
    };
  },
};
