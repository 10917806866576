// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cehf3pqdQaH2apRkLiog{display:flex;justify-content:center;width:100%;column-gap:5px;line-height:20px}`, "",{"version":3,"sources":["webpack://./front/src/components/table-side-header-with-checkbox/table-side-header-with-checkbox.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".th {\r\n  display: flex;\r\n  justify-content: center;\r\n  width: 100%;\r\n  column-gap: 5px;\r\n  line-height: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": `Cehf3pqdQaH2apRkLiog`
};
export default ___CSS_LOADER_EXPORT___;
