import { createContext } from 'react';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';

export { Context as AffiliatedPersonCompanySelectModalListContext };

interface State {
  list?: AffiliatedPersonCompanyView[];
}

const Context = createContext<State>({});
