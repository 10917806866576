import React from 'react';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import ProjectSalesAsideCreateForm from '@front/src/features/project-sales/features/aside/features/create-button/components/Form';

interface Props {
  open: boolean;
  onClose: () => void;
  menuId: number | undefined;
}

export default function ProjectSalesAsideCreateModal({ onClose, open, menuId }: Readonly<Props>) {
  return (
    <ModalUI
      title="신규 프로젝트 등록"
      open={open}
      onClose={onClose}
    >
      <ProjectSalesAsideCreateForm
        onClose={onClose}
        menuId={menuId}
      />
    </ModalUI>
  );
}
