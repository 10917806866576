import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { Outlet } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { AffiliatedPersonDetailTab } from '@front/src/features/affiliated-person/features/detail/components/tab';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { AffiliatedPersonDetailDeleteWidget } from '@front/src/features/affiliated-person/features/detail/widgets/delete/widget';
import { Box } from '@mui/material';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { isMobileDevice } from '@front/util/PwaUtil';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';

interface Props {
  children: ReactNode;
}

const AffiliatedPersonDetailFeature = ({ children }: Props) => {
  const { '*': pathVariables } = useParams<{ '*': string }>();
  const { modalId, setModalId } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      modalId: state.modalId,
      setModalId: state.setModalId,
    }))
  );
  const { setId, setTab } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      setId: state.setId,
      setTab: state.setTab,
    }))
  );
  useEffect(() => {
    if (typeof pathVariables === 'undefined') return;
    const [stringId, pathname] = pathVariables.split('/');
    setModalId(+stringId);
    setId(+stringId);
    setTab(pathname);
    return () => {
      setModalId(undefined);
      setId(undefined);
      setTab('outline');
    };
  }, [pathVariables, setId, setModalId, setTab]);
  const isMemo = useMobileMenuState((state) => state.isMemo);
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  const menuId = useGlobalNavBarState((state) => state.menuId);
  if (typeof modalId === 'undefined') {
    return <LoadingSpinner height="100px" />;
  }
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          p: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
          <AffiliatedPersonDetailDeleteWidget menuId={menuId} />
        </Box>
        <AffiliatedPersonDetailTab />
        <Box
          sx={{
            maxHeight: 'calc(100dvh - 240px)',
            p: '20px',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Box>
      </Box>
      {(!isMobileDevice() || isMemo) && <Box>{children}</Box>}
    </Box>
  );
};

export default AffiliatedPersonDetailFeature;
