import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import ManagementAccountTable from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/Table';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export default function ManagementAccountFeature(props: SectionComponentProps) {
  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <ManagementAccountTable {...props} />
    </Suspense>
  );
}
