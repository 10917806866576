import React, { Suspense } from 'react';
import { AccountingSettingsTexAccountingAccountGetDateProvider } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/provider/provider';
import { AccountingSettingsTexAccountingAccountUpdatedAt } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/components/updated-at';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as AccountingSettingsTexAccountingAccountGetDateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<LoadingSpinner height="20px" />}>
    <AccountingSettingsTexAccountingAccountGetDateProvider menuId={menuId}>
      <AccountingSettingsTexAccountingAccountUpdatedAt />
    </AccountingSettingsTexAccountingAccountGetDateProvider>
  </Suspense>
);
