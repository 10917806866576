import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import Box from '@mui/material/Box';

import type { WorkOutputFileUpdateParameter } from '@front/src/features/work/features/work/features/output/types/parameter';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface Props extends CellComponentProps<WorkOutputView> {
  onUpdateFile: (params: WorkOutputFileUpdateParameter) => void;
  targetId?: number;
}

export default function WorkOutputTableBodyRowFile({
  item,
  onUpdateFile,
  sectionId,
  dataId,
  menuId,
  targetId,
  isForm,
}: Props) {
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const request = {
      menuId,
      dataId,
      sectionId,
      targetId,
    };
    const formData = new FormData();
    formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
    formData.append('image', data.fileItem && data.fileItem.multipartFile);
    onUpdateFile({
      id: data.id,
      formData,
    });
  });
  return (
    <FormProvider {...methods}>
      <Box>
        <HookFormFileUploader
          name="fileItem"
          onSubmit={isForm ? undefined : onSubmit}
          id={`${item.id}-output`}
        />
      </Box>
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    id: item.id,
    fileItem: item.fileItem ?? '',
  };
}
