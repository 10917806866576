import { AccountingUploadSalesInfoRepository } from '@front/src/features/accounting/features/upload/features/sales-info/repository/repository';
import { useAccountingUploadSalesInfoState } from '@front/src/features/accounting/features/upload/features/sales-info/useState';
import { useShallow } from 'zustand/react/shallow';

export { useLogic as useAccountingUploadSalesInfoDownloadExcelLogic };

const useLogic = (menuId) => {
  const { year } = useAccountingUploadSalesInfoState(
    useShallow((state) => ({
      year: state.year,
    }))
  );
  const { data: list } = AccountingUploadSalesInfoRepository.useGetList(
    {
      year,
    },
    menuId
  );
  return {
    list,
  };
};
