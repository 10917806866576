import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectAspectRatioExaminationParameter } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getList: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination`,
  create: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination`,
  update: (id?) => `${projectSalesInfoURL}/aspect-ratio-examination/${id}`,
};

export const projectSalesInfoSubjectReviewShapeRatioApi = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  create: async (id: number, menuId) => {
    const { data } = await axios.post(url.create(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params: ProjectAspectRatioExaminationParameter, menuId) => {
    await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
