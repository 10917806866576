import React from 'react';
import { type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import ManagementAccountTableFullCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/FullCell';
import ManagementAccountTableCellInput from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/CellInput';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';

interface Props extends SectionComponentProps {
  colIndex: number;
  rowIndex: number;
  showListFieldArray: UseFieldArrayReturn<ManagementAccountTableFormValues, 'showList', 'id'>;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
  onTableBodyFocus: () => void;
}

export default function ManagementAccountTableCell(props: Props) {
  const { rowIndex, colIndex, readOnly } = props;
  const { getValues, watch } = useFormContext<ManagementAccountTableFormValues>();

  const showList = getValues('showList');
  const selectedRowIndexList = getValues('selectedRowIndexList');

  const cellData = showList[colIndex]?.[rowIndex];
  const beforeCellData = showList[colIndex]?.[rowIndex - 1];

  const updateId = watch('updateId');

  const isNextDepthFirstCell = rowIndex === 0 && selectedRowIndexList.length === colIndex;
  const isLastCell = !cellData && beforeCellData;

  const renderCellContent = () => {
    if (cellData) {
      if (!readOnly && updateId === cellData.id) {
        return (
          <ManagementAccountTableCellInput
            {...props}
            cellData={cellData}
            mode="update"
          />
        );
      }
      return (
        <ManagementAccountTableFullCell
          {...props}
          cellData={cellData}
        />
      );
    }

    if (!readOnly && (isNextDepthFirstCell || isLastCell)) {
      return (
        <ManagementAccountTableCellInput
          {...props}
          cellData={cellData}
          mode="create"
        />
      );
    }

    return <NDepthTableBoxCell>{''}</NDepthTableBoxCell>;
  };

  return renderCellContent();
}
