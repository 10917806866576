import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useShallow } from 'zustand/react/shallow';
import { projectSalesInfoAbstractCollectionRepository } from '@front/src/features/project-sales-info/features/abstract/features/collection/repository/repository';

export { useLogic as useProjectSalesInfoAbstractCollectionListLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { data: list } = projectSalesInfoAbstractCollectionRepository.useListGet(id, menuId);
  return {
    list,
  };
};
