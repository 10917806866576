import React from 'react';
import Box from '@mui/material/Box';
import type { DefaultFunction } from 'type/Function';
import type { ConfirmView } from '@front/src/features/dialog/types';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';

interface Props extends ConfirmView {
  open: boolean;
  onClose: DefaultFunction;
  onConfirm: DefaultFunction;
}

export function DialogConfirm(props: Props) {
  const {
    open,
    onClose,
    onConfirm,
    confirmText,
    closeText,
    children,
    title = '확인',
    lineBreakChildren,
  } = props;
  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title={title}
    >
      <ModalBodyUI>
        <ModalHeaderUI>
          <ButtonBasicUI
            onClick={onClose}
            shape="tertiary"
            sx={{
              width: '64px',
            }}
          >
            {closeText}
          </ButtonBasicUI>
          <ButtonBasicUI
            onClick={onConfirm}
            shape="primary2"
            sx={{
              width: '64px',
            }}
          >
            {confirmText}
          </ButtonBasicUI>
        </ModalHeaderUI>
        <ModalContentUI
          sx={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'pre-line',
          }}
        >
          {children}
          <Box>
            {lineBreakChildren?.map(({ value, sx }) => (
              <Box
                key={value}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  ...sx,
                }}
              >
                {value}
              </Box>
            ))}
          </Box>
        </ModalContentUI>
      </ModalBodyUI>
    </ModalUI>
  );
}
