import Button from '@front/layouts/Button';
import React, { useContext } from 'react';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/components/update-modal.module.scss';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import { useFormContext } from 'react-hook-form';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import { ProjectSalesInfoCollectionManagementModalUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/features/update-modal/widgets/context';
import { shallow } from 'zustand/shallow';
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

export { UpdateModalBtn as ProjectSalesInfoCollectionManagementModalUpdateModalBtn };

const UpdateModalBtn = () => {
  const { setIsUpdateModalOpen, updateCollectionManagementList, idx } =
    useProjectSalesInfoCollectionManagementModalState(
      (state) => ({
        setIsUpdateModalOpen: state.setIsUpdateModalOpen,
        updateCollectionManagementList: state.updateCollectionManagementList,
        idx: state.idx,
      }),
      shallow
    );
  const { handleSubmit, getValues } = useFormContext<ProjectCollectionManagementParams>();
  const {
    d: { affiliatedPersonList },
  } = useContext(ProjectSalesInfoCollectionManagementModalUpdateModalContext);
  const onClick = handleSubmit((data) => {
    updateCollectionManagementList(idx!, {
      ...data,
      collectionDueDate: formatDateOrNull(data.collectionDueDate),
      billingDate: formatDateOrNull(data.billingDate),
      collectionForecastDate: formatDateOrNull(data.collectionForecastDate),
      affiliatedPersonName:
        affiliatedPersonList?.find((item) => item.person?.id === +getValues('affiliatedPersonId'))
          ?.person?.name ?? '',
    });
    setIsUpdateModalOpen();
  });

  return (
    <div className={classes.btn__group}>
      <Button
        shape="basic2"
        fullWidth={true}
        onClick={() => setIsUpdateModalOpen()}
      >
        취소
      </Button>
      <Button
        fullWidth={true}
        onClick={onClick}
      >
        저장
      </Button>
    </div>
  );
};
