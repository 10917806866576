import React from 'react';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import HeaderHistoryTableContent from '@front/src/features/header-history/components/TableContent';

export default function HeaderHistoryTable() {
  return (
    <TableContainerUI>
      <TableTableUI>
        <TableHeadUI>
          <TableRowUI>
            <TableCellUI
              isHead
              width="100px"
            >
              변경일시
            </TableCellUI>
            <TableCellUI
              isHead
              width="200px"
            >
              현재 헤더명
            </TableCellUI>
            <TableCellUI
              isHead
              width="200px"
            >
              기존
            </TableCellUI>
            <TableCellUI
              isHead
              width="200px"
            >
              변경
            </TableCellUI>
            <TableCellUI
              isHead
              width="400px"
            >
              비고
            </TableCellUI>
            <TableCellUI
              isHead
              width="80px"
            >
              처리자
            </TableCellUI>
          </TableRowUI>
        </TableHeadUI>
        <TableBodyUI>
          <HeaderHistoryTableContent />
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
