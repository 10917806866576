import React from 'react';

import {DialogPrompt} from "@front/src/features/dialog/components/prompt";
import {useDialogPromptLogic} from "@front/src/features/dialog/widgets/prompt/useLogic";

export { Widget as DialogPromptWidget };

const Widget = () => {
  const {
    h: { onClose },
    promptProps,
  } = useDialogPromptLogic();

  const isOpen = typeof promptProps !== 'undefined';

  if (!promptProps) {
    return <></>;
  }
  return (
    <>
      {isOpen && (
        <DialogPrompt
          {...promptProps}
          open={typeof promptProps !== 'undefined'}
          onClose={() => {
            if (promptProps?.afterClose) {
              promptProps.afterClose();
            }
            onClose();
          }}
          onConfirm={(value: string) => {
            if (promptProps?.afterPrompt) {
              promptProps.afterPrompt(value);
            }
            onClose();
          }}
        />
      )}
    </>
  );
};
