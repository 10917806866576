import { useIsMutating } from 'react-query';

export const useBoxMutationLoading = () => {
  const isCommentCreateLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'create'],
  });
  const isCommentUpdateLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'update'],
  });
  const isCommentDeleteLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'delete'],
  });
  const isDocumentCancelLoading = useIsMutating({ mutationKey: ['approval-document', 'cancel'] });

  return !!(
    isCommentCreateLoading ||
    isCommentUpdateLoading ||
    isCommentDeleteLoading ||
    isDocumentCancelLoading
  );
};
