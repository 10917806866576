export interface ArrangementQuery {
  keyword: string;
  status: string;
  arrangementStatus: string;
}

export const initialArrangementQuery: ArrangementQuery = {
  keyword: '',
  status: '',
  arrangementStatus: '',
};

export interface ArrangementMemoQuery {
  keyword: string;
}

export const initialArrangementMemoQuery: ArrangementMemoQuery = {
  keyword: '',
};
