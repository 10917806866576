import React, { useMemo } from 'react';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { Box } from '@mui/material';
import { personalSettingsModalUISettingsHeaderSettingsPositionRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/repository/repository';
import { useFormContext } from 'react-hook-form';

export { Status as PersonalSettingsModalUISettingsHeaderSettingsTableContentStatus };
interface Props {
  authMenuId: number | undefined;
}
const Status = ({ authMenuId }: Props) => {
  const { data: list } =
    personalSettingsModalUISettingsHeaderSettingsPositionRepository.useGetStatus(authMenuId);
  const methods = useFormContext();
  const emptyRows = useMemo(
    () =>
      Array(20 - (list?.length ?? 0))
        .fill(null)
        .map((_, index) => (
          <TableRowBox key={`empty-status-${index}`}>
            <TbcBox />
          </TableRowBox>
        )),
    [list]
  );
  if (!list || methods.getValues('section').length === 0)
    return (
      <>
        {Array(20)
          .fill(null)
          .map((_, index) => (
            <TableRowBox key={`empty-status-${index}`}>
              <TbcBox />
            </TableRowBox>
          ))}
      </>
    );
  return (
    <>
      {list.map(({ origin }, index) => (
        <TableRowBox key={`status-${index}`}>
          <TbcBox>
            <Box>{origin}</Box>
          </TbcBox>
        </TableRowBox>
      ))}
      {emptyRows}
    </>
  );
};
