// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XHnTHk6FT66rfgOOxNI0{display:flex;justify-content:center;gap:10px;width:100%}.IM8SSSbp_HxYns2Jeea1{display:flex;flex-direction:column;row-gap:16px}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/person/components/create-modal/modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,QAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,YAAA","sourcesContent":[".footer {\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 10px;\r\n  width: 100%;\r\n}\r\n\r\n.section {\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `XHnTHk6FT66rfgOOxNI0`,
	"section": `IM8SSSbp_HxYns2Jeea1`
};
export default ___CSS_LOADER_EXPORT___;
