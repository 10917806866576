import type { AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import { accountingManagementAccountingReadQuery } from '@front/src/features/accounting/features/settings/features/management-accounting-read/query/query';
import type { AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-read/types/parameter';

export { Repository as accountingManagementAccountingReadRepository };

const Repository = {
  useListGet: (
    params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter,
    menuId
  ) => {
    const { data, isLoading, remove } = accountingManagementAccountingReadQuery.useListGet(
      params,
      menuId
    );
    return {
      data,
      isLoading,
      remove,
    };
  },
  useCategoryMicroListGet: (
    params: AccountingSettingsManagementAccountingReadEAccountAndManualListGetParameter,
    menuId
  ) => {
    const { data, isLoading, remove } =
      accountingManagementAccountingReadQuery.useCategoryMicroListGet(params, menuId);
    return {
      data,
      isLoading,
      remove,
    };
  },
};
