import {
  ForeignCurrencyAccountInUse,
  ForeignCurrencyAccountInUseObject,
  IsUse,
} from '@front/src/features/accounting/features/settings/features/bank-account/types/domain';

export const convertForeignCurrencyAccountInUseBoolean = (input) => {
  if (typeof input !== 'boolean') {
    return '-';
  }
  if (input) {
    return ForeignCurrencyAccountInUseObject[ForeignCurrencyAccountInUse.IN_USE];
  }
  return ForeignCurrencyAccountInUseObject[ForeignCurrencyAccountInUse.NOT_IN_USE];
};

export const convertInUseBoolean = (input) => {
  if (typeof input !== 'boolean') {
    return '-';
  }
  if (input) {
    return IsUse.YES;
  }
  return IsUse.NO;
};
