import React, { Suspense } from 'react';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { CollectionHistoryTable } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/table';
import { collectionHistoryTableCells } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/table-cells';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';

export { Section as CollectionHistorySection };

function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(collectionHistoryTableCells);

  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={props.name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <CollectionHistoryTable sectionProps={props} />
      </Suspense>
    </Layout>
  );
}
