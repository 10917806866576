import React from 'react';
import { Badge as MuiBadge } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { Badge as NotificationBadge };

interface Props {
  count?: number;
  sx?: React.CSSProperties;
  children?: React.ReactNode;
  max?: number;
  type?: 'menu' | 'drawer' | 'basic';
}

const Badge = ({ count, sx, children, max = 99, type = 'basic' }: Props) => (
  <MuiBadge
    invisible={!count}
    badgeContent={count}
    max={max}
    sx={{
      ...(type === 'drawer'
        ? {
            textAlign: 'center',
            overflow: 'visible !important',
            display: 'inline-block',
            width: '100%',
          }
        : {}),
      '& .MuiBadge-badge': {
        display: count ? 'flex' : 'none',
        backgroundColor: ColorPalette._eb4c4c,
        color: ColorPalette._f1f5fc,
        ...(type === 'menu' || type === 'drawer'
          ? {
              top: '50%',
              transform: 'translate3d(40px, -10px, 0)',
            }
          : {}),
        ...sx,
      },
    }}
  >
    {children}
  </MuiBadge>
);
