import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import Box from '@mui/material/Box';

export default function TooltipUI(props: TooltipProps) {
  const { children, ...rest } = props;
  return (
    <Tooltip
      placement="top-start"
      slotProps={{
        tooltip: {
          sx: {
            padding: '4px 6px',
            fontSize: '1.2rem',
            fontWeight: '400',
            lineHeight: 'normal',
            color: ColorPalette.greyscale.white,
            backgroundColor: ColorPalette.background.bg04,
            borderRadius: '5px',
          },
        },
      }}
      {...rest}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}
