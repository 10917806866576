import React from 'react';
import { useFormContext } from 'react-hook-form';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import WorkCarouselMoveSeqFeature from 'src/features/work/features/work/features/carousel/features/move-seq';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

export default function WorkCarouselMoveSeqModal(props: Readonly<WorkCarouselSectionProps>) {
  const { watch, setValue } = useFormContext();
  const open = watch('openMoveSeqModal');
  const onClose = () => {
    setValue('openMoveSeqModal', false);
  };
  return (
    <ModalUI
      title="이미지 슬라이드 순서 이동"
      open={open}
      onClose={onClose}
    >
      <WorkCarouselMoveSeqFeature
        {...props}
        onClose={onClose}
      />
    </ModalUI>
  );
}
