import React from 'react';
import WorkOutputBundleModalForm from '@front/src/features/work/features/work/features/output/components/Form';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';

import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {
  item: WorkOutputBundleView;
  open: boolean;
  isCreate: boolean;
  onClose: () => void;
}

export default function WorkOutputModal(props: Readonly<Props>) {
  const { open, onClose, isCreate } = props;
  const title = `${isCreate ? '폴더 추가' : '폴더명 수정'}`;
  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title={title}
    >
      <WorkOutputBundleModalForm
        {...props}
        isCreate={isCreate}
        onClose={onClose}
      />
    </ModalUI>
  );
}
