import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { projectSalesInfoBidInfoUpdateModalQuery } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query';
import { projectSalesInfoBidInfoUpdateModalMutation } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/query/mutation';

export const projectSalesInfoBidInfoUpdateModalRepository = {
  useProjectSalesInfoBidInfoDetailGet: (id: number, menuId) => {
    const { data, isLoading } =
      projectSalesInfoBidInfoUpdateModalQuery.useProjectSalesInfoBidInfoDetailGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useProjectSalesInfoBidInfoDetailUpdate: (id: number, menuId) => {
    const { mutate } = projectSalesInfoBidInfoUpdateModalMutation.useProjectSalesInfoBidInfoUpdate(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectSalesInfoBidInfoParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useEstimateIdListGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoBidInfoUpdateModalQuery.useEstimateIdListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
