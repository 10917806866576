// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xOm6Yn6OzCBHXxlDEciC{display:flex;justify-content:center;padding:20px;border:1px solid #e4e9f2}.f7I3HtOICVZLyV5Y88nL{width:150px}.IH8sYGr3_5FvPsXJEY1S{width:1%}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,sBAAA,CACA,YAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,QAAA","sourcesContent":["@import \"@front/assets/colors.scss\";\r\n\r\n.no__result {\r\n  display: flex;\r\n  justify-content: center;\r\n  padding: 20px;\r\n  border: 1px solid $_e4e9f2;\r\n}\r\n\r\n.width__6 {\r\n  width: 150px;\r\n}\r\n\r\n.width__4 {\r\n  width: 1%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no__result": `xOm6Yn6OzCBHXxlDEciC`,
	"width__6": `f7I3HtOICVZLyV5Y88nL`,
	"width__4": `IH8sYGr3_5FvPsXJEY1S`
};
export default ___CSS_LOADER_EXPORT___;
