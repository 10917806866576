import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { HookFormCompanySelector } from '@front/src/components/company-selector-with-hook-form';
import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { ProjectSalesInfoCMPaymentHistoryCreateModalBtn } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/components/CM-payment-history-create-modal-btn';
import { convertToManageableOptionList } from '@front/src/features/project-sales-info/features/collection/utils/constant';
import { ProjectSalesInfoCMPaymentHistoryCreateModalContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/widgets/context';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

export { CMPaymentHistoryCreateModalTable as ProjectSalesInfoCMPaymentHistoryCreateModalTable };

const CMPaymentHistoryCreateModalTable = () => {
  const methods = useForm<ProjectCMPaymentHistoryParams>({
    values: {
      companyId: '',
      paymentRequestDate: '',
      paymentMethod: '',
      paymentAmount: '',
      paymentType: '',
      paymentDate: '',
      note: '',
      company: undefined,
    },
  });
  const {
    d: { CMPaymentMethodOptionList, CMPaymentStatusOptionList },
  } = useContext(ProjectSalesInfoCMPaymentHistoryCreateModalContext);
  const { readOnly } = useProjectSalesInfoCMPaymentHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <Th width="140px">지급 대상자</Th>
              <OldTd>
                <HookFormCompanySelector
                  position="left"
                  name="company"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>지급 예정일</Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="paymentRequestDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>지급액</Th>
              <OldTd>
                <HookFormInput
                  name="paymentAmount"
                  inputType={InputType.AMOUNT}
                  width="200px"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>지급 방법</Th>
              <OldTd>
                <HookFormSelect
                  name="paymentMethod"
                  optionList={convertToManageableOptionList(CMPaymentMethodOptionList)}
                  width="150px"
                  defaultLabel="선택"
                  isDefaultPossible={true}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>지급 여부</Th>
              <OldTd>
                <HookFormSelect
                  name="paymentType"
                  optionList={convertToManageableOptionList(CMPaymentStatusOptionList)}
                  width="150px"
                  defaultLabel="선택"
                  isDefaultPossible={true}
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>지급일</Th>
              <OldTd sx={{textAlign:'left'}}>
                <DatePickerWithHookForm
                  name="paymentDate"
                  disabled={readOnly}
                />
              </OldTd>
            </TableRow>
            <TableRow>
              <Th>비고</Th>
              <OldTd>
                <HookFormTextarea
                  name="note"
                  minRows={2}
                  resize="none"
                  disabled={readOnly}
                  variable={true}
                />
              </OldTd>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectSalesInfoCMPaymentHistoryCreateModalBtn />
    </FormProvider>
  );
};
