import React from 'react';
import type { TableProps } from '@mui/material/Table';
import Table from '@mui/material/Table';

export default function VirtualizedAutoSizerTableTableUI({ ...rest }: Readonly<TableProps>) {
  return (
    <Table
      {...rest}
      component="div"
      sx={{
        width: '100%',
        height: '100%',
      }}
    />
  );
}
