import React from 'react';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import type { UseFieldArraySwap } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  index: number;
  swap: UseFieldArraySwap;
}

export default function WorkCarouselMoveSeqTableRow({ index, swap }: Readonly<Props>) {
  const { getValues, setValue } = useFormContext();
  const item: WorkCarouselView = getValues(`list.${index}`);
  const onSwapFront = () => {
    if (index === 0) return;
    swap(index, index - 1);
    setValue('movedId', item.id);
  };
  const lastIndex = getValues('list').length - 1;
  const onSwapBack = () => {
    if (index >= lastIndex) return;
    swap(index, index + 1);
    setValue('movedId', item.id);
  };
  const src = `/api/file-item/${item.fileItem?.id}`;
  const disabledFront = index === 0;
  const disabledBack = index === lastIndex;
  const sx: SxProps = {
    backgroundColor: item.seq == index + 1 ? 'transparent' : ColorPalette.main.main_tertiary,
  };
  return (
    <TableRowUI sx={sx}>
      <TableCellUI>{index + 1}</TableCellUI>
      <TableCellUI>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            padding: '5px 10px',
          }}
        >
          <Box
            sx={{
              width: '100px',
              height: '100px',
              minWidth: '100px',
              padding: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgba(0,0,0,0.1)',
              borderRadius: '5px',
              backgroundColor: '#e7f0ff',
              boxSizing: 'border-box',
            }}
          >
            <img
              src={src}
              alt="image-slide-image"
              style={{
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: 0,
              }}
            />
          </Box>
          <TypographyUI
            sx={{
              textAlign: 'left',
            }}
          >
            {item.fileItem?.filename ?? '-'}
          </TypographyUI>
        </Box>
      </TableCellUI>
      <TableCellUI>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <ButtonIconUI
            onClick={onSwapFront}
            disabled={disabledFront}
          >
            <FontAwesomeIcon icon="arrow-up-long" />
          </ButtonIconUI>
          <ButtonIconUI
            onClick={onSwapBack}
            disabled={disabledBack}
          >
            <FontAwesomeIcon icon="arrow-down-long" />
          </ButtonIconUI>
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
}
