import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineEducationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/types/parameter';
import type { AffiliatedCompanyPersonEducationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/types/view';
import type { IdListDeleteParameter } from '@front/src/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineEducationContext };

interface State {
  list?: AffiliatedCompanyPersonEducationView[];
  onCreate: (id: number) => void;
  onUpdate: (params: AffiliatedCompanyPersonOutlineEducationUpdateParameter) => void;
  onDelete: (params: IdListDeleteParameter) => void;
}

const Context = createContext<State>({
  onCreate: noOp,
  onUpdate: noOp,
  onDelete: noOp,
});
