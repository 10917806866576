import React, { useCallback, useContext } from 'react';
import UserSelector from '@front/components/UserSelector';
import type { UserId } from '@front/user/domain';
import { ProjectSalesInfoActivityUserItemContext } from '@front/src/features/project-sales-info/features/activity/widgets/activity-user-item/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';
import { SalesInfoActivityRowContext } from '@front/src/features/project-sales-info/features/activity/widgets/row/provider/context';
import { Td } from '@front/src/components/layout/table/td';
import { Box } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useCustomDialog } from '@front/src/features/dialog';

export { CellUser as SalesInfoActivityTableCellUser };

const CellUser = () => {
  const { item } = useContext(SalesInfoActivityRowContext);
  const { id } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const salesActivityUserId = item.activityUser?.id;
  const { onUpdate, onDelete } = useContext(ProjectSalesInfoActivityUserItemContext);
  const handleSelectorChange = useCallback(
    (userId) => {
      if (!salesActivityUserId) return;
      if (typeof userId === 'undefined') {
        onDelete(salesActivityUserId);
        return;
      }
      onUpdate({
        salesActivityUserId: salesActivityUserId,
        userId,
      });
    },
    [onUpdate, salesActivityUserId]
  );
  const { confirm } = useCustomDialog();
  const handleIconClick = useCallback(() => {
    if (!salesActivityUserId) return;
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onDelete(salesActivityUserId);
      },
    });
  }, [confirm, onDelete, salesActivityUserId]);
  if (item.activity?.id !== id) {
    return <Td rowSpan={item.sameActivityUserSize}>{item.activityUser?.user?.name}</Td>;
  }
  return (
    <Td rowSpan={item.sameActivityUserSize}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <UserSelector
          value={item.activityUser?.user?.id as UserId}
          onChange={handleSelectorChange}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DeleteForeverIcon onClick={handleIconClick} />
        </Box>
      </Box>
    </Td>
  );
};
