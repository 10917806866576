import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoContractContractUpdateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/widgets/useState';
import { ProjectSalesInfoContractContractUpdateModalWidget } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/widgets/widget';
import { useProjectSalesInfoContractContractCreateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/useState';
import { ProjectSalesInfoContractContractCreateModalWidget } from '@front/src/features/project-sales-info/features/contract/features/contract/features/create-modal/widgets/widget';

export { ContractModal as ProjectSalesInfoContractContractModal };
interface Props {
  menuId?: number;
}
const ContractModal = ({ menuId }: Props) => {
  const { isOpen: isCreateModalOpen } = useProjectSalesInfoContractContractCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  const { isOpen: isUpdateModalOpen } = useProjectSalesInfoContractContractUpdateModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (isCreateModalOpen) {
    return <ProjectSalesInfoContractContractCreateModalWidget menuId={menuId} />;
  }
  if (isUpdateModalOpen) {
    return <ProjectSalesInfoContractContractUpdateModalWidget menuId={menuId} />;
  }
  return <></>;
};
