import React from 'react';
import { Box, CircularProgress as MuiCircularProgress } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

interface Props {
  size?: number;
  sx?: SxProps<Theme>;
}

function CircularProgress(props: Props) {
  const size = props.size ? props.size : 40;
  return (
    <Box
      sx={{
        ...props?.sx,
        display: 'flex',
        width: '100%',
        height: '100%',
        color: 'grey.500',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <MuiCircularProgress
        color="inherit"
        size={size}
      />
    </Box>
  );
}

export default CircularProgress;
