import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyPersonCharacterBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPersonCharacterBasic: (id?) => `${personBaseUrl}/${id}/character-basic`,
  updatePersonCharacterBasic: (id?) => `${personBaseUrl}/character-basic/${id}`,
};

export const affiliatedCompanyPersonCharacterBasicApi = {
  getPersonCharacterBasic: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonCharacterBasic(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updatePersonCharacterBasic: async (
    params: AffiliatedCompanyPersonCharacterBasicUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonCharacterBasic(params.id), convertIsYNToBoolean(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};

const convertIsYNToBoolean = (params: AffiliatedCompanyPersonCharacterBasicUpdateParameter) => ({
  ...params,
  isMarried: params.isMarried === '' ? null : params.isMarried === '예',
  isSmoking: params.isSmoking === '' ? null : params.isSmoking === '예',
});
