import type { CSSProperties } from 'react';
import React from 'react';
import type { TableCellProps } from '@mui/material';
import { TableCell } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props extends TableCellProps {
  variant?: 'body' | 'footer';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sx?: CSSProperties;
  height?: string;
}

export const Td = ({
  children,
  align = 'center',
  sx,
  height = '52px',
  ...rest
}: Readonly<Props>) => (
  <TableCell
    align={align}
    sx={{
      backgroundColor: 'transparent',
      color: ColorPalette._252627,
      fontSize: '13px',
      height,
      padding: '0 4px',
      textWrap: 'nowrap',
      ...sx,
    }}
    {...rest}
  >
    {children ?? '-'}
  </TableCell>
);
