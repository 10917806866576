import React, { memo, useCallback, useEffect } from 'react';

import OstMemoAddFormService from '@front/ost/service/memo/addForm';
import OstMemoSearchFormService from '@front/ost/service/memo/searchForm';
import OstMemoListService from '@front/ost/service/memo/list';
import MemoDrawerService from '@front/components/Memo/route/drawer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ostAction } from '@front/ost/action';

const MemoService = () => {
  const dispatch = useDispatch();
  const { isOpen, id } = useSelector(
    (root: RootState) => ({ isOpen: root.ost.isMemoOpen, id: root.ost.id }),
    shallowEqual
  );

  const setDrawer = useCallback(
    (isOpen: boolean) => {
      dispatch(ostAction.setIsMemoOpen(isOpen));
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      dispatch(
        ostAction.setMemoFilter({
          keyword: '',
        })
      );
    }
  }, [id, dispatch]);

  return (
    <MemoDrawerService
      isOpen={isOpen}
      setDrawer={setDrawer}
    >
      <OstMemoAddFormService />
      <OstMemoSearchFormService />
      <OstMemoListService />
    </MemoDrawerService>
  );
};

const OstMemoService = memo(MemoService);

export default OstMemoService;
