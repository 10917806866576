import React, { useMemo } from 'react';
import { useProjectSalesInfoCMSaveHistoryLogic } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/useLogic';
import { ProjectSalesInfoCMSaveHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/context';

export { Provider as ProjectSalesInfoCMSaveHistoryProvider };

interface Props {
  children: React.ReactNode;
}

const Provider = ({ children }: Props) => {
  const {
    d: { CMSaveHistoryList },
    h: { onUpdate },
    isLoading,
  } = useProjectSalesInfoCMSaveHistoryLogic();
  const value = useMemo(
    () => ({
      d: {
        CMSaveHistoryList,
      },
      h: {
        onUpdate,
      },
      isLoading,
    }),
    [CMSaveHistoryList, onUpdate, isLoading]
  );
  return (
    <ProjectSalesInfoCMSaveHistoryContext.Provider value={value}>
      {children}
    </ProjectSalesInfoCMSaveHistoryContext.Provider>
  );
};
