import { useCustomDialog } from '@front/src/features/dialog';
import useWorkOutputAddTop from '@front/src/features/work/features/work/features/output/repository/useAddTop';
import useWorkOutputAddBottom from '@front/src/features/work/features/work/features/output/repository/useAddBottom';
import workOutputBundleMutation from '@front/src/features/work/features/work/features/output-bundle/query/mutation';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import { getEditOpenIdList } from '@front/src/components/ui-builder/table/utils';
import type { ArithmeticOperator } from '@front/src/features/work/features/work/types/parameter';

const useWorkOutputTableHeadMutation = ({ id: bundleId, menuId, sectionId, dataId }) => {
  const commonParams = { menuId, sectionId, dataId };

  const { getValues, setValue } = useFormContext<UIBuilderTableFieldValues>();

  const editOpenIdList = getValues('editOpenIdList');

  const toggleEditOpenId = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId = (id) => {
    setValue('recentUpdatedId', id);
  };

  const { confirm } = useCustomDialog();
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkOutputAddTop(
    bundleId,
    commonParams
  );
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onAddBottom, setCallback: setAddBottomCallback } = useWorkOutputAddBottom(
    bundleId,
    commonParams
  );
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });
  const { mutate: onDeleteBundle } = workOutputBundleMutation.useDelete(commonParams);
  const { mutate: onDeleteAll } = workOutputMutation.useDeleteAll(bundleId, commonParams);

  const onRemoveBundle = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [
        { value: '폴더 삭제 시, 관련된 모든 데이터가 삭제됩니다.' },
        { value: '삭제하시겠습니까?' },
      ],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteBundle(bundleId);
      },
    });
  };

  const onRemoveAll = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  const onMove = workOutputBundleMutation.useMove(commonParams);

  const handleMove = (operator: ArithmeticOperator) => {
    onMove({
      id: bundleId,
      operator,
    });
  };

  return {
    onRemoveBundle,
    onAddTop,
    onAddBottom,
    onRemoveAll,
    onMove: handleMove,
  };
};

export default useWorkOutputTableHeadMutation;
