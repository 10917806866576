import dayjs from 'dayjs';

export type DrawIoData = {
  xml: string | undefined;
  lastModified: string | undefined;
  name: string | undefined;
  id?: number;
};

export const defaultData: DrawIoData = {
  xml: undefined,
  lastModified: undefined,
  name: undefined,
};

type DrawIoProps = {
  className?: string;
  autoSaveKey?: string;
  drawIoUrl?: string;
  hideSaveButton?: boolean;
};

/**
 * Draw.io integration helper.
 * @param {(string, string)=>void} onSave - save callback function.
 * @param {()=>void} onClose - close callback function.
 * @param {DrawIoProps} options - options
 */
export const useDrawIo = (
  onSave: (stringifiedData: string, fileName: string, id?: number | undefined) => void,
  onClose: () => void,
  options?: DrawIoProps
) => {
  const editorUrl = options?.drawIoUrl ? options.drawIoUrl : 'https://drawio.319plan.com';
  const localStorageAutoSaveKey = options?.autoSaveKey ? options.autoSaveKey : 'draw-io-auto-save';
  const editorUrlWithParam = editorUrl + '/?embed=1&ui=atlas&spin=1&proto=json';

  const iframeElement = createIframeElement(
    editorUrlWithParam,
    options?.className,
    options?.hideSaveButton
  );
  let eventHandler;

  const afterSave = (msg, json) => {
    iframeElement.contentWindow?.postMessage(
      JSON.stringify({
        action: 'export',
        format: 'xmlpng',
        xml: msg.xml,
        spin: 'Updating page',
      }),
      '*'
    );

    localStorage.setItem(localStorageAutoSaveKey, json);
  };

  const handleIframeMessage = (evt, workingDrawData) => {
    if (evt.data.length > 0) {
      const msg = JSON.parse(evt.data);
      if (msg.event === 'init') {
        iframeElement.contentWindow?.postMessage(
          JSON.stringify({
            action: 'load',
            autosave: 1,
            xml: workingDrawData.xml,
          }),
          '*'
        );
        iframeElement.contentWindow?.postMessage(
          JSON.stringify({
            action: 'status',
            modified: true,
          }),
          '*'
        );
      } else if (msg.event === 'export') {
        localStorage.setItem(
          localStorageAutoSaveKey,
          JSON.stringify({ lastModified: new Date(), data: msg.data })
        );
        localStorage.removeItem(localStorageAutoSaveKey);
        close();
      } else if (msg.event === 'autosave') {
        localStorage.setItem(
          localStorageAutoSaveKey,
          JSON.stringify({
            lastModified: new Date(),
            xml: msg.xml,
          })
        );
      } else if (msg.event === 'save') {
        const json = JSON.stringify({ lastModified: new Date(), xml: msg.xml });
        let fileName = workingDrawData.name;
        if (fileName === undefined) {
          const tmp = prompt('파일명을 입력하세요');
          if (tmp === '' || tmp === null || tmp.trim() === '') {
            fileName = 'untitled';
          } else {
            fileName = tmp;
          }
        }
        onSave(msg.xml, fileName.split('.')[0], workingDrawData.id);
        afterSave(msg, json);
      } else if (msg.event == 'exit') {
        localStorage.removeItem(localStorageAutoSaveKey);
        close();
      }
    }
  };

  /**
   * Open Draw.io editor on top of the screen.
   * @param {DrawIoData | undefined} originalDrawData - DrawIoData type param is for the loading previous drawing data. And the undefined is for a new drawing.
   */
  const open = (originalDrawData: DrawIoData | undefined) => {
    document.body.append(iframeElement);

    let workingDrawData: DrawIoData = { ...defaultData };
    if (originalDrawData) {
      workingDrawData = originalDrawData;
    } else {
      const autoSavedData = getAutoSavedData(localStorageAutoSaveKey);
      if (autoSavedData.xml) {
        const lastModified = dayjs(autoSavedData.lastModified).format('YYYY-MM-DD HH:mm');
        if (
          confirm(lastModified + ' 시점에 임시 저장된 데이터가 있습니다.\n이어서 편집하겠습니까?')
        ) {
          workingDrawData = autoSavedData;
        }
      }
    }

    eventHandler = (e: MessageEvent<any>) => {
      handleIframeMessage(e, workingDrawData);
    };
    window.addEventListener('message', eventHandler);
  };

  const close = () => {
    window.removeEventListener('message', eventHandler);
    eventHandler = () => {};
    iframeElement.remove();
    onClose();
  };

  const download = (data: DrawIoData) => {
    const link = document.createElement('a');
    link.download = `${data.name}.drawio`;
    const blob = new Blob([data.xml!], { type: 'text/plain' });
    link.href = window.URL.createObjectURL(blob);
    link.click();
  };

  return { open, download };
};

const getAutoSavedData = (localStorageAutoSaveKey) => {
  const rawAutoSavedData = localStorage.getItem(localStorageAutoSaveKey);
  if (rawAutoSavedData == null) {
    return { ...defaultData };
  } else {
    return JSON.parse(rawAutoSavedData) as DrawIoData;
  }
};

const createIframeElement = (
  src: string,
  className: string | undefined,
  hideSaveButton: boolean | undefined
) => {
  const url = hideSaveButton ? `${src}&noSaveBtn=1&saveAndExit=0` : src;
  const iframeElement = document.createElement('iframe');
  iframeElement.setAttribute('src', url);
  iframeElement.className = className ? className : 'custom-draw-io-holder';
  iframeElement.style.width = '100dvw';
  iframeElement.style.height = '100dvh';
  iframeElement.style.position = 'absolute';
  iframeElement.style.zIndex = '10000';
  iframeElement.style.left = '0';
  iframeElement.style.top = '0';
  iframeElement.style.backgroundColor = '#ffffff';
  return iframeElement;
};
