import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyMeetingHistoryRepository } from '@front/src/features/affiliated-company/features/meeting-history/repository/repository';
import { useAffiliatedCompanyMeetingHistoryModalState } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/useState';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';

export { useLogic as useAffiliatedCompanyMeetingHistoryLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    setIsUpdateModalOpen,
    isCreateModalOpen,
    isUpdateModalOpen,
    setIsCreateModalOpen,
    setMeetingHistoryId,
  } = useAffiliatedCompanyMeetingHistoryModalState(
    (state) => ({
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      isCreateModalOpen: state.isCreateModalOpen,
      isUpdateModalOpen: state.isUpdateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
      setMeetingHistoryId: state.setMeetingHistoryId,
    }),
    shallow
  );
  const { idList, setIdList } = useAffiliatedCompanyMeetingHistoryState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }),
    shallow
  );
  const { data: meetingList, isLoading } =
    affiliatedCompanyMeetingHistoryRepository.useMeetingHistoryListGet(id, menuId);
  const { run: onMeetingHistoryDelete } =
    affiliatedCompanyMeetingHistoryRepository.useMeetingHistoryDelete(menuId);
  const d = { meetingList };
  const h = {
    onCreateModalOpen: () => setIsCreateModalOpen(true),
    onUpdateModalOpen: (id?: number) => {
      if (typeof id !== 'undefined') {
        setMeetingHistoryId(id);
      }
      setIsUpdateModalOpen(true);
    },
    setIdList,
    onMeetingHistoryDelete,
  };
  return {
    d,
    h,
    isLoading,
    idList,
    isUpdateModalOpen,
    isCreateModalOpen,
  };
};
