import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSectionTableBody } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-section-table-body';

export { SelectedPartSection as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSection };

const SelectedPartSection = () => (
  <TableContainer>
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSectionTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="60px">선택</Th>
      <Th width="100px">업체구분</Th>
      <Th width="200px">업체명</Th>
      <Th width="100px">이름</Th>
      <Th width="100px">직위</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName =
  'ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartSectionTableHeader';
