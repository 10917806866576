import { createContext } from 'react';
import { noOp } from '@front/src/utils';

import type { DefaultFunction } from '@front/src/types';
import type { AffiliatedCompanyPersonGiftHistoryView } from '@front/src/features/affiliated-company/features/gift-history/types/view';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import {GiftTargetType} from "@front/src/features/affiliated-company/types/domain";

export { Context as AffiliatedCompanyPersonDetailModalGiftHistoryContext };

interface State {
  d: { giftHistoryList?: AffiliatedCompanyPersonGiftHistoryView[] };
  h: {
    onGiftHistoryCreate: DefaultFunction;
    onGiftHistoryUpdate: (params: {
      date: string | null;
      gift: string;
      note: string;
      amount: number;
      senderId: number;
      purpose: string;
      deliveryMethod: string;
      id: number;
      type: GiftTargetType | ""
    }) => void;
    onGiftHistoryDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {},
  h: {
    onGiftHistoryCreate: noOp,
    onGiftHistoryUpdate: noOp,
    onGiftHistoryDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
