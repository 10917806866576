import axios from 'axios';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getBidInfoList: (id: number) => `${bidURLAPI}/${id}/bid-information`,
  deleteBidInfo: () => `${bidURLAPI}/bid-information`,
};

export const projectSalesInfoBidInfoApi = {
  getBidInfoList: async (id: number, menuId) => {
    const { data } = await axios.get(url.getBidInfoList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteBidInfo: async (params: IdDeleteParams, menuId) => {
    await axios.delete(url.deleteBidInfo(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
};
