import React from 'react';

import { DialogAlertWidget } from '@front/src/features/dialog/widgets/alert/widget';
import { DialogConfirmWidget } from '@front/src/features/dialog/widgets/confirm/widget';
import {DialogPromptWidget} from "@front/src/features/dialog/widgets/prompt/widget";

const DialogFeature = () => (
  <>
    <DialogPromptWidget />
    <DialogConfirmWidget />
    <DialogAlertWidget />
  </>
);

export default DialogFeature;
