import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import UserSelectorSelectedList from '@front/src/features/user-selector/features/form/components/SelectedList';
import { ColorPalette } from '@front/assets/theme';
import type { AuthorizationType, UserVO } from '@front/user/domain';
import UserSelectorUserList from '@front/src/features/user-selector/features/form/components/UserList';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface Props {
  multi: boolean;
  menuIdList: number[];
  typeList: AuthorizationType[];
  onChange: (value: UserVO | UserVO[] | undefined) => void;
  predefinedIdList?: number[];
}

export default function UserSelectorUserSelectForm({
  multi,
  menuIdList,
  typeList,
  onChange,
  predefinedIdList,
}: Readonly<Props>) {
  const { control } = useFormContext();
  const { append, remove, fields, replace } = useFieldArray({
    name: 'selectedList',
    control,
  });
  return (
    <>
      <Box
        sx={{
          flex: 2.5,
          flexDirection: 'column',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          minHeight: '40dvh',
          maxHeight: '40dvh',
          overflowY: 'auto',
          scrollbarGutter: 'stable',
        }}
      >
        <UserSelectorUserList
          multi={multi}
          append={append}
          remove={remove}
          replace={replace}
          menuIdList={menuIdList}
          typeList={typeList}
          onChange={onChange}
          predefinedIdList={predefinedIdList}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ArrowRightIcon />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          borderRadius: '5px',
          p: '10px',
          maxHeight: '40dvh',
          overflowY: 'auto',
        }}
      >
        <UserSelectorSelectedList
          multi={multi}
          fields={fields}
          remove={remove}
          onChange={onChange}
        />
      </Box>
    </>
  );
}
