import { projectSalesInfoSectionComponent } from '@front/src/features/project-sales-info/components/section-component';
import type React from 'react';
import { affiliatedCompanySectionComponent } from '@front/src/features/affiliated-company/components/section-component';
import { affiliatedCompanyPersonSectionComponent } from '@front/src/features/affiliated-person/components/section-component';
import { ostSectionComponent } from '@front/src/features/ost/components/section-component';
import { projectSalesManagementSectionComponent } from '@front/src/features/project-sales-management/components/section-component';
import { proceedingsSectionComponent } from '@front/src/features/proceedings/components/section-component';
import { accountingSectionComponent } from '@front/src/features/accounting/components/section-component';
import { myPageSectionComponent } from '@front/src/features/my-page/components/section-component';
import workWorkSectionComponent from '@front/src/features/work/features/work/components/sectionComponent';
import hRCardSectionComponent from '@front/src/features/human-resources/hr-card/components/sectionComponent';
import departmentManagementSectionComponent from '@front/src/features/human-resources/department-management/components/sectionComponent';

export interface SectionComponentProps {
  name?: string;
  sectionId?: number;
  readOnly: boolean;
  dataId?: number;
  menuId?: number;
}

export interface SectionComponent {
  [key: number]: React.FunctionComponent<SectionComponentProps>;
}

export const sectionComponent: SectionComponent = {
  ...workWorkSectionComponent,
  ...projectSalesInfoSectionComponent,
  ...affiliatedCompanySectionComponent,
  ...affiliatedCompanyPersonSectionComponent,
  ...ostSectionComponent,
  ...projectSalesManagementSectionComponent,
  ...proceedingsSectionComponent,
  ...hRCardSectionComponent,
  ...departmentManagementSectionComponent,
  ...accountingSectionComponent,
  // ...approvalSectionComponent,
  ...myPageSectionComponent,
};
