import React from 'react';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoContractHistoryModalState } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/useState';
import { ProjectSalesInfoContractHistoryModalWidget } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/widgets/widget';

export { ContractHistoryModal as ProjectSalesInfoContractHistoryModal };
interface Props {
  menuId?: number;
}
const ContractHistoryModal = ({ menuId }: Props) => {
  const { isOpen } = useProjectSalesInfoContractHistoryModalState(
    (state) => ({
      isOpen: state.isOpen,
    }),
    shallow
  );
  if (!isOpen) {
    return <></>;
  }
  return <ProjectSalesInfoContractHistoryModalWidget menuId={menuId} />;
};
