import { useCallback } from 'react';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import { affiliatedCompanyOutlineShortRepository } from '@front/src/features/affiliated-company/features/outline/features/short/repository/repository';
import type { AffiliatedCompanyOutlineShortUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/short/types/parameter';

export { useLogic as useAffiliatedCompanyOutlineShortLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detailOutline, isLoading } = affiliatedCompanyOutlineRepository.useOutlineGet(
    id,
    menuId
  );
  const { data: categoryOptionList } =
    affiliatedCompanyOutlineShortRepository.useCategoryOptionListGet(menuId);
  const { run: onOutlineShortUpdate } =
    affiliatedCompanyOutlineShortRepository.useOutlineShortUpdate(menuId);
  const handleOutlineShortUpdate = useCallback(
    (params: AffiliatedCompanyOutlineShortUpdateParameter) => {
      onOutlineShortUpdate({ ...params, id });
    },
    [onOutlineShortUpdate, id]
  );
  const d = { detailOutline, categoryOptionList };
  const h = { onOutlineShortUpdate: handleOutlineShortUpdate };
  return { d, h, isLoading };
};
