import { useQuery } from 'react-query';
import type { ProjectEstimationView } from '@front/src/features/project-sales-info/features/estimation/features/estimation/types/view';
import { projectSalesInfoEstimationEstimationUpdateModalApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/query/api';

export { query as projectSalesInfoEstimationEstimationUpdateModalQuery };

const query = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectEstimationView>({
      queryFn: () => projectSalesInfoEstimationEstimationUpdateModalApi.getDetail(id, menuId),
      queryKey: ['project', 'sales-info', 'estimation', 'estimation', id],
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
