import UIBuilderTableHeader, {
  UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';
import PlusOne from '@mui/icons-material/PlusOne';
import React from 'react';

export { TableHead as EstimationFinalTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const itemList = [
    {
      text: '테스트',
      icon: PlusOne,
      action: () => {
        alert('tbd');
      },
      seq: 4,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      isSingleRow
      controlPanelHeaderItemList={itemList}
    />
  );
}
