import { useShallow } from 'zustand/react/shallow';
import { affiliatedPersonMemoRepository } from '@front/src/features/affiliated-person/features/memo/repository/repository';
import { useEffect } from 'react';
import { useAffiliatedPersonDetailState } from '@front/src/features/affiliated-person/features/detail/useState';
import { useAffiliatedPersonMemoState } from '@front/src/features/affiliated-person/features/memo/useState';

export { useLogic as useAffiliatedPersonMemoListLogic };

const useLogic = () => {
  const { id: personId } = useAffiliatedPersonDetailState(
    useShallow((state) => ({
      id: state.id,
    }))
  );
  const { searchParameter } = useAffiliatedPersonMemoState(
    useShallow((state) => ({
      searchParameter: state.searchParameter,
    }))
  );
  const { data: list, remove } = affiliatedPersonMemoRepository.useListGet({
    personId,
    ...searchParameter,
  });
  useEffect(
    () => () => {
      remove();
    },
    [remove]
  );
  return {
    list,
  };
};
