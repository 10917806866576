import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import type { ProjectDesignOutlineParameter } from '@front/src/features/project-sales-info/features/design-outline/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getDesignOutline: (id: number) => `${projectSalesInfoURL}/${id}/design-outline`,
  updateDesignOutline: (id: number) => `${projectSalesInfoURL}/design-outline/${id}`,
  getCityData2: (code?: string) => `/api/address?code=${code?.slice(0, 2)}00000000`,
  getCityData1: () => '/api/address?code=00000000',
};

export const projectSalesInfoDesignOutlineApi = {
  useProjectDesignOutlineGet: async (id: number, menuId) => {
    const { data } = await axios.get(url.getDesignOutline(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  useProjectDesignOutlineUpdate: async (params: ProjectDesignOutlineParameter, menuId) => {
    await axios.put(url.updateDesignOutline(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },

  useCity1DataGet: async (menuId) => {
    const { data } = await axios.get(url.getCityData1(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  useCity2DataGet: async (code?: string, menuId?) => {
    const { data } = await axios.get(url.getCityData2(code), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
