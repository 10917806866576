import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import useActiveTab from '@front/src/features/project-sales/hooks/useActiveTab';
import useProjectSalesAuthCheck from '@front/src/features/project-sales/hooks/useProjectSalesAuth';

export { Tab as AbstractTab };

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]요약
 * @constructor
 */
function Tab() {
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);
  const { id } = useParams<{ id: string }>();
  const dataId = id ? +id : 0;
  useProjectSalesAuthCheck({ dataId, menuId: activeTab?.id });

  return (
    <div>
      {`TAB #${activeTab?.id}`}: {activeTab?.title}
    </div>
  );
}
