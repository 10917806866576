import type { ReactNode } from 'react';
import React from 'react';
import workWorkQuery from '@front/src/features/work/features/work/query/query';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import Box from '@mui/material/Box';
import { WorkCreatorType } from '@front/src/features/work/utils/constants';
import VerticalLine from '@front/src/components/components-with-design/component/VerticalLine';
import SuspenseWrapper from '@front/src/components/components-with-design/component/loading-spinner/SuspenseWrapper';

export default function WorkDetailTitleRight(props: Readonly<SectionComponentProps>) {
  return (
    <SuspenseWrapper>
      <Index {...props} />
    </SuspenseWrapper>
  );
}

function Index({ dataId, menuId, sectionId }: Readonly<SectionComponentProps>) {
  const { data } = workWorkQuery.useGetRole({
    dataId,
    menuId,
    sectionId,
  });
  const renderCreator = (type: WorkCreatorType) => (
    <UserName>{data?.creators.find((c) => c.type === type)?.user?.name ?? '-'}</UserName>
  );
  const renderWorkers = () => {
    if (data?.workers.filter((w) => !!w.user).length === 0) return <UserName>-</UserName>;
    const text = data?.workers
      .filter((w) => !!w.user)
      .map((w) => w.user.name)
      .join(', ');
    return <UserName>{text}</UserName>;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
      }}
    >
      <Item>
        <Label>지시자:</Label>
        {renderCreator(WorkCreatorType.INDICATOR)}
      </Item>
      <VerticalLine />
      <Item>
        <Label>담당자:</Label>
        {renderCreator(WorkCreatorType.MANAGER)}
      </Item>
      <VerticalLine />
      <Item>
        <Label>협업자:</Label>
        {renderWorkers()}
      </Item>
    </Box>
  );
}

interface Props {
  children: ReactNode;
}

function Item({ children }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
      }}
    >
      {children}
    </Box>
  );
}

function Label({ children }: Props) {
  return (
    <Box
      sx={{
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
  );
}

function UserName({ children }: Props) {
  return (
    <Box
      sx={{
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
  );
}
