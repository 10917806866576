import type {
  AdminManageableVariableSearchParams,
  AdminManageableVariableUpdateParams,
} from '@front/src/features/manageable-variable/types/parameter';
import { adminManageableVariableQuery } from '@front/src/features/manageable-variable/query';
import { adminManageableVariableMutation } from '@front/src/features/manageable-variable/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

export { repository as adminManageableVariableRepository };
const repository = {
  useAdminManageableVariableSearch: (params: AdminManageableVariableSearchParams, menuId) => {
    const { data, isLoading } = adminManageableVariableQuery.useAdminManageableVariableSearch(
      params,
      menuId
    );
    return { data, isLoading };
  },
  useCreate: (menuId) => {
    const { mutate } = adminManageableVariableMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: () => {
        mutate(undefined, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = adminManageableVariableMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params: AdminManageableVariableUpdateParams) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useListGet: (key: string, menuId) => {
    const { data, isLoading } = adminManageableVariableQuery.useListGet(key, menuId);
    return { data, isLoading };
  },
};
