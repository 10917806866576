import { useFieldArray, useForm } from 'react-hook-form';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import type { WorkCarouselSeqUpdateParameter } from '@front/src/features/work/features/work/features/carousel/features/move-seq/types/parameter';

interface Props {
  list: WorkCarouselView[] | undefined;
  onUpdateSeq: (params: WorkCarouselSeqUpdateParameter) => void;
}

const useWorkCarouselMoveSeqForm = ({ list = [], onUpdateSeq }: Props) => {
  const methods = useForm<TFieldValues>({
    defaultValues: {
      list: list
    },
  });
  const { control, handleSubmit } = methods;
  const { fields, swap } = useFieldArray({
    name: 'list',
    control,
  });
  const onSubmit = handleSubmit((data) => {
    onUpdateSeq({
      list: data.list.map((l, index) => ({
        ...l,
        seq: index + 1,
      })),
    });
  });
  return {
    fields,
    swap,
    onSubmit,
    methods,
  };
};

export default useWorkCarouselMoveSeqForm;

interface TFieldValues {
  list: WorkCarouselView[];
}
