import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { approvalBoxMutation } from '@front/src/features/approval-box/query/mutation';
import { useShallow } from 'zustand/react/shallow';
import { APPROVAL_DOCUMENT_STATUS, APPROVAL_TYPE } from '@front/type/domain';
import { approvalDocumentMutation } from '@front/src/features/drawer-approval-document/query/mutation';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { approvalBoxQuery } from '@front/src/features/approval-box/query/query';
import { useNavigate } from 'react-router-dom';
import useNoteStore from '@front/src/features/note/useState';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

export default function ApprovalBoxDetailForm() {
  const { setIsBoxList } = useApprovalBoxStore(
    useShallow((state) => ({
      setIsBoxList: state.setIsBoxList,
    }))
  );
  const { documentId, params } = useApprovalDocumentStore(
    useShallow((state) => ({
      documentId: state.documentId,
      params: state.params,
    }))
  );
  const { data: boxDetail } = approvalBoxQuery.useGetApprovalBoxDetail(documentId, params);
  const { mutate: cancelApprovalDocument } = approvalBoxMutation.useCancelApprovalDocument();
  const { confirm, alert } = useCustomDialog();
  const onReturnClick = () => {
    setIsBoxList(true);
  };
  const onCancel = () => {
    confirm({
      title: '결재 상신 취소 확인',
      lineBreakChildren: [
        { value: '결재 상신이 취소되면 되돌릴 수 없습니다.' },
        { value: '결재 요청을 취소 하시겠습니까?' },
      ],
      afterConfirm: () => {
        cancelApprovalDocument(documentId);
      },
    });
  };
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);

  const isSubmitter = boxDetail?.approvalSteps[0].manager.id === loginUser?.id;
  const isApprover = boxDetail?.approvalSteps
    .slice(1)
    .find((step) => step.manager.id === loginUser?.id);
  const isOnGoing = isApprover?.stepName === '진행';

  const approvalId = isApprover?.approvalId;
  const { mutate: decideApprovalDocument } = approvalDocumentMutation.useDecideApprovalDocument(
    approvalId ?? 0
  );
  const { data: linkData } = approvalBoxQuery.useGetApprovalDocumentLink(documentId);
  const navigate = useNavigate();

  const { setSectionId, setRowId, setSelectedNoteId } = useNoteStore(
    useShallow((state) => ({
      setSectionId: state.setSectionId,
      setRowId: state.setRowId,
      setSelectedNoteId: state.setSelectedNoteId,
    }))
  );

  const { setNoteId, setIsRightDrawerOpen, setMenuId, setIsThroughLink } = useGlobalNavBarState(
    useShallow((state) => ({
      setNoteId: state.setNoteId,
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
      setMenuId: state.setMenuId,
      setIsThroughLink: state.setIsThroughLink,
    }))
  );

  const onLinkClick = () => {
    if (!linkData?.forwardUrl) {
      alert({
        title: '링크 이동 오류',
        children: [{ value: '열람 권한이 없습니다.' }],
      });
      return;
    }
    const isWork = !linkData?.noteId && !linkData?.approvalDocumentId;
    sessionStorage.setItem('dataId', `${linkData?.dataId ?? ''}`);
    sessionStorage.setItem('menuId', `${linkData?.menuId ?? ''}`);
    const noteAuth = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    sessionStorage.setItem(
      'noteAuth',
      JSON.stringify({ ...noteAuth, noteId: linkData.noteMenuId })
    );
    navigate(linkData?.forwardUrl ?? '');
    setMenuId(linkData?.menuId);
    setSectionId(linkData?.sectionId ?? 0);
    setRowId(linkData?.rowId ?? 0);

    if (isWork) return;
    setNoteId(linkData?.noteId ?? 0);
    setIsThroughLink(true);
    setIsRightDrawerOpen(true);

    setSelectedNoteId(linkData?.noteId);
    setTimeout(() => {
      setSelectedNoteId();
    }, 5000);
  };

  const onDecide = (isApprove: boolean) => {
    if (isApprove) {
      confirm({
        title: '결재 승인 확인',
        lineBreakChildren: [
          { value: '결재가 승인되면 되돌릴 수 없습니다.' },
          { value: '결재를 승인하시겠습니까?' },
        ],
        afterConfirm: () => {
          decideApprovalDocument({
            result: APPROVAL_TYPE.APPROVED,
          });
        },
      });
    } else {
      confirm({
        title: '결재 반려 확인',
        lineBreakChildren: [
          { value: '결재가 반려되면 되돌릴 수 없습니다.' },
          { value: '결재를 반려하시겠습니까?' },
        ],
        afterConfirm: () => {
          decideApprovalDocument({
            result: APPROVAL_TYPE.REJECTED,
          });
        },
      });
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        gap="10px"
      >
        <ButtonBasicUI
          onClick={onReturnClick}
          sx={{ background: 'var(--design-v-10-light-main-main-secondary, #77A0E5)' }}
        >
          뒤로
        </ButtonBasicUI>
        <ButtonBasicUI
          onClick={onLinkClick}
          sx={{ background: 'var(--design-v-10-light-main-main-primary, #2F6ACA)' }}
        >
          해당 페이지 열기
        </ButtonBasicUI>
      </Box>
      {!isSubmitter && !isApprover && <Typography>{}</Typography>}
      {isSubmitter && boxDetail?.status === APPROVAL_DOCUMENT_STATUS.SUBMISSION && (
        <ButtonBasicUI
          onClick={onCancel}
          sx={{ marginRight: '5px' }}
        >
          결재 상신 취소
        </ButtonBasicUI>
      )}
      {isApprover && isOnGoing && (
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <ButtonBasicUI
            onClick={() => onDecide(true)}
            sx={{ background: 'var(--design-v-10-light-background-bg-05, #35455F)', width: '70px' }}
          >
            승인
          </ButtonBasicUI>
          <ButtonBasicUI
            onClick={() => onDecide(false)}
            sx={{
              background: 'var(--design-v-10-light-sub-2-sub-2-primary, #E43333)',
              width: '70px',
            }}
          >
            반려
          </ButtonBasicUI>
        </Box>
      )}
    </Box>
  );
}
