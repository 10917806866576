import { useCustomDialog } from '@front/src/features/dialog';
import useWorkReviewerUpdate from '@front/src/features/work/features/work/features/reviewer/repository/useUpdate';
import useWorkReviewerCreate from '@front/src/features/work/features/work/features/reviewer/repository/useCreate';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';
import useWorkReviewerSeqUp from '@front/src/features/work/features/work/features/reviewer/repository/useSeqUp';
import useWorkReviewerSeqDown from '@front/src/features/work/features/work/features/reviewer/repository/useSeqDown';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkReviewerTableRowMutation = ({
  sectionId,
  dataId,
  menuId,
  reset,
  onCloseEditModal,
  formContext,
}) => {
  const commonParams = { sectionId, dataId, menuId };

  const { toggleEditOpenId, setRecentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();
  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkReviewerUpdate(commonParams);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
      onCloseEditModal(reset);
    },
  });

  const { run: onCreate, setCallback: setCreateCallback } = useWorkReviewerCreate(commonParams);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDelete } = workReviewerMutation.useDelete(commonParams);
  const { run: onSeqUp, setCallback: setSeqUpCallback } = useWorkReviewerSeqUp(commonParams);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onSeqDown, setCallback: setSeqDownCallback } = useWorkReviewerSeqDown(commonParams);
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  return {
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
  };
};

export default useWorkReviewerTableRowMutation;
