import type { ReactNode } from 'react';
import React from 'react';
import { Box } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { useQueryErrorResetBoundary } from 'react-query';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props {
  children: ReactNode;
  to?: string;
}

const DefaultErrorBoundary = ({ children, to = '/' }: Props) => {
  const navigate = useNavigate();
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50%',
          }}
        >
          <TypographyUI>데이터를 가져오는데 실패했습니다. 다시 가져오시겠습니까?</TypographyUI>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <ButtonBasicUI
              shape="primary"
              size="medium"
              onClick={resetErrorBoundary}
            >
              확인
            </ButtonBasicUI>
            <ButtonBasicUI
              shape="secondary"
              size="medium"
              onClick={() => navigate(to)}
            >
              뒤로가기
            </ButtonBasicUI>
          </Box>
        </Box>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default DefaultErrorBoundary;
