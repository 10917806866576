import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as fabric from "fabric";
import ModalUI, {ModalBodyUI,} from '@front/src/components/components-with-design/layout/ModalUI';
import {CommonImageView} from "@front/src/features/work/features/work/components/ZoomImageButton";
import useCanvasUtils, {CanvasRuntimeProps} from "@front/src/features/image-draw/hooks/useCanvasUtil";
import {BRUSH_SIZE, COLOR_SWATCH, Toolbar} from "@front/src/features/image-draw/components/ImageDrawModalToolbar";
import useStateManager, {EXT_OBJECT_ATTR_DRAW_ID} from "@front/src/features/image-draw/hooks/useStateManager";
import '@front/src/features/image-draw/components/image-draw.css';
import ImageDrawHistoryAside from "@front/src/features/image-draw/components/ImageDrawHistoryAside";
import useObjectManager from "@front/src/features/image-draw/hooks/useObjectManager";
import {useCustomDialog} from "@front/src/features/dialog";
import useOverlayManager from "@front/src/features/image-draw/hooks/useOverlayManager";
import imageDrawMutation from "@front/src/features/image-draw/query/mutation";
import {ImageDrawHistoryView} from "@front/src/features/image-draw/types/view";
import {SnackbarSeverityType} from "@front/components/Snackbar/action";
import imageDrawHistoryQuery from "@front/src/features/image-draw/query/query";
import {useDispatch} from "react-redux";
import {imageDrawAction} from "@front/src/features/image-draw/reducer/reducer";

interface Props {
  title?: string,
  item: CommonImageView;
  open: boolean;
  onClose: () => void;
  editable?: boolean;
}

export default function ImageDrawModalFeature({
  open, item, onClose, editable: defaultEditable = false, title = `${item.fileItem.description? item.fileItem.description : '자료 이미지'}`
}: Readonly<Props>) {

  const {current: runtimeProps} = useRef<CanvasRuntimeProps>({
    zoom: {
      level: 1,
      min: 0.2,
      max: 5
    },
    touch: {
      lastDistance: 0
    },
    selectable:false,
    drag: {
      enabled: !defaultEditable,
      dragging: false,
      lastPosX: undefined,
      lastPosY: undefined,
    },
    brush: {
      enabled: defaultEditable,
      size: BRUSH_SIZE[0],
      color: COLOR_SWATCH[0]
    },
    history: {undo: [], redo: []},
    historyAside: false,
    isModified: false,
    actingDrawingId: null,
    usePinchToZoom: false
  });

  const { confirm, prompt } = useCustomDialog();
  const overlayManager = useOverlayManager();
  const {createBrush, createTextBox,} = useObjectManager();

  const {
    save: saveCanvasData,
    saveState,
    redo,
    undo,
  } = useStateManager();

  const {
    lockObject,
    unlockObject,
    easeOut,
    getTouchDistance,
    getTransformedPointer,
    setBackgroundImage,
    removeSelectedObjects,
    fitCanvasToContainer,
    setSelectableForAllObjects,
    removeAllObjects,
    setObjectOpacityByEditableState,
    openSnackbar,
    zoom
  } = useCanvasUtils();

  const imageFileId = item.fileItem.id;
  const menuId = item.menuId;
  const {data: drawingCount} = imageDrawHistoryQuery.useCountByFileId(imageFileId!, menuId!);
  const {mutate: onDrawingCreate} = imageDrawMutation.useCreate(menuId!);
  const {mutate: onDrawingUpdate} = imageDrawMutation.useUpdate(menuId!);

  const [canvasReady, setCanvasReady] = useState(false);
  const [editable, setEditable] = useState(defaultEditable);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
  const gifAnimationHandlerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatcher = useDispatch();
  const [checkedList, setCheckedList] = useState<{[keys: number]:boolean}>({});
  const [toolbarSelectable, setToolbarSelectable] = useState(runtimeProps.selectable);
  const [toolbarDrawable, setToolbarDrawable] = useState(runtimeProps.brush.enabled);
  const [toolbarBrushColor, setToolbarBrushColor] = useState(runtimeProps.brush.color);
  const [toolbarBrushSize, setToolbarBrushSize] = useState(runtimeProps.brush.size);
  const [toolbarUndoable, setToolbarUndoable] = useState(false);
  const [toolbarRedoable, setToolbarRedoable] = useState(false);
  const [isModified, setIsModified] = useState(runtimeProps.isModified);
  const [asideOpen, setAsideOpen] = useState(false);
  const [actingDrawing, setActingDrawing] = useState<number | null>(null);

  const syncRuntimePropsWithToolbar =()=>{
    setToolbarSelectable(runtimeProps.selectable);
    setToolbarDrawable(runtimeProps.brush.enabled);
    setToolbarBrushColor(runtimeProps.brush.color);
    setToolbarBrushSize(runtimeProps.brush.size);
    setToolbarUndoable(runtimeProps.actingDrawingId?
      runtimeProps.history.undo.length > 1 :
      runtimeProps.history.undo.length > 0);
    setToolbarRedoable(runtimeProps.history.redo.length > 0);
    setAsideOpen(runtimeProps.historyAside);
    setIsModified(runtimeProps.isModified);
  };

  const cancelActingDrawing = useCallback(() => {
    if (runtimeProps.isModified) {
      confirm({
        lineBreakChildren: [
          { value: '수정 사항을 저장하지 않고 마지막 저장 상태로 돌아갑니다.' },
        ],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          if(fabricCanvasRef.current) {
            const _canvas = fabricCanvasRef.current;
            if(actingDrawing){
              overlayManager.revert(_canvas, actingDrawing, runtimeProps);
              setActingDrawing(null);
            } else {
              removeAllObjects(_canvas);
            }
            _canvas.renderAll();
            runtimeProps.isModified = false;
            runtimeProps.history.undo.length = 0;
            runtimeProps.history.redo.length = 0;
            syncRuntimePropsWithToolbar();
          }
        },
      });
    } else {
      setActingDrawing(null);
    }

  }, [actingDrawing]);

  const handleActingDrawingObjects = useCallback((actingDrawingId: number | null) => {
    if (fabricCanvasRef.current) {
      const _canvas = fabricCanvasRef.current;
      runtimeProps.actingDrawingId = actingDrawingId;

      _canvas.forEachObject((obj: fabric.Object) => {
        if (actingDrawingId) {
          const selectable = obj.get(EXT_OBJECT_ATTR_DRAW_ID) === actingDrawingId;
          setObjectOpacityByEditableState(obj, selectable);
          if (selectable) {
            unlockObject(obj);
            obj.set({selectable: runtimeProps.selectable});
          } else {
            lockObject(obj);
          }
        } else {
          obj.set('opacity', 1);
          if (obj.get(EXT_OBJECT_ATTR_DRAW_ID)) {
            lockObject(obj);
          } else {
            unlockObject(obj);
            obj.set({selectable: runtimeProps.selectable});
          }
        }
      });
      _canvas.renderAll();
    }
  }, [fabricCanvasRef.current]);

  const saveUndoRedoState = useCallback(() => {
    if(fabricCanvasRef.current){
      saveState(fabricCanvasRef.current, runtimeProps, () => {
        runtimeProps.isModified = true;
        syncRuntimePropsWithToolbar();
      });
    }
  },[fabricCanvasRef.current]);

  const toolBarHandlers = useMemo(()=>({
    onReset:() => {
      if(fabricCanvasRef.current) {
        removeAllObjects(fabricCanvasRef.current, runtimeProps.actingDrawingId);
        saveUndoRedoState();
      }
    },
      onUndo:() => {
      if(fabricCanvasRef.current){
        undo(fabricCanvasRef.current, runtimeProps);
        syncRuntimePropsWithToolbar();
      }
    },
      onRedo:() => {
      if(fabricCanvasRef.current){
        redo(fabricCanvasRef.current, runtimeProps);
        syncRuntimePropsWithToolbar();
      }
    },
      onFullScreen:() => {
      if (document.fullscreenElement) {
        document.exitFullscreen().then(()=>{
        });
      } else {
        document.documentElement.requestFullscreen().catch(err => {
          console.error(`[image-drawing] Error attempting to enable full-screen mode: ${err.message}`);
        });
      }
    },
      onHistory:() => {
      runtimeProps.historyAside=!runtimeProps.historyAside;
      syncRuntimePropsWithToolbar();
    },
      onSelecting:() => {
      if(fabricCanvasRef.current){
        runtimeProps.brush.enabled = false;
        runtimeProps.selectable = !runtimeProps.selectable;
        runtimeProps.drag.enabled = !runtimeProps.selectable;

        fabricCanvasRef.current.selection = runtimeProps.selectable;
        if(!runtimeProps.selectable) {
          fabricCanvasRef.current.discardActiveObject();
        }
        setSelectableForAllObjects(fabricCanvasRef.current, runtimeProps.selectable, runtimeProps.actingDrawingId);
        syncRuntimePropsWithToolbar();
      }
    },
      onDrawing:() => {
      if(fabricCanvasRef.current){
        runtimeProps.brush.enabled = !runtimeProps.brush.enabled;
        runtimeProps.drag.enabled = !runtimeProps.brush.enabled;
        runtimeProps.selectable = false;
        fabricCanvasRef.current.selection = false;

        fabricCanvasRef.current.discardActiveObject();
        setSelectableForAllObjects(fabricCanvasRef.current, runtimeProps.selectable);
        syncRuntimePropsWithToolbar();
      }
    },
      onTexting:() => {
      if(fabricCanvasRef.current){
        runtimeProps.drag.enabled = false;
        runtimeProps.brush.enabled = false;
        runtimeProps.selectable = true;

        fabricCanvasRef.current.selection = true;
        const textObject = createTextBox(fabricCanvasRef.current, {text: '메모', color: runtimeProps.brush.color});

        if(runtimeProps.actingDrawingId){
          textObject.set(EXT_OBJECT_ATTR_DRAW_ID, runtimeProps.actingDrawingId)
        }

        setSelectableForAllObjects(fabricCanvasRef.current, runtimeProps.selectable);

        saveUndoRedoState();
        syncRuntimePropsWithToolbar();
      }
    },
    onColorChange: (color: string) => {
      runtimeProps.brush.color = color;
      syncRuntimePropsWithToolbar();

      if(fabricCanvasRef.current){
        const _canvas = fabricCanvasRef.current;
        _canvas.freeDrawingBrush = createBrush(_canvas, runtimeProps);

        const _obj = _canvas.getActiveObject();
        if(_obj){
          const _type = _obj.get('type');
          if (_type == 'textbox') {
            _obj.set('fill', color);
            _canvas.renderAll();
            saveUndoRedoState();
          } else if (_type == 'path') {
            _obj.set('stroke', color);
            _canvas.renderAll();
            saveUndoRedoState();
          }
        }
      }
    },
    onBrushSizeChange: (size: number) => {
      runtimeProps.brush.size = size;
      syncRuntimePropsWithToolbar();

      if(fabricCanvasRef.current) {
        const _canvas = fabricCanvasRef.current;
        _canvas.freeDrawingBrush = createBrush(_canvas, runtimeProps);
      }
    },
    onSave: () => {

      if(fabricCanvasRef.current) {
        const _canvas = fabricCanvasRef.current;
        const  defaultPromptText = runtimeProps.actingDrawingId?
          overlayManager.get(runtimeProps.actingDrawingId).comment : '';

        saveCanvasData(
          fabricCanvasRef.current,
          runtimeProps.actingDrawingId,
          (serializedObjects) => {
            prompt({
              lineBreakChildren: [],
              defaultValue: defaultPromptText,
              title: '첨삭 메모를 입력하세요',
              promptText: '확인',
              closeText: '취소',
              multiline: true,
              maxLength: 500,
              rows: 10,
              afterPrompt: (memo: string) => {
                if(runtimeProps.actingDrawingId) {
                  // 기존 첨삭 저장
                  onDrawingUpdate({
                    drawingId: runtimeProps.actingDrawingId,
                    fileId: imageFileId!,
                    contents: serializedObjects,
                    comment: memo,
                  }, {
                    onSuccess: () => {
                      const newItem = {...overlayManager.get(runtimeProps.actingDrawingId!)};
                      newItem.contents = serializedObjects;
                      newItem.comment = memo;
                      overlayManager.replace(_canvas, newItem);

                      setActingDrawing(runtimeProps.actingDrawingId = null);
                      syncRuntimePropsWithToolbar();

                      openSnackbar('기존 첨삭에 저장하였습니다', SnackbarSeverityType.success);
                    }
                  });

                } else {
                  // 신규 첨삭 저장
                  onDrawingCreate({
                    contents: serializedObjects,
                    fileId: imageFileId!,
                    comment: memo,
                  }, {
                    onSuccess: (data: ImageDrawHistoryView)=>{
                      removeAllObjects(_canvas);
                      overlayManager.load(_canvas, {
                        fileId: data.fileId,
                        imageDrawId: data.id,
                        contents: data.contents,
                        comment: data.comment
                      }, runtimeProps);
                      setActingDrawing(data.id);
                      setCheckedList((prevCheckedList) => ({
                        ...prevCheckedList,
                        [data.id]: true,
                      }));
                      saveState(_canvas, runtimeProps, ()=>{
                        setActingDrawing(runtimeProps.actingDrawingId);
                        console.debug('initial drawing state pushed');
                      });
                      openSnackbar('신규 첨삭으로 저장하였습니다', SnackbarSeverityType.success);
                    }
                  });
                }
                runtimeProps.isModified = false;
                runtimeProps.history.redo.length = 0;
                runtimeProps.history.undo.length = 0;
                syncRuntimePropsWithToolbar();
              },
            });
          }
        );
      }
    },
    onDownload: () => {
      document.location = `/api/file-item/${item.fileItem.id}`;
    },
    onZoomIn: () => {
      fabricCanvasRef.current && zoom(0.1, fabricCanvasRef.current, runtimeProps);
    },
    onZoomOut: () => {
      fabricCanvasRef.current && zoom(-0.1, fabricCanvasRef.current, runtimeProps);
    }
  }),[fabricCanvasRef.current]);

  const addScrollToZoomHandlers = useCallback(()=>{
    if(!fabricCanvasRef.current) return;

    const _canvas = fabricCanvasRef.current;
    _canvas.on('mouse:wheel', function(opt) {
      const delta = opt.e.deltaY;
      // 스크롤 방향에 따라 줌 레벨 변경 (속도 조절)
      const zoomChange = delta > 0 ? -0.05 : 0.05;
      // 이징 함수 적용 (여기선 easeOut을 사용, delta에 비례)
      runtimeProps.zoom.level += easeOut(Math.abs(zoomChange)) * (zoomChange > 0 ? 1 : -1);
      // 줌 범위 제한
      runtimeProps.zoom.level = Math.max(
        runtimeProps.zoom.min,
        Math.min(runtimeProps.zoom.max,
          runtimeProps.zoom.level
        ));
      // 캔버스 중심을 기준으로 줌 적용
      if(_canvas){
        const zoomPoint = _canvas.getViewportPoint(opt.e);
        _canvas.zoomToPoint(zoomPoint, runtimeProps.zoom.level);
      }
      // 스크롤 기본 동작 방지
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  },[fabricCanvasRef.current]);

  const addDragEventHandlers = useCallback(() => {
    if(!fabricCanvasRef.current) return;

    const _canvas = fabricCanvasRef.current;
    _canvas.freeDrawingBrush = createBrush(_canvas, runtimeProps);

    _canvas.on('mouse:down', (opt) => {
      const pointer = _canvas.getViewportPoint(opt.e);

      if(runtimeProps.drag.enabled){
        const pointer = _canvas.getViewportPoint(opt.e);
        runtimeProps.drag.lastPosX = pointer.x
        runtimeProps.drag.lastPosY = pointer.y
        runtimeProps.drag.dragging = true;
      } else if (runtimeProps.brush.enabled) {
        setSelectableForAllObjects(_canvas, false, runtimeProps.actingDrawingId);
        _canvas.isDrawingMode = true;
        _canvas.freeDrawingBrush?.onMouseDown(getTransformedPointer(pointer, _canvas), opt);
      } else {
        // ...
      }
    });

    _canvas.on('mouse:move', (opt) => {
      const pointer = _canvas.getViewportPoint(opt.e);

      if (runtimeProps.drag.enabled
        && runtimeProps.drag.lastPosX !== undefined
        && runtimeProps.drag.lastPosY !== undefined) {

        if(runtimeProps.drag.dragging) {
          const deltaX = pointer.x - runtimeProps.drag.lastPosX
          const deltaY = pointer.y - runtimeProps.drag.lastPosY

          const vpt = _canvas.viewportTransform;
          vpt[4] += deltaX;
          vpt[5] += deltaY;
          _canvas.setViewportTransform(vpt);

          runtimeProps.drag.lastPosX = pointer.x;
          runtimeProps.drag.lastPosY = pointer.y;
        }

      } else if (runtimeProps.brush.enabled) {
        if(_canvas.isDrawingMode){
          _canvas.freeDrawingBrush?.onMouseMove(getTransformedPointer(pointer, _canvas), opt);
        }
      }
    })

    _canvas.on('path:created', function(event) {
      const path = event.path;
      path.selectable = false;

      if(runtimeProps.actingDrawingId){
        path.set(EXT_OBJECT_ATTR_DRAW_ID, runtimeProps.actingDrawingId);
      }
      saveUndoRedoState();
    });

    _canvas.on('mouse:up', (opt) => {
      if (runtimeProps.drag.enabled) {
        runtimeProps.drag.dragging = false;
      } else if (runtimeProps.brush.enabled) {
        _canvas.freeDrawingBrush?.onMouseUp(opt);
        _canvas.isDrawingMode = false;
      }
    })
  }, [fabricCanvasRef.current])

  // acting drawing handler
  useEffect(() => {
    handleActingDrawingObjects(actingDrawing);
  }, [actingDrawing]);

  useEffect(() => {
    if(editable){
      runtimeProps.brush.enabled = true;
      runtimeProps.selectable = false;
      runtimeProps.drag.enabled = false;
    }else {
      runtimeProps.brush.enabled = false;
      runtimeProps.selectable = false;
      runtimeProps.drag.enabled = true;
    }
    setToolbarDrawable(runtimeProps.brush.enabled);
    setToolbarSelectable(runtimeProps.selectable);
  }, [editable]);

  // initialize canvas event listeners
  useEffect(()=>{
    if(!canvasReady || !fabricCanvasRef.current) {
      return;
    }

    const EVENT_HANDLERS= {
      TOUCH: {
        START: (e: TouchEvent) => {
          if (e.touches.length === 2) {
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            runtimeProps.touch.lastDistance = getTouchDistance(touch1, touch2);
          }
        },
        MOVE: (e: TouchEvent) => {
          if(!runtimeProps.usePinchToZoom){
            return;
          }
          const minZoom = runtimeProps.zoom.min;
          const maxZoom = runtimeProps.zoom.max;

          if (e.touches.length === 2) {
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const currentDistance = getTouchDistance(touch1, touch2);

            if (runtimeProps.touch.lastDistance) {
              const zoomChange = (currentDistance - runtimeProps.touch.lastDistance) / 200;
              runtimeProps.zoom.level += easeOut(Math.abs(zoomChange)) * (zoomChange > 0 ? 1 : -1);
              runtimeProps.zoom.level = Math.max(minZoom, Math.min(maxZoom, runtimeProps.zoom.level));

              if(fabricCanvasRef.current){
                const zoomPoint = fabricCanvasRef.current.getViewportPoint(e);
                fabricCanvasRef.current.zoomToPoint(zoomPoint, runtimeProps.zoom.level);
              }
            }
            runtimeProps.touch.lastDistance = currentDistance;
          }
        },
        END: (e: TouchEvent) => {
          if (e.touches.length < 2) {
            runtimeProps.touch.lastDistance = 0;
          }
        }
      },
      RESIZE: (/*e: UIEvent*/) => {
        if(runtimeProps.resizeDebounceHandlerId) {
          clearTimeout(runtimeProps.resizeDebounceHandlerId);
        }
        runtimeProps.resizeDebounceHandlerId = window.setTimeout(()=>{
          if (fabricCanvasRef.current) {
            canvasRef.current && fitCanvasToContainer(fabricCanvasRef.current, canvasRef.current);
            runtimeProps.resizeDebounceHandlerId = undefined;
          }
        }, 500);
      },
      KEYDOWN: (e: KeyboardEvent) => {
        if (e.key === 'Delete' || e.key === 'Backspace') { // Delete 또는 Backspace 키 확인
          if (fabricCanvasRef.current) {
            if(removeSelectedObjects(fabricCanvasRef.current)){
              saveUndoRedoState();
            }
          }
        }
      }
    };

    const canvas = fabricCanvasRef.current;
    addDragEventHandlers();
    addScrollToZoomHandlers();
    canvas.upperCanvasEl.addEventListener('touchstart', EVENT_HANDLERS.TOUCH.START, {passive: true});
    canvas.upperCanvasEl.addEventListener('touchmove', EVENT_HANDLERS.TOUCH.MOVE, {passive: true});
    canvas.upperCanvasEl.addEventListener('touchend', EVENT_HANDLERS.TOUCH.END, {passive: true});
    window.addEventListener('resize', EVENT_HANDLERS.RESIZE, {passive: true});
    window.addEventListener('keydown', EVENT_HANDLERS.KEYDOWN, {passive: true});

    canvas.on('selection:created', () => {
      const _obj = canvas.getActiveObject();
      const _type = _obj?.get('type');

      if (_type == 'textbox') {
        runtimeProps.brush.color = _obj?.get('fill');
        syncRuntimePropsWithToolbar();
      } else if (_type == 'path') {
        runtimeProps.brush.color = _obj?.get('stroke');
        syncRuntimePropsWithToolbar();
      }
    });

    canvas.on('object:modified', () => {
      saveUndoRedoState();
    });

    console.info('[image-drawing] start event listeners');

    return () => {
      if (fabricCanvasRef.current) {
        const canvas = fabricCanvasRef.current;
        canvas.off();
        canvas.upperCanvasEl.removeEventListener('touchstart', EVENT_HANDLERS.TOUCH.START);
        canvas.upperCanvasEl.removeEventListener('touchmove', EVENT_HANDLERS.TOUCH.MOVE);
        canvas.upperCanvasEl.removeEventListener('touchend', EVENT_HANDLERS.TOUCH.END);
      }
      window.removeEventListener('resize', EVENT_HANDLERS.RESIZE);
      window.removeEventListener('keydown', EVENT_HANDLERS.KEYDOWN);
      console.info('[image-drawing] dispose event listeners.');
    };
  },[canvasReady]);

  // initialize fabric.js runtime
  useEffect(() => {
    if (!open) return;

    const initialize = () => {
      dispatcher(imageDrawAction.setImageDrawIsLoading(true));
      setTimeout(()=>{
        const canvas = fabricCanvasRef.current = new fabric.Canvas(canvasRef.current!, {
          selection: false,
        });
        canvasRef.current && fitCanvasToContainer(canvas, canvasRef.current);
        setBackgroundImage(canvas, item, (animationIntervalHandlerId?: NodeJS.Timeout) => {
          if(animationIntervalHandlerId && gifAnimationHandlerRef){
            gifAnimationHandlerRef.current = animationIntervalHandlerId;
            console.info('[image-drawing] start animated background image handler');
          }
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
          canvas.viewportTransform = [1, 0, 0, 1, centerX, centerY];
          canvas.renderAll();
          setCanvasReady(true);
          dispatcher(imageDrawAction.setImageDrawIsLoading(false));
          console.info('[image-drawing] canvas initialized');
        });
      },300);
    };

    if(canvasRef.current){
      console.info('[image-drawing] canvas ref is valid. set canvas is ready.');
      initialize();
      return;
    }

    const observer = new MutationObserver(() => {
      if (open && canvasRef.current) {
        initialize();
      }
      observer.disconnect(); // 초기화 후 관찰 중지
    });

    // 모달이 열릴 때 DOM 변화 관찰 시작
    if (open) {
      observer.observe(document.body, { childList: true });
      console.info('[image-drawing] start canvas creation observer');
    }

    return () => {
      observer && observer.disconnect();
      console.info('[image-drawing] stop canvas creation observer');

      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose().finally(() => {
          fabricCanvasRef.current = null;
          if(gifAnimationHandlerRef.current){
            clearInterval(gifAnimationHandlerRef.current);
            gifAnimationHandlerRef.current = null;
            console.info('[image-drawing] stop animated background image handler');
          }
          setCanvasReady(false);
        });
      }
    }
  }, [open]);

  const getTitle = useCallback(()=>{
    if(actingDrawing){
      const _drawingData = overlayManager.get(actingDrawing);
      const _comment = _drawingData.comment?
        _drawingData.comment.length > 20? `
        ${_drawingData.comment.substring(0, 20)}...`
          : _drawingData.comment
        : '메모없음';
      return `${title} - 기존 첨삭 수정(${_comment})`;
    }else{
      return editable ? `${title} - 신규 첨삭 작성` : `${title}`;
    }
  },[title, actingDrawing, editable]);

  return (
    <ModalUI
      title={getTitle()}
      shape="modal-full-screen"
      open={open}
      onClose={onClose}
      className={`image-canvas-modal ${actingDrawing? `edit`: editable? 'new':'view'}`}
    >
      <>
        <div className="image-canvas-container">
          <Toolbar
            selectable={toolbarSelectable}
            drawable={toolbarDrawable}
            brushColor={toolbarBrushColor}
            brushSize={toolbarBrushSize}
            editable={editable}
            setEditable={setEditable}
            undoable={toolbarUndoable}
            redoable={toolbarRedoable}
            actingDrawing={actingDrawing}
            cancelActingDrawing={cancelActingDrawing}
            isModified={isModified}
            drawingCount={drawingCount}
            {...toolBarHandlers}
          />
          <div className="image-canvas-aside-holder">
            <canvas ref={canvasRef} style={{height: 'calc(100% - 50px)'}}></canvas>
            <ImageDrawHistoryAside
                menuId={menuId!}
                imageFileId={imageFileId!}
                canvas={fabricCanvasRef?.current}
                open={asideOpen}
                load={overlayManager.load}
                unload={overlayManager.unload}
                contains={overlayManager.contains}
                actingDrawing={actingDrawing}
                setActingDrawing={setActingDrawing}
                cancelActingDrawing={cancelActingDrawing}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                runtimeProps={runtimeProps}
                editable={editable}
            />
          </div>
        </div>
        <ModalBodyUI sx={{padding: 0, height: '100%'}}>
        </ModalBodyUI>
      </>
    </ModalUI>
  );
}





