export const enum AffiliatedCompanyCategory {
  DEFAULT = '',
  // 건축
  BUILDING = '건축',

  // 구조
  STRUCTURAL_DESIGN = '구조',

  // 시행
  IMPLEMENTING = '시행',

  // 시공
  CONSTRUCTING = '시공',
}

export const purposeCategoryKOR = {
  KICK_OFF: '킥오프',
  SCHEDULE: '일정 관련',
  TECH: '기술 관련',
  SALES: '영업',
  ETC: '기타',
};

export const enum PersonTabType {
  OUTLINE = 'OUTLINE',
  CHARACTER = 'CHARACTER',
  RELATION = 'RELATION',
  GIFT_HISTORY = 'GIFT_HISTORY',
  MEETING_HISTORY = 'MEETING_HISTORY',
  SALES_ACTIVITY = 'SALES_ACTIVITY',
}

export const enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export const enum PurposeCategory {
  // 킥오프
  KICK_OFF = 'KICK_OFF',

  // 일정 관련
  SCHEDULE = 'SCHEDULE',

  // 기술 관련
  TECH = 'TECH',

  // 영업
  SALES = 'SALES',

  // 기타
  ETC = 'ETC',
}

export const enum TransportationType {
  // 차량
  CAR = 'CAR',

  // 대중교통
  PUBLIC_TRANSPORTATION = 'PUBLIC_TRANSPORTATION',

  // 기타
  ETC = 'ETC',
}

export const enum GiftTargetType {
  // 회사
  COMPANY = 'COMPANY',

  // 개인
  PERSON = 'PERSON',
}

export const enum PreferenceLevel {
  // 최상
  TOP = 'TOP',

  // 상
  HIGH = 'HIGH',

  // 중
  MIDDLE = 'MIDDLE',

  // 하
  LOW = 'LOW',

  // 최하
  BOTTOM = 'BOTTOM',
}

export const enum FamilyRelation {
  SPOUSE = 'SPOUSE',
  SON = 'SON',
  DAUGHTER = 'DAUGHTER',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  GRANDFATHER = 'GRANDFATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  OTHER = 'OTHER',
}

export const enum InputType {
  FAX = 'FAX',
  BUSINESS_NUMBER = 'BUSINESS_NUMBER',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  AMOUNT = 'AMOUNT',
  COMPANY_MAIN_CONTACT = 'COMPANY_MAIN_CONTACT',
  COMPANY_CONTACT = 'COMPANY_CONTACT',
  DIRECT_CONTACT = 'DIRECT_CONTACT',
  URL = 'URL',
  POSITIVE = 'POSITIVE',
  EST_AMOUNT = 'EST_AMOUNT',
  RATIO = 'RATIO',
}

export const enum AffiliatedCompanyStatus {
  NORMAL = 'NORMAL',
  CLOSED = 'CLOSED',
}

export const enum PersonStatus {
  EMPLOYED = 'EMPLOYED',
  RESIGNATION = 'RESIGNATION',
}

export const enum HomeTown {
  SEOUL = 'SEOUL',
  BUSAN = 'BUSAN',
  DAEGU = 'DAEGU',
  GWANGJU = 'GWANGJU',
  INCHEON = 'INCHEON',
  DAEJEON = 'DAEJEON',
  ULSAN = 'ULSAN',
  GYEONGGI_DO = 'GYEONGGI_DO',
  GANGWON_DO = 'GANGWON_DO',
  CHUNGCHEONGBUK_DO = 'CHUNGCHEONGBUK_DO',
  CHUNGCHEONGNAM_DO = 'CHUNGCHEONGNAM_DO',
  JEOLLABUK_DO = 'JEOLLABUK_DO',
  JEOLLANAM_DO = 'JEOLLANAM_DO',
  GYEONGSANGBUK_DO = 'GYEONGSANGBUK_DO',
  GYEONGSANGNAM_DO = 'GYEONGSANGNAM_DO',
  JEJU_DO = 'JEJU_DO',
}
