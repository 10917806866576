import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import workIndicatorQuery from '@front/src/features/work/features/work/features/creator/features/indicator/query/query';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import WorkIndicatorTableRow from '@front/src/features/work/features/work/features/creator/features/indicator/components/TableRow';

export default function WorkIndicatorTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { sectionId, dataId, menuId } = props;
  const list = workIndicatorQuery.useGetOne({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UIBuilderTableBody<WorkCreatorView>
      {...props}
      list={list}
      RowComponent={WorkIndicatorTableRow}
    />
  );
}
