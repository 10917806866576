import React from 'react';
import UIBuilderTableBody, { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import AccountStatusWidgetSummaryTableRow
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/summary-table/row';

type Props = Readonly<UIBuilderTableBodyProps>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UIBuilderTableBody>;

const AccountStatusWidgetSummaryTableBody: ElementType = (props) => {

  return (
    <UIBuilderTableBody
      {...props}
      RowComponent={AccountStatusWidgetSummaryTableRow}
    />
  );
};

export default AccountStatusWidgetSummaryTableBody;

