import React from 'react';
import type { ModalPositionType } from '@front/layouts/ModalLayout';
import ModalLayout from '@front/layouts/ModalLayout';
import { Box } from '@mui/material';
import type {
  CompanySearchHelper,
  PersonSearchHelper,
} from '@front/src/features/affiliated-company-selector/widgets/useLogic';
import AffiliatedCompanySelectorModalPersonList from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector-modal-person-list';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import AffiliatedCompanySelectorModalCompanyList from '@front/src/features/affiliated-company-selector/components/affiliated-company-selector-modal-company-list';

export { Modal as AffiliatedCompanySelectorModal };

type ModalProps = {
  onClose: () => void;
  companySearchHelper: CompanySearchHelper;
  personSearchHelper: PersonSearchHelper;
  usePersonSelector: boolean;
  onCompanySelect: (company: AffiliatedCompanyShortView) => void;
  onPersonSelect?: (person: AffiliatedCompanyPersonShortView) => void;
  title?: string;
  exclude?: number[];
  readonly position?: ModalPositionType;
};

function Modal(props: ModalProps) {
  const {
    onClose,
    title,
    usePersonSelector,
    personSearchHelper,
    companySearchHelper,
    onCompanySelect,
    onPersonSelect,
    position,
  } = props;

  const companyBoxWidth = usePersonSelector ? 'calc(50% - 8px)' : '100%';

  return (
    <ModalLayout
      width={`${usePersonSelector ? '44dvw' : '560px'}`}
      height="80dvh"
      position={position}
      open={true}
      onClose={onClose}
      title={title ?? '관계사 선택'}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'calc(80dvh - 100px)' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: companyBoxWidth,
            height: 'calc(100% - 30px)',
            marginRight: '8px',
          }}
        >
          <AffiliatedCompanySelectorModalCompanyList
            helper={companySearchHelper}
            onSelect={onCompanySelect}
            usePersonSelector={usePersonSelector}
            exclude={props.exclude}
          />
        </Box>
        {usePersonSelector && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(50% - 8px)',
              height: 'calc(100% - 30px)',
              marginLeft: '8px',
            }}
          >
            <AffiliatedCompanySelectorModalPersonList
              helper={personSearchHelper}
              targetCompanyId={companySearchHelper.targetCompany?.id}
              onSelect={onPersonSelect}
            />
          </Box>
        )}
      </Box>
    </ModalLayout>
  );
}
