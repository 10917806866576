import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import React from 'react';
import AccountingSettingsTaxAccountingAccountFeature from '@front/src/features/accounting/features/settings/features/tax-accounting-account';
import AccountingSettingsManagementAccountingAccountFeature from '@front/src/features/accounting/features/settings/features/management-accounting-account';
import AccountingSettingsBankAccountFeature from '@front/src/features/accounting/features/settings/features/bank-account';
import AccountingSettingsDailyReportStatusFeature from '@front/src/features/accounting/features/settings/features/daily-report-status';
import AccountingUploadHistoryManagementUploadFeature from '@front/src/features/accounting/features/upload/features/history-management-upload';
import AccountingSettingsManagementAccountingReadFeature from '@front/src/features/accounting/features/settings/features/management-accounting-read';
import AccountingUploadSalesInfoFeature from '@front/src/features/accounting/features/upload/features/sales-info';

export { sectionComponent as accountingSectionComponent };

const sectionComponent: SectionComponent = {
  82: ({ ...rest }) => <AccountingSettingsTaxAccountingAccountFeature {...rest} />,
  83: ({ ...rest }) => <AccountingSettingsManagementAccountingAccountFeature {...rest} />,
  84: ({ ...rest }) => <AccountingSettingsBankAccountFeature {...rest} />,
  85: ({ ...rest }) => <AccountingSettingsDailyReportStatusFeature {...rest} />,
  86: ({ ...rest }) => <AccountingUploadHistoryManagementUploadFeature {...rest} />,
  87: ({ ...rest }) => <AccountingSettingsManagementAccountingReadFeature {...rest} />,
  89: ({ ...rest }) => <AccountingUploadSalesInfoFeature {...rest} />,
};
