import React from 'react';
import { AccountingSettingsManagementAccountingAccountTableBankBookNameAndCategoryBigList } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/bank-book-name-and-category-big-list';
import { AccountingSettingsManagementAccountingAccountTableCategoryMiddleAndSmallList } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/category-middle-and-small-list';
import { accountingSettingsManagementAccountingTableLayout } from '@front/src/features/accounting/features/settings/components/layouts/table';

export { CategoryList as AccountingSettingsManagementAccountingAccountTableCategoryList };

const CategoryList = () => {
  const { TableBody } = accountingSettingsManagementAccountingTableLayout;
  return (
    <TableBody
      sx={{
        maxHeight: '80dvh',
        overflowY: 'auto',
      }}
    >
      <AccountingSettingsManagementAccountingAccountTableBankBookNameAndCategoryBigList />
      <AccountingSettingsManagementAccountingAccountTableCategoryMiddleAndSmallList />
    </TableBody>
  );
};
