import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyOutlinePrimaryBusinessContext } from '@front/src/features/affiliated-company/features/outline/features/primary-business/widgets/context';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ButtonGroup as AffiliatedCompanyOutlinePrimaryBusinessButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreatePrimaryBusiness, onDeletePrimaryBusiness, setIdList },
    idList,
  } = useContext(AffiliatedCompanyOutlinePrimaryBusinessContext);
  const handleDeleteClick = useCallback(() => {
    onDeletePrimaryBusiness({ idList });
    setIdList([]);
  }, [onDeletePrimaryBusiness, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onCreatePrimaryBusiness}
        disabled={readOnly}
      >
        사업추가
      </Button>
    </>
  );
};
