import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { useFormContext } from 'react-hook-form';
import { ColorPalette } from '@front/assets/theme';
import UserIcon from '@front/layouts/UserIcon';

export { Content as AffiliatedPersonMemoContent };

const Content = () => {
  const { getValues } = useFormContext();
  const description = getValues('description');
  const attendanceList = getValues('attendanceList');
  const renderAttendanceList = useCallback(() => {
    if (!attendanceList || attendanceList.length === 0) return <></>;
    return attendanceList.map((item) => (
      <Box
        key={item}
        sx={{
          marginRight: '4px',
        }}
      >
        <UserIcon user={item} />
      </Box>
    ));
  }, [attendanceList]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          paddingBlock: '12px',
          fontSize: '13px',
          color: ColorPalette._252627,
          wordBreak: 'break-all',
          whiteSpace: 'break-spaces',
        }}
      >
        <TextBox variant="body9">{description}</TextBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {renderAttendanceList()}
      </Box>
    </>
  );
};
