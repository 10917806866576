import React, { useCallback, useState } from 'react';
import { Table } from '@front/layouts/Table';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import Button from '@front/layouts/Button';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useFormContext } from 'react-hook-form';
import { spliceAndPushParticipantListIfExist } from '@front/src/features/affiliated-company/utils';
import { ProjectSelectorModalWidget as ProjectSelectorModalFeature } from '@front/src/features/project-selector-modal/widgets/widget';
import { AffiliatedCompanyMeetingHistoryModalFormProjectBody } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-project-body';
import { useAffiliatedCompanyMeetingHistoryState } from '@front/src/features/affiliated-company/features/meeting-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { FormProject as AffiliatedCompanyMeetingHistoryModalFormProject };
const FormProject = () => {
  const { Layout, Header, Body } = AddDeleteTable;
  const { setValue, getValues } = useFormContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [idList, setIdList] = useState<number[]>([]);
  const projectList = getValues('projectList');
  const handleSelect = useCallback(
    (project) => {
      setValue('projectList', spliceAndPushParticipantListIfExist(projectList, project));
    },
    [setValue, projectList]
  );
  const handleDelete = useCallback(() => {
    setValue(
      'projectList',
      [...projectList].filter((project) => !idList.includes(project.id))
    );
    setIdList([]);
  }, [projectList, idList, setValue]);
  const { readOnly } = useAffiliatedCompanyMeetingHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Layout>
      <Header>
        <ProjectSelectorModalFeature
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSelect={handleSelect}
        />
        <Button
          onClick={handleDelete}
          disabled={readOnly}
        >
          선택삭제
        </Button>
        <Button
          onClick={() => setIsOpen(true)}
          disabled={readOnly}
        >
          추가
        </Button>
      </Header>
      <Body>
        <TableContainer>
          <Table>
            <TableHeader />
            <TableBody>
              <AffiliatedCompanyMeetingHistoryModalFormProjectBody
                idList={idList}
                setIdList={setIdList}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Body>
    </Layout>
  );
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <Th
        center
        width="12%"
        colSpan={2}
      >
        관련프로젝트
      </Th>
      <Th
        center
        width="12%"
      >
        PJT No.
      </Th>
      <Th
        center
        width="60%"
      >
        프로젝트명
      </Th>
    </TableRow>
  </TableHead>
);
