import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { useProjectSalesInfoEstimationCompetitorUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/useState';
import { projectSalesInfoEstimationCompetitorUpdateModalRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/repository';

export { useLogic as useProjectSalesInfoEstimationCompetitorUpdateModalLogic };

const useLogic = (menuId) => {
  const { isOpen, setIsOpen, id, setId } = useProjectSalesInfoEstimationCompetitorUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { data: detail, isLoading } =
    projectSalesInfoEstimationCompetitorUpdateModalRepository.useDetailGet(id, menuId);
  const { run: onUpdate, setCallback } =
    projectSalesInfoEstimationCompetitorUpdateModalRepository.useUpdate(menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setIsOpen(false);
  }, [setId, setIsOpen]);
  const d = { detail };
  const h = { onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isLoading,
    isOpen,
  };
};
