export const enum UISettingsType {
  HEADER_SETTINGS = 'HEADER_SETTINGS',
  MODAL_SETTINGS = 'MODAL_SETTINGS',
}

const convertObjectUISettingsType = {
  [UISettingsType.HEADER_SETTINGS]: '헤더 설정',
  [UISettingsType.MODAL_SETTINGS]: '모달 설정',
};

export const uISettingsTypeList = [
  {
    label: convertObjectUISettingsType[UISettingsType.HEADER_SETTINGS],
    value: UISettingsType.HEADER_SETTINGS,
  },
  {
    label: convertObjectUISettingsType[UISettingsType.MODAL_SETTINGS],
    value: UISettingsType.MODAL_SETTINGS,
  },
];
