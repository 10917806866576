import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteSweep } from '@mui/icons-material';
import UIBuilderTableHeader, {
  type UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';
import useWorkReferenceTableHeadMutationLogic from '@front/src/features/work/features/work/features/reference/hooks/useTableHeadMutation';

export default function WorkReferenceTableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;

  const { onAddTop, onAddBottom, onRemoveAll } = useWorkReferenceTableHeadMutationLogic({
    dataId,
    menuId,
    sectionId,
  });

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
  };

  const controlPanelHeaderItemList = [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop, seq: 5 },
    {
      text: '최하단에 추가',
      icon: AddCircleOutlineIcon,
      action: actions.onAddBottom,
      split: true,
      seq: 6,
    },
    {
      text: '전체 삭제',
      icon: DeleteSweep,
      action: actions.onRemoveAll,
      split: true,
      iconColor: '#e43333',
      seq: 100,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
    />
  );
}
