import React, { Suspense } from 'react';
import { MobileNotificationListProvider } from '@front/src/features/mobile-notification/widgets/list/provider/provider';
import { MobileNotificationList } from '@front/src/features/mobile-notification/widgets/list/components/mobile-notification-list';
import { LoadingSpinner } from '@front/src/components/loading-spinner';

export { Widget as MobileNotificationListWidget };

const Widget = () => (
  <Suspense fallback={<LoadingSpinner padding="20px" />}>
    <MobileNotificationListProvider>
      <MobileNotificationList />
    </MobileNotificationListProvider>
  </Suspense>
);
