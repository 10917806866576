import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useAccountingSettingsTexAccountingAccountGetDateLogic } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/provider/useLogic';
import { AccountingSettingsTexAccountingAccountGetDateContext } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/widgets/get-date/provider/context';

export { Provider as AccountingSettingsTexAccountingAccountGetDateProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { createdAt } = useAccountingSettingsTexAccountingAccountGetDateLogic(menuId);
  const value = useMemo(() => ({ createdAt }), [createdAt]);
  return (
    <AccountingSettingsTexAccountingAccountGetDateContext.Provider value={value}>
      {children}
    </AccountingSettingsTexAccountingAccountGetDateContext.Provider>
  );
};
