import { useIsMutating } from 'react-query';

const useWorkOutputBundleGetIsLoading = () => {
  const isCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'create'],
  });

  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'update'],
  });

  const isDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'delete'],
  });

  const isMoveLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'move'],
  });

  return isUpdateLoading || isCreateLoading || isDeleteLoading || isMoveLoading;
};

export default useWorkOutputBundleGetIsLoading;
