import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workBasicInfoMutation from '@front/src/features/work/features/work/features/basic-info/query/mutation';

const useWorkSearchWordCreate = (commonParams) => {
  const callback = getNoOpCallback();
  const { mutate } = workBasicInfoMutation.useSearchWordCreate(commonParams);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkSearchWordCreate;
