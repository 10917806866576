import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@front/layouts/Button';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import { FormProvider, useFormContext } from 'react-hook-form';
import UserIcon from '@front/layouts/UserIcon';
import { UserId } from '@front/user/domain';
import type { CommentUpdateParams } from '@front/src/features/note/types/parameter';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '@front/src/utils';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';
import { useShallow } from 'zustand/react/shallow';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import useNoteStore from '@front/src/features/note/useState';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  onDelete: (commentId: number) => void;
  onUpdate: (params: CommentUpdateParams) => void;
  isMention?: boolean;
  index: number;
  isNote?: boolean;
  noteIdx?: number;
  userList?: { id: number; display: string }[];
}

interface ControlPanelProps {
  handleDelete: () => void;
  handleEdit: () => void;
  isEditMode: boolean;
}

export default function DrawerCommentItem({
  onDelete,
  index,
  onUpdate,
  isMention,
  isNote,
  noteIdx,
  userList,
}: Props) {
  const { boxDetailState, setBoxDetailState } = useApprovalBoxStore(
    useShallow((state) => ({
      boxDetailState: state.boxDetailState,
      setBoxDetailState: state.setBoxDetailState,
    }))
  );
  // const [isEditMode, setIsEditMode] = useState(!!boxDetailState.commentList[index]?.isEditMode);
  const { confirm, alert } = useCustomDialog();
  const isEditMode = boxDetailState[index]?.isEditMode;

  const { getValues } = useFormContext();
  const nameStr = isNote ? `noteList.${noteIdx}.commentList.${index}` : `commentList.${index}`;
  const user = useSelector((root: RootState) => root.login.detail);
  const commentRef = useRef<HTMLDivElement>(null);

  const { selectedCommentId, selectedNoteId, cardState } = useNoteStore(
    useShallow((state) => ({
      selectedCommentId: state.selectedCommentId,
      selectedNoteId: state.selectedNoteId,
      cardState: state.cardState,
    }))
  );

  const item = getValues(nameStr);
  const isCommentBordered = item.id === selectedCommentId;
  const isFooterOpen = !cardState[selectedNoteId ?? 0]?.isFooterOpen;

  useEffect(() => {
    if (commentRef.current && isCommentBordered) {
      const boxEl = document.getElementById(`${selectedNoteId ?? ''}`)?.parentElement;
      if (!boxEl || isFooterOpen) return;
      if (!commentRef.current) return;
      setTimeout(() => {
        if (!commentRef.current) return;
        boxEl?.scrollTo({
          top: commentRef.current.getBoundingClientRect().top - boxEl.getBoundingClientRect().top,
          behavior: 'smooth',
        });
      }, 300);
    }
  }, [commentRef, isCommentBordered, selectedNoteId, isFooterOpen]);
  const handleEdit = () => {
    if (item.writer.id !== user?.id) {
      alert({
        title: '인증 오류',
        children: [
          {
            value: '작성자만 삭제 가능합니다.',
          },
        ],
      });
      return;
    }
    setBoxDetailState(index, { isEditMode: !isEditMode });
  };

  const methods = useFormContext();

  const onUpdateClick = methods.handleSubmit((data) => {
    onUpdate({
      id: item.id,
      contents: isNote
        ? data.noteList[noteIdx!].commentList[index].contents
        : data.commentList[index].contents,
    });
    setBoxDetailState(index, { isEditMode: !isEditMode });
  });

  const onCancelClick = () => {
    methods.resetField(nameStr + '.contents');
    setBoxDetailState(index, { isEditMode: !isEditMode });
  };

  const handleDelete = () => {
    if (item.writer.id !== user?.id) {
      alert({
        title: '인증 오류',
        children: [
          {
            value: '작성자만 삭제 가능합니다.',
          },
        ],
      });
      return;
    }
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [{ value: '댓글을 삭제하시겠습니까?' }],
      afterConfirm: () => onDelete(item.id),
    });
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          background: 'var(--design-v-10-light-background-bg-02, #EBEEF2)',
          padding: '10px 0',
          border: isCommentBordered ? `1px solid ${ColorPalette.main.main_primary}` : 'none',
        }}
        ref={commentRef}
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              marginBottom: '5px',
            }}
          >
            <CommentControlPanel
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              isEditMode={isEditMode}
            />
            <Box sx={{ ...SX_NOTECARD_HEADER_USERINFO_WRAP }}>
              <UserIcon
                user={UserId(item.writer.profile?.id)}
                sx={{ border: '1px solid rgba(0,0,0,0.08)' }}
              />
              <Typography>
                <>
                  {item.writer.name} {dayjs(item.createdAt).format(DATE_TIME_FORMAT)}
                </>
              </Typography>
              {item.modifiedAt && (
                <Typography>
                  ({dayjs(item.modifiedAt).format(DATE_TIME_FORMAT)} 최종수정)
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            padding: '5px',
            borderTop: '1px solid var(--design-v-10-light-line-line, #DCE0E7)',
          }}
        >
          {isEditMode ? (
            isMention ? (
              <HookFormMention
                name={nameStr + '.contents'}
                userList={userList ?? []}
              />
            ) : (
              <HookFormTextarea
                name={nameStr + '.contents'}
                sx={{ height: 63 }}
                resize="none"
              />
            )
          ) : (
            <Typography
              sx={{
                paddingLeft: '10px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {item.contents}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          {isEditMode && (
            <Box
              display="flex"
              gap="5px"
              padding="5px"
              justifyContent="end"
            >
              <Button onClick={onCancelClick}>취소</Button>
              <Button onClick={onUpdateClick}>등록</Button>
            </Box>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
}

const CommentControlPanel = ({ handleEdit, handleDelete, isEditMode }: ControlPanelProps) => {
  const itemList = [
    {
      text: isEditMode ? '수정 닫기' : '수정',
      icon: isEditMode ? HighlightOffIcon : EditIcon,
      action: handleEdit,
    },
    {
      text: '삭제',
      icon: DeleteIcon,
      action: handleDelete,
    },
  ];
  return <ControlPanel itemList={itemList} />;
};

const SX_NOTECARD_HEADER_USERINFO_WRAP = {
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
};
