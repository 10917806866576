import {
  accessAuthSettingsDepartmentUrl,
  accessAuthSettingsUrl,
  accessAuthSettingsUserUrl,
} from '@front/src/features/admin/utils/constant';
import type {
  AuthSearchParams,
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import type { AdminAccessAuthorizationSettingsHistorySearchParameter } from '@front/src/features/admin/features/access-authorization-settings/features/history/features/search/types/parameter';
import type { AuthorizationUserView } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/types/view';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getMenuList: () => `${accessAuthSettingsUrl}/menu`,
  getTabList: (menuId: number) => `${accessAuthSettingsUrl}/menu/${menuId}/tab`,
  getDepartmentList: (menuId: number) => `${accessAuthSettingsDepartmentUrl}/menu-parent/${menuId}`,
  updateAuth: () => `${accessAuthSettingsUrl}/department`,
  getUserList: (menuId: number) => `${accessAuthSettingsUserUrl}/menu-parent/${menuId}`,
  updateUserAuth: () => `${accessAuthSettingsUserUrl}`,
  getHistoryListByMenuId: (id: number) => `${accessAuthSettingsUrl}/menu/${id}/history`,
};

export const accessAuthSettingsApi = {
  getMenuList: async (authMenuId) => {
    const { data } = await axios.get(url.getMenuList(), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getDepartmentList: async (menuId: number, params: AuthSearchParams, authMenuId?: number) => {
    const { data } = await axios.get(url.getDepartmentList(menuId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getTabList: async (menuId: number, authMenuId?: number) => {
    const { data } = await axios.get(url.getTabList(menuId), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  updateAuth: async (params?: AuthUpdateParams, authMenuId?: number) =>
    await axios.patch(url.updateAuth(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getUserList: async <T = AuthorizationUserView[]>(
    menuId: number,
    params: AuthUserSearchParams,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getUserList(menuId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  updateUserAuth: async (params?: AuthUpdateParams, authMenuId?: number) =>
    await axios.patch(url.updateUserAuth(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHistoryList: async (
    params: AdminAccessAuthorizationSettingsHistorySearchParameter,
    authMenuId?: number
  ) => {
    const { data } = await axios.get(url.getHistoryListByMenuId(params.id), {
      params: {
        page: params.page,
        size: params.size,
        category: params.category,
      },
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
};
