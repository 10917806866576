import React from 'react';
import { AccountingSettingsManagementAccountingAccountHeaderUploadModal } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/components/modal';
import { AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/widget';

export { Header as AccountingSettingsManagementAccountingAccountHeaderFeature };
interface Props {
  menuId?: number;
}
const Header = ({ menuId }: Props) => (
  <>
    <AccountingSettingsManagementAccountingAccountHeaderUploadModal menuId={menuId} />
    <AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtWidget menuId={menuId} />
  </>
);
