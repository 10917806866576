import { useQuery } from 'react-query';
import { affiliatedCompanyPersonApi } from '@front/src/features/affiliated-company/features/person/query/api';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

export const affiliatedCompanyPersonQuery = {
  usePersonListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonShortView[]>({
      queryKey: ['affiliated-company', 'person', 'list', id],
      queryFn: () => affiliatedCompanyPersonApi.getPersonList(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
