import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import { useQuery } from 'react-query';
import { projectSalesInfoProjectDivisionModalApi } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/query/api';

export const projectSalesInfoProjectDivisionModalQuery = {
  useProjectDivisionModalGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectStepInformationView[]>({
      queryKey: ['project-sales-info', 'project-division', 'modal', id],
      queryFn: () =>
        projectSalesInfoProjectDivisionModalApi.getProjectDivisionModalData(id, menuId),
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
