import type { MouseEventHandler } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import type { ContextMenuItem } from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';
import ContextMenuNewIconUI from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';
import { ZoomOutMapOutlined } from '@mui/icons-material';

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
  order: number;
  sx?: SxProps;
  readOnly?: boolean;
  itemList?: ContextMenuItem[] | undefined;
  description?: string;
}

export default function ImgCarouselUI(props: Readonly<Props>) {
  const { order, onClick, sx = {}, readOnly = false, itemList, description } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        fontSize: '1.6rem',
        fontWeight: 500,
        lineHeight: '22px',
        color: ColorPalette.greyscale.white,
        background: 'transparent',
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        cursor: 'pointer',
        '& > div': {},
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'flex',
          gap: '15px',
          width: '100%',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        {!readOnly && (
          <Box
            sx={{
              display: 'inline-flex',
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '10%',
              backgroundColor: 'rgba(255,255,255,0.7)',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ContextMenuNewIconUI
              isPropagation
              itemList={itemList}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'inline-flex',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)',
          }}
        >
          <div style={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}>{description}</div>
        </Box>
        <Box
          sx={{
            display: 'inline-flex',
            width: '2.4rem',
            height: '2.4rem',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid rgba(255,255,255,0.8)'
          }}
        >{order}</Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: ColorPalette.etc.modal_scrim_lightest,
          visibility: 'hidden',
        }}
      >
        <ZoomOutMapOutlined sx={{ width: '5rem', height: '5rem' }} />
      </Box>
    </Box>
  );
}
