import { useIsMutating } from 'react-query';

const useWorkOutputGetIsLoading = (bundleId) => {
  const isAddTopLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'add-top'],
  });
  const isAddBottomLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'add-bottom'],
  });
  const isCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'create'],
  });
  const isUpdateFileLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'update-file'],
  });
  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'seq-up'],
  });
  const isSeqUpLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'add-top'],
  });
  const isSeqDownLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'seq-down'],
  });
  const isDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'delete'],
  });
  const isDeleteAllLoading = !!useIsMutating({
    mutationKey: ['work', 'output-bundle', 'output', bundleId, 'delete-all'],
  });
  return (
    isAddTopLoading ||
    isAddBottomLoading ||
    isCreateLoading ||
    isUpdateFileLoading ||
    isUpdateLoading ||
    isSeqUpLoading ||
    isSeqDownLoading ||
    isDeleteLoading ||
    isDeleteAllLoading
  );
};

export default useWorkOutputGetIsLoading;
