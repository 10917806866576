import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { useShallow } from 'zustand/react/shallow';
import { personalSettingsModalUISettingsHeaderSettingsPositionQuery } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/query/query';

export { repository as personalSettingsModalUISettingsHeaderSettingsPositionRepository };
const repository = {
  useGetHeaderUsageList: (authMenuId) => {
    const { header } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
      useShallow((state) => ({
        header: state.header,
      }))
    );
    const { data } =
      personalSettingsModalUISettingsHeaderSettingsPositionQuery.useGetHeaderUsageList(
        header?.id,
        authMenuId
      );
    return {
      data,
    };
  },
  useGetStatus: (authMenuId) => {
    const { header, menuId } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
      useShallow((state) => ({
        header: state.header,
        menuId: state.menuId,
      }))
    );
    const { data } = personalSettingsModalUISettingsHeaderSettingsPositionQuery.useGetStatus(
      menuId,
      header?.id,
      authMenuId
    );
    return {
      data,
    };
  },
};
