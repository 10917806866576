import React from 'react';
import { ProjectSalesInfoSubjectReviewModalSpecialWindTableRow } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/components/special-wind-table-row';
import { ProjectSalesInfoSubjectReviewModalSpecialWindProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/widgets/provider';

export { Widget as ProjectSalesInfoSubjectReviewModalSpecialWindWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalSpecialWindProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewModalSpecialWindTableRow />
  </ProjectSalesInfoSubjectReviewModalSpecialWindProvider>
);
