import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkWorkerTableBody from '@front/src/features/work/features/work/features/worker/components/TableBody';
import useWorkWorkerGetIsLoading from '@front/src/features/work/features/work/features/worker/hooks/useGetIsLoading';
import workWorkerQuery from '@front/src/features/work/features/work/features/worker/query/query';
import WorkWorkerTableHead from '@front/src/features/work/features/work/features/worker/components/TableHead';

export default function WorkWorkerTable(props: Readonly<SectionComponentProps>) {
  const { sectionId, dataId, menuId } = props;

  const isLoading = useWorkWorkerGetIsLoading();

  const { data: list } = workWorkerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkWorkerTableHead}
      BodyComponent={WorkWorkerTableBody}
      list={list}
    />
  );
}
