import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { useFormContext } from 'react-hook-form';
import DateAttr from '@front/src/components/ui-builder/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/TextAttr';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import Box from '@mui/material/Box';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import EditorUI from '@front/src/components/components-with-design/compound/EditorUI';
import 'react-quill/dist/quill.snow.css';
import EditorCollapseText from '@front/src/components/components-with-design/compound/EditorCollapseText';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

const workDetailCellComponent = {
  18: (props: CellComponentProps) => <WorkDetailTableCellDate {...props} />,
  19: (props: CellComponentProps) => <WorkDetailTableCellUserName {...props} />,
  20: (props: CellComponentProps) => <WorkDetailTableCellContent {...props} />,
  312: (props: CellComponentProps) => <WorkDetailTableCellCategory {...props} />,
  313: (props: CellComponentProps) => <WorkDetailTableCellCompletionRate {...props} />,

  95: (props: CellComponentProps) => <WorkDetailTableCellNumberAttr1 {...props} />,
  96: (props: CellComponentProps) => <WorkDetailTableCellTextAttr1 {...props} />,
  97: (props: CellComponentProps) => <WorkDetailTableCellDateAttr1 {...props} />,
  98: (props: CellComponentProps) => <WorkDetailTableCellNumberAttr2 {...props} />,
  99: (props: CellComponentProps) => <WorkDetailTableCellTextAttr2 {...props} />,
  100: (props: CellComponentProps) => <WorkDetailTableCellDateAttr2 {...props} />,
  101: (props: CellComponentProps) => <WorkDetailTableCellNumberAttr3 {...props} />,
  102: (props: CellComponentProps) => <WorkDetailTableCellTextAttr3 {...props} />,
  103: (props: CellComponentProps) => <WorkDetailTableCellDateAttr3 {...props} />,
};

export default workDetailCellComponent;

interface Props extends CellComponentProps<WorkDetailView> {}

const WorkDetailTableCellDate = ({
  isEditMode,
  onSubmit,
  sx = {},
  onClick,
  readOnly,
  isForm,
}: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={{
      ...sx,
    }}
  >
    <DatePickerWithHookForm
      name="date"
      onSubmit={isForm ? undefined : onSubmit}
      readOnly={readOnly || !isEditMode}
    />
  </Box>
);
const WorkDetailTableCellUserName = ({ item, onClick, sx = {} }: Readonly<Props>) => (
  <Box
    onClick={onClick}
    sx={sx}
  >
    {item.user?.name}
  </Box>
);
const WorkDetailTableCellCategory = ({
  onClick,
  sx,
  item,
  isEditMode,
  onSubmit,
  isForm,
  readOnly,
}: Props) => {
  const content = isEditMode ? (
    <UncontrolledInput
      name="category"
      onBlur={isForm ? undefined : onSubmit}
      readOnly={readOnly || !isEditMode}
    />
  ) : (
    item.category
  );

  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      {content}
    </Box>
  );
};

const WorkDetailTableCellCompletionRate = ({
  onClick,
  sx,
  item,
  isEditMode,
  onSubmit,
  isForm,
  readOnly,
}: Props) => {
  const getContent = () => {
    if (item.isExcludedCompletionRate) return '제외';
    if (isEditMode) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <UncontrolledInput
            type="number"
            name="completionRate"
            onBlur={isForm ? undefined : onSubmit}
            readOnly={readOnly || !isEditMode}
          />
          %
        </Box>
      );
    }
    return item.completionRate && `${item.completionRate}%`;
  };

  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      {getContent()}
    </Box>
  );
};

const WorkDetailTableCellContent = ({
  isEditMode,
  onSubmit,
  sx = {},
  onClick,
  isForm,
  readOnly,
}: Readonly<Props>) => {
  const { setValue, getValues } = useFormContext();

  const update = (value) => {
    setValue('content', value);
    onSubmit?.();
  };
  const value = getValues('content');

  const handleChange = (value) => {
    setValue('content', value);
  };

  const handleBlur = (value) => {
    update(value);
  };

  const content = isEditMode ? (
    <EditorUI
      value={value}
      placeholder="업무 내용을 작성해주세요"
      onChange={handleChange}
      onBlur={isForm ? undefined : handleBlur}
      enableImageUpload={false}
      readOnly={readOnly || !isEditMode}
    />
  ) : (
    <EditorCollapseText value={value} />
  );

  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      {content}
    </Box>
  );
};

const WorkDetailTableCellNumberAttr1 = (props: Readonly<Props>) => (
  <NumberAttr
    {...props}
    name="numberAttr1"
  />
);

const WorkDetailTableCellTextAttr1 = (props: Readonly<Props>) => (
  <TextAttr
    {...props}
    name="textAttr1"
  />
);

const WorkDetailTableCellDateAttr1 = (props: Readonly<Props>) => (
  <DateAttr
    {...props}
    name="dateAttr1"
  />
);

const WorkDetailTableCellNumberAttr2 = (props: Readonly<Props>) => (
  <NumberAttr
    {...props}
    name="numberAttr2"
  />
);

const WorkDetailTableCellTextAttr2 = (props: Readonly<Props>) => (
  <TextAttr
    {...props}
    name="textAttr2"
  />
);

const WorkDetailTableCellDateAttr2 = (props: Readonly<Props>) => (
  <DateAttr
    {...props}
    name="dateAttr2"
  />
);

const WorkDetailTableCellNumberAttr3 = (props: Readonly<Props>) => (
  <NumberAttr
    {...props}
    name="numberAttr3"
  />
);

const WorkDetailTableCellTextAttr3 = (props: Readonly<Props>) => (
  <TextAttr
    {...props}
    name="textAttr3"
  />
);

const WorkDetailTableCellDateAttr3 = (props: Readonly<Props>) => (
  <DateAttr
    {...props}
    name="dateAttr3"
  />
);
