// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mh_MbjaUtOzORMrdkDSQ{display:flex;align-items:center;gap:20px}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/estimation/features/estimation/components/experiment-information.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Mh_MbjaUtOzORMrdkDSQ`
};
export default ___CSS_LOADER_EXPORT___;
