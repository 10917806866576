import React, { useEffect, useMemo } from 'react';
import { ProjectSalesInfoDesignOutlineForm } from '@front/src/features/project-sales-info/features/design-outline/components/form';
import { useProjectSalesInfoDesignOutlineLogic } from '@front/src/features/project-sales-info/features/design-outline/widgets/useLogic';
import { ProjectSalesInfoDesignOutlineContext } from '@front/src/features/project-sales-info/features/design-outline/widgets/context';
import { ProjectSalesInfoDesignOutlineFormWrapper } from '@front/src/features/project-sales-info/features/design-outline/components/FormWrapper';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoDesignOutlineCityState } from '@front/src/features/project-sales-info/features/design-outline/widgets/useState';

import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoDesignOutlineWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    h: { onUpdate, setRegCode },
    d: { projectDesignOutlineData, structurePurposeOptionList, cityOptionList2, cityOptionList1 },
    isLoading,
  } = useProjectSalesInfoDesignOutlineLogic(menuId);
  const value = useMemo(
    () => ({
      h: { onUpdate, setRegCode },
      d: { projectDesignOutlineData, cityOptionList2, cityOptionList1, structurePurposeOptionList },
      isLoading,
    }),
    [
      onUpdate,
      setRegCode,
      projectDesignOutlineData,
      cityOptionList2,
      cityOptionList1,
      structurePurposeOptionList,
      isLoading,
    ]
  );
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  const { setReadOnly } = useProjectSalesInfoDesignOutlineCityState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <ProjectSalesInfoDesignOutlineContext.Provider value={value}>
      <Layout>
        <Body>
          <Header title={name} />
          <ProjectSalesInfoDesignOutlineFormWrapper>
            <ProjectSalesInfoDesignOutlineForm />
          </ProjectSalesInfoDesignOutlineFormWrapper>
        </Body>
      </Layout>
    </ProjectSalesInfoDesignOutlineContext.Provider>
  );
};

export default ProjectSalesInfoDesignOutlineWidget;
