import React from 'react';
import type { IDropDownOption } from '@front/src/components/components-with-design/component/DropDownUI';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import type { ModalPositionType } from '@front/layouts/ModalLayout';
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";

interface Props {
  position: ModalPositionType;
  setPositionType: (position: ModalPositionType) => void;
  sx?: SxProps<Theme> | undefined;
}

export default function ModalUIPositionDropDown({ position, setPositionType, sx }: Readonly<Props>) {
  const handleChange = (e) => {
    setPositionType(e.target.value);
  };
  return (
    <DropDownUI
      size="medium"
      value={position}
      onChange={handleChange}
      options={optionList}
      sx={{...sx}}
    />
  );
}

export const getSxFromModalPosition = (positionType: ModalPositionType) => {
  switch (positionType) {
    case 'center': {
      return {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
      };
    }
    case 'top': {
      return {
        transform: 'translate(-50%, 0%)',
        top: '0',
        left: '50%',
      };
    }
    case 'bottom': {
      return {
        transform: 'translate(-50%, 0%)',
        bottom: '0',
        left: '50%',
      };
    }
    case 'left': {
      return {
        transform: 'translate(0%, -50%)',
        top: '50%',
        left: '0',
      };
    }
    case 'right': {
      return {
        transform: 'translate(0%, -50%)',
        top: '50%',
        right: '0',
      };
    }
    case 'fullscreen': {
      return {
        transform: 'translate(0%, 0%)',
        top: '0',
        left: '0',
        width: '100dvw',
        height: '100dvh',
        maxWidth: 'inherit',
        maxHeight: 'inherit'
      };
    }
  }
};

const optionList: IDropDownOption[] = [
  {
    value: 'center',
    label: '중앙',
  },
  {
    value: 'top',
    label: '상단',
  },
  {
    value: 'bottom',
    label: '하단',
  },
  {
    value: 'left',
    label: '왼쪽',
  },
  {
    value: 'right',
    label: '오른쪽',
  },
  {
    value: 'fullscreen',
    label: '전체화면',
  },
];
