import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { convertIsYesOrNOToBoolean } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyCharacterPartnerCompanyUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/parameter';

const url = {
  createCharacterPartnerCompany: (id?) => `${baseUrl}/${id}/partner-company`,
  getCharacterPartnerCompany: (id?) => `${baseUrl}/${id}/partner-company`,
  updateCharacterPartnerCompany: (id?) => `${baseUrl}/partner-company/${id}`,
  deleteCharacterPartnerCompany: () => `${baseUrl}/partner-company`,
};

export const affiliatedCompanyCharacterPartnerCompanyApi = {
  createCharacterPartnerCompany: async (id?: number, menuId?) => {
    await axios.post(url.createCharacterPartnerCompany(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getCharacterPartnerCompany: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getCharacterPartnerCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCharacterPartnerCompany: async (
    params: AffiliatedCompanyCharacterPartnerCompanyUpdateParameter,
    menuId
  ) => {
    await axios.put(
      url.updateCharacterPartnerCompany(params.id),
      convertEmptyToNull(convertIsYesOrNOToBoolean(params)),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },
  deleteCharacterPartnerCompany: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deleteCharacterPartnerCompany(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
