import { adminManageableVariableApiURL } from '@front/src/features/manageable-variable/utils/constants';
import type {
  AdminManageableVariableSearchParams,
  AdminManageableVariableUpdateParams,
} from '@front/src/features/manageable-variable/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { api as adminManageableVariableApi };
const url = {
  create: () => `${adminManageableVariableApiURL}`,
  update: (id?) => `${adminManageableVariableApiURL}/${id}`,
  getManageableVariable: () => `${adminManageableVariableApiURL}/search`,
  getList: () => `${adminManageableVariableApiURL}`,
};

const api = {
  create: async (menuId) =>
    await axios.post(url.create(), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  update: async (params: AdminManageableVariableUpdateParams, menuId) =>
    await axios.put(url.update(params?.id), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  onAdminManageableVariableSearch: async (params: AdminManageableVariableSearchParams, menuId) => {
    const { data } = await axios.get(url.getManageableVariable(), {
      params: {
        keywordType: params.keywordType,
        keyword: params.keyword,
      },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getList: async <T = VariableInformationView[]>(key: string, menuId) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getList(), {
      params: { key },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
