import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { FormProvider, useForm } from 'react-hook-form';
import type { AffiliatedCompanyGiftHistoryGetParameter } from '@front/src/features/affiliated-company/features/gift-history/types/parameter';
import { AffiliatedCompanyGiftHistoryTableBodyTotalSelect } from '@front/src/features/affiliated-company/features/gift-history/components/gift-history-table-body-total-select';
import { giftTargetTypeList } from '@front/src/features/affiliated-company/utils/constant';
import { AffiliatedCompanyGiftHistoryContext } from '@front/src/features/affiliated-company/features/gift-history/widgets/context';
import { useAffiliatedCompanyGiftHistoryState } from '@front/src/features/affiliated-company/features/gift-history/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { GiftHistoryTableBodyTotal as AffiliatedCompanyGiftHistoryTableBodyTotal };

const GiftHistoryTableBodyTotal = () => {
  const {
    d: { giftList },
    h: { setParameter },
  } = useContext(AffiliatedCompanyGiftHistoryContext);
  const totalAmount = giftList
    ?.map((gift) => gift.amount)
    .reduce((a, c) => a + c, 0)
    .toLocaleString();
  const methods = useForm<AffiliatedCompanyGiftHistoryGetParameter>({
    values: {
      name: '',
      type: '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  const { readOnly } = useAffiliatedCompanyGiftHistoryState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow>
      <OldTd>합계</OldTd>
      <FormProvider {...methods}>
        <OldTd>
          <AffiliatedCompanyGiftHistoryTableBodyTotalSelect
            optionList={giftTargetTypeList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="name"
            onKeyDown={handleKeyDown}
            disabled={readOnly}
          />
        </OldTd>
      </FormProvider>
      <OldTd> </OldTd>
      <OldTd> </OldTd>
      <OldTd> </OldTd>
      <OldTd>{totalAmount}원</OldTd>
      <OldTd> </OldTd>
      <OldTd> </OldTd>
      <OldTd> </OldTd>
    </TableRow>
  );
};
