import React from 'react';
import ModalLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/ModalLoadingSpinner';
import { useIsMutating } from 'react-query';

export default function WorkCarouselUpdateSeqLoadingSpinner() {
  const isLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel', 'update-seq'],
  });
  return <ModalLoadingSpinnerUI open={isLoading} />;
}
