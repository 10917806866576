import { useQuery } from 'react-query';
import type { ProjectSalesInfoAffiliatedCompanyWebHardView } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/view';
import { projectSalesInfoAffiliatedCompanyWebHardApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/query/api';

export { query as projectSalesInfoAffiliatedCompanyWebHardQuery };
const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoAffiliatedCompanyWebHardView[]>({
      queryKey: ['project', 'sales-info', 'affiliated-company', 'web-hard', 'list', id],
      queryFn: () => projectSalesInfoAffiliatedCompanyWebHardApi.getList(id, menuId),
      cacheTime: 0,
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
