import type {
  AffiliatedCompanyMemoCreateParameter,
  AffiliatedCompanyMemoDeleteParameter,
  AffiliatedCompanyMemoSearchParameter,
  AffiliatedCompanyMemoUpdateParameter,
  AffiliatedCompanySearchParameter,
} from '@front/src/features/affiliated-company/types/parameter';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as affiliatedCompanyApi };

const url = {
  getList: () => `${baseUrl}`,
  create: () => `${baseUrl}`,
  delete: (id) => `${baseUrl}/${id}`,
  getMemoList: (id) => `/api/affiliated-company/${id}/memo`,
  createMemo: (id) => `/api/affiliated-company/${id}/memo`,
  updateMemo: (id, memoId) => `/api/affiliated-company/${id}/memo/${memoId}`,
  deleteMemo: (id, memoId) => `/api/affiliated-company/${id}/memo/${memoId}`,
  getSalesActivityList: (id) => `/api/affiliated-company/${id}/sales-activity`,
};

const api = {
  getList: async (params: AffiliatedCompanySearchParameter, menuId) => {
    const { data } = await axios.get(url.getList(), {
      params: {
        keyword: params.keyword,
        isIncludedClosedAffiliatedCompany: true,
      },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  delete: async (id: number, menuId) => {
    const { data } = await axios.delete(url.delete(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getMemoList: async (params: AffiliatedCompanyMemoSearchParameter, menuId) => {
    const { data } = await axios.get(url.getMemoList(params.id), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createMemo: async (params: AffiliatedCompanyMemoCreateParameter, menuId) => {
    await axios.post(url.createMemo(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  updateMemo: async (params: AffiliatedCompanyMemoUpdateParameter, menuId) => {
    await axios.put(url.updateMemo(params.id, params.memoId), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteMemo: async (params: AffiliatedCompanyMemoDeleteParameter, menuId) => {
    await axios.delete(url.deleteMemo(params.id, params.memoId), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  getSalesActivityList: async (id, menuId) =>
    await axios.get(url.getSalesActivityList(id), {
      headers: getMenuIdHeaders(menuId),
    }),
};
