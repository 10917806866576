import { useIsMutating } from 'react-query';

const useWorkCarouselBundleGetIsLoading = () => {
  const isCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel-bundle', 'create'],
  });

  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel-bundle', 'update'],
  });

  const isDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel-bundle', 'delete'],
  });

  const isMoveLoading = !!useIsMutating({
    mutationKey: ['work', 'carousel-bundle', 'move'],
  });

  return isCreateLoading || isUpdateLoading || isDeleteLoading;
};

export default useWorkCarouselBundleGetIsLoading;
