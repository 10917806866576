import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  NDepthTableBox,
  NDepthTableBoxBody,
  NDepthTableBoxCell,
  NDepthTableBoxHead,
  NDepthTableBoxRow,
  NDepthTableContainerBox,
} from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import AccountingAccountManagementAccountSettingsManagementAccountTableHeadCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/TableHeadCell';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import managementAccountQuery from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/query/query';
import ManagementAccountTableVerticalRow from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/VerticalRow';
import useManagementAccountTableForm from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import useManagementAccountTableActions from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableActions';
import Box from '@mui/material/Box';

export default function ManagementAccountTable(props: SectionComponentProps) {
  const { dataId, sectionId, menuId } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const onTableBodyFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const commonParams = {
    dataId,
    sectionId,
    menuId,
  };

  const list = managementAccountQuery.useGetList(commonParams);

  const { methods, showListFieldArray, selectedRowIndexListFieldArray, initialize } =
    useManagementAccountTableForm();

  const { getValues, watch, setValue } = methods;

  const manualList = managementAccountQuery.useGetManual(
    {
      category: getValues('showList')?.[4]?.[watch('selectedRowIndexList')[4]?.value]?.name ?? '',
    },
    commonParams
  );

  const emptyColList = [...Array(getValues('col'))];

  const { onKeyDown } = useManagementAccountTableActions({
    ...props,
    selectedRowIndexListFieldArray,
    formContext: methods,
  });

  useEffect(() => {
    if (list) {
      initialize(list);
    }
  }, [list]);

  useEffect(() => {
    if (manualList) {
      setValue('manual', manualList);
    }
  }, [manualList]);

  const renderTableHead = () =>
    emptyColList.map((h, index) => (
      <AccountingAccountManagementAccountSettingsManagementAccountTableHeadCell
        key={`head-${index}`}
        index={index}
      />
    ));

  const renderTableBody = () =>
    emptyColList.map((col, index) => (
      <ManagementAccountTableVerticalRow
        {...props}
        key={`col-${index}`}
        index={index}
        showListFieldArray={showListFieldArray}
        selectedRowIndexListFieldArray={selectedRowIndexListFieldArray}
        onTableBodyFocus={onTableBodyFocus}
      />
    ));

  return (
    <FormProvider {...methods}>
      <NDepthTableContainerBox
        sx={{ overflowY: 'visible !important', overflowX: 'visible !important' }}
      >
        <NDepthTableBox>
          <NDepthTableBoxHead>
            <NDepthTableBoxRow>
              {renderTableHead()}
              {watch('showManual') && (
                <NDepthTableBoxCell
                  isHead
                  key="head-manual"
                  sx={{
                    position: 'relative',
                  }}
                >
                  {`한풍메뉴얼(${manualList?.length ?? 0})`}
                </NDepthTableBoxCell>
              )}
            </NDepthTableBoxRow>
          </NDepthTableBoxHead>
          <NDepthTableBoxBody
            ref={ref}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            {renderTableBody()}
            {watch('showManual') && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {[...Array(getValues('row'))].map((_, index) => {
                  if (manualList?.[index]) {
                    return (
                      <NDepthTableBoxCell key={`manual-${index}`}>
                        {manualList[index].manual || '-'}
                      </NDepthTableBoxCell>
                    );
                  }
                  return <NDepthTableBoxCell key={`manual-${index}`}>{''}</NDepthTableBoxCell>;
                })}
              </Box>
            )}
          </NDepthTableBoxBody>
        </NDepthTableBox>
      </NDepthTableContainerBox>
    </FormProvider>
  );
}
