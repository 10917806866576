import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ProposalContentId } from '@front/ost_proposal/domain';
import Contents from '@front/ost_proposal/view/detail/content/ContentLayout';
import type {
  ProposalContentAddParameter,
  ProposalContentChangeParameter,
} from '@front/ost_proposal/parameter';
import { proposalAction } from '@front/ost_proposal/action';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  isContributor: boolean;
}

function ContentsService({ isContributor }: Props) {
  const dispatch = useDispatch();
  const addContent = useCallback(
    (params: ProposalContentAddParameter) => () => dispatch(proposalAction.addContent(params)),
    [dispatch]
  );
  const deleteContent = useCallback(
    (id: ProposalContentId) => dispatch(proposalAction.deleteContent(id)),
    [dispatch]
  );
  const updateContent = useCallback(
    (params: ProposalContentChangeParameter) => dispatch(proposalAction.updateContent(params)),
    [dispatch]
  );
  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  return (
    <Contents
      addContent={addContent}
      deleteContent={deleteContent}
      updateContent={updateContent}
      openSnackbar={openSnackbar}
      isContributor={isContributor}
    />
  );
}

export default ContentsService;
