import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workOutputMutation from '@front/src/features/work/features/work/features/output/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkOutputAddBottom = (bundleId: number, commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workOutputMutation.useAddBottom(bundleId, commonParams);
  return {
    run: () => mutate(undefined, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkOutputAddBottom;
