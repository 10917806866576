import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import ProjectSalesInfoContractEarningsWidget from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/widget';
import ProjectSalesInfoCollectionSaveHistoryWidget from '@front/src/features/project-sales-info/features/collection/features/save-history/widgets/widget';
import ProjectSalesInfoCollectionPaymentHistoryWidget from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/widget';
import ProjectSalesInfoCMSaveHistoryWidget from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/widgets/widget';
import ProjectSalesInfoCMPaymentHistoryWidget from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/widget';

export { sectionComponent as projectSalesInfoCollectionSectionComponent };

const sectionComponent: SectionComponent = {
  34: ({ ...rest }) => <ProjectSalesInfoContractEarningsWidget {...rest} />,
  35: ({ ...rest }) => <ProjectSalesInfoCollectionSaveHistoryWidget {...rest} />,
  36: ({ ...rest }) => <ProjectSalesInfoCollectionPaymentHistoryWidget {...rest} />,
  37: ({ ...rest }) => <ProjectSalesInfoCMSaveHistoryWidget {...rest} />,
  38: ({ ...rest }) => <ProjectSalesInfoCMPaymentHistoryWidget {...rest} />,
};
