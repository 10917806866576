import { useMutation, useQueryClient } from 'react-query';
import type {
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import { accessAuthSettingsApi } from '@front/src/features/admin/features/access-authorization-settings/api/api';
import type { AuthorizationUserView } from '@front/src/features/admin/features/access-authorization-settings/features/user-authorization/types/view';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export const adminAccessAuthSettingsUserMutation = {
  useAuthUserUpdate: (menuId: number, params: AuthUserSearchParams, authMenuId?: number) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params?: AuthUpdateParams) =>
        accessAuthSettingsApi.updateUserAuth(params, authMenuId),
      onMutate: async (newData) => {
        await queryClient.cancelQueries([
          'admin',
          'access-auth-settings',
          'user',
          menuId,
          params.keyword,
        ]);
        const response = queryClient.getQueryData<{
          data: AuthorizationUserView[] | undefined;
        }>(['admin', 'access-auth-settings', 'user', menuId, params.keyword]);
        const previousData = response?.data;

        const updateTypeOptimistic = (list: AuthorizationUserView[] | undefined) => {
          if (!list) return [];
          list.map((item) => ({
            ...item,
            menus: item.menus.map((el) => {
              const updateData = newData?.list?.find(
                (updateItem) => updateItem.id === el.authorizationUser.id
              );
              return updateData
                ? {
                    ...el,
                    authorizationUser: {
                      ...el.authorizationUser,
                      type: updateData.type,
                    },
                  }
                : el;
            }),
          }));
        };

        queryClient.setQueryData(
          ['admin', 'access-auth-settings', 'user', menuId, params.keyword],
          updateTypeOptimistic(previousData)
        );

        return () =>
          queryClient.setQueryData(
            ['admin', 'access-auth-settings', 'user', menuId, params.keyword],
            previousData
          );
      },
      onError: (error, _, rollback) => {
        if (rollback) rollback();
        else console.log(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'admin',
          'access-auth-settings',
          'user',
          menuId,
          params.keyword,
        ]);
      },
    });

    return {
      mutate,
      isLoading,
    };
  },
};
