import React, { useMemo } from 'react';
import { useProjectSalesInfoEstimationEstimationCreateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/useLogic';
import { ProjectSalesInfoEstimationEstimationCreateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoEstimationEstimationCreateModalTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/create-modal/components/modal-table';

export { Widget as ProjectSalesInfoEstimationEstimationCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    d: { categoryList, experiment },
    h: { onClose, onCreate },
    isLoading,
  } = useProjectSalesInfoEstimationEstimationCreateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { categoryList, experiment },
      h: { onClose, onCreate },
    }),
    [categoryList, onClose, onCreate, experiment]
  );
  if (isLoading) {
    return <LoadingSpinner height="920px" />;
  }
  return (
    <ProjectSalesInfoEstimationEstimationCreateModalContext.Provider value={value}>
      <ProjectSalesInfoEstimationEstimationCreateModalTable />
    </ProjectSalesInfoEstimationEstimationCreateModalContext.Provider>
  );
};
