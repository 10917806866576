import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';
import MemoDrawerService from '@front/components/Memo/route/drawer';
import ProposalFormService from '@front/ost_proposal/service/memo/form';
import ProposalFilterService from '@front/ost_proposal/service/memo/filter';
import ProposalListService from '@front/ost_proposal/service/memo/list';
import { initialProposalMemoQuery } from '@front/ost_proposal/query';
import { fixedContentsHeight } from '@front/util/ConstantCommonVariable';

export default function ProposalMemoService() {
  const dispatch = useDispatch();
  const memoDrawerOpen = useSelector(
    (root: RootState) => root.proposal.memoDrawerOpen,
    shallowEqual
  );
  const id = useSelector((root: RootState) => root.proposal.id, shallowEqual);
  const setMemoDrawerOpen = useCallback(
    (open: boolean) => dispatch(proposalAction.setMemoDrawerOpen(open)),
    [dispatch]
  );
  const [formHeight, setFormHeight] = useState<number>(fixedContentsHeight);

  useEffect(() => {
    if (id) {
      dispatch(proposalAction.setMemoFilter(initialProposalMemoQuery));
    }
  }, [id]);
  return (
    <MemoDrawerService
      isOpen={memoDrawerOpen}
      setDrawer={setMemoDrawerOpen}
      children={
        <>
          <ProposalFormService setFormHeight={setFormHeight} />
          <ProposalFilterService id={id} />
          <ProposalListService formHeight={formHeight} />
        </>
      }
    />
  );
}
