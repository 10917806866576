import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonCharacterBasicQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/query/query';
import { affiliatedCompanyPersonCharacterBasicMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/basic/query/mutation';

export const affiliatedCompanyPersonCharacterBasicRepository = {
  usePersonCharacterBasicGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyPersonCharacterBasicQuery.usePersonCharacterBasicGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  usePersonCharacterBasicUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyPersonCharacterBasicMutation.usePersonCharacterBasicUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
