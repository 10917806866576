import React from 'react';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { ProjectSalesInfoPaymentHistoryCreateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/create-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoPaymentHistoryCreateModalWrapper };

const ModalWrapper = () => {
  const isCreateModalOpen = useProjectSalesInfoPaymentHistoryState(
    (state) => state.isCreateModalOpen
  );
  return <>{isCreateModalOpen && <ProjectSalesInfoPaymentHistoryCreateModalWidget />}</>;
};
