import React from 'react';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import type {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import ApprovalStepControlPanel from '@front/src/features/drawer-approval/components/ApprovalStepControlPanel';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import UserSelectorInputFeature from '@front/src/features/user-selector/features/input';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import { useCustomDialog } from '@front/src/features/dialog';
import noteQuery from '@front/src/features/note/query/query';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  index: number;
  append: UseFieldArrayAppend<FieldValues, 'request.approvalList'>;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  id: string;
}

export default function ApprovalStepTableRow({ index, append, remove, move, id }: Props) {
  const { setValue, watch, getValues } = useFormContext();
  const editOpenList = useApprovalStore((state) => state.editOpenList);
  const isEditOpen = editOpenList.includes(id);
  const currentUserId = watch(`request.approvalList.${index}.userId`);
  const { data: user } = userSelectorRepository.useGetUser(currentUserId);
  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );
  const { menuId, dataId } = useIdGroup();
  const { data: userList } = noteQuery.useGetAuthUserList({ menuId, dataId });
  const { alert } = useCustomDialog();
  const combinedList = Array.from(
    new Set(
      getValues('request.referenceList')
        .map((item) => item.userId)
        .concat(getValues('request.approvalList').map((item) => item.userId))
    )
  );
  const newList = userList
    ?.filter((item) => !combinedList.includes(item.id) && login?.id !== item.id)
    .map((item) => item.id);

  return (
    <TableRowUI
      sx={{
        '& .MuiTableCell-root': { padding: '0' },
      }}
    >
      <TableCellUI>
        <ApprovalStepControlPanel
          append={append}
          remove={remove}
          move={move}
          index={index}
          isEditOpen={isEditOpen}
          id={id}
        />
      </TableCellUI>
      <TableCellUI>{index + 1}차 결재자</TableCellUI>
      <TableCellUI>{user?.department.name ?? '-'}</TableCellUI>
      <TableCellUI>{user?.role.name ?? '-'}</TableCellUI>
      <TableCellUI>
        <UserSelectorInputFeature
          value={currentUserId}
          onChange={(value) => {
            const userId = value?.[0]?.id;
            if (getValues('request.referenceList').find((item) => item.userId === userId)) {
              alert({
                children: [{ value: '결재자를 중복으로 선택할 수 없습니다.' }],
                title: '중복 선택 불가 알림',
                closeText: '닫기',
              });
              return;
            }
            if (getValues('request.referenceList').find((item) => item.userId === userId)) {
              alert({
                children: [{ value: '참조자를 결재자로 선택할 수 없습니다.' }],
                title: '중복 선택 불가 알림',
                closeText: '닫기',
              });
              return;
            }
            setValue(`request.approvalList.${index}.userId`, userId);
          }}
          predefinedIdList={newList}
        />
      </TableCellUI>
    </TableRowUI>
  );
}
