import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';

/**
 * 스페어 타입 셀 렌더러 일괄 생성 유틸리티
 * @param startKeyId 일괄 생성 시작 헤더셀 ID (예: 100 입력시 100~108 numberAttr#, textAttr#, dateAttr# 각 3벌 생성)
 */
export const generateSpareAttrCellRenders = (startKeyId: number) => {
  const renders = {};
  for (let i = 0; i < 3; i++) {
    renders[startKeyId + i * 3] = ({ ...rest }: CellComponentProps) => (
      <UIBuilderTableCellInputNumber
        name={`numberAttr${i}`}
        {...rest}
      />
    );
    renders[startKeyId + i * 3 + 1] = ({ ...rest }: CellComponentProps) => (
      <UIBuilderTableCellInputText
        name={`textAttr${i}`}
        {...rest}
      />
    );
    renders[startKeyId + i * 3 + 2] = ({ ...rest }: CellComponentProps) => (
      <UIBuilderTableCellInputDate
        name={`dateAttr${i}`}
        {...rest}
      />
    );
  }
  return renders;
};
