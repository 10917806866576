import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { ContractPaymentTermsTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/components/table-body';
import { ContractPaymentTermsTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/components/table-head';
import contractPaymentTermsQuery from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/query/query';

export { Table as ContractPaymentTermsTable };

interface TableProps {
  sectionProps: SectionComponentProps;
  clientId: number | undefined;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const { data: list } = contractPaymentTermsQuery.useList({
    dataId: sectionProps.sectionId,
    menuId: sectionProps.sectionId,
    sectionId: sectionProps.sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={ContractPaymentTermsTableHead}
      BodyComponent={ContractPaymentTermsTableBody}
      readOnly={props.sectionProps.readOnly}
      list={list}
      useSummary={true}
    />
  );
}
