import { noOp } from '@front/src/utils';
import type { AffiliatedPersonMemoUpdateParameter } from '@front/src/features/affiliated-person/features/memo/types/parameter';
import { createContext } from 'react';

export { Context as AffiliatedPersonMemoUpdateContext };

interface State {
  onUpdate: (params: AffiliatedPersonMemoUpdateParameter) => void;
}

const Context = createContext<State>({
  onUpdate: noOp,
});
