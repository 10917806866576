import { approvalDocumentUrlPath } from '@front/src/features/drawer-approval/utils/constants';
import type {
  ApprovalDocumentView,
  HasFileItemView,
} from '@front/src/features/drawer-approval/types/view';
import axios, { AxiosResponse } from 'axios';
import type {
  ApprovalDocumentCreateParameter,
  ApprovalDocumentUpdateParameter,
  ApprovalHasFileListParams,
} from '@front/src/features/drawer-approval/types/parameter';
import type { FileItemQueryView } from '@front/src/features/drawer-approval-document/types/view';
import type { WorkRoleView } from '@front/src/features/work/features/work/types/view';

const url = {
  getApprovalDocumentList: () => `${approvalDocumentUrlPath}`,
  createApprovalDocument: () => `${approvalDocumentUrlPath}`,
  getApprovalDocumentSourceTitle: () => `${approvalDocumentUrlPath}/source-title`,

  deleteTemp: (documentId: number) => `${approvalDocumentUrlPath}/${documentId}`,
  updateTemp: () => `${approvalDocumentUrlPath}`,

  getHasFileList: () => '/api/file-item/list',
  getWorkRole: (workId: number) => `/api/work/${workId}/role`,
};

export const approvalApi = {
  getApprovalDocumentList: async <T = ApprovalDocumentView[]>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocumentList());
    return data;
  },
  createApprovalDocument: async <T = unknown>(params: ApprovalDocumentCreateParameter) => {
    const { multipartFile, ...rest } = params.beforeFileItem ?? {};
    const { multipartFile: afterFile, ...afterRest } = params.afterFileItem ?? {};

    const formData = new FormData();
    formData.append(
      'request',
      new Blob([JSON.stringify(params.request)], { type: 'application/json' })
    );
    if (params.fileItemList !== undefined) {
      Array.from(params.fileItemList).forEach((file) => {
        formData.append('fileItemList', file?.multipartFile ?? '');
      });
    }
    formData.append(
      'fileItemMetaList',
      new Blob([JSON.stringify(params.fileItemList)], { type: 'application/json' })
    );
    // formData.append('beforeFileItem', (params.beforeFileItem?.multipartFile as File) ?? '');
    //
    // formData.append(
    //   'beforeFileItemMeta',
    //   new Blob([JSON.stringify(rest)], { type: 'application/json' })
    // );
    //
    // formData.append('afterFileItem', (params.afterFileItem?.multipartFile as File) ?? '');
    //
    // formData.append(
    //   'afterFileItemMeta',
    //   new Blob([JSON.stringify(afterRest)], { type: 'application/json' })
    // );

    if (params.beforeFileItem && !(params.beforeFileItem?.multipartFile as FileItemQueryView)?.id) {
      formData.append('beforeFileItem', (params.beforeFileItem?.multipartFile as File) ?? '');
      const { multipartFile, ...rest } = params.beforeFileItem ?? {};
      formData.append(
        'beforeFileItemMeta',
        new Blob([JSON.stringify(rest)], { type: 'application/json' })
      );
    }

    if (params.afterFileItem && !(params.afterFileItem?.multipartFile as FileItemQueryView)?.id) {
      formData.append('afterFileItem', (params.afterFileItem?.multipartFile as File) ?? '');
      const { multipartFile: afterFile, ...afterRest } = params.afterFileItem ?? {};
      formData.append(
        'afterFileItemMeta',
        new Blob([JSON.stringify(afterRest)], { type: 'application/json' })
      );
    }

    await axios.post(url.createApprovalDocument(), formData);
  },
  getApprovalDocumentSourceTitle: async <T = string>(params: {
    menuId?: string;
    dataId?: string;
  }) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getApprovalDocumentSourceTitle(), {
      params,
    });
    return data;
  },

  deleteTemp: async <T = unknown>(documentId: number) =>
    await axios.delete<T, AxiosResponse<T>>(url.deleteTemp(documentId)),
  updateTemp: async <T = unknown>(params: ApprovalDocumentUpdateParameter) => {
    const formData = new FormData();
    formData.append(
      'request',
      new Blob([JSON.stringify(params.request)], { type: 'application/json' })
    );
    if (params.addFileItemList !== undefined) {
      Array.from(params.addFileItemList).forEach((file) => {
        formData.append('addFileItemList', file?.multipartFile ?? '');
      });
    }
    formData.append(
      'fileItemMetaList',
      new Blob([JSON.stringify(params.addFileItemList)], { type: 'application/json' })
    );

    if (params.beforeFileItem && !(params.beforeFileItem?.multipartFile as FileItemQueryView)?.id) {
      formData.append('beforeFileItem', (params.beforeFileItem?.multipartFile as File) ?? '');
      const { multipartFile, ...rest } = params.beforeFileItem ?? {};
      formData.append(
        'beforeFileItemMeta',
        new Blob([JSON.stringify(rest)], { type: 'application/json' })
      );
    }

    if (params.afterFileItem && !(params.afterFileItem?.multipartFile as FileItemQueryView)?.id) {
      formData.append('afterFileItem', (params.afterFileItem?.multipartFile as File) ?? '');
      const { multipartFile: afterFile, ...afterRest } = params.afterFileItem ?? {};
      formData.append(
        'afterFileItemMeta',
        new Blob([JSON.stringify(afterRest)], { type: 'application/json' })
      );
    }

    await axios.put<T, AxiosResponse<T>>(url.updateTemp(), formData);
  },

  getHasFileList: async <T = HasFileItemView[]>(params: ApprovalHasFileListParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHasFileList(), { params });
    return data;
  },
  getWorkRole: async <T = WorkRoleView>(workId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getWorkRole(workId));
    return data;
  },
};
