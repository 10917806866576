import React from 'react';
import { Box, Typography } from '@mui/material';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import Button from '@front/layouts/Button';
import noteMutation from '@front/src/features/note/query/mutation';
import type { NoteItemView } from '@front/src/features/note/types/view';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';

interface Props {
  isEditOpen: boolean;
  handleEdit: () => void;
  item: NoteItemView;
  index: number;
}

export default function NoteCardBody({ isEditOpen, handleEdit, item, index }: Props) {
  const methods = useFormContext();
  const { mutate: updateNote, isLoading } = noteMutation.useUpdateNote(item.id);
  const { enqueueSnackbar } = useSnackbar();

  const nameStr = `noteList.${index}.contents`;

  const onUpdateClick = methods.handleSubmit((data) => {
    if (!methods.getValues(nameStr)) {
      enqueueSnackbar('값을 입력해주세요.', { variant: 'warning' });
      return;
    }

    updateNote({ contents: data.noteList[index].contents });
    handleEdit();
  });
  return (
    <>
      {isEditOpen ? (
        <Box
          width="100%"
          padding="5px"
        >
          <HookFormTextarea
            name={nameStr}
            sx={{ height: '200px' }}
          />
          <Box
            display="flex"
            gap="5px"
            paddingTop="5px"
          >
            <Button
              onClick={() => handleEdit()}
              fullWidth={true}
            >
              취소
            </Button>
            <Button
              onClick={onUpdateClick}
              fullWidth={true}
            >
              저장
            </Button>
          </Box>
        </Box>
      ) : (
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
          }}
        >
          {item.contents}
        </Typography>
      )}
    </>
  );
}
