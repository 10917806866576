import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { bidInfoModalLayout } from '@front/src/features/project-sales-info/features/bid/features/bid-information/components/layout';
import { ProjectSalesInfoBidResultCreateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/widgets/context';
import { useProjectSalesInfoBidResultCreateModalLogic } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/widgets/useLogic';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { ProjectSalesInfoBidResultCreateModalTable } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/components/modal-table';
import { useShallow } from 'zustand/react/shallow';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoBidResultCreateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const { Layout, Body } = bidInfoModalLayout;
  const { isCreateModalOpen, setIsCreateModalOpen } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      isCreateModalOpen: state.isCreateModalOpen,
      setIsCreateModalOpen: state.setIsCreateModalOpen,
    }))
  );
  const {
    h: { onCreate },
  } = useProjectSalesInfoBidResultCreateModalLogic(menuId);
  const value = useMemo(
    () => ({
      h: { onCreate },
    }),
    [onCreate]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isCreateModalOpen}
      onClose={() => setIsCreateModalOpen()}
      title="입찰결과 등록"
    >
      <ProjectSalesInfoBidResultCreateModalContext.Provider value={value}>
        <Layout>
          <Body>
            <ProjectSalesInfoBidResultCreateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoBidResultCreateModalContext.Provider>
    </ModalLayout>
  );
};
