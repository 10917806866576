import React from 'react';
import { ProjectSalesInfoSubjectReviewModalComplexInformationDeleteProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/delete/widgets/provider';
import { ProjectSalesInfoSubjectReviewModalComplexInformationDeleteButton } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/delete/components/delete-button';

export { Widget as ProjectSalesInfoSubjectReviewModalComplexInformationDeleteWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalComplexInformationDeleteProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewModalComplexInformationDeleteButton />
  </ProjectSalesInfoSubjectReviewModalComplexInformationDeleteProvider>
);
