import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd } from '@front/layouts/Table';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyOutlineBankInfoContext } from '@front/src/features/affiliated-company/features/outline/features/bank-info/widgets/context';
import type { AffiliatedCompanyOutlineBankInfoUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { convertToOptionList } from '@front/src/utils';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { BankInfo as AffiliatedCompanyOutlineBankInfo };
const BankInfo = () => {
  const {
    d: { bankInfo, bankNameOptionList },
    h: { onBankInfoUpdate },
  } = useContext(AffiliatedCompanyOutlineBankInfoContext);
  const methods = useForm<AffiliatedCompanyOutlineBankInfoUpdateParameter>({
    defaultValues: {
      bankName: bankInfo?.bankName ?? '',
      accountNumber: bankInfo?.accountNumber ?? '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onBankInfoUpdate(data);
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow key={bankInfo?.bankName ?? Math.random()}>
        <Th>은행명</Th>
        <TableCell>
          <HookFormSelect
            width="120px"
            defaultLabel="선택"
            name="bankName"
            onSubmit={onSubmit}
            optionList={convertToOptionList(bankNameOptionList)!}
            disabled={readOnly}
          />
        </TableCell>
      </TableRow>
      <TableRow key={bankInfo?.accountNumber ?? Math.random()}>
        <Th>계좌번호</Th>
        <OldTd>
          <HookFormInput
            name="accountNumber"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
