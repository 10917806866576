import React from 'react';
import type { FormikLayoutProps } from 'layouts/PageLayout';
import PageLayout from 'layouts/PageLayout';
import type { DepartmentQuery } from 'department/query';
import TreeViewButton from 'department/view/Page/TreeViewButton';
import SearchForm from './SearchForm';
import type { ListProps } from './List';
import List from './List';
import Box from '@mui/material/Box';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';

import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends ListProps, FormikLayoutProps<DepartmentQuery>, SectionComponentProps {}

export default function DepartmentPage(props: Props) {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  return (
    <PageLayout
      titleRightComponent={
        <Box
          display="flex"
          gap="10px"
          sx={{position:'absolute',top:'1.5rem',right:'1.5rem'}}
        >
          {isValid && <PersonalSettingsFeature authMenuId={props.menuId} />}
          <TreeViewButton />
        </Box>
      }
      filter={<SearchForm />}
      body={<List {...props} />}
      formik={props.formik}
    />
  );
}
