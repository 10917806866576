import React from 'react';
import { FormProvider } from 'react-hook-form';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import { Box } from '@mui/material';
import useProjectSalesCreateForm from '@front/src/features/project-sales/features/aside/features/create-button/hooks/useForm';
import ProjectSalesAsideCreateTable from '@front/src/features/project-sales/features/aside/features/create-button/components/Table';
import useProjectSalesCreateLogic from '@front/src/features/project-sales/features/aside/features/create-button/hooks/useCreateLogic';
import ProjectSalesCreateLoadingSpinner from '@front/src/features/project-sales/features/aside/features/create-button/components/LoadingSpinner';

interface Props {
  onClose: () => void;
  menuId: number | undefined;
}

export default function ProjectSalesAsideCreateForm({ onClose, menuId }: Readonly<Props>) {
  const { onCreate } = useProjectSalesCreateLogic({ onClose, menuId });
  const { onSubmit, methods } = useProjectSalesCreateForm({ onCreate });
  return (
    <FormProvider {...methods}>
      <ProjectSalesCreateLoadingSpinner />
      <ModalBodyUI
        sx={{
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            maxWidth: '80rem',
            minWidth: '60rem',
            maxHeight: '20rem',
            margin: 'auto',
            gap: '1rem',
          }}
        >
          <ModalHeaderUI>
            <ButtonBasicUI
              shape="tertiary"
              size="medium"
              onClick={onClose}
              sx={{
                width: '6.4rem',
              }}
            >
              취소
            </ButtonBasicUI>
            <ButtonBasicUI
              shape="primary2"
              size="medium"
              onClick={onSubmit}
              sx={{
                width: '6.4rem',
              }}
            >
              등록
            </ButtonBasicUI>
          </ModalHeaderUI>
          <ModalContentUI>
            <ProjectSalesAsideCreateTable />
          </ModalContentUI>
        </Box>
      </ModalBodyUI>
    </FormProvider>
  );
}
