import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import Box from '@mui/material/Box';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import { convertToOptionList } from '@front/src/utils';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';

const workCategoryCellComponent = {
  190: ({ ...rest }: CellComponentProps) => <WorkCategoryArea2 {...rest} />,
  191: ({ ...rest }: CellComponentProps) => <WorkCategoryDepartment {...rest} />,
  192: ({ ...rest }: CellComponentProps) => <WorkCategoryCollaboration {...rest} />,
  193: ({ ...rest }: CellComponentProps) => <WorkCategoryImpact {...rest} />,
  194: ({ ...rest }: CellComponentProps) => <WorkCategorySecurity {...rest} />,
  196: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr1 {...rest} />,
  197: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr1 {...rest} />,
  198: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr1 {...rest} />,
  199: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr2 {...rest} />,
  200: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr2 {...rest} />,
  201: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr2 {...rest} />,
  202: ({ ...rest }: CellComponentProps) => <WorkCategoryNumberAttr3 {...rest} />,
  203: ({ ...rest }: CellComponentProps) => <WorkCategoryTextAttr3 {...rest} />,
  204: ({ ...rest }: CellComponentProps) => <WorkCategoryDateAttr3 {...rest} />,
};

export default workCategoryCellComponent;

interface Props extends CellComponentProps<WorkCategoryView> {}

const WorkCategoryArea2 = (props: Readonly<Props>) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="area"
    code={ManagedVariable.WorkArea}
  />
);

const WorkCategoryArea = (props: Readonly<Props>) => {
  const { sx = {}, onSubmit, onClick, isEditMode, readOnly, menuId, isForm, item } = props;
  const { data: workAreaList } = adminManageableVariableRepository.useListGet('업무-영역', menuId);
  const renderContent = () => {
    if (readOnly || !isEditMode) return item.area ?? '-';

    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="area"
        options={convertToOptionList(workAreaList)}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent() ?? '-'}
    </Box>
  );
};

const WorkCategoryDepartment = (props: Readonly<Props>) => {
  const { onClick, sx = {}, onSubmit, isEditMode, readOnly, menuId, isForm, item } = props;
  const { data: workDepartmentList } = adminManageableVariableRepository.useListGet(
    '업무-부서',
    menuId
  );
  const renderContent = () => {
    if (readOnly || !isEditMode) return item.department ?? '-';
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="department"
        options={convertToOptionList(workDepartmentList)}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
};

const WorkCategoryCollaboration = (props: Readonly<Props>) => {
  const { onClick, sx = {}, onSubmit, isEditMode, readOnly, menuId, isForm, item } = props;
  const { data: workCollaborationList } = adminManageableVariableRepository.useListGet(
    '업무-협업/영향',
    menuId
  );
  const renderContent = () => {
    if (readOnly || !isEditMode) return item.collaboration ?? '-';
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="collaboration"
        options={convertToOptionList(workCollaborationList)}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
};

const WorkCategoryImpact = (props: Readonly<Props>) => {
  const { onClick, sx = {}, onSubmit, isEditMode, readOnly, menuId, isForm, item } = props;
  const { data: workCollaborationList } = adminManageableVariableRepository.useListGet(
    '업무-협업/영향',
    menuId
  );
  const renderContent = () => {
    if (readOnly || !isEditMode) return item.impact ?? '-';
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="impact"
        options={convertToOptionList(workCollaborationList)}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
};
const WorkCategorySecurity = (props: Readonly<Props>) => {
  const { onClick, sx = {}, onSubmit, isEditMode, readOnly, menuId, isForm, item } = props;
  const { data: workSecurityList } = adminManageableVariableRepository.useListGet(
    '업무-보안',
    menuId
  );
  const renderContent = () => {
    if (readOnly || !isEditMode) return item.security ?? '-';
    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name="security"
        options={convertToOptionList(workSecurityList)}
        onSubmit={isForm ? undefined : onSubmit}
      />
    );
  };
  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
};

function WorkCategoryNumberAttr1(props: Readonly<Props>) {
  return (
    <NumberAttr
      name="numberAttr1"
      {...props}
    />
  );
}

function WorkCategoryTextAttr1(props: Readonly<Props>) {
  return (
    <TextAttr
      {...props}
      name="textAttr1"
    />
  );
}

function WorkCategoryDateAttr1(props: Readonly<Props>) {
  return (
    <DateAttr
      {...props}
      name="dateAttr1"
    />
  );
}

function WorkCategoryNumberAttr2(props: Readonly<Props>) {
  return (
    <NumberAttr
      {...props}
      name="numberAttr2"
    />
  );
}

function WorkCategoryTextAttr2(props: Readonly<Props>) {
  return (
    <TextAttr
      {...props}
      name="textAttr2"
    />
  );
}

function WorkCategoryDateAttr2(props: Readonly<Props>) {
  return (
    <DateAttr
      {...props}
      name="dateAttr2"
    />
  );
}

function WorkCategoryNumberAttr3(props: Readonly<Props>) {
  return (
    <NumberAttr
      {...props}
      name="numberAttr3"
    />
  );
}

function WorkCategoryTextAttr3(props: Readonly<Props>) {
  return (
    <TextAttr
      {...props}
      name="textAttr3"
    />
  );
}

function WorkCategoryDateAttr3(props: Readonly<Props>) {
  return (
    <DateAttr
      {...props}
      name="dateAttr3"
    />
  );
}
