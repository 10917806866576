import { create } from 'zustand';

export { useState as usePersonalSettingsModalUISettingsHeaderSettingsState };

interface State {
  editOpenIdList: number[];
}

interface Actions {
  addEditOpenId: (id: number) => void;
  clearEditOpenIdList: () => void;
}

const useState = create<State & Actions>((set) => ({
  editOpenIdList: [],
  addEditOpenId: (id) =>
    set((state) => {
      if (state.editOpenIdList.includes(id)) {
        return { editOpenIdList: state.editOpenIdList.filter((originId) => originId !== id) };
      }
      return { editOpenIdList: [...state.editOpenIdList, id] };
    }),
  clearEditOpenIdList: () => set(() => ({ editOpenIdList: [] })),
}));
