import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyMeetingHistoryQuery } from '@front/src/features/affiliated-company/features/meeting-history/query/query';
import { affiliatedCompanyMeetingHistoryMutation } from '@front/src/features/affiliated-company/features/meeting-history/query/mutation';

export const affiliatedCompanyMeetingHistoryRepository = {
  useMeetingHistoryListGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyMeetingHistoryQuery.useMeetingHistoryListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useMeetingHistoryDelete: (menuId) => {
    const { mutate } = affiliatedCompanyMeetingHistoryMutation.useMeetingHistoryDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
