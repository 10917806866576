import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectSalesInfoAffiliatedCompanyWebHardParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/types/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/affiliated-company-web-hard`,
};

export const projectSalesInfoAffiliatedCompanyWebHardCreateModalApi = {
  create: async (params: ProjectSalesInfoAffiliatedCompanyWebHardParameter, menuId) => {
    await axios.post(url.create(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
