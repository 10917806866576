import { useQuery } from 'react-query';
import { AccountingSettingsTexAccountingAccountApi } from '@front/src/features/accounting/features/settings/features/tax-accounting-account/query/api';
import type {
  TaxAccountingCountView,
  TaxAccountingView,
} from '@front/src/features/accounting/features/settings/features/tax-accounting-account/types/view';

export { Query as AccountingSettingsTaxAccountingAccountQuery };

const Query = {
  useListGet: (menuId) => {
    const { data, isLoading } = useQuery<TaxAccountingView[]>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getAll(menuId),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'all'],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
  useCountGet: (menuId) => {
    const { data, isLoading } = useQuery<TaxAccountingCountView>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getCount(menuId),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'count'],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
  useCreatedAtGet: (menuId) => {
    const { data, isLoading } = useQuery<string>({
      queryFn: () => AccountingSettingsTexAccountingAccountApi.getCreatedAt(menuId),
      queryKey: ['accounting', 'settings', 'text-accounting-account', 'created-at'],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
