import React, { useContext, useMemo } from 'react';
import { AccountingUploadSalesInfoInfoText } from '@front/src/features/accounting/features/upload/features/sales-info/components/info-text';
import { AccountingUploadSalesInfoGetYearContext } from '@front/src/features/accounting/features/upload/features/sales-info/widgets/get-year/provider/context';
import { SalesAccountingAmountSubType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';

export { TargetIsConfirmed as AccountingUploadSalesInfoGetYearTargetIsConfirmed };

const TargetIsConfirmed = () => {
  const { list } = useContext(AccountingUploadSalesInfoGetYearContext);
  const isConfirmed = useMemo(
    () => list?.find((l) => l.subType === SalesAccountingAmountSubType.TARGET)?.isConfirmed,
    [list]
  );
  return (
    <AccountingUploadSalesInfoInfoText
      label="목표 확정여부"
      value={isConfirmed ? '예' : '아니오'}
      sx={{
        width: '40px',
      }}
    />
  );
};
