import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';
import type { WorkInterimReportDateView } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/view';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import useWorkScheduleInterimReportDateMutation from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/hooks/useMutation';
import useWorkScheduleInterimReportDateForm from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/hooks/useForm';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

interface Props extends SectionComponentProps {
  item: WorkInterimReportDateView;
  index: number;
  size: number;
}

export default function WorkInterimReportDateRow(props: Readonly<Props>) {
  const { item, index, size, readOnly, dataId, menuId, sectionId } = props;
  const { onUpdate, onCreate, onDelete } = useWorkScheduleInterimReportDateMutation({
    dataId,
    menuId,
    sectionId,
  });
  const { onSubmit, methods } = useWorkScheduleInterimReportDateForm({
    onUpdate,
    item,
  });
  const { confirm } = useCustomDialog();
  const onDeleteWithConfirm = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(item.id);
      },
    });
  };
  const renderButton = () => {
    if (readOnly) return <></>;
    const onClick = index === 0 ? (onCreate as unknown as () => void) : onDeleteWithConfirm;
    const icon = index === 0 ? 'plus' : 'minus';
    return (
      <ButtonIconUI
        shape="ghost"
        size="small"
        onClick={onClick}
        sx={{
          width: '26px',
          height: '26px',
          borderRadius: '5px',
          position: 'absolute',
          right: '-8px',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{
            width: '11px',
            height: '13px',
            fontSize: '12px',
            fontWeight: '900',
            lineHeight: '12px',
            textAlign: 'center',
          }}
        />
      </ButtonIconUI>
    );
  };
  const getLabel = () => {
    const label = '중간보고일';
    if (size === 1) {
      return label;
    }
    return `${label}${index + 1}`;
  };
  return (
    <FormProvider {...methods}>
      <TableRowUI>
        <TableCellUI
          isHead
          width="160px"
          sx={{
            position: 'relative',
          }}
        >
          {getLabel()}
          {renderButton()}
        </TableCellUI>
        <TableCellUI>
          <DatePickerWithHookForm
            name="date"
            onSubmit={onSubmit}
            readOnly={readOnly}
          />
        </TableCellUI>
        <TableCellUI>
          <UncontrolledInput
            name="description"
            readOnly={readOnly}
            onBlur={onSubmit}
            placeholder="중간 보고 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
          />
        </TableCellUI>
      </TableRowUI>
    </FormProvider>
  );
}
