import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import {ContractCmView} from "@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views";

const useContractCmForm = (item: ContractCmView) => {
  const methods = useForm<ContractCmView>({
    defaultValues: getFormValues(item),
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

const getFormValues = (item: ContractCmView) => ({
  id: item.id ?? null,
  paymentStage: item.paymentStage ?? null,
  contractCM: item.contractCM ?? null,
  cmRatioAgainstContractPrice: item.cmRatioAgainstContractPrice ?? null,
  cmPostDeduction: item.cmPostDeduction ?? null,
  cmDeductionRatio: item.cmDeductionRatio ?? null,
  cmMethod: item.cmMethod ?? null,
  cmRecipient: item.cmRecipient ?? null,
  cmPaymentTiming: item.cmPaymentTiming ?? null,
  cmExpectedPaymentDate: item.cmExpectedPaymentDate ?? null,
  noteCount: item.noteCount ?? null,
  textAttr1: item.textAttr1 ?? null,
  textAttr2: item.textAttr2 ?? null,
  textAttr3: item.textAttr3 ?? null,
  dateAttr1: item.dateAttr1 ?? null,
  dateAttr2: item.dateAttr2 ?? null,
  dateAttr3: item.dateAttr3 ?? null,
  numberAttr1: item.numberAttr1 ?? null,
  numberAttr2: item.numberAttr2 ?? null,
  numberAttr3: item.numberAttr3 ?? null,
});

export default useContractCmForm;
