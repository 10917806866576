import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';

interface State {
  h: {
    onCreate: (params: SmProjectCollectionParameter) => void;
  };
  d: {
    consortiumCompanyList?: ProjectContractEarningsConsortiumCompanyView[];
    confirmedContractDetail?: ProjectContractConfirmedView;
  };
  isLoading?: boolean;
}

export { Context as ProjectSalesInfoContractEarningsItemCreateModalContext };

const Context = createContext<State>({
  h: {
    onCreate: noOp,
  },
  d: {},
});
