import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/manage-collection-table.module.scss';
import { ProjectSalesInfoCollectionManagementTableRow } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/manage-collection-table-row';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';

export { ManageCollectionTable as ProjectSalesInfoCollectionManagementModalTable };

const ManageCollectionTable = () => {
  const collectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.collectionManagementList
  );
  const isListEmpty = collectionManagementList?.length === 0;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <Th className={classes.width__2}>{}</Th>
            <Th className={classes.width__2}>No.</Th>
            <Th className={classes.width__6}>미수금사유</Th>
            <Th className={classes.width__6}>고객예정일</Th>
            <Th className={classes.width__6}>청구일</Th>
            <Th className={classes.width__6}>한풍예측일</Th>
            <Th className={classes.width__6}>수금가능성</Th>
            <Th className={classes.width__6}>영업 담당자</Th>
            <Th className={classes.width__6}>관계사 담당자</Th>
            <Th className={classes.width__8}>코멘트</Th>
            <Th className={classes.width__8}>등록일</Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {isListEmpty && <NoResult />}
          {!isListEmpty &&
            collectionManagementList?.map((item, index) => (
              <ProjectSalesInfoCollectionManagementTableRow
                item={item as unknown as ProjectCollectionManagementParams}
                index={index}
                key={index}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NoResult = memo(() => (
  <TableRow>
    <OldTd colSpan={10}>저장된 수금정보가 없습니다.</OldTd>
  </TableRow>
));
