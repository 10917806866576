import { SortStatus } from '@front/src/types';

const useUIBuilderTableSort = ({ id, fieldArray, sortable }) => {
  const { append, remove, fields } = fieldArray;

  const handleClick = () => {
    if (!sortable) return;

    const findIndex = fields.findIndex((f) => f.id === id);

    if (findIndex === -1) {
      append({ id, sortStatus: SortStatus.ASC });
      return;
    }

    remove(findIndex);

    if (fields[findIndex].sortStatus === SortStatus.ASC) {
      append({ id, sortStatus: SortStatus.DESC });
    }
  };

  const findIndex = fields.findIndex((f) => f.id === id);

  const sortStatus = fieldArray.fields[findIndex]?.sortStatus;

  return {
    onSort: handleClick,
    findIndex,
    sortStatus,
  };
};

export default useUIBuilderTableSort;
