import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';

import type { ProjectCMPaymentHistoryParams } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getCMPaymentHistoryDetail: (itemId: number) =>
    `${projectSalesInfoURL}/cm-amount-payment-history/${itemId}`,
  updateCMPaymentHistory: (itemId: number) =>
    `${projectSalesInfoURL}/cm-amount-payment-history/${itemId}`,
};

export const projectSalesInfoCMPaymentHistoryUpdateModalApi = {
  getCMPaymentHistoryDetail: async (itemId: number, menuId) => {
    const { data } = await axios.get(url.getCMPaymentHistoryDetail(itemId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCMPaymentHistory: async (params: ProjectCMPaymentHistoryParams, menuId) =>
    await axios.put(url.updateCMPaymentHistory(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
};
