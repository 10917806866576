import React from 'react';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { Form as AffiliatedCompanyPersonCreateModalForm };

interface Props {
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const Form = ({ onKeyDown }: Props) => {
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <DataFieldWithLabel label="이름">
        <HookFormInput
          name="name"
          onKeyDown={onKeyDown}
          disabled={readOnly}
        />
      </DataFieldWithLabel>
      <DataFieldWithLabel label="직급">
        <HookFormInput
          name="position"
          onKeyDown={onKeyDown}
          disabled={readOnly}
        />
      </DataFieldWithLabel>
    </>
  );
};
