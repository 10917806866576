import React from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { convertProjectContribution, getValueIfExist, YNToStrConvertor } from '@front/src/utils';
import type { SmProjectBriefPersonView } from '@front/src/features/project-sales-info/features/abstract/features/affiliated-company/types/view';

export { Row as ProjectSalesInfoAbstractAffiliatedCompanyRow };

interface Props {
  item: SmProjectBriefPersonView;
}

const Row = ({ item }: Props) => (
  <TableRow>
    <OldTd>{getValueIfExist(item.category)}</OldTd>
    <OldTd>{getValueIfExist(item.companyName)}</OldTd>
    <OldTd>{getValueIfExist(item.personName)}</OldTd>
    <OldTd>{getValueIfExist(item.position)}</OldTd>
    <OldTd>{getValueIfExist(item.department)}</OldTd>
    <OldTd>{YNToStrConvertor(item.isKeyPerson)}</OldTd>
    <OldTd>{convertProjectContribution(item.contribution)}</OldTd>
  </TableRow>
);
