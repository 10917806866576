import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { UIBuilderTableFilterItem } from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useUIBuilderFilterForm from '@front/src/components/ui-builder/filter/hooks/useForm';
import UIBuilderFilterItem from '@front/src/components/ui-builder/filter/FilterItem';

interface Props extends UiBuilderTableProps {
  headerId: number;
  bundleIndex: number;
  onFilterChange: (newFilter: UIBuilderTableFilterItem[], active: boolean) => void;
  onClose: () => void;
}

export default function UIBuilderFilter({ bundleIndex, onFilterChange, onClose }: Readonly<Props>) {
  const { methods, onSubmit, onKeyDown, fields } = useUIBuilderFilterForm({
    bundleIndex,
    onFilterChange,
  });

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          '& .Mui-disabled': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
          }}
        >
          <ButtonBasicUI
            shape="tertiary"
            size="medium"
            onClick={onClose}
            sx={{
              width: '64px',
            }}
          >
            취소
          </ButtonBasicUI>
          <ButtonBasicUI
            shape="primary2"
            size="medium"
            onClick={onSubmit}
            sx={{
              width: '64px',
            }}
          >
            확인
          </ButtonBasicUI>
        </Box>
        <UncontrolledInput
          name="keyword"
          onKeyDown={onKeyDown}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {fields.map((f, index) => (
            <UIBuilderFilterItem
              key={f.id}
              index={index}
            />
          ))}
        </Box>
      </Box>
    </FormProvider>
  );
}
