import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkReferenceTableBody from '@front/src/features/work/features/work/features/reference/components/TableBody';
import useWorkReferenceGetIsLoading from '@front/src/features/work/features/work/features/reference/hooks/useGetIsLoading';
import workReferenceQuery from '@front/src/features/work/features/work/features/reference/query/query';
import WorkReferenceTableHead from '@front/src/features/work/features/work/features/reference/components/TableHead';

export default function WorkReferenceTable(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const isLoading = useWorkReferenceGetIsLoading();

  const { data: list } = workReferenceQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkReferenceTableHead}
      BodyComponent={WorkReferenceTableBody}
      list={list}
    />
  );
}
