import {useQuery} from 'react-query';
import {ImageDrawHistoryView} from "@front/src/features/image-draw/types/view";
import {drawingApi} from "@front/src/features/image-draw/api/api";

const imageDrawHistoryQuery = {
  useListByFileId: (imageFileId: number, menuId?: number) => {
    const {data, isLoading} = useQuery<ImageDrawHistoryView[]>({
      queryKey: ['image', 'draw', 'list', imageFileId],
      queryFn: () => drawingApi.getDrawingList(imageFileId, menuId),
      suspense: true,
      enabled: !!imageFileId,
    });

    return {
      isLoading,
      listData: data,
    }
  },
  useGetOne: (drawingId: number, menuId?: number) => {
    const { data } = useQuery({
      queryKey: ['image', 'draw', 'item'],
      queryFn: () => drawingApi.getDrawing(drawingId, menuId),
      suspense: true,
      enabled: !!drawingId,
    });
    return {
      data,
    };
  },
  useCountByFileId: (imageFileId: number, menuId?: number) => {
    const {data} = useQuery({
      queryKey: ['image', 'draw', 'count', imageFileId],
      queryFn: () => drawingApi.count(imageFileId, menuId),
      suspense: true,
      enabled: !!imageFileId,
    });
    return {
      data,
    }
  },
  useFileItemByFileKey: (fileKey: string | undefined, menuId?: number) => {
    if (!fileKey) return {data: undefined, isLoading: false};
    const {data, isLoading} = useQuery({
      queryKey: ['image', 'draw', 'item', fileKey],
      queryFn: () => drawingApi.getFileItemDataByFileKey(fileKey!, menuId),
      suspense: true,
      enabled: !!fileKey,
    });
    return {
      data,
      isLoading
    }
  }
};

export default imageDrawHistoryQuery;