import { projectSalesInfoCMPaymentHistoryUpdateModalRepository } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/repository';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import {
  CMPaymentMethodKeyword,
  CMPaymentStatusKeyword,
} from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/utils/constants';

export { useLogic as useCMPaymentHistoryUpdateModalLogic };
const useLogic = (menuId) => {
  const itemId = useProjectSalesInfoCMPaymentHistoryState((state) => state.itemId);
  const { data: CMPaymentHistoryDetail, isLoading } =
    projectSalesInfoCMPaymentHistoryUpdateModalRepository.useCMPaymentHistoryDetailGet(
      itemId!,
      menuId
    );
  const { run: onUpdate } =
    projectSalesInfoCMPaymentHistoryUpdateModalRepository.useCMPaymentHistoryUpdate(menuId);
  const { data: CMPaymentMethodOptionList } = adminManageableVariableRepository.useListGet(
    CMPaymentMethodKeyword,
    menuId
  );
  const { data: CMPaymentStatusOptionList } = adminManageableVariableRepository.useListGet(
    CMPaymentStatusKeyword,
    menuId
  );
  return {
    d: {
      CMPaymentHistoryDetail,
      CMPaymentMethodOptionList,
      CMPaymentStatusOptionList,
    },
    h: {
      onUpdate,
    },
    isLoading,
  };
};
