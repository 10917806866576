import { useQuery } from 'react-query';
import type { ProjectContractReviewHistoryView } from '@front/src/features/project-sales-info/features/contract/features/review-history/types/view';
import { projectSalesInfoContractReviewHistoryApi } from '@front/src/features/project-sales-info/features/contract/features/review-history/query/api';

export { query as projectSalesInfoContractReviewHistoryQuery };

const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectContractReviewHistoryView[]>({
      queryFn: () => projectSalesInfoContractReviewHistoryApi.getList(id, menuId),
      queryKey: ['project', 'sales-info', 'contract', 'contract-review-history', 'list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
