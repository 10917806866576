import axios from 'axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { ProjectSalesInfoBidResultParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getBidResultDetail: (id: number) => `${bidURLAPI}/bid-result/${id}`,
  updateBidResult: (id: number) => `${bidURLAPI}/bid-result/${id}`,
};

export const projectSalesInfoBidResultUpdateModalApi = {
  getBidResultDetail: async (id: number, menuId) => {
    const { data } = await axios.get(url.getBidResultDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateBidResult: async (params: ProjectSalesInfoBidResultParams, menuId) => {
    await axios.put(url.updateBidResult(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
