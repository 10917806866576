import { accountingUploadMutation } from '@front/src/features/accounting/features/upload/features/ecount-data/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { accountingUploadQuery } from '@front/src/features/accounting/features/upload/features/ecount-data/query/query';

export { Repository as accountingUploadRepository };

const Repository = {
  useCreateTaxAccounting: (menuId) => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateTaxAccounting(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useCreateBankAccount: (menuId) => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateBankAccount(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useCreateDailyReport: (menuId) => {
    const { mutate, isLoading } = accountingUploadMutation.useCreateDailyReport(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
      isLoading,
    };
  },
  useGetList: (menuId) => {
    const { data, isLoading } = accountingUploadQuery.useGetList(menuId);
    return {
      data,
      isLoading,
    };
  },
};
