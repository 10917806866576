import type { CollectionIdDeleteParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';
import type { SmProjectCollectionView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import type { ProjectContractEarningsNameParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/types/parameter';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

interface State {
  h: {
    onDelete: (id: number) => void;
    onDeleteDetails: (params: CollectionIdDeleteParams) => void;
    onUpdate: (params: ProjectContractEarningsNameParams) => void;
  };
  d: {
    contractEarningsItemList?: SmProjectCollectionView[];
    collectionPossibilityOptionList?: VariableInformationView[];
    affiliatedCompanyPersonList?: ProjectAffiliatedCompanyPersonView[];
    bondClassificationOptionList?: VariableInformationView[];
  };
  isLoading: boolean;
  itemId?: number;
  name?: string;
  idList: number[];
  setIdList: (idList: number[]) => void;
}

export { Context as ProjectSalesInfoContractEarningsItemContext };

const Context = createContext<State>({
  h: {
    onDelete: noOp,
    onDeleteDetails: noOp,
    onUpdate: noOp,
  },
  d: {},
  isLoading: false,
  idList: [],
  setIdList: noOp,
});
