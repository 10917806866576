// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q1Wl5xLEjNrKAr2HnHNv{display:flex;flex-direction:column;row-gap:16px;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/meeting-history/features/modal/components/form.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,UAAA","sourcesContent":[".section {\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 16px;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `q1Wl5xLEjNrKAr2HnHNv`
};
export default ___CSS_LOADER_EXPORT___;
