import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import { adminHeaderSettingsApi } from '@front/src/features/admin/features/header-settings/api/api';

export { query as personalSettingsModalUISettingsHeaderSettingsQuery };

const query = {
  useGetHeaderList: (id?: number, authMenuId?) => {
    const { data } = useQuery({
      queryKey: ['personal-settings-modal', 'ui-settings', 'header-settings', 'section', id],
      queryFn: () =>
        commonApi.getHeaderList(
          id,
          {
            position: 'table',
            isIncludedBlindItem: true,
            from: 'modal',
          },
          authMenuId
        ),
      enabled: !!id && !!authMenuId,
    });
    return {
      data,
    };
  },
  useGetLink: (params, authMenuId) => {
    const { data } = useQuery({
      queryKey: [
        'personal-settings-modal',
        'ui-settings',
        'header-settings',
        'section',
        params,
        'link',
      ],
      queryFn: () => adminHeaderSettingsApi.getLink(params, authMenuId),
      enabled: !!params.targetId && !!authMenuId,
    });
    return {
      data,
    };
  },

  useGetHeaderBySectionId: (sectionId: number, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['personal-settings-modal', 'ui-settings', 'header-settings', sectionId],
      queryFn: () => adminHeaderSettingsApi.getHeaderListBySectionId(sectionId, authMenuId),
      enabled: !!sectionId && !!authMenuId,
    });
    return {
      data,
    };
  },
};
