import React, { useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useAffiliatedPersonAppBarState } from '@front/src/features/affiliated-person/features/app-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { defaultAffiliatedPersonCreateForm } from '@front/src/features/affiliated-person/features/app-bar/utils';
import { AffiliatedPersonAppBarCompanySelectModalFeature } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal';
import { useSnackbar } from '@front/src/features/snackbar';

export { CreateModalForm as AffiliatedPersonCreateModalForm };

interface Props {
  menuId?: number;
}
const CreateModalForm = ({ menuId }: Props) => {
  const {
    setIsCreateModalOpen,
    setIsSearchModalOpen,
    isSearchModalOpen,
    setCreateParameter,
    createParameter,
    setIsConfirmModalOpen,
  } = useAffiliatedPersonAppBarState(
    useShallow((state) => ({
      setIsCreateModalOpen: state.setIsCreateModalOpen,
      setIsSearchModalOpen: state.setIsSearchModalOpen,
      isSearchModalOpen: state.isSearchModalOpen,
      setCreateParameter: state.setCreateParameter,
      createParameter: state.createParameter,
      setIsConfirmModalOpen: state.setIsConfirmModalOpen,
    }))
  );
  const methods = useForm({
    defaultValues: defaultAffiliatedPersonCreateForm,
  });
  const { control, setValue } = methods;
  const name = useWatch({ name: 'personName', control });
  const handleInputBlur = useCallback(() => {
    setCreateParameter({ ...createParameter, personName: name });
  }, [setCreateParameter, name, createParameter]);
  const handleInputKeyDown = useCallback(
    (e) => {
      const { isComposing, key, keyCode } = e;
      if (isComposing || keyCode === 229) return;
      if (key !== 'Enter') return;
      setCreateParameter({ ...createParameter, personName: name });
    },
    [setCreateParameter, name, createParameter]
  );
  const handleAddClick = useCallback(() => {
    setIsSearchModalOpen(true);
  }, [setIsSearchModalOpen]);
  const handleCancel = useCallback(() => {
    setIsCreateModalOpen(false);
  }, [setIsCreateModalOpen]);
  const handleCompanySelect = useCallback(
    (company) => {
      setValue('affiliatedCompany', company);
      setCreateParameter({ ...createParameter, affiliatedCompany: company });
    },
    [setValue, setCreateParameter, createParameter]
  );
  const { show } = useSnackbar();
  const handleCreateClick = useCallback(() => {
    if (!name) {
      show({
        message: '이름을 입력해주세요.',
      });
      return;
    }
    setIsConfirmModalOpen(true);
  }, [setIsConfirmModalOpen, name, show]);
  return (
    <FormProvider {...methods}>
      <AffiliatedPersonAppBarCompanySelectModalFeature
        onCompanySelect={handleCompanySelect}
        setIsOpen={setIsSearchModalOpen}
        isOpen={isSearchModalOpen}
        menuId={menuId}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '600px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Box
              sx={{
                width: '52px',
                fontSize: '12px',
              }}
            >
              이름
            </Box>
            <HookFormInput
              width="280px"
              name="personName"
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Box
              sx={{
                width: '52px',
                fontSize: '12px',
              }}
            >
              소속회사
            </Box>
            <HookFormInput
              readOnly
              width="280px"
              name="affiliatedCompany.name"
            />
            <Button onClick={handleAddClick}>추가</Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '12px',
            width: '100%',
          }}
        >
          <Button
            shape="basic2"
            onClick={handleCancel}
          >
            취소
          </Button>
          <Button onClick={handleCreateClick}>등록</Button>
        </Box>
      </Box>
    </FormProvider>
  );
};
