import { ProjectSalesInfoAbstractContractQuery } from '@front/src/features/project-sales-info/features/abstract/features/contract/query/query';

export { repository as ProjectSalesInfoAbstractContractRepository };

const repository = {
  useDetailGet: (id?, menuId?) => {
    const { data } = ProjectSalesInfoAbstractContractQuery.useDetailGet(id, menuId);
    return {
      data,
    };
  },
};
