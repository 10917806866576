import { accountingURL } from '@front/src/features/accounting/utils/constant';
import { range } from '@front/util/ArrayUtil';

type ConstantType = {
  URL: string,
  queryKey: {
    list: string[],
    update: string[],
  }
  SummaryColumnKey: number[],
  DetailColumnKey: number[]
}

const AccountStatusConstant: ConstantType = {
  URL: `${accountingURL}/bank-account`,
  queryKey: {
    list: ['accounting', 'bank-account', 'status', 'list'],
    update: ['accounting', 'bank-account', 'status', 'update'],
  },
  SummaryColumnKey: Array.from(range(314, 327)),
  DetailColumnKey: Array.from(range(327, 342)),
};

export default AccountStatusConstant;