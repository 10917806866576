import { useQuery } from 'react-query';
import { affiliatedCompanyCharacterCharacterApi } from '@front/src/features/affiliated-company/features/character/features/character/query/api';
import type { AffiliatedCompanyCharacterCharacterView } from '@front/src/features/affiliated-company/features/character/features/character/types/view';

export const affiliatedCompanyCharacterCharacterQuery = {
  useCharacterCharacterGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyCharacterCharacterView[]>({
      queryKey: ['affiliated-company', 'character', 'character', id],
      queryFn: () => affiliatedCompanyCharacterCharacterApi.getCharacterCharacter(id, menuId),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
