import React, { useMemo, useState } from 'react';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';
import ContentsWrapUI from '@front/src/components/components-with-design/layout/ContentsWrapUI';
import { Outlet } from 'react-router-dom';
import ProjectSalesAsideFeature from '@front/src/features/project-sales/features/aside';
import type { CommonFeatureProps } from '@front/src/types/domain';
import DefaultErrorBoundary from '@front/src/components/error/DefaultErrorBoundary';
import { projectSalesPath } from '@front/src/features/project-sales/routes';
import { findNestedObject } from '@front/src/utils';
import useNoteMenuId from '@front/src/hooks/useNoteMenuId';

export interface ProjectSalesCommonFeatureProps extends CommonFeatureProps {
  featureRootMenuId: number | undefined;
}

export default function ProjectSalesFeature() {
  removeDeprecatedMenuIdValueAtSessionStorage();

  const { menuId, tabList, menuList } = useSetMenuAndTab();
  useNoteMenuId(menuId);
  if (!menuId || !tabList) {
    console.info(`No menuId found. Cannot serve requested feature.`);
    return <>Unauthorized</>;
  }
  const featureRootMenuId = useMemo(() => {
    const featureRootMenu = menuList
      ? findNestedObject(menuList, 'path', projectSalesPath)
      : undefined;
    return featureRootMenu ? featureRootMenu.id : undefined;
  }, [menuList]);

  const [commonFeatureProps] = useState<ProjectSalesCommonFeatureProps>({
    featureRootMenuId,
    menuId,
    tabList,
  });

  return (
    <>
      <ProjectSalesAsideFeature menuId={featureRootMenuId} />
      <ContentsWrapUI shape="aside">
        <DefaultErrorBoundary to={projectSalesPath}>
          <Outlet context={commonFeatureProps} />
        </DefaultErrorBoundary>
      </ContentsWrapUI>
    </>
  );
}

/**
 * @remarks legacy code에서 사용하는 menuId를 신규 feature에서 사용하는 문제를 해결하기 위해 임시로 사용하는 로직
 */
function removeDeprecatedMenuIdValueAtSessionStorage() {
  sessionStorage.removeItem('menuId');
  // sessionStorage.removeItem('noteAuth');
}
