import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { useProjectSalesInfoEstimationEstimationUpdateModalState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/widgets/useState';
import { projectSalesInfoEstimationEstimationUpdateModalRepository } from '@front/src/features/project-sales-info/features/estimation/features/estimation/features/update-modal/repository';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoEstimationCategory } from '@front/src/features/project-sales-info/features/estimation/features/estimation/utils/constant';
import { projectSalesInfoSubjectReviewExperimentRepository } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoEstimationEstimationUpdateModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    isOpen,
    setIsOpen,
    id: modalId,
  } = useProjectSalesInfoEstimationEstimationUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
    }),
    shallow
  );
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoEstimationEstimationUpdateModalRepository.useDetailGet(modalId, menuId);
  const { run: onUpdate, setCallback } =
    projectSalesInfoEstimationEstimationUpdateModalRepository.useUpdate(menuId);
  const { data: experiment, isLoading: isExperimentLoading } =
    projectSalesInfoSubjectReviewExperimentRepository.useDetailGet(id, menuId);
  const { data: categoryList, isLoading: isCategoryLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoEstimationCategory, menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const d = { detail, categoryList, experiment };
  const h = { onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isLoading: isDetailLoading || isCategoryLoading || isExperimentLoading,
    isOpen,
  };
};
