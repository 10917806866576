import React, { memo } from 'react';

import TextBox from '@front/layouts/Text';
import type { UserId } from '@front/user/domain';
import type { OstStatus } from '@front/ost/domain';
import { ArticleItem } from '@front/src/components/article-item';
import type { CampaignId, CampaignVO } from '@front/ost_campaign/domain';
import OstCampaignListTable from '@front/ost/view/detail/CampaignListTable';
import ModalAddCampaignService from '@front/ost_campaign/service/modal/addCampaignService';

interface Props {
  sumAllocated: number;
  campaignList?: CampaignVO[];
  status?: OstStatus;
  totalBudget?: number;
  onChange: (id: CampaignId) => (value: UserId | UserId[] | undefined) => void;
}

const OstBudgetStatusView = ({
  campaignList,
  totalBudget,
  onChange,
  status,
  sumAllocated,
}: Props) => (
  <ArticleItem
    title={
      <TextBox
        variant={'heading1'}
        sx={{
          width: '13%',
          marginBottom: '10px',
        }}
      >
        예산 집행 현황
      </TextBox>
    }
    buttonsNode={<ModalAddCampaignService status={status} />}
  >
    <OstCampaignListTable
      sumAllocated={sumAllocated}
      campaignList={campaignList}
      totalBudget={totalBudget}
      onChange={onChange}
    />
  </ArticleItem>
);

const OstBudgetStatus = memo(OstBudgetStatusView);

export default OstBudgetStatus;
