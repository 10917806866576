import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkScheduleParams } from '@front/src/features/work/features/work/features/schedule/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workScheduleMutation = {
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkScheduleParams) => workWorkApi.updateSchedule(params, commonParams),
      mutationKey: ['work', 'schedule', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'schedule'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workScheduleMutation;
