import { useQuery } from 'react-query';
import { projectSalesInfoContractHistoryApi } from '@front/src/features/project-sales-info/features/contract/features/history/query/api';
import type { ProjectContractHistoryView } from '@front/src/features/project-sales-info/features/contract/features/history/types/view';

export { query as projectSalesInfoContractHistoryQuery };

const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectContractHistoryView[]>({
      queryFn: () => projectSalesInfoContractHistoryApi.getList(id, menuId),
      queryKey: ['project', 'sales-info', 'contract', 'contract-history', 'list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
