import React from "react";
import {
  faBrush,
  faClockRotateLeft, faDownload,
  faEraser,
  faExpand,
  faFloppyDisk,
  faFont,
  faRedo,
  faUndo,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faHand} from "@fortawesome/free-regular-svg-icons";

interface ToolbarProps {
  selectable: boolean,
  drawable: boolean,
  brushColor: string,
  brushSize: number,
  editable: boolean,
  setEditable: (editable: boolean) => void,
  undoable: boolean,
  redoable: boolean,
  actingDrawing: number | null,
  cancelActingDrawing: () => void,
  isModified: boolean,
  onReset: () => void,
  onUndo: () => void,
  onRedo: () => void,
  onFullScreen: () => void,
  onHistory: () => void,
  onSelecting: () => void,
  onDrawing: () => void,
  onTexting: () => void,
  onColorChange: (color: string) => void,
  onBrushSizeChange: (size: number) => void,
  onSave: () => void,
  drawingCount: number | undefined,
  onDownload: () => void
}

export const COLOR_SWATCH = [
  '#FF3434','#FF7600','#FFDA30',
  '#77DD00','#5050FF','#02C0FF',
  '#B7B9E2','#F4B183',
  '#000000','#AAAAAA','#EEEEEE',
];

export const BRUSH_SIZE = [5, 10, 15, 20];

export function Toolbar(props: ToolbarProps) {
  const {
    selectable,
    drawable,
    brushColor,
    brushSize,
    editable,
    setEditable,
    undoable = false,
    redoable = false,
    actingDrawing,
    cancelActingDrawing,
    isModified,
    onReset,
    onUndo,
    onRedo,
    onFullScreen,
    onHistory,
    onSelecting,
    onDrawing,
    onTexting,
    onColorChange,
    onBrushSizeChange,
    onSave,
    drawingCount,
    onDownload
  } = props;

  return (
    <div className="image-canvas-toolbar">
      {editable && (
        <>
          <div className='action-container'>
            <div className='group'>
              <ActionButton text='초기화' icon={faEraser} onClick={onReset}/>
            </div>
            <div className='group'>
              <ActionButton text='되돌리기' icon={faUndo} onClick={onUndo} disabled={!undoable}/>
              <ActionButton text='다시실행' icon={faRedo} onClick={onRedo} disabled={!redoable}/>
            </div>
            <div className='group'>
              <ActionButton text='전체모드' icon={faExpand} onClick={onFullScreen}/>
              <ActionButton text='다운로드' icon={faDownload} onClick={onDownload}/>
            </div>
            <div className='group'>
              <ActionButton
                text='선택' icon={faHand} onClick={onSelecting}
                className={selectable ? 'active' : ''}
              />
              <ActionButton
                text='그리기' icon={faBrush} onClick={onDrawing}
                className={drawable ? 'active' : ''}
              />
              <ActionButton
                text='텍스트' icon={faFont} onClick={onTexting}/>
            </div>
          </div>
          <div className='color-swatch'>
            <div className='label'>색상</div>
            {COLOR_SWATCH.map((color) =>
              <ColorSample
                selected={brushColor === color}
                color={color}
                key={color}
                onClick={onColorChange}
              />
            )}
          </div>
          {drawable &&
            <>
              <div className='label'>굵기</div>
              <div className='stick-container'>
                <div className='brush-swatch'>
                  {BRUSH_SIZE.map((size) =>
                    <BrushSample
                      selected={brushSize === size}
                      size={size}
                      key={size}
                      onClick={onBrushSizeChange}
                      brushColor={brushColor}
                    />
                  )}
                </div>
                <div className='stick'/>
              </div>
            </>}
          <div className='action-container pinned-right'>
            <div className='group'>
              <ActionButton
                text={`첨삭이력(${drawingCount || 0})`} icon={faClockRotateLeft} onClick={onHistory}/>

              <ActionButton
                disabled={!actingDrawing && !isModified}
                text='수정취소' icon={faXmark} onClick={cancelActingDrawing}/>

              <ActionButton
                disabled={!isModified}
                text={actingDrawing ? '수정저장' : '저장'} icon={faFloppyDisk} onClick={onSave}/>
            </div>
          </div>
        </>
      )}
      {!editable && (
        <>
          <div className='action-container'>
            <ActionButton text='전체모드' icon={faExpand} onClick={onFullScreen}/>
          </div>
          <div className='action-container'>
            <ActionButton text='다운로드' icon={faDownload} onClick={onDownload}/>
          </div>
          <div className='action-container pinned-right'>
            <div className='group'>
              <ActionButton text={`첨삭이력(${drawingCount || 0})`} icon={faClockRotateLeft} onClick={onHistory}/>
              <ActionButton text='첨삭추가' icon={faBrush} onClick={() => {
                setEditable(!editable);
              }}/>
            </div>
          </div>
        </>
      )}
    </div>);
}


interface ActionButtonProps {
  onClick: () => void;
  text: string;
  icon: IconProp;
  className?: string,
  disabled?: boolean
}

function ActionButton(props: ActionButtonProps){
  return (
    <button
      onClick={props.onClick}
      className={props.className? props.className : ''}
      disabled={props.disabled}
    >
      <FontAwesomeIcon icon={props.icon}/>
      <span>{props.text}</span>
    </button>
  );
}

interface ColorSampleProps {
  selected: boolean,
  color: string,
  onClick: (color: string) => void,
}

function ColorSample(props: ColorSampleProps) {
  return <button
    className={`color-sample ${props.selected? 'active' : ''}`}
    style={{backgroundColor: props.color}}
    onClick={()=>{
      props.onClick(props.color);
    }}
  />;
}

interface BrushSampleProps {
  selected: boolean,
  size: number,
  onClick: (size: number) => void,
  brushColor: string
}

function BrushSample(props: BrushSampleProps) {
  const maxSize = BRUSH_SIZE[BRUSH_SIZE.length - 1] * 1.2;
  return <button
    className={`brush-sample ${props.selected ? 'active' : ''}`}
    style={{width: `${maxSize}px`}}
    onClick={() => {
      props.onClick(props.size);
    }}>
    <div
      style={{
        backgroundColor: props.selected ? props.brushColor : '',
        width: `${props.size / maxSize * 100}%`,
        height: `${props.size / maxSize * 100}%`,
      }}
    />
  </button>;
}
