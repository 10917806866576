import { useMutation, useQueryClient } from 'react-query';
import accountingApi from '@front/src/features/accounting/api/api';
import type { ManagementAccountingParameter } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/parameter';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';

const managementAccountMutation = {
  useCreate: (commonParams, onSuccess) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, ManagementAccountingParameter>({
      mutationFn: (params) => accountingApi.createManagementAccount(params, commonParams),
      mutationKey: ['accounting', 'account-settings', 'management-account', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'account-settings', 'management-account'],
        });
      },
      onSuccess,
    });

    return mutate;
  },

  useInsert: (commonParams, onSuccess) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<number, unknown, ManagementAccountingParameter>({
      mutationFn: (params) => accountingApi.insertManagementAccount(params, commonParams),
      mutationKey: ['accounting', 'account-settings', 'management-account', 'insert'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'account-settings', 'management-account'],
        });
      },
      onSuccess,
    });
    return mutate;
  },

  useUpdate: (commonParams, onSuccess) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<
      ManagementAccountingTreeView,
      unknown,
      ManagementAccountingParameter
    >({
      mutationFn: (params) => accountingApi.updateManagementAccount(params, commonParams),
      mutationKey: ['accounting', 'account-settings', 'management-account', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'account-settings', 'management-account'],
        });
      },
      onSuccess,
    });
    return mutate;
  },

  useDelete: (commonParams, onSuccess) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, number>({
      mutationFn: (id) => accountingApi.deleteManagementAccount(id, commonParams),
      mutationKey: ['accounting', 'account-settings', 'management-account', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['accounting', 'account-settings', 'management-account'],
        });
      },
      onSuccess,
    });
    return mutate;
  },
};

export default managementAccountMutation;
