import ModalUI, { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';
import { Box } from '@mui/material';
import React, { Suspense } from 'react';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import ClientTabs from '@front/src/features/project-sales/features/tabs/contract/components/client-tabs';
import { ClientShortView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

interface ModalWithClientTabsProps {
  name: string;
  isOpen: boolean;
  onClose: () => void;
  defaultClientId: number;
  clients: ClientShortView[];
  onTabClick: (clientId: number) => void;
  children: React.ReactNode;
}

export default function ModalWithClientTabs(props: ModalWithClientTabsProps) {
  const { name, isOpen, onClose, defaultClientId, clients, onTabClick, children } = props;
  return (
    <ModalUI
      title={name}
      open={isOpen}
      onClose={onClose}
    >
      <ModalBodyUI
        sx={{
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden',
          backgroundColor: '#fff',
          minWidth: '70dvw',
          minHeight: '50dvh',
          padding: 0,
        }}
      >
        <ClientTabs
          id={defaultClientId}
          clients={clients}
          onClientSelected={onTabClick}
        />
        <Box sx={{ padding: '1.5rem' }}>
          <Suspense fallback={<LoadingSpinnerUI />}>{children}</Suspense>
        </Box>
      </ModalBodyUI>
    </ModalUI>
  );
}
