import { useProjectSalesInfoActivityDeleteButtonLogic } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/provider/useLogic';
import { ProjectSalesInfoActivityDeleteButtonContext } from '@front/src/features/project-sales-info/features/activity/widgets/delete-button/provider/context';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

export { Provider as ProjectSalesInfoActivityDeleteButtonProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onDelete } = useProjectSalesInfoActivityDeleteButtonLogic(menuId);
  const value = useMemo(() => ({ onDelete }), [onDelete]);
  return (
    <ProjectSalesInfoActivityDeleteButtonContext.Provider value={value}>
      {children}
    </ProjectSalesInfoActivityDeleteButtonContext.Provider>
  );
};
