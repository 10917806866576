import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyPrimaryBusinessApi } from '@front/src/features/affiliated-company/features/outline/features/primary-business/query/api';
import type { AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/parameter';

export const affiliatedCompanyPrimaryBusinessMutation = {
  useOutlinePrimaryBusinessCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        affiliatedCompanyPrimaryBusinessApi.createOutlinePrimaryBusiness(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'primary-business']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useOutlinePrimaryBusinessUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter) =>
        affiliatedCompanyPrimaryBusinessApi.updateOutlinePrimaryBusiness(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'primary-business']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useOutlinePrimaryBusinessDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPrimaryBusinessApi.deleteOutlinePrimaryBusiness(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['affiliated-company', 'primary-business']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
