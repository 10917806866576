import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getProjectInfo: (id) => `${projectSalesInfoURL}/${id}/brief/project-info`,
  getAffiliatedCompanyList: (id) => `${projectSalesInfoURL}/${id}/brief/person-list`,
  getEstimation: (id) => `${projectSalesInfoURL}/${id}/brief/estimation`,
  getContract: (id) => `${projectSalesInfoURL}/${id}/brief/contract`,
  getCollectionList: (id) => `${projectSalesInfoURL}/${id}/brief/collection-list`,
};

export { api as projectSalesInfoAbstractApi };
const api = {
  getProjectInfo: async (id, menuId) => {
    const { data } = await axios.get(url.getProjectInfo(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getAffiliatedCompanyList: async (id, menuId) => {
    const { data } = await axios.get(url.getAffiliatedCompanyList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getEstimation: async (id, menuId) => {
    const { data } = await axios.get(url.getEstimation(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getContract: async (id, menuId) => {
    const { data } = await axios.get(url.getContract(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getCollectionList: async (id, menuId) => {
    const { data } = await axios.get(url.getCollectionList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
