import React from 'react';
import workCarouselBundleQuery from '@front/src/features/work/features/work/features/carousel-bundle/query/query';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import RadioButtonUI from '@front/src/components/components-with-design/component/RadioButtonUI';
import { useController, useFormContext } from 'react-hook-form';

export default function WorkCarouselMoveSlideTableBody(props: UIBuilderParameter) {
  const list = workCarouselBundleQuery.useGetList(props);
  const { control } = useFormContext();

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'workCarouselBundleId',
    rules: {
      required: '이미지 슬라이드를 선택해주세요.',
    },
  });

  const getChecked = (id) => value === id;

  const handleChange = (id) => {
    onChange(id);
  };

  return (
    <TableBodyUI>
      {list?.map((l) => (
        <TableRowUI key={l.id}>
          <TableCellUI>
            <RadioButtonUI
              checked={getChecked(l.id)}
              onChange={() => handleChange(l.id)}
            />
          </TableCellUI>
          <TableCellUI>{l.name}</TableCellUI>
        </TableRowUI>
      ))}
    </TableBodyUI>
  );
}
