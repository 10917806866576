import {
  accessAuthSettingsDepartmentUrl,
  accessAuthSettingsUserUrl,
} from '@front/src/features/admin/utils/constant';
import type {
  AuthSearchParams,
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import type { AuthPersonalSettingsModalCUDParams } from '@front/src/features/personal-settings-modal/features/auth-settings/types/parameter';
import { apiAminSectionRequestUrl, getMenuIdHeaders } from '@front/src/utils';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { HeaderHistoryView } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/types/view';
import type { HeaderSettingUpdateParameter } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/types/parameter';
import type { HeaderSettingsConvertToModalHeaderView } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/types/view';

const url = {
  getUserList: (id: number) => `${accessAuthSettingsUserUrl}/menu/${id}`,
  cudUserList: (id: number) => `${accessAuthSettingsUserUrl}/menu/${id}`,
  getDeptList: (id: number) => `${accessAuthSettingsDepartmentUrl}/menu/${id}`,
  updateDeptList: () => `${accessAuthSettingsDepartmentUrl}`,
  updateHeader: () => `${apiAminSectionRequestUrl}/header`,
  getHeaderHistoryList: (id: number) => `${apiAminSectionRequestUrl}/header/${id}/history`,
  getModalHeaderList: (sectionId) => `${apiAminSectionRequestUrl}/${sectionId}/modal-header`,
  updateForModalSettings: () => `${apiAminSectionRequestUrl}/modal-header`,
};

export const personalSettingsModalApi = {
  getUserList: async (menuId: number, params: AuthUserSearchParams, authMenuId) =>
    await axios.get(url.getUserList(menuId), { params, headers: getMenuIdHeaders(authMenuId) }),
  cudUserList: async (menuId: number, params: AuthPersonalSettingsModalCUDParams, authMenuId) =>
    await axios.patch(url.cudUserList(menuId), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getDeptList: async (menuId: number, params: AuthSearchParams, authMenuId) =>
    await axios.get(url.getDeptList(menuId), { params, headers: getMenuIdHeaders(authMenuId) }),
  updateDeptList: async (params: AuthUpdateParams, authMenuId) =>
    await axios.patch(url.updateDeptList(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateHeader: async <T = any, R = HeaderSettingUpdateParameter>(params: R, authMenuId) =>
    await axios.patch<T, AxiosResponse<T>, R>(url.updateHeader(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHeaderHistoryList: async <T = HeaderHistoryView[]>(id, authMenuId) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderHistoryList(id), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getModalHeaderList: async <T = HeaderSettingsConvertToModalHeaderView[]>(
    sectionId,
    authMenuId
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getModalHeaderList(sectionId), {
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  updateForModalSettings: async <T = any, R = any>(params, authMenuId) =>
    await axios.patch<T, AxiosResponse<T>, R>(url.updateForModalSettings(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
};
