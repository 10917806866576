import React from 'react';
import { ProjectSalesInfoContractEarningsItemManageCollectionModalWidget } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/widgets/widget';
import { useProjectSalesInfoContractEarningsItemCreateModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/widgets/useState';

export { ModalWrapper as ProjectSalesInfoCollectionManagementModalWrapper };
interface Props {
  menuId?: number;
}
const ModalWrapper = ({ menuId }: Props) => {
  const { isManagementModalOpen } = useProjectSalesInfoContractEarningsItemCreateModalState(
    (state) => ({
      isManagementModalOpen: state.isManagementModalOpen,
    })
  );
  return (
    <>
      {isManagementModalOpen && (
        <ProjectSalesInfoContractEarningsItemManageCollectionModalWidget menuId={menuId} />
      )}
    </>
  );
};
