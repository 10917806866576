import { useCustomDialog } from '@front/src/features/dialog';
import useWorkReferenceUpdate from '@front/src/features/work/features/work/features/reference/repository/useUpdate';
import useWorkReferenceCreate from '@front/src/features/work/features/work/features/reference/repository/useCreate';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import useWorkReferenceSeqUp from '@front/src/features/work/features/work/features/reference/repository/useSeqUp';
import useWorkReferenceSeqDown from '@front/src/features/work/features/work/features/reference/repository/useSeqDown';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkReferenceTableRowMutationLogic = ({ dataId, menuId, sectionId, formContext }) => {
  const params = { dataId, menuId, sectionId };

  const { setRecentUpdatedId, toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();

  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkReferenceUpdate(params);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
    },
  });

  const { run: onCreate, setCallback: setCreateCallback } = useWorkReferenceCreate(params);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { mutate: onDelete } = workReferenceMutation.useDelete(params);
  const { run: onSeqUp, setCallback: setSeqUpCallback } = useWorkReferenceSeqUp(params);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onSeqDown, setCallback: setSeqDownCallback } = useWorkReferenceSeqDown(params);
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };

  return {
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
  };
};

export default useWorkReferenceTableRowMutationLogic;
