import { menuRequestUrl, userNotificationApiURL } from '@front/src/utils';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  NotificationFilterView,
  UserNotificationLinkView,
  UserNotificationSearchView,
} from '@front/src/features/notification/types/view';

import type {
  UserNotificationIsImportantParameter,
  UserNotificationIsReadParameter,
  UserNotificationSearchParameter,
} from '@front/src/features/notification/types/parameter';

const url = {
  getList: () => `${userNotificationApiURL}/search`,
  getLink: (notificationId: number) => `${userNotificationApiURL}/${notificationId}/link`,
  getCount: () => `${userNotificationApiURL}/count`,
  getFilterList: () => `${menuRequestUrl}/notification-menu`,
  delete: (notificationId: number) => `${userNotificationApiURL}/${notificationId}`,
  updateBookMark: (notificationId: number) => `${userNotificationApiURL}/${notificationId}`,
  toggleRead: (notificationId: number) => `${userNotificationApiURL}/${notificationId}`,
};

export const notificationApi = {
  getList: async <T = UserNotificationSearchView, R = UserNotificationSearchParameter>(
    params: R
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>, R>(url.getList(), {
      params,
    });
    return data;
  },
  getLink: async <T = UserNotificationLinkView>(notificationId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getLink(notificationId));
    return data;
  },
  getCount: async <T = number>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getCount());
    return data;
  },
  getFilterList: async <T = NotificationFilterView[]>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getFilterList());
    return data;
  },
  delete: async <T = unknown>(notificationId: number) => {
    await axios.delete<T, AxiosResponse<T>>(url.delete(notificationId));
  },
  toggleBookMark: async <T = unknown, R = UserNotificationIsImportantParameter>(
    notificationId: number,
    params: R
  ) => {
    await axios.put<T, AxiosResponse<T>, R>(url.updateBookMark(notificationId), params);
  },
  toggleRead: async <T = unknown, R = UserNotificationIsReadParameter>(
    notificationId: number,
    params: R
  ) => {
    await axios.post<T, AxiosResponse<T>, R>(url.toggleRead(notificationId), params);
  },
};
