import { useSnackbar } from '@front/src/features/snackbar';
import { useCallback } from 'react';
import type { SnackbarShowProps } from '@front/src/features/snackbar/types';
import {
  BUSINESS_REGISTRATION_NUMBER_REGEX,
  COMPANY_CONTACT_NUMBER_REGEX,
  EMAIL_REGEX,
  FAX_NUMBER_REGEX,
  MOBILE_REGEX,
  POSITIVE_NUMBER_REGEX,
  URL_REGEX,
} from '@front/src/features/affiliated-company/utils/constant';
import { InputType } from '@front/src/features/affiliated-company/types/domain';

export const useExtraInput = (inputType?: InputType) => {
  const { show } = useSnackbar();

  const inputValidator = useCallback(
    (value: any, REGEX: RegExp, msg: string, show: (props: SnackbarShowProps) => void) => {
      if (value !== '' && !value.match(REGEX)) {
        show({ message: msg });
        return false;
      }
      return true;
    },
    [show]
  );

  return useCallback(
    (value: any) => {
      let isValid = true;
      if (inputType === InputType.EMAIL) {
        isValid = inputValidator(value, EMAIL_REGEX, '이메일 형식이 맞지 않습니다.', show);
      }
      if (inputType === InputType.FAX) {
        isValid = inputValidator(value, FAX_NUMBER_REGEX, 'FAX 번호 형식이 맞지 않습니다.', show);
      }
      if (inputType === InputType.BUSINESS_NUMBER) {
        isValid = inputValidator(
          value,
          BUSINESS_REGISTRATION_NUMBER_REGEX,
          '사업자등록번호 형식이 맞지 않습니다.',
          show
        );
      }
      if (inputType === InputType.COMPANY_MAIN_CONTACT) {
        isValid = inputValidator(
          value,
          new RegExp(`${COMPANY_CONTACT_NUMBER_REGEX.source}|${FAX_NUMBER_REGEX.source}`),
          '회사 대표 번호 형식이 맞지 않습니다.',
          show
        );
      }
      if (inputType === InputType.COMPANY_CONTACT) {
        isValid = inputValidator(
          value,
          new RegExp(`${COMPANY_CONTACT_NUMBER_REGEX.source}|${FAX_NUMBER_REGEX.source}`),
          '회사 전화 번호 형식이 맞지 않습니다.',
          show
        );
      }
      if (inputType === InputType.DIRECT_CONTACT) {
        isValid = inputValidator(
          value,
          new RegExp(`${COMPANY_CONTACT_NUMBER_REGEX.source}|${FAX_NUMBER_REGEX.source}`),
          '직통 전화 번호 형식이 맞지 않습니다.',
          show
        );
      }
      if (inputType === InputType.MOBILE) {
        isValid = inputValidator(value, MOBILE_REGEX, '핸드폰 번호 형식이 맞지 않습니다.', show);
      }
      if (inputType === InputType.URL) {
        isValid = inputValidator(value, URL_REGEX, '접속주소 형식이 맞지 않습니다.', show);
      }
      if (inputType === InputType.POSITIVE) {
        isValid = inputValidator(value, POSITIVE_NUMBER_REGEX, '자연수만 받습니다.', show);
      }
      return isValid;
    },
    [show]
  );
};
