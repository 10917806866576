import React, { useEffect } from 'react';
import { sectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import { commonRepository } from '@front/src/api/repository';
import { useDispatch } from 'react-redux';
import { loginAction } from '@front/login/action';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';
import DefaultErrorBoundary from '@front/src/components/error/DefaultErrorBoundary';

interface Props {
  menuId?: number;
  dataId?: number;
  readOnly?: boolean;
}
export const sectionWrapperClassName = 'fn-section-wrapper';
export default function CommonSectionList(props: Readonly<Props>) {
  const { menuId, readOnly } = props;
  const dispatch = useDispatch();
  const { data: list } = commonRepository.useGetSectionList(menuId);
  const { readOnly: isReadOnly } = useGetReadOnlyFromMenuId(menuId);
  useEffect(() => {
    dispatch(loginAction.requestDetail());
  }, [dispatch]);
  return (
    <>
      {list?.map(({ id, name }) => {
        const SectionComponent = sectionComponent[id];
        return (
          <DefaultErrorBoundary key={id}>
            <div className={sectionWrapperClassName}>
              <SectionComponent
                {...props}
                name={name}
                readOnly={readOnly ?? isReadOnly}
                sectionId={id}
              />
            </div>
          </DefaultErrorBoundary>
        );
      })}
    </>
  );
}
