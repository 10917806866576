import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { WorkCarouselPosition } from '@front/src/features/work/features/work/features/carousel/utils/constant';

const useWorkCarouselCreateForm = ({ bundleId, onCreate, menuId, dataId, sectionId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<TFormValues>({
    defaultValues: {
      description: '',
      position: WorkCarouselPosition.FRONT,
      images: [],
    },
  });
  const { handleSubmit } = methods;
  const onValid = (data) => {
    const formData = new FormData();
    const request = {
      description: data.description,
      position: data.position,
      menuId,
      dataId,
      sectionId,
    };
    formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
    Array.from(data.images as File[]).forEach((image) => {
      formData.append(`images`, image);
    });

    onCreate({
      bundleId,
      formData,
    });
  };
  const onInvalid = (errors) => {
    if (errors.position) {
      enqueueSnackbar(errors.position.message, { variant: 'error' });
    }
    if (errors.images) {
      enqueueSnackbar(errors.images.message, { variant: 'error' });
    }
  };
  const onSubmit = handleSubmit(onValid, onInvalid);
  return {
    onSubmit,
    methods,
  };
};

export default useWorkCarouselCreateForm;

interface TFormValues {
  description: string;
  position: WorkCarouselPosition;
  images: File[];
}
