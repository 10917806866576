import { personalSettingsModalQuery } from '@front/src/features/personal-settings-modal/features/auth-settings/query/query';
import type {
  AuthSearchParams,
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import { personalSettingsModalMutation } from '@front/src/features/personal-settings-modal/features/auth-settings/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { AuthPersonalSettingsModalCUDParams } from '@front/src/features/personal-settings-modal/features/auth-settings/types/parameter';

export const personalSettingsModalRepository = {
  useUserListGet: (menuId: number, params: AuthUserSearchParams, authMenuId) => {
    const { data } = personalSettingsModalQuery.useUserListGet(menuId, params, authMenuId);
    return {
      data,
    };
  },

  useUserListCud: (menuId: number, params: AuthUserSearchParams, authMenuId) => {
    const callback = getNoOpCallback();
    const { mutate } = personalSettingsModalMutation.useUserListCud(menuId, params, authMenuId);
    return {
      run: (params: AuthPersonalSettingsModalCUDParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useDeptListGet: (menuId: number, params: AuthSearchParams, authMenuId) => {
    const { data } = personalSettingsModalQuery.useDeptListGet(menuId, params, authMenuId);
    return {
      data,
    };
  },

  useDeptListUpdate: (authMenuId) => {
    const callback = getNoOpCallback();
    const { mutate } = personalSettingsModalMutation.useDeptListUpdate(authMenuId);
    return {
      run: (params: AuthUpdateParams) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
