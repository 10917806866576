import React, { useCallback } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import {
  filterPersonListIfNotIncludes,
  spliceAndPushPersonListIfExist,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/add-or-delete-button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';

export { AddOrDeleteButton as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalAddOrDeleteButton };

const AddOrDeleteButton = () => {
  const { addList, setSelectedList, setAddList, selectedList, deleteIdList, setDeleteIdList } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState((state) => ({
      addList: state.addList,
      setAddList: state.setAddList,
      setSelectedList: state.setSelectedList,
      selectedList: state.selectedList,
      deleteIdList: state.deleteIdList,
      setDeleteIdList: state.setDeleteIdList,
    }));
  const handlePlusClick = useCallback(() => {
    if (addList.length === 0) {
      return;
    }
    const result = spliceAndPushPersonListIfExist(selectedList, addList);
    setSelectedList(result);
    setAddList([]);
  }, [addList, setSelectedList, setAddList, selectedList]);
  const handleMinusClick = useCallback(() => {
    if (deleteIdList.length === 0) {
      return;
    }
    const result = filterPersonListIfNotIncludes(selectedList, deleteIdList);
    setSelectedList(result);
    setDeleteIdList([]);
  }, [deleteIdList, setDeleteIdList, setAddList, selectedList, setSelectedList]);
  return (
    <div className={classes.container}>
      <Button onClick={handlePlusClick}>
        <FontAwesomeIcon
          icon={'angle-right'}
          style={{
            cursor: 'pointer',
            fontSize: '11px',
            color: ColorPalette._9bb6ea,
          }}
        />
      </Button>
      <Button onClick={handleMinusClick}>
        <FontAwesomeIcon
          icon={'angle-left'}
          style={{
            cursor: 'pointer',
            fontSize: '11px',
            color: ColorPalette._9bb6ea,
          }}
        />
      </Button>
    </div>
  );
};
