import { TableRow } from '@mui/material';
import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from '@front/src/features/snackbar';
import { OldTd } from '@front/layouts/Table';
import classes from '@front/src/features/affiliated-company/features/outline/features/web-hard/components/web-hard-table-row.module.scss';
import type { AffiliatedCompanyWebHardView } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/view';
import type { AffiliatedCompanyOutlineWebHardUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/parameter';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { URL_REGEX } from '@front/src/features/affiliated-company/utils/constant';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { AffiliatedCompanyOutlineWebHardContext } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/context';
import { useAffiliatedCompanyOutlineWebHardState } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/useState';
import { convertNullToEmptyForForm } from '@front/src/utils';
import Checkbox from '@front/layouts/Checkbox';
import { useAffiliatedCompanyOutlineState } from '@front/src/features/affiliated-company/features/outline/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { WebHardTableRow as AffiliatedCompanyOutlineWebHardTableRow };

interface Props {
  item: AffiliatedCompanyWebHardView;
}

const WebHardTableRow = ({ item }: Props) => {
  const {
    h: { onUpdate },
  } = useContext(AffiliatedCompanyOutlineWebHardContext);
  const { idList, setIdList } = useAffiliatedCompanyOutlineWebHardState(
    useShallow((state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
    }))
  );
  const methods = useForm<AffiliatedCompanyOutlineWebHardUpdateParameter>({
    values: getValues(item),
  });
  const { readOnly } = useAffiliatedCompanyOutlineState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const onSubmit = methods.handleSubmit((data) => {
    if (readOnly) return;
    onUpdate(data);
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { show } = useSnackbar();
  const handleBlur = useCallback(
    async (value) => {
      if (value !== '' && !value.match(URL_REGEX)) {
        show({ message: '접속주소 형식이 맞지 않습니다.' });
        return;
      }
      await onSubmit();
    },
    [show, onSubmit]
  );
  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>
          <div className={classes.td}>
            {item.isDeletable ? (
              <Checkbox
                checked={idList.includes(item.id)}
                onChange={handleCheckboxChange(item.id)}
                disabled={!item.isDeletable || readOnly}
              />
            ) : (
              <div className={classes.disabled} />
            )}
            <HookFormInput
              width="100%"
              name="name"
              onSubmit={onSubmit}
              disabled={readOnly}
            />
          </div>
        </OldTd>
        <OldTd>
          <HookFormInput
            name="url"
            onBlur={handleBlur}
            placeholder={'http(s)://(www.)example.com 혹은 www.example.com'}
            inputType={InputType.URL}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="note"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};

const getValues = (item: AffiliatedCompanyWebHardView) => ({
  id: item.id,
  name: convertNullToEmptyForForm(item.name),
  url: convertNullToEmptyForForm(item.url),
  note: convertNullToEmptyForForm(item.note),
});
