import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesInfoContractHistoryModalApi } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/query/api';
import type { ProjectSalesInfoContractHistoryParameter } from '@front/src/features/project-sales-info/features/contract/features/history/features/modal/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';

export { mutation as projectSalesInfoContractHistoryModalMutation };

const mutation = {
  useCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoContractHistoryParameter) =>
        projectSalesInfoContractHistoryModalApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'contract', 'contract-history', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ProjectSalesInfoContractHistoryParameter) =>
        projectSalesInfoContractHistoryModalApi.update(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales-info', 'contract', 'contract-history', 'list'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
