import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import { useMutation, useQueryClient } from 'react-query';
import type { WorkScheduleInterimReportDateParameter } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workInterimReportDateMutation = {
  useCreate: (params: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.createScheduleInterimReportDate(params),
      mutationKey: ['work', 'interim-report-date', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'interim-report-date'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkScheduleInterimReportDateParameter) =>
        workWorkApi.updateScheduleInterimReportDate(params, commonParams),
      mutationKey: ['work', 'interim-report-date', 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'interim-report-date'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (params: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteScheduleInterimReportDate(id, params),
      mutationKey: ['work', 'interim-report-date', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'interim-report-date'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workInterimReportDateMutation;
