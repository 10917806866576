import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { SalesInfoActivityAffiliatesUpdateParams } from '@front/src/features/project-sales-info/features/activity/types/parameters';

export { context as ProjectSalesInfoActivityAffiliatesItemContext };

interface State {
  onUpdate: (params: SalesInfoActivityAffiliatesUpdateParams) => void;
  onDelete: (id: number) => void;
}

const context = createContext<State>({
  onUpdate: noOp,
  onDelete: noOp,
});
