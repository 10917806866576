import type { Dispatch } from 'redux';
import type { AnyAction } from 'redux-saga';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';
import type { AlertView } from '@front/src/features/dialog/types';

type GlobalClickCallback = (
  event: MouseEvent,
  dispatcher: Dispatch<AnyAction>,
  alert: (props: AlertView) => void
) => void;
export const useGlobalClickInterceptor = (callback: GlobalClickCallback) => {
  const dispatcher = useDispatch();
  const { alert } = useCustomDialog();
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      callback(event, dispatcher, alert);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback]);
};
