import React, { memo, useCallback, useRef } from 'react';
import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { useDispatch } from 'react-redux';
import type { OstUpdateTotalBudgetParameter } from '@front/ost/parameter';
import { ostAction } from '@front/ost/action';
import { OstStatus } from '@front/ost/domain';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  totalBudget?: number;
  status?: OstStatus;
}

const OstTotalBudget = ({ totalBudget, status }: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const openSnackbarWarning = useCallback(
    (message) => {
      dispatch(
        snackbarAction.show({
          message,
          severity: SnackbarSeverityType.warning,
        })
      );
    },
    [dispatch]
  );

  const updateTotalBudget = useCallback(
    (params: OstUpdateTotalBudgetParameter) => {
      dispatch(ostAction.updateTotalBudget(params));
    },
    [dispatch]
  );

  const restoreValue = (totalBudget) => {
    if (inputRef.current) {
      if (totalBudget) {
        inputRef.current.value = totalBudget.toLocaleString();
      } else {
        inputRef.current.value = '';
      }
    }
  };

  const onBlur = useCallback(
    (e) => {
      const value = e.target.value.replace(/,/g, '');
      if (value === '') {
        updateTotalBudget({
          totalBudget: undefined,
        });
        return;
      }
      if (Number.isNaN(+value) || +value > 2000000000) {
        openSnackbarWarning('총 예산에 올바른 값을 입력해주세요.');
        restoreValue(totalBudget);
        return;
      }
      if (status === OstStatus.DONE) {
        openSnackbarWarning('종료된 OST입니다.');
        restoreValue(totalBudget);
        return;
      }
      updateTotalBudget({
        totalBudget: +value,
      });
    },
    [totalBudget, updateTotalBudget, openSnackbarWarning, status]
  );

  return (
    <DataFieldWithLabel
      label="총 예산"
      labelSX={{ minWidth: '80px' }}
    >
      <Input
        isAmount
        key={totalBudget}
        inputRef={inputRef}
        variant="outlined"
        defaultValue={totalBudget?.toLocaleString()}
        onBlur={onBlur}
      />
    </DataFieldWithLabel>
  );
};

const OstFormTotalBudget = memo(OstTotalBudget);

export default OstFormTotalBudget;
