import { create } from 'zustand';
import type { AffiliatedPersonSalesActivityConvertedView } from '@front/src/features/affiliated-person/features/sales-activity/types/view';

export { useState as useAffiliatedPersonSalesActivityState };

interface State {
  list: AffiliatedPersonSalesActivityConvertedView[];
}

interface Actions {
  setList: (list: AffiliatedPersonSalesActivityConvertedView[]) => void;
}

const useState = create<State & Actions>((set) => ({
  list: [],
  setList: (list) => set(() => ({ list })),
}));
