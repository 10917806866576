import React from 'react';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import { useIsMutating } from 'react-query';

export default function ProjectSalesCreateLoadingSpinner() {
  const isLoading = !!useIsMutating({
    mutationKey: ['project', 'sales', 'create'],
  });
  return <BackdropLoadingSpinnerUI open={isLoading} />;
}
