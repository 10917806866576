import { useQuery } from 'react-query';
import type { ProjectReviewSavePaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/save-history/types/view';
import { projectSalesInfoCollectionSaveHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/save-history/query/api';

export const projectSalesInfoCollectionSaveHistoryQuery = {
  useSaveHistoryGet: (id: number, menuId) => {
    const { data, isLoading } = useQuery<ProjectReviewSavePaymentHistoryView[]>({
      queryFn: () => projectSalesInfoCollectionSaveHistoryApi.getSaveHistory(id, menuId),
      queryKey: ['project-sales-info', 'collection', 'save-history', id],
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
