import { useCustomDialog } from '@front/src/features/dialog';
import useWorkDetailUpdate from '@front/src/features/work/features/work/features/detail/repository/useUpdate';
import useWorkDetailCreate from '@front/src/features/work/features/work/features/detail/repository/useCreate';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';
import useWorkDetailSeqUp from '@front/src/features/work/features/work/features/detail/repository/useSeqUp';
import useWorkDetailSeqDown from '@front/src/features/work/features/work/features/detail/repository/useSeqDown';
import { getEditOpenIdList } from '@front/src/components/ui-builder/table/utils';

const useWorkDetailTableRowMutation = ({
  dataId,
  menuId,
  sectionId,
  onCloseEditModal,
  reset,
  formContext,
}) => {
  const params = { dataId, menuId, sectionId };

  const { getValues, setValue } = formContext;

  const editOpenIdList = getValues('editOpenIdList');

  const toggleEditOpenId = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId = (id) => {
    setValue('recentUpdatedId', id);
  };

  const { run: onUpdate, setCallback: setUpdateCallback } = useWorkDetailUpdate(params);
  setUpdateCallback({
    onSuccess: ({ data }, _, context) => {
      !context && setRecentUpdatedId(data);
      onCloseEditModal(reset);
    },
  });

  const { run: onCreate, setCallback: setCreateCallback } = useWorkDetailCreate(params);
  setCreateCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onSeqUp, setCallback: setSeqUpCallback } = useWorkDetailSeqUp(params);
  setSeqUpCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { run: onSeqDown, setCallback: setSeqDownCallback } = useWorkDetailSeqDown(params);
  setSeqDownCallback({
    onSuccess: ({ data }) => setRecentUpdatedId(data),
  });

  const { mutate: onDelete } = workDetailMutation.useDelete(params);
  const { confirm } = useCustomDialog();
  const onRemove = (id) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };
  return {
    onUpdate,
    onCreate,
    onSeqUp,
    onSeqDown,
    onRemove,
  };
};

export default useWorkDetailTableRowMutation;
