import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import classes from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/components/family-information-table-row.module.scss';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import type { AffiliatedCompanyPersonCharacterFamilyInformationView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/types/view';
import { AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/widgets/context';
import type { AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/family-information/types/parameter';
import {
  familyOptionList,
} from '@front/src/features/affiliated-company/utils/constant';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";
import {formatDateOrNull} from "@front/src/features/project-sales-info/utils";

interface Props {
  item: AffiliatedCompanyPersonCharacterFamilyInformationView;
}

export { FamilyInformationTableRow as AffiliatedCompanyPersonDetailModalCharacterFamilyInformationTableRow };
const FamilyInformationTableRow = ({ item }: Props) => {
  const {
    idList,
    h: { setIdList, onUpdateFamilyInformation },
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterFamilyInformationContext);
  const methods = useForm<AffiliatedCompanyPersonCharacterFamilyInformationUpdateParameter>({
    values: {
      id: item.id,
      relation: item.relation ?? '',
      name: item.name ?? '',
      age: item.age !== null ? String(item.age) : '',
      birthday: item.birthday ?? '',
    },
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onUpdateFamilyInformation({
      ...data,
      birthday: formatDateOrNull(data.birthday)
    });
  });
  return (
    <FormProvider {...methods}>
      <TableRow key={Math.random()}>
        <OldTd>
          <div className={classes.th}>
            <Checkbox
              checked={idList.includes(item.id)}
              onChange={handleCheckboxChange(item.id)}
              disabled={readOnly}
            />
          </div>
        </OldTd>
        <TableCell>
          <HookFormSelect
            name="relation"
            defaultLabel="선택"
            optionList={familyOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </TableCell>
        <OldTd>
          <HookFormInput
            name="name"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="age"
            onSubmit={onSubmit}
            type="number"
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <DatePickerWithHookForm
            name="birthday"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
