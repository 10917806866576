import React, { useContext } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { OldTd, Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoSubjectReviewExperimentContext } from '@front/src/features/project-sales-info/features/subject-review/features/experiment/widgets/context';
import { getValueIfExist, getValueToYNIfExist } from '@front/src/utils';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ExperimentTable as ProjectSalesInfoSubjectReviewExperimentExperimentTable };

const ExperimentTable = () => {
  const {
    d: { detail },
  } = useContext(ProjectSalesInfoSubjectReviewExperimentContext);
  return (
    <TableContainer
      sx={{
        ...useGetMaxWidthSx({ hasMemo: true }),
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
            <Th width="200px">풍력</Th>
            <OldTd width="280px">
              {getValueIfExist(detail?.experiment?.windForceCount?.count)}
            </OldTd>
            <OldTd width="280px">
              {getValueIfExist(detail?.experiment?.windForceCount?.complex)}
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="200px">풍압</Th>
            <OldTd width="280px">
              {getValueIfExist(detail?.experiment?.windPressureCount?.count)}
            </OldTd>
            <OldTd width="280px">
              {getValueIfExist(detail?.experiment?.windPressureCount?.complex)}
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="200px">풍환경 여부</Th>
            <OldTd width="280px">
              {getValueToYNIfExist(detail?.experiment?.hasWindEnvironment)}
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="200px">공기력</Th>
            <OldTd width="280px">{getValueIfExist(detail?.experiment?.airForceCount?.count)}</OldTd>
            <OldTd width="280px">
              {getValueIfExist(detail?.experiment?.airForceCount?.complex)}
            </OldTd>
          </TableRow>
          <TableRow>
            <Th width="200px">빌딩풍</Th>
            <OldTd width="280px">{getValueToYNIfExist(detail?.experiment?.hasBuildingWind)}</OldTd>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
