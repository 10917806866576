import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoContractReviewTableBody } from '@front/src/features/project-sales-info/features/contract/features/reivew/components/review-table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ReviewTable as ProjectSalesInfoContractReviewTable };

const ReviewTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoContractReviewTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px" />
      <Th width="80px">순번</Th>
      <Th width="80px">계약분류</Th>
      <Th width="200px">계약 구조사</Th>
      <Th width="120px">구검비</Th>
      <Th width="120px">구검계약일자</Th>
      <Th width="200px">비고</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoContractReviewTableHeader';
