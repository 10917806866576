import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import type { WorkReferenceFileUpdateParameter } from '@front/src/features/work/features/work/features/reference/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkReferenceUpdateFile = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workReferenceMutation.useUpdateFile(commonParams);
  return {
    run: (params: WorkReferenceFileUpdateParameter) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkReferenceUpdateFile;
