import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/affiliated-company/components/layout/modal-with-tab/header.module.scss';
interface Props {
  children: ReactNode;
  button?: ReactNode;
}

export { Header };

const Header = ({ children, button }: Props) => (
  <div>
    {button && <div className={classes.box__delete}>{button}</div>}
    {children}
  </div>
);
