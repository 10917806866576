// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZOWK1w5Tf8BgHA0XFFV2{display:flex;width:300px;gap:10px}.kKB4QLPBZr9oeDqq07xA{display:flex;flex-direction:column;gap:15px;align-items:center;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/features/create-modal/components/create-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".btn__group {\r\n  display: flex;\r\n  width: 300px;\r\n  gap: 10px;\r\n}\r\n\r\n.modal__group {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 15px;\r\n  align-items: center;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn__group": `ZOWK1w5Tf8BgHA0XFFV2`,
	"modal__group": `kKB4QLPBZr9oeDqq07xA`
};
export default ___CSS_LOADER_EXPORT___;
