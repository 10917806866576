import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import type { UserListGetParams } from '@front/src/types/parameter';

export const commonQuery = {
  useGetSectionList: (authMenuId) => {
    const { data } = useQuery({
      queryKey: ['menu', authMenuId, 'section-list'],
      queryFn: () => commonApi.getSectionList(authMenuId),
      enabled: !!authMenuId,
    });
    return {
      data,
    };
  },
  useGetHeaderList: (sectionId, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['section', sectionId, 'header-list'],
      queryFn: () =>
        commonApi.getHeaderList(
          sectionId,
          {
            position: 'table',
          },
          authMenuId
        ),
      enabled: !!authMenuId,
    });
    return {
      data,
    };
  },
  useGetUserList: (params: UserListGetParams, isMentionOpen: boolean, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['user', params],
      queryFn: () => commonApi.getUserList(params, authMenuId),
      enabled: isMentionOpen && !!authMenuId,
    });
    return { data };
  },
};
