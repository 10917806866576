import axios from 'axios';
import type { ProjectSalesInfoBidInfoParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { estimationURLAPI } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/utils/constants';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import { toFormData } from '@front/services/api';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createBidInfo: (id: number) => `${bidURLAPI}/${id}/bid-information`,
  getEstimateId: (id: number) => `${estimationURLAPI}/${id}/estimation?isConfirmed=true`,
};

export const projectSalesInfoBidInfoCreateModalApi = {
  createBidInfo: async (data: { params: ProjectSalesInfoBidInfoParams; id: number }, menuId) => {
    await axios.post(
      url.createBidInfo(data.id!),
      toFormData({
        ...data.params,
        bidNoticeFile: data.params.bidNoticeFile,
        bidGuideLineFile: data.params.bidGuideLineFile,
      }),
      {
        headers: getMenuIdHeaders(menuId),
      }
    );
  },

  getEstimateId: async (id: number, menuId) => {
    const { data } = await axios.get(url.getEstimateId(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
