import { useEffect } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';

const FileDownLoadRequestHandler = () => {
  const { alert } = useCustomDialog();
  useEffect(() => {
    const handlePendingRequest = async () => {
      const pendingRequest = sessionStorage.getItem('pendingFileDownloadRequest');

      if (pendingRequest) {
        try {
          // 먼저 헤더 정보를 가져옵니다
          const response = await fetch(pendingRequest);
          const contentDisposition = response.headers.get('content-disposition');

          if (contentDisposition == null) {
            alert({
              children: [
                {
                  value: '해당 링크를 다운로드할 권한이 없습니다.',
                  sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              ],
            });
            sessionStorage.removeItem('pendingFileDownloadRequest');
            return;
          }
          // filename 추출
          let filename = 'download'; // 기본 파일명
          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          const blob = await response.blob();

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          sessionStorage.removeItem('pendingFileDownloadRequest');
        } catch (error) {
          console.error('Download request error:', error);
        }
      }
    };

    handlePendingRequest();
  }, []);

  return null;
};

export default FileDownLoadRequestHandler;
