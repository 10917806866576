import React, { Suspense, useEffect, useState } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { Box } from '@mui/material';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { useShallow } from 'zustand/react/shallow';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import CheckboxBasicUI from '@front/src/components/components-with-design/component/CheckboxBasicUI';
import { approvalQuery } from '@front/src/features/drawer-approval/query/query';
import { NoResult } from '@front/src/components/layout/table/no-result';
import type { HasFileItemView } from '@front/src/features/drawer-approval/types/view';
import dayjs from 'dayjs';
import { YY_MM_DD_HH_MM } from '@front/src/utils';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props {
  append: (fileItem: HasFileItemView[]) => void;
  onChange?: (file: HasFileItemView) => void;
}

function ApprovalHasFileModal({ append, onChange }: Props) {
  const { isHasModalOpen, setIsHasModalOpen, setHasQueryParams, selectedHasList, isHasCompare } =
    useApprovalStore(
      useShallow((state) => ({
        isHasModalOpen: state.isHasModalOpen,
        setIsHasModalOpen: state.setIsHasModalOpen,
        setHasQueryParams: state.setHasQueryParams,
        selectedHasList: state.selectedHasList,
        isHasCompare: state.isHasCompare,
      }))
    );
  const [value, setValue] = useState('');
  const { alert } = useCustomDialog();

  return (
    <ModalLayout
      open={isHasModalOpen}
      title="HAS 파일 추가"
      onClose={() => setIsHasModalOpen(false)}
      width="60dvh"
      height="60dvh"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            borderBottom: '1px solid',
            padding: '10px 15px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <ButtonBasicUI onClick={() => setIsHasModalOpen(false)}>취소</ButtonBasicUI>
            <ButtonBasicUI
              onClick={() => {
                if (isHasCompare && onChange && selectedHasList.length !== 1) {
                  alert({
                    title: '선택 오류',
                    children: [{ value: '1개만 선택 가능합니다.' }],
                  });
                  return;
                }
                setIsHasModalOpen(false);
                isHasCompare && onChange ? onChange?.(selectedHasList[0]) : append(selectedHasList);
              }}
            >
              확인
            </ButtonBasicUI>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              padding: '10px 15px',
              whiteSpace: 'nowrap',
            }}
          >
            <TextFieldBasicUI
              placeholder="검색어를 쉼표로 구분하여 입력하세요"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <ButtonBasicUI onClick={() => setHasQueryParams({ keyword: value })}>
              검색
            </ButtonBasicUI>
            <ButtonBasicUI
              onClick={() => {
                setHasQueryParams({});
                setValue('');
              }}
            >
              초기화
            </ButtonBasicUI>
          </Box>
          <Box>
            <Suspense fallback={<LoadingSpinnerUI />}>
              <ApprovalHasTable />
            </Suspense>
          </Box>
        </Box>
      </Box>
    </ModalLayout>
  );
}

function ApprovalHasTable() {
  const { hasQueryParams, selectedHasList, toggleAllSelectedHasList, resetSelectedHasList } =
    useApprovalStore(
      useShallow((state) => ({
        hasQueryParams: state.hasQueryParams,
        selectedHasList: state.selectedHasList,
        setSelectedHasList: state.setSelectedHasList,
        toggleAllSelectedHasList: state.toggleAllSelectedHasList,
        resetSelectedHasList: state.resetSelectedHasList,
      }))
    );
  const { data: hasFileList } = approvalQuery.useGetHasFileList(hasQueryParams);
  const isEmpty = hasFileList?.length === 0;

  useEffect(() => {
    return () => {
      resetSelectedHasList();
    };
  }, []);

  return (
    <TableContainerUI
      sx={{
        maxHeight: '50dvh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <TableTableUI>
        <TableHeadUI>
          <TableRowUI>
            <TableCellUI isHead={true}>
              <CheckboxBasicUI
                labelSx={{
                  margin: 0,
                }}
                checked={selectedHasList.length === hasFileList?.length}
                onChange={() =>
                  toggleAllSelectedHasList(
                    selectedHasList.length === hasFileList?.length,
                    hasFileList ?? []
                  )
                }
              />
            </TableCellUI>
            <TableCellUI isHead={true}>섹션명</TableCellUI>
            <TableCellUI isHead={true}>날짜</TableCellUI>
            <TableCellUI isHead={true}>등록자</TableCellUI>
            <TableCellUI isHead={true}>파일명</TableCellUI>
          </TableRowUI>
        </TableHeadUI>
        <TableBodyUI>
          {isEmpty && <NoResult colSpan={5} />}
          {hasFileList?.map((hasFile) => (
            <ApprovalHasTableRow
              key={hasFile.id}
              item={hasFile}
            />
          ))}
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}

interface RowProps {
  item: HasFileItemView;
}

function ApprovalHasTableRow({ item }: RowProps) {
  const { selectedHasList, setSelectedHasList } = useApprovalStore(
    useShallow((state) => ({
      selectedHasList: state.selectedHasList,
      setSelectedHasList: state.setSelectedHasList,
    }))
  );
  return (
    <TableRowUI>
      <TableCellUI>
        <CheckboxBasicUI
          onChange={() => setSelectedHasList(item)}
          checked={selectedHasList.includes(item)}
          labelSx={{
            margin: 0,
          }}
        />
      </TableCellUI>
      <TableCellUI>{item.sectionName}</TableCellUI>
      <TableCellUI>{dayjs(item.createdAt).format(YY_MM_DD_HH_MM)}</TableCellUI>
      <TableCellUI>{item.registerName}</TableCellUI>
      <TableCellUI>{item.filename}</TableCellUI>
    </TableRowUI>
  );
}

export default function ApprovalHasFileModalWrapper({ append, onChange }: Props) {
  const isHasModalOpen = useApprovalStore((state) => state.isHasModalOpen);
  return (
    <>
      {isHasModalOpen && (
        <ApprovalHasFileModal
          append={append}
          onChange={onChange}
        />
      )}
    </>
  );
}
