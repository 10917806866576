import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import dayjs from 'dayjs';

import useDialog from '@front/dialog/hook';
import { OldTd } from '@front/layouts/Table';
import TextLink from '@front/layouts/TextLink';
import Button from '@front/layouts/Button';
import type { DrawingVO } from '@front/ost_proposal/domain';
import { DrawingId, ProposalStatus } from '@front/ost_proposal/domain';
import type { DrawIoData } from '@front/ost_proposal/view/detail/draw_io/integration/useDrawIo';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { TargetPage } from '@front/navigate/domain';

interface Props {
  item: DrawingVO;
  index: number;
  deleteDrawing: (id: DrawingId) => void;
  onClickText: (originalDrawData: DrawIoData | undefined) => () => void;
  onDownloadFile?: (drawIoData: DrawIoData) => void;
  isContributor: boolean;
}

const DrawIoItem = ({
  item,
  index,
  deleteDrawing,
  onClickText,
  onDownloadFile,
  isContributor,
}: Props) => {
  const { confirm } = useDialog();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const confirmToDelete = useCallback(() => {
    confirm({
      children: '해당 드로잉을 삭제하시겠습니까?',
      confirmText: '확인',
      afterConfirm: () => {
        deleteDrawing(DrawingId(item.id));
      },
    });
  }, [item]);

  const confirmToDownload = useCallback(() => {
    confirm({
      children: (
        <div>
          <p>해당 드로잉을 파일로 저장하겠습니까?</p>
          <p style={{ fontSize: '12px' }}>
            저장된 파일은{' '}
            <a
              href="https://app.diagrams.net"
              target="_blank"
              rel="noreferrer"
            >
              https://app.diagrams.net
            </a>{' '}
            사이트 또는 Draw.io 프로그램을 통해 열람할 수 있습니다.
          </p>
        </div>
      ),
      confirmText: '확인',
      afterConfirm: () => {
        onDownloadFile &&
          onDownloadFile({
            xml: item.drawing.stringifiedData,
            name: item.drawing.fileName,
            lastModified: item.drawing.modifiedAt,
            id: item.drawing.id,
          });
      },
    });
  }, [item]);
  const currentPage = useSelector(
    (root: RootState) => root.navigation.currentPage!.targetPage,
    shallowEqual
  );
  return (
    <TableRow key={`${item.id}_${index}`}>
      <OldTd>{dayjs(item.drawing.createdAt).format('YYYY-MM-DD HH:mm')}</OldTd>
      {currentPage === TargetPage.VOTE && <OldTd>****</OldTd>}
      {currentPage !== TargetPage.VOTE && <OldTd>{item.writer.department.name}</OldTd>}
      {currentPage === TargetPage.VOTE && <OldTd>{`제안자${index + 1}`}</OldTd>}
      {currentPage !== TargetPage.VOTE && <OldTd>{item.writer.name}</OldTd>}
      <OldTd width="40%">
        <TextLink
          onClick={onClickText({
            id: item.id,
            name: item.drawing.fileName,
            lastModified: item.drawing.createdAt,
            xml: item.drawing.stringifiedData,
          })}
        >
          {item.drawing.fileName}
        </TextLink>
      </OldTd>
      {status === ProposalStatus.WRITING && isContributor && (
        <OldTd width="20%">
          <Button
            shape="basic3"
            sx={{ marginRight: '10px' }}
            onClick={confirmToDelete}
          >
            삭제
          </Button>
          <Button onClick={confirmToDownload}>다운로드</Button>
        </OldTd>
      )}
    </TableRow>
  );
};

export default DrawIoItem;
