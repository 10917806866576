import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TableBody, TableHead, TableRow } from '@mui/material';

import { OldTh, Table, OldTd } from '@front/layouts/Table';
import type { RootState } from '@front/services/reducer';
import LoadingBar from '@front/layouts/LoadingBar';

export const PaymentStatusTable = () => {
  const [campaignRewards] = useSelector(
    (root: RootState) => [root.ostRewardMangement.campaignRewards],
    shallowEqual
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <OldTh>번호</OldTh>
          <OldTh>소속</OldTh>
          <OldTh>성명</OldTh>
          <OldTh>직급</OldTh>
          <OldTh>상금</OldTh>
          <OldTh>연간 개인 누적상금 총액</OldTh>
        </TableRow>
      </TableHead>
      <TableBody>
        {typeof campaignRewards === 'undefined' && (
          <TableRow>
            <OldTd colSpan={9}>
              <LoadingBar />
            </OldTd>
          </TableRow>
        )}
        {campaignRewards && campaignRewards.length === 0 && (
          <TableRow>
            <OldTd colSpan={9}>{'정보가 없습니다.'}</OldTd>
          </TableRow>
        )}
        {typeof campaignRewards !== 'undefined' &&
          campaignRewards.map((item, i) => (
            <TableRow key={item.name + item.reward}>
              <OldTd>{i + 1}</OldTd>
              <OldTd>{item.department}</OldTd>
              <OldTd>{item.name}</OldTd>
              <OldTd>{item.role}</OldTd>
              <OldTd>{item.reward.toLocaleString()} 원</OldTd>
              <OldTd>{item.annualAccumulation.toLocaleString()} 원</OldTd>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
