import React, { Suspense } from 'react';
import useWorkCarouselMoveSLideForm from '@front/src/features/work/features/work/features/carousel/features/move-slide/hooks/useForm';

import { ModalContentUI } from '@front/src/components/components-with-design/layout/ModalUI';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import { FormProvider } from 'react-hook-form';
import WorkCarouselMoveSlideTableBody from '@front/src/features/work/features/work/features/carousel/features/move-slide/components/TableBody';
import TableBodyLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableBodyLoadingSpinnerUI';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import workCarouselMoveSlideRepository from '@front/src/features/work/features/work/features/carousel/features/move-slide/repository';

import WorkCarouselMoveSlideHeader from '@front/src/features/work/features/work/features/carousel/features/move-slide/components/Header';

interface Props extends UIBuilderParameter {
  id: number;
  onClose: () => void;
}

export default function WorkCarouselMoveSlideFeature(props: Readonly<Props>) {
  const { onClose, menuId, dataId, sectionId, id } = props;

  const { run: onMoveSlide, setCallback } = workCarouselMoveSlideRepository.useMoveSlide({
    menuId,
    dataId,
    sectionId,
  });

  const { methods, resetValues, onInvalid } = useWorkCarouselMoveSLideForm();

  const { handleSubmit } = methods;

  const onValid = (data) => {
    onMoveSlide({
      ...data,
      workCarouselId: id,
    });
  };

  const onSubmit = handleSubmit(onValid, onInvalid);

  const handleClose = () => {
    resetValues();
    onClose();
  };

  setCallback({
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <FormProvider {...methods}>
      <WorkCarouselMoveSlideHeader
        onClose={handleClose}
        onSubmit={onSubmit}
      />
      <ModalContentUI>
        <TableContainerUI>
          <TableTableUI>
            <TableHeadUI>
              <TableRowUI>
                <TableCellUI isHead>선택</TableCellUI>
                <TableCellUI isHead>슬라이드 이름</TableCellUI>
              </TableRowUI>
            </TableHeadUI>
            <Suspense fallback={<TableBodyLoadingSpinnerUI open />}>
              <WorkCarouselMoveSlideTableBody {...props} />
            </Suspense>
          </TableTableUI>
        </TableContainerUI>
      </ModalContentUI>
    </FormProvider>
  );
}
