import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import EditIcon from '@mui/icons-material/Edit';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/TableBody';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import NoteIcon from '@mui/icons-material/Note';
import useWorkCategoryForm from '@front/src/features/work/features/work/features/category/hooks/useForm';
import workCategoryMutation from '@front/src/features/work/features/work/features/category/query/mutation';

export default function WorkWorkCategoryTableRow(
  props: Readonly<UIBuilderTableRowProps<WorkCategoryView>>
) {
  const { item, sectionId, dataId, menuId } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: onUpdate } = workCategoryMutation.useUpdate({ dataId, menuId, sectionId });
  const { onSubmit, methods } = useWorkCategoryForm({
    item,
    onUpdate,
  });
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);
  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );
  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(sectionId);
  };
  const itemList = [
    {
      text: open ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => setOpen(!open),
      seq: 1,
    },
    { text: '비고', icon: NoteIcon, action: onNoteOpen, seq: 6 },
  ];
  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        item={item}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={open}
      />
    </FormProvider>
  );
}
