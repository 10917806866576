import React from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAbstractEstimationTable } from '@front/src/features/project-sales-info/features/abstract/features/estimation/components/table';
import { ProjectSalesInfoAbstractEstimationDetailWidget } from '@front/src/features/project-sales-info/features/abstract/features/estimation/widgets/detail/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

/**
 * @module 영업정보>요약탭>견적섹션
 */
const ProjectSalesInfoAbstractEstimationFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <ProjectSalesInfoAbstractEstimationTable>
          <ProjectSalesInfoAbstractEstimationDetailWidget menuId={menuId} />
        </ProjectSalesInfoAbstractEstimationTable>
      </Body>
    </Layout>
  );
};

export default ProjectSalesInfoAbstractEstimationFeature;
