import type { MouseEvent } from 'react';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import Input from '@front/layouts/Input';
import { ProposalGrade, ProposalStatus } from '@front/ost_proposal/domain';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  onClick?: (e: MouseEvent) => void;
  grade?: ProposalGrade;
  title: string;
}

const EstimateRank = ({ onClick, grade, title }: Props) => {
  const { status, fixedResult, loginUser, fixedAt } = useSelector(
    (root: RootState) => ({
      status: root.proposal.detail!.status,
      fixedResult: root.proposal.detail!.fixedResult,
      fixedAt: root.proposal.detail!.fixedAt,
      loginUser: root.login.detail,
    }),
    shallowEqual
  );
  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );

  const isContributor = useMemo(
    () =>
      contributorList.some((contributorView) => contributorView.contributor?.id === loginUser?.id),
    [contributorList, loginUser]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px',
        maxHeight: '200px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
          gridTemplateRows: '30px auto',
          gridTemplateAreas: `"title1 title1 title1 title1 title2"
        "tall grande venti supreme result"
        "tall-detail grande-detail venti-detail supreme-detail ."`,
          columnGap: '20px',
          rowGap: '5px',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '20px',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            gridArea: 'title1',
          }}
        >
          <TextBox variant="body1">{title}</TextBox>
        </Box>
        <Box
          sx={{
            gridArea: 'tall',
            minHeight: '50px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              backgroundColor: grade == ProposalGrade.TALL ? ColorPalette._d2e7fa : 'white',
              cursor: `${
                status === ProposalStatus.WRITING && isContributor ? 'pointer' : 'default'
              }`,
              borderRadius: '5px',
            }}
            aria-valuetext="TALL"
            onClick={onClick}
          >
            <TextBox variant={'body1'}>Tall</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'tall-detail',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <TextBox variant={'body9'}>0 ~ 10만원</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'grande',
            minHeight: '50px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: grade == ProposalGrade.GRANDE ? ColorPalette._d2e7fa : 'white',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              cursor: `${
                status === ProposalStatus.WRITING && isContributor ? 'pointer' : 'default'
              }`,
              borderRadius: '5px',
            }}
            aria-valuetext="GRANDE"
            onClick={onClick}
          >
            <TextBox variant={'body1'}>Grande</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'grande-detail',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <TextBox variant={'body9'}>10 ~ 100만원</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'venti',
            minHeight: '50px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor:
                grade == ProposalGrade.VENTI ? ColorPalette._d2e7fa : ColorPalette._ffffff,
              border: `1px solid ${ColorPalette._e4e9f2}`,
              cursor: `${
                status === ProposalStatus.WRITING && isContributor ? 'pointer' : 'default'
              }`,
              borderRadius: '5px',
            }}
            aria-valuetext="VENTI"
            onClick={onClick}
          >
            <TextBox variant={'body1'}>Venti</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'venti-detail',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <TextBox variant={'body9'}>100 ~ 500만원</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'supreme',
            minHeight: '50px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: grade == ProposalGrade.SUPREME ? ColorPalette._d2e7fa : 'white',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              cursor: `${
                status === ProposalStatus.WRITING && isContributor ? 'pointer' : 'default'
              }`,
              borderRadius: '5px',
            }}
            aria-valuetext="SUPREME"
            onClick={onClick}
          >
            <TextBox variant={'body1'}>Supreme</TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            gridArea: 'supreme-detail',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <TextBox variant={'body9'}>500 ~ 1000만원</TextBox>
          </Box>
        </Box>
        {status === ProposalStatus.DENY && (
          <>
            <Box
              sx={{
                gridArea: 'result',
                minHeight: '50px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  backgroundColor: grade == null ? ColorPalette._d2e7fa : 'white',
                  border: `1px solid ${ColorPalette._e4e9f2}`,
                  borderRadius: '5px',
                }}
              >
                <TextBox variant={'body1'}>반려</TextBox>
              </Box>
            </Box>
            <Box
              sx={{
                gridArea: 'venti-detail',
              }}
            />
          </>
        )}
        {fixedAt !== null && fixedResult !== null && (
          <>
            <Box
              sx={{
                gridArea: 'title2',
              }}
            >
              <TextBox
                variant="body1"
                sx={{
                  marginBottom: '10px',
                }}
              >
                확정 금액
              </TextBox>
            </Box>
            <Box
              sx={{
                gridArea: 'result',
                alignSelf: 'end',
              }}
            >
              <Input
                readOnly
                key={fixedResult}
                sx={{
                  minHeight: '50px',
                }}
                isAmount
                variant="outlined"
                defaultValue={fixedResult.toLocaleString()}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EstimateRank;
