import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalCharacterPreferenceContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/widgets/context';

export { ButtonGroup as AffiliatedCompanyPersonDetailModalCharacterPreferenceButtonGroup };
const ButtonGroup = () => {
  const {
    h: { onCreatePreference, onDeletePreference, setIdList },
    idList,
    readOnly,
  } = useContext(AffiliatedCompanyPersonDetailModalCharacterPreferenceContext);
  const handleDeleteClick = useCallback(() => {
    onDeletePreference({ idList });
    setIdList([]);
  }, [onDeletePreference, idList, setIdList]);
  if (readOnly) {
    return <></>;
  }
  return (
    <>
      <Button
        disabled={readOnly}
        onClick={handleDeleteClick}
      >
        선택삭제
      </Button>
      <Button
        disabled={readOnly}
        onClick={onCreatePreference}
      >
        취향 추가
      </Button>
    </>
  );
};
