import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workReviewerMutation from '@front/src/features/work/features/work/features/reviewer/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkReviewerSeqDown = (params: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workReviewerMutation.useSeqDown(params);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkReviewerSeqDown;
