import React, { useCallback, useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { AccountingSettingsManagementAccountingAccountTableBodyCellAliasItem } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/alias-item';
import { useAccountingSettingsManagementAccountingAccountCategoryClick } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/hooks/useCategoryClick';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { AccountingSettingsManagementAccountingAccountTableLargeCategoryUpdateInput } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/large-category-update-input';
import { AccountingSettingsManagementAccountingAccountTableBankBookNameUpdateInput } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/bank-book-name-update-input';
import { AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/category-list-create-input';
import { accountingSettingsManagementAccountingAccountTableLayout } from '@front/src/features/accounting/features/settings/features/management-accounting-account/components/layouts/table';
import { AccountingSettingsManagementAccountingAccountTableBodyCellLargeItem } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/components/large-item';
import { useShallow } from 'zustand/react/shallow';

export { BankBookNameAndCategoryBigList as AccountingSettingsManagementAccountingAccountTableBankBookNameAndCategoryBigList };

const BankBookNameAndCategoryBigList = () => {
  const { TableBodyCell } = accountingSettingsManagementAccountingAccountTableLayout;
  const { tableRowNum, list } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const { updateId } = useAccountingSettingsManagementAccountingAccountTableState(
    useShallow((state) => ({
      updateId: state.updateId,
    }))
  );
  const { handleLargeCategoryClick } =
    useAccountingSettingsManagementAccountingAccountCategoryClick();
  const categoryBigList = useMemo(() => list?.filter((t) => t.depth === 0), [list]);
  const lastItem = categoryBigList?.at(-1);
  const renderBankBookNameList = useCallback(
    () =>
      new Array(tableRowNum).fill(0).map((_, i) => {
        const item = categoryBigList?.[i];
        if (!item) {
          return <TableBodyCell key={`bank-book-name-${i}`} />;
        }
        if (updateId === item.id) {
          return (
            <TableBodyCell
              key={item.id}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableBankBookNameUpdateInput
                item={item}
              />
            </TableBodyCell>
          );
        }
        return (
          <AccountingSettingsManagementAccountingAccountTableBodyCellAliasItem
            key={`bank-book-name-${i}`}
            item={item}
            handleCategoryClick={handleLargeCategoryClick}
          />
        );
      }),
    [categoryBigList, handleLargeCategoryClick, updateId, tableRowNum, TableBodyCell]
  );
  const renderCategoryBigList = useCallback(
    () =>
      new Array(tableRowNum).fill(0).map((_, i) => {
        if (
          (categoryBigList?.length === 0 && i === 0) ||
          (lastItem && lastItem.sequence + 1 === i)
        ) {
          return (
            <TableBodyCell
              key={`category-big-${i}`}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableCategoryListCreateInput
                depth={0}
                parentId={null}
              />
            </TableBodyCell>
          );
        }
        const item = categoryBigList?.[i];
        if (!item) {
          return <TableBodyCell key={`category-big-${i}`} />;
        }
        if (updateId === item.id) {
          return (
            <TableBodyCell
              key={item.id}
              justifyContent="center"
            >
              <AccountingSettingsManagementAccountingAccountTableLargeCategoryUpdateInput
                item={item}
              />
            </TableBodyCell>
          );
        }
        return (
          <AccountingSettingsManagementAccountingAccountTableBodyCellLargeItem
            key={`category-big-${i}`}
            item={item}
            handleCategoryClick={handleLargeCategoryClick}
          />
        );
      }),
    [categoryBigList, handleLargeCategoryClick, updateId, lastItem, tableRowNum, TableBodyCell]
  );
  return (
    <>
      <Box>{renderBankBookNameList()}</Box>
      <Box>{renderCategoryBigList()}</Box>
    </>
  );
};
