import { useMutation } from 'react-query';
import { commonApi } from '@front/src/api/api';

const commonMutation = {
  useCreateImage: (menuId) => {
    const { mutate } = useMutation<any, unknown, FormData>({
      mutationFn: (formData) => commonApi.createImage(formData, menuId),
    });
    return mutate;
  },
};

export default commonMutation;
