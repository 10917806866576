import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { ProjectSalesInfoCollectionManagementModalWrapper } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/components/modal-wrapper';
import { ProjectSalesInfoContractEarningsItemDetailUpdateModalTable } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/components/detail-update-modal-table';
import { ProjectSalesInfoContractEarningsItemUpdateModalProvider } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/widgets/provider';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Widget as ProjectSalesInfoContractEarningsItemUpdateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const { Body, Layout } = collectionModalLayout;
  const { isDetailUpdateModalOpen, setIsDetailUpdateModalOpen } = useContractEarningsItemState(
    useShallow((state) => ({
      isDetailUpdateModalOpen: state.isDetailUpdateModalOpen,
      setIsDetailUpdateModalOpen: state.setIsDetailUpdateModalOpen,
    }))
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="기성 수정"
      open={isDetailUpdateModalOpen}
      onClose={() => setIsDetailUpdateModalOpen()}
    >
      <ProjectSalesInfoContractEarningsItemUpdateModalProvider menuId={menuId}>
        <ProjectSalesInfoCollectionManagementModalWrapper />
        <Layout width="100%">
          <Body>
            <ProjectSalesInfoContractEarningsItemDetailUpdateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoContractEarningsItemUpdateModalProvider>
    </ModalLayout>
  );
};
