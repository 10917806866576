import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { CollectionFinalTableHead } from '@front/src/features/project-sales/features/tabs/collection/sections/final/components/table-head';
import { CollectionFinalTableBody } from '@front/src/features/project-sales/features/tabs/collection/sections/final/components/table-body';
import collectionFinalQuery from '@front/src/features/project-sales/features/tabs/collection/sections/final/query/query';
import { SummaryRenderer } from '@front/src/components/ui-builder/table/HeaderSummaryRow';

export { Table as CollectionFinalTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const { data: list } = collectionFinalQuery.useList({
    dataId: sectionProps.sectionId,
    menuId: sectionProps.sectionId,
    sectionId: sectionProps.sectionId,
  });

  const summaryRenderer: SummaryRenderer = {
    paymentSequence: (summaryData) => {
      return '-';
    },
    clientRatio: (summaryData) => {
      return '-';
    },
    structuralReviewRatio: (summaryData) => {
      const totalContractPrice = summaryData['totalContractPrice'];
      const structuralReviewPrice = summaryData['structuralReviewPrice'];
      if (totalContractPrice && structuralReviewPrice) {
        return `${Math.round((structuralReviewPrice / totalContractPrice) * 100)}%`;
      } else {
        return '-';
      }
    },
    cmRatioAgainstContractPrice: (summaryData) => {
      const totalContractPrice = summaryData['totalContractPrice'];
      const contractCM = summaryData['contractCM'];
      if (totalContractPrice && contractCM) {
        return `${Math.round((contractCM / totalContractPrice) * 100)}%`;
      } else {
        return '-';
      }
    }
  };

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={CollectionFinalTableHead}
      BodyComponent={CollectionFinalTableBody}
      readOnly={true} // 읽기모드에서는 동작버튼[:]을 제공하지 않는다
      useNote={false} // 노트 사용 여부
      useSummary={true} // 합산행 사용 여부
      summaryRenderer={summaryRenderer}
      list={list}
    />
  );
}
