import { Box, Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { isMobileDevice } from '@front/util/PwaUtil';
import { shallowEqual, useSelector } from 'react-redux';
import { NotificationBadge } from '@front/components/badge/badge';
import type { RootState } from '@front/services/reducer';
import type { MenuView } from '@front/src/types';
import Depth2Menu from '@front/src/features/global-nav-bar/widgets/list/components/Depth2Menu';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';

interface Props extends MenuView {}

export default function Depth1Menu(props: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const menuOpen = useSelector((root: RootState) => root.menu.open, shallowEqual);
  const { onClick, clickable, active, hasChild } = useGnbLogic({
    path: props.path,
    hasDrawer: props.hasDrawer,
    children: props.children,
    id: props.id,
  });
  useEffect(() => {
    if (isMobileDevice() && menuOpen) navigate('/');
  }, [menuOpen, navigate]);

  return (
    <Box
      onClick={onClick}
      sx={{
        width: '100%',
        marginBottom: '5px',
        color: ColorPalette._ffffff,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        cursor: clickable ? 'pointer' : 'default',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          height: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: active ? ColorPalette._4c9eeb : 'transparent',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={props.icon}
            style={{
              padding: '0 10px',
              fontSize: '16px',
            }}
          />
          <NotificationBadge
            count={props.count}
            type="menu"
          >
            <Typography
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {props.title}
            </Typography>
          </NotificationBadge>
        </Box>
        {hasChild && (
          <FontAwesomeIcon
            icon="angle-up"
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              cursor: 'pointer',
              transition: 'transform .2s',
              transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
              color: ColorPalette._697183,
              padding: '0 10px',
              fontSize: '13px',
            }}
          />
        )}
      </Box>
      {hasChild && (
        <Collapse
          in={open}
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              backgroundColor: ColorPalette._242e43,
              marginTop: '5px',
              alignContent: 'flex-start',
            }}
          >
            {props.children.map((child) => {
              if (child.depth === null) return;
              return (
                <Depth2Menu
                  key={child.id}
                  {...child}
                />
              );
            })}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}
