import type { SalesInfoActivityUpdateParams } from '@front/src/features/project-sales-info/features/activity/types/parameters';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';

export { Context as ProjectSalesInfoActivityItemContext };

interface State {
  onActivityUpdate: (params: SalesInfoActivityUpdateParams) => void;
  onUserCreate: (activityId: number) => void;
}

const Context = createContext<State>({
  onActivityUpdate: noOp,
  onUserCreate: noOp,
});
