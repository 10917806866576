import React, { memo, useCallback } from 'react';

import EstimatorForm from '@front/ost_campaign/view/detail/EstimatorForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { campaignAction } from '@front/ost_campaign/action';
import type { RootState } from '@front/services/reducer';
import type { UserId } from '@front/user/domain';
import useDialog from '@front/dialog/hook';
import ModalAddEstimatorService from '@front/ost_campaign/service/modal/addCampaignEstimatorService';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

const CampaignEstimatorFormService = () => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();

  const [estimatorList] = useSelector(
    (root: RootState) => [root.campaign.estimatorList],
    shallowEqual
  );
  const isModalOpen = useSelector(
    (root: RootState) => root.campaign.addCampaignEstimatorModalOpen,
    shallowEqual
  );

  const addEstimatorHandler = useCallback(() => {
    dispatch(campaignAction.setAddCampaignEstimatorModalOpen(true));
  }, [dispatch]);

  const deleteEstimatorHandler = useCallback(
    (userId: UserId) => () => {
      confirm({
        children: '해당 심사 담당자를 삭제하시겠습니까?',
        afterConfirm: () => {
          if (estimatorList.length <= 1) {
            dispatch(
              snackbarAction.show({
                message: '최소 한 명의 심사 담당자가 필요합니다.',
                severity: SnackbarSeverityType.warning,
              })
            );
            return;
          }
          dispatch(campaignAction.deleteCampaignEstimator(userId));
        },
        confirmText: '확인',
      });
    },
    [confirm, dispatch, estimatorList]
  );

  return (
    <>
      <EstimatorForm
        estimatorList={estimatorList}
        onAdd={addEstimatorHandler}
        onDelete={deleteEstimatorHandler}
      />
      {isModalOpen && <ModalAddEstimatorService />}
    </>
  );
};

const EstimatorFormService = memo(CampaignEstimatorFormService);

export default EstimatorFormService;
