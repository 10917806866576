import React from 'react';
import Box from '@mui/material/Box';
import { type UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { UserSelectorUnchangedMultiFormTFieldValues } from '@front/src/features/user-selector/features/unchanged-multi-form';
import AvatarUI from '@front/src/components/components-with-design/component/AvatarUI';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  fields: Record<'id', string>[];
  remove: UseFieldArrayRemove;
}

export default function UserSelectorUnchangedMultiFormSelectedList(props: Readonly<Props>) {
  const { remove, fields } = props;
  const { getValues, watch } = useFormContext<UserSelectorUnchangedMultiFormTFieldValues>();
  const unchangeableUserList = watch('unchangeableUserList');
  const handleClick = (index) => {
    remove(index);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '136px',
        height: '360px',
        padding: '10px',
        gap: '10px',
        border: `1px solid ${ColorPalette.line.line}`,
        borderRadius: '5px',
      }}
    >
      {unchangeableUserList.length + fields.length === 0 && (
        <TypographyUI>선택된 유저 없음</TypographyUI>
      )}
      {unchangeableUserList.map((user) => (
        <Box
          key={user.id}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <AvatarUI size="small" />
          <TypographyUI>{user.name}</TypographyUI>
        </Box>
      ))}
      {fields.length > 0 &&
        fields.map((f, i) => {
          const item = getValues(`changeableUserList.${i}`);
          return (
            <Box
              key={f.id}
              onClick={() => handleClick(i)}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
              }}
            >
              <AvatarUI size="small" />
              <TypographyUI>{item.name}</TypographyUI>
              <ButtonIconUI
                shape="quaternary"
                size="medium"
              >
                <FontAwesomeIcon icon="xmark" />
              </ButtonIconUI>
            </Box>
          );
        })}
    </Box>
  );
}
