import React, { useCallback } from 'react';
import TextBox from '@front/layouts/Text';
import { Box, type TextFieldProps } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ProceedingsStatus, type ProceedingsVO } from '@front/proceedings/domain';
import dayjs from 'dayjs';
import Input from '@front/layouts/Input';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { ProceedingsUpdateParameter } from '@front/proceedings/parameter';
import { useDispatch } from 'react-redux';

interface Props {
  detail: ProceedingsVO;
  update: (params: ProceedingsUpdateParameter) => void;
  isReader: boolean;
  isWriter: boolean;
  isAttendance: boolean;
}

const MeetingInfoSection = ({ detail, update, isReader, isAttendance }: Props) => {
  const dispatch = useDispatch();
  const [isStartDateTimePickerOpen, setIsStartDateTimePickerOpen] = React.useState(false);
  const [isEndDateTimePickerOpen, setIsEndDateTimePickerOpen] = React.useState(false);
  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );
  return (
    <>
      <TextBox
        variant={'body7'}
        sx={{
          width: '13%',
          marginBottom: '10px',
        }}
      >
        회의일시 및 장소
      </TextBox>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          width: '100%',
          border: `1px solid ${ColorPalette._e4e9f2}`,
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <DataFieldWithLabel
          label="회의 시작"
          labelSX={{ minWidth: '80px' }}
        >
          <DateTimePicker
            readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
            inputFormat={'YYYY-MM-DD HH:mm'}
            ampm
            ampmInClock={true}
            disableMaskedInput={false}
            mask={'____-__-__ __:__'}
            onOpen={() => {
              setIsStartDateTimePickerOpen(true);
            }}
            onClose={() => {
              setIsStartDateTimePickerOpen(false);
            }}
            onAccept={(e) => {
              const value = e ? dayjs(e).format('YYYY-MM-DD HH:mm:00') : '';
              const error = !value || !isValidDate(value);
              if (value) {
                if (error) {
                  openSnackbar('올바르지 않은 날짜 형식입니다.');
                } else {
                  update({
                    startTime: value,
                  });
                  setIsStartDateTimePickerOpen(false);
                }
              } else {
                update({
                  startTime: '',
                });
              }
            }}
            onChange={(e) => {
              const value = e ? dayjs(e).format('YYYY-MM-DD HH:mm:00') : '';
              const error = !value || !isValidDate(value);
              if (value) {
                if (error) {
                  openSnackbar('올바르지 않은 날짜 형식입니다.');
                } else {
                  if (isStartDateTimePickerOpen) {
                    return;
                  }
                  update({
                    startTime: value,
                  });
                }
              } else {
                update({
                  startTime: '',
                });
              }
            }}
            value={detail.startTime ? dayjs(detail.startTime).format('YYYY-MM-DD HH:mm') : null}
            renderInput={renderDateInput}
          />
        </DataFieldWithLabel>
        <DataFieldWithLabel
          label="회의 종료"
          labelSX={{ minWidth: '80px' }}
        >
          <DateTimePicker
            readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
            inputFormat={'YYYY-MM-DD HH:mm'}
            ampm
            ampmInClock={true}
            disableMaskedInput={false}
            mask={'____-__-__ __:__'}
            onOpen={() => {
              setIsEndDateTimePickerOpen(true);
            }}
            onClose={() => {
              setIsEndDateTimePickerOpen(false);
            }}
            onAccept={(e) => {
              const value = e ? dayjs(e).format('YYYY-MM-DD HH:mm:00') : '';
              const error = !value || !isValidDate(value);
              if (value) {
                if (error) {
                  openSnackbar('올바르지 않은 날짜 형식입니다.');
                } else {
                  update({
                    endTime: value,
                  });
                }
              } else {
                update({
                  endTime: '',
                });
              }
            }}
            onChange={(e) => {
              const value = e ? dayjs(e).format('YYYY-MM-DD HH:mm:00') : '';
              const error = !value || !isValidDate(value);
              if (value) {
                if (error) {
                  openSnackbar('올바르지 않은 날짜 형식입니다.');
                } else {
                  if (isEndDateTimePickerOpen) {
                    return;
                  }
                  update({
                    endTime: value,
                  });
                }
              } else {
                update({
                  endTime: '',
                });
              }
            }}
            value={detail.endTime ? dayjs(detail.endTime).format('YYYY-MM-DD HH:mm') : null}
            renderInput={renderDateInput}
          />
        </DataFieldWithLabel>
        <DataFieldWithLabel
          label="회의 장소"
          labelSX={{ minWidth: '80px' }}
        >
          <Input
            readOnly={isReader || (isAttendance && detail.status !== ProceedingsStatus.READING)}
            variant="outlined"
            defaultValue={detail.place}
            onBlur={(e) => {
              const value = e.target.value;
              if (value === '') {
                update({
                  place: value,
                });
                return;
              }

              if (value !== detail.place) {
                update({
                  place: value,
                });
              }
            }}
          />
        </DataFieldWithLabel>
      </Box>
    </>
  );
};

function isValidDate(strDate: string) {
  return strDate && dayjs(strDate, 'YYYY-MM-DD HH:mm:00', true).isValid();
}

function renderDateInput(parameter: TextFieldProps) {
  const value = parameter.inputProps?.value;
  const error = value != '' && !isValidDate(value);
  return (
    <Input
      {...parameter.InputProps}
      inputRef={parameter.inputRef}
      variant="outlined"
      inputProps={parameter.inputProps}
      error={error}
    />
  );
}

export default MeetingInfoSection;
