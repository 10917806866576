import React from 'react';
import ProposalMemoService from 'ost_proposal/service/memo';
import ProposalDetailService from 'ost_proposal/service/detail';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import { useMobileMenuState } from '@front/mobile-menu/useState';
import { useOstAuth } from '@front/src/hooks/useOstAuth';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const OstProposalDetailRoute = ({ name }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const isMemo = useMobileMenuState((state) => state.isMemo);
  useOstAuth('/ost/proposal-center');
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <PageLayoutWithMemo>
          <ProposalDetailService />
          {isMemo && <ProposalMemoService />}
        </PageLayoutWithMemo>
      </Body>
    </Layout>
  );
};

export default OstProposalDetailRoute;
