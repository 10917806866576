import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyPersonEducationQuery } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/query/query';
import { affiliatedCompanyPersonEducationMutation } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/query/mutation';

export const affiliatedCompanyPersonEducationRepository = {
  useCreate: (menuId) => {
    const { mutate } = affiliatedCompanyPersonEducationMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (id) => {
        mutate(id, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useListGet: (id?: number, menuId?) => {
    const { data, remove } = affiliatedCompanyPersonEducationQuery.useListGet(id, menuId);
    return {
      data,
      remove,
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyPersonEducationMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: (menuId) => {
    const { mutate } = affiliatedCompanyPersonEducationMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
