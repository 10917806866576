import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type { ProjectContractReviewView } from '@front/src/features/project-sales-info/features/contract/features/reivew/types/view';

export { context as ProjectSalesInfoContractReviewContext };

interface State {
  d: {
    list?: ProjectContractReviewView[];
  };
  h: {
    onDelete: (params: IdListDeleteParameter) => void;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
});
