import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyCareerApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/query/api';
import type { AffiliatedCompanyPersonOutlineCareerUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/career/types/parameter';

export const affiliatedCompanyCareerMutation = {
  usePersonOutlineCareerCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => affiliatedCompanyCareerApi.createPersonOutlineCareer(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'career'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonOutlineCareerUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonOutlineCareerUpdateParameter) =>
        affiliatedCompanyCareerApi.updatePersonOutlineCareer(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'career'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonOutlineCareerDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyCareerApi.deletePersonOutlineCareer(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'outline', 'career'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
