import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TableCell, TableRow } from '@mui/material';
import Checkbox from '@front/layouts/Checkbox';
import { OldTd } from '@front/layouts/Table';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import type { AffiliatedCompanyCharacterOpinionView } from '@front/src/features/affiliated-company/features/character/features/opinion/types/view';
import { AffiliatedCompanyCharacterOpinionContext } from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/context';
import type { AffiliatedCompanyCharacterOpinionUpdateParameter } from '@front/src/features/affiliated-company/features/character/features/opinion/types/parameter';
import {
  preferenceLevelList,
} from '@front/src/features/affiliated-company/utils/constant';
import { AffiliatedCompanyCharacterOpinionTableRowSelector } from '@front/src/features/affiliated-company/features/character/features/opinion/components/opinion-table-row-selector';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import DatePickerWithHookForm from "@front/src/components/hook-form/DatePicker";

interface Props {
  item: AffiliatedCompanyCharacterOpinionView;
  index: number;
}

export { OpinionTableRow as AffiliatedCompanyCharacterOpinionTableRow };
const OpinionTableRow = ({ item }: Props) => {
  const {
    h: { setIdList, onCharacterUpdate },
    idList,
  } = useContext(AffiliatedCompanyCharacterOpinionContext);
  const methods = useForm<AffiliatedCompanyCharacterOpinionUpdateParameter>({
    values: {
      id: item.id,
      date: item.date ?? null,
      affiliatedCompanyId: item.competitor?.id,
      reason: item.reason ?? '',
      preferenceLevel: item.preferenceLevel ?? '',
      source: item.source ?? '',
    },
  });
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const onSubmit = methods.handleSubmit((data) => {
    onCharacterUpdate({
      ...data,
      date: item.date && dayjs(item.date).format(YYYY_MM_DD),
    });
  });
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <TableRow
        style={{
          height: '100%',
        }}
        key={Math.random()}
      >
        <OldTd>
          <Checkbox
            checked={idList.includes(item.id)}
            onChange={handleCheckboxChange(item.id)}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <DatePickerWithHookForm
            name="date"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          <AffiliatedCompanyCharacterOpinionTableRowSelector
            item={item}
            onSubmit={onSubmit}
          />
        </OldTd>
        <OldTd>
          <HookFormInput
            name="reason"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <TableCell>
          <HookFormSelect
            name="preferenceLevel"
            defaultLabel="선택"
            optionList={preferenceLevelList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </TableCell>
        <OldTd>
          <HookFormInput
            name="source"
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
