import React, { useCallback } from 'react';
import type {
  PersonModalShortView,
  ProjectStepInformationView,
} from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  item: ProjectStepInformationView;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export { SelectorWrapper as ProjectSalesInfoProjectDivisionSelectorWrapper };

const SelectorWrapper = ({ item, onSubmit }: Props) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { setValue } = useFormContext();
  const handleSelector = useCallback(
    async (_, person?: AffiliatedCompanyPersonShortView) => {
      setValue('affiliatedCompanyManager', person?.id);
      await onSubmit();
    },
    [onSubmit, setValue]
  );
  const { readOnly } = useProjectSalesInfoProjectDivisionState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <AffiliatedCompanySelectorWidget
        position="top"
        value={{
          personId: (item.affiliatedCompanyManager as PersonModalShortView)?.id,
          textValue: (item.affiliatedCompanyManager as PersonModalShortView)?.name,
        }}
        usePersonSelector={true}
        onPersonSelect={handleSelector}
        exclude={[id!]}
        disabled={readOnly}
      />
    </div>
  );
};
