// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uz1dMyQBl2mrZx7ydXdP{display:flex;justify-content:space-between;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,UAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Uz1dMyQBl2mrZx7ydXdP`
};
export default ___CSS_LOADER_EXPORT___;
