import React from 'react';
import useDialog from '@front/dialog/hook';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import type { ArrangementAttachedFileId, AttachFileVO } from '@front/ost_proposal/domain';
import {
  AttachedFileId,
  ProposalArrangementInformationStatus,
  ProposalStatus,
} from '@front/ost_proposal/domain';
import { OldTd } from '@front/layouts/Table';
import dayjs from 'dayjs';
import TextLink from '@front/layouts/TextLink';
import { Box, TableRow } from '@mui/material';
import Button from '@front/layouts/Button';
import ImageModalService from '@front/ost_proposal/service/modal/imageModal';

interface Props {
  item: AttachFileVO;
  index: number;
  deleteArrangementAttachedFile: (id: ArrangementAttachedFileId) => void;
  isAdvisor: boolean;
}

export { Item as ArrangementFileItem };
const Item = ({ item, index, deleteArrangementAttachedFile, isAdvisor }: Props) => {
  const { confirm } = useDialog();
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );

  return (
    <TableRow key={`${item.file.id}_${index}`}>
      <OldTd>{dayjs(item.file.createdAt).format('YYYY-MM-DD')}</OldTd>
      <OldTd>
        <TextLink
          onClick={() => {
            window.location.assign(`/api/file-item/${item.file.id}`);
          }}
        >
          {item.file.filename}
        </TextLink>
      </OldTd>

      <OldTd>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            {status === ProposalStatus.WRITING &&
              isAdvisor &&
              arrangementStatus === ProposalArrangementInformationStatus.ON_GOING && (
                <Button
                  shape="basic3"
                  onClick={() => {
                    confirm({
                      children: '해당 첨부 파일을 삭제하시겠습니까?',
                      confirmText: '확인',
                      afterConfirm: () => {
                        deleteArrangementAttachedFile(AttachedFileId(item.id));
                      },
                    });
                  }}
                >
                  삭제
                </Button>
              )}
            {['png', 'jpg', 'jpeg'].includes(item.file.ext) && <ImageModalService item={item} />}
          </>
        </Box>
      </OldTd>
    </TableRow>
  );
};
