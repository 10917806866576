import { createContext } from 'react';

import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineBasicView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/view';
import type { AffiliatedCompanyPersonOutlineBasicUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/basic/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineBasicContext };

interface State {
  d: { personOutlineBasic?: AffiliatedCompanyPersonOutlineBasicView };
  h: {
    onPersonOutlineBasicUpdate: (
      params: AffiliatedCompanyPersonOutlineBasicUpdateParameter
    ) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onPersonOutlineBasicUpdate: noOp,
  },
});
