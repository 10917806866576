import React from 'react';
import { TableRow as MuiTableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { getLocaleStringIfExist, getValueIfExist } from '@front/src/utils';
import type { DailyReportStatusView } from '@front/src/features/accounting/features/settings/features/daily-report-status/types/view';

export { TableRow as AccountingSettingsDailyReportStatusListTableRow };

interface Props {
  item: DailyReportStatusView;
  index: number;
  list: DailyReportStatusView[];
}

const TableRow = ({ item, index, list }: Props) => (
  <MuiTableRow>
    <OldTd>
      {index > 0 && list[index].category === list[index - 1].category ? '' : item.category}
    </OldTd>
    <OldTd>{getValueIfExist(item.name)}</OldTd>
    <OldTd>{getValueIfExist(item.accountCode)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.balanceCarriedForward)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.balanceIncrement)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.balanceDecrement)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.balance)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.balanceAtAccount)}</OldTd>
    <OldTd>{getLocaleStringIfExist(item.difference)}</OldTd>
  </MuiTableRow>
);
