import { create } from 'zustand';

export { useState as useListDrawerState };

interface State {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggle: () => void;

  isResizeActive: boolean;
  setIsResizeActive: (isResizeActive: boolean) => void;

  currentWidth: number;
  setCurrentWidth: (currentWidth: number) => void;
}

const useState = create<State>((set) => ({
  open: true,
  setOpen: (open) => set(() => ({ open })),
  toggle: () => set(({ open }) => ({ open: !open })),
  isResizeActive: false,
  setIsResizeActive: (isResizeActive) => set(() => ({ isResizeActive })),

  currentWidth: 560,
  setCurrentWidth: (currentWidth: number) => set(() => ({ currentWidth })),
}));
