import type { ReactNode } from 'react';
import React from 'react';
import { Table as MuiTable } from '@front/layouts/Table';
import { TableBody, TableContainer, TableHead } from '@mui/material';

export { Table as AccountingSettingsManagementAccountingReadTable };

interface Props {
  header: ReactNode;
  body: ReactNode;
}

const Table = ({ header, body }: Props) => (
  <TableContainer>
    <MuiTable>
      <TableHead>{header}</TableHead>
      <TableBody>{body}</TableBody>
    </MuiTable>
  </TableContainer>
);
