import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyProjectListGetParameter } from '@front/src/features/affiliated-company/features/project/types/parameter';
import { affiliatedCompanyProjectURL } from '@front/src/features/affiliated-company/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

export { Api as AffiliatedCompanyProjectApi };

const url = {
  getList: (id?: number) => `${affiliatedCompanyProjectURL}/project/${id}`,
};

const Api = {
  getList: async (params: AffiliatedCompanyProjectListGetParameter, menuId) => {
    const { data } = await axios.get(url.getList(params.id), {
      params,
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
