import React from 'react';
import { AffiliatedPersonMemoUpdateProvider } from '@front/src/features/affiliated-person/features/memo/widgets/update/provider/provider';
import { AffiliatedPersonMemoUpdateButton } from '@front/src/features/affiliated-person/features/memo/widgets/update/components/update-button';

export { Widget as AffiliatedPersonMemoUpdateWidget };

const Widget = () => (
  <AffiliatedPersonMemoUpdateProvider>
    <AffiliatedPersonMemoUpdateButton />
  </AffiliatedPersonMemoUpdateProvider>
);
