import { projectSalesInfoProjectDivisionModalRepository } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useProjectSalesInfoProjectDivisionModalState } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useQueryClient } from 'react-query';
import { useProjectSalesInfoProjectDivisionState } from '@front/src/features/project-sales-info/features/project-division/widgets/useState';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';

export { useLogic as useProjectSalesInfoProjectDivisionModalLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { setIdList, idList, setId } = useProjectSalesInfoProjectDivisionModalState(
    (state) => ({
      setIdList: state.setIdList,
      idList: state.idList,
      setId: state.setId,
    }),
    shallow
  );
  const { isModalOpen, setIsModalOpen } = useProjectSalesInfoProjectDivisionState(
    (state) => ({ isModalOpen: state.isModalOpen, setIsModalOpen: state.setIsModalOpen }),
    shallow
  );
  const { data: projectDivisionModalData, isLoading } =
    projectSalesInfoProjectDivisionModalRepository.useProjectDivisionModalGet(id, menuId);
  const { run: onCreate, setCallback } =
    projectSalesInfoProjectDivisionModalRepository.useProjectDivisionModalCreate(id!, menuId);
  setCallback({
    onSuccess: ({ data }) => {
      setId(data);
    },
  });
  const { run: onDelete } =
    projectSalesInfoProjectDivisionModalRepository.useProjectDivisionModalDelete(id!, menuId);
  const { run: onUpdate } =
    projectSalesInfoProjectDivisionModalRepository.useProjectDivisionModalUpdate(id!, menuId);
  const { data: businessProgressStepOptionList } = adminManageableVariableRepository.useListGet(
    '영업관리-사업진행단계',
    menuId
  );
  const queryClient = useQueryClient();

  const onModalClose = async () => {
    setIsModalOpen();
    setId(-1);
    await queryClient.invalidateQueries({
      queryKey: ['project-sales-info', 'project-division', id],
    });
  };

  return {
    d: {
      projectDivisionModalData,
      businessProgressStepOptionList,
    },
    h: {
      onCreate,
      onDelete,
      onUpdate,
      setIdList,
      onModalClose,
    },
    isLoading,
    idList,
    isModalOpen,
  };
};
