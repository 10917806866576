import React from 'react';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import useWorkNameGetIsLoading from '@front/src/features/work/features/work/features/name/hooks/useGetIsLoading';

export default function WorkNameTableMutationLoadingSpinner() {
  const isLoading = useWorkNameGetIsLoading();
  return (
    <BackdropLoadingSpinnerUI
      sx={{
        position: 'absolute',
      }}
      open={isLoading}
    />
  );
}
