import { projectSalesInfoContractEarningsItemRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useState } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { collectionPossibilityKeyword } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/features/manage-collection-modal/utils/constant';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/repository';
import { bondClassificationKeyword } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/utils/constant';

export { useLogic as useProjectSalesInfoContractEarningsItemLogic };
interface Props {
  itemId: number;
  menuId?: number;
}

const useLogic = ({ itemId, menuId }: Props) => {
  const [idList, setIdList] = useState<number[]>([]);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { run: onDelete } =
    projectSalesInfoContractEarningsItemRepository.useContractEarningsDelete(itemId, id!, menuId);
  const { run: onDeleteDetails } =
    projectSalesInfoContractEarningsItemRepository.useContractEarningsItemDetailsDelete(
      itemId,
      id!,
      menuId
    );
  const { data: collectionPossibilityOptionList } = adminManageableVariableRepository.useListGet(
    collectionPossibilityKeyword,
    menuId
  );
  const { run: onUpdate } =
    projectSalesInfoContractEarningsItemRepository.useContractEarningsItemDetailsUpdate(
      id!,
      menuId
    );
  const { data: contractEarningsItemList, isLoading } =
    projectSalesInfoContractEarningsItemRepository.useContractEarningsItemGet(itemId, menuId);
  const { data: affiliatedCompanyPersonList } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonRepository.useListGet(id!, menuId);
  const { data: bondClassificationOptionList } = adminManageableVariableRepository.useListGet(
    bondClassificationKeyword,
    menuId
  );
  return {
    h: {
      onDelete,
      onDeleteDetails,
      onUpdate,
    },
    d: {
      contractEarningsItemList,
      collectionPossibilityOptionList,
      affiliatedCompanyPersonList,
      bondClassificationOptionList,
    },
    isLoading,
    idList,
    setIdList,
  };
};
