import React, { memo, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import CategoryForm from '@front/ost_campaign/view/detail/CategoryForm';
import { campaignAction } from '@front/ost_campaign/action';
import type { ProposalCategoryId } from '@front/ost_campaign/domain';
import type { RootState } from '@front/services/reducer';
import useDialog from '@front/dialog/hook';
import ModalAddCategoryService from '@front/ost_campaign/service/modal/addProposalCategoryService';

import type { CampaignUpdateProposalCategoryParameter } from '@front/ost_campaign/parameter';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

const ProposalCategoryFormService = () => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const isOpenModal = useSelector(
    (root: RootState) => root.campaign.addProposalCategoryModalOpen,
    shallowEqual
  );

  const [proposalCategoryList] = useSelector(
    (root: RootState) => [root.campaign.proposalCategoryList],
    shallowEqual
  );

  const onAdd = () => {
    dispatch(campaignAction.setAddProposalCategoryModalOpen(true));
  };

  const onDelete = (proposalCategoryId: ProposalCategoryId) => () => {
    confirm({
      children: '해당 제안 구분을 삭제하시겠습니까?',
      afterConfirm: () => {
        if (proposalCategoryList.length <= 1) {
          dispatch(
            snackbarAction.show({
              message: '최소 하나의 제안 구분이 필요합니다.',
              severity: SnackbarSeverityType.warning,
            })
          );
          return;
        }
        dispatch(campaignAction.deleteProposalCategory(proposalCategoryId));
      },
      confirmText: '확인',
    });
  };

  const onSubmit = useCallback(
    (params) => {
      dispatch(campaignAction.updateProposalCategoryList(params));
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      campaignProposalCategoryList: [],
    } as CampaignUpdateProposalCategoryParameter,
    onSubmit: onSubmit,
  });

  const onChange = (id) => () => {
    const list: ProposalCategoryId[] = [
      ...proposalCategoryList.filter((category) => category.isUsed).map((category) => category.id),
    ];
    const index = list.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    formik.setFieldValue('campaignProposalCategoryList', list);
    formik.handleSubmit();
  };

  return (
    <>
      <CategoryForm
        onChange={onChange}
        proposalCategoryList={proposalCategoryList}
        onAdd={onAdd}
        onDelete={onDelete}
      />
      {isOpenModal && <ModalAddCategoryService />}
    </>
  );
};

const CategoryFormService = memo(ProposalCategoryFormService);
export default CategoryFormService;
