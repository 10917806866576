import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import type { PathRouteProps } from 'react-router/dist/lib/components';
import {
  projectCollectionPageRoute,
  projectOrdersPageRoute,
  projectTasksPageRoute,
} from '@front/src/app/component/notReadyPage';
import newRoutes from '@front/src/routes';
import loginRoute from '@front/login/route/login';
import loginForgotRoute from '@front/login/route/passwordForgotten';
import userDetailRoute, { userAddRoute } from '@front/user/route/detail';
import userPageRoute from '@front/user/route/page';
import ganttPageRoute from '@front/gantt/route/page';
import passwordToChangeRoute from '@front/login/route/passwordToChange';
import passwordInvalidRoute from '@front/login/route/passwordInvalid';
import defaultPageRoute from '@front/src/app/component/defaultPage';

export interface AppRoute extends PathRouteProps {
  // extension wrapper
}

const Routes = () => {
  const routes: AppRoute[] = [
    loginRoute,
    loginForgotRoute,
    userDetailRoute,
    userPageRoute,
    ganttPageRoute,
    passwordToChangeRoute,
    passwordInvalidRoute,
    defaultPageRoute,
    projectCollectionPageRoute,
    projectOrdersPageRoute,
    projectTasksPageRoute,
    userAddRoute,
    ...newRoutes,
  ];

  return useRoutes(routes as RouteObject[]);
};

export default Routes;
