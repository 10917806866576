import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type {
  WorkOutputContentUpdateParameter,
  WorkOutputFileUpdateParameter,
} from '@front/src/features/work/features/work/features/output/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { useMutation, useQueryClient } from 'react-query';

const workOutputMutation = {
  useAddTop: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.createOutputTop(bundleId, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'add-top'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useAddBottom: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.createOutputBottom(bundleId, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'add-bottom'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useCreate: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.createOutput(id, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useUpdate: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkOutputContentUpdateParameter) =>
        workWorkApi.updateOutput(params, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', bundleId, 'output'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useUpdateFile: (commonParams, bundleId?: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkOutputFileUpdateParameter) =>
        workWorkApi.updateOutputFile(params, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'update-file'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useSeqUp: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateOutputSeqUp(id, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'seq-up'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useSeqDown: (bundleId: number, commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateOutputSeqDown(id, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'seq-down'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'carousel'],
        });
      },
    });
    return {
      mutate,
    };
  },

  useDelete: (bundleId: number, commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteOutput(id, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },

  useDeleteAll: (bundleId: number, commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.deleteAllOutput(bundleId, commonParams),
      mutationKey: ['work', 'output-bundle', 'output', bundleId, 'delete-all'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'output-bundle', 'output', bundleId],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workOutputMutation;
