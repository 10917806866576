export const enum SalesAccountingAmountType {
  ORDER = 'ORDER',
  REVIEW = 'REVIEW',
  NET_ORDER = 'NET_ORDER',
  COLLECTION = 'COLLECTION',
  SAVED_REVIEW = 'SAVED_REVIEW',
  NET_COLLECTION = 'NET_COLLECTION',
  NET_ORDER_BALANCE = 'NET_ORDER_BALANCE',
  UNPAID_AMOUNT = 'UNPAID_AMOUNT',
  EXPECTED_AMOUNT = 'EXPECTED_AMOUNT',
  UNCLAIMED_AMOUNT = 'UNCLAIMED_AMOUNT',
}

export const SalesAccountingAmountTypeObjectForLabel = {
  [SalesAccountingAmountType.ORDER]: '수주',
  [SalesAccountingAmountType.REVIEW]: '구검',
  [SalesAccountingAmountType.NET_ORDER]: '순수주',
  [SalesAccountingAmountType.COLLECTION]: '수금',
  [SalesAccountingAmountType.SAVED_REVIEW]: '적립구검',
  [SalesAccountingAmountType.NET_COLLECTION]: '순수금',
  [SalesAccountingAmountType.NET_ORDER_BALANCE]: '순수주 잔고',
  [SalesAccountingAmountType.UNPAID_AMOUNT]: '미수금',
  [SalesAccountingAmountType.EXPECTED_AMOUNT]: '응당 예정금',
  [SalesAccountingAmountType.UNCLAIMED_AMOUNT]: '미착수금',
};

export const enum SalesAccountingAmountSubType {
  TARGET = 'TARGET',
  EXPECTED = 'EXPECTED',
  PERFORMANCE = 'PERFORMANCE',
}

export const SalesAccountingAmountSubTypeObjectForLabel = {
  [SalesAccountingAmountSubType.TARGET]: '목표',
  [SalesAccountingAmountSubType.EXPECTED]: '예상',
  [SalesAccountingAmountSubType.PERFORMANCE]: '실적',
};
