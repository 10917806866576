import { AccountingSettingsManagementAccountingTableLayoutTable } from '@front/src/features/accounting/features/settings/components/layouts/table/table';
import { AccountingSettingsManagementAccountingTableLayoutTableBody } from '@front/src/features/accounting/features/settings/components/layouts/table/table-body';
import { AccountingSettingsManagementAccountingTableLayoutTableHeader } from '@front/src/features/accounting/features/settings/components/layouts/table/table-header';
import { AccountingSettingsManagementAccountingTableLayoutTableHeaderCell } from '@front/src/features/accounting/features/settings/components/layouts/table/table-header-cell';
import { AccountingSettingsManagementAccountingTableLayoutTableRowFlexColumn } from '@front/src/features/accounting/features/settings/components/layouts/table/table-row-flex-column';
import { AccountingSettingsManagementAccountingTableLayoutTableBodyCell } from '@front/src/features/accounting/features/settings/components/layouts/table/table-body-cell';

export { index as accountingSettingsManagementAccountingTableLayout };

const index = {
  Table: AccountingSettingsManagementAccountingTableLayoutTable,
  TableHeader: AccountingSettingsManagementAccountingTableLayoutTableHeader,
  TableHeaderCell: AccountingSettingsManagementAccountingTableLayoutTableHeaderCell,
  TableRowFlexColumn: AccountingSettingsManagementAccountingTableLayoutTableRowFlexColumn,
  TableBody: AccountingSettingsManagementAccountingTableLayoutTableBody,
  TableBodyCell: AccountingSettingsManagementAccountingTableLayoutTableBodyCell,
};
