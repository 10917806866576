import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

import type { UIBuilderParameter } from '@front/src/types/parameter';
import workCategoryMutation from '@front/src/features/work/features/work/features/category/query/mutation';

const useWorkCategoryUpdate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const mutate = workCategoryMutation.useUpdate(commonParams);
  return {
    run: (params) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkCategoryUpdate;
