import React, { memo, useCallback, useContext, useEffect } from 'react';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/useState';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/useState';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/affiliated-company-person-table-row.module.scss';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { convertNullToEmptyForForm, getValueIfEmpty, getValueIfExist } from '@front/src/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import {
  affiliatedCompanyPersonContributionOptionForLabel,
  affiliatedCompanyPersonContributionOptionList,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/utils/constant';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/widgets/context';
import {
  convertAffiliatedCompanyPersonIsKeyPersonToForm,
  convertAffiliatedCompanyPersonIsKeyPersonToParameter,
  convertContributionForParameter,
} from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/utils';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRowCheckBox } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/components/check-box';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useShallow } from 'zustand/react/shallow';
import Checkbox from '@front/layouts/Checkbox';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export { AffiliatedCompanyPersonTableRow as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRow };

interface Props {
  item: ProjectAffiliatedCompanyPersonView;
}

const AffiliatedCompanyPersonTableRow = memo(({ item }: Props) => {
  const { ItemTableCell, Text } = AddDeleteTable;
  const {
    h: { onUpdate },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext);
  const { id, setId } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    useShallow((state) => ({
      id: state.id,
      setId: state.setId,
    }))
  );
  const { setIsOpen, setModalId, setPersonId } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState(
      useShallow((state) => ({
        setIsOpen: state.setIsOpen,
        setModalId: state.setModalId,
        setPersonId: state.setPersonId,
      }))
    );
  const methods = useForm({
    values: getValuesForForm(item),
  });
  const { getValues, handleSubmit, control, setValue } = methods;
  const handleClick = useCallback(() => {
    if (!item.person) return;
    setModalId(item.id);
    setPersonId(item.person?.id);
    setIsOpen(true);
  }, [setIsOpen, setModalId, setPersonId, item.id, item.person]);
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      ...data,
      id: item.id,
      isKeyPerson: convertAffiliatedCompanyPersonIsKeyPersonToParameter(data.isKeyPerson),
      contribution: convertContributionForParameter(data.contribution),
    });
  });
  const isSelected = item.id === id;
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const renderIfSelected = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <HookFormInput
            name={name}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValueIfEmpty(getValues(name))}</Text>;
    },
    [isSelected, onSubmit, getValues, Text, readOnly]
  );
  const {
    field: { value, onChange },
  } = useController({ name: 'isKeyPerson', control });
  const handleCheckboxChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setValue('isKeyPerson', 'O');
      } else {
        setValue('isKeyPerson', 'X');
      }
      onSubmit();
    },
    [onChange, onSubmit]
  );

  const renderIsKeyPersonIfSelected = useCallback(() => {
    if (isSelected) {
      return (
        <Checkbox
          checked={value === 'O'}
          onChange={handleCheckboxChange}
          disabled={readOnly}
        />
      );
    }
    return <Text>{value || '-'}</Text>;
  }, [isSelected, onSubmit, Text, readOnly]);
  const renderContributionIfSelected = useCallback(
    (name, optionList) => {
      if (isSelected) {
        return (
          <HookFormSelect
            isDefaultPossible
            defaultLabel="선택"
            name={name}
            optionList={optionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        );
      }
      return (
        <Text>
          {getValueIfExist(affiliatedCompanyPersonContributionOptionForLabel[getValues(name)])}
        </Text>
      );
    },
    [isSelected, onSubmit, getValues, Text, readOnly]
  );
  useEffect(() => {
    if (!isSelected) return;
    setValue('isKeyPerson', item.isKeyPerson ? 'O' : 'X');
  }, [isSelected, setValue]);

  const menuId = useGlobalNavBarState((state) => state.menuId);
  const loginUser = useSelector((root: RootState) => root.login.detail);
  const currentAuth = loginUser?.menus?.find((item) => item.id === (menuId ? +menuId : 0))
    ?.authorization.type;

  useEffect(() => {
    if (currentAuth === 'V') setId();
  }, [setId, currentAuth]);
  return (
    <FormProvider {...methods}>
      <ItemTableCell
        center
        height="56px"
      >
        <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonTableRowCheckBox item={item} />
      </ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.affiliatedCompany?.category)}</ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.affiliatedCompany?.name)}</ItemTableCell>
      <ItemTableCell center>
        <div
          className={classes.name}
          onClick={handleClick}
          aria-hidden
        >
          {getValueIfExist(item.person?.name)}
        </div>
      </ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.person?.position)}</ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.person?.department)}</ItemTableCell>
      <ItemTableCell center>{renderIsKeyPersonIfSelected()}</ItemTableCell>
      <ItemTableCell center>
        {renderContributionIfSelected(
          'contribution',
          affiliatedCompanyPersonContributionOptionList
        )}
      </ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.person?.directPhoneNumber)}</ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.person?.phoneNumber)}</ItemTableCell>
      <ItemTableCell center>{getValueIfExist(item.person?.email)}</ItemTableCell>
      <ItemTableCell center>{renderIfSelected('note')}</ItemTableCell>
    </FormProvider>
  );
});

const getValuesForForm = (item: ProjectAffiliatedCompanyPersonView) => ({
  contribution: convertNullToEmptyForForm(item.contribution),
  note: convertNullToEmptyForForm(item.note),
  isKeyPerson: convertAffiliatedCompanyPersonIsKeyPersonToForm(item.isKeyPerson),
});
