import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/payment-history/widgets/useState';
import { collectionModalLayout } from '@front/src/features/project-sales-info/features/collection/components/layout';
import { ProjectSalesInfoPaymentHistoryUpdateModalContext } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/widgets/context';
import { useProjectSalesInfoPaymentHistoryUpdateModalLogic } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoPaymentHistoryUpdateModalTable } from '@front/src/features/project-sales-info/features/collection/features/payment-history/features/update-modal/components/update-modal-table';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoPaymentHistoryUpdateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    h: { onUpdate },
    d: { isPaidOptionList, paymentHistoryDetail, consortiumCompanyList },
  } = useProjectSalesInfoPaymentHistoryUpdateModalLogic(menuId);
  const { Layout, Body } = collectionModalLayout;
  const { isUpdateModalOpen, setIsUpdateModalOpen } = useProjectSalesInfoPaymentHistoryState(
    (state) => ({
      isUpdateModalOpen: state.isUpdateModalOpen,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
    })
  );
  const value = useMemo(
    () => ({
      d: {
        isPaidOptionList,
        consortiumCompanyList,
        paymentHistoryDetail,
      },
      h: {
        onUpdate,
      },
    }),
    [onUpdate, isPaidOptionList, paymentHistoryDetail]
  );
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      open={isUpdateModalOpen}
      onClose={() => setIsUpdateModalOpen()}
      title="구조 검토비 지급 내역 추가"
    >
      <ProjectSalesInfoPaymentHistoryUpdateModalContext.Provider value={value}>
        <Layout width="100%">
          <Body>
            <ProjectSalesInfoPaymentHistoryUpdateModalTable />
          </Body>
        </Layout>
      </ProjectSalesInfoPaymentHistoryUpdateModalContext.Provider>
    </ModalLayout>
  );
};
