import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteSweep } from '@mui/icons-material';
import useWorkOutputTableMutation from '@front/src/features/work/features/work/features/output/hooks/useTableHeadMutation';
import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ArithmeticOperator } from '@front/src/utils';

export default function WorkOutputTableHead(props: UIBuilderTableHeadProps) {
  const { sectionId, dataId, menuId, bundleOptions } = props;

  const { onRemoveBundle, onRemoveAll, onAddBottom, onAddTop, onMove } = useWorkOutputTableMutation(
    {
      id: bundleOptions?.item.id,
      dataId,
      menuId,
      sectionId,
    }
  );

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onAddBundle: bundleOptions?.onOpenCreateModal,
    onEditBundleName: bundleOptions?.onOpenUpdateModal,
    onRemoveBundle,
  };

  const itemList = [
    { text: '최상단에 추가', icon: AddCircleOutlineIcon, action: actions.onAddTop, seq: 5 },
    {
      text: '최하단에 추가',
      icon: AddCircleOutlineIcon,
      action: actions.onAddBottom,
      split: true,
      seq: 6,
    },
    { text: '폴더 추가', icon: AddCircleOutlineIcon, action: actions.onAddBundle, seq: 7 },
    {
      text: bundleOptions?.index === 0 ? '' : '폴더 삭제',
      icon: DeleteIcon,
      action: actions.onRemoveBundle,
      seq: 8,
    },
    { text: '폴더명 수정', icon: EditIcon, action: actions.onEditBundleName, seq: 9 },
    {
      text: '폴더 이동(위)',
      icon: ArrowUpwardIcon,
      action: () => onMove(ArithmeticOperator.SUBTRACTION),
      seq: 10,
    },
    {
      text: '폴더 이동(아래)',
      icon: ArrowDownwardIcon,
      action: () => onMove(ArithmeticOperator.ADDITION),
      split: true,
      seq: 11,
    },
    {
      text: '전체 삭제',
      icon: DeleteSweep,
      action: actions.onRemoveAll,
      iconColor: '#e43333',
      seq: 100,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
