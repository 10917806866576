import React, { useMemo } from 'react';
import { useProjectSalesInfoCMPaymentHistoryLogic } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useLogic';
import { ProjectSalesInfoCMPaymentHistoryContext } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/context';

export { Provider as ProjectSalesInfoCMPaymentHistoryProvider };

interface Props {
  children: React.ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const {
    d: { CMPaymentHistoryList },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoCMPaymentHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { CMPaymentHistoryList },
      h: {
        onDelete,
      },
      isLoading,
    }),
    [onDelete, CMPaymentHistoryList, isLoading]
  );
  return (
    <ProjectSalesInfoCMPaymentHistoryContext.Provider value={value}>
      {children}
    </ProjectSalesInfoCMPaymentHistoryContext.Provider>
  );
};
