import React from 'react';
import { PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableRow } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ParentTableRow';
import {
  TableRowBox,
  TbcBox,
} from '@front/src/features/personal-settings-modal/features/ui-settings/components';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { ParentTableContent as PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableContent };
interface Props {
  authMenuId: number | undefined;
}
const ParentTableContent = ({ authMenuId }: Props) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const emptyRows = Array(20 - (list ? list.length : 0))
    .fill(null)
    .map((_, index) => (
      <TableRowBox key={`empty-${index}`}>
        <TbcBox
          sx={{
            flex: 2,
          }}
        />
        <TbcBox
          sx={{
            flex: 1,
          }}
        />
      </TableRowBox>
    ));
  return (
    <>
      {list?.map((item) => (
        <PersonalSettingsModalUISettingsModalSettingsModalStatusParentTableRow
          key={item.id}
          item={item}
        />
      ))}
      {emptyRows}
    </>
  );
};
