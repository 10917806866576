export function noOp() {}

export function getNoOpCallback(): CallbackOption {
  return {
    onSuccess: noOp,
    onError: noOp,
    onSettled: noOp,
    onMutate: noOp,
  };
}

type CallbackOption = {
  onSuccess: (data: any, variables: any, context: any) => void;
  onError: (error: any, variables: any) => void;
  onSettled: (data: any, error: any, variables: any) => void;
  onMutate: (variables: any) => any;
};

export function getSetCallbackFunc(from: CallbackOption) {
  return (to: Partial<CallbackOption>) => {
    if (to?.onSuccess) {
      from.onSuccess = to.onSuccess;
    }
    if (to?.onError) {
      from.onError = to.onError;
    }
    if (to?.onSettled) {
      from.onSettled = to.onSettled;
    }
    if (to.onMutate) {
      from.onMutate = to.onMutate;
    }
  };
}
