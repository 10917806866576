import { useQuery } from 'react-query';
import type { ProjectReviewAmountPaymentHistoryView } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/view';
import { projectSalesInfoCollectionPaymentHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/payment-history/query/api';

export const projectSalesInfoCollectionPaymentHistoryQuery = {
  usePaymentHistoryListGet: (id: number, menuId) => {
    const { data, isLoading } = useQuery<ProjectReviewAmountPaymentHistoryView[]>({
      queryFn: () => projectSalesInfoCollectionPaymentHistoryApi.getPaymentHistoryList(id, menuId),
      queryKey: ['project-sales-info', 'collection', 'payment-history', id],
    });
    return {
      data,
      isLoading,
    };
  },
};
