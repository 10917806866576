import React from 'react';
import { type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { getRemoveIndex } from '@front/src/components/n-depth-table-box/utils';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import { ColorPalette } from '@front/assets/theme';
import ManagementAccountContextMenu from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/ContextMenu';
import useGetAnchorEl from '@front/src/hooks/useGetAnchorEl';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';

interface Props extends SectionComponentProps {
  colIndex: number;
  rowIndex: number;
  showListFieldArray: UseFieldArrayReturn<ManagementAccountTableFormValues, 'showList', 'id'>;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
  cellData: ManagementAccountingTreeView;
}

export default function ManagementAccountTableFullCell(props: Props) {
  const {
    colIndex,
    showListFieldArray,
    selectedRowIndexListFieldArray,
    rowIndex,
    readOnly,
    cellData,
  } = props;

  const { anchorEl, onOpen, onClose } = useGetAnchorEl<HTMLDivElement>();

  const { getValues, setValue, watch } = useFormContext<ManagementAccountTableFormValues>();

  const selectedRowIndexList = getValues('selectedRowIndexList');
  const active = watch('active');
  const lastCol = getValues('col') - 1;
  const isSelected = selectedRowIndexList[colIndex]?.value === rowIndex;

  const { append, remove } = showListFieldArray;
  const { append: appendSelected, remove: removeSelected } = selectedRowIndexListFieldArray;

  const handleClick = () => {
    if (selectedRowIndexList[colIndex]) {
      remove(getRemoveIndex(colIndex + 1, lastCol));
      removeSelected(getRemoveIndex(colIndex, lastCol));
    }

    if (colIndex !== lastCol) {
      append([cellData.children]);
    }
    appendSelected({ value: rowIndex });

    setValue('active', cellData);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (readOnly) return;
    onOpen(e);
  };

  const isActive = active && active.id === cellData.id;

  const sx = {
    backgroundColor: isActive
      ? ColorPalette.sub.sub_primary
      : isSelected
      ? ColorPalette.main.main_secondary
      : 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: isActive
        ? ColorPalette.sub.sub_primary
        : isSelected
        ? ColorPalette.main.main_secondary
        : ColorPalette.main.main_tertiary,
    },
  };

  return (
    <>
      {!readOnly && (
        <ManagementAccountContextMenu
          anchorEl={anchorEl}
          onClose={onClose}
          {...props}
        />
      )}
      <NDepthTableBoxCell
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        sx={sx}
      >
        {cellData?.name ?? ''}
      </NDepthTableBoxCell>
    </>
  );
}
