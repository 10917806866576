import React from 'react';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import { AccountingSettingsManagementAccountingAccountListWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/list/widget';
import { AccountingSettingsManagementAccountingReadCountWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-read/widgets/count/widget';
import { AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtWidget } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header/widgets/get-updated-at/widget';
import { AccountingSettingsManagementAccountingReadTable } from '@front/src/features/accounting/features/settings/features/management-accounting-read/components/table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AccountingSettingsManagementAccountingReadFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  return (
    <Layout>
      <Header
        title={name}
        rightComponent={
          <AccountingSettingsManagementAccountingAccountHeaderGetUpdatedAtWidget menuId={menuId} />
        }
      />
      <Body>
        <AccountingSettingsManagementAccountingReadTable
          header={<AccountingSettingsManagementAccountingReadCountWidget menuId={menuId} />}
          body={<AccountingSettingsManagementAccountingAccountListWidget menuId={menuId} />}
        />
      </Body>
    </Layout>
  );
};

export default AccountingSettingsManagementAccountingReadFeature;
