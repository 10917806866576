import { proceedingsAction } from './action';
import { call, fork, put, select, take } from 'redux-saga/effects';
import type { RootState } from '../services/reducer';
import { dialogAction } from '../dialog/action';
import { getErrorMessage } from '../type/Error';
import type { ProceedingsAccessVO, ProceedingsShortVO, ProceedingsVO } from './domain';
import { departmentApi } from '../department/api';
import { proceedingsApi } from './api';
import { userNotificationAction } from '../user_notification/action';

function* watchAdd() {
  while (true) {
    const { payload: addParameter } = yield take(proceedingsAction.add);
    const { filter } = yield select((root: RootState) => root.proceedings);
    if (addParameter) {
      try {
        yield call(proceedingsApi.add, addParameter);
        yield put(dialogAction.openAlert('등록 완료 했습니다.'));
        yield call(proceedingsAction.add, undefined);
        yield put(proceedingsAction.setAddModal(false));
        yield put(proceedingsAction.setFilter(filter));
      } catch (e) {
        const message = getErrorMessage(proceedingsAction.add, e);
        yield put(dialogAction.openAlert(message));
      }
    }
  }
}

function* watchId() {
  while (true) {
    const { payload: id } = yield take(proceedingsAction.setId);
    const { memoFilter } = yield select((root: RootState) => root.proceedings);
    if (id) {
      try {
        const detail: ProceedingsVO = yield call(proceedingsApi.getDetail, id);
        yield put(proceedingsAction.setDetail(detail));
        yield put(proceedingsAction.setMemoFilter(memoFilter));
      } catch (e) {
        const message = getErrorMessage(proceedingsAction.setId, e);
        yield put(dialogAction.openAlert(message));
      }
    }
  }
}

function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(proceedingsAction.setFilter);
    yield put(proceedingsAction.setListLoading(true));
    try {
      const list: ProceedingsShortVO[] = yield call(proceedingsApi.getFilteredList, query);
      yield put(proceedingsAction.setList(list));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.setFilter, e);
      yield put(dialogAction.openAlert(message));
    } finally {
      yield put(proceedingsAction.setListLoading(false));
    }
  }
}

function* watchRequestDepartmentList() {
  while (true) {
    const { payload: menuId } = yield take(proceedingsAction.requestDepartmentList);
    try {
      const departmentList = yield call(departmentApi.getList, menuId);
      yield put(proceedingsAction.setDepartmentList(departmentList));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.requestDepartmentList, e);
      yield put(dialogAction.openAlert(message));
    }
  }
}

function* watchUpdate() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.update);
    const { id, filter } = yield select((root: RootState) => root.proceedings);
    if (params) {
      try {
        yield call(proceedingsApi.update, id, params);
        yield put(proceedingsAction.setId(id));
        if (params.agenda || params.isFavorite || params.startTime || params.status) {
          yield put(proceedingsAction.setFilter(filter));
        }
      } catch (e) {
        const message = getErrorMessage(proceedingsAction.update, e);
        yield put(dialogAction.openAlert(message));
      }
    }
  }
}

function* watchDelete() {
  while (true) {
    const { payload: id } = yield take(proceedingsAction.delete);
    const { filter } = yield select((root: RootState) => root.proceedings);
    if (id) {
      try {
        yield put(proceedingsAction.requestDelete('request'));
        yield call(proceedingsApi.delete, id);
        yield put(dialogAction.openAlert('삭제하였습니다.'));
        yield put(proceedingsAction.setFilter(filter));
        yield put(proceedingsAction.requestDelete('done'));
      } catch (e) {
        const message = getErrorMessage(proceedingsAction.delete, e);
        yield put(dialogAction.openAlert(message));
      }
    }
  }
}

function* watchAddMemo() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.addMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proceedings);
    try {
      yield put(proceedingsAction.requestAddMemo('request'));
      yield call(proceedingsApi.addMemo, id, params);
      yield put(proceedingsAction.setId(id));
      yield put(proceedingsAction.setMemoFilter(memoFilter));
      if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
        yield put(userNotificationAction.requestCount());
      }
      yield put(proceedingsAction.requestAddMemo('done'));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.addMemo, e);
      yield put(proceedingsAction.requestAddMemo(message));
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoFilter() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.setMemoFilter);
    yield put(proceedingsAction.setMemoLoading(true));
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      const list = yield call(proceedingsApi.getMemoList, id, params);
      yield put(proceedingsAction.setMemoList(list));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.setMemoFilter, e);
      yield put(dialogAction.openError(message));
    } finally {
      yield put(proceedingsAction.setMemoLoading(false));
    }
  }
}

function* watchMemoChange() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.changeMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.changeMemo, id, params);
      yield put(proceedingsAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoDelete() {
  while (true) {
    const { payload: memoId } = yield take(proceedingsAction.deleteMemo);
    const { id, memoFilter } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.deleteMemo, id, memoId);
      yield put(proceedingsAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.deleteMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchAddComment() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.addComment);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.addComment, id, params);
      yield put(proceedingsAction.setId(id));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.addComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchUpdateComment() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.updateComment);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.updateComment, id, params);
      yield put(proceedingsAction.setId(id));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.updateComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchDeleteComment() {
  while (true) {
    const { payload: commentId } = yield take(proceedingsAction.deleteComment);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.deleteComment, id, commentId);
      yield put(proceedingsAction.setId(id));
      yield put(dialogAction.openAlert('삭제했습니다.'));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.deleteComment, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchAddFile() {
  while (true) {
    const { payload: params } = yield take(proceedingsAction.addAttachedFile);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.addAttachedFile, id, params);
      yield put(proceedingsAction.setId(id));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.addAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchDeleteFile() {
  while (true) {
    const { payload: fileId } = yield take(proceedingsAction.deleteAttachedFile);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      yield call(proceedingsApi.deleteAttachedFile, id, fileId);
      yield put(proceedingsAction.setId(id));
      yield put(dialogAction.openAlert('삭제했습니다.'));
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.deleteAttachedFile, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchAccess() {
  while (true) {
    yield take(proceedingsAction.getAccess);
    const { id } = yield select((root: RootState) => root.proceedings);
    try {
      if (id) {
        yield put(proceedingsAction.requestAccess('request'));
        const result: ProceedingsAccessVO = yield call(proceedingsApi.canAccess, id);
        yield put(proceedingsAction.setAccess(result));
        yield put(proceedingsAction.requestAccess('done'));
      }
    } catch (e) {
      const message = getErrorMessage(proceedingsAction.getAccess, e);
      yield put(proceedingsAction.requestAccess(message));
    }
  }
}

export default function* proceedingsSaga() {
  yield fork(watchAdd);
  yield fork(watchFilter);
  yield fork(watchRequestDepartmentList);
  yield fork(watchId);
  yield fork(watchUpdate);
  yield fork(watchAddMemo);
  yield fork(watchMemoFilter);
  yield fork(watchMemoChange);
  yield fork(watchMemoDelete);
  yield fork(watchAddComment);
  yield fork(watchUpdateComment);
  yield fork(watchDeleteComment);
  yield fork(watchAddFile);
  yield fork(watchDeleteFile);
  yield fork(watchDelete);
  yield fork(watchAccess);
}
