import React from 'react';
import { Box } from '@mui/material';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import CircularProgress from '@mui/material/CircularProgress';
import { ColorPalette } from '@front/assets/theme';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';

interface Props {
  colSpan?: number;
  rowSpan?: number;
}

export default function TableRowLoadingSpinnerUI({ colSpan, rowSpan }: Props) {
  return (
    <TableRowUI>
      <TableCellUI
        colSpan={colSpan}
        rowSpan={rowSpan}
        style={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          style={{
            zIndex: '3',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: ColorPalette.etc.loading_spinner,
          }}
        >
          <CircularProgress
            style={{
              width: '26px',
              height: '26px',
              strokeLinecap: 'round',
              strokeWidth: 4,
              color: ColorPalette.greyscale.white,
            }}
          />
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
}
