import React from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { Table as MuiTable } from '@front/layouts/Table';
import { AccountingSettingsBankAccountListTableHeader } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/table-header';
import { AccountingSettingsBankAccountListTableBody } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/list/components/table-body';

export { Table as AccountingSettingsBankAccountListTable };
interface Props {
  menuId?: number;
}

const Table = ({ menuId }: Props) => (
  <TableContainer
    sx={{
      maxHeight: '84dvh',
      overflowY: 'auto',
    }}
  >
    <MuiTable>
      <AccountingSettingsBankAccountListTableHeader />
      <TableBody>
        <AccountingSettingsBankAccountListTableBody menuId={menuId} />
      </TableBody>
    </MuiTable>
  </TableContainer>
);
