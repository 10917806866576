import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';
import AccountStatusConstant
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/constant';
import {
  AccountStatusBankAccountStatusGroupDTO,
  AccountStatusGetParameter,
  AccountStatusUpdateParameter,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';

type UrlType = {
  getList: (params: AccountStatusGetParameter) => string;
  update: (id: number) => string;
}

type ApiType = {
  getList: (params: AccountStatusGetParameter) => Promise<AccountStatusBankAccountStatusGroupDTO>,
  update: (params: AccountStatusUpdateParameter) => Promise<unknown>,
}

const url: UrlType = {
  getList: (params) => `${AccountStatusConstant.URL}/status?menuId=${params.menuId}&sectionId=${params.sectionId}`,
  update: (id) => `${AccountStatusConstant.URL}/${id}/purpose`,
};

const AccountStatusApi: ApiType = {
  getList: async (params) => {
    const { data } = await axios.get<AccountStatusBankAccountStatusGroupDTO>(url.getList(params), {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  },
  update: async (params) => {
    const { data } = await axios.patch(url.update(params.id), {
      purpose: (params.alias ?? '').trim() || null,
    }, {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  },
};

export default AccountStatusApi;