import { useQuery } from 'react-query';
import type { ProjectSalesInfoBidResultView } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/view';
import { projectSalesInfoBidResultUpdateModalApi } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/query/api';

export const projectSalesInfoBidResultUpdateModalQuery = {
  useProjectSalesInfoBidResultDetailGet: (id: number, menuId) => {
    const { data, isLoading } = useQuery<ProjectSalesInfoBidResultView>({
      queryKey: ['project-sales-info', 'bid-result', 'detail', id],
      queryFn: () => projectSalesInfoBidResultUpdateModalApi.getBidResultDetail(id, menuId),
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
