import React from 'react';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import { Box } from '@mui/material';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { ColorPalette } from '@front/assets/theme';
import type { FileItemDto } from '@front/src/features/drawer-approval-document/types/view';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  item: FileItemDto;
}

export default function ApprovalDocumentImgModal({ item }: Readonly<Props>) {
  const { imgModalId, setImgModalId } = useApprovalDocumentStore(
    useShallow((state) => ({
      imgModalId: state.imgModalId,
      setImgModalId: state.setImgModalId,
    }))
  );
  const open = imgModalId === item.id;
  const onClose = () => {
    setImgModalId(0);
  };
  return (
    <ModalUI
      title="미리 보기"
      open={open}
      onClose={onClose}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            borderBottom: `1px solid ${ColorPalette.greyscale.black}`,
            backgroundColor: ColorPalette.background.bg,
          }}
        >
          <TypographyUI>
            {`날짜: ${dayjs(item.createdAt).format(YY_MM_DD)} / 파일명: ${item.filename}`}
          </TypographyUI>
          <ButtonBasicUI
            shape="secondary"
            size="medium"
            onClick={onClose}
          >
            닫기
          </ButtonBasicUI>
        </Box>
        <ModalBodyUI>
          <ModalContentUI>
            <img
              src={`/api/file-item/${item?.id}`}
              alt="클릭한 자료 이미지"
              style={{
                width: '800px',
                height: '800px',
              }}
            />
          </ModalContentUI>
        </ModalBodyUI>
      </>
    </ModalUI>
  );
}
