import { useQuery } from 'react-query';
import type { AffiliatedCompanyPersonOutlineExtraCompanyView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/view';
import { affiliatedCompanyExtraCompanyApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/query/api';

export const affiliatedCompanyExtraCompanyQuery = {
  usePersonOutlineExtraCompanyGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonOutlineExtraCompanyView[]>({
      queryKey: ['affiliated-company', 'person', 'outline', 'extra-company', id],
      queryFn: () => affiliatedCompanyExtraCompanyApi.getPersonOutlineExtraCompany(id, menuId),
      enabled: !!id,
      cacheTime: 0,
    });
    return {
      data,
      isLoading,
    };
  },
};
