import { createContext } from 'react';
import type { AccountingSettingsManagementAccountingAccountCountView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';

export { Context as AccountingSettingsManagementAccountingAccountTableHeaderContext };

interface State {
  detail?: AccountingSettingsManagementAccountingAccountCountView;
  isLoading: boolean;
}

const Context = createContext<State>({
  isLoading: false,
});
