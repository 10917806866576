import { useCallback, useMemo } from 'react';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { affiliatedCompanyOutlineRepository } from '@front/src/features/affiliated-company/features/outline/repository/repository';
import type { AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/features/business-registration/types/parameter';
import { affiliatedCompanyOutlineBankInfoRepository } from '@front/src/features/affiliated-company/features/outline/features/bank-info/repository/repository';
import type { AffiliatedCompanyOutlineBankInfoUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/bank-info/types/parameter';

export { useLogic as useAffiliatedCompanyOutlineBankInfoLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { data: detailOutline, isLoading } = affiliatedCompanyOutlineRepository.useOutlineGet(
    id,
    menuId
  );
  const { data: bankNameOptionList } =
    affiliatedCompanyOutlineBankInfoRepository.useBankNameOptionListGet(menuId);
  const { run: onBankInfoUpdate } =
    affiliatedCompanyOutlineBankInfoRepository.useBankInfoUpdate(menuId);
  const handleBankInfoUpdate = useCallback(
    (params: AffiliatedCompanyOutlineBankInfoUpdateParameter) => {
      onBankInfoUpdate({ ...params, id });
    },
    [onBankInfoUpdate, id]
  );
  const { run: onBankBookFileUpdate } =
    affiliatedCompanyOutlineBankInfoRepository.useBankBookFileUpdate(menuId);
  const handleBankBookFIleUpdate = useCallback(
    (params: AffiliatedCompanyOutlineBusinessRegistrationFileUpdateParameter) => {
      onBankBookFileUpdate({ ...params, id });
    },
    [onBankBookFileUpdate, id]
  );
  const bankInfo = useMemo(() => {
    if (typeof detailOutline === 'undefined' || isLoading) {
      return undefined;
    }
    return {
      bankName: detailOutline?.bankName,
      accountNumber: detailOutline?.accountNumber,
    };
  }, [detailOutline, isLoading]);
  const bankBookFile = detailOutline?.bankBookFile;
  const d = { bankInfo, bankBookFile, bankNameOptionList };
  const h = {
    onBankInfoUpdate: handleBankInfoUpdate,
    onBankBookFileUpdate: handleBankBookFIleUpdate,
  };
  return { d, h };
};
