import { paymentHistoryApiUrl } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import axios from 'axios';
import type { IdDeleteCollectionParams } from '@front/src/features/project-sales-info/features/collection/features/payment-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPaymentHistoryList: (id: number) =>
    `${paymentHistoryApiUrl}/${id}/review-amount-payment-history`,
  deletePaymentHistory: () => `${paymentHistoryApiUrl}/review-amount-payment-history`,
};

export const projectSalesInfoCollectionPaymentHistoryApi = {
  getPaymentHistoryList: async (id: number, menuId) => {
    const { data } = await axios.get(url.getPaymentHistoryList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deletePaymentHistory: async (params: IdDeleteCollectionParams, menuId) =>
    await axios.delete(url.deletePaymentHistory(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    }),
};
