import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useDialog from '../../dialog/hook';
import { default as axios } from '@front/src/config/axios';
import { toFormData } from '@front/services/api';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface EditorComponentProps<T extends { id: any; beforeContent: string }> {
  readOnly?: boolean;
  value: T;
  update: (params: { id: any; beforeContent: string }) => void;
  dataId?: number; // 수명업무, 프로젝트 등 그런 데이터의 id를 의미합니다.
  filePath?: string;
}

const EditorComponent = <T extends { id: any; beforeContent: string }>({
  value,
  update,
  readOnly = false,
  dataId,
  filePath,
}: EditorComponentProps<T>) => {
  const { error } = useDialog();
  const quillRef = useRef<ReactQuill>(null);
  const [editorValue, setEditorValue] = useState<string>('');
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files?.[0];
      if (!file) {
        return;
      }
      try {
        // TODO: 결재 상신시 문제 menuId와 dataId가 제외하고 올리는게 맞음. 필요하지 않음.
        const result = await axios.post(
          '/api/file-item/image',
          toFormData({
            multipartFile: file,
            subPath: filePath,
            menuId: sessionStorage.getItem('menuId'),
            dataId: dataId,
          })
        );

        if (!quillRef.current) {
          return;
        }
        const editor = quillRef.current!.getEditor();
        if (!editor) {
          return;
        }

        const range = editor.getSelection();
        if (!range) {
          return;
        }
        editor.insertEmbed(range.index, 'image', result.data);
      } catch (e) {
        error('이미지 업로드에 실패했습니다.');
      }
    });
  }, []);

  const modules = useMemo(
    () =>
      readOnly
        ? { toolbar: false }
        : {
            toolbar: {
              container: [
                [{ font: ['Noto Sans KR'] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image'],
                [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
                ['clean'],
              ],
              handlers: {
                image: () => {
                  imageHandler();
                },
              },
            },
          },
    [readOnly]
  );

  const formats = [
    //'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];
  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (editorValue === value.beforeContent || readOnly) {
        return;
      }
      if (isUpdated) {
        clearTimeout(debounceId);
        setIsUpdated(false);
        return;
      }
      update({
        id: value.id,
        beforeContent: editorValue,
      });
    }, 3000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [editorValue, isUpdated]);

  useEffect(() => {
    // 내부 스타일을 위해서 useRef를 이용해서 처리... -> webpack css loader 수정 필요할 듯.
    const editor = quillRef.current!.getEditor();
    const styleTag = document.createElement('style');
    styleTag.innerHTML =
      '.ql-editor { min-height: 200px; border: 1px solid #e4e9f2; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; } .ql-container.ql-snow{border:none;}';
    editor.addContainer(styleTag);
  }, []);

  useEffect(() => {
    setEditorValue(value.beforeContent);
  }, [value.id, value.beforeContent]);
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ReactQuill
        style={{
          backgroundColor: ColorPalette._ffffff,
          fontSize: '30px',
        }}
        readOnly={readOnly}
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={editorValue || ''}
        onChange={(beforeContent, delta, source, editor) => setEditorValue(editor.getHTML())}
        onBlur={() => {
          if (editorValue === value.beforeContent) {
            return;
          }
          update({
            id: value.id,
            beforeContent: editorValue,
          });
          setIsUpdated(true);
        }}
      />
    </Box>
  );
};

export default EditorComponent;
