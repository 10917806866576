import React from 'react';
import { AccountingUploadSalesInfoPerformanceModalUpdateProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/update/provider/provider';
import { AccountingUploadSalesInfoPerformanceModalButtonGroup } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/update/components/button-group';

export { Widget as AccountingUploadSalesInfoPerformanceModalUpdateWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <AccountingUploadSalesInfoPerformanceModalUpdateProvider menuId={menuId}>
    <AccountingUploadSalesInfoPerformanceModalButtonGroup />
  </AccountingUploadSalesInfoPerformanceModalUpdateProvider>
);
