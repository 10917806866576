import React from 'react';
import AffiliatedCompanyPersonDetailModalRelationshipWidget from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/widget';

import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedPersonRelationFeature = (props: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={props.name} />
      <Body>
        <AffiliatedCompanyPersonDetailModalRelationshipWidget {...props} />
      </Body>
    </Layout>
  );
};

export default AffiliatedPersonRelationFeature;
