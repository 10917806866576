import React from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import ApprovalForm from '@front/src/features/drawer-approval/components/ApprovalForm';
import ApprovalStepTable from '@front/src/features/drawer-approval/components/ApprovalStepTable';
import ApprovalReferenceSection from '@front/src/features/drawer-approval/components/ApprovalReferenceSection';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import ApprovalDescriptionTable from '@front/src/features/drawer-approval/components/ApprovalDescriptionTable';
import { APPROVAL_DOCUMENT_STATUS, BODY_TEMPLATE_TYPE } from '@front/type/domain';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import ApprovalCompareTable from '@front/src/features/drawer-approval/components/ApprovalCompareTable';
import { useShallow } from 'zustand/react/shallow';
import { useQueryClient } from 'react-query';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

export default function ApprovalContent() {
  const { menuId, dataId, documentId, noteId } = useIdGroup();
  const queryClient = useQueryClient();
  const docStatus = useApprovalDocumentStore((state) => state.docStatus);
  const tempDocument: any = queryClient.getQueryData([
    'approval-document',
    documentId,
    {
      where: 'approval-document',
      noteId,
    },
    docStatus === APPROVAL_DOCUMENT_STATUS.TEMPORARY || undefined,
  ]);
  const sourceTitle: any = queryClient.getQueryData([
    'approval-document',
    'source-title',
    { menuId, dataId },
  ]);
  const { formType, setFormType } = useApprovalStore(
    useShallow((state) => ({
      formType: state.formType,
      setFormType: state.setFormType,
      approvalState: state.approvalState,
      setApprovalState: state.setApprovalState,
    }))
  );
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const onClose = () => {
    setLeftDrawerState('');
    setFormType(BODY_TEMPLATE_TYPE.BASIC);
  };

  return (
    <Box sx={{ ...SX_MODAL }}>
      <Box sx={{ ...SX_HEADER }}>
        <Typography sx={{ ...SX_HEADER_TITLE }}>결재 상신</Typography>
        <CloseIcon
          onClick={onClose}
          sx={{ ...SX_CLOSE }}
        />
      </Box>
      <ApprovalForm />
      <Box sx={{ ...SX_BODY }}>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
        >
          <Typography sx={{ ...SX_APPROVAL_ORIGIN_NAME }}>{sourceTitle}</Typography>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography sx={{ ...SX_APPROVAL_SECTION }}>제목</Typography>
            <HookFormInput
              name="request.title"
              placeholder="제목을 입력해 주세요"
              // onPressChange={() => setTemp(watch())}
            />
          </Box>
          <ApprovalStepTable />
          <ApprovalReferenceSection />
          <Box>
            <Typography sx={{ ...SX_APPROVAL_SECTION }}>본문</Typography>
            {formType === BODY_TEMPLATE_TYPE.BASIC ? (
              <ApprovalDescriptionTable fileItemList={tempDocument?.fileItemList} />
            ) : (
              <ApprovalCompareTable fileItemList={tempDocument?.fileItemList} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  boxShadow: '20px 0px 10px rgba(0,0,0,0.15)',
  overflow: 'hidden',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_BODY = {
  height: 'calc(100dvh - 102px)',
  overflow: 'auto',
  padding: '14px',
};

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};

const SX_CLOSE = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  width: '18px',
  height: '18px',
};

const SX_APPROVAL_ORIGIN_NAME = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
};

export const SX_APPROVAL_SECTION = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  margin: '10px 0',
};
