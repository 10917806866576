import { workApi } from '@front/src/features/work/utils/constants';
import type {
  WorkBasicInfoParams,
  WorkBasicSearchWordParams,
} from '@front/src/features/work/features/work/features/basic-info/types/parameter';
import type { WorkCategoryParams } from '@front/src/features/work/features/work/features/category/types/parameter';
import type { WorkScheduleParams } from '@front/src/features/work/features/work/features/schedule/types/parameter';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import type {
  WorkReferenceFileUpdateParameter,
  WorkReferenceUpdateParameter,
} from '@front/src/features/work/features/work/features/reference/types/parameter';
import type {
  WorkOutputBundleCreateParameter,
  WorkOutputBundleUpdateParameter,
} from '@front/src/features/work/features/work/features/output-bundle/types/parameter';
import type {
  WorkOutputContentUpdateParameter,
  WorkOutputFileUpdateParameter,
} from '@front/src/features/work/features/work/features/output/types/parameter';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { WorkScheduleView } from '@front/src/features/work/features/work/features/schedule/types/view';
import type { WorkNameView } from '@front/src/features/work/features/work/features/name/types/view';
import type { WorkNameUpdateParameter } from '@front/src/features/work/features/work/features/name/types/parameter';
import type { WorkRoleView } from '@front/src/features/work/features/work/types/view';
import type { WorkInterimReportDateView } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/view';
import type { WorkScheduleInterimReportDateParameter } from '@front/src/features/work/features/work/features/schedule/features/interim-report-date/types/parameter';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import type { WorkCreatorParameter } from '@front/src/features/work/features/work/features/creator/types/parameter';
import type { WorkBasicInfoView } from '@front/src/features/work/features/work/features/basic-info/types/view';
import type { WorkWorkerView } from '@front/src/features/work/features/work/features/worker/types/view';
import type { WorkReviewerView } from '@front/src/features/work/features/work/features/reviewer/types/view';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import type { WorkOutputBundleView } from '@front/src/features/work/features/work/features/output-bundle/types/view';
import type { WorkOutputView } from '@front/src/features/work/features/work/features/output/types/view';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import type {
  WorkApprovalDocumentView,
  WorkApprovalView,
} from '@front/src/features/work/features/work/features/approval/types/view';
import type { WorkDetailView } from '@front/src/features/work/features/work/features/detail/types/view';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { WorkReviewerParams } from '@front/src/features/work/features/work/features/reviewer/types/parameter';
import type { WorkCarouselBundleView } from '@front/src/features/work/features/work/features/carousel-bundle/types/view';

const url = {
  getBasicInfo: (id?: number) => `${workApi}/${id}/basic`,
  updateBasicInfo: (id?: number) => `${workApi}/${id}/basic`,
  getCategory: (id?: number) => `${workApi}/${id}/category`,
  updateCategory: (id?: number) => `${workApi}/${id}/category`,
  getWorkerList: (id?: number) => `${workApi}/${id}/worker`,
  addTopWorker: (id?: number) => `${workApi}/${id}/worker/top`,
  addBottomWorker: (id?: number) => `${workApi}/${id}/worker/bottom`,
  createWorker: (id?: number) => `${workApi}/worker/${id}`,
  updateWorker: (id?: number) => `${workApi}/worker/${id}`,
  deleteWorker: (id?: number) => `${workApi}/worker/${id}`,
  deleteAllWorker: (id?: number) => `${workApi}/${id}/worker`,
  updateWorkerSeqUp: (id?: number) => `${workApi}/worker/${id}/seq-up`,
  updateWorkerSeqDown: (id?: number) => `${workApi}/worker/${id}/seq-down`,
  createSearchWord: (id?: number) => `${workApi}/${id}/search-word`,
  deleteSearchWord: (id?: number) => `${workApi}/${id}/search-word`,
  getSchedule: (id?: number) => `${workApi}/${id}/schedule`,
  updateSchedule: (id?: number) => `${workApi}/${id}/schedule`,
  createScheduleInterimReportDate: (id?: number) => `${workApi}/${id}/interim-report-date`,
  getScheduleInterimReportDate: (id?: number) => `${workApi}/${id}/interim-report-date`,
  updateScheduleInterimReportDate: (id?: number) => `${workApi}/interim-report-date/${id}`,
  deleteScheduleInterimReportDate: (id?: number) => `${workApi}/interim-report-date/${id}`,
  getIndicator: (id?: number) => `${workApi}/${id}/indicator`,
  updateIndicator: (id?: number) => `${workApi}/indicator/${id}`,
  getManager: (id?: number) => `${workApi}/${id}/manager`,
  updateManager: (id?: number) => `${workApi}/manager/${id}`,
  getReviewerList: (id?: number) => `${workApi}/${id}/reviewer`,
  createReviewer: (id?: number) => `${workApi}/reviewer/${id}`,
  addTopReviewer: (id?: number) => `${workApi}/${id}/reviewer/top`,
  addBottomReviewer: (id?: number) => `${workApi}/${id}/reviewer/bottom`,
  updateReviewer: (id?: number) => `${workApi}/reviewer/${id}`,
  deleteReviewer: (id: number | undefined) => `${workApi}/reviewer/${id}`,
  deleteAllReviewer: (id?: number) => `${workApi}/${id}/reviewer`,
  updateReviewerSeqUp: (id?: number) => `${workApi}/reviewer/${id}/seq-up`,
  updateReviewerSeqDown: (id?: number) => `${workApi}/reviewer/${id}/seq-down`,
  getDetailList: (id?: number) => `${workApi}/${id}/detail`,
  createDetail: (id?: number) => `${workApi}/detail/${id}`,
  addTopDetail: (id?: number) => `${workApi}/${id}/detail/top`,
  addBottomDetail: (id?: number) => `${workApi}/${id}/detail/bottom`,
  updateDetail: (id?: number) => `${workApi}/detail/${id}`,
  deleteDetail: (id?: number) => `${workApi}/detail/${id}`,
  deleteAllDetail: (id?: number) => `${workApi}/${id}/detail`,
  updateDetailSeqUp: (id?: number) => `${workApi}/detail/${id}/seq-up`,
  updateDetailSeqDown: (id?: number) => `${workApi}/detail/${id}/seq-down`,
  createApprovalReferenceTop: (id?: number) => `${workApi}/${id}/reference/top`,
  createApprovalReferenceBottom: (id?: number) => `${workApi}/${id}/reference/bottom`,
  createReference: (id?: number) => `${workApi}/reference/${id}`,
  getReference: (id?: number) => `${workApi}/${id}/reference`,
  updateReferenceFile: (id?: number) => `${workApi}/reference/${id}/file`,
  updateReference: (id?: number) => `${workApi}/reference/${id}/content`,
  updateReferenceSeqUp: (id?: number) => `${workApi}/reference/${id}/seq-up`,
  updateReferenceSeqDown: (id?: number) => `${workApi}/reference/${id}/seq-down`,
  deleteReference: (id?: number) => `${workApi}/reference/${id}`,
  deleteAllReference: (id?: number) => `${workApi}/${id}/reference`,
  createOutputBundle: (id?: number) => `${workApi}/${id}/output-bundle`,
  getOutputBundle: (id?: number) => `${workApi}/${id}/output-bundle`,
  updateOutputBundle: (id?: number) => `${workApi}/output-bundle/${id}`,
  deleteOutputBundle: (id?: number) => `${workApi}/output-bundle/${id}`,
  createOutputTop: (id?: number) => `${workApi}/output-bundle/${id}/top`,
  createOutputBottom: (id?: number) => `${workApi}/output-bundle/${id}/bottom`,
  createOutput: (id?: number) => `${workApi}/output/${id}`,
  updateOutputFile: (id?: number) => `${workApi}/output/${id}/file`,
  updateOutput: (id?: number) => `${workApi}/output/${id}`,
  updateOutputSeqUp: (id?: number) => `${workApi}/output/${id}/seq-up`,
  updateOutputSeqDown: (id?: number) => `${workApi}/output/${id}/seq-down`,
  deleteOutput: (id?: number) => `${workApi}/output/${id}`,
  deleteAllOutput: (id?: number) => `${workApi}/output-bundle/${id}/all`,
  createCarousel: (id?: number) => `${workApi}/carousel-bundle/${id}/carousel`,
  getCarousel: (id?: number) => `${workApi}/carousel-bundle/${id}/carousel`,
  deleteCarousel: (id?: number) => `${workApi}/carousel-bundle/carousel/${id}`,
  getName: (id?: number) => `/api/work/${id}/name`,
  updateName: (id?: number) => `/api/work/${id}/name`,
  getRole: (id?: number) => `/api/work/${id}/role`,
  getOutputList: (id?: number) => `/api/work/output-bundle/${id}`,
  getWorkApproval: (id?: number) => `/api/work/${id}/approval`,
  getWorkApprovalDocumentList: (id?: number) => `/api/work/${id}/approval-document`,
  updateWorkCarouselSeq: (id) => `/api/work/carousel-bundle/${id}/carousel/seq`,

  deleteAllCarousel: (id?: number) => `/api/work/carousel-bundle/${id}/carousel`,
  moveSlideCarousel: (id?: number) => `/api/work/carousel-bundle/carousel/${id}/bundle`,

  createCarouselBundle: (id?: number) => `/api/work/${id}/carousel-bundle`,
  getCarouselBundleList: (id?: number) => `/api/work/${id}/carousel-bundle`,
  updateCarouselBundle: (id?: number) => `/api/work/carousel-bundle/${id}`,
  deleteCarouselBundle: (id?: number) => `/api/work/carousel-bundle/${id}`,

  moveCarouselBundle: `/api/work/carousel-bundle/seq`,
  moveOutputBundle: `/api/work/output-bundle/seq`,
};

export const workWorkApi = {
  getBasicInfo: async <T = WorkBasicInfoView>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getBasicInfo(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  updateBasicInfo: async (params: WorkBasicInfoParams, commonParams) =>
    await axios.patch(url.updateBasicInfo(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getCategory: async <T = WorkCategoryView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getCategory(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: commonParams,
    });
    return data;
  },
  updateCategory: async (params: WorkCategoryParams, commonParams) =>
    await axios.patch(url.updateCategory(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getWorkerList: async <T = WorkWorkerView[]>(params: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getWorkerList(params.dataId), {
      headers: getMenuIdHeaders(params.menuId),
      params,
    });
    return data;
  },
  addTopWorker: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addTopWorker(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  addBottomWorker: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addBottomWorker(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createWorker: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.post(url.createWorker(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateWorker: async (params, commonParams) =>
    await axios.patch(url.updateWorker(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteWorker: async (id, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteWorker(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  deleteAllWorker: async (commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteAllWorker(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  updateWorkerSeqUp: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateWorkerSeqUp(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateWorkerSeqDown: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateWorkerSeqDown(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createSearchWord: async (params: WorkBasicSearchWordParams, commonParams) =>
    await axios.post(url.createSearchWord(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteSearchWord: async (params: WorkBasicSearchWordParams, commonParams) =>
    await axios.delete(url.deleteSearchWord(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: params,
    }),
  getSchedule: async <T = WorkScheduleView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getSchedule(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  updateSchedule: async (params: WorkScheduleParams, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateSchedule(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createScheduleInterimReportDate: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.createScheduleInterimReportDate(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getScheduleInterimReportDate: async <T = WorkInterimReportDateView[]>(
    commonParams: UIBuilderParameter
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getScheduleInterimReportDate(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
      }
    );
    return data;
  },
  updateScheduleInterimReportDate: async (
    params: WorkScheduleInterimReportDateParameter,
    commonParams: UIBuilderParameter
  ) =>
    await axios.patch(url.updateScheduleInterimReportDate(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteScheduleInterimReportDate: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteScheduleInterimReportDate(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  getIndicator: async <T = WorkCreatorView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getIndicator(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: commonParams,
    });
    return data;
  },
  updateIndicator: async (params: WorkCreatorParameter, commonParams) =>
    await axios.patch(url.updateIndicator(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getManager: async <T = WorkCreatorView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getManager(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: commonParams,
    });
    return data;
  },
  updateManager: async (params: WorkCreatorParameter, commonParams) =>
    await axios.patch(url.updateManager(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getReviewerList: async <T = WorkReviewerView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getReviewerList(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
        params: commonParams,
      }
    );
    return data;
  },
  createReviewer: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.post(url.createReviewer(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  addTopReviewer: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addTopReviewer(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  addBottomReviewer: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addBottomReviewer(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateReviewer: async (params: WorkReviewerParams, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReviewer(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteReviewer: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteReviewer(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  deleteAllReviewer: async (commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteAllReviewer(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  updateReviewerSeqUp: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReviewerSeqUp(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateReviewerSeqDown: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReviewerSeqDown(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getDetailList: async <T = WorkDetailView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getDetailList(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: commonParams,
    });
    return data;
  },
  createDetail: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.post(url.createDetail(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  addTopDetail: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addTopDetail(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  addBottomDetail: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.addBottomDetail(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateDetail: async (params: WorkDetailUpdateParameter, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateDetail(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteDetail: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteDetail(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  deleteAllDetail: async (commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteAllDetail(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  updateDetailSeqUp: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateDetailSeqUp(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateDetailSeqDown: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateDetailSeqDown(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createApprovalReferenceTop: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.createApprovalReferenceTop(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createApprovalReferenceBottom: async (commonParams: UIBuilderParameter) =>
    await axios.post(url.createApprovalReferenceBottom(commonParams.dataId), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createReference: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.post(url.createReference(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getReference: async <T = WorkReferenceView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getReference(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      params: commonParams,
    });
    return data;
  },
  updateReferenceFile: async (
    params: WorkReferenceFileUpdateParameter,
    commonParams: UIBuilderParameter
  ) =>
    await axios.patch(url.updateReferenceFile(params.id), params.formData, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateReference: async (params: WorkReferenceUpdateParameter, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReference(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateReferenceSeqUp: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReferenceSeqUp(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateReferenceSeqDown: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateReferenceSeqDown(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteReference: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteReference(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  deleteAllReference: async (commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteAllReference(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  createOutputBundle: async (
    params: WorkOutputBundleCreateParameter,
    commonParams: UIBuilderParameter
  ) =>
    await axios.post(url.createOutputBundle(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getOutputBundle: async <T = WorkOutputBundleView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getOutputBundle(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
      }
    );
    return data;
  },
  updateOutputBundle: async (
    params: WorkOutputBundleUpdateParameter,
    commonParams: UIBuilderParameter
  ) =>
    await axios.patch(url.updateOutputBundle(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: params,
    }),
  deleteOutputBundle: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteOutputBundle(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  createOutputTop: async (id: number, commonParams) =>
    await axios.post(url.createOutputTop(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createOutputBottom: async (id: number, commonParams) =>
    await axios.post(url.createOutputBottom(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  createOutput: async (id: number, commonParams) =>
    await axios.post(url.createOutput(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateOutput: async (params: WorkOutputContentUpdateParameter, commonParams) =>
    await axios.patch(url.updateOutput(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateOutputFile: async (params: WorkOutputFileUpdateParameter, commonParams) =>
    await axios.patch(url.updateOutputFile(params.id), params.formData, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateOutputSeqUp: async (id: number, commonParams) =>
    await axios.patch(url.updateOutputSeqUp(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  updateOutputSeqDown: async (id: number, commonParams) =>
    await axios.patch(url.updateOutputSeqDown(id), null, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteOutput: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteOutput(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  deleteAllOutput: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteAllOutput(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  createCarousel: async (params, commonParams) =>
    await axios.post(url.createCarousel(params.bundleId), params.formData, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getCarousel: async <T = WorkCarouselView[]>(bundleId, commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getCarousel(bundleId), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  deleteCarousel: async (id: number, commonParams: UIBuilderParameter) =>
    await axios.delete(url.deleteCarousel(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
      data: commonParams,
    }),
  getName: async <T = WorkNameView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getName(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  updateName: async (params: WorkNameUpdateParameter, commonParams: UIBuilderParameter) =>
    await axios.patch(url.updateName(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getRole: async <T = WorkRoleView>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getRole(commonParams.dataId), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  getOutputList: async <T = WorkOutputView[]>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getOutputList(commonParams.bundleId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
        params: commonParams,
      }
    );
    return data;
  },
  getWorkApproval: async <T = WorkApprovalView>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getWorkApproval(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
        params: commonParams,
      }
    );
    return data;
  },
  getWorkApprovalDocumentList: async <T = WorkApprovalDocumentView[]>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getWorkApprovalDocumentList(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
      }
    );
    return data;
  },
  updateWorkCarouselSeq: async (params, commonParams) =>
    await axios.patch(url.updateWorkCarouselSeq(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),

  deleteAllCarousel: async (id, commonParams) =>
    await axios.delete(url.deleteAllCarousel(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),

  moveSlideCarousel: async (params, commonParams) =>
    await axios.patch(url.moveSlideCarousel(params.workCarouselId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),

  createCarouselBundle: async (params, commonParams) =>
    await axios.post(url.createCarouselBundle(commonParams.dataId), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  getCarouselBundleList: async <T = WorkCarouselBundleView[]>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(
      url.getCarouselBundleList(commonParams.dataId),
      {
        headers: getMenuIdHeaders(commonParams.menuId),
      }
    );
    return data;
  },
  updateCarouselBundle: async (params, commonParams) =>
    await axios.patch(url.updateCarouselBundle(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  deleteCarouselBundle: async (id, commonParams) =>
    await axios.delete(url.deleteCarouselBundle(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),

  moveCarouselBundle: async (params, commonParams) =>
    await axios.patch(url.moveCarouselBundle, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),

  moveOutputBundle: async (params, commonParams) =>
    await axios.patch(url.moveOutputBundle, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
};

const getMenuIdHeaders = (menuId) => ({
  'Menu-Id': menuId,
});
