import React, { Suspense } from 'react';
import { AccountingUploadSalesInfoExpectModalListProvider } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/list/provider/provider';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { AccountingUploadSalesInfoExpectModalForm } from '@front/src/features/accounting/features/upload/features/sales-info/features/expect-modal/widgets/list/components/form';

export { Widget as AccountingUploadSalesInfoExpectModalListWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <Suspense fallback={<LoadingSpinner height="592px" />}>
    <AccountingUploadSalesInfoExpectModalListProvider menuId={menuId}>
      <AccountingUploadSalesInfoExpectModalForm menuId={menuId} />
    </AccountingUploadSalesInfoExpectModalListProvider>
  </Suspense>
);
