import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { fixedContentsHeight } from '@front/util/ConstantCommonVariable';
import { evaluationAction } from '@front/ost_evaluation/action';
import { initialEvaluationQuery } from '@front/ost_evaluation/query';
import MemoDrawerService from '@front/components/Memo/route/drawer';
import EvaluationFormService from '@front/ost_evaluation/service/memo/form';
import EvaluationFilterService from '@front/ost_evaluation/service/memo/filter';
import EvaluationListService from '@front/ost_evaluation/service/memo/list';

const EvaluationMemoService = () => {
  const dispatch = useDispatch();
  const memoDrawerOpen = useSelector(
    (root: RootState) => root.evaluation.memoDrawerOpen,
    shallowEqual
  );
  const id = useSelector((root: RootState) => root.proposal.id, shallowEqual);
  const setMemoDrawerOpen = useCallback(
    (open: boolean) => dispatch(evaluationAction.setMemoDrawerOpen(open)),
    [dispatch]
  );
  const [formHeight, setFormHeight] = useState<number>(fixedContentsHeight);

  useEffect(() => {
    if (id) {
      dispatch(evaluationAction.setMemoFilter(initialEvaluationQuery));
    }
  }, [id]);
  return (
    <MemoDrawerService
      isOpen={memoDrawerOpen}
      setDrawer={setMemoDrawerOpen}
      children={
        <>
          <EvaluationFormService setFormHeight={setFormHeight} />
          <EvaluationFilterService id={id} />
          <EvaluationListService formHeight={formHeight} />
        </>
      }
    />
  );
};

export default EvaluationMemoService;
