import React from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import type { ArrangementCommentParameter } from '@front/ost_proposal/parameter';
import type {
  ArrangementCommentId,
  ProposalArrangementCommentEditVO,
} from '@front/ost_proposal/domain';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import UserIcon from '@front/layouts/UserIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';
import useDialog from '@front/dialog/hook';
import ReplyArrangementAddRowLayout from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/contents/ReplyArrangementAddRowLayout';
import ArrangementCommentItem from '@front/ost_proposal/view/detail/advisorForm/arrangement_comment/contents/ArrangementCommentItem';

interface Props {
  updateArrangementComment: (comment: ArrangementCommentParameter) => void;
  deleteArrangementComment: (id: ArrangementCommentId) => void;
  setArrangementCommentId: (id: ArrangementCommentId | undefined) => void;
  setArrangementCommentEditInput: (state: ProposalArrangementCommentEditVO) => void;
  setArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
  addArrangementComment: (comment: ArrangementCommentParameter) => void;
  setReplyArrangementCommentParentId: (id: ArrangementCommentId | undefined) => void;
}

function ArrangementCommentList({
  updateArrangementComment,
  deleteArrangementComment,
  setArrangementCommentId,
  setArrangementCommentEditInput,
  setArrangementCommentParentId,
  addArrangementComment,
  setReplyArrangementCommentParentId,
}: Props) {
  const [arrangementCommentList] = useSelector(
    (root: RootState) => [root.proposal.arrangementCommentList],
    shallowEqual
  );
  const loginUser = useSelector((root: RootState) => root.login.detail!, shallowEqual);
  const arrangementCommentId = useSelector(
    (root: RootState) => root.proposal.arrangementCommentId,
    shallowEqual
  );
  const arrangementCommentParentId = useSelector(
    (root: RootState) => root.proposal.arrangementCommentParentId,
    shallowEqual
  );
  const replyArrangementCommentParentId = useSelector(
    (root: RootState) => root.proposal.replyArrangementCommentParentId,
    shallowEqual
  );
  const { confirm } = useDialog();
  return (
    <>
      {arrangementCommentList.length > 0 &&
        arrangementCommentList.map((item) => (
          <Box
            key={item.id}
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: '30px auto 30px',
              gridTemplateAreas: `"avatar contents setting"
              "reply reply reply"`,
              padding: '5px 10px',
              minHeight: '100px',
            }}
          >
            {!item.parentId && item.isBlind && (
              <Box
                sx={{
                  gridColumn: '2',
                  alignSelf: 'self-end',
                  color: ColorPalette._b2b4b7,
                  fontSize: '12px',
                }}
              >
                삭제된 피드백 입니다.
              </Box>
            )}
            {!item.parentId && !item.isBlind && (
              <>
                <Box
                  sx={{
                    gridArea: 'avatar',
                    width: '100%',
                    paddingTop: '5px',
                  }}
                >
                  <UserIcon user={item.writer.id} />
                </Box>
                <ArrangementCommentItem
                  key={item.id}
                  item={item}
                  updateArrangementComment={updateArrangementComment}
                  deleteArrangementComment={deleteArrangementComment}
                  setArrangementCommentEditInput={setArrangementCommentEditInput}
                  setArrangementCommentParentId={setArrangementCommentParentId}
                />
                <Box
                  sx={{
                    gridArea: 'setting',
                    width: '100%',
                  }}
                >
                  {loginUser.id === item.writer.id && (
                    <>
                      <IconButton
                        children={
                          <MoreVertIcon
                            sx={{
                              color: `${ColorPalette._b2b4b7}`,
                              cursor: 'pointer',
                            }}
                          />
                        }
                        onClick={() => {
                          if (arrangementCommentId) {
                            setArrangementCommentId(undefined);
                          } else {
                            setArrangementCommentId(item.id);
                          }
                        }}
                        onBlur={() => {
                          setArrangementCommentId(undefined);
                        }}
                      />
                      {arrangementCommentId === item.id && (
                        <Collapse
                          in={!!arrangementCommentId}
                          sx={{
                            width: '60px',
                            zIndex: 1,
                            backgroundColor: `${ColorPalette._ffffff}`,
                            border: `1px solid ${ColorPalette._b2b4b7}`,
                            position: 'relative',
                            left: '-30px',
                            borderRadius: '5px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '20px',
                              borderTopLeftRadius: '5px',
                              borderTopRightRadius: '5px',
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: `${ColorPalette._e4e9f2}`,
                              },
                            }}
                            onMouseDown={() => {
                              setArrangementCommentEditInput({
                                id: item.id,
                                show: true,
                              });
                            }}
                          >
                            <TextBox variant="body9">수정</TextBox>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '20px',
                              cursor: 'pointer',
                              borderBottomLeftRadius: '5px',
                              borderBottomRightRadius: '5px',
                              ':hover': {
                                backgroundColor: `${ColorPalette._e4e9f2}`,
                              },
                            }}
                            onMouseDown={() => {
                              confirm({
                                children: '해당 피드백을 삭제하시겠습니까?',
                                confirmText: '확인',
                                afterConfirm: () => {
                                  deleteArrangementComment(item.id);
                                },
                              });
                            }}
                          >
                            <TextBox variant="body9">삭제</TextBox>
                          </Box>
                        </Collapse>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}

            {arrangementCommentParentId === item.id && (
              <ReplyArrangementAddRowLayout
                setArrangementCommentParentId={setArrangementCommentParentId}
                addArrangementComment={addArrangementComment}
                arrangementCommentParentId={arrangementCommentParentId}
              />
            )}
            {item.step > 0 && (
              <>
                <Box
                  sx={{
                    gridArea: 'reply',
                    display: 'grid',
                    width: '100%',
                    paddingLeft: '20px',
                    gridTemplateColumns: '30px auto 30px',
                    gridTemplateAreas: `"avatar contents setting"
              "reply reply reply"`,
                    minHeight: '100px',
                    marginTop: '5px',
                  }}
                >
                  <Box
                    sx={{
                      gridArea: 'avatar',
                      width: '100%',
                      paddingTop: '5px',
                    }}
                  >
                    <UserIcon user={item.writer.id} />
                  </Box>
                  <ArrangementCommentItem
                    key={`${item.id}_${item.step}`}
                    item={item}
                    updateArrangementComment={updateArrangementComment}
                    deleteArrangementComment={deleteArrangementComment}
                    setArrangementCommentEditInput={setArrangementCommentEditInput}
                    setArrangementCommentParentId={setReplyArrangementCommentParentId}
                  />
                  <Box
                    sx={{
                      gridArea: 'setting',
                      width: '100%',
                    }}
                  >
                    {loginUser.id === item.writer.id && (
                      <>
                        <IconButton
                          children={
                            <MoreVertIcon
                              sx={{
                                color: `${ColorPalette._b2b4b7}`,
                                cursor: 'pointer',
                              }}
                            />
                          }
                          onClick={() => {
                            if (arrangementCommentId) {
                              setArrangementCommentId(undefined);
                            } else {
                              setArrangementCommentId(item.id);
                            }
                          }}
                          onBlur={() => {
                            setArrangementCommentId(undefined);
                          }}
                        />
                        {arrangementCommentId === item.id && (
                          <Collapse
                            in={!!arrangementCommentId}
                            sx={{
                              width: '60px',
                              zIndex: 1,
                              backgroundColor: `${ColorPalette._ffffff}`,
                              border: `1px solid ${ColorPalette._b2b4b7}`,
                              position: 'relative',
                              left: '-30px',
                              borderRadius: '5px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20px',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                                cursor: 'pointer',
                                ':hover': {
                                  backgroundColor: `${ColorPalette._e4e9f2}`,
                                },
                              }}
                              onMouseDown={() => {
                                setArrangementCommentEditInput({
                                  id: item.id,
                                  show: true,
                                });
                              }}
                            >
                              <TextBox variant="body9">수정</TextBox>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '20px',
                                cursor: 'pointer',
                                borderBottomLeftRadius: '5px',
                                borderBottomRightRadius: '5px',
                                ':hover': {
                                  backgroundColor: `${ColorPalette._e4e9f2}`,
                                },
                              }}
                              onMouseDown={() => {
                                confirm({
                                  children: '해당 피드백을 삭제하시겠습니까?',
                                  confirmText: '확인',
                                  afterConfirm: () => {
                                    deleteArrangementComment(item.id);
                                  },
                                });
                              }}
                            >
                              <TextBox variant="body9">삭제</TextBox>
                            </Box>
                          </Collapse>
                        )}
                      </>
                    )}
                  </Box>
                  {replyArrangementCommentParentId === item.id && (
                    <ReplyArrangementAddRowLayout
                      setArrangementCommentParentId={setReplyArrangementCommentParentId}
                      addArrangementComment={addArrangementComment}
                      arrangementCommentParentId={replyArrangementCommentParentId}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        ))}
    </>
  );
}

export default ArrangementCommentList;
