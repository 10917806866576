import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import { ColorPalette } from '@front/assets/theme';
import { useCustomDialog } from '@front/src/features/dialog';
import { FormProvider } from 'react-hook-form';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NoteIcon from '@mui/icons-material/Note';
import useWorkReferenceForm from '@front/src/features/work/features/work/features/reference/hooks/useForm';
import useWorkReferenceTableRowMutationLogic from '@front/src/features/work/features/work/features/reference/hooks/useTableRowMutation';
import LinkIcon from '@mui/icons-material/Link';
import { Download, ManageAccounts } from '@mui/icons-material';
import useCopyLink from '@front/src/features/file-item/features/link/hooks/useCopyLink';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import useGetUIBuilderTableFileAuthState from '@front/src/components/ui-builder/table/hooks/useGetFileAuthState';

export default function WorkReferenceTableRow(props: UIBuilderTableRowProps<WorkReferenceView>) {
  const { item, sectionId, menuId, dataId, formContext } = props;

  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );

  const { watch } = formContext;

  const editOpenIdList = watch('editOpenIdList');
  const { toggleEditOpenId, recentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { onSeqUp, onSeqDown, onRemove, onCreate, onUpdate } =
    useWorkReferenceTableRowMutationLogic({
      sectionId,
      menuId,
      dataId,
      formContext,
    });

  const { methods, onSubmit } = useWorkReferenceForm({
    item,
    onUpdate,
  });

  const { alert } = useCustomDialog();
  const onEdit = () => {
    toggleEditOpenId(item.id);
  };
  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);

  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(sectionId!);
  };

  const { onDownload } = useFileItemDownload({
    fileKey: item.fileItem?.fileKey,
  });

  const { onCopyLink } = useCopyLink({ fileItemId: item.fileItem?.id });

  const { setAndOpen } = useGetUIBuilderTableFileAuthState({
    formContext,
  });

  const actions = {
    onAdd: () => onCreate(item.id),
    onRemove: () => onRemove(item.id),
    onMoveUp: () => onSeqDown(item.id),
    onMoveDown: () => onSeqUp(item.id),
    onEdit,
    onNoteOpen,
    onDownload,
    onOpenFileAuthSettingsModal: () => setAndOpen(item.fileItem?.id),
    onCopyLink,
  };

  const sx = getSx(recentUpdatedId, item.id);
  const isEditOpen = editOpenIdList.includes(item.id);

  const itemList = [
    { text: '추가', icon: AddCircleOutlineIcon, action: actions.onAdd, seq: 2 },
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: actions.onEdit,
      split: true,
      seq: 3,
    },
    { text: '이동(위)', icon: ArrowUpwardIcon, action: actions.onMoveUp, seq: 4 },
    {
      text: '이동(아래)',
      icon: ArrowDownwardIcon,
      action: actions.onMoveDown,
      split: true,
      seq: 5,
    },
    { text: '비고', icon: NoteIcon, action: actions.onNoteOpen, seq: 6 },
    //{ text: '미리 보기', icon: SearchOutlined, action: () => {}, seq: 1 },
    { text: '링크 복사', icon: LinkIcon, action: actions.onCopyLink, seq: 7 },
    { text: '다운로드', icon: Download, action: actions.onDownload, split: true, seq: 8 },
    {
      text: '권한 설정',
      icon: ManageAccounts,
      action: actions.onOpenFileAuthSettingsModal,
      seq: 9,
    },
    { text: '삭제', icon: DeleteIcon, action: actions.onRemove, iconColor: '#EF3333', seq: 100 },
  ];

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        sx={sx}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
}

const getSx = (colored, id) => ({
  backgroundColor: colored === id ? `${ColorPalette._d2e7fa}` : 'inherit',
});
