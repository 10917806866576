import { create } from 'zustand';
import { PersonTabType } from '@front/src/features/affiliated-company/types/domain';

export { useState as useAffiliatedCompanyPersonDetailModalState };

interface State {
  tab: PersonTabType;
  setTab: (tab: PersonTabType) => void;
}

const useState = create<State>((set) => ({
  tab: PersonTabType.OUTLINE,
  setTab: (tab: PersonTabType) => set(() => ({ tab })),
}));
