import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyMutation } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/query/mutation';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyQuery } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/query/query';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyRepository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoAffiliatedCompanyAffiliatedCompanyQuery.useListGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const { mutate } = projectSalesInfoAffiliatedCompanyAffiliatedCompanyMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
