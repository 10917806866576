import { IconButton } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import Tooltip from '@front/components/Tooltip';
import React from 'react';
import type { DefaultFunction } from '@front/type/Function';
import { ColorPalette } from '@front/assets/theme';
import BadgeUI from "@front/src/components/components-with-design/component/BadgeUI";

interface Props {
  count: number;
  onClick: DefaultFunction;
}

export default function (props: Props) {
  return (
    <Tooltip
      title="알림"
      placement="bottom"
    >
      <IconButton
        onClick={props.onClick}
        sx={{
          padding: '0.4rem',
          color: props.count ? ColorPalette._ffb72b : ColorPalette._2d3a54,
          '&:hover': {
            color: props.count ? ColorPalette._ffb72b : ColorPalette._e4e9f2,
          },
        }}
      >
        <BadgeUI badgeContent={props.count}>
          <NotificationsIcon/>
        </BadgeUI>
      </IconButton>
    </Tooltip>
  );
}
