export const enum ForeignCurrencyAccountInUse {
  'IN_USE' = 'IN_USE',
  'NOT_IN_USE' = 'NOT_IN_USE',
}

export const ForeignCurrencyAccountInUseObject = {
  [ForeignCurrencyAccountInUse.IN_USE]: '사용',
  [ForeignCurrencyAccountInUse.NOT_IN_USE]: '미사용',
};

export const enum IsUse {
  'YES' = 'YES',
  'NO' = 'NO',
}
