import React, { useEffect, useMemo } from 'react';
import { PersonalSettingsModalUISettingsModalSettingsSubmitSection } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SubmitSection';
import { Box, Divider } from '@mui/material';
import { PersonalSettingsModalUISettingsModalSettingsModalStatus } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ModalStatus';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeader } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeader';
import { FormProvider, useForm } from 'react-hook-form';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useShallow } from 'zustand/react/shallow';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { personalSettingsModalUISettingsModalSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/repository/repository';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { Form as PersonalSettingsModalUISettingsModalSettingsForm };
interface Props {
  authMenuId: number | undefined;
}
const Form = ({ authMenuId }: Props) => {
  const { sectionId } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      sectionId: state.sectionId,
    }))
  );
  const { data: list } = personalSettingsModalUISettingsModalSettingsRepository.useGetHeaderList(
    sectionId,
    authMenuId
  );
  const { data: selectableHeaderList } =
    personalSettingsModalUISettingsModalSettingsRepository.useGetModalHeaderList(
      sectionId,
      authMenuId
    );
  const { setIsDirty } = useCancelBlockModalState(
    useShallow((state) => ({
      setIsDirty: state.setIsDirty,
    }))
  );
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  const childList = useMemo(
    () =>
      list!
        .find((l) => l.id === parentId)
        ?.modalHeaderList.map((m) => ({
          headerSettingId: m.headerSettingId,
          name: m.name,
        })),
    [parentId, list]
  );
  const parent = useMemo(() => list!.find((parent) => parent.id === parentId), [list, parentId]);
  const methods = useForm({
    values: {
      childList,
      selectableHeaderList: selectableHeaderList!
        .filter((s) => s.headerSettingId !== parentId)
        .map((sh) => ({
          headerSettingId: sh.headerSettingId,
          name: sh.name,
          isUsed: parent?.id === sh.parentId && sh.isUsed,
          id: sh.id,
          note: sh.note ?? '',
          parentId: sh.parentId,
          parentName: sh.parentName ?? '',
          version: sh.version,
        })),
    },
  });
  const {
    formState: { isDirty },
  } = methods;
  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);
  return (
    <FormProvider {...methods}>
      <PersonalSettingsModalUISettingsModalSettingsSubmitSection authMenuId={authMenuId} />
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 40px 4fr',
        }}
      >
        <PersonalSettingsModalUISettingsModalSettingsModalStatus authMenuId={authMenuId} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <ArrowLeftIcon />
          <ArrowRightIcon />
        </Box>
        <PersonalSettingsModalUISettingsModalSettingsSelectableHeader authMenuId={authMenuId} />
      </Box>
    </FormProvider>
  );
};
