import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const useWorkBasicInfoForm = ({ detail, onUpdate }) => {
  const methods = useForm<TFormValues>({
    defaultValues: {
      purpose: '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate(data);
  });
  useEffect(() => {
    if (!detail) return;
    methods.reset({
      ...getFormValues(detail),
    });
  }, [detail]);
  return {
    methods,
    onSubmit,
  };
};

export default useWorkBasicInfoForm;

const getFormValues = (detail) => ({
  purpose: detail?.purpose ?? '',
});

interface TFormValues {
  purpose: string;
}
