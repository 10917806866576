import React from 'react';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { ProjectSalesInfoCMPaymentHistoryCreateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/create-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoCMPaymentHistoryCreateModalWrapper };
interface Props {
  menuId?: number;
}
const ModalWrapper = ({ menuId }: Props) => {
  const isCreateModalOpen = useProjectSalesInfoCMPaymentHistoryState(
    (state) => state.isCreateModalOpen
  );
  return (
    <>
      {isCreateModalOpen && <ProjectSalesInfoCMPaymentHistoryCreateModalWidget menuId={menuId} />}
    </>
  );
};
