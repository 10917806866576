import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useShallow } from 'zustand/react/shallow';
import { useAccountingUploadSalesInfoPerformanceModalState } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/useState';
import { AccountingUploadSalesInfoPerformanceModalBody } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/components/body';
import { AccountingUploadSalesInfoPerformanceModalListWidget } from '@front/src/features/accounting/features/upload/features/sales-info/features/performance-modal/widgets/list/widget';

export { Modal as AccountingUploadSalesInfoPerformanceModal };
interface Props {
  menuId?: number;
}
const Modal = ({ menuId }: Props) => {
  const { setIsOpen, isOpen } = useAccountingUploadSalesInfoPerformanceModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }))
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      title="실적마감"
      open={isOpen}
      onClose={handleClose}
      width="72dvw"
      height="636px"
    >
      <AccountingUploadSalesInfoPerformanceModalBody>
        <AccountingUploadSalesInfoPerformanceModalListWidget menuId={menuId} />
      </AccountingUploadSalesInfoPerformanceModalBody>
    </ModalLayout>
  );
};
