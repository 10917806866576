import { approvalApi } from '@front/src/features/drawer-approval/api/api';
import { useQuery } from 'react-query';
import type { ApprovalHasFileListParams } from '@front/src/features/drawer-approval/types/parameter';

export const approvalQuery = {
  useGetApprovalDocumentList: () => {
    const { data } = useQuery({
      queryKey: ['approval-document'],
      queryFn: () => approvalApi.getApprovalDocumentList(),
      suspense: true,
    });
    return { data };
  },

  useGetApprovalDocumentSourceTitle: (params) => {
    const { data } = useQuery({
      queryKey: ['approval-document', 'source-title', params],
      queryFn: () => approvalApi.getApprovalDocumentSourceTitle(params),
      suspense: true,
    });
    return { data };
  },

  useGetHasFileList: (params: ApprovalHasFileListParams) => {
    const { data } = useQuery({
      queryKey: ['approval', 'has-file', params],
      queryFn: () => approvalApi.getHasFileList(params),
      suspense: true,
    });
    return { data };
  },

  useGetWorkRole: (workId: number) => {
    const { data } = useQuery({
      queryKey: ['approval', 'work-role', workId],
      queryFn: () => approvalApi.getWorkRole(workId),
      suspense: true,
    });
    return { data };
  },
};
