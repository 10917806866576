import React from 'react';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import type { UserVO } from '@front/user/domain';
import { useFormContext } from 'react-hook-form';

interface Props {
  onChange: (value: UserVO[]) => void;
  onClose?: () => void;
}

export default function UserSelectorUnchangedMultiFormSubmitButtonGroup(props: Readonly<Props>) {
  const { onChange, onClose } = props;
  const { handleSubmit } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    onChange(data.unchangeableUserList.concat(data.changeableUserList));
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px',
      }}
    >
      <ButtonBasicUI
        shape="secondary2"
        size="medium"
        onClick={onClose}
      >
        취소
      </ButtonBasicUI>
      <ButtonBasicUI
        shape="primary2"
        size="medium"
        onClick={onSubmit}
      >
        저장
      </ButtonBasicUI>
    </Box>
  );
}
