import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import AccountStatusWidgetSummaryTableHead
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/summary-table/head';
import AccountStatusWidgetSummaryTableBody
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/summary-table/body';
import useAccountStatusIsLoading
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/hook';
import AccountStatusRepository
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/repository';

type Props = Readonly<SectionComponentProps>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UiBuilderTable>;

const AccountStatusWidgetSummaryTable: ElementType = (props) => {

  const { menuId, sectionId } = props;
  const isLoading = useAccountStatusIsLoading();

  const { data } = AccountStatusRepository.useGetSummaryList({ menuId, sectionId });
  if (!menuId || !data) {
    return (<LoadingSpinnerUI />);
  }
  return (
    <UiBuilderTable
      {...props}
      HeadComponent={AccountStatusWidgetSummaryTableHead}
      BodyComponent={AccountStatusWidgetSummaryTableBody}
      isLoading={isLoading}
      list={data}
      readOnly={true}
      useNote={false}
    />
  );
};

export default AccountStatusWidgetSummaryTable;
