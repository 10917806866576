import { accountingTaxAccountingURL } from '@front/src/features/accounting/features/settings/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

export { Api as AccountingSettingsTexAccountingAccountApi };

const url = {
  getAll: () => `${accountingTaxAccountingURL}/get-all`,
  getCount: () => `${accountingTaxAccountingURL}/get-count`,
  getCreatedAt: () => `${accountingTaxAccountingURL}/created-at`,
};

const Api = {
  getAll: async (menuId) => {
    const { data } = await axios.get(url.getAll(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getCount: async (menuId) => {
    const { data } = await axios.get(url.getCount(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getCreatedAt: async (menuId) => {
    const { data } = await axios.get(url.getCreatedAt(), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
