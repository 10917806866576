import React from 'react';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import { WorkCarouselPosition } from '@front/src/features/work/features/work/features/carousel/utils/constant';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

interface Props {
  onClose: () => void;
  onSubmit: any;
}

export default function WorkCarouselMoveSlideHeader({ onSubmit, onClose }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 20px',
      }}
    >
      <DropDownWithHookForm
        name="position"
        options={options}
        required="이미지 슬라이드 위치를 선택해주세요."
        sx={{
          width: '80%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={onClose}
          sx={{
            width: '64px',
          }}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          onClick={onSubmit}
          shape="primary2"
          size="medium"
          sx={{
            width: '64px',
          }}
        >
          이동
        </ButtonBasicUI>
      </Box>
    </Box>
  );
}

const options = [
  {
    label: '맨 앞으로 이동',
    value: WorkCarouselPosition.FRONT,
  },
  {
    label: '맨 뒤로 이동',
    value: WorkCarouselPosition.BACK,
  },
];
