import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { ContractFinalTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/final/components/table-head';
import { ContractFinalTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/final/components/table-body';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';

export { Table as ContractFinalTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const { data: list } = contractHistoryQuery.useList({
    dataId: sectionProps.sectionId,
    menuId: sectionProps.sectionId,
    sectionId: sectionProps.sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={ContractFinalTableHead}
      BodyComponent={ContractFinalTableBody}
      readOnly={true} // 읽기모드에서는 동작버튼[:]을 제공하지 않는다
      useNote={false} // 노트 사용 여부
      useSummary={true} // 합산행 사용 여부
      list={list?.filter((item) => item.confirmed === 'Y')}
    />
  );
}
