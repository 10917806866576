import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { contractEarningsHeadList } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import { Th } from '@front/src/components/layout/table/th';
import classes from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/components/contract-earnings.module.scss';

export { ContractEarningsHeader as ContractHeaderWrapper };

const ContractEarningsHeader = () => (
  <TableHead>
    <TableRow>
      {contractEarningsHeadList.map((item) => (
        <Th
          key={item}
          center={true}
          className={`${classes.width__4} ${classes.bid__th}`}
        >
          {item}
        </Th>
      ))}
    </TableRow>
  </TableHead>
);
