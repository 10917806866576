import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { CollectionHistoryTableBody } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/table-body';
import { CollectionHistoryTableHead } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/table-head';

export { Table as CollectionHistoryTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const list = [];

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={CollectionHistoryTableHead}
      BodyComponent={CollectionHistoryTableBody}
      readOnly={false}
      list={list}
    />
  );
}
