import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import type { ProjectEstimationConfirmedParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-confirmed/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as projectSalesInfoEstimationConfirmedApi };
const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/${id}/estimation-confirmed`,
  update: (id?) => `${projectSalesInfoURL}/estimation-confirmed/${id}`,
};

const api = {
  getDetail: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  update: async (params: ProjectEstimationConfirmedParameter, menuId) => {
    await axios.put(url.update(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
