import React from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import type { DefaultFunction } from '@front/src/types';
import { useProjectSelectorModalLogic } from '@front/src/features/project-selector-modal/widgets/useLogic';
import type { ProjectShortView } from '@front/src/features/project-selector-modal/types/view';
import { ProjectSelectorModalBodyWidget } from '@front/src/features/project-selector-modal/widgets/body/widget';

export { Widget as ProjectSelectorModalWidget };

interface Props {
  isOpen: boolean;
  onClose: DefaultFunction;
  onSelect: (project: ProjectShortView) => void;
}

const Widget = ({ isOpen, onClose, onSelect }: Props) => {
  const {
    h: { onProjectSelect },
  } = useProjectSelectorModalLogic({ onSelect, onClose, isOpen });
  return (
    <ModalLayout
      width="44dvw"
      open={isOpen}
      onClose={onClose}
      title="프로젝트 선택"
    >
      <ProjectSelectorModalBodyWidget onSelect={onProjectSelect} />
    </ModalLayout>
  );
};
