import {
  register as registerSection,
  SectionComponent,
  unregister as unregisterSection,
} from '@front/src/components/ui-builder/sectionComponent';
import { useEffect } from 'react';

export const useSectionComponentLifeCycleManager = (sectionComponents: SectionComponent) => {
  Object.keys(sectionComponents).forEach((sectionId) => {
    registerSection(+sectionId, sectionComponents[sectionId]);
  });

  useEffect(() => {
    return () => {
      Object.keys(sectionComponents).forEach((sectionId) => {
        unregisterSection(+sectionId);
      });
    };
  }, []);
};
