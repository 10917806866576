import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { AffiliatedPersonDrawerListContext } from '@front/src/features/affiliated-person/features/drawer/widgets/list/provider/context';
import { useAffiliatedPersonDrawerListLogic } from '@front/src/features/affiliated-person/features/drawer/widgets/list/provider/useLogic';

export { Provider as AffiliatedPersonDrawerListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list } = useAffiliatedPersonDrawerListLogic(menuId);
  const value = useMemo(
    () => ({
      list,
    }),
    [list]
  );
  return (
    <AffiliatedPersonDrawerListContext.Provider value={value}>
      {children}
    </AffiliatedPersonDrawerListContext.Provider>
  );
};
