import React, { Suspense, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import NotificationFilter from '@front/src/features/notification/components/Filter';
import NotificationCard from '@front/src/features/notification/components/Card';
import useGetNotificationList from '@front/src/features/notification/repository/useGetNotificationList';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import SkeletonUI from '@front/src/components/components-with-design/component/skeleton/Skeleton';
import { useNotificationMutationLoading } from '@front/src/features/notification/hooks/useNotificationMutationLoading';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';

export default function NotificationContent() {
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const onClose = () => {
    setLeftDrawerState('');
  };
  const isLoading = useNotificationMutationLoading();

  return (
    <Box sx={{ ...SX_MODAL }}>
      <BackdropLoadingSpinnerUI
        open={isLoading}
        sx={{
          position: 'absolute',
        }}
      />
      <Box sx={{ ...SX_HEADER }}>
        <Typography sx={{ ...SX_HEADER_TITLE }}>알림함</Typography>
        <CloseIcon
          onClick={onClose}
          sx={{ ...SX_CLOSE }}
        />
      </Box>
      <Suspense fallback={<LoadingSpinnerUI height="103px" />}>
        <NotificationFilter />
      </Suspense>
      <Box sx={{ ...SX_BODY }}>
        <Box>
          <Suspense fallback={<SkeletonUI />}>
            <CardList />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

const CardList = () => {
  const { bookmarkedList, filteredList } = useGetNotificationList();
  const cardRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);
  useEffect(() => {
    if (cardRef.current) {
      setHeight(cardRef.current.offsetHeight ?? 0);
    }
  }, [cardRef, bookmarkedList]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: bookmarkedList?.length === 0 ? '0px' : '14px',
      }}
    >
      <Box
        sx={{
          height: `${
            (bookmarkedList?.length ?? 0) >= 3
              ? 392
              : 121 * (bookmarkedList?.length ?? 0) + 15 * ((bookmarkedList?.length ?? 0) - 1)
          }px`,
          overflow: 'auto',
        }}
        ref={cardRef}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          }}
        >
          {bookmarkedList?.map((item) => (
            <NotificationCard
              key={item.id}
              item={item}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          height:
            bookmarkedList?.length === 0
              ? 'calc(100dvh - 200px)'
              : `calc(100dvh - 200px - ${height}px)`,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '14px',
          }}
        >
          {filteredList?.map((item) => (
            <NotificationCard
              key={item.id}
              item={item}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const SX_MODAL = {
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  boxShadow: '20px 0px 10px rgba(0,0,0,0.15)',
  overflow: 'hidden',
  position: 'relative',
};

const SX_HEADER = {
  display: 'flex',
  height: '42px',
  padding: '0px 14px',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  color: 'white',
  position: 'relative',
  background: 'var(--design-v-10-light-background-bg-04, #1F314E)',
};

const SX_BODY = {
  height: 'calc(100dvh - 166px)',
  padding: '14px',
};

const SX_HEADER_TITLE = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  '& > span': {
    color: 'var(--design-v-10-light-sub-sub-primary, #FFB611)',
    borderLeft: '1px solid #B0B5BE',
    paddingLeft: '10px',
    marginLeft: '10px',
  },
};

const SX_CLOSE = {
  cursor: 'pointer',
  position: 'absolute',
  right: '14px',
  width: '18px',
  height: '18px',
};
