import { createContext } from 'react';
import type { DefaultFunction } from '@front/src/types';
import { noOp } from '@front/common/contants';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import type { AffiliatedCompanyPrimaryBusinessView } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/view';
import type { AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/primary-business/types/parameter';

export { Context as AffiliatedCompanyOutlinePrimaryBusinessContext };

interface State {
  d: {
    primaryBusinessList?: AffiliatedCompanyPrimaryBusinessView[];
  };
  h: {
    onCreatePrimaryBusiness: DefaultFunction;
    setIdList: (idList: number[]) => void;
    onDeletePrimaryBusiness: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    onUpdatePrimaryBusiness: (
      params: AffiliatedCompanyOutlinePrimaryBusinessUpdateParameter
    ) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {
    primaryBusinessList: [],
  },
  h: {
    onCreatePrimaryBusiness: noOp,
    setIdList: noOp,
    onDeletePrimaryBusiness: noOp,
    onUpdatePrimaryBusiness: noOp,
  },
  isLoading: false,
  idList: [],
});
