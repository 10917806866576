import React, { useState } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkApprovalTableBody from '@front/src/features/work/features/work/features/approval/components/TableBody';
import WorkApprovalModal from '@front/src/features/work/features/work/features/approval/components/Modal';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';
import WorkApprovalTableHead from '@front/src/features/work/features/work/features/approval/components/TableHead';

export default function WorkApprovalTable(props: Readonly<SectionComponentProps>) {
  const { dataId, menuId, sectionId } = props;
  const [workId, setWorkId] = useState<number | undefined>();

  const onClose = () => setWorkId(undefined);

  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <>
      {workId && (
        <WorkApprovalModal
          {...props}
          workId={workId}
          onClose={onClose}
        />
      )}
      <UiBuilderTable
        {...props}
        HeadComponent={WorkApprovalTableHead}
        BodyComponent={WorkApprovalTableBody}
        options={{
          setWorkId,
        }}
        list={data?.approvalDocumentList}
      />
    </>
  );
}
