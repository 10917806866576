import React from 'react';
import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import { contractPaymentTermsTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-terms/components/table-cells';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';

interface Props extends CellComponentProps<CollectionFinalView> {}

/**
 * 발주처 지불담당자
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanyPersonSelector
    {...props}
    companyName="client"
    personName="clientPaymentManager"
  />
);

/**
 * 계약실풍동금액
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => <>{props.item.actualWindTunnelPrice}</>;

/**
 * 계약CM비율액
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => <>{props.item.contractCM}</>;

/**
 * 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => <>{props.item.cmRatioAgainstContractPrice}</>;

export const collectionFinalTableCells: CellComponent = {
  603: contractPaymentTermsTableCells[562], // 계약지불단계
  604: contractPaymentTermsTableCells[563], // 계약지불순번
  605: contractPaymentTermsTableCells[564], // 계약발주처
  606: CellRenderer1, // 발주처 지불담당자
  607: contractPaymentTermsTableCells[565], // 계약수금시기
  608: contractPaymentTermsTableCells[566], // 계약응당일
  609: contractPaymentTermsTableCells[567], // 발주처별 비율
  610: contractPaymentTermsTableCells[568], // 각사 지불단계비율
  611: contractPaymentTermsTableCells[569], // 계약총액
  612: contractPaymentTermsTableCells[570], // 계약순풍동금액
  613: CellRenderer2, // 계약실풍동금액 = 계약총액 - (계약구검비율액 + 계약CM비율액)
  614: contractPaymentTermsTableCells[571], // 계약구검
  615: contractPaymentTermsTableCells[572], // 계약구검비율
  616: contractPaymentTermsTableCells[574], // 계약구검 지급대상
  617: contractPaymentTermsTableCells[575], // 계약구검 지급예정일
  618: CellRenderer3, // 계약CM비율액 = 계약총액 * 계약CM비율
  619: CellRenderer4, // 계약CM비율
};
