import { useIsMutating } from 'react-query';
import AccountStatusConstant
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/constant';

type UseIsLoadingType = () => boolean;

const useAccountStatusIsLoading: UseIsLoadingType = () => {
  const list = !!useIsMutating({
    mutationKey: AccountStatusConstant.queryKey.list,
  });

  return (
    list
  );
};

export default useAccountStatusIsLoading;
