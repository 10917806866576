import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import WorkOutputBundleList from '@front/src/features/work/features/work/features/output-bundle/components/List';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import useWorkOutputBundleGetIsLoading from '@front/src/features/work/features/work/features/output-bundle/hooks/useGetIsLoading';

export default function WorkOutputBundleFeature(props: Readonly<SectionComponentProps>) {
  const { Layout } = SectionWrapperUI;

  const isLoading = useWorkOutputBundleGetIsLoading();

  return (
    <Layout
      sx={{
        position: 'relative',
      }}
    >
      <BackdropLoadingSpinnerUI
        open={isLoading}
        sx={{
          position: 'absolute',
          zIndex: 3,
        }}
      />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkOutputBundleList {...props} />
      </Suspense>
    </Layout>
  );
}
