import React from 'react';
import AccountingUploadSalesInfoFeature from '@front/src/features/accounting/features/upload/features/sales-info';

import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const ProjectSalesManagementSalesTargetExpectationFeature = (props: Readonly<Props>) => {
  const { name, menuId } = props;
  const { Layout, Header, Body } = AddDeleteTable;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <AccountingUploadSalesInfoFeature
          {...props}
          readOnly={readOnly}
        />
      </Body>
    </Layout>
  );
};

export default ProjectSalesManagementSalesTargetExpectationFeature;
