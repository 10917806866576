import React from 'react';
import { type UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import ManagementAccountTableCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/Cell';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';

interface Props extends SectionComponentProps {
  index: number;
  showListFieldArray: UseFieldArrayReturn<ManagementAccountTableFormValues, 'showList', 'id'>;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
  onTableBodyFocus: () => void;
}

export default function ManagementAccountTableVerticalRow(props: Props) {
  const { index: colIndex } = props;
  const { getValues } = useFormContext<ManagementAccountTableFormValues>();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {[...Array(getValues('row'))].map((_, rowIndex) => (
        <ManagementAccountTableCell
          {...props}
          key={`col-${colIndex}-row-${rowIndex}`}
          colIndex={colIndex}
          rowIndex={rowIndex}
        />
      ))}
    </Box>
  );
}
