import React, { useCallback, useContext } from 'react';
import type { SmProjectCollectionView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import Checkbox from '@front/layouts/Checkbox';
import { useContractEarningsItemState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/collection/features/payment-history/utils/constant';
import { ProjectSalesInfoContractEarningsItemContext } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/widgets/context';
import { useProjectSalesInfoCollectionManagementModalState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useShallow } from 'zustand/react/shallow';

export { ContractEarningsTableRow as ProjectSalesInfoContractEarningsTableRow };

interface Props {
  item: SmProjectCollectionView;
  index: number;
}

const ContractEarningsTableRow = ({ item }: Props) => {
  const { itemId, idList, setIdList } = useContext(ProjectSalesInfoContractEarningsItemContext);
  const { setIsDetailUpdateModalOpen, setDetailId, setItemId } = useContractEarningsItemState(
    (state) => ({
      setIsDetailUpdateModalOpen: state.setIsDetailUpdateModalOpen,
      setDetailId: state.setDetailId,
      setItemId: state.setItemId,
    }),
    shallow
  );
  const resetCollectionManagementList = useProjectSalesInfoCollectionManagementModalState(
    (state) => state.resetCollectionManagementList
  );
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const { readOnly } = useContractEarningsItemState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <TableRow
      hover={true}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setDetailId(item.id);
        setItemId(itemId!);
        setIsDetailUpdateModalOpen();
        resetCollectionManagementList();
      }}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(item.id)}
          onChange={handleCheckboxChange(item.id)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          disabled={readOnly}
        />
      </OldTd>
      <OldTd>{item.name ?? '-'}</OldTd>
      <OldTd sx={{ whiteSpace: 'nowrap' }}>{item.ordererCompany?.name ?? '-'}</OldTd>
      <OldTd>{item.contractEarningsName ?? '-'}</OldTd>
      <OldTd>{item.paymentRatio ? item.paymentRatio + '%' : '-'}</OldTd>
      <OldTd>{item.paymentCondition ?? '-'}</OldTd>
      <OldTd>{item.amount?.toLocaleString() ?? '-'}</OldTd>
      <OldTd>{item.netAmount?.toLocaleString() ?? '-'}</OldTd>
      <OldTd>{item.collectionAmount?.toLocaleString() ?? '-'}</OldTd>
      <OldTd>{item.collectionDueDate ?? '-'}</OldTd>
      <OldTd>{item.collectionForecastDate ?? '-'}</OldTd>
      <OldTd>{item.collectionPossibilityRatio ? item.collectionPossibilityRatio + '%' : '-'}</OldTd>
    </TableRow>
  );
};
