import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { getTypeForAccountingUploadSalesInfoFormByList } from '@front/src/features/accounting/features/upload/features/sales-info/features/utils';
import { AccountingUploadSalesInfoGoalModalListTableRow } from '@front/src/features/accounting/features/upload/features/sales-info/features/goal-modal/widgets/list/components/table-row';

export { TableBody as AccountingUploadSalesInfoGoalModalListTableBody };

const TableBody = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    name: 'info',
    control,
  });
  return (
    <>
      {fields.map((field, index) => (
        <AccountingUploadSalesInfoGoalModalListTableRow
          key={field.id}
          index={index}
          type={getTypeForAccountingUploadSalesInfoFormByList[index]}
        />
      ))}
    </>
  );
};
