import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workWorkerMutation from '@front/src/features/work/features/work/features/worker/query/mutation';
import type { WorkWorkerParams } from '@front/src/features/work/features/work/features/worker/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkWorkerUpdate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workWorkerMutation.useUpdate(commonParams);
  return {
    run: (params: WorkWorkerParams) => mutate(params, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkWorkerUpdate;
