import React from 'react';

import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
interface Props {
  onOpen: () => void;
}
export const PersonalSettingsModalBtn = ({ onOpen }: Props) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  if (!isValid) return <></>;
  return (
    <ButtonBasicUI
      shape="primary"
      size="medium"
      onClick={onOpen}
    >
      설정
    </ButtonBasicUI>
  );
};
