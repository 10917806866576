import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import AccountingAccountManagementAccountSettingsManagementAccountTableHeadMenu from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/components/HeadMenu';
import Box from '@mui/material/Box';

interface Props {
  index: number;
}

export default function AccountingAccountManagementAccountSettingsManagementAccountTableHeadCell({
  index,
}: Readonly<Props>) {
  const { getValues } = useFormContext<ManagementAccountTableFormValues>();

  const showList = getValues('showList');

  const cellData = `${headMeta[index]?.name}(${showList[index]?.length ?? 0}/${
    getValues('header')[index] ?? 0
  })`;

  return (
    <NDepthTableBoxCell
      isHead
      key={`head-${index}`}
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
        }}
      >
        {<AccountingAccountManagementAccountSettingsManagementAccountTableHeadMenu index={index} />}
      </Box>
      {cellData}
    </NDepthTableBoxCell>
  );
}

const headMeta: Record<number, any> = {
  0: {
    name: '대분류',
  },
  1: {
    name: '계좌 용도',
  },
  2: {
    name: '중분류',
  },
  3: {
    name: '소분류',
  },
  4: {
    name: '미분류',
  },
  5: {
    name: '말단계정',
  },
};
