import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import workDetailMutation from '@front/src/features/work/features/work/features/detail/query/mutation';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkDetailCreate = (commonParams: UIBuilderParameter) => {
  const callback = getNoOpCallback();
  const { mutate } = workDetailMutation.useCreate(commonParams);
  return {
    run: (id: number) => mutate(id, callback),
    setCallback: getSetCallbackFunc(callback),
  };
};

export default useWorkDetailCreate;
