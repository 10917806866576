import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

import DateAttr from '@front/src/components/ui-builder/table/cell-renderer/DateAttr';
import NumberAttr from '@front/src/components/ui-builder/table/cell-renderer/NumberAttr';
import TextAttr from '@front/src/components/ui-builder/table/cell-renderer/TextAttr';
import type { WorkWorkerView } from '@front/src/features/work/features/work/features/worker/types/view';
import Box from '@mui/material/Box';

import type { ConfirmView } from '@front/src/features/dialog/types';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

const workWorkerCellComponent = {
  1: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowDepartment {...rest} />,
  2: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowJobDuty {...rest} />,
  3: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowUser {...rest} />,
  4: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowWorkerRole {...rest} />,
  5: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowParticipationRate {...rest} />,
  6: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowRoleDetail {...rest} />,
  68: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowNumberAttr1 {...rest} />,
  69: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowTextAttr1 {...rest} />,
  70: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowDateAttr1 {...rest} />,
  71: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowNumberAttr2 {...rest} />,
  72: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowTextAttr2 {...rest} />,
  73: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowDateAttr2 {...rest} />,
  74: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowNumberAttr3 {...rest} />,
  75: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowTextAttr3 {...rest} />,
  76: ({ ...rest }: CellComponentProps) => <WorkWorkerTableRowDateAttr3 {...rest} />,
};

export default workWorkerCellComponent;

interface Props extends CellComponentProps<WorkWorkerView> {}

const WorkWorkerTableRowDepartment = ({ item, onClick, sx = {} }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.department?.name ?? '-'}
  </Box>
);

const WorkWorkerTableRowJobDuty = ({ item, onClick, sx = {} }: Props) => (
  <Box
    sx={sx}
    onClick={onClick}
  >
    {item.user?.jobDuty ?? '-'}
  </Box>
);

const WorkWorkerTableRowUser = (props: Props) => {
  const confirmProps: ConfirmView = {
    title: '협업자 삭제 확인 모달',
    lineBreakChildren: [
      { value: '역할명에서 제외되면 해당 업무를 열람할 수 없습니다.' },
      { value: '제외 하시겠습니까?' },
    ],
  };

  return (
    <UIBuilderTableCellUserSelector
      {...props}
      name="user"
      confirmProps={confirmProps}
    />
  );
};

function WorkWorkerTableRowWorkerRole({
  isEditMode,
  onSubmit,
  onClick,
  isForm,
  sx = {},
  readOnly,
}: Props) {
  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      <UncontrolledInput
        name="workerRole"
        onBlur={isForm ? undefined : onSubmit}
        placeholder="직접 입력"
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

function WorkWorkerTableRowParticipationRate({
  isEditMode,
  onSubmit,
  onClick,
  isForm,
  sx = {},
  readOnly,
}: Props) {
  return (
    <Box
      onClick={onClick}
      sx={sx}
    >
      <UncontrolledInput
        type="number"
        name="participationRate"
        onBlur={isForm ? undefined : onSubmit}
        placeholder="직접 입력"
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

function WorkWorkerTableRowRoleDetail({
  isEditMode,
  onSubmit,
  onClick,
  sx = {},
  readOnly,
  isForm,
}: Props) {
  return (
    <Box
      onClick={onClick}
      sx={{
        ...sx,
        textAlign: 'left',
      }}
    >
      <UncontrolledInput
        name="roleDetail"
        onBlur={isForm ? undefined : onSubmit}
        placeholder="직접 입력"
        readOnly={readOnly || !isEditMode}
      />
    </Box>
  );
}

const WorkWorkerTableRowNumberAttr1 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr1"
    {...rest}
  />
);

const WorkWorkerTableRowTextAttr1 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr1"
    {...rest}
  />
);

const WorkWorkerTableRowDateAttr1 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr1"
    {...rest}
  />
);

const WorkWorkerTableRowNumberAttr2 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr2"
    {...rest}
  />
);

const WorkWorkerTableRowTextAttr2 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr2"
    {...rest}
  />
);

const WorkWorkerTableRowDateAttr2 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr2"
    {...rest}
  />
);

const WorkWorkerTableRowNumberAttr3 = ({ ...rest }: Props) => (
  <NumberAttr
    name="numberAttr3"
    {...rest}
  />
);

const WorkWorkerTableRowTextAttr3 = ({ ...rest }: Props) => (
  <TextAttr
    name="textAttr3"
    {...rest}
  />
);

const WorkWorkerTableRowDateAttr3 = ({ ...rest }: Props) => (
  <DateAttr
    name="dateAttr3"
    {...rest}
  />
);
