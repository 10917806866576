import { useCustomDialog } from '@front/src/features/dialog';
import { type UseFormReturn } from 'react-hook-form';
import { getManagementAccountParentId } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/utils';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';
import { useSnackbar } from 'notistack';
import useDebounce from '@front/src/hooks/useDebounce';

interface Props {
  onMoveUp: (params) => void;
  onMoveDown: (params) => void;
  onDelete: (id) => void;
  onInsert: (params) => void;
  cellData?: ManagementAccountingTreeView;
  onClose?: () => void;
  formContext: UseFormReturn<ManagementAccountTableFormValues, any, undefined>;
}

const useManagementAccountContextMenuActions = ({
  onMoveUp,
  onMoveDown,
  onDelete,
  onInsert,
  cellData,
  onClose,
  formContext,
}: Props) => {
  const { getValues, setValue } = formContext;

  const showList = getValues('showList');
  const selectedRowIndexList = getValues('selectedRowIndexList');

  const parentId = getManagementAccountParentId(showList, selectedRowIndexList, cellData?.depth);

  const handleClickChangeName = () => {
    if (!cellData) return;
    const { id } = cellData;
    setValue('updateId', id);
    onClose?.();
  };

  const onDebounce = useDebounce();

  const { enqueueSnackbar } = useSnackbar();
  const handleMoveUp = () => {
    if (!cellData) return;
    const { name, sequence, depth } = cellData;
    if (sequence === 0) {
      enqueueSnackbar('해당 열의 첫번째 행입니다', { variant: 'warning' });
      return;
    }
    onMoveUp({
      id: cellData.id,
      name,
      sequence: sequence - 1,
      depth,
      parentId,
    });
  };

  const handleMoveDown = () => {
    if (!cellData) return;
    const { name, sequence, depth } = cellData;
    if (showList[depth].length - 1 === sequence) {
      enqueueSnackbar('해당 열의 마지막 행입니다', { variant: 'warning' });
      return;
    }
    onMoveDown({
      id: cellData.id,
      name,
      sequence: sequence + 1,
      depth,
      parentId,
    });
  };

  const handleInsert = () => {
    if (!cellData) return;
    const { sequence, depth } = cellData;
    onInsert({
      name: '',
      parentId,
      depth,
      sequence,
    });
  };

  const { confirm } = useCustomDialog();

  const handleDelete = () => {
    if (!cellData) return;
    const { id } = cellData;
    confirm({
      confirmText: '확인',
      children: '정말로 삭제하시겠습니까?',
      afterConfirm: () => {
        onDelete(id);
      },
    });
  };

  const handleKeyDown = (e) => {
    const { key, ctrlKey, altKey } = e;

    onDebounce(() => {
      switch (true) {
        case key === 'F2':
          handleClickChangeName();
          break;
        case ctrlKey && altKey && key === 'ArrowUp':
          handleMoveUp();
          break;
        case ctrlKey && altKey && key === 'ArrowDown':
          handleMoveDown();
          break;
        case ctrlKey && key === 'Help':
          handleInsert();
          break;
        case ctrlKey && key === 'Delete':
          handleDelete();
          break;
      }
    });
  };

  return {
    handleClickChangeName,
    handleMoveUp,
    handleMoveDown,
    handleInsert,
    handleDelete,
    handleKeyDown,
  };
};

export default useManagementAccountContextMenuActions;
