import { createAction } from 'typesafe-actions';

import type { OstMemoSearchQuery, OstSearchQuery } from '@front/ost/query';
import type { OstId, OstMemoId, OstMemoVO, OstVO } from '@front/ost/domain';
import type {
  OstAddParameter,
  OstMemoAddParameter,
  OstMemoUpdateParameter,
  OstTrimmedMeanBottomRateUpdateParameter,
  OstTrimmedMeanTopRateUpdateParameter,
  OstUpdateNameParameter,
  OstUpdatePeriodParameter,
  OstUpdateStatusParameter,
  OstUpdateTotalBudgetParameter,
} from '@front/ost/parameter';
import type { CampaignVO } from '@front/ost_campaign/domain';

export enum OstAction {
  setIsLoading = 'ost/is-loading/set',
  openDrawer = 'ost/drawer/open',
  openAddModal = 'ost/add-modal/open',
  setFilter = 'ost/filter/set',
  setList = 'ost/list/set',
  setCampaignList = 'ost/campaignList/set',
  add = 'ost/add',
  setId = 'ost/id/set',
  setDetail = 'ost/detail/set',

  updateName = 'ost/name/update',
  updatePeriod = 'ost/period/update',
  updateTotalBudget = 'ost/total-budget/update',
  updateStatus = 'ost/status/update',

  deleteDetail = 'ost/detail/delete',

  setIsMemoOpen = 'ost/is-modal-open/set',
  setMemoFilter = 'ost/memo-filter/set',
  addMemo = 'ost/memo/add',
  setMemoList = 'ost/memo-list/set',
  setIsMemoLoading = 'ost/is-memo-loading/set',
  updateMemo = 'ost/memo/update',
  deleteMemo = 'ost/memo/delete',
  updateTrimmedMeanBottomRate = 'ost/evaluation-method-bottom-rate/update',
  updateTrimmedMeanTopRate = 'ost/evaluation-method-top-rate/update',
}

export const ostAction = {
  setIsLoading: createAction(OstAction.setIsLoading)<boolean>(),
  openDrawer: createAction(OstAction.openDrawer)<boolean>(),
  openAddModal: createAction(OstAction.openAddModal)<boolean>(),
  setFilter: createAction(OstAction.setFilter)<OstSearchQuery>(),
  setList: createAction(OstAction.setList)<OstVO[]>(),
  setCampaignList: createAction(OstAction.setCampaignList)<CampaignVO[]>(),

  add: createAction(OstAction.add)<OstAddParameter>(),

  setId: createAction(OstAction.setId)<OstId>(),
  setDetail: createAction(OstAction.setDetail)<OstVO>(),

  updateName: createAction(OstAction.updateName)<OstUpdateNameParameter>(),
  updatePeriod: createAction(OstAction.updatePeriod)<OstUpdatePeriodParameter>(),
  updateTotalBudget: createAction(OstAction.updateTotalBudget)<OstUpdateTotalBudgetParameter>(),
  updateStatus: createAction(OstAction.updateStatus)<OstUpdateStatusParameter>(),

  deleteDetail: createAction(OstAction.deleteDetail)(),
  setIsMemoOpen: createAction(OstAction.setIsMemoOpen)<boolean>(),
  setMemoFilter: createAction(OstAction.setMemoFilter)<OstMemoSearchQuery>(),

  addMemo: createAction(OstAction.addMemo)<OstMemoAddParameter>(),
  setMemoList: createAction(OstAction.setMemoList)<OstMemoVO[]>(),
  setIsMemoLoading: createAction(OstAction.setIsMemoLoading)<boolean>(),
  updateMemo: createAction(OstAction.updateMemo)<OstMemoUpdateParameter>(),
  deleteMemo: createAction(OstAction.deleteMemo)<OstMemoId>(),

  updateTrimmedMeanBottomRate: createAction(
    OstAction.updateTrimmedMeanBottomRate
  )<OstTrimmedMeanBottomRateUpdateParameter>(),
  updateTrimmedMeanTopRate: createAction(
    OstAction.updateTrimmedMeanTopRate
  )<OstTrimmedMeanTopRateUpdateParameter>(),
};
