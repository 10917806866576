import { AccountingSettingsBankAccountRepository } from '@front/src/features/accounting/features/settings/features/bank-account/repository/repository';

export { useLogic as useAccountingSettingsBankAccountUpdateBankNameLogic };

const useLogic = (menuId) => {
  const { run: onUpdate } = AccountingSettingsBankAccountRepository.useUpdate(menuId);
  return {
    onUpdate,
  };
};
