import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import Box from '@mui/material/Box';
import type { BoxProps } from '@mui/material';

interface Props extends BoxProps {
  shape?: 'left';
}

const componentObj = {
  left: AsideLeftWrap,
};

export default function AsideWrapUI({ shape = 'left', ...rest }: Readonly<Props>) {
  const Component = componentObj[shape];
  return <Component {...rest} />;
}

function AsideLeftWrap({ children, ...rest }: Readonly<Props>) {
  const [open, setOpen] = React.useState<boolean>(true);
  const toggle = () => setOpen(!open);
  return (
    <Box
      {...rest}
      sx={{
        position: 'relative',
        display: 'flex',
        width: open ? '350px' : '0px',
        height: '100%',
        backgroundColor: ColorPalette.background.bg,
        transition: 'width .2s ease',
      }}
    >
      <Box
        onClick={toggle}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '-20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20px',
          height: '32px',
          backgroundColor: ColorPalette.background.bg,
          borderRadius: '0 5px 5px 0',
          boxShadow: '2px 1px 2px 0px rgba(0, 0, 0, 0.25)',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon
          icon="angle-left"
          style={{
            fontSize: '1.4rem',
            color: ColorPalette.main.main_primary,
            transition: 'transform .2s',
            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      </Box>
      {open && children}
    </Box>
  );
}
