import type { ComponentType } from 'react';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/material';
import type { CampaignVO } from '@front/ost_campaign/domain';
import { CampaignStatus } from '@front/ost_campaign/domain';
import { campaignAction } from '@front/ost_campaign/action';
import CampaignFormService from '@front/ost_campaign/service/detail/campaignForm';
import CampaignCategoryFormService from '@front/ost_campaign/service/detail/categoryForm';
import CampaignEstimatorFormService from '@front/ost_campaign/service/detail/estimatorForm';
import ProposalStateFormService from '@front/ost_campaign/service/detail/proposalStateForm';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import ErrorBoundary from '@front/components/ErrorBoundary';
import LoadingBar from '@front/layouts/LoadingBar';
import type { RootState } from '@front/services/reducer';
import Button from '@front/layouts/Button';
import useDialog from '@front/dialog/hook';
import type { DefaultFunction } from '@front/type/Function';
import DetailLayout from '@front/ost_proposal/view/detail';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { ProposalStatus, proposalStatusName } from '@front/ost_proposal/domain';
import ApprovalLineSettingService from '@front/ost_campaign/service/detail/approvalLineSettingForm';
import BannerFormService from '@front/ost_campaign/service/detail/BannerFormService';
import PersonalSettingsFeature from 'src/features/personal-settings-modal';

import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface ConditionalProps extends SectionComponentProps {
  detail?: CampaignVO;
  deleteDetail: DefaultFunction;
  onChangeStatus: (e: SelectChangeEvent<unknown>) => void;
}

const CampaignDetailServiceGroup = ({
  detail,
  deleteDetail,
  onChangeStatus,
  menuId,
}: ConditionalProps) => {
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  return detail ? (
    <DetailLayout
      titleRightComponent={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
          }}
        >
          {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
          <Button
            shape={'basic3'}
            onClick={deleteDetail}
          >
            삭제
          </Button>
        </Box>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BannerFormService />
        <CampaignFormService onChangeStatus={onChangeStatus} />
        <ApprovalLineSettingService />
        <CampaignCategoryFormService />
        <CampaignEstimatorFormService />
        <ProposalStateFormService />
      </Box>
    </DetailLayout>
  ) : (
    <PageLayoutWithMemo>
      <ErrorBoundary>요청한 데이터를 가져오는데 실패했습니다.</ErrorBoundary>
    </PageLayoutWithMemo>
  );
};

const withConditionalRender =
  (Component: ComponentType<ConditionalProps>) => (props: ConditionalProps) => {
    if (props.detail) {
      return <Component {...props} />;
    }
    return <LoadingBar />;
  };

const ConditionedServiceGroup = React.memo(withConditionalRender(CampaignDetailServiceGroup));

const CampaignDetailService = (props: SectionComponentProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirm } = useDialog();
  const detail = useSelector((root: RootState) => root.campaign.detail, shallowEqual);

  const {
    name,
    totalReward,
    manager,
    startDate,
    endDate,
    status,
    proposalCategoryList,
    estimatorList,
    proposalList,
    approvalList,
    estimationCompletionRate,
  } = useSelector(
    (root: RootState) => ({
      name: root.campaign.detail?.name,
      totalReward: root.campaign.detail?.totalReward,
      startDate: root.campaign.detail?.startDate,
      endDate: root.campaign.detail?.endDate,
      estimationCompletionRate: root.campaign.detail?.estimationCompletionRate,
      manager: root.campaign.detail?.manager,
      status: root.campaign.detail?.status,
      proposalCategoryList: root.campaign.proposalCategoryList,
      estimatorList: root.campaign.estimatorList,
      proposalList: root.campaign.proposalList,
      approvalList: root.campaign.approvalList,
      campaignBanner: root.campaign.detail?.campaignBanner,
    }),
    shallowEqual
  );

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const updateStatus = useCallback(
    (params) => {
      dispatch(campaignAction.updateStatus(params));
    },
    [dispatch]
  );

  const onChangeStatus = useCallback(
    (e) => {
      const value = e.target.value as CampaignStatus;
      if (!value) {
        return;
      }
      if (status === value) {
        return;
      }

      if (value === CampaignStatus.ON_GOING) {
        if (!name || !startDate || !endDate || !totalReward || !manager) {
          openSnackbar('캠페인 정보를 모두 입력해야 진행할 수 있습니다.');
          return;
        }
        if (!estimationCompletionRate) {
          openSnackbar('심사 완료 비율을 입력해주세요.');
          return;
        }
        if (startDate > endDate) {
          openSnackbar('접수 기간을 확인해주세요.');
          return;
        }

        if (proposalCategoryList.length <= 0) {
          openSnackbar('제안 구분을 추가해주세요.');
          return;
        }

        if (estimatorList.length <= 0) {
          openSnackbar('심사 담당자를 추가해주세요.');
          return;
        }

        if (approvalList.length <= 0) {
          openSnackbar('결재 라인을 설정해주세요.');
          return;
        }

        if (approvalList.map((approval) => approval.manager).some((manager) => !manager)) {
          openSnackbar('결재자를 지정해주시기 바랍니다.');
          return;
        }
      }

      if (value === CampaignStatus.DONE && proposalList) {
        for (const proposal of proposalList) {
          if (
            proposal.status === ProposalStatus.ESTIMATING ||
            proposal.status === ProposalStatus.VOTING
          ) {
            openSnackbar(
              `${proposalStatusName(proposal.status)}인 제안이 있어서 종료할 수 없습니다.`
            );
            return;
          }
        }
      }

      if (value === CampaignStatus.STOP_VOTING) {
        confirm({
          children: '해당 캠페인의 투찰 중인 모든 제안을 투찰중지 하시겠습니까?',
          afterConfirm: () => {
            updateStatus({
              status: value,
            });
          },
          confirmText: '확인',
        });
        return;
      }

      updateStatus({
        status: value,
      });
    },
    [
      status,
      updateStatus,
      name,
      startDate,
      endDate,
      totalReward,
      manager,
      openSnackbar,
      proposalCategoryList,
      estimatorList,
      proposalList,
      approvalList,
    ]
  );

  const deleteDetail = useCallback(() => {
    confirm({
      children: '해당 캠페인을 삭제하시겠습니까?' + '\n하위 모든 제안들이 삭제됩니다.',
      afterConfirm: () => {
        dispatch(campaignAction.deleteDetail());
      },
      confirmText: '확인',
    });
  }, [confirm, dispatch]);

  useEffect(() => {
    dispatch(campaignAction.setId(location.state?.id));
  }, [dispatch, location.state]);

  return (
    <ConditionedServiceGroup
      detail={detail}
      deleteDetail={deleteDetail}
      onChangeStatus={onChangeStatus}
      {...props}
    />
  );
};

export default CampaignDetailService;
