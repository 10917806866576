import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyPersonPreferenceApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/query/api';
import type { AffiliatedCompanyPersonCharacterPreferenceUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/preference/types/parameter';

export const affiliatedCompanyPersonPreferenceMutation = {
  usePersonCharacterPreferenceCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (id: number) =>
        affiliatedCompanyPersonPreferenceApi.createPersonCharacterPreference(id, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'preference',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterPreferenceDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPersonPreferenceApi.deletePersonCharacterPreference(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'preference',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  usePersonCharacterPreferenceUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: AffiliatedCompanyPersonCharacterPreferenceUpdateParameter) =>
        affiliatedCompanyPersonPreferenceApi.updatePersonCharacterPreference(params, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            'affiliated-company',
            'person',
            'character',
            'preference',
          ]);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
