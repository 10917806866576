import { createReducer } from 'typesafe-actions';

import { OstAction } from '@front/ost/action';
import type { OstMemoSearchQuery, OstSearchQuery } from '@front/ost/query';
import type { OstMemoVO, OstVO } from '@front/ost/domain';
import type { CampaignVO } from '@front/ost_campaign/domain';

export interface OstState {
  isLoading: boolean;
  isOpenDrawer: boolean;
  isOpenAddModal: boolean;
  filter?: OstSearchQuery;
  list: OstVO[];
  id?: number;
  detail?: OstVO;
  campaignList?: CampaignVO[];
  isMemoOpen: boolean;
  memoList: OstMemoVO[];
  memoFilter?: OstMemoSearchQuery;
  isMemoLoading: boolean;
}

const initialState: OstState = {
  isLoading: false,
  isOpenDrawer: true,
  isOpenAddModal: false,
  list: [],
  isMemoOpen: true,
  memoList: [],
  isMemoLoading: false,
};

const initialHandlers = {
  [OstAction.setIsLoading]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [OstAction.openDrawer]: (state, action) => ({
    ...state,
    isOpenDrawer: action.payload,
  }),
  [OstAction.openAddModal]: (state, action) => ({
    ...state,
    isOpenAddModal: action.payload,
  }),
  [OstAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [OstAction.setList]: (state, action) => ({
    ...state,
    list: action.payload,
  }),
  [OstAction.setCampaignList]: (state, action) => ({
    ...state,
    campaignList: action.payload,
  }),
  [OstAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [OstAction.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  [OstAction.setIsMemoOpen]: (state, action) => ({
    ...state,
    isMemoOpen: action.payload,
  }),
  [OstAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
  [OstAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [OstAction.setIsMemoLoading]: (state, action) => ({
    ...state,
    isMemoLoading: action.payload,
  }),
};

export const ostReducer = createReducer(initialState, initialHandlers);
