import React from 'react';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import type { NoteItemView } from '@front/src/features/note/types/view';
import noteMutation from '@front/src/features/note/query/mutation';
import useNoteStore from '@front/src/features/note/useState';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import { useShallow } from 'zustand/react/shallow';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { useCustomDialog } from '@front/src/features/dialog';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import useApprovalStore from '@front/src/features/drawer-approval/useState';
import { APPROVAL_DOCUMENT_STATUS, BODY_TEMPLATE_TYPE } from '@front/type/domain';
import { useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  isEditOpen?: boolean;
  item: NoteItemView;
  setIsEditOpen?: () => void;
}

export default function NotificationControlPanel({ isEditOpen, setIsEditOpen, item }: Props) {
  const { mutate: deleteNote, isLoading: isDeleteLoading } = noteMutation.useDeleteNote(item.id);
  const { mutate: toggleRead, isLoading: isReadLoading } = noteMutation.useToggleRead(
    item.reviewerInfoId,
    {
      isRead: !item.readAt,
    }
  );
  const { setIsUserSelectorOpen, setCardState, cardState } = useNoteStore(
    useShallow((state) => ({
      setIsUserSelectorOpen: state.setIsUserSelectorOpen,
      setCardState: state.setCardState,
      cardState: state.cardState,
    }))
  );
  const { setDocumentId, setParams, setDocStatus } = useApprovalDocumentStore(
    useShallow((state) => ({
      setDocumentId: state.setDocumentId,
      setParams: state.setParams,
      setDocStatus: state.setDocStatus,
    }))
  );
  const setFormType = useApprovalStore((state) => state.setFormType);
  const setIsBoxList = useApprovalBoxStore((state) => state.setIsBoxList);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const { alert } = useCustomDialog();
  const { noteId } = useIdGroup();
  const { confirm } = useCustomDialog();

  const handlePanelClick = () => {};
  const handleDelete = () => {
    confirm({
      title: '삭제 확인',
      lineBreakChildren: [
        { value: '비고 삭제 시, 관련된 모든 데이터가 삭제됩니다.' },
        { value: '삭제하시겠습니까?' },
      ],
      afterConfirm: () => {
        deleteNote();
      },
    });
  };
  const handleEdit = () => {
    setIsEditOpen?.();
  };
  const handleReview = () => {
    setIsUserSelectorOpen(true);
    setNoteId(item.id);
  };
  const handleApproval = () => {
    setLeftDrawerState('approval');
    setIsBoxList(true);
    setDocumentId(item.approvalDocumentId);
    setFormType(BODY_TEMPLATE_TYPE.BASIC);
    setDocStatus(item.approvalDocumentStatus);
    setNoteId(item.id);
  };
  const handleReviewApprovalDoc = () => {
    if (
      !item.approvalDocumentId ||
      (item.approvalDocumentStatus === APPROVAL_DOCUMENT_STATUS.TEMPORARY &&
        !item.latestApprovalDocumentIdInSubmission)
    ) {
      alert({
        title: '오류',
        children: [{ value: '상신된 결재 없음' }],
        closeText: '닫기',
      });
      return;
    }
    setParams({
      where: 'approval-document',
      noteId: noteId,
    });
    setIsBoxList(true);
    setDocumentId(item.latestApprovalDocumentIdInSubmission);
    setNoteId(item.id);
    setLeftDrawerState('approval-document');
  };
  const isHeaderOpen = cardState[item.id]?.isHeaderOpen || false;
  const isFooterOpen = cardState[item.id]?.isFooterOpen || false;
  const handleDetail = () => {
    setCardState(item.id, { isHeaderOpen: !isHeaderOpen });
  };
  const handleComment = () => {
    setCardState(item.id, { isFooterOpen: !isFooterOpen });
  };
  const handleRead = () => {
    toggleRead();
  };
  const itemList = [
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: isEditOpen ? HighlightOffIcon : EditIcon,
      action: handleEdit,
    },
    {
      text: '삭제',
      icon: DeleteIcon,
      action: handleDelete,
    },
    {
      text: '회람',
      icon: PeopleAltIcon,
      action: handleReview,
    },
    {
      text: '결재 상신',
      icon: HowToRegIcon,
      action: handleApproval,
    },
    {
      text: '기안서 열람',
      icon: HistoryIcon,
      action: handleReviewApprovalDoc,
    },
    {
      text: '상세',
      icon: DescriptionIcon,
      action: handleDetail,
    },
    {
      text: '댓글',
      icon: ChatBubbleIcon,
      action: handleComment,
    },
  ];

  const reviewerItemList = [
    {
      text: item.readAt ? '읽지 않음' : '읽음',
      icon: PeopleAltIcon,
      action: handleRead,
    },
    {
      text: '기안서 열람',
      icon: HistoryIcon,
      action: handleReviewApprovalDoc,
    },
    {
      text: '상세',
      icon: DescriptionIcon,
      action: handleDetail,
    },
    {
      text: '댓글',
      icon: ChatBubbleIcon,
      action: handleComment,
    },
  ];

  const commonItemList = [
    {
      text: '기안서 열람',
      icon: HistoryIcon,
      action: handleReviewApprovalDoc,
    },
    {
      text: '상세',
      icon: DescriptionIcon,
      action: handleDetail,
    },
    {
      text: '댓글',
      icon: ChatBubbleIcon,
      action: handleComment,
    },
  ];

  const user = useSelector((root: RootState) => root.login.detail);

  const newItemList =
    item?.writerId === user?.id
      ? itemList
      : item?.reviewerInfoId
      ? reviewerItemList
      : commonItemList;

  return (
    <>
      <ControlPanel
        itemList={newItemList}
        isPropagation={true}
        onClick={handlePanelClick}
      />
    </>
  );
}
