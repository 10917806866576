import React from 'react';
import { projectSalesInfoSubjectReviewModalLayout } from '@front/src/features/project-sales-info/features/subject-review/features/modal/components/layout';
import { ProjectSalesInfoSubjectReviewModalKaKaoMapWidget } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/kakao-map/widgets/widget';
import { ProjectSalesInfoSubjectReviewModalComplexInformationFeature } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information';
import { ProjectSalesInfoSubjectReviewModalDesignDateFeature } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date';
import { ProjectSalesInfoSubjectReviewModalSpecialWindFeature } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetFeature } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target';

export { Body as ProjectSalesInfoSubjectReviewModalBody };
interface Props {
  menuId?: number;
}
const Body = ({ menuId }: Props) => {
  const { Layout, Section } = projectSalesInfoSubjectReviewModalLayout;
  return (
    <Layout>
      <Section>
        <ProjectSalesInfoSubjectReviewModalDesignDateFeature menuId={menuId} />
        <ProjectSalesInfoSubjectReviewModalComplexInformationFeature menuId={menuId} />
        <ProjectSalesInfoSubjectReviewModalSpecialWindFeature menuId={menuId} />
        <ProjectSalesInfoSubjectReviewModalExperimentTargetFeature menuId={menuId} />
      </Section>
      <Section>
        <ProjectSalesInfoSubjectReviewModalKaKaoMapWidget menuId={menuId} />
      </Section>
    </Layout>
  );
};
