import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { ManagementAccountingTreeWithCountView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import type { TaxAccountingTreeWithCountView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/types/view';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getTaxAccount: `/api/accounting/tax-accounting/get-all`,
  getManagementAccount: `/api/accounting/management-accounting/get-list`,
  getManual: `/api/accounting/tax-accounting`,

  createManagementAccount: `/api/accounting/management-accounting`,
  insertManagementAccount: `/api/accounting/management-accounting/insert`,
  updateManagementAccount: (id) => `/api/accounting/management-accounting/${id}`,
  deleteManagementAccount: (id) => `/api/accounting/management-accounting/${id}`,
};

const accountingApi = {
  getTaxAccount: async <T = TaxAccountingTreeWithCountView>() => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getTaxAccount);
    return data;
  },
  getManagementAccount: async <T = ManagementAccountingTreeWithCountView>(commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getManagementAccount, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },

  getManual: async <T = any>(params, commonParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getManual, {
      params,
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },

  createManagementAccount: async (params, commonParams) =>
    await axios.post(url.createManagementAccount, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    }),
  insertManagementAccount: async (params, commonParams) => {
    const { data } = await axios.post(url.insertManagementAccount, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  updateManagementAccount: async (params, commonParams) => {
    const { data } = await axios.put(url.updateManagementAccount(params.id), params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  deleteManagementAccount: async (id, commonParams) => {
    await axios.delete(url.deleteManagementAccount(id), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
  },
};

export default accountingApi;
