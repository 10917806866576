import React, { useEffect, useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTable } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/components/table';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyButtonGroup } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/components/button-group';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyLogic } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/useLogic';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModal } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/components/modal';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyWidget = ({
  readOnly,
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { list },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list },
      h: { onDelete },
      isLoading,
    }),
    [isLoading, list, onDelete]
  );
  const { setReadOnly } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <>
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyModal menuId={menuId} />
      <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext.Provider value={value}>
        <Layout>
          <Header title={name}>
            <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyButtonGroup />
          </Header>
          <Body>
            <ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyTable />
          </Body>
        </Layout>
      </ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyContext.Provider>
    </>
  );
};

export default ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyWidget;
