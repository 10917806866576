import React from 'react';
import type { AuthorizationPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import { useShallow } from 'zustand/react/shallow';
import { TableRow } from '@mui/material';
import { WorkTd } from '@front/src/features/work/features/work/components/work-td';
import { usePersonalSettingsModalDeptSettingsState } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/useState';

export { DeptSettingsTableDeptRow as PersonalSettingsModalDeptSettingsTableDeptRow };

interface Props {
  item: AuthorizationPersonalSettingsDeptView;
}

const DeptSettingsTableDeptRow = ({ item }: Props) => {
  const {} = usePersonalSettingsModalDeptSettingsState(
    useShallow((state) => ({
      coloredId: state.coloredId,
    }))
  );

  return (
    <TableRow key={item.id}>
      <WorkTd
        height="53px"
        align="left"
        sx={{
          paddingLeft: `${item.depth > 1 ? (item.depth - 1) * 15 : 0}px`,
        }}
      >
        {item.name}
      </WorkTd>
    </TableRow>
  );
};
