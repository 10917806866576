import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyOutlineView } from '@front/src/features/affiliated-company/features/outline/types/view';
import type { AffiliatedCompanyOutlineNumberUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/number/types/parameter';

export { Context as AffiliatedCompanyOutlineNumberContext };

interface State {
  d: {
    detailOutline?: AffiliatedCompanyOutlineView;
  };
  h: {
    onOutlineNumberUpdate: (params: AffiliatedCompanyOutlineNumberUpdateParameter) => void;
  };
}

const Context = createContext<State>({
  d: {},
  h: {
    onOutlineNumberUpdate: noOp,
  },
});
