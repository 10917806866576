import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import useId from '@front/services/useId';
import Divider from '@front/layouts/Divider';
import type { CurrentPage } from '@front/navigate/domain';
import { TargetPage } from '@front/navigate/domain';
import type { RootState } from '@front/services/reducer';
import { navigationAction } from '@front/navigate/action';
import { getCurrentPageParams } from '@front/navigate/util';
import { proposalAction } from '@front/ost_proposal/action';
import ErrorBoundary from '@front/components/ErrorBoundary';
import type { ProposalContributorVO, ProposalVO } from '@front/ost_proposal/domain';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import { closeStatus } from '@front/components/DataFieldProps';
import { initialProposalQuery } from '@front/ost_proposal/query';
import CircularProgress from '@front/components/CircularProgress';
import VoteService from '@front/ost_proposal/service/detail/vote';
import PageLayoutWithMemo from '@front/layouts/PageLayoutWithMemo';
import TitleService from '@front/ost_proposal/service/detail/title';
import DrawIoService from '@front/ost_proposal/service/detail/drawIo';
import NoticeService from '@front/ost_proposal/service/detail/notice';
import StatusService from '@front/ost_proposal/service/detail/status';
import CommentService from '@front/ost_proposal/service/detail/comment';
import ContentsService from '@front/ost_proposal/service/detail/contents';
import AdvisorFormService from '@front/ost_proposal/service/detail/advisorForm';
import ButtonGroupService from '@front/ost_proposal/service/detail/buttonGroup';
import CampaignInfoService from '@front/ost_proposal/service/detail/campaignInfo';
import EstimateRankService from '@front/ost_proposal/service/detail/estimateRank';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import EvaluationInfoService from '@front/ost_proposal/service/detail/evaluationInfo';
import ContributorInfoService from '@front/ost_proposal/service/detail/contributorInfo';
import AttachedFileListService from '@front/ost_proposal/service/detail/attachedFileList';
import CampaignInfoModalService from '@front/ost_proposal/service/modal/campaignInfoModal';
import HeadButtonControllerService from '@front/ost_proposal/service/detail/headButtonController';
import CancelSubmissionModalService from '@front/ost_proposal/service/modal/cancelSubmissionModal';
import { RequestProposalSubmissionCancelService } from '@front/ost_proposal/service/detail/requestProposalSubmissionCancel';
import ProposalBannerService from '@front/ost_proposal/service/detail/proposalBanner';
import { ApprovalEstimationList } from '@front/ost_proposal/view/detail/approval/List';
import { isMobileDevice } from '@front/util/PwaUtil';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export default function ProposalDetailService(props: SectionComponentProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlId = useId();
  const navigate = useNavigate();
  const detail = useSelector((root: RootState) => root.proposal.detail, shallowEqual);
  const requestDelete = useSelector((root: RootState) => root.proposal.requestDelete, shallowEqual);

  const setCurrentPage = useCallback(
    (params: CurrentPage | undefined) => dispatch(navigationAction.setCurrentPage(params)),
    [dispatch]
  );

  const updateStatus = useCallback(
    (params: ProposalStatusUpdateParameter) =>
      dispatch(proposalAction.updateProposalStatus(params)),
    [dispatch]
  );

  const currentPage = useSelector((root: RootState) => root.navigation.currentPage, shallowEqual);

  useEffect(() => {
    closeStatus(
      requestDelete,
      () => {
        navigate('/ost/proposal-center');
        dispatch(proposalAction.setId(undefined));
        dispatch(proposalAction.setFilter(initialProposalQuery));
      },
      () => {
        dispatch(proposalAction.requestDelete('idle'));
      }
    );
  }, [requestDelete]);

  useEffect(() => {
    dispatch(proposalAction.setId((location.state && location.state.id) ?? urlId));
    setCurrentPage(getCurrentPageParams(location.pathname));
    return () => {
      setCurrentPage(undefined);
      dispatch(proposalAction.setProposalSubmissionCancel(undefined));
      dispatch(proposalAction.setEstimatorInfoList([]));
    };
  }, [(location.state && location.state.id) ?? urlId]);

  useEffect(() => {
    if (currentPage) {
      dispatch(proposalAction.requestPermission(currentPage));
    }
  }, [currentPage]);

  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isContributor = useMemo(
    () =>
      contributorList.some((contributorView) => contributorView.contributor?.id === loginUser?.id),
    [contributorList, loginUser]
  );

  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.estimatorList],
    shallowEqual
  );

  const isEstimator = useMemo(
    () => estimatorList.some((estimatorView) => estimatorView.estimator.id === loginUser?.id),
    [estimatorList, loginUser]
  );

  return (
    <Services
      detail={detail}
      currentPage={currentPage}
      updateStatus={updateStatus}
      isContributor={isContributor}
      isEstimator={isEstimator}
      contributorList={contributorList}
      {...props}
    />
  );
}

interface ConditionalProps {
  detail?: ProposalVO;
  currentPage?: CurrentPage;
  updateStatus: (params: ProposalStatusUpdateParameter) => void;
  isContributor: boolean;
  isEstimator: boolean;
  contributorList: ProposalContributorVO[];
  menuId?: number | undefined;
}

function withConditionalRender(Component: React.ComponentType<ConditionalProps>) {
  return function (props: ConditionalProps) {
    const { detail, currentPage, contributorList } = props;
    return detail && currentPage && contributorList.length > 0 ? (
      <Component {...props} />
    ) : (
      <Loading />
    );
  };
}

function Loading() {
  return (
    <PageLayoutWithMemo>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
        }}
      >
        <CircularProgress
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Box>
    </PageLayoutWithMemo>
  );
}

function ProposalServices(props: ConditionalProps) {
  const { detail, currentPage, updateStatus, isContributor, isEstimator } = props;

  return detail && currentPage ? (
    <Layout>
      <HeadButtonControllerService
        currentPage={currentPage}
        updateStatus={updateStatus}
        isContributor={isContributor}
        menuId={props.menuId}
      />
      {currentPage.targetPage !== TargetPage.EVALUATION && <NoticeService />}
      {currentPage.targetPage === TargetPage.VOTE ? <VoteService /> : <StatusService />}
      <ButtonGroupService
        updateStatus={updateStatus}
        currentPage={currentPage}
        isContributor={isContributor}
        isEstimator={isEstimator}
      />
      <CampaignInfoService />
      {detail.campaign.campaignBanner.isExposed && <ProposalBannerService />}
      {currentPage.targetPage === TargetPage.EVALUATION && (
        <RequestProposalSubmissionCancelService />
      )}
      {currentPage.targetPage === TargetPage.EVALUATION && !isEstimator && (
        <ApprovalEstimationList />
      )}
      <TitleService isContributor={isContributor} />
      <EstimateRankService
        title={'제안 아이디어 가치 등급'}
        targetPage={currentPage.targetPage}
      />
      {currentPage.targetPage === TargetPage.EVALUATION && isEstimator && <EvaluationInfoService />}
      {currentPage.targetPage !== TargetPage.VOTE && (
        <ContributorInfoService isContributor={isContributor} />
      )}
      <ContentsService isContributor={isContributor} />
      <DrawIoService isContributor={isContributor} />
      <AttachedFileListService isContributor={isContributor} />
      {currentPage.targetPage !== TargetPage.ARRANGEMENT && <CommentService />}
      {detail.arrangementStatus === ProposalArrangementInformationStatus.COMPLETE ? (
        <>
          <Divider />
          <AdvisorFormService isContributor={isContributor} />
        </>
      ) : (
        (detail.status === ProposalStatus.WRITING || detail.status === ProposalStatus.SUBMISSION) &&
        detail.advisor && (
          <>
            <Divider />
            <AdvisorFormService isContributor={isContributor} />
          </>
        )
      )}
      <CampaignInfoModalService />
      <CancelSubmissionModalService />
    </Layout>
  ) : (
    <PageLayoutWithMemo>
      <ErrorBoundary>요청한 데이터를 가져오는데 실패했습니다.</ErrorBoundary>
    </PageLayoutWithMemo>
  );
}

const Layout = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  padding: '0 0 50px 0',
  paddingTop: isMobileDevice() ? '50px' : 0,
});

const Services = React.memo(withConditionalRender(ProposalServices));
