import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/drawer-app-bar/button-wrapper.module.scss';

export { ButtonWrapper as DrawerAppBarButtonWrapper };

interface Props {
  children: ReactNode;
}

const ButtonWrapper = ({ children }: Props) => <div className={classes.container}>{children}</div>;
