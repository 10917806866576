import { useQueryClient } from 'react-query';
import { affiliatedCompanyPersonDetailModalRepository } from '@front/src/features/affiliated-company/features/person/features/detail-modal/repository/repository';
import { PersonTabType } from '@front/src/features/affiliated-company/types/domain';
import { useAffiliatedCompanyPersonDetailModalState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/widgets/useState';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';

export { useLogic as useAffiliatedCompanyPersonDetailModalLogic };

const useLogic = () => {
  const queryClient = useQueryClient();
  const { setDetailModalIsOpen, isDetailModalOpen, modalId, setModalId } =
    useAffiliatedCompanyPersonState(
      useShallow((state) => ({
        setDetailModalIsOpen: state.setDetailModalIsOpen,
        isDetailModalOpen: state.isDetailModalOpen,
        modalId: state.modalId,
        setModalId: state.setModalId,
      }))
    );
  const { run: onDelete, setCallback } =
    affiliatedCompanyPersonDetailModalRepository.usePersonDelete();
  const { tab, setTab } = useAffiliatedCompanyPersonDetailModalState(
    useShallow((state) => ({
      tab: state.tab,
      setTab: state.setTab,
    }))
  );
  setCallback({
    onSuccess: () => {
      setDetailModalIsOpen(false);
    },
  });
  const h = {
    onDelete,
    setTab,
    onDetailModalClose: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['affiliated-company', 'person', 'list'],
      });
      setTab(PersonTabType.OUTLINE);
      setDetailModalIsOpen(false);
    },
  };
  useEffect(
    () => () => {
      setModalId(undefined);
    },
    [setModalId]
  );
  return { h, tab, isDetailModalOpen, modalId };
};
