import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';

export { PartnerCompanyTableRowSelector as ProjectSalesInfoBidResultPartnerCompanyTableRowSelector };

const PartnerCompanyTableRowSelector = ({ readOnly }: { readOnly?: boolean }) => {
  const { setValue, watch } = useFormContext();
  const handleSelector = useCallback(
    (company?: AffiliatedCompanyShortView) => {
      setValue('winnerId', company?.id);
      setValue('winnerName', company?.name);
    },
    [setValue]
  );
  return (
    <div style={{ width: '350px' }}>
      <AffiliatedCompanySelectorWidget
        position="left"
        value={{
          companyId: watch('winnerId') ?? undefined,
          textValue: watch('winnerName') ?? '',
        }}
        onCompanySelect={handleSelector}
        title="업체 선택"
        isCompany={true}
        disabled={readOnly}
      />
    </div>
  );
};
