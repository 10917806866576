import headerMeta from '@front/src/components/ui-builder/headerMeta';

export const getDefaultFilterBundle = (headerList, list) =>
  headerList.map((h) => {
    const { attributeName, filterable } = headerMeta[h.id];

    if (filterable) {
      return {
        headerId: h.id,
        filterable,
        name: h.name,
        active: false,
        attributeName,
        filter: Array.from(
          new Set(list?.map((l) => attributeName?.reduce((acc, key) => acc?.[key], l) ?? ''))
        )
          .sort()
          .map((m) => ({
            value: m,
            checked: true,
            shown: true,
          })),
      };
    }
    return {
      headerId: h.id,
      filterable,
      name: h.name,
      active: false,
      attributeName,
      filter: [],
    };
  });

export const getEditOpenIdList = (id, editOpenIdList) => {
  if (editOpenIdList.includes(id)) {
    return editOpenIdList.filter((originId) => originId !== id);
  }
  return [...editOpenIdList, id];
};
