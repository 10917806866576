import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { FormProvider, useForm } from 'react-hook-form';
import '@front/src/features/work/features/work/features/carousel/components/Carousel.scss';
import WorkCarouselHeader from '@front/src/features/work/features/work/features/carousel/components/Header';
import WorkCarousel from '@front/src/features/work/features/work/features/carousel/components/Carousel';
import WorkCarouselCreateModal from '@front/src/features/work/features/work/features/carousel/components/CreateModal';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import WorkCarouselMoveSeqModal from '@front/src/features/work/features/work/features/carousel/components/MoveSeqModal';
import WorkCarouselFileAuthModal from '@front/src/features/work/features/work/features/carousel/components/FileAuthModal';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';
import WorkCarouselMoveSlideModal from '@front/src/features/work/features/work/features/carousel/components/MoveSlideModal';

export default function WorkCarouselForm(props: Readonly<WorkCarouselSectionProps>) {
  const { menuId, sectionId, dataId } = props;
  const { Layout } = SectionWrapperUI;
  const methods = useForm<WorkCarouselFieldValues>({
    defaultValues,
  });
  return (
    <FormProvider {...methods}>
      <WorkCarouselMoveSlideModal
        menuId={menuId}
        sectionId={sectionId}
        dataId={dataId}
      />
      <WorkCarouselCreateModal {...props} />
      <WorkCarouselMoveSeqModal {...props} />
      <WorkCarouselFileAuthModal {...props} />
      <Layout>
        <WorkCarouselHeader {...props} />
        <WorkCarousel {...props} />
      </Layout>
    </FormProvider>
  );
}

const defaultValues = {
  openCreateModal: false,
  openMoveSeqModal: false,
  openFileAuthSettingsModal: false,
  imageModalId: undefined,
  fileItemId: undefined,
  fileItemKey: '',
  moveSlideId: undefined,
};

export interface WorkCarouselFieldValues {
  openCreateModal: boolean;
  openMoveSeqModal: boolean;
  openFileAuthSettingsModal: boolean;
  imageModalId: number | undefined;
  fileItemId: number | undefined;
  fileItemKey: string;
  moveSlideId: number | undefined;
}
