import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { TaxAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';

import { NDepthTableBoxCell } from '@front/src/components/n-depth-table-box/components/NDepthTableBox';

interface Props {
  rowIndex: number;
}

export default function TaxAccountTableLastCell(props: Props) {
  const { rowIndex } = props;
  const { getValues } = useFormContext<TaxAccountTableFormValues>();
  const lastAccount = getValues('lastAccount');

  const cellData = lastAccount?.[rowIndex];

  if (cellData) return <NDepthTableBoxCell>{cellData.name}</NDepthTableBoxCell>;

  return <NDepthTableBoxCell>{''}</NDepthTableBoxCell>;
}
