import { Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type { ListDepartment } from '@front/department_tree/entity';
import type { AuthorizationType, UserId, UserVO } from '@front/user/domain';
import { userApi } from '@front/user/api';
import departmentTreeApi from '@front/department_tree/api';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import SelectedUserList from '@front/components/UserSelector/SelectedUserList';
import UserList from '@front/components/UserSelector/UserList';
import DepartmentTree from '@front/components/UserSelector/DepartmentTree';
import Progress from '@front/components/Progress';
import ModalLayout, { type ModalPositionType } from '@front/layouts/ModalLayout';
import { isMobileDevice } from '@front/util/PwaUtil';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import type { ReviewerView, ReviewHistoryView } from '@front/src/features/note/types/view';
import noteQuery from '@front/src/features/note/query/query';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { YY_MM_DD_HH_MM } from '@front/src/utils';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { useCustomDialog } from '@front/src/features/dialog';
import { LogicalOperator } from '@front/user/query';

interface Props {
  multi: boolean | undefined;
  title?: string;
  value?: UserId | UserId[];
  reviewerDetail?: ReviewerView;
  departmentId?: number;
  onChange: (
    value: UserId | UserId[] | undefined,
    date: string,
    addList?: number[],
    deleteList?: number[],
    name?: string
  ) => void;
  addOnly?: boolean;
  include?: UserId[];
  exclude?: UserId[];
  menuIdList?: number[];
  typeList?: AuthorizationType[];
  position?: ModalPositionType;
  fixedList: number[];
}

export default function NoteUserSelectorModal({
  menuIdList = [],
  typeList = [],
  position,
  reviewerDetail,
  ...props
}: Props) {
  const { isUserSelectorOpen, setIsUserSelectorOpen } = useNoteStore(
    useShallow((state) => ({
      isUserSelectorOpen: state.isUserSelectorOpen,
      setIsUserSelectorOpen: state.setIsUserSelectorOpen,
    }))
  );

  const { alert } = useCustomDialog();

  const [userList, setUserList] = useState<UserVO[] | undefined>(undefined);
  const [selectedUserList, setSelectedUserList] = useState<UserVO[] | undefined>(undefined);
  const [departmentList, setDepartmentList] = useState<ListDepartment[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [addList, setAddList] = useState<number[]>([]);
  const [deleteList, setDeleteList] = useState<number[]>([]);
  const [date, setDate] = useState<string>(reviewerDetail?.reviewDate ?? '');
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);

  const search = useCallback(
    (values) => {
      setLoading(true);
      userApi
        .getListByDepartmentId({
          keyword: values.keyword,
          departmentId: values.departmentId,
          menuIdList: menuIdList.join(','),
          typeList: typeList.join(','),
          menuIdListType: LogicalOperator.AND,
        })
        .then((values) => {
          if (props.fixedList) {
            const result = values.filter((user) => props.fixedList?.includes(user.id));
            setUserList(result.filter((user) => user.statusEn === 'EMPLOYED'));
          } else if (props.exclude && props.exclude.length > 0) {
            const result = values.filter((user) => !props.exclude?.includes(user.id));
            setUserList(result);
          } else if (props.include && props.include.length > 0) {
            const result = values.filter((user) => props.include?.includes(user.id));
            setUserList(result);
          } else {
            setUserList(values);
          }
          setTimeout(() => {
            setLoading(false);
          }, 200);
        });
    },
    [props.exclude, props.include]
  );

  const getSelectedUserList = useCallback(
    (query: UserId[], callback: (selectedUsers: UserVO[]) => void) => {
      userApi
        .getListByDepartmentId({
          keyword: '',
          menuIdList: '',
          typeList: '',
        })
        .then(callback);
    },
    [isUserSelectorOpen]
  );

  const excludeSelectedUserList = useCallback(
    (user: UserVO) => {
      if (selectedUserList) {
        setSelectedUserList((prev) => {
          if (typeof prev === 'undefined') {
            return;
          }
          return [...prev.filter((value) => value.id !== user.id)];
        });
        setDeleteList([...deleteList, user.id]);
      }
    },
    [selectedUserList, deleteList, setDeleteList]
  );

  const formik = useFormik({
    initialValues: {
      keyword: '',
      departmentId: props?.departmentId,
    },
    onSubmit: (values) => {
      search(values);
    },
  });

  const handleKeywordChange = useCallback(
    (e) => {
      const value = e.target.value || undefined;
      if (formik.values.keyword !== value) {
        formik.setFieldValue('departmentId', undefined);
        formik.setFieldValue('keyword', value);
      }
    },
    [formik]
  );

  useEffect(() => {
    if (isUserSelectorOpen) {
      departmentTreeApi.getAll().then(setDepartmentList);
      getSelectedUserList([], (selectedUsers) => {
        if (Array.isArray(props?.value)) {
          const compArr: UserId[] = props.value;
          setSelectedUserList([...selectedUsers.filter((user) => compArr.includes(user.id))]);
        } else {
          setSelectedUserList([...selectedUsers.filter((value1) => value1.id === props?.value)]);
        }
      });
      search({
        keyword: '',
        departmentId: props.departmentId,
        menuIdList: '',
        typeList: '',
      });
    } else {
      formik.resetForm();
      setSelectedUserList(undefined);
      setDepartmentList(undefined);
      setUserList(undefined);
    }
  }, [isUserSelectorOpen, props.departmentId]);

  useEffect(() => {
    formik.setFieldValue('departmentId', props.departmentId);
  }, [props.departmentId]);

  return (
    <>
      {isUserSelectorOpen && (
        <ModalLayout
          width={isMobileDevice() ? '100dvw' : '50dvw'}
          height={isMobileDevice() ? '100dvh' : '55dvh'}
          open={isUserSelectorOpen}
          title={props.title ?? '유저 선택'}
          onClose={() => setIsUserSelectorOpen(false)}
          position={position}
          children={
            <FormikProvider value={formik}>
              <Box
                sx={{
                  width: '100%',
                  paddingBottom: '20px',
                  borderBottom: '1px solid',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'right',
                  }}
                >
                  <Button
                    shape="basic2"
                    onClick={() => setIsUserSelectorOpen(false)}
                    sx={{
                      marginRight: '10px',
                      width: '120px',
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    onClick={() => {
                      if (!date && selectedUserList?.length !== 0) {
                        alert({
                          title: '저장 오류',
                          children: [{ value: '회람 기간을 입력해주세요.' }],
                        });
                        return;
                      }
                      if (props.multi) {
                        const result: UserId[] = [];
                        selectedUserList?.forEach((user) => {
                          result.push(user.id);
                        });
                        props.onChange(result, date, addList, deleteList);
                      } else {
                        props.onChange(
                          selectedUserList && selectedUserList.length > 0
                            ? selectedUserList[0].id
                            : undefined,
                          date,
                          addList,
                          deleteList,
                          selectedUserList && selectedUserList[0]?.name
                        );
                      }
                      setIsUserSelectorOpen(false);
                    }}
                    sx={{
                      width: '120px',
                    }}
                  >
                    저장
                  </Button>
                </Box>
              </Box>
              <Progress
                loading={loading}
                sx={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  minWidth: '900px',
                  flexWrap: 'wrap',
                  height: isMobileDevice() ? '100dvh' : 'calc(50dvh - 120px)',
                  justifyContent: 'center',
                  paddingTop: '10px',
                }}
              >
                <TableContainerUI>
                  <TableTableUI>
                    <TableBodyUI>
                      <TableRowUI>
                        <TableCellUI isHead={true}>회람 기간</TableCellUI>
                        <TableCellUI>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            padding="10px"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              gap="5px"
                            >
                              <DatePicker
                                value={dayjs(date)}
                                onChange={(value) =>
                                  setDate(dayjs(value).format('YYYY-MM-DDT00:00:00'))
                                }
                              />
                              까지
                            </Box>
                            {reviewerDetail?.reviewDate && (
                              <ButtonBasicUI onClick={() => setIsHistoryOpen((prev) => !prev)}>
                                변경 이력 {isHistoryOpen ? '닫기' : '열기'}
                              </ButtonBasicUI>
                            )}
                          </Box>
                        </TableCellUI>
                      </TableRowUI>
                      {isHistoryOpen && <NoteReviewerHistoryTable />}
                    </TableBodyUI>
                  </TableTableUI>
                </TableContainerUI>
                <Box
                  sx={{
                    display: 'flex',
                    width: isMobileDevice() ? '40%' : '100%',
                    flexWrap: 'nowrap',
                    padding: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      flex: 1,
                      marginRight: '10px',
                      alignItems: 'center',
                    }}
                  >
                    <DataFieldWithLabel label="검색어">
                      <Input
                        key={formik.values.keyword}
                        variant="standard"
                        defaultValue={formik.values.keyword ?? ''}
                        placeholder="전체 부서 사용자 대상 검색"
                        onBlur={handleKeywordChange}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === 'enter') {
                            const value = (e.target as HTMLInputElement).value ?? undefined;
                            formik.setFieldValue('departmentId', undefined);
                            formik.setFieldValue('keyword', value);
                            formik.handleSubmit();
                          }
                        }}
                      />
                    </DataFieldWithLabel>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Button
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      검색
                    </Button>
                    <Button
                      disabled={!formik.values.keyword && !formik.values.departmentId}
                      shape="basic2"
                      onClick={() => {
                        formik.setFieldValue('keyword', undefined);
                        formik.setFieldValue('departmentId', undefined);
                        formik.handleSubmit();
                      }}
                    >
                      초기화
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: isMobileDevice() ? 'noWrap' : 'wrap',
                    flexDirection: isMobileDevice() ? 'column' : 'row',
                    alignItems: isMobileDevice() ? 'center' : '',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '40%',
                      height: isMobileDevice() ? 'auto' : '100%',
                      margin: '0 0.5%',
                      flexWrap: 'wrap',
                      border: '1px solid #e4e9f2',
                      borderRadius: '5px',
                    }}
                  >
                    <DepartmentTree departmentList={departmentList} />
                  </Box>
                  <Box
                    className="scroll-bar-holder"
                    sx={{
                      display: 'flex',
                      width: `${props.addOnly ? '53%' : '40%'}`,
                      height: '100%',
                      margin: '0 0.5%',
                      overflowY: 'auto',
                      flexWrap: 'wrap',
                      border: '1px solid #e4e9f2',
                      borderRadius: '5px',
                      alignItems: 'baseline',
                      alignContent: 'start',
                    }}
                  >
                    <UserList
                      multi={props.multi}
                      userList={userList}
                      selectedUserList={selectedUserList}
                      onChange={(user, checked) => {
                        if (props.multi) {
                          setSelectedUserList((prev) => {
                            if (typeof prev === 'undefined') {
                              return;
                            }
                            if (checked) {
                              setDeleteList([...deleteList, user.id]);
                              return [...prev.filter((value) => value.id !== user.id)];
                            } else {
                              setAddList([...addList, user.id]);
                              return [...prev, user];
                            }
                          });
                        } else {
                          setSelectedUserList([user]);
                        }
                      }}
                    />
                  </Box>
                  {!props.addOnly && (
                    <Box
                      className="scroll-bar-holder"
                      sx={{
                        display: 'flex',
                        flex: 1,
                        height: '100%',
                        margin: '0 0.5%',
                        overflowY: 'auto',
                        flexWrap: 'wrap',
                        border: '1px solid #e4e9f2',
                        borderRadius: '5px',
                        alignItems: 'baseline',
                        alignContent: 'start',
                      }}
                    >
                      <SelectedUserList
                        userList={selectedUserList}
                        onUserClick={excludeSelectedUserList}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormikProvider>
          }
        />
      )}
    </>
  );
}

const NoteReviewerHistoryTable = () => {
  const { noteId } = useIdGroup();
  const { data: historyList } = noteQuery.useGetReviewHistoryList(noteId ?? 0);
  return (
    <TableRowUI>
      <TableCellUI isHead={true}>회람 기간 변경 이력</TableCellUI>
      <TableCellUI>
        <Box padding="10px">
          <TableContainerUI>
            <TableTableUI>
              <TableHeadUI>
                <TableRowUI>
                  <TableCellUI isHead={true}>변경일시</TableCellUI>
                  <TableCellUI isHead={true}>회람기간</TableCellUI>
                  <TableCellUI isHead={true}>처리자</TableCellUI>
                </TableRowUI>
              </TableHeadUI>
              <TableBodyUI>
                {historyList?.length === 0 && <NoResult colSpan={3} />}
                {historyList?.map((item, index) => (
                  <NoteReviewerHistoryTableRow
                    key={index}
                    item={item}
                  />
                ))}
              </TableBodyUI>
            </TableTableUI>
          </TableContainerUI>
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
};

interface HistoryTableRowProps {
  item: ReviewHistoryView;
}

const NoteReviewerHistoryTableRow = ({ item }: HistoryTableRowProps) => (
  <TableRowUI>
    <TableCellUI>{dayjs(item.createdAt).format(YY_MM_DD_HH_MM)}</TableCellUI>
    <TableCellUI>{dayjs(item.reviewDate).format(YY_MM_DD_HH_MM)}</TableCellUI>
    <TableCellUI>{item.name}</TableCellUI>
  </TableRowUI>
);
