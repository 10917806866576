import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type { EstimationCompetitorView } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/types/views';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';

import type { FileItemView } from '@front/file-item';
import type { UserNameView } from '@front/src/types';
import type { SpareParameter } from '@front/src/types/parameter';

const useEstimationCompetitorTableRowForm = (item: EstimationCompetitorView) => {
  const methods = useForm<EstimationCompetitorFormValues>({
    defaultValues,
  });

  useEffect(() => {
    const {
      formState: { isDirty },
      setValue,
    } = methods;
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof EstimationCompetitorFormValues)[]).forEach(
        (field) => {
          setValue(field, getFormValues(item)[field]);
        }
      );
    }
  }, [item, methods]);

  return {
    methods,
  };
};
export default useEstimationCompetitorTableRowForm;

const defaultValues = {
  id: null,
  seq: null,
  estimationDate: null,
  affiliatedCompany: null,
  affiliatedCompanyPerson: null,
  estimationRequestCompany: null,
  testPlan: null,
  totalPriceDiff: null,
  competitorTotalPrice: null,
  windTunnelPrice: null,
  structuralReviewPrice: null,
  pureUnitPrice: null,
  requiredWeeksStart: null,
  requiredWeeksEnd: null,
  estimationDocument: null,
  createdBy: null,
  createdAt: null,

  numberAttr1: null,
  numberAttr2: null,
  numberAttr3: null,
  textAttr1: null,
  textAttr2: null,
  textAttr3: null,
  dateAttr1: null,
  dateAttr2: null,
  dateAttr3: null,
};

const getFormValues = (item: EstimationCompetitorView) => ({
  id: item.id ?? null,
  seq: item.seq ?? null,
  estimationDate: item.estimationDate ?? null,
  competitorCompany: item.competitorCompany ?? null,
  testPlan: item.testPlan ?? null,
  totalPriceDiff: item.totalPriceDiff ?? null,
  competitorTotalPrice: item.competitorTotalPrice ?? null,
  windTunnelPrice: item.windTunnelPrice ?? null,
  structuralReviewPrice: item.structuralReviewPrice ?? null,
  pureUnitPrice: item.pureUnitPrice ?? null,
  requiredWeeksStart: item.requiredWeeksStart ?? null,
  requiredWeeksEnd: item.requiredWeeksEnd ?? null,
  estimationDocument: item.estimationDocument ?? null,
  createdBy: item.createdBy ?? null,
  createdAt: item.createdAt ?? null,

  numberAttr1: item.numberAttr1 ?? null,
  numberAttr2: item.numberAttr2 ?? null,
  numberAttr3: item.numberAttr3 ?? null,
  textAttr1: item.textAttr1 ?? null,
  textAttr2: item.textAttr2 ?? null,
  textAttr3: item.textAttr3 ?? null,
  dateAttr1: item.dateAttr1 ?? null,
  dateAttr2: item.dateAttr2 ?? null,
  dateAttr3: item.dateAttr3 ?? null,
});

interface EstimationCompetitorFormValues extends SpareParameter {
  id: number | null;
  seq: number | null;
  estimationDate: string | null;
  competitorCompany: AffiliatedCompanyShortView | null;
  testPlan: string | null;
  totalPriceDiff: number | null;
  competitorTotalPrice: number | null;
  windTunnelPrice: number | null;
  structuralReviewPrice: number | null;
  pureUnitPrice: number | null;
  requiredWeeksStart: number | null;
  requiredWeeksEnd: number | null;
  estimationDocument: FileItemView | null;
  createdBy: UserNameView | null;
  createdAt: string | null;
}
