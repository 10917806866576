import type { LoginError, LoginVO } from 'login/domain';
import { createReducer } from 'typesafe-actions';
import { LoginActionType } from 'login/action';
import type { ApiStatus } from 'components/DataFieldProps';
import type { PasswordValidation } from 'login/parameter';

export interface LoginState {
  detail?: LoginVO;
  requestLogin: ApiStatus;
  requestLogout: ApiStatus;
  requestChange: ApiStatus;
  changeModal: boolean;
  passwordChangeModal: boolean;
  passwordValidation?: PasswordValidation;
  loginError?: LoginError;
  requestReset: ApiStatus;
  isOpenLoginModal: boolean;
  isSessionExpired: boolean;
}

const initial: LoginState = {
  requestLogin: 'idle',
  requestLogout: 'idle',
  requestChange: 'idle',
  changeModal: false,
  passwordChangeModal: false,
  requestReset: 'idle',
  isOpenLoginModal: false,
  isSessionExpired: false,
};

export const loginReducer = createReducer(initial, {
  [LoginActionType.setDetail]: (state, action) => ({
    ...state,
    detail: action.payload,
  }),
  [LoginActionType.requestLogin]: (state, action) => ({
    ...state,
    requestLogin: action.payload,
  }),
  [LoginActionType.requestLogout]: (state, action) => ({
    ...state,
    requestLogout: action.payload,
  }),
  [LoginActionType.requestChange]: (state, action) => ({
    ...state,
    requestChange: action.payload,
  }),
  [LoginActionType.changeModal]: (state, action) => ({
    ...state,
    changeModal: action.payload,
  }),
  [LoginActionType.passwordChangeModal]: (state, action) => ({
    ...state,
    passwordChangeModal: action.payload,
  }),
  [LoginActionType.passwordValidation]: (state, action) => ({
    ...state,
    passwordValidation: action.payload,
  }),
  [LoginActionType.loginError]: (state, action) => ({
    ...state,
    loginError: action.payload,
  }),
  [LoginActionType.requestReset]: (state, action) => ({
    ...state,
    requestReset: action.payload,
  }),
  [LoginActionType.setLoginModal]: (state, action) => ({
    ...state,
    isOpenLoginModal: action.payload,
  }),
  [LoginActionType.setSessionExpired]: (state, action) => ({
    ...state,
    isSessionExpired: action.payload,
  }),
});
