import React, { useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoActivityCreateButtonContext } from '@front/src/features/project-sales-info/features/activity/widgets/create-button/provider/context';
import { useProjectSalesInfoActivityState } from '@front/src/features/project-sales-info/features/activity/useState';
import { useShallow } from 'zustand/react/shallow';

export { CreateButton as ProjectSalesInfoActivityCreateButton };

const CreateButton = () => {
  const { onCreate } = useContext(ProjectSalesInfoActivityCreateButtonContext);
  const { readOnly } = useProjectSalesInfoActivityState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={onCreate}
      disabled={readOnly}
    >
      추가
    </Button>
  );
};
