import { AccountingSettingsContentLayoutLayout } from '@front/src/features/accounting/features/settings/components/layouts/content/layout';
import { AccountingSettingsContentLayoutHeader } from '@front/src/features/accounting/features/settings/components/layouts/content/header';
import { AccountingSettingsContentLayoutBody } from '@front/src/features/accounting/features/settings/components/layouts/content/body';
import { AccountingSettingsContentLayoutTable } from '@front/src/features/accounting/features/settings/components/layouts/content/table';
import { AccountingSettingsContentLayoutTableHeader } from '@front/src/features/accounting/features/settings/components/layouts/content/table-header';
import { AccountingSettingsContentLayoutTableBody } from '@front/src/features/accounting/features/settings/components/layouts/content/table-body';

export { index as accountingSettingsContentLayout };
const index = {
  Layout: AccountingSettingsContentLayoutLayout,
  Header: AccountingSettingsContentLayoutHeader,
  Body: AccountingSettingsContentLayoutBody,
  Table: AccountingSettingsContentLayoutTable,
  TableHeader: AccountingSettingsContentLayoutTableHeader,
  TableBody: AccountingSettingsContentLayoutTableBody,
};
