import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import fileItemApi from '@front/src/features/file-item/api/api';

interface Props {
  fileItemId: number | undefined;
}

const useCopyLink = (props: Props) => {
  const { fileItemId } = props;
  const [enabled, setEnabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleCopyLink = () => setEnabled(true);
  useQuery<unknown, unknown, string>({
    queryFn: () => fileItemApi.getLink(fileItemId),
    queryKey: ['file-auth', fileItemId, enabled],
    enabled: !!fileItemId && enabled,
    useErrorBoundary: false,
    onSuccess: async (data) => {
      await navigator.clipboard.writeText(`${window.origin}${data}`);
      enqueueSnackbar('주소 복사 성공했습니다.', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('파일 권한이 없습니다.', { variant: 'error' });
    },
    onSettled: () => setEnabled(false),
  });
  return {
    onCopyLink: handleCopyLink,
  };
};

export default useCopyLink;
