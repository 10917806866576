import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormFileUploader } from '@front/src/components/file-uploader/hook-form-file-uploader';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import Box from '@mui/material/Box';

import type { WorkReferenceFileUpdateParameter } from '@front/src/features/work/features/work/features/reference/types/parameter';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface Props extends CellComponentProps<WorkReferenceView> {
  item: WorkReferenceView;
  onUpdateFile: (params: WorkReferenceFileUpdateParameter) => void;
}

export default function WorkReferenceTableBodyRowFile({
  item,
  onUpdateFile,
  sectionId,
  dataId,
  menuId,
  isForm,
}: Readonly<Props>) {
  const methods = useForm({
    values: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    const request = {
      menuId,
      dataId,
      sectionId,
    };
    const formData = new FormData();
    formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
    formData.append('image', data.fileItem && data.fileItem.multipartFile);
    onUpdateFile({
      id: item.id,
      formData,
    });
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);
  return (
    <FormProvider {...methods}>
      <Box>
        <HookFormFileUploader
          name="fileItem"
          onSubmit={isForm ? undefined : onSubmit}
          id={`${item.id}-reference`}
        />
      </Box>
    </FormProvider>
  );
}

function getFormValues(item) {
  return {
    fileItem: item.fileItem ?? '',
  };
}
