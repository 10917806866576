import React, { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from '@front/src/utils';
import Button from '@front/layouts/Button';
import ModalLayout from '@front/layouts/ModalLayout';
import { useSnackbar } from '@front/src/features/snackbar';
import classes from '@front/src/features/affiliated-company/features/person/components/create-modal/modal.module.scss';
import type { AffiliatedCompanyPersonCreateParameter } from '@front/src/features/affiliated-company/features/person/types/parameter';
import { AffiliatedCompanyPersonCreateModalForm } from '@front/src/features/affiliated-company/features/person/components/create-modal/form';
import { AffiliatedCompanyPersonContext } from '@front/src/features/affiliated-company/features/person/widgets/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { Modal as AffiliatedCompanyPersonCreateModal };
const Modal = () => {
  const {
    h: { onCreatePerson, onCreateModalClose },
    isCreateModalOpen,
  } = useContext(AffiliatedCompanyPersonContext);
  const methods = useForm<AffiliatedCompanyPersonCreateParameter>({
    values: {
      name: '',
      position: '',
    },
  });
  const { show } = useSnackbar();
  const isValid = ({ name, position }) => {
    if (isEmpty(name)) {
      show({ message: '이름을 입력해주세요.' });
      return false;
    }
    if (isEmpty(position)) {
      show({ message: '직급을 입력해주세요.' });
      return false;
    }
    return true;
  };
  const handleClick = methods.handleSubmit((data) => {
    if (!isValid(data)) {
      return;
    }
    onCreatePerson(data);
    methods.reset();
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await handleClick();
    },
    [handleClick]
  );
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      {isCreateModalOpen && (
        <ModalLayout
          title="인물 추가"
          width="20dvw"
          height="20dvh"
          open={isCreateModalOpen}
          onClose={() => onCreateModalClose(methods.reset)}
          footer={
            <div className={classes.footer}>
              <Button
                shape={'basic2'}
                onClick={() => onCreateModalClose(methods.reset)}
                disabled={readOnly}
              >
                취소
              </Button>
              <Button
                onClick={handleClick}
                disabled={readOnly}
              >
                등록
              </Button>
            </div>
          }
        >
          <FormProvider {...methods}>
            <div className={classes.section}>
              <AffiliatedCompanyPersonCreateModalForm onKeyDown={handleKeyDown} />
            </div>
          </FormProvider>
        </ModalLayout>
      )}
    </>
  );
};
