import { useQuery } from 'react-query';
import type { AffiliatedCompanyPersonCharacterHobbyView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/types/view';
import { affiliatedCompanyPersonHobbyApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/character/hobby/query/api';

export const affiliatedCompanyPersonHobbyQuery = {
  usePersonCharacterHobbyGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<AffiliatedCompanyPersonCharacterHobbyView[]>({
      queryKey: ['affiliated-company', 'person', 'character', 'hobby', id],
      queryFn: () => affiliatedCompanyPersonHobbyApi.getPersonCharacterHobby(id, menuId),
      enabled: !!id,
    });
    return {
      data,
      isLoading,
    };
  },
};
