import { createContext } from 'react';
import type { AffiliatedPersonShortView } from '@front/src/features/affiliated-person/types/view';

export { Context as AffiliatedPersonDrawerListContext };

interface State {
  list?: AffiliatedPersonShortView[];
}

const Context = createContext<State>({});
