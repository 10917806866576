import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Modal, Paper } from '@mui/material';
import { ColorPalette } from 'assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DefaultFunction } from 'type/Function';
import TextBox from 'layouts/Text';
import Select from '@front/layouts/Select';

export type ModalPositionType = 'left' | 'top' | 'right' | 'bottom' | 'center' | 'fullscreen' | 'none';

export interface ModalLayoutProps {
  open: boolean;
  title: string;
  width?: string | number;
  height?: string | number;
  onClose?: DefaultFunction;
  children: ReactNode;
  footer?: ReactNode;
  backdropClose?: boolean;
  position?: ModalPositionType;
  sx?: CSSProperties;
}

export default function ModalLayout({
  open,
  title,
  width = '80dvw',
  height = 'inherit',
  onClose,
  children,
  backdropClose = true,
  position = 'center',
  footer,
  sx = {},
}: Readonly<ModalLayoutProps>) {
  const [positionType, setPositionType] = useState(position);
  const sxPosition = useMemo(() => {
    switch (positionType) {
      case 'center': {
        return {
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
        };
      }
      case 'top': {
        return {
          transform: 'translate(-50%, 0%)',
          top: '0',
          left: '50%',
        };
      }
      case 'bottom': {
        return {
          transform: 'translate(-50%, 0%)',
          bottom: '0',
          left: '50%',
        };
      }
      case 'left': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          left: '0',
        };
      }
      case 'right': {
        return {
          transform: 'translate(0%, -50%)',
          top: '50%',
          right: '0',
        };
      }
      case 'fullscreen': {
        return {
          transform: 'translate(0%, 0%)',
          top: '0',
          left: '0',
          width: '100dvw',
          height: '100dvh',
          maxWidth: 'inherit',
          maxHeight: 'inherit'
        };
      }
    }
  }, [positionType]);
  const handleSelectChange = useCallback(
    (e) => {
      setPositionType(e.target.value);
    },
    [setPositionType]
  );
  useEffect(() => {
    return () => setPositionType('center');
  }, []);
  if (!open) return null;
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if ((!backdropClose && reason === 'backdropClick') || reason === 'escapeKeyDown') {
          return;
        }
        if (onClose) {
          onClose();
        }
      }}
      disableEscapeKeyDown
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        ...sx,
      }}
    >
      <Paper
        sx={{
          width,
          position: 'fixed',
          borderRadius: 0,
          maxWidth: '90vw',
          minHeight: height,
          maxHeight: '90vh',
          overflow: 'hidden',
          bgColor: ColorPalette._b2b4b7,
          boxShadow: `4px 4px 20px 0px ${ColorPalette._252627}`,
          ...sxPosition,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '0 20px',
            height: '50px',
            alignItems: 'center',
            backgroundColor: ColorPalette._2d3a54,
            color: ColorPalette._ffffff,
          }}
        >
          <TextBox variant="heading2">{title}</TextBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Select
              variant="outlined"
              value={positionType}
              onChange={handleSelectChange}
            >
              {optionList.map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
            {onClose && (
              <FontAwesomeIcon
                icon="xmark"
                onClick={onClose}
                style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'flex-start',
            width: '100%',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            maxHeight: '80dvh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
                height: footer ? 'calc(100% - 80px)' : '100%',
                padding: '20px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '10px',
                  height: '10px',
                  backgroundColor: ColorPalette._e4e9f2,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: ColorPalette._697183,
                },
                scrollbarGutter: 'stable',
              }}
            >
              {children}
            </Box>
            {footer && (
              <Box
                sx={{
                  width: '100%',
                  height: '80px',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                {footer}
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

const optionList = [
  {
    value: 'center',
    label: '중앙',
  },
  {
    value: 'top',
    label: '상단',
  },
  {
    value: 'bottom',
    label: '하단',
  },
  {
    value: 'left',
    label: '왼쪽',
  },
  {
    value: 'right',
    label: '오른쪽',
  },
  {
    value: 'fullscreen',
    label: '전체화면',
  },
];
