import React from 'react';
import { Box } from '@mui/material';
import type { DefaultFunction } from 'type/Function';
import type { AlertView } from '@front/src/features/dialog/types';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';

interface Props extends AlertView {
  open: boolean;
  onClose: DefaultFunction;
}

export const DialogAlert = (props: Props) => {
  const { open, onClose, closeText, children, title = '알림' } = props;
  return (
    <ModalUI
      open={open}
      onClose={onClose}
      title={title}
    >
      <ModalBodyUI>
        <ModalHeaderUI>
          <ButtonBasicUI
            onClick={onClose}
            shape="tertiary"
            sx={{
              width: '64px',
            }}
          >
            {closeText}
          </ButtonBasicUI>
        </ModalHeaderUI>
        <ModalContentUI
          sx={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'pre-line',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children?.map(({ value, sx }) => (
              <Box
                key={value}
                sx={{ textAlign: 'center', ...sx }}
              >
                {value}
              </Box>
            ))}
          </Box>
        </ModalContentUI>
      </ModalBodyUI>
    </ModalUI>
  );
};
