import React from 'react';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import workCategoryQuery from '@front/src/features/work/features/work/features/category/query/query';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import WorkWorkCategoryTableRow from '@front/src/features/work/features/work/features/category/components/TableRow';

export default function WorkCategoryTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { sectionId, dataId, menuId } = props;
  const { data: list } = workCategoryQuery.useGetOne({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UIBuilderTableBody<WorkCategoryView>
      {...props}
      list={list}
      RowComponent={WorkWorkCategoryTableRow}
    />
  );
}
