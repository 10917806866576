import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/widgets/useState';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/detail-modal/repository';
import { useCallback } from 'react';

export { useLogic as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalLogic };

const useLogic = (menuId) => {
  const { modalId, isOpen, setIsOpen, personId } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonDetailModalState(
      (state) => ({
        modalId: state.modalId,
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
        personId: state.personId,
      }),
      shallow
    );
  const { data: detail, isLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository.useDetailGet(
      modalId,
      menuId
    );
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const d = {
    detail,
  };
  const h = {
    onClose: handleClose,
  };
  return {
    h,
    d,
    isLoading,
    isOpen,
    personId,
  };
};
