import React, { useCallback } from 'react';
import type { ProposalUpdateArrangementPlanParameter } from '@front/ost_proposal/parameter';
import type {
  ProposalArrangementPlanId,
  ProposalArrangementPlanVO,
} from '@front/ost_proposal/domain';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import useDialog from '@front/dialog/hook';
import type { TextFieldProps } from '@mui/material';
import { Box, TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import dayjs from 'dayjs';
import Button from '@front/layouts/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { RenderDateInput } from '@front/components/RenderDateInput';
import { isValidDate } from '@front/util/DateUtil';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Input from '@front/layouts/Input';
import type { RootState } from '@front/services/reducer';

interface Props {
  key: ProposalArrangementPlanId;
  item: ProposalArrangementPlanVO;
  index: number;
  onUpdate: (params: ProposalUpdateArrangementPlanParameter) => void;
  onDelete: (id: ProposalArrangementPlanId) => void;
  isAdvisor: boolean;
}

export { Item as PlanItem };
const Item = ({ item, index, onUpdate, onDelete, isAdvisor }: Props) => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const openSnackbar = useCallback(
    (message) => {
      dispatch(
        snackbarAction.show({
          message,
          severity: SnackbarSeverityType.warning,
        })
      );
    },
    [dispatch]
  );
  const status = useSelector((root: RootState) => root.proposal.detail!.status, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  return (
    <TableRow key={`${item.id}_${index}`}>
      <OldTd>
        <DatePicker
          readOnly={
            !isAdvisor ||
            !(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) ||
            arrangementStatus !== ProposalArrangementInformationStatus.ON_GOING
          }
          inputFormat="YYYY-MM-DD"
          mask="____-__-__"
          value={item.startDate && dayjs(item.startDate).format('YYYY-MM-DD')}
          onChange={(e) => {
            const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
            const error = !value || !isValidDate(value);
            if (!value) {
              onUpdate({
                id: item.id,
                startDate: undefined,
              });
              return;
            }
            if (error) {
              openSnackbar('올바르지 않은 날짜 형식입니다.');
              return;
            }
            onUpdate({
              id: item.id,
              startDate: value,
            });
          }}
          renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
        />
      </OldTd>
      <OldTd>
        <DatePicker
          readOnly={
            !isAdvisor ||
            !(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) ||
            arrangementStatus !== ProposalArrangementInformationStatus.ON_GOING
          }
          inputFormat="YYYY-MM-DD"
          mask="____-__-__"
          value={item.endDate && dayjs(item.endDate).format('YYYY-MM-DD')}
          onChange={(e) => {
            const value = e ? dayjs(e).format('YYYY-MM-DD') : '';
            const error = !value || !isValidDate(value);
            if (!value) {
              onUpdate({
                id: item.id,
                endDate: undefined,
              });
              return;
            }
            if (error) {
              openSnackbar('올바르지 않은 날짜 형식입니다.');
              return;
            }
            onUpdate({
              id: item.id,
              endDate: value,
            });
          }}
          renderInput={(parameter: TextFieldProps) => <RenderDateInput parameter={parameter} />}
        />
      </OldTd>
      <OldTd>
        <Input
          readOnly={
            !isAdvisor ||
            !(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) ||
            arrangementStatus !== ProposalArrangementInformationStatus.ON_GOING
          }
          variant="outlined"
          defaultValue={item.title}
          onBlur={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (!value.trim()) {
              dispatch(
                snackbarAction.show({
                  message: '활동명을 입력 하세요.',
                  severity: SnackbarSeverityType.warning,
                })
              );
            }
            onUpdate({
              id: item.id,
              title: value,
            });
          }}
        />
      </OldTd>
      <OldTd>
        <span>
          <Input
            readOnly={
              !isAdvisor ||
              !(status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) ||
              arrangementStatus !== ProposalArrangementInformationStatus.ON_GOING
            }
            variant="outlined"
            defaultValue={item.note}
            onBlur={(e) => {
              const value = (e.target as HTMLInputElement).value;
              if (!value.trim()) {
                dispatch(
                  snackbarAction.show({
                    message: '비고를 입력 하세요.',
                    severity: SnackbarSeverityType.warning,
                  })
                );
              }
              onUpdate({
                id: item.id,
                note: value,
              });
            }}
          />
        </span>
      </OldTd>
      {isAdvisor &&
        (status === ProposalStatus.WRITING || status === ProposalStatus.SUBMISSION) &&
        arrangementStatus === ProposalArrangementInformationStatus.ON_GOING && (
          <OldTd>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                shape="basic3"
                onClick={() => {
                  confirm({
                    children: '해당 수행 계획을 삭제하시겠습니까?',
                    confirmText: '확인',
                    afterConfirm: () => {
                      onDelete(item.id);
                    },
                  });
                }}
              >
                삭제
              </Button>
            </Box>
          </OldTd>
        )}
    </TableRow>
  );
};
