import React from 'react';
import workReviewerQuery from '@front/src/features/work/features/work/features/reviewer/query/query';
import type { WorkReviewerView } from '@front/src/features/work/features/work/features/reviewer/types/view';
import WorkReviewerTableRow from '@front/src/features/work/features/work/features/reviewer/components/TableRow';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';

export default function WorkReviewerTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { sectionId, dataId, menuId } = props;

  const { data: list } = workReviewerQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UIBuilderTableBody<WorkReviewerView>
      {...props}
      list={list}
      RowComponent={WorkReviewerTableRow}
    />
  );
}
