import React, { Suspense, useCallback, useState } from 'react';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import { contractCmTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table-cells';
import { ContractCmTable } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table';
import useCmModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/useCmModalStore';
import ModalWithClientTabs from '../../components/modal-with-client-tabs';
import { ClientShortView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

export { Section as ContractCmSection };

function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(contractCmTableCells);

  const { isOpen, onClose, readOnly } = useCmModalStore();

  //TODO: 발주처 목록 FETCH (By props.dataId) 후 clientsData map
  isOpen && console.debug(`발주처 목록 fetch 구현 예정`);
  const clientsData: ClientShortView[] = [
    {
      id: 1,
      name: '테스트1',
    },
    {
      id: 2,
      name: '테스트2',
    },
    {
      id: 3,
      name: '테스트3',
    },
  ];

  const [clientId, setClientId] = useState<number>(clientsData.length > 0 ? clientsData[0].id : 0);
  const onClientSelected = useCallback((selectedClientId: number) => {
    setClientId(selectedClientId);
  }, []);

  if (!isOpen) return <></>;
  return (
    <ModalWithClientTabs
      name="계약CM"
      isOpen={isOpen}
      onClose={onClose}
      onTabClick={onClientSelected}
      clients={clientsData}
      defaultClientId={clientId}
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <ContractCmTable sectionProps={{ ...props, readOnly }} />
      </Suspense>
    </ModalWithClientTabs>
  );
}
