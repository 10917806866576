import React, { memo } from 'react';

import Input from '@front/layouts/Input';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import TextBox from '@front/layouts/Text';
import { Box } from '@mui/material';

interface Props {
  totalBudget?: number;
  unspentBudget?: number;
}

const OstUnspentBudget = ({ unspentBudget, totalBudget }: Props) => (
  <DataFieldWithLabel
    label="잔여 예산"
    labelSX={{ minWidth: '80px' }}
  >
    <Input
      isAmount={true}
      readOnly={true}
      key={unspentBudget}
      variant="outlined"
      defaultValue={unspentBudget?.toLocaleString()}
    />
    <Box
      sx={{
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TextBox
        variant="body2"
        sx={{
          marginRight: '4px',
        }}
      >
        원
      </TextBox>
      <TextBox variant="body2">
        ({totalBudget && unspentBudget && ((unspentBudget / totalBudget) * 100).toFixed(2)}%)
      </TextBox>
    </Box>
  </DataFieldWithLabel>
);

const OstFormUnspentBudget = memo(OstUnspentBudget);

export default OstFormUnspentBudget;
