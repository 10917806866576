import type { MenuView } from '@front/src/types';
import { create } from 'zustand';

interface State {
  tabList?: MenuView[];
  menuProps?: MenuView;
  menuData: MenuView[];
  open: boolean;
  noteId?: number;
  /**
   * 좌측 퀵뷰의 경우 여러 종류가 있고 모든 퀵뷰는 한 사이드에서 중첩하여
   * 띄우지 않는 기획이기에 하나의 퀵뷰에 문자열로 구분하여 조건부 렌더링
   */
  leftDrawerState: string;
  /**
   * 우측 퀵뷰의 경우 현재 종류가 하나뿐이기에 boolean으로 구분
   * 추후에 우측 퀵뷰의 종류가 추가되면 문자열로 변경 필요
   */
  isRightDrawerOpen: boolean;
  menuId?: number;
  /**
   * Url pathName이 변경되면 우측 퀵뷰가 닫히는 공통 코드가 있어
   * 페이지 링크 이동시 우측 퀵뷰가 닫히는 현상이 발생하여
   * 이를 우회하고자 링크를 통해 이동했는지 여부를 판별하기 위한 플래그
   */
  isThroughLink: boolean;
}

interface Actions {
  setTabList: (tabList: MenuView[]) => void;
  setMenuProps: (menuProps: MenuView) => void;
  setMenuData: (menuData: MenuView[]) => void;
  toggle: () => void;
  setNoteId: (noteId: number) => void;
  setLeftDrawerState: (leftDrawerState: string) => void;
  setIsRightDrawerOpen: (isRightDrawerOpen: boolean) => void;
  setMenuId: (noteId?: number) => void;
  setIsThroughLink: (isThroughLink: boolean) => void;
}

export { useState as useGlobalNavBarState };

const useState = create<State & Actions>((set) => ({
  setTabList: (tabList: MenuView[]) => set(() => ({ tabList })),
  setMenuProps: (menuProps: MenuView) =>
    set(() => ({
      menuProps,
    })),
  menuData: [],
  setMenuData: (menuData: MenuView[]) =>
    set(() => ({
      menuData,
    })),
  open: true,
  toggle: () => set(({ open }) => ({ open: !open })),
  leftDrawerState: '',
  isRightDrawerOpen: false,
  setLeftDrawerState: (leftDrawerState: string) =>
    set(() => ({
      leftDrawerState,
    })),
  setIsRightDrawerOpen: (isRightDrawerOpen: boolean) =>
    set(() => ({
      isRightDrawerOpen,
    })),
  noteId: 0,
  setNoteId: (noteId: number) => set(() => ({ noteId })),
  setMenuId: (menuId) => set(() => ({ menuId })),
  isThroughLink: false,
  setIsThroughLink: (isThroughLink: boolean) => set(() => ({ isThroughLink })),
}));
