import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import estimationHistoryQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/query';
import { EstimationFinalTableHead } from '@front/src/features/project-sales/features/tabs/estimation/sections/final/components/table-head';
import { EstimationFinalTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/final/components/table-body';

export { Table as EstimationFinalTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;
  const { dataId, menuId, sectionId } = sectionProps;

  const list = estimationHistoryQuery.useList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={EstimationFinalTableHead}
      BodyComponent={EstimationFinalTableBody}
      readOnly={true} // 읽기모드에서는 동작버튼[:]을 제공하지 않는다
      useNote={false} // 노트 사용 여부
      list={list?.filter((item) => item.estimation.isConfirmed)}
    />
  );
}
