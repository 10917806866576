import type React from 'react';
import workCellComponent from '@front/src/features/work/features/work/components/cellComponent';
import type { SxProps } from '@mui/system';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';

export interface CellComponentProps<T = any> extends UIBuilderTableRowProps<T> {
  isEditMode?: boolean;
  onClick?: () => void;
  isForm?: boolean;
  sx?: SxProps;
}

export interface CellComponent<T = any> {
  [key: number]: React.FunctionComponent<CellComponentProps<T>>;
}

const cellComponent: CellComponent = {
  ...workCellComponent,
};

export default cellComponent;
