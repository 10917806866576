import React, { useContext } from 'react';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/src/components/layout/table/table';
import { Th } from '@front/src/components/layout/table/th';
import classes from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/modal-table.module.scss';
import { ProjectSalesInfoProjectDivisionModalContext } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/context';
import { NoResultBox } from '@front/src/components/no-result-box/no-result-box';
import CircularProgress from '@front/components/CircularProgress';
import { ProjectSalesInfoProjectDivisionModalTableRow } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/components/modal-table-row';
import { RowClickTableContainer } from '@front/src/components/layout/table/row-click-table-container';
import { useProjectSalesInfoProjectDivisionModalState } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/widgets/useState';

export { ModalTable as ProjectSalesInfoProjectDivisionModalTable };

const ModalTable = () => {
  const {
    d: { projectDivisionModalData },
    isLoading,
  } = useContext(ProjectSalesInfoProjectDivisionModalContext);
  const isDataUndefinedOrLoading = typeof projectDivisionModalData === 'undefined' || isLoading;
  const loadingSpinner = (
    <div className={classes.no__result}>
      <CircularProgress size={30} />
    </div>
  );
  const setId = useProjectSalesInfoProjectDivisionModalState((state) => state.setId);
  const isDataEmpty = projectDivisionModalData?.length === 0;
  const noDataLetter = <NoResultBox message="관련 정보가 없습니다." />;
  return (
    <RowClickTableContainer setId={setId}>
      <Table>
        <TableHead>
          <TableRow>
            <Th
              className={classes.width__4}
              center={true}
            >
              {}
            </Th>
            <Th
              className={classes.width__4}
              center={true}
            >
              No.
            </Th>
            <Th
              className={classes.width__6}
              center={true}
            >
              날짜
            </Th>
            <Th
              className={classes.width__5}
              center={true}
            >
              영업 담당자
            </Th>
            <Th
              className={classes.width__7}
              center={true}
            >
              관계사
            </Th>
            <Th
              className={classes.width__5}
              center={true}
            >
              관계사 담당자
            </Th>
            <Th
              className={classes.width__6}
              center={true}
            >
              사업진행단계
            </Th>
            <Th
              className={classes.width__6}
              center={true}
            >
              예상착수시기
            </Th>
            <Th
              className={classes.width__7}
              center={true}
            >
              코멘트
            </Th>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isDataUndefinedOrLoading &&
            !isDataEmpty &&
            projectDivisionModalData?.map((item, index) => (
              <ProjectSalesInfoProjectDivisionModalTableRow
                key={item.id}
                item={item}
                index={index}
              />
            ))}
        </TableBody>
      </Table>
      {isDataUndefinedOrLoading && loadingSpinner}
      {isDataEmpty && !isLoading && noDataLetter}
    </RowClickTableContainer>
  );
};
