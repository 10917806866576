import React from 'react';
import Box from '@mui/material/Box';
import ButtonIconFileInfo from '@front/src/features/work/features/work/components/ButtonIconFileInfo';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type {
  UIBuilderTableFieldValues,
  UIBuilderTableFilterItem,
} from '@front/src/components/ui-builder/table/hooks/useForm';
import UIBuilderTableHeaderCellItem from '@front/src/components/ui-builder/table/HeaderCellItem';

export default function UIBuilderTableHeaderCellList(props: Readonly<UiBuilderTableProps>) {
  const { list: originalList } = props;

  const { control, setValue } = useFormContext<UIBuilderTableFieldValues>();

  const sortFieldArray = useFieldArray<UIBuilderTableFieldValues, 'sortPriorityList', string>({
    control,
    name: 'sortPriorityList',
    keyName: 'fieldId',
  });

  const { fields: bundleFields } = useFieldArray({
    control,
    name: 'filterBundle',
  });

  const getContent = (name) =>
    name === '파일명' ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
        }}
      >
        {name}
        <ButtonIconFileInfo />
      </Box>
    ) : (
      name
    );

  const onFilterChange = (
    newFilter: UIBuilderTableFilterItem[],
    active: boolean,
    index: number
  ) => {
    if (!originalList) return;

    const newShowList = bundleFields.reduce((currentList, bundleField, bundleIndex) => {
      const isCurrentFilter = bundleIndex === index;
      const isActiveFilter = bundleField.active;

      if (!isCurrentFilter && !isActiveFilter) {
        return currentList;
      }

      const checkedValues = (isCurrentFilter ? newFilter : bundleField.filter)
        .filter((f) => f.checked)
        .map((m) => m.value);

      return currentList.filter((item) => {
        const value = bundleField.attributeName?.reduce((acc, key) => acc?.[key], item) ?? '';
        return checkedValues.includes(value);
      });
    }, originalList);

    setValue('showList', newShowList);

    const newFilterBundle = bundleFields.map((bundleField, bundleIndex) => {
      if (bundleIndex === index) {
        if (active) {
          return { ...bundleField, active: true, filter: newFilter };
        }
        return { ...bundleField, active: false, filter: newFilter };
      }
      if (bundleField.active) {
        return {
          ...bundleField,
          filter: bundleField.filter.map((filterItem) => {
            const valuesInNewShowList = newShowList.map(
              (item) => bundleField.attributeName?.reduce((acc, key) => acc?.[key], item) ?? ''
            );
            if (filterItem.checked) {
              return {
                ...filterItem,
                shown: valuesInNewShowList.includes(filterItem.value),
              };
            }
            return {
              ...filterItem,
              checked: valuesInNewShowList.includes(filterItem.value),
            };
          }),
        };
      }
      return {
        ...bundleField,
        filter: bundleField.filter.map((filterItem) => {
          const valuesInNewShowList = newShowList.map(
            (item) => bundleField.attributeName?.reduce((acc, key) => acc?.[key], item) ?? ''
          );
          return {
            ...filterItem,
            shown: valuesInNewShowList.includes(filterItem.value),
          };
        }),
      };
    });

    setValue(`filterBundle`, newFilterBundle);
  };

  return (
    <>
      {bundleFields?.map((f, index) => (
        <UIBuilderTableHeaderCellItem
          {...props}
          key={f.id}
          headerId={f.headerId}
          sortFieldArray={sortFieldArray}
          bundleIndex={index}
          onFilterChange={(filter, active) => onFilterChange(filter, active, index)}
          active={bundleFields[index].active}
        >
          {getContent(bundleFields[index]?.name)}
        </UIBuilderTableHeaderCellItem>
      ))}
    </>
  );
}
