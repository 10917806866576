import { shallow } from 'zustand/shallow';
import { useCallback } from 'react';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { useProjectSalesInfoContractContractUpdateModalState } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/widgets/useState';
import { projectSalesInfoContractContractUpdateModalRepository } from '@front/src/features/project-sales-info/features/contract/features/contract/features/update-modal/repository';
import { projectSalesInfoContractCategoryKey } from '@front/src/features/project-sales-info/features/contract/utils/constant';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { projectSalesInfoContractConfirmedRepository } from '@front/src/features/project-sales-info/features/contract/features/confirmed/repository';
import { projectSalesInfoContractContractRepository } from '@front/src/features/project-sales-info/features/contract/features/contract/repository';

export { useLogic as useProjectSalesInfoContractContractUpdateModalLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    isOpen,
    setIsOpen,
    id: modalId,
    setId,
  } = useProjectSalesInfoContractContractUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoContractContractUpdateModalRepository.useDetailGet(modalId, menuId);
  const { run: onUpdate, setCallback } =
    projectSalesInfoContractContractUpdateModalRepository.useUpdate(menuId);
  const { data: categoryList, isLoading: isCategoryLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoContractCategoryKey, menuId);
  const { data: contractConfirmed, isLoading: isContractConfirmedLoading } =
    projectSalesInfoContractConfirmedRepository.useDetailGet(id, menuId);
  const { data: estimationList, isLoading: isEstimationListLoading } =
    projectSalesInfoContractContractRepository.useEstimationListGet(id, menuId);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const handleClose = useCallback(() => {
    setId(undefined);
    setIsOpen(false);
  }, [setIsOpen, setId]);
  const d = { detail, categoryList, contractConfirmed, estimationList };
  const h = { onUpdate, onClose: handleClose };
  return {
    d,
    h,
    isLoading:
      isDetailLoading || isCategoryLoading || isContractConfirmedLoading || isEstimationListLoading,
    isOpen,
  };
};
