import React from 'react';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import {
  getApprovalDocumentControlPanelList,
  getApprovalDocumentHeaderControlPanelList,
} from '@front/src/features/drawer-approval/utils/constants';
import {
  type FieldValues,
  type UseFieldArrayAppend,
  type UseFieldArrayMove,
  type UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import useApprovalStore from '@front/src/features/drawer-approval/useState';

interface Props {
  isHeader?: boolean;
  append: UseFieldArrayAppend<FieldValues, 'request.approvalList'>;
  remove: UseFieldArrayRemove;
  move?: UseFieldArrayMove;
  id?: string;
  isEditOpen?: boolean;
  index?: number;
}

export default function ApprovalStepControlPanel({
  isHeader,
  move,
  remove,
  append,
  id,
  index = 0,
  isEditOpen,
}: Props) {
  const setEditOpenList = useApprovalStore((state) => state.setEditOpenList);
  const { getValues } = useFormContext();
  const isTop = index === 0;
  const isBottom = index === getValues('request.approvalList').length - 1;
  const actions = isHeader
    ? {
        onAdd: () => {
          append({ userId: 0 });
        },
        onRemoveAll: () => {
          remove();
        },
        onCloseAll: () => {
          setEditOpenList();
        },
      }
    : {
        onAdd: () => {
          append({ userId: 0 });
        },
        onEdit: () => {
          setEditOpenList(id);
        },
        onRemove: () => {
          remove(index);
        },
        onMoveUp: () => {
          move?.(index, index - 1);
        },
        onMoveDown: () => {
          move?.(index, index + 1);
        },
      };

  const itemList = isHeader
    ? getApprovalDocumentHeaderControlPanelList({ actions })
    : getApprovalDocumentControlPanelList({ actions, isEditOpen, isTop, isBottom });

  return <ContextMenuUI itemList={itemList} />;
}
