import React from 'react';
import { ColorPalette } from '@front/assets/theme';
import TableContainer from '@mui/material/TableContainer';
import type { BoxProps } from '@mui/material';

export default function VirtualizedAutoSizerTableContainerUI({ ...rest }: Readonly<BoxProps>) {
  return (
    <TableContainer
      component="div"
      {...rest}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        border: `1px solid ${ColorPalette.line.line}`,
        overflow: 'hidden',
      }}
    />
  );
}
