// npm install is-mobile --force
import { isMobile as isMobileAlias } from 'is-mobile';

const VIEWPORT_TAG_ID = 'viewport_tag';

export enum ViewportMode {
  MOBILE = 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1',
  DESKTOP = 'initial-scale=0.3',
}

export const changeViewport = (mode: ViewportMode) => {
  const viewportTag = document.getElementById(VIEWPORT_TAG_ID);
  console.debug(`change viewport to ${mode}`);
  viewportTag?.setAttribute('content', mode);
  return mode === ViewportMode.MOBILE ? 'MOBILE' : 'DESKTOP';
};

export const isMobileDevice = () => isMobileAlias();
