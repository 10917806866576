import React, { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Checkbox from '@front/layouts/Checkbox';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import {
  convertBooleanToYNIfExistForForm,
  convertEmptyToNullForParameter,
  convertEmptyToNullIfNumberForParameter,
  convertNullToEmptyForForm,
  convertYNToBooleanForParameter,
  YNOptionList,
} from '@front/src/utils';
import type { ProjectComplexInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/types/view';
import { shallow } from 'zustand/shallow';
import { HookFormMultipleSelect } from '@front/src/components/select-with-hook-form/multiple-select-with-hook-form';
import { useProjectSalesInfoSubjectReviewModalComplexInformationState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/useState';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/features/item/widgets/context';
import { projectSalesInfoSubjectReviewModalComplexInformationExperimentOptionList } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/utils/constant';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { getSortedExperimentString } from '@front/src/features/project-sales-info/utils';
import { experimentCustomSort } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/utils';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';

export { Item as ProjectSalesInfoSubjectReviewModalComplexInformationListItem };

interface Props {
  item: ProjectComplexInformationView;
}

const Item = ({ item }: Props) => {
  const { ItemTableCell, Text } = AddDeleteTable;
  const { onUpdate } = useContext(
    ProjectSalesInfoSubjectReviewModalComplexInformationListItemContext
  );
  const { idList, setIdList, id } = useProjectSalesInfoSubjectReviewModalComplexInformationState(
    (state) => ({
      idList: state.idList,
      setIdList: state.setIdList,
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const methods = useForm({
    values: getValuesForForm(item),
  });
  const { handleSubmit, getValues } = methods;
  const onSubmit = handleSubmit((data) => {
    const experimentType = data.experimentType.join(',');
    onUpdate({
      ...data,
      name: convertEmptyToNullForParameter(data.name),
      floor: convertEmptyToNullIfNumberForParameter(data.floor),
      height: convertEmptyToNullIfNumberForParameter(data.height),
      floorArea: convertEmptyToNullIfNumberForParameter(data.floorArea),
      aspectRatio: convertEmptyToNullIfNumberForParameter(data.aspectRatio),
      hasAspectRatioOverThree: convertYNToBooleanForParameter(data.hasAspectRatioOverThree),
      hasConditionOfSpecialWindExperiment: convertYNToBooleanForParameter(
        data.hasConditionOfSpecialWindExperiment
      ),
      experimentType: experimentType || null,
      isChanged: item.experimentType !== experimentType,
    });
  });
  const checked = useMemo(() => idList.includes(item.id), [idList, item.id]);
  const handleCheckbox = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, item.id));
  }, [setIdList, idList, item.id]);
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const isSelected = item.id === id && !readOnly;
  const renderIfSelected = useCallback(
    (name, type?, readOnly?) => {
      if (isSelected) {
        return (
          <HookFormInput
            name={name}
            onSubmit={onSubmit}
            type={type}
            disabled={readOnly}
          />
        );
      }
      return <Text>{getValues(name) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text]
  );
  const renderSelectIfSelected = useCallback(
    (name, optionList) => {
      if (isSelected) {
        return (
          <HookFormSelect
            onSubmit={onSubmit}
            isDefaultPossible
            defaultLabel="선택"
            name={name}
            optionList={optionList}
            height="0px"
          />
        );
      }
      return <Text>{getValues(name) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text]
  );
  const renderMultiSelectIfSelected = useCallback(
    (name) => {
      if (isSelected) {
        return (
          <HookFormMultipleSelect
            onSubmit={onSubmit}
            name={name}
            optionList={projectSalesInfoSubjectReviewModalComplexInformationExperimentOptionList}
            sortFn={experimentCustomSort}
          />
        );
      }
      return <Text>{getSortedExperimentString(getValues(name)) || '-'}</Text>;
    },
    [isSelected, onSubmit, getValues, Text]
  );
  return (
    <FormProvider {...methods}>
      <ItemTableCell
        center
        height="48px"
      >
        <Checkbox
          checked={checked}
          onChange={handleCheckbox}
          onClick={(e) => e.stopPropagation()}
          disabled={readOnly}
        />
      </ItemTableCell>
      <ItemTableCell center>{renderIfSelected('name')}</ItemTableCell>
      <ItemTableCell center>{renderIfSelected('floor', 'number')}</ItemTableCell>
      <ItemTableCell center>{renderIfSelected('height', 'number')}</ItemTableCell>
      <ItemTableCell center>{renderIfSelected('floorArea', 'number')}</ItemTableCell>
      <ItemTableCell center>{renderIfSelected('aspectRatio', 'text', true)}</ItemTableCell>
      <ItemTableCell center>
        {renderSelectIfSelected('hasAspectRatioOverThree', YNOptionList)}
      </ItemTableCell>
      <ItemTableCell center>
        {renderSelectIfSelected('hasConditionOfSpecialWindExperiment', YNOptionList)}
      </ItemTableCell>
      <ItemTableCell center>{renderMultiSelectIfSelected('experimentType')}</ItemTableCell>
    </FormProvider>
  );
};

const getValuesForForm = (item) => ({
  id: item.id,
  name: convertNullToEmptyForForm(item.name),
  floor: convertNullToEmptyForForm(item.floor),
  height: convertNullToEmptyForForm(item.height),
  floorArea: convertNullToEmptyForForm(item.floorArea),
  aspectRatio: convertNullToEmptyForForm(item.aspectRatio),
  hasAspectRatioOverThree: convertBooleanToYNIfExistForForm(item.hasAspectRatioOverThree),
  hasConditionOfSpecialWindExperiment: convertBooleanToYNIfExistForForm(
    item.hasConditionOfSpecialWindExperiment
  ),
  experimentType: item.experimentType === null ? [] : item.experimentType.split(','),
});
