import React from 'react';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButton } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/components/delete-button';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/provider';

export { Widget as ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider menuId={menuId}>
    <ProjectSalesInfoSubjectReviewModalDesignDateDeleteButton />
  </ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonProvider>
);
