import React from 'react';
import { AccountingSettingsBankAccountUploadProvider } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/provider/provider';
import { AccountingSettingsManagementAccountingAccountHeaderUploadModal } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/components/modal';
import { AccountingSettingsBankAccountUploadButton } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/upload/components/upload-button';

export { Widget as AccountingSettingsBankAccountUploadWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => (
  <>
    <AccountingSettingsManagementAccountingAccountHeaderUploadModal menuId={menuId} />
    <AccountingSettingsBankAccountUploadProvider menuId={menuId}>
      <AccountingSettingsBankAccountUploadButton />
    </AccountingSettingsBankAccountUploadProvider>
  </>
);
