import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import estimationCompetitorQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/query/query';
import { EstimationCompetitorTableHead } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/components/table-head';
import { EstimationCompetitorTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/components/table-body';

export { Table as EstimationCompetitorTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;

  const list = estimationCompetitorQuery.useList({
    dataId: sectionProps.dataId,
    menuId: sectionProps.menuId,
    sectionId: sectionProps.sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={EstimationCompetitorTableHead}
      BodyComponent={EstimationCompetitorTableBody}
      readOnly={false}
      list={list}
      groupAttr="competitorCompany.name"
      groupSumAttrs={['windTunnelPrice']}
    />
  );
}
