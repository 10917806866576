import React from 'react';
import { FormProvider } from 'react-hook-form';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkBasicInfoTableSearchWordRow from '@front/src/features/work/features/work/features/basic-info/components/SearchWorkRow';
import workBasicInfoQuery from '@front/src/features/work/features/work/features/basic-info/query/query';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import WorkBasicInfoLoadingSpinner from '@front/src/features/work/features/work/features/basic-info/components/LoadingSpinner';
import useWorkBasicInfoMutationLogic from '@front/src/features/work/features/work/features/basic-info/hooks/useMutation';
import useWorkBasicInfoForm from '@front/src/features/work/features/work/features/basic-info/hooks/useForm';

export default function WorkBasicInfoTable(props: Readonly<SectionComponentProps>) {
  const { readOnly, dataId, menuId, sectionId } = props;
  const { data: detail } = workBasicInfoQuery.useGetOne({ dataId, menuId, sectionId });
  const { onUpdate } = useWorkBasicInfoMutationLogic({
    dataId,
    menuId,
    sectionId,
  });
  const { methods, onSubmit } = useWorkBasicInfoForm({
    detail,
    onUpdate,
  });
  const { getValues } = methods;
  const renderPurpose = () => {
    if (readOnly) return <TypographyUI>{getValues('purpose')}</TypographyUI>;
    return (
      <FormProvider {...methods}>
        <HookFormMention
          name="purpose"
          userList={[]}
          onBlur={onSubmit}
          placeholder="직접 입력"
        />
      </FormProvider>
    );
  };
  return (
    <TableContainerUI
      sx={{
        position: 'relative',
      }}
    >
      <WorkBasicInfoLoadingSpinner />
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <TableCellUI
              isHead
              width="160px"
            >
              코드
            </TableCellUI>
            <TableCellUI align="left">{detail?.code}</TableCellUI>
          </TableRowUI>
          <WorkBasicInfoTableSearchWordRow {...props} />
          <TableRowUI>
            <TableCellUI isHead>업무목적</TableCellUI>
            <TableCellUI>{renderPurpose()}</TableCellUI>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
