import React from 'react';
import { useQuery } from 'react-query';
import accountingApi from '@front/src/features/accounting/api/api';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import Box from '@mui/material/Box';
import TaxAccountTable from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export default function AccountingAccountManagementAccountSettingsTaxAccountFeature(
  _: SectionComponentProps
) {
  const { data } = useQuery({
    queryFn: () => accountingApi.getTaxAccount(),
    queryKey: ['accounting', 'account-settings', 'tax-account'],
    suspense: false,
  });

  if (!data) return <LoadingSpinnerUI />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <TaxAccountTable
        header={data?.countList.countList}
        body={data.tree}
      />
    </Box>
  );
}
