import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import type { AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/parameter';
import { accountingManagementAccountingAccountTableQuery } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/query/query';
import { accountingManagementAccountingAccountTableMutation } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/query/mutation';

export { Repository as accountingManagementAccountingAccountTableRepository };

const Repository = {
  useCountGet: (menuId) => {
    const { data, isLoading } = accountingManagementAccountingAccountTableQuery.useCountGet(menuId);
    return {
      data,
      isLoading,
    };
  },
  useListGet: (
    params: AccountingSettingsManagementAccountingAccountTableCategoryListGetParameter,
    menuId
  ) => {
    const { data, isLoading, remove } = accountingManagementAccountingAccountTableQuery.useListGet(
      params,
      menuId
    );
    return {
      data,
      isLoading,
      remove,
    };
  },
  useCreate: (menuId) => {
    const { mutate } = accountingManagementAccountingAccountTableMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useInsert: (menuId) => {
    const { mutate } = accountingManagementAccountingAccountTableMutation.useInsert(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const { mutate } = accountingManagementAccountingAccountTableMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        if (!params.id) {
          alert({
            children: [{ value: '수정 대상이 없습니다.' }],
          });
          return;
        }
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: (menuId) => {
    const { mutate } = accountingManagementAccountingAccountTableMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
