import AccountStatusSummaryTableCellComponent
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/summary-table/cell';
import AccountStatusDetailTableCellComponent
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/detail-table/cell';

const AccountStatusCellComponents = {
  ...AccountStatusSummaryTableCellComponent,
  ...AccountStatusDetailTableCellComponent,

}

export default AccountStatusCellComponents;