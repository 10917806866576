import { useQuery } from 'react-query';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';

import { adminManageableVariableApi } from '@front/src/features/manageable-variable/query/api';

interface Props {
  code: ManagedVariable;
  // menuId?: number | undefined;
}
const useManagedVariable = ({ code }: Props) => {
  const { data } = useQuery<VariableInformationView[]>({
    queryKey: ['managed-variable', code],
    queryFn: () => adminManageableVariableApi.getList(code),
    enabled: !!code,
  });

  return data;
};

export default useManagedVariable;
