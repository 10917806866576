import { createContext } from 'react';
import type { AffiliatedPersonCompanyView } from '@front/src/features/affiliated-person/types/view';
import { noOp } from '@front/common/contants';

export { Context as AffiliatedPersonAppBarCompanySelectModalContext };

interface State {
  onCompanySelect: (company: AffiliatedPersonCompanyView) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const Context = createContext<State>({
  onCompanySelect: noOp,
  setIsOpen: noOp,
});
