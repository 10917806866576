import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { proposalAction } from '@front/ost_proposal/action';
import { RequestProposalSubmissionCancelForm } from '@front/ost_proposal/view/detail/RequestProposalSubmissionCancel';
import type { RootState } from '@front/services/reducer';

export { RequestProposalSubmissionCancel as RequestProposalSubmissionCancelService };
const RequestProposalSubmissionCancel = () => {
  const dispatch = useDispatch();
  const onAgree = useCallback(
    (isAgreed: boolean) => dispatch(proposalAction.agreeProposalSubmissionCancel(isAgreed)),
    [dispatch]
  );
  const proposalCancelRequest = useSelector(
    (root: RootState) => root.proposal.proposalCancelRequest,
    shallowEqual
  );

  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);

  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.estimatorList],
    shallowEqual
  );

  const estimatorInfo = useMemo(
    () => estimatorList.find((estimatorView) => estimatorView.estimator.id === loginUser?.id),
    [estimatorList, loginUser]
  );

  return (
    <>
      {proposalCancelRequest &&
        !proposalCancelRequest.isEndedAt &&
        proposalCancelRequest.reason &&
        estimatorInfo && (
          <RequestProposalSubmissionCancelForm
            onAgree={onAgree}
            estimatorInfo={estimatorInfo}
          />
        )}
    </>
  );
};
