import React, { useCallback } from 'react';
import { TableRow } from '@mui/material';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { spliceAndPushCombinedIdIfExist } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/utils';
import { ColorPalette } from '@front/assets/theme';

export { ResultTableBodyRow as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalResultTableBodyRow };

interface NewRowProps {
  item: PersonModalShortView;
}

const ResultTableBodyRow = ({ item, ...props }: NewRowProps) => {
  const { addList, setAddList } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
      (state) => ({
        addList: state.addList,
        setAddList: state.setAddList,
      }),
      shallow
    );
  const handleCheckbox = useCallback(() => {
    setAddList(spliceAndPushCombinedIdIfExist(addList, item));
  }, [addList, setAddList, item]);
  return (
    <TableRow
      {...props}
      onClick={handleCheckbox}
      hover={true}
      style={{
        cursor: 'pointer',
        backgroundColor: addList
          .map((item) => `${item.id}${item.affiliatedCompanyId}`)
          .includes(`${item.id}${item.affiliatedCompanyId}`)
          ? ColorPalette._cddaf5
          : '',
      }}
    />
  );
};
