import { useEffect } from 'react';
import type { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { useAccountingUploadEcountDataState } from '@front/src/features/accounting/features/upload/features/ecount-data/useState';
import { shallow } from 'zustand/shallow';

export { useLogic as useAccountingUploadEcountDataLogic };

interface Props {
  type?: ECOUNT_FILE_TYPES;
  isModalMode?: boolean;
}

const useLogic = ({ isModalMode, type }: Props) => {
  const { setEcountFileType } = useAccountingUploadEcountDataState(
    (state) => ({
      setEcountFileType: state.setEcountFileType,
    }),
    shallow
  );
  useEffect(() => {
    if (!type) {
      setEcountFileType(undefined);
      return;
    }
    if (!isModalMode) return;
    setEcountFileType(type);
  }, [setEcountFileType, type, isModalMode]);
};
