import React, { useCallback, useContext, useState } from 'react';
import UserSelectorModal from '@front/components/UserSelector/Modal';
import { AffiliatedCompanyMeetingHistoryModalFormCompanionTable } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/components/form-companion-table';
import { AffiliatedCompanyMeetingHistoryModalContext } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/widgets/context';
import type { UserId } from '@front/user/domain';

export { FormCompanion as AffiliatedCompanyMeetingHistoryModalFormCompanion };
const FormCompanion = () => {
  const {
    h: { setUserIdList },
    userIdList,
  } = useContext(AffiliatedCompanyMeetingHistoryModalContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleChange = useCallback(
    (value) => {
      setUserIdList(value);
    },
    [setUserIdList]
  );
  const handleClick = useCallback(() => setIsOpen(true), [setIsOpen]);
  return (
    <>
      {isOpen && (
        <UserSelectorModal
          multi
          open={isOpen}
          onClose={handleClose}
          value={userIdList as UserId[]}
          onChange={handleChange}
        />
      )}
      <AffiliatedCompanyMeetingHistoryModalFormCompanionTable onClick={handleClick} />
    </>
  );
};
