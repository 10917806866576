import React, { useCallback, useMemo } from 'react';
import Checkbox from '@front/layouts/Checkbox';
import { spliceAndPushIfExist } from '@front/src/features/affiliated-company/utils';
import { useProjectSalesInfoAffiliatedCompanyWebHardState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/useState';
import { shallow } from 'zustand/shallow';
import { useShallow } from 'zustand/react/shallow';

export { CheckBox as ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRowCheckBox };

interface Props {
  id: number;
}

const CheckBox = ({ id }: Props) => {
  const { setIdList, idList } = useProjectSalesInfoAffiliatedCompanyWebHardState(
    (state) => ({
      setIdList: state.setIdList,
      idList: state.idList,
    }),
    shallow
  );
  const checked = useMemo(() => idList.includes(id), [idList, id]);
  const handleChange = useCallback(() => {
    setIdList(spliceAndPushIfExist(idList, id));
  }, [setIdList, idList, id]);
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyWebHardState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      disabled={readOnly}
    />
  );
};
