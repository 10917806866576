import React from 'react';
import { Box, Typography } from '@mui/material';
import HookFormMention from '@front/src/components/mention-with-hook-form/hook-form-mention';
import Button from '@front/layouts/Button';
import DrawerCommentItem from '@front/src/components/drawer-comment/DrawerCommentItem';
import type { CommentUpdateParams } from '@front/src/features/note/types/parameter';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useSnackbar } from 'notistack';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface SectionProps {
  children: React.ReactNode;
}

interface CountProps {
  text: string;
  count: number;
}

interface InputProps {
  onSubmit: () => void;
  userList?: { id: number; display: string }[];
  isMention?: boolean;
  setMentionList?: (mentionList: number[]) => void;
  clickedReviewer?: string;
  noteIdx?: number;
  noteId?: number; // if noteId
}

interface ListProps {
  comments?: any;
  onDelete: (commentId: number) => void;
  onUpdate: (params: CommentUpdateParams) => void;
  userList?: { id: number; display: string }[];
  noteIdx?: number;
  isNote?: boolean;
}

const DrawerCommentSection = ({ children }: SectionProps) => {
  return <Box>{children}</Box>;
};

const CommentCount = ({ count, text }: CountProps) => (
  <Typography sx={{ fontWeight: 600, margin: '10px 0' }}>
    {text} {count}건
  </Typography>
);

const CommentInput = ({
  onSubmit,
  userList,
  isMention,
  setMentionList,
  clickedReviewer,
  noteIdx,
  noteId,
}: InputProps) => {
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const nameStr = isMention ? `noteList.${noteIdx}.comment` : `comment`;
  const onClick = () => {
    if (!getValues(nameStr)) {
      enqueueSnackbar('댓글을 입력해주세요.', { variant: 'warning' });
      return;
    }

    onSubmit();
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        {isMention ? (
          <HookFormMention
            name={nameStr}
            userList={userList ?? []}
            setMentionList={setMentionList}
            clickedReviewer={clickedReviewer}
            noteId={isMention ? noteId : undefined}
          />
        ) : (
          <HookFormTextarea
            name={nameStr}
            sx={{ height: 63 }}
            resize="none"
          />
        )}
        <Box textAlign="right">
          <Button onClick={onClick}>등록</Button>
        </Box>
      </Box>
    </Box>
  );
};

const CommentList = ({ comments, onDelete, onUpdate, noteIdx, isNote, userList }: ListProps) => {
  const { control, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'commentList',
  });
  const isEmpty = comments ? comments.length === 0 : fields.length === 0;

  return (
    <Box
      padding="10px 0px 10px 10px"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      {isEmpty && (
        <Box
          sx={{
            border: '1px solid grey',
            padding: '5px',
            textAlign: 'center',
          }}
        >
          {isNote ? '댓글' : '의견'}이 없습니다.
        </Box>
      )}
      {(comments || fields)?.map((comment, index) => (
        <DrawerCommentItem
          isNote={isNote}
          userList={userList}
          key={index}
          index={index}
          noteIdx={noteIdx}
          onDelete={onDelete}
          onUpdate={onUpdate}
        />
      ))}
    </Box>
  );
};

DrawerCommentSection.Count = CommentCount;
DrawerCommentSection.Input = CommentInput;
DrawerCommentSection.List = CommentList;

export default DrawerCommentSection;
