import { accountingUploadRepository } from '@front/src/features/accounting/features/upload/features/ecount-data/repository/repository';

export { useLogic as useAccountingSettingsDailyReportStatusUploadLogic };

const useLogic = (menuId) => {
  const { run: onCreate } = accountingUploadRepository.useCreateDailyReport(menuId);
  return {
    onCreate,
  };
};
