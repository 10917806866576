import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const useWorkWorkerForm = ({ item }) => {
  const methods = useForm({
    defaultValues: getFormValues(item),
  });

  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return {
    methods,
  };
};

export default useWorkWorkerForm;

const getFormValues = (item) => ({
  workerRole: item.workerRole ?? '',
  participationRate: item.participationRate ?? '',
  roleDetail: item.roleDetail ?? '',
  user: item.user,
  textAttr1: item.textAttr1 ?? '',
  textAttr2: item.textAttr2 ?? '',
  textAttr3: item.textAttr3 ?? '',
  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  dateAttr1: item.dateAttr1 ?? '',
  dateAttr2: item.dateAttr2 ?? '',
  dateAttr3: item.dateAttr3 ?? '',
});
