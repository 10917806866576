import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

export { Context as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonContext };

interface State {
  d: {
    list?: ProjectAffiliatedCompanyPersonView[];
  };
  h: {
    onDelete: (params) => void;
    onUpdate: (params) => void;
  };
  isLoading: boolean;
}

const Context = createContext<State>({
  d: {
    list: [],
  },
  h: {
    onDelete: noOp,
    onUpdate: noOp,
  },
  isLoading: false,
});
