import { useQuery } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const useWorkOutputBundleQuery = {
  useListGet: (commonParams: UIBuilderParameter) => {
    const { data } = useQuery({
      queryFn: () => workWorkApi.getOutputBundle(commonParams),
      queryKey: ['work', 'output-bundle', commonParams.dataId],
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });
    return { data };
  },
};

export default useWorkOutputBundleQuery;
