import {CanvasRuntimeProps} from "./useCanvasUtil";
import * as fabric from "fabric";

interface TextBoxProp {
  text?: string,
  left?: number,
  top?: number,
  color?: string
}

export default function useObjectManager() {
  /**
   * 텍스트 박스 객체 생성
   * @param canvas
   * @param props
   * @remarks Textbox.hiddenTextareaContainer 속성은 모달에서 캔버스 구동 시 중요(글자 입력 오류 시 본 함수 구현부 수정 검토 요망)
   * @return TextBox Object
   */
  const createTextBox = (canvas: fabric.Canvas, props: TextBoxProp) => {
    const {text, left, top, color} = props;
    const fontSize = 30;
    const width = fontSize * 10;
    const vpCenter = canvas.getVpCenter();

    const textBox = new fabric.Textbox(text || 'comment', {
      fontSize: fontSize,
      fill: color || '#000',
      editable: true,
      width: width,
      left: left ? left : vpCenter.x - width / 2,
      top: top ? top : vpCenter.y - fontSize / 2,
      fontFamily: 'pretendard'
    });

    // MUI Modal에서 구동 시 정말 중요한 속성 설정
    textBox.hiddenTextareaContainer = canvas.lowerCanvasEl.parentElement;
    canvas.add(textBox);
    canvas.setActiveObject(textBox);

    textBox.on('editing:entered', function () {
      textBox.set('backgroundColor', 'rgba(255,255,0,0.4');
      canvas.renderAll();
    });

    // 편집 모드 종료 시 배경색 원래대로 복원
    textBox.on('editing:exited', function () {
      textBox.set('backgroundColor', '');
      canvas.renderAll();
    });

    textBox.enterEditing();
    textBox.selectionStart = textBox.text.length; // 커서를 텍스트 끝으로 이동
    textBox.selectionEnd = textBox.text.length;
    if (textBox.hiddenTextarea) {
      textBox.hiddenTextarea.selectionStart = textBox.text.length;
      textBox.hiddenTextarea.selectionEnd = textBox.text.length;
    }

    return textBox;
  };

  /**
   * 브러시 객체 생성
   * @param canvas
   * @param runtimeProps
   */
  const createBrush = (canvas: fabric.Canvas, runtimeProps: CanvasRuntimeProps) => {
    const brush = new fabric.PencilBrush(canvas);
    brush.width = runtimeProps.brush.size;
    brush.color = runtimeProps.brush.color;
    return brush;
  };

  return {
    createTextBox,
    createBrush,
  }

}