import React, { useMemo } from 'react';
import type { ProposalApprovalDocumentVO, ProposalApprovalVO } from '@front/ost_proposal/domain';
import { proposalGradeConvertToString } from '@front/ost_proposal/domain';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { OldTh, Table } from '@front/layouts/Table';
import { ApprovalItem } from '@front/ost_proposal/view/modal/approval/table/Item';
import type { RootState } from '@front/services/reducer';
import { shallowEqual, useSelector } from 'react-redux';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  approvalList: ProposalApprovalVO[];
  approvalDocument: ProposalApprovalDocumentVO;
}

export { List as ApprovalList };
const List = ({ approvalList, approvalDocument }: Props) => {
  const campaignManagerId = useSelector(
    (root: RootState) => root.proposal.detail?.campaign.manager.id,
    shallowEqual
  );
  const detail = useSelector((root: RootState) => root.proposal.detail!, shallowEqual);
  const { estimatorList, gradeTextListFilter } = getTextList();

  return (
    <TableContainer
      sx={{
        height: '250px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <OldTh>구분</OldTh>
            <OldTh>직책</OldTh>
            <OldTh>담당자</OldTh>
            <OldTh>상태</OldTh>
            <OldTh>심사의견</OldTh>
            <OldTh>등급</OldTh>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <OldTh>{'기안'}</OldTh>
            <OldTh>{'-'}</OldTh>
            <OldTh>
              {campaignManagerId === approvalDocument.writer.id
                ? '캠페인 담당자'
                : approvalDocument.writer.name}
            </OldTh>
            <OldTh>{'제출'}</OldTh>
            <OldTh>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                {approvalDocument.content}
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: '1fr 4fr',
                }}
              >
                <Box>
                  <TextBox variant="body8">{'[심사 제안 등급 결과]'}</TextBox>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  {gradeTextListFilter.map((item) => (
                    <React.Fragment key={item.title}>
                      <Box>
                        <TextBox
                          sx={{
                            color: ColorPalette._eb4c4c,
                          }}
                          variant="body8"
                        >{`${item.title} : ${item.count}`}</TextBox>
                        <TextBox variant={'body8'}>{` / ${
                          estimatorList.filter(
                            (estimatorInfo) =>
                              !detail.contributorViewList
                                .filter((cv) => cv.contributor)
                                .map((cv) => cv.contributor.id)
                                .includes(estimatorInfo.estimator.id)
                          ).length
                        }`}</TextBox>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </OldTh>
            <OldTh>{'-'}</OldTh>
          </TableRow>
          {approvalList.map((item, index) => (
            <ApprovalItem
              key={item.id}
              id={item.id}
              item={item}
              index={index}
              approvalList={approvalList}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;

function getTextList() {
  const [estimatorList] = useSelector(
    (state: RootState) => [state.proposal.estimatorList],
    shallowEqual
  );
  const set = new Set();
  const gradeList = useMemo(
    () => estimatorList.map((estimatorInformation) => estimatorInformation.grade),
    [estimatorList]
  );
  const gradeTextList = estimatorList
    .filter((e) => e.grade)
    .map((estimatorInformation) => ({
      title: estimatorInformation.grade && proposalGradeConvertToString(estimatorInformation.grade),
      count: gradeList.filter((grade) => grade === estimatorInformation.grade).length,
    }));

  const gradeTextListFilter = gradeTextList.filter((item) => {
    if (set.has(item.title)) {
      return false;
    }
    set.add(item.title);
    return true;
  });
  return { estimatorList, gradeTextListFilter };
}
