import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useProjectSalesInfoSubjectReviewModalComplexInformationListLogic } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/widgets/useLogic';
import { ProjectSalesInfoSubjectReviewModalComplexInformationListContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/complex-information/features/table/widgets/context';

export { Provider as ProjectSalesInfoSubjectReviewModalComplexInformationListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { list, isLoading } =
    useProjectSalesInfoSubjectReviewModalComplexInformationListLogic(menuId);
  const value = useMemo(
    () => ({
      list,
      isLoading,
    }),
    [list, isLoading]
  );
  return (
    <ProjectSalesInfoSubjectReviewModalComplexInformationListContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewModalComplexInformationListContext.Provider>
  );
};
