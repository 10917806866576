import { useQuery } from 'react-query';
import { AffiliatedCompanyProjectApi } from '@front/src/features/affiliated-company/features/project/query/api';
import type { AffiliatedCompanyProjectListGetParameter } from '@front/src/features/affiliated-company/features/project/types/parameter';
import type { AffiliatedCompanyProjectView } from '@front/src/features/affiliated-company/features/project/types/view';

export { Query as AffiliatedCompanyProjectQuery };

const Query = {
  useListGet: (params: AffiliatedCompanyProjectListGetParameter, menuId) => {
    const { data, remove } = useQuery<AffiliatedCompanyProjectView[]>({
      queryFn: () => AffiliatedCompanyProjectApi.getList(params, menuId),
      queryKey: ['affiliated-company', 'project', 'list', params],
      suspense: true,
      enabled: !!params.id,
    });
    return {
      data,
      remove,
    };
  },
};
