import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyOutlineWebHardButtonGroup } from '@front/src/features/affiliated-company/features/outline/features/web-hard/components/button-group';
import { AffiliatedCompanyOutlineWebHardTable } from '@front/src/features/affiliated-company/features/outline/features/web-hard/components/web-hard-table';
import { useAffiliatedCompanyOutlineWebHardLogic } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/useLogic';
import { AffiliatedCompanyOutlineWebHardContext } from '@front/src/features/affiliated-company/features/outline/features/web-hard/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyOutlineWebHardWidget = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { webHardList },
    h: { onCreate, onDelete, onUpdate },
    isLoading,
  } = useAffiliatedCompanyOutlineWebHardLogic(menuId);
  const value = useMemo(
    () => ({
      d: { webHardList },
      h: { onCreate, onDelete, onUpdate },
    }),
    [webHardList, onCreate, onDelete, onUpdate]
  );
  if (isLoading) {
    return <LoadingSpinner height="200px" />;
  }
  return (
    <AffiliatedCompanyOutlineWebHardContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyOutlineWebHardButtonGroup />
        </Header>
        <Body>
          <AffiliatedCompanyOutlineWebHardTable />
        </Body>
      </Layout>
    </AffiliatedCompanyOutlineWebHardContext.Provider>
  );
};

export default AffiliatedCompanyOutlineWebHardWidget;
