import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getDetail: (id?) => `${projectSalesInfoURL}/${id}/aspect-ratio-examination/complex-experiment`,
};

export const projectSalesInfoSubjectReviewExperimentApi = {
  getDetail: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getDetail(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
