import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyCharacterPartnerCompanyQuery } from '@front/src/features/affiliated-company/features/character/features/partner-company/query/query';
import { affiliatedCompanyCharacterPartnerCompanyMutation } from '@front/src/features/affiliated-company/features/character/features/partner-company/query/mutation';

export const affiliatedCompanyCharacterPartnerCompanyRepository = {
  useCharacterPartnerCompanyCreate: (menuId) => {
    const { mutate } =
      affiliatedCompanyCharacterPartnerCompanyMutation.useCharacterPartnerCompanyCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCharacterPartnerCompanyGet: (id?: number, menuId?) => {
    const { data, isLoading } =
      affiliatedCompanyCharacterPartnerCompanyQuery.useCharacterPartnerCompanyGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useCharacterPartnerCompanyUpdate: (menuId) => {
    const { mutate } =
      affiliatedCompanyCharacterPartnerCompanyMutation.useCharacterPartnerCompanyUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useCharacterPartnerCompanyDelete: (menuId) => {
    const { mutate } =
      affiliatedCompanyCharacterPartnerCompanyMutation.useCharacterPartnerCompanyDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
