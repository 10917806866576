import React, { Suspense, useEffect } from 'react';
import {
  Accordion,
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import NotificationControlPanel from '@front/src/features/note/components/ControlPanel';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table } from '@front/layouts/Table';
import { Tbc, Thc } from '@front/src/components/ui-builder/table';
import type { SxProps } from '@mui/system';
import type { ApprovalDocumentByNoteView, NoteItemView } from '@front/src/features/note/types/view';
import noteQuery from '@front/src/features/note/query/query';
import DrawerCommentSection from '@front/src/components/drawer-comment/DrawerCommentSection';
import noteMutation from '@front/src/features/note/query/mutation';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT, YY_MM_DD_HH_MM } from '@front/src/utils';
import NoteCardBody from '@front/src/features/note/components/NoteCardBody';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { UserId } from '@front/user/domain';
import UserIcon from '@front/layouts/UserIcon';
import { NoResult } from '@front/src/components/layout/table/no-result';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { getBorderColorByReviewedState } from '@front/src/features/note/utils';
import { useIsMutating, useQueryClient } from 'react-query';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useApprovalBoxStore from '@front/src/features/approval-box/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import useIdGroup from '@front/src/features/note/hooks/useIdGroup';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';

interface Props {
  index: number;
  setIsEditOpen?: () => void;
  isEditOpen?: boolean;
}

interface CardDetailTableProps {
  sx: SxProps;
  approvalDocument?: ApprovalDocumentByNoteView[];
  item: NoteItemView;
}

export default function NoteCard({ index }: Props) {
  const { cardState, setCardState, selectedNoteId, selectedCommentId } = useNoteStore(
    useShallow((state) => ({
      cardState: state.cardState,
      setCardState: state.setCardState,
      selectedNoteId: state.selectedNoteId,
      selectedCommentId: state.selectedCommentId,
    }))
  );
  const { getValues, watch, setValue } = useFormContext();
  const item = watch(`noteList.${index}`);

  const noteRef = React.useRef<HTMLDivElement>(null);
  const isBorderColored = !selectedCommentId && item?.id === selectedNoteId;

  useEffect(() => {
    if (noteRef.current && isBorderColored) {
      const parentElement = noteRef.current.parentElement;

      // 부모 컨테이너까지 스크롤
      if (parentElement) {
        parentElement.scrollTo({
          top: noteRef.current.offsetTop - parentElement.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }, [noteRef, isBorderColored]);

  const isApprovalDocumentCreateLoading = useIsMutating({
    mutationKey: ['approval-document', 'create', item?.id],
  });

  useEffect(() => {
    if (selectedCommentId && item?.id === selectedNoteId && noteRef.current) {
      setCardState(item.id, { isFooterOpen: true });
    }
  }, [selectedCommentId, selectedNoteId, noteRef, item]);

  if (!item) return null;

  const isEditOpen = cardState[item.id]?.isNoteEditOpen ?? false;
  const isHeaderOpen = cardState[item.id]?.isHeaderOpen ?? false;
  const isFooterOpen = cardState[item.id]?.isFooterOpen ?? false;

  const handleEdit = () => {
    setCardState(item.id, { isNoteEditOpen: !isEditOpen });
    // setValue(`noteList.${index}.contents`, item.contents ?? '');
  };

  const handleOpen = (expanded: boolean, isHeader: boolean) => {
    setCardState(item.id, isHeader ? { isHeaderOpen: expanded } : { isFooterOpen: expanded });
  };

  return (
    <Box
      sx={{
        '& .MuiAccordionSummary-root': {
          minHeight: '40px',
          padding: '10px',
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        border: isBorderColored ? `3px solid ${ColorPalette.main.main_primary}` : 'none',
        position: 'relative',
        boxSizing: 'border-box'
      }}
      ref={noteRef}
      id={item.id}
    >
      <BackdropLoadingSpinnerUI
        open={!!isApprovalDocumentCreateLoading}
        sx={{
          position: 'absolute',
        }}
      />
      <Accordion
        disableGutters={true}
        onChange={(_, expanded) => handleOpen(expanded, true)}
        expanded={isHeaderOpen}
        sx={{ ...SX_NOTECARD_HEADER_WRAP }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ overflow: 'hidden' }}
        >
          <CardHeader
            index={index}
            setIsEditOpen={handleEdit}
            isEditOpen={isEditOpen}
          />
        </AccordionSummary>
        <AccordionDetails>
          {isHeaderOpen && (
            <Suspense
              fallback={
                <LoadingSpinner
                  width="100%"
                  height="100px"
                  padding="20px"
                />
              }
            >
              <CardHeaderDetail index={index} />
            </Suspense>
          )}
        </AccordionDetails>
      </Accordion>
      <Box sx={{ ...SX_NOTECARD_BODY_WRAP }}>
        <NoteCardBody
          isEditOpen={isEditOpen}
          handleEdit={handleEdit}
          item={item}
          index={index}
        />
      </Box>
      <Accordion
        disableGutters={true}
        onChange={(_, expanded) => handleOpen(expanded, false)}
        expanded={isFooterOpen}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          댓글 {item.commentCount}건
        </AccordionSummary>
        <AccordionDetails>
          {isFooterOpen && (
            <Suspense
              fallback={
                <LoadingSpinner
                  width="100%"
                  height="100px"
                  padding="20px"
                />
              }
            >
              <CardFooter index={index} />
            </Suspense>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const NoteApprovalStatusTable = ({ sx, approvalDocument, item }: CardDetailTableProps) => {
  const isEmpty = approvalDocument?.length === 0;
  const isApprover = approvalDocument?.[0]?.myRole?.includes('결재자');
  const isReferencer = approvalDocument?.[0]?.myRole?.includes('참조자');

  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);
  const setIsBoxList = useApprovalBoxStore((state) => state.setIsBoxList);
  const { setDocumentId, setParams } = useApprovalDocumentStore(
    useShallow((state) => ({
      setDocumentId: state.setDocumentId,
      setParams: state.setParams,
    }))
  );

  const onClick = () => {
    setLeftDrawerState('approval-document');
    setNoteId(item.id);
    setParams({
      where: 'approval-document',
      noteId: item.id,
    });
    setIsBoxList(true);
    setDocumentId(item.latestApprovalDocumentIdInSubmission);
  };

  return (
    <TableContainer sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            <Thc>요청 일시</Thc>
            <Thc>제목</Thc>
            <Thc>결재 상태</Thc>
            {(isApprover || isReferencer) && <Thc>나의 역할</Thc>}
            {isApprover && <Thc>나의 결재</Thc>}
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty && (
            <NoResult
              colSpan={5}
              message="결재 건이 없습니다."
            />
          )}
          {!isEmpty &&
            approvalDocument?.map((item) => (
              <TableRow key={item.submissionDate + item.title}>
                <Tbc align="center">
                  {dayjs(item?.submissionDate).format(YY_MM_DD_HH_MM) ?? '-'}
                </Tbc>
                <Tbc>
                  <Typography
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={onClick}
                  >
                    {item?.title ?? '-'}
                  </Typography>
                </Tbc>
                <Tbc align="center">{item?.status ?? '-'}</Tbc>
                {(isApprover || isReferencer) && <Tbc align="center">{item?.myRole ?? '-'}</Tbc>}
                {isApprover && <Tbc align="center">{item?.myOrder ?? '-'}</Tbc>}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CardHeader = ({ index, setIsEditOpen, isEditOpen }: Props) => {
  const { getValues, setValue } = useFormContext();
  const item = getValues(`noteList.${index}`);

  const { mutate: toggleRead } = noteMutation.useToggleRead(item.reviewerInfoId ?? 0, {
    isRead: !item.readAt,
  });

  const onClick = (e) => {
    e.stopPropagation();
    toggleRead();
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        gap="5px"
        alignItems="center"
      >
        <NotificationControlPanel
          item={item}
          setIsEditOpen={setIsEditOpen}
          isEditOpen={isEditOpen}
        />
        <Box sx={{ ...SX_NOTECARD_HEADER_DATA_WRAP }}>
          {/*<UserIcon user={UserId(item.writerId)} />*/}
          <Box
            sx={{
              display: 'flex',
              width: '25px',
              height: '25px',
              justifyContent: 'center',
              fontSize: '18px',
              borderRadius: '25px',
              backgroundColor: ColorPalette._e4e9f2,
              color: ColorPalette._ffffff,
              border: `1px solid ${ColorPalette._e4e9f2}`,
              overflow: 'hidden',
              alignItems: 'flex-end',
            }}
          >
            {item.writerProfile.id ? (
              <img
                alt="프로필 사진"
                src={`/api/file-item/${item.writerProfile.id}`}
                style={{
                  objectFit: 'cover',
                  width: '25px',
                  height: '25px',
                }}
              />
            ) : (
              <FontAwesomeIcon icon="user" />
            )}
          </Box>
          <Typography sx={{ ...SX_NOTECARD_HEADER_DATA }}>
            {item.writerName}{' '}
            {dayjs(item.modifiedAt ?? item.createdAt ?? '').format(DATE_TIME_FORMAT)}
            {item.reviewerCount ? `(${item.reviewerCount}명 공유)` : ''}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        gap="5px"
        alignItems="center"
      >
        <Typography sx={{ ...SX_NOTECARD_HEADER_DATA, marginLeft: '5px' }}>
          {item.approvalDocumentStatusName}
          {item.approvalDocumentRelatedDate
            ? `(${dayjs(item.approvalDocumentRelatedDate).format(DATE_TIME_FORMAT)})`
            : ''}
        </Typography>
        {item.isReviewer && (
          <ButtonBasicUI onClick={onClick}>
            <Typography>{item.readAt ? '읽지 않음' : '읽음'}</Typography>
          </ButtonBasicUI>
        )}
      </Box>
    </Box>
  );
};

const CardHeaderDetail = ({ index }: Props) => {
  const { getValues, setValue } = useFormContext();
  const item = getValues(`noteList.${index}`);
  const { data: noteDetail } = noteQuery.useGetDetail(item.id);
  const { setClickedReviewer, setClickedNoteId } = useNoteStore(
    useShallow((state) => ({
      setClickedReviewer: state.setClickedReviewer,
      setClickedNoteId: state.setClickedNoteId,
    }))
  );
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoColumns: '1fr 2fr 1fr',
        rowGap: '5px',
      }}
    >
      <Typography>최종 수정 일시</Typography>
      <Typography
        sx={{ gridColumn: '2 / span 2', color: noteDetail?.modifiedAt ? 'inherit' : 'gray' }}
      >
        {noteDetail?.modifiedAt
          ? dayjs(noteDetail?.modifiedAt).format(YY_MM_DD_HH_MM)
          : '수정되지 않았습니다.'}
      </Typography>
      <Typography>회람자</Typography>
      <Box
        display="flex"
        gap="5px"
        sx={{
          color: noteDetail?.reviewerList?.length === 0 ? 'gray' : 'inherit',
        }}
      >
        {noteDetail?.reviewerList?.length === 0 && '지정된 회람자가 없습니다.'}
        {noteDetail?.reviewerList?.map((reviewer) => (
          <UserIcon
            key={reviewer?.id}
            user={UserId(reviewer?.id ?? 0)}
            sx={{
              cursor: 'pointer',
              border: `1px solid ${getBorderColorByReviewedState(
                !!reviewer?.readAt,
                noteDetail?.reviewDate
              )}`,
            }}
            onClick={() => {
              setClickedReviewer(`@[${reviewer.name}](${reviewer.id})`);
              setClickedNoteId(item.id);
            }}
          />
        ))}
      </Box>
      <Typography sx={{ justifySelf: 'end' }}>
        {noteDetail?.reviewDate ? dayjs(noteDetail?.reviewDate).format('YY-MM-DD') + '까지' : ''}
      </Typography>
      <Typography sx={{ gridColumn: '1 / span 3' }}>결재 현황</Typography>
      <NoteApprovalStatusTable
        sx={{ gridColumn: '1 / span 3', '& .MuiTableCell-root': { height: '35px' } }}
        approvalDocument={noteDetail?.approvalDocumentList}
        item={item}
      />
    </Box>
  );
};

const CardFooter = ({ index }: Props) => {
  const { getValues, handleSubmit, setValue } = useFormContext();
  const item = getValues(`noteList.${index}`);
  const queryClient = useQueryClient();
  const { menuId, dataId } = useIdGroup();
  const userList: any = queryClient.getQueryData(['note', 'authUserList', { menuId, dataId }]);
  const commentQueryParams = {
    targetId: item.id,
    targetType: 'note',
  };

  const { mutate: createComment, isLoading: isCreateLoading } =
    noteMutation.useCreateComment(commentQueryParams);
  const { mutate: updateComment, isLoading: isUpdateLoading } =
    noteMutation.useUpdateComment(commentQueryParams);
  const { mutate: deleteComment, isLoading: isDeleteLoading } =
    noteMutation.useDeleteComment(commentQueryParams);
  const { mentionList, setMentionList, clickedReviewer, noteState, setNoteState } = useNoteStore(
    useShallow((state) => ({
      mentionList: state.mentionList,
      setMentionList: state.setMentionList,
      clickedReviewer: state.clickedReviewer,
      noteState: state.noteState,
      setNoteState: state.setNoteState,
    }))
  );

  const setMentionListHandler = (mentionList: number[]) => {
    setMentionList(mentionList);
  };

  const onSubmit = handleSubmit((data) => {
    createComment({
      targetId: item.id,
      targetType: 'NOTE',
      contents: data.noteList[index].comment?.replace(/@\[(.*?)\]\(\d+\)/g, '@$1'),
      mentionList: mentionList,
    });
    setNoteState({ comment: '' });
  });

  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: `noteList.${index}.commentList`,
  });

  const { login } = useSelector(
    (root: RootState) => ({
      login: root.login.detail,
    }),
    shallowEqual
  );
  return (
    <DrawerCommentSection>
      <DrawerCommentSection.Input
        onSubmit={onSubmit}
        userList={
          userList
            ?.filter((item) => item.id !== login?.id && item.statusEn === 'EMPLOYED')
            .map((item) => ({
              id: item.id,
              display: item.name,
            })) ?? []
        }
        isMention={true}
        setMentionList={setMentionListHandler}
        clickedReviewer={clickedReviewer}
        noteIdx={index}
        noteId={item.id}
      />
      <DrawerCommentSection.List
        comments={fields}
        noteIdx={index}
        onDelete={deleteComment}
        onUpdate={updateComment}
        isNote={true}
        userList={
          userList
            ?.filter((item) => item.id !== login?.id)
            .map((item) => ({
              id: item.id,
              display: item.name,
            })) ?? []
        }
      />
    </DrawerCommentSection>
  );
};

const SX_NOTECARD_BODY_WRAP = {
  backgroundColor: 'white',
  minHeight: '40px',
  padding: '10px 14PX',
  display: 'flex',
  alignItems: 'center',
};

const SX_NOTECARD_HEADER_WRAP = {
  borderBottom: '1px solid var(--design-v-10-light-line-line, #DCE0E7)',
  boxShadow: 'none',
};

const SX_NOTECARD_HEADER_DATA_WRAP = {
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
};

const SX_NOTECARD_HEADER_DATA = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
};
