import { useQuery } from 'react-query';
import { affiliatedCompanyPersonEducationApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/education/query/api';

export { query as affiliatedCompanyPersonEducationQuery };
const query = {
  useListGet: (id?, menuId?) => {
    const { data, remove } = useQuery({
      queryFn: () => affiliatedCompanyPersonEducationApi.getList(id, menuId),
      queryKey: ['affiliated-company', 'person', 'outline', 'education', id],
      enabled: !!id,
    });
    return {
      data,
      remove,
    };
  },
};
