import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Box } from '@mui/material';
import Input from '@front/layouts/Input';
import UserSelector from '@front/components/UserSelector';
import Button from '@front/layouts/Button';

import type { UserId } from '@front/user/domain';
import { useQueryClient } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { proposalAction } from '@front/ost_proposal/action';

interface Props {
  setFormHeight: (height: number) => void;
  contributorList?: UserId[];
}

export default function ProposalMemoForm({ setFormHeight, contributorList }: Props) {
  const formik = useContext(FormikContext);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { filter: proposalFilter } = useSelector(
    (root: RootState) => ({
      filter: root.proposal.filter,
    }),
    shallowEqual
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'unwrap',
            padding: '0 10px',
          }}
        >
          <Input
            required
            multiline
            key={formik.values.description}
            defaultValue={formik.values.description ?? ''}
            variant="outlined"
            placeholder="메모 입력"
            onBlur={(e) => {
              const value = e.target.value || undefined;
              if (value !== formik.values.description) {
                formik.setFieldValue('description', value);
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            padding: '10px',
          }}
        >
          <UserSelector
            multi
            value={formik.values.attendanceList}
            onChange={(value) => {
              formik.setFieldValue('attendanceList', value);
            }}
            setFormHeight={setFormHeight}
            include={contributorList}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'unwrap',
            justifyContent: 'space-between',
            padding: '0 10px',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            fullWidth
            onClick={() => {
              formik.handleSubmit();
              setTimeout(() => {
                queryClient.invalidateQueries(['global-nav-bar']);
                dispatch(proposalAction.setFilter(proposalFilter));
              }, 100);
            }}
          >
            작성 완료
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
