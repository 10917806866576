import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import WorkReferenceTable from '@front/src/features/work/features/work/features/reference/components/Table';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

/**
 * @module 업무-업무탭-참고자료섹션
 */
export default function WorkReferenceFeature(props: Readonly<SectionComponentProps>) {
  const { name } = props;
  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        <WorkReferenceTable {...props} />
      </Suspense>
    </Layout>
  );
}
