import React from 'react';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import AffiliatedCompanyCharacterPartnerCompanyWidget from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/widget';
import AffiliatedCompanyCharacterCharacterWidget from '@front/src/features/affiliated-company/features/character/features/character/widgets/widget';
import AffiliatedCompanyCharacterOpinionWidget from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/widget';

export { sectionComponent as affiliatedCompanyCharacterSectionComponent };

const sectionComponent: SectionComponent = {
  49: ({ ...rest }) => <AffiliatedCompanyCharacterPartnerCompanyWidget {...rest} />,
  50: ({ ...rest }) => <AffiliatedCompanyCharacterCharacterWidget {...rest} />,
  51: ({ ...rest }) => <AffiliatedCompanyCharacterOpinionWidget {...rest} />,
};
