import { personBaseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import type { AffiliatedCompanyPersonRelationshipUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/parameter';

const url = {
  createPersonRelationship: (id?) => `${personBaseUrl}/${id}/relationship`,
  getPersonRelationship: (id?) => `${personBaseUrl}/${id}/relationship`,
  deletePersonRelationship: () => `${personBaseUrl}/relationship`,
  updatePersonRelationship: (id?) => `${personBaseUrl}/relationship/${id}`,
};

export const affiliatedCompanyPersonRelationshipApi = {
  getPersonRelationship: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonRelationship(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPersonRelationship: async (id?: number, menuId?) => {
    await axios.post(url.createPersonRelationship(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  updatePersonRelationship: async (
    params: AffiliatedCompanyPersonRelationshipUpdateParameter,
    menuId
  ) => {
    await axios.put(url.updatePersonRelationship(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deletePersonRelationship: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.deletePersonRelationship(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
