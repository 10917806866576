import { useIsMutating } from 'react-query';

export const useDocumentMutationLoading = () => {
  const isCommentCreateLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'create'],
  });
  const isCommentUpdateLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'update'],
  });
  const isCommentDeleteLoading = useIsMutating({
    mutationKey: ['approval-document', 'comment', 'delete'],
  });

  return !!(isCommentCreateLoading || isCommentUpdateLoading || isCommentDeleteLoading);
};
