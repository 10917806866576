import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { useCallback } from 'react';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/repository';
import { ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/domain';

export { useLogic as useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalLogic };

const useLogic = (menuId) => {
  const {
    isOpen,
    setIsOpen,
    setParameter,
    parameter,
    setSelectedList,
    setAddList,
    setDeleteIdList,
  } = useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
    (state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
      parameter: state.parameter,
      selectedList: state.selectedList,
      setSelectedList: state.setSelectedList,
      setAddList: state.setAddList,
      setDeleteIdList: state.setDeleteIdList,
      setParameter: state.setParameter,
    }),
    shallow
  );
  const { data: list, isLoading } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository.usePersonListGet(
      parameter,
      menuId
    );
  const { run: onCreate, setCallback } =
    projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalRepository.useCreate(menuId);
  const handleClose = useCallback(() => {
    setIsOpen(false);
    setSelectedList([]);
    setAddList([]);
    setDeleteIdList([]);
    setParameter({
      keyword: '',
      keywordType: ProjectSalesInfoAffiliatedCompanyPersonSearchKeywordType.AFFILIATED_COMPANY_NAME,
    });
  }, [setIsOpen, setSelectedList, setAddList, setDeleteIdList, setParameter]);
  setCallback({
    onSuccess: () => {
      handleClose();
    },
  });
  const d = {
    list,
  };
  const h = {
    onClose: handleClose,
    onCreate,
  };
  return {
    d,
    h,
    isOpen,
    isLoading,
  };
};
