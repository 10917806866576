import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';
import { adminManageableVariableRepository } from '@front/src/features/manageable-variable/repository';
import { projectSalesInfoContractConfirmedRepository } from '@front/src/features/project-sales-info/features/contract/features/confirmed/repository';
import {
  projectSalesInfoContractStatusKey,
  projectSalesInfoContractStepKey,
} from '@front/src/features/project-sales-info/features/contract/utils/constant';

export { useLogic as useProjectSalesInfoContractConfirmedLogic };

const useLogic = (menuId) => {
  const { id } = useProjectSalesInfoDetailState((state) => ({ id: state.id }), shallow);
  const { data: detail, isLoading: isDetailLoading } =
    projectSalesInfoContractConfirmedRepository.useDetailGet(id, menuId);
  const { run: onUpdate } = projectSalesInfoContractConfirmedRepository.useUpdate(menuId);
  const { data: statusVariableList, isLoading: isStatusVariableLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoContractStatusKey, menuId);
  const { data: stepVariableList, isLoading: isStepVariableLoading } =
    adminManageableVariableRepository.useListGet(projectSalesInfoContractStepKey, menuId);
  const d = { detail, statusVariableList, stepVariableList };
  const h = { onUpdate };
  return {
    d,
    h,
    isLoading: isDetailLoading || isStatusVariableLoading || isStepVariableLoading,
  };
};
