import { createContext } from 'react';
import { noOp } from '@front/common/contants';

import type { DefaultFunction, UserRecordView } from '@front/src/types';
import type { AffiliatedCompanyMeetingHistoryUpdateParameter } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/parameter';
import type { AffiliatedCompanyMeetingHistoryView } from '@front/src/features/affiliated-company/features/meeting-history/features/modal/types/view';

export { Context as AffiliatedCompanyMeetingHistoryModalContext };

interface State {
  d: {
    detail?: AffiliatedCompanyMeetingHistoryView;
    userList?: UserRecordView[];
    status?: string;
  };
  h: {
    onMeetingHistoryCreate: (params: AffiliatedCompanyMeetingHistoryUpdateParameter) => void;
    onMeetingHistoryUpdate: (params: AffiliatedCompanyMeetingHistoryUpdateParameter) => void;
    onCreateModalClose: DefaultFunction;
    onUpdateModalClose: DefaultFunction;
    setUserIdList: (list: number[]) => void;
  };
  isUpdateModalOpen: boolean;
  id?: number;
  userIdList: number[];
}

const Context = createContext<State>({
  d: {},
  h: {
    onMeetingHistoryCreate: noOp,
    onMeetingHistoryUpdate: noOp,
    onCreateModalClose: noOp,
    onUpdateModalClose: noOp,
    setUserIdList: noOp,
  },
  isUpdateModalOpen: false,
  userIdList: [],
});
