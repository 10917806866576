import React from 'react';
import { useFormContext } from 'react-hook-form';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import WorkCarouselCreateFeature from '@front/src/features/work/features/work/features/carousel/features/create';
import type { WorkCarouselSectionProps } from '@front/src/features/work/features/work/features/carousel';

export default function WorkCarouselCreateModal(props: Readonly<WorkCarouselSectionProps>) {
  const { watch, setValue } = useFormContext();
  const open = watch('openCreateModal');
  const onClose = () => {
    setValue('openCreateModal', false);
  };
  return (
    <ModalUI
      title="이미지 슬라이드 추가"
      open={open}
      onClose={onClose}
    >
      <WorkCarouselCreateFeature
        {...props}
        onClose={onClose}
      />
    </ModalUI>
  );
}
