import React from 'react';
import { useProjectSalesInfoCMPaymentHistoryState } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/widgets/useState';
import { ProjectSalesInfoCMPaymentHistoryUpdateModalWidget } from '@front/src/features/project-sales-info/features/collection/features/CM-payment-history/features/update-modal/widgets/widget';

export { ModalWrapper as ProjectSalesInfoCMPaymentHistoryUpdateModalWrapper };
interface Props {
  menuId?: number;
}
const ModalWrapper = ({ menuId }: Props) => {
  const isUpdateModalOpen = useProjectSalesInfoCMPaymentHistoryState(
    (state) => state.isUpdateModalOpen
  );
  return (
    <>
      {isUpdateModalOpen && <ProjectSalesInfoCMPaymentHistoryUpdateModalWidget menuId={menuId} />}
    </>
  );
};
