import React from 'react';
import ContextMenuNewIconUI from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import type { WorkCarouselBundleView } from '@front/src/features/work/features/work/features/carousel-bundle/types/view';
import { useCustomDialog } from '@front/src/features/dialog';
import workCarouselBundleRepository from '@front/src/features/work/features/work/features/carousel-bundle/repository';
import workCarouselMutation from '@front/src/features/work/features/work/features/carousel/query/mutation';
import workCarouselBundleMutation from '@front/src/features/work/features/work/features/carousel-bundle/query/mutation';
import { ArithmeticOperator } from '@front/src/features/work/features/work/types/parameter';

interface Props extends SectionComponentProps {
  item: WorkCarouselBundleView;
  index: number;
}

export default function WorkCarouselContextMenu(props: Readonly<Props>) {
  const { sectionId, dataId, menuId, item } = props;

  const { prompt, confirm } = useCustomDialog();

  const commonParams = {
    sectionId,
    dataId,
    menuId,
  };

  const { run: onCreate } = workCarouselBundleRepository.useCreate(commonParams);

  const { run: onUpdate } = workCarouselBundleRepository.useUpdate(commonParams);

  const { run: onDelete } = workCarouselBundleRepository.useDelete(commonParams);

  const onMove = workCarouselBundleMutation.useMove(commonParams);

  const { mutate: onDeleteAll } = workCarouselMutation.useDeleteAll(commonParams);

  const onClickCreate = () => {
    prompt({
      defaultValue: '',
      title: '슬라이드 추가',
      promptText: '확인',
      closeText: '취소',
      multiline: false,
      maxLength: 500,
      rows: 10,
      afterPrompt: (value) => {
        onCreate({
          name: value,
        });
      },
    });
  };

  const onClickUpdate = () => {
    prompt({
      defaultValue: item.name,
      title: '슬라이드명 수정',
      promptText: '확인',
      closeText: '취소',
      multiline: false,
      maxLength: 500,
      rows: 10,
      afterPrompt: (value) => {
        onUpdate({
          id: item.id,
          name: value,
        });
      },
    });
  };

  const onClickDelete = () => {
    confirm({
      lineBreakChildren: [
        {
          value: '슬라이드를 삭제하시겠습니까?',
        },
      ],
      title: '슬라이드 삭제',
      closeText: '취소',
      afterConfirm: () => {
        onDelete(item.id);
      },
    });
  };

  const onClickDeleteAll = () => {
    confirm({
      lineBreakChildren: [
        {
          value: '슬라이드 전체를 삭제하시겠습니까?',
        },
      ],
      title: '슬라이드 전체 삭제',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll(item.id);
      },
    });
  };

  const handleMove = (operator: ArithmeticOperator) => {
    onMove({
      id: item.id,
      operator,
    });
  };

  const itemList = [
    {
      text: '슬라이드 추가',
      action: onClickCreate,
      IconComponent: <FontAwesomeIcon icon="plus" />,
    },
    {
      text: '슬라이드 수정',
      action: onClickUpdate,
      IconComponent: <FontAwesomeIcon icon="pen" />,
    },
    {
      text: '슬라이드 이동(위)',
      action: () => handleMove(ArithmeticOperator.SUBTRACTION),
      IconComponent: <FontAwesomeIcon icon="arrow-up-long" />,
    },
    {
      text: '슬라이드 이동(아래)',
      action: () => handleMove(ArithmeticOperator.ADDITION),
      IconComponent: <FontAwesomeIcon icon="arrow-down-long" />,
      split: true,
    },
    {
      text: '슬라이드 삭제',
      action: onClickDelete,
      IconComponent: (
        <FontAwesomeIcon
          icon="trash"
          className="cs-delete-color"
        />
      ),
    },
    {
      text: '전체 삭제',
      action: onClickDeleteAll,
      IconComponent: (
        <FontAwesomeIcon
          icon="trash"
          className="cs-delete-color"
        />
      ),
    },
  ];

  return <ContextMenuNewIconUI itemList={itemList} />;
}
