import workBasicInfoMutation from '@front/src/features/work/features/work/features/basic-info/query/mutation';
import useWorkSearchWordCreate from '@front/src/features/work/features/work/features/basic-info/repository/useSearchWordCreate';

const useWorkSearchWordMutation = ({ dataId, menuId, sectionId }) => {
  const commonParams = { dataId, menuId, sectionId };
  const { run: onCreate, setCallback } = useWorkSearchWordCreate(commonParams);
  const { mutate: onDelete } = workBasicInfoMutation.useSearchWordDelete(commonParams);
  return {
    onCreate,
    onDelete,
    setCreateCallback: setCallback,
  };
};

export default useWorkSearchWordMutation;
