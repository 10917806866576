import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoAffiliatedCompanyWebHardCreateModalForm } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/components/modal-form';
import { useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/create-modal/widgets/useState';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Modal as ProjectSalesInfoAffiliatedCompanyWebHardCreateModal };

const Modal = () => {
  const { isOpen, setIsOpen } = useProjectSalesInfoAffiliatedCompanyWebHardCreateModalState(
    useShallow((state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
    }))
  );
  const handleCloseClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="웹하드 추가"
      open={isOpen}
      onClose={handleCloseClick}
    >
      <ProjectSalesInfoAffiliatedCompanyWebHardCreateModalForm />
    </ModalLayout>
  );
};
