import React, { memo } from 'react';
import { Badge, Box } from '@mui/material';

import TextBox from '@front/layouts/Text';
import type { DefaultFunction } from '@front/type/Function';
import UserIcon from '@front/layouts/UserIcon';
import type { UserId } from '@front/user/domain';
import type { CampaignEstimatorVO } from '@front/ost_campaign/domain';
import type { SectionActionProp } from '@front/components/Section';
import Section from '@front/components/Section';
import { styled } from '@mui/material/styles';

interface Props {
  estimatorList: CampaignEstimatorVO[];
  onAdd: DefaultFunction;
  onDelete: (id: UserId) => () => void;
}

const CampaignEstimatorForm = ({ estimatorList, onAdd, onDelete }: Props) => {
  const actions: SectionActionProp[] = [
    {
      name: '추가',
      onClick: onAdd,
    },
  ];

  return (
    <Section
      title="심사 담당자"
      actions={actions}
      minHeight={70}
    >
      {estimatorList.length === 0 && <NoRegisteredEstimators />}
      {estimatorList.length > 0 && (
        <EstimatorItemContainer>
          {estimatorList.map((estimator) => (
            <EstimatorItem
              key={estimator.id}
              onClick={onDelete(estimator.id)}
            >
              <EstimatorBadge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                badgeContent="X"
                color="error"
                overlap="rectangular"
              >
                <UserIcon
                  user={estimator.estimator}
                  sx={{
                    marginRight: '10px',
                  }}
                />
              </EstimatorBadge>
              <TextBox
                variant="body2"
                sx={{
                  marginRight: '10px',
                }}
              >
                {estimator.estimator.name}
              </TextBox>
              <TextBox variant="body2">{estimator.estimator.department.name}</TextBox>
            </EstimatorItem>
          ))}
        </EstimatorItemContainer>
      )}
    </Section>
  );
};

const EstimatorItemContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  columnGap: '30px',
  rowGap: '20px',
  alignItems: 'start',
});

const EstimatorItem = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr 4fr',
  cursor: 'pointer',
});

const EstimatorBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    fontSize: '0.6rem',
    height: '0.7rem',
    minWidth: '0.7rem',
    padding: '0.3rem',
  },
});

const NoRegisteredEstimators = () => (
  <Box
    sx={{
      display: 'grid',
      placeItems: 'center',
    }}
  >
    <TextBox variant="body9">심사 담당자가 없습니다.</TextBox>
  </Box>
);

const EstimatorForm = memo(CampaignEstimatorForm);

export default EstimatorForm;
