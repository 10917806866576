import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import type { RootState } from '@front/services/reducer';
import ProposalStateForm from '@front/ost_campaign/view/detail/state';
import ModalAddProposalEstimatorService from '@front/ost_campaign/service/modal/addProposalEstimatorService';

const ProposalStateService = () => {
  const isOpenModal = useSelector(
    (root: RootState) => root.campaign.addProposalEstimatorModalOpen,
    shallowEqual
  );

  return (
    <>
      {isOpenModal && <ModalAddProposalEstimatorService />}
      <ProposalStateForm />
    </>
  );
};

const ProposalStateFormService = memo(ProposalStateService);

export default ProposalStateFormService;
