// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rAeTCSB7J3hPD8FkCh4V{display:flex;justify-content:space-between;width:70%}`, "",{"version":3,"sources":["webpack://./front/src/features/affiliated-company/features/meeting-history/components/meeting-history-table-row.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,SAAA","sourcesContent":[".th {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width: 70%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": `rAeTCSB7J3hPD8FkCh4V`
};
export default ___CSS_LOADER_EXPORT___;
