import React from 'react';
import { MenuItem } from '@mui/material';
import { default as MuiSelect } from '@front/layouts/Select';

export interface OptionType<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface Props<T = string> {
  defaultLabel?: string;
  value: T;
  onChange: (e) => void;
  optionList: OptionType<T>[];
  variant?: 'standard' | 'outlined' | 'filled' | 'transparent';
  className?: string;
  width?: string;
  isDefaultPossible?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  disableValue?: string;
}

export { Index as Select };
const Index = ({
  defaultLabel,
  optionList,
  width,
  isDefaultPossible,
  displayEmpty = true,
  variant = 'outlined',
  style,
  disableValue,
  readOnly,
  ...rest
}: Props) => (
  <MuiSelect
    variant={variant}
    style={{ display: 'flex', ...style }}
    displayEmpty={displayEmpty}
    readOnly={readOnly}
    MenuProps={{
      width,
    }}
    {...rest}
  >
    {defaultLabel && (
      <MenuItem
        disabled={!isDefaultPossible}
        value=""
      >
        {defaultLabel}
      </MenuItem>
    )}
    {optionList.map((item) => (
      <MenuItem
        key={item.value}
        value={item.value}
        disabled={disableValue === item.value}
      >
        {item.label}
      </MenuItem>
    ))}
  </MuiSelect>
);
