import React, { memo } from 'react';
import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Table } from '@front/layouts/Table';
import { Th } from '@front/src/components/layout/table/th';
import { ProjectSalesInfoContractHistoryTableBody } from '@front/src/features/project-sales-info/features/contract/features/history/components/contract-history-table-body';
import { useGetMaxWidthSx } from '@front/src/hooks/useGetWidth';

export { ContractHistoryTable as ProjectSalesInfoContractHistoryTable };

const ContractHistoryTable = () => (
  <TableContainer
    sx={{
      ...useGetMaxWidthSx({ hasMemo: true }),
      maxHeight: '400px',
      overflow: 'auto',
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        <ProjectSalesInfoContractHistoryTableBody />
      </TableBody>
    </Table>
  </TableContainer>
);

const TableHeader = memo(() => (
  <TableHead>
    <TableRow>
      <Th width="40px" />
      <Th width="80px">순번</Th>
      <Th width="120px">계약분류</Th>
      <Th width="120px">계약서 구분</Th>
      <Th width="200px">한글 파일</Th>
      <Th width="200px">PDF 파일</Th>
      <Th width="140px">등록일시</Th>
      <Th width="80px">등록자</Th>
    </TableRow>
  </TableHead>
));

TableHeader.displayName = 'ProjectSalesInfoContractHistoryTableHeader';
