import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { ControlPanelItem } from '@front/src/types/domain';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import useHistoryForm from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';

export { TableBody as EstimationFinalTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationHistoryView>) {
  const { item, formContext } = props;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);
  const { watch } = formContext;
  const editOpenIdList = watch('editOpenIdList');
  const isEditOpen = editOpenIdList.includes(item.id);

  const { methods } = useHistoryForm(props.item);
  const { handleSubmit } = methods;

  const contextMenuItems: ControlPanelItem[] = [
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => toggleEditOpenId(item.id),
      split: true,
      seq: 2,
    },
  ];

  const onSubmit = handleSubmit((data) => {
    console.debug(data);
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
}
