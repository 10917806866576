import { create } from 'zustand';

interface State {
  id?: number;
  setId: (id?: number) => void;
}

export { useState as useProjectSalesInfoDetailState };

const useState = create<State>((set) => ({
  setId: (id) => set(() => ({ id })),
}));
