import type { ProjectAffiliatedCompanyPersonView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/view';

export const convertToAffiliatedPersonOptionList = (data?: ProjectAffiliatedCompanyPersonView[]) =>
  data
    ?.filter((item) => item?.person)
    .map((item) => ({
      value: String(item.person?.id),
      label: item.person?.name,
    }));

export const collectionPossibilityKeyword = '영업관리-수금가능성';
