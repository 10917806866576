import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import React, { useCallback, useEffect } from 'react';
import type { CommonImageView } from '@front/src/features/work/features/work/components/ZoomImageButton';
import { imageDrawAction } from '@front/src/features/image-draw/reducer/reducer';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import ImageDrawModalFeature from '@front/src/features/image-draw';
import type { Dispatch } from 'redux';
import type { AnyAction } from 'redux-saga';
import { drawingApi } from '@front/src/features/image-draw/api/api';
import { hasImageExtension } from '@front/src/utils';
import type { AlertView } from '@front/src/features/dialog/types';
import useImageDraw from "@front/src/features/image-draw/hooks/useImageDraw";

export function ImageDrawProvider() {
  const { close: closeImageDraw } = useImageDraw();
  const user = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const { fileItem, isLoading } = useSelector((root: RootState) => root.imageDraw);
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState<CommonImageView | undefined>(undefined);

  useEffect(() => {
    if (fileItem) {
      setItem({
        createdAt: fileItem.createdAt,
        fileItem: { ...fileItem },
        user: {
          id: user!.id,
          name: user!.name,
        },
      });
      setOpen(true);
    }
  }, [fileItem, user]);

  const onClose = useCallback(() => {
    setOpen(false);
    closeImageDraw();
    setItem(undefined);
  }, []);

  return (
    <>
      {isLoading && (
        <div
          style={{
            width: '100dvw',
            height: '100dvh',
            position: 'fixed',
            zIndex: 9999,
            display: 'flex',
            backgroundColor: 'rgba(0,0,0,0.1)',
            justifyContent: 'center',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
      {open && item && (
        <ImageDrawModalFeature
          item={item}
          open={open}
          onClose={onClose}
        />
      )}
    </>
  );
}

/**
 * 이미지 파일 클릭(to download) 이벤트에 대한 이미지 첨삭 구동 callback
 * @param event MouseEvent
 * @param dispatcher Redux action dispatcher
 * @param alert
 * @remarks useGlobalClickInterceptor 통해 App 전체 클릭 이벤트에 개입하여 활용
 */
export const imageDrawInitiatingCallbackForClickEvent = async (
  event: MouseEvent,
  dispatcher: Dispatch<AnyAction>,
  alert: (props: AlertView) => void
) => {
  const target = event.target as HTMLElement;
  if (target instanceof HTMLAnchorElement) {
    if (target.href.includes(`/api/file-item?fileKey=`)) {
      const urlParams = new URL(target.href).searchParams;
      const fileKey = urlParams.get('fileKey');
      if (fileKey) {
        event.preventDefault();
        dispatcher(imageDrawAction.setImageDrawIsLoading(true));

        drawingApi
          .getFileItemDataByFileKey(fileKey)
          .then((fileItem) => {
            if (fileItem && hasImageExtension(fileItem.ext)) {
              dispatcher(
                imageDrawAction.setImageFileItem({
                  fileItem,
                  fileKey,
                })
              );
            } else {
              document.location = target.href;
            }
          })
          .catch((e) => {
            const { message } = e.response.data;
            alert({
              children: message.map((m) => ({
                value: m,
              })),
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatcher(imageDrawAction.setImageDrawIsLoading(false));
            }, 300);
          });
      }
    }
  }
};
