import { shallow } from 'zustand/shallow';
import { useAffiliatedCompanyDetailState } from '@front/src/features/affiliated-company/widgets/detail/useState';
import { affiliatedCompanyPersonRepository } from '@front/src/features/affiliated-company/features/person/repository/repository';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';

export { useLogic as useAffiliatedCompanyPersonLogic };

const useLogic = (menuId) => {
  const { id } = useAffiliatedCompanyDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const {
    isDetailModalOpen,
    isCreateModalOpen,
    setCreateModalIsOpen,
    setDetailModalIsOpen,
    setModalId,
    modalId,
  } = useAffiliatedCompanyPersonState(
    (state) => ({
      isDetailModalOpen: state.isDetailModalOpen,
      isCreateModalOpen: state.isCreateModalOpen,
      setCreateModalIsOpen: state.setCreateModalIsOpen,
      setDetailModalIsOpen: state.setDetailModalIsOpen,
      setModalId: state.setModalId,
      modalId: state.modalId,
    }),
    shallow
  );
  const { data: personList, isLoading } = affiliatedCompanyPersonRepository.usePersonListGet(
    id,
    menuId
  );
  const { run: onCreatePerson, setCallback } =
    affiliatedCompanyPersonRepository.usePersonCreate(menuId);
  setCallback({
    onSuccess: () => {
      setCreateModalIsOpen(false);
    },
  });
  const d = { personList };
  const h = {
    onCreatePerson: (params) => onCreatePerson({ ...params, id }),
    onCreateModalClose: (callback?) => {
      setCreateModalIsOpen(false);
      callback?.();
    },
    onCreateModalOpen: () => setCreateModalIsOpen(true),
    onDetailModalOpen: (modalId: number) => {
      setModalId(modalId);
      setDetailModalIsOpen(true);
    },
  };
  return { d, h, isLoading, isCreateModalOpen, isDetailModalOpen, modalId };
};
