import axios from 'axios';
import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import { toFormData } from '@front/services/api';
import type { ProjectEstimationCompetitorParameter } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

export { api as projectSalesInfoEstimationCompetitorCreateModalApi };
const url = {
  create: (id?) => `${projectSalesInfoURL}/${id}/estimation-competitor`,
};

const api = {
  create: async (params: ProjectEstimationCompetitorParameter, menuId) => {
    await axios.post(url.create(params.id), toFormData(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
