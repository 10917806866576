import { useForm } from 'react-hook-form';

const useWorkSearchWordForm = ({ onCreate }) => {
  const methods = useForm<TFormValues>({
    defaultValues: {
      searchWord: '',
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    if (data.searchWord.trim() === '') {
      return;
    }
    onCreate({
      searchWord: data.searchWord,
    });
  });
  return {
    methods,
    onSubmit,
  };
};

export default useWorkSearchWordForm;

interface TFormValues {
  searchWord: string;
}
