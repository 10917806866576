import React, {useCallback, useState} from 'react';
import {TabMain2ItemUI, TabMain2UI,} from '@front/src/components/components-with-design/component/TabUI';
import {Box} from '@mui/material';
import {ClientShortView} from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

/**
 * 모달내 발주처 탭 구성 용도
 */
interface ClientTabsProps {
  id: number;
  clients: ClientShortView[];
  onClientSelected: (clientId: number) => void;
}

export default function ClientTabs({ id, clients, onClientSelected }: ClientTabsProps) {
  const [clientId, setClientId] = useState<number>(id);

  const onChange = useCallback((event, value) => {
    setClientId(value);
    onClientSelected(value);
  }, []);

  return (
    <Box sx={{ backgroundColor: '#d8dce2', padding: '1rem 0 0 0' }}>
      <TabMain2UI
        value={clientId}
        onChange={onChange}
      >
        {clients.map((client) => (
          <TabMain2ItemUI
            key={client.id}
            value={client.id}
            label={client.name}
          />
        ))}
      </TabMain2UI>
    </Box>
  );
}
