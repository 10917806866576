import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkNameUpdateParameter } from '@front/src/features/work/features/work/features/name/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workNameMutation = {
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: WorkNameUpdateParameter) => workWorkApi.updateName(params, commonParams),
      mutationKey: ['work', 'name', 'update'],
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'aside'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'name'],
        });
      },
    });
    return { mutate, isLoading };
  },
};

export default workNameMutation;
