import React, { useCallback, useEffect, useMemo } from 'react';
import EditorComponent from '@front/components/TextEditor/EditorComponent';
import Section from '@front/components/Section';
import type {
  CampaignBannerUpdateContentParameter,
  CampaignBannerUpdateIsExposedParameter,
} from '@front/ost_campaign/parameter';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Checkbox from '@front/layouts/Checkbox';
import { Box } from '@mui/material';
import { campaignAction } from '@front/ost_campaign/action';
import { FilePaths } from '@front/common/filePath';

interface Props {
  onUpdateContent: (parameter: CampaignBannerUpdateContentParameter) => void;
  onUpdateIsExposed: (parameter: CampaignBannerUpdateIsExposedParameter) => void;
}

const BannerForm = ({ onUpdateContent, onUpdateIsExposed }: Props) => {
  const {
    id,
    content: beforeContent,
    isExposed,
  } = useSelector((root: RootState) => root.campaign.detail!.campaignBanner, shallowEqual);
  const { loginUser, manager, campaignId } = useSelector(
    (root: RootState) => ({
      loginUser: root.login.detail,
      manager: root.campaign.detail?.manager,
      campaignId: root.campaign.detail?.id,
    }),
    shallowEqual
  );
  const value = useMemo(
    () => ({
      id,
      beforeContent,
    }),
    [id, beforeContent]
  );

  const onChange = useCallback(
    (e) => {
      onUpdateIsExposed({
        isExposed: e.target.checked,
      });
    },
    [onUpdateIsExposed]
  );

  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(campaignAction.setDetail(undefined));
    },
    []
  );

  return (
    <Section title="캠페인 안내">
      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <DataFieldWithLabel
          label="노출여부"
          labelSX={{ minWidth: '80px' }}
        >
          <Checkbox
            value={isExposed ?? false}
            checked={isExposed ?? false}
            onChange={onChange}
          />
        </DataFieldWithLabel>
      </Box>
      {loginUser && manager && (
        <EditorComponent
          value={value}
          update={onUpdateContent}
          readOnly={loginUser?.id !== manager?.id}
          dataId={campaignId}
          filePath={FilePaths.CAMPAIGN}
        />
      )}
    </Section>
  );
};

export default BannerForm;
