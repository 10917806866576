import React, { useCallback, useContext } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import type { AffiliatedCompanyCharacterPartnerCompanyView } from '@front/src/features/affiliated-company/features/character/features/partner-company/types/view';
import { AffiliatedCompanyCharacterPartnerCompanyContext } from '@front/src/features/affiliated-company/features/character/features/partner-company/widgets/context';
import { useAffiliatedCompanyCharacterState } from '@front/src/features/affiliated-company/features/character/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { PartnerCompanyTableRowSelector as AffiliatedCompanyCharacterPartnerCompanyTableRowSelector };

interface Props {
  item: AffiliatedCompanyCharacterPartnerCompanyView;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

const PartnerCompanyTableRowSelector = ({ item, onSubmit }: Props) => {
  const { id } = useContext(AffiliatedCompanyCharacterPartnerCompanyContext);
  const { setValue } = useFormContext();
  const handleSelector = useCallback(
    async (company, person) => {
      setValue('affiliatedCompanyId', company?.id);
      setValue('affiliatedCompanyPersonId', person?.id);
      await onSubmit();
    },
    [onSubmit, setValue]
  );
  const { readOnly } = useAffiliatedCompanyCharacterState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      value={{
        companyId: item.partnerCompanyId ?? undefined,
        personId: item.partnerCompanyPerson?.id ?? undefined,
        textValue: `${item.name ?? ''}`,
      }}
      onPersonSelect={handleSelector}
      usePersonSelector
      exclude={[id!]}
      disabled={readOnly}
    />
  );
};
