import { AccountingUploadEcountDataHeader } from '@front/src/features/accounting/features/upload/features/ecount-data/components/layouts/header';
import { AccountingUploadEcountDataBody } from '@front/src/features/accounting/features/upload/features/ecount-data/components/layouts/body';
import { AccountingUploadEcountDataLayout } from '@front/src/features/accounting/features/upload/features/ecount-data/components/layouts/layout';

export { index as accountingUploadEcountData };
const index = {
  Layout: AccountingUploadEcountDataLayout,
  Header: AccountingUploadEcountDataHeader,
  Body: AccountingUploadEcountDataBody,
};
