import React from 'react';
import { Box } from '@mui/material';
import { PersonalSettingsModalUISettingsModalSettingsModalStatusTable } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/ModalStatusTable';

export { ModalStatus as PersonalSettingsModalUISettingsModalSettingsModalStatus };
interface Props {
  authMenuId: number | undefined;
}
const ModalStatus = ({ authMenuId }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    }}
  >
    <Box>모달 현황</Box>
    <PersonalSettingsModalUISettingsModalSettingsModalStatusTable authMenuId={authMenuId} />
  </Box>
);
