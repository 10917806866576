import React, { useEffect } from 'react';
import type { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import { useOutletContext, useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
import { ProjectSalesPageHeaderTabs } from '@front/src/features/project-sales/features/page/components/header-tabs';
import '@front/src/components/components-with-design/common-styles.css';
import PersonalSettingsFeature from '@front/src/features/personal-settings-modal';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import ProjectStatusTable from '@front/src/features/project-sales/features/page/components/project-status-table';
import './index.scss';
import projectSalesListQuery from '@front/src/features/project-sales/query/query';

export default function ProjectSalesPageFeature() {
  const parentOutletContext = useOutletContext<ProjectSalesCommonFeatureProps>();
  const { menuId } = parentOutletContext;
  const { id } = useParams<{ id: string }>();
  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;
  const { data: projectSales } = projectSalesListQuery.useOne(+id!, { menuId });

  useEffect(() => {
    sessionStorage.setItem('dataId', id ?? '');
  }, [id]);

  return (
    <>
      <div className="page-header">
        <div className="brief-project-information">
          <h1>{projectSales?.name}</h1>
          <ProjectStatusTable />
        </div>
        <ProjectSalesPageHeaderTabs
          children={
            <div
              style={{
                position: 'absolute',
                right: 15,
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
            </div>
          }
        />
      </div>
      <div
        className="cs-page-container"
        style={{ height: 'calc(100vh - 115px)' }}
      >
        <Outlet context={parentOutletContext} />
      </div>
    </>
  );
}
