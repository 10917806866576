import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';
import type { ProjectContractConfirmedUpdateParameter } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/parameter';

export { context as ProjectSalesInfoContractConfirmedContext };

interface State {
  d: {
    detail?: ProjectContractConfirmedView;
    statusVariableList?: VariableInformationView[];
    stepVariableList?: VariableInformationView[];
  };
  h: {
    onUpdate: (params: ProjectContractConfirmedUpdateParameter) => void;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    onUpdate: noOp,
  },
});
