import type { CurrentPage } from '@front/navigate/domain';
import { TargetPage } from '@front/navigate/domain';
import {
  arrangementPath,
  evaluationPath,
  proposalPath,
  votePath,
} from '@front/navigate/constant/ConstantPath';

export const getTargetPage = (path: string): TargetPage => {
  if (path.startsWith(votePath)) {
    return TargetPage.VOTE;
  }
  if (path.startsWith(proposalPath)) {
    return TargetPage.PROPOSAL;
  }
  if (path.startsWith(evaluationPath)) {
    return TargetPage.EVALUATION;
  }
  if (path.startsWith(arrangementPath)) {
    return TargetPage.ARRANGEMENT;
  }
  return TargetPage.PROPOSAL;
};

export const getCurrentPageParams = (path: string): CurrentPage => {
  const targetPage = getTargetPage(path);
  return { path, targetPage };
};
