import React from 'react';
import UIBuilderTableBody, { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import AccountStatusWidgetDetailTableRow
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/widgets/detail-table/row';


type Props = Readonly<UIBuilderTableBodyProps>;
type ElementType = (props: Props) => React.ReactElement<Props, typeof UIBuilderTableBody>;


const AccountStatusWidgetDetailTableBody: ElementType = (props) => {
  return (
    <UIBuilderTableBody
      {...props}
      RowComponent={AccountStatusWidgetDetailTableRow}
    />
  );
};

export default AccountStatusWidgetDetailTableBody;
