import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { ListDrawer } from '@front/src/components/list-drawer/components/list-drawer';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';
import { useShallow } from 'zustand/react/shallow';
import { ListDrawerClosedChildren } from '@front/src/components/list-drawer/components/closed-children';

export { Index as ListDrawerFeature };

interface Props {
  children: ReactNode;
}

const Index = ({ children }: Props) => {
  const { open, setOpen } = useListDrawerState(
    useShallow((state) => ({
      open: state.open,
      setOpen: state.setOpen,
    }))
  );
  useEffect(
    () => () => {
      setOpen(true);
    },
    [setOpen]
  );
  return (
    <ListDrawer
      open={open}
      closedWidth={40}
      closedChildren={<ListDrawerClosedChildren />}
    >
      {children}
    </ListDrawer>
  );
};
