import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import WorkApprovalSection from '@front/src/features/work/features/work/features/approval/components/Section';

export default function WorkApprovalFeature({ ...props }: Readonly<SectionComponentProps>) {
  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <WorkApprovalSection {...props} />
    </Suspense>
  );
}
