import React from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput, { type OutlinedInputProps } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { ColorPalette } from '@front/assets/theme';

interface Props extends Omit<OutlinedInputProps, 'required'> {
  multiline?: boolean;
  maxLength?: number;
}

const TextFieldBasicUI = (props: Props) => {
  const { error, disabled, sx = {}, multiline, maxLength, type = 'text', rows, ...rest } = props;
  return (
    <FormControl
      error={error}
      sx={{
        width: '100%',
        ...sx,
      }}
    >
      <OutlinedInput
        {...rest}
        rows={rows}
        type={type}
        multiline={multiline}
        disabled={disabled}
        inputProps={{
          sx: {
            height: '32px',
            padding: '6px 10px',
            boxSizing: 'border-box',
            '&::placeholder': {
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16.71px',
              textAlign: 'left',
              color: ColorPalette.greyscale.text_quaternary,
              opacity: '1',
            },
          },
          maxLength,
        }}
        sx={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height: '32px',
          padding: multiline ? '0' : '',
          fontSize: '1.4rem',
          fontWeight: '400',
          lineHeight: 'normal',
          color: ColorPalette.greyscale.text_primary,
          backgroundColor: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
          borderRadius: '5px',
          gap: '10px',

          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&:active .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: disabled
              ? `1px solid ${ColorPalette.line.line02}`
              : `1px solid ${ColorPalette.main.main_hover}`,

            '&:focus-visible': {
              border: `none`,
              outline: `2px solid ${ColorPalette.sub.sub_primary}`,
              outlineOffset: '-2px',
            },
          },

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },

          '& .MuiInputAdornment-root': {
            width: '24px',
            height: '24px',
            marginLeft: '0',
          },
          '& .MuiIconButton-root': {
            padding: '0px',
          },
        }}
        {...rest}
      />
      {error && (
        <FormHelperText
          sx={{
            fontSize: '1.2rem',
            color: ColorPalette.sub2.sub2_primary,
            margin: '2px 0 0 0',
          }}
        >
          Error
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TextFieldBasicUI;
