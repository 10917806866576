import React, { useState } from 'react';
import AsideWrapUI from '@front/src/components/components-with-design/layout/AsideWrap';
import Box from '@mui/material/Box';
import List from '@front/src/features/project-sales/features/aside/components/list';
import ProjectSalesAsideSearchForm from '@front/src/features/project-sales/features/aside/components/search';
import { FormProvider, useForm } from 'react-hook-form';
import { ProjectSalesSearchParameter } from '@front/src/features/project-sales/features/aside/type/parameters';
import ProjectSalesCreateButtonFeature from '@front/src/features/project-sales/features/aside/features/create-button';

interface Props {
  menuId: number;
}

export default function ProjectSalesAsideFeature(props: Props) {
  const [parameter, setParameter] = useState<ProjectSalesSearchParameter>({
    codeOrName: '',
    searchWordOrPurpose: '',
  });
  const methods = useForm({
    defaultValues: {
      codeOrName: '',
      searchWordOrPurpose: '',
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter(data as ProjectSalesSearchParameter);
  });

  return (
    <FormProvider {...methods}>
      <AsideWrapUI>
        <Box
          sx={{
            width: '100%',
            minWidth: '330px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            p: '16px',
          }}
        >
          <ProjectSalesCreateButtonFeature menuId={props.menuId} />
          <ProjectSalesAsideSearchForm onSubmit={onSubmit} />
          <List
            menuId={props.menuId}
            parameter={parameter}
          />
        </Box>
      </AsideWrapUI>
    </FormProvider>
  );
}
