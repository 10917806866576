import { createContext } from 'react';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { context as SalesInfoActivityListContext };

interface State {
  categoryList?: VariableInformationView[];
}

const context = createContext<State>({});
