import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/components/layout/add-delete-table/text.module.scss';

export { Text as AddDeleteTableText };

interface Props {
  children: ReactNode;
}

const Text = ({ children }: Props) => <div className={classes.container}>{children}</div>;
