import { default as axios } from '@front/src/config/axios';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getPersonList: (id?) => `${baseUrl}/${id}/person`,
  createPerson: (id?) => `${baseUrl}/${id}/person`,
};

export const affiliatedCompanyPersonApi = {
  getPersonList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getPersonList(id), {
      params: {
        isIncludedResignedPerson: true,
      },
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createPerson: async (params, menuId) => {
    const { data } = await axios.post(url.createPerson(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
