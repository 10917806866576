import React from 'react';
import { EstimationComparisonTableHead } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/components/table-head';
import { EstimationComparisonTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/components/table-body';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

import estimationComparisonQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/query/query';

export { Table as EstimationComparisonTable };

interface TableProps {
  sectionProps: SectionComponentProps;
}

function Table(props: TableProps) {
  const { sectionProps } = props;
  const { dataId, sectionId, menuId } = sectionProps;

  const list = estimationComparisonQuery.useList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...sectionProps}
      HeadComponent={EstimationComparisonTableHead}
      BodyComponent={EstimationComparisonTableBody}
      readOnly={false}
      list={list}
    />
  );
}
