import React from 'react';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import WorkApprovalTableRow from '@front/src/features/work/features/work/features/approval/components/TableRow';
import type { WorkApprovalDocumentView } from '@front/src/features/work/features/work/features/approval/types/view';

export default function WorkApprovalTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { sectionId, dataId, menuId } = props;
  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UIBuilderTableBody<WorkApprovalDocumentView>
      {...props}
      list={data?.approvalDocumentList}
      RowComponent={WorkApprovalTableRow}
    />
  );
}
