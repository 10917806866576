import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyOutlineAddressQuery } from '@front/src/features/affiliated-company/features/outline/features/address/query/query';
import { affiliatedCompanyOutlineAddressMutation } from '@front/src/features/affiliated-company/features/outline/features/address/query/mutation';

export const affiliatedCompanyOutlineAddressRepository = {
  useOutlineAddressGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyOutlineAddressQuery.useOutlineAddressGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useOutlineAddressCreate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineAddressMutation.useOutlineAddressCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useOutlineAddressDelete: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineAddressMutation.useOutlineAddressDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useOutlineAddressUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyOutlineAddressMutation.useOutlineAddressUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
