import { UseFormReturn } from 'react-hook-form';
import { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

type UseStateType = (formContext: UseFormReturn<UIBuilderTableFieldValues>) => StateReturnType;
type GetEditOpenIdListType = (id: number, editOpenIdList: number[]) => number[];
type ToggleEditOpenIdType = (id: number) => void;
type SetRecentUpdateIdType = (id: number) => void;
type ClearEditOpenIdListType = () => void;

type StateReturnType = {
  getEditOpenIdList: GetEditOpenIdListType,
  toggleEditOpenId: ToggleEditOpenIdType,
  setRecentUpdatedId: SetRecentUpdateIdType,
  clearEditOpenIdList: ClearEditOpenIdListType,
  recentUpdatedId: number | undefined,
}

const useAccountStatusState: UseStateType = (formContext) => {
  const { getValues, setValue } = formContext;

  const editOpenIdList = getValues('editOpenIdList');
  const recentUpdatedId = getValues('recentUpdatedId');

  const getEditOpenIdList: GetEditOpenIdListType = (id, editOpenIdList) => {
    if (editOpenIdList.includes(id)) {
      return editOpenIdList.filter(originId => originId !== id);
    }
    return [...editOpenIdList, id];
  };
  const toggleEditOpenId: ToggleEditOpenIdType = (id) => {
    setValue('editOpenIdList', getEditOpenIdList(id, editOpenIdList));
  };

  const setRecentUpdatedId: SetRecentUpdateIdType = (id) => {
    setValue('recentUpdatedId', id);
  };

  const clearEditOpenIdList: ClearEditOpenIdListType = () => {
    if (getValues('editOpenIdList').length === 0) {
      return;
    }
    setValue('editOpenIdList', []);
  };

  return {
    getEditOpenIdList,
    toggleEditOpenId,
    setRecentUpdatedId,
    clearEditOpenIdList,
    recentUpdatedId,
  };
};

export default useAccountStatusState;
