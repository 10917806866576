import React, { memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Input from '@front/layouts/Input';
import { ostAction } from '@front/ost/action';
import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';

interface Props {
  name?: string;
}

const FormName = ({ name }: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const onBlur = useCallback(
    (e) => {
      const value = (e.target as HTMLInputElement).value;
      if (!value.trim()) {
        openSnackbar('캠페인 이름을 입력 하세요.');
        if (inputRef.current && name) {
          inputRef.current.value = name;
        }
        return;
      }
      dispatch(ostAction.updateName({ name: value }));
    },
    [dispatch, name, openSnackbar]
  );

  return (
    <DataFieldWithLabel
      label="캠페인명"
      labelSX={{ minWidth: '80px' }}
    >
      <Input
        key={name}
        inputRef={inputRef}
        variant="outlined"
        defaultValue={name}
        onBlur={onBlur}
      />
    </DataFieldWithLabel>
  );
};

const OstFormName = memo(FormName);

export default OstFormName;
