import React, { useEffect } from 'react';

import { accountingUploadEcountData } from '@front/src/features/accounting/features/upload/features/ecount-data/components/layouts';
import { AccountingUploadEcountDataUploadWidget } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/widget';
import { AccountingUploadEcountDataTable } from '@front/src/features/accounting/features/upload/features/ecount-data/components/table';
import { AccountingUploadEcountDataListWidget } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/list/widget';
import { AccountingUploadEcountDataProvider } from '@front/src/features/accounting/features/upload/features/ecount-data/provider/provider';
import type { ECOUNT_FILE_TYPES } from '@front/src/features/accounting/features/upload/features/ecount-data/types/domain';
import { AccountingUploadEcountDataDownloadTemplateButton } from '@front/src/features/accounting/features/upload/features/ecount-data/components/download-template-button';
import { useAccountingUploadEcountDataState } from '@front/src/features/accounting/features/upload/features/ecount-data/useState';
import { useShallow } from 'zustand/react/shallow';

export { Index as AccountingUploadEcountDataFeature };

interface Props {
  type?: ECOUNT_FILE_TYPES;
  isModalMode?: boolean;
  readOnly?: boolean;
  menuId?: number;
}

const Index = ({ type, isModalMode = false, readOnly, menuId }: Props) => {
  const { Layout, Header, Body } = accountingUploadEcountData;
  const { setReadOnly } = useAccountingUploadEcountDataState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  return (
    <AccountingUploadEcountDataProvider
      type={type}
      isModalMode={isModalMode}
    >
      <Layout>
        <Header>
          <AccountingUploadEcountDataUploadWidget menuId={menuId} />
          <AccountingUploadEcountDataDownloadTemplateButton />
        </Header>
        <Body>
          <AccountingUploadEcountDataTable>
            <AccountingUploadEcountDataListWidget menuId={menuId} />
          </AccountingUploadEcountDataTable>
        </Body>
      </Layout>
    </AccountingUploadEcountDataProvider>
  );
};
