import { useQuery } from 'react-query';
import { AccountingSettingsBankAccountApi } from '@front/src/features/accounting/features/settings/features/bank-account/query/api';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';

export { Query as AccountingSettingsBankAccountQuery };

const Query = {
  useGetList: (menuId) => {
    const { data, isLoading } = useQuery<BankAccountView[]>({
      queryFn: () => AccountingSettingsBankAccountApi.getList(menuId),
      queryKey: ['accounting', 'settings', 'bank-account', 'list'],
      suspense: true,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
