import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { ProjectSalesInfoSubjectReviewShapeRatioListItemContext } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/widgets/context';
import { useProjectSalesInfoSubjectReviewShapeRatioListItemLogic } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/features/list/features/list-item/widgets/useLogic';

export { Provider as ProjectSalesInfoSubjectReviewShapeRatioListItemProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onUpdate } = useProjectSalesInfoSubjectReviewShapeRatioListItemLogic(menuId);
  const value = useMemo(() => ({ onUpdate }), [onUpdate]);
  return (
    <ProjectSalesInfoSubjectReviewShapeRatioListItemContext.Provider value={value}>
      {children}
    </ProjectSalesInfoSubjectReviewShapeRatioListItemContext.Provider>
  );
};
