import React from 'react';
import type { SkeletonProps } from '@mui/material/Skeleton';
import Skeleton from '@mui/material/Skeleton';
import { ColorPalette } from '@front/assets/theme';

interface Props extends SkeletonProps {
  variant?: 'text' | 'rectangular' | 'circular'; // 추가적인 variant 옵션
}

export default function SkeletonUI({ sx = {}, variant = 'rectangular', ...rest }: Props) {
  let additionalStyles = {};

  switch (variant) {
    case 'text':
      additionalStyles = {
        width: '100%',
        height: '20px',
        transform: 'none',
        borderRadius: '5px',
      };
      break;
    case 'rectangular':
      additionalStyles = {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
      };
      break;
    case 'circular':
      additionalStyles = {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      };
      break;
    default:
      additionalStyles = {

      };
      break;
  }

  return (
    <Skeleton
      variant={variant}
      animation="wave"
      sx={{
        background: ColorPalette.background.bg02,
        ...additionalStyles,
        '&::after': {
          background: `linear-gradient(90deg, transparent, ${ColorPalette.line.line}, transparent)`,
        },
        ...sx,
      }}
      {...rest}
    />
  );
}
