import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import axios from 'axios';
import type { ContractEarningsDetailCreateParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/create-modal/types/parameters';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createContractEarningsItem: (itemId: number) =>
    `${smProjectCollectionApiUrl}/${itemId}/collection`,
  getConsortiumCompany: (id: number) => `${contractEarningsApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoContractEarningsItemCreateModalApi = {
  createContractEarningsItem: async (data: ContractEarningsDetailCreateParams, menuId) =>
    await axios.post(url.createContractEarningsItem(data.itemId!), data.params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getConsortiumCompany: async (id: number, menuId) => {
    const { data } = await axios.get(url.getConsortiumCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
