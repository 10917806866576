import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { TaxAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/Table';
import Box from '@mui/material/Box';
import TaxAccountTableLastCell from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account/components/LastCell';

export default function TaxAccountTableLastRow() {
  const { getValues } = useFormContext<TaxAccountTableFormValues>();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {[...Array(getValues('row'))].map((b, rowIndex) => (
        <TaxAccountTableLastCell
          key={`last-cell-${rowIndex}`}
          rowIndex={rowIndex}
        />
      ))}
    </Box>
  );
}
