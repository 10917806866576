import { useProjectSalesInfoBidInfoState } from '@front/src/features/project-sales-info/features/bid/features/bid-information/widgets/useState';
import { projectSalesInfoBidInfoUpdateModalRepository } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/update-modal/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';

export { useLogic as useProjectSalesInfoBidInfoUpdateModalLogic };
const useLogic = (menuId) => {
  const detailId = useProjectSalesInfoBidInfoState((state) => state.detailId);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const { data: estimationIdList } =
    projectSalesInfoBidInfoUpdateModalRepository.useEstimateIdListGet(id!, menuId);
  const { data: bidInfoDetail, isLoading } =
    projectSalesInfoBidInfoUpdateModalRepository.useProjectSalesInfoBidInfoDetailGet(
      detailId!,
      menuId
    );
  const { run: onUpdate } =
    projectSalesInfoBidInfoUpdateModalRepository.useProjectSalesInfoBidInfoDetailUpdate(
      id!,
      menuId
    );

  return {
    h: {
      onUpdate,
    },
    d: {
      bidInfoDetail,
      estimationIdList,
    },
    isLoading,
  };
};
