import React from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';

interface Props {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export const TableBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      maxHeight: '56dvh',
      display: 'flex',
      flexDirection: 'column',
      gap: '1px',
      border: `1px solid ${ColorPalette._e4e9f2}`,
      borderRadius: '5px',
      backgroundColor: ColorPalette._e4e9f2,
      fontSize: '13px',
      overflowY: 'auto',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const TableHeadBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '1px',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);
export const TableBodyBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      gap: '1px',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const TableRowBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      gap: '1px',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const ThcBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      p: '0 8px',
      backgroundColor: ColorPalette._f1f5fc,
      fontWeight: '500',
      textWrap: 'wrap',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);

export const TbcBox = ({ children, sx = {}, ...rest }: Readonly<Props>) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      height: '44px',
      p: '0 8px',
      backgroundColor: ColorPalette._ffffff,
      textWrap: 'wrap',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);
