import type { ReactNode } from 'react';
import React from 'react';
import { useAffiliatedPersonSalesActivityListLogic } from '@front/src/features/affiliated-person/features/sales-activity/widgets/list/provider/useLogic';

export { Provider as AffiliatedPersonSalesActivityListProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  useAffiliatedPersonSalesActivityListLogic(menuId);
  return <>{children}</>;
};
