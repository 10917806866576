import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoContractReviewQuery } from '@front/src/features/project-sales-info/features/contract/features/reivew/query/query';
import { projectSalesInfoContractReviewMutation } from '@front/src/features/project-sales-info/features/contract/features/reivew/query/mutation';

export { repository as projectSalesInfoContractReviewRepository };

const repository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoContractReviewQuery.useListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useDelete: (menuId) => {
    const { mutate } = projectSalesInfoContractReviewMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
