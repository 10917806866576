import React from 'react';
import { Box } from '@mui/material';
import TextBox from '@front/layouts/Text';

function TempWidget() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <TextBox variant={'heading1'}>미개발된 페이지</TextBox>
      </Box>
    </Box>
  );
}

export default TempWidget;
