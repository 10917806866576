import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkCategoryParams } from '@front/src/features/work/features/work/features/category/types/parameter';

const workCategoryMutation = {
  useUpdate: (commonParams) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: WorkCategoryParams) => workWorkApi.updateCategory(params, commonParams),
      mutationKey: ['work', 'category', 'update'],
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'category'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workCategoryMutation;
