import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useManagementAccountContextMenuActions from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useContextMenuActions';
import useManagementAccountContextMenuMutation from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useContextMenuMutation';
import type { ManagementAccountingTreeView } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/types/view';
import type { UseFieldArrayReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import type { ManagementAccountTableFormValues } from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account/hooks/useTableForm';

interface Props extends SectionComponentProps {
  cellData: ManagementAccountingTreeView;
  anchorEl: null | HTMLDivElement;
  onClose: () => void;
  selectedRowIndexListFieldArray: UseFieldArrayReturn<
    ManagementAccountTableFormValues,
    'selectedRowIndexList',
    'id'
  >;
}

export default function ManagementAccountContextMenu(props: Props) {
  const { menuId, sectionId, dataId, cellData, anchorEl, onClose, selectedRowIndexListFieldArray } =
    props;
  const commonParams = {
    menuId,
    sectionId,
    dataId,
  };

  const formContext = useFormContext<ManagementAccountTableFormValues>();

  const mutations = useManagementAccountContextMenuMutation({
    commonParams,
    cellData,
    onClose,
    selectedRowIndexListFieldArray,
    formContext,
  });

  const { handleClickChangeName, handleMoveUp, handleMoveDown, handleInsert, handleDelete } =
    useManagementAccountContextMenuActions({
      ...mutations,
      cellData,
      onClose,
      formContext,
    });

  const handleContextMenu = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      onContextMenu={handleContextMenu}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem onClick={handleClickChangeName}>
        <MenuItemContent
          name="이름 변경"
          short="F2"
        />
      </MenuItem>
      <MenuItem onClick={handleMoveUp}>
        <MenuItemContent
          name="위로 이동"
          short="CTRL + ALT + &uarr;"
        />
      </MenuItem>
      <MenuItem onClick={handleMoveDown}>
        <MenuItemContent
          name="아래로 이동"
          short="CTRL + ALT + &darr;"
        />
      </MenuItem>
      <MenuItem onClick={handleInsert}>
        <MenuItemContent
          name="위에 추가"
          short="CTRL + INS"
        />
      </MenuItem>
      <MenuItem onClick={handleDelete}>
        <MenuItemContent
          name="삭제"
          short="CTRL + DEL"
        />
      </MenuItem>
    </Menu>
  );
}

interface MenuItemContentProps {
  name: string;
  short: string;
}

const MenuItemContent = memo(({ name, short }: MenuItemContentProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '12px',
      width: '100%',
      fontSize: '12px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {name}
    </Box>
    <Box
      sx={{
        display: 'flex',
        color: ColorPalette._386dd6,
        fontWeight: 'bold',
      }}
    >
      {short}
    </Box>
  </Box>
));

MenuItemContent.displayName = `ManagementAccountContextMenuItem`;
