import React from 'react';
import { Box, Typography } from '@mui/material';
import { NotificationBadge } from '@front/components/badge/badge';
import type { MenuView } from '@front/src/types';
import { useGnbLogic } from '@front/src/features/global-nav-bar/widgets/list/components/useGnbLogic';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props extends MenuView {}

export default function Depth3Menu(props: Props) {
  const { onClick, clickable, active } = useGnbLogic({
    path: props.path,
    hasDrawer: props.hasDrawer,
    children: props.children,
    id: props.id,
  });
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '38px',
        alignItems: 'center',
        paddingLeft: '50px',
        backgroundColor: active ? 'var(--design-v-10-light-main-main-primary, #2F6ACA)' : 'transparent',
      }}
    >
      <FontAwesomeIcon
        icon={props.icon}
        style={{
          padding: '0 10px',
          fontSize: '6px',
        }}
      />
      <NotificationBadge
        count={props.count}
        type="menu"
      >
        <Typography
          onClick={onClick}
          sx={{
            fontSize: '13px',
            cursor: clickable ? 'pointer' : 'default',
          }}
        >{`${props.title}`}</Typography>
      </NotificationBadge>
    </Box>
  );
}
