import {
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faAngleUp,
  faArrowDownLong,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowUpLong,
  faBars,
  faBell,
  faBookmark,
  faBuilding,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartGantt,
  faCheck,
  faCircle,
  faCircleInfo,
  faClock,
  faComment,
  faDownload,
  faEllipsisVertical,
  faExclamation,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFile,
  faFileContract,
  faFilePen,
  faFileSignature,
  faFloppyDisk,
  faGear,
  faHouse,
  faInfo,
  faLightbulb,
  faLink,
  faListOl,
  faLocationDot,
  faMagnifyingGlass,
  faMinus,
  faNoteSticky,
  faP,
  faPaperPlane,
  faPaste,
  faPen,
  faPlus,
  faRectangleList,
  faSearch,
  faSignature,
  faSquareMinus,
  faSquarePlus,
  faSquareXmark,
  faStar,
  faThumbtack,
  faThumbtackSlash,
  faTimes,
  faTrash,
  faUser,
  faUserGear,
  faUserGroup,
  faWonSign,
  faXmark,
  faUpRightFromSquare,
  faUndo,
  faRedo,
  faEraser,
  faExpand,
  faClockRotateLeft,
  faBrush,
  faFont, faTruckMoving,
} from '@fortawesome/free-solid-svg-icons';
import { faReadme } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBookmark as faBookmarkSlash,
  faHand,
  faNoteSticky as faNoteStickySlash,
} from '@fortawesome/free-regular-svg-icons';
import { faSquareCaretDown } from '@fortawesome/free-solid-svg-icons/faSquareCaretDown';

export function configFontAwesome() {
  library.add(
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAnglesLeft,
    faAnglesRight,
    faArrowRightFromBracket,
    faBuilding,
    faChartGantt,
    faCheck,
    faCircle,
    faDownload,
    faP,
    faFloppyDisk,
    faGear,
    faLink,
    faListOl,
    faMinus,
    faPen,
    faPlus,
    faReadme,
    faStar,
    faTimes,
    faTrash,
    faUser,
    faXmark,
    faArrowRightLong,
    faPaperPlane,
    faFileSignature,
    faFaceSmile,
    faWonSign,
    faSearch,
    faBars,
    faHouse,
    faMagnifyingGlass,
    faCalendar,
    faBell,
    faExclamation,
    faInfo,
    faCircleInfo,
    faCaretUp,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faEye,
    faEyeSlash,
    faLightbulb,
    faFilePen,
    faRectangleList,
    faUserGroup,
    faSignature,
    faEllipsisVertical,
    faLocationDot,
    faThumbtack,
    faThumbtackSlash,
    faNoteSticky,
    faNoteStickySlash,
    faClock,
    faArrowUpLong,
    faArrowDownLong,
    faSquareMinus,
    faSquarePlus,
    faSquareXmark,
    faFileContract,
    faPaste,
    faFile,
    faComment,
    faBookmark,
    faBookmarkSlash,
    faUserGear,
    faUpRightFromSquare,
    faUndo,
    faRedo,
    faEraser,
    faExpand,
    faClockRotateLeft,
    faHand,
    faBrush,
    faFont,
    faXmark,
    faSquareCaretDown,
    faTruckMoving
  );
}
