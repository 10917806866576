import {ContractCmView} from "@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views";

export const contractCmApi = {
  getList: async <T = ContractCmView[]>(commonParams) => {
    return await generateCmDummyData(3);
  },
};

const generateCmDummyData = async (recordCounts: number) => {
  return new Promise<ContractCmView[]>((resolve) => {
    setTimeout(() => {
      const contractCM: ContractCmView[] = [];
      for (let i = 1; i <= recordCounts; i++) {
        const contractCMItem: ContractCmView = {
          id: i,
          paymentStage: ['계약금', '중도금', '잔금'][i % 3],
          contractCM: Math.round(Math.random() * 10000000),
          cmRatioAgainstContractPrice: Math.round(Math.random() * 40),
          cmPostDeduction: Math.round(Math.random() * 10000000),
          cmDeductionRatio: Math.round(Math.random() * 100),
          cmMethod: ['현금', '상품권', '구검', '프리렌서', '기타'][i % 5],
          cmRecipient: {
            id: 1,
            name: '홍길동',
            position: '과장',
            department: '구매팀',
            affiliatedCompany: {
              id: 1,
              name: '테스트회사',
              code: 'C11111111',
              status: '',
              alarmCount: 0,
            },
          },
          cmPaymentTiming: '잔금완료후',
          cmExpectedPaymentDate: '2024-12-25',
          textAttr1: '',
          textAttr2: '',
          textAttr3: '',
          dateAttr1: '',
          dateAttr2: '',
          dateAttr3: '',
          numberAttr1: 0,
          numberAttr2: 0,
          numberAttr3: 0,
        };

        contractCM.push(contractCMItem);
      }
      resolve(contractCM);
    }, Math.random() * 1000);
  });
};
