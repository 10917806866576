import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import { useIsMutating } from 'react-query';
import WorkIndicatorTableBody from '@front/src/features/work/features/work/features/creator/features/manager/components/TableBody';

import workManagerQuery from '@front/src/features/work/features/work/features/creator/features/manager/query/query';

export default function WorkManagerTable(props: Readonly<SectionComponentProps>) {
  const { dataId, menuId, sectionId } = props;
  const isLoading = !!useIsMutating({
    mutationKey: ['work', 'manager', 'update'],
  });

  const list = workManagerQuery.useGetOne({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      BodyComponent={WorkIndicatorTableBody}
      list={list}
    />
  );
}
