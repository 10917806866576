// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teyYM782NVFlbnrsjD7w{display:flex;flex-direction:column;gap:10px;width:100%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/layout/search-part.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGA,UAAA","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  //max-height: 48dvh;\r\n  //scrollbar-gutter: stable;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `teyYM782NVFlbnrsjD7w`
};
export default ___CSS_LOADER_EXPORT___;
