import { WorkStatus } from '@front/src/features/work/features/work/utils/constant';

export const conventWorkStatusLabelToVariable = {
  미시작: WorkStatus.NOT_STARTED,
  진행중: WorkStatus.IN_PROGRESS,
  보고전: WorkStatus.BEFORE_REPORT,
  보고완료: WorkStatus.REPORT_COMPLETED,
  업무완료: WorkStatus.WORK_COMPLETED,
  보류: WorkStatus.ON_HOLD,
  기타: WorkStatus.ETC,
};

export const conventWorkStatusVariableToLabel = {
  [WorkStatus.NOT_STARTED]: '미시작',
  [WorkStatus.IN_PROGRESS]: '진행중',
  [WorkStatus.BEFORE_REPORT]: '보고전',
  [WorkStatus.REPORT_COMPLETED]: '보고완료',
  [WorkStatus.WORK_COMPLETED]: '업무완료',
  [WorkStatus.ON_HOLD]: '보류',
  [WorkStatus.ETC]: '기타',
};
