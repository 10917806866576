import type { KeyboardEvent } from 'react';
import React, { memo } from 'react';
import type { FormikProps } from 'formik';

import { Box } from '@mui/material';

import Input from '@front/layouts/Input';
import Divider from '@front/layouts/Divider';
import type { CampaignMemoQuery } from '@front/ost_campaign/query';

interface Props {
  enterKeyDownHandler: (e: KeyboardEvent<HTMLInputElement>) => void;
  formik: FormikProps<CampaignMemoQuery>;
}

const CampaignMemoFilter = ({ formik, enterKeyDownHandler }: Props) => (
  <Box
    sx={{
      padding: '0 10px',
      margin: '10px 0',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      flex: 1,
    }}
  >
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Input
        key={formik.values.keyword}
        defaultValue={formik.values.keyword ?? ''}
        variant="outlined"
        placeholder="검색어 입력 후 엔터"
        onKeyDown={enterKeyDownHandler}
      />
    </Box>
    <Divider sx={{ margin: '10px 0 0 0', padding: '0' }} />
  </Box>
);

const FilterMemo = memo(CampaignMemoFilter);

export default FilterMemo;
