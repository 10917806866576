import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyCharacterCharacterLogic } from '@front/src/features/affiliated-company/features/character/features/character/widgets/useLogic';
import { AffiliatedCompanyCharacterCharacterContext } from '@front/src/features/affiliated-company/features/character/features/character/widgets/context';
import { AffiliatedCompanyCharacterCharacterButton } from '@front/src/features/affiliated-company/features/character/features/character/components/character-button';
import { AffiliatedCompanyCharacterCharacterTable } from '@front/src/features/affiliated-company/features/character/features/character/components/character-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedCompanyCharacterCharacterWidget = ({
  name,
  menuId,
}: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { characterList },
    h: { setIdList, onCharacterCreate, onCharacterUpdate, onCharacterDelete },
    isLoading,
    idList,
  } = useAffiliatedCompanyCharacterCharacterLogic(menuId);
  const value = useMemo(
    () => ({
      d: { characterList },
      h: { onCharacterCreate, onCharacterUpdate, onCharacterDelete, setIdList },
      isLoading,
      idList,
    }),
    [
      characterList,
      isLoading,
      setIdList,
      onCharacterCreate,
      onCharacterUpdate,
      onCharacterDelete,
      idList,
    ]
  );
  return (
    <AffiliatedCompanyCharacterCharacterContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyCharacterCharacterButton />
        </Header>
        <Body>
          <AffiliatedCompanyCharacterCharacterTable />
        </Body>
      </Layout>
    </AffiliatedCompanyCharacterCharacterContext.Provider>
  );
};

export default AffiliatedCompanyCharacterCharacterWidget;
