import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';

export { Context as AccountingSettingsManagementAccountingAccountTableCategoryListContext };

interface State {
  list?: AccountingSettingsManagementAccountingAccountTableCategoryView[];
  onCreate: (params) => void;
  onUpdate: (params) => void;
  handleKeyDown: (e) => void;
  handleMoveDown: () => void;
  handleDelete: () => void;
  handleInsert: () => void;
  handleChangeName: () => void;
  handleMoveUp: () => void;
  handleClose: () => void;
  tableRowNum: number;
}

const Context = createContext<State>({
  onCreate: noOp,
  onUpdate: noOp,
  handleKeyDown: noOp,
  handleMoveDown: noOp,
  handleDelete: noOp,
  handleInsert: noOp,
  handleChangeName: noOp,
  handleMoveUp: noOp,
  handleClose: noOp,
  tableRowNum: 20,
});
