import React, { useCallback } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { useFormContext } from 'react-hook-form';
import type { AffiliatedCompanyPersonRelationshipView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/types/view';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalRelationshipState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useState';

export { RelationshipTableRowSelector as AffiliatedCompanyCharacterRelationshipTableRowSelector };

interface Props {
  item: AffiliatedCompanyPersonRelationshipView;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

const RelationshipTableRowSelector = ({ item, onSubmit }: Props) => {
  const { setValue } = useFormContext();
  const handleSelector = useCallback(
    async (company, person) => {
      setValue('affiliatedCompanyId', company?.id);
      setValue('personId', person?.id);
      await onSubmit();
    },
    [onSubmit, setValue]
  );
  const { readOnly } = useAffiliatedCompanyPersonDetailModalRelationshipState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      value={{
        companyId: item.affiliatedCompanyId ?? undefined,
        personId: item.personId ?? undefined,
        textValue: `${item.name ?? ''}`,
      }}
      onCompanySelect={() => {}}
      onPersonSelect={handleSelector}
      usePersonSelector
      disabled={readOnly}
    />
  );
};
