import React, { useContext, useEffect } from 'react';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { getOptionList } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/utils';
import { useFormContext } from 'react-hook-form';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/context';

export { WebHardSelect as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalWebHardSelect };

const WebHardSelect = () => {
  const {
    d: { webHardList, detail },
    isWebHardListLoading,
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalContext);
  const { setValue } = useFormContext();
  useEffect(() => {
    if (typeof webHardList === 'undefined' || isWebHardListLoading || webHardList.length === 0) {
      return;
    }
    setValue('webHardId', detail?.webHardId?.toString());
  }, [detail, setValue, isWebHardListLoading, webHardList]);
  if (typeof webHardList === 'undefined' || isWebHardListLoading || webHardList.length === 0) {
    return (
      <HookFormSelect
        isDefaultPossible
        defaultLabel="선택"
        name="webHardId"
        optionList={[]}
      />
    );
  }
  return (
    <HookFormSelect
      isDefaultPossible
      defaultLabel="선택"
      name="webHardId"
      optionList={getOptionList(webHardList)}
    />
  );
};
