import React from 'react';
import { AccountingSettingsManagementAccountingAccountTableFeature } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table';
import { AccountingSettingsManagementAccountingAccountFeatureProvider } from '@front/src/features/accounting/features/settings/features/management-accounting-account/provider/provider';
import { accountingSettingsContentLayout } from '@front/src/features/accounting/features/settings/components/layouts/content';
import { AccountingSettingsManagementAccountingAccountHeaderFeature } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/header';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface Props extends SectionComponentProps {}

const AccountingSettingsManagementAccountingAccountFeature = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Header, Body } = accountingSettingsContentLayout;
  const { readOnly } = useGetReadOnlyFromMenuId(menuId);
  return (
    <AccountingSettingsManagementAccountingAccountFeatureProvider readOnly={readOnly}>
      <Layout>
        <Header
          title={name}
          rightComponent={
            <AccountingSettingsManagementAccountingAccountHeaderFeature menuId={menuId} />
          }
        />
        <Body>
          <AccountingSettingsManagementAccountingAccountTableFeature menuId={menuId} />
        </Body>
      </Layout>
    </AccountingSettingsManagementAccountingAccountFeatureProvider>
  );
};

export default AccountingSettingsManagementAccountingAccountFeature;
