import type { AddParameter } from '@front/src/types/parameter';
import { type IdsDataParameter, type SeqUpdateParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ArithmeticOperator } from '@front/src/utils';
import { AddPosition } from '@front/src/utils';
import type { MutateOptions } from 'react-query';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

interface Props {
  onAdd: (
    params: AddParameter,
    options?: MutateOptions<'', Error, AddParameter, unknown> | undefined
  ) => void;

  onUpdateSeq: (
    params: SeqUpdateParameter,
    options?: MutateOptions<'', Error, SeqUpdateParameter, unknown> | undefined
  ) => void;
  onDelete: (
    params: IdsDataParameter,
    options?: MutateOptions<'', Error, IdsDataParameter, unknown> | undefined
  ) => void;

  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
  queryKey: (string | number | undefined)[];
}

const useUIBuilderTableRowChildMutation = ({
  onAdd,
  onUpdateSeq,
  onDelete,
  formContext,
}: Props) => {
  const { confirm } = useCustomDialog();
  const { setValue } = formContext;
  const { addIfNotExist } = useGetUIBuilderTableEditState(formContext);

  const handleAdd = (rowId: number, parentId: number) => {
    onAdd(
      {
        parentId,
        rowId,
        position: AddPosition.DEFAULT,
      },
      {
        onSuccess: (data, variables) => {
          setValue('recentUpdatedId', variables.parentId);
          addIfNotExist(variables.parentId);
        },
      }
    );
  };

  const handleUpdateSeq = (id: number, operator: ArithmeticOperator, parentId: number) => {
    onUpdateSeq(
      {
        id,
        operator,
        parentId,
      },
      {
        onSuccess: async (data, variables) => {
          setValue('recentUpdatedId', variables.parentId);
          // addIfNotExist(variables.parentId);
        },
      }
    );
  };

  const handleDelete = (id: number, parentId: number) => {
    onDelete(
      { id, parentId },
      {
        onSuccess: async (data, variables) => {
          setValue('recentUpdatedId', variables.parentId);
          // addIfNotExist(variables.parentId);
        },
      }
    );
  };

  const handleDeleteAfterConfirm = (id: number, parentId: number) => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        handleDelete(id, parentId);
      },
    });
  };

  return {
    onAdd: handleAdd,
    onUpdateSeq: handleUpdateSeq,
    onDelete: handleDeleteAfterConfirm,
  };
};

export default useUIBuilderTableRowChildMutation;
