import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { handleError } from '@front/src/utils';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AxiosError } from 'axios';
import { loginAction } from '@front/login/action';

interface Props {
  children: ReactNode;
}

export default function CustomQueryClientProvider({ children }: Readonly<Props>) {
  const { alert } = useCustomDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoginModalOpen = useSelector(
    (root: RootState) => root.login.isOpenLoginModal,
    shallowEqual
  );
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: !isLoginModalOpen,
            staleTime: 0,
          },
          mutations: {
            onError: async (error) => {
              handleError(error, alert);
            },
          },
        },
        queryCache: new QueryCache({
          onError: async (error) => {
            if (error instanceof AxiosError) {
              if (error.response?.status === 401) {
                dispatch(loginAction.setSessionExpired(true));
                return Promise.resolve();
              }
            } else {
              handleError(error, alert, navigate);
            }
          },
        }),
      }),
    []
  );
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        position="bottom-right"
      />
    </QueryClientProvider>
  );
}
