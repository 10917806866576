import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import type { ProjectStepInformationView } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/view';
import type { IdDeleteParameter } from '@front/src/features/project-sales-info/features/project-division/features/estimate-modal/type/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/${id}/step-information`,
  createProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/${id}/step-information`,
  updateProjectDivisionModalData: (id?) => `${projectSalesInfoURL}/step-information/${id}`,
  deleteProjectDivisionModalData: () => `${projectSalesInfoURL}/step-information`,
};

export const projectSalesInfoProjectDivisionModalApi = {
  getProjectDivisionModalData: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getProjectDivisionModalData(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createProjectDivisionModalData: async (id?: number, menuId?) =>
    await axios.post(url.createProjectDivisionModalData(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateProjectDivisionModalData: async (params: ProjectStepInformationView, menuId) => {
    await axios.put(url.updateProjectDivisionModalData(params.id), params, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  deleteProjectDivisionModalData: async (params: IdDeleteParameter, menuId) => {
    await axios.delete(url.deleteProjectDivisionModalData(), {
      data: params,
      headers: getMenuIdHeaders(menuId),
    });
  },
};
