import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import React, {Suspense, useCallback, useRef} from 'react';
import * as fabric from "fabric";
import './image-draw.css';
import TableRowLoadingSpinnerUI
  from "@front/src/components/components-with-design/component/loading-spinner/TableRowLoadingSpinnerUI";
import TableBodyUI from "@front/src/components/components-with-design/compound/table/TableBodyUI";
import TableRowUI from "@front/src/components/components-with-design/compound/table/TableRowUI";
import TableCellUI from "@front/src/components/components-with-design/compound/table/TableCellUI";
import TableHeadUI from "@front/src/components/components-with-design/compound/table/TableHeadUI";
import CheckboxBasicUI from "@front/src/components/components-with-design/component/CheckboxBasicUI";
import imageDrawHistoryQuery from "@front/src/features/image-draw/query/query";
import TableTableUI from "@front/src/components/components-with-design/compound/table/TableTableUI";
import dayjs from "dayjs";
import {HH_MM, YY_MM_DD} from "@front/src/utils";
import ContextMenuUI from "@front/src/components/components-with-design/compound/ContextMenuUI";
import {CheckBoxOutlineBlank, ChecklistRtl, NoteAlt} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {ImageDrawHistoryView} from "@front/src/features/image-draw/types/view";
import {useCustomDialog} from "@front/src/features/dialog";
import {OverlayItem} from "@front/src/features/image-draw/hooks/useOverlayManager";
import imageDrawMutation from "@front/src/features/image-draw/query/mutation";
import {UseMutateFunction} from "react-query";
import {AxiosResponse} from "axios";
import useCanvasUtil, {
  CanvasRuntimeProps
} from "@front/src/features/image-draw/hooks/useCanvasUtil";
import {shallowEqual, useSelector} from "react-redux";
import type {RootState} from "@front/services/reducer";
import {SnackbarSeverityType} from "@front/components/Snackbar/action";
import useStateManager from "@front/src/features/image-draw/hooks/useStateManager";
import type {ControlPanelItem} from "@front/src/types/domain";

interface Props {
  menuId: number,
  imageFileId: number,
  open: boolean;
  canvas: fabric.Canvas | null,
  actingDrawing: number | null,
  setActingDrawing: React.Dispatch<React.SetStateAction<number | null>>,
  cancelActingDrawing: () => void,
  load: (canvas: fabric.Canvas, item: OverlayItem, runtimeProps: CanvasRuntimeProps) => void,
  unload: (canvas: fabric.Canvas, imageDrawId: number) => void,
  contains: (imageDrawId: number) => boolean
  checkedList: {[key: string]: boolean},
  setCheckedList: React.Dispatch<any>,
  runtimeProps: CanvasRuntimeProps,
  editable: boolean,
}

export default function ImageDrawHistoryAside(props: Props) {
  const {menuId, imageFileId, open, canvas, load, unload, contains,
    actingDrawing, setActingDrawing, cancelActingDrawing, runtimeProps, editable,
  } = props;

  const ref = useRef<HTMLTableSectionElement | null>(null);
  const {mutate: onDrawingDelete} = imageDrawMutation.useDelete(menuId!);

  const loadContents = useCallback((item: OverlayItem) => {
    canvas && load(canvas, item, runtimeProps);
  }, [canvas, actingDrawing]);

  const unloadContents = useCallback((imageDrawId: number) => {
    canvas && unload(canvas, imageDrawId);
  }, [canvas]);

  const setCheckStateAll = useCallback((tobeState: boolean) => {
    if (ref.current) {
      const checkBoxElements: HTMLCollectionOf<HTMLInputElement> = ref.current.getElementsByTagName('input');
      Array.from(checkBoxElements).forEach((el) => {
        if (el.checked) {
          if (!tobeState && !el.disabled) {
            el.click();
          }
        } else {
          if (tobeState) {
            el.click();
          }
        }
      });
    }
  }, [ref.current]);

  const headerActionItemList = [{
    text: '전체 열기',
    icon: ChecklistRtl,
    action: () => {
      setCheckStateAll(true);
    },
  }, {
    text: '전체 닫기',
    icon: CheckBoxOutlineBlank,
    action: () => {
      setCheckStateAll(false);
    },
  },];

  return (
    <div className={`image-draw-history-aside ${open ? 'active' : ''}`}>
      <TableContainerUI
        sx={{width: '100%', height: '100%', overflowY: 'auto'}}
      >
        <TableTableUI>
          <TableHeadUI>
            <TableRowUI>
              <TableCellUI
                isHead
                sx={{width: '7%'}}>
                <ContextMenuUI
                  itemList={headerActionItemList}
                />
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{width: '8%'}}>
                No.
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{width: '13%'}}>
                일시
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{width: '14%'}}>
                작성자
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{width: '52%'}}>
                메모
              </TableCellUI>
              <TableCellUI
                isHead
                sx={{width: '7%'}}>
                열기
              </TableCellUI>
            </TableRowUI>
          </TableHeadUI>
          {open && (
            <TableBodyUI ref={ref}>
              <Suspense fallback={<TableRowLoadingSpinnerUI colSpan={6}/>}>
                <TableBodyRows
                  menuId={menuId}
                  imageFileId={imageFileId}
                  canvas={canvas}
                  load={loadContents}
                  unload={unloadContents}
                  contains={contains}
                  setActingDrawing={setActingDrawing}
                  actingDrawing={actingDrawing}
                  cancelActingDrawing={cancelActingDrawing}
                  checkedList={props.checkedList}
                  setCheckedList={props.setCheckedList}
                  onDrawingDelete={onDrawingDelete}
                  editable={editable}
                  runtimeProps={runtimeProps}
                />
              </Suspense>
            </TableBodyUI>
          )}
        </TableTableUI>
      </TableContainerUI>
    </div>
  );
}

interface TableBodyRowsProps {
  menuId: number,
  imageFileId: number,
  canvas: fabric.Canvas | null,
  load: (item: OverlayItem) => void,
  unload: (imageDrawId: number) => void,
  contains: (imageDrawId: number) => boolean,
  actingDrawing: number | null,
  setActingDrawing: React.Dispatch<React.SetStateAction<number | null>>,
  cancelActingDrawing: () => void,
  checkedList: {[key: string]: boolean},
  setCheckedList: React.Dispatch<any>,
  onDrawingDelete: UseMutateFunction<AxiosResponse<any, any>, unknown, number, unknown>,
  editable: boolean,
  runtimeProps: CanvasRuntimeProps
}

function TableBodyRows(props: TableBodyRowsProps) {
  const {
    menuId, imageFileId, canvas, load, unload,
    checkedList, setCheckedList,
    actingDrawing, setActingDrawing,
    cancelActingDrawing, onDrawingDelete, editable,
    runtimeProps
  } = props;

  const {alert, confirm, prompt} = useCustomDialog();
  const { removeObjectsByImageDrawId, openSnackbar } = useCanvasUtil();

  const {listData} = imageDrawHistoryQuery.useListByFileId(imageFileId, menuId);
  const {mutateComment} = imageDrawMutation.useUpdate(menuId!);
  const {saveState} = useStateManager();

  const user = useSelector((root: RootState) => root.login.detail, shallowEqual);

  const createActionItemList = (data: ImageDrawHistoryView): ControlPanelItem[] => [{
      text: data.id === actingDrawing ? '수정종료' : '수정',
      icon: EditIcon,
      disabled: !((checkedList[data.id] || false) && editable && user?.id === data.writer.id),
      tooltip: (checkedList[data.id] || false)? '' : '체크된 첨삭만 수정가능',
      action: () => {
        if (data.id === actingDrawing) {
          cancelActingDrawing();
        } else {
          if(actingDrawing || runtimeProps.isModified) {
            alert({
              title: '확인',
              children: [{value: '작성중인 첨삭이 있습니다. 먼저 저장하거나 수정취소 처리 바랍니다.'}]
            });
          } else {
            runtimeProps.actingDrawingId = data.id;
            saveState(canvas!, runtimeProps, ()=>{
              setActingDrawing(runtimeProps.actingDrawingId);
              console.debug('initial drawing state pushed');
            });
          }
        }
      },
    },{
      text: '메모',
      icon: NoteAlt,
      disabled: !(editable && user?.id === data.writer.id),
      action: () => {
        prompt({
          lineBreakChildren: [],
          defaultValue: data.comment,
          title: '첨삭 메모를 입력하세요',
          promptText: '확인',
          closeText: '취소',
          multiline: true,
          maxLength: 500,
          rows: 10,
          afterPrompt: (memo: string) => {
            mutateComment({
              drawingId: data.id,
              comment: memo,
            }, {
              onSuccess: () => {
                openSnackbar('메모를 수정하였습니다', SnackbarSeverityType.success);
              }
            });
          }
        });
      },
    },{
      text: '삭제',
      disabled: !(editable && user?.id === data.writer.id),
      icon: DeleteIcon,
      action: () => {
        confirm({
          lineBreakChildren: [
            { value: '첨삭을 삭제하겠습니까?' },
          ],
          confirmText: '확인',
          closeText: '취소',
          afterConfirm: () => {
            onDrawingDelete(data.id, {
              onSuccess: () => {
                canvas && removeObjectsByImageDrawId(canvas, data.id);
                cancelActingDrawing();
                canvas?.renderAll();
              }
            });
          },
        });
      },
    },
  ];

  return (
    <>
      {listData?.map((data, index) =>{
        const _date = dayjs(data.createdAt);
        return (
          <TableRowUI key={data.id}
                      className={`${actingDrawing === data.id ? 'acting-drawing' : ''} ${checkedList[data.id] || false ? 'checked' : ''}`}
          >
            <TableCellUI>
              <ContextMenuUI
                disabled={!editable || user?.id !== data.writer.id}
                itemList={createActionItemList(data)}
              />
            </TableCellUI>
            <TableCellUI>{index + 1}</TableCellUI>
            <TableCellUI>
              <div className="date">{_date.format(YY_MM_DD)}</div>
              <div className="time">{_date.format(HH_MM)}</div>
            </TableCellUI>
            <TableCellUI>{data.writer.name}</TableCellUI>
            <TableCellUI className="comment">{data.comment}</TableCellUI>
            <TableCellUI sx={{alignContent: 'center'}}>
              <CheckboxBasicUI
                checked={checkedList[data.id] || false}
                disabled={actingDrawing === data.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    load({
                      contents: data.contents,
                      imageDrawId: data.id,
                      fileId: data.fileId,
                      comment: data.comment
                    });
                  } else {
                    unload(data.id);
                  }
                  setCheckedList((prevCheckedList) => ({
                    ...prevCheckedList,
                    [data.id]: e.target.checked,
                  }));
                }}/>
            </TableCellUI>
          </TableRowUI>
        );
      })}
    </>
  );
}
