import { useMutation, useQueryClient } from 'react-query';
import type {
  AddParameter,
  IdsDataParameter,
  SeqUpdateParameter,
  UIBuilderParameter,
  UpdateByFormDataParameter,
} from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';

const estimationHistoryClientMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, AddParameter>({
      mutationFn: (params) =>
        commonApi.add(
          `/api/project/sales/sales-information/estimation/${params.parentId}/estimation-client`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useUpdateByFormData: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, UpdateByFormDataParameter>({
      mutationFn: (params) =>
        commonApi.update(
          `/api/project/sales/sales-information/estimation/estimation-client/${params.id}`,
          params.formData,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useUpdateSeq: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, SeqUpdateParameter>({
      mutationFn: (params) =>
        commonApi.updateSeq(
          `/api/project/sales/sales-information/estimation/${params.parentId}/estimation-client/seq`,
          {
            id: params.id,
            operator: params.operator,
          },
          commonParams
        ),
      mutationKey: [...queryKey, 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<'', Error, IdsDataParameter>({
      mutationFn: (params) =>
        commonApi.delete(
          `/api/project/sales/sales-information/estimation/${params.parentId}/estimation-client`,
          {
            id: params.id,
            ...commonParams,
          },
          commonParams
        ),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },
};

export default estimationHistoryClientMutation;

const getQueryKey = (dataId) => ['project', 'sales', dataId, 'estimation', 'history', 'client'];

const getParentQueryKey = (dataId) => ['project', 'sales', dataId, 'estimation', 'history'];
