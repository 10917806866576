import { projectSalesInfoDesignOutlineQuery } from '@front/src/features/project-sales-info/features/design-outline/query';
import { projectSalesInfoDesignOutlineMutation } from '@front/src/features/project-sales-info/features/design-outline/query/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectDesignOutlineParameter } from '@front/src/features/project-sales-info/features/design-outline/types/parameter';

export const projectSalesInfoDesignOutlineRepository = {
  useProjectDesignOutlineGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoDesignOutlineQuery.useProjectDesignOutlineGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },

  useProjectDesignOutlineUpdate: (id: number, menuId) => {
    const { mutate } = projectSalesInfoDesignOutlineMutation.useProjectDesignOutlineUpdate(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectDesignOutlineParameter) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },

  useCity1DataGet(id: number, menuId) {
    const { data } = projectSalesInfoDesignOutlineQuery.useCity1DataGet(id, menuId);
    return {
      data,
    };
  },

  useCity2DataGet(id: number, code?: string, menuId?) {
    const { data } = projectSalesInfoDesignOutlineQuery.useCity2DataGet(id, code, menuId);
    return {
      data,
    };
  },
};
