import React, { useMemo } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { useProjectSalesInfoEstimationCompetitorUpdateModalLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/useLogic';
import { ProjectSalesInfoEstimationCompetitorUpdateModalContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/widgets/context';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { ProjectSalesInfoEstimationCompetitorUpdateModalForm } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/features/update-modal/components/modal-form';
import { isMobileDevice } from '@front/util/PwaUtil';

export { Widget as ProjectSalesInfoEstimationCompetitorUpdateModalWidget };
interface Props {
  menuId?: number;
}
const Widget = ({ menuId }: Props) => {
  const {
    d: { detail },
    h: { onClose, onUpdate },
    isLoading,
    isOpen,
  } = useProjectSalesInfoEstimationCompetitorUpdateModalLogic(menuId);
  const value = useMemo(
    () => ({
      d: { detail },
      h: { onClose, onUpdate },
    }),
    [detail, onClose, onUpdate]
  );
  return (
    <ProjectSalesInfoEstimationCompetitorUpdateModalContext.Provider value={value}>
      <ModalLayout
        position="left"
        width={isMobileDevice() ? '29dvw' : '560px'}
        title="경쟁사 정보 수정"
        open={isOpen}
        onClose={onClose}
      >
        {isLoading && <LoadingSpinner height="748px" />}
        {!isLoading && <ProjectSalesInfoEstimationCompetitorUpdateModalForm />}
      </ModalLayout>
    </ProjectSalesInfoEstimationCompetitorUpdateModalContext.Provider>
  );
};
