import type { ReactNode } from 'react';
import React from 'react';
import classes from '@front/src/features/affiliated-company/components/layout/modal-with-tab/layout.module.scss';

interface Props {
  children: ReactNode;
}

export { Layout };

const Layout = ({ children }: Props) => <div className={classes.container}>{children}</div>;
