import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleDeleteIdConfirmAfterClick } from '@front/src/utils';
import { ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/design-date/features/delete-button/widgets/context';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';

export { DeleteButton as ProjectSalesInfoSubjectReviewModalDesignDateDeleteButton };

const DeleteButton = () => {
  const { onDelete } = useContext(ProjectSalesInfoSubjectReviewModalDesignDateDeleteButtonContext);
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
      setId: state.setId,
    }),
    shallow
  );
  const { confirm } = useCustomDialog();
  const handleDeleteClick = useCallback(() => {
    handleDeleteIdConfirmAfterClick(id, confirm, null, () => {
      onDelete(id);
    });
  }, [id, confirm, onDelete]);
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <Button
      onClick={handleDeleteClick}
      disabled={readOnly}
    >
      실험대상 삭제
    </Button>
  );
};
