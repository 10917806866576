import { useIsMutating } from 'react-query';

const useWorkWorkerGetIsLoading = () => {
  const isAddTopLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'add-top'],
  });
  const isAddBottomLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'add-bottom'],
  });
  const isCreateLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'create'],
  });
  const isUpdateLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'update'],
  });
  const isSeqUpLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'seq-up'],
  });
  const isSeqDownLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'seq-down'],
  });
  const isDeleteLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'delete'],
  });
  const isDeleteAllLoading = !!useIsMutating({
    mutationKey: ['work', 'reviewer', 'delete-all'],
  });
  return (
    isDeleteAllLoading ||
    isAddTopLoading ||
    isAddBottomLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isDeleteLoading ||
    isSeqUpLoading ||
    isSeqDownLoading
  );
};

export default useWorkWorkerGetIsLoading;
