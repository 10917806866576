import { useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import { handleError } from '@front/src/utils';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { projectSalesInfoBidResultApi } from '@front/src/features/project-sales-info/features/bid/features/bid-result/query/api';

export const projectSalesInfoBidResultMutation = {
  useProjectSalesInfoDelete: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: IdDeleteParams) =>
        projectSalesInfoBidResultApi.deleteBidResult(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-sales-info', 'bid-result', 'list']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
