import React from 'react';
import { AccountingSettingsBankAccountUpdateBankNameProvider } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/provider/provider';
import type { BankAccountView } from '@front/src/features/accounting/features/settings/features/bank-account/types/view';
import { AccountingSettingsBankAccountUpdateBankName } from '@front/src/features/accounting/features/settings/features/bank-account/widgets/update-bank-name/components/bank-name';

export { Widget as AccountingSettingsBankAccountUpdateBankNameWidget };

interface Props {
  item: BankAccountView;
  menuId?: number;
}

const Widget = ({ item, menuId }: Props) => (
  <AccountingSettingsBankAccountUpdateBankNameProvider
    item={item}
    menuId={menuId}
  >
    <AccountingSettingsBankAccountUpdateBankName />
  </AccountingSettingsBankAccountUpdateBankNameProvider>
);
