import type React from 'react';
import { useState } from 'react';

const useGetAnchorEl = <T>() => {
  const [anchorEl, setAnchorEl] = useState<null | T>(null);
  const handleClick = (event: React.MouseEvent<T>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    onOpen: handleClick,
    onClose: handleClose,
  };
};

export default useGetAnchorEl;
