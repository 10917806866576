import { projectActivityURL } from '@front/src/features/project-sales-info/features/activity/utils/constants';
import type { IdListDeleteParameter } from '@front/src/types/parameter';
import type {
  SalesInfoActivityAffiliatesUpdateParams,
  SalesInfoActivityUpdateParams,
  SalesInfoActivityUserUpdateParams,
} from '@front/src/features/project-sales-info/features/activity/types/parameters';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getActivityList: (id: number) => `${projectActivityURL}/${id}/sales-activity`,
  createActivity: (id: number) => `${projectActivityURL}/${id}/sales-activity`,
  createUsers: (activityId: number) =>
    `${projectActivityURL}/sales-activity/${activityId}/sales-activity-user`,
  createAffiliates: (userId: number) =>
    `${projectActivityURL}/sales-activity-user/${userId}/sales-activity-affiliates`,
  updateActivity: () => `${projectActivityURL}/sales-activity`,
  updateUsers: () => `${projectActivityURL}/sales-activity-user`,
  updateAffiliates: () => `${projectActivityURL}/sales-activity-affiliates`,
  deleteActivity: () => `${projectActivityURL}/sales-activity`,
  deleteUsers: (userId: number) => `${projectActivityURL}/sales-activity-user/${userId}`,
  deleteAffiliates: (affiliatesId: number) =>
    `${projectActivityURL}/sales-activity-affiliates/${affiliatesId}`,
};

export const projectSalesInfoActivityApi = {
  getActivityList: async (id: number, menuId) =>
    await axios.get(url.getActivityList(id), {
      headers: getMenuIdHeaders(menuId),
    }),
  createActivity: async (id: number, menuId) =>
    await axios.post(url.createActivity(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createUsers: async (activityId: number, menuId) =>
    await axios.post(url.createUsers(activityId), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  createAffiliates: async (userId: number, menuId) =>
    await axios.post(url.createAffiliates(userId), null, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateActivity: async (params: SalesInfoActivityUpdateParams, menuId) =>
    await axios.put(url.updateActivity(), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateUsers: async (params: SalesInfoActivityUserUpdateParams, menuId) =>
    await axios.put(url.updateUsers(), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  updateAffiliates: async (params: SalesInfoActivityAffiliatesUpdateParams, menuId) =>
    await axios.put(url.updateAffiliates(), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteActivity: async (params: IdListDeleteParameter, menuId) =>
    await axios.delete(url.deleteActivity(), { data: params, headers: getMenuIdHeaders(menuId) }),
  deleteUsers: async (userId: number, menuId) =>
    await axios.delete(url.deleteUsers(userId), {
      headers: getMenuIdHeaders(menuId),
    }),
  deleteAffiliates: async (affiliatesId: number, menuId) =>
    await axios.delete(url.deleteAffiliates(affiliatesId), {
      headers: getMenuIdHeaders(menuId),
    }),
};
