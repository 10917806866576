import { useMutation, useQueryClient } from 'react-query';
import uIBuilderApi from '@front/src/features/ui-builder/api/api';
import type { UiMetaUpsertParameter } from '@front/src/features/ui-builder/features/ui-meta/types/parameter';

const useUpdateUiMeta = (commonParams) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation<unknown, unknown, UiMetaUpsertParameter>({
    mutationFn: (params) => uIBuilderApi.updateUIMeta(params, commonParams),
    mutationKey: ['ui-builder', 'ui-meta', 'hidden-list', commonParams.sectionId, 'update'],
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [],
      });
    },
  });

  return mutate;
};

export default useUpdateUiMeta;
