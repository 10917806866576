import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import type { UserVO } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';
import UserSelectorInputFeature from '@front/src/features/user-selector/features/input';
import type { ConfirmView } from '@front/src/features/dialog/types';
import { useFormContext } from 'react-hook-form';
import { useCustomDialog } from '@front/src/features/dialog';

interface Props extends CellComponentProps {
  name: string;
  confirmProps?: ConfirmView;
  menuIdList?: number[];
  typeList?: AuthorizationType[];
}

export default function UIBuilderTableCellUserSelector(props: Props) {
  const {
    onClick,
    sx = {},
    isEditMode,
    readOnly,
    menuId,
    name,
    isForm,
    onSubmit,
    confirmProps = defaultConfirmProps,
    menuIdList = menuId ? [menuId] : [],
    typeList = [AuthorizationType.W],
  } = props;

  const { setValue, watch } = useFormContext();
  const { confirm } = useCustomDialog();

  const handleUserSelection = (value: UserVO[] | undefined, callback?: () => void) => {
    const selectedUser = value?.[0];
    if (!selectedUser) {
      confirm({
        ...confirmProps,
        afterConfirm: () => {
          setValue(name, undefined);
          callback?.();
        },
      });
    } else {
      setValue(name, selectedUser);
      callback?.();
    }
  };

  const user = watch(name);

  const renderContent = () => {
    if (readOnly || !isEditMode) return user?.name ?? '-';

    return (
      <UserSelectorInputFeature
        key={user?.id}
        value={user?.id}
        onChange={(value) => handleUserSelection(value, isForm ? undefined : onSubmit)}
        readOnly={readOnly || !isEditMode}
        menuIdList={menuIdList}
        typeList={typeList}
      />
    );
  };

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
}

const defaultConfirmProps: ConfirmView = {
  title: '삭제 확인 모달',
  lineBreakChildren: [{ value: '제외 하시겠습니까?' }],
};
