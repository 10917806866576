import { useForm } from 'react-hook-form';
import { YYYY_MM_DD } from '@front/src/utils';
import dayjs from 'dayjs';
import { useEffect } from 'react';

const useWorkScheduleInterimReportDateForm = ({ onUpdate, item }) => {
  const methods = useForm({
    defaultValues: getFormValues(item),
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit((data) => {
    onUpdate({
      id: item.id,
      ...data,
      date: data.date && dayjs(data.date).format(YYYY_MM_DD),
      description: data.description || null,
    });
  });
  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);
  return { methods, onSubmit };
};

export default useWorkScheduleInterimReportDateForm;

const getFormValues = (item) => ({
  date: item.date ?? '',
  description: item.description ?? '',
});
