import React, { useCallback } from 'react';
import Input from '@front/layouts/Input';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  initialize: () => void;
}

export default function UserSelectorSearchForm({ initialize }: Props) {
  const { setValue, control, getValues } = useFormContext();

  const handleKeyDown = async (e) => {
    if (e.isComposing || e.keyCode === 229 || e.key !== 'Enter') return;

    setValue('keyword', getValues('searchKeyword'));
  };

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'searchKeyword',
  });

  const handleSearchClick = useCallback(() => {
    setValue('keyword', value);
    setValue('departmentId', undefined);
  }, [setValue, value]);

  const handleReset = () => {
    initialize();
    setValue('keyword', getValues('searchKeyword'));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
      }}
    >
      <Input
        variant="standard"
        placeholder="전체 부서 사용자 대상 검색"
        onKeyDown={handleKeyDown}
        value={value}
        onChange={onChange}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Button
          shape="basic2"
          onClick={handleReset}
        >
          초기화
        </Button>
        <Button onClick={handleSearchClick}>검색</Button>
      </Box>
    </Box>
  );
}
