import React, { useContext } from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { getLocaleStringIfExist, getValueIfExist, getValueToYNIfExist } from '@front/src/utils';
import { ProjectSalesInfoAbstractContractDetailContext } from '@front/src/features/project-sales-info/features/abstract/features/contract/widgets/detail/provider/context';

export { Detail as ProjectSalesInfoAbstractContractDetail };

const Detail = () => {
  const { detail } = useContext(ProjectSalesInfoAbstractContractDetailContext);
  if (!detail) return <NoResult colSpan={8} />;
  return (
    <TableRow>
      <OldTd>{getValueIfExist(detail.contractDate)}</OldTd>
      <OldTd>{getValueIfExist(detail.status)}</OldTd>
      <OldTd>{getLocaleStringIfExist(detail.totalAmount)}</OldTd>
      <OldTd>{getValueIfExist(detail.schedule)}</OldTd>
      <OldTd>{getValueIfExist(detail.orderCompanyName)}</OldTd>
      <OldTd>{getValueIfExist(detail.clientCompanyName)}</OldTd>
      <OldTd>{getValueToYNIfExist(detail.isCm)}</OldTd>
    </TableRow>
  );
};
