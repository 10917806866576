import React, { memo, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Box, styled, TextareaAutosize } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

export { TextareaWithHookForm as HookFormTextarea };

interface Props {
  name: string;
  multiline?: string;
  minRows?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
  sx?: React.CSSProperties;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  variable?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

const TextareaWithHookForm = memo(({ name, resize, sx, variable, ...rest }: Props) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const style = useMemo(
    () => ({
      border: `1px solid ${ColorPalette._e4e9f2}`,
      width: '100%',
      padding: '6px 10px',
      fontFamily: 'Noto Sans KR',
      borderRadius: '5px',
      resize: resize,
      ...(variable
        ? {}
        : {
            height: '80%',
            minHeight: '10px',
          }),
      ...sx,
    }),
    [resize, sx, variable]
  );
  return (
    <TextareaAutosizeContainer>
      <TextareaAutosize
        value={value}
        onChange={onChange}
        style={style}
        {...rest}
      />
    </TextareaAutosizeContainer>
  );
});

const TextareaAutosizeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '& > textarea::placeholder': {
    color: 'rgb(175 175 176)',
    fontFamily: 'Noto Sans KR',
  },
});
