import React from 'react';
import { Box, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { CustomTable, Thc } from '@front/src/components/ui-builder/table';
import { PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/components/SelectableHeaderTableContent';
import { usePersonalSettingsModalUISettingsModalSettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/modal-settings/useState';
import { useShallow } from 'zustand/react/shallow';

export { SelectableHeader as PersonalSettingsModalUISettingsModalSettingsSelectableHeader };

interface Props {
  authMenuId: number | undefined;
}

const SelectableHeader = ({ authMenuId }: Props) => {
  const { parentId } = usePersonalSettingsModalUISettingsModalSettingsState(
    useShallow((state) => ({
      parentId: state.parentId,
    }))
  );
  if (parentId === undefined) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box>선택가능 헤더</Box>
      <TableContainer>
        <CustomTable>
          <TableHead>
            <TableRow>
              <Thc width="48px">선택</Thc>
              <Thc width="160px">헤더명</Thc>
              <Thc width="160px">자로 사용중인 모</Thc>
              <Thc width="300px">비고</Thc>
            </TableRow>
          </TableHead>
          <TableBody>
            <PersonalSettingsModalUISettingsModalSettingsSelectableHeaderTableContent
              authMenuId={authMenuId}
            />
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Box>
  );
};
