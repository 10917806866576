import type { ProjectSalesInfoBidInfoView } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/view';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-information/types/parameter';
import { createContext } from 'react';
import { noOp } from '@front/common/contants';

interface State {
  d: {
    bidInfoList?: ProjectSalesInfoBidInfoView[];
  };
  h: {
    onDelete: (params: IdDeleteParams) => void;
  };
  isLoading: boolean;
}

export { Context as ProjectSalesInfoBidInfoContext };

const Context = createContext<State>({
  d: {},
  h: {
    onDelete: noOp,
  },
  isLoading: false,
});
