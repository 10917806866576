import { createContext } from 'react';
import { noOp } from '@front/src/utils';

export { context as ProjectSalesInfoSubjectReviewModalComplexInformationDeleteContext };

interface State {
  onDelete: (idList) => void;
}

const context = createContext<State>({
  onDelete: noOp,
});
