import type { FocusEvent } from 'react';
import React, { useContext } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { Box } from '@mui/material';
import Button from '@front/layouts/Button';
import { FormikContext } from 'formik';
import Input from '@front/layouts/Input';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  open: boolean;
  onClose: () => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

export { Index as CancelSubmissionModal };
const Index = ({ open, onClose, onBlur }: Props) => {
  const formik = useContext(FormikContext);
  return (
    <ModalLayout
      title="제안 제출 회수 요청"
      width="500px"
      height="10dvh"
      open={open}
      onClose={onClose}
      children={
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            width: '100%',
            margin: '10px 0px',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              marginBottom: '10px',
            }}
          >
            <Input
              multiline
              key={formik.values.name}
              sx={{
                border: `1px solid ${ColorPalette._e4e9f2}`,
              }}
              variant="outlined"
              placeholder="제출 회수 사유를 작성해주세요."
              defaultValue={formik.values.name ?? ''}
              onBlur={onBlur}
            />
          </Box>
        </Box>
      }
      footer={
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Button
            children="등록"
            onClick={() => {
              formik.handleSubmit();
            }}
            sx={{
              marginRight: '20px',
            }}
          />
          <Button
            shape={'basic3'}
            onClick={onClose}
          >
            취소
          </Button>
        </Box>
      }
    />
  );
};

export default Index;
