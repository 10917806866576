import React, { useCallback } from 'react';
import ButtonGroup from '@front/ost_proposal/view/detail/ButtonGroup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import { ProposalArrangementInformationStatus, ProposalStatus } from '@front/ost_proposal/domain';
import { proposalAction } from '@front/ost_proposal/action';
import { snackbarAction, SnackbarSeverityType } from '@front/components/Snackbar/action';
import type { ProposalStatusUpdateParameter } from '@front/ost_proposal/parameter';
import type { CurrentPage } from '@front/navigate/domain';

interface Props {
  updateStatus: (status: ProposalStatusUpdateParameter) => void;
  currentPage: CurrentPage;
  isContributor: boolean;
  isEstimator: boolean;
}

const ButtonGroupService = ({ updateStatus, currentPage, isContributor }: Props) => {
  const dispatch = useDispatch();
  const grade = useSelector((root: RootState) => root.proposal.gradeByContributor, shallowEqual);
  const arrangementStatus = useSelector(
    (root: RootState) => root.proposal.detail!.arrangementStatus,
    shallowEqual
  );
  const advisor = useSelector((root: RootState) => root.proposal.detail!.advisor, shallowEqual);

  const [contributorList] = useSelector(
    (root: RootState) => [root.proposal.contributorList],
    shallowEqual
  );
  const updateFavorite = useCallback(
    (isFavorite: boolean) => dispatch(proposalAction.updateFavorite(isFavorite)),
    [dispatch]
  );

  const openSnackbar = useCallback(
    (message, severity: SnackbarSeverityType = SnackbarSeverityType.warning) => {
      dispatch(snackbarAction.show({ message, severity }));
    },
    [dispatch]
  );

  const submit = useCallback(() => {
    if (!grade) {
      openSnackbar('가치 등급을 선택해주세요.');
      return;
    }

    for (const contributor of contributorList) {
      if (contributor.contributor === null) {
        openSnackbar('참여자를 선택해주세요.');
        return;
      }
    }

    const rateList = contributorList.map((user) => user.rate);
    for (const rate of rateList) {
      if (typeof rate === 'undefined' || rate <= 0 || rate > 100) {
        openSnackbar('참여자 기여도 확인 후 다시 제출 바랍니다.');
        return;
      }
    }
    const rateSum = rateList.reduce((a?: number, c?: number) => {
      if (typeof a === 'undefined') {
        return 0;
      }
      if (typeof c === 'undefined') {
        return 0;
      }
      return a + c;
    }, 0);
    if (rateSum !== 100) {
      openSnackbar('합산 기여율이 100%가 아닙니다. 확인 후 다시 제출 바랍니다.');
      return;
    }

    const noteList = contributorList.map((user) => user.note);
    for (const note of noteList) {
      if (!note || note.trim() === '') {
        openSnackbar('참여자 역할을 확인 후 다시 제출 바랍니다.');
        return;
      }
    }
    if (arrangementStatus === ProposalArrangementInformationStatus.SKIP) {
      dispatch(proposalAction.updateProposalStatus({ status: ProposalStatus.ESTIMATING }));
    } else {
      if (!advisor) {
        dispatch(proposalAction.updateProposalStatus({ status: ProposalStatus.SUBMISSION }));
        return;
      }
      if (arrangementStatus === ProposalArrangementInformationStatus.COMPLETE) {
        dispatch(proposalAction.updateProposalStatus({ status: ProposalStatus.ESTIMATING }));
      }
    }
  }, [dispatch, contributorList, openSnackbar, grade]);

  const openCampaignInfoModal = useCallback(
    () => dispatch(proposalAction.setCampaignInfoModal(true)),
    [dispatch]
  );

  const requestAdvisor = useCallback(() => dispatch(proposalAction.requestAdvisor()), [dispatch]);

  const onOpenCancelSubmissionModal = useCallback(
    () => dispatch(proposalAction.setProposalSubmissionCancelModal(true)),
    [dispatch]
  );

  return (
    <ButtonGroup
      updateStatus={updateStatus}
      currentPage={currentPage}
      updateFavorite={updateFavorite}
      submit={submit}
      openCampaignInfoModal={openCampaignInfoModal}
      requestAdvisor={requestAdvisor}
      isContributor={isContributor}
      onOpenCancelSubmissionModal={onOpenCancelSubmissionModal}
    />
  );
};

export default ButtonGroupService;
