import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import EditorUI from '@front/src/components/components-with-design/compound/EditorUI';
import EditorCollapseText from '@front/src/components/components-with-design/compound/EditorCollapseText';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<CellComponentProps, 'item' | 'onCloseEditModal'> {
  name: string;
  enableImageUpload?: boolean;
  placeholder?: string;
}

export default function UIBuilderTableCellQuill(props: Props) {
  const {
    isForm,
    readOnly,
    onClick,
    sx,
    isEditMode,
    name,
    onSubmit,
    placeholder = '내용을 작성해주세요',
    enableImageUpload = false,
  } = props;

  const { setValue, getValues } = useFormContext();

  const value = getValues(name);

  const handleChange = (value) => {
    setValue(name, value);
  };

  const update = (value) => {
    handleChange(value);
    onSubmit?.();
  };

  const handleBlur = (value) => {
    update(value);
  };

  const renderContent = () => {
    if (readOnly || !isEditMode) return <EditorCollapseText value={value} />;

    return (
      <EditorUI
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={isForm ? undefined : handleBlur}
        enableImageUpload={enableImageUpload}
        readOnly={readOnly || !isEditMode}
      />
    );
  };

  return (
    <Box
      onClick={() => { onClick && onClick()}}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
