import { useCustomDialog } from '@front/src/features/dialog';
import workReferenceMutation from '@front/src/features/work/features/work/features/reference/query/mutation';
import useWorkReferenceAddTop from '@front/src/features/work/features/work/features/reference/repository/useAddTop';
import useWorkReferenceAddBottom from '@front/src/features/work/features/work/features/reference/repository/useAddBottom';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

const useWorkReferenceTableHeadMutationLogic = ({ dataId, menuId, sectionId }) => {
  const params = { dataId, menuId, sectionId };

  const formContext = useFormContext<UIBuilderTableFieldValues>();

  const { setRecentUpdatedId, toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);

  const { confirm } = useCustomDialog();

  const { mutate: onDeleteAll } = workReferenceMutation.useDeleteAll(params);
  const { run: onAddTop, setCallback: setAddTopCallback } = useWorkReferenceAddTop(params);
  setAddTopCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const { run: onAddBottom, setCallback: setAddBottomCallback } = useWorkReferenceAddBottom(params);
  setAddBottomCallback({
    onSuccess: ({ data }) => {
      setRecentUpdatedId(data);
      toggleEditOpenId(data);
    },
  });

  const onRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '선택한 내용을 전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDeleteAll();
      },
    });
  };

  return {
    onAddTop,
    onAddBottom,
    onRemoveAll,
  };
};

export default useWorkReferenceTableHeadMutationLogic;
