import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { affiliatedCompanyWebHardQuery } from '@front/src/features/affiliated-company/features/outline/features/web-hard/query/query';
import { affiliatedCompanyWebHardMutation } from '@front/src/features/affiliated-company/features/outline/features/web-hard/query/mutation';

export const affiliatedCompanyWebHardRepository = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = affiliatedCompanyWebHardQuery.useListGet(id, menuId);
    return {
      data,
      isLoading,
    };
  },
  useCreate: (menuId) => {
    const { mutate } = affiliatedCompanyWebHardMutation.useCreate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: (menuId) => {
    const { mutate } = affiliatedCompanyWebHardMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = affiliatedCompanyWebHardMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
