import React, { memo, useCallback } from 'react';
import { TextareaAutosize } from '@mui/material';

import DataFieldWithLabel from '@front/layouts/DataFieldLabel';
import Checkbox from '@front/layouts/Checkbox';
import { ColorPalette } from '@front/assets/theme';
import { useDispatch } from 'react-redux';
import { campaignAction } from '@front/ost_campaign/action';

interface Props {
  notice?: string;
  isNoticeExposed?: boolean;
}

const Notice = ({ notice, isNoticeExposed }: Props) => {
  const dispatch = useDispatch();
  const onBlur = useCallback(
    (e) => {
      const value = e.target.value ?? '';
      dispatch(
        campaignAction.updateNotice({
          notice: value,
        })
      );
    },
    [dispatch]
  );
  const onChange = useCallback(
    (e) => {
      dispatch(
        campaignAction.updateIsNoticeExposed({
          isNoticeExposed: e.target.checked,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <DataFieldWithLabel
        label="공지사항"
        labelSX={{ minWidth: '80px' }}
      >
        <TextareaAutosize
          key={notice}
          style={{
            border: `1px solid ${ColorPalette._e4e9f2}`,
            marginTop: '10px',
            width: '100%',
            minHeight: '10px',
            padding: '10px',
            fontFamily: 'Noto Sans KR',
            resize: 'none',
          }}
          minRows={2}
          placeholder="본 영역에 공지사항을 입력하면 제안 본문 상단(제안/심사/투찰)에 입력한 내용이 사용자에게 노출됩니다. 노출 여부는 우측 체크 받스를 통해 제어할 수 있습니다."
          defaultValue={notice}
          onBlur={onBlur}
        />
      </DataFieldWithLabel>

      <DataFieldWithLabel
        label="노출여부"
        labelSX={{ minWidth: '80px' }}
      >
        <Checkbox
          value={isNoticeExposed ?? false}
          checked={isNoticeExposed ?? false}
          onChange={onChange}
        />
      </DataFieldWithLabel>
    </>
  );
};

const NoticeForm = memo(Notice);
export default NoticeForm;
