import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import type { AccountingSettingsManagementAccountingAccountTableCategoryView } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/types/view';
import { AccountingSettingsManagementAccountingAccountTableCategoryListContext } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/widgets/category-list/provider/context';
import { useAccountingSettingsManagementAccountingAccountTableState } from '@front/src/features/accounting/features/settings/features/management-accounting-account/features/table/useState';
import { useShallow } from 'zustand/react/shallow';

export { LargeCategoryUpdateInput as AccountingSettingsManagementAccountingAccountTableLargeCategoryUpdateInput };

interface Props {
  item: AccountingSettingsManagementAccountingAccountTableCategoryView;
}

const LargeCategoryUpdateInput = ({ item }: Props) => {
  const { onUpdate } = useContext(
    AccountingSettingsManagementAccountingAccountTableCategoryListContext
  );
  const { updateId } = useAccountingSettingsManagementAccountingAccountTableState(
    useShallow((state) => ({
      updateId: state.updateId,
    }))
  );
  const ref = useRef<HTMLDivElement>(null);
  const methods = useForm({
    values: {
      id: item.id,
      sequence: item.sequence,
      name: item.name,
      alias: item.alias,
      parentId: null,
      depth: 0,
      isBudgetManagementCategory: item.isBudgetManagementCategory,
      isIncomeExpenseReconciliation: item.isIncomeExpenseReconciliation,
    },
  });
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  useEffect(() => {
    if (!updateId) return;
    (ref.current?.children[0].children[0].children[0] as HTMLInputElement).focus();
  }, [updateId]);
  return (
    <div ref={ref}>
      <FormProvider {...methods}>
        <HookFormInput
          name="name"
          onKeyDown={handleKeyDown}
        />
      </FormProvider>
    </div>
  );
};
