import { useShallow } from 'zustand/react/shallow';
import useNoteStore from '@front/src/features/note/useState';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useApprovalDocumentStore from '@front/src/features/drawer-approval-document/useState';

const useIdGroup = () => {
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const dataId = sessionStorage.getItem('dataId');
  const { rowId, sectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );
  const noteId = useGlobalNavBarState((state) => state.noteId);
  const documentId = useApprovalDocumentStore((state) => state.documentId);

  return {
    menuId: Number(menuId) || 0,
    dataId: Number(dataId) || 0,
    sectionId,
    rowId,
    noteId,
    documentId,
  };
};

export default useIdGroup;
