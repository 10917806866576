import { projectSalesInfoContractEarningsQuery } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/query';

export const projectSalesInfoContractEarningsRepository = {
  useContractEarningsStatusGet: (id?: number, menuId?) => {
    const { data, isLoading } = projectSalesInfoContractEarningsQuery.useContractEarningsStatusGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
  useContractEarningsGet: (id: number, menuId) => {
    const { data, isLoading } = projectSalesInfoContractEarningsQuery.useContractEarningsGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
    };
  },
};
