import { SalesAccountingAmountType } from '@front/src/features/accounting/features/upload/features/sales-info/types/domain';
import type { IAlertChildren } from '@front/src/features/dialog/types';

export const descriptionObjectFromProjectSalesManagementPerformanceModalForAlert: {
  [s in SalesAccountingAmountType]: IAlertChildren[];
} = {
  [SalesAccountingAmountType.ORDER]: [
    { value: '수주 실적' },
    { value: '금액 : 영업정보-계약탭-계약내용-총액' },
    {
      value: '시기(월) : 1. 영업정보-계약탭-계약내용-수주일이 해당월인 경우의 모든 행 ',
      sx: {
        fontSize: '14px',
      },
    },
  ],
  [SalesAccountingAmountType.REVIEW]: [
    { value: '구검 실적' },
    { value: '금액 : 영업정보-계약탭-계약내용-구검비' },
    {
      value: '시기(월) : 1. 영업정보-계약탭-계약내용-수주일이 해당월인 경우의 모든 행 ',
      sx: {
        fontSize: '14px',
      },
    },
  ],
  [SalesAccountingAmountType.NET_ORDER]: [
    { value: '순수주 실적' },
    { value: '금액 : 영업정보-계약탭-계약내용-풍동금액' },
    {
      value: '시기(월) : 1. 영업정보-계약탭-계약내용-수주일이 해당월인 경우의 모든 행 ',
      sx: {
        fontSize: '14px',
      },
    },
  ],
  [SalesAccountingAmountType.COLLECTION]: [
    { value: '수금 실적' },
    {
      value: '금액 : 영업정보-수금탭-각기성단계-수금구검비',
    },
    { value: '시기 : 영업정보-수금탭-각기성단계-입금일' },
  ],
  [SalesAccountingAmountType.SAVED_REVIEW]: [
    { value: '적립구검 예상' },
    {
      value:
        '금액 : 영업정보-수금탭-각기성단계-입금일이 없는 기성구분의 구검비의 합(단,수금가능성80%만)',
    },
    { value: '시기 (월) : 영업정보-수금탭-각기성단계-한풍예측일' },
  ],
  [SalesAccountingAmountType.NET_COLLECTION]: [
    { value: '순수금 실적' },
    {
      value: '금액 : 영업정보-수금탭-각기성단계-순수금액',
    },
    { value: '시기 : 영업정보-수금탭-각기성단계-입금일' },
  ],
  [SalesAccountingAmountType.NET_ORDER_BALANCE]: [
    { value: '순수주잔고 실적' },
    { value: '금액 : 미수금 실적 + 응당예정금 실적 + 미착수금 실적' },
    { value: '시기 : 오늘 기준' },
  ],
  [SalesAccountingAmountType.UNPAID_AMOUNT]: [
    { value: '미수금 실적' },
    { value: '금액 : 영업정보-수금탭-기성현황-순미수금액' },
    { value: '시기 : 오늘 기준' },
  ],
  [SalesAccountingAmountType.EXPECTED_AMOUNT]: [
    { value: '응당예정금 실적' },
    { value: '금액 : 영업정보-수금탭-기성현황-순응당예정금' },
    { value: '시기 : 오늘 기준' },
  ],
  [SalesAccountingAmountType.UNCLAIMED_AMOUNT]: [
    { value: '미착수금 실적' },
    { value: '금액 : 영업정보-수금탭-기성현황-순미착수금' },
    { value: '시기 : 오늘 기준' },
  ],
};
