import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoContractReviewContext } from '@front/src/features/project-sales-info/features/contract/features/reivew/widgets/context';
import { ProjectSalesInfoContractReviewTableRow } from '@front/src/features/project-sales-info/features/contract/features/reivew/components/review-table-row';

export { ReviewTableBody as ProjectSalesInfoContractReviewTableBody };

const ReviewTableBody = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoContractReviewContext);
  if (list?.length === 0) {
    return (
      <NoResult
        height="52px"
        colSpan={12}
      />
    );
  }
  return (
    <>
      {list?.map((item, index) => (
        <ProjectSalesInfoContractReviewTableRow
          key={item.id}
          item={item}
          index={index}
        />
      ))}
    </>
  );
};
