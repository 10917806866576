import { projectSalesInfoSubjectReviewShapeRatioRepository } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/repository';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';

export { useLogic as useProjectSalesInfoSubjectReviewShapeRatioCreateButtonLogic };

const useLogic = (menuId) => {
  const { setId, setIsOpen } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const { run: onCreate, setCallback } =
    projectSalesInfoSubjectReviewShapeRatioRepository.useCreate(menuId);
  setCallback({
    onSuccess: (id) => {
      setId(id);
      setIsOpen(true);
    },
  });
  return { onCreate };
};
