import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { proposalAction } from '@front/ost_proposal/action';
import { ApprovalModal } from '@front/ost_proposal/view/modal/approval';
import type { RootState } from '@front/services/reducer';
import type { ProposalApprovalParameter } from '@front/ost_proposal/parameter';
import { FormikProvider, useFormik } from 'formik';
import { ApprovalResult } from '@front/ost_proposal/domain';

const ApprovalModalService = () => {
  const dispatch = useDispatch();
  const onAdd = useCallback(
    (params: ProposalApprovalParameter) => dispatch(proposalAction.updateProposalApproval(params)),
    [dispatch]
  );
  const onClose = useCallback(() => dispatch(proposalAction.setApprovalModal(false)), [dispatch]);
  const open = useSelector((root: RootState) => root.proposal.approvalModalOpen, shallowEqual);
  const [approvalList] = useSelector(
    (root: RootState) => [root.proposal.approvalList],
    shallowEqual
  );
  const approvalDocument = useSelector(
    (root: RootState) => root.proposal.approvalDocument,
    shallowEqual
  );

  const formik = useFormik<ProposalApprovalParameter>({
    initialValues: {
      result: ApprovalResult.APPROVED,
    } as ProposalApprovalParameter,
    onSubmit: (values) => {
      onAdd(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    dispatch(proposalAction.getApprovalList());
    dispatch(proposalAction.getApprovalDocument());
    return () => {
      dispatch(proposalAction.setApprovalList([]));
      dispatch(proposalAction.setApprovalDocument(undefined));
    };
  }, [dispatch]);
  return (
    <>
      {approvalList.length > 0 && approvalDocument && (
        <FormikProvider value={formik}>
          <ApprovalModal
            onClose={onClose}
            open={open}
            approvalList={approvalList}
            approvalDocument={approvalDocument}
          />
        </FormikProvider>
      )}
    </>
  );
};
export default ApprovalModalService;
