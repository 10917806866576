import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { affiliatedCompanyPersonGiftHistoryApi } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/query/api';
import type { AffiliatedCompanyPersonGiftHistoryUpdateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/gift-history/types/parameter';

export const affiliatedCompanyPersonGiftHistoryMutation = {
  usePersonGiftHistoryCreate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) =>
        affiliatedCompanyPersonGiftHistoryApi.createPersonGiftHistory(id, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'gift-history'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonGiftHistoryUpdate: (menuId) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyPersonGiftHistoryUpdateParameter) =>
        affiliatedCompanyPersonGiftHistoryApi.updatePersonGiftHistory(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'gift-history'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  usePersonGiftHistoryDelete: (menuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AffiliatedCompanyIdListDeleteParameter) =>
        affiliatedCompanyPersonGiftHistoryApi.deletePersonGiftHistory(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['affiliated-company', 'person', 'gift-history'],
        });
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
};
