import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

import { AccountingUploadEcountDataUploadContext } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/provider/context';
import { useAccountingUploadEcountDataUploadLogic } from '@front/src/features/accounting/features/upload/features/ecount-data/widgets/upload/provider/useLogic';

export { Provider as AccountingUploadDataUploadProvider };

interface Props {
  children: ReactNode;
  menuId?: number;
}

const Provider = ({ children, menuId }: Props) => {
  const { onTexAccountingCreate, onBankAccountCreate, onDailyReportCreate, isLoading } =
    useAccountingUploadEcountDataUploadLogic(menuId);
  const value = useMemo(
    () => ({
      onTexAccountingCreate,
      onBankAccountCreate,
      onDailyReportCreate,
      isLoading,
    }),
    [onTexAccountingCreate, onBankAccountCreate, onDailyReportCreate, isLoading]
  );
  return (
    <AccountingUploadEcountDataUploadContext.Provider value={value}>
      {children}
    </AccountingUploadEcountDataUploadContext.Provider>
  );
};
