import React, { useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import {
  convertBooleanToYNIfExistForForm,
  convertEmptyToNullForParameter,
  convertYNToBooleanForParameter,
  getValueIfExist,
  YNOptionList,
} from '@front/src/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { ProjectSalesInfoSubjectReviewModalExperimentTargetContext } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/experiment-target/widgets/context';
import type { ProjectExperimentInformationView } from '@front/src/features/project-sales-info/features/subject-review/features/modal/types/view';
import { shallow } from 'zustand/shallow';
import { useProjectSalesInfoSubjectReviewModalState } from '@front/src/features/project-sales-info/features/subject-review/features/modal/useState';
import { useProjectSalesInfoSubjectReviewShapeRatioState } from '@front/src/features/project-sales-info/features/subject-review/features/shape-ratio/useState';
import { useShallow } from 'zustand/react/shallow';

export { ExperimentTargetTableRow as ProjectSalesInfoSubjectReviewModalExperimentTargetTableRow };

const getValues = (detail?: ProjectExperimentInformationView) => ({
  hasWindEnvironment: convertBooleanToYNIfExistForForm(detail?.hasWindEnvironment),
  hasBuildingWind: convertBooleanToYNIfExistForForm(detail?.hasBuildingWind),
});

const ExperimentTargetTableRow = () => {
  const { onUpdate, detail } = useContext(
    ProjectSalesInfoSubjectReviewModalExperimentTargetContext
  );
  const methods = useForm({
    values: getValues(detail),
  });
  const { id } = useProjectSalesInfoSubjectReviewModalState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoSubjectReviewShapeRatioState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const onSubmit = methods.handleSubmit((data) => {
    onUpdate({
      id,
      gettingDesignDate: convertEmptyToNullForParameter(detail?.gettingDesignDate),
      experimentStandardCode: convertEmptyToNullForParameter(detail?.experimentStandardCode),
      countOfSiteModel: convertEmptyToNullForParameter(detail?.countOfSiteModel),
      hasConditionOfSpecialWindExperiment: convertYNToBooleanForParameter(
        detail?.hasConditionOfSpecialWindExperiment
      ),
      isNearCoastBy3Km: convertYNToBooleanForParameter(detail?.isNearCoastBy3Km),
      basicWindSpeed: convertEmptyToNullForParameter(detail?.basicWindSpeed),
      file: detail?.windSpeedMapPdf,

      hasWindEnvironment: convertYNToBooleanForParameter(data.hasWindEnvironment),
      hasBuildingWind: convertYNToBooleanForParameter(data.hasBuildingWind),
      isChanged: false,
    });
  });
  return (
    <FormProvider {...methods}>
      <TableRow>
        <OldTd>{getValueIfExist(detail?.complexExperimentInfo?.experiment?.name)}</OldTd>
        <OldTd>
          {getValueIfExist(detail?.complexExperimentInfo?.experiment?.windForceCount?.count)}
        </OldTd>
        <OldTd>
          {getValueIfExist(detail?.complexExperimentInfo?.experiment?.windPressureCount?.count)}
        </OldTd>
        <OldTd>
          <HookFormSelect
            height="60px"
            isDefaultPossible
            defaultLabel="선택"
            name="hasWindEnvironment"
            optionList={YNOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
        <OldTd>
          {getValueIfExist(detail?.complexExperimentInfo?.experiment?.airForceCount?.count)}
        </OldTd>
        <OldTd>
          <HookFormSelect
            height="60px"
            isDefaultPossible
            defaultLabel="선택"
            name="hasBuildingWind"
            optionList={YNOptionList}
            onSubmit={onSubmit}
            disabled={readOnly}
          />
        </OldTd>
      </TableRow>
    </FormProvider>
  );
};
