import {Box, styled} from '@mui/material';
import React, {Suspense} from 'react';
import Drawer from '@front/layouts/Drawer';
import {ColorPalette} from '@front/assets/theme';
import {isMobileDevice} from '@front/util/PwaUtil';
import {GlobalNavBarListWidget} from '@front/src/features/global-nav-bar/widgets/list/widget';
import NotificationButtonRoute from '@front/src/app/component/notificationButton';
import AccountButtonRoute from '@front/src/app/component/accountButton';
import {GlobalNavBarClosedChildren} from '@front/src/features/global-nav-bar/components/closed-children';
import {LoadingSpinner} from '@front/src/components/loading-spinner';
import ApprovalButton from '@front/src/app/view/ApprovalButton';
import {GlobalNavBarHamburger} from "@front/src/features/global-nav-bar/components/hamburger";

interface Props {
  open: boolean;
}

const CustomScrollBox = styled(Box)({
  overflowY: 'auto',
  maxHeight: 'calc(100% - 10rem)',
  scrollbarGutter: 'stable',
  '&::-webkit-scrollbar': {
    width: '1rem',
  },
  '::-webkit-scrollbar-track': {
    background: ColorPalette.background.bg04 /* color of the tracking area */,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: ColorPalette.background.bg06,
  },
});

export default function MenuDrawer({ open }: Readonly<Props>) {
  return (
    <Drawer
      open={open}
      openedWidth={isMobileDevice() ? window.screen.width : 230}
      closedWidth={52}
      closedChildren={<GlobalNavBarClosedChildren />}
      sx={{
        backgroundColor: 'var(--design-v-10-light-background-bg-04, #1F314E);',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0.8rem 0 0.6rem 0',
          borderBottom: '1px solid #49576F',
          height: '4.6rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '1rem'
          }}
        >
          <Suspense
            fallback={
              <LoadingSpinner
                width="2rem"
                height="2rem"
                circleSize={20}
              />
            }
          >
            <NotificationButtonRoute />
          </Suspense>
          <Suspense
            fallback={
              <LoadingSpinner
                width="2rem"
                height="2rem"
                circleSize={20}
              />
            }
          >
            <ApprovalButton />
          </Suspense>
          <AccountButtonRoute />
          {/*<LogoutButtonRoute />*/}
          <GlobalNavBarHamburger />
        </Box>
      </Box>
      <CustomScrollBox
        sx={{
          display: 'flex',
          overflowY: 'auto',
          height: '100%',
          maxHeight: `calc(100% - 5rem)`,
          scrollbarGutter: 'stable',
          '&::-webkit-scrollbar': {
            width: '1rem',
            backgroundColor: ColorPalette._697183,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: ColorPalette._4c576d,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            padding: '1rem',
            width: '100%',
            height: '100%',
            alignContent: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <GlobalNavBarListWidget />
        </Box>
      </CustomScrollBox>
    </Drawer>
  );
}
