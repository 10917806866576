import React, { useCallback } from 'react';
import Input from '@front/layouts/Input';
import { useController, useFormContext } from 'react-hook-form';
import { AMOUNT_CHANGE_REGEX } from '@front/src/features/affiliated-company/utils/constant';
import { onChangeValidator } from '@front/src/components/input-with-hook-form/onChangeValidator';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import classes from '@front/src/features/project-sales-info/features/estimation/components/hook-form.module.scss';

export { ReviewAmountHookForm as ProjectSalesInfoEstimationReviewAmountHookForm };

interface Props {
  readOnly?: boolean;
}

const ReviewAmountHookForm = ({ readOnly }: Props) => {
  const { control, setValue, getValues } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'reviewAmount' });
  const handleChange = useCallback(
    (e) => {
      const newValue = onChangeValidator(e, value, InputType.EST_AMOUNT);
      onChange({ ...e, target: { ...e.target, value: newValue } });
      const refinedValue = newValue === '-' ? '0' : newValue;
      const refinedExperimentAmount =
        getValues('experimentAmount') === '-' ? '0' : getValues('experimentAmount');
      const totalAmount = (
        +refinedValue.replace(/,/g, '') + +refinedExperimentAmount.replace(/,/g, '')
      )
        .toString()
        .replace(AMOUNT_CHANGE_REGEX, ',');
      setValue('totalAmount', totalAmount === '0' ? '' : totalAmount);
    },
    [setValue, value, getValues, onChange]
  );
  return (
    <div className={classes.container}>
      <Input
        variant="outlined"
        value={value}
        onChange={handleChange}
        disabled={readOnly}
      />
    </div>
  );
};
