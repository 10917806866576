import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { WorkReferenceView } from '@front/src/features/work/features/work/features/reference/types/view';
import workReferenceQuery from '@front/src/features/work/features/work/features/reference/query/query';
import WorkReferenceTableRow from '@front/src/features/work/features/work/features/reference/components/TableRow';

export default function WorkReferenceTableBody(props: Readonly<UIBuilderTableBodyProps>) {
  const { sectionId, dataId, menuId } = props;
  const { data: list } = workReferenceQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });
  return (
    <UIBuilderTableBody<WorkReferenceView>
      {...props}
      list={list}
      RowComponent={WorkReferenceTableRow}
    />
  );
}
