import type { ProjectContractEarningsConsortiumCompanyView } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/views';

export const contractEarningsHeadList = [
  '계약금액',
  '수금상태',
  '수금액',
  '순수금액',
  '수금비율',
  '미수금액',
  '순미수금액',
  '응당예정금',
  '순응당예정금',
  '미착수금',
  '순미착수금',
];

export const contractEarningsItemHeadList = [
  '',
  '기성구분',
  '발주처명',
  '기성명',
  '지급비율',
  '지급조건',
  '금액',
  '순금액',
  '수금액',
  '고객예상일',
  '한풍예측일',
  '수금가능성',
];

export const convertToDepositCompanyOptionList = (
  data?: ProjectContractEarningsConsortiumCompanyView[]
) => {
  const companyList = data
    ?.map((item) => {
      const filteredData = Object.keys(item).filter(
        (key) => key !== 'recommenderName' && key !== 'id' && item[key]?.name !== undefined
      );
      return filteredData.map((key) => item[key]);
    })
    .flat();
  const newCompanyList = Array.from(new Map(companyList?.map((obj) => [obj.id, obj])).values());
  return newCompanyList.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
};

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const contractEarningsApiUrl = '/api/project';
export const smProjectCollectionApiUrl = '/api/project/contract-earnings';

export const vatRatioKeyword = '영업관리-부가세';
