import type { AddParameter, UIBuilderParameter } from '@front/src/types/parameter';
import { type IdsDataParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { MutateOptions } from 'react-query';
import { useQueryClient } from 'react-query';
import { AddPosition } from '@front/src/utils';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';

interface Props {
  onAdd: (
    params: AddParameter,
    options?: MutateOptions<'', Error, AddParameter, unknown> | undefined
  ) => void;
  onDelete: (
    params: IdsDataParameter,
    options?: MutateOptions<'', Error, IdsDataParameter, unknown> | undefined
  ) => void;
  commonParams: UIBuilderParameter;
  queryKey: (string | number | undefined)[];
}

const useUIBuilderTableHeadMutation = ({ onAdd, onDelete, commonParams, queryKey }: Props) => {
  const { confirm } = useCustomDialog();
  const formContext = useFormContext<UIBuilderTableFieldValues>();
  const { setValue } = formContext;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);
  const queryClient = useQueryClient();

  const handleAdd = (position: AddPosition) => {
    onAdd(
      {
        position,
      },
      {
        onSuccess: (data, variables) => {
          const prevData: any[] | undefined = queryClient.getQueryData(queryKey);

          const id =
            variables.position === AddPosition.TOP ? prevData?.[0]?.id : prevData?.at(-1)?.id;
          if (id) {
            setValue('recentUpdatedId', id);
            toggleEditOpenId(id);
          }
        },
      }
    );
  };

  const handleDelete = () => {
    onDelete(
      { ...commonParams },
      {
        onSuccess: async () => {
          setValue('recentUpdatedId', undefined);
        },
      }
    );
  };

  const handleDeleteAfterConfirm = () => {
    confirm({
      lineBreakChildren: [{ value: '전체 삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        handleDelete();
      },
    });
  };

  return {
    onAdd: handleAdd,
    onDelete: handleDeleteAfterConfirm,
  };
};

export default useUIBuilderTableHeadMutation;
