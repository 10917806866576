// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ug7U5se19WptNQbMRrxi{width:2%}.FyAxCuRRVPLb0nxBHiwX{width:4%}._2rhO8KUUwP2WI4YQLmX{width:6%}.Hm4mJZPr_BQ6OB3K5h2w{width:8%}.n_XQuiGEnalRFvROorOW{width:10%}`, "",{"version":3,"sources":["webpack://./front/src/features/project-sales-info/features/bid/features/bid-result/components/bid-result-table.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,QAAA,CAGF,sBACE,SAAA","sourcesContent":[".width__2 {\r\n  width: 2%;\r\n}\r\n\r\n.width__4 {\r\n  width: 4%;\r\n}\r\n\r\n.width__6 {\r\n  width: 6%;\r\n}\r\n\r\n.width__8 {\r\n  width: 8%;\r\n}\r\n\r\n.width__10 {\r\n  width: 10%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"width__2": `Ug7U5se19WptNQbMRrxi`,
	"width__4": `FyAxCuRRVPLb0nxBHiwX`,
	"width__6": `_2rhO8KUUwP2WI4YQLmX`,
	"width__8": `Hm4mJZPr_BQ6OB3K5h2w`,
	"width__10": `n_XQuiGEnalRFvROorOW`
};
export default ___CSS_LOADER_EXPORT___;
