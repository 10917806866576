import axios from 'axios';
import { bidURLAPI } from '@front/src/features/project-sales-info/features/bid/utils/constant';
import type { IdDeleteParams } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getBidResultList: (id: number) => `${bidURLAPI}/${id}/bid-result`,
  deleteBidResult: () => `${bidURLAPI}/bid-result`,
};

export const projectSalesInfoBidResultApi = {
  getBidResultList: async (id: number, menuId) => {
    const { data } = await axios.get(url.getBidResultList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  deleteBidResult: async (params: IdDeleteParams, menuId) => {
    await axios.delete(url.deleteBidResult(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
};
