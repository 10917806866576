import { createContext } from 'react';

export { context as ProjectSalesInfoSubjectReviewModalKaKaoMapContext };

interface State {
  d: {
    address?: string;
  };
  h: {
    remove: () => void;
  };
}

const context = createContext<State>({
  d: {},
  h: {
    remove: () => {},
  },
});
