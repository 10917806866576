import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { uISettingsTypeList } from '@front/src/features/personal-settings-modal/features/ui-settings/utils/constant';
import { useShallow } from 'zustand/react/shallow';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { usePersonalSettingsModalUISettingsState } from '@front/src/features/personal-settings-modal/features/ui-settings/useState';

export { TabList as PersonalSettingsModalUISettingsTabList };

const TabList = () => {
  const { setType, type } = usePersonalSettingsModalUISettingsState(
    useShallow((state) => ({
      setType: state.setType,
      type: state.type,
    }))
  );
  const { isDirty, setOpen, setDescriptionList, setTitle } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      isDirty: state.isDirty,
      setDescriptionList: state.setDescriptionList,
      setTitle: state.setTitle,
    }))
  );
  const handleClick = useCallback(
    (value) => {
      if (type === value) return;
      if (isDirty) {
        setTitle('탭 이동 확인');
        setDescriptionList([
          '저장되지 않은 변경사항은 반영되지 않습니다.',
          '탭을 이동하시겠습니까?',
        ]);
        setOpen(true, () => {
          setType(value);
        });
        return;
      }
      setType(value);
    },
    [setType, type, isDirty, setOpen, setDescriptionList, setTitle]
  );
  const getActiveSx = useCallback(
    (value) => {
      const active = type === value;
      return {
        backgroundColor: active ? ColorPalette._ffffff : ColorPalette._e4e9f2,
        cursor: active ? 'default' : 'pointer',
      };
    },
    [type]
  );
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        height: '38px',
        padding: '0 20px',
        borderBottom: `2px solid ${ColorPalette._e4e9f2}`,
      }}
    >
      {uISettingsTypeList.map(({ label, value }) => (
        <Box
          key={value}
          onClick={() => handleClick(value)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100px',
            height: '38px',
            border: `2px solid ${ColorPalette._e4e9f2}`,
            borderRadius: '5px 5px 0 0',
            borderBottom: 'none',
            color: ColorPalette._252627,
            fontSize: '14px',
            fontWeight: 'inherit',
            textDecoration: 'none',
            ...getActiveSx(value),
          }}
        >
          {label}
        </Box>
      ))}
    </Box>
  );
};
