import React, { memo, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import type { OstMemoSearchQuery } from '@front/ost/query';
import { useDispatch } from 'react-redux';
import { ostAction } from '@front/ost/action';
import OstMemoSearchForm from '@front/ost/view/memo/searchForm';

const MemoSearchFormService = () => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (query: OstMemoSearchQuery) => {
      dispatch(ostAction.setMemoFilter(query));
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      keyword: '',
    } as OstMemoSearchQuery,
    onSubmit: onSubmit,
  });

  const onKeyDown = useCallback(
    (e) => {
      if (e.key.toLowerCase() === 'enter') {
        const value = (e.target as HTMLInputElement).value ?? '';
        formik.setFieldValue('keyword', value);
        formik.handleSubmit();
      }
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <OstMemoSearchForm onKeyDown={onKeyDown} />
    </FormikProvider>
  );
};

const OstMemoSearchFormService = memo(MemoSearchFormService);

export default OstMemoSearchFormService;
