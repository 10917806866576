import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export { CurrentName as PersonalSettingsModalUISettingsHeaderSettingsTableContentCurrentName };

interface Props {
  index: number;
}

const CurrentName = ({ index }: Readonly<Props>) => {
  const { control } = useFormContext();
  const currentName = useWatch({ name: `list.${index}.currentName`, control });
  return <>{currentName ?? '-'}</>;
};
