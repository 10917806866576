import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import type { ProjectDivisionParams } from '@front/src/features/project-sales-info/features/project-division/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  createCode: (id: number) => `${projectSalesInfoURL}/${id}/summary/code`,
  updateProjectDivision: (id: number) => `${projectSalesInfoURL}/${id}/summary`,
  getProjectDivision: (id?: number) => `${projectSalesInfoURL}/${id}/summary`,
};

export const projectSalesInfoProjectDivisionApi = {
  updateProjectDivision: async (params: ProjectDivisionParams, menuId) =>
    await axios.put(url.updateProjectDivision(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getProjectDivision: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getProjectDivision(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  createCode: async (id: number, menuId) =>
    await axios.post(url.createCode(id), null, {
      headers: getMenuIdHeaders(menuId),
    }),
};
