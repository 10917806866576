import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { useAffiliatedCompanyCharacterOpinionLogic } from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/useLogic';
import { AffiliatedCompanyCharacterOpinionContext } from '@front/src/features/affiliated-company/features/character/features/opinion/widgets/context';
import { AffiliatedCompanyCharacterOpinionButton } from '@front/src/features/affiliated-company/features/character/features/opinion/components/opinion-button';
import { AffiliatedCompanyCharacterOpinionTable } from '@front/src/features/affiliated-company/features/character/features/opinion/components/opinion-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AffiliatedCompanyCharacterOpinionWidget = ({
  name,
  menuId,
}: Readonly<SectionComponentProps>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { characterList },
    h: { setIdList, onCharacterCreate, onCharacterUpdate, onCharacterDelete },
    isLoading,
    idList,
  } = useAffiliatedCompanyCharacterOpinionLogic(menuId);
  const value = useMemo(
    () => ({
      d: { characterList },
      h: { onCharacterCreate, onCharacterUpdate, onCharacterDelete, setIdList },
      isLoading,
      idList,
    }),
    [
      characterList,
      isLoading,
      setIdList,
      onCharacterCreate,
      onCharacterUpdate,
      onCharacterDelete,
      idList,
    ]
  );
  return (
    <AffiliatedCompanyCharacterOpinionContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyCharacterOpinionButton />
        </Header>
        <Body>
          <AffiliatedCompanyCharacterOpinionTable />
        </Body>
      </Layout>
    </AffiliatedCompanyCharacterOpinionContext.Provider>
  );
};

export default AffiliatedCompanyCharacterOpinionWidget;
