import { useQuery } from 'react-query';
import { projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/query/api';
import type { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/parameter';
import type { PersonModalShortView } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/types/view';

export const projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalQuery = {
  usePersonListGet: (
    params: ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonSearchParameter,
    menuId
  ) => {
    const { data, isLoading } = useQuery<PersonModalShortView[]>({
      queryKey: [
        'project',
        'sales-info',
        'affiliated-company',
        'affiliated-company-person',
        'list',
        params,
      ],
      queryFn: () =>
        projectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalApi.getPersonList(
          params,
          menuId
        ),
      cacheTime: 0,
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
