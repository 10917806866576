import type { FocusEvent, KeyboardEvent } from 'react';
import React, { useContext } from 'react';
import { FormikContext } from 'formik';
import { Box } from '@mui/material';

import Input from '@front/layouts/Input';

interface Props {
  onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}
const FormAddCampaign = ({ onKeyUp, onBlur }: Props) => {
  const formik = useContext(FormikContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        width: '100%',
        margin: '10px 0px',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <Input
          key={formik.values.name}
          sx={{
            marginTop: '10px',
          }}
          variant="outlined"
          placeholder="신규 캠페인명을 입력해주세요."
          defaultValue={formik.values.name ?? ''}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
};

export default FormAddCampaign;
