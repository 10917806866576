import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoAffiliatedCompanyWebHardContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/context';
import { ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRow } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/web-hard-table-body-row';

export { WebHardTableBody as ProjectSalesInfoAffiliatedCompanyWebHardTableBody };
const WebHardTableBody = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardContext);
  if (list?.length === 0) {
    return (
      <NoResult
        colSpan={10}
        message="등록된 웹하드가 없습니다"
      />
    );
  }
  return <ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRow />;
};
