import React, { useContext } from 'react';
import SearchForm, { SearchFormField } from 'layouts/SearchForm';
import {
  accountStateTypeList,
  dateTypeList,
  hiredTypeList,
  keywordTypeList,
} from 'personnel/query';
import CheckboxField from 'components/CheckboxField';
import DepartmentCheckboxField from 'components/DepartmentCheckboxField';
import TextBox from 'layouts/Text';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Input from 'layouts/Input';
import { FormikContext } from 'formik';
import { Box, MenuItem } from '@mui/material';
import { sexCategoryList } from 'user/domain';
import Select from 'layouts/Select';

interface Props {
  menuId?: number | undefined;
}

export default function SearchBox({ menuId }: Props) {
  const formik = useContext(FormikContext);
  return (
    <SearchForm>
      <SearchFormField
        label="성별"
        children={
          <CheckboxField
            disableLabel
            name="sex"
            label="성별"
            options={sexCategoryList.map((item) => ({
              key: item as string,
              text: item,
            }))}
          />
        }
      />
      <SearchFormField
        label="입사구분"
        children={
          <CheckboxField
            disableLabel
            name="hiredType"
            label="입사구분"
            options={hiredTypeList}
          />
        }
      />
      <SearchFormField
        label="계정 상태"
        children={
          <CheckboxField
            disableLabel
            name="status"
            label="계정상태"
            options={accountStateTypeList}
          />
        }
      />
      <SearchFormField
        label={
          <Select
            variant="transparent"
            value={formik.values.keywordType ?? ''}
            onChange={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.keywordType !== value) {
                formik.setFieldValue('keywordType', value);
              }
            }}
          >
            {keywordTypeList.map((item) => (
              <MenuItem
                key={item.key}
                value={item.key}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        }
        children={
          <Input
            variant="outlined"
            placeholder="검색어를 입력하세요"
            key={formik.values.keyword}
            defaultValue={formik.values.keyword ?? ''}
            onBlur={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.keyword !== value) {
                formik.setFieldValue('keyword', value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === 'enter') {
                const value = (e.target as HTMLInputElement).value ?? undefined;
                formik.setFieldValue('keyword', value);
                formik.handleSubmit();
                formik.setSubmitting(false);
              }
            }}
          />
        }
      />
      <SearchFormField
        label={
          <Select
            variant="transparent"
            value={formik.values.dateType ?? ''}
            onChange={(e) => {
              const value = e.target.value || undefined;
              if (formik.values.dateType !== value) {
                formik.setFieldValue('dateType', value);
              }
            }}
          >
            {dateTypeList.map((item) => (
              <MenuItem
                key={item.key}
                value={item.key}
              >
                {item.text}
              </MenuItem>
            ))}
          </Select>
        }
        children={
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <DatePicker
              value={formik.values.startDate ? dayjs(formik.values.startDate) : null}
              format="YY-MM-DD"
              onChange={(e) => {
                formik.setFieldValue('startDate', e && dayjs(e));
              }}
              // renderInput={(parameter) => (
              //   <Input
              //     {...parameter.InputProps}
              //     inputRef={parameter.inputRef}
              //     variant="outlined"
              //     value={parameter.value}
              //     inputProps={parameter.inputProps}
              //   />
              // )}
            />
            <TextBox
              variant="body9"
              sx={{
                margin: '0 10px',
              }}
            >
              ~
            </TextBox>
            <DatePicker
              value={formik.values.endDate ? dayjs(formik.values.endDate) : null}
              format="YY-MM-DD"
              onChange={(e) => {
                formik.setFieldValue('endDate', e && dayjs(e));
              }}
              // renderInput={(parameter) => (
              //   <Input
              //     {...parameter.InputProps}
              //     inputRef={parameter.inputRef}
              //     variant="outlined"
              //     value={parameter.value}
              //     inputProps={parameter.inputProps}
              //   />
              // )}
            />
          </Box>
        }
      />
      <SearchFormField
        label="소속"
        children={
          <DepartmentCheckboxField
            disableLabel
            name="departmentId"
            label="소속"
            menuId={menuId}
          />
        }
      />
    </SearchForm>
  );
}
