import React from 'react';
import { FormProvider } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import type { WorkCreatorView } from '@front/src/features/work/features/work/features/creator/types/view';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import NoteIcon from '@mui/icons-material/Note';
import workWorkQuery from '@front/src/features/work/features/work/query/query';
import useModalOpen from '@front/src/hooks/useModalOpen';
import useWorkIndicatorForm from '@front/src/features/work/features/work/features/creator/features/indicator/hooks/useForm';
import { useSnackbar } from 'notistack';
import { WorkCreatorType } from '@front/src/features/work/utils/constants';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import useWorkIndicatorMutation from '@front/src/features/work/features/work/features/creator/features/indicator/hooks/useMutation';

export default function WorkIndicatorTableRow(
  props: Readonly<UIBuilderTableRowProps<WorkCreatorView>>
) {
  const { item, sectionId, dataId, menuId, onCloseEditModal } = props;
  const commonParams = {
    sectionId,
    dataId,
    menuId,
  };

  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);

  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );

  const { enqueueSnackbar } = useSnackbar();
  const { open, toggle } = useModalOpen();

  const { methods } = useWorkIndicatorForm({
    item,
  });
  const { handleSubmit, reset } = methods;

  const onUpdate = useWorkIndicatorMutation({ commonParams, onCloseEditModal, reset });
  const { data: workRole } = workWorkQuery.useGetRole(commonParams);

  const onSubmit = handleSubmit((data) => {
    if (
      (workRole?.creators ?? []).filter((c) => c.user && c.type === WorkCreatorType.MANAGER)
        .length === 0
    ) {
      enqueueSnackbar('지시자 또는 담당자 최소 1명 필요합니다.', { variant: 'error' });
      reset();
      return;
    }
    onUpdate({
      id: item.id,
      description: data.description,
      userId: (data.userId as number) ?? null,
      textAttr1: data.textAttr1 ?? '',
      textAttr2: data.textAttr2 ?? '',
      textAttr3: data.textAttr3 ?? '',
      numberAttr1: data.numberAttr1,
      numberAttr2: data.numberAttr2,
      numberAttr3: data.numberAttr3,
      dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
      dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
      dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
    });
  });

  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(sectionId);
  };

  const itemList = [
    {
      text: open ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: toggle,
      seq: 1,
    },
    { text: '비고', icon: NoteIcon, action: onNoteOpen, seq: 6 },
  ];

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={itemList}
        isEditMode={open}
      />
    </FormProvider>
  );
}
