import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkDetailTableBody from '@front/src/features/work/features/work/features/detail/components/TableBody';
import useWorkDetailGetIsLoading from '@front/src/features/work/features/work/features/detail/hooks/useGetIsLoading';
import workDetailQuery from '@front/src/features/work/features/work/features/detail/query/query';
import WorkDetailTableHead from '@front/src/features/work/features/work/features/detail/components/TableHead';

export default function WorkDetailTable(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const isLoading = useWorkDetailGetIsLoading();

  const { data: list } = workDetailQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      HeadComponent={WorkDetailTableHead}
      BodyComponent={WorkDetailTableBody}
      list={list}
    />
  );
}
