import { createContext } from 'react';
import type { SmProjectBriefProjectInfoView } from '@front/src/features/project-sales-info/features/abstract/features/project-info/types/view';

interface State {
  detail?: SmProjectBriefProjectInfoView;
}

export { Context as ProjectSalesInfoAbstractProjectInfoDetailContext };

const Context = createContext<State>({});
