import React, { useEffect, useMemo, useRef } from 'react';
import type { ReactQuillProps } from 'react-quill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import { commonRepository } from '@front/src/api/repository';
import type { DeltaOperation, RangeStatic } from 'quill';
import type { BoxProps } from '@mui/material';

interface EditorComponentProps extends Omit<ReactQuillProps, 'onBlur' | 'onChange'> {
  onBlur?: (value: string) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
  menuId?: number;
  dataId?: number;
  sectionId?: number;
  sx?: SxProps;
  enableImageUpload?: boolean;
  targetId?: number;
  targetType?: string;
}

export default function EditorUI(props: EditorComponentProps) {
  const {
    disabled = false,
    placeholder = '내용을 작성해주세요',
    sx = {},
    value,
    onBlur,
    enableImageUpload = true,
    onChange,
    ...rest
  } = props;
  const quillRef = useRef<ReactQuill | null>(null);
  const handleBlur = (previousSelection, source, editor) => {
    if (!onBlur) return;
    if (value === editor.getHTML()) return;
    setTimeout(() => {
      const fixRange = editor.getSelection();
      if (fixRange) return;
      onBlur(editor.getHTML());
    }, 50);
  };
  const imageHandler = useImage({
    ...rest,
    quillRef,
  });
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', enableImageUpload && 'image'],
          [{ align: [] }, { color: [] }, { background: [] }],
          ['clean'],
        ],
        handlers: {
          image: enableImageUpload && imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  useAutoFormatLink({ quillRef });

  const containerRef = useRef<HTMLDivElement | null>(null);
  const userSelectionRef = useRef<RangeStatic | null>(null);

  const handeContainerBlur = (event) => {
    if(!quillRef.current) return;
    const editor = quillRef.current.getEditor();
    const relatedTarget = event.relatedTarget as HTMLElement;
    const quillContainerA = relatedTarget?.closest('.quill'); // 이벤트 발생 위치의 퀼 컨테이너
    const quillContainerB = quillRef.current.getEditingArea().parentElement; // 본 컴포넌트의 퀼 컨테이너

    if (quillContainerA === quillContainerB) { // 다수의 Quill editor 동작하는 경우 대응
      const className = relatedTarget?.className;
      if (className && className != 'ql-editor' && className != 'ql-clipboard' && className.indexOf('ql-') > -1) {  // 툴바 클릭 시 선택영역 유지
        userSelectionRef.current && editor.setSelection(userSelectionRef.current);
      } else {
        editor.blur();
      }
    } else {
      editor.blur();
    }
  };
  const handleSelectionChange = (
    range: RangeStatic, oldRange: RangeStatic, source: string) => {
    if (range) {
      if (source == 'user') {
        userSelectionRef.current = {...range};
      }
    } else {
      userSelectionRef.current = null;
    }
  };
  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    editor && editor.on('selection-change', handleSelectionChange);
    return () => {
      editor && editor.off('selection-change', handleSelectionChange);
    };
  }, []);

  return (
    <QuillContainer
      ref={containerRef}
      disabled={disabled}
      onBlur={handeContainerBlur}
    >
      <ReactQuill
        {...rest}
        theme="snow"
        modules={modules}
        formats={formats}
        ref={quillRef}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </QuillContainer>
  );
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
  'color',
  'background',
];

const useAutoFormatLink = ({ quillRef }) => {
  useEffect(() => {
    if (!quillRef.current) return;
    const editor = quillRef.current.editor;
    editor?.clipboard.addMatcher(Node.TEXT_NODE, (node, delta) => {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);
      if (matches && matches.length > 0) {
        const ops: DeltaOperation[] = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }
      return delta;
    });
  }, []);
};

const useImage = (props) => {
  const { menuId, dataId, sectionId, quillRef, targetId, targetType } = props;
  const { run: onCreate, setCallback } = commonRepository.useCreateImage(menuId);
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const formData = new FormData();
      const request = {
        menuId,
        dataId,
        sectionId,
        targetId,
        targetType,
      };
      formData.append(`request`, new Blob([JSON.stringify(request)], { type: 'application/json' }));
      if (input.files?.[0]) {
        formData.append('image', input.files[0]);
      }
      onCreate(formData);
    });
  };
  setCallback({
    onSuccess: ({ data }) => {
      if (!quillRef.current) return;
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      if (!range) return;
      editor.insertEmbed(range.index, 'image', data);
      editor.setSelection((range.index + 1) as unknown as RangeStatic);
    },
  });
  return imageHandler;
};

interface QuillContainerProps extends BoxProps {
  disabled: boolean;
}

const QuillContainer = (props: QuillContainerProps) => {
  const { disabled, sx, children, ...rest } = props;
  return (
    <Box
      {...rest}
      ref={props.ref}
      sx={{
        background: disabled ? ColorPalette.greyscale.disabled : ColorPalette.background.bg,
        border: `1px solid ${ColorPalette.line.line02}`,
        borderRadius: '5px',

        '&:hover': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '&:active': {
          border: disabled
            ? `1px solid ${ColorPalette.line.line02}`
            : `1px solid ${ColorPalette.main.main_hover}`,
        },

        '& .ql-toolbar': {
          textAlign: 'start',
        },

        '& .ql-toolbar.ql-snow': {
          border: 'none',
          borderBottom: `1px solid ${ColorPalette.line.line}`,
          padding: '10px',

          '& .ql-picker,': {
            color: ColorPalette.greyscale.text_primary,
          },
          '& .ql-stroke': {
            stroke: ColorPalette.greyscale.text_primary,
          },
          '& .ql-fill': {
            fill: ColorPalette.greyscale.text_primary,
          },
        },

        '& .ql-editor': {
          minHeight: '200px',
          padding: '10px',
          border: 'none',
          fontSize: '1.4rem',

          '&.ql-blank::before': {
            fontStyle: 'normal',
            color: ColorPalette.greyscale.text_quaternary,
            left: '10px',
          },
        },

        // Button states: General states (hover, focus, active)
        '& .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Button .ql-stroke states
        '& .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Button .ql-stroke-miter states
        '& .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // .ql-picker-label states
        '& .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // .ql-picker-item states
        '& .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Additional states for .ql-stroke under .ql-picker-label and .ql-picker-item
        '& .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '& .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        // Additional states for .ql-stroke-miter under .ql-picker-label and .ql-picker-item
        '& .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '& .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter':
          {
            color: `${ColorPalette.main.main_primary} !important`,
            stroke: `${ColorPalette.main.main_primary} !important`,
          },

        '& .ql-snow a': {
          color: `${ColorPalette.main.main_primary} !important`,
        },

        '& .ql-toolbar.ql-snow .ql-picker-label:focus, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus':
          {
            outlineColor: `${ColorPalette.sub.sub_primary} !important`,
          },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
