import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';

export { query as personalSettingsModalUISettingsQuery };

const query = {
  useGetSectionList: (authMenuId) => {
    const { data } = useQuery({
      queryKey: ['person-settings-modal', 'ui-settings', 'section', authMenuId],
      queryFn: () =>
        commonApi.getSectionList(authMenuId, {
          type: 'table',
        }),
      enabled: !!authMenuId,
    });
    return {
      data,
    };
  },
};
