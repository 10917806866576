import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/context';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/components/selected-part-header.module.scss';
import { useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/features/create-modal/widgets/useState';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { shallow } from 'zustand/shallow';

export { SelectedPartHeader as ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalSelectedPartHeader };

const SelectedPartHeader = () => {
  const {
    h: { onCreate },
  } = useContext(ProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalContext);
  const { id } = useProjectSalesInfoDetailState(
    (state) => ({
      id: state.id,
    }),
    shallow
  );
  const { selectedList } =
    useProjectSalesInfoAffiliatedCompanyAffiliatedCompanyPersonCreateModalState(
      (state) => ({
        isOpen: state.isOpen,
        setIsOpen: state.setIsOpen,
        parameter: state.parameter,
        selectedList: state.selectedList,
        setSelectedList: state.setSelectedList,
        setAddList: state.setAddList,
        setDeleteIdList: state.setDeleteIdList,
      }),
      shallow
    );
  const handleAddClick = useCallback(() => {
    const personAndCompanyIdList = selectedList.map((item) => ({
      id: item.id,
      affiliatedCompanyId: item.affiliatedCompanyId,
    }));
    if (personAndCompanyIdList.length === 0) return;
    onCreate({
      id,
      personAndCompanyIdList,
    });
  }, [onCreate, id, selectedList]);
  return (
    <div className={classes.container}>
      <div className={classes.title}>선택된 인물 목록</div>
      <Button onClick={handleAddClick}>하단 인물 일괄 추가</Button>
    </div>
  );
};
