import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';
import type { ProjectContractEarningsParameters } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { projectSalesInfoContractEarningsModalMutation } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/modal/query/mutation';

export const projectSalesInfoContractEarningsModalRepository = {
  useContractEarningsCreate: (id: number, menuId) => {
    const { mutate } = projectSalesInfoContractEarningsModalMutation.useContractEarningsCreate(
      id,
      menuId
    );
    const callback = getNoOpCallback();
    return {
      run: (params: ProjectContractEarningsParameters) => mutate(params, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
