import {call, fork, put, select, take} from 'redux-saga/effects';
import {loginAction} from 'login/action';
import {loginApi} from 'login/api';
import {getErrorCode, getErrorMessage} from 'type/Error';
import {dialogAction} from 'dialog/action';
import {eventChannel} from 'redux-saga';
import type {RootState} from '@front/services/reducer';
import {progressAction} from "@front/components/Progress/action";

function createWindowFocusChannel() {
  return eventChannel((emitter) => {
    const onFocus = () => emitter(true);
    window.addEventListener('focus', onFocus);
    // The subscriber must return an unsubscribe function
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });
}

function* watchWindowFocus() {
  const windowFocusChannel = createWindowFocusChannel();

  while (true) {
    yield take(windowFocusChannel);
    try {
      const detail = yield select((root: RootState) => root.login.detail);
      if (detail) {
        yield put(loginAction.requestDetail());
      }
    } catch (e) {
      const message = getErrorMessage(loginAction.requestDetail, e);
      yield put(dialogAction.openError(message));
      yield put(loginAction.setDetail(undefined));
    }
  }
}

function* watchLogin() {
  while (true) {
    const { payload: params } = yield take(loginAction.login);
    try {
      yield put(progressAction.progress(true));
      yield put(loginAction.requestLogin('request'));
      yield call(loginApi.login, params);
      const detail = yield call(loginApi.get);
      if (detail) {
        yield put(loginAction.setDetail(detail));
      }
      yield put(loginAction.requestLogin('done'));
      yield put(progressAction.progress(false));
    } catch (e) {
      yield put(
        loginAction.loginError({
          code: getErrorCode(loginAction.login, e) ?? '',
          message: getErrorMessage(loginAction.login, e) ?? '로그인에 실패하였습니다.',
        })
      );
      yield put(progressAction.progress(false));
    }
  }
}

function* watchLogout() {
  while (true) {
    yield take(loginAction.logout);
    try {
      yield put(progressAction.progress(true));
      yield put(loginAction.requestLogout('request'));
      yield call(loginApi.logout);
      yield put(loginAction.requestLogout('done'));
      yield put(progressAction.progress(false));
    } catch (e) {
      const message = getErrorMessage(loginAction.logout, e);
      yield put(progressAction.progress(false));
      yield put(dialogAction.openError(message));
      yield put(loginAction.requestLogout(message));
    }
  }
}

function* watchDetail() {
  while (true) {
    yield take(loginAction.requestDetail);
    try {
      const detail = yield call(loginApi.get);
      if (detail) {
        yield put(loginAction.setDetail(detail));
      } else {
        yield put(loginAction.setDetail(undefined));
        yield put(loginAction.requestLogin('로그인 정보가 없습니다.'));
      }
    } catch (e) {
      const message = getErrorMessage(loginAction.requestDetail, e);
      yield put(loginAction.setDetail(undefined));
      yield put(dialogAction.openError(message));
      yield put(loginAction.requestLogin(message));
    }
  }
}

function* watchChange() {
  while (true) {
    const { payload: params } = yield take(loginAction.change);
    try {
      yield put(loginAction.requestChange('request'));
      yield call(loginApi.change, params);
      yield put(loginAction.requestChange('done'));
      yield put(dialogAction.openAlert('변경하였습니다.'));
    } catch (e) {
      const message = getErrorMessage(loginAction.change, e);
      yield put(dialogAction.openError(message));
      yield put(loginAction.requestChange(message));
    }
  }
}

function* watchPasswordChange() {
  while (true) {
    const { payload: params } = yield take(loginAction.changePassword);
    try {
      yield put(loginAction.requestChange('request'));
      yield call(loginApi.changePassword, params);
      yield put(loginAction.requestChange('done'));
      yield put(dialogAction.openAlert('변경하였습니다.'));
      yield put(loginAction.passwordChangeModal(false));
    } catch (e) {
      const message = getErrorMessage(loginAction.changePassword, e);
      const code = getErrorCode(loginAction.changePassword, e);
      yield put(
        loginAction.passwordValidation({
          code: code,
          message: message,
        })
      );
      yield put(loginAction.requestChange(message));
    }
  }
}

function* watchResetPassword() {
  while (true) {
    const { payload: params } = yield take(loginAction.reset);
    try {
      yield put(loginAction.requestReset('request'));
      yield call(loginApi.resetPassword, params);
      yield put(loginAction.requestReset('done'));
    } catch (e) {
      const message = getErrorMessage(loginAction.reset, e);
      const code = getErrorCode(loginAction.reset, e);
      yield put(
        loginAction.passwordValidation({
          code: code,
          message: message,
        })
      );
      yield put(loginAction.requestReset(message));
    }
  }
}

export default function* loginSaga() {
  yield fork(watchLogin);
  yield fork(watchLogout);
  yield fork(watchDetail);
  yield fork(watchChange);
  yield fork(watchPasswordChange);
  yield fork(watchResetPassword);
  yield fork(watchWindowFocus);
}
