import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/context';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyButton } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/components/extra-company-button';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/components/extra-company-table';
import { useAffiliatedCompanyPersonDetailModalOutlineExtraCompanyLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/useLogic';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalOutlineExtraCompanyWidget = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Header, Body } = AddDeleteTable;
  const {
    d: { extraCompanyList },
    h: { setIdList, onExtraCompanyCreate, onExtraCompanyDelete },
    idList,
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalOutlineExtraCompanyLogic(menuId);
  const value = useMemo(
    () => ({
      d: { extraCompanyList },
      h: { setIdList, onExtraCompanyCreate, onExtraCompanyDelete },
      isLoading,
      idList,
    }),
    [extraCompanyList, isLoading, setIdList, idList, onExtraCompanyCreate, onExtraCompanyDelete]
  );
  return (
    <AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext.Provider value={value}>
      <Layout>
        <Header title={name}>
          <AffiliatedCompanyPersonDetailModalOutlineExtraCompanyButton />
        </Header>
        <Body>
          <AffiliatedCompanyPersonDetailModalOutlineExtraCompanyTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalOutlineExtraCompanyWidget;
