import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import type { EvaluationMemoAddParameter } from '@front/ost_evaluation/parameter';
import EvaluationMemoForm from '@front/ost_evaluation/view/memo/Form';
import { evaluationAction } from '@front/ost_evaluation/action';
import type { RootState } from '@front/services/reducer';

interface Props {
  setFormHeight: (height: number) => void;
}

const EvaluationFormService = ({ setFormHeight }: Props) => {
  const dispatch = useDispatch();
  const [estimatorList] = useSelector(
    (root: RootState) => [root.proposal.detail?.estimatorList],
    shallowEqual
  );

  const estimatorIdList = useMemo(
    () => estimatorList?.map((estimatorInfo) => estimatorInfo.estimator.id),
    [estimatorList]
  );

  const addMemo = useCallback(
    (params: EvaluationMemoAddParameter) => dispatch(evaluationAction.addMemo(params)),
    [dispatch]
  );
  const formik = useFormik<EvaluationMemoAddParameter>({
    initialValues: {} as EvaluationMemoAddParameter,
    onSubmit: (values) => {
      addMemo(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setFieldValue('attendanceList', estimatorIdList);
  }, [estimatorIdList]);

  return (
    <FormikProvider value={formik}>
      <EvaluationMemoForm
        setFormHeight={setFormHeight}
        estimatorList={estimatorIdList}
      />
    </FormikProvider>
  );
};

export default EvaluationFormService;
