import { projectSalesInfoContractEarningsRepository } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/repository';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { useProjectSalesInfoContractEarningsState } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/widgets/useState';

export { useLogic as useProjectSalesInfoContractEarningsLogic };
const useLogic = (menuId) => {
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const isModalOpen = useProjectSalesInfoContractEarningsState((state) => state.isModalOpen);
  const { data: contractEarningsStatus, isLoading: isStatusLoading } =
    projectSalesInfoContractEarningsRepository.useContractEarningsStatusGet(id!, menuId);
  const { data: contractEarnings, isLoading } =
    projectSalesInfoContractEarningsRepository.useContractEarningsGet(id!, menuId);
  return {
    d: { contractEarningsStatus, contractEarnings },
    isLoading,
    isStatusLoading,
    isModalOpen,
  };
};
