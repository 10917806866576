import React, { useContext, useRef, useState } from 'react';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import type { UserNotificationView } from '@front/src/features/mobile-notification/types/view';
import { ColorPalette } from '@front/assets/theme';
import { formatDateKor } from '@front/src/features/mobile-notification/utils';
import classes from '@front/src/features/mobile-notification/widgets/list/widgets/item/components/mobile-notification-list-item.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { MobileNotificationListItemContext } from '@front/src/features/mobile-notification/widgets/list/widgets/item/provider/context';
import { useNavigate } from 'react-router-dom';
import {
  CARD_DETAILS_HEIGHT,
  CARD_HEIGHT,
  NOTE_DETAILS_HEIGHT,
  NOTE_HEIGHT,
} from '@front/src/features/mobile-notification/constants/constant';
import { noOp } from '@front/common/contants';
import PushPinIcon from '@mui/icons-material/PushPin';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EastIcon from '@mui/icons-material/East';
import { useMobileNotificationState } from '@front/src/features/mobile-notification/useState';
import { shallow } from 'zustand/shallow';
import { parentsTypeInKor } from '@front/src/features/mobile-notification/types/domain';
import UserIcon from '@front/layouts/UserIcon';
import type { UserId } from '@front/user/domain';

interface Props {
  item: UserNotificationView;
  refs: {
    leadingRef: React.RefObject<HTMLDivElement[] | null[]>;
    trailingRef: React.RefObject<HTMLDivElement[] | null[]>;
  };
  idx: number;
}

export const MobileNotificationListItem = ({ item, refs, idx }: Props) => {
  const { onToggle, onDelete, onPinUpdate, onNavigateToggle } = useContext(
    MobileNotificationListItemContext
  );
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isPersonOpen, setIsPersonOpen] = useState(false);
  const navigate = useNavigate();
  const { addOpenedIdList, deleteOpenedIdList, setPrevMenuId } = useMobileNotificationState(
    (state) => ({
      addOpenedIdList: state.addOpenedIdList,
      deleteOpenedIdList: state.deleteOpenedIdList,
      setPrevMenuId: state.setPrevMenuId,
    }),
    shallow
  );
  const noteRef = useRef(null);
  const isPersonClicked = isPersonOpen && item.attendanceList.length > 1;
  const projectCodeClickHandler = async () => {
    onNavigateToggle({
      id: item.id,
      isRead: true,
    });
    navigate(`${item.forwardUrl}`);
    setPrevMenuId(`${item.menuId}`);
    sessionStorage.setItem('tabList', JSON.stringify(item.authorizedTabList));
  };

  const onToggleHandler = () => {
    onToggle({
      id: item.id,
      isRead: !item.readAt,
    });
  };

  const onPersonClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsPersonOpen((prev) => !prev);
  };

  const cardClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (refs.leadingRef.current![idx]!.getBoundingClientRect().width > 0) return;
    if (refs.trailingRef.current![idx]!.getBoundingClientRect().width > 0) return;
    isCardOpen && isPersonOpen && setIsPersonOpen((prev) => !prev);
    setIsCardOpen((prev) => !prev);
    isCardOpen ? deleteOpenedIdList(item.id) : addOpenedIdList(item.id);
  };

  const getPersonDetailCardHeight = () => {
    if (isPersonClicked) return 'auto';
    return isCardOpen ? CARD_DETAILS_HEIGHT : CARD_HEIGHT;
  };

  return (
    <Box
      sx={{
        minWidth: 270,
        width: '100%',
        marginBottom: '5px',
        '&:hover': {
          cursor: 'pointer',
          color: ColorPalette._4c576d,
        },
      }}
      onClick={(e) => cardClickHandler(e)}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          height: getPersonDetailCardHeight(),
          backgroundColor: item.readAt && ColorPalette._D5D4D4FF,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <CardContent sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <div className={classes.date__name__container}>
              <Typography
                sx={{
                  fontSize: 10,
                  color: ColorPalette._9b9ea4,
                  fontWeight: 'bold',
                  '@media (max-width: 360px)': {
                    fontSize: 9.5,
                  },
                }}
              >
                {formatDateKor(item.createdAt)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 10,
                  color: ColorPalette._9b9ea4,
                  fontWeight: 'bold',
                  margin: '0 5px',
                  '@media (max-width: 360px)': {
                    fontSize: 9.5,
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                {item.sender.name}
              </Typography>
              {item.isImportant && (
                <PushPinIcon
                  sx={{
                    fontSize: 13,
                    color: ColorPalette._9b9ea4,
                    fontWeight: 'bold',
                    '@media (max-width: 360px)': {
                      fontSize: 11,
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
            </div>
            <div className={classes.project__info__container}>
              <Typography
                sx={{
                  fontSize: 14,
                  '@media (max-width: 360px)': {
                    fontSize: 9.5,
                    whiteSpace: 'nowrap',
                  },
                }}
                fontWeight="bold"
                onClick={noOp}
              >
                [{parentsTypeInKor[item.parentsType]}]{item.title}
                {item.code ? ` - ${item.code}` : ''} {item.type}
              </Typography>
            </div>
            <Typography
              variant="body2"
              sx={{
                height: isCardOpen ? NOTE_DETAILS_HEIGHT : NOTE_HEIGHT,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: isCardOpen ? 'none' : '2',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-wrap',
              }}
              ref={noteRef}
              onClick={noOp}
            >
              {item.note}
            </Typography>
          </CardContent>
          {isCardOpen ? (
            <CardActions sx={{ justifyContent: 'space-between', padding: '0 10px 20px 10px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                className={classes.hover}
                onClick={onPersonClickHandler}
              >
                <UserIcon
                  user={item.attendanceList[0]?.id as UserId}
                  defaultImage={true}
                />
                {item.attendanceList[0]?.name}{' '}
                {item.attendanceList?.length > 1 ? `외 ${item.attendanceList?.length - 1}명` : ''}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <DeleteIcon
                  sx={{
                    color: ColorPalette._b2b4b7,
                    fontSize: '30px',
                    '&:hover': {
                      cursor: 'pointer',
                      color: ColorPalette._4c576d,
                    },
                    '&:active': {
                      color: ColorPalette._4c576d,
                    },
                  }}
                  onClick={() => onDelete(item.id)}
                />
                <PushPinIcon
                  sx={{
                    color: item.isImportant ? ColorPalette._4c576d : ColorPalette._b2b4b7,
                    fontSize: '30px',
                    '&:hover': {
                      cursor: 'pointer',
                      color: ColorPalette._4c576d,
                    },
                    '&:active': {
                      color: ColorPalette._4c576d,
                    },
                  }}
                  onClick={() =>
                    onPinUpdate({
                      id: item.id,
                      isImportant: !item.isImportant,
                    })
                  }
                />
                <CheckCircleOutlineIcon
                  sx={{
                    color: item.readAt ? ColorPalette._4c576d : ColorPalette._b2b4b7,
                    fontSize: '30px',
                    '&:hover': {
                      cursor: 'pointer',
                      color: ColorPalette._4c576d,
                    },
                    '&:active': {
                      color: ColorPalette._4c576d,
                    },
                  }}
                  onClick={onToggleHandler}
                />
                {item.forwardUrl && (
                  <EastIcon
                    sx={{
                      color: ColorPalette._b2b4b7,
                      fontSize: '30px',
                      '&:hover': {
                        cursor: 'pointer',
                        color: ColorPalette._4c576d,
                      },
                      '&:active': {
                        color: ColorPalette._4c576d,
                      },
                    }}
                    onClick={projectCodeClickHandler}
                  />
                )}
              </div>
            </CardActions>
          ) : (
            <CardActions sx={{ paddingLeft: '17px' }}>
              <Typography fontSize="x-small">{item.breadCrumb}</Typography>
            </CardActions>
          )}
          {isPersonClicked && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                padding: '0 10px 20px 10px',
                justifyContent: 'start',
                flexWrap: 'wrap',
              }}
            >
              {item.attendanceList.slice(1).map((attendance) => (
                <div
                  key={attendance.id}
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <UserIcon
                    user={attendance.id as UserId}
                    defaultImage={!attendance.profile?.id}
                  />
                  {attendance.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>
    </Box>
  );
};
