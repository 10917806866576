import { useQuery } from 'react-query';
import { approvalBoxApi } from '@front/src/features/approval-box/api/api';
import type { ApprovalDocumentDetailQueryParams } from '@front/src/features/drawer-approval-document/types/parameter';
import type { ApprovalBoxSearchParams } from '@front/src/features/approval-box/type/parameter';

export const approvalBoxQuery = {
  useGetApprovalBoxCount: () => {
    const { data } = useQuery({
      queryKey: ['approval-box'],
      queryFn: () => approvalBoxApi.getApprovalBoxCount(),
      suspense: true,
    });

    return { data };
  },

  useGetApprovalBoxDetail: (documentId: number, params: ApprovalDocumentDetailQueryParams) => {
    const { data } = useQuery({
      queryKey: ['approval-box', 'detail', documentId, params],
      queryFn: () => approvalBoxApi.getApprovalBoxDetail(documentId, params),
      enabled: !!documentId,
      suspense: true,
    });

    return { data };
  },

  useGetApprovalBoxSearchList: (params: ApprovalBoxSearchParams) => {
    const { data } = useQuery({
      queryKey: ['approval-box', 'search', params],
      queryFn: () => approvalBoxApi.getApprovalBoxSearchList(params),
      suspense: true,
    });
    return { data };
  },

  useGetApprovalDocumentLink: (documentId: number) => {
    const { data } = useQuery({
      queryKey: ['approval-box', 'link', documentId],
      queryFn: () => approvalBoxApi.getApprovalDocumentLink(documentId),
      suspense: true,
      enabled: !!documentId,
    });
    return { data };
  },
};
