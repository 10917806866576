import { useQuery } from 'react-query';
import { projectSalesInfoCMSaveHistoryApi } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/query/api';
import type { ProjectCMSaveHistoryView } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/view';

export const projectSalesInfoCMSaveHistoryQuery = {
  useCMSaveHistoryGet: (id: number, menuId) => {
    const { data, isLoading } = useQuery<ProjectCMSaveHistoryView[]>({
      queryFn: () => projectSalesInfoCMSaveHistoryApi.getCMSaveHistory(id, menuId),
      queryKey: ['project-sales-info', 'collection', 'CM-save-history', id],
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
