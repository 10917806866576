import React, { useCallback } from 'react';
import ModalLayout from '@front/layouts/ModalLayout';
import { ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalForm } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/components/modal-form';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { isMobileDevice } from '@front/util/PwaUtil';
import { useShallow } from 'zustand/react/shallow';

export { Modal as ProjectSalesInfoAffiliatedCompanyWebHardUpdateModal };

const Modal = () => {
  const { setIsOpen, isOpen } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    useShallow((state) => ({
      setIsOpen: state.setIsOpen,
      isOpen: state.isOpen,
    }))
  );
  const handleCloseClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <ModalLayout
      position="left"
      width={isMobileDevice() ? '29dvw' : '560px'}
      title="웹하드 수정"
      open={isOpen}
      onClose={handleCloseClick}
    >
      <ProjectSalesInfoAffiliatedCompanyWebHardUpdateModalForm />
    </ModalLayout>
  );
};
