import React, { useMemo } from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { AffiliatedCompanyPersonDetailModalMeetingHistoryContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/widgets/context';
import { AffiliatedCompanyPersonDetailModalMeetingHistoryTable } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/components/meeting-history-table';
import { useAffiliatedCompanyPersonDetailModalMeetingHistoryLogic } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/meeting-history/widgets/useLogic';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

const AffiliatedCompanyPersonDetailModalMeetingHistoryWidget = ({
  name,
  menuId,
}: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  const {
    d: { meetingList },
    isLoading,
  } = useAffiliatedCompanyPersonDetailModalMeetingHistoryLogic(menuId);
  const value = useMemo(
    () => ({
      d: { meetingList },
      isLoading,
    }),
    [meetingList, isLoading]
  );
  return (
    <AffiliatedCompanyPersonDetailModalMeetingHistoryContext.Provider value={value}>
      <Layout>
        <Header title={name} />
        <Body>
          <AffiliatedCompanyPersonDetailModalMeetingHistoryTable />
        </Body>
      </Layout>
    </AffiliatedCompanyPersonDetailModalMeetingHistoryContext.Provider>
  );
};

export default AffiliatedCompanyPersonDetailModalMeetingHistoryWidget;
