import React, { useCallback, useContext } from 'react';
import Button from '@front/layouts/Button';
import { AffiliatedCompanyPersonDetailModalRelationshipContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/context';
import { useShallow } from 'zustand/react/shallow';
import { useAffiliatedCompanyPersonDetailModalRelationshipState } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/relationship/widgets/useState';

export { RelationshipButton as AffiliatedCompanyPersonDetailModalRelationshipButton };
const RelationshipButton = () => {
  const {
    h: { onRelationshipCreate, onRelationshipDelete, setIdList },
    idList,
  } = useContext(AffiliatedCompanyPersonDetailModalRelationshipContext);
  const handleDeleteClick = useCallback(() => {
    onRelationshipDelete({ idList });
    setIdList([]);
  }, [onRelationshipDelete, idList, setIdList]);
  const { readOnly } = useAffiliatedCompanyPersonDetailModalRelationshipState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <>
      <Button
        onClick={handleDeleteClick}
        disabled={readOnly}
      >
        선택삭제
      </Button>
      <Button
        onClick={onRelationshipCreate}
        disabled={readOnly}
      >
        인맥 추가
      </Button>
    </>
  );
};
