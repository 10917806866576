import type { ReactNode } from 'react';
import React from 'react';
import { Box, Table, TableBody } from '@mui/material';
import { drawerTableHeaderNameList } from '@front/src/features/affiliated-person/features/drawer/utils';
import { useDrawerListStyles } from '@front/components/CommonDrawer/style';
import { ColorPalette } from '@front/assets/theme';

export { ResultTable as AffiliatedPersonDrawerResultTable };

interface Props {
  children: ReactNode;
}

const ResultTable = ({ children }: Props) => {
  const classes = useDrawerListStyles();
  return (
    <div className={classes.root}>
      <Table
        component="div"
        stickyHeader
        aria-label="sticky table"
        sx={{
          height: '97%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '32px',
              backgroundColor: ColorPalette._ffffff,
            }}
          >
            {drawerTableHeaderNameList.map(({ label, width }) => (
              <Box
                key={label}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width,
                  padding: '5px',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {label}
              </Box>
            ))}
          </Box>
        </Box>
        <TableBody
          component="div"
          className={classes.tbody}
        >
          {children}
        </TableBody>
      </Table>
    </div>
  );
};
