import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@front/layouts/Button';
import classes from '@front/src/features/project-selector-modal/components/form.module.scss';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import type { ProjectListGetParameter } from '@front/src/features/project-selector-modal/types/parameter';
import { projectTypeList } from '@front/src/features/project-selector-modal/utils/constant';
import { useProjectSelectorModalFormState } from '@front/src/features/project-selector-modal/widgets/body/useState';
import { shallow } from 'zustand/shallow';

export { Form as ProjectSelectorModalBodyForm };

const Form = () => {
  const { parameter, setParameter } = useProjectSelectorModalFormState(
    (state) => ({
      parameter: state.parameter,
      setParameter: state.setParameter,
    }),
    shallow
  );
  const methods = useForm<ProjectListGetParameter>({
    values: parameter,
  });
  const onSubmit = methods.handleSubmit((data) => {
    setParameter(data);
  });
  const handleKeyDown = useCallback(
    async (e) => {
      if (e.isComposing || e.keyCode === 229) return;
      if (e.key !== 'Enter') return;
      await onSubmit();
    },
    [onSubmit]
  );
  return (
    <div className={classes.container}>
      <FormProvider {...methods}>
        <HookFormSelect
          width="124px"
          name="keywordType"
          optionList={projectTypeList}
        />
        <HookFormInput
          width="40%"
          name="keyword"
          placeholder="검색어 입력"
          onKeyDown={handleKeyDown}
        />
      </FormProvider>
      <Button onClick={onSubmit}>검색</Button>
    </div>
  );
};
