import type { RefObject } from 'react';
import React, { useCallback, useContext } from 'react';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import { AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/widgets/context';
import { useAffiliatedCompanyPersonState } from '@front/src/features/affiliated-company/features/person/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { ExtraCompanySelector as AffiliatedCompanyPersonOutlineExtraCompanySelector };

interface Props {
  inputRef?: RefObject<HTMLInputElement>;
}

const ExtraCompanySelector = ({ inputRef }: Props) => {
  const {
    h: { onExtraCompanyCreate },
  } = useContext(AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext);
  const handleSelector = useCallback(
    (company) => {
      onExtraCompanyCreate({ affiliatedCompanyId: company?.id });
    },
    [onExtraCompanyCreate]
  );
  const { readOnly } = useAffiliatedCompanyPersonState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <AffiliatedCompanySelectorWidget
      inputRef={inputRef}
      value={{
        textValue: '',
      }}
      onCompanySelect={handleSelector}
      onPersonSelect={() => {}}
      usePersonSelector={false}
      disabled={readOnly}
    />
  );
};
