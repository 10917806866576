import { useMutation, useQueryClient } from 'react-query';
import { workWorkApi } from '@front/src/features/work/features/work/api/api';
import type { WorkDetailUpdateParameter } from '@front/src/features/work/features/work/features/detail/types/parameter';
import { convertEmptyToNull } from '@front/src/utils';
import type { UIBuilderParameter } from '@front/src/types/parameter';

const workDetailMutation = {
  useCreate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.createDetail(id, commonParams),
      mutationKey: ['work', 'detail', 'create'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddTop: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addTopDetail(commonParams),
      mutationKey: ['work', 'detail', 'add-top'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useAddBottom: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.addBottomDetail(commonParams),
      mutationKey: ['work', 'detail', 'add-bottom'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation<unknown, unknown, WorkDetailUpdateParameter>({
      mutationFn: (params) => workWorkApi.updateDetail(params, commonParams),
      mutationKey: ['work', 'detail', 'update'],
      onMutate: async (data) => {
        const { data: previousDataList } =
          (queryClient.getQueryData(['work', 'detail']) as any) ?? {};
        const newData = convertEmptyToNull(data);

        const previousData = previousDataList?.find((item) => item?.id === newData.id);
        const prevData = convertEmptyToNull(previousData);

        return newData.content === prevData?.content && newData.date === prevData?.date;
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'name'],
        });
        await queryClient.invalidateQueries({
          queryKey: ['work', 'aside'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqUp: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateDetailSeqUp(id, commonParams),
      mutationKey: ['work', 'detail', 'seq-up'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useSeqDown: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.updateDetailSeqDown(id, commonParams),
      mutationKey: ['work', 'detail', 'seq-down'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (id: number) => workWorkApi.deleteDetail(id, commonParams),
      mutationKey: ['work', 'detail', 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
  useDeleteAll: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: () => workWorkApi.deleteAllDetail(commonParams),
      mutationKey: ['work', 'detail', 'delete-all'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['work', 'detail'],
        });
      },
    });
    return {
      mutate,
    };
  },
};

export default workDetailMutation;
