import React from 'react';
import type { TableRowProps } from '@mui/material/TableRow';
import TableRow from '@mui/material/TableRow';

export default function VirtualizedAutoSizerTableRowUI({
  sx = {},
  ...rest
}: Readonly<TableRowProps>) {
  return (
    <TableRow
      {...rest}
      component="div"
      sx={{
        display: 'flex',
        ...sx,
      }}
    />
  );
}
