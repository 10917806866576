import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export const convertToManageableOptionList = (
  view: VariableInformationView[] | undefined,
  isPercent?: boolean
) => {
  if (!view)
    return [
      {
        label: '',
        value: '',
      },
    ];
  return view?.map((item) => ({
    label: item.label + (isPercent ? '%' : ''),
    value: item.value,
  }));
};
