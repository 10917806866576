import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { AuthPersonalSettingsDeptView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import { getTab } from '@front/src/features/personal-settings-modal/features/auth-settings/utils';
import {
  Box,
  MenuItem,
  Select,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { Table } from '@front/layouts/Table';
import { WorkTh } from '@front/src/features/work/features/work/components/work-th';
import { sortDepartmentList } from '@front/src/features/admin/features/access-authorization-settings/features/department-authorization/utils';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import TextBox from '@front/layouts/Text';
import { ColorPalette } from '@front/assets/theme';
import { usePersonalSettingsModalDeptSettingsState } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/useState';
import { PersonalSettingsModalDeptSettingsContext } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/provider/context';
import { PersonalSettingsModalDeptSettingsTableAuthRow } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/components/dept-settings-table-auth-row';
import { PersonalSettingsModalDeptSettingsTableDeptRow } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/components/dept-settings-table-dept-row';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';
import { NoResult } from '@front/src/components/layout/table/no-result';

export { DeptSettings as PersonalSettingsModalDeptSettings };

const DeptSettings = () => {
  const { setKeyword, keyword, updateList, setUpdateList, setColoredId, setEditId } =
    usePersonalSettingsModalDeptSettingsState(
      useShallow((state) => ({
        setKeyword: state.setKeyword,
        keyword: state.keyword,
        updateList: state.updateList,
        setUpdateList: state.setUpdateList,
        setColoredId: state.setColoredId,
        setEditId: state.setEditId,
      }))
    );
  const { authType, setAuthType } = usePersonalSettingsModalState(
    useShallow((state) => ({
      authType: state.authType,
      setAuthType: state.setAuthType,
    }))
  );
  const {
    setOpen,
    reset: resetStore,
    setIsDirty,
    setTitle,
  } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      reset: state.reset,
      setIsDirty: state.setIsDirty,
      setTitle: state.setTitle,
    }))
  );
  const tableRef = useRef<HTMLDivElement>(null);
  const { clientDeptList, onAuthUpdate } = useContext(PersonalSettingsModalDeptSettingsContext);
  const newList = clientDeptList?.map((item) => ({
    ...item,
    originalId: item.id,
  }));
  const methods = useForm<AuthPersonalSettingsDeptView>({
    values: {
      dept: sortDepartmentList(!!keyword, newList),
    },
  });
  const {
    reset,
    control,
    formState: { isDirty },
  } = methods;
  const { fields } = useFieldArray({
    control: control,
    name: 'dept',
  });
  const [inputVal, setInputVal] = useState('');
  const onModalConfirmClick = useCallback(() => {
    setUpdateList([]);
    setColoredId(0);
    setEditId(0);
    reset();
  }, [setUpdateList, setColoredId, setEditId, reset]);
  const onSaveClick = () => {
    onAuthUpdate({ list: updateList });
    setUpdateList([]);
    setEditId(0);
  };
  const onCancelClick = useCallback(() => {
    if (isDirty) {
      setOpen(true, () => {
        onModalConfirmClick();
        resetStore();
      });
      setTitle('취소 확인');
      return;
    }
    onModalConfirmClick();
  }, [isDirty, setOpen, onModalConfirmClick, resetStore]);
  const onSelectChange = useCallback(
    (e) => {
      if (isDirty) {
        setOpen(true, () => {
          onModalConfirmClick();
          setAuthType(e.target.value);
          resetStore();
        });
        return;
      }
      setAuthType(e.target.value);
    },
    [isDirty, setAuthType, resetStore, onModalConfirmClick, setOpen]
  );
  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);
  useEffect(
    () => () => {
      reset();
      setIsDirty(false);
      onModalConfirmClick();
    },
    [reset, onModalConfirmClick, setIsDirty]
  );
  if (!clientDeptList) return null;
  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          gap="10px"
          alignItems="center"
        >
          <Select
            sx={{ height: '32px', fontSize: '13px' }}
            value={authType}
            onChange={(e) => onSelectChange(e)}
          >
            <MenuItem
              value={'개인권한'}
              selected={true}
              sx={{
                fontSize: '13px',
              }}
            >
              개인권한
            </MenuItem>
            <MenuItem
              value={'부서권한'}
              sx={{
                fontSize: '13px',
              }}
            >
              부서권한
            </MenuItem>
          </Select>
          <Input
            variant="outlined"
            onChange={(e) => setInputVal(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setKeyword(inputVal);
              }
            }}
            value={inputVal}
          />
          <Button onClick={() => setKeyword(inputVal)}>검색</Button>
          <Button
            shape="basic2"
            onClick={() => {
              setKeyword('');
              setInputVal('');
            }}
          >
            초기화
          </Button>
        </Box>
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
        >
          <TextBox variant="body2">저장하지 않은 내용은 반영되지 않습니다.</TextBox>
          <Button
            onClick={onCancelClick}
            shape="basic2"
          >
            취소
          </Button>
          <Button onClick={() => onSaveClick()}>저장</Button>
        </Box>
      </Box>
      <TableContainer
        sx={{
          display: 'flex',
          maxHeight: '65dvh',
          maxWidth: '50dvw',
          gap: '6px',
          backgroundColor: ColorPalette._252627,
        }}
        ref={tableRef}
      >
        <Table
          stickyHeader
          sx={{
            borderRadius: 'none',
          }}
        >
          <TableHead>
            <TableRow>
              <WorkTh
                width="120px"
                sx={{ fontWeight: 'bold' }}
              >
                부서명
              </WorkTh>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.length === 0 && <NoResult colSpan={1} />}
            {fields?.map((item) => (
              <PersonalSettingsModalDeptSettingsTableDeptRow
                key={item.id}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
        <Table
          stickyHeader
          sx={{
            borderRadius: 'none',
          }}
        >
          <TableHead>
            <TableRow>
              {!getTab() ? (
                <WorkTh sx={{ fontWeight: 'bold' }}>페이지</WorkTh>
              ) : (
                <WorkTh sx={{ fontWeight: 'bold' }}>{getTab()}</WorkTh>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.length === 0 && <NoResult colSpan={1} />}
            {fields?.map((item, index) => (
              <PersonalSettingsModalDeptSettingsTableAuthRow
                key={item.id}
                rowIdx={index}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormProvider>
  );
};
