import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { WorkCategoryView } from '@front/src/features/work/features/work/features/category/types/view';
import EditIcon from '@mui/icons-material/Edit';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import type { NoteMenuIdView } from '@front/src/features/note/types/view';
import NoteIcon from '@mui/icons-material/Note';
import useWorkCategoryForm from '@front/src/features/work/features/work/features/category/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import { ColorPalette } from '@front/assets/theme';
import useWorkCategoryUpdate from '@front/src/features/work/features/work/features/category/repository/useUpdate';

export default function WorkWorkCategoryTableRow(props: UIBuilderTableRowProps<WorkCategoryView>) {
  const { item, sectionId, dataId, menuId, formContext } = props;

  const setIsRightDrawerOpen = useGlobalNavBarState((state) => state.setIsRightDrawerOpen);

  const { setRowId, setSectionId } = useNoteStore(
    useShallow((state) => ({
      setRowId: state.setRowId,
      setSectionId: state.setSectionId,
    }))
  );

  const { watch } = formContext;

  const editOpenIdList = watch('editOpenIdList');
  const { toggleEditOpenId, recentUpdatedId } = useGetUIBuilderTableEditState(formContext);

  const { run: onUpdate } = useWorkCategoryUpdate({ dataId, menuId, sectionId });

  const { onSubmit, methods } = useWorkCategoryForm({
    item,
    onUpdate,
  });

  const onNoteOpen = () => {
    const noteAuth: NoteMenuIdView = JSON.parse(sessionStorage.getItem('noteAuth') ?? '{}');
    if (!noteAuth.noteId || noteAuth.type === 'X') {
      alert({
        title: '권한 오류',
        children: [{ value: '접근 권한이 없습니다.' }],
      });
      return;
    }
    setIsRightDrawerOpen(true);
    setRowId(item.id);
    setSectionId(sectionId!);
  };

  const isEditOpen = editOpenIdList.includes(item.id);

  const itemList = [
    {
      text: isEditOpen ? '수정 닫기' : '수정',
      icon: EditIcon,
      action: () => toggleEditOpenId(item.id),
      seq: 2,
    },
    { text: '비고', icon: NoteIcon, action: onNoteOpen, seq: 6 },
  ];

  const sx = {
    backgroundColor: recentUpdatedId === item.id ? ColorPalette._d2e7fa : 'inherit',
  };

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        sx={sx}
        itemList={itemList}
        isEditMode={isEditOpen}
      />
    </FormProvider>
  );
}
