import React, { useCallback, useContext } from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import classes from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/web-hard-table-body-row.module.scss';
import { ProjectSalesInfoAffiliatedCompanyWebHardContext } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/context';
import { useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/features/update-modal/widgets/useState';
import { shallow } from 'zustand/shallow';
import { getValueIfExist, openNewTabFromUrl } from '@front/src/utils';
import { ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRowCheckBox } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/components/check-box';
import { useProjectSalesInfoAffiliatedCompanyWebHardState } from '@front/src/features/project-sales-info/features/affiliated-company/features/web-hard/widgets/useState';
import { useShallow } from 'zustand/react/shallow';

export { WebHardTableBodyRow as ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRow };

const WebHardTableBodyRow = () => {
  const {
    d: { list },
  } = useContext(ProjectSalesInfoAffiliatedCompanyWebHardContext);
  const { setIsOpen, setId } = useProjectSalesInfoAffiliatedCompanyWebHardUpdateModalState(
    (state) => ({
      setIsOpen: state.setIsOpen,
      setId: state.setId,
    }),
    shallow
  );
  const { readOnly } = useProjectSalesInfoAffiliatedCompanyWebHardState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const handleRowClick = useCallback(
    (id) => {
      if (readOnly) return;
      setId(id);
      setIsOpen(true);
    },
    [setId, setIsOpen, readOnly]
  );
  return (
    <>
      {list?.map((item) => (
        <TableRow key={item.id}>
          <OldTd>
            <ProjectSalesInfoAffiliatedCompanyWebHardTableBodyRowCheckBox id={item.id} />
          </OldTd>
          <OldTd onClick={() => handleRowClick(item.id)}>
            <div className={classes.company__name}>
              {getValueIfExist(item.affiliatedCompanyName)}
            </div>
          </OldTd>
          <OldTd>
            {item.url ? (
              <div
                className={classes.url}
                onClick={() => openNewTabFromUrl(item.url)}
                aria-hidden
              >
                {item.url}
              </div>
            ) : (
              '-'
            )}
          </OldTd>
          <OldTd>{getValueIfExist(item.purpose)}</OldTd>
          <OldTd>{getValueIfExist(item.webHardUsername)}</OldTd>
          <OldTd>{getValueIfExist(item.webHardPassword)}</OldTd>
        </TableRow>
      ))}
    </>
  );
};
