import type { ForwardedRef, ReactNode } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import type { Theme } from '@mui/material';
import { TableContainer } from '@mui/material';
import type { TableContainerProps } from '@mui/material/TableContainer/TableContainer';
import type { SxProps } from '@mui/system';

interface Props extends TableContainerProps {
  children?: ReactNode;
  setId?: (id?: number) => void;
  virtualRef?: ForwardedRef<HTMLDivElement>;
  sx?: SxProps<Theme>;
}

export const RowClickTableContainer = ({
  children,
  setId,
  virtualRef,
  sx = {},
  ...rest
}: Readonly<Props>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const onClick = useCallback(
    (e) => {
      if (!containerRef.current) return;
      const modalElement = containerRef.current.closest('.MuiModal-root');
      if (
        (!modalElement && e.target.closest('.MuiModal-root, .MuiPickersPopper-root')) ||
        e.target.matches('body')
      )
        return;
      if (
        !containerRef.current.contains(e.target) &&
        e.target.closest('.MuiModal-root, .MuiPickersPopper-root') &&
        !modalElement?.contains(e.target)
      )
        return;
      if (!containerRef.current.contains(e.target)) setId?.();
    },
    [setId]
  );

  useEffect(() => {
    document.addEventListener('click', onClick, { capture: true });
    return () => {
      document.removeEventListener('click', onClick, { capture: true });
    };
  }, [onClick]);
  return (
    <div
      ref={containerRef}
      {...rest}
    >
      <TableContainer
        ref={virtualRef}
        sx={{ ...sx }}
        {...rest}
      >
        {children}
      </TableContainer>
    </div>
  );
};
