import { createContext } from 'react';
import { noOp } from '@front/src/utils';
import type { AffiliatedCompanyPersonOutlineExtraCompanyView } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/view';
import type { AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter } from '@front/src/features/affiliated-company/features/person/features/detail-modal/features/outline/extra-company/types/parameter';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';

export { Context as AffiliatedCompanyPersonDetailModalOutlineExtraCompanyContext };

interface State {
  d: { extraCompanyList?: AffiliatedCompanyPersonOutlineExtraCompanyView[] };
  h: {
    onExtraCompanyCreate: (
      params: AffiliatedCompanyPersonOutlineExtraCompanyCreateParameter
    ) => void;
    onExtraCompanyDelete: (params: AffiliatedCompanyIdListDeleteParameter) => void;
    setIdList: (idList: number[]) => void;
  };
  isLoading: boolean;
  idList: number[];
}

const Context = createContext<State>({
  d: {},
  h: {
    onExtraCompanyCreate: noOp,
    onExtraCompanyDelete: noOp,
    setIdList: noOp,
  },
  isLoading: false,
  idList: [],
});
