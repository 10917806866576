import React, { useContext } from 'react';
import { TableLoadingSpinner } from '@front/src/components/loading-spinner/table-loading-spinner';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ProjectSalesInfoEstimationCompetitorTableRow } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/components/estimation-competitor-table-row';
import { ProjectSalesInfoEstimationCompetitorContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/widgets/context';

export { EstimationCompetitorTableBody as ProjectSalesInfoEstimationCompetitorTableBody };

const EstimationCompetitorTableBody = () => {
  const {
    d: { list },
    isLoading,
  } = useContext(ProjectSalesInfoEstimationCompetitorContext);
  if (typeof list === 'undefined' || isLoading) {
    return (
      <TableLoadingSpinner
        height="52px"
        colSpan={14}
      />
    );
  }
  if (list.length === 0) {
    return (
      <NoResult
        height="52px"
        colSpan={14}
      />
    );
  }
  return (
    <>
      {list.map((item, index) => (
        <ProjectSalesInfoEstimationCompetitorTableRow
          key={item.id}
          item={item}
          index={index}
        />
      ))}
    </>
  );
};
