import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { useLocation } from 'react-router-dom';
import globalNavBarRepository from '@front/src/features/global-nav-bar/repository/repository';
import { useEffect } from 'react';
import type { MenuView } from '@front/src/types';

const useSetMenuAndTab = () => {
  const { setTabList, setMenuId } = useGlobalNavBarState(
    useShallow((state) => ({
      setTabList: state.setTabList,
      setMenuId: state.setMenuId,
    }))
  );
  const { pathname } = useLocation();
  const newParams =
    pathname
      ?.split('/')
      .map((item) => (item !== '' && !isNaN(+item) ? '{:id}' : item))
      .join('/') ?? '';
  const { data: menuList } = globalNavBarRepository.useListGet();
  const tabList = getTabList(menuList, newParams);
  const menuId = getMenuId(menuList, newParams);
  const { setIsRightDrawerOpen, setIsThroughLink, isThroughLink } = useGlobalNavBarState(
    useShallow((state) => ({
      setIsRightDrawerOpen: state.setIsRightDrawerOpen,
      setIsThroughLink: state.setIsThroughLink,
      isThroughLink: state.isThroughLink,
    }))
  );
  useEffect(() => {
    if (!tabList || !menuId) return;
    setTabList(tabList);
    setMenuId(menuId);
    if (isThroughLink) {
      setIsThroughLink(false);
      return;
    }
    setIsRightDrawerOpen(false);
  }, [menuList, pathname]);
  return {
    tabList,
    menuId,
  };
};

export default useSetMenuAndTab;

const getTabList = (menuList, newParams) => {
  const parentMenu = findParentMenu(menuList ?? [], newParams);
  const menu = findMenu(menuList ?? [], newParams);

  if (!menu) return undefined;

  if (menu.hasDrawer) {
    return menu.children?.filter((item) => item.isTab);
  }
  const tabChildrenFromParent = parentMenu?.children?.filter((item) => item.isTab);
  return tabChildrenFromParent?.length
    ? tabChildrenFromParent
    : menu.children?.filter((item) => item.isTab);
};

const getMenuId = (menuList, newParams) => {
  const menu = findMenu(menuList ?? [], newParams);
  const tabChildren = getTabList(menuList, newParams);

  if (!menu) return;

  if (!menu.hasDrawer && tabChildren?.length) {
    const lowestSeqTab = tabChildren.reduce((prev, curr) => (prev.seq < curr.seq ? prev : curr));
    return lowestSeqTab.path === newParams ? lowestSeqTab.id : menu.id;
  }

  return menu.id;
};

const findMenu = (data: MenuView[], path: string) => {
  let menuView: MenuView | undefined;
  const recurFind = (menu: MenuView) => {
    if (
      menu?.path === path ||
      path === menu?.path + '/{:id}' ||
      (path.startsWith('/hr-card-management') && menu?.path?.startsWith('/hr-card-management'))
    ) {
      menuView = menu;
    }
    if (menu.children && menu.children.length > 0) {
      menu.children.forEach((item) => recurFind(item));
    }
  };
  data?.forEach((item) => recurFind(item));
  return menuView;
};

const findParentMenu = (data: MenuView[], path: string) => {
  let menuView: MenuView | undefined;
  const recurFind = (menuList: MenuView[]) => {
    menuList?.forEach((item) => {
      if (item.children?.find((el) => el?.path === path)) {
        menuView = item;
      }
      if (item.children) recurFind(item.children);
    });
  };
  recurFind(data);
  return menuView;
};
