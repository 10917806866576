import React, { useContext } from 'react';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { AffiliatedPersonCompanySelectModalListContext } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/provider/context';
import { AffiliatedPersonCompanySelectModalListRow } from '@front/src/features/affiliated-person/features/app-bar/features/company-select-modal/widgets/list/components/row';

export { List as AffiliatedPersonCompanySelectModalList };

const List = () => {
  const { list } = useContext(AffiliatedPersonCompanySelectModalListContext);
  if (typeof list === 'undefined' || list.length === 0) {
    return (
      <NoResult
        colSpan={4}
        height="40px"
      />
    );
  }
  return (
    <>
      {list.map((item) => (
        <AffiliatedPersonCompanySelectModalListRow
          key={item.id}
          item={item}
        />
      ))}
    </>
  );
};
