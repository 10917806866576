import { createAction } from 'typesafe-actions';
import type { PersonnelQuery } from 'personnel/query';
import type Page from 'type/Page';
import type { PersonnelId, PersonnelShortVO, PersonnelVO } from 'personnel/domain';
import type { PersonnelParameter } from 'personnel/parameter';
import type { ApiStatus } from 'components/DataFieldProps';
import type { ProposalVO } from '@front/ost_proposal/domain';
import type { VoteVO } from '@front/ost_vote/domain';

export enum PersonnelActionType {
  setId = 'personnel/id/set',
  setOne = 'personnel/one/set',
  setFilter = 'personnel/filter/set',
  setPage = 'personnel/page/set',
  update = 'personnel/update',
  requestUpdate = 'personnel/update/request',
  setProposalLog = 'personnel/proposal/log/set',
  getProposalLog = 'personnel/proposal/log/get',
  setVoteLog = 'personnel/vote/log/set',
  getVoteLog = 'personnel/vote/log/get',
}

export const personnelAction = {
  setId: createAction(PersonnelActionType.setId)<{
    id: PersonnelId | undefined;
    menuId?: number | undefined;
  }>(),
  setOne: createAction(PersonnelActionType.setOne)<PersonnelVO | undefined>(),
  setFilter: createAction(PersonnelActionType.setFilter)<PersonnelQuery>(),
  setPage: createAction(PersonnelActionType.setPage)<Page<PersonnelShortVO> | undefined>(),
  update: createAction(PersonnelActionType.update)<PersonnelParameter>(),
  requestUpdate: createAction(PersonnelActionType.requestUpdate)<ApiStatus>(),
  setProposalLog: createAction(PersonnelActionType.setProposalLog)<ProposalVO[] | undefined>(),
  getProposalLog: createAction(PersonnelActionType.getProposalLog)(),
  setVoteLog: createAction(PersonnelActionType.setVoteLog)<VoteVO[] | undefined>(),
  getVoteLog: createAction(PersonnelActionType.getVoteLog)(),
};
