import { projectSalesInfoURL } from '@front/src/features/project-sales-info/utils/constant';
import axios from 'axios';
import type { ProjectCMSaveHistoryParameter } from '@front/src/features/project-sales-info/features/collection/features/CM-save-history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getCMSaveHistory: (id: number) => `${projectSalesInfoURL}/${id}/cm-amount-save-history`,
  updateCMSaveHistory: (historyId: number) =>
    `${projectSalesInfoURL}/cm-amount-save-history/${historyId}`,
};

export const projectSalesInfoCMSaveHistoryApi = {
  getCMSaveHistory: async (id: number, menuId) => {
    const { data } = await axios.get(url.getCMSaveHistory(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  updateCMSaveHistory: async (params: ProjectCMSaveHistoryParameter, menuId) =>
    await axios.put(url.updateCMSaveHistory(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
};
