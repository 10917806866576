import React from 'react';
import { ProjectSalesInfoSubjectReviewModalSpecialWindTable } from '@front/src/features/project-sales-info/features/subject-review/features/modal/features/special-wind/components/special-wind-table';

export { Index as ProjectSalesInfoSubjectReviewModalSpecialWindFeature };
interface Props {
  menuId?: number;
}
const Index = ({ menuId }: Props) => (
  <ProjectSalesInfoSubjectReviewModalSpecialWindTable menuId={menuId} />
);
