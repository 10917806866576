import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import ImgCarouselUI from '@front/src/components/components-with-design/compound/ImgCarouselUI';
import type { WorkCarouselView } from '@front/src/features/work/features/work/features/carousel/types/view';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import useCopyLink from '@front/src/features/file-item/features/link/hooks/useCopyLink';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import useWorkCarouselMutation from '@front/src/features/work/features/work/features/carousel/hooks/useMutation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomDialog } from '@front/src/features/dialog';
import useFileItemDescriptionUpdate from '@front/src/features/file-item/hooks/useDescriptionUpdate';
import WorkCarouselNoImageCard from '@front/src/features/work/features/work/features/carousel/components/NoImageCard';
import '@front/src/components/components-with-design/common-styles.css';
import { useNote } from '@front/src/features/note/hooks/useNote';
import useImageDraw from '@front/src/features/image-draw/hooks/useImageDraw';
import {noteAutoScrollingAnchorClassName} from "@front/src/features/note/utils";
import useNoteStore from "@front/src/features/note/useState";
import {useShallow} from "zustand/react/shallow";

interface Props {
  item: WorkCarouselView;
  readOnly: boolean;
  menuId: number | undefined;
  dataId: number | undefined;
  sectionId: number | undefined;
}

export default function WorkCarouselCard(props: Readonly<Props>) {
  const { menuId, item, readOnly, sectionId, dataId } = props;
  const { setValue } = useFormContext();
  const { open: openImageDraw } = useImageDraw();
  const { open: openNoteQuickView } = useNote();
  const imageSrc = `/api/file-item/${item.fileItem?.id}`;

  const onClick = useCallback(() => {
    openImageDraw(item.fileItem);
  }, [item]);
  const { onRemove } = useWorkCarouselMutation({ dataId, menuId, sectionId });
  const onDelete = () => onRemove(item.id);
  const onFileAuthSettings = () => {
    setValue('fileItemId', item.fileItem?.id);
    setValue('openFileAuthSettingsModal', true);
  };
  const { onDownload } = useFileItemDownload({ fileKey: item.fileItem?.fileKey });
  const { onCopyLink } = useCopyLink({ fileItemId: item.fileItem?.id });
  const commonParams = {
    menuId,
    dataId,
    sectionId,
  };
  const onUpdateDescription = useFileItemDescriptionUpdate(commonParams);
  const onOpenMoveSlideModal = () => setValue('moveSlideId', item.id);
  const onNoteQuickView = () => {
    sectionId && openNoteQuickView({ sectionId, rowId: item.id });
  };

  const { prompt } = useCustomDialog();

  const onEditDescription = () => {
    prompt({
      lineBreakChildren: [],
      defaultValue: item.fileItem.description,
      title: '제목 수정',
      promptText: '확인',
      closeText: '취소',
      multiline: false,
      maxLength: 500,
      rows: 10,
      afterPrompt: (value) => {
        onUpdateDescription({
          id: item.fileItem.id,
          description: value,
        });
      },
    });
  };
  const itemList = [
    {
      text: '제목 변경',
      action: onEditDescription,
      IconComponent: <FontAwesomeIcon icon="pen" />,
    },
    {
      text: '링크 복사',
      action: onCopyLink,
      IconComponent: <FontAwesomeIcon icon="link" />,
    },
    {
      text: '다운로드',
      action: onDownload,
      IconComponent: <FontAwesomeIcon icon="download" />,
    },
    {
      text: '슬라이드 이동',
      action: onOpenMoveSlideModal,
      IconComponent: <FontAwesomeIcon icon="truck-moving" />,
    },
    {
      text: '비고',
      action: onNoteQuickView,
      IconComponent: <FontAwesomeIcon icon={['fas', 'note-sticky']} />,
      split: true,
    },
    {
      text: '권한 설정',
      action: onFileAuthSettings,
      IconComponent: <FontAwesomeIcon icon="gear" />,
    },
    {
      text: '삭제',
      action: onDelete,
      IconComponent: (
        <FontAwesomeIcon
          icon="trash"
          className="cs-delete-color"
        />
      ),
    },
  ];

  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );

  if (!item.fileItem.hasAuth)
    return <WorkCarouselNoImageCard>권한 없는 이미지</WorkCarouselNoImageCard>;

  return (
    <CarouselCardWrapper
      className={
        sectionId === noteSectionId && rowId === item.id
          ? `${noteAutoScrollingAnchorClassName}-${rowId}`
          : ''
      }
    >
      <ImgCarouselUI
        order={item.seq}
        itemList={itemList}
        onClick={onClick}
        readOnly={readOnly}
        description={item.fileItem?.description}
        sx={{
          position: 'absolute',
        }}
      />
      <img
        style={{
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: 0,
        }}
        src={imageSrc}
        alt="이미지 슬라이드 이미지"
        loading="lazy"
      />
    </CarouselCardWrapper>
  );
}

interface ChildrenProps {
  children: ReactNode;
  className?: string;
}

const CarouselCardWrapper = ({ children, className }: ChildrenProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px',
      border: '1px solid rgba(0,0,0,0.1)',
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
      position: 'relative',
      backgroundColor: '#e7f0ff',
      borderRadius: '5px',
      '&:hover *': {
        visibility: 'visible',
      },
    }}
    className={className ?? ''}
  >
    {children}
  </Box>
);
