import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ApprovalHead } from '@front/ost_proposal/view/detail/approval/Head';
import { useLocation } from 'react-router-dom';
import { ApprovalLayout } from '@front/ost_proposal/view/detail/approval';
import { proposalAction } from '@front/ost_proposal/action';
import type { RootState } from '@front/services/reducer';
import ApprovalModalService from '@front/ost_proposal/service/modal/approvalModal';

export { Approval as ApprovalService };
const Approval = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const onOpen = useCallback(() => dispatch(proposalAction.setApprovalModal(true)), [dispatch]);
  const open = useSelector((root: RootState) => root.proposal.approvalModalOpen, shallowEqual);
  return (
    <>
      {location.state && location.state.isAdvisor && (
        <>
          <ApprovalLayout>
            <ApprovalHead onOpen={onOpen} />
          </ApprovalLayout>
          {open && <ApprovalModalService />}
        </>
      )}
    </>
  );
};
