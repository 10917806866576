import React from 'react';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoAbstractCollectionTable } from '@front/src/features/project-sales-info/features/abstract/features/collection/components/table';
import { ProjectSalesInfoAbstractCollectionListWidget } from '@front/src/features/project-sales-info/features/abstract/features/collection/widgets/list/widget';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

interface Props extends SectionComponentProps {}

/**
 * @module 영업정보>요약탭>수금섹션
 */
const ProjectSalesInfoAbstractCollectionFeature = ({ name, menuId }: Readonly<Props>) => {
  const { Layout, Body, Header } = AddDeleteTable;
  return (
    <Layout>
      <Header title={name} />
      <Body>
        <ProjectSalesInfoAbstractCollectionTable>
          <ProjectSalesInfoAbstractCollectionListWidget menuId={menuId} />
        </ProjectSalesInfoAbstractCollectionTable>
      </Body>
    </Layout>
  );
};
export default ProjectSalesInfoAbstractCollectionFeature;
