import React, { useCallback } from 'react';
import AccountButton from '@front/src/app/view/AccountButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loginAction } from '@front/login/action';
import LoginChangeModalRoute from '@front/login/route/changeModal';
import type { RootState } from '@front/services/reducer';

export default function AccountButtonRoute() {
  const dispatch = useDispatch();
  const openModal = useCallback(() => dispatch(loginAction.changeModal(true)), [dispatch]);
  const changeModal = useSelector((root: RootState) => root.login.changeModal, shallowEqual);
  return (
    <>
      {changeModal && <LoginChangeModalRoute />}
      <AccountButton openModal={openModal} />
    </>
  );
}
