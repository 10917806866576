import { call, delay, fork, put, select, take } from 'redux-saga/effects';
import type { ProposalShortVO } from '@front/ost_proposal/domain';
import { getErrorCode, getErrorMessage } from '@front/type/Error';
import { dialogAction } from '@front/dialog/action';
import { voteApi } from '@front/ost_vote/api';
import type { RootState } from '@front/services/reducer';
import { userNotificationAction } from '@front/user_notification/action';
import { voteAction } from '@front/ost_vote/action';
import { CustomHttpStatus, getCustomErrorMessage } from '@front/common/type/http-status.type';

/**
 * 투찰 목록 및 검색 관련 saga
 */
function* watchFilter() {
  while (true) {
    const { payload: query } = yield take(voteAction.setFilter);
    yield put(voteAction.setLoading(true));
    try {
      const list: ProposalShortVO[] = yield call(voteApi.getProposalList, query);
      yield put(voteAction.setList(list));
    } catch (e) {
      const message = getErrorMessage(voteAction.setFilter, e);
      yield put(dialogAction.openError(message));
    } finally {
      yield delay(500);
      yield put(voteAction.setLoading(false));
    }
  }
}

/**
 * 투찰 메모 관련 saga
 */
function* watchAddMemo() {
  while (true) {
    const { payload: params } = yield take(voteAction.addMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.vote.memoFilter);
    try {
      yield call(voteApi.addMemo, id, params);
      yield put(voteAction.setMemoFilter(memoFilter));
      if (Array.isArray(params.attendanceList) && params.attendanceList.length > 0) {
        yield put(userNotificationAction.requestCount());
      }
    } catch (e) {
      const message = getErrorMessage(voteAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchFilterMemo() {
  while (true) {
    const { payload: params } = yield take(voteAction.setMemoFilter);
    yield put(voteAction.setMemoLoading(true));
    const id = yield select((root: RootState) => root.proposal.id);
    try {
      const list = yield call(voteApi.getMemoList, id, params);
      yield put(voteAction.setMemoList(list));
    } catch (e) {
      const message = getErrorMessage(voteAction.setMemoFilter, e);
      const code = getErrorCode(voteAction.setMemoFilter, e);
      if (code === CustomHttpStatus.NO_AUTHORIZATION) {
        yield put(dialogAction.openBlock(getCustomErrorMessage(CustomHttpStatus.NO_AUTHORIZATION)));
        return;
      }
      yield put(dialogAction.openError(message));
    } finally {
      yield put(voteAction.setMemoLoading(false));
    }
  }
}

function* watchChangeMemo() {
  while (true) {
    const { payload: params } = yield take(voteAction.changeMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.vote.memoFilter);
    try {
      yield call(voteApi.changeMemo, id, params);
      yield put(voteAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(voteAction.addMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

function* watchMemoDelete() {
  while (true) {
    const { payload: memoId } = yield take(voteAction.deleteMemo);
    const id = yield select((root: RootState) => root.proposal.id);
    const memoFilter = yield select((root: RootState) => root.vote.memoFilter);
    try {
      yield call(voteApi.deleteMemo, id, memoId);
      yield put(voteAction.setMemoFilter(memoFilter));
    } catch (e) {
      const message = getErrorMessage(voteAction.deleteMemo, e);
      yield put(dialogAction.openError(message));
    }
  }
}

export default function* voteSaga() {
  yield fork(watchChangeMemo);
  yield fork(watchAddMemo);
  yield fork(watchFilterMemo);
  yield fork(watchMemoDelete);
  yield fork(watchFilter);
}
