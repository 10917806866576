import React, { useEffect, useMemo } from 'react';
import { useProjectSalesInfoEstimationEstimationLogic } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useLogic';
import { ProjectSalesInfoEstimationEstimationContext } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/context';
import { ProjectSalesInfoEstimationEstimationModal } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-modal';
import { useShallow } from 'zustand/react/shallow';
import { useProjectSalesInfoEstimationEstimationState } from '@front/src/features/project-sales-info/features/estimation/features/estimation/widgets/useState';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { AddDeleteTable } from '@front/src/components/layout/add-delete-table';
import { ProjectSalesInfoEstimationEstimationButtonGroup } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-button-group';
import { ProjectSalesInfoEstimationEstimationTable } from '@front/src/features/project-sales-info/features/estimation/features/estimation/components/estimation-table';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import { Box } from '@mui/material';

interface Props extends SectionComponentProps {}

const ProjectSalesInfoEstimationEstimationWidget = ({
  readOnly,
  name,
  menuId,
}: Readonly<Props>) => {
  const { Header, Body } = AddDeleteTable;
  const {
    d: { list, CMMethodOptionList },
    h: { onDelete },
    isLoading,
  } = useProjectSalesInfoEstimationEstimationLogic(menuId);
  const value = useMemo(
    () => ({
      d: { list, CMMethodOptionList },
      h: { onDelete },
      isLoading,
    }),
    [list, onDelete, isLoading, CMMethodOptionList]
  );
  const { setReadOnly } = useProjectSalesInfoEstimationEstimationState(
    useShallow((state) => ({
      setReadOnly: state.setReadOnly,
    }))
  );
  useEffect(() => {
    setReadOnly(readOnly);
  }, [setReadOnly, readOnly]);
  if (typeof list === 'undefined' || isLoading) {
    return <LoadingSpinner height="172px" />;
  }
  return (
    <ProjectSalesInfoEstimationEstimationContext.Provider value={value}>
      <ProjectSalesInfoEstimationEstimationModal menuId={menuId} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Header title={name}>
          <ProjectSalesInfoEstimationEstimationButtonGroup />
        </Header>
        <Body>
          <ProjectSalesInfoEstimationEstimationTable />
        </Body>
      </Box>
    </ProjectSalesInfoEstimationEstimationContext.Provider>
  );
};

export default ProjectSalesInfoEstimationEstimationWidget;
