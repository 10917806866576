import type { ProposalShortVO } from '@front/ost_proposal/domain';
import type { EvaluationId, EvaluationMemoVO } from '@front/ost_evaluation/domain';
import type { EvaluationMemoQuery, EvaluationQuery } from '@front/ost_evaluation/query';
import { initialEvaluationMemoQuery, initialEvaluationQuery } from '@front/ost_evaluation/query';
import { createReducer } from 'typesafe-actions';
import { EvaluationAction } from '@front/ost_evaluation/action';

export interface EvaluationState {
  /**
   * 제안 목록 및 상세에 필요한 상태
   */
  id?: EvaluationId;
  listDrawerOpen: boolean;
  filter: EvaluationQuery;
  proposalList: ProposalShortVO[];
  loading: boolean;
  /**
   * 제안 메모 관련 상태
   */
  memoDrawerOpen: boolean;
  memoList: EvaluationMemoVO[];
  memoLoading: boolean;
  memoFilter: EvaluationMemoQuery;
}

const initial: EvaluationState = {
  listDrawerOpen: true,
  filter: initialEvaluationQuery,
  proposalList: [],
  loading: false,
  /**
   * 매모 관련 초기 상태
   */
  memoDrawerOpen: true,
  memoList: [],
  memoLoading: false,
  memoFilter: initialEvaluationMemoQuery,
};

export const evaluationReducer = createReducer(initial, {
  /**
   * 제안 목록 및 상세에 필요한 핸들러
   */
  [EvaluationAction.setId]: (state, action) => ({
    ...state,
    id: action.payload,
  }),
  [EvaluationAction.toggleDrawer]: (state, action) => ({
    ...state,
    listDrawerOpen: action.payload,
  }),
  [EvaluationAction.setList]: (state, action) => ({
    ...state,
    proposalList: action.payload,
  }),
  [EvaluationAction.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [EvaluationAction.setFilter]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  /**
   * 평가 메모 관련 핸들러
   */
  [EvaluationAction.setMemoDrawerOpen]: (state, action) => ({
    ...state,
    memoDrawerOpen: action.payload,
  }),
  [EvaluationAction.setMemoList]: (state, action) => ({
    ...state,
    memoList: action.payload,
  }),
  [EvaluationAction.setMemoLoading]: (state, action) => ({
    ...state,
    memoLoading: action.payload,
  }),
  [EvaluationAction.setMemoFilter]: (state, action) => ({
    ...state,
    memoFilter: action.payload,
  }),
});
