import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';

export { query as personalSettingsModalUISettingsModalSettingsQuery };

const query = {
  useGetHeaderList: (id?: number, authMenuId?: number) => {
    const { data } = useQuery({
      queryKey: ['personal-settings-modal', 'ui-settings', 'modal-settings', 'section', id],
      queryFn: () =>
        commonApi.getHeaderList(
          id,
          {
            position: 'table',
            isIncludedBlindItem: false,
            from: 'modal',
          },
          authMenuId
        ),
      enabled: !!id && !!authMenuId,
    });
    return {
      data,
    };
  },
  useGetModalHeaderList: (id, authMenuId) => {
    const { data } = useQuery({
      queryKey: [
        'personal-settings-modal',
        'ui-settings',
        'modal-settings',
        'section',
        id,
        'modal-header-list',
      ],
      queryFn: () => personalSettingsModalApi.getModalHeaderList(id, authMenuId),
      enabled: !!id && !!authMenuId,
    });
    return {
      data,
    };
  },
};
