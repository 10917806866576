import React, { useCallback, useMemo } from 'react';
import Button from '@front/layouts/Button';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useShallow } from 'zustand/react/shallow';
import { usePersonalSettingsModalUISettingsHeaderSettingsPositionState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/position/useState';
import { usePersonalSettingsModalUISettingsHeaderSettingsHistoryState } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/features/history/useState';
import { personalSettingsModalUISettingsHeaderSettingsRepository } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/repository/repository';

export { CheckDuplicateButton as PersonalSettingsModalUISettingsHeaderSettingsTableContentNewNameCheckDuplicateButton };

interface Props {
  index: number;
  authMenuId: number | undefined;
}

const CheckDuplicateButton = ({ index, authMenuId }: Readonly<Props>) => {
  const { setHeader: setHistoryHeader } =
    usePersonalSettingsModalUISettingsHeaderSettingsHistoryState(
      useShallow((state) => ({
        setHeader: state.setHeader,
      }))
    );
  const { setHeader } = usePersonalSettingsModalUISettingsHeaderSettingsPositionState(
    useShallow((state) => ({
      setHeader: state.setHeader,
    }))
  );
  const { data } =
    personalSettingsModalUISettingsHeaderSettingsRepository.useGetHeaderBySectionId(authMenuId);
  const { control } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const list = useWatch({ name: `list`, control });
  const item = useWatch({ name: `list.${index}`, control });
  const {
    field: { onChange: onChangeIsPassed },
  } = useController({ name: `list.${index}.isPassed`, control });
  const {
    field: { onChange: onChangeIsDuplicate },
  } = useController({ name: `list.${index}.isDuplicate`, control });
  const { newName, isPassed, id, categoryId } = item ?? {};
  const foundDuplicate = useCallback(
    (find) => {
      enqueueSnackbar(
        `용어사전의 현 카테고리에 중복이 있어 사용할 수 없습니다. 다른 이름을 입력해주세요. (${newName})`,
        {
          variant: 'error',
        }
      );
      setHeader({
        id: find.headerId,
        name: find.newName || find.currentName,
        isDuplicate: true,
      });
      onChangeIsDuplicate(true);
    },
    [enqueueSnackbar, setHeader, onChangeIsDuplicate, newName]
  );
  const notFoundDuplicate = useCallback(() => {
    onChangeIsPassed(true);
    enqueueSnackbar(`중복되지 않아 사용할 수 있습니다. (${newName})`, {
      variant: 'success',
    });
    setHeader({
      id: item.id,
      name: newName,
      isDuplicate: true,
    });
    onChangeIsDuplicate(false);
  }, [onChangeIsPassed, enqueueSnackbar, setHeader, item, newName]);
  const handleClick = useCallback(() => {
    if (!newName) return;
    setHistoryHeader(undefined);
    const findFromAll = data?.find((el) => el.categoryId !== categoryId && el.id === id);
    const find = list.find((l) => {
      if (l.newName === '') {
        return newName === l.currentName;
      }
      if (l.isPassed) {
        return newName === l.newName;
      }
      return newName === l.currentName;
    });
    if (find || findFromAll) {
      foundDuplicate(find || findFromAll);
      return;
    }
    notFoundDuplicate();
  }, [newName, setHistoryHeader, list, id, foundDuplicate, notFoundDuplicate, data, categoryId]);
  const disabled = useMemo(() => isPassed || newName === '', [isPassed, newName]);
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
    >
      중복확인
    </Button>
  );
};
