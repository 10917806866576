import React from 'react';
import { TableBody, TableContainer } from '@mui/material';
import { PersonalSettingsModalUISettingsHeaderSettingsTableHeader } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/TableHeader';
import { PersonalSettingsModalUISettingsHeaderSettingsTableContent } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/components/TableContent';
import { CustomTable } from '@front/src/components/ui-builder/table';

export { UISettingsTable as PersonalSettingsModalUISettingsHeaderSettingsTable };

const UISettingsTable = () => (
  <TableContainer>
    <CustomTable>
      <PersonalSettingsModalUISettingsHeaderSettingsTableHeader />
      <TableBody>
        <PersonalSettingsModalUISettingsHeaderSettingsTableContent />
      </TableBody>
    </CustomTable>
  </TableContainer>
);
