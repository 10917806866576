import { useQuery } from 'react-query';
import userSelectorApi from '@front/src/features/user-selector/api/api';

const userSelectorQuery = {
  useGetUser: (id) => {
    const { data } = useQuery({
      queryFn: () => userSelectorApi.getUser(id),
      queryKey: ['user-selector', 'user', id],
      enabled: !!id,
    });
    return { data };
  },
  useGetAllUser: () => {
    const { data } = useQuery({
      queryFn: () =>
        userSelectorApi.getUserList({
          keyword: '',
          departmentId: null,
          menuIdList: '',
          typeList: '',
        }),
      queryKey: ['user-selector', 'all-user'],
    });
    return { data };
  },
  useGetDepartmentList: () => {
    const { data } = useQuery({
      queryFn: () => userSelectorApi.getDepartmentList(),
      queryKey: ['user-selector', 'department-list'],
    });
    return { data };
  },
  useGetUserList: (params) => {
    const { data } = useQuery({
      queryFn: () => userSelectorApi.getUserList(params),
      queryKey: ['user-selector', 'user-list', params],
    });
    return { data };
  },
};

export default userSelectorQuery;
