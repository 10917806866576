import { useForm } from 'react-hook-form';
import type { SortStatus } from '@front/src/types';
import { useEffect } from 'react';
import { getDefaultFilterBundle } from '@front/src/components/ui-builder/table/utils';

const useUIBuilderTableForm = ({ list, headerList }) => {
  const methods = useForm<UIBuilderTableFieldValues>({
    defaultValues,
  });

  const { setValue, reset, getValues } = methods;

  const initializeFilterBundle = () => getDefaultFilterBundle(headerList, list);
  const resetValues = () => {
    const currentValues = getValues();
    reset({
      ...currentValues,
      filterBundle: initializeFilterBundle(),
      showList: list,
    });
  };

  const onCloseEditModal = (reset) => {
    reset();
    setValue('headerId', undefined);
    setValue('rowId', undefined);
  };

  const onOpenFileAuthSettingsModal = () => {
    setValue('openFileAuthSettingsModal', true);
  };
  const setFileItemId = (id) => {
    setValue('fileItemId', id);
  };

  useEffect(() => {
    if (list && headerList) {
      resetValues();
    }
  }, [list, headerList]);

  return {
    methods,
    onCloseEditModal,
    onOpenFileAuthSettingsModal,
    setFileItemId,
    resetValues,
  };
};

export default useUIBuilderTableForm;

const defaultValues = {
  fileItemId: undefined,
  openFileAuthSettingsModal: false,
  openHeaderSettingsHistoryModal: false,
  headerId: undefined,
  rowId: undefined,
  sortPriorityList: [],
  useFilter: false,
  filterBundle: [],
  showList: undefined,
  showAllRow: false,
  recentUpdatedId: undefined,
  editOpenIdList: [],
};

export interface UIBuilderTableFieldValues {
  fileItemId: number | undefined;
  openFileAuthSettingsModal: boolean;
  openHeaderSettingsHistoryModal: boolean;
  headerId: number | undefined;
  rowId: number | undefined;
  sortPriorityList: {
    id: number;
    sortStatus: SortStatus;
  }[];
  useFilter: boolean;
  filterBundle: UIBuilderTableFilterBundle[];
  showList: any[] | undefined;
  showAllRow: boolean;
  recentUpdatedId: number | undefined;
  editOpenIdList: number[];
}

export interface UIBuilderTableFilterBundle {
  headerId: number;
  name: string;
  active: boolean;
  attributeName?: string[];
  filter: UIBuilderTableFilterItem[];
}

export interface UIBuilderTableFilterItem {
  value: string;
  checked: boolean;
  shown: boolean;
}
