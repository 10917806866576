import { getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import { projectSalesInfoSubjectReviewModalQuery } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/query';
import { projectSalesInfoSubjectReviewModalMutation } from '@front/src/features/project-sales-info/features/subject-review/features/modal/query/mutation';

export const projectSalesInfoSubjectReviewModalRepository = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading, remove } = projectSalesInfoSubjectReviewModalQuery.useDetailGet(
      id,
      menuId
    );
    return {
      data,
      isLoading,
      remove,
    };
  },
  useUpdate: (menuId) => {
    const { mutate } = projectSalesInfoSubjectReviewModalMutation.useUpdate(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useDelete: (menuId) => {
    const { mutate } = projectSalesInfoSubjectReviewModalMutation.useDelete(menuId);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
