import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate } from 'react-router-dom';
import useProjectSalesCreate from '@front/src/features/project-sales/features/aside/features/create-button/repository/useCreate';

const useProjectSalesCreateLogic = ({ onClose, menuId }) => {
  const { tabList } = useGlobalNavBarState(
    useShallow((state) => ({
      tabList: state.tabList,
    }))
  );
  const { run: onCreate, setCallback } = useProjectSalesCreate({ menuId });
  const navigate = useNavigate();
  setCallback({
    onSuccess: ({ data }) => {
      const converted = tabList?.[0].path?.replace('{:id}', `${data}`) ?? '/sales';
      navigate(converted);
      onClose();
    },
  });
  return {
    onCreate,
  };
};

export default useProjectSalesCreateLogic;
