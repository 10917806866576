import { useQuery } from 'react-query';
import { projectSalesInfoEstimationCompetitorApi } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/query/api';
import type { ProjectEstimationCompetitorView } from '@front/src/features/project-sales-info/features/estimation/features/estimation-competitor/types/view';

export { query as projectSalesInfoEstimationCompetitorQuery };

const query = {
  useListGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectEstimationCompetitorView[]>({
      queryFn: () => projectSalesInfoEstimationCompetitorApi.getList(id, menuId),
      queryKey: ['project', 'sales-info', 'estimation', 'estimation-competitor', 'list', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
