import dayjs from 'dayjs';
import { controlPanelTableCellWidth, DATE_TIME_FORMAT } from '@front/src/utils/constant';
import type { ProjectCollectionManagementParams } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/features/contract-item/features/update-modal/features/manage-collection-modal/types/parameter';
import type { ProjectContribution } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/types/domain';
import { affiliatedCompanyPersonContributionOptionForLabel } from '@front/src/features/project-sales-info/features/affiliated-company/features/affiliated-company-person/utils/constant';
import type { MenuShortView } from '@front/user/domain';
import { AuthorizationType } from '@front/user/domain';
import type { HeaderSettingView } from '@front/src/types';
import headerMeta from '@front/src/components/ui-builder/headerMeta';

export * from '@front/src/utils/constant';

export const isEmpty = (input) =>
  typeof input === 'undefined' ||
  input === null ||
  input === '' ||
  input.trim() === '' ||
  input === 'null' ||
  input.length === 0 ||
  (typeof input === 'object' && !Object.keys(input).length);

export const convertEmptyToNull = (input) => {
  const copy = { ...input };
  Object.keys(copy).forEach((key) => {
    if (typeof copy[key] !== 'boolean') {
      copy[key] = copy[key] || null;
    }
  });
  return copy;
};

export const convertBooleanToYNIfExistForForm = (value) => {
  if (typeof value !== 'boolean') {
    return '';
  }
  if (value) {
    return 'Y';
  }
  return 'N';
};
export const convertNullToEmptyForForm = (value) => value ?? '';

export const convertNumberToStringForForm = (value) => value?.toString() ?? '';
export const convertAmountToStringForForm = (value) => value?.toLocaleString() ?? '';

export const convertYNToBooleanForParameter = (value) => {
  if (value === 'Y' || value === true) {
    return true;
  }
  if (value === 'N' || value === false) {
    return false;
  }
  return null;
};

export const convertEmptyToNullIfNumberForParameter = (value: string) => {
  if (!value) {
    return null;
  }
  return +value;
};

export const convertEmptyToNullForParameter = (value) => value || null;
export const convertEmptyToNullForNumberParameter = (value) => (value ? +value : null);
export const convertEmptyToNullForAmountParameter = (value) => {
  if (!value) {
    return null;
  }
  if (value[0] === '-') {
    return Number(value.slice(1, value.length).split(',').join('')) * -1;
  }
  return +value.split(',').join('');
};

export const downloadFile = (id) => () => {
  if (typeof id === 'undefined') {
    return;
  }
  window.location.assign(`/api/file-item/${id}`);
};

export const getChecked = (id, idList) => idList.includes(id);

export const handleCheckboxChange = (id, idList, setIdList) => {
  setIdList(spliceAndPushIfExist(idList, id));
};

export const getValueIfExist = (value) => {
  //TODO: string undefined 처리
  if (value === 'undefined') return '-';
  return value ?? '-';
};
export const getValueIfEmpty = (value) => value || '-';
export const getLocaleStringIfExist = (value) => value?.toLocaleString() ?? '-';

export const convertBooleanToYNForLabel = (value: boolean | null) => {
  if (typeof value !== 'boolean') return '-';
  return value ? 'Y' : 'N';
};

export const handleDeleteIdListConfirmAfterClick = (idList, confirm, afterConfirm) => {
  if (idList.length === 0) {
    return;
  }
  confirm({
    children: '선택한 내용을 삭제하시겠습니까?',
    confirmText: '삭제',
    closeText: '취소',
    afterConfirm,
  });
};

export const handleDeleteIdConfirmAfterClick = (id, confirm, MSG, afterConfirm) => {
  if (typeof id === 'undefined') {
    return;
  }
  confirm({
    children: MSG || '선택한 내용을 삭제하시겠습니까?',
    confirmText: '삭제',
    closeText: '취소',
    afterConfirm,
  });
};

export const convertDateToStringFormat = (date, format = DATE_TIME_FORMAT) => {
  if (!date) {
    return '-';
  }
  return dayjs(date).format(format);
};
export const confirmDeleteAll = (confirm, afterConfirm) => {
  confirm({
    children: '알림을 전부 삭제하시겠습니까?',
    confirmText: '삭제',
    closeText: '취소',
    afterConfirm,
  });
};

export const getValueToYNIfExist = (value) => {
  if (typeof value !== 'boolean') {
    return '-';
  }
  if (value) {
    return 'Y';
  }
  return 'N';
};

export const YNToStrConvertor = (value: boolean | undefined) => {
  if (typeof value === 'undefined' || value === null) return '';
  return value ? 'Y' : 'N';
};

export const strToYNConvertor = {
  Y: true,
  N: false,
};

export const spliceAndPushIfExist = (idList: number[], id: number) => {
  const index = idList.findIndex((item) => item === id);
  const updatedIdList = [...idList];
  if (index !== -1) {
    updatedIdList.splice(index, 1);
  } else {
    updatedIdList.push(id);
  }
  return updatedIdList;
};

export const calcUnitPrice = (expInfo: string, expAmount: number) => {
  if (expAmount === 0 || !expAmount) return '';
  if (!expInfo || !expInfo.match(/.(?=F)|.(?=P)/)) return '';
  const windForce = expInfo.match(/.(?=F)/)?.[0] ?? 0;
  const windPressure = expInfo.match(/.(?=P)/)?.[0] ?? 0;
  const resultBoth = expAmount / ((+windForce + +windPressure) / 2);
  const resultSingle = expAmount / (+windForce || +windPressure);
  const result = windForce && windPressure ? resultBoth : resultSingle;

  if (isNaN(result)) return '';
  return Math.round(result).toLocaleString();
};

export const convertEmptyClientStateToNull = (
  collectionList: ProjectCollectionManagementParams[]
) =>
  collectionList.map((collection) => {
    const collectionCopy = { ...collection };
    Object.keys(collection).forEach((key) => {
      if (typeof collectionCopy[key] !== 'boolean')
        collectionCopy[key] = collectionCopy[key] || null;
    });
    return collectionCopy;
  });

export const openNewTabFromUrl = (url) => {
  if (!url) return;
  window.open(url, '_blank', 'noopener, noreferrer');
};

export const convertProjectContribution = (value: ProjectContribution | null | undefined) => {
  if (value === null || value === undefined) {
    return '-';
  }
  return affiliatedCompanyPersonContributionOptionForLabel[value];
};

export const convertValueToEmptyFromObjectForFormIfIsNull = (input) => {
  Object.keys({ ...input }).forEach((key) => {
    input[key] = input[key] ?? '';
  });
  return input;
};

export const filterUndefinedAndNull = (list: string[]) => {
  if (!Array.isArray(list) || list.length === 0) {
    return [];
  }
  return list.filter((item) => item !== undefined && item !== null);
};

export const toggleItemInArray = (array, item) => {
  const index = array.indexOf(item);
  if (index > -1) {
    return array.filter((value) => value !== item);
  } else {
    return [...array, item];
  }
};

export const downloadFileFromS3 = (id) => () => {
  if (typeof id === 'undefined') {
    return;
  }
  window.location.assign(`/api/file-item/${id}`);
};

export const getIsShowForAuthorizationFromTab = (menus?: MenuShortView[]) => {
  const menuId = sessionStorage.getItem('menuId');
  if (!menuId || !menus) return false;
  const find = menus.find((m) => m.id === +menuId);
  if (!find) return false;
  return menus
    .filter((m) => m.parentId === find.parentId)
    .some((f) => f.authorization.type === AuthorizationType.W);
};

export const getIsShowForAuthorizationFromMenu = (menus?: MenuShortView[]) => {
  const menuId = sessionStorage.getItem('menuId');
  if (!menuId || !menus) return false;
  const find = menus.find((m) => m.id === +menuId);
  if (!find) return false;
  return find.authorization.type === AuthorizationType.W;
};

export const getReadOnlyForAuthorizationFromChildren = (menus?: MenuShortView[]) => {
  const menuId = sessionStorage.getItem('menuId');
  if (!menuId || !menus) return true;
  const filter = menus.filter((m) => m.parentId === +menuId);
  if (filter.length === 0) return true;
  return !filter.some((f) => f.authorization.type === AuthorizationType.W);
};

export const getReadOnlyFromChildren = (menus?: MenuShortView[], menuId?) => {
  if (!menuId || !menus) return true;
  const filter = menus.filter((m) => m.parentId === +menuId);
  if (filter.length === 0) return true;
  return !filter.some((f) => f.authorization.type === AuthorizationType.W);
};

export const getReadonlyForAuthorizationFromMenu = (menus?: MenuShortView[]) => {
  const menuId = sessionStorage.getItem('menuId');
  if (!menuId || !menus) return true;
  const find = menus.find((m) => m.id === +menuId);
  if (!find) return true;
  return find.authorization.type !== AuthorizationType.W;
};

export const getReadonlyFromMenu = (menus?: MenuShortView[], menuId?) => {
  if (!menuId || !menus) return true;
  const find = menus.find((m) => m.id === +menuId);
  if (!find) return true;
  return find.authorization.type !== AuthorizationType.W;
};

export const getLeftListFromHeaderList = (headerList?: HeaderSettingView[], readOnly?: boolean) => {
  if (headerList === undefined || headerList.length === 0) return undefined;
  const leftList: number[] = readOnly ? [0] : [controlPanelTableCellWidth];
  const stickyIdx = headerList.findIndex((item) => item.isFixed);
  for (let i = 0; i < headerList.length; i += 1) {
    if (i >= stickyIdx) {
      break;
    }
    const h = headerList[i];
    const lastLeft = leftList.at(-1);
    leftList.push(lastLeft! + headerMeta[h.id].width);
  }
  return leftList;
};

export const getCaretCoordinates = (
  textArea: HTMLTextAreaElement,
  caretPosition: number,
  container: HTMLDivElement | null
) => {
  const mirroredEl = document.createElement('div');
  const originalStyle = getComputedStyle(textArea);
  [
    'border',
    'boxSizing',
    'fontFamily',
    'fontSize',
    'fontWeight',
    'letterSpacing',
    'lineHeight',
    'padding',
    'textDecoration',
    'textIndent',
    'textTransform',
    'whiteSpace',
    'wordSpacing',
    'wordWrap',
  ].forEach((property) => (mirroredEl.style[property] = originalStyle[property]));
  mirroredEl.style.visibility = 'hidden';
  container?.prepend(mirroredEl);

  const textContent = textArea.value.slice(0, caretPosition);
  const remainingText = textArea.value.slice(caretPosition);

  const pre = document.createTextNode(textContent);
  const post = document.createTextNode(remainingText);
  const span = document.createElement('span');

  span.textContent = '\u200B';
  mirroredEl.style.position = 'absolute';
  mirroredEl.style.color = 'transparent';
  mirroredEl.append(pre, span, post);

  const rect = span.getBoundingClientRect();
  const containerRect = container?.getBoundingClientRect();
  container?.removeChild(mirroredEl);

  return {
    top: rect.top - (containerRect?.top ?? 0),
    left: rect.left - (containerRect?.left ?? 0),
  };
};

export const setStyledMentionOverlay = (
  text: string,
  textArea: HTMLElement | null,
  top: number,
  left: number
) => {
  const span = document.createElement('span');
  span.style.color = 'blue';
  span.style.position = 'absolute';
  span.style.top = `${top}px`;
  span.style.left = `${left + 2}px`;
  // span.style.fontSize = '20px';

  span.textContent = `@${text}`;
  span.className = `${text}`;
  textArea?.append(span);
};

export const approvalDocumentStatusToKor = {
  TEMPORARY: '임시 저장',
  SUBMISSION: '결재 상신',
  ON_GOING: '결재 진행중',
  COMPLETED: '결재 완료',
  REJECTED: '반려',
  CANCELED: '상신 취소',
};

export const isImg = (str: string) => /(jpg|jpeg|png|webp|avif|gif|svg|pdf)$/.test(str);

export const getMenuIdHeaders = (menuId) => ({
  'Menu-Id': menuId,
});

export const getSpareParameter = (data) => ({
  textAttr1: data.textAttr1,
  textAttr2: data.textAttr2,
  textAttr3: data.textAttr3,
  numberAttr1: data.numberAttr1,
  numberAttr2: data.numberAttr2,
  numberAttr3: data.numberAttr3,
  dateAttr1: data.dateAttr1,
  dateAttr2: data.dateAttr2,
  dateAttr3: data.dateAttr3,
});

export const fillNullValues = (data) => {
  const params = {
    ...data,
  };
  Object.keys(params).forEach((key) => {
    params[key] = params[key] || null;
  });
  return params;
};

/**
 * Element로 스크롤 이동 후 callback 실행 유틸리티 함수
 * @param element 스크롤 대상 엔리먼트
 * @param callback 스크롤 이동 후 콜백
 * @param threshold 스크롤 viewport 겹칩 정도(0~1.0, 기본값 1.0)판정 기준(콜백 실행 문턱값)
 */
export const scrollToElement = (element: HTMLElement, callback: () => void, threshold?: number) => {
  element.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setTimeout(()=>{
          callback()
        },200);
        observer.disconnect()
      }
    },
    { threshold: threshold ?? 1.0 }
  )

  observer.observe(element)
}
