import { approvalDocumentApi } from '@front/src/features/drawer-approval-document/api/api';
import { useMutation, useQueryClient } from 'react-query';
import type { ApprovalDecisionUpdateParams } from '@front/src/features/drawer-approval-document/types/parameter';
import type {
  CommentFilterParams,
  CommentParams,
  CommentUpdateParams,
} from '@front/src/features/note/types/parameter';

export const approvalDocumentMutation = {
  useCancelApprovalDocument: () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (documentId: number) => approvalDocumentApi.cancelApprovalDocument(documentId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['approval-box']);
      },
    });
    return { mutate };
  },

  useDecideApprovalDocument: (approvalId: number) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: ApprovalDecisionUpdateParams) =>
        approvalDocumentApi.decideApprovalDocument(approvalId, params),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['approval-document']);
        await queryClient.invalidateQueries(['approval-box']);
        await queryClient.invalidateQueries(['note', 'list']);
      },
    });
    return { mutate };
  },

  useCreateComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['approval-document', 'comment', 'create'],
      mutationFn: (params: CommentParams) => approvalDocumentApi.createComment(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['approval-document', 'comment', commentQueryParams],
        });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
      },
    });
    return { mutate, isLoading };
  },

  useUpdateComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['approval-document', 'comment', 'update'],
      mutationFn: (params: CommentUpdateParams) => approvalDocumentApi.updateComment(params),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['approval-document', 'comment', commentQueryParams],
        });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
      },
    });
    return { mutate, isLoading };
  },

  useDeleteComment: (commentQueryParams: CommentFilterParams) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationKey: ['approval-document', 'comment', 'delete'],
      mutationFn: (commentId: number) => approvalDocumentApi.deleteComment(commentId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['approval-document', 'comment', commentQueryParams],
        });
        await queryClient.invalidateQueries({ queryKey: ['approval-document'] });
        await queryClient.invalidateQueries({ queryKey: ['approval-box', 'detail'] });
      },
    });
    return { mutate, isLoading };
  },
};
