import { default as axios } from '@front/src/config/axios';
import type { AffiliatedCompanyIdListDeleteParameter } from '@front/src/features/affiliated-company/types/parameter';
import { convertEmptyToNull, getMenuIdHeaders } from '@front/src/utils';
import { baseUrl } from '@front/src/features/affiliated-company/utils/constant';
import type { AffiliatedCompanyOutlineWebHardUpdateParameter } from '@front/src/features/affiliated-company/features/outline/features/web-hard/types/parameter';

const url = {
  getList: (id?) => `${baseUrl}/${id}/web-hard`,
  create: (id?) => `${baseUrl}/${id}/web-hard`,
  delete: () => `${baseUrl}/web-hard`,
  update: (id?) => `${baseUrl}/web-hard/${id}`,
};

export const affiliatedCompanyWebHardApi = {
  getList: async (id?: number, menuId?) => {
    const { data } = await axios.get(url.getList(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  create: async (id?: number, menuId?) => {
    await axios.post(url.create(id), null, {
      headers: getMenuIdHeaders(menuId),
    });
  },
  delete: async (params: AffiliatedCompanyIdListDeleteParameter, menuId) => {
    await axios.delete(url.delete(), { data: params, headers: getMenuIdHeaders(menuId) });
  },
  update: async (params: AffiliatedCompanyOutlineWebHardUpdateParameter, menuId) => {
    await axios.put(url.update(params.id), convertEmptyToNull(params), {
      headers: getMenuIdHeaders(menuId),
    });
  },
};
