import { useMutation, useQueryClient } from 'react-query';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';
import type { AuthPersonalSettingsModalCUDParams } from '@front/src/features/personal-settings-modal/features/auth-settings/types/parameter';
import type {
  AuthUpdateParams,
  AuthUserSearchParams,
} from '@front/src/features/admin/features/access-authorization-settings/type/parameter';
import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';

export const personalSettingsModalMutation = {
  useUserListCud: (menuId: number, params: AuthUserSearchParams, authMenuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AuthPersonalSettingsModalCUDParams) =>
        personalSettingsModalApi.cudUserList(menuId, params, authMenuId),
      onMutate: async (newData) => {
        await queryClient.invalidateQueries({ queryKey: [] });
        await queryClient.cancelQueries(['personal-settings-modal', menuId, params.keyword]);
        const response = queryClient.getQueryData<{
          data: AuthorizationPersonalSettingsUserView[] | undefined;
        }>(['personal-settings-modal', menuId, params.keyword]);
        const previousData = response?.data;

        const updateTypeOptimistic = (
          list: AuthorizationPersonalSettingsUserView[] | undefined
        ) => {
          if (!list) return [];
          list.map((item) => ({
            ...item,
            menus: item.menus.map((el) => {
              const updateData = newData?.updateIdList?.find(
                (updateItem) => updateItem.authorizationByUserAndMenuId === el.id
              );
              return updateData
                ? {
                    ...el,
                    authorizationUser: updateData.type,
                  }
                : el;
            }),
          }));
        };

        queryClient.setQueryData(
          ['personal-settings-modal', menuId, params.keyword],
          updateTypeOptimistic(previousData)
        );

        return () =>
          queryClient.setQueryData(
            ['personal-settings-modal', menuId, params.keyword],
            previousData
          );
      },
      onError: (error, _, rollback) => {
        if (rollback) rollback();
        else console.log(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['personal-settings-modal', menuId, params.keyword]);
      },
    });

    return {
      mutate,
    };
  },

  useDeptListUpdate: (authMenuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: AuthUpdateParams) =>
        personalSettingsModalApi.updateDeptList(params, authMenuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [] });
      },
    });
    return {
      mutate,
    };
  },
};
