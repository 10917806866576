import React, { useCallback } from 'react';
import { OldTd } from '@front/layouts/Table';
import { TableRow } from '@mui/material';
import type { ProjectSalesInfoBidResultView } from '@front/src/features/project-sales-info/features/bid/features/bid-result/types/view';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import { spliceAndPushIfExist } from '@front/src/features/project-sales-info/features/bid/features/bid-result/utils/constants';
import Checkbox from '@front/layouts/Checkbox';
import { AMOUNT_CHANGE_REGEX } from '@front/src/features/affiliated-company/utils/constant';
import { convertBooleanToYNForLabel, getValueIfExist } from '@front/src/utils';
import { useShallow } from 'zustand/react/shallow';

export { BidResultTableRow as ProjectSalesInfoBidResultTableRow };

interface Props {
  item: ProjectSalesInfoBidResultView;
  index: number;
  list: ProjectSalesInfoBidResultView[];
}

const BidResultTableRow = ({ item, list }: Props) => {
  const { setIdList, idList, setIsUpdateModalOpen, setDetailId } =
    useProjectSalesInfoBidResultState((state) => ({
      setIdList: state.setIdList,
      idList: state.idList,
      setIsUpdateModalOpen: state.setIsUpdateModalOpen,
      setDetailId: state.setDetailId,
    }));
  const handleCheckboxChange = useCallback(
    (id) => () => {
      setIdList(spliceAndPushIfExist(idList, id));
    },
    [setIdList, idList]
  );
  const newList = list.filter((el) => el.projectBidInformationId === item.projectBidInformationId);
  const isFirst = newList[0].id === item.id;
  const len = newList.length;
  const { readOnly } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  const onRowClick = () => {
    setDetailId(item.id);
    setIsUpdateModalOpen();
  };
  return (
    <TableRow
      hover={true}
      style={{ cursor: 'pointer' }}
      onClick={onRowClick}
    >
      <OldTd>
        <Checkbox
          checked={idList.includes(item.id)}
          onChange={handleCheckboxChange(item.id)}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          disabled={readOnly}
        />
      </OldTd>
      {isFirst && (
        <OldTd
          rowSpan={len}
          style={{ backgroundColor: 'white' }}
        >
          {getValueIfExist(item.seq)}
        </OldTd>
      )}
      <OldTd>{getValueIfExist(item.winnerCompanyName)}</OldTd>
      <OldTd>
        {item.amount
          ? String(item.amount).replace(/\D/g, '').replace(AMOUNT_CHANGE_REGEX, ',').trim()
          : '-'}
      </OldTd>
      <OldTd>{convertBooleanToYNForLabel(item.isDoneBid)}</OldTd>
      <OldTd>{getValueIfExist(item.note)}</OldTd>
    </TableRow>
  );
};
