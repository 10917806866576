import React, { useContext } from 'react';
import type { LoginVO } from '@front/login/domain';
import type { ArrangementMemoId, ArrangementMemoVO } from '@front/ost_arrangement/domain';
import type { ArrangementMemoChangeParameter } from '@front/ost_arrangement/parameter';
import { FormikContext, FormikProvider } from 'formik';
import useDialog from '@front/dialog/hook';
import { fixedMemoFormAndFilterHeight } from '@front/util/ConstantCommonVariable';
import { Box, Tooltip, Typography } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import TextBox from '@front/layouts/Text';
import CircularProgress from '@front/components/CircularProgress';
import DateFormat from '@front/layouts/DateFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import UserIcon from '@front/layouts/UserIcon';

interface Props {
  loading: boolean;
  list: ArrangementMemoVO[];
  login: LoginVO | undefined;
  onChange: (params: ArrangementMemoChangeParameter) => void;
  onDelete: (id: ArrangementMemoId) => void;
  formHeight: number;
}

const ArrangementMemoList = ({ list, loading, login, onDelete, formHeight }: Props) => {
  const formik = useContext(FormikContext);
  const { confirm } = useDialog();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: `calc(100dvh - ${fixedMemoFormAndFilterHeight + formHeight}px)`,
        flexWrap: 'nowrap',
        flexDirection: 'column',
        overflowY: 'hidden',
        alignItems: 'flex-start',
        padding: '0 0 15px 0',
      }}
    >
      <Box
        className="scroll-bar-holder"
        sx={{
          width: '100%',
          height: `100%`,
          overflowY: 'auto',
          padding: '0 10px 0px 10px',
        }}
      >
        {list && Array.isArray(list) && list.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              border: `1px solid ${ColorPalette._e4e9f2}`,
              borderRadius: '5px',
              marginBottom: '10px',
              backgroundColor: ColorPalette._ffffff,
              padding: '15px',
              justifyContent: 'center',
            }}
          >
            <TextBox
              variant="body2"
              sx={{ minHeight: '30px', lineHeight: '30px' }}
            >
              {loading && <CircularProgress size={30} />}
              {!loading && <>해당하는 메모가 없습니다.</>}
            </TextBox>
          </Box>
        )}
        {list &&
          list.map((item, index) => (
            <Box
              key={`${item.id}_${index}`}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                border: `1px solid ${ColorPalette._e4e9f2}`,
                borderRadius: '5px',
                marginBottom: '10px',
                backgroundColor: ColorPalette._ffffff,
                padding: '15px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'unwrap',
                  width: '100%',
                  justifyContent:
                    item.writer.id === (login?.id ?? 1) ? 'space-between' : 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'unwrap',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      marginRight: '4px',
                    }}
                  >
                    <DateFormat
                      date={item.createdAt}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      marginRight: '4px',
                    }}
                  >
                    {item.writer.department.name === '한양풍동실험연구소'
                      ? '회사'
                      : item.writer.department.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.writer.name}
                  </Typography>
                </Box>
                {item.writer.id === (login?.id ?? 1) && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'unwrap',
                      visibility: formik.values.id === item.id ? 'hidden' : 'visible',
                    }}
                  >
                    <Tooltip title={'수정'}>
                      <FontAwesomeIcon
                        icon={'pen'}
                        style={{
                          cursor: 'pointer',
                          fontSize: '11px',
                          color: ColorPalette._9bb6ea,
                          marginRight: '10px',
                        }}
                        onClick={() => {
                          formik.setValues({
                            id: item.id,
                            description: item.description,
                          });
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={'삭제'}>
                      <FontAwesomeIcon
                        icon="trash"
                        style={{
                          cursor: 'pointer',
                          fontSize: '11px',
                          color: ColorPalette._9bb6ea,
                        }}
                        onClick={() => {
                          confirm({
                            children: '해당 메모를 삭제하시겠습니까?',
                            afterConfirm: () => {
                              onDelete(item.id);
                            },
                            confirmText: '확인',
                          });
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
              {item.modifiedAt && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'unwrap',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: 'bold',
                      marginRight: '4px',
                    }}
                  >
                    (
                    <DateFormat
                      date={item.modifiedAt}
                      format="YYYY-MM-DD HH:mm"
                    />{' '}
                    편집됨)
                  </Typography>
                </Box>
              )}
              {formik.values.id !== item.id && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    padding: '12px 0',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '13px',
                      color: ColorPalette._252627,
                      wordBreak: 'break-all',
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    <TextBox variant="body9">{item.description}</TextBox>
                  </Typography>
                </Box>
              )}
              {formik.values.id === item.id && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    padding: '12px 0',
                  }}
                >
                  <FormikProvider value={formik}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        border: `1px solid ${ColorPalette._e4e9f2}`,
                        marginBottom: '5px',
                      }}
                    >
                      <Input
                        required
                        multiline
                        variant="outlined"
                        placeholder="메모 입력"
                        key={formik.values.description}
                        defaultValue={formik.values.description ?? ''}
                        onBlur={(e) => {
                          const value = e.target.value || undefined;
                          if (formik.values.description !== value) {
                            formik.setFieldValue('description', value);
                          }
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          sx={{
                            margin: '0px 5px',
                          }}
                          onClick={() => {
                            if (formik.values.id === item.id) {
                              formik.handleSubmit();
                            }
                          }}
                        >
                          저장
                        </Button>
                        <Button
                          shape="basic2"
                          onClick={() => {
                            if (formik.values.id === item.id) {
                              formik.setValues({});
                            }
                          }}
                        >
                          취소
                        </Button>
                      </Box>
                    </Box>
                  </FormikProvider>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                {item.attendanceList?.length !== 0 && (
                  <>
                    {item.attendanceList?.map((item) => (
                      <UserIcon
                        key={item}
                        user={item}
                        sx={{
                          marginRight: '4px',
                        }}
                      />
                    ))}
                  </>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ArrangementMemoList;
