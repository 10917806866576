import React from 'react';
import HeaderSettingsHistoryModal from '@front/src/features/header-history/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';

interface Props {
  sectionId?: number;
}

export default function UIBuilderHeaderSettingsHistoryModal(props: Readonly<Props>) {
  const { sectionId } = props;

  const { watch, setValue } = useFormContext<UIBuilderTableFieldValues>();
  const open = watch('openHeaderSettingsHistoryModal');

  const onClose = () => setValue('openHeaderSettingsHistoryModal', false);

  return (
    <HeaderSettingsHistoryModal
      open={open}
      onClose={onClose}
      sectionId={sectionId}
    />
  );
}
