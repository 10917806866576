import workBasicInfoMutation from '@front/src/features/work/features/work/features/basic-info/query/mutation';

const useWorkBasicInfoMutationLogic = ({ dataId, menuId, sectionId }) => {
  const commonParams = { dataId, menuId, sectionId };
  const { mutate: onUpdate } = workBasicInfoMutation.useUpdate(commonParams);
  return {
    onUpdate,
  };
};

export default useWorkBasicInfoMutationLogic;
