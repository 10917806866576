import { useMutation, useQueryClient } from 'react-query';
import { personalSettingsModalApi } from '@front/src/features/personal-settings-modal/api/api';
import type { HeaderSettingUpdateParameter } from '@front/src/features/personal-settings-modal/features/ui-settings/features/header-settings/types/parameter';

export { mutation as personalSettingsModalUISettingsHeaderSettingsMutation };
const mutation = {
  useUpdateHeader: (authMenuId) => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: HeaderSettingUpdateParameter) =>
        personalSettingsModalApi.updateHeader(params, authMenuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
    return {
      mutate,
    };
  },
};
