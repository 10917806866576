import type { ReactNode } from 'react';
import React from 'react';

export { SearchPartHeader as ProjectSalesInfoAffiliatedCompanyPersonCreateModalLayoutSearchPartHeader };

interface Props {
  children: ReactNode;
}

const SearchPartHeader = ({ children }: Props) => <div>{children}</div>;
