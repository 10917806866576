import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { getCommonControlPanelHeaderItemList } from '@front/src/features/work/features/work/utils/constant';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import useWorkReferenceState from '@front/src/features/work/features/work/features/reference/useState';
import WorkReferenceTableBody from '@front/src/features/work/features/work/features/reference/components/TableBody';
import useWorkReferenceGetIsLoading from '@front/src/features/work/features/work/features/reference/hooks/useGetIsLoading';
import useWorkReferenceTableMutationLogic from '@front/src/features/work/features/work/features/reference/hooks/useTableMutation';
import workReferenceQuery from '@front/src/features/work/features/work/features/reference/query/query';

export default function WorkReferenceTable(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;
  const { clearEditOpenList } = useWorkReferenceState(
    useShallow((state) => ({
      clearEditOpenList: state.clearEditOpenList,
    }))
  );

  const { onAddTop, onAddBottom, onRemoveAll } = useWorkReferenceTableMutationLogic({
    dataId,
    menuId,
    sectionId,
  });

  const actions = {
    onRemoveAll,
    onAddTop,
    onAddBottom,
    onCloseAll: clearEditOpenList,
  };

  const controlPanelHeaderItemList = getCommonControlPanelHeaderItemList({
    actions,
  });

  const isLoading = useWorkReferenceGetIsLoading();

  const { data: list } = workReferenceQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <UiBuilderTable
      {...props}
      isLoading={isLoading}
      BodyComponent={WorkReferenceTableBody}
      controlPanelHeaderItemList={controlPanelHeaderItemList}
      list={list}
    />
  );
}
