import { create } from 'zustand';

interface State {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: () => void;
  isList: boolean;
  setIsList: (isList: boolean) => void;
  isDirectory: boolean;
  setIsDirectory: (isDirectory: boolean) => void;
  isDetails: boolean;
  setIsDetails: (isDetails: boolean) => void;
  isMemo: boolean;
  setIsMemo: (isMemo: boolean) => void;
  hasDrawer?: boolean;
  setHasDrawer: (hasDrawer: boolean) => void;
}

export { useState as useMobileMenuState };

const useState = create<State>((set) => ({
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: () => set((state) => ({ isMobileMenuOpen: !state.isMobileMenuOpen })),
  isList: localStorage.getItem('isList') === 'true' || false,
  setIsList: (isList: boolean) =>
    set({
      isList,
    }),
  isDirectory: localStorage.getItem('isDirectory') === 'true' || false,
  setIsDirectory: (isDirectory: boolean) =>
    set({
      isDirectory,
    }),
  isDetails: localStorage.getItem('isDetails') === 'true' || false,
  setIsDetails: (isDetails: boolean) =>
    set({
      isDetails,
    }),
  isMemo: false,
  setIsMemo: (isMemo: boolean) =>
    set({
      isMemo,
    }),
  hasDrawer: localStorage.getItem('hasDrawer') === 'true' || false,
  setHasDrawer: (hasDrawer: boolean) =>
    set({
      hasDrawer,
    }),
}));
