import { ProjectSalesInfoAffiliatedCompanyCategory } from '@front/src/features/project-sales-info/features/affiliated-company/types/domain';

export const projectSalesInfoAffiliatedCompanyCategoryObject = {
  [ProjectSalesInfoAffiliatedCompanyCategory.BUILDING_COMPANY]: '건축',
  [ProjectSalesInfoAffiliatedCompanyCategory.STRUCTURAL_DESIGN_COMPANY]: '구조',
  [ProjectSalesInfoAffiliatedCompanyCategory.IMPLEMENTING_COMPANY]: '시행사',
  [ProjectSalesInfoAffiliatedCompanyCategory.CONSTRUCTING_COMPANY]: '시공사',
  [ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_CONTRACT_COMPANY]: '발주처(계약서)',
  [ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_COMPANY]: '실발주처',
  [ProjectSalesInfoAffiliatedCompanyCategory.ETC_COMPANY]: '기타',
};

export const projectSalesInfoAffiliatedCompanyCategoryOptionList = [
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.BUILDING_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.BUILDING_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.STRUCTURAL_DESIGN_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.STRUCTURAL_DESIGN_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.IMPLEMENTING_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.IMPLEMENTING_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CONSTRUCTING_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CONSTRUCTING_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_CONTRACT_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_CONTRACT_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.CLIENT_COMPANY
      ],
  },
  {
    label:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.ETC_COMPANY
      ],
    value:
      projectSalesInfoAffiliatedCompanyCategoryObject[
        ProjectSalesInfoAffiliatedCompanyCategory.ETC_COMPANY
      ],
  },
];
