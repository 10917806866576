import { useQuery } from 'react-query';
import type { ProjectContractConfirmedView } from '@front/src/features/project-sales-info/features/contract/features/confirmed/types/view';
import { projectSalesInfoContractConfirmedApi } from '@front/src/features/project-sales-info/features/contract/features/confirmed/query/api';

export { query as projectSalesInfoContractConfirmedQuery };

const query = {
  useDetailGet: (id?: number, menuId?) => {
    const { data, isLoading } = useQuery<ProjectContractConfirmedView>({
      queryFn: () => projectSalesInfoContractConfirmedApi.getDetail(id, menuId),
      queryKey: ['project', 'sales-info', 'contract', 'confirmed', id],
      enabled: !!id && !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
