import React, { useState } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import WorkApprovalTableBody from '@front/src/features/work/features/work/features/approval/components/TableBody';
import PlusOne from '@mui/icons-material/PlusOne';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import WorkApprovalModal from '@front/src/features/work/features/work/features/approval/components/Modal';
import workApprovalQuery from '@front/src/features/work/features/work/features/approval/query/query';

export default function WorkApprovalTable(props: Readonly<SectionComponentProps>) {
  const { dataId, menuId, sectionId } = props;
  const [workId, setWorkId] = useState<number | undefined>();
  const setLeftDrawerState = useGlobalNavBarState((state) => state.setLeftDrawerState);
  const setNoteId = useGlobalNavBarState((state) => state.setNoteId);

  const action = () => {
    sessionStorage.setItem('dataId', dataId ? dataId.toString() : '');
    setNoteId(0);
    setLeftDrawerState('approval');
  };

  const itemList = [
    {
      text: '결재 상신',
      icon: PlusOne,
      action,
    },
  ];

  const onClose = () => setWorkId(undefined);

  const { data } = workApprovalQuery.useGetList({
    dataId,
    menuId,
    sectionId,
  });

  return (
    <>
      {workId && (
        <WorkApprovalModal
          {...props}
          workId={workId}
          onClose={onClose}
        />
      )}
      <UiBuilderTable
        {...props}
        BodyComponent={WorkApprovalTableBody}
        controlPanelHeaderItemList={itemList}
        options={{
          setWorkId,
        }}
        list={data?.approvalDocumentList}
      />
    </>
  );
}
