import React from 'react';
import Box from '@mui/material/Box';
import userSelectorRepository from '@front/src/features/user-selector/repository/repository';
import {
  type UseFieldArrayAppend,
  type UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import CheckboxBasicUI from '@front/src/components/components-with-design/component/CheckboxBasicUI';
import AvatarUI from '@front/src/components/components-with-design/component/AvatarUI';
import type { UserSelectorUnchangedMultiFormTFieldValues } from '@front/src/features/user-selector/features/unchanged-multi-form';
import type { UserVO } from '@front/user/domain';
import { ColorPalette } from '@front/assets/theme';

interface Props {
  append: UseFieldArrayAppend<UserSelectorUnchangedMultiFormTFieldValues, never>;
  remove: UseFieldArrayRemove;
}

export default function UserSelectorUnchangedMultiFormUserList(props: Readonly<Props>) {
  const { remove, append } = props;
  const { getValues, watch } = useFormContext<UserSelectorUnchangedMultiFormTFieldValues>();
  const { data: userList } = userSelectorRepository.useGetUserList({
    keyword: watch('keyword'),
    departmentId: watch('departmentId'),
    menuIdList: '',
    typeList: '',
  });
  const unchangeableUserList = getValues('unchangeableUserList');
  const changeableUserList = getValues('changeableUserList');
  const getChecked = (id: number) =>
    !!unchangeableUserList.concat(changeableUserList).find((user) => user.id === id);
  const getDisabled = (id) => !!unchangeableUserList.find((user) => user.id === id);
  const handleChange = (item: UserVO) => {
    const index = changeableUserList.findIndex((user) => user.id === item.id);
    if (index > -1) {
      remove(index);
    } else {
      append(item);
    }
  };
  return (
    <Box
      sx={{
        width: '270px',
        height: '360px',
        maxHeight: '360px',
        border: `1px solid ${ColorPalette.line.line}`,
        borderRadius: '5px',
        padding: '10px',
        overflowY: 'auto',
      }}
    >
      {userList?.length === 0 && <TypographyUI>검색 결과가 없습니다.</TypographyUI>}
      {userList &&
        userList.length > 0 &&
        userList?.map((user) => (
          <Box
            key={user.id}
            sx={{
              display: 'flex',
              gap: '6px',
            }}
          >
            <CheckboxBasicUI
              checked={getChecked(user.id)}
              disabled={getDisabled(user.id)}
              onChange={() => handleChange(user)}
            />
            <AvatarUI
              size="small"
              sx={{
                width: '26px',
              }}
            />
            <TypographyUI
              sx={{
                width: '40px',
              }}
            >
              {user.name}
            </TypographyUI>
            <TypographyUI
              sx={{
                width: 'auto',
              }}
            >
              {user.department.name}
            </TypographyUI>
          </Box>
        ))}
    </Box>
  );
}
