import { Layout } from '@front/src/features/affiliated-company/components/layout/modal-with-tab/layout';
import { Section } from '@front/src/features/affiliated-company/components/layout/modal-with-tab/section';
import { Header } from '@front/src/features/affiliated-company/components/layout/modal-with-tab/header';

export { modalWithTab };
const modalWithTab = {
  Layout,
  Header,
  Section,
};
