import { createContext } from 'react';
import type { AffiliatedCompanyProjectView } from '@front/src/features/affiliated-company/features/project/types/view';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';

export { Context as AffiliatedCompanyProjectListContext };

interface State {
  list?: AffiliatedCompanyProjectView[];
  estimationStatusVariableList?: VariableInformationView[];
  contractStatusVariableList?: VariableInformationView[];
  contractStepVariableList?: VariableInformationView[];
}

const Context = createContext<State>({});
