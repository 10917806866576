import React from 'react';
import { TableRow } from '@mui/material';
import { OldTd } from '@front/layouts/Table';
import TextLink from '@front/layouts/TextLink';
import type { ProposalVO } from '@front/ost_proposal/domain';
import { ProposalStatus, proposalStatusName } from '@front/ost_proposal/domain';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ListInCell from '@front/components/ListInCell';

interface Props {
  item: ProposalVO;
  index: number;
}

const ProposalHistoryItem = ({ item, index }: Props) => {
  const navigate = useNavigate();
  return (
    <TableRow key={`${item.id}_${index}`}>
      <OldTd>{index + 1}</OldTd>
      <OldTd>
        <TextLink
          onClick={() => {
            navigate(`/ost/proposal-center/${item.id}`);
          }}
        >
          {item.title}
        </TextLink>
      </OldTd>
      <OldTd>
        <ListInCell
          list={item.contributorViewList.map((item) => {
            if (item.contributor) {
              return item.contributor.name;
            }
            return '';
          })}
        />
      </OldTd>
      <OldTd>{item.category.name}</OldTd>
      <OldTd>{proposalStatusName(item.status)}</OldTd>
      <OldTd>
        <ListInCell
          list={item.estimatorList.map((item) => {
            if (item.estimator) {
              return item.estimator.name;
            }
            return '';
          })}
        />
      </OldTd>
      <OldTd>{item.grade ? item.grade : '-'}</OldTd>
      <OldTd>{item.result ? item.result.toLocaleString() : 0}</OldTd>
      <OldTd>
        {item.status === ProposalStatus.VOTING
          ? '진행중'
          : item.status === ProposalStatus.END
          ? '완료'
          : '-'}
      </OldTd>
      <OldTd>{item.paymentDate ? dayjs(item.paymentDate).format('YYYY-MM-DD') : '-'}</OldTd>
    </TableRow>
  );
};

export default ProposalHistoryItem;
