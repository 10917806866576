import React from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { FormProvider } from 'react-hook-form';
import workScheduleQuery from '@front/src/features/work/features/work/features/schedule/query/query';
import workScheduleMutation from '@front/src/features/work/features/work/features/schedule/query/mutation';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import WorkInterimReportDateFeature from '@front/src/features/work/features/work/features/schedule/features/interim-report-date';
import WorkScheduleLoadingSpinner from '@front/src/features/work/features/work/features/schedule/components/LoadingSpinner';
import useWorkScheduleForm from '@front/src/features/work/features/work/features/schedule/hooks/useForm';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';

export default function WorkScheduleTable(props: Readonly<SectionComponentProps>) {
  const { readOnly, dataId, menuId, sectionId } = props;
  const params = { dataId, menuId, sectionId };
  const { data: detail } = workScheduleQuery.useGetDetail(params);
  const { mutate: onUpdate } = workScheduleMutation.useUpdate(params);
  const { onSubmit, methods } = useWorkScheduleForm({
    onUpdate,
    detail,
  });
  return (
    <FormProvider {...methods}>
      <TableContainerUI
        sx={{
          position: 'relative',
        }}
      >
        <WorkScheduleLoadingSpinner />
        <TableTableUI>
          <TableBodyUI>
            <TableRowUI>
              <TableCellUI
                isHead
                width="160px"
              >
                지시일
              </TableCellUI>
              <TableCellUI width="140px">
                <DatePickerWithHookForm
                  name="instructionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="instructionDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="지시 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI
                isHead
                width="160px"
              >
                업무시작일
              </TableCellUI>
              <TableCellUI width="140px">
                <DatePickerWithHookForm
                  name="startDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="startDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="업무시작일 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <WorkInterimReportDateFeature {...props} />
            <TableRowUI>
              <TableCellUI isHead>최종보고일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="finalReportDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="finalReportDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="최종 보고 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>마감예정일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="dueDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="dueDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="마감 예정 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>완료일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="completionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="completionDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="완료 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
            <TableRowUI>
              <TableCellUI isHead>차기수행일</TableCellUI>
              <TableCellUI>
                <DatePickerWithHookForm
                  name="nextExecutionDate"
                  onSubmit={onSubmit}
                  readOnly={readOnly}
                />
              </TableCellUI>
              <TableCellUI>
                <UncontrolledInput
                  name="nextExecutionDateNote"
                  readOnly={readOnly}
                  onBlur={onSubmit}
                  placeholder="차기 수행 목적 및 KPI가 있다면 해당 내용을 입력해주세요"
                />
              </TableCellUI>
            </TableRowUI>
          </TableBodyUI>
        </TableTableUI>
      </TableContainerUI>
    </FormProvider>
  );
}
