import React from 'react';
import { ModalBodyUI } from '@front/src/components/components-with-design/layout/ModalUI';
import { FormProvider, useForm } from 'react-hook-form';
import WorkApprovalReadingHeader from '@front/src/features/work/features/work/features/approval/features/reading/components/Header';
import WorkApprovalReadingContent from '@front/src/features/work/features/work/features/approval/features/reading/components/Content';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

interface Props extends Pick<CellComponentProps, 'dataId' | 'menuId' | 'sectionId'> {
  workId: number;
  onClose: () => void;
}

export default function WorkApprovalReadingFeature(props: Readonly<Props>) {
  const { workId, onClose } = props;
  const methods = useForm({
    values: {
      workId: workId.toString(),
    },
  });
  const { watch } = methods;
  return (
    <ModalBodyUI
      sx={{
        width: '1334px',
      }}
    >
      <FormProvider {...methods}>
        <WorkApprovalReadingHeader
          {...props}
          onClose={onClose}
        />
        <WorkApprovalReadingContent key={watch('workId')} />
      </FormProvider>
    </ModalBodyUI>
  );
}
