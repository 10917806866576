import React, { useContext, useEffect } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { Th } from '@front/src/components/layout/table/th';
import { OldTd, Table } from '@front/layouts/Table';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormSelect } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import classes from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/components/modal-table.module.scss';
import { HookFormInput } from '@front/src/components/input-with-hook-form/hook-form-input';
import { YNOptionList } from '@front/src/features/project-sales-info/features/bid/features/bid-information/features/create-modal/utils/constants';
import { ProjectSalesInfoBidResultUpdateModalContext } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/widgets/context';
import { useProjectSalesInfoBidResultState } from '@front/src/features/project-sales-info/features/bid/features/bid-result/widgets/useState';
import {
  useBidInfoNumberArray,
  YNToStrConvertor,
} from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/create-modal/utils/constants';
import { InputType } from '@front/src/features/affiliated-company/types/domain';
import { useProjectSalesInfoDetailState } from '@front/src/features/project-sales-info/widgets/detail/useState';
import { ProjectSalesInfoBidResultUpdateModalBtnGroup } from '@front/src/features/project-sales-info/features/bid/features/bid-result/features/update-modal/components/modal-btn-group';
import { ProjectSalesInfoBidResultPartnerCompanyTableRowSelector } from '@front/src/features/project-sales-info/features/bid/features/bid-result/components/partner-company-table-row-selector';
import { HookFormTextarea } from '@front/src/components/textarea-with-hook-form/textarea-with-hook-form';
import { useShallow } from 'zustand/react/shallow';

export { ModalTable as ProjectSalesInfoBidResultUpdateModalTable };

const ModalTable = () => {
  const {
    d: { bidResultDetail },
  } = useContext(ProjectSalesInfoBidResultUpdateModalContext);
  const id = useProjectSalesInfoDetailState((state) => state.id);
  const detailId = useProjectSalesInfoBidResultState((state) => state.detailId);
  const methods = useForm({
    values: {
      id: bidResultDetail?.id ?? detailId,
      amount: bidResultDetail?.amount?.toLocaleString() ?? '',
      note: bidResultDetail?.note ?? '',
      isDoneBid: YNToStrConvertor(bidResultDetail?.isDoneBid),
      projectBidInformationId: bidResultDetail?.projectBidInformationId ?? '',
      winnerId: bidResultDetail?.winnerId ?? '',
      winnerName: bidResultDetail?.winnerCompanyName ?? '',
    },
  });
  const projectBidInfoList = useBidInfoNumberArray(id!);
  useEffect(() => {
    methods.setValue('projectBidInformationId', bidResultDetail?.projectBidInformationId ?? '');
  }, [projectBidInfoList]);
  const { readOnly } = useProjectSalesInfoBidResultState(
    useShallow((state) => ({
      readOnly: state.readOnly,
    }))
  );
  return (
    <FormProvider {...methods}>
      <div className={classes.wrapper}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Th
                  center={true}
                  width="140px"
                >
                  입찰 회차
                </Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="projectBidInformationId"
                    optionList={projectBidInfoList}
                    width="120px"
                    defaultLabel="선택"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>업체명</Th>
                <OldTd>
                  <ProjectSalesInfoBidResultPartnerCompanyTableRowSelector readOnly={!!readOnly} />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>총 금액</Th>
                <OldTd>
                  <HookFormInput
                    name="amount"
                    inputType={InputType.AMOUNT}
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>최종낙찰여부</Th>
                <OldTd>
                  <HookFormSelect
                    isDefaultPossible
                    name="isDoneBid"
                    optionList={YNOptionList}
                    width="120px"
                    defaultLabel="선택"
                    disabled={readOnly}
                  />
                </OldTd>
              </TableRow>
              <TableRow>
                <Th center={true}>비고</Th>
                <OldTd>
                  <HookFormTextarea
                    name="note"
                    minRows={2}
                    resize="none"
                    disabled={readOnly}
                    variable={true}
                  />
                </OldTd>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ProjectSalesInfoBidResultUpdateModalBtnGroup />
      </div>
    </FormProvider>
  );
};
