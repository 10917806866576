import React from 'react';
import useComment from '@front/src/features/drawer-approval-document/repository/useComment';
import DrawerCommentSection from '@front/src/components/drawer-comment/DrawerCommentSection';
import type { ApprovalDocumentDetailView } from '@front/src/features/drawer-approval-document/types/view';
import { useFormContext } from 'react-hook-form';

interface Props {
  item?: ApprovalDocumentDetailView;
}

export default function ApprovalDocumentComment({ item }: Props) {
  const { createComment, updateComment, deleteComment } = useComment('approval');
  const { handleSubmit, reset } = useFormContext();
  const onSubmit = handleSubmit((data) => {
    createComment({
      targetId: item?.id ?? 0,
      targetType: 'APPROVAL',
      contents: data.comment,
      mentionList: [],
    });
    reset();
  });
  return (
    <DrawerCommentSection>
      <DrawerCommentSection.Count
        text="결재 의견"
        count={item?.commentList.length ?? 0}
      />
      <DrawerCommentSection.Input
        isMention={false}
        onSubmit={onSubmit}
      />
      <DrawerCommentSection.List
        onDelete={deleteComment}
        onUpdate={updateComment}
      />
    </DrawerCommentSection>
  );
}
