import {
  contractEarningsApiUrl,
  smProjectCollectionApiUrl,
} from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/utils/constants';
import axios from 'axios';
import type { SmProjectCollectionParameter } from '@front/src/features/project-sales-info/features/collection/features/contract-earnings/types/parameters';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  updateContractEarningsItemDetails: (detailId: number) =>
    `${smProjectCollectionApiUrl}/collection/${detailId}`,
  getContractEarningsItemDetails: (detailId: number) =>
    `${smProjectCollectionApiUrl}/collection/${detailId}`,
  getConsortiumCompany: (id: number) => `${contractEarningsApiUrl}/${id}/consortium-company`,
};

export const projectSalesInfoContractEarningsItemUpdateModalApi = {
  updateContractEarningsItemDetails: async (params: SmProjectCollectionParameter, menuId) =>
    await axios.put(url.updateContractEarningsItemDetails(params.id!), params, {
      headers: getMenuIdHeaders(menuId),
    }),
  getContractEarningsItemDetails: async (detailId: number, menuId) => {
    const { data } = await axios.get(url.getContractEarningsItemDetails(detailId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getConsortiumCompany: async (id: number, menuId) => {
    const { data } = await axios.get(url.getConsortiumCompany(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
